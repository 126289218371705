import { api } from "./api";

async function getById(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/inventory/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getTotals(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/inventory/totals/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}


async function getFiles(companyId: number, year: number, type: number, name: string, second_name = '') {
  try {
    if (second_name && second_name !== ''){
      const { data } = await api.get(`/inventory/file/${companyId}/${year}/${type}/${name}/${second_name}`);
      return data
    }else{
      const { data } = await api.get(`/inventory/file/${companyId}/${year}/${type}/${name}`);
      return data
    }
  } catch (error) {
    console.log(error);
  }
}

async function getCompensationFiles(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/inventory/compensation/file/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getCertificates(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/inventory/certificate/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getART(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/inventory/art/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveFile(companyId: number, year: number, itens: any) {
  try {
    const { data } = await api.put(`/inventory/file/${companyId}/${year}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveCompensationFile(companyId: number, year: number, itens: any) {
  try {
    const { data } = await api.put(`/inventory/compensation/file/${companyId}/${year}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveCertificate(companyId: number, year: number, itens: any) {
  try {
    const { data } = await api.put(`/inventory/certificate/${companyId}/${year}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveART(companyId: number, year: number, itens: any) {
  try {
    const { data } = await api.put(`/inventory/art/${companyId}/${year}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteFile(fileId: number) {
  try {
    const { data } = await api.delete(`/inventory/file/${fileId}`);
    return data;
  } catch (error) {
    return false;
  }
}

async function deleteCompensationFile(fileId: number) {
  try {
    const { data } = await api.delete(`/inventory/compensation/file/${fileId}`);
    return data;
  } catch (error) {
    return false;
  }
}

async function deleteCertificate(fileId: number) {
  try {
    const { data } = await api.delete(`/inventory/certificate/${fileId}`);
    return data;
  } catch (error) {
    return false;
  }
}

async function deleteART(fileId: number) {
  try {
    const { data } = await api.delete(`/inventory/art/${fileId}`);
    return data;
  } catch (error) {
    return false;
  }
}

async function getScopes(companyId: number, year: number, type: number) {
  try {
    const { data } = await api.get(`/inventory/scopes/${companyId}/${year}/${type}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/inventory", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function updateById(companyId: number, year: number, inventory: any) {
  try {
    const { data } = await api.put(`/inventory/${companyId}/${year}`, inventory);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateMethodId(companyId: number, year: number, itens: any) {
  try {
    const { data } = await api.put(`/inventory/method/${companyId}/${year}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateCompensationId(companyId: number, year: number, itens: any) {
  try {
    const { data } = await api.put(`/inventory/compensation/${companyId}/${year}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateOtherId(companyId: number, year: number, itens: any) {
  try {
    const { data } = await api.put(`/inventory/other/${companyId}/${year}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateOperationalLimitId(companyId: number, year: number, itens: any) {
  try {
    const { data } = await api.put(`/inventory/operational_limit/${companyId}/${year}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateOrganismId(companyId: number, year: number, itens: any) {
  try {
    const { data } = await api.put(`/inventory/organism/${companyId}/${year}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateBasicDataId(companyId: number, year: number, itens: any) {
  try {
    const { data } = await api.put(`/inventory/basic_data/${companyId}/${year}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateGoalId( 
    year: number, 
    company_id: number,
    emission_tco2e: any, 
    reduction_percentage: any, 
    goal_year: number, 
    reduction_target: boolean, 
    inventory_id: number) {
  try {
    const { data } = await api.put(`/inventory/goal/calculate`,
    {
      company_id: company_id,
      year: year,
      emission_tco2e: emission_tco2e,
      reduction_percentage: reduction_percentage,
      goal_year: goal_year,
      reduction_target: reduction_target,
      inventory_id: inventory_id
    }
    );
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateGoalId(companyId: number, year: number, itens: any) {
  try {
    const { data } = await api.put(`/inventory/goal/${companyId}/${year}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateInventoryLimitId(companyId: number, year: number, itens: any) {
  try {
    const { data } = await api.put(`/inventory/inventory_limit/${companyId}/${year}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

const inventoryService = {
  getById,
  getFiles,
  saveFile,
  getScopes,
  getTotals,
  deleteFile,
  getByParams,
  updateById,
  updateGoalId,
  updateOtherId,
  updateMethodId,
  getCertificates,
  saveCertificate,
  updateOrganismId,
  updateBasicDataId,
  deleteCertificate,
  updateCompensationId,
  getCompensationFiles,
  saveCompensationFile,
  updateInventoryLimitId,
  deleteCompensationFile,
  updateOperationalLimitId,
  calculateGoalId,
  saveART,
  getART,
  deleteART
};

export default inventoryService;
