import styled from 'styled-components';

export const Card = styled.div`
    display: flex;
    flex-wrap: wrap; 
    // border: 1px solid #31363F;
    padding: 16px;
    margin: 16px 0;
    margin-right: 16px;
    align-items: start; 
`;

export const CardColumn = styled.div`
    flex: 1; 
    padding: 8px;
    box-sizing: border-box; 
`;

export const TableContainer = styled.div`
    overflow-x: auto; 
    max-width: 100%; 
`;

export const Table = styled.table`
    width: 100%; 
    border-collapse: collapse;
`;

export const TableHeader = styled.th`
    background-color: #E0E0E0; // Cinza
    padding: 8px 16px;
    text-align: left;
`;

export const TableRow = styled.tr`
    &:not(:last-child) {
        border-bottom: 1px solid #31363F;
    }
`;

export const TableCell = styled.td`
    padding: 8px 16px;
`;

export const Summary = styled.div`
    padding-left: 32px; 
    box-sizing: border-box;
    max-width: 100%; 
`;

export const Legend = styled.div`
    display: flex;
    padding: 16px 0; 
    font-size: 0.9em; 
`;

export const LegendItem = styled.span`
    display: flex;
    align-items: center; 

    & + & { 
        margin-left: 16px; 
    }

    & > svg, & > span {
        margin-right: 8px; 
    }
`;

export const EmptyStateContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 300px;  // Defina a altura que desejar
    border: 1px dashed gray;
    border-radius: 5px;
    padding: 20px;
    background-color: #f9f9f9;

    h3 {
        color: gray;
        margin-bottom: 10px;
    }

    p {
        text-align: center;
        color: #999;
    }
`;