import React, { useState, useEffect } from 'react';
import { View, Text, Image as ImagePDF} from '@react-pdf/renderer';
import { styles } from '../styles/common';
import sinergiaLogo from '../../../assets/images/sinergiaLogo.png';
import { ClientLogo } from '../../../pages/Inventory/components/Reports/DownloadIGEE/components/ClientLogo';
import sinergiaAzul from "../../../assets/images/logo-azul.png";

interface HeaderProps {
  title: string;
  companyName: string;
  logo?: string;
  companyId?: number;
}

const Header: React.FC<HeaderProps> = ({ title, companyName, logo, companyId }) => {
  const [logoWidth, setLogoWidth] = useState<number | null>(null);

  useEffect(() => {
    if (logo) {
      const img = new Image();
      img.onload = function() {
        const originalWidth = (this as HTMLImageElement).width;
        const originalHeight = (this as HTMLImageElement).height;
        const targetHeight = 45;
        const scaleFactor = targetHeight / originalHeight;
        const calculatedWidth = originalWidth * scaleFactor;
        setLogoWidth(calculatedWidth);
      };
      img.src = logo;
    }
  }, [logo]);

  return (
    <View style={styles.headerContainer} fixed>
      <View style={styles.headerMainContent}>
        
        <View style={styles.headerTitleSection}>
          <Text style={{...styles.headerTitle, ...styles.Bold}}>{title}</Text>
          <Text style={{...styles.headerTitle, ...styles.Bold}}>{companyName}</Text>
        </View>
        <View style={styles.logoLine} />
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10, marginRight: 20 }}>
          <ClientLogo logo={logo} companyId={companyId} />
            <ImagePDF
              src={sinergiaAzul}
              style={{
              width: 65,
              height: 25
              }}
            />
        </View>
      </View>

      {/* Linha de separação */}
      <View style={styles.separatorLine} />
    </View>
  );
};

export default Header;
