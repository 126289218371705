import styled from "styled-components";

interface ContainerProps {
    isMenuOpen: boolean;
    observation?: boolean;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    border: 1px dashed #9E9E9E;
    margin: 5px;
    width: ${props => props.isMenuOpen ? "calc(100vw - 320px)" : "calc(100vw - 120px)"};
    max-width: ${props => props.isMenuOpen ? "calc(100vw - 320px)" : "calc(100vw - 120px)"};
    height: ${props => props?.observation ? '70px' : '40px'};
    
`;

export const Main = styled.div`
    margin: 10px;
    width: 70%;
`;

export const Section = styled.div`
    font-weight: bold;
    margin: 10px;
    text-align: right;
    width: 30%;

`;

export const Highlight = styled.div`
    font-weight: bold;
    font-size: 13px;
    margin-top: 10px;
    text-align: left;
    width: 70%;
`;
