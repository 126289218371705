import { StyleSheet, Font } from '@react-pdf/renderer'
import poppinsBold from '../../../assets/fonts/Poppins-SemiBold.ttf'
import poppinsRegular from '../../../assets/fonts/Poppins-Light.ttf'
import notoSans from '../../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf'
import { colors } from '../../../pages/Dashboard/utils/colors'
import MuseoModerno from "../../../assets/fonts/MuseoModerno-Light.ttf";
import MuseoModernoItalic from "../../../assets/fonts/MuseoModerno-Italic.ttf";
import MuseoModernoBoldItalic from "../../../assets/fonts/MuseoModerno-BoldItalic.ttf";
import MuseoModernoExtraLight from "../../../assets/fonts/MuseoModerno-ExtraLight.ttf";
import MuseoModernoExtraBold from "../../../assets/fonts/MuseoModerno-ExtraBold.ttf";
import MuseoModernoBold from "../../../assets/fonts/MuseoModerno-Bold.ttf";


Font.register({ family: 'NotoSans-Regular', src: notoSans })

Font.register({ family: 'PoppinsBold', src: poppinsBold })
Font.register({ family: 'PoppinsLight', src: poppinsRegular })
Font.register({ family: "MuseoModerno", src: MuseoModerno });
Font.register({
  family: "MuseoModerno-Light",
  src: MuseoModernoExtraLight,
});
Font.register({
  family: "MuseoModerno-Italic",
  src: MuseoModernoItalic,
});
Font.register({
  family: "MuseoModerno-ExtraBold",
  src: MuseoModernoExtraBold,
});
Font.register({
  family: "MuseoModerno-BoldItalic",
  src: MuseoModernoBoldItalic,
});
Font.register({
  family: "MuseoModerno-Bold",
  src: MuseoModernoBold,
});

const bold = 'PoppinsBold'
const lineHeight = 5
const subTitleMargin = 10
const subTitleLineSize = 30
const sidesMargin = `${subTitleLineSize + subTitleMargin}px`
const textSize = 9
const mainTitleSize = 18
const subTitleSize = 14
const darkGrey = '#c6c5c5'

export const styles = StyleSheet.create({
  boldItalic: {
    fontFamily: "MuseoModerno-BoldItalic",
  },
  italic: {
    fontFamily: "MuseoModerno-Italic",
  },
  light: {
    fontFamily: "MuseoModerno",
  },
  extraBold: {
    fontFamily: "MuseoModerno-ExtraBold",
  },
  extraLight: {
    fontFamily: "MuseoModerno-Light",
  },
  Bold:{
    fontFamily: "MuseoModerno-Bold"
  },
  headerContainer: {
    padding: 10, 
    flexDirection: 'column', 
  },
  headerMainContent: {
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    marginBottom: 5, 
  },
  headerTitleSection: {
    flex: 2, 
    marginRight: 10, 
  },
  headerTitle: {
    fontSize: 12,
  },
  headerLogosSection: {
    flex: 1, 
    flexDirection: 'row', 
    justifyContent: 'space-between', 
  },
  companyLogo: {
    width: 50,
    height: 'auto',
    marginRight: 20, 
  },
  sinergiaLogo: {
    width: 100, 
    height: 39.2,
  },
  separatorLine: {
    borderTopWidth: 1, 
    borderTopColor: colors.SkyBlueMedium, 
  },
  dot: {
    width: 3,
    height: 3,
    marginRight: 5,
    borderRadius: '50%',
    backgroundColor: 'black'
  },
  container: { margin: `15px ${sidesMargin}` },
  textContainer: { margin: `5px ${sidesMargin}` },
  subscript: { fontFamily: 'NotoSans-Regular' },
  sourceContainer: { margin: '3px 0' },
  sourcesItemsContainer: { marginTop: 20 },
  totalRow: { flexDirection: 'row', backgroundColor: darkGrey },
  column: { flexDirection: 'column' },
  row: { flexDirection: 'row' },
  titleSummary: {
    fontSize: 12,
    textAlign: 'center',
    fontFamily: bold,
    marginBottom: 15,
  },
  emphasis: {
    fontSize: textSize,
    marginBottom: 5,
    fontFamily: bold,
    fontWeight: 'bold',
  },
  textSummary: {
    fontSize: textSize,
    marginBottom: 10,
    fontFamily: 'PoppinsLight',
    textAlign: 'justify',
  },
  spaceBetween: { justifyContent: 'space-between' },
  alignCenter: { alignItems: 'center' },
  start: { alignItems: 'flex-start' },
  justifiedText:{ textAlign: 'justify'},
  mainTitle: { fontSize: mainTitleSize, fontFamily: bold },
  preliminaryVersion: { fontSize: 64, fontFamily: bold, transform: "rotate(-70deg)", color: "rgb(196, 35, 41, 0.3)", width: 1180, height: 520 },
  subTitle: {
    fontFamily: bold,
    fontSize: subTitleSize,
    marginLeft: subTitleMargin
  },
  companyFooter: {
    fontFamily: bold,
    fontSize: 10
  },
  subTopicTitle: { fontFamily: bold, fontSize: 10 },
  scopeTitle: {
    fontSize: 10,
    fontFamily: bold,
    borderBottom: `1px solid ${colors.LightMintGreen}`,
    borderTop: `1px solid ${colors.LightMintGreen}`,
    textAlign: 'center'
  },
  pageTitle: { fontSize: 12, fontFamily: bold },
  text: { fontFamily: 'PoppinsLight', fontSize: textSize },
  textBold: { fontFamily: 'PoppinsBold', fontSize: textSize, fontWeight: "bold", marginRight: 5 },
  textSubTitle: { fontFamily: 'PoppinsBold', fontSize: 13, fontWeight: "bold", marginRight: 5, marginBottom: 5 },
  sourceText: {
    fontFamily: 'NotoSans-Regular',
    fontSize: 10,
    marginRight: 50,
  },
  emptySourceText: {
    fontFamily: 'NotoSans-Regular',
    fontSize: 10,
    margin: 20,
    textAlign: "center",
  },
  imageSourceText: {
    width: 6,
    height: 6,
    marginTop: 4,
    marginLeft: 30,
    marginRight: 10,
  },
  logoLine: {
    backgroundColor: colors.SkyBlueMedium,
    height: 40,
    width: 2,
    margin: '0 10px'
  },
  fullWidthLine: {
    backgroundColor: colors.SkyBlueMedium,
    height: lineHeight,
    width: '100%'
  },
  line: {
    backgroundColor: colors.SkyBlueMedium,
    height: lineHeight,
    width: '60%',
    margin: '10px 0'
  },
  subTitleLine: {
    backgroundColor: colors.SkyBlueMedium,
    height: lineHeight,
    width: subTitleLineSize
  },
  sourceLine: {
    backgroundColor: colors.SkyBlueMedium,
    height: 2,
    width: 30
  },
  footerLine: {
    backgroundColor: colors.SkyBlueMedium,
    height: 1,
    width: '100%',
    bottom: 0,
    margin: '0 10px'
  },
  footerContainer: {
    margin: `0px 10px`,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
