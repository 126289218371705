import { EletricityParams } from "../interfaces/ParamEmissonGeneric";
import { api } from "./api";

async function calculatePurchaseThermalEnergy(kettle_efficiency: number, purchased_steam_gj: number) {
  try {
    const { data } = await api.put(`/scope2/purchase_thermal_energy/calculate`,
      { kettle_efficiency: kettle_efficiency, purchased_steam_gj: purchased_steam_gj });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getPurchaseThermalEnergy(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope2/purchase_thermal_energy/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function savePurchaseThermalEnergy(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope2/purchase_thermal_energy/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculatePurchaseEletricalEnergy(fuel_id: number, energy_type_id: number, emission_factor: boolean,
  purchase_jan: number, purchase_feb: number, purchase_mar: number, purchase_apr: number, purchase_may: number,
  purchase_jun: number, purchase_jul: number, purchase_aug: number, purchase_sep: number, purchase_oct: number,
  purchase_nov: number, purchase_dec: number, purchase_yearly: number, plant_efficiency: number,
  emission_tco2_mwh: number, emission_tch4_mwh: number, emission_tn2o_mwh: number,
  emission_tco2_bio_mwh: number) {
  try {
    const { data } = await api.put(`/scope2/purchase_eletrical_energy/tracked/calculate`,
      {
        fuel_id: fuel_id, energy_type_id: energy_type_id, emission_factor: emission_factor,
        purchase_jan: purchase_jan, purchase_feb: purchase_feb, purchase_mar: purchase_mar,
        purchase_apr: purchase_apr, purchase_may: purchase_may, purchase_jun: purchase_jun,
        purchase_jul: purchase_jul, purchase_aug: purchase_aug, purchase_sep: purchase_sep,
        purchase_oct: purchase_oct, purchase_nov: purchase_nov, purchase_dec: purchase_dec,
        purchase_yearly: purchase_yearly, plant_efficiency: plant_efficiency,
        emission_tco2_mwh: emission_tco2_mwh, emission_tch4_mwh: emission_tch4_mwh,
        emission_tn2o_mwh: emission_tn2o_mwh, emission_tco2_bio_mwh: emission_tco2_bio_mwh
      });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculatePurchaseEletricalEnergyUntracked(year: number, purchase_jan: number, purchase_feb: number,
  purchase_mar: number, purchase_apr: number, purchase_may: number, purchase_jun: number, purchase_jul: number,
  purchase_aug: number, purchase_sep: number, purchase_oct: number, purchase_nov: number, purchase_dec: number,
  purchase_yearly: number) {
  try {
    const { data } = await api.put(`/scope2/purchase_eletrical_energy/untracked/calculate`,
      {
        year: year, purchase_jan: purchase_jan, purchase_feb: purchase_feb, purchase_mar: purchase_mar,
        purchase_apr: purchase_apr, purchase_may: purchase_may, purchase_jun: purchase_jun,
        purchase_jul: purchase_jul, purchase_aug: purchase_aug, purchase_sep: purchase_sep,
        purchase_oct: purchase_oct, purchase_nov: purchase_nov, purchase_dec: purchase_dec,
        purchase_yearly: purchase_yearly
      });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getPurchaseEletricalEnergy(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope2/purchase_eletrical_energy/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function savePurchaseEletricalEnergy(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope2/purchase_eletrical_energy/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}


async function calculateElectricityLocation(location: 'other' | 'amazonas' | 'sin' | 'avoided', params: EletricityParams) {
  try {
    const { data } = await api.put(`/scope2/eletrical_energy_location/${location}/calculate`, params);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateElectricityLocationOther(params: EletricityParams) {
  return calculateElectricityLocation('other', params);
}

async function calculateElectricityLocationAmazonas(params: EletricityParams) {
  return calculateElectricityLocation('amazonas', params);
}

async function calculateElectricityLocationSin(params: EletricityParams) {
  return calculateElectricityLocation('sin', params);
}

async function calculateElectricityLocationAvoided(params: EletricityParams) {
  return calculateElectricityLocation('avoided', params);
}

async function getElectricityLocation(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope2/eletrical_energy_location/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveElectricityLocation(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope2/eletrical_energy_location/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateLossesElectricalEnergyPurchase(fuel_id: number, energy_type_id: number, emission_factor: boolean,
  purchase_jan: number, purchase_feb: number, purchase_mar: number, purchase_apr: number, purchase_may: number,
  purchase_jun: number, purchase_jul: number, purchase_aug: number, purchase_sep: number, purchase_oct: number,
  purchase_nov: number, purchase_dec: number, purchase_yearly: number, plant_efficiency: number,
  emission_tco2_mwh: number, emission_tch4_mwh: number, emission_tn2o_mwh: number,
  emission_tco2_bio_mwh: number) {
  try {
    const { data } = await api.put(`/scope2/losses_electrical_energy_purchase/tracked/calculate`,
      {
        fuel_id: fuel_id, energy_type_id: energy_type_id, emission_factor: emission_factor,
        purchase_jan: purchase_jan, purchase_feb: purchase_feb, purchase_mar: purchase_mar,
        purchase_apr: purchase_apr, purchase_may: purchase_may, purchase_jun: purchase_jun,
        purchase_jul: purchase_jul, purchase_aug: purchase_aug, purchase_sep: purchase_sep,
        purchase_oct: purchase_oct, purchase_nov: purchase_nov, purchase_dec: purchase_dec,
        purchase_yearly: purchase_yearly, plant_efficiency: plant_efficiency,
        emission_tco2_mwh: emission_tco2_mwh, emission_tch4_mwh: emission_tch4_mwh,
        emission_tn2o_mwh: emission_tn2o_mwh, emission_tco2_bio_mwh: emission_tco2_bio_mwh
      });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateLossesElectricalEnergyPurchaseUntracked(year: number, purchase_jan: number, purchase_feb: number,
  purchase_mar: number, purchase_apr: number, purchase_may: number, purchase_jun: number, purchase_jul: number,
  purchase_aug: number, purchase_sep: number, purchase_oct: number, purchase_nov: number, purchase_dec: number,
  purchase_yearly: number) {
  try {
    const { data } = await api.put(`/scope2/losses_electrical_energy_purchase/untracked/calculate`,
      {
        year: year, purchase_jan: purchase_jan, purchase_feb: purchase_feb, purchase_mar: purchase_mar,
        purchase_apr: purchase_apr, purchase_may: purchase_may, purchase_jun: purchase_jun,
        purchase_jul: purchase_jul, purchase_aug: purchase_aug, purchase_sep: purchase_sep,
        purchase_oct: purchase_oct, purchase_nov: purchase_nov, purchase_dec: purchase_dec,
        purchase_yearly: purchase_yearly
      });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getLossesElectricalEnergyPurchase(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope2/losses_electrical_energy_purchase/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveLossesElectricalEnergyPurchase(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope2/losses_electrical_energy_purchase/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateLossesEletricalEnergyLocationOther(emission_co2: number, emission_ch4: number, emission_n2o: number) {
  try {
    const { data } = await api.put(`/scope2/losses_eletrical_energy_location/other/calculate`,
      { emission_co2: emission_co2, emission_ch4: emission_ch4, emission_n2o: emission_n2o });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateLossesEletricalEnergyLocationAmazonas(year: number, purchase_jan: number, purchase_feb: number,
  purchase_mar: number, purchase_apr: number, purchase_may: number, purchase_jun: number, purchase_jul: number,
  purchase_aug: number, purchase_sep: number, purchase_oct: number, purchase_nov: number, purchase_dec: number,
  purchase_yearly: number) {
  try {
    const { data } = await api.put(`/scope2/losses_eletrical_energy_location/amazonas/calculate`,
      {
        year: year, purchase_jan: purchase_jan, purchase_feb: purchase_feb, purchase_mar: purchase_mar,
        purchase_apr: purchase_apr, purchase_may: purchase_may, purchase_jun: purchase_jun,
        purchase_jul: purchase_jul, purchase_aug: purchase_aug, purchase_sep: purchase_sep,
        purchase_oct: purchase_oct, purchase_nov: purchase_nov, purchase_dec: purchase_dec,
        purchase_yearly: purchase_yearly
      });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateLossesEletricalEnergyLocationSin(year: number, purchase_jan: number, purchase_feb: number,
  purchase_mar: number, purchase_apr: number, purchase_may: number, purchase_jun: number, purchase_jul: number,
  purchase_aug: number, purchase_sep: number, purchase_oct: number, purchase_nov: number, purchase_dec: number,
  purchase_yearly: number) {
  try {
    const { data } = await api.put(`/scope2/losses_eletrical_energy_location/sin/calculate`,
      {
        year: year, purchase_jan: purchase_jan, purchase_feb: purchase_feb, purchase_mar: purchase_mar,
        purchase_apr: purchase_apr, purchase_may: purchase_may, purchase_jun: purchase_jun,
        purchase_jul: purchase_jul, purchase_aug: purchase_aug, purchase_sep: purchase_sep,
        purchase_oct: purchase_oct, purchase_nov: purchase_nov, purchase_dec: purchase_dec,
        purchase_yearly: purchase_yearly
      });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getLossesEletricalEnergyLocation(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope2/losses_eletrical_energy_location/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveLossesEletricalEnergyLocation(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope2/losses_eletrical_energy_location/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

const scope2Service = {
  calculatePurchaseThermalEnergy,
  savePurchaseThermalEnergy,
  getPurchaseThermalEnergy,

  calculatePurchaseEletricalEnergyUntracked,
  calculatePurchaseEletricalEnergy,
  savePurchaseEletricalEnergy,
  getPurchaseEletricalEnergy,

  calculateLossesElectricalEnergyPurchaseUntracked,
  calculateLossesElectricalEnergyPurchase,
  saveLossesElectricalEnergyPurchase,
  getLossesElectricalEnergyPurchase,

  calculateElectricityLocationAmazonas,
  calculateElectricityLocationOther,
  calculateElectricityLocationSin,
  calculateElectricityLocationAvoided,
  getElectricityLocation,
  saveElectricityLocation,

  calculateLossesEletricalEnergyLocationAmazonas,
  calculateLossesEletricalEnergyLocationOther,
  calculateLossesEletricalEnergyLocationSin,
  getLossesEletricalEnergyLocation,
  saveLossesEletricalEnergyLocation,
};

export default scope2Service;
