import React from 'react';
import { WrappedContainer } from './styles'; // Ajuste o caminho conforme necessário
import { Uninformed } from '../Uninformed';


export interface WrappedUninformedProps {
  text: string;
}


const WrappedUninformed: React.FC<WrappedUninformedProps> = ({ text }) => {
    return (
        <WrappedContainer>
            <Uninformed text={text} />
        </WrappedContainer>
    );
};

export default WrappedUninformed;
