import { useLocation, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import Select from 'react-select'
import Parser from 'html-react-parser'
import { GoBack } from '../../../../../components/GoBack'
import { TitleText } from '../../../../../components/TitleText'
import { Button } from '../../../../../components/Button/styles'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import { Uninformed } from '../../../../../components/Uninformed'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'
import { Modal } from '../../../../../components/Modal'
import useAuth from '../../../../../hooks/useAuth'
import { Container } from './styles'
import { formatPrice3, formatPrice5 } from '../../../../../utils/transform'
import TrashIcon from '../../../../../assets/icons/trash.svg'
import InfoButtonMin from '../../../../../assets/icons/info-sign.png'
import inventoryService from '../../../../../services/inventoryService'
import scope1Service from '../../../../../services/scope1Service'
import vehicleFuelService from '../../../../../services/vehicleFuelService'
import scopeService from '../../../../../services/scopeService'
import { toast } from 'react-hot-toast'
import { Loading } from '../../../../../components/Loading'
import { ModalDeleteDataTable } from '../../../../../components/ModalDeleteDataTable'

export const MobileAirTransport = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([]) 
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  const [guidelines, setGuidelines] = useState<string | undefined>()
  const [openModal, setOpenModal] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const location = useLocation()

  const [fuels, setFuels] = useState<any>([])
  const [scopes, setAirFuel] = useState<any>([])
  const [emissionCo2AirFuel, setEmissionCo2AirFuel] = useState<any>()
  const [emissionCh4AirFuel, setEmissionCh4AirFuel] = useState<any>()
  const [emissionN2oAirFuel, setEmissionN2oAirFuel] = useState<any>()
  const [scopeAirFuel, setScopeAirFuel] = useState<string | undefined>()
  const [emissionTco2eAirFuel, setEmissionTco2eAirFuel] = useState<any>()
  const [selectedYear, setSelectedYear] = useState<any>();
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const { isCommonUser } = useAuth()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-1/combustao-movel'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(1, 'combustao_movel', 'transporte_aereo')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  const getYearId = (year: number) : number | null => {
    const yearMap: {[key: number]: number} ={
      2024: 2024,
      2023: 2023,
      2022: 2022,
      2021: 2021,
      2020: 2020,
      2019: 2019,
    }
    return yearMap[year] || null;
  }

  const filteredFuel = fuels?.filter((fuel: any) => fuel.year_id === getYearId(selectedYear)) || [];
  
  useEffect(() => {
    if (inventorySelected.year) {
      setSelectedYear(inventorySelected.year);
    }
  }, [inventorySelected.year])

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors }
  } = useForm<any>()

  const handleChangeFuel = (index: number, ignore_field?: string) => {
    const vehicle_fuel_id = scopes[index].vehicle_fuel_id
    const consumptionYearly = scopes[index].consumption_yearly
      ? parseFloat(scopes[index].consumption_yearly)
      : 0

    async function calculate() {
      const result = await scope1Service.calculateMobileCombustionAirFuel(
        vehicle_fuel_id,
        consumptionYearly
      )

      if (result) {
        scopes[index].emission_co2 = parseFloat(result.emission_co2)
        scopes[index].emission_ch4 = parseFloat(result.emission_ch4)
        scopes[index].emission_n2o = parseFloat(result.emission_n2o)
        scopes[index].factor_co2_kg = parseFloat(result.factor_co2_kg)
        scopes[index].factor_ch4_kg = parseFloat(result.factor_ch4_kg)
        scopes[index].factor_n2o_kg = parseFloat(result.factor_n2o_kg)
        scopes[index].emission_tco2e = parseFloat(result.emission_tco2e)
        scopes[index].factor_co2_kg_bio = parseFloat(result.factor_co2_kg_bio)
        scopes[index].factor_ch4_kg_bio = parseFloat(result.factor_ch4_kg_bio)
        scopes[index].factor_n2o_kg_bio = parseFloat(result.factor_n2o_kg_bio)

        setScopeAirFuel(
          'change_scope_item_' + index + '_' + vehicle_fuel_id + '_' + consumptionYearly
        )

        setAirFuelValues(scopes, ignore_field)
        calculateAirFuel(scopes)
      }
    }

    calculate()
  }

  function handleDeleteFuel(index: number) {
    const itens = []

    for (let i = 0; i < scopes?.length; i++) {
      if (i !== index) {
        itens.push(scopes[i])
      }
    }

    setScopeAirFuel('remove_scope_item_' + index)

    setValue('fuel_private_vehicle', [])

    setAirFuelValues(itens)
    calculateAirFuel(itens)
  }

  function handleOpenModalDelete() {
    setOpenModalDelete(true); 
  }

  function handleDeleteScopeAll() {
    const itens: any = [];

    setScopeAirFuel('remove_all_scope_items');
    setValue('fuel_private_vehicle', []);
    setAirFuelValues(itens);
    calculateAirFuel(itens);

    setOpenModalDelete(false);
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const setAirFuelValues = (itens: any, ignore_field?: string) => {
    let scopeAir = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`scopes.${i}.source`, item.source)
        setValue(`scopes.${i}.description`, item.description)

        setValue(`scopes.${i}.vehicle_fuel_id`, item.vehicle_fuel_id)
        setValue(`scopes.${i}.vehicle_fuel_name`, item.vehicle_fuel_name)
        setValue(`scopes.${i}.vehicle_fuel_description`, item.vehicle_fuel_description)

        setValue(`scopes.${i}.fuel_id`, item.fuel_id)
        setValue(`scopes.${i}.fuel_name`, item.fuel_name)

        setValue(`scopes.${i}.bio_fuel_id`, item.bio_fuel_id)
        setValue(`scopes.${i}.bio_fuel_name`, item.bio_fuel_name)

        if ('emission_co2' !== ignore_field) {
          setValue(`scopes.${i}.emission_co2`, formatPrice5(item.emission_co2, false))
        }

        if ('emission_ch4' !== ignore_field) {
          setValue(`scopes.${i}.emission_ch4`, formatPrice5(item.emission_ch4, false))
        }

        if ('emission_n2o' !== ignore_field) {
          setValue(`scopes.${i}.emission_n2o`, formatPrice5(item.emission_n2o, false))
        }

        if ('factor_co2_kg' !== ignore_field) {
          setValue(`scopes.${i}.factor_co2_kg`, formatPrice5(item.factor_co2_kg, false))
        }

        if ('factor_ch4_kg' !== ignore_field) {
          setValue(`scopes.${i}.factor_ch4_kg`, formatPrice5(item.factor_ch4_kg, false))
        }

        if ('factor_n2o_kg' !== ignore_field) {
          setValue(`scopes.${i}.factor_n2o_kg`, formatPrice5(item.factor_n2o_kg, false))
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`scopes.${i}.emission_tco2e`, formatPrice5(item.emission_tco2e, false))
        }

        if ('factor_co2_kg_bio' !== ignore_field) {
          setValue(`scopes.${i}.factor_co2_kg_bio`, formatPrice5(item.factor_co2_kg_bio, false))
        }

        if ('factor_ch4_kg_bio' !== ignore_field) {
          setValue(`scopes.${i}.factor_ch4_kg_bio`, formatPrice5(item.factor_ch4_kg_bio, false))
        }

        if ('factor_n2o_kg_bio' !== ignore_field) {
          setValue(`scopes.${i}.factor_n2o_kg_bio`, formatPrice5(item.factor_n2o_kg_bio, false))
        }

        if ('emission_tco2e_bio' !== ignore_field) {
          setValue(`scopes.${i}.emission_tco2e_bio`, formatPrice5(item.emission_tco2e_bio, false))
        }

        if ('consumption_yearly' !== ignore_field) {
          setValue(`scopes.${i}.consumption_yearly`, formatPrice5(item.consumption_yearly, false))
        }

        scopeAir = scopeAir + '_' + item.vehicle_fuel_id + '_' + item.consumption_yearly
      }
    }

    setAirFuel(itens)
    setScopeAirFuel(scopeAir)
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d)(\d{5})$/, '$1,$2')
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')
    return value
  }

  const handleChangeCarFleetFuel = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await vehicleFuelService.getById(id)

      if (result) {
        const item = {
          vehicle_fuel_id: result.id,
          vehicle_fuel_name: result.name,
          vehicle_fuel_year: result.year,
          fuel_id: result.fuel ? result.fuel.id : undefined,
          fuel_name: result.fuel ? result.fuel.name : undefined,
          bio_fuel_id: result.bioFuel ? result.bioFuel.id : undefined,
          bio_fuel_name: result.bioFuel ? result.bioFuel.name : undefined,
          only_view_yearly: false
        }

        const index = scopes.length

        const itens = scopes.concat(item)

        setScopeAirFuel('new_scope_item_' + index)

        setAirFuelValues(itens)
        calculateAirFuel(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('fuel_private_vehicle', [])
  }

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const fuels = await vehicleFuelService.getByParams({
      limit: 9000,
      page: 1,
      type: 'air'
    })

    setFuels(
      fuels?.itens.map((fuel: any) => {
        return {
          label: fuel.name,
          value: fuel.id,
          year_id: fuel.year_id
        }
      })
    )

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope1Service.getMobileCombustionAir(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      1,
      'combustao_movel',
      'transporte_aereo'
    )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)

      setIgnoreFill(entity.ignore_fill)

      setScopeSelected(entity)

      for (const item of entity?.fuels) {
        item.only_view_yearly =
          item.consumption_jan > 0 ||
          item.consumption_feb > 0 ||
          item.consumption_mar > 0 ||
          item.consumption_apr > 0 ||
          item.consumption_may > 0 ||
          item.consumption_jun > 0 ||
          item.consumption_jul > 0 ||
          item.consumption_aug > 0 ||
          item.consumption_sep > 0 ||
          item.consumption_oct > 0 ||
          item.consumption_nov > 0 ||
          item.consumption_dec > 0
      }

      setAirFuelValues(entity?.fuels)
      calculateAirFuel(entity?.fuels)

      setScopeAirFuel('load_scope_' + entity.id + '_scopes_' + entity?.fuels.length)
    } else {
      setScopeSelected({})

      setIgnoreFill(false)

      setAirFuelValues([])
      calculateAirFuel([])

      setScopeAirFuel('load_scope_0_scopes_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  const calculateAirFuel = (itens: any) => {
    let emissionCo2Air = 0
    let emissionCh4Air = 0
    let emissionN2oAir = 0
    let emissionTco2eAir = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2Air = emissionCo2Air + item.emission_co2
        }
        if (item.emission_ch4) {
          emissionCh4Air = emissionCh4Air + item.emission_ch4
        }
        if (item.emission_n2o) {
          emissionN2oAir = emissionN2oAir + item.emission_co2
        }
        if (item.emission_tco2e) {
          emissionTco2eAir = emissionTco2eAir + item.emission_tco2e
        }
      }
    }

    setEmissionCo2AirFuel(emissionCo2Air)
    setEmissionCh4AirFuel(emissionCh4Air)
    setEmissionN2oAirFuel(emissionN2oAir)
    setEmissionTco2eAirFuel(emissionTco2eAir)

    setScopeAirFuel(
      'calculate_iten_' +
        emissionCo2Air +
        '_' +
        emissionCh4Air +
        '_' +
        emissionN2oAir +
        '_' +
        emissionTco2eAir
    )
  }

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {

      if (openModalDelete) {
        setLoadingModal(false);
        return;
      }

      const scopesItens: any[] = scopes.map((item: any, index: number) => {
        return {
          source: item.source,
          description: item.description,
          vehicle_fuel_id: item.vehicle_fuel_id,
          emission_ch4: item.emission_ch4 ? parseFloat(item.emission_ch4) : 0,
          emission_n2o: item.emission_n2o ? parseFloat(item.emission_n2o) : 0,
          emission_co2: item.emission_co2 ? parseFloat(item.emission_co2) : 0,
          factor_ch4_kg: item.factor_ch4_kg ? parseFloat(item.factor_ch4_kg) : 0,
          factor_co2_kg: item.factor_co2_kg ? parseFloat(item.factor_co2_kg) : 0,
          factor_n2o_kg: item.factor_n2o_kg ? parseFloat(item.factor_n2o_kg) : 0,
          emission_tco2e: item.emission_tco2e ? parseFloat(item.emission_tco2e) : 0,
          factor_ch4_kg_bio: item.factor_ch4_kg_bio ? parseFloat(item.factor_ch4_kg_bio) : 0,
          factor_co2_kg_bio: item.factor_co2_kg_bio ? parseFloat(item.factor_co2_kg_bio) : 0,
          factor_n2o_kg_bio: item.factor_n2o_kg_bio ? parseFloat(item.factor_n2o_kg_bio) : 0,
          consumption_yearly: item.consumption_yearly ? parseFloat(item.consumption_yearly) : 0
        }
      })

      const scope = {
        status: 2,
        fuels: scopesItens,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        reason: formData.ignore_fill ? formData.reason : ''
      }

      const result = await scope1Service.saveMobileCombustionAir(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('MobileCombustionAir', JSON.stringify(scope)); 
        
        setErrorApi(undefined)
          
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'combustao_movel',
          'transporte_aereo'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const downloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const changeAttachmentHandler = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 1,
        name: 'combustao_movel',
        file: base64,
        filename: filename,
        second_name: 'transporte_aereo'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'combustao_movel',
          'transporte_aereo'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 01 - ' + title}  />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}

            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill && (
          <div className="column" style={{ margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 230px)',
            maxHeight: 'calc(100vh - 230px)'
          }}
        >
          {!ignoreFill && (
            <div
              className="column"
              style={{
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                className="row"
                style={{
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div className="column" style={{ marginRight: 30 }}>
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 14,
                      marginTop: 25,
                      fontWeight: 'bold'
                    }}
                  >
                    Cálculo de emissões de gases de efeito estufa por transporte de carga
                    por consumo de combustível no ano
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5 }} >
                    Selecione, o combustível ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && selectedYear && filteredFuel.length > 0 && (
                  <Controller
                    control={control}
                    name="fuel_private_vehicle"
                    render={({ field }) => (
                      <Select placeholder="[Selecione]"
                        {...field}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            width: '350px',
                            height: 40,
                            marginTop: 15,
                            marginRight: 10,
                            backgroundColor: 'rgb(246, 246, 246)'
                          }),
                          option: (styles) => {
                            return { ...styles, color: '#31363F' }
                          }
                        }}
                        defaultValue={getValues('fuel_private_vehicle')}
                        options={filteredFuel}
                        onChange={(selectedOption) => {
                        field.onChange(selectedOption); 
                        handleChangeCarFleetFuel(selectedOption); 
                      }}
                      />
                    )}
                  />
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 328px)',
                  maxWidth: 'calc(100vw - 328px)'
                }}
              >
                {scopeAirFuel && scopes && scopes.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={2}>
                          {'Frota'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Tipo de combustível'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Consumo anual'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Unidades'}
                        </th>
                        <th className="head-tr-item" style={{ width: 250 }} colSpan={2}>
                          {'O combustível utilizado é formado por'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={3}>
                          {'Fatores de Emissão do combustível fóssil'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CO₂ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CH₄ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de N₂o (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões totais em CO₂e (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 50 }} rowSpan={2} >
                          {!isCommonUser && (
                            <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                              <div onClick={handleOpenModalDelete}>
                                <button style={{background: '#b81414', color: 'white', borderRadius: 5, cursor:'pointer'}}>Apagar tudo</button>
                              </div>
                            </td>
                          )}
                        </th>
                      </tr>
                      <tr>
                        <th className="head-tr-item" style={{ width: 150 }} >
                          {'Registro'}
                        </th>
                        <th className="head-tr-item" style={{ width: 150 }} >
                          {'Descrição'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 120 }}>
                          {'Combustível fóssil'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 240 }}>
                          {'Biocombustível'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CO₂ / litro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CH₄ / litro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg N₂O / litro'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {scopes.map((fuel: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 150 }}
                                  className="input-item"
                                  {...register(`scopes.${index}.source`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`scopes.${index}.source`, event.target.value)
                                    scopes[index].source = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 150 }}
                                  className="input-item"
                                  {...register(`scopes.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `scopes.${index}.description`,
                                      event.target.value
                                    )
                                    scopes[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <div
                                    style={{ display: 'flex', flexDirection: 'column' }}
                                  >
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        width: 200,
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        display: 'inline-block'
                                      }}
                                    >
                                      {scopes[index].vehicle_fuel_name}
                                    </span>
                                  </div>

                                  {scopes[index].vehicle_fuel_description && (
                                    <div className="tooltip">
                                      <img src={InfoButtonMin} alt="information"/>
                                      <span className="tooltiptext">
                                        {scopes[index].vehicle_fuel_description}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.consumption_yearly`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].consumption_yearly = value

                                    setValue(
                                      `scopes.${index}.consumption_yearly`,
                                      formatValue
                                    )

                                    handleChangeFuel(index, 'consumption_yearly')
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {'litros'}
                                </span>
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {scopes[index].fuel_name}
                                </span>
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {scopes[index].bio_fuel_name}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].factor_co2_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].factor_ch4_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].factor_n2o_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].emission_co2, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].emission_ch4, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].emission_n2o, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].emission_tco2e, false)}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }} >
                                  <div
                                    onClick={() => handleDeleteFuel(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img alt="deleteIcon" src={TrashIcon} />
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={10}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionCo2AirFuel, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionCh4AirFuel, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionN2oAirFuel, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionTco2eAirFuel, false)}
                          </span>
                        </td>
                        <td className="head-tr-item" />
                      </tr>
                    </tfoot>
                  </table>
                )}

                {!scopes.length && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum combustível cadastrado`
                        : `Nenhum combustível foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}

                <div
                  className="row"
                  style={{
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.scopes && (
                    <div
                      className="row"
                      style={{
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Registro da Frota e Descrição da Frota são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {!ignoreFill && (
            <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                          width: "calc(100vw - 330px)", maxWidth: "calc(100vw - 330px)",
                          borderColor: "#9E9E9E", height: "40px", display: "flex", 
                          flexDirection: "row", marginLeft: 15}} >
              <span style={{ margin: 10, width: "70%" }}>
                Emissões totais em CO₂ equivalente (toneladas métricas):
              </span>
              <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                {formatPrice3(emissionTco2eAirFuel, false)}
              </span>
            </div>
          )}

          {!ignoreFill && (
            <div className="column" style={{ margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                className="column"
                style={{
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div className="column" style={{ margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={downloadFile}
                  onChangeAttachment={changeAttachmentHandler}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
      
      {openModalDelete && (
        <ModalDeleteDataTable
          isOpen={openModalDelete}
          handleModalVisible={() => setOpenModalDelete(false)}
          handleOk={handleDeleteScopeAll}
        />
      )}
    </Container>
  )
}
