import Parser from 'html-react-parser'
import Select from 'react-select'
import { useLocation, useHistory } from 'react-router-dom'
import React, { useEffect, useState, ChangeEvent } from 'react'
import useAuth from '../../../../../hooks/useAuth'
import inventoryService from '../../../../../services/inventoryService'
import { Container } from './styles'
import { Button } from '../../../../../components/Button/styles'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import TrashIcon from '../../../../../assets/icons/trash.svg'
import { formatPrice3, formatPrice5, toNumberValues } from '../../../../../utils/transform'
import scope3Service from '../../../../../services/scope3Service'
import carFleetService from '../../../../../services/carFleetService'
import vehicleFuelService from '../../../../../services/vehicleFuelService'
import scopeService from '../../../../../services/scopeService'
import { Modal } from '../../../../../components/Modal'
import { ModalImportData } from '../../../../../components/ModalImportData'
import { GoBack } from '../../../../../components/GoBack'
import { TitleText } from '../../../../../components/TitleText'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'
import { Uninformed } from '../../../../../components/Uninformed'
import utilsService from '../../../../../services/utilsService'
import { Loading } from '../../../../../components/Loading'
import { toast } from 'react-hot-toast'
import { ModalDeleteDataTable } from '../../../../../components/ModalDeleteDataTable'
import HeaderSelect from '../../../../../components/ContentPages/HeaderSelect';
import ModalHandleImportData from '../../../../../components/ModalHandleImportData';
import TableContainerWrap from '../../../../../components/ContentPages/TableContainerWrap';

export const PrivateVehicles = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([])
  const [openModal, setOpenModal] = useState(false)
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const [guidelines, setGuidelines] = useState<string | undefined>()
  const location = useLocation()
  const [openModalImport1, setOpenModalImport1] = useState(false)
  const [openModalImport2, setOpenModalImport2] = useState(false)
  const [openModalImport3, setOpenModalImport3] = useState(false)
  const [fuels, setFuels] = useState<any>([])
  const [vehicles, setVehicles] = useState<any>([])
  const [carFleets, setCarFleets] = useState<any>([])
  const [vehicleFuels, setVehicleFuels] = useState<any>([])
  const [importErrors, setImportErrors] = useState<any>([])
  const [openModalError, setOpenModalError] = useState(false)
  const [vehicleDistances, setVehicleDistances] = useState<any>([])
  const [emissionCo2Vehicles, setEmissionCo2Vehicles] = useState<any>()
  const [emissionCh4Vehicles, setEmissionCh4Vehicles] = useState<any>()
  const [emissionN2oVehicles, setEmissionN2oVehicles] = useState<any>()
  const [scopeVehicle, setScopeVehicle] = useState<string | undefined>()
  const [emissionTco2eVehicles, setEmissionTco2eVehicles] = useState<any>()
  const [emissionCo2VehicleFuels, setEmissionCo2VehicleFuels] = useState<any>()
  const [emissionCh4VehicleFuels, setEmissionCh4VehicleFuels] = useState<any>()
  const [emissionN2oVehicleFuels, setEmissionN2oVehicleFuels] = useState<any>()
  const [scopeVehicleFuel, setScopeVehicleFuel] = useState<string | undefined>()
  const [emissionTco2eBioVehicles, setEmissionTco2eBioVehicles] = useState<any>()
  const [emissionTco2eVehicleFuels, setEmissionTco2eVehicleFuels] = useState<any>()
  const [emissionCo2VehicleDistances, setEmissionCo2VehicleDistances] = useState<any>()
  const [emissionCh4VehicleDistances, setEmissionCh4VehicleDistances] = useState<any>()
  const [emissionN2oVehicleDistances, setEmissionN2oVehicleDistances] = useState<any>()
  const [scopeVehicleDistance, setScopeVehicleDistance] = useState<string | undefined>()
  const [emissionTco2eBioVehicleFuels, setEmissionTco2eBioVehicleFuels] = useState<any>()
  const [emissionTco2eVehicleDistances, setEmissionTco2eVehicleDistances] = useState<any>()
  const [emissionTco2eBioVehicleDistances, setEmissionTco2eBioVehicleDistances] = useState<any>()
  const [emissionCo2VehicleDistancesManual, setEmissionCo2VehicleDistancesManual] = useState<any>()
  const [emissionCh4VehicleDistancesManual, setEmissionCh4VehicleDistancesManual] = useState<any>()
  const [emissionN2oVehicleDistancesManual, setEmissionN2oVehicleDistancesManual] = useState<any>()
  const [emissionTco2eVehicleDistancesManual, setEmissionTco2eVehicleDistancesManual] = useState<any>()
  const [emissionTco2eBioVehicleDistancesManual, setEmissionTco2eBioVehicleDistancesManual] = useState<any>()
  const [vehicleDistancesManual, setVehicleDistancesManual] = useState<any>([])
  const [scopeVehicleDistanceManual, setScopeVehicleDistanceManual] = useState<string | undefined>()
  const [openModalImport4, setOpenModalImport4] = useState(false)
  const [selectedYear, setSelectedYear] = useState<any>();

  const [openModalDeleteVehicle, setOpenModalDeleteVehicle] = useState(false);
  const [openModalDeleteVehicleFuel, setOpenModalDeleteVehicleFuel] = useState(false);
  const [openModalDeleteDistances, setOpenModalDeleteDistances] = useState(false);
  const [openModalDeleteDistancesManual, setOpenModalDeleteDistancesManual] = useState(false);

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-3'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

   const getYearId = (year: number) : number | null => {
    const yearMap: {[key: number]: number} ={
      2024: 2024,
      2023: 2023,
      2022: 2022,
      2021: 2021,
      2020: 2020,
      2019: 2019,
    }
    return yearMap[year] || null;
  }

  const filteredFleet = carFleets?.filter((fuel: any) => fuel.year_id === getYearId(selectedYear)) || [];

  const filteredBioFleet = fuels?.filter((bio_fuel: any) => bio_fuel.year_id === getYearId(selectedYear)) || [];

  useEffect(() => {
    if (inventorySelected.year) {
      setSelectedYear(inventorySelected.year);
    }
  }, [inventorySelected.year])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(3, 'deslocamento_casa_trabalho', 'veiculos_particulares')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors }
  } = useForm<any>()

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {

      if(openModalDeleteVehicle){
        setLoadingModal(false);
        return;
      }

      if(openModalDeleteVehicleFuel){
        setLoadingModal(false);
        return;
      }

      if(openModalDeleteDistances){
        setLoadingModal(false);
        return;
      }

      if(openModalDeleteDistancesManual){
        setLoadingModal(false);
        return;
      }

      const privVehicles: any[] = vehicles.map((item: any, index: number) => {
        return {
          year: parseInt(item.year),
          description: item.description,
          car_fleet_id: item.car_fleet_id,
          register: item.register.toString(),
          emission_co2: item.emission_co2 ? parseFloat(item.emission_co2) : 0,
          emission_ch4: item.emission_ch4 ? parseFloat(item.emission_ch4 ): 0,
          emission_n2o: item.emission_n2o ? parseFloat(item.emission_n2o ): 0,
          factor_ch4_kg: item.factor_ch4_kg ? parseFloat(item.factor_ch4_kg ): 0,
          factor_co2_kg: item.factor_co2_kg ? parseFloat(item.factor_co2_kg ): 0,
          factor_n2o_kg: item.factor_n2o_kg ? parseFloat(item.factor_n2o_kg ): 0,
          emission_tco2e: item.emission_tco2e ? parseFloat(item.emission_tco2e ): 0,
          fuel_amount_jan: item.fuel_amount_jan ? parseFloat(item.fuel_amount_jan ): 0,
          fuel_amount_feb: item.fuel_amount_feb ? parseFloat(item.fuel_amount_feb ): 0,
          fuel_amount_mar: item.fuel_amount_mar ? parseFloat(item.fuel_amount_mar ): 0,
          fuel_amount_apr: item.fuel_amount_apr ? parseFloat(item.fuel_amount_apr ): 0,
          fuel_amount_may: item.fuel_amount_may ? parseFloat(item.fuel_amount_may ): 0,
          fuel_amount_jun: item.fuel_amount_jun ? parseFloat(item.fuel_amount_jun ): 0,
          fuel_amount_jul: item.fuel_amount_jul ? parseFloat(item.fuel_amount_jul ): 0,
          fuel_amount_aug: item.fuel_amount_aug ? parseFloat(item.fuel_amount_aug ): 0,
          fuel_amount_sep: item.fuel_amount_sep ? parseFloat(item.fuel_amount_sep ): 0,
          fuel_amount_oct: item.fuel_amount_oct ? parseFloat(item.fuel_amount_oct ): 0,
          fuel_amount_nov: item.fuel_amount_nov ? parseFloat(item.fuel_amount_nov ): 0,
          fuel_amount_dec: item.fuel_amount_dec ? parseFloat(item.fuel_amount_dec ): 0,
          worked_days_jan: item.worked_days_jan ? parseFloat(item.worked_days_jan ): 0,
          worked_days_feb: item.worked_days_feb ? parseFloat(item.worked_days_feb ): 0,
          worked_days_mar: item.worked_days_mar ? parseFloat(item.worked_days_mar ): 0,
          worked_days_apr: item.worked_days_apr ? parseFloat(item.worked_days_apr ): 0,
          worked_days_may: item.worked_days_may ? parseFloat(item.worked_days_may ): 0,
          worked_days_jun: item.worked_days_jun ? parseFloat(item.worked_days_jun ): 0,
          worked_days_jul: item.worked_days_jul ? parseFloat(item.worked_days_jul ): 0,
          worked_days_aug: item.worked_days_aug ? parseFloat(item.worked_days_aug ): 0,
          worked_days_sep: item.worked_days_sep ? parseFloat(item.worked_days_sep ): 0,
          worked_days_oct: item.worked_days_oct ? parseFloat(item.worked_days_oct ): 0,
          worked_days_nov: item.worked_days_nov ? parseFloat(item.worked_days_nov ): 0,
          worked_days_dec: item.worked_days_dec ? parseFloat(item.worked_days_dec ): 0,
          factor_co2_kg_bio: item.factor_co2_kg_bio ? parseFloat(item.factor_co2_kg_bio ): 0,
          worked_days_yearly: item.worked_days_yearly ? parseFloat(item.worked_days_yearly ): 0,
          emission_tco2e_bio: item.emission_tco2e_bio ? parseFloat(item.emission_tco2e_bio ): 0,
          fuel_amount_yearly: item.fuel_amount_yearly ? parseFloat(item.fuel_amount_yearly ): 0,
          fuel_amount_bio_jan: item.fuel_amount_bio_jan ? parseFloat(item.fuel_amount_bio_jan ): 0,
          fuel_amount_bio_feb: item.fuel_amount_bio_feb ? parseFloat(item.fuel_amount_bio_feb ): 0,
          fuel_amount_bio_mar: item.fuel_amount_bio_mar ? parseFloat(item.fuel_amount_bio_mar ): 0,
          fuel_amount_bio_apr: item.fuel_amount_bio_apr ? parseFloat(item.fuel_amount_bio_apr ): 0,
          fuel_amount_bio_may: item.fuel_amount_bio_may ? parseFloat(item.fuel_amount_bio_may ): 0,
          fuel_amount_bio_jun: item.fuel_amount_bio_jun ? parseFloat(item.fuel_amount_bio_jun ): 0,
          fuel_amount_bio_jul: item.fuel_amount_bio_jul ? parseFloat(item.fuel_amount_bio_jul ): 0,
          fuel_amount_bio_aug: item.fuel_amount_bio_aug ? parseFloat(item.fuel_amount_bio_aug ): 0,
          fuel_amount_bio_sep: item.fuel_amount_bio_sep ? parseFloat(item.fuel_amount_bio_sep ): 0,
          fuel_amount_bio_oct: item.fuel_amount_bio_oct ? parseFloat(item.fuel_amount_bio_oct ): 0,
          fuel_amount_bio_nov: item.fuel_amount_bio_nov ? parseFloat(item.fuel_amount_bio_nov ): 0,
          fuel_amount_bio_dec: item.fuel_amount_bio_dec ? parseFloat(item.fuel_amount_bio_dec ): 0,
          fuel_amount_bio_yearly: item.fuel_amount_bio_yearly ? parseFloat(item.fuel_amount_bio_yearly ): 0,
          average_consumption_fuel: item.average_consumption_fuel ? parseFloat(item.average_consumption_fuel) : 0
        }
      })

      const privVehicleFuels: any[] = vehicleFuels.map((item: any, index: number) => {
        return {
          year: parseInt(item.year),
          description: item.description,
          register: item.register.toString(),
          vehicle_fuel_id: item.vehicle_fuel_id,
          emission_co2: item.emission_co2 ? item.emission_co2 : '0',
          emission_ch4: item.emission_ch4 ? item.emission_ch4 : '0',
          emission_n2o: item.emission_n2o ? item.emission_n2o : '0',
          factor_ch4_kg: item.factor_ch4_kg ? item.factor_ch4_kg : '0',
          factor_co2_kg: item.factor_co2_kg ? item.factor_co2_kg : '0',
          factor_n2o_kg: item.factor_n2o_kg ? item.factor_n2o_kg : '0',
          emission_tco2e: item.emission_tco2e ? item.emission_tco2e : '0',
          fuel_amount_jan: item.fuel_amount_jan ? item.fuel_amount_jan : '0',
          fuel_amount_feb: item.fuel_amount_feb ? item.fuel_amount_feb : '0',
          fuel_amount_mar: item.fuel_amount_mar ? item.fuel_amount_mar : '0',
          fuel_amount_apr: item.fuel_amount_apr ? item.fuel_amount_apr : '0',
          fuel_amount_may: item.fuel_amount_may ? item.fuel_amount_may : '0',
          fuel_amount_jun: item.fuel_amount_jun ? item.fuel_amount_jun : '0',
          fuel_amount_jul: item.fuel_amount_jul ? item.fuel_amount_jul : '0',
          fuel_amount_aug: item.fuel_amount_aug ? item.fuel_amount_aug : '0',
          fuel_amount_sep: item.fuel_amount_sep ? item.fuel_amount_sep : '0',
          fuel_amount_oct: item.fuel_amount_oct ? item.fuel_amount_oct : '0',
          fuel_amount_nov: item.fuel_amount_nov ? item.fuel_amount_nov : '0',
          fuel_amount_dec: item.fuel_amount_dec ? item.fuel_amount_dec : '0',
          worked_days_jan: item.worked_days_jan ? item.worked_days_jan.toString() : '0',
          worked_days_feb: item.worked_days_feb ? item.worked_days_feb.toString() : '0',
          worked_days_mar: item.worked_days_mar ? item.worked_days_mar.toString() : '0',
          worked_days_apr: item.worked_days_apr ? item.worked_days_apr.toString() : '0',
          worked_days_may: item.worked_days_may ? item.worked_days_may.toString() : '0',
          worked_days_jun: item.worked_days_jun ? item.worked_days_jun.toString() : '0',
          worked_days_jul: item.worked_days_jul ? item.worked_days_jul.toString() : '0',
          worked_days_aug: item.worked_days_aug ? item.worked_days_aug.toString() : '0',
          worked_days_sep: item.worked_days_sep ? item.worked_days_sep.toString() : '0',
          worked_days_oct: item.worked_days_oct ? item.worked_days_oct.toString() : '0',
          worked_days_nov: item.worked_days_nov ? item.worked_days_nov.toString() : '0',
          worked_days_dec: item.worked_days_dec ? item.worked_days_dec.toString() : '0',
          factor_co2_kg_bio: item.factor_co2_kg_bi ? item.factor_co2_kg_bio : '0',
          factor_ch4_kg_bio: item.factor_ch4_kg_bi ? item.factor_ch4_kg_bio : '0',
          factor_n2o_kg_bio: item.factor_n2o_kg_bi ? item.factor_n2o_kg_bio : '0',
          worked_days_yearly: item.worked_days_yearly ? item.worked_days_yearly.toString() : '0',
          emission_tco2e_bio: item.emission_tco2e_bi ? item.emission_tco2e_bio : '0',
          fuel_amount_yearly: item.fuel_amount_yearl ? item.fuel_amount_yearly : '0',
          fuel_amount_bio_jan: item.fuel_amount_bio_ja ? item.fuel_amount_bio_jan : '0',
          fuel_amount_bio_feb: item.fuel_amount_bio_fe ? item.fuel_amount_bio_feb : '0',
          fuel_amount_bio_mar: item.fuel_amount_bio_ma ? item.fuel_amount_bio_mar : '0',
          fuel_amount_bio_apr: item.fuel_amount_bio_ap ? item.fuel_amount_bio_apr : '0',
          fuel_amount_bio_may: item.fuel_amount_bio_ma ? item.fuel_amount_bio_may : '0',
          fuel_amount_bio_jun: item.fuel_amount_bio_ju ? item.fuel_amount_bio_jun : '0',
          fuel_amount_bio_jul: item.fuel_amount_bio_ju ? item.fuel_amount_bio_jul : '0',
          fuel_amount_bio_aug: item.fuel_amount_bio_au ? item.fuel_amount_bio_aug : '0',
          fuel_amount_bio_sep: item.fuel_amount_bio_se ? item.fuel_amount_bio_sep : '0',
          fuel_amount_bio_oct: item.fuel_amount_bio_oc ? item.fuel_amount_bio_oct : '0',
          fuel_amount_bio_nov: item.fuel_amount_bio_no ? item.fuel_amount_bio_nov : '0',
          fuel_amount_bio_dec: item.fuel_amount_bio_de ? item.fuel_amount_bio_dec : '0',
          fuel_amount_bio_yearly: item.fuel_amount_bio_yearl ? item.fuel_amount_bio_yearly : '0',
          average_consumption_fuel: item.average_consumption_fuel ? item.average_consumption_fuel : '0'
        }
      })

      const privDistances: any[] = vehicleDistances.map((item: any, index: number) => {
        
        return {
          year: parseInt(item.year),
          description: item.description,
          car_fleet_id: item.car_fleet_id,
          register: item.register.toString(),
          emission_co2: item.emission_co2 ? item.emission_co2 : '0',
          emission_ch4: item.emission_ch4 ? item.emission_ch4 : '0',
          emission_n2o: item.emission_n2o ? item.emission_n2o : '0',
          factor_ch4_kg: item.factor_ch4_kg ? item.factor_ch4_kg : '0',
          factor_co2_kg: item.factor_co2_kg ? item.factor_co2_kg : '0',
          factor_n2o_kg: item.factor_n2o_kg ? item.factor_n2o_kg : '0',
          emission_tco2e: item.emission_tco2e ? item.emission_tco2e : '0',
          fuel_amount_jan: item.fuel_amount_jan ? item.fuel_amount_jan : '0',
          fuel_amount_feb: item.fuel_amount_feb ? item.fuel_amount_feb : '0',
          fuel_amount_mar: item.fuel_amount_mar ? item.fuel_amount_mar : '0',
          fuel_amount_apr: item.fuel_amount_apr ? item.fuel_amount_apr : '0',
          fuel_amount_may: item.fuel_amount_may ? item.fuel_amount_may : '0',
          fuel_amount_jun: item.fuel_amount_jun ? item.fuel_amount_jun : '0',
          fuel_amount_jul: item.fuel_amount_jul ? item.fuel_amount_jul : '0',
          fuel_amount_aug: item.fuel_amount_aug ? item.fuel_amount_aug : '0',
          fuel_amount_sep: item.fuel_amount_sep ? item.fuel_amount_sep : '0',
          fuel_amount_oct: item.fuel_amount_oct ? item.fuel_amount_oct : '0',
          fuel_amount_nov: item.fuel_amount_nov ? item.fuel_amount_nov : '0',
          fuel_amount_dec: item.fuel_amount_dec ? item.fuel_amount_dec : '0',
          worked_days_jan: item.worked_days_jan ? item.worked_days_jan.toString() : '0',
          worked_days_feb: item.worked_days_feb ? item.worked_days_feb.toString() : '0',
          worked_days_mar: item.worked_days_mar ? item.worked_days_mar.toString() : '0',
          worked_days_apr: item.worked_days_apr ? item.worked_days_apr.toString() : '0',
          worked_days_may: item.worked_days_may ? item.worked_days_may.toString() : '0',
          worked_days_jun: item.worked_days_jun ? item.worked_days_jun.toString() : '0',
          worked_days_jul: item.worked_days_jul ? item.worked_days_jul.toString() : '0',
          worked_days_aug: item.worked_days_aug ? item.worked_days_aug.toString() : '0',
          worked_days_sep: item.worked_days_sep ? item.worked_days_sep.toString() : '0',
          worked_days_oct: item.worked_days_oct ? item.worked_days_oct.toString() : '0',
          worked_days_nov: item.worked_days_nov ? item.worked_days_nov.toString() : '0',
          worked_days_dec: item.worked_days_dec ? item.worked_days_dec.toString() : '0',
          average_distance: item.average_distance ? item.average_distance : '0',
          factor_co2_kg_bio: item.factor_co2_kg_bio ? item.factor_co2_kg_bio: '0',
          worked_days_yearly: item.worked_days_yearly ? item.worked_days_yearly.toString(): '0',
          emission_tco2e_bio: item.emission_tco2e_bio ? item.emission_tco2e_bio : '0',
          fuel_amount_yearly: item.fuel_amount_yearly ? item.fuel_amount_yearly : '0',
          fuel_amount_bio_jan: item.fuel_amount_bio_jan ? item.fuel_amount_bio_jan : '0',
          fuel_amount_bio_feb: item.fuel_amount_bio_feb ? item.fuel_amount_bio_feb : '0',
          fuel_amount_bio_mar: item.fuel_amount_bio_mar ? item.fuel_amount_bio_mar : '0',
          fuel_amount_bio_apr: item.fuel_amount_bio_apr ? item.fuel_amount_bio_apr : '0',
          fuel_amount_bio_may: item.fuel_amount_bio_may ? item.fuel_amount_bio_may : '0',
          fuel_amount_bio_jun: item.fuel_amount_bio_jun ? item.fuel_amount_bio_jun : '0',
          fuel_amount_bio_jul: item.fuel_amount_bio_jul ? item.fuel_amount_bio_jul : '0',
          fuel_amount_bio_aug: item.fuel_amount_bio_aug ? item.fuel_amount_bio_aug : '0',
          fuel_amount_bio_sep: item.fuel_amount_bio_sep ? item.fuel_amount_bio_sep : '0',
          fuel_amount_bio_oct: item.fuel_amount_bio_oct ? item.fuel_amount_bio_oct : '0',
          fuel_amount_bio_nov: item.fuel_amount_bio_nov ? item.fuel_amount_bio_nov : '0',
          fuel_amount_bio_dec: item.fuel_amount_bio_dec ? item.fuel_amount_bio_dec : '0',
          fuel_amount_bio_yearly: item.fuel_amount_bio_yearly ? item.fuel_amount_bio_yearly : '0',
          fuel_amount_fossil_jan: item.fuel_amount_fossil_jan ? item.fuel_amount_fossil_jan : '0',
          fuel_amount_fossil_feb: item.fuel_amount_fossil_feb ? item.fuel_amount_fossil_feb : '0',
          fuel_amount_fossil_mar: item.fuel_amount_fossil_mar ? item.fuel_amount_fossil_mar : '0',
          fuel_amount_fossil_apr: item.fuel_amount_fossil_apr ? item.fuel_amount_fossil_apr : '0',
          fuel_amount_fossil_may: item.fuel_amount_fossil_may ? item.fuel_amount_fossil_may : '0',
          fuel_amount_fossil_jun: item.fuel_amount_fossil_jun ? item.fuel_amount_fossil_jun : '0',
          fuel_amount_fossil_jul: item.fuel_amount_fossil_jul ? item.fuel_amount_fossil_jul : '0',
          fuel_amount_fossil_aug: item.fuel_amount_fossil_aug ? item.fuel_amount_fossil_aug : '0',
          fuel_amount_fossil_sep: item.fuel_amount_fossil_sep ? item.fuel_amount_fossil_sep : '0',
          fuel_amount_fossil_oct: item.fuel_amount_fossil_oct ? item.fuel_amount_fossil_oct : '0',
          fuel_amount_fossil_nov: item.fuel_amount_fossil_nov ? item.fuel_amount_fossil_nov : '0',
          fuel_amount_fossil_dec: item.fuel_amount_fossil_dec ? item.fuel_amount_fossil_dec : '0',
          average_consumption_fuel: item.average_consumption_fuel ? item.average_consumption_fuel : '0',
          fuel_amount_fossil_yearly: item.fuel_amount_fossil_yearly ? item.fuel_amount_fossil_yearly : '0'
        }
      })

      const privDistancesManual: any[] = vehicleDistancesManual.map((item: any, index: number) => {

        return {
          year: parseInt(item.year),
          car_fleet_id: item.car_fleet_id,
          register: item.register.toString(),
          average_distance: item.average_distance ? item.average_distance: '0',
          emission_co2: item.emission_co2 ? item.emission_co2 : '0',
          emission_ch4: item.emission_ch4 ? item.emission_ch4 : '0',
          emission_n2o: item.emission_n2o ? item.emission_n2o : '0',
          factor_ch4_kg: item.factor_ch4_kg ? item.factor_ch4_kg : '0',
          factor_co2_kg: item.factor_co2_kg ? item.factor_co2_kg : '0',
          factor_n2o_kg: item.factor_n2o_kg ? item.factor_n2o_kg : '0',
          emission_tco2e: item.emission_tco2e ? item.emission_tco2e : '0',
          fuel_amount_jan: item.fuel_amount_jan ? item.fuel_amount_jan : '0',
          fuel_amount_feb: item.fuel_amount_feb ? item.fuel_amount_feb : '0',
          fuel_amount_mar: item.fuel_amount_mar ? item.fuel_amount_mar : '0',
          fuel_amount_apr: item.fuel_amount_apr ? item.fuel_amount_apr : '0',
          fuel_amount_may: item.fuel_amount_may ? item.fuel_amount_may : '0',
          fuel_amount_jun: item.fuel_amount_jun ? item.fuel_amount_jun : '0',
          fuel_amount_jul: item.fuel_amount_jul ? item.fuel_amount_jul : '0',
          fuel_amount_aug: item.fuel_amount_aug ? item.fuel_amount_aug : '0',
          fuel_amount_sep: item.fuel_amount_sep ? item.fuel_amount_sep : '0',
          fuel_amount_oct: item.fuel_amount_oct ? item.fuel_amount_oct : '0',
          fuel_amount_nov: item.fuel_amount_nov ? item.fuel_amount_nov : '0',
          fuel_amount_dec: item.fuel_amount_dec ? item.fuel_amount_dec : '0',
          worked_days_jan: item.worked_days_jan ? item.worked_days_jan.toString() : '0',
          worked_days_feb: item.worked_days_feb ? item.worked_days_feb.toString() : '0',
          worked_days_mar: item.worked_days_mar ? item.worked_days_mar.toString() : '0',
          worked_days_apr: item.worked_days_apr ? item.worked_days_apr.toString() : '0',
          worked_days_may: item.worked_days_may ? item.worked_days_may.toString() : '0',
          worked_days_jun: item.worked_days_jun ? item.worked_days_jun.toString() : '0',
          worked_days_jul: item.worked_days_jul ? item.worked_days_jul.toString() : '0',
          worked_days_aug: item.worked_days_aug ? item.worked_days_aug.toString() : '0',
          worked_days_sep: item.worked_days_sep ? item.worked_days_sep.toString() : '0',
          worked_days_oct: item.worked_days_oct ? item.worked_days_oct.toString() : '0',
          worked_days_nov: item.worked_days_nov ? item.worked_days_nov.toString() : '0',
          worked_days_dec: item.worked_days_dec ? item.worked_days_dec.toString() : '0',
          factor_co2_kg_bio: item.factor_co2_kg_bio ? item.factor_co2_kg_bio : '0',
          worked_days_yearly: item.worked_days_yearly ? item.worked_days_yearly.toString() : '0',
          emission_tco2e_bio: item.emission_tco2e_bio ? item.emission_tco2e_bio : '0',
          fuel_amount_yearly: item.fuel_amount_yearly ? item.fuel_amount_yearly : '0',
          fuel_amount_bio_jan: item.fuel_amount_bio_jan ? item.fuel_amount_bio_jan : '0',
          fuel_amount_bio_feb: item.fuel_amount_bio_feb ? item.fuel_amount_bio_feb : '0',
          fuel_amount_bio_mar: item.fuel_amount_bio_mar ? item.fuel_amount_bio_mar : '0',
          fuel_amount_bio_apr: item.fuel_amount_bio_apr ? item.fuel_amount_bio_apr : '0',
          fuel_amount_bio_may: item.fuel_amount_bio_may ? item.fuel_amount_bio_may : '0',
          fuel_amount_bio_jun: item.fuel_amount_bio_jun ? item.fuel_amount_bio_jun : '0',
          fuel_amount_bio_jul: item.fuel_amount_bio_jul ? item.fuel_amount_bio_jul : '0',
          fuel_amount_bio_aug: item.fuel_amount_bio_aug ? item.fuel_amount_bio_aug : '0',
          fuel_amount_bio_sep: item.fuel_amount_bio_sep ? item.fuel_amount_bio_sep : '0',
          fuel_amount_bio_oct: item.fuel_amount_bio_oct ? item.fuel_amount_bio_oct : '0',
          fuel_amount_bio_nov: item.fuel_amount_bio_nov ? item.fuel_amount_bio_nov : '0',
          fuel_amount_bio_dec: item.fuel_amount_bio_dec ? item.fuel_amount_bio_dec : '0',
          fuel_amount_bio_yearly: item.fuel_amount_bio_yearly ? item.fuel_amount_bio_yearly : '0',
          fuel_amount_fossil_jan: item.fuel_amount_fossil_jan ? item.fuel_amount_fossil_jan : '0',
          fuel_amount_fossil_feb: item.fuel_amount_fossil_feb ? item.fuel_amount_fossil_feb : '0',
          fuel_amount_fossil_mar: item.fuel_amount_fossil_mar ? item.fuel_amount_fossil_mar : '0',
          fuel_amount_fossil_apr: item.fuel_amount_fossil_apr ? item.fuel_amount_fossil_apr : '0',
          fuel_amount_fossil_may: item.fuel_amount_fossil_may ? item.fuel_amount_fossil_may : '0',
          fuel_amount_fossil_jun: item.fuel_amount_fossil_jun ? item.fuel_amount_fossil_jun : '0',
          fuel_amount_fossil_jul: item.fuel_amount_fossil_jul ? item.fuel_amount_fossil_jul : '0',
          fuel_amount_fossil_aug: item.fuel_amount_fossil_aug ? item.fuel_amount_fossil_aug : '0',
          fuel_amount_fossil_sep: item.fuel_amount_fossil_sep ? item.fuel_amount_fossil_sep : '0',
          fuel_amount_fossil_oct: item.fuel_amount_fossil_oct ? item.fuel_amount_fossil_oct : '0',
          fuel_amount_fossil_nov: item.fuel_amount_fossil_nov ? item.fuel_amount_fossil_nov : '0',
          fuel_amount_fossil_dec: item.fuel_amount_fossil_dec ? item.fuel_amount_fossil_dec : '0',
          average_consumption_fuel: item.average_consumption_fuel ? item.average_consumption_fuel : '0',
          fuel_amount_fossil_yearly: item.fuel_amount_fossil_yearly ? item.fuel_amount_fossil_yearly : '0'
        }
      })

      const scope = {
        evidence: formData.evidence,
        privateVehicles: privVehicles,
        ignore_fill: formData.ignore_fill,
        privateVehicleFuels: privVehicleFuels,
        privateVehicleDistances: privDistances,
        privateVehicleDistancesManual: privDistancesManual,
        reason: formData.ignore_fill ? formData.reason : ''
      }

      const result = await scope3Service.savePrivateVehicles(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('PrivateVehicles', JSON.stringify(scope));

        setErrorApi(undefined)

        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  const { isCommonUser } = useAuth()

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const carFleets = await carFleetService.getByParams({ limit: 9000, page: 1, type: 1 })

    setCarFleets(
      carFleets?.itens.map((carFleet: any) => {
        return {
          label: carFleet.name,
          value: carFleet.id,
          year_id: carFleet.year_id
        }
      })
    )

    const fuels = await vehicleFuelService.getHomeWorkDisplacement()

    setFuels(
      fuels?.itens.map((fuel: any) => {
        return {
          label: fuel.name,
          value: fuel.id,
          year_id: fuel.year_id
        }
      })
    )

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope3Service.getPrivateVehicles(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      3,
      'deslocamento_casa_trabalho',
      'veiculos_particulares'
    )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)

      setIgnoreFill(entity.ignore_fill)

      setScopeSelected(entity)

      for (const item of entity?.privateVehicles) {
        item.only_view_yearly =
          item.worked_days_jan > 0 ||
          item.worked_days_feb > 0 ||
          item.worked_days_mar > 0 ||
          item.worked_days_apr > 0 ||
          item.worked_days_may > 0 ||
          item.worked_days_jun > 0 ||
          item.worked_days_jul > 0 ||
          item.worked_days_aug > 0 ||
          item.worked_days_sep > 0 ||
          item.worked_days_oct > 0 ||
          item.worked_days_nov > 0 ||
          item.worked_days_dec > 0

        item.only_view_monthly = item.worked_days_yearly > 0
      }

      for (const item of entity?.privateVehicleFuels) {
        item.only_view_yearly =
          item.worked_days_jan > 0 ||
          item.worked_days_feb > 0 ||
          item.worked_days_mar > 0 ||
          item.worked_days_apr > 0 ||
          item.worked_days_may > 0 ||
          item.worked_days_jun > 0 ||
          item.worked_days_jul > 0 ||
          item.worked_days_aug > 0 ||
          item.worked_days_sep > 0 ||
          item.worked_days_oct > 0 ||
          item.worked_days_nov > 0 ||
          item.worked_days_dec > 0

        item.only_view_monthly = item.worked_days_yearly > 0
      }

      for (const item of entity?.privateVehicleDistances) {
        item.only_view_yearly =
          item.worked_days_jan > 0 ||
          item.worked_days_feb > 0 ||
          item.worked_days_mar > 0 ||
          item.worked_days_apr > 0 ||
          item.worked_days_may > 0 ||
          item.worked_days_jun > 0 ||
          item.worked_days_jul > 0 ||
          item.worked_days_aug > 0 ||
          item.worked_days_sep > 0 ||
          item.worked_days_oct > 0 ||
          item.worked_days_nov > 0 ||
          item.worked_days_dec > 0

        item.only_view_monthly = item.worked_days_yearly > 0
      }

      for (const item of entity?.privateVehicleDistancesManual) {
        item.only_view_yearly =
          item.worked_days_jan > 0 ||
          item.worked_days_feb > 0 ||
          item.worked_days_mar > 0 ||
          item.worked_days_apr > 0 ||
          item.worked_days_may > 0 ||
          item.worked_days_jun > 0 ||
          item.worked_days_jul > 0 ||
          item.worked_days_aug > 0 ||
          item.worked_days_sep > 0 ||
          item.worked_days_oct > 0 ||
          item.worked_days_nov > 0 ||
          item.worked_days_dec > 0

        item.only_view_monthly = item.worked_days_yearly > 0
      }

      setVehiclesValues(entity?.privateVehicles)
      calculateVehicles(entity?.privateVehicles)

      setVehicleFuelsValues(entity?.privateVehicleFuels)
      calculateVehicleFuels(entity?.privateVehicleFuels)

      setVehicleDistancesValues(entity?.privateVehicleDistances)
      calculateVehicleDistances(entity?.privateVehicleDistances)

      setVehicleDistancesValuesManual(entity?.privateVehicleDistancesManual)
      calculateVehicleDistancesManual(entity?.privateVehicleDistancesManual)

      setScopeVehicle(
        'load_scope_' + entity.id + '_vehicles_' + entity?.privateVehicles.length
      )
      setScopeVehicleFuel(
        'load_scope_' + entity.id + '_vehicles_' + entity?.privateVehicleFuels.length
      )
      setScopeVehicleDistance(
        'load_scope_' + entity.id + '_vehicles_' + entity?.privateVehicleDistances.length
      )
      setScopeVehicleDistanceManual(
        'load_scope_' + entity.id + '_vehicles_' + entity?.privateVehicleDistancesManual.length
      )
    } else {
      setScopeSelected({})

      setIgnoreFill(false)

      setVehiclesValues([])
      calculateVehicles([])

      setVehicleFuelsValues([])
      calculateVehicleFuels([])

      setVehicleDistancesValues([])
      calculateVehicleDistances([])

      setVehicleDistancesValuesManual([])
      calculateVehicleDistancesManual([])

      setScopeVehicle('load_scope_0_vehicles_0')
      setScopeVehicleFuel('load_scope_0_vehicles_0')
      setScopeVehicleDistance('load_scope_0_vehicles_0')
      setScopeVehicleDistanceManual('load_scope_0_vehicles_0')
    }

    setInventorySelected(inventory)

    setLoadingModal(false)
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{5})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos

    // Verifica se o valor é igual a zero após a formatação e remove os zeros à esquerda
    if (value === '') {
        value = '0';
    }

    return value;
  }

  const handleChangeCarFleet = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await carFleetService.getById(id)

      if (result) {
        const item = {
          car_fleet_id: result.id,
          car_fleet_name: result.name,
          car_fleet_year_id: result.year,
          fuel_id: result.fuel ? result.fuel.id : undefined,
          fuel_name: result.fuel ? result.fuel.name : undefined,
          bio_fuel_id: result.bioFuel ? result.bioFuel.id : undefined,
          bio_fuel_name: result.bioFuel ? result.bioFuel.name : undefined,
          only_view_monthly: false,
          only_view_yearly: false
        }

        const index = vehicles.length

        const itens = vehicles.concat(item)

        setScopeVehicle('new_scope_item_' + index)

        setVehiclesValues(itens)
        calculateVehicles(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('car_freet_private_vehicle', [])
  }

  const handleChangeCarFleetDistance = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await carFleetService.getById(id)

      if (result) {
        const item = {
          car_fleet_id: result.id,
          car_fleet_name: result.name,
          car_fleet_year_id: result.year,
          fuel_id: result.fuel ? result.fuel.id : undefined,
          fuel_name: result.fuel ? result.fuel.name : undefined,
          bio_fuel_id: result.bioFuel ? result.bioFuel.id : undefined,
          bio_fuel_name: result.bioFuel ? result.bioFuel.name : undefined,
          only_view_monthly: false,
          only_view_yearly: false
        }

        const index = vehicleDistances.length

        const itens = vehicleDistances.concat(item)

        setScopeVehicleDistance('new_scope_item_' + index)

        setVehicleDistancesValues(itens)
        calculateVehicleDistances(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('car_freet_private_vehicle_distance', [])
  }

  const handleChangeCarFleetDistanceManual = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await carFleetService.getById(id)

      if (result) {
        const item = {
          car_fleet_id: result.id,
          car_fleet_name: result.name,
          car_fleet_year_id: result.year,
          fuel_id: result.fuel ? result.fuel.id : undefined,
          fuel_name: result.fuel ? result.fuel.name : undefined,
          bio_fuel_id: result.bioFuel ? result.bioFuel.id : undefined,
          bio_fuel_name: result.bioFuel ? result.bioFuel.name : undefined,
          only_view_monthly: false,
          only_view_yearly: false
        }

        const index = vehicleDistancesManual.length

        const itens = vehicleDistancesManual.concat(item)

        setScopeVehicleDistanceManual('new_scope_item_' + index)

        setVehicleDistancesValuesManual(itens)
        calculateVehicleDistancesManual(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('car_freet_private_vehicle_distance_manual', [])
  }

  const handleChangeFuel = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await vehicleFuelService.getById(id)

      if (result) {
        const item = {
          vehicle_fuel_id: result.id,
          vehicle_fuel_name: result.name,
          vehicle_fuel_year_id: result.year,
          fuel_id: result.fuel ? result.fuel.id : undefined,
          fuel_name: result.fuel ? result.fuel.name : undefined,
          bio_fuel_id: result.bioFuel ? result.bioFuel.id : undefined,
          bio_fuel_name: result.bioFuel ? result.bioFuel.name : undefined,
          only_view_monthly: false,
          only_view_yearly: false
        }

        const index = vehicleFuels.length

        const itens = vehicleFuels.concat(item)

        setScopeVehicleFuel('new_scope_item_' + index)

        setVehicleFuelsValues(itens)
        calculateVehicleFuels(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('fuel_private_vehicle', [])
  }

  const setVehiclesValues = (itens: any, ignore_field?: string) => {
    let scopeVehicle = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`vehicles.${i}.year`, item.year)
        setValue(`vehicles.${i}.register`, item.register)
        setValue(`vehicles.${i}.description`, item.description)
        
        setValue(`vehicles.${i}.car_fleet_id`, item.car_fleet_id)
        setValue(`vehicles.${i}.car_fleet_name`, item.car_fleet_name)

        setValue(`vehicles.${i}.fuel_id`, item.fuel_id)
        setValue(`vehicles.${i}.fuel_name`, item.fuel_name)

        setValue(`vehicles.${i}.bio_fuel_id`, item.bio_fuel_id)
        setValue(`vehicles.${i}.bio_fuel_name`, item.bio_fuel_name)

        if ('average_consumption_fuel' !== ignore_field){
          setValue(`vehicles.${i}.average_consumption_fuel`, formatPrice5(item.average_consumption_fuel, false))
        }
        if ('emission_co2' !== ignore_field) {
          setValue(`vehicles.${i}.emission_co2`, formatPrice5(item.emission_co2, false))
        }

        if ('emission_ch4' !== ignore_field) {
          setValue(`vehicles.${i}.emission_ch4`, formatPrice5(item.emission_ch4, false))
        }

        if ('emission_n2o' !== ignore_field) {
          setValue(`vehicles.${i}.emission_n2o`, formatPrice5(item.emission_n2o, false))
        }

        if ('factor_ch4_kg' !== ignore_field) {
          setValue(`vehicles.${i}.factor_ch4_kg`, formatPrice5(item.factor_ch4_kg, false))
        }

        if ('factor_co2_kg' !== ignore_field) {
          setValue(`vehicles.${i}.factor_co2_kg`, formatPrice5(item.factor_co2_kg, false))
        }

        if ('factor_n2o_kg' !== ignore_field) {
          setValue(`vehicles.${i}.factor_n2o_kg`, formatPrice5(item.factor_n2o_kg, false))
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`vehicles.${i}.emission_tco2e`, formatPrice5(item.emission_tco2e, false))
        }

        if ('worked_days_jan' !== ignore_field) {
          setValue(`vehicles.${i}.worked_days_jan`, item.worked_days_jan)
        }

        if ('worked_days_feb' !== ignore_field) {
          setValue(`vehicles.${i}.worked_days_feb`, item.worked_days_feb)
        }

        if ('worked_days_mar' !== ignore_field) {
          setValue(`vehicles.${i}.worked_days_mar`, item.worked_days_mar)
        }

        if ('worked_days_apr' !== ignore_field) {
          setValue(`vehicles.${i}.worked_days_apr`, item.worked_days_apr)
        }

        if ('worked_days_may' !== ignore_field) {
          setValue(`vehicles.${i}.worked_days_may`, item.worked_days_may)
        }

        if ('worked_days_jun' !== ignore_field) {
          setValue(`vehicles.${i}.worked_days_jun`, item.worked_days_jun)
        }

        if ('worked_days_jul' !== ignore_field) {
          setValue(`vehicles.${i}.worked_days_jul`, item.worked_days_jul)
        }

        if ('worked_days_aug' !== ignore_field) {
          setValue(`vehicles.${i}.worked_days_aug`, item.worked_days_aug)
        }

        if ('worked_days_sep' !== ignore_field) {
          setValue(`vehicles.${i}.worked_days_sep`, item.worked_days_sep)
        }

        if ('worked_days_oct' !== ignore_field) {
          setValue(`vehicles.${i}.worked_days_oct`, item.worked_days_oct)
        }

        if ('worked_days_nov' !== ignore_field) {
          setValue(`vehicles.${i}.worked_days_nov`, item.worked_days_nov)
        }

        if ('worked_days_dec' !== ignore_field) {
          setValue(`vehicles.${i}.worked_days_dec`, item.worked_days_dec)
        }

        if ('fuel_amount_jan' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_jan`, formatPrice5(item.fuel_amount_jan, false))
        }

        if ('fuel_amount_feb' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_feb`, formatPrice5(item.fuel_amount_feb, false))
        }

        if ('fuel_amount_mar' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_mar`, formatPrice5(item.fuel_amount_mar, false))
        }

        if ('fuel_amount_apr' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_apr`, formatPrice5(item.fuel_amount_apr, false))
        }

        if ('fuel_amount_may' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_may`, formatPrice5(item.fuel_amount_may, false))
        }

        if ('fuel_amount_jun' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_jun`, formatPrice5(item.fuel_amount_jun, false))
        }

        if ('fuel_amount_jul' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_jul`, formatPrice5(item.fuel_amount_jul, false))
        }

        if ('fuel_amount_aug' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_aug`, formatPrice5(item.fuel_amount_aug, false))
        }

        if ('fuel_amount_sep' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_sep`, formatPrice5(item.fuel_amount_sep, false))
        }

        if ('fuel_amount_oct' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_oct`, formatPrice5(item.fuel_amount_oct, false))
        }

        if ('fuel_amount_nov' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_nov`, formatPrice5(item.fuel_amount_nov, false))
        }

        if ('fuel_amount_dec' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_dec`, formatPrice5(item.fuel_amount_dec, false))
        }

        if ('factor_co2_kg_bio' !== ignore_field) {
          setValue(`vehicles.${i}.factor_co2_kg_bio`, formatPrice5(item.factor_co2_kg_bio, false))
        }

        if ('emission_tco2e_bio' !== ignore_field) {
          setValue(`vehicles.${i}.emission_tco2e_bio`, formatPrice5(item.emission_tco2e_bio, false))
        }

        if ('fuel_amount_yearly' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_yearly`, formatPrice5(item.fuel_amount_yearly, false))
        }

        if ('fuel_amount_bio_jan' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_bio_jan`, formatPrice5(item.fuel_amount_bio_jan, false))
        }

        if ('fuel_amount_bio_feb' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_bio_feb`, formatPrice5(item.fuel_amount_bio_feb, false))
        }

        if ('fuel_amount_bio_mar' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_bio_mar`, formatPrice5(item.fuel_amount_bio_mar, false))
        }

        if ('fuel_amount_bio_apr' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_bio_apr`, formatPrice5(item.fuel_amount_bio_apr, false))
        }

        if ('fuel_amount_bio_may' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_bio_may`, formatPrice5(item.fuel_amount_bio_may, false))
        }

        if ('fuel_amount_bio_jun' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_bio_jun`, formatPrice5(item.fuel_amount_bio_jun, false))
        }

        if ('fuel_amount_bio_jul' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_bio_jul`, formatPrice5(item.fuel_amount_bio_jul, false))
        }

        if ('fuel_amount_bio_aug' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_bio_aug`, formatPrice5(item.fuel_amount_bio_aug, false))
        }

        if ('fuel_amount_bio_sep' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_bio_sep`, formatPrice5(item.fuel_amount_bio_sep, false))
        }

        if ('fuel_amount_bio_oct' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_bio_oct`, formatPrice5(item.fuel_amount_bio_oct, false))
        }

        if ('fuel_amount_bio_nov' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_bio_nov`, formatPrice5(item.fuel_amount_bio_nov, false))
        }

        if ('fuel_amount_bio_dec' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_bio_dec`, formatPrice5(item.fuel_amount_bio_dec, false))
        }

        if ('fuel_amount_bio_yearly' !== ignore_field) {
          setValue(`vehicles.${i}.fuel_amount_bio_yearly`, formatPrice5(item.fuel_amount_bio_yearly, false))
        }

        if ('worked_days_yearly' !== ignore_field) {
          setValue(`vehicles.${i}.worked_days_yearly`, item.worked_days_yearly)
        }

        scopeVehicle =
          scopeVehicle +
          '_' +
          item.car_fleet_id +
          '_' +
          item.worked_days_jan +
          '_' +
          item.worked_days_feb +
          '_' +
          item.worked_days_mar +
          '_' +
          item.worked_days_apr +
          '_' +
          item.worked_days_may +
          '_' +
          item.worked_days_jun +
          '_' +
          item.worked_days_jul +
          '_' +
          item.worked_days_aug +
          '_' +
          item.worked_days_sep +
          '_' +
          item.worked_days_oct +
          '_' +
          item.worked_days_nov +
          '_' +
          item.worked_days_dec +
          '_' +
          item.worked_days_yearly
      }
    }

    setVehicles(itens)
    setScopeVehicle(scopeVehicle)
  }

  const setVehicleDistancesValues = (itens: any, ignore_field?: string) => {
    let scopeVehicle = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`distance.${i}.year`, item.year)
        setValue(`distance.${i}.register`, item.register)
        setValue(`distance.${i}.description`, item.description)
        setValue(`distance.${i}.average_consumption_fuel`, item.average_consumption_fuel)

        setValue(`distance.${i}.car_fleet_id`, item.car_fleet_id)
        setValue(`distance.${i}.car_fleet_name`, item.car_fleet_name)

        setValue(`distance.${i}.fuel_id`, item.fuel_id)
        setValue(`distance.${i}.fuel_name`, item.fuel_name)

        setValue(`distance.${i}.bio_fuel_id`, item.bio_fuel_id)
        setValue(`distance.${i}.bio_fuel_name`, item.bio_fuel_name)

        if ('average_distance' !== ignore_field) {
          setValue(`distance.${i}.average_distance`, formatPrice5(item.average_distance, false))
        }
  
        if ('emission_co2' !== ignore_field) {
          setValue(`distance.${i}.emission_co2`, formatPrice5(item.emission_co2, false))
        }

        if ('emission_ch4' !== ignore_field) {
          setValue(`distance.${i}.emission_ch4`, formatPrice5(item.emission_ch4, false))
        }

        if ('emission_n2o' !== ignore_field) {
          setValue(`distance.${i}.emission_n2o`, formatPrice5(item.emission_n2o, false))
        }

        if ('factor_ch4_kg' !== ignore_field) {
          setValue(`distance.${i}.factor_ch4_kg`, formatPrice5(item.factor_ch4_kg, false))
        }

        if ('factor_co2_kg' !== ignore_field) {
          setValue(`distance.${i}.factor_co2_kg`, formatPrice5(item.factor_co2_kg, false))
        }

        if ('factor_n2o_kg' !== ignore_field) {
          setValue(`distance.${i}.factor_n2o_kg`, formatPrice5(item.factor_n2o_kg, false))
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`distance.${i}.emission_tco2e`, formatPrice5(item.emission_tco2e, false))
        }

        if ('worked_days_jan' !== ignore_field) {
          setValue(`distance.${i}.worked_days_jan`, item.worked_days_jan)
        }

        if ('worked_days_feb' !== ignore_field) {
          setValue(`distance.${i}.worked_days_feb`, item.worked_days_feb)
        }

        if ('worked_days_mar' !== ignore_field) {
          setValue(`distance.${i}.worked_days_mar`, item.worked_days_mar)
        }

        if ('worked_days_apr' !== ignore_field) {
          setValue(`distance.${i}.worked_days_apr`, item.worked_days_apr)
        }

        if ('worked_days_may' !== ignore_field) {
          setValue(`distance.${i}.worked_days_may`, item.worked_days_may)
        }

        if ('worked_days_jun' !== ignore_field) {
          setValue(`distance.${i}.worked_days_jun`, item.worked_days_jun)
        }

        if ('worked_days_jul' !== ignore_field) {
          setValue(`distance.${i}.worked_days_jul`, item.worked_days_jul)
        }

        if ('worked_days_aug' !== ignore_field) {
          setValue(`distance.${i}.worked_days_aug`, item.worked_days_aug)
        }

        if ('worked_days_sep' !== ignore_field) {
          setValue(`distance.${i}.worked_days_sep`, item.worked_days_sep)
        }

        if ('worked_days_oct' !== ignore_field) {
          setValue(`distance.${i}.worked_days_oct`, item.worked_days_oct)
        }

        if ('worked_days_nov' !== ignore_field) {
          setValue(`distance.${i}.worked_days_nov`, item.worked_days_nov)
        }

        if ('worked_days_dec' !== ignore_field) {
          setValue(`distance.${i}.worked_days_dec`, item.worked_days_dec)
        }

        if ('fuel_amount_jan' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_jan`, formatPrice5(item.fuel_amount_jan, false))
        }

        if ('fuel_amount_feb' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_feb`, formatPrice5(item.fuel_amount_feb, false))
        }

        if ('fuel_amount_mar' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_mar`, formatPrice5(item.fuel_amount_mar, false))
        }

        if ('fuel_amount_apr' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_apr`, formatPrice5(item.fuel_amount_apr, false))
        }

        if ('fuel_amount_may' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_may`, formatPrice5(item.fuel_amount_may, false))
        }

        if ('fuel_amount_jun' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_jun`, formatPrice5(item.fuel_amount_jun, false))
        }

        if ('fuel_amount_jul' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_jul`, formatPrice5(item.fuel_amount_jul, false))
        }

        if ('fuel_amount_aug' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_aug`, formatPrice5(item.fuel_amount_aug, false))
        }

        if ('fuel_amount_sep' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_sep`, formatPrice5(item.fuel_amount_sep, false))
        }

        if ('fuel_amount_oct' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_oct`, formatPrice5(item.fuel_amount_oct, false))
        }

        if ('fuel_amount_nov' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_nov`, formatPrice5(item.fuel_amount_nov, false))
        }

        if ('fuel_amount_dec' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_dec`, formatPrice5(item.fuel_amount_dec, false))
        }

        if ('factor_co2_kg_bio' !== ignore_field) {
          setValue(`distance.${i}.factor_co2_kg_bio`, formatPrice5(item.factor_co2_kg_bio, false))
        }

        if ('emission_tco2e_bio' !== ignore_field) {
          setValue(`distance.${i}.emission_tco2e_bio`, item.emission_tco2e_bio)
        }

        if ('fuel_amount_yearly' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_yearly`, formatPrice5(item.fuel_amount_yearly, false))
        }

        if ('fuel_amount_bio_jan' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_jan`, formatPrice5(item.fuel_amount_bio_jan, false))
        }

        if ('fuel_amount_bio_feb' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_feb`, formatPrice5(item.fuel_amount_bio_feb, false))
        }

        if ('fuel_amount_bio_mar' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_mar`, formatPrice5(item.fuel_amount_bio_mar, false))
        }

        if ('fuel_amount_bio_apr' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_apr`, formatPrice5(item.fuel_amount_bio_apr, false))
        }

        if ('fuel_amount_bio_may' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_may`, formatPrice5(item.fuel_amount_bio_may, false))
        }

        if ('fuel_amount_bio_jun' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_jun`, formatPrice5(item.fuel_amount_bio_jun, false))
        }

        if ('fuel_amount_bio_jul' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_jul`, formatPrice5(item.fuel_amount_bio_jul, false))
        }

        if ('fuel_amount_bio_aug' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_aug`, formatPrice5(item.fuel_amount_bio_aug, false))
        }

        if ('fuel_amount_bio_sep' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_sep`, formatPrice5(item.fuel_amount_bio_sep, false))
        }

        if ('fuel_amount_bio_oct' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_oct`, formatPrice5(item.fuel_amount_bio_oct, false))
        }

        if ('fuel_amount_bio_nov' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_nov`, formatPrice5(item.fuel_amount_bio_nov, false))
        }

        if ('fuel_amount_bio_dec' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_dec`, formatPrice5(item.fuel_amount_bio_dec, false))
        }

        if ('fuel_amount_bio_yearly' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_yearly`, formatPrice5(item.fuel_amount_bio_yearly, false))
        }

        if ('fuel_amount_fossil_jan' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_jan`, formatPrice5(item.fuel_amount_fossil_jan, false))
        }

        if ('fuel_amount_fossil_feb' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_feb`, formatPrice5(item.fuel_amount_fossil_feb, false))
        }

        if ('fuel_amount_fossil_mar' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_mar`, formatPrice5(item.fuel_amount_fossil_mar, false))
        }

        if ('fuel_amount_fossil_apr' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_apr`, formatPrice5(item.fuel_amount_fossil_apr, false))
        }

        if ('fuel_amount_fossil_may' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_may`, formatPrice5(item.fuel_amount_fossil_may, false))
        }

        if ('fuel_amount_fossil_jun' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_jun`, formatPrice5(item.fuel_amount_fossil_jun, false))
        }

        if ('fuel_amount_fossil_jul' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_jul`, formatPrice5(item.fuel_amount_fossil_jul, false))
        }

        if ('fuel_amount_fossil_aug' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_aug`, formatPrice5(item.fuel_amount_fossil_aug, false))
        }

        if ('fuel_amount_fossil_sep' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_sep`, formatPrice5(item.fuel_amount_fossil_sep, false))
        }

        if ('fuel_amount_fossil_oct' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_oct`, formatPrice5(item.fuel_amount_fossil_oct, false))
        }

        if ('fuel_amount_fossil_nov' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_nov`, formatPrice5(item.fuel_amount_fossil_nov, false))
        }

        if ('fuel_amount_fossil_dec' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_dec`, formatPrice5(item.fuel_amount_fossil_dec, false))
        }

        if ('fuel_amount_fossil_yearly' !== ignore_field) {
          setValue(
            `distance.${i}.fuel_amount_fossil_yearly`,
            formatPrice5(item.fuel_amount_fossil_yearly, false)
          )
        }

        if ('worked_days_yearly' !== ignore_field) {
          setValue(`distance.${i}.worked_days_yearly`, item.worked_days_yearly)
        }

        scopeVehicle =
          scopeVehicle +
          '_' +
          item.car_fleet_id +
          '_' +
          item.worked_days_jan +
          '_' +
          item.worked_days_feb +
          '_' +
          item.worked_days_mar +
          '_' +
          item.worked_days_apr +
          '_' +
          item.worked_days_may +
          '_' +
          item.worked_days_jun +
          '_' +
          item.worked_days_jul +
          '_' +
          item.worked_days_aug +
          '_' +
          item.worked_days_sep +
          '_' +
          item.worked_days_oct +
          '_' +
          item.worked_days_nov +
          '_' +
          item.worked_days_dec +
          '_' +
          item.worked_days_yearly
      }
    }

    setVehicleDistances(itens)
    setScopeVehicleDistance(scopeVehicle)
  }

  const setVehicleDistancesValuesManual = (itens: any, ignore_field?: string) => {
    let scopeVehicle = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`distance.${i}.year_manual`, item.year)
        setValue(`distance.${i}.register_manual`, item.register)
        setValue(`distance.${i}.average_consumption_fuel_manual`, item.average_consumption_fuel)

        setValue(`distance.${i}.car_fleet_id_manual`, item.car_fleet_id)
        setValue(`distance.${i}.car_fleet_name_manual`, item.car_fleet_name)

        setValue(`distance.${i}.fuel_id_manual`, item.fuel_id)
        setValue(`distance.${i}.fuel_name_manual`, item.fuel_name)

        setValue(`distance.${i}.bio_fuel_id_manual`, item.bio_fuel_id)
        setValue(`distance.${i}.bio_fuel_name_manual`, item.bio_fuel_name)
        
        if ('average_distance_manual' !== ignore_field) {
          setValue(`distance.${i}.average_distance_manual`, formatPrice5(item.average_distance, false))
        }

        if ('emission_co2_manual' !== ignore_field) {
          setValue(`distance.${i}.emission_co2_manual`, formatPrice5(item.emission_co2, false))
        }

        if ('emission_ch4_manual' !== ignore_field) {
          setValue(`distance.${i}.emission_ch4_manual`, formatPrice5(item.emission_ch4, false))
        }

        if ('emission_n2o_manual' !== ignore_field) {
          setValue(`distance.${i}.emission_n2o_manual`, formatPrice5(item.emission_n2o, false))
        }

        if ('factor_ch4_kg_manual' !== ignore_field) {
          setValue(`distance.${i}.factor_ch4_kg_manual`, formatPrice5(item.factor_ch4_kg, false))
        }

        if ('factor_co2_kg_manual' !== ignore_field) {
          setValue(`distance.${i}.factor_co2_kg_manual`, formatPrice5(item.factor_co2_kg, false))
        }

        if ('factor_n2o_kg_manual' !== ignore_field) {
          setValue(`distance.${i}.factor_n2o_kg_manual`, formatPrice5(item.factor_n2o_kg, false))
        }

        if ('emission_tco2e_manual' !== ignore_field) {
          setValue(`distance.${i}.emission_tco2e_manual`, formatPrice5(item.emission_tco2e, false))
        }

        if ('worked_days_jan_manual' !== ignore_field) {
          setValue(`distance.${i}.worked_days_jan_manual`, item.worked_days_jan)
        }

        if ('worked_days_feb_manual' !== ignore_field) {
          setValue(`distance.${i}.worked_days_feb_manual`, item.worked_days_feb)
        }

        if ('worked_days_mar_manual' !== ignore_field) {
          setValue(`distance.${i}.worked_days_mar_manual`, item.worked_days_mar)
        }

        if ('worked_days_apr_manual' !== ignore_field) {
          setValue(`distance.${i}.worked_days_apr_manual`, item.worked_days_apr)
        }

        if ('worked_days_may_manual' !== ignore_field) {
          setValue(`distance.${i}.worked_days_may_manual`, item.worked_days_may)
        }

        if ('worked_days_jun_manual' !== ignore_field) {
          setValue(`distance.${i}.worked_days_jun_manual`, item.worked_days_jun)
        }

        if ('worked_days_jul_manual' !== ignore_field) {
          setValue(`distance.${i}.worked_days_jul_manual`, item.worked_days_jul)
        }

        if ('worked_days_aug_manual' !== ignore_field) {
          setValue(`distance.${i}.worked_days_aug_manual`, item.worked_days_aug)
        }

        if ('worked_days_sep_manual' !== ignore_field) {
          setValue(`distance.${i}.worked_days_sep_manual`, item.worked_days_sep)
        }

        if ('worked_days_oct_manual' !== ignore_field) {
          setValue(`distance.${i}.worked_days_oct_manual`, item.worked_days_oct)
        }

        if ('worked_days_nov_manual' !== ignore_field) {
          setValue(`distance.${i}.worked_days_nov_manual`, item.worked_days_nov)
        }

        if ('worked_days_dec_manual' !== ignore_field) {
          setValue(`distance.${i}.worked_days_dec_manual`, item.worked_days_dec)
        }

        if ('worked_days_yearly_manual' !== ignore_field) {
          setValue(`distance.${i}.worked_days_yearly_manual`, item.worked_days_yearly)
        }

        if ('fuel_amount_jan_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_jan_manual`, formatPrice5(item.fuel_amount_jan, false))
        }

        if ('fuel_amount_feb_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_feb_manual`, formatPrice5(item.fuel_amount_feb, false))
        }

        if ('fuel_amount_mar_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_mar_manual`, formatPrice5(item.fuel_amount_mar, false))
        }

        if ('fuel_amount_apr_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_apr_manual`, formatPrice5(item.fuel_amount_apr, false))
        }

        if ('fuel_amount_may_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_may_manual`, formatPrice5(item.fuel_amount_may, false))
        }

        if ('fuel_amount_jun_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_jun_manual`, formatPrice5(item.fuel_amount_jun, false))
        }

        if ('fuel_amount_jul_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_jul_manual`, formatPrice5(item.fuel_amount_jul, false))
        }

        if ('fuel_amount_aug_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_aug_manual`, formatPrice5(item.fuel_amount_aug, false))
        }

        if ('fuel_amount_sep_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_sep_manual`, formatPrice5(item.fuel_amount_sep, false))
        }

        if ('fuel_amount_oct_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_oct_manual`, formatPrice5(item.fuel_amount_oct, false))
        }

        if ('fuel_amount_nov_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_nov_manual`, formatPrice5(item.fuel_amount_nov, false))
        }

        if ('fuel_amount_dec_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_dec_manual`, formatPrice5(item.fuel_amount_dec, false))
        }

        if ('factor_co2_kg_bio_manual' !== ignore_field) {
          setValue(`distance.${i}.factor_co2_kg_bio_manual`, formatPrice5(item.factor_co2_kg_bio, false))
        }

        if ('emission_tco2e_bio_manual' !== ignore_field) {
          setValue(`distance.${i}.emission_tco2e_bio_manual`, item.emission_tco2e_bio)
        }

        if ('fuel_amount_yearly_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_yearly_manual`, formatPrice5(item.fuel_amount_yearly, false))
        }

        if ('fuel_amount_bio_jan_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_jan_manual`, formatPrice5(item.fuel_amount_bio_jan, false))
        }

        if ('fuel_amount_bio_feb_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_feb_manual`, formatPrice5(item.fuel_amount_bio_feb, false))
        }

        if ('fuel_amount_bio_mar_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_mar_manual`, formatPrice5(item.fuel_amount_bio_mar, false))
        }

        if ('fuel_amount_bio_apr_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_apr_manual`, formatPrice5(item.fuel_amount_bio_apr, false))
        }

        if ('fuel_amount_bio_may_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_may_manual`, formatPrice5(item.fuel_amount_bio_may, false))
        }

        if ('fuel_amount_bio_jun_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_jun_manual`, formatPrice5(item.fuel_amount_bio_jun, false))
        }

        if ('fuel_amount_bio_jul_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_jul_manual`, formatPrice5(item.fuel_amount_bio_jul, false))
        }

        if ('fuel_amount_bio_aug_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_aug_manual`, formatPrice5(item.fuel_amount_bio_aug, false))
        }

        if ('fuel_amount_bio_sep_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_sep_manual`, formatPrice5(item.fuel_amount_bio_sep, false))
        }

        if ('fuel_amount_bio_oct_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_oct_manual`, formatPrice5(item.fuel_amount_bio_oct, false))
        }

        if ('fuel_amount_bio_nov_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_nov_manual`, formatPrice5(item.fuel_amount_bio_nov, false))
        }

        if ('fuel_amount_bio_dec_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_dec_manual`, formatPrice5(item.fuel_amount_bio_dec, false))
        }

        if ('fuel_amount_bio_yearly_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_bio_yearly_manual`, formatPrice5(item.fuel_amount_bio_yearly, false))
        }

        if ('fuel_amount_fossil_jan_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_jan_manual`, formatPrice5(item.fuel_amount_fossil_jan, false))
        }

        if ('fuel_amount_fossil_feb_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_feb_manual`, formatPrice5(item.fuel_amount_fossil_feb, false))
        }

        if ('fuel_amount_fossil_mar_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_mar_manual`, formatPrice5(item.fuel_amount_fossil_mar, false))
        }

        if ('fuel_amount_fossil_apr_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_apr_manual`, formatPrice5(item.fuel_amount_fossil_apr, false))
        }

        if ('fuel_amount_fossil_may_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_may_manual`, formatPrice5(item.fuel_amount_fossil_may, false))
        }

        if ('fuel_amount_fossil_jun_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_jun_manual`, formatPrice5(item.fuel_amount_fossil_jun, false))
        }

        if ('fuel_amount_fossil_jul_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_jul_manual`, formatPrice5(item.fuel_amount_fossil_jul, false))
        }

        if ('fuel_amount_fossil_aug_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_aug_manual`, formatPrice5(item.fuel_amount_fossil_aug, false))
        }

        if ('fuel_amount_fossil_sep_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_sep_manual`, formatPrice5(item.fuel_amount_fossil_sep, false))
        }

        if ('fuel_amount_fossil_oct_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_oct_manual`, formatPrice5(item.fuel_amount_fossil_oct, false))
        }

        if ('fuel_amount_fossil_nov_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_nov_manual`, formatPrice5(item.fuel_amount_fossil_nov, false))
        }

        if ('fuel_amount_fossil_dec_manual' !== ignore_field) {
          setValue(`distance.${i}.fuel_amount_fossil_dec_manual`, formatPrice5(item.fuel_amount_fossil_dec, false))
        }

        if ('fuel_amount_fossil_yearly_manual' !== ignore_field) {
          setValue(
            `distance.${i}.fuel_amount_fossil_yearly_manual`,
            formatPrice5(item.fuel_amount_fossil_yearly, false)
          )
        }

        scopeVehicle =
          scopeVehicle +
          '_' +
          item.car_fleet_id +
          '_' +
          item.worked_days_jan +
          '_' +
          item.worked_days_feb +
          '_' +
          item.worked_days_mar +
          '_' +
          item.worked_days_apr +
          '_' +
          item.worked_days_may +
          '_' +
          item.worked_days_jun +
          '_' +
          item.worked_days_jul +
          '_' +
          item.worked_days_aug +
          '_' +
          item.worked_days_sep +
          '_' +
          item.worked_days_oct +
          '_' +
          item.worked_days_nov +
          '_' +
          item.worked_days_dec +
          '_' +
          item.worked_days_yearly
      }
    }

    setVehicleDistancesManual(itens)
    setScopeVehicleDistanceManual(scopeVehicle)
  }


  const setVehicleFuelsValues = (itens: any, ignore_field?: string) => {
    let scopeVehicle = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`fuel.${i}.year`, item.year)
        setValue(`fuel.${i}.register`, item.register)
        setValue(`fuel.${i}.description`, item.description)
        setValue(`fuel.${i}.average_consumption_fuel`, item.average_consumption_fuel)

        setValue(`fuel.${i}.vehicle_fuel_id`, item.vehicle_fuel_id)
        setValue(`fuel.${i}.vehicle_fuel_name`, item.vehicle_fuel_name)

        setValue(`fuel.${i}.fuel_id`, item.fuel_id)
        setValue(`fuel.${i}.fuel_name`, item.fuel_name)

        setValue(`fuel.${i}.bio_fuel_id`, item.bio_fuel_id)
        setValue(`fuel.${i}.bio_fuel_name`, item.bio_fuel_name)

        if ('emission_co2' !== ignore_field) {
          setValue(`fuel.${i}.emission_co2`, formatPrice5(item.emission_co2, false))
        }

        if ('emission_ch4' !== ignore_field) {
          setValue(`fuel.${i}.emission_ch4`, formatPrice5(item.emission_ch4, false))
        }

        if ('emission_n2o' !== ignore_field) {
          setValue(`fuel.${i}.emission_n2o`, formatPrice5(item.emission_n2o, false))
        }

        if ('factor_ch4_kg' !== ignore_field) {
          setValue(`fuel.${i}.factor_ch4_kg`, formatPrice5(item.factor_ch4_kg, false))
        }

        if ('factor_co2_kg' !== ignore_field) {
          setValue(`fuel.${i}.factor_co2_kg`, formatPrice5(item.factor_co2_kg, false))
        }

        if ('factor_n2o_kg' !== ignore_field) {
          setValue(`fuel.${i}.factor_n2o_kg`, formatPrice5(item.factor_n2o_kg, false))
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`fuel.${i}.emission_tco2e`, formatPrice5(item.emission_tco2e, false))
        }

        if ('worked_days_jan' !== ignore_field) {
          setValue(`fuel.${i}.worked_days_jan`, item.worked_days_jan)
        }

        if ('worked_days_feb' !== ignore_field) {
          setValue(`fuel.${i}.worked_days_feb`, item.worked_days_feb)
        }

        if ('worked_days_mar' !== ignore_field) {
          setValue(`fuel.${i}.worked_days_mar`, item.worked_days_mar)
        }

        if ('worked_days_apr' !== ignore_field) {
          setValue(`fuel.${i}.worked_days_apr`, item.worked_days_apr)
        }

        if ('worked_days_may' !== ignore_field) {
          setValue(`fuel.${i}.worked_days_may`, item.worked_days_may)
        }

        if ('worked_days_jun' !== ignore_field) {
          setValue(`fuel.${i}.worked_days_jun`, item.worked_days_jun)
        }

        if ('worked_days_jul' !== ignore_field) {
          setValue(`fuel.${i}.worked_days_jul`, item.worked_days_jul)
        }

        if ('worked_days_aug' !== ignore_field) {
          setValue(`fuel.${i}.worked_days_aug`, item.worked_days_aug)
        }

        if ('worked_days_sep' !== ignore_field) {
          setValue(`fuel.${i}.worked_days_sep`, item.worked_days_sep)
        }

        if ('worked_days_oct' !== ignore_field) {
          setValue(`fuel.${i}.worked_days_oct`, item.worked_days_oct)
        }

        if ('worked_days_nov' !== ignore_field) {
          setValue(`fuel.${i}.worked_days_nov`, item.worked_days_nov)
        }

        if ('worked_days_dec' !== ignore_field) {
          setValue(`fuel.${i}.worked_days_dec`, item.worked_days_dec)
        }

        if ('fuel_amount_jan' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_jan`, formatPrice5(item.fuel_amount_jan, false))
        }

        if ('fuel_amount_feb' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_feb`, formatPrice5(item.fuel_amount_feb, false))
        }

        if ('fuel_amount_mar' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_mar`, formatPrice5(item.fuel_amount_mar, false))
        }

        if ('fuel_amount_apr' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_apr`, formatPrice5(item.fuel_amount_apr, false))
        }

        if ('fuel_amount_may' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_may`, formatPrice5(item.fuel_amount_may, false))
        }

        if ('fuel_amount_jun' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_jun`, formatPrice5(item.fuel_amount_jun, false))
        }

        if ('fuel_amount_jul' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_jul`, formatPrice5(item.fuel_amount_jul, false))
        }

        if ('fuel_amount_aug' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_aug`, formatPrice5(item.fuel_amount_aug, false))
        }

        if ('fuel_amount_sep' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_sep`, formatPrice5(item.fuel_amount_sep, false))
        }

        if ('fuel_amount_oct' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_oct`, formatPrice5(item.fuel_amount_oct, false))
        }

        if ('fuel_amount_nov' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_nov`, formatPrice5(item.fuel_amount_nov, false))
        }

        if ('fuel_amount_dec' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_dec`, formatPrice5(item.fuel_amount_dec, false))
        }

        if ('factor_co2_kg_bio' !== ignore_field) {
          setValue(`fuel.${i}.factor_co2_kg_bio`, formatPrice5(item.factor_co2_kg_bio, false))
        }

        if ('factor_ch4_kg_bio' !== ignore_field) {
          setValue(`fuel.${i}.factor_ch4_kg_bio`, formatPrice5(item.factor_ch4_kg_bio, false))
        }

        if ('factor_n2o_kg_bio' !== ignore_field) {
          setValue(`fuel.${i}.factor_n2o_kg_bio`, formatPrice5(item.factor_n2o_kg_bio, false))
        }

        if ('emission_tco2e_bio' !== ignore_field) {
          setValue(`fuel.${i}.emission_tco2e_bio`, formatPrice5(item.emission_tco2e_bio, false))
        }

        if ('fuel_amount_yearly' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_yearly`, formatPrice5(item.fuel_amount_yearly, false))
        }

        if ('fuel_amount_bio_jan' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_bio_jan`, formatPrice5(item.fuel_amount_bio_jan, false))
        }

        if ('fuel_amount_bio_feb' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_bio_feb`, formatPrice5(item.fuel_amount_bio_feb, false))
        }

        if ('fuel_amount_bio_mar' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_bio_mar`, formatPrice5(item.fuel_amount_bio_mar, false))
        }

        if ('fuel_amount_bio_apr' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_bio_apr`, formatPrice5(item.fuel_amount_bio_apr, false))
        }

        if ('fuel_amount_bio_may' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_bio_may`, formatPrice5(item.fuel_amount_bio_may, false))
        }

        if ('fuel_amount_bio_jun' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_bio_jun`, formatPrice5(item.fuel_amount_bio_jun, false))
        }

        if ('fuel_amount_bio_jul' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_bio_jul`, formatPrice5(item.fuel_amount_bio_jul, false))
        }

        if ('fuel_amount_bio_aug' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_bio_aug`, formatPrice5(item.fuel_amount_bio_aug, false))
        }

        if ('fuel_amount_bio_sep' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_bio_sep`, formatPrice5(item.fuel_amount_bio_sep, false))
        }

        if ('fuel_amount_bio_oct' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_bio_oct`, formatPrice5(item.fuel_amount_bio_oct, false))
        }

        if ('fuel_amount_bio_nov' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_bio_nov`, formatPrice5(item.fuel_amount_bio_nov, false))
        }

        if ('fuel_amount_bio_dec' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_bio_dec`, formatPrice5(item.fuel_amount_bio_dec, false))
        }

        if ('fuel_amount_bio_yearly' !== ignore_field) {
          setValue(`fuel.${i}.fuel_amount_bio_yearly`, formatPrice5(item.fuel_amount_bio_yearly, false))
        }

        scopeVehicle =
          scopeVehicle +
          '_' +
          item.car_fleet_id +
          '_' +
          item.worked_days_jan +
          '_' +
          item.worked_days_feb +
          '_' +
          item.worked_days_mar +
          '_' +
          item.worked_days_apr +
          '_' +
          item.worked_days_may +
          '_' +
          item.worked_days_jun +
          '_' +
          item.worked_days_jul +
          '_' +
          item.worked_days_aug +
          '_' +
          item.worked_days_sep +
          '_' +
          item.worked_days_oct +
          '_' +
          item.worked_days_nov +
          '_' +
          item.worked_days_dec +
          '_' +
          item.worked_days_yearly
      }
    }

    setVehicleFuels(itens)
    setScopeVehicleFuel(scopeVehicle)
  }

  const calculateVehicles = (itens: any) => {
    let emissionCo2Vehicles = 0
    let emissionCh4Vehicles = 0
    let emissionN2oVehicles = 0
    let emissionTco2eVehicles = 0
    let emissionTco2eBioVehicles = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2Vehicles = emissionCo2Vehicles + item.emission_co2
        }
        if (item.emission_ch4) {
          emissionCh4Vehicles = emissionCh4Vehicles + item.emission_ch4
        }
        if (item.emission_n2o) {
          emissionN2oVehicles = emissionN2oVehicles + item.emission_n2o
        }
        if (item.emission_tco2e) {
          emissionTco2eVehicles = emissionTco2eVehicles + item.emission_tco2e
        }
        if (item.emission_tco2e_bio) {
          emissionTco2eBioVehicles = emissionTco2eBioVehicles + item.emission_tco2e_bio
        }
      }
    }

    setEmissionCo2Vehicles(emissionCo2Vehicles)
    setEmissionCh4Vehicles(emissionCh4Vehicles)
    setEmissionN2oVehicles(emissionN2oVehicles)
    setEmissionTco2eVehicles(emissionTco2eVehicles)
    setEmissionTco2eBioVehicles(emissionTco2eBioVehicles)

    setScopeVehicle(
      'calculate_iten_' +
      emissionCo2Vehicles +
      '_' +
      emissionCh4Vehicles +
      '_' +
      emissionN2oVehicles +
      '_' +
      emissionTco2eVehicles +
      '_' +
      emissionTco2eBioVehicles
    )
  }

  const calculateVehicleDistances = (itens: any) => {
    let emissionCo2Vehicles = 0
    let emissionCh4Vehicles = 0
    let emissionN2oVehicles = 0
    let emissionTco2eVehicles = 0
    let emissionTco2eBioVehicles = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2Vehicles = emissionCo2Vehicles + item.emission_co2
        }
        if (item.emission_ch4) {
          emissionCh4Vehicles = emissionCh4Vehicles + item.emission_ch4
        }
        if (item.emission_n2o) {
          emissionN2oVehicles = emissionN2oVehicles + item.emission_n2o
        }
        if (item.emission_tco2e) {
          emissionTco2eVehicles = emissionTco2eVehicles + item.emission_tco2e
        }
        if (item.emission_tco2e_bio) {
          emissionTco2eBioVehicles = emissionTco2eBioVehicles + item.emission_tco2e_bio
        }
      }
    }

    setEmissionCo2VehicleDistances(emissionCo2Vehicles)
    setEmissionCh4VehicleDistances(emissionCh4Vehicles)
    setEmissionN2oVehicleDistances(emissionN2oVehicles)
    setEmissionTco2eVehicleDistances(emissionTco2eVehicles)
    setEmissionTco2eBioVehicleDistances(emissionTco2eBioVehicles)

    setScopeVehicleDistance(
      'calculate_iten_' +
      emissionCo2Vehicles +
      '_' +
      emissionCh4Vehicles +
      '_' +
      emissionN2oVehicles +
      '_' +
      emissionTco2eVehicles +
      '_' +
      emissionTco2eBioVehicles
    )
  }

  const calculateVehicleDistancesManual = (itens: any) => {
    let emissionCo2VehiclesManual = 0
    let emissionCh4VehiclesManual = 0
    let emissionN2oVehiclesManual = 0
    let emissionTco2eVehiclesManual = 0
    let emissionTco2eBioVehiclesManual = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2VehiclesManual = emissionCo2VehiclesManual + item.emission_co2
        }
        if (item.emission_ch4) {
          emissionCh4VehiclesManual = emissionCh4VehiclesManual + item.emission_ch4
        }
        if (item.emission_n2o) {
          emissionN2oVehiclesManual = emissionN2oVehiclesManual + item.emission_n2o
        }
        if (item.emission_tco2e) {
          emissionTco2eVehiclesManual = emissionTco2eVehiclesManual + item.emission_tco2e
        }
        if (item.emission_tco2e_bio) {
          emissionTco2eBioVehiclesManual = emissionTco2eBioVehiclesManual + item.emission_tco2e_bio
        }
      }
    }

    setEmissionCo2VehicleDistancesManual(emissionCo2VehiclesManual)
    setEmissionCh4VehicleDistancesManual(emissionCh4VehiclesManual)
    setEmissionN2oVehicleDistancesManual(emissionN2oVehiclesManual)
    setEmissionTco2eVehicleDistancesManual(emissionTco2eVehiclesManual)
    setEmissionTco2eBioVehicleDistancesManual(emissionTco2eBioVehiclesManual)

    setScopeVehicleDistanceManual(
      'calculate_iten_' +
      emissionCo2VehiclesManual +
      '_' +
      emissionCh4VehiclesManual +
      '_' +
      emissionN2oVehiclesManual +
      '_' +
      emissionTco2eVehiclesManual +
      '_' +
      emissionTco2eBioVehiclesManual
    )
  }

  const calculateVehicleFuels = (itens: any) => {
    let emissionCo2Vehicles = 0
    let emissionCh4Vehicles = 0
    let emissionN2oVehicles = 0
    let emissionTco2eVehicles = 0
    let emissionTco2eBioVehicles = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2Vehicles = emissionCo2Vehicles + item.emission_co2
        }
        if (item.emission_ch4) {
          emissionCh4Vehicles = emissionCh4Vehicles + item.emission_ch4
        }
        if (item.emission_n2o) {
          emissionN2oVehicles = emissionN2oVehicles + item.emission_n2o
        }
        if (item.emission_tco2e) {
          emissionTco2eVehicles = emissionTco2eVehicles + item.emission_tco2e
        }
        if (item.emission_tco2e_bio) {
          emissionTco2eBioVehicles = emissionTco2eBioVehicles + item.emission_tco2e_bio
        }
      }
    }

    setEmissionCo2VehicleFuels(emissionCo2Vehicles)
    setEmissionCh4VehicleFuels(emissionCh4Vehicles)
    setEmissionN2oVehicleFuels(emissionN2oVehicles)
    setEmissionTco2eVehicleFuels(emissionTco2eVehicles)
    setEmissionTco2eBioVehicleFuels(emissionTco2eBioVehicles)

    setScopeVehicleFuel(
      'calculate_iten_' +
      emissionCo2Vehicles +
      '_' +
      emissionCh4Vehicles +
      '_' +
      emissionN2oVehicles +
      '_' +
      emissionTco2eVehicles +
      '_' +
      emissionTco2eBioVehicles
    )
  }

  const handleChangeVehicle = (index: number, ignore_field?: string) => {
    const year = vehicles[index].year ? parseInt(vehicles[index].year) : 0
    const car_fleet_id = vehicles[index].car_fleet_id
    const worked_days_jan = vehicles[index].worked_days_jan
      ? parseFloat(vehicles[index].worked_days_jan)
      : 0
    const worked_days_feb = vehicles[index].worked_days_feb
      ? parseFloat(vehicles[index].worked_days_feb)
      : 0
    const worked_days_mar = vehicles[index].worked_days_mar
      ? parseFloat(vehicles[index].worked_days_mar)
      : 0
    const worked_days_apr = vehicles[index].worked_days_apr
      ? parseFloat(vehicles[index].worked_days_apr)
      : 0
    const worked_days_may = vehicles[index].worked_days_may
      ? parseFloat(vehicles[index].worked_days_may)
      : 0
    const worked_days_jun = vehicles[index].worked_days_jun
      ? parseFloat(vehicles[index].worked_days_jun)
      : 0
    const worked_days_jul = vehicles[index].worked_days_jul
      ? parseFloat(vehicles[index].worked_days_jul)
      : 0
    const worked_days_aug = vehicles[index].worked_days_aug
      ? parseFloat(vehicles[index].worked_days_aug)
      : 0
    const worked_days_sep = vehicles[index].worked_days_sep
      ? parseFloat(vehicles[index].worked_days_sep)
      : 0
    const worked_days_oct = vehicles[index].worked_days_oct
      ? parseFloat(vehicles[index].worked_days_oct)
      : 0
    const worked_days_nov = vehicles[index].worked_days_nov
      ? parseFloat(vehicles[index].worked_days_nov)
      : 0
    const worked_days_dec = vehicles[index].worked_days_dec
      ? parseFloat(vehicles[index].worked_days_dec)
      : 0
    const average_consumption_fuel = vehicles[index].average_consumption_fuel
      ? parseFloat(vehicles[index].average_consumption_fuel)
      : 0

    const onlyViewYearly =
      worked_days_jan > 0 ||
      worked_days_feb > 0 ||
      worked_days_mar > 0 ||
      worked_days_apr > 0 ||
      worked_days_may > 0 ||
      worked_days_jun > 0 ||
      worked_days_jul > 0 ||
      worked_days_aug > 0 ||
      worked_days_sep > 0 ||
      worked_days_oct > 0 ||
      worked_days_nov > 0 ||
      worked_days_dec > 0

    const worked_days_yearly = onlyViewYearly ? 0 : (vehicles[index].worked_days_yearly
      ? parseFloat(vehicles[index].worked_days_yearly) : 0)

    const onlyViewMonthly = worked_days_yearly > 0

    async function calculate() {
      const result = await scope3Service.calculatePrivateVehicle(
        year,
        car_fleet_id,
        worked_days_jan,
        worked_days_feb,
        worked_days_mar,
        worked_days_apr,
        worked_days_may,
        worked_days_jun,
        worked_days_jul,
        worked_days_aug,
        worked_days_sep,
        worked_days_oct,
        worked_days_nov,
        worked_days_dec,
        worked_days_yearly,
        average_consumption_fuel
      )

      if (result) {
        vehicles[index].only_view_yearly = onlyViewYearly
        vehicles[index].only_view_monthly = onlyViewMonthly

        vehicles[index].emission_co2 = parseFloat(result.emission_co2)
        vehicles[index].emission_ch4 = parseFloat(result.emission_ch4)
        vehicles[index].emission_n2o = parseFloat(result.emission_n2o)
        vehicles[index].factor_co2_kg = parseFloat(result.factor_co2_kg)
        vehicles[index].factor_ch4_kg = parseFloat(result.factor_ch4_kg)
        vehicles[index].factor_n2o_kg = parseFloat(result.factor_n2o_kg)
        vehicles[index].emission_tco2e = parseFloat(result.emission_tco2e)
        vehicles[index].fuel_amount_jan = parseFloat(result.fuel_amount_jan)
        vehicles[index].fuel_amount_feb = parseFloat(result.fuel_amount_feb)
        vehicles[index].fuel_amount_mar = parseFloat(result.fuel_amount_mar)
        vehicles[index].fuel_amount_apr = parseFloat(result.fuel_amount_apr)
        vehicles[index].fuel_amount_may = parseFloat(result.fuel_amount_may)
        vehicles[index].fuel_amount_jun = parseFloat(result.fuel_amount_jun)
        vehicles[index].fuel_amount_jul = parseFloat(result.fuel_amount_jul)
        vehicles[index].fuel_amount_aug = parseFloat(result.fuel_amount_aug)
        vehicles[index].fuel_amount_sep = parseFloat(result.fuel_amount_sep)
        vehicles[index].fuel_amount_oct = parseFloat(result.fuel_amount_oct)
        vehicles[index].fuel_amount_nov = parseFloat(result.fuel_amount_nov)
        vehicles[index].fuel_amount_dec = parseFloat(result.fuel_amount_dec)
        vehicles[index].factor_co2_kg_bio = parseFloat(result.factor_co2_kg_bio)
        vehicles[index].emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
        vehicles[index].fuel_amount_yearly = parseFloat(result.fuel_amount_yearly)
        vehicles[index].fuel_amount_bio_jan = parseFloat(result.fuel_amount_bio_jan)
        vehicles[index].fuel_amount_bio_feb = parseFloat(result.fuel_amount_bio_feb)
        vehicles[index].fuel_amount_bio_mar = parseFloat(result.fuel_amount_bio_mar)
        vehicles[index].fuel_amount_bio_apr = parseFloat(result.fuel_amount_bio_apr)
        vehicles[index].fuel_amount_bio_may = parseFloat(result.fuel_amount_bio_may)
        vehicles[index].fuel_amount_bio_jun = parseFloat(result.fuel_amount_bio_jun)
        vehicles[index].fuel_amount_bio_jul = parseFloat(result.fuel_amount_bio_jul)
        vehicles[index].fuel_amount_bio_aug = parseFloat(result.fuel_amount_bio_aug)
        vehicles[index].fuel_amount_bio_sep = parseFloat(result.fuel_amount_bio_sep)
        vehicles[index].fuel_amount_bio_oct = parseFloat(result.fuel_amount_bio_oct)
        vehicles[index].fuel_amount_bio_nov = parseFloat(result.fuel_amount_bio_nov)
        vehicles[index].fuel_amount_bio_dec = parseFloat(result.fuel_amount_bio_dec)
        vehicles[index].fuel_amount_bio_yearly = parseFloat(result.fuel_amount_bio_yearly)

        setScopeVehicle(
          'change_scope_item_' +
          index +
          '_' +
          car_fleet_id +
          '_' +
          worked_days_jan +
          '_' +
          worked_days_feb +
          '_' +
          worked_days_mar +
          '_' +
          worked_days_apr +
          '_' +
          worked_days_may +
          '_' +
          worked_days_jun +
          '_' +
          worked_days_jul +
          '_' +
          worked_days_aug +
          '_' +
          worked_days_sep +
          '_' +
          worked_days_oct +
          '_' +
          worked_days_nov +
          '_' +
          worked_days_dec +
          '_' +
          worked_days_yearly
        )

        setVehiclesValues(vehicles, ignore_field)
        calculateVehicles(vehicles)
      }
    }

    calculate()
  }
  // LIDA COM MUDANÇAS "LIDA NADA!"
  const handleChangeVehicleDistance = (index: number, ignore_field?: string) => {
    const year = vehicleDistances[index].year ? parseInt(vehicleDistances[index].year) : 0
    const description = vehicleDistances[index].description 
    const car_fleet_id = vehicleDistances[index].car_fleet_id
    let average_distance = vehicleDistances[index].average_distance ? parseFloat(vehicleDistances[index].average_distance) : 0
    const worked_days_jan = vehicleDistances[index].worked_days_jan ? parseFloat(vehicleDistances[index].worked_days_jan) : 0
    const worked_days_feb = vehicleDistances[index].worked_days_feb ? parseFloat(vehicleDistances[index].worked_days_feb) : 0
    const worked_days_mar = vehicleDistances[index].worked_days_mar ? parseFloat(vehicleDistances[index].worked_days_mar) : 0
    const worked_days_apr = vehicleDistances[index].worked_days_apr ? parseFloat(vehicleDistances[index].worked_days_apr) : 0
    const worked_days_may = vehicleDistances[index].worked_days_may ? parseFloat(vehicleDistances[index].worked_days_may) : 0
    const worked_days_jun = vehicleDistances[index].worked_days_jun ? parseFloat(vehicleDistances[index].worked_days_jun) : 0
    const worked_days_jul = vehicleDistances[index].worked_days_jul ? parseFloat(vehicleDistances[index].worked_days_jul) : 0
    const worked_days_aug = vehicleDistances[index].worked_days_aug ? parseFloat(vehicleDistances[index].worked_days_aug) : 0
    const worked_days_sep = vehicleDistances[index].worked_days_sep ? parseFloat(vehicleDistances[index].worked_days_sep) : 0
    const worked_days_oct = vehicleDistances[index].worked_days_oct ? parseFloat(vehicleDistances[index].worked_days_oct) : 0
    const worked_days_nov = vehicleDistances[index].worked_days_nov ? parseFloat(vehicleDistances[index].worked_days_nov) : 0
    const worked_days_dec = vehicleDistances[index].worked_days_dec ? parseFloat(vehicleDistances[index].worked_days_dec) : 0

    const onlyViewYearly =
      worked_days_jan > 0 ||
      worked_days_feb > 0 ||
      worked_days_mar > 0 ||
      worked_days_apr > 0 ||
      worked_days_may > 0 ||
      worked_days_jun > 0 ||
      worked_days_jul > 0 ||
      worked_days_aug > 0 ||
      worked_days_sep > 0 ||
      worked_days_oct > 0 ||
      worked_days_nov > 0 ||
      worked_days_dec > 0

    const worked_days_yearly = onlyViewYearly ? 0 : (vehicleDistances[index].worked_days_yearly ? parseFloat(vehicleDistances[index].worked_days_yearly) : 0)

    const onlyViewMonthly = worked_days_yearly > 0
    
    average_distance = average_distance ? average_distance : 0

    async function calculate() {
      const result = await scope3Service.calculateVehicleDistance(
        year,
        car_fleet_id,
        inventorySelected.company.id,
        description,
        worked_days_jan,
        worked_days_feb,
        worked_days_mar,
        worked_days_apr,
        worked_days_may,
        worked_days_jun,
        worked_days_jul,
        worked_days_aug,
        worked_days_sep,
        worked_days_oct,
        worked_days_nov,
        worked_days_dec,
        worked_days_yearly,
        average_distance
      )

      if (result) {
        vehicleDistances[index].only_view_yearly = onlyViewYearly
        vehicleDistances[index].only_view_monthly = onlyViewMonthly

        vehicleDistances[index].emission_co2 = parseFloat(result.emission_co2)
        vehicleDistances[index].emission_ch4 = parseFloat(result.emission_ch4)
        vehicleDistances[index].emission_n2o = parseFloat(result.emission_n2o)
        vehicleDistances[index].factor_co2_kg = parseFloat(result.factor_co2_kg)
        vehicleDistances[index].factor_ch4_kg = parseFloat(result.factor_ch4_kg)
        vehicleDistances[index].factor_n2o_kg = parseFloat(result.factor_n2o_kg)
        vehicleDistances[index].emission_tco2e = parseFloat(result.emission_tco2e)
        vehicleDistances[index].fuel_amount_jan = parseFloat(result.fuel_amount_jan)
        vehicleDistances[index].fuel_amount_feb = parseFloat(result.fuel_amount_feb)
        vehicleDistances[index].fuel_amount_mar = parseFloat(result.fuel_amount_mar)
        vehicleDistances[index].fuel_amount_apr = parseFloat(result.fuel_amount_apr)
        vehicleDistances[index].fuel_amount_may = parseFloat(result.fuel_amount_may)
        vehicleDistances[index].fuel_amount_jun = parseFloat(result.fuel_amount_jun)
        vehicleDistances[index].fuel_amount_jul = parseFloat(result.fuel_amount_jul)
        vehicleDistances[index].fuel_amount_aug = parseFloat(result.fuel_amount_aug)
        vehicleDistances[index].fuel_amount_sep = parseFloat(result.fuel_amount_sep)
        vehicleDistances[index].fuel_amount_oct = parseFloat(result.fuel_amount_oct)
        vehicleDistances[index].fuel_amount_nov = parseFloat(result.fuel_amount_nov)
        vehicleDistances[index].fuel_amount_dec = parseFloat(result.fuel_amount_dec)
        vehicleDistances[index].average_distance = parseFloat(result.average_distance)
        vehicleDistances[index].factor_co2_kg_bio = parseFloat(result.factor_co2_kg_bio)
        vehicleDistances[index].emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
        vehicleDistances[index].fuel_amount_yearly = parseFloat(result.fuel_amount_yearly)
        vehicleDistances[index].fuel_amount_bio_jan = parseFloat(result.fuel_amount_bio_jan)
        vehicleDistances[index].fuel_amount_bio_feb = parseFloat(result.fuel_amount_bio_feb)
        vehicleDistances[index].fuel_amount_bio_mar = parseFloat(result.fuel_amount_bio_mar)
        vehicleDistances[index].fuel_amount_bio_apr = parseFloat(result.fuel_amount_bio_apr)
        vehicleDistances[index].fuel_amount_bio_may = parseFloat(result.fuel_amount_bio_may)
        vehicleDistances[index].fuel_amount_bio_jun = parseFloat(result.fuel_amount_bio_jun)
        vehicleDistances[index].fuel_amount_bio_jul = parseFloat(result.fuel_amount_bio_jul)
        vehicleDistances[index].fuel_amount_bio_aug = parseFloat(result.fuel_amount_bio_aug)
        vehicleDistances[index].fuel_amount_bio_sep = parseFloat(result.fuel_amount_bio_sep)
        vehicleDistances[index].fuel_amount_bio_oct = parseFloat(result.fuel_amount_bio_oct)
        vehicleDistances[index].fuel_amount_bio_nov = parseFloat(result.fuel_amount_bio_nov)
        vehicleDistances[index].fuel_amount_bio_dec = parseFloat(result.fuel_amount_bio_dec)
        vehicleDistances[index].fuel_amount_bio_yearly = parseFloat(result.fuel_amount_bio_yearly)
        vehicleDistances[index].fuel_amount_fossil_jan = parseFloat(result.fuel_amount_fossil_jan)
        vehicleDistances[index].fuel_amount_fossil_feb = parseFloat(result.fuel_amount_fossil_feb)
        vehicleDistances[index].fuel_amount_fossil_mar = parseFloat(result.fuel_amount_fossil_mar)
        vehicleDistances[index].fuel_amount_fossil_apr = parseFloat(result.fuel_amount_fossil_apr)
        vehicleDistances[index].fuel_amount_fossil_may = parseFloat(result.fuel_amount_fossil_may)
        vehicleDistances[index].fuel_amount_fossil_jun = parseFloat(result.fuel_amount_fossil_jun)
        vehicleDistances[index].fuel_amount_fossil_jul = parseFloat(result.fuel_amount_fossil_jul)
        vehicleDistances[index].fuel_amount_fossil_aug = parseFloat(result.fuel_amount_fossil_aug)
        vehicleDistances[index].fuel_amount_fossil_sep = parseFloat(result.fuel_amount_fossil_sep)
        vehicleDistances[index].fuel_amount_fossil_oct = parseFloat(result.fuel_amount_fossil_oct)
        vehicleDistances[index].fuel_amount_fossil_nov = parseFloat(result.fuel_amount_fossil_nov)
        vehicleDistances[index].fuel_amount_fossil_dec = parseFloat(result.fuel_amount_fossil_dec)
        vehicleDistances[index].average_consumption_fuel = parseFloat(result.average_consumption_fuel)
        vehicleDistances[index].fuel_amount_fossil_yearly = parseFloat(result.fuel_amount_fossil_yearly)

        setScopeVehicleDistance(
          'change_scope_item_' +
          index +
          '_' +
          average_distance +
          '_' +
          car_fleet_id +          
          '_' +
          worked_days_jan +
          '_' +
          worked_days_feb +
          '_' +
          worked_days_mar +
          '_' +
          worked_days_apr +
          '_' +
          worked_days_may +
          '_' +
          worked_days_jun +
          '_' +
          worked_days_jul +
          '_' +
          worked_days_aug +
          '_' +
          worked_days_sep +
          '_' +
          worked_days_oct +
          '_' +
          worked_days_nov +
          '_' +
          worked_days_dec +
          '_' +
          worked_days_yearly
        )
        
        setVehicleDistancesValues(vehicleDistances, ignore_field)
        calculateVehicleDistances(vehicleDistances)
      }
    }
    calculate()

  }

  const handleChangeVehicleDistanceManual = (index: number, ignore_field?: string) => {
    const year = vehicleDistancesManual[index].year ? parseInt(vehicleDistancesManual[index].year) : 0
    const car_fleet_id = vehicleDistancesManual[index].car_fleet_id
    const average_distance = vehicleDistancesManual[index].average_distance
    const worked_days_jan = vehicleDistancesManual[index].worked_days_jan ? parseFloat(vehicleDistancesManual[index].worked_days_jan) : 0
    const worked_days_feb = vehicleDistancesManual[index].worked_days_feb ? parseFloat(vehicleDistancesManual[index].worked_days_feb) : 0
    const worked_days_mar = vehicleDistancesManual[index].worked_days_mar ? parseFloat(vehicleDistancesManual[index].worked_days_mar) : 0
    const worked_days_apr = vehicleDistancesManual[index].worked_days_apr ? parseFloat(vehicleDistancesManual[index].worked_days_apr) : 0
    const worked_days_may = vehicleDistancesManual[index].worked_days_may ? parseFloat(vehicleDistancesManual[index].worked_days_may) : 0
    const worked_days_jun = vehicleDistancesManual[index].worked_days_jun ? parseFloat(vehicleDistancesManual[index].worked_days_jun) : 0
    const worked_days_jul = vehicleDistancesManual[index].worked_days_jul ? parseFloat(vehicleDistancesManual[index].worked_days_jul) : 0
    const worked_days_aug = vehicleDistancesManual[index].worked_days_aug ? parseFloat(vehicleDistancesManual[index].worked_days_aug) : 0
    const worked_days_sep = vehicleDistancesManual[index].worked_days_sep ? parseFloat(vehicleDistancesManual[index].worked_days_sep) : 0
    const worked_days_oct = vehicleDistancesManual[index].worked_days_oct ? parseFloat(vehicleDistancesManual[index].worked_days_oct) : 0
    const worked_days_nov = vehicleDistancesManual[index].worked_days_nov ? parseFloat(vehicleDistancesManual[index].worked_days_nov) : 0
    const worked_days_dec = vehicleDistancesManual[index].worked_days_dec ? parseFloat(vehicleDistancesManual[index].worked_days_dec) : 0

    const onlyViewYearly =
      worked_days_jan > 0 ||
      worked_days_feb > 0 ||
      worked_days_mar > 0 ||
      worked_days_apr > 0 ||
      worked_days_may > 0 ||
      worked_days_jun > 0 ||
      worked_days_jul > 0 ||
      worked_days_aug > 0 ||
      worked_days_sep > 0 ||
      worked_days_oct > 0 ||
      worked_days_nov > 0 ||
      worked_days_dec > 0

    const worked_days_yearly = onlyViewYearly ? 0 : (vehicleDistancesManual[index].worked_days_yearly ? parseFloat(vehicleDistancesManual[index].worked_days_yearly) : 0)

    const onlyViewMonthly = worked_days_yearly > 0

    // average_distance = average_distance ? average_distance : 0

    async function calculate() {
      const result = await scope3Service.calculateVehicleDistanceManual(
        year,
        car_fleet_id,
        inventorySelected.company.id,
        worked_days_jan,
        worked_days_feb,
        worked_days_mar,
        worked_days_apr,
        worked_days_may,
        worked_days_jun,
        worked_days_jul,
        worked_days_aug,
        worked_days_sep,
        worked_days_oct,
        worked_days_nov,
        worked_days_dec,
        worked_days_yearly,
        average_distance
      )

      if (result) {
        vehicleDistancesManual[index].only_view_yearly = onlyViewYearly
        vehicleDistancesManual[index].only_view_monthly = onlyViewMonthly

        vehicleDistancesManual[index].emission_co2 = parseFloat(result.emission_co2)
        vehicleDistancesManual[index].emission_ch4 = parseFloat(result.emission_ch4)
        vehicleDistancesManual[index].emission_n2o = parseFloat(result.emission_n2o)
        vehicleDistancesManual[index].factor_co2_kg = parseFloat(result.factor_co2_kg)
        vehicleDistancesManual[index].factor_ch4_kg = parseFloat(result.factor_ch4_kg)
        vehicleDistancesManual[index].factor_n2o_kg = parseFloat(result.factor_n2o_kg)
        vehicleDistancesManual[index].emission_tco2e = parseFloat(result.emission_tco2e)
        vehicleDistancesManual[index].fuel_amount_jan = parseFloat(result.fuel_amount_jan)
        vehicleDistancesManual[index].fuel_amount_feb = parseFloat(result.fuel_amount_feb)
        vehicleDistancesManual[index].fuel_amount_mar = parseFloat(result.fuel_amount_mar)
        vehicleDistancesManual[index].fuel_amount_apr = parseFloat(result.fuel_amount_apr)
        vehicleDistancesManual[index].fuel_amount_may = parseFloat(result.fuel_amount_may)
        vehicleDistancesManual[index].fuel_amount_jun = parseFloat(result.fuel_amount_jun)
        vehicleDistancesManual[index].fuel_amount_jul = parseFloat(result.fuel_amount_jul)
        vehicleDistancesManual[index].fuel_amount_aug = parseFloat(result.fuel_amount_aug)
        vehicleDistancesManual[index].fuel_amount_sep = parseFloat(result.fuel_amount_sep)
        vehicleDistancesManual[index].fuel_amount_oct = parseFloat(result.fuel_amount_oct)
        vehicleDistancesManual[index].fuel_amount_nov = parseFloat(result.fuel_amount_nov)
        vehicleDistancesManual[index].fuel_amount_dec = parseFloat(result.fuel_amount_dec)
        vehicleDistancesManual[index].average_distance = parseFloat(result.average_distance)
        vehicleDistancesManual[index].factor_co2_kg_bio = parseFloat(result.factor_co2_kg_bio)
        vehicleDistancesManual[index].emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
        vehicleDistancesManual[index].fuel_amount_yearly = parseFloat(result.fuel_amount_yearly)
        vehicleDistancesManual[index].fuel_amount_bio_jan = parseFloat(result.fuel_amount_bio_jan)
        vehicleDistancesManual[index].fuel_amount_bio_feb = parseFloat(result.fuel_amount_bio_feb)
        vehicleDistancesManual[index].fuel_amount_bio_mar = parseFloat(result.fuel_amount_bio_mar)
        vehicleDistancesManual[index].fuel_amount_bio_apr = parseFloat(result.fuel_amount_bio_apr)
        vehicleDistancesManual[index].fuel_amount_bio_may = parseFloat(result.fuel_amount_bio_may)
        vehicleDistancesManual[index].fuel_amount_bio_jun = parseFloat(result.fuel_amount_bio_jun)
        vehicleDistancesManual[index].fuel_amount_bio_jul = parseFloat(result.fuel_amount_bio_jul)
        vehicleDistancesManual[index].fuel_amount_bio_aug = parseFloat(result.fuel_amount_bio_aug)
        vehicleDistancesManual[index].fuel_amount_bio_sep = parseFloat(result.fuel_amount_bio_sep)
        vehicleDistancesManual[index].fuel_amount_bio_oct = parseFloat(result.fuel_amount_bio_oct)
        vehicleDistancesManual[index].fuel_amount_bio_nov = parseFloat(result.fuel_amount_bio_nov)
        vehicleDistancesManual[index].fuel_amount_bio_dec = parseFloat(result.fuel_amount_bio_dec)
        vehicleDistancesManual[index].fuel_amount_bio_yearly = parseFloat(result.fuel_amount_bio_yearly)
        vehicleDistancesManual[index].fuel_amount_fossil_jan = parseFloat(result.fuel_amount_fossil_jan)
        vehicleDistancesManual[index].fuel_amount_fossil_feb = parseFloat(result.fuel_amount_fossil_feb)
        vehicleDistancesManual[index].fuel_amount_fossil_mar = parseFloat(result.fuel_amount_fossil_mar)
        vehicleDistancesManual[index].fuel_amount_fossil_apr = parseFloat(result.fuel_amount_fossil_apr)
        vehicleDistancesManual[index].fuel_amount_fossil_may = parseFloat(result.fuel_amount_fossil_may)
        vehicleDistancesManual[index].fuel_amount_fossil_jun = parseFloat(result.fuel_amount_fossil_jun)
        vehicleDistancesManual[index].fuel_amount_fossil_jul = parseFloat(result.fuel_amount_fossil_jul)
        vehicleDistancesManual[index].fuel_amount_fossil_aug = parseFloat(result.fuel_amount_fossil_aug)
        vehicleDistancesManual[index].fuel_amount_fossil_sep = parseFloat(result.fuel_amount_fossil_sep)
        vehicleDistancesManual[index].fuel_amount_fossil_oct = parseFloat(result.fuel_amount_fossil_oct)
        vehicleDistancesManual[index].fuel_amount_fossil_nov = parseFloat(result.fuel_amount_fossil_nov)
        vehicleDistancesManual[index].fuel_amount_fossil_dec = parseFloat(result.fuel_amount_fossil_dec)
        vehicleDistancesManual[index].average_consumption_fuel = parseFloat(result.average_consumption_fuel)
        vehicleDistancesManual[index].fuel_amount_fossil_yearly = parseFloat(result.fuel_amount_fossil_yearly)

        setScopeVehicleDistanceManual(
          'change_scope_item_' +
          index +
          '_' +
          average_distance +
          '_' +
          car_fleet_id +
          '_' +
          worked_days_jan +
          '_' +
          worked_days_feb +
          '_' +
          worked_days_mar +
          '_' +
          worked_days_apr +
          '_' +
          worked_days_may +
          '_' +
          worked_days_jun +
          '_' +
          worked_days_jul +
          '_' +
          worked_days_aug +
          '_' +
          worked_days_sep +
          '_' +
          worked_days_oct +
          '_' +
          worked_days_nov +
          '_' +
          worked_days_dec +
          '_' +
          worked_days_yearly
        )

        setVehicleDistancesValuesManual(vehicleDistancesManual, ignore_field)
        calculateVehicleDistancesManual(vehicleDistancesManual)
      }
    }
    calculate()

  }

  const handleChangeVehicleFuel = (index: number, ignore_field?: string) => {
    const year = inventorySelected.year
    const vehicle_fuel_id = vehicleFuels[index].vehicle_fuel_id
    const worked_days_jan = vehicleFuels[index].worked_days_jan
      ? parseFloat(vehicleFuels[index].worked_days_jan)
      : 0
    const worked_days_feb = vehicleFuels[index].worked_days_feb
      ? parseFloat(vehicleFuels[index].worked_days_feb)
      : 0
    const worked_days_mar = vehicleFuels[index].worked_days_mar
      ? parseFloat(vehicleFuels[index].worked_days_mar)
      : 0
    const worked_days_apr = vehicleFuels[index].worked_days_apr
      ? parseFloat(vehicleFuels[index].worked_days_apr)
      : 0
    const worked_days_may = vehicleFuels[index].worked_days_may
      ? parseFloat(vehicleFuels[index].worked_days_may)
      : 0
    const worked_days_jun = vehicleFuels[index].worked_days_jun
      ? parseFloat(vehicleFuels[index].worked_days_jun)
      : 0
    const worked_days_jul = vehicleFuels[index].worked_days_jul
      ? parseFloat(vehicleFuels[index].worked_days_jul)
      : 0
    const worked_days_aug = vehicleFuels[index].worked_days_aug
      ? parseFloat(vehicleFuels[index].worked_days_aug)
      : 0
    const worked_days_sep = vehicleFuels[index].worked_days_sep
      ? parseFloat(vehicleFuels[index].worked_days_sep)
      : 0
    const worked_days_oct = vehicleFuels[index].worked_days_oct
      ? parseFloat(vehicleFuels[index].worked_days_oct)
      : 0
    const worked_days_nov = vehicleFuels[index].worked_days_nov
      ? parseFloat(vehicleFuels[index].worked_days_nov)
      : 0
    const worked_days_dec = vehicleFuels[index].worked_days_dec
      ? parseFloat(vehicleFuels[index].worked_days_dec)
      : 0
    const average_consumption_fuel = vehicleFuels[index].average_consumption_fuel
      ? parseFloat(vehicleFuels[index].average_consumption_fuel)
      : 0

    const onlyViewYearly =
      worked_days_jan > 0 ||
      worked_days_feb > 0 ||
      worked_days_mar > 0 ||
      worked_days_apr > 0 ||
      worked_days_may > 0 ||
      worked_days_jun > 0 ||
      worked_days_jul > 0 ||
      worked_days_aug > 0 ||
      worked_days_sep > 0 ||
      worked_days_oct > 0 ||
      worked_days_nov > 0 ||
      worked_days_dec > 0

    const worked_days_yearly = onlyViewYearly ? 0 : (vehicleFuels[index].worked_days_yearly
      ? parseFloat(vehicleFuels[index].worked_days_yearly) : 0)

    const onlyViewMonthly = worked_days_yearly > 0

    async function calculate() {
      const result = await scope3Service.calculateVehicleFuel(
        year,
        vehicle_fuel_id,
        worked_days_jan,
        worked_days_feb,
        worked_days_mar,
        worked_days_apr,
        worked_days_may,
        worked_days_jun,
        worked_days_jul,
        worked_days_aug,
        worked_days_sep,
        worked_days_oct,
        worked_days_nov,
        worked_days_dec,
        worked_days_yearly,
        average_consumption_fuel
      )

      if (result) {
        vehicleFuels[index].only_view_yearly = onlyViewYearly
        vehicleFuels[index].only_view_monthly = onlyViewMonthly

        vehicleFuels[index].emission_co2 = parseFloat(result.emission_co2)
        vehicleFuels[index].emission_ch4 = parseFloat(result.emission_ch4)
        vehicleFuels[index].emission_n2o = parseFloat(result.emission_n2o)
        vehicleFuels[index].factor_co2_kg = parseFloat(result.factor_co2_kg)
        vehicleFuels[index].factor_ch4_kg = parseFloat(result.factor_ch4_kg)
        vehicleFuels[index].factor_n2o_kg = parseFloat(result.factor_n2o_kg)
        vehicleFuels[index].emission_tco2e = parseFloat(result.emission_tco2e)
        vehicleFuels[index].fuel_amount_jan = parseFloat(result.fuel_amount_jan)
        vehicleFuels[index].fuel_amount_feb = parseFloat(result.fuel_amount_feb)
        vehicleFuels[index].fuel_amount_mar = parseFloat(result.fuel_amount_mar)
        vehicleFuels[index].fuel_amount_apr = parseFloat(result.fuel_amount_apr)
        vehicleFuels[index].fuel_amount_may = parseFloat(result.fuel_amount_may)
        vehicleFuels[index].fuel_amount_jun = parseFloat(result.fuel_amount_jun)
        vehicleFuels[index].fuel_amount_jul = parseFloat(result.fuel_amount_jul)
        vehicleFuels[index].fuel_amount_aug = parseFloat(result.fuel_amount_aug)
        vehicleFuels[index].fuel_amount_sep = parseFloat(result.fuel_amount_sep)
        vehicleFuels[index].fuel_amount_oct = parseFloat(result.fuel_amount_oct)
        vehicleFuels[index].fuel_amount_nov = parseFloat(result.fuel_amount_nov)
        vehicleFuels[index].fuel_amount_dec = parseFloat(result.fuel_amount_dec)
        vehicleFuels[index].factor_co2_kg_bio = parseFloat(result.factor_co2_kg_bio)
        vehicleFuels[index].factor_ch4_kg_bio = parseFloat(result.factor_ch4_kg_bio)
        vehicleFuels[index].factor_n2o_kg_bio = parseFloat(result.factor_n2o_kg_bio)
        vehicleFuels[index].emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
        vehicleFuels[index].fuel_amount_yearly = parseFloat(result.fuel_amount_yearly)
        vehicleFuels[index].fuel_amount_bio_jan = parseFloat(result.fuel_amount_bio_jan)
        vehicleFuels[index].fuel_amount_bio_feb = parseFloat(result.fuel_amount_bio_feb)
        vehicleFuels[index].fuel_amount_bio_mar = parseFloat(result.fuel_amount_bio_mar)
        vehicleFuels[index].fuel_amount_bio_apr = parseFloat(result.fuel_amount_bio_apr)
        vehicleFuels[index].fuel_amount_bio_may = parseFloat(result.fuel_amount_bio_may)
        vehicleFuels[index].fuel_amount_bio_jun = parseFloat(result.fuel_amount_bio_jun)
        vehicleFuels[index].fuel_amount_bio_jul = parseFloat(result.fuel_amount_bio_jul)
        vehicleFuels[index].fuel_amount_bio_aug = parseFloat(result.fuel_amount_bio_aug)
        vehicleFuels[index].fuel_amount_bio_sep = parseFloat(result.fuel_amount_bio_sep)
        vehicleFuels[index].fuel_amount_bio_oct = parseFloat(result.fuel_amount_bio_oct)
        vehicleFuels[index].fuel_amount_bio_nov = parseFloat(result.fuel_amount_bio_nov)
        vehicleFuels[index].fuel_amount_bio_dec = parseFloat(result.fuel_amount_bio_dec)
        vehicleFuels[index].fuel_amount_bio_yearly = parseFloat(
          result.fuel_amount_bio_yearly
        )

        setScopeVehicle(
          'change_scope_item_' +
          index +
          '_' +
          vehicle_fuel_id +
          '_' +
          worked_days_jan +
          '_' +
          worked_days_feb +
          '_' +
          worked_days_mar +
          '_' +
          worked_days_apr +
          '_' +
          worked_days_may +
          '_' +
          worked_days_jun +
          '_' +
          worked_days_jul +
          '_' +
          worked_days_aug +
          '_' +
          worked_days_sep +
          '_' +
          worked_days_oct +
          '_' +
          worked_days_nov +
          '_' +
          worked_days_dec +
          '_' +
          worked_days_yearly
        )

        setVehiclesValues(vehicles, ignore_field)
        calculateVehicles(vehicles)
      }
    }

    calculate()
  }

  function handleDeleteVehicle(index: number) {
    const itens = []

    for (let i = 0; i < vehicles?.length; i++) {
      if (i !== index) {
        itens.push(vehicles[i])
      }
    }

    setScopeVehicle('remove_scope_item_' + index)

    setValue('car_freet_private_vehicle', [])

    setVehiclesValues(itens)
    calculateVehicles(itens)
  }

  function handleOpenModalDeleteVehicle(){
    setOpenModalDeleteVehicle(true)
  }

  function handleDeleteAllScopeVehicle(){
    const itens: any = [];

    setScopeVehicle('remove_scope_all_items')
    setValue('car_freet_private_vehicle', [])

    setVehiclesValues(itens)
    calculateVehicles(itens)

    setOpenModalDeleteVehicle(false)

  }

  function handleDeleteVehicleFuel(index: number) {
    const itens = []

    for (let i = 0; i < vehicleFuels?.length; i++) {
      if (i !== index) {
        itens.push(vehicleFuels[i])
      }
    }

    setScopeVehicleFuel('remove_scope_item_' + index)

    setValue('fuel_private_vehicle', [])

    setVehicleFuelsValues(itens)
    calculateVehicleFuels(itens)
  }

  function handleOpenModalDeleteVehicleFuel(){
    setOpenModalDeleteVehicleFuel(true)
  }

  function handleDeleteAllScopeVehicleFuel(){
    const itens: any = [];

    setScopeVehicleFuel('remove_scope_item_');

    setValue('fuel_private_vehicle', [])

    setVehicleFuelsValues(itens)
    calculateVehicleFuels(itens)

    setOpenModalDeleteVehicle(false)

  }

  function handleDeleteVehicleDistance(index: number) {
    const itens = []

    for (let i = 0; i < vehicleDistances?.length; i++) {
      if (i !== index) {
        itens.push(vehicleDistances[i])
      }
    }

    setScopeVehicleDistance('remove_scope_item_' + index)

    setValue('car_freet_private_vehicle_distance', [])

    setVehicleDistancesValues(itens)
    calculateVehicleDistances(itens)
  }

  function handleOpenModalDeleteDistances(){
    setOpenModalDeleteDistances(true)
  }

  function handleDeleteAllScopeDistances(){
    const itens: any = [];

    setScopeVehicleDistance('remove_scope_item_')

    setValue('car_freet_private_vehicle_distance', [])

    setVehicleDistancesValues(itens)
    calculateVehicleDistances(itens)

    setOpenModalDeleteVehicle(false)

  }

  function handleDeleteVehicleDistanceManual(index: number) {
    const itens = []

    for (let i = 0; i < vehicleDistancesManual?.length; i++) {
      if (i !== index) {
        itens.push(vehicleDistancesManual[i])
      }
    }

    setScopeVehicleDistanceManual('remove_scope_item_' + index)

    setValue('car_freet_private_vehicle_distance_manual', [])

    setVehicleDistancesValuesManual(itens)
    calculateVehicleDistancesManual(itens)
  }

  function handleOpenModalDeleteDistancesManual(){
    setOpenModalDeleteDistancesManual(true)
  }

  function handleDeleteAllScopeDistancesManual(){
    const itens: any = [];

    setScopeVehicleDistanceManual('remove_scope_item_')

    setValue('car_freet_private_vehicle_distance_manual', [])

    setVehicleDistancesValuesManual(itens)
    calculateVehicleDistancesManual(itens)

    setOpenModalDeleteVehicle(false)

  }


  const downloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'deslocamento_casa_trabalho',
          'veiculos_particulares'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const attachmentChangeHandler = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 3,
        name: 'deslocamento_casa_trabalho',
        file: base64,
        filename: filename,
        second_name: 'veiculos_particulares'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'deslocamento_casa_trabalho',
          'veiculos_particulares'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()

      reader.readAsDataURL(file)

      reader.onload = () => {
        const base64 = reader.result

        saveImage(base64?.toString(), file.name)
      }
    }
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const handleOpenModalImport1 = () => {
    setOpenModalImport1(!openModalImport1)
  }

  const handleOpenModalImport2 = () => {
    setOpenModalImport2(!openModalImport2)
  }

  const handleOpenModalImport3 = () => {
    setOpenModalImport3(!openModalImport3)
  }

  const handleOpenModalImport4 = () => {
    setOpenModalImport4(!openModalImport4)
  }

  const handleImportCarFleet = (type?: number, data?: any[]) => {
    async function importData() {
      if (data) {
        setLoadingModal(true)

        const errors: any[] = []
        const itens = vehicles

        for (let i = 1; i < data?.length; i++) {
          const carFleet = data[i][2]

          if (carFleet) {
            let obj: any = undefined

            for (const t of carFleets) {
              if (t.label === carFleet) {
                obj = t
                break;
              }
            }

            if (obj) {
              const carObj = await carFleetService.getById(obj.value)
              if (carObj) {
                const fuel = carObj.fuel
                const bioFuel = carObj.bioFuel
                
                const register = data[i][0]
                const address = data[i][1]
                const year = data[i][3]
                const average_consumption_fuel = data[i][4]
                const admission = data[i][6]
                const resignation = data[i][7]
                let worked_days_yearly = 0

                if (admission && resignation){
                  const call = await utilsService.getDifferenceDays(
                    { start_date: admission, end_date: resignation, year: inventorySelected.year }
                  )

                  worked_days_yearly = call.result
                } else if (admission){
                  const call = await utilsService.getDifferenceDays(
                    { start_date: admission, year: inventorySelected.year }
                  )

                  worked_days_yearly = call.result
                } else if (resignation){
                  const call = await utilsService.getDifferenceDays(
                    { end_date: resignation, year: inventorySelected.year }
                  )

                  worked_days_yearly = call.result
                } else {
                  const call = await utilsService.getDifferenceDays(
                    { year: inventorySelected.year }
                  )

                  worked_days_yearly = call.result
                }


                const scope = {
                  worked_days_yearly: worked_days_yearly,
                  register: register, description: address, year: year, car_fleet_id: obj.value,
                  car_fleet_name: obj.label, fuel_id: fuel ? fuel.id : undefined,
                  fuel_name: fuel ? fuel.name : undefined, bio_fuel_id: bioFuel ? bioFuel.id : undefined,
                  bio_fuel_name: bioFuel ? bioFuel.name : undefined, worked_days_jan: '',
                  worked_days_feb: '', worked_days_mar: '', worked_days_apr: '',
                  worked_days_may: '', worked_days_jun: '', worked_days_jul: '',
                  worked_days_aug: '', worked_days_sep: '', worked_days_oct: '',
                  worked_days_nov: '', worked_days_dec: '',
                  average_consumption_fuel: average_consumption_fuel, emission_co2: 0, emission_ch4: 0,
                  emission_n2o: 0, factor_co2_kg: 0, factor_ch4_kg: 0, factor_n2o_kg: 0, emission_tco2e: 0,
                  fuel_amount_jan: 0, fuel_amount_feb: 0, fuel_amount_mar: 0, fuel_amount_apr: 0, fuel_amount_may: 0,
                  fuel_amount_jun: 0, fuel_amount_jul: 0, fuel_amount_aug: 0, fuel_amount_sep: 0, fuel_amount_oct: 0,
                  fuel_amount_nov: 0, fuel_amount_dec: 0, factor_co2_kg_bio: 0, emission_tco2e_bio: 0, fuel_amount_yearly: 0,
                  fuel_amount_bio_jan: 0, fuel_amount_bio_feb: 0, fuel_amount_bio_mar: 0, fuel_amount_bio_apr: 0,
                  fuel_amount_bio_may: 0, fuel_amount_bio_jun: 0, fuel_amount_bio_jul: 0, fuel_amount_bio_aug: 0,
                  fuel_amount_bio_sep: 0, fuel_amount_bio_oct: 0, fuel_amount_bio_nov: 0, fuel_amount_bio_dec: 0,
                  fuel_amount_bio_yearly: 0, only_view_yearly: false, only_view_monthly: true
                }

                const result = await scope3Service.calculatePrivateVehicle(scope.year, scope.car_fleet_id,
                  scope.worked_days_jan, scope.worked_days_feb, scope.worked_days_mar, scope.worked_days_apr,
                  scope.worked_days_may, scope.worked_days_jun, scope.worked_days_jul, scope.worked_days_aug,
                  scope.worked_days_sep, scope.worked_days_oct, scope.worked_days_nov, scope.worked_days_dec,
                  scope.worked_days_yearly, scope.average_consumption_fuel
                )

                if (result) {
                  scope.emission_co2 = parseFloat(result.emission_co2)
                  scope.emission_ch4 = parseFloat(result.emission_ch4)
                  scope.emission_n2o = parseFloat(result.emission_n2o)
                  scope.factor_co2_kg = parseFloat(result.factor_co2_kg)
                  scope.factor_ch4_kg = parseFloat(result.factor_ch4_kg)
                  scope.factor_n2o_kg = parseFloat(result.factor_n2o_kg)
                  scope.emission_tco2e = parseFloat(result.emission_tco2e)
                  scope.fuel_amount_jan = parseFloat(result.fuel_amount_jan)
                  scope.fuel_amount_feb = parseFloat(result.fuel_amount_feb)
                  scope.fuel_amount_mar = parseFloat(result.fuel_amount_mar)
                  scope.fuel_amount_apr = parseFloat(result.fuel_amount_apr)
                  scope.fuel_amount_may = parseFloat(result.fuel_amount_may)
                  scope.fuel_amount_jun = parseFloat(result.fuel_amount_jun)
                  scope.fuel_amount_jul = parseFloat(result.fuel_amount_jul)
                  scope.fuel_amount_aug = parseFloat(result.fuel_amount_aug)
                  scope.fuel_amount_sep = parseFloat(result.fuel_amount_sep)
                  scope.fuel_amount_oct = parseFloat(result.fuel_amount_oct)
                  scope.fuel_amount_nov = parseFloat(result.fuel_amount_nov)
                  scope.fuel_amount_dec = parseFloat(result.fuel_amount_dec)
                  scope.factor_co2_kg_bio = parseFloat(result.factor_co2_kg_bio)
                  scope.emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
                  scope.fuel_amount_yearly = parseFloat(result.fuel_amount_yearly)
                  scope.fuel_amount_bio_jan = parseFloat(result.fuel_amount_bio_jan)
                  scope.fuel_amount_bio_feb = parseFloat(result.fuel_amount_bio_feb)
                  scope.fuel_amount_bio_mar = parseFloat(result.fuel_amount_bio_mar)
                  scope.fuel_amount_bio_apr = parseFloat(result.fuel_amount_bio_apr)
                  scope.fuel_amount_bio_may = parseFloat(result.fuel_amount_bio_may)
                  scope.fuel_amount_bio_jun = parseFloat(result.fuel_amount_bio_jun)
                  scope.fuel_amount_bio_jul = parseFloat(result.fuel_amount_bio_jul)
                  scope.fuel_amount_bio_aug = parseFloat(result.fuel_amount_bio_aug)
                  scope.fuel_amount_bio_sep = parseFloat(result.fuel_amount_bio_sep)
                  scope.fuel_amount_bio_oct = parseFloat(result.fuel_amount_bio_oct)
                  scope.fuel_amount_bio_nov = parseFloat(result.fuel_amount_bio_nov)
                  scope.fuel_amount_bio_dec = parseFloat(result.fuel_amount_bio_dec)
                  scope.fuel_amount_bio_yearly = parseFloat(result.fuel_amount_bio_yearly)

                  itens.push(scope)
                }
              } else {
                errors.push({ id: i, label: carFleet })
              }
            } else {
              errors.push({ id: i, label: carFleet })
            }
          }
        }

        setScopeVehicle(`import_scope_data_${data?.length}`);

        setVehiclesValues(itens)
        calculateVehicles(itens)

        if (errors && errors.length > 0) {
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data) {
      importData()
    }
  }

  const handleImportFuel = (type?: number, data?: any[]) => {
    async function importData() {
      if (data) {
        setLoadingModal(true)

        const errors: any[] = []
        const itens = vehicleFuels

        for (let i = 1; i < data?.length; i++) {
          const vFuel = data[i][2]

          if (vFuel) {
            let obj: any = undefined

            for (const t of fuels) {
              if (t.label === vFuel) {
                obj = t
                break;
              }
            }

            if (obj) {
              const fuelObj = await vehicleFuelService.getById(obj.value)

              if (fuelObj) {
                const fuel = fuelObj.fuel
                const bioFuel = fuelObj.bioFuel

                const register = data[i][0]
                const address = data[i][1]
                const year = data[i][3]
                const average_consumption_fuel = data[i][4]
                const admission = data[i][5]
                const resignation = data[i][6]
                let months: any = {}

                if (admission && resignation) {
                  months = await utilsService.getDifferenceDaysMonth(
                    { start_date: admission, end_date: resignation, year: inventorySelected.year }
                  )
                } else if (admission) {
                  months = await utilsService.getDifferenceDaysMonth(
                    { start_date: admission, year: inventorySelected.year }
                  )
                } else if (resignation) {
                  months = await utilsService.getDifferenceDaysMonth(
                    { end_date: resignation, year: inventorySelected.year }
                  )
                } else {
                  months = await utilsService.getDifferenceDaysMonth({ year: inventorySelected.year })
                }

                const scope = {
                  register: register, description: address, year: year, vehicle_fuel_id: obj.value,
                  vehicle_fuel_name: obj.label, fuel_id: fuel ? fuel.id : undefined,
                  fuel_name: fuel ? fuel.name : undefined, bio_fuel_id: bioFuel ? bioFuel.id : undefined,
                  bio_fuel_name: bioFuel ? bioFuel.name : undefined, worked_days_jan: months.jan,
                  worked_days_feb: months.feb, worked_days_mar: months.mar, worked_days_apr: months.apr,
                  worked_days_may: months.may, worked_days_jun: months.jun, worked_days_jul: months.jul,
                  worked_days_aug: months.aug, worked_days_sep: months.sep, worked_days_oct: months.oct,
                  worked_days_nov: months.nov, worked_days_dec: months.dec, worked_days_yearly: 0,
                  average_consumption_fuel: average_consumption_fuel, emission_co2: 0, emission_ch4: 0,
                  emission_n2o: 0, factor_co2_kg: 0, factor_ch4_kg: 0, factor_n2o_kg: 0, emission_tco2e: 0,
                  fuel_amount_jan: 0, fuel_amount_feb: 0, fuel_amount_mar: 0, fuel_amount_apr: 0, fuel_amount_may: 0,
                  fuel_amount_jun: 0, fuel_amount_jul: 0, fuel_amount_aug: 0, fuel_amount_sep: 0, fuel_amount_oct: 0,
                  fuel_amount_nov: 0, fuel_amount_dec: 0, factor_co2_kg_bio: 0, emission_tco2e_bio: 0, fuel_amount_yearly: 0,
                  fuel_amount_bio_jan: 0, fuel_amount_bio_feb: 0, fuel_amount_bio_mar: 0, fuel_amount_bio_apr: 0,
                  fuel_amount_bio_may: 0, fuel_amount_bio_jun: 0, fuel_amount_bio_jul: 0, fuel_amount_bio_aug: 0,
                  fuel_amount_bio_sep: 0, fuel_amount_bio_oct: 0, fuel_amount_bio_nov: 0, fuel_amount_bio_dec: 0,
                  fuel_amount_bio_yearly: 0, only_view_yearly: true, only_view_monthly: false
                }

                const result = await scope3Service.calculateVehicleFuel(scope.year, scope.vehicle_fuel_id,
                  scope.worked_days_jan, scope.worked_days_feb, scope.worked_days_mar, scope.worked_days_apr,
                  scope.worked_days_may, scope.worked_days_jun, scope.worked_days_jul, scope.worked_days_aug,
                  scope.worked_days_sep, scope.worked_days_oct, scope.worked_days_nov, scope.worked_days_dec,
                  scope.worked_days_yearly, scope.average_consumption_fuel
                )

                if (result) {
                  scope.emission_co2 = parseFloat(result.emission_co2)
                  scope.emission_ch4 = parseFloat(result.emission_ch4)
                  scope.emission_n2o = parseFloat(result.emission_n2o)
                  scope.factor_co2_kg = parseFloat(result.factor_co2_kg)
                  scope.factor_ch4_kg = parseFloat(result.factor_ch4_kg)
                  scope.factor_n2o_kg = parseFloat(result.factor_n2o_kg)
                  scope.emission_tco2e = parseFloat(result.emission_tco2e)
                  scope.fuel_amount_jan = parseFloat(result.fuel_amount_jan)
                  scope.fuel_amount_feb = parseFloat(result.fuel_amount_feb)
                  scope.fuel_amount_mar = parseFloat(result.fuel_amount_mar)
                  scope.fuel_amount_apr = parseFloat(result.fuel_amount_apr)
                  scope.fuel_amount_may = parseFloat(result.fuel_amount_may)
                  scope.fuel_amount_jun = parseFloat(result.fuel_amount_jun)
                  scope.fuel_amount_jul = parseFloat(result.fuel_amount_jul)
                  scope.fuel_amount_aug = parseFloat(result.fuel_amount_aug)
                  scope.fuel_amount_sep = parseFloat(result.fuel_amount_sep)
                  scope.fuel_amount_oct = parseFloat(result.fuel_amount_oct)
                  scope.fuel_amount_nov = parseFloat(result.fuel_amount_nov)
                  scope.fuel_amount_dec = parseFloat(result.fuel_amount_dec)
                  scope.factor_co2_kg_bio = parseFloat(result.factor_co2_kg_bio)
                  scope.emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
                  scope.fuel_amount_yearly = parseFloat(result.fuel_amount_yearly)
                  scope.fuel_amount_bio_jan = parseFloat(result.fuel_amount_bio_jan)
                  scope.fuel_amount_bio_feb = parseFloat(result.fuel_amount_bio_feb)
                  scope.fuel_amount_bio_mar = parseFloat(result.fuel_amount_bio_mar)
                  scope.fuel_amount_bio_apr = parseFloat(result.fuel_amount_bio_apr)
                  scope.fuel_amount_bio_may = parseFloat(result.fuel_amount_bio_may)
                  scope.fuel_amount_bio_jun = parseFloat(result.fuel_amount_bio_jun)
                  scope.fuel_amount_bio_jul = parseFloat(result.fuel_amount_bio_jul)
                  scope.fuel_amount_bio_aug = parseFloat(result.fuel_amount_bio_aug)
                  scope.fuel_amount_bio_sep = parseFloat(result.fuel_amount_bio_sep)
                  scope.fuel_amount_bio_oct = parseFloat(result.fuel_amount_bio_oct)
                  scope.fuel_amount_bio_nov = parseFloat(result.fuel_amount_bio_nov)
                  scope.fuel_amount_bio_dec = parseFloat(result.fuel_amount_bio_dec)
                  scope.fuel_amount_bio_yearly = parseFloat(result.fuel_amount_bio_yearly)

                  itens.push(scope)
                }
              } else {
                errors.push({ id: i, label: vFuel })
              }
            } else {
              errors.push({ id: i, label: vFuel })
            }
          }
        }

        setScopeVehicleFuel(`import_scope_data_${data?.length}`);

        setVehicleFuelsValues(itens)
        calculateVehicleFuels(itens)

        if (errors && errors.length > 0) {
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data) {
      importData()
    }
  }

  const handleOpenModalError = (data?: any) => {
    setImportErrors(data)
    setOpenModalError(!openModalError)
  }
  // IMPORTAÇÃO DO ARQUIVO
  const handleImportDistance = (type?: number, data?: any[]) => {
    async function importData() {
      if (data) {
        setLoadingModal(true)

        const errors: any[] = []
        const itens = vehicleDistances

        for (let i = 1; i < data?.length; i++) {
          const carFleet = data[i][2]

          if (carFleet) {
            let obj: any = undefined

            for (const t of carFleets) {
              if (t.label === carFleet) {
                obj = t
                break;
              }
            }

            if (obj) {
              const carObj = await carFleetService.getById(obj.value)

              if (carObj) {
                const fuel = carObj.fuel
                const bioFuel = carObj.bioFuel

                const register = data[i][0]
                const address = data[i][1]
                const year = data[i][3]
                const admission = data[i][4]
                const resignation = data[i][5]
                let worked_days_yearly = 0

                if (admission && resignation){
                  const call = await utilsService.getDifferenceDays(
                    { start_date: admission, end_date: resignation, year: inventorySelected.year }
                  )

                  worked_days_yearly = call.result
                } else if (admission){
                  const call = await utilsService.getDifferenceDays(
                    { start_date: admission, year: inventorySelected.year }
                  )

                  worked_days_yearly = call.result
                } else if (resignation){
                  const call = await utilsService.getDifferenceDays(
                    { end_date: resignation, year: inventorySelected.year }
                  )

                  worked_days_yearly = call.result
                } else {
                  const call = await utilsService.getDifferenceDays(
                    { year: inventorySelected.year }
                  )

                  worked_days_yearly = call.result
                }

                const scope = {
                  register: register,
                  description: address,
                  year: year,
                  car_fleet_id: obj.value,
                  car_fleet_name: obj.label,
                  fuel_id: fuel ? fuel.id : undefined,
                  fuel_name: fuel ? fuel.name : undefined,
                  bio_fuel_id: bioFuel ? bioFuel.id : undefined,
                  bio_fuel_name: bioFuel ? bioFuel.name : undefined,
                  average_distance : 0,
                  worked_days_jan: '',
                  worked_days_feb: '', 
                  worked_days_mar: '', 
                  worked_days_apr: '',
                  worked_days_may: '', 
                  worked_days_jun: '', 
                  worked_days_jul: '', 
                  worked_days_aug: '',
                  worked_days_sep: '', 
                  worked_days_oct: '', 
                  worked_days_nov: '', 
                  worked_days_dec: '',
                  worked_days_yearly: worked_days_yearly, 
                  emission_co2: 0, 
                  emission_ch4: 0, 
                  emission_n2o: 0,
                  factor_co2_kg: 0, 
                  factor_ch4_kg: 0, 
                  factor_n2o_kg: 0, 
                  emission_tco2e: 0,
                  fuel_amount_jan: 0, 
                  fuel_amount_feb: 0, 
                  fuel_amount_mar: 0, 
                  fuel_amount_apr: 0, 
                  fuel_amount_may: 0,
                  fuel_amount_jun: 0, 
                  fuel_amount_jul: 0, 
                  fuel_amount_aug: 0, 
                  fuel_amount_sep: 0, 
                  fuel_amount_oct: 0,
                  fuel_amount_nov: 0, 
                  fuel_amount_dec: 0, 
                  factor_co2_kg_bio: 0, 
                  emission_tco2e_bio: 0, 
                  fuel_amount_yearly: 0,
                  fuel_amount_bio_jan: 0, 
                  fuel_amount_bio_feb: 0, 
                  fuel_amount_bio_mar: 0, 
                  fuel_amount_bio_apr: 0,
                  fuel_amount_bio_may: 0, 
                  fuel_amount_bio_jun: 0, 
                  fuel_amount_bio_jul: 0, 
                  fuel_amount_bio_aug: 0,
                  fuel_amount_bio_sep: 0, 
                  fuel_amount_bio_oct: 0, 
                  fuel_amount_bio_nov: 0, 
                  fuel_amount_bio_dec: 0,
                  fuel_amount_bio_yearly: 0, 
                  fuel_amount_fossil_jan: 0, 
                  fuel_amount_fossil_feb: 0, 
                  fuel_amount_fossil_mar: 0,
                  fuel_amount_fossil_apr: 0, 
                  fuel_amount_fossil_may: 0, 
                  fuel_amount_fossil_jun: 0, 
                  fuel_amount_fossil_jul: 0,
                  fuel_amount_fossil_aug: 0, 
                  fuel_amount_fossil_sep: 0, 
                  fuel_amount_fossil_oct: 0, 
                  fuel_amount_fossil_nov: 0,
                  fuel_amount_fossil_dec: 0, 
                  fuel_amount_fossil_yearly: 0, 
                  average_consumption_fuel: 0,
                  only_view_yearly: false, 
                  only_view_monthly: true
                }

                const result = await scope3Service.calculateVehicleDistance(scope.year, scope.car_fleet_id,
                  inventorySelected.company.id, address, scope.worked_days_jan, scope.worked_days_feb,
                  scope.worked_days_mar, scope.worked_days_apr, scope.worked_days_may, scope.worked_days_jun,
                  scope.worked_days_jul, scope.worked_days_aug, scope.worked_days_sep, scope.worked_days_oct,
                  scope.worked_days_nov, scope.worked_days_dec, scope.worked_days_yearly, scope.average_distance
                )

                if (result) {
                  scope.emission_co2 = parseFloat(result.emission_co2)
                  scope.emission_ch4 = parseFloat(result.emission_ch4)
                  scope.emission_n2o = parseFloat(result.emission_n2o)
                  scope.factor_co2_kg = parseFloat(result.factor_co2_kg)
                  scope.factor_ch4_kg = parseFloat(result.factor_ch4_kg)
                  scope.factor_n2o_kg = parseFloat(result.factor_n2o_kg)
                  scope.emission_tco2e = parseFloat(result.emission_tco2e)
                  scope.fuel_amount_jan = parseFloat(result.fuel_amount_jan)
                  scope.fuel_amount_feb = parseFloat(result.fuel_amount_feb)
                  scope.fuel_amount_mar = parseFloat(result.fuel_amount_mar)
                  scope.fuel_amount_apr = parseFloat(result.fuel_amount_apr)
                  scope.fuel_amount_may = parseFloat(result.fuel_amount_may)
                  scope.fuel_amount_jun = parseFloat(result.fuel_amount_jun)
                  scope.fuel_amount_jul = parseFloat(result.fuel_amount_jul)
                  scope.fuel_amount_aug = parseFloat(result.fuel_amount_aug)
                  scope.fuel_amount_sep = parseFloat(result.fuel_amount_sep)
                  scope.fuel_amount_oct = parseFloat(result.fuel_amount_oct)
                  scope.fuel_amount_nov = parseFloat(result.fuel_amount_nov)
                  scope.fuel_amount_dec = parseFloat(result.fuel_amount_dec)
                  scope.average_distance = parseFloat(result.average_distance)
                  scope.factor_co2_kg_bio = parseFloat(result.factor_co2_kg_bio)
                  scope.emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
                  scope.fuel_amount_yearly = parseFloat(result.fuel_amount_yearly)
                  scope.fuel_amount_bio_jan = parseFloat(result.fuel_amount_bio_jan)
                  scope.fuel_amount_bio_feb = parseFloat(result.fuel_amount_bio_feb)
                  scope.fuel_amount_bio_mar = parseFloat(result.fuel_amount_bio_mar)
                  scope.fuel_amount_bio_apr = parseFloat(result.fuel_amount_bio_apr)
                  scope.fuel_amount_bio_may = parseFloat(result.fuel_amount_bio_may)
                  scope.fuel_amount_bio_jun = parseFloat(result.fuel_amount_bio_jun)
                  scope.fuel_amount_bio_jul = parseFloat(result.fuel_amount_bio_jul)
                  scope.fuel_amount_bio_aug = parseFloat(result.fuel_amount_bio_aug)
                  scope.fuel_amount_bio_sep = parseFloat(result.fuel_amount_bio_sep)
                  scope.fuel_amount_bio_oct = parseFloat(result.fuel_amount_bio_oct)
                  scope.fuel_amount_bio_nov = parseFloat(result.fuel_amount_bio_nov)
                  scope.fuel_amount_bio_dec = parseFloat(result.fuel_amount_bio_dec)
                  scope.fuel_amount_bio_yearly = parseFloat(result.fuel_amount_bio_yearly)
                  scope.fuel_amount_fossil_jan = parseFloat(result.fuel_amount_fossil_jan)
                  scope.fuel_amount_fossil_feb = parseFloat(result.fuel_amount_fossil_feb)
                  scope.fuel_amount_fossil_mar = parseFloat(result.fuel_amount_fossil_mar)
                  scope.fuel_amount_fossil_apr = parseFloat(result.fuel_amount_fossil_apr)
                  scope.fuel_amount_fossil_may = parseFloat(result.fuel_amount_fossil_may)
                  scope.fuel_amount_fossil_jun = parseFloat(result.fuel_amount_fossil_jun)
                  scope.fuel_amount_fossil_jul = parseFloat(result.fuel_amount_fossil_jul)
                  scope.fuel_amount_fossil_aug = parseFloat(result.fuel_amount_fossil_aug)
                  scope.fuel_amount_fossil_sep = parseFloat(result.fuel_amount_fossil_sep)
                  scope.fuel_amount_fossil_oct = parseFloat(result.fuel_amount_fossil_oct)
                  scope.fuel_amount_fossil_nov = parseFloat(result.fuel_amount_fossil_nov)
                  scope.fuel_amount_fossil_dec = parseFloat(result.fuel_amount_fossil_dec)
                  scope.average_consumption_fuel = parseFloat(result.average_consumption_fuel)
                  scope.fuel_amount_fossil_yearly = parseFloat(result.fuel_amount_fossil_yearly)

                  itens.push(scope)
                }
              } else {
                errors.push({ id: i, label: carFleet })
              }
            } else {
              errors.push({ id: i, label: carFleet })
            }
          }
        }

        setScopeVehicleDistance(`import_scope_data_${data?.length}`);

        setVehicleDistancesValues(itens)
        calculateVehicleDistances(itens)

        if (errors && errors.length > 0) {
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data) {
      importData()
    }
  }

  const handleImportDistanceManual = (type?: number, data?: any[]) => {
    async function importData() {
      if (data) {
        setLoadingModal(true)

        const errors: any[] = []
        const itens = vehicleDistancesManual

        for (let i = 1; i < data?.length; i++) {
          const carFleet = data[i][2]

          if (carFleet) {
            let obj: any = undefined

            for (const t of carFleets) {
              if (t.label === carFleet) {
                obj = t
                break;
              }
            }

            if (obj) {
              const carObj = await carFleetService.getById(obj.value)

              if (carObj) {
                const fuel = carObj.fuel
                const bioFuel = carObj.bioFuel

                const register = data[i][0]
                const average_distance = data[i][1]
                const year = data[i][3]
                const admission = data[i][4]
                const resignation = data[i][5]
                let worked_days_yearly = 0

                if (admission && resignation){
                  const call = await utilsService.getDifferenceDays(
                    { start_date: admission, end_date: resignation, year: inventorySelected.year }
                  )

                  worked_days_yearly = call.result
                } else if (admission){
                  const call = await utilsService.getDifferenceDays(
                    { start_date: admission, year: inventorySelected.year }
                  )

                  worked_days_yearly = call.result
                } else if (resignation){
                  const call = await utilsService.getDifferenceDays(
                    { end_date: resignation, year: inventorySelected.year }
                  )

                  worked_days_yearly = call.result
                } else {
                  const call = await utilsService.getDifferenceDays(
                    { year: inventorySelected.year }
                  )

                  worked_days_yearly = call.result
                }

                const scope = {
                  average_distance: average_distance,
                  register: register,
                  year: year,
                  car_fleet_id: obj.value,
                  car_fleet_name: obj.label,
                  fuel_id: fuel ? fuel.id : undefined,
                  fuel_name: fuel ? fuel.name : undefined,
                  bio_fuel_id: bioFuel ? bioFuel.id : undefined,
                  bio_fuel_name: bioFuel ? bioFuel.name : undefined,
                  worked_days_jan: '',
                  worked_days_feb: '', 
                  worked_days_mar: '', 
                  worked_days_apr: '',
                  worked_days_may: '', 
                  worked_days_jun: '', 
                  worked_days_jul: '', 
                  worked_days_aug: '',
                  worked_days_sep: '', 
                  worked_days_oct: '', 
                  worked_days_nov: '', 
                  worked_days_dec: '',
                  worked_days_yearly: worked_days_yearly, 
                  emission_co2: 0, 
                  emission_ch4: 0, 
                  emission_n2o: 0,
                  factor_co2_kg: 0, 
                  factor_ch4_kg: 0, 
                  factor_n2o_kg: 0, 
                  emission_tco2e: 0,
                  fuel_amount_jan: 0, 
                  fuel_amount_feb: 0, 
                  fuel_amount_mar: 0, 
                  fuel_amount_apr: 0, 
                  fuel_amount_may: 0,
                  fuel_amount_jun: 0, 
                  fuel_amount_jul: 0, 
                  fuel_amount_aug: 0, 
                  fuel_amount_sep: 0, 
                  fuel_amount_oct: 0,
                  fuel_amount_nov: 0, 
                  fuel_amount_dec: 0, 
                  factor_co2_kg_bio: 0, 
                  emission_tco2e_bio: 0, 
                  fuel_amount_yearly: 0,
                  fuel_amount_bio_jan: 0, 
                  fuel_amount_bio_feb: 0, 
                  fuel_amount_bio_mar: 0, 
                  fuel_amount_bio_apr: 0,
                  fuel_amount_bio_may: 0, 
                  fuel_amount_bio_jun: 0, 
                  fuel_amount_bio_jul: 0, 
                  fuel_amount_bio_aug: 0,
                  fuel_amount_bio_sep: 0, 
                  fuel_amount_bio_oct: 0, 
                  fuel_amount_bio_nov: 0, 
                  fuel_amount_bio_dec: 0,
                  fuel_amount_bio_yearly: 0, 
                  fuel_amount_fossil_jan: 0, 
                  fuel_amount_fossil_feb: 0, 
                  fuel_amount_fossil_mar: 0,
                  fuel_amount_fossil_apr: 0, 
                  fuel_amount_fossil_may: 0, 
                  fuel_amount_fossil_jun: 0, 
                  fuel_amount_fossil_jul: 0,
                  fuel_amount_fossil_aug: 0, 
                  fuel_amount_fossil_sep: 0, 
                  fuel_amount_fossil_oct: 0, 
                  fuel_amount_fossil_nov: 0,
                  fuel_amount_fossil_dec: 0, 
                  fuel_amount_fossil_yearly: 0, 
                  average_consumption_fuel: 0,
                  only_view_yearly: false, 
                  only_view_monthly: true
                }

                const result = await scope3Service.calculateVehicleDistanceManual(scope.year, scope.car_fleet_id,
                  inventorySelected.company.id, scope.worked_days_jan, scope.worked_days_feb,
                  scope.worked_days_mar, scope.worked_days_apr, scope.worked_days_may, scope.worked_days_jun,
                  scope.worked_days_jul, scope.worked_days_aug, scope.worked_days_sep, scope.worked_days_oct,
                  scope.worked_days_nov, scope.worked_days_dec, scope.worked_days_yearly, scope.average_distance
                )

                if (result) {
                  scope.emission_co2 = parseFloat(result.emission_co2)
                  scope.emission_ch4 = parseFloat(result.emission_ch4)
                  scope.emission_n2o = parseFloat(result.emission_n2o)
                  scope.factor_co2_kg = parseFloat(result.factor_co2_kg)
                  scope.factor_ch4_kg = parseFloat(result.factor_ch4_kg)
                  scope.factor_n2o_kg = parseFloat(result.factor_n2o_kg)
                  scope.emission_tco2e = parseFloat(result.emission_tco2e)
                  scope.fuel_amount_jan = parseFloat(result.fuel_amount_jan)
                  scope.fuel_amount_feb = parseFloat(result.fuel_amount_feb)
                  scope.fuel_amount_mar = parseFloat(result.fuel_amount_mar)
                  scope.fuel_amount_apr = parseFloat(result.fuel_amount_apr)
                  scope.fuel_amount_may = parseFloat(result.fuel_amount_may)
                  scope.fuel_amount_jun = parseFloat(result.fuel_amount_jun)
                  scope.fuel_amount_jul = parseFloat(result.fuel_amount_jul)
                  scope.fuel_amount_aug = parseFloat(result.fuel_amount_aug)
                  scope.fuel_amount_sep = parseFloat(result.fuel_amount_sep)
                  scope.fuel_amount_oct = parseFloat(result.fuel_amount_oct)
                  scope.fuel_amount_nov = parseFloat(result.fuel_amount_nov)
                  scope.fuel_amount_dec = parseFloat(result.fuel_amount_dec)
                  // scope.average_distance = parseFloat(result.average_distance)
                  scope.factor_co2_kg_bio = parseFloat(result.factor_co2_kg_bio)
                  scope.emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
                  scope.fuel_amount_yearly = parseFloat(result.fuel_amount_yearly)
                  scope.fuel_amount_bio_jan = parseFloat(result.fuel_amount_bio_jan)
                  scope.fuel_amount_bio_feb = parseFloat(result.fuel_amount_bio_feb)
                  scope.fuel_amount_bio_mar = parseFloat(result.fuel_amount_bio_mar)
                  scope.fuel_amount_bio_apr = parseFloat(result.fuel_amount_bio_apr)
                  scope.fuel_amount_bio_may = parseFloat(result.fuel_amount_bio_may)
                  scope.fuel_amount_bio_jun = parseFloat(result.fuel_amount_bio_jun)
                  scope.fuel_amount_bio_jul = parseFloat(result.fuel_amount_bio_jul)
                  scope.fuel_amount_bio_aug = parseFloat(result.fuel_amount_bio_aug)
                  scope.fuel_amount_bio_sep = parseFloat(result.fuel_amount_bio_sep)
                  scope.fuel_amount_bio_oct = parseFloat(result.fuel_amount_bio_oct)
                  scope.fuel_amount_bio_nov = parseFloat(result.fuel_amount_bio_nov)
                  scope.fuel_amount_bio_dec = parseFloat(result.fuel_amount_bio_dec)
                  scope.fuel_amount_bio_yearly = parseFloat(result.fuel_amount_bio_yearly)
                  scope.fuel_amount_fossil_jan = parseFloat(result.fuel_amount_fossil_jan)
                  scope.fuel_amount_fossil_feb = parseFloat(result.fuel_amount_fossil_feb)
                  scope.fuel_amount_fossil_mar = parseFloat(result.fuel_amount_fossil_mar)
                  scope.fuel_amount_fossil_apr = parseFloat(result.fuel_amount_fossil_apr)
                  scope.fuel_amount_fossil_may = parseFloat(result.fuel_amount_fossil_may)
                  scope.fuel_amount_fossil_jun = parseFloat(result.fuel_amount_fossil_jun)
                  scope.fuel_amount_fossil_jul = parseFloat(result.fuel_amount_fossil_jul)
                  scope.fuel_amount_fossil_aug = parseFloat(result.fuel_amount_fossil_aug)
                  scope.fuel_amount_fossil_sep = parseFloat(result.fuel_amount_fossil_sep)
                  scope.fuel_amount_fossil_oct = parseFloat(result.fuel_amount_fossil_oct)
                  scope.fuel_amount_fossil_nov = parseFloat(result.fuel_amount_fossil_nov)
                  scope.fuel_amount_fossil_dec = parseFloat(result.fuel_amount_fossil_dec)
                  scope.average_consumption_fuel = parseFloat(result.average_consumption_fuel)
                  scope.fuel_amount_fossil_yearly = parseFloat(result.fuel_amount_fossil_yearly)

                  itens.push(scope)
                }
              } else {
                errors.push({ id: i, label: carFleet })
              }
            } else {
              errors.push({ id: i, label: carFleet })
            }
          }
        }

        setScopeVehicleDistanceManual(`import_scope_data_${data?.length}`);

        setVehicleDistancesValuesManual(itens)
        calculateVehicleDistancesManual(itens)

        if (errors && errors.length > 0) {
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data) {
      importData()
    }
  }

  function moeda(a: string, e: string, r: string): string {
    // Remove caracteres não numéricos
    let n = a.replace(/[^\d]+/g, '');

    if (n.length === 0) return '0' + r + '00000';

    while (n.length < 5) {  
        n = '0' + n;
    }

    const len = n.length;
    const intPart = n.substring(0, len - 4);
    const decPart = n.substring(len - 4);

    // Remove zeros desnecessários da parte inteira
    const cleanedIntPart = intPart.replace(/^0+/, '') || '0';

    let formattedValue = cleanedIntPart.replace(/\B(?=(\d{3})+(?!\d))/g, e);
    formattedValue += r + decPart;

    return formattedValue;
  }

  const handleImportData = (data?: any, option_table?: string) => {
    const item: any = location?.state;

    const importSpreadsheet = async () => {
      if (data){
        setLoadingModal(true);
        const formData = new FormData();
        formData.append("file", data);
        let data_imported: number = 0;
        let data_not_imported: number = 0;
        let has_error: any;

        try {
          const response = await scope3Service.importDataSpreadsheetPrivateVehicle(item.company_id, item.year, formData, option_table || "")
            .then(response => {
              
              data_imported = response && response[0]?.data_imported ? response[0].data_imported?.length : 0;
              data_not_imported = response && response[0]?.data_not_imported ? response[0].data_not_imported?.length : 0;
              if (response?.error) {
                return { error: response?.error }
              } else if (response[0]?.has_error?.length > 0) {
                has_error = response[0].has_error;
              }
            })
            .finally(() => {
              setLoadingModal(false);
            });

          history.goBack();
          return has_error ? { error: has_error } :{ message: `Operação realizada com sucesso! Foram importados ${data_imported}`, data_imported: data_imported, data_not_imported: data_not_imported }

        } catch (error: any) {
          console.error(error);
          toast.error(error);
          return { error: error };
        }
      } else {
        toast.error("ERRO: Nenhum arquivo selecionado!");
      }
    };
    if(data) {
      importSpreadsheet().then((response) => {
        if(response?.error) {
          toast.error(response.error)
        } else if (response?.message) {
          toast.success(response.message)
        }
      })
    };
  };

  const handleExport = async (option_table?: string) => {
    const item: any = location?.state;
  
    if (!option_table) {
      toast.error("ERRO: Nenhum arquivo baixado!");
      return;
    }
  
    setLoadingModal(true);
    try {
      const response = await scope3Service.exportDataHomeWorkDisplacementPrivateVehicle(
        item.company_id,
        item.year,
        option_table
      );
  
      if (response && response instanceof Blob) {
        // Criar URL para download do arquivo
        const url = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${option_table}.xlsx`); // Nome do arquivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        toast.success("Arquivo exportado com sucesso.");
      } else {
        toast.error("Erro ao processar o arquivo.");
      }
    } catch (error) {
      console.error("Erro ao exportar dados:", error);
      toast.error("Erro ao exportar dados. Verifique os logs.");
    } finally {
      setLoadingModal(false);
    }
  };

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year + ' - ' + inventorySelected?.company?.corporate_name} />
                <TitleText level={4} title={'Escopo 03 - ' + title} />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
          {guidelines && (
              <Button
                color="blue"
                size="md"
                type="button"
                onClick={handleOpenInfo}
                style={{ margin: "auto 10px auto auto" }}
              >
                Orientações
              </Button>
            )}

            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill === true && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 230px)',
            maxHeight: 'calc(100vh - 230px)'
          }}
        >
          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{ display: 'flex', flexDirection: 'column', marginRight: 30 }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5, marginLeft: 10, fontWeight: 'bold' }} >
                    Opção 1 - Cálculo de emissões por transporte de funcionários para deslocamento
                    casa-trabalho realizadas em veículos particulares, com base no tipo e
                    ano da frota
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, marginLeft: 10, }} >
                    Selecione, o tipo de transporte ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && selectedYear && filteredFleet.length > 0 && (
                  <div
                    style={{
                      marginTop: 15,
                      marginRight: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <Controller
                      control={control}
                      name="car_freet_private_vehicle"
                      render={({ field }) => (
                        <Select placeholder="[Selecione]"
                          {...field}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: '350px',
                              height: 40,
                              backgroundColor: 'rgb(246, 246, 246)'
                            }),
                            option: (styles) => {
                              return { ...styles, color: '#31363F' }
                            }
                          }}
                          defaultValue={getValues('car_freet_private_vehicle')}
                          options={filteredFleet}
                          onChange={(selectedOption) => {
                          field.onChange(selectedOption); 
                          handleChangeCarFleet(selectedOption); 
                        }}
                        />
                      )}
                    />

                    <button
                      className="button-view"
                      style={{ height: 40, marginLeft: 5 }}
                      onClick={() => handleOpenModalImport1()}
                      type="button"
                    >
                      Importar Dados
                    </button>
                  </div>
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {scopeVehicle && vehicles && vehicles.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={2}>
                          {'Percurso'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={2}>
                          {'Frota de veiculos'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Consumo médio de combustível p/ dia'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={12}>
                          {'Dias trabalhados / mês'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Dias trabalhados no ano'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={2}>
                          {'Fator de Emissão (kg CO₂ / litro)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={2}>
                          {'Fatores de Emissão do combustível comercial'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CO₂ fóssil (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CH₄ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de N₂O (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões totais em CO₂e (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CO₂ biogênico (t CO₂)'}
                        </th>
                        {!isCommonUser && (
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          >
                            {!isCommonUser && (
                              <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                                <div onClick={handleOpenModalDeleteVehicle}>
                                  <button style={{background: '#b81414', color: 'white', borderRadius: 5, cursor:'pointer'}}>Apagar tudo</button>
                                </div>
                              </td>
                            )}
                          </th>
                        )}
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Registro do colaborador'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Descrição'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Tipo de frota'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Ano de frota'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Janeiro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Fevereiro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Março'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Abril'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Maio'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Junho'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Julho'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Agosto'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Setembro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Outubro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Novembro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Dezembro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Combustível fóssil'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Biocombustível'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CH₄ / litro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg N₂O / litro'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {vehicles.map((vehicle: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`vehicles.${index}.register`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `vehicles.${index}.register`,
                                      event.target.value
                                    )
                                    vehicles[index].register = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`vehicles.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `vehicles.${index}.description`,
                                      event.target.value
                                    )
                                    vehicles[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 200,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    display: 'inline-block'
                                  }}
                                >
                                  {vehicles[index].car_fleet_name}
                                </span>
                                <br />
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 300,
                                    fontSize: 12,
                                    display: 'inline-block'
                                  }}
                                >
                                  <b>{'Combustível fóssil: '}</b>
                                  {vehicles[index].fuel_name}
                                </span>
                                <br />
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 300,
                                    fontSize: 12,
                                    display: 'inline-block'
                                  }}
                                >
                                  <b>{'Biocombustível: '}</b>
                                  {vehicles[index].bio_fuel_name}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`vehicles.${index}.year`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    vehicles[index].year = numberValue

                                    setValue(`vehicles.${index}.year`, numberValue)

                                    handleChangeVehicle(index, 'year')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 150, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(
                                    `vehicles.${index}.average_consumption_fuel`,
                                    { required: true }
                                  )}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr? parseFloat(valueStr.replace(',', '.')): 0

                                    vehicles[index].average_consumption_fuel = value

                                    setValue(`vehicles.${index}.average_consumption_fuel`,formatValue)

                                    handleChangeVehicle(index, 'average_consumption_fuel')
                                  }}
                                />
                              </td>
                              <td>
                                {vehicles[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`vehicles.${index}.worked_days_jan`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicles[index].worked_days_jan = numberValue

                                      setValue(
                                        `vehicles.${index}.worked_days_jan`,
                                        numberValue
                                      )

                                      handleChangeVehicle(index, 'worked_days_jan')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicles[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`vehicles.${index}.worked_days_feb`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicles[index].worked_days_feb = numberValue

                                      setValue(
                                        `vehicles.${index}.worked_days_feb`,
                                        numberValue
                                      )

                                      handleChangeVehicle(index, 'worked_days_feb')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicles[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`vehicles.${index}.worked_days_mar`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicles[index].worked_days_mar = numberValue

                                      setValue(
                                        `vehicles.${index}.worked_days_mar`,
                                        numberValue
                                      )

                                      handleChangeVehicle(index, 'worked_days_mar')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicles[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`vehicles.${index}.worked_days_apr`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicles[index].worked_days_apr = numberValue

                                      setValue(
                                        `vehicles.${index}.worked_days_apr`,
                                        numberValue
                                      )

                                      handleChangeVehicle(index, 'worked_days_apr')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicles[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`vehicles.${index}.worked_days_may`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicles[index].worked_days_may = numberValue

                                      setValue(
                                        `vehicles.${index}.worked_days_may`,
                                        numberValue
                                      )

                                      handleChangeVehicle(index, 'worked_days_may')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicles[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`vehicles.${index}.worked_days_jun`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicles[index].worked_days_jun = numberValue

                                      setValue(
                                        `vehicles.${index}.worked_days_jun`,
                                        numberValue
                                      )

                                      handleChangeVehicle(index, 'worked_days_jun')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicles[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`vehicles.${index}.worked_days_jul`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicles[index].worked_days_jul = numberValue

                                      setValue(
                                        `vehicles.${index}.worked_days_jul`,
                                        numberValue
                                      )

                                      handleChangeVehicle(index, 'worked_days_jul')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicles[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`vehicles.${index}.worked_days_aug`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicles[index].worked_days_aug = numberValue

                                      setValue(
                                        `vehicles.${index}.worked_days_aug`,
                                        numberValue
                                      )

                                      handleChangeVehicle(index, 'worked_days_aug')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicles[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`vehicles.${index}.worked_days_sep`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicles[index].worked_days_sep = numberValue

                                      setValue(
                                        `vehicles.${index}.worked_days_sep`,
                                        numberValue
                                      )

                                      handleChangeVehicle(index, 'worked_days_sep')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicles[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`vehicles.${index}.worked_days_oct`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicles[index].worked_days_oct = numberValue

                                      setValue(
                                        `vehicles.${index}.worked_days_oct`,
                                        numberValue
                                      )

                                      handleChangeVehicle(index, 'worked_days_oct')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicles[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`vehicles.${index}.worked_days_nov`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicles[index].worked_days_nov = numberValue

                                      setValue(
                                        `vehicles.${index}.worked_days_nov`,
                                        numberValue
                                      )

                                      handleChangeVehicle(index, 'worked_days_nov')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicles[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`vehicles.${index}.worked_days_dec`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicles[index].worked_days_dec = numberValue

                                      setValue(
                                        `vehicles.${index}.worked_days_dec`,
                                        numberValue
                                      )

                                      handleChangeVehicle(index, 'worked_days_dec')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicles[index].only_view_yearly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(
                                      `vehicles.${index}.worked_days_yearly`
                                    )}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicles[index].worked_days_yearly = numberValue

                                      setValue(
                                        `vehicles.${index}.worked_days_yearly`,
                                        numberValue
                                      )

                                      handleChangeVehicle(
                                        index,
                                        'worked_days_yearly'
                                      )
                                    }}
                                  />
                                )}
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(vehicles[index].factor_co2_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(vehicles[index].factor_co2_kg_bio, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(vehicles[index].factor_ch4_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(vehicles[index].factor_n2o_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(vehicles[index].emission_co2, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(vehicles[index].emission_ch4, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(vehicles[index].emission_n2o, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(vehicles[index].emission_tco2e, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(
                                    vehicles[index].emission_tco2e_bio,
                                    false
                                  )}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }} rowSpan={3}>
                                  <div
                                    onClick={() => handleDeleteVehicle(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="lixeirinha"/>
                                  </div>
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td
                                colSpan={5}
                                className="subhead-item"
                                style={{ paddingRight: 5, textAlign: 'right' }}
                              >
                                {'Quantidade de combustível fóssil (litros ou m³)'}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(vehicles[index].fuel_amount_jan, false)}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(vehicles[index].fuel_amount_feb, false)}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(vehicles[index].fuel_amount_mar, false)}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(vehicles[index].fuel_amount_apr, false)}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(vehicles[index].fuel_amount_may, false)}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(vehicles[index].fuel_amount_jun, false)}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(vehicles[index].fuel_amount_jul, false)}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(vehicles[index].fuel_amount_aug, false)}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(vehicles[index].fuel_amount_sep, false)}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(vehicles[index].fuel_amount_oct, false)}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(vehicles[index].fuel_amount_nov, false)}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(vehicles[index].fuel_amount_dec, false)}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_yearly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicles[index].fuel_amount_yearly,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={5}
                                className="subhead-item"
                                style={{ paddingRight: 5, textAlign: 'right' }}
                              >
                                {'Quantidade de biocombustível (litros)'}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicles[index].fuel_amount_bio_jan,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicles[index].fuel_amount_bio_feb,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicles[index].fuel_amount_bio_mar,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicles[index].fuel_amount_bio_apr,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicles[index].fuel_amount_bio_may,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicles[index].fuel_amount_bio_jun,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicles[index].fuel_amount_bio_jul,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicles[index].fuel_amount_bio_aug,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicles[index].fuel_amount_bio_sep,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicles[index].fuel_amount_bio_oct,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicles[index].fuel_amount_bio_nov,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicles[index].fuel_amount_bio_dec,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicles[index].only_view_yearly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicles[index].fuel_amount_bio_yearly,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={22}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionCo2Vehicles, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionCh4Vehicles, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionN2oVehicles, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionTco2eVehicles, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionTco2eBioVehicles, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {vehicles && vehicles.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum tipo de transporte cadastrado`
                        : `Nenhum tipo de transporte foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.train && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{ display: 'flex', flexDirection: 'column', marginRight: 30 }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5, marginLeft: 10, fontWeight: 'bold' }} >
                  Opção 2 -Cálculo de emissões por transporte de funcionários para deslocamento
                    casa-trabalho realizadas em veículos particulares com base no tipo de
                    combustível e consumo médio no ano
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, marginLeft: 10, }} >
                    Selecione, o tipo de combustível ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && selectedYear && filteredBioFleet.length > 0 && (
                  <div
                    style={{
                      marginTop: 15,
                      marginRight: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <Controller
                      control={control}
                      name="fuel_private_vehicle"
                      render={({ field }) => (
                        <Select placeholder="[Selecione]"
                          {...field}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: '350px',
                              height: 40,
                              backgroundColor: 'rgb(246, 246, 246)'
                            }),
                            option: (styles) => {
                              return { ...styles, color: '#31363F' }
                            }
                          }}
                          defaultValue={getValues('fuel_private_vehicle')}
                          options={filteredBioFleet}
                          onChange={(selectedOption) => {
                          field.onChange(selectedOption); 
                          handleChangeFuel(selectedOption); 
                        }}
                        />
                      )}
                    />

                    <button
                      className="button-view"
                      style={{ height: 40, marginLeft: 5 }}
                      onClick={() => handleOpenModalImport2()}
                      type="button"
                    >
                      Importar Dados
                    </button>
                  </div>
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {scopeVehicleFuel && vehicleFuels && vehicleFuels.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={2}>
                          {'Percurso'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Tipo de combustível'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Consumo médio de combustível p/ dia'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={12}>
                          {'Dias trabalhados / mês'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Dias trabalhados no ano'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={3}>
                          {'Fatores de Emissão do combustível fóssil'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={3}>
                          {'Fatores de Emissão do biocombustível'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CO₂ fóssil (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CH₄ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de N₂O (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões totais em CO₂e (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CO₂ biogênico (t CO₂)'}
                        </th>
                        {!isCommonUser && (
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          >
                            {!isCommonUser && (
                              <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                                <div onClick={handleOpenModalDeleteVehicleFuel}>
                                  <button style={{background: '#b81414', color: 'white', borderRadius: 5, cursor:'pointer'}}>Apagar tudo</button>
                                </div>
                              </td>
                            )}
                          </th>
                        )}
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Registro do colaborador'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Descrição'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Janeiro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Fevereiro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Março'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Abril'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Maio'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Junho'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Julho'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Agosto'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Setembro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Outubro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Novembro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Dezembro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CO₂ / litro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CH₄ / litro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg N₂O / litro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CO₂ / litro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CH₄ / litro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg N₂O / litro'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {vehicleFuels.map((fuel: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`fuel.${index}.register`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`fuel.${index}.register`, event.target.value)
                                    vehicleFuels[index].register = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`fuel.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `fuel.${index}.description`,
                                      event.target.value
                                    )
                                    vehicleFuels[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 200,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    display: 'inline-block'
                                  }}
                                >
                                  {vehicleFuels[index].vehicle_fuel_name}
                                </span>
                                <br />
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 300,
                                    fontSize: 12,
                                    display: 'inline-block'
                                  }}
                                >
                                  <b>{'Combustível fóssil: '}</b>
                                  {vehicleFuels[index].fuel_name}
                                </span>
                                <br />
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 300,
                                    fontSize: 12,
                                    display: 'inline-block'
                                  }}
                                >
                                  <b>{'Biocombustível: '}</b>
                                  {vehicleFuels[index].bio_fuel_name}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 150, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`fuel.${index}.average_consumption_fuel`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    vehicleFuels[index].average_consumption_fuel = value

                                    setValue(
                                      `fuel.${index}.average_consumption_fuel`,
                                      formatValue
                                    )

                                    handleChangeVehicleFuel(
                                      index,
                                      'average_consumption_fuel'
                                    )
                                  }}
                                />
                              </td>
                              <td>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`fuel.${index}.worked_days_jan`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleFuels[index].worked_days_jan = numberValue

                                      setValue(`fuel.${index}.worked_days_jan`, numberValue)

                                      handleChangeVehicleFuel(index, 'worked_days_jan')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`fuel.${index}.worked_days_feb`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleFuels[index].worked_days_feb = numberValue

                                      setValue(`fuel.${index}.worked_days_feb`, numberValue)

                                      handleChangeVehicleFuel(index, 'worked_days_feb')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`fuel.${index}.worked_days_mar`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleFuels[index].worked_days_mar = numberValue

                                      setValue(`fuel.${index}.worked_days_mar`, numberValue)

                                      handleChangeVehicleFuel(index, 'worked_days_mar')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`fuel.${index}.worked_days_apr`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleFuels[index].worked_days_apr = numberValue

                                      setValue(`fuel.${index}.worked_days_apr`, numberValue)

                                      handleChangeVehicleFuel(index, 'worked_days_apr')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`fuel.${index}.worked_days_may`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleFuels[index].worked_days_may = numberValue

                                      setValue(`fuel.${index}.worked_days_may`, numberValue)

                                      handleChangeVehicleFuel(index, 'worked_days_may')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`fuel.${index}.worked_days_jun`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleFuels[index].worked_days_jun = numberValue

                                      setValue(`fuel.${index}.worked_days_jun`, numberValue)

                                      handleChangeVehicleFuel(index, 'worked_days_jun')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`fuel.${index}.worked_days_jul`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleFuels[index].worked_days_jul = numberValue

                                      setValue(`fuel.${index}.worked_days_jul`, numberValue)

                                      handleChangeVehicleFuel(index, 'worked_days_jul')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`fuel.${index}.worked_days_aug`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleFuels[index].worked_days_aug = numberValue

                                      setValue(`fuel.${index}.worked_days_aug`, numberValue)

                                      handleChangeVehicleFuel(index, 'worked_days_aug')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`fuel.${index}.worked_days_sep`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleFuels[index].worked_days_sep = numberValue

                                      setValue(`fuel.${index}.worked_days_sep`, numberValue)

                                      handleChangeVehicleFuel(index, 'worked_days_sep')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`fuel.${index}.worked_days_oct`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleFuels[index].worked_days_oct = numberValue

                                      setValue(`fuel.${index}.worked_days_oct`, numberValue)

                                      handleChangeVehicleFuel(index, 'worked_days_oct')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`fuel.${index}.worked_days_nov`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleFuels[index].worked_days_nov = numberValue

                                      setValue(`fuel.${index}.worked_days_nov`, numberValue)

                                      handleChangeVehicleFuel(index, 'worked_days_nov')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`fuel.${index}.worked_days_dec`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleFuels[index].worked_days_dec = numberValue

                                      setValue(`fuel.${index}.worked_days_dec`, numberValue)

                                      handleChangeVehicleFuel(index, 'worked_days_dec')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleFuels[index].only_view_yearly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`fuel.${index}.worked_days_yearly`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event);
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      vehicleFuels[index].worked_days_yearly = value

                                      setValue(
                                        `fuel.${index}.worked_days_yearly`,
                                        formatValue
                                      )

                                      handleChangeVehicleFuel(index, 'worked_days_yearly')
                                    }}
                                  />
                                )}
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(vehicleFuels[index].factor_co2_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(vehicleFuels[index].factor_ch4_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(vehicleFuels[index].factor_n2o_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(
                                    vehicleFuels[index].factor_co2_kg_bio,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(
                                    vehicleFuels[index].factor_ch4_kg_bio,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(
                                    vehicleFuels[index].factor_n2o_kg_bio,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(vehicleFuels[index].emission_co2, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(vehicleFuels[index].emission_ch4, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(vehicleFuels[index].emission_n2o, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(
                                    vehicleFuels[index].emission_tco2e,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(
                                    vehicleFuels[index].emission_tco2e_bio,
                                    false
                                  )}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }} rowSpan={3}>
                                  <div
                                    onClick={() => handleDeleteVehicleFuel(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="lixeirinha"/>
                                  </div>
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td
                                colSpan={5}
                                className="subhead-item"
                                style={{ paddingRight: 5, textAlign: 'right' }}
                              >
                                {'Quantidade de combustível fóssil (litros ou m³)'}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_jan,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_feb,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_mar,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_apr,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_may,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_jun,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_jul,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_aug,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_sep,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_oct,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_nov,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_dec,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_yearly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_yearly,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={5}
                                className="subhead-item"
                                style={{ paddingRight: 5, textAlign: 'right' }}
                              >
                                {'Quantidade de biocombustível (litros)'}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_bio_jan,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_bio_feb,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_bio_mar,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_bio_apr,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_bio_may,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_bio_jun,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_bio_jul,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_bio_aug,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_bio_sep,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_bio_oct,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_bio_nov,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_bio_dec,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleFuels[index].only_view_yearly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleFuels[index].fuel_amount_bio_yearly,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={23}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionCo2VehicleFuels, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionCh4VehicleFuels, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionN2oVehicleFuels, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionTco2eVehicleFuels, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionTco2eBioVehicleFuels, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {vehicleFuels && vehicleFuels.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum tipo de combustível cadastrado`
                        : `Nenhum tipo de combustível foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.train && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{ display: 'flex', flexDirection: 'column', marginRight: 30 }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5, marginLeft: 10, fontWeight: 'bold' }} >
                  Opção 3 -Cálculo de emissões por transporte de funcionários para deslocamento
                    casa-trabalho realizadas em veículos particulares com base na
                    distância média percorrida no ano
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, marginLeft: 10, }} >
                    Selecione, o tipo de transporte ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && selectedYear && filteredFleet.length > 0 && (
                  <div
                    style={{
                      marginTop: 15,
                      marginRight: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <Controller
                      control={control}
                      name="car_freet_private_vehicle_distance"
                      render={({ field }) => (
                        <Select placeholder="[Selecione]"
                          {...field}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: '350px',
                              height: 40,
                              backgroundColor: 'rgb(246, 246, 246)'
                            }),
                            option: (styles) => {
                              return { ...styles, color: '#31363F' }
                            }
                          }}
                          defaultValue={getValues('car_freet_private_vehicle_distance')}
                          options={filteredFleet}
                          onChange={(selectedOption) => {
                          field.onChange(selectedOption); 
                          handleChangeCarFleetDistance(selectedOption);
                        }}
                        />
                      )}
                    />

                    <button
                      className="button-view"
                      style={{ height: 40, marginLeft: 5 }}
                      onClick={() => handleOpenModalImport3()}
                      type="button"
                    >
                      Importar Dados
                    </button>
                  </div>
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {scopeVehicleDistance && vehicleDistances && vehicleDistances.length > 0 && (
                    <table
                      style={{
                        maxWidth: '100%',
                        width: '4000px',
                        borderCollapse: 'collapse'
                      }}
                      className="table-scope"
                    >
                      <thead>
                        <tr>
                          <th className="head-tr-item" style={{ width: 200 }} colSpan={2}>
                            {'Percurso'}
                          </th>
                          <th className="head-tr-item" style={{ width: 300 }} colSpan={2}>
                            {'Frota de veiculos'}
                          </th>
                          <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                            {'Distância média / dia (km)'}
                          </th>
                          <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                            {'Consumo médio sugerido'}
                          </th>
                          <th
                            className="head-tr-item"
                            style={{ width: 300 }}
                            colSpan={12}
                          >
                            {'Dias trabalhados / mês'}
                          </th>
                          <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                            {'Dias trabalhados no ano'}
                          </th>
                          <th className="head-tr-item" style={{ width: 300 }} colSpan={2}>
                            {'Fator de Emissão (kg CO₂ / litro)'}
                          </th>
                          <th className="head-tr-item" style={{ width: 300 }} colSpan={2}>
                            {'Fatores de Emissão do combustível comercial'}
                          </th>
                          <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                            {'Emissões de CO₂ fóssil (t)'}
                          </th>
                          <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                            {'Emissões de CH₄ (t)'}
                          </th>
                          <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                            {'Emissões de N₂O (t)'}
                          </th>
                          <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                            {'Emissões totais em CO₂e (t)'}
                          </th>
                          <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                            {'Emissões de CO₂ biogênico (t CO₂)'}
                          </th>
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          >
                            {!isCommonUser && (
                              <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                                <div onClick={handleOpenModalDeleteDistances}>
                                  <button style={{background: '#b81414', color: 'white', borderRadius: 5, cursor:'pointer'}}>Apagar tudo</button>
                                </div>
                              </td>
                            )}
                          </th>
                        </tr>
                        <tr>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Registro do colaborador'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Endereço'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Tipo de frota'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Ano de frota'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Janeiro'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Fevereiro'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Março'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Abril'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Maio'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Junho'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Julho'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Agosto'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Setembro'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Outubro'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Novembro'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Dezembro'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Combustível fóssil'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Biocombustível'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'kg CH₄ / litro'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'kg N₂O / litro'}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {vehicleDistances.map((distance: any, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td>
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 200 }}
                                    className="input-item"
                                    {...register(`distance.${index}.register`, {
                                      required: true
                                    })}
                                    onChange={(event) => {
                                      setValue(
                                        `distance.${index}.register`,
                                        event.target.value
                                      )
                                      vehicleDistances[index].register =
                                        event.target.value
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 200 }}
                                    className="input-item"
                                    {...register(`distance.${index}.description`, {
                                      required: true
                                    })}
                                    onBlur={(event) => {
                                      const { value } = event.target

                                      event.target.value = value
                                      
                                      setValue(`distance.${index}.description`, value)

                                      vehicleDistances[index].description = value

                                      handleChangeVehicleDistance(index, 'description')
                                    }}
                                  />
                                </td>
                                <td style={{ width: 200 }}>
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      width: 200,
                                      fontSize: 12,
                                      fontWeight: 'bold',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {vehicleDistances[index].car_fleet_name}
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      width: 300,
                                      fontSize: 12,
                                      display: 'inline-block'
                                    }}
                                  >
                                    <b>{'Combustível fóssil: '}</b>
                                    {vehicleDistances[index].fuel_name}
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      width: 300,
                                      fontSize: 12,
                                      display: 'inline-block'
                                    }}
                                  >
                                    <b>{'Biocombustível: '}</b>
                                    {vehicleDistances[index].bio_fuel_name}
                                  </span>
                                </td>
                                <td>
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`distance.${index}.year`, {
                                      required: true
                                    })}
                                    onChange={(event) => {
                                      
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)
                                      event.target.value = numberValue
                                      vehicleDistances[index].year = numberValue
                                      setValue(`distance.${index}.year`, numberValue)
                                      handleChangeVehicleDistance(index, 'year')
                                      
                                    }}
                                  />
                                </td>
                                <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 150, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`distance.${index}.average_distance`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0
                                    vehicleDistances[index].average_distance = value;
                                    setValue(`distance.${index}.average_distance`, formatValue);
                                    handleChangeVehicleDistance(index, 'average_distance')
                                  }}
                                />
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistances[index].average_consumption_fuel,
                                      false
                                    )}
                                  </span>
                                </td>
                                <td>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 120, textAlign: 'right' }}
                                      className="input-item"
                                      {...register(`distance.${index}.worked_days_jan`)}
                                      onChange={(event) => {
                                        const { value } = event.target
                                        const numberValue = toNumberValues(value)

                                        event.target.value = numberValue

                                        vehicleDistances[index].worked_days_jan =
                                          numberValue

                                        setValue(
                                          `distance.${index}.worked_days_jan`,
                                          numberValue
                                        )

                                        handleChangeVehicleDistance(
                                          index,
                                          'worked_days_jan'
                                        )
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 120, textAlign: 'right' }}
                                      className="input-item"
                                      {...register(`distance.${index}.worked_days_feb`)}
                                      onChange={(event) => {
                                        const { value } = event.target
                                        const numberValue = toNumberValues(value)

                                        event.target.value = numberValue

                                        vehicleDistances[index].worked_days_feb =
                                          numberValue

                                        setValue(
                                          `distance.${index}.worked_days_feb`,
                                          numberValue
                                        )

                                        handleChangeVehicleDistance(
                                          index,
                                          'worked_days_feb'
                                        )
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 120, textAlign: 'right' }}
                                      className="input-item"
                                      {...register(`distance.${index}.worked_days_mar`)}
                                      onChange={(event) => {
                                        const { value } = event.target
                                        const numberValue = toNumberValues(value)

                                        event.target.value = numberValue

                                        vehicleDistances[index].worked_days_mar =
                                          numberValue

                                        setValue(
                                          `distance.${index}.worked_days_mar`,
                                          numberValue
                                        )

                                        handleChangeVehicleDistance(
                                          index,
                                          'worked_days_mar'
                                        )
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 120, textAlign: 'right' }}
                                      className="input-item"
                                      {...register(`distance.${index}.worked_days_apr`)}
                                      onChange={(event) => {
                                        const { value } = event.target
                                        const numberValue = toNumberValues(value)

                                        event.target.value = numberValue

                                        vehicleDistances[index].worked_days_apr =
                                          numberValue

                                        setValue(
                                          `distance.${index}.worked_days_apr`,
                                          numberValue
                                        )

                                        handleChangeVehicleDistance(
                                          index,
                                          'worked_days_apr'
                                        )
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 120, textAlign: 'right' }}
                                      className="input-item"
                                      {...register(`distance.${index}.worked_days_may`)}
                                      onChange={(event) => {
                                        const { value } = event.target
                                        const numberValue = toNumberValues(value)

                                        event.target.value = numberValue

                                        vehicleDistances[index].worked_days_may =
                                          numberValue

                                        setValue(
                                          `distance.${index}.worked_days_may`,
                                          numberValue
                                        )

                                        handleChangeVehicleDistance(
                                          index,
                                          'worked_days_may'
                                        )
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 120, textAlign: 'right' }}
                                      className="input-item"
                                      {...register(`distance.${index}.worked_days_jun`)}
                                      onChange={(event) => {
                                        const { value } = event.target
                                        const numberValue = toNumberValues(value)

                                        event.target.value = numberValue

                                        vehicleDistances[index].worked_days_jun =
                                          numberValue

                                        setValue(
                                          `distance.${index}.worked_days_jun`,
                                          numberValue
                                        )

                                        handleChangeVehicleDistance(
                                          index,
                                          'worked_days_jun'
                                        )
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 120, textAlign: 'right' }}
                                      className="input-item"
                                      {...register(`distance.${index}.worked_days_jul`)}
                                      onChange={(event) => {
                                        const { value } = event.target
                                        const numberValue = toNumberValues(value)

                                        event.target.value = numberValue

                                        vehicleDistances[index].worked_days_jul =
                                          numberValue

                                        setValue(
                                          `distance.${index}.worked_days_jul`,
                                          numberValue
                                        )

                                        handleChangeVehicleDistance(
                                          index,
                                          'worked_days_jul'
                                        )
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 120, textAlign: 'right' }}
                                      className="input-item"
                                      {...register(`distance.${index}.worked_days_aug`)}
                                      onChange={(event) => {
                                        const { value } = event.target
                                        const numberValue = toNumberValues(value)

                                        event.target.value = numberValue

                                        vehicleDistances[index].worked_days_aug =
                                          numberValue

                                        setValue(
                                          `distance.${index}.worked_days_aug`,
                                          numberValue
                                        )

                                        handleChangeVehicleDistance(
                                          index,
                                          'worked_days_aug'
                                        )
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 120, textAlign: 'right' }}
                                      className="input-item"
                                      {...register(`distance.${index}.worked_days_sep`)}
                                      onChange={(event) => {
                                        const { value } = event.target
                                        const numberValue = toNumberValues(value)

                                        event.target.value = numberValue

                                        vehicleDistances[index].worked_days_sep =
                                          numberValue

                                        setValue(
                                          `distance.${index}.worked_days_sep`,
                                          numberValue
                                        )

                                        handleChangeVehicleDistance(
                                          index,
                                          'worked_days_sep'
                                        )
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 120, textAlign: 'right' }}
                                      className="input-item"
                                      {...register(`distance.${index}.worked_days_oct`)}
                                      onChange={(event) => {
                                        const { value } = event.target
                                        const numberValue = toNumberValues(value)

                                        event.target.value = numberValue

                                        vehicleDistances[index].worked_days_oct =
                                          numberValue

                                        setValue(
                                          `distance.${index}.worked_days_oct`,
                                          numberValue
                                        )

                                        handleChangeVehicleDistance(
                                          index,
                                          'worked_days_oct'
                                        )
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 120, textAlign: 'right' }}
                                      className="input-item"
                                      {...register(`distance.${index}.worked_days_nov`)}
                                      onChange={(event) => {
                                        const { value } = event.target
                                        const numberValue = toNumberValues(value)

                                        event.target.value = numberValue

                                        vehicleDistances[index].worked_days_nov =
                                          numberValue

                                        setValue(
                                          `distance.${index}.worked_days_nov`,
                                          numberValue
                                        )

                                        handleChangeVehicleDistance(
                                          index,
                                          'worked_days_nov'
                                        )
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 120, textAlign: 'right' }}
                                      className="input-item"
                                      {...register(`distance.${index}.worked_days_dec`)}
                                      onChange={(event) => {
                                        const { value } = event.target
                                        const numberValue = toNumberValues(value)

                                        event.target.value = numberValue

                                        vehicleDistances[index].worked_days_dec =
                                          numberValue

                                        setValue(
                                          `distance.${index}.worked_days_dec`,
                                          numberValue
                                        )

                                        handleChangeVehicleDistance(
                                          index,
                                          'worked_days_dec'
                                        )
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  {vehicleDistances[index].only_view_yearly === false && (
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 100, textAlign: 'center' }}
                                      className="input-item"
                                      {...register(
                                        `distance.${index}.worked_days_yearly`
                                      )}
                                      onChange={(event) => {
                                        const formatValue = currency(event);
                                        const valueStr = formatValue.replace('.', '')
                                        const value = valueStr
                                          ? parseFloat(valueStr.replace(',', '.'))
                                          : 0

                                        vehicleDistances[index].worked_days_yearly = value

                                        setValue(
                                          `distance.${index}.worked_days_yearly`,
                                          formatValue
                                        )

                                        handleChangeVehicleDistance(
                                          index,
                                          'worked_days_yearly'
                                        )
                                      }}
                                    />
                                  )}
                                </td>
                                <td style={{ textAlign: 'center' }} rowSpan={4}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistances[index].factor_co2_kg,
                                      false
                                    )}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'center' }} rowSpan={4}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistances[index].factor_co2_kg_bio,
                                      false
                                    )}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'center' }} rowSpan={4}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistances[index].factor_ch4_kg,
                                      false
                                    )}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'center' }} rowSpan={4}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistances[index].factor_n2o_kg,
                                      false
                                    )}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'center' }} rowSpan={4}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistances[index].emission_co2,
                                      false
                                    )}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'center' }} rowSpan={4}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistances[index].emission_ch4,
                                      false
                                    )}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'center' }} rowSpan={4}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistances[index].emission_n2o,
                                      false
                                    )}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'center' }} rowSpan={4}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistances[index].emission_tco2e,
                                      false
                                    )}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'center' }} rowSpan={4}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistances[index].emission_tco2e_bio,
                                      false
                                    )}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'center' }} rowSpan={4}>
                                  <div
                                    onClick={() => handleDeleteVehicleDistance(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="lixeirinha"/>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={6}
                                  className="subhead-item"
                                  style={{ paddingRight: 5, textAlign: 'right' }}
                                >
                                  {'Quantidade de combustível consumido'}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_jan,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_feb,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_mar,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_apr,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_may,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_jun,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_jul,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_aug,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_sep,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_oct,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_nov,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_dec,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_yearly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_yearly,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={6}
                                  className="subhead-item"
                                  style={{ paddingRight: 5, textAlign: 'right' }}
                                >
                                  {'Quantidade de combustível fóssil (litros ou m³)'}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_fossil_jan,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_fossil_feb,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_fossil_mar,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_fossil_apr,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_fossil_may,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_fossil_jun,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_fossil_jul,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_fossil_aug,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_fossil_sep,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_fossil_oct,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_fossil_nov,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_fossil_dec,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_yearly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_fossil_yearly,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={6}
                                  className="subhead-item"
                                  style={{ paddingRight: 5, textAlign: 'right' }}
                                >
                                  {'Quantidade de biocombustível (litros)'}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_bio_jan,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_bio_feb,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_bio_mar,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_bio_apr,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_bio_may,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_bio_jun,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_bio_jul,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_bio_aug,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_bio_sep,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_bio_oct,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_bio_nov,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_monthly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_bio_dec,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td style={{ width: 100 }}>
                                  {vehicleDistances[index].only_view_yearly === false && (
                                    <span
                                      style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: 'inline-block',
                                        textAlign: 'right'
                                      }}
                                    >
                                      {formatPrice5(
                                        vehicleDistances[index].fuel_amount_bio_yearly,
                                        false
                                      )}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            </React.Fragment>
                          )
                        })}
                      </tbody>
                      <tfoot>
                        <tr style={{ fontWeight: 'bold' }}>
                          <td
                            colSpan={23}
                            className="head-tr-item"
                            style={{ paddingRight: 5, textAlign: 'right' }}
                          >
                            {'total'}
                          </td>
                          <td className="head-tr-item">
                            <span
                              style={{
                                marginRight: 5,
                                width: 120,
                                fontSize: 12,
                                textAlign: 'right',
                                display: 'inline-block'
                              }}
                            >
                              {formatPrice5(emissionCo2VehicleDistances, false)}
                            </span>
                          </td>
                          <td className="head-tr-item">
                            <span
                              style={{
                                marginRight: 5,
                                width: 120,
                                fontSize: 12,
                                textAlign: 'right',
                                display: 'inline-block'
                              }}
                            >
                              {formatPrice5(emissionCh4VehicleDistances, false)}
                            </span>
                          </td>
                          <td className="head-tr-item">
                            <span
                              style={{
                                marginRight: 5,
                                width: 120,
                                fontSize: 12,
                                textAlign: 'right',
                                display: 'inline-block'
                              }}
                            >
                              {formatPrice5(emissionN2oVehicleDistances, false)}
                            </span>
                          </td>
                          <td className="head-tr-item">
                            <span
                              style={{
                                marginRight: 5,
                                width: 120,
                                fontSize: 12,
                                textAlign: 'right',
                                display: 'inline-block'
                              }}
                            >
                              {formatPrice5(emissionTco2eVehicleDistances, false)}
                            </span>
                          </td>
                          <td className="head-tr-item">
                            <span
                              style={{
                                marginRight: 5,
                                width: 120,
                                fontSize: 12,
                                textAlign: 'right',
                                display: 'inline-block'
                              }}
                            >
                              {formatPrice5(emissionTco2eBioVehicleDistances, false)}
                            </span>
                          </td>
                          {!isCommonUser && <td className="head-tr-item" />}
                        </tr>
                      </tfoot>
                    </table>
                  )}

                {vehicleDistances && vehicleDistances.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum tipo de transporte cadastrado`
                        : `Nenhum tipo de transporte foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.train && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* DISTANCIA MANUAL */}
          {ignoreFill === false &&  (

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
              className='custom-scrollbar'
            >
              {/* Header option 3.1 */}
              <HeaderSelect 
                description="Opção 3.1 - distância adicionada manualmente -Cálculo de emissões por transporte de funcionários para deslocamento casa-trabalho
                realizadas em veículos particulares com base na distância média percorrida no ano"
                hasImport={true}
                isCommonUser={isCommonUser}
                selectedYear={selectedYear}
                options={filteredFleet}
                control={control}
                handleChangeValue={handleChangeCarFleetDistanceManual}
                name="car_freet_private_vehicle_distance_manual"
                defaultValue={getValues('car_freet_private_vehicle_distance_manual')}
                hasExport={{
                    active: true,
                    handleExport: handleExport,
                }}
                modalImport={{
                  title: "Deslocamento casa-trabalho realizadas em veículos particulares, com base na distância média percorrida no ano",
                  templateFileName: "template_scope3_home_work_displacement_private_vehicle_option3_1.xlsx",
                  option_table: "car_freet_private_vehicle_distance_manual",
                  handleImportData: handleImportData,
                }}
              />

              {/* Table 3.1 */}
              <TableContainerWrap hasContent={scopeVehicleDistanceManual && vehicleDistancesManual && vehicleDistancesManual.length > 0 ? true : false} count={vehicleDistancesManual.length}>
                { scopeVehicleDistanceManual && vehicleDistancesManual && vehicleDistancesManual.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={1}>
                          {'Percurso'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={2}>
                          {'Frota de veiculos'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Distância média / dia (km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Consumo médio sugerido'}
                        </th>
                        <th
                          className="head-tr-item"
                          style={{ width: 300 }}
                          colSpan={12}
                        >
                          {'Dias trabalhados / mês'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Dias trabalhados no ano'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={2}>
                          {'Fator de Emissão (kg CO₂ / litro)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={2}>
                          {'Fatores de Emissão do combustível comercial'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CO₂ fóssil (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CH₄ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de N₂O (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões totais em CO₂e (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CO₂ biogênico (t CO₂)'}
                        </th>
                        <th
                          className="head-tr-item"
                          style={{ width: 50 }}
                          rowSpan={2}
                        >
                         {!isCommonUser && (
                              <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                                <div onClick={handleOpenModalDeleteDistancesManual}>
                                  <button style={{background: '#b81414', color: 'white', borderRadius: 5, cursor:'pointer'}}>Apagar tudo</button>
                                </div>
                              </td>
                            )}
                        </th>
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Registro do colaborador'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Tipo de frota'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Ano de frota'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Janeiro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Fevereiro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Março'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Abril'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Maio'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Junho'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Julho'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Agosto'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Setembro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Outubro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Novembro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Dezembro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Combustível fóssil'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Biocombustível'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CH₄ / litro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg N₂O / litro'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {vehicleDistancesManual.map((distance: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`distance.${index}.register_manual`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `distance.${index}.register_manual`,
                                      event.target.value
                                    )
                                    vehicleDistancesManual[index].register =
                                      event.target.value
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 200,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    display: 'inline-block'
                                  }}
                                >
                                  {vehicleDistancesManual[index].car_fleet_name}
                                </span>
                                <br />
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 300,
                                    fontSize: 12,
                                    display: 'inline-block'
                                  }}
                                >
                                  <b>{'Combustível fóssil: '}</b>
                                  {vehicleDistancesManual[index].fuel_name}
                                </span>
                                <br />
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 300,
                                    fontSize: 12,
                                    display: 'inline-block'
                                  }}
                                >
                                  <b>{'Biocombustível: '}</b>
                                  {vehicleDistancesManual[index].bio_fuel_name}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`distance.${index}.year_manual`, {
                                    required: true
                                  })}
                                  onChange={(event) => {

                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)
                                    event.target.value = numberValue
                                    vehicleDistancesManual[index].year = numberValue
                                    setValue(`distance.${index}.year_manual`, numberValue)
                                    handleChangeVehicleDistanceManual(index, 'year_manual')

                                  }}
                                />
                              </td>
                              <td>
                                <input
                                    style={{ width: 150, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`distance.${index}.average_distance_manual`, {
                                        required: true
                                    })}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const inputValue = currency(event);
                                        const formattedValue = moeda(inputValue, '.', ',');

                                        const valueStr = formattedValue.replace(/[.,]/g, '');
                                        const value = valueStr ? parseFloat(valueStr) / 10000 : 0;

                                        vehicleDistancesManual[index].average_distance = value;

                                        setValue(`distance.${index}.average_distance_manual`, formattedValue);
                                        handleChangeVehicleDistanceManual(index, 'average_distance_manual');
                                        event.target.value = formattedValue;
                                    }}
                                />
                            </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(
                                    vehicleDistancesManual[index].average_consumption_fuel,
                                    false
                                  )}
                                </span>
                              </td>
                              <td>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`distance.${index}.worked_days_jan_manual`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleDistancesManual[index].worked_days_jan =
                                        numberValue

                                      setValue(
                                        `distance.${index}.worked_days_jan_manual`,
                                        numberValue
                                      )

                                      handleChangeVehicleDistanceManual(
                                        index,
                                        'worked_days_jan_manual'
                                      )
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`distance.${index}.worked_days_feb_manual`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleDistancesManual[index].worked_days_feb =
                                        numberValue

                                      setValue(
                                        `distance.${index}.worked_days_feb_manual`,
                                        numberValue
                                      )

                                      handleChangeVehicleDistanceManual(
                                        index,
                                        'worked_days_feb_manual'
                                      )
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`distance.${index}.worked_days_mar_manual`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleDistancesManual[index].worked_days_mar =
                                        numberValue

                                      setValue(
                                        `distance.${index}.worked_days_mar_manual`,
                                        numberValue
                                      )

                                      handleChangeVehicleDistanceManual(
                                        index,
                                        'worked_days_mar_manual'
                                      )
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`distance.${index}.worked_days_apr_manual`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleDistancesManual[index].worked_days_apr =
                                        numberValue

                                      setValue(
                                        `distance.${index}.worked_days_apr_manual`,
                                        numberValue
                                      )

                                      handleChangeVehicleDistanceManual(
                                        index,
                                        'worked_days_apr_manual'
                                      )
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`distance.${index}.worked_days_may_manual`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleDistancesManual[index].worked_days_may =
                                        numberValue

                                      setValue(
                                        `distance.${index}.worked_days_may_manual`,
                                        numberValue
                                      )

                                      handleChangeVehicleDistanceManual(
                                        index,
                                        'worked_days_may_manual'
                                      )
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`distance.${index}.worked_days_jun_manual`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleDistancesManual[index].worked_days_jun =
                                        numberValue

                                      setValue(
                                        `distance.${index}.worked_days_jun_manual`,
                                        numberValue
                                      )

                                      handleChangeVehicleDistanceManual(
                                        index,
                                        'worked_days_jun_manual'
                                      )
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`distance.${index}.worked_days_jul_manual`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleDistancesManual[index].worked_days_jul =
                                        numberValue

                                      setValue(
                                        `distance.${index}.worked_days_jul_manual`,
                                        numberValue
                                      )

                                      handleChangeVehicleDistanceManual(
                                        index,
                                        'worked_days_jul_manual'
                                      )
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`distance.${index}.worked_days_aug_manual`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleDistancesManual[index].worked_days_aug =
                                        numberValue

                                      setValue(
                                        `distance.${index}.worked_days_aug_manual`,
                                        numberValue
                                      )

                                      handleChangeVehicleDistanceManual(
                                        index,
                                        'worked_days_aug_manual'
                                      )
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`distance.${index}.worked_days_sep_manual`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleDistancesManual[index].worked_days_sep =
                                        numberValue

                                      setValue(
                                        `distance.${index}.worked_days_sep_manual`,
                                        numberValue
                                      )

                                      handleChangeVehicleDistanceManual(
                                        index,
                                        'worked_days_sep_manual'
                                      )
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`distance.${index}.worked_days_oct_manual`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleDistancesManual[index].worked_days_oct =
                                        numberValue

                                      setValue(
                                        `distance.${index}.worked_days_oct_manual`,
                                        numberValue
                                      )

                                      handleChangeVehicleDistanceManual(
                                        index,
                                        'worked_days_oct_manual'
                                      )
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`distance.${index}.worked_days_nov_manual`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleDistancesManual[index].worked_days_nov =
                                        numberValue

                                      setValue(
                                        `distance.${index}.worked_days_nov_manual`,
                                        numberValue
                                      )

                                      handleChangeVehicleDistanceManual(
                                        index,
                                        'worked_days_nov_manual'
                                      )
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`distance.${index}.worked_days_dec_manual`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleDistancesManual[index].worked_days_dec =
                                        numberValue

                                      setValue(
                                        `distance.${index}.worked_days_dec_manual`,
                                        numberValue
                                      )

                                      handleChangeVehicleDistanceManual(
                                        index,
                                        'worked_days_dec_manual'
                                      )
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {vehicleDistancesManual[index].only_view_yearly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(
                                      `distance.${index}.worked_days_yearly_manual`
                                    )}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      vehicleDistancesManual[index].worked_days_yearly = numberValue

                                      setValue(
                                        `distance.${index}.worked_days_yearly_manual`,
                                        numberValue
                                      )

                                      handleChangeVehicleDistanceManual(
                                        index,
                                        'worked_days_yearly_manual'
                                      )
                                    }}
                                  />
                                )}
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(
                                    vehicleDistancesManual[index].factor_co2_kg,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(
                                    vehicleDistancesManual[index].factor_co2_kg_bio,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(
                                    vehicleDistancesManual[index].factor_ch4_kg,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(
                                    vehicleDistancesManual[index].factor_n2o_kg,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(
                                    vehicleDistancesManual[index].emission_co2,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(
                                    vehicleDistancesManual[index].emission_ch4,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(
                                    vehicleDistancesManual[index].emission_n2o,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(
                                    vehicleDistancesManual[index].emission_tco2e,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(
                                    vehicleDistancesManual[index].emission_tco2e_bio,
                                    false
                                  )}
                                </span>
                                </td>
                                <td style={{ textAlign: 'center' }} rowSpan={4}>
                                  <div
                                      onClick={() => handleDeleteVehicleDistanceManual(index)}
                                      style={{
                                        cursor: 'pointer',
                                        textAlign: 'center',
                                        width: 70
                                      }}
                                    >
                                    <img src={TrashIcon} alt="lixeirinha" />
                                </div>
                              </td>   
                            </tr>
                            <tr>
                              <td
                                colSpan={5}
                                className="subhead-item"
                                style={{ paddingRight: 5, textAlign: 'right' }}
                              >
                                {'Quantidade de combustível consumido'}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_jan,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_feb,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_mar,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_apr,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_may,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_jun,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_jul,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_aug,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_sep,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_oct,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_nov,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_dec,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_yearly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_yearly,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={5}
                                className="subhead-item"
                                style={{ paddingRight: 5, textAlign: 'right' }}
                              >
                                {'Quantidade de combustível fóssil (litros ou m³)'}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_fossil_jan,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_fossil_feb,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_fossil_mar,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_fossil_apr,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_fossil_may,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_fossil_jun,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_fossil_jul,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_fossil_aug,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_fossil_sep,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_fossil_oct,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_fossil_nov,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_fossil_dec,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_yearly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_fossil_yearly,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={5}
                                className="subhead-item"
                                style={{ paddingRight: 5, textAlign: 'right' }}
                              >
                                {'Quantidade de biocombustível (litros)'}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_bio_jan,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_bio_feb,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_bio_mar,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_bio_apr,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_bio_may,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_bio_jun,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_bio_jul,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_bio_aug,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_bio_sep,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_bio_oct,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_bio_nov,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_bio_dec,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {vehicleDistancesManual[index].only_view_yearly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      vehicleDistancesManual[index].fuel_amount_bio_yearly,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={22}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionCo2VehicleDistancesManual, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionCh4VehicleDistancesManual, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionN2oVehicleDistancesManual, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionTco2eVehicleDistancesManual, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionTco2eBioVehicleDistancesManual, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {vehicleDistancesManual && vehicleDistancesManual.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum tipo de transporte cadastrado`
                        : `Nenhum tipo de transporte foi selecionado, favor selecionar para dar
                prosseguimento`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.train && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </TableContainerWrap>
            </div>
          )}

          {!ignoreFill && vehicles && (
            <div
              style={{
                borderStyle: "dashed",
                margin: 5,
                borderWidth: "1px",
                width: "calc(100vw - 330px)",
                maxWidth: "calc(100vw - 330px)",
                borderColor: "#9E9E9E",
                height: "40px",
                display: "flex",
                flexDirection: "row",
                marginLeft: 15,
              }}
            >
              <span style={{ margin: 10, width: "70%" }}>
                Emissões totais em CO₂ equivalente (toneladas métricas):
              </span>
              <span
                style={{
                  fontWeight: "bold",
                  margin: 10,
                  textAlign: "right",
                  width: "30%",
                }}
              >
                {formatPrice3(emissionTco2eVehicles + emissionTco2eVehicleFuels + emissionTco2eVehicleDistances + emissionTco2eVehicleDistancesManual, false)}
              </span>
            </div>
          )}
          {!ignoreFill && vehicles &&(
            <div
              style={{
                borderStyle: 'dashed',
                margin: 5,
                borderWidth: '1px',
                width: 'calc(100vw - 330px)',
                maxWidth: 'calc(100vw - 330px)',
                borderColor: '#9E9E9E',
                height: '40px',
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 15
              }}
            >
              <span style={{ margin: 10, width: '70%' }}>
                Emissões totais em CO₂ biogênico (toneladas métricas):
              </span>
              <span
                style={{
                  fontWeight: 'bold',
                  margin: 10,
                  textAlign: 'right',
                  width: '30%'
                }}
              >
                {formatPrice3(emissionTco2eBioVehicleDistances + emissionTco2eBioVehicleDistancesManual, false)}
              </span>
            </div>
          )}
          {ignoreFill === false && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={downloadFile}
                  onChangeAttachment={attachmentChangeHandler}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && <span style={{ fontSize: 12 }}>{Parser(guidelines)}</span>}
        </div>
      </Modal>

      {openModalImport1 && (
        <ModalImportData
          entityType={1}
          isOpen={openModalImport1}
          handleModalVisible={handleOpenModalImport1}
          entityName="Deslocamento casa-trabalho realizadas em veículos particulares, com base no tipo e ano da frota"
          templateFileName="template_displacement_Private1.xlsx"
          handleImportData={handleImportCarFleet}
        />
      )}

      {openModalImport2 && (
        <ModalImportData
          entityType={2}
          isOpen={openModalImport2}
          handleModalVisible={handleOpenModalImport2}
          entityName="Deslocamento casa-trabalho realizadas em veículos particulares, com base no tipo de combustível e consumo médio no ano"
          templateFileName="template_displacement_Private2.xlsx"
          handleImportData={handleImportFuel}
        />
      )}
      
      {/* Import Option 3 */}
      {openModalImport3 && (
        <ModalImportData
          entityType={3}
          isOpen={openModalImport3}
          handleModalVisible={handleOpenModalImport3}
          entityName="Deslocamento casa-trabalho realizadas em veículos particulares, com base na distância média percorrida no ano"
          templateFileName="template_displacement_Private3.xlsx"
          handleImportData={handleImportDistance}
        />
      )}

      {/* Import Option 3.1 ING-9 */}
      {/* {openModalImport4 && (
        <ModalImportData
          entityType={4}
          isOpen={openModalImport4}
          handleModalVisible={handleOpenModalImport4}
          entityName="Deslocamento casa-trabalho realizadas em veículos particulares, com base na distância média percorrida no ano"
          templateFileName="template_scope3_home_work_displacement_private_vehicle_option3_1.xlsx"
          handleImportData={handleImportDistanceManual}
        />
      )} */}

      {openModalError && importErrors && (
        <Modal isOpen={openModalError} isPayOrTypeClient={true} handleModalVisible={handleOpenModalError} >
          <div style={{ margin: 5, textAlign: "left" }}>
            <TitleText level={3} title="Falha na importação de dados" color="red" />

            <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }} >
              Ocorreram falhas na importação dos dados previstos. Abaixo maiores detalhes.
            </span>
          </div>

          <div style={{ margin: 10, textAlign: "center", cursor: "pointer" }} >
            <table style={{ width: '100%', borderCollapse: 'collapse' }} className="table-scope" >
              <thead>
                <tr style={{ borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                  <th style={{ backgroundColor: "#D8D7D7", fontSize: 12, height: 25 }} colSpan={3}>
                    {'Falhas de Importação'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {importErrors.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr style={{ borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "20%", fontSize: 12, textAlign: "left" }} >
                            {'Linha ' + item.id}
                          </span>
                        </td>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "80%", fontSize: 12, textAlign: "left" }} >
                            {'Tipo de transporte não identificado: ' + item.label}
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Modal >
      )}

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}

    {openModalDeleteVehicle && (
      <ModalDeleteDataTable
        isOpen={openModalDeleteVehicle}
        handleModalVisible={() => setOpenModalDeleteVehicle(false)}
        handleOk={handleDeleteAllScopeVehicle}
      />
    )}

    {openModalDeleteVehicleFuel && (
      <ModalDeleteDataTable
        isOpen={openModalDeleteVehicleFuel}
        handleModalVisible={() => setOpenModalDeleteVehicleFuel(false)}
        handleOk={handleDeleteAllScopeVehicleFuel}
      />
    )}

    {openModalDeleteDistances && (
      <ModalDeleteDataTable
        isOpen={openModalDeleteDistances}
        handleModalVisible={() => setOpenModalDeleteDistances(false)}
        handleOk={handleDeleteAllScopeDistances}
      />
    )}

    {openModalDeleteDistancesManual && (
      <ModalDeleteDataTable
        isOpen={openModalDeleteDistancesManual}
        handleModalVisible={() => setOpenModalDeleteDistancesManual(false)}
        handleOk={handleDeleteAllScopeDistancesManual}
      />
    )}
    </Container>
  )
}
