import styled from "styled-components";

export const Container = styled.div `

    .div-body {
        display: flex; 
        flex-direction: column; 
        margin-top: 10px; 
        margin-left: 10px; 
    }
    
    .div-text {
        display: flex; 
        flex-direction: row; 
        margin-top: 5px;
        margin-right: 30px; 
    }

    .text-statement {
        text-align: start;
        font-size: 16px;
        margin-top: 5px;
        font-weight: bold;
    }

    .sub-item{
        display: flex;
        flex-direction: row;
        margin-top: 5px;
        margin-right: 30px;
        font-weight: bold;
    }

    .input-item {
        display: flex;
        flex-direction: row;
        margin-top: 0px;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .sub-input-item{
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        margin-right: 30px; 
    }

    .selection-input{
        display: flex;
        justify-content: start;
        align-items: center;
        margin-top: 5px;
        margin-right: 20px;
    }

    .div-item {
        display: flex; 
        flex-direction: column; 
        margin-top: 5px;
        margin-right: 30px; 
    }

    .prompt-text {
        text-align: start;
        font-size: 14px;
        margin-top: 5px; 
    }

    .unit-item-content{
        text-align: center;
        font-size: 14px;
        padding: 11px;
        margin-top: 1px;
        width: 70px;
        border-bottom: 1px solid black;
    }

    .unit-item-organic{
        text-align: center;
        font-size: 14px;
        padding: 11px;
        margin-top: 2px;
        width: 100px;
        border-bottom: 1px solid black;
    }

    .unit-item{
        text-align: center;
        font-size: 14px;
        padding: 11px;
        margin-top: 2px;
        width: 40px;
        border-bottom: 1px solid black;
    }

    .value-item{
        height: 25px;
        text-align: right;
        background-color: #c0c0c0;
        border: none;
        pointer-events: none;
        color: #333;
    }

    .value-item{
        height: 25px;
        text-align: right;
        background-color: #c0c0c0;
        border: none;
        pointer-events: none;
        color: #333;
    }

    .data-input{
        display: flex;
        flex-direction: row;
        margin: 0px;
    }

    .span-item{
        text-align: center;
        font-size: 14px;
        padding: 6px;
        margin-top 0px;
        width: 100px;
        border-bottom: 1px solid black;
    }

    .select-box{
        border-width: 0px;
        width: 100px;
        height: 25px;
    }

    .choice-one {
        display: flex;
        justify-content: start;
        align-items: center;
        marginTop: 5px;
        margin-right: 20px; 
    }
`