import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import unityService from "../../../services/unityService";
import { Container } from "./styles";

import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

interface Inputs {
  name: string;
  description: string;
}

export const UnityEdit = () => {
  const history = useHistory();

  const [unitySelected, setUnitySelected] = useState<any>({});
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.unity_id){
      getUnitysById(item.unity_id)
    }
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [location]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const newUnity = {
        name: formData.name,
        description: formData.description
      };

      if (unitySelected?.id) {
        const result = await unityService.updateById(
          unitySelected.id,
          newUnity
        );

        if (result && !result.error) {
         // history.goBack()

         // reset();
          //setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await unityService.create(newUnity);

        if (result && !result.error) {
          //history.goBack()

          //reset();
          //setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  async function getUnitysById(id: number) {
    const unity = await unityService.getById(id);

    setValue("name", unity?.name);
    setValue("description", unity?.description);

    setUnitySelected(unity)
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, justifyContent: "space-between", marginBottom: 10 }} >
          <TitleText level={1} title="Unidade" />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 20, marginLeft: 50, marginRight: 50  }} >
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Nome
          </label>

          <input style={{width: '95%'}}
                {...register("name", { required: true })}/>
                      
          {errors.name && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
          
          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Descrição
          </label>

          <textarea style={{width: '95%', height: 100}}
                {...register("description")}/>
                      
          {errors.description && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>
      </form>
    </Container>
  );
};
