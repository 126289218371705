import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../components/Button/styles";
import { GoBack } from "../../components/GoBack";
import { TitleText } from "../../components/TitleText";
import useAuth from "../../hooks/useAuth";
import termsService from "../../services/termsService";
import { ModalDetails } from "./components/ModalDetails";
import { Container } from "./styles";

import { Loading } from "../../components/Loading";
import { toast } from "react-hot-toast";

interface Inputs {
  name: string;
  subsector: string;
}

export const TermsOfUse = () => {
  const [loadingModal, setLoadingModal] = useState(false);
  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [term, setTerm] = useState<any>([]);
  const [history, setHistory] = useState<any>([]);
  const [historyTerms, setHistoryTerms] = useState<any>([]);
  const [historyPolicies, setHistoryPolicies] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  async function getItem() {
    setTerm(await termsService.getItem());

    const result = await termsService.getHistory()

    if (result){
      setHistoryTerms(result.filter(function(a:any) { 
        return a.type === 'terms_of_use'
      }))
  
      setHistoryPolicies(result.filter(function(a:any) { 
        return a.type === 'usage_policies'
      }))
    }else{
      setHistoryTerms([])
      setHistoryPolicies([])
    }
  }

	const termsOfUseChangeHandler = (event:any) => {
    setLoadingModal(true)
    
    async function saveTerms(base64?:string) {
      const termsItem = {
        terms_of_use: base64,
        terms_of_use_filename: file.name,
      };

      const result = await termsService.save(
        termsItem
      );

      if (result && !result.error) {
        getItem()

        toast.success("Operação realizada com sucesso!")
      }

      setLoadingModal(false)
    }

    const file = event.target.files[0]
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64 = reader.result;

      saveTerms(base64?.toString())
    };
	};

	const policiesChangeHandler = (event:any) => {
    setLoadingModal(true)
    
    async function saveTerms(base64?:string) {
      const termsItem = {
        usage_policies: base64,
        usage_policies_filename: file.name,
      };

      const result = await termsService.save(
        termsItem
      );

      if (result && !result.error) {
        getItem()
        
        toast.success("Operação realizada com sucesso!")
      }

      setLoadingModal(false)
    }

    const file = event.target.files[0]
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64 = reader.result;

      saveTerms(base64?.toString())
    };
	};

  function handleOpenModalDetails(item?: any) {
    setOpenModalDetails(!openModalDetails);
    setHistory(item);
  }

  useEffect(() => {
    getItem()
  }, []);

  const {
    handleSubmit
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async () => {console.log('send')};  
  
  return (
    <Container>
      <GoBack />
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 5, 
                      justifyContent: "space-between"}} >
          <div style={{ display: "flex", flexDirection: "column", marginTop: 15 }}>
            <TitleText level={1} title="Termos de uso" />
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button color="blue" style={{float : "right"}}
                    onClick={() => (document.querySelector("#termsOfUseInput") as HTMLInputElement).click()}>
              + Atualizar Termos de uso
            </Button>

            <input type="file" id="termsOfUseInput" 
                  accept="application/pdf" 
                  style={{ display: "none"}} name="file" 
                  onChange={termsOfUseChangeHandler} />
          </div>
        </div>

        <div style={{ borderStyle: "dashed", marginTop: 5, borderWidth: "1px", 
                      borderColor: "#9E9E9E", height: 200, display: "flex", 
                      flexDirection: "row", width: "100%", verticalAlign: "middle", 
                      textAlign: "center"}} >
            {historyTerms.length === 0 && (
              <span style={{width: "100%", height: "200px", marginTop: 90}}>
                Ainda não registrada nenhuma versão de temos de uso
              </span>
            )}
            {historyTerms.length > 0 && (
              <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}} >
                <div style={{ display: "flex", flexDirection: "column", padding: 10, width: "60%" }}>
                <iframe title="Termos de Uso" src={term.terms_of_use_url} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", 
                              width: "40%", borderStyle: "dashed", borderColor: "#9E9E9E",  
                              borderWidth: "0px 0px 0px 1px"}}>
                  {historyTerms && historyTerms.length > 0 && (
                    <div style={{ paddingBottom: 15, maxHeight: "250px", overflow: "auto" }}>
                      <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                          <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                            <th
                              className="quantity-employees"
                              style={{ textAlign: "end", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                            >
                              {"Versão"}
                            </th>
                            <th
                              className="quantity-employees"
                              style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                            >
                              {"Data"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {historyTerms.map((item: any, index: number) => {
                            return (
                              <React.Fragment key={index}>
                                <tr>
                                  <td style={{ padding: "10px" }}>
                                    <span className="list-text">
                                      {item.version}
                                    </span>
                                  </td>
                                  <td style={{ padding: "10px" }}>
                                    <span className="list-text">
                                      {item.date}
                                    </span>
                                  </td>
                                  <td>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                      <button
                                        className="button-edit"
                                        onClick={() => handleOpenModalDetails(item)}
                                      >
                                        Visualizar
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>

        <div style={{ display: "flex", flexDirection: "row", marginTop: 5, 
                      justifyContent: "space-between"}} >
          <div style={{ display: "flex", flexDirection: "column", marginTop: 15 }}>
            <TitleText level={1} title="Políticas de privacidade" />
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button color="blue" style={{float : "right"}}
                    onClick={() => (document.querySelector("#PoliciesInput") as HTMLInputElement).click()}>
              + Atualizar Políticas
            </Button>

            <input type="file" id="PoliciesInput" 
                  accept="application/pdf" 
                  style={{ display: "none"}} name="file" 
                  onChange={policiesChangeHandler} />
          </div>
        </div>
        <div style={{ borderStyle: "dashed", marginTop: 5, borderWidth: "1px", 
                      borderColor: "#9E9E9E", height: 200, display: "flex", 
                      flexDirection: "row", width: "100%", verticalAlign: "middle", 
                      textAlign: "center"}} >
            {historyPolicies.length === 0 && (
              <span style={{width: "100%", height: "200px", marginTop: 90}}>
                Ainda não registrada nenhuma versão de politicas de privacidade
              </span>
            )}
            {historyPolicies.length > 0 && (
              <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}} >
                <div style={{ display: "flex", flexDirection: "column", padding: 10, width: "60%" }}>
                  <iframe title="Politicas de Uso" src={term.usage_policies_url}/>
                </div>
                <div style={{ display: "flex", flexDirection: "column", 
                              width: "40%", borderStyle: "dashed", borderColor: "#9E9E9E",  
                              borderWidth: "0px 0px 0px 1px"}}>
                  {historyPolicies && historyPolicies.length > 0 && (
                    <div style={{ paddingBottom: 15, maxHeight: "250px", overflow: "auto" }}>
                      <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                          <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                            <th
                              className="quantity-employees"
                              style={{ textAlign: "end", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                            >
                              {"Versão"}
                            </th>
                            <th
                              className="quantity-employees"
                              style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                            >
                              {"Data"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {historyPolicies.map((item: any, index: number) => {
                            return (
                              <React.Fragment key={index}>
                                <tr>
                                  <td style={{ padding: "10px" }}>
                                    <span className="list-text">
                                      {item.version}
                                    </span>
                                  </td>
                                  <td style={{ padding: "10px" }}>
                                    <span className="list-text">
                                      {item.date}
                                    </span>
                                  </td>
                                  <td>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                      <button
                                        className="button-edit"
                                        onClick={() => handleOpenModalDetails(item)}
                                      >
                                        Visualizar
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>
      </form>

      {loadingModal && (
        <Loading
          isOpen={loadingModal}
        />
      )}

      {history && openModalDetails && (
        <ModalDetails
          history={history}
          isOpen={openModalDetails}
          handleModalVisible={handleOpenModalDetails}
        />
      )}
      
    </Container>
  );
};
