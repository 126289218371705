import styled from "styled-components";

type StatusLabelProps = {
  status: string | undefined;
};

export const Container = styled.div<StatusLabelProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 6px 8px;
  border-radius: 2.5px;

  .status-text {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 19px;
  }

  .bulled-0-35{
    border: 1px solid #C42329;
    color: #C42329;
    background: #FBACAF;
    padding: 5px 10px;
    border-radius: 5px;
    width: 90px;
    padding: 8px;
    vertical-align:middle;
    font-weight: bold;
    text-align: center;
  }

  .bulled-36-99{
    border: 1px solid #D37620;
    color: #D37620;
    background: #F7DEC6;
    padding: 5px 10px;
    border-radius: 5px;
    width: 90px;
    padding: 8px;
    vertical-align:middle;
    font-weight: bold;
    text-align: center;
  }

  .bulled-100{
    border: 1px solid #81c787;
    color: #81c787;
    background: #D1EDE6;
    padding: 5px 10px;
    border-radius: 5px;
    width: 90px;
    padding: 8px;
    vertical-align:middle;
    font-weight: bold;
    text-align: center;
  }

  word-wrap: break-word;
`;
