import { Container } from './styles';
import LoadingImg from '../../../../../assets/images/loading.gif';
import ReactModal from "react-modal";

type ModalDetailsProps = {
    isOpen: boolean;
    text?: string;
}

const LoadingScopeEvidenceAttachments = ({ isOpen, text }: ModalDetailsProps) => {
    return (
        <Container>
            <div style={{textAlign: "center"}} className='content'>
                
                <div>
                    <img src={LoadingImg} alt="loading" width='50' />
                </div>

                <div>
                    <span style={{width: "100%", height: "200px", marginTop: 90}}>
                        {text ? (
                            text
                        ): ( 'Aguarde ...' )}
                    </span>
                </div>
            </div>
        </Container>
    )
};

export default LoadingScopeEvidenceAttachments;