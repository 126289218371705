import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../../components/Button/styles";
import { GoBack } from "../../../../components/GoBack";
import { TitleText } from "../../../../components/TitleText";
import useAuth from "../../../../hooks/useAuth";
import inventoryService from "../../../../services/inventoryService";
import { Container } from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

interface Inputs {
  method_cross_sector_tools: string;
  method_sector_specific_tools: string;
  method_emission_factors: string;
  method_intersectional: string;
  method_cross_sector_tools_na: boolean;
  method_sector_specific_tools_na: boolean;
  method_emission_factors_na: boolean;
  method_intersectional_na: boolean;
}

export const InventoryMethod = () => {
  const history = useHistory();

  const [inventorySelected, setInventorySelected] = useState<any>({});
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [crossSectorToolsNa, setCrossSectorToolsNa] = useState<boolean | undefined>();
  const [sectorSpecificToolsNa, setSectorSpecificToolsNa] = useState<boolean | undefined>();
  const [emissionFactorsNa, setEmissionFactorsNa] = useState<boolean | undefined>();
  const [intersectionalNa, setIntersectionalNa] = useState<boolean | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [anonymize, setAnonymize] = useState(false);
  const [onlyView, setOnlyView] = useState(false);

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.company_id && item.year){
      getInventorysById(item.company_id, item.year)

      setAnonymize(user.pseudo_anonymize)
      setOnlyView(user.profile !== 'sinergia' && user.profile !== 'admin' && user.profile !== 'analyst')
    }else{
      history.push({
        pathname: '/inventario',
      });
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);

  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const newInventory = {
        method_cross_sector_tools: crossSectorToolsNa ? 'N/A' : formData.method_cross_sector_tools,
        method_sector_specific_tools: sectorSpecificToolsNa ? 'N/A' : formData.method_sector_specific_tools,
        method_emission_factors: emissionFactorsNa ? 'N/A' : formData.method_emission_factors,
        method_intersectional: intersectionalNa ? 'N/A' : formData.method_intersectional
      };

      const result = await inventoryService.updateMethodId(
        inventorySelected.company.id, inventorySelected.year, newInventory
      );

      if (result && !result.error) {
       // history.goBack()

       // reset();
        //setErrorApi(undefined);
          
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error);
      }
    }
  };

  async function getInventorysById(company_id: number, year: number) {
    const inventory = await inventoryService.getById(company_id, year);

    setValue("method_cross_sector_tools", inventory?.method_cross_sector_tools);
    setValue("method_sector_specific_tools", inventory?.method_sector_specific_tools);
    setValue("method_emission_factors", inventory?.method_emission_factors);
    setValue("method_intersectional", inventory?.method_intersectional);

    setCrossSectorToolsNa(inventory?.method_cross_sector_tools === 'N/A')
    setSectorSpecificToolsNa(inventory?.method_sector_specific_tools === 'N/A')
    setEmissionFactorsNa(inventory?.method_emission_factors === 'N/A')
    setIntersectionalNa(inventory?.method_intersectional === 'N/A')

    setValue("method_cross_sector_tools_na", inventory?.method_cross_sector_tools === 'N/A');
    setValue("method_sector_specific_tools_na", inventory?.method_sector_specific_tools === 'N/A');
    setValue("method_emission_factors_na", inventory?.method_emission_factors === 'N/A');
    setValue("method_intersectional_na", inventory?.method_intersectional === 'N/A');

    setInventorySelected(inventory)
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <div style={{ display: "flex", flexDirection: "column"}} >
            <TitleText level={1} title={'Inventário: ' + inventorySelected?.year + ' - ' + inventorySelected?.company?.corporate_name}  />
            <TitleText level={4} title="Métodos do inventário"  />
          </div>
          
          {onlyView === false && (
            <Button color="green" size="md" type="submit">
              Salvar
            </Button>
          )}
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 30, marginLeft: 50, marginRight: 50  }} >

       {/*    <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Métodos e/ou ferramentas intersetoriais 
          </label>

          <label style={{ fontFamily: "'Roboto', sans-serif", fontSize: "12px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Foi utilizado algum método e/ ou ferramenta inter-setorial além daquelas fornecidas pelo Programa Brasileiro GHG Protocol?
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={crossSectorToolsNa || onlyView}
              {...register("method_cross_sector_tools", { required: !crossSectorToolsNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView}
                {...register("method_cross_sector_tools_na")}
                  onChange={(event) => {
                    if (crossSectorToolsNa === false){
                        setValue("method_cross_sector_tools", "N/A")
                    }

                    setCrossSectorToolsNa(!crossSectorToolsNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica
            </label>
          </div> */}
                      
          {errors.method_cross_sector_tools && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Métodos e/ou ferramentas para setores específicos.
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={sectorSpecificToolsNa || onlyView}
              {...register("method_sector_specific_tools", { required: !sectorSpecificToolsNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView} 
                {...register("method_sector_specific_tools_na")}
                  onChange={(event) => {
                    if (crossSectorToolsNa === false){
                        setValue("method_sector_specific_tools", "N/A")
                    }

                    setSectorSpecificToolsNa(!sectorSpecificToolsNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica.
            </label>
          </div>
                      
          {errors.method_sector_specific_tools && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Fatores de emissão.
          </label>  

          <label style={{ fontFamily: "'Roboto', sans-serif", fontSize: "12px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Foi utilizado algum fator de emissão diferente daqueles sugeridos pelo Programa Brasileiro GHG Protocol?
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={emissionFactorsNa || onlyView}
              {...register("method_emission_factors", { required: !emissionFactorsNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView} 
                {...register("method_emission_factors_na")}
                  onChange={(event) => {
                    if (emissionFactorsNa === false){
                        setValue("method_emission_factors", "N/A")
                    }

                    setEmissionFactorsNa(!emissionFactorsNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica.
            </label>
          </div>
                      
          {errors.method_emission_factors && (
              <span className='requiredLabel' >Esse campo é obrigatório.</span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold", width: "80%" }} >
              Foi utilizado algum método e/ ou ferramenta inter-setorial além daquelas fornecidas pelo Programa Brasileiro GHG Protocol?
          </label>  

          <label style={{ fontFamily: "'Roboto', sans-serif", fontSize: "12px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold", width: "80%" }} >
              Para os cálculos atrelados aos resíduos sólidos (incineração e coprocessamento), as fórmulas de cálculo foram extraídas do IPCC (Intergovernmental Panel on Climate Change), 6° Relatório de Avaliação - AR6. Climate Change 2021: The Physical Science Basis, 2021.
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={intersectionalNa || onlyView}
              {...register("method_intersectional", { required: !intersectionalNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView} 
                {...register("method_intersectional_na")}
                  onChange={(event) => {
                    if (emissionFactorsNa === false){
                        setValue("method_intersectional", "N/A")
                    }

                    setIntersectionalNa(!intersectionalNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica.
            </label>
          </div>
                      
          {errors.method_intersectional && (
              <span className='requiredLabel' >Esse campo é obrigatório.</span>
          )}
        </div>
      </form>
    </Container>
  );
};
