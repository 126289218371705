import { api } from "./api";

const getInformation = async () => {
    try {
        const { data } = await api.get(`/import_manager/logs`);
        return data;
    } catch (error) {
        console.error(error);
        return error;
    };
};

const getInformationById = async (id: number) => {
    try {
        const { data } = await api.get(`/import_manager/logs/${id}`);
        return data;
    } catch (error) {
        console.error(error);
        return error;
    };
};

const importManagerService = {
    getInformation,
    getInformationById,
}

export default importManagerService;