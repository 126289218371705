import { useMenu } from "../../contexts/menuContext";
import { Container, Main, Section, Highlight } from "./styles";

interface BoxResultProps {
    result: string | number;
    fossil: boolean;
    text?: string;
    style?: any;
    observation?: string;
};

const BoxResult = ({ result, fossil, text, style, observation}: BoxResultProps) => {
    const { isMenuOpen } = useMenu();

    return (
        <Container isMenuOpen={isMenuOpen} style={style ? style : null} observation={observation ? true : false}>
            <Main>
                {text ? (
                    text
                ):(
                    fossil ? ("Emissões totais em CO₂ equivalente (toneladas métricas):") : ("Emissões totais em CO₂ biogênico (toneladas métricas):")
                )}
                {observation &&
                    <Highlight>
                        {observation}
                    </Highlight>
                }
            </Main>
            <Section>
                {result}
            </Section>
            
        </Container>
    );
};

export default BoxResult;