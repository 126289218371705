import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';
import { styles } from '../../../../components/PDF/styles/common';
import { colors } from '../../../../components/PDF/styles/colors';

import checkImage from '../../../../assets/images/check.png'; // Caminho para a sua imagem de check
import alertImage from '../../../../assets/images/alert.png'; // Caminho para a sua imagem de alerta

const ResumeProjectTable: React.FC<{
  project: string | React.ReactNode;
  tCO2: number | React.ReactNode;
  cost: number | React.ReactNode;
}> = (props) => {
 
  const symbolImage = Number(props.cost) < 0 ? checkImage : alertImage;
  const alertHeight = 10; 
  const alertOriginalWidth = 50; 
  const alertOriginalHeight = 132; 
  const alertProportionalWidth = (alertOriginalWidth / alertOriginalHeight) * alertHeight;
  const isAlertImage = Number(props.cost) >= 0;
  const imageWidth = isAlertImage ? alertProportionalWidth : 10; 
  return (
    <View
      style={{
        ...styles.row,
        borderBottom: `1px solid ${colors.darkGrey}`,
        borderLeft: `1px solid ${colors.darkGrey}`,
        borderRight: `1px solid ${colors.darkGrey}`,
        padding: 5,
      }}
    >
      <Text style={{ ...styles.text, width: '33.33%' }}>{props.project}</Text>
      <Text style={{ ...styles.text, width: '33.33%' }}>
        {Number(props.tCO2).toLocaleString('pt-BR', { minimumFractionDigits: 4 })}
      </Text>
      <View style={{ width: '33.33%', flexDirection: 'row', alignItems: 'center' }}>
        <Image
           src={symbolImage}
          style={{
            width: imageWidth, 
            height: 10, 
            marginRight: 5, 
          }}
        />
        <Text style={styles.text}>
          {Number(props.cost).toLocaleString('pt-BR', { minimumFractionDigits: 4 })}
        </Text>
      </View>
    </View>
  );
};

export default ResumeProjectTable;
