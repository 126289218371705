import React, { useState, useEffect } from 'react';
import { View, Text, Image as ImagePDF} from '@react-pdf/renderer';
import { styles } from '../styles/common';
import sinergiaLogo from '../../../assets/images/sinergiaLogo.png';

interface HeaderProps {
  title: string;
  companyName: string;
  logo?: string;
}

const Header: React.FC<HeaderProps> = ({ title, companyName, logo }) => {
  const [logoWidth, setLogoWidth] = useState<number | null>(null);

  useEffect(() => {
    if (logo) {
      const img = new Image();
      img.onload = function() {
        const originalWidth = (this as HTMLImageElement).width;
        const originalHeight = (this as HTMLImageElement).height;
        const targetHeight = 45;
        const scaleFactor = targetHeight / originalHeight;
        const calculatedWidth = originalWidth * scaleFactor;
        setLogoWidth(calculatedWidth);
      };
      img.src = logo;
    }
  }, [logo]);

  return (
    <View style={styles.headerContainer} fixed>
      <View style={styles.headerMainContent}>
        
        <View style={styles.headerTitleSection}>
          <Text style={{...styles.headerTitle, ...styles.Bold}}>{title}</Text>
          <Text style={{...styles.headerTitle, ...styles.Bold}}>{companyName}</Text>
        </View>
        <View style={styles.logoLine} />
        <View style={styles.headerLogosSection}>
          {logo && logoWidth && (
            <ImagePDF style={{ width: logoWidth, height: 40}} src={logo} />
          )}
          <ImagePDF style={{  width: 75, height: 30, marginRight: 20, marginTop: 5 }} src={sinergiaLogo} />
        </View>
      </View>

      {/* Linha de separação */}
      <View style={styles.separatorLine} />
    </View>
  );
};

export default Header;
