import {
  Page,
  Text,
  Image as ImagePDF,
  View,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";
import React from "react";
import { Logos } from "../../components/Logos";
import { Header } from "../../components/Header";
import { styles } from "../../styles";
import { IGEEProps } from "../../../interfaces/IGEEData";
import instaIcon from "../../../../../../../assets/icons/instagram-icon.png";
import phoneIcon from "../../../../../../../assets/icons/telefone-icon.png";
import emailIcon from "../../../../../../../assets/icons/email-icon.png";
import sinergiaAzul from "../../../../../../../assets/images/logo-azul.png";

export const Page6: React.FC<IGEEProps> = (props) => {
  const { resume } = props;
  const { data } = props;

  const formatPhoneNumber = (phoneNumber: any) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumber;
  };

  const leftInfo = [
    {
      title: "Ano inventariado: ",
      content: data?.year,
    },
    {
      title: "Tipo de inventário: ",
      content: resume?.igee_itens?.inventory_type,
    },
    {
      title: "Responsável pela elaboração do inventário: ",
      content: resume?.igee_itens.responsible.name,
    },
    {
      title: "Contatos do responsável pela elaboração:",
    },
    // {
    //   title: "Organismo verificador: ",
    //   content: resume?.igee_itens.third_party.organism,
    // },
  ];
  
  const contacts = [
    { label: resume?.igee_itens.responsible.contacts_responsible.email, icon: emailIcon },
    { label: formatPhoneNumber(resume?.company_itens.contacts.phone_number), icon: phoneIcon },
    { label: resume?.company_itens.contacts.instagram, icon: instaIcon },
  ];

  const rightInfo = [
    {
      title: "Nome do responsável pela verificação: ",
      content: resume?.igee_itens.third_party.controller_name.toString(),
    },
    {
      title: "Desk Review"
    },
    {
      title: "Nome do profissional:",
      content: resume?.igee_itens.third_party.name_professional
    },
    {
      title: "Número da Art:",
      content: resume?.igee_itens.third_party.number_art
    },
    {
      title: "Registro no Crea:",
      content: resume?.igee_itens.third_party.record_crea
    },
    {
      title: "Profissão:",
      content: resume?.igee_itens.third_party.profession
    },
  ];

  const contactsChecker = [
    { label: data?.third_party_controller_mail, icon: emailIcon },
    { label: formatPhoneNumber(data?.third_party_controller_phone), icon: phoneIcon },
  ];



  return (
    <Page size={[640, 480]} style={styles.Page}>
      <Header backColor="#326ed6" title="3. Dados do inventário" />
      <View style={styles.body}>
        <View style={local.section}>
          <View style={{ height: "100%" }}>
            {leftInfo.map((info, idx) => (
              <View style={local.item1} key={idx}>
                <Text style={{ ...local.itemTitle1, ...styles.Bold }}>
                  {info.title}
                </Text>
                <Text style={{ ...local.itemContent, ...styles.light }}>
                  {info.content}
                </Text>
              </View>
            ))}
            {contacts.map((contact, idx) => {
              if (!contact.label || contact.label === null) {
                contact.label = "-";
              }

              return (
                <View
                  style={{
                    position: "absolute",
                    top: `${43 + 8 * idx}%`,
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  key={idx}
                >
                  <ImagePDF src={contact.icon} style={{...styles.blueBall}} />
                  <Text style={styles.iconContentBlue}>{contact.label}</Text>
                </View>
              );
            })}
            <Text style={{ ...local.itemTitle1, ...styles.Bold, marginLeft: "10px", marginTop: 120 }}>
              O inventário foi verificado por terceira parte:
            </Text>
            <Text style={{ ...local.itemContent, ...styles.light, marginLeft: "10px" }}>
              {data?.third_party_verified ? "Sim": "Não"}
            </Text>
            <Text style={{ ...local.itemTitle1, ...styles.Bold, marginLeft: "10px", marginTop: 10 }}>
              Organismo verificador:
            </Text>
            <Text style={{ ...local.itemContent, ...styles.light, marginLeft: "10px" }}>
              {resume?.igee_itens.third_party.organism}
            </Text>
          </View>
        </View>
        <View style={local.section2}>
          <View style={{ height: "100%" }}>
            <Text style={{ ...local.itemTitle1, ...styles.Bold, marginLeft: "10px", marginTop: 10 }}>
              Contatos do responsável pela verificação:
            </Text>
            {contactsChecker.map((contact, idx) => {
              if (!contact.label || contact.label === null) {
                contact.label = "-";
              }

              return (
                <View
                  style={{
                    position: "absolute",
                    top: `${50 + 8 * idx}%`,
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  key={idx}
                >
                  <ImagePDF src={contact.icon} style={styles.blueBall2} />
                  <Text style={styles.iconContentBlue2}>{contact.label}</Text>
                </View>
              );
            })}
            {rightInfo.map((info, idx) => (
              <View style={local.item2} key={idx}>
                <Text style={{ ...local.itemTitle2, ...styles.Bold}}>
                  {info.title}
                </Text>
                <Text style={{ ...local.itemContent2, ...styles.light }}>
                  {info.content}
                </Text>
              </View>
            ))}
          </View>
          {/* <View>
            <ImagePDF 
              src={resume?.company_itens.logo_image_url}
                style={{
                width: 60,
                right: '100px',
                bottom: '6px',
                position: 'absolute'
              }}
              />
            <ImagePDF 
              src={sinergiaAzul}
              style={{
                width: 60,
                right: '30px',
                bottom: '10px',
                position: 'absolute'
              }}
            />
          </View> */}
          <View style={{ position: 'absolute', bottom: 10, right: 10, flexDirection: 'row', alignItems: 'center' }}>
              <ImagePDF
                src={resume?.company_itens.logo_image_url}
                style={{
                  width: 70,
                  height: 'auto',
                  marginRight: 10
                }}
              />
              <ImagePDF
                src={sinergiaAzul}
                style={{
                  width: 70,
                  height: 'auto',
                  marginRight: 10
                }}
              />
          </View>
        </View>
      </View>
    </Page>
  );
};

const local = StyleSheet.create({
  section: {
    marginHorizontal: "20px",
    width: "50%",
    height: "100%",
    borderRight: "0.5px solid #326ed6",
  },
  section2: {
    width: "50%",
    height: "100%",
  },
  item1: {
    margin: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "95%",
  },
  item2: {
    marginLeft: "10px",
    marginBottom: "2px",
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "95%",
  },
  itemTitle1: {
    color: "#326ed6",
    fontSize: 12,
    top: 5,
  },
  itemTitle2: {
    color: "#326ed6",
    fontSize: 12,
    top: 80,
  },
  itemContent: {
    color: "#326ed6",
    fontSize: 11,
    top: 5,
  },
  itemContent2: {
    color: "#326ed6",
    fontSize: 11,
    top: 80,
  },
});
