import React from 'react';
import { Container } from '../styles';
import { useForm } from 'react-hook-form';
import { formatPrice6 } from '../../../../../utils/transform';

type EmissionFactorsTableProps = {
    factorOxideNitrousFirst: number;
    firstFactorMethaneDbo: number;
    factorOxideNitrousSecond: number;
    secondFactorMethaneDbo: number;
    factorOxideNitrousFinal: number;
    finalFactorMethaneDbo: number;
    register: (value: string) => any;
};

export const EmissionFactorsTable: React.FC<EmissionFactorsTableProps> = ({ 
    factorOxideNitrousFirst, 
    firstFactorMethaneDbo,
    factorOxideNitrousSecond,
    secondFactorMethaneDbo,
    factorOxideNitrousFinal,
    finalFactorMethaneDbo,
    register 
}) => {
  return (
    <Container>
      <table style={{ width: '99%', borderCollapse: 'collapse', border: '1px solid #ddd' }}>
        <thead>
          <tr style={{ backgroundColor: '#f4f4f4', borderBottom: '2px solid #ccc' }}>
            <th style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'left' }}>Fator de Emissão</th>
            <th style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>1º Tratamento</th>
            <th style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>2º Tratamento</th>
            <th style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>Disposição Final</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '5px' }}>
                Fator de emissão de metano (kgCH₄/kgDBO)
            </td>
            <td style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>
                {formatPrice6(firstFactorMethaneDbo, false)}
            </td>
            <td style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>
                {formatPrice6(secondFactorMethaneDbo, false)}
            </td>
            <td style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>
                {formatPrice6(finalFactorMethaneDbo, false)}
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '5px' }}>
                Fator de emissão de óxido nitroso (kgN₂O/kgN)
            </td>
            <td style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>
                {formatPrice6(factorOxideNitrousFirst, false)}
            </td>
            <td style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>
                {formatPrice6(factorOxideNitrousSecond, false)}
            </td>
            <td style={{ border: '1px solid #ddd', padding: '5px', textAlign: 'center' }}>
                {formatPrice6(factorOxideNitrousFinal, false)}
            </td>
          </tr>
        </tbody>
      </table>
    </Container>
  );
};
