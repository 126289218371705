import { Container } from "./styles";

export const TableWaste = () => {
  return (
    <Container>
      <tbody>
        <tr>
          <td style={{ width: "25%" }} rowSpan={8}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                fontWeight: "bold",
                display: "inline-block",
              }}
            >
              Qualidade do local de disposição dos resíduos
            </span>
          </td>
          <td style={{ width: "25%" }}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                fontWeight: "bold",
                display: "inline-block",
              }}
            >
              A: se aterro sanitário
            </span>
          </td>
          <td style={{ width: "50%" }}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                display: "inline-block",
              }}
            >
              Devem ter controle do aterramento de resíduo (ex. resíduo destinado especificamente para uma área do aterro, controle de escavação e de chamas), incluindo ao menos um dos seguintes métodos: (i) material de cobertura; (ii) compactação mecânica; ou (iii) nivelamento do resíduo.
            </span>
          </td>
        </tr>
        <tr>
          <td style={{ width: "25%" }}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                fontWeight: "bold",
                display: "inline-block",
              }}
            >
              {"B: se aterro semi-aeróbico"}
            </span>
          </td>
          <td style={{ width: "50%" }}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                display: "inline-block",
              }}
            >
              Devem ter controle do aterramento de resíduo e incluir todas as seguintes estruturas para introduzir ar nas camadas de resíduos: (i) material de cobertura permeável; (ii) sistema de drenagem de chorume sem afundamento; (iii) tanque de regulação; (iv) sistema de ventilação de gases sem tampa e (v) conexão dos sistemas de drenagem e de ventilação.
            </span>
          </td>
        </tr>
        <tr>
          <td style={{ width: "25%" }}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                fontWeight: "bold",
                display: "inline-block",
              }}
            >
              {"C: se aterro semi-aeróbio (mal manejado)"}
            </span>
          </td>
          <td style={{ width: "50%" }}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                display: "inline-block",
              }}
            >
              É considerado um aterro semi-aeróbio mal manejado nos casos em que: (i) sistema de drenagem de chorume afundado; (ii) fechamento da válvula de drenagem (iii) obstrução da saída de gás do sistema de ventilação.
            </span>
          </td>
        </tr>
        <tr>
          <td style={{ width: "25%" }}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                fontWeight: "bold",
                display: "inline-block",
              }}
            >
              D: se aterro com aeração ativa
            </span>
          </td>
          <td style={{ width: "50%" }}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                display: "inline-block",
              }}
            >
              Inclui a tecnologia de aeração in situ de baixa pressão, aspersão de ar, bioventilação, ventilação passiva com extração (sucção). Devem ter controle do aterramento e incluir sistema de drenagem de chorume para evitar o bloqueio da entrada de ar. Deve conter: (i) material de cobertura; (ii) sistema de injeção de ar ou extração de gás sem secagem de resíduos.
            </span>
          </td>
        </tr>
        <tr>
          <td style={{ width: "25%" }}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                fontWeight: "bold",
                display: "inline-block",
              }}
            >
              E: se aterro com aeração ativa (mal manejado)
            </span>
          </td>
          <td style={{ width: "50%" }}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                display: "inline-block",
              }}
            >
              Inclui as tecnologias do aterro acima, porém é considerado mal manejado por conta de: (i) bloqueio do sistema de aeração por falha na drenagem; (ii) falta de umidade para microorganismos devido à aeração de alta pressão.
            </span>
          </td>
        </tr>
        <tr>
          <td style={{ width: "25%" }}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                fontWeight: "bold",
                display: "inline-block",
              }}
            >
             {'F: se aterro com profundidade >= 5m'}
            </span>
          </td>
          <td style={{ width: "50%" }}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                display: "inline-block",
              }}
            >
              Todos os aterros que não atendem aos critérios dos aterros sanitário e semi-aeróbio, e que possuem profundidade igual ou maior a 5 m e/ou alto nível do lençol freático (próximo à superfície). Este último critério corresponde ao preenchimento de antigos corpos d'água por resíduos (ex. lagos ou rios vazios).
            </span>
          </td>
        </tr>
        <tr>
          <td style={{ width: "25%" }}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                fontWeight: "bold",
                display: "inline-block",
              }}
            >
             {'G: se aterro com profundidade < 5m'}
            </span>
          </td>
          <td style={{ width: "50%" }}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                display: "inline-block",
              }}
            >
              Todos os aterros que não atendem aos critérios dos aterros sanitários e semi-aeróbios, e que possuem profundidade menor que 5 metros.
            </span>
          </td>
        </tr>
        <tr>
          <td style={{ width: "25%" }}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                fontWeight: "bold",
                display: "inline-block",
              }}
            >
             {'H: se não possui a classificação do aterro'}
            </span>
          </td>
          <td style={{ width: "50%" }}>
            <span
              style={{
                marginLeft: 5,
                width: "100%",
                fontSize: 12,
                display: "inline-block",
              }}
            >
              Aterros com classificação desconhecida e que não se encaixe em nenhuma das categorias acima.
            </span>
          </td>
        </tr>
      </tbody>
    </Container>
  );
};
