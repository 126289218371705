import { Container } from "./styles";

type DefaultTextProps = {
  title: string | undefined;
  bold?: boolean | undefined;
};

export const DefaultText = ({ title, bold }: DefaultTextProps) => {
  return <Container bold={bold}>{title}</Container>;
};
