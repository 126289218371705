import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import gasService from "../../../services/gasService";
import { Container } from "./styles";
import { formatPrice3 } from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

interface Inputs {
  gwp: any;
  name: string;
  family: string;
  reference: string;
  fe_percent_1: any,
  fe_percent_2: any,
  fe_percent_3: any,
  fe_percent_4: any,
  fe_percent_5: any,
  description: string;
  gwp_non_kyoto: any;
  is_fugitive_emissions: boolean;
  is_changes_in_land_use: boolean;
  is_industrial_processes: boolean;
  is_agricultural_activities: boolean;
  is_fugitive_emissions_stock: boolean;
  is_non_kioto_fugitive_emissions: boolean;
}
 
export const GasEdit = () => {
  const history = useHistory();

  const [gasSelected, setGasSelected] = useState<any>({});
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.gas_id){
      getGassById(item.gas_id)
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const gwp = formData.gwp.replace(".", "");
      const fe_percent_1 = formData.fe_percent_1.replace(".", "");
      const fe_percent_2 = formData.fe_percent_2.replace(".", "");
      const fe_percent_3 = formData.fe_percent_3.replace(".", "");
      const fe_percent_4 = formData.fe_percent_4.replace(".", "");
      const fe_percent_5 = formData.fe_percent_4.replace(".", "");
      const gwp_non_kyoto = formData.gwp_non_kyoto.replace(".", "");

      const newGas = {
        name: formData.name,
        family: formData.family,
        reference: formData.reference,
        description: formData.description,
        gwp: gwp ? parseFloat(gwp.replace(",", ".")) : 0,
        is_fugitive_emissions: formData.is_fugitive_emissions,
        is_changes_in_land_use: formData.is_changes_in_land_use,
        is_industrial_processes: formData.is_industrial_processes,
        is_agricultural_activities: formData.is_agricultural_activities,
        is_fugitive_emissions_stock: formData.is_fugitive_emissions_stock,
        is_non_kioto_fugitive_emissions: formData.is_non_kioto_fugitive_emissions,
        fe_percent_1: fe_percent_1 ? parseFloat(fe_percent_1.replace(",", ".")) : 0,
        fe_percent_2: fe_percent_2 ? parseFloat(fe_percent_2.replace(",", ".")) : 0,
        fe_percent_3: fe_percent_3 ? parseFloat(fe_percent_3.replace(",", ".")) : 0,
        fe_percent_4: fe_percent_4 ? parseFloat(fe_percent_4.replace(",", ".")) : 0,
        fe_percent_5: fe_percent_5 ? parseFloat(fe_percent_5.replace(",", ".")) : 0,
        gwp_non_kyoto: gwp_non_kyoto ? parseFloat(gwp_non_kyoto.replace(",", ".")) : 0,
      };

      if (gasSelected?.id) {
        const result = await gasService.updateById(
          gasSelected.id,
          newGas
        );

        if (result && !result.error) {
         // history.goBack()

         // reset();
          //setErrorApi(undefined);

          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await gasService.create(newGas);

        if (result && !result.error) {
         // history.goBack()

         // reset();
         // setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  async function getGassById(id: number) {
    const gas = await gasService.getById(id);

    setValue("name", gas?.name);
    setValue("family", gas?.family);
    setValue("reference", gas?.reference);
    setValue("description", gas?.description);
    setValue("gwp", formatPrice3(Number(gas.gwp), false));
    setValue("is_fugitive_emissions", gas.is_fugitive_emissions);
    setValue("is_changes_in_land_use", gas.is_changes_in_land_use);
    setValue("is_industrial_processes", gas.is_industrial_processes);
    setValue("is_agricultural_activities", gas.is_agricultural_activities);
    setValue("fe_percent_1", formatPrice3(Number(gas.fe_percent_1), false));
    setValue("fe_percent_2", formatPrice3(Number(gas.fe_percent_2), false));
    setValue("fe_percent_3", formatPrice3(Number(gas.fe_percent_3), false));
    setValue("fe_percent_4", formatPrice3(Number(gas.fe_percent_4), false));
    setValue("fe_percent_5", formatPrice3(Number(gas.fe_percent_5), false));
    setValue("is_fugitive_emissions_stock", gas.is_fugitive_emissions_stock);
    setValue("gwp_non_kyoto", formatPrice3(Number(gas.gwp_non_kyoto), false));
    setValue("is_non_kioto_fugitive_emissions", gas.is_non_kioto_fugitive_emissions);
    
    setGasSelected(gas)
  }
    
  function currency(e: React.FormEvent<HTMLInputElement>) {
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d)(\d{4})$/, "$1,$2");
      value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
      return value;
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Gás do efeito estufa" />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 10, marginLeft: 50, marginRight: 50  }} >
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Nome
          </label>

          <input style={{width: '92%'}}
                {...register("name", { required: true })}/>
                      
          {errors.name && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 10 }}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "25%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Família
              </label>

              <input style={{width: '95%'}}
                    {...register("family")}/>
                          
              {errors.family && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "25%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  GWP
              </label>

              <input style={{width: '95%', height: 35}}
                    {...register("gwp", { required: true })}
                    onChange={(event) => {
                        setValue("gwp", currency(event));
                    }}/>
                          
              {errors.gwp && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "25%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  GWP (Não Kyoto)
              </label>

              <input style={{width: '95%', height: 35}}
                    {...register("gwp_non_kyoto", { required: true })}
                    onChange={(event) => {
                        setValue("gwp_non_kyoto", currency(event));
                    }}/>
                          
              {errors.gwp_non_kyoto && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "25%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Referência
              </label>

              <input style={{width: '95%'}}
                    {...register("reference", { required: true })}/>
                          
              {errors.reference && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
          </div>

          <label style={{ marginTop: 10, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Escopos
          </label>

          <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 0, marginBottom: 10 }}>
            <div style={{ display: "flex", flexDirection: "row", marginRight: 10, width: "34%" }}>
              <input style={{ height: "20px", width: "20px" }} type="checkbox" 
                  {...register("is_agricultural_activities")}/>

              <label style={{ marginTop: 10, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8" }} >
                  Escopo 01 - Atividades Agrícolas
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginRight: 10, width: "33%" }}>
              <input style={{ height: "20px", width: "20px" }} type="checkbox" 
                  {...register("is_fugitive_emissions")}/>

              <label style={{ marginTop: 10, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8" }} >
                  Escopo 01 - Emissões fugitivas
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginRight: 10, width: "33%" }}>
              <input style={{ height: "20px", width: "20px" }} type="checkbox" 
                  {...register("is_fugitive_emissions_stock")}/>

              <label style={{ marginTop: 10, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8" }} >
                  Escopo 01 - Emissões fugitivas (SF₆ / NF₃)
              </label>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 0, marginBottom: 10 }}>
            <div style={{ display: "flex", flexDirection: "row", marginRight: 10, width: "34%" }}>
              <input style={{ height: "20px", width: "20px" }} type="checkbox" 
                  {...register("is_changes_in_land_use")}/>

              <label style={{ marginTop: 10, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8" }} >
                  Escopo 01 - Mudanças no uso do solo
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginRight: 10, width: "33%" }}>
              <input style={{ height: "20px", width: "20px" }} type="checkbox" 
                  {...register("is_industrial_processes")}/>

              <label style={{ marginTop: 10, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8" }} >
                  Escopo 01 - Processos industriais
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginRight: 10, width: "33%" }}>
              <input style={{ height: "20px", width: "20px" }} type="checkbox" 
                  {...register("is_non_kioto_fugitive_emissions")}/>

              <label style={{ marginTop: 10, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8" }} >
                  Escopo 01 - Emissões fugitivas - GEE não Quioto
              </label>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 10 }}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "25%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Fator 01
              </label>

              <input style={{width: '95%', height: 35}}
                    {...register("fe_percent_1")}
                    onChange={(event) => {
                        setValue("fe_percent_1", currency(event));
                    }}/>
                          
              {errors.fe_percent_1 && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "25%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Fator 02
              </label>

              <input style={{width: '95%', height: 35}}
                    {...register("fe_percent_2")}
                    onChange={(event) => {
                        setValue("fe_percent_2", currency(event));
                    }}/>
                          
              {errors.fe_percent_2 && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "25%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Fator 03
              </label>

              <input style={{width: '95%', height: 35}}
                    {...register("fe_percent_3")}
                    onChange={(event) => {
                        setValue("fe_percent_3", currency(event));
                    }}/>
                          
              {errors.fe_percent_3 && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "25%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Fator 04
              </label>

              <input style={{width: '95%', height: 35}}
                    {...register("fe_percent_4")}
                    onChange={(event) => {
                        setValue("fe_percent_4", currency(event));
                    }}/>
                          
              {errors.fe_percent_4 && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "25%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Fator 05
              </label>

              <input style={{width: '95%', height: 35}}
                    {...register("fe_percent_5")}
                    onChange={(event) => {
                        setValue("fe_percent_5", currency(event));
                    }}/>
                          
              {errors.fe_percent_5 && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
          </div>
          
          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Descrição
          </label>

          <textarea style={{width: '92%', height: 100}}
                {...register("description")}/>
                      
          {errors.description && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>
      </form>
    </Container>
  );
};
