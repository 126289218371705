import { api } from "./api";

async function getById(userId: number) {
  try {
    const { data } = await api.get(`/user/${userId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByToken(token: string) {
  try {
    const { data } = await api.get(`/user/by_token/${token}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByProfile(companyId: number,  profile: string) {
  try {
    const { data } = await api.get(`/user/by_profile/${companyId}/${profile}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/user", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}


async function create(user: any) {
  try {
    const { data } = await api.post(`/user`, user);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function register(user: any) {
  try {
    const { data } = await api.post(`/user/register`, user);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(userId: number, user: any) {
  try {
    const { data } = await api.put(`/user/${userId}`, user);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function unlockById(userId: number) {
  try {
    const { data } = await api.put(`/user/unlock/${userId}`);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function lockById(userId: number) {
  try {
    const { data } = await api.put(`/user/lock/${userId}`);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function reactiveById(userId: number) {
  try {
    const { data } = await api.put(`/user/reactivate/${userId}`);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function inactivateById(userId: number) {
  try {
    const { data } = await api.put(`/user/inactivate/${userId}`);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateByToken(token: string, user: any) {
  try {
    const { data } = await api.put(`/user/by_token/${token}`, user);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(userId: number) {
  try {
    const { data } = await api.delete(`/user/${userId}`);
    return data;
  } catch (error) {
    return false;
  }
}

const userService = {
  getById,
  create,
  lockById,
  register,
  unlockById,
  getByToken,
  updateById,
  deleteById, 
  getByParams,
  reactiveById,
  getByProfile,
  updateByToken, 
  inactivateById
};

export default userService;
