import Parser from 'html-react-parser'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Select from 'react-select'
import { formatInteger, formatPrice3, formatPrice5 } from '../../../../../utils/transform'
import useAuth from '../../../../../hooks/useAuth'
import { Container } from './styles'
import gasService from '../../../../../services/gasService'
import scope1Service from '../../../../../services/scope1Service'
import scopeService from '../../../../../services/scopeService'
import inventoryService from '../../../../../services/inventoryService'
import TrashIcon from '../../../../../assets/icons/trash.svg'
import InfoButtonMin from '../../../../../assets/icons/info-button.svg'
import { Modal } from '../../../../../components/Modal'
import { GoBack } from '../../../../../components/GoBack'
import { TitleText } from '../../../../../components/TitleText'
import { Button } from '../../../../../components/Button/styles'
import { Uninformed } from '../../../../../components/Uninformed'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import { toast } from 'react-hot-toast'
import { Loading } from '../../../../../components/Loading'
import { ModalDeleteDataTable } from '../../../../../components/ModalDeleteDataTable'

export const FugitiveEmissionsReport = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([])
  const [title, setTitle] = useState<string>('')
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false);
  const [guidelines, setGuidelines] = useState<string | undefined>()
  const [openModalInfo, setOpenModalInfo] = useState(false)
  // const [requiredItens, setRequiredItens] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const location = useLocation()

  const [scopeOther, setScopeOther] = useState<any>([])

  const [gases, setGases] = useState<any>([])

  const [changeOther, setChangeOther] = useState<string | undefined>()

  const [emissionTco2eOther, setEmissionTco2eOther] = useState<any>()
  const [openModalDelete, setOpenModalDelete] = useState(false);

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-1'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(1, 'emissoes_fugitivas', 'relato_de_emissoes')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  function handleOpenInfo() {
    setOpenModalInfo(!openModalInfo)
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors }
  } = useForm<any>()
  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

      if (formData) {

        if(openModalDelete) {
          setLoadingModal(false);
          return;
        }
        const other: any[] = scopeOther.map((item: any, index: number) => {
          return {
            source: item.source,
            gas_id: item.gas_id,
            description: item.description,
            emissions_kg: item.emissions_kg,
            emission_tco2e: item.emission_tco2e
          }
        })

        const scope = {
          reason: formData.reason,
          evidence: formData.evidence,
          ignore_fill: formData.ignore_fill,
          other: other,
          status: 2
        }

        const result = await scope1Service.saveGeeReport(
          inventorySelected.company.id,
          inventorySelected.year,
          scope
        )

        if (result && !result.error) {
          localStorage.setItem('GeeReport', JSON.stringify(scope));

          setErrorApi(undefined)

          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error)
        }
      }

    setLoadingModal(false)
  }

  const { isCommonUser } = useAuth()

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const gasesItens = await gasService.getFugitiveEmissions()

    setGases(
      gasesItens?.itens.map((gas: any) => {
        return {
          label: gas.name,
          value: gas.id
        }
      })
    )

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope1Service.getGeeReport(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      1,
      'emissoes_fugitivas',
      'relato_de_emissoes'
    )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence_gee)
      setValue('ignore_fill', entity.ignore_fill)

      setIgnoreFill(entity.ignore_fill)

      setScopeSelected(entity)

      setScopeOtherValues(entity?.other)
      calculateOtherTotals(entity?.other)

      setChangeOther('load_scope_' + entity.id + '_scopeOther_' + entity?.other.length)
    } else {
      setScopeSelected({})

      setScopeOtherValues([])
      calculateOtherTotals([])

      setIgnoreFill(false)

      setChangeOther('load_scope_0_scopeOther_0')
    }

    setInventorySelected(inventory)

    setLoadingModal(false)
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{5})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos

    // Verifica se o valor é igual a zero após a formatação e remove os zeros à esquerda
    if (value === '') {
        value = '0';
    }

    return value;
  }

  const setScopeOtherValues = (itens: any, ignore_field?: string) => {
    let changeScope = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`other.${i}.source`, item.source)
        setValue(`other.${i}.description`, item.description)

        setValue(`other.${i}.gas_id`, item.gas_id)
        setValue(`other.${i}.gas_gwp`, item.gas_gwp)
        setValue(`other.${i}.gas_name`, item.gas_name)

        if ('emissions_kg' !== ignore_field) {
          setValue(`other.${i}.emissions_kg`, formatPrice5(item.emissions_kg, false))
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`other.${i}.emission_tco2e`, formatPrice5(item.emission_tco2e, false))
        }

        changeScope = changeScope + '_' + item.gas_id + '_' + item.amount
      }
    }

    setScopeOther(itens)
    setChangeOther(changeScope)
  }

  const handleChangeGasOther = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await gasService.getById(id)

      if (result) {
        const item = {
          gas_id: result.id,
          gas_name: result.name,
          gas_gwp: result.gwp,
          source: undefined,
          description: undefined,
          emissions_kg: undefined,
          emission_tco2e: undefined,
          gas_description: result.description
        }

        const index = scopeOther.length

        const itens = scopeOther.concat(item)

        setChangeOther('new_scope_item_' + index)

        setScopeOtherValues(itens)
        calculateOtherTotals(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('gases_other', [])
  }

  const calculateOtherTotals = (itens: any) => {
    let emissionTco2e = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_tco2e) {
          emissionTco2e = emissionTco2e + item.emission_tco2e
        }
      }
    }

    setEmissionTco2eOther(emissionTco2e)
  }

  const handleChangeValueOther = (index: number, ignore_field?: string) => {
    const gasId = scopeOther[index].gas_id
    const emissionsKg = scopeOther[index].emissions_kg

    async function calculate() {
      const result = await scope1Service.calculateOtherFugitiveEmissions(
        gasId,
        emissionsKg
      )

      if (result) {
        scopeOther[index].emission_tco2e = result.emission_tco2e

        setChangeOther('change_scope_item_' + index + '_' + emissionsKg)

        setScopeOtherValues(scopeOther, ignore_field)
        calculateOtherTotals(scopeOther)
      }
    }

    calculate()
  }

  function handleDeleteOther(index: number) {
    const itens = []

    for (let i = 0; i < scopeOther?.length; i++) {
      if (i !== index) {
        itens.push(scopeOther[i])
      }
    }

    setChangeOther('remove_scope_item_' + index)

    setValue('gases_other', [])

    setScopeOtherValues(itens)
    calculateOtherTotals(itens)
  }

  function handleOpenModalDelete (){
    setOpenModalDelete(true);
  }

  function handleDeleteScopeAllOther() {
    const itens: any = [];

    setChangeOther('remove_all_scope_items');
    setValue('gases_other', []);
    
    setScopeOtherValues(itens);
    calculateOtherTotals(itens);
    
    setOpenModalDelete(false);
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'emissoes_fugitivas',
          'relato_de_emissoes'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const handleDownloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const handleAttachmentChange = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 1,
        name: 'emissoes_fugitivas',
        file: base64,
        filename: filename,
        second_name: 'relato_de_emissoes'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'emissoes_fugitivas',
          'relato_de_emissoes'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} className="column">
        <div
          className="row"
          style={{
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div className="column">
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 01 - ' + title}  />
              </div>
            )}
          </div>
          <div className="row">
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />
        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill && (
          <div className="column" style={{ margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 270px)',
            maxHeight: 'calc(100vh - 270px)'
          }}
        >
          {!ignoreFill && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  width: '98%',
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 14,
                      marginTop: 5,
                      fontWeight: 'bold'
                    }}
                  >
                    Relato de emissões estimadas a partir de outras ferramentas.
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, }} >
                    Selecione, o gás/composto ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && gases && (
                  <Controller
                    control={control}
                    name="gases_other"
                    render={({ field }) => (
                      <Select placeholder="[Selecione]"
                        {...field}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            width: '350px',
                            height: 40,
                            marginTop: 5,
                            marginRight: 10,
                            backgroundColor: 'rgb(246, 246, 246)'
                          }),
                          option: (styles) => {
                            return { ...styles, color: '#31363F' }
                          }
                        }}
                        defaultValue={getValues('gases_other')}
                        options={gases}
                        onChange={handleChangeGasOther}
                      />
                    )}
                  />
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeOther && !!scopeOther.length && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }}>
                          {'Registro da fonte'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }}>
                          {'Descrição da fonte'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Gás de Efeito Estufa'}
                        </th>
                        <th className="head-tr-item" style={{ width: 450 }}>
                          {'Emissões (kg GEE)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }}>
                          {'GWP'}
                        </th>
                        <th className="head-tr-item" style={{ width: 150 }}>
                          {'Emissões em CO₂e (t)'}
                        </th>
                        {!isCommonUser && (
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          >
                            {!isCommonUser && (
                              <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                                <div onClick={handleOpenModalDelete}>
                                  <button style={{background: '#b81414', color: 'white', borderRadius: 5, cursor:'pointer'}}>Apagar tudo</button>
                                </div>
                              </td>
                            )}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {scopeOther.map((other: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`other.${index}.source`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`other.${index}.source`, event.target.value)
                                    scopeOther[index].source = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`other.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `other.${index}.description`,
                                      event.target.value
                                    )
                                    scopeOther[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <div className="row">
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      width: 200,
                                      fontSize: 12,
                                      fontWeight: 'bold',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {other.gas_name}
                                  </span>

                                  {other.gas_description && (
                                    <div className="tooltip">
                                      <img src={InfoButtonMin} alt="information"/>
                                      <span className="tooltiptext">
                                        {other.gas_description}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: "95%", textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`other.${index}.emissions_kg`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeOther[index].emissions_kg = value

                                    setValue(`other.${index}.emissions_kg`, formatValue)

                                    handleChangeValueOther(index, 'emissions_kg')
                                  }}
                                />
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatInteger(other.gas_gwp)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 150,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(other.emission_tco2e, false)}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }}>
                                  <div
                                    onClick={() => handleDeleteOther(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img alt="deleteIcon" src={TrashIcon} />
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={5}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 200,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionTco2eOther, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td colSpan={5} className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {!scopeOther.length && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum gás / composto cadastrado`
                        : `Nenhum gás / composto foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {/* {requiredItens && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'left',
                        margin: 10
                      }}
                    >
                      <span className="requiredLabel" style={{ fontSize: 14 }}>
                        * Necessário incluir pelo menos um item para o escopo selecionado
                      </span>
                    </div>
                  )} */}

                  {errors && errors.other && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Registro da fonte e Descrição da fonte são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {!ignoreFill && (
            <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                          width: "calc(100vw - 330px)", maxWidth: "calc(100vw - 330px)",
                          borderColor: "#9E9E9E", height: "auto", display: "flex", 
                          flexDirection: "column", marginLeft: 15}} >
              <div style={{ width: "100%", display: "flex", flexDirection: "row"}} >
                <span style={{ margin: 10, width: "70%" }}>
                  Emissões totais em CO₂ equivalente (toneladas métricas):
                </span>
                <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                  {formatPrice3(emissionTco2eOther, false)}
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: "row", color: "#31363F", 
                    fontSize: 12, fontWeight: "bold", marginLeft: 10 }} >
                Atenção: para os cálculos aqui apresentados não existe a emissão de CO₂ biogênico associado.
              </div>
            </div>
          )}

          {!ignoreFill && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={handleDownloadFile}
                  onChangeAttachment={handleAttachmentChange}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModalInfo}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div className="column">
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
      
      {openModalDelete && (
        <ModalDeleteDataTable
          isOpen={openModalDelete}
          handleModalVisible={() => setOpenModalDelete(false)}
          handleOk={handleDeleteScopeAllOther}
        />
      )}
    </Container>
  )
}
