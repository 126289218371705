import { useEffect, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { Button } from "../../../../components/Button/styles";
import { Modal } from "../../../../components/Modal";
import { TitleText } from "../../../../components/TitleText";
import ticketTypeService from "../../../../services/ticketTypeService";
import { Container } from "./styles";
import Select from "react-select";
import AttachmentIcon from '../../../../assets/icons/attachment.svg'
import DownloadIcon from '../../../../assets/icons/download.svg'
import helpService from "../../../../services/helpService";
import fileDownload from "js-file-download";
import axios from "axios";
import { toast } from "react-hot-toast";

type ModalTicketProps = {
    isOpen: boolean;
    handleModalVisible: () => void;
}

export const ModalTicket = ({ isOpen, handleModalVisible }: ModalTicketProps) => {

    const [file, setFile] = useState('')
    const [fileName, setFileName] = useState('')
    const [fileChanged, setFileChanged] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [errorSubmit, setErrorSubmit] = useState(false);
    const [ticketTypes, setTicketTypes] = useState<any>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [errorApi, setErrorApi] = useState<string | undefined>();

    interface Inputs {
        file: string;
        subject: string;
        filename: string;
        ticket_type: any;
        description: string;
    }

    useEffect(() => {
        getTicketTypes()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    async function getTicketTypes() {
      const result = await ticketTypeService.getByParams({ limit: 9999999, page: 1 });
  
      const options = result.itens.map((ticket: any) => {
        return {
          label: ticket.name,
          value: ticket.id,
        };
      });


  
      setTicketTypes(options);
    }

    const {
        register,
        handleSubmit,
        setValue,
        control,
        getValues,
        formState: { errors },
    } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = async (formData) => {
        if (formData) {
          const ticket = {
            subject: formData.subject, 
            description: formData.description,
            ticket_type: formData.ticket_type.label,
            file: fileChanged ? formData.file : undefined,
            filename: fileChanged ? formData.filename : undefined,
          };
    
          const result = await helpService.openTicket(ticket);
    
          if (result && !result.error) {
            handleModalVisible()
            
            toast.success("Sua mensagem foi enviada e em breve será respondida!")
          }
    
          if (result.error) {
            setErrorApi(result.error);
          }
        }
    };

	const fileChangeHandler = (event:any) => {
        async function saveImage(base64?:string) {
          setValue("file", base64 ? base64 : "")
          setFile(base64 ? base64 : "")
          setValue("filename", file.name)

          setFileName(file.name)
        }
    
        setFileChanged(true)
    
        const file = event.target.files[0]
        const reader = new FileReader();
    
        reader.readAsDataURL(file);
    
        reader.onload = () => {
          const base64 = reader.result;
    
          saveImage(base64?.toString())
        };
	};

    function base64toBlob(base64Image: string) {
      const parts = base64Image.split(';base64,');
      const imageType = parts[0].split(':')[1];
      const decodedData = window.atob(parts[1]);
    
      const uInt8Array = new Uint8Array(decodedData.length);
    
      for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
      }
    
      return new Blob([uInt8Array], { type: imageType });
    }

	const downloadFile = () => {
        if (file){
            if (fileChanged){
                fileDownload(base64toBlob(getValues("file")), fileName)
            }else{
                axios.get(file, { responseType: 'blob' })
                    .then((res) => { fileDownload(res.data, fileName)})
            }
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            isPayOrTypeClient={true}
            handleModalVisible={handleModalVisible}
        >
            <Container>
                <div style={{ margin: 5, textAlign: "center" }}>
                    <TitleText level={2} title="Abrir Chamado" color="blue" />
                </div>

                <div style={{ margin: 5, paddingBottom: 15, textAlign: "center"  }}>
                    <span style={{fontWeight: "bold"}}>Informe aqui, abaixo, o seu chamado a ser aberto</span>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                        lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                        Tipo de chamado
                    </label>

                    <Controller control={control} name="ticket_type" rules={{ required: true }}
                        render={({ field }) => (
                        <Select placeholder="[Selecione]" {...field} styles={{ control: (styles) => ({
                                ...styles, width: "100%", height: 40, marginTop: 10,
                                backgroundColor: "rgb(246, 246, 246)"}),
                            option: (styles) => { return { ...styles, color: "#31363F" };},
                            }} defaultValue={getValues("ticket_type")}
                            options={ticketTypes} isClearable={true}                      
                        />
                        )}
                    />
                                
                    {errors.ticket_type && (
                        <span className='requiredLabel' >Esse campo é obrigatório</span>
                    )}

                    <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                      lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold", display: 'block'}} >
                        Assunto
                    </label>
          
                    <input style={{width: '100%'}} {...register("subject", { required: true })}/>
                                
                    {errors.subject && (
                        <span className='requiredLabel' >Esse campo é obrigatório</span>
                    )}

                    <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                      lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold", display: 'block' }} >
                        Observações
                    </label>
          
                    <textarea style={{width: '100%', height: 100}}
                          {...register("description", { required: true })}/>
                                
                    {errors.description && (
                        <span className='requiredLabel' >Esse campo é obrigatório</span>
                    )}

                    <div style={{ borderStyle: "dashed", marginTop: 20, borderWidth: "1px", 
                                paddingTop: 15, paddingBottom: 15, borderColor: "#9E9E9E", 
                                width: "100%", textAlign: "center", cursor: "pointer"}} 
                                onClick={() => (document.querySelector("#fileInput") as HTMLInputElement).click()}>
                        <img src={AttachmentIcon} alt="coisa" width={32}/>

                        <br/>

                        <span style={{padding: 5, fontSize: 14, fontStyle: "bold"}}>
                            Insira aqui um anexo, caso desejado.
                        </span>
                    </div>

                    <input type="file" id="fileInput" 
                            accept="image/png,image/gif,image/jpeg,application/pdf" 
                            style={{ display: "none"}} name="file" 
                            onChange={fileChangeHandler} />

                    {file && file.length > 0 && (
                        <div style={{ display: "flex", justifyContent: "center" }} >
                        <div style={{ borderStyle: "dashed", marginTop: 20, borderWidth: "1px", 
                                        padding: 10, borderColor: "#9E9E9E", 
                                        width: "300px", textAlign: "center", cursor: "pointer"}} 
                                        onClick={() => downloadFile()}>
                            <div style={{ display: "flex", justifyContent: "center" }} >
                            <img src={DownloadIcon} alt="download" width={24}/>
            
                            <span style={{padding: 5, fontSize: 16, fontStyle: "bold"}}>Baixar arquivo anexado</span>
                            </div>
                        </div>
                        </div>
                    )}

                    <div style={{ margin: 5, display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <Button color='black' type="button" size="md"
                                onClick={handleModalVisible}
                                style={{ margin: 10, width: '285px', }}>
                            Cancelar
                        </Button>

                        <Button color='blue' type="submit" size="md"
                                style={{ margin: 10, width: '285px' }}>
                            Confirmar
                        </Button>
                    </div>
                </form>
            </Container>
        </Modal >
    )
}