export function transformPhone(num: string) {
  if (num) {
    num = num.toString();
    num = num.replace(/[^0-9*]/g, "");

    if (num.length > 8) {
      num = num.substring(0, 11);
    }
    switch (num.length) {
      case 2:
        num = num.replace(/(\d{2})/, "$1");
        break;
      case 3:
        num = num.replace(/(\d{2})(\d{1})/, "($1) $2");
        break;
      case 4:
        num = num.replace(/(\d{2})(\d{2})/, "($1) $2");
        break;
      case 5:
        num = num.replace(/(\d{2})(\d{3})/, "($1) $2");
        break;
      case 6:
        num = num.replace(/(\d{2})(\d{4})/, "($1) $2");
        break;
      case 7:
        num = num.replace(/(\d{2})(\d{4})(\d{1})/, "($1) $2-$3");
        break;
      case 8:
        num = num.replace(/(\d{2})(\d{4})(\d{2})/, "($1) $2-$3");
        break;
      case 9:
        num = num.replace(/(\d{2})(\d{4})(\d{3})/, "($1) $2-$3");
        break;
      case 10:
        num = num.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
        break;
      case 11:
        num = num.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
        break;
      default:
        return num;
    }
  }
  return num;
}

export function transformCPF(value: string) {
  if (value) {
    value = value.toString();
    value = value.replace(/[^0-9*]/g, "");

    if (value.length > 11) {
      value = value.substring(0, 11);
    }
    
    switch (value.length) {
      case 4:
        value = value.replace(/(\d+)(\d{1})/, "$1.$2");
        break;
      case 5:
        value = value.replace(/(\d+)(\d{2})/, "$1.$2");
        break;
      case 6:
        value = value.replace(/(\d+)(\d{3})/, "$1.$2");
        break;
      case 7:
        value = value.replace(/(\d+)(\d{3})(\d{1})/, "$1.$2.$3");
        break;
      case 8:
        value = value.replace(/(\d+)(\d{3})(\d{2})/, "$1.$2.$3");
        break;
      case 9:
        value = value.replace(/(\d+)(\d{3})(\d{3})/, "$1.$2.$3");
        break;
      case 10:
        value = value.replace(/(\d+)(\d{3})(\d{3})(\d{1})/, "$1.$2.$3-$4");
        break;
      case 11:
        value = value.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        break;
      default:
        return value;
    }
  }
  return value;
}

export function transformDocument(value: string) {
  if (value) {
    value = value.toString();
    value = value.replace(/[^0-9*]/g, "");

    if (value.length > 11) {
        return transformCNPJ(value)
    }else{
        return transformCPF(value)
    }
  }

  return value;
}

export function transformCNPJ(value: string) {
  if (value) {
    value = value.toString();
    value = value.replace(/[^0-9*]/g, "");
    if (value.length > 14) {
      value = value.substring(0, 14);
    }
    switch (value.length) {
      case 4:
        value = value.replace(/(\d+)(\d{1})/, "$1.$2");
        break;
      case 5:
        value = value.replace(/(\d+)(\d{2})/, "$1.$2");
        break;
      case 6:
        value = value.replace(/(\d+)(\d{3})/, "$1.$2");
        break;
      case 7:
        value = value.replace(/(\d+)(\d{3})(\d{1})/, "$1.$2.$3");
        break;
      case 8:
        value = value.replace(/(\d+)(\d{3})(\d{2})/, "$1.$2.$3");
        break;
      case 9:
        value = value.replace(/(\d+)(\d{3})(\d{3})/, "$1.$2.$3");
        break;
      case 10:
        value = value.replace(/(\d+)(\d{3})(\d{3})(\d{1})/, "$1.$2.$3-$4");
        break;
      case 11:
        value = value.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        break;
      case 12:
        value = value.replace(/(\d+)(\d{3})(\d{3})(\d{4})/, "$1.$2.$3/$4");
        break;
      case 13:
        value = value.replace(
          /(\d+)(\d{3})(\d{3})(\d{4})(\d{1})/,
          "$1.$2.$3/$4-$5"
        );
        break;
      case 14:
        value = value.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/,
          "$1.$2.$3/$4-$5"
        );
        break;
      default:
        return value;
    }
  }
  return value;
}

export function formatPrice(value: number, showCurrencyName: boolean) {
  if (value === null || value === undefined || isNaN(value)) {
    return 0;
  }
  const numero = value.toFixed(1).split(".");
  numero[0] =
    (!showCurrencyName ? "" : "R$ ") +
    numero[0].split(/(?=(?:...)*$)/).join(".");
  return numero.join(",");
}

export function formatPrice2(value: number, showCurrencyName: boolean) {
  if (value === null || value === undefined || isNaN(value)) {
    return 0;
  }
  let is_negative = false;
  if(value < 0){
    value *= -1;
    is_negative = true;
  }
  const numero = value.toFixed(2).split(".");
  numero[0] =
    (!showCurrencyName ? "" : "R$ ") +
    numero[0].split(/(?=(?:...)*$)/).join(".");
  if(is_negative)
    return '-' + numero.join(",");
  else
    return numero.join(",");
}

export function formatPrice3(value: number, showCurrencyName: boolean) {
  if (value === null || value === undefined || isNaN(value)) {
    return 0;
  }
  const numero = value.toFixed(3).split(".");
  numero[0] =
    (!showCurrencyName ? "" : "R$ ") +
    numero[0].split(/(?=(?:...)*$)/).join(".");
  return numero.join(",");
}

export function formatPrice4(value: number, showCurrencyName: boolean) {
  if (value === null || value === undefined || isNaN(value)) {
    value = 0;
  }

  try{
    const numero = value.toFixed(4).split(".");
    numero[0] =
      (!showCurrencyName ? "" : "R$ ") +
      numero[0].split(/(?=(?:...)*$)/).join(".");
    return numero.join(",");
  }catch{
    return "0,00"
  }
}

export function formatPrice5(value: number, showCurrencyName: boolean) {
  if (value === null || value === undefined || isNaN(value)) {
    value = 0;
  }

  try{
    const numero = value.toFixed(5).split(".");
    numero[0] =
      (!showCurrencyName ? "" : "R$ ") +
      numero[0].split(/(?=(?:...)*$)/).join(".");
    return numero.join(",");
  }catch{
    return "0,00"
  }
}

export function formatPrice6(value: number, showCurrencyName: boolean) {
  if (value === null || value === undefined || isNaN(value)) {
    value = 0;
  }

  try{
    const numero = value.toFixed(6).split(".");
    numero[0] =
      (!showCurrencyName ? "" : "R$ ") +
      numero[0].split(/(?=(?:...)*$)/).join(".");
    return numero.join(",");
  }catch{
    return "0,00"
  }
}

export function formatPrice8(value: number, showCurrencyName: boolean) {
  if (value === null || value === undefined || isNaN(value)) {
    value = 0;
  }

  try{
    const numero = value.toFixed(8).split(".");
    numero[0] =
      (!showCurrencyName ? "" : "R$ ") +
      numero[0].split(/(?=(?:...)*$)/).join(".");
    return numero.join(",");
  }catch{
    return "0,00"
  }
}

export function formatPrice14(value: number, showCurrencyName: boolean) {
  if (value === null || value === undefined || isNaN(value)) {
    value = 0;
  }

  try {
    const numero = value.toFixed(14).split(".");
    numero[0] =
      (!showCurrencyName ? "" : "R$ ") +
      numero[0].split(/(?=(?:...)*$)/).join(".");
    return numero.join(",");
  } catch {
    return "0,00";
  }
}

export function formatPrice17(value: number, showCurrencyName: boolean) {
  if (value === null || value === undefined || isNaN(value)) {
    value = 0;
  }

  try {
    const numero = value.toFixed(17).split(".");
    numero[0] =
      (!showCurrencyName ? "" : "R$ ") +
      numero[0].split(/(?=(?:...)*$)/).join(".");
    return numero.join(",");
  } catch {
    return "0,00";
  }
}

export function formatPrice20(value: number, showCurrencyName: boolean) {
  if (value === null || value === undefined || isNaN(value)) {
    value = 0;
  }

  try {
    const numero = value.toFixed(20).split(".");
    numero[0] =
      (!showCurrencyName ? "" : "R$ ") +
      numero[0].split(/(?=(?:...)*$)/).join(".");
    return numero.join(",");
  } catch {
    return "0,00";
  }
}

export function formatPriceMil4(value: number, showCurrencyName: boolean) {
  if (value === null || value === undefined || isNaN(value)) {
    value = 0;
  }

  try {
    const numero = value.toFixed(4).split(".");
    if (Math.abs(value) >= 1000) {
      numero[0] = numero[0].split(/(?=(?:...)*$)/).join(".");
    }
    numero[0] = (!showCurrencyName ? "" : "R$ ") + numero[0];
    return numero.join(",");
  } catch {
    return "0,00";
  }
}

export function formatInteger(value: number) {
  if (value === null || value === undefined || isNaN(value)) {
    value = 0;
  }
  const numero = value.toFixed(0).split(".");
  numero[0] = numero[0].split(/(?=(?:...)*$)/).join(".");
  return numero.join(",");
}

export function formatDateObj(date: Date) {
  if (date) {
    const day = date.getDate()
    const month = date.getMonth();
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }
}

export function formatDate(date: string) {
  if (date) {
    const dt = date.split(" ")[0];
    const day = dt.split("/")[1];
    const month = dt.split("/")[0];
    const year = dt.split("/")[2];
    return `${day}/${month}/${year}`;
  }
}

export function transformDate(date: string) {
  if (date) {
    const day = date.split("/")[0];
    const month = date.split("/")[1];
    const year = date.split("/")[2];

    return `${year}-${month}-${day}`;
  }

  return ''
}

export function formatDateDefault(date: string) {
  if (date) {
    const dt = date.split(" ")[0];

    const day = dt.split("-")[2];
    const month = dt.split("-")[1];
    const year = dt.split("-")[0];

    return `${day}/${month}/${year}`;
  }
}

export function formatDateTimeDefault(date: string) {
  if (date) {
    const dt = date.split(" ")[0];
    const time = date.split(" ")[1];

    const day = dt.split("-")[2];
    const month = dt.split("-")[1];
    const year = dt.split("-")[0];

    return `${day}/${month}/${year} ${time}`;
  }
}

export function transformOnlyNumbers(value: string) {
  return value?.replace(/[^\w\s]/gi, "")?.replace(/\s/g, "")?.toUpperCase();
}

export function toNumberValues(value: string): string {
  // Remove todos os caracteres não numéricos
  const numericValue = value.replace(/\D/g, "");
  // Verifica se a string resultante não está vazia
  if (numericValue === "") {
    return ""; // Retorna "" se a string estiver vazia
  }
  // Converte a string para um número inteiro
  const numberValue = parseInt(numericValue, 10);
  // Retorna o número como string
  return numberValue.toString();
}


export function toCurrencyValues(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
  return value;
}

export function isStrongPassword(value: string) {
  let passo, passo1, passo2;

  if (value.match(/.{6,}/)) {
     passo = true;
  } else {
     passo = false;
  }

  if (value.match(/[A-Z]{1,}/)) {
     passo1 = true;
  } else {
     passo1 = false;
  }

  if (value.match(/[0-9]{1,}/)) {
     passo2 = true;
  } else {
     passo2 = false;
  }

  return passo && passo1 && passo2;
}

export function isValidCnpj(cnpj: string) {
  cnpj = cnpj.replace(/[^\d]+/g, '');


  if (cnpj === '') return false;
   
  if (cnpj.length !== 14) return false;


  if (cnpj === "00000000000000" || 
      cnpj === "11111111111111" || 
      cnpj === "22222222222222" || 
      cnpj === "33333333333333" || 
      cnpj === "44444444444444" || 
      cnpj === "55555555555555" || 
      cnpj === "66666666666666" || 
      cnpj === "77777777777777" || 
      cnpj === "88888888888888" || 
      cnpj === "99999999999999") {
    return false;
  }

  // Valida DVs
  let tamanho: any = cnpj.length - 2;
  let numeros: any = cnpj.substring(0, tamanho);
  const digitos: any = cnpj.substring(tamanho);

  let soma: any = 0;
  let pos: any = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    let num: any = parseInt(numeros.charAt(tamanho - i), 10);
    soma += num * pos--;

    if (pos < 2) pos = 9;
  }

  let resultado1: any = soma % 11 < 2 ? 0 : 11 - soma % 11;


  // Compara o primeiro dígito verificador
  if (resultado1 !== parseInt(digitos.charAt(0), 10)) {
    return false;
  }

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    let num: any = parseInt(numeros.charAt(tamanho - i), 10);
    soma += num * pos--;

    if (pos < 2) pos = 9;
  }

  let resultado2: any = soma % 11 < 2 ? 0 : 11 - soma % 11;


  // Compara o segundo dígito verificador
  if (resultado2 !== parseInt(digitos.charAt(1), 10)) {
    return false;
  }

  return true;
}

export function isValidCpf(cpf: string) {
	cpf = cpf.replace(/[^\d]+/g,'');	

	if(cpf === '') return false;	
	// Elimina CPFs invalidos conhecidos	
	if (cpf.length !== 11 || 
		cpf === "00000000000" || 
		cpf === "11111111111" || 
		cpf === "22222222222" || 
		cpf === "33333333333" || 
		cpf === "44444444444" || 
		cpf === "55555555555" || 
		cpf === "66666666666" || 
		cpf === "77777777777" || 
		cpf === "88888888888" || 
		cpf === "99999999999")
			return false;		
	// Valida 1o digito	

	let add = 0;	

	for (let i=0; i < 9; i ++)		
		add += parseInt(cpf.charAt(i)) * (10 - i);	

		let rev = 11 - (add % 11);	
		
    if (rev === 10 || rev === 11)		
			rev = 0;	
		
      if (rev !== parseInt(cpf.charAt(9)))		
			return false;		
	// Valida 2o digito	
	add = 0;	
	
  for (let i = 0; i < 10; i ++)		
		add += parseInt(cpf.charAt(i)) * (11 - i);
    
	rev = 11 - (add % 11);	
	
  if (rev === 10 || rev === 11)	
		rev = 0;	
	if (rev !== parseInt(cpf.charAt(10)))
		return false;	

	return true;   
}