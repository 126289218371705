import { api } from "./api";

async function getById(companyId: number) {
  try {
    const { data } = await api.get(`/company/${companyId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getScopeById(companyId: number) {
  try {
    const { data } = await api.get(`/company/scopes/${companyId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getScopeYearById(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/company/scopes/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getScopesDefault(scope: number) {
  try {
    const { data } = await api.get(`/company/default_scopes/${scope}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/company", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getParents() {
  try {
    const { data } = await api.get("/company/parents");
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(company: any) {
  try {
    const { data } = await api.post(`/company`, company);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function register(company: any) {
  try {
    const { data } = await api.post(`/company/register`, company);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(companyId: number, company: any) {
  try {
    const { data } = await api.put(`/company/${companyId}`, company);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(companyId: number) {
  try {
    const { data } = await api.delete(`/company/${companyId}`);
    return data;
  } catch (error) {
    return false;
  }
}

async function updateYears(companyId:number, itens: any) {
  try {
    const { data } = await api.put(`/company/years/${companyId}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateScopes(companyId:number, itens: any) {
  try {
    const { data } = await api.put(`/company/scopes/${companyId}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateInfo(companyId:number, values: any) {
  try {
    const { data } = await api.put(`/company/info/${companyId}`, values);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

const companyService = {
  getById,
  getParents,
  getByParams,
  getScopeById,
  getScopeYearById,
  getScopesDefault,
  create,
  register,
  updateById,
  deleteById, 
  updateYears, 
  updateScopes, 
  updateInfo
};

export default companyService;
