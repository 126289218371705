import styled from 'styled-components';

interface ContainerProps {
    width?: string;
}

export const Container = styled.nav<ContainerProps>`
    display: flex;
    flex-direction: column;
    // box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    max-width: 280px;
    width: ${props => props.width ? props.width : "280px"};
    background: #FFFFFF;
    height: 100vh;
    z-index: 999999999;

    .menu-divider{
        border-top: 0.6px solid rgba(49, 54, 63, 0.35);
        margin: 2px 10px 2px 10px;
    }

    .user-name {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #192A3E;
    }

    .user-mail {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: #90A0B7;
    }

    .container-links {
        list-style-type: none;
        padding: 0;
        margin-top: 10px;
    }

    .link {
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 15px;
        padding-top: 8px;
        padding-bottom: 8px;
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: ${props => props.theme.colors.grey};
        opacity: 1;
    }

    .logout-text {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: ${props => props.theme.colors.grey};
        cursor: pointer;
        opacity: 1;
    }

    .active-link {
        background: rgba(49, 54, 63, 0.08);
        opacity: 1;
        border-right: 3px solid ${props=>props.theme.colors.blue};
    }

    .content-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    .containt-logout {
        padding-left: 15px;
        padding-top: 8px;
        padding-bottom: 20px;
        position: absolute;
        bottom: 0px;
        width: 250px;
    }

    .containt-help {
        padding-left: 13px;
        padding-top: 8px;
        padding-bottom: 20px;
        position: absolute;
        bottom: 35px;
        width: 250px;
    }

    .link-text {
        margin-left: 10px;
    }

    .escopo-inventario-link{
        margin-left: 2px;
    }

    .organismo-link, 
    .dashboard-link, 
    .home-office-link,
    .refrigeracao-link, 
    .topico-ajuda-link,
    .tipo-chamado-link,
    .tipo-transporte-link,
    .tipo-transporte-link,
    .combustivel-particular-link, 
    .conssessionaria-trem-link{
        margin-left: -2px;
    }
    
    .logo-image {
        position: absolute;
        bottom: 100px;
        padding-left: 70px;
    }
    
    .image-help {
        margin-leftt: -2px;
    }

    /* Estilos icones menu */

    .collapse-menu {
        position: fixed;
        top: 40px;
        left: 270px;
        box-shadow: 0px 0px 3px 3px #d0dae7c5;
        border-radius: 0px 50px 50px 0px;
        padding: 5px 5px 5px 2px;
        width: 12px;
    }

    .expand-menu {
        position: fixed;
        top: 40px;
        left: 60px;
        box-shadow: 0px 0px 3px 3px #d0dae7c5;
        border-radius: 0px 50px 50px 0px;
        padding: 5px 5px 5px 2px;
        width: 12px;
    }

    .logo-menu {
        margin: 5px 5px;
    }

    /* Estilos barra rolagem menu */
    .custom-scrollbar {
        overflow-y: scroll;
        overflow: hidden;
    }

    .custom-scrollbar:hover {
        overflow: auto;
    }

    .custom-scrollbar::-webkit-scrollbar {
        width: 5px; /* Largura da barra de rolagem */
    }

    .custom-scrollbar::-webkit-scrollbar-track {
        background: #f1f1f1; /* Cor da trilha */
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #d0dae7; /* Cor do polegar */
        border-radius: 10px; /* Borda arredondada */
    }

    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #555; /* Cor do polegar ao passar o mouse */
    }

    /* Estilizando Efeitos do Menu */

    .menu-container {
        overflow: hidden;
        transition: max-height 0.3s ease;
        max-height: 0; /* Estado recolhido */
    }

    .menu-container.expanded {
        max-height: 110px; /* Estado expandido, ajuste conforme necessário */
    }

`;