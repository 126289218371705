import styled from 'styled-components';
import SearchIcon from '../../assets/icons/search.svg';

export const Container = styled.div`
    padding: 15px;
    width: 100%;

    .units-title {
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        font-size: 32px;
        line-height: 135%;
        letter-spacing: -0.015em;
        color: ${props => props.theme.colors.black};
    }

    .store-title {
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        font-size: 24px;
        line-height: 135%;
        letter-spacing: -0.015em;
        color: ${props => props.theme.colors.black};
    }

    .button {
        width: 217px;
        height: 47px;
        background: ${props => props.theme.colors.black};
        border-radius: 2.5px;
        cursor: pointer;
        border: none;
        color: ${props => props.theme.colors.white};
    }

    .input-search {
        background: #F6F6F6;
        border: 1px solid #DFDFDF;
        border-radius: 2.5px;
        width: 250px;
        background-image: url(${SearchIcon});
        background-position: 8px 8px;
        background-repeat: no-repeat;
        padding-left: 50px;
        margin-top: 5px;
    }

    .input-search::placeholder {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: 19px;
        color: rgba(49, 54, 63, 0.35);
}

    .input-search:-ms-input-placeholder { 
        font-size: 16px;
        line-height: 19px;
        color: rgba(49, 54, 63, 0.35);
    }

    .input-search::-ms-input-placeholder {
        font-size: 16px;
        line-height: 19px;
        color: rgba(49, 54, 63, 0.35);
    }

    .select {
        width: 232px;
        height: 51px;
        margin-left: 10px;
        background: #F6F6F6;
        border: 1px solid #DFDFDF;
        border-radius: 2.5px;
        color: rgba(49, 54, 63, 0.35);
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: 19px;
    }

    .store-card {
        display: flex;
        flex-direction: column;
        width: 250px;
        border: 1px solid #DFDFDF;
        border-radius: 2.5px;
        padding: 24px;
        margin: 15px;
    }

    .store-card-title {
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        font-size: 20px;
        line-height: 135%;
        letter-spacing: -0.015em;
    }

    .description{
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        color: ${props => props.theme.colors.black};
        margin-top: 5px;
    }

    .manager-text {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.005em;
        text-decoration-line: underline;
        color: ${props => props.theme.colors.black};
        margin-top: 5px;
    }

    .button-order {
        width: 35px;
        height: 35px;
        background: #4E535C;
        border-radius: 2.5px;
        cursor: pointer;
        border: none;
    }

    .error-input-message {
        color: ${props => props.theme.colors.red};
        margin-left: 8px;
    }
`;