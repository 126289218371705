import { api } from "./api";

async function getById(seasonal_dataId: number) {
  try {
    const { data } = await api.get(`/seasonal_data/${seasonal_dataId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/seasonal_data", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(seasonal_data: any) {
  try {
    const { data } = await api.post(`/seasonal_data`, seasonal_data);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(seasonal_dataId: number, seasonal_data: any) {
  try {
    const { data } = await api.put(`/seasonal_data/${seasonal_dataId}`, seasonal_data);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(seasonal_dataId: number) {
  try {
    const { data } = await api.delete(`/seasonal_data/${seasonal_dataId}`);
    return data;
  } catch (error) {
    return false;
  }
}

const getSeasonaByYear = async (seasonal_year: number) => {
  try {
    const { data } = await api.get(`/seasonal_data/info/${seasonal_year}`);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  };
};

const seasonalDataService = {
  getById,
  getByParams,
  create,
  updateById,
  deleteById,
  getSeasonaByYear
};

export default seasonalDataService;
