import React, { useEffect, useState } from "react";
import { Container, Table, THead, TBody, TableItem, TableHeader, FilterContainer, Input, Select, Button, NoItemsFound } from "./styles";
import importManagerService from "../../services/importManagerService";
import { toast } from 'react-hot-toast'
import { Loading } from '../../components/Loading';
import { FaEllipsis } from "react-icons/fa6";
import Modal from './components/Modal';
import Paginator from "../../components/Paginator"


const ImportManager = () => {
    const [information, setInformation] = useState<any>([]);
    const [statusFilter, setStatusFilter] = useState<string>("");
    const [originalInformation, setOriginalInformation] = useState<any[]>(information); // Armazena os dados originais
    const [idFilter, setIdFilter] = useState<number | string>('');
    const [amountView, setAmountView] = useState<number>(10);
    const [filterIsActive, setFilterIsActive] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [pagination, setPagination] = useState({prev: 0, next: amountView, current: 1});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);


    const fields = ["id", "filename", "status", "created_at", "error_message", "user_id", "company_id", "year", "local", "item_id",]

    const headers = [
        { label: "ID", className: "", db_field: "id" },
        { label: "Data", className: "", db_field: "created_at" },
        { label: "status", className: "", db_field: "status" },
        { label: "Mensagem", className: "", db_field: "error_log" },
        { label: "Usuário", className: "", db_field: "user_name" },
        { label: "Empresa", className: "", db_field: "company_name" },
        // { label: "Usuário", className: "", db_field: "user_id" },
        // { label: "Empresa", className: "", db_field: "company_id" },
        { label: "Inventário", className: "", db_field: "inventory_year" },
        { label: "local", className: "", db_field: "model_name" },
        // { label: "ID Item", className: "", db_field: "item_id" },
        { label: "Ações", className: "", db_field: null },
    ];

    const renderRow = (row: any, rowIndex: number) => (
        <tr key={rowIndex}>
            {headers.map((header, index) => (
                <TableItem key={index}
                    className={header.db_field === "status" 
                        ? row[header.db_field] === "completed" 
                            ? "success" 
                            : "error" 
                        : ""
                    }
                >
                    {header.db_field 
                        ? header.db_field === "file_path" && typeof row[header.db_field] === "string" 
                            ? row[header.db_field].slice(0, 10) + "..." // Trunca a data se for string
                            : String(row[header.db_field] ?? "—") // Exibe "—" se o valor for nulo ou undefined
                        : (
                            <FaEllipsis className="button-actions" size={16} onClick={() => openModal(row)} />
                            // <Button onClick={() => openModal(row)}> <FaEllipsis color="#000" size={16} />  </Button>
                        )
                    }
                </TableItem>
            ))}
        </tr>
    );
    
    

    const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setStatusFilter(event.target.value);
        console.log(event.target.value)
    };

    const formatDate = (date: Date): string => {
        return date.toLocaleDateString("pt-BR");
    };

    const openModal = (item: any) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handlePageNext = () => {
        // Se a próxima página tiver mais itens, avançar
        if (pagination.next < information.length) {
            setPagination({
                prev: pagination.next,
                next: pagination.next + amountView,
                current: pagination.current + 1,
            });
        }
    };

    const handlePagePrev = () => {
        // Se a página anterior for válida, retroceder
        if (pagination.prev > 0) {
            setPagination({
                prev: pagination.prev - amountView,
                next: pagination.prev,
                current: pagination.current - 1,
            });
        }
    };
    
    const handleAmountViewChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setAmountView(parseInt(event.target.value));
        setPagination({prev: 0, next: parseInt(event.target.value), current: 1});
    };

    const handleFilter = () => {
        if(statusFilter != "" || idFilter != 0 && idFilter != "") {
            setLoading(true)
            setOriginalInformation(information);
            setFilterIsActive(true);
            const filteredInformation = information.filter((item: any) => {
                const statusMatch = statusFilter ? item.status.toLowerCase().includes(statusFilter.toLowerCase()) : true;
                const idMatch = idFilter ? String(item.id).includes(String(idFilter)) : true;
                return statusMatch && idMatch;
            });
            setInformation(filteredInformation);
            setLoading(false);
            toast.success("Filtro aplicado.");
        }
    };

    const handleClearFilter = () => {
        setInformation(originalInformation);
        setFilterIsActive(false);
    };
    
    const handleGetInformation = async () => {
        try {
            setLoading(true)
            const response = await importManagerService.getInformation();
            
            if (response) {
                // Ordena os dados pelo ID de forma decrescente (do maior para o menor)
                const sortedData = response.sort((a: any, b: any) => b.id - a.id);
    
                setInformation(response);
                toast.success("Data loaded successfully!");
            } else {
                toast.error("No data found.");
            }
            setLoading(false)
        } catch (error: any) {
            console.error("Error fetching data:", error);
            toast.error(`Oops! Something went wrong. Error: ${error.message || error}`);
            setLoading(false);
        }
    };
    

    useEffect((() => {
        handleGetInformation();
    }), []);

    return (
        <Container className="custom-scrollbar">
            <h2>Gerenciador de Importações</h2>
            {/* <p>Neste modal você vai encontrar os logs das operações de importação.</p> */}
            <FilterContainer>
                <div className="container">
                    <Input placeholder="ID" type="number" onChange={e => setIdFilter(e.target.value)} disabled={filterIsActive ? true : false}/>
                    <Select value={statusFilter} onChange={handleStatusChange} disabled={filterIsActive ? true : false}>
                        <option value="">Selecione o Status</option>
                        <option value="processing">Interrompido</option>
                        <option value="pending">Pendente</option>
                        <option value="completed">Concluído</option>
                        <option value="failed">Erro</option>
                    </Select>
                    {filterIsActive ? (
                        <Button style={{backgroundColor: "#C1C1C1"}} onClick={handleClearFilter}>Limpar</Button>
                    ):(
                        <Button onClick={handleFilter}>Filtrar</Button>
                    )}
                </div>
                <div className="paginator">
                    <Paginator state={pagination} information={information} handlePageNext={handlePageNext} handlePagePrev={handlePagePrev} />
                </div>
            </FilterContainer>

            {information.length > 0 && (
                <>
                    <div className="custom-scrollbar">
                        <Table className="custom-scrollbar">
                            <THead>
                                <tr>
                                    {headers.map((header, index) => (
                                        <TableHeader key={index} className={header.className || ""}>
                                            {header.label}
                                        </TableHeader>
                                    ))}
                                </tr>
                            </THead>
                            <TBody>
                                {/* Limita a exibição a 5 itens - futuramente montar um botao para selecionar a qtde */}
                                {information.slice(pagination.prev, pagination.next).map((row: any, index: number) => (
                                    <React.Fragment key={index}>{renderRow(row, index)}</React.Fragment>
                                ))}
                            </TBody>
                        </Table>
                        <div className="container-global">
                            <p>{information.length} registros</p>
                            <Select value={amountView} onChange={handleAmountViewChange}>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                            </Select>
                        </div>
                    </div>
                </>
            )}

            


            {information.length === 0 && (
                <NoItemsFound>Nenhum item encontrado...</NoItemsFound>
            )}

            <Modal isOpen={isModalOpen} onClose={closeModal} item={selectedItem} />
            <Loading isOpen={loading} />
        </Container>
    );
};

export default ImportManager;
