import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Button } from "../../../../components/Button/styles";
import { GoBack } from "../../../../components/GoBack";
import { TitleText } from "../../../../components/TitleText";
import useAuth from "../../../../hooks/useAuth";
import inventoryService from "../../../../services/inventoryService";
import { Container } from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

interface Inputs {
  other_organization_performance: string;
  other_strategies_uncertainties: string;
  other_gee_indicators: string;
  other_renewable_energy: string;
  other_strategies_gee_projects: string;
  other_strategies_gee_contracts: string;
  other_sinternal_actions_gee: string;
  other_purchase_of_electricity: string;
  other_carbon_stock: string;
  other_organization_performance_na: boolean;
  other_strategies_uncertainties_na: boolean;
  other_gee_indicators_na: boolean;
  other_strategies_gee_projects_na: boolean;
  other_strategies_gee_contracts_na: boolean;
  other_sinternal_actions_gee_na: boolean;
  other_purchase_of_electricity_na: boolean;
  other_renewable_energy_na: boolean;
  other_carbon_stock_na: boolean;
}

export const InventoryOther = () => {
  const history = useHistory();

  const [inventorySelected, setInventorySelected] = useState<any>({});
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [organizationPerformanceNa, setOrganizationPerformanceNa] = useState<boolean | undefined>();
  const [geeIndicatorsNa, setGeeIndicatorsNa] = useState<boolean | undefined>();
  const [strategiesUncertaintiesNa, setStrategiesUncertaintiesNa] = useState<boolean | undefined>();
  const [strategiesGeeProjectsNa, setStrategiesGeeProjectsNa] = useState<boolean | undefined>();
  const [strategiesGeeContractsNa, setStrategiesGeeContractsNa] = useState<boolean | undefined>();
  const [internalActionsGeeNa, setInternalActionsGeeNa] = useState<boolean | undefined>();
  const [purchaseElectricityNa, setPurchaseElectricityNa] = useState<boolean | undefined>();
  const [renewableEnergyNa, setRenewableEnergyNa] = useState<boolean | undefined>();
  const [carbonStockNa, setCarbonStockNa] = useState<boolean | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [anonymize, setAnonymize] = useState(false);
  const [onlyView, setOnlyView] = useState(false);

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.company_id && item.year){
      getInventorysById(item.company_id, item.year)

      setAnonymize(user.pseudo_anonymize)
      setOnlyView(user.profile !== 'sinergia' && user.profile !== 'admin' && user.profile !== 'analyst')
    }else{
      history.push({
        pathname: '/inventario',
      });
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location]);

  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const newInventory = {
        other_carbon_stock: carbonStockNa ? 'N/A' : formData.other_carbon_stock,
        other_gee_indicators: geeIndicatorsNa ? 'N/A' : formData.other_gee_indicators,
        other_renewable_energy: renewableEnergyNa ? 'N/A' : formData.other_renewable_energy,
        other_sinternal_actions_gee: internalActionsGeeNa ? 'N/A' : formData.other_sinternal_actions_gee,
        other_purchase_of_electricity: purchaseElectricityNa ? 'N/A' : formData.other_purchase_of_electricity,
        other_strategies_gee_projects: strategiesGeeProjectsNa ? 'N/A' : formData.other_strategies_gee_projects,
        other_strategies_gee_contracts: strategiesGeeContractsNa ? 'N/A' : formData.other_strategies_gee_contracts,
        other_strategies_uncertainties: strategiesUncertaintiesNa ? 'N/A' : formData.other_strategies_uncertainties,
        other_organization_performance: organizationPerformanceNa ? 'N/A' : formData.other_organization_performance,
      };

      const result = await inventoryService.updateOtherId(
        inventorySelected.company.id, inventorySelected.year, newInventory
      );

      if (result && !result.error) {
       // history.goBack()

       // reset();
       // setErrorApi(undefined);
          
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error);
      }
    }
  };

  async function getInventorysById(company_id: number, year: number) {
    const inventory = await inventoryService.getById(company_id, year);

    setValue("other_carbon_stock", inventory?.other_carbon_stock);
    setValue("other_gee_indicators", inventory?.other_gee_indicators);
    setValue("other_renewable_energy", inventory?.other_renewable_energy);
    setValue("other_sinternal_actions_gee", inventory?.other_sinternal_actions_gee);
    setValue("other_strategies_gee_projects", inventory?.other_strategies_gee_projects);
    setValue("other_purchase_of_electricity", inventory?.other_purchase_of_electricity);
    setValue("other_strategies_uncertainties", inventory?.other_strategies_uncertainties);
    setValue("other_organization_performance", inventory?.other_organization_performance);
    setValue("other_strategies_gee_contracts", inventory?.other_strategies_gee_contracts);

    setCarbonStockNa(inventory?.other_carbon_stock === 'N/A');
    setGeeIndicatorsNa(inventory?.other_gee_indicators === 'N/A');
    setRenewableEnergyNa(inventory?.other_renewable_energy === 'N/A');
    setInternalActionsGeeNa(inventory?.other_sinternal_actions_gee === 'N/A');
    setPurchaseElectricityNa(inventory?.other_purchase_of_electricity === 'N/A');
    setStrategiesGeeProjectsNa(inventory?.other_strategies_gee_projects === 'N/A');
    setStrategiesGeeContractsNa(inventory?.other_strategies_gee_contracts === 'N/A');
    setStrategiesUncertaintiesNa(inventory?.other_strategies_uncertainties === 'N/A');
    setOrganizationPerformanceNa(inventory?.other_organization_performance === 'N/A');

    setValue("other_carbon_stock_na", inventory?.other_carbon_stock === 'N/A');
    setValue("other_gee_indicators_na", inventory?.other_gee_indicators === 'N/A');
    setValue("other_renewable_energy_na", inventory?.other_renewable_energy === 'N/A');
    setValue("other_sinternal_actions_gee_na", inventory?.other_sinternal_actions_gee === 'N/A');
    setValue("other_purchase_of_electricity_na", inventory?.other_purchase_of_electricity === 'N/A');
    setValue("other_strategies_gee_projects_na", inventory?.other_strategies_gee_projects === 'N/A');
    setValue("other_strategies_gee_contracts_na", inventory?.other_strategies_gee_contracts === 'N/A');
    setValue("other_strategies_uncertainties_na", inventory?.other_strategies_uncertainties === 'N/A');
    setValue("other_organization_performance_na", inventory?.other_organization_performance === 'N/A');

    setInventorySelected(inventory)
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <div style={{ display: "flex", flexDirection: "column"}} >
            <TitleText level={1} title={'Inventário: ' + inventorySelected?.year + ' - ' + inventorySelected?.company?.corporate_name}  />
            <TitleText level={4} title="Informe os outros elementos do inventário."  />
          </div>
          
          {onlyView === false && (
            <Button color="green" size="md" type="submit">
              Salvar
            </Button>
          )}
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", overflow: "auto", 
              position: "absolute", top: "150px", right: "30px", left: "280px", bottom: "20px"}} >

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold", width: '85%' }} >
              Informações sobre a performance da organização, em comparação com benchmarks internos.
              (ex: outras unidades) ou externos (ex: organizações do mesmo setor).
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={organizationPerformanceNa || onlyView}
              {...register("other_organization_performance", { required: !organizationPerformanceNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView}
                {...register("other_organization_performance_na")}
                  onChange={(event) => {
                    if (organizationPerformanceNa === false){
                        setValue("other_organization_performance", "N/A")
                    }

                    setOrganizationPerformanceNa(!organizationPerformanceNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica
            </label>
          </div>
                      
          {errors.other_organization_performance && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold", width: '85%' }} >
              Descrição de indicadores de emissões de GEE para as atividades da organização. 
              Por exemplo, tCO₂e/produtos fabricados. 
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={geeIndicatorsNa || onlyView}
              {...register("other_gee_indicators", { required: !geeIndicatorsNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView}
                {...register("other_gee_indicators_na")}
                  onChange={(event) => {
                    if (geeIndicatorsNa === false){
                        setValue("other_gee_indicators", "N/A")
                    }

                    setGeeIndicatorsNa(!geeIndicatorsNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica
            </label>
          </div>
                      
          {errors.other_gee_indicators && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold", width: '85%' }} >
              Descrição de estratégias e projetos para a gestão de emissões de GEE. 
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={strategiesGeeProjectsNa || onlyView}
              {...register("other_strategies_gee_projects", { required: !strategiesGeeProjectsNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView}
                {...register("other_strategies_gee_projects_na")}
                  onChange={(event) => {
                    if (strategiesGeeProjectsNa === false){
                        setValue("other_strategies_gee_projects", "N/A")
                    }

                    setStrategiesGeeProjectsNa(!strategiesGeeProjectsNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica
            </label>
          </div>
                      
          {errors.other_strategies_gee_projects && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold", width: '85%' }} >
              Informações sobre contratos com clientes e fornecedores que incluam cláusulas vinculadas à elaboração de inventários 
              de GEE e/ou ao envio de informações relacionadas.
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={strategiesGeeContractsNa || onlyView}
              {...register("other_strategies_gee_contracts", { required: !strategiesGeeContractsNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView}
                {...register("other_strategies_gee_contracts_na")}
                  onChange={(event) => {
                    if (strategiesGeeProjectsNa === false){
                        setValue("other_strategies_gee_contracts", "N/A")
                    }

                    setStrategiesGeeContractsNa(!strategiesGeeContractsNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica
            </label>
          </div>
                      
          {errors.other_strategies_gee_contracts && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold", width: '85%' }} >
              Informações sobre incertezas, exclusões de fontes de dados e outras características da elaboração do inventário. 
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={strategiesUncertaintiesNa || onlyView}
              {...register("other_strategies_uncertainties", { required: !strategiesUncertaintiesNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView}
                {...register("other_strategies_uncertainties_na")}
                  onChange={(event) => {
                    if (strategiesGeeProjectsNa === false){
                        setValue("other_strategies_uncertainties", "N/A")
                    }

                    setStrategiesUncertaintiesNa(!strategiesUncertaintiesNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica
            </label>
          </div>
                      
          {errors.other_strategies_uncertainties && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}


          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold", width: '85%' }} >
              Descrição sobre ações internas para melhoria da qualidade do inventário de GEE. 
              Por exemplo, sistematização da coleta de dados, contratação de verificação externa, etc. 
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={internalActionsGeeNa || onlyView}
              {...register("other_sinternal_actions_gee", { required: !internalActionsGeeNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView}
                {...register("other_sinternal_actions_gee_na")}
                  onChange={(event) => {
                    if (internalActionsGeeNa === false){
                        setValue("other_sinternal_actions_gee", "N/A")
                    }

                    setInternalActionsGeeNa(!internalActionsGeeNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica
            </label>
          </div>
                      
          {errors.other_sinternal_actions_gee && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold", width: '85%' }} >
              Informações sobre a compra de energia elétrica oriunda de fonte renovável.
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={purchaseElectricityNa || onlyView}
              {...register("other_purchase_of_electricity", { required: !purchaseElectricityNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView} 
                {...register("other_purchase_of_electricity_na")}
                  onChange={(event) => {
                    if (purchaseElectricityNa === false){
                        setValue("other_purchase_of_electricity", "N/A")
                    }

                    setPurchaseElectricityNa(!purchaseElectricityNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica
            </label>
          </div>
                      
          {errors.other_purchase_of_electricity && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold", width: '85%' }} >
              Informações sobre autoprodução de energia oriunda de fonte renovável para consumo próprio.
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={renewableEnergyNa || onlyView}
              {...register("other_renewable_energy", { required: !renewableEnergyNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView} 
                {...register("other_renewable_energy_na")}
                  onChange={(event) => {
                    if (renewableEnergyNa === false){
                        setValue("other_renewable_energy", "N/A")
                    }

                    setRenewableEnergyNa(!renewableEnergyNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica
            </label>
          </div>
                      
          {errors.other_renewable_energy && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
          
          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold", width: '85%' }} >
              Informações sobre o estoque de carbono, em toneladas, de sua organização em 
              31 de dezembro do ano inventariado. 
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={carbonStockNa || onlyView}
              {...register("other_carbon_stock", { required: !carbonStockNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView}
                {...register("other_carbon_stock_na")}
                  onChange={(event) => {
                    if (carbonStockNa === false){
                        setValue("other_carbon_stock", "N/A")
                    }

                    setCarbonStockNa(!carbonStockNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica
            </label>
          </div>
                      
          {errors.other_carbon_stock && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>
      </form>
    </Container>
  );
};
