import {
  Page,
  Text,
  View,
  StyleSheet,
  Image as ImagePDF,
} from "@react-pdf/renderer";
import React from "react";

import { Header } from "../../components/Header";
import { dictToList } from "./dictToList";
import { styles } from "../../styles";
import { IGEEProps } from "../../../interfaces/IGEEData";
import caminhao from "../../../../../../../assets/images/caminhao-icon.png";
import industria from "../../../../../../../assets/images/industria-icon.png";
import raio from "../../../../../../../assets/images/raios-icon.png";
import sinergiaAzul from "../../../../../../../assets/images/logo-azul.png";

export const Page8: React.FC<IGEEProps> = (props) => {
  const { data } = props;
  const { resume } = props;


  return (
    <Page size={[640, 480]} style={styles.Page}>
      <Header backColor="#2A70B8" title="5. Limites do inventário" />
      <View style={local.body}>
        <Text style={{ ...local.title, ...styles.Bold, ...styles.italic }}>
          Limites operacionais: fontes inclusas
        </Text>

        <View style={local.table}>
          <View style={{ ...local.column, borderRight: "1px solid #81c787"}}>
          <View
            style={{
              position: "absolute",
              top: "112.6%",
              width: 20,
              height: 20,
              right: '18.5%',
              borderRadius: "50%",
              backgroundColor: "#81c787",
              transform: "translate(50%, -50%)",
            }}
          />
              <View style={local.rowTitle}>
                <Text style={{ ...local.itemTitle1, ...styles.light}}>
                  Escopo 1
                </Text>
                <ImagePDF src={industria} style={{ width: "30px", height: "23px", marginTop: 10, marginRight: 5 }} />
              </View>
              <View style={local.listScope1}>
                {data?.scopes_enabled?.scope1 && (
                  <React.Fragment>
                    {data.scopes_enabled.scope1.map((item, idx) => (
                      <Text key={idx}>
                        • {item}
                        {"\n"}
                      </Text>
                    ))}
                  </React.Fragment>
                )}
              </View>
          </View>

          <View style={{ ...local.column, borderRight: "1px solid #76B987" }}>
          <View
            style={{
              position: "absolute",
              top: "112.6%",
              width: 20,
              height: 20,
              right: '18.5%',
              borderRadius: "50%",
              backgroundColor: "#81c787",
              transform: "translate(50%, -50%)",
            }}
          />
            <View style={local.rowTitle}>
              <Text style={{ ...local.itemTitle2, ...styles.light }}>
                Escopo 2
              </Text>
              <ImagePDF src={raio} style={{ width: "30px", height: "23px", marginTop: 10, marginRight: 12 }} />
            </View>
            <View style={local.listScope2}>
                {data?.scopes_enabled?.scope2 && (
                  <React.Fragment>
                    {data.scopes_enabled.scope2.map((item, idx) => (
                      <Text key={idx}>
                        • {item}
                        {"\n"}
                      </Text>
                    ))}
                  </React.Fragment>
                )}
              </View>


          </View>
              
          <View style={{ ...local.column, borderRight: "1px solid #76B987" }}>
            <View style={local.rowTitle}>
              <Text style={{ ...local.itemTitle3, ...styles.light }}>
                Escopo 3
              </Text>
              <ImagePDF
                src={caminhao}
                style={{ width: "44px", height: "23px", marginTop: 10, marginRight: 6 }}
              />
            </View>
            <View style={{...local.listScope3}}>
                {data?.scopes_enabled?.scope3 && (
                  <React.Fragment>
                    {data.scopes_enabled.scope3.map((item, idx) => (
                      <Text key={idx}>
                        • {item}
                        {"\n"}
                      </Text>
                    ))}
                  </React.Fragment>
                )}
              </View>


            </View>
          </View>
          <ImagePDF 
            src={resume?.company_itens.logo_image_url}
            style={{
              width: 60,
              right: '100px',
              position: 'absolute',
              bottom: '8px'
            }}
          />
          <ImagePDF 
            src={sinergiaAzul}
            style={{
              width: 60,
              right: '30px',
              position: 'absolute',
              bottom: '8px'
            }}
          />
        </View>
      </Page>
    );
  };


  const local = StyleSheet.create({
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "92%",
      alignItems: "center",
      padding: "20px",
      backgroundColor: "#ecf3f4",
    },
    title: {
      color: "#326ed6",
      fontSize: 18,
      padding: "5px",
      paddingLeft: "12px",
      alignSelf: "flex-start",
      marginBottom: '10px'
    },
    rowTitle: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
    },
    table: {
      border: "1px solid #76B987",
      width: "110%",
      height: "76%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
    },
    column: {
      width: "35%",
      display: "flex",
      height: "auto",
      flexDirection: "column",
      position: "relative", 
    },
    itemTitle1: {
      fontSize: 18,
      color: "#326ed6",
      marginLeft: "20px",
      marginTop: 10
    },
    itemTitle2: {
      fontSize: 18,
      color: "#326ed6",
      marginLeft: "10px",
      marginTop: 10
    },
    itemTitle3: {
      fontSize: 18,
      color: "#326ed6",
      marginLeft: "10px",
      marginTop: 10
    },
    listScope1: {
      fontSize: 9,
      marginLeft: "20px",
      color: "#326ed6",
      position: "relative", 
      textAlign: 'justify',
      marginRight: "12px",
      marginTop: 5,
      lineHeight: 1.2
    },
    listScope2: {
      fontSize: 9,
      marginLeft: "10px",
      color: "#326ed6",
      position: "relative", 
      textAlign: 'justify',
      marginRight: "12px",
      marginTop: 5,
      lineHeight: 1.2
    },
    listScope3: {
      fontSize: 9,
      marginLeft: "10px",
      color: "#326ed6",
      position: "relative", 
      textAlign: 'justify',
      marginTop: 5,
      lineHeight: 1.2,
      marginRight: "20px"
    },
    list: {
      fontSize: 9,
      marginLeft: '25px',
      color: "#326ed6",
      position: "relative",
      textAlign: 'justify',
      marginRight: "10px"
    },
  });
