import React from 'react'
import { Container } from './styles'
import { formatPrice6 } from '../../../../utils/transform'

type StepNineProps = {
    register: (value: string) => any;
    setValue: (value: string, e: any) => any;
    treatmentSecondTypes: any[];
    handleChangeTreatmentSecond: (id: any) => void;
    factorOxideNitrousSecond: number;
}

export const StepNine: React.FC<StepNineProps> = ({
    register,
    treatmentSecondTypes,
    handleChangeTreatmentSecond,
    factorOxideNitrousSecond
}) => {
  return (
    <Container>
        <div className='div-body'>
            <div className='div-text'>
                <span className='text-statement'>
                    Passo 9 -  Segundo tipo de tratamento aplicado ao efluente doméstico
                </span>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text'>
                        Escolha o tipo de tratamento aplicado ao efluente doméstico
                    </span>
                </div>

                {treatmentSecondTypes && (
                    <select
                        style={{ borderRadius: 5, width: "325px", height: 30 }}
                        {...register("treatment_second_id")}
                        onChange={(event) => handleChangeTreatmentSecond(event.target.value)}
                    >
                        {treatmentSecondTypes.map((type: any) => (
                        <option value={type.id} key={type.id}>
                            {type.name}
                        </option>
                        ))}
                    </select>
                )}
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text'>
                        Fator de emissão de metano (kgCH₄/kgDBO)
                    </span>
                </div>
                <div className='selection-input'>
                    <input 
                        style={{ width: "33vh", textAlign: 'right'}}
                        readOnly
                        {...register("treatment_methane_factor_dbo_second")}
                    />
                </div>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text'>
                        Fator de emissão de metano (kgCH₄/kgDQO)
                    </span>
                </div>
                <div className='selection-input'>
                    <input 
                        style={{ width: "33vh", textAlign: 'right'}}
                        readOnly
                        {...register("treatment_methane_factor_dqo_second")}
                    />
                </div>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text'>
                        Fator de emissão de óxido nitroso (kgN₂O/kgN)
                    </span>
                </div>
                <div className='selection-input'>
                    <input 
                        style={{ width: "33vh", textAlign: 'right'}}
                        readOnly
                        {...register("treatment_factor_emission_nitrous_oxide_second")}
                        value={formatPrice6(factorOxideNitrousSecond, false)}
                        />
                </div>
            </div>
        </div>
    </Container>
  )
}

