import { api } from "./api";

async function getById(treatment_typeId: number) {
  try {
    const { data } = await api.get(`/treatment_type/${treatment_typeId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/treatment_type", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(treatment_type: any) {
  try {
    const { data } = await api.post(`/treatment_type`, treatment_type);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(treatment_typeId: number, treatment_type: any) {
  try {
    const { data } = await api.put(`/treatment_type/${treatment_typeId}`, treatment_type);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(treatment_typeId: number) {
  try {
    const { data } = await api.delete(`/treatment_type/${treatment_typeId}`);
    return data;
  } catch (error) {
    return false;
  }
}

const treatmentTypeService = {
  getById,
  getByParams,
  create,
  updateById,
  deleteById
};

export default treatmentTypeService;
