import React, {useEffect} from "react";
import { Container } from "./styles";
import { useForm } from "react-hook-form";

type AlternativeProps = {
  alternative: boolean | null; 
  setAlternative: (value: boolean) => void;
  register: (value: string) => any;
  setValue: (value: string, e: any) => any;
  handleChange: () => void
};

export const Alternative: React.FC<AlternativeProps> = ({ alternative, setAlternative, register, setValue, handleChange}) => {

  return (
    <Container>
      <div className="body">
        <div className="div-text">
          Você possui os dados do volume de efluente gerado [m³], da carga orgânica [DBO/m³ ou DQO/m³] e teor de N [kgN/m³] do efluente?
        </div>
        <div className="checkbox">
          <span>SIM</span>
          <input
            type="radio"
            value="1"
            style={{width: 20}}
            {...register("alternative")}
            checked={alternative === true}
            onChange={() => {
              setValue("alternative", true);
              setAlternative(true)
              handleChange();
            }}
          />
          <span>NÃO</span>
          <input
            type="radio"
            value="0"
            style={{width: 20}}
            {...register("alternative")}
            checked={alternative === false}
            onChange={() => {
              setValue("alternative", false);
              setAlternative(false)
              handleChange();
            }}
          />
        </div>
      </div>
    </Container>
  );
};
