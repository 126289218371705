import { api } from './api'

async function getResume(companyId: string, year: string) {
  try {
    const { data } = await api.get(`/resume/${companyId}/${year}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

async function getIGEE(companyId: string, year: string) {
  try {
    const { data } = await api.get(`/resume/igee/${companyId}/${year}`)
    return data
  } catch (error) {
    console.error("Error getting IGEE: ", error)
  }
}

async function getIndicators(companyId: string, year: string) {
  try {
    const { data } = await api.get(`/resume/indicators/${companyId}/${year}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

const projectMaccService = {
  getResume,
  getIndicators,
  getIGEE
}

export default projectMaccService