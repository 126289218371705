import React from 'react'
import AttachmentIcon from '../../../../../assets/icons/attachment.svg'

interface Props {
  attachmentChangeHandler: any
}

const InsertScopeEvidenceAttachments: React.FC<Props> = (props) => {
  const { attachmentChangeHandler } = props

  return (
    <>
      <div 
        style={{
          borderStyle: 'dashed',
          marginTop: 20,
          borderWidth: '1px',
          paddingTop: 15,
          paddingBottom: 15,
          borderColor: '#9E9E9E',
          width: '100%',
          textAlign: 'center',
          cursor: 'pointer'
        }}
        onClick={() =>
          (document.querySelector('#attachmentInput') as HTMLInputElement).click()
        }
      >
        <img alt="attachmentIcon" src={AttachmentIcon} width={32} />

        <br />

        <span style={{ padding: 5, fontSize: 14, fontWeight: 'bold' }}>
          Insira aqui os arquivos que comprovam as evidências do escopo informado.
        </span>
      </div>

      <input
        type="file"
        id="attachmentInput"
        style={{ display: 'none' }}
        name="file" multiple
        onChange={attachmentChangeHandler}
      />
    </>
  )
}

export default InsertScopeEvidenceAttachments
