import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import equipamentService from "../../../services/equipamentService";
import { Container } from "./styles";
import { formatPrice  } from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

interface Inputs {
  name: string;
  fe_percent_1: any,
  fe_percent_2: any,
  fe_percent_3: any,
  fe_percent_4: any,
  description: string;
}

export const EquipamentEdit = () => {
  const history = useHistory();

  const [equipamentSelected, setEquipamentSelected] = useState<any>({});
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.equipament_id){
      getEquipamentsById(item.equipament_id)
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location]);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const fe_percent_1 = formData.fe_percent_1.replace(".", "");
      const fe_percent_2 = formData.fe_percent_2.replace(".", "");
      const fe_percent_3 = formData.fe_percent_3.replace(".", "");
      const fe_percent_4 = formData.fe_percent_4.replace(".", "");

      const newEquipament = {
        name: formData.name,
        description: formData.description,
        fe_percent_1: fe_percent_1 ? parseFloat(fe_percent_1.replace(",", ".")) : 0,
        fe_percent_2: fe_percent_2 ? parseFloat(fe_percent_2.replace(",", ".")) : 0,
        fe_percent_3: fe_percent_3 ? parseFloat(fe_percent_3.replace(",", ".")) : 0,
        fe_percent_4: fe_percent_4 ? parseFloat(fe_percent_4.replace(",", ".")) : 0,
      };

      if (equipamentSelected?.id) {  
        const result = await equipamentService.updateById(
          equipamentSelected.id,
          newEquipament
        );

        if (result && !result.error) {
          //history.goBack()

          //reset();
          //setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await equipamentService.create(newEquipament);

        if (result && !result.error) {
          //history.goBack()

        //  reset();
         // setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };
    
  function currency(e: React.FormEvent<HTMLInputElement>) {
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d)(\d{4})$/, "$1,$2");
      value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
      return value;
  }

  async function getEquipamentsById(id: number) {
    const equipament = await equipamentService.getById(id);

    setValue("name", equipament?.name);
    setValue("description", equipament?.description);
    setValue("fe_percent_1", formatPrice(Number(equipament.fe_percent_1), false));
    setValue("fe_percent_2", formatPrice(Number(equipament.fe_percent_2), false));
    setValue("fe_percent_3", formatPrice(Number(equipament.fe_percent_3), false));
    setValue("fe_percent_4", formatPrice(Number(equipament.fe_percent_4), false));

    setEquipamentSelected(equipament)
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Equipamento de refrigeração" />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 10, marginLeft: 50, marginRight: 50  }} >
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Nome
          </label>

          <input style={{width: '95%'}}
                {...register("name", { required: true })}/>
                      
          {errors.name && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 10 }}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "25%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Fator 01
              </label>

              <input style={{width: '95%', height: 100}}
                    {...register("fe_percent_1")}
                    onChange={(event) => {
                        setValue("fe_percent_1", currency(event));
                    }}/>
                          
              {errors.fe_percent_1 && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "25%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Fator 02
              </label>

              <input style={{width: '95%', height: 100}}
                    {...register("fe_percent_2")}
                    onChange={(event) => {
                        setValue("fe_percent_2", currency(event));
                    }}/>
                          
              {errors.fe_percent_2 && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Fator 03
              </label>

              <input style={{width: '95%', height: 100}}
                    {...register("fe_percent_3")}
                    onChange={(event) => {
                        setValue("fe_percent_3", currency(event));
                    }}/>
                          
              {errors.fe_percent_3 && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Fator 04
              </label>

              <input style={{width: '95%', height: 100}}
                    {...register("fe_percent_4")}
                    onChange={(event) => {
                        setValue("fe_percent_4", currency(event));
                    }}/>
                          
              {errors.fe_percent_4 && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
          </div>
          
          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Descrição
          </label>

          <textarea style={{width: '95%', height: 100}}
                {...register("description")}/>
                      
          {errors.description && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>
      </form>
    </Container>
  );
};
