import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { DescriptionText } from "../../../components/DescriptionText";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import projectMaccService from "../../../services/projectMaccService";
import InfoButtonMin from '../../../assets/icons/info-button.svg'
import { formatPrice2, toNumberValues } from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import companyService from "../../../services/companyService";
import { Container } from "../styles";
import { toast } from "react-hot-toast";
interface Inputs {
  project: string;
  investment: any;
  financial_beneficiary: any;
  operational_cost: any;
  tax_fees: any;
  lifetime: any;
  annual_emission: any;
}
export const ProjectEdit = () => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();
  const [projectSelected, setProjectSelected] = useState<any>({});
  const [onlyView, setOnlyView] = useState(false);
  const [company, setCompany] = useState<any>([]);
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const projectTooltip = `Nome do projeto almejado para análise.`;
  const investmentTooltip = `Custo da implementação do projeto considerando o valor dos equipamentos, materiais e mão de obra.`;
  const financialBeneficiaryTooltip = `Valor do retorno financeiro que o projeto poderá gerar para o empreendimento, através da redução de custos ou geração de receita adicional. Ex.: A instalação de painel solar permite que o empreendimento deixe de gastar R$ 1.000,00 por ano, nas contas de energia.`;
  const operationalCostTooltip = `Custo referente a manutenção do projeto, seja pela manutenção em equipamentos e troca de peças ou pelo consumo de insumos, considerando a mão de obra necessária.`;
  const taxFeesTooltip = `Taxa paga pelo empreendimento devido ao empréstimo/financiamento de capital. Adicionar o valor em decimais. Ex.: 1,05`;
  const lifetimeTooltip = `Período no qual um projeto/bem novo tem condições de ser operado em sua plenitude, garantindo uma eficiência satisfatória. Ex.: A duração das placas solares é de 30 anos, após esse período as placas devem ser substituídas.`;
  const annualEmissionTooltip = `Quantidade de emissões de CO2e, em toneladas, que o projeto/equipamento evitaria, se fosse implementado. Sendo assim, a emissão anual é calculada da seguinte forma: emissão anual do projeto já implantado - emissão anual da medida de descarbonização estudada.`;
  useEffect(() => {
    const item: any = location?.state;
    if (item && item.company_id) {
      getCompany(item.company_id);
      if (item && item.project_id) {
        getProjectsById(item.project_id);
      }
    } else {
      history.push({ pathname: '/curva-macc' });
    }
  }, [location]);
  useEffect(() => {
    setOnlyView(
      user.profile !== 'sinergia' &&
      user.profile !== 'admin' &&
      user.profile !== 'analyst'
    );
  }, []);
  async function getCompany(id: number) {
    if (id) {
      const result = await companyService.getById(id);
      setCompany(result);
    }
  }
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm<Inputs>();
  const parseCurrency = (value: string) => {
    let withoutThousands = value.replaceAll('.', '');
    let floatValue = parseFloat(withoutThousands.replace(',', '.'));
    return isNaN(floatValue) ? 0 : parseFloat(floatValue.toFixed(2));
  };
  const parseNegativeCurrency = (value: string) => {
    let withoutThousands = value.replaceAll('.', '');
    let floatValue = parseFloat(withoutThousands.replace(',', '.'));
    return isNaN(floatValue) ? 0 : parseFloat(floatValue.toFixed(2));
  };
  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');
    if (value.length > 2) {
      const intValue = parseInt(value.slice(0, -2), 10);
      const decimalValue = value.slice(-2);
      value = `${intValue},${decimalValue}`;
    } else if (value.length === 2) {
      value = `0,${value}`;
    } else if (value.length === 1) {
      value = `0,0${value}`;
    } else {
      value = '0,00';
    }
    value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1');
    return value;
  }
  function currencyWithNegative(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/[^\d-]/g, '');
    const isNegative = value.charAt(0) === '-';
    value = value.replace('-', '');

    if (value.length > 2) {
      const intValue = parseInt(value.slice(0, -2), 10);
      const decimalValue = value.slice(-2);
      value = `${intValue},${decimalValue}`;
    } else if (value.length === 2) {
      value = `0,${value}`;
    } else if (value.length === 1) {
      value = `0,0${value}`;
    } else {
      value = '0,00';
    }
    value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1');
    if (isNegative) {
      value = `-${value}`;
    }
    return value;
  }
  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const newProject = {
        company_id: company.id,
        project: formData.project,
        tax_fees: parseCurrency(formData.tax_fees),
        investment: parseCurrency(formData.investment),
        financial_beneficiary: parseNegativeCurrency(formData.financial_beneficiary),
        operational_cost: parseCurrency(formData.operational_cost),
        annual_emission: parseNegativeCurrency(formData.annual_emission),
        lifetime: formData.lifetime ? parseInt(formData.lifetime) : 0,
      };
      if (projectSelected?.id) {
        const result = await projectMaccService.updateById(projectSelected.id, newProject);
        handleResult(result);
      } else {
        const result = await projectMaccService.create(newProject);
        handleResult(result);
      }
    }
  };
  const handleResult = (result: any) => {
    if (result && !result.error) {
      //history.goBack();
      //reset();
      //setErrorApi(undefined);
      toast.success("Operação realizada com sucesso!");
    }
    if (result.error) {
      setErrorApi(result.error);
    }
  };
  async function getProjectsById(id: number) {
    const project = await projectMaccService.getById(id);
    setValue("project", project?.project);
    setValue("lifetime", project?.lifetime);
    setValue("annual_emission", formatPrice2(Number(project.annual_emission), false));
    setValue("investment", formatPrice2(Number(project.investment), false));
    setValue("financial_beneficiary", formatPrice2(Number(project.financial_beneficiary), false));
    setValue("operational_cost", formatPrice2(Number(project.operational_cost), false));
    setValue("tax_fees", formatPrice2(Number(project.tax_fees), false));
    setProjectSelected(project);
  }
  return (
    <Container>
      <GoBack />
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <div style={{ display: "flex", flexDirection: "column" }} >
            <TitleText level={2} title="Projeto de descarbonização" />
            <DescriptionText title={'Empresa: ' + company.corporate_name} />
          </div>
          {onlyView === false && (
            <Button color="green" size="md" type="submit">
              Salvar
            </Button>
          )}
        </div>
        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />
        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 30, marginLeft: 50, marginRight: 50  }} >
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Nome do projeto
            <div className="tooltip" style={{marginLeft: 10, position: "relative", top: 5}}>
            <img src={InfoButtonMin} alt="Informações" />
              <span className="tooltiptext">{projectTooltip}</span>
            </div>
          </label>
          <input style={{width: '95%'}}
                {...register("project", { required: true })}/>        
          {errors.project && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
          <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 10 }}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Investimento
                <div className="tooltip" style={{marginLeft: 10, position: "relative", top: 5}}>
                <img src={InfoButtonMin} alt="Informações" />
                  <span className="tooltiptext">{investmentTooltip}</span>
                </div>
              </label>
              <input style={{width: '95%', height: 100}}
                    {...register("investment")}
                    onChange={(event) => {
                      setValue("investment", currency(event));
                    }}/>     
              {errors.investment && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Benefício financeiro
                <div className="tooltip" style={{marginLeft: 10, position: "relative", top: 5}}>
                <img src={InfoButtonMin} alt="Informações" />
                  <span className="tooltiptext">{financialBeneficiaryTooltip}</span>
                </div>
              </label>
              <input style={{width: '95%', height: 100}}
                    {...register("financial_beneficiary")}
                    onChange={(event) => {
                      setValue("financial_beneficiary", currencyWithNegative(event));
                    }}/>          
              {errors.financial_beneficiary && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 10 }}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Custo operacional
                <div className="tooltip" style={{marginLeft: 10, position: "relative", top: 5}}>
                  <img src={InfoButtonMin} alt="Informações" />
                  <span className="tooltiptext">{operationalCostTooltip}</span>
                </div>
              </label>
              <input style={{width: '95%', height: 100}}
                    {...register("operational_cost")}
                    onChange={(event) => {
                      setValue("operational_cost", currency(event));
                    }}/>        
              {errors.investment && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Taxa de juros
                <div className="tooltip" style={{marginLeft: 10, position: "relative", top: 5}}>
                  <img src={InfoButtonMin} alt="Informações" />
                  <span className="tooltiptext">{taxFeesTooltip}</span>
                </div>
              </label>
              <input style={{width: '95%', height: 100}}
                    {...register("tax_fees")}
                    onChange={(event) => {
                      setValue("tax_fees", currency(event));
                    }}/>      
              {errors.financial_beneficiary && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 10 }}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Tempo de vida
                <div className="tooltip" style={{marginLeft: 10, position: "relative", top: 5}}>
                  <img src={InfoButtonMin} alt="Informações" />
                  <span className="tooltiptext">{lifetimeTooltip}</span>
                </div>
              </label>
              <input style={{width: '95%', height: 100}}
                    {...register("lifetime")}
                    onChange={(event) => {
                      const { value } = event.target
                      event.target.value = toNumberValues(value)
                    }}/>     
              {errors.investment && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Emissão anual
                <div className="tooltip" style={{marginLeft: 10, position: "relative", top: 5}}>
                  <img src={InfoButtonMin} alt="Informações" />
                  <span className="tooltiptext">{annualEmissionTooltip}</span>
                </div>
              </label>
              <input style={{width: '95%', height: 100}}
                    {...register("annual_emission")}
                    onChange={(event) => {
                        setValue("annual_emission", currencyWithNegative(event));
                    }}/>         
              {errors.financial_beneficiary && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
          </div>
        </div>
      </form>
    </Container>
  );
};