import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Container } from "./styles";
import { transformCNPJ } from "../../utils/transform";
import inventoryService from "../../services/inventoryService";
import companyService from "../../services/companyService";
import { BulletInventory } from "./components/BulletInventory";
import { BulletScope } from "./components/BulletScope";
import { GoBack } from "../../components/GoBack";
import { Pagination } from "../../components/Pagination";
import { TitleText } from "../../components/TitleText";
import useAuth from "../../hooks/useAuth";
import AttachmentIcon from "../../assets/icons/attachment.svg";
import { ModalExportInventory } from "./components/ModalExportInventory";
import { ModalExportIGEE } from "./components/ModalExportIGEE";
import resumeService from "../../services/resumeService";
import { MACCChartPDF } from "../MaccCurve/utils/MACCChartPDF";
import { Loading } from "../../components/Loading";

export const Inventory = () => {
  const history = useHistory();
  const [year, setYear] = useState<any>([]);
  const [years, setYears] = useState<any>([]);
  const [companys, setCompanys] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [inventory, setInventory] = useState<any>({});
  const [igeeData, setIgeeData] = useState<any>(null);
  const [inventorys, setInventorys] = useState<any>([]);
  const [companySelected, setCompanySelected] = useState<any>([]);
  const [barChartMaccImageBase64, setBarChartMaccImageBase64] =
    useState<string>("");
  const [projectsMacc, setProjectsMacc] = useState<any[]>([]);
  const [loadingModal, setLoadingModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const { user } = useAuth();

  const { register, setValue } = useForm<any>();

  const handleBarMaccImageChange = useCallback((image: string) => {
    setBarChartMaccImageBase64(image);
  }, []);

  async function getInventorys(page: number, companyId: number, year: any | undefined) {
    setLoadingModal(true);
    if (companyId) {
      const yearValue = year > 0 ? year : undefined;
  
      const result = await inventoryService.getByParams({
        limit: 25,
        page,
        company: companyId,
        year: yearValue,
      });
  
      setInventorys(result);
  
      setCurrentPage(page);
      setLoadingModal(false);
    }
  }
  

  async function getIgeeData(companyId: string, year: string) {
    const data = await resumeService.getIGEE(companyId, year);

    setIgeeData(data);
    setProjectsMacc(data.maccCurve_itens?.macc_curve);

    return data;
  }

  const getCompanys = useCallback(async () => {
    try {
      const result = await companyService.getByParams({ limit: 9000, page: 1 });
      setCompanys(result?.itens);

      const savedCompany = localStorage.getItem("selectedCompany");
      if (savedCompany) {
        const company = result.itens.find((item: any) => item.id === parseInt(savedCompany));
        if (company) {
          const option = { label: company.corporate_name, value: company.id };
          setCompanySelected(option);
          setValue("company", company.id);
          loadYears(company.id);
        }
      } else if (result?.itens?.length > 0) {
        const initialCompany = result.itens[0];
        const option = { label: initialCompany.corporate_name, value: initialCompany.id };
        setCompanySelected(option);
        setValue("company", initialCompany.id);
        loadYears(initialCompany.id);
      } else {
        console.warn("Nenhuma empresa encontrada.");
      }
    } catch (error) {
      console.error("Erro ao buscar empresas:", error);
    }
  }, [setValue]);
  

  useEffect(
    () => {
      getCompanys();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [getCompanys]
  );

  useEffect(() => {
    if (companySelected && companySelected.value && year) {
      getInventorys(1, companySelected.value, year);
    }
  }, [companySelected, year]);  

  function redirectToRoute(inventory: any, route: string, state?: any) {
    if (inventory.company_id && inventory.year) { 
      const path = `/inventario/${route}`;
      const routeState = {
        company_id: inventory.company_id,
        year: inventory.year,
        ...state,
      };

      history.push({
        pathname: path,
        state: routeState,
      });
    }
  }

  function redirectToCompany(inventory?: any) {
    redirectToRoute(inventory, "edicao");
  }

  function redirectToBasicData(inventory?: any) {
    if (inventory.company_id && inventory.year) {
      redirectToRoute(inventory, "dados-basicos");
    }
  }

  function redirectToCompanyInfo(inventory?: any) {
    redirectToRoute(inventory, "info");
  }

  function redirectToMethod(inventory?: any) {
    if (inventory.company_id && inventory.year) {
      redirectToRoute(inventory, "metodo");
    }
  }

  function redirectToCompensation(inventory?: any) {
    redirectToRoute(inventory, "compensacao");
  }

  function redirectToOther(inventory?: any) {
    redirectToRoute(inventory, "outro");
  }

  function redirectToScope(inventory: any, type: number) {
    if (inventory.company_id && inventory.year) {
      redirectToRoute(inventory, `escopo-${type}`, { scope: type });
    }
  }

  function redirectThirdPart(inventory?: any) {
    redirectToRoute(inventory, "auditoria");
  }

  function redirectOperLimit(inventory?: any) {
    redirectToRoute(inventory, "limite-operational");
  }

  function redirectInventoryLimit(inventory?: any) {
    redirectToRoute(inventory, "limite-inventario");
  }

  function redirectToGoal(inventory?: any) {
    redirectToRoute(inventory, "meta");
  }

  const goPrev = () => {
    setCurrentPage(currentPage - 1);
    getInventorys(currentPage - 1, companySelected.value, year);
  };

  const goNext = () => {
    setCurrentPage(currentPage + 1);
    getInventorys(currentPage + 1, companySelected.value, year);
  };

  const handleChangeCompany = (e: any) => {
    const id = e.target.value;
  
  
    localStorage.setItem("selectedCompany", id);
  

    localStorage.setItem("selectedCompany", id);
  
    async function loadCompany() {
      const selected = await companyService.getById(id);
      setCompanySelected({
        value: selected.id,
        label: selected.corporate_name,
      });
      loadYears(id);
    }
    loadCompany();
  };
  
  



  const loadYears = (id: number) => {
    async function loadCompany() {
      const selected = await companyService.getById(id);
  
      const years = [];
      years.push({ id: 0, name: "TODOS" });
  
      if (selected?.years) {
        for (const item of selected?.years) {
          years.push({ id: item.year, name: item.year });
        }
      }
  
      setYears(years);
  
      const savedYear = localStorage.getItem("selectedYear");
      const yearToSelect = savedYear ? parseInt(savedYear) : years[0].id;
  
      if (years && years.length > 0) {
        selectYear(id, yearToSelect);
      }
    }
  
    loadCompany();
  };

  const selectYear = (companyId: number, year?: number) => {
    if (year !== undefined) {
      setYear(year);
    } else {
      setYear(0);
    }
    getInventorys(1, companyId, year);
  };

const handleChangeYear = (e: any) => {
  const id = e.target.value;

  localStorage.setItem("selectedYear", id);

  const yearValue = id === '0' ? undefined : parseInt(id);

  selectYear(companySelected.value, yearValue);
};


  async function handleModalVisible(inventory: any = undefined) {
    setLoadingModal(true)
    if (!openModal) {
      await getIgeeData(
        inventory.company_id.toString(),
        inventory.year.toString()
      );
      setInventory(inventory);
    }
    setOpenModal(!openModal);
    setLoadingModal(false)
  }

  useEffect(() => {
    const savedYear = localStorage.getItem("selectedYear");
    if (savedYear) {
      setYear(parseInt(savedYear));
    }
  }, []);
  
  

  return (
    <Container>
      <GoBack />
      <div
        className="row"
        style={{
          marginTop: 25,
        }}
      >
        <TitleText level={1} title="Inventários" />

        <select
          style={{
            background: "#F6F6F6",
            border: "1px solid #DFDFDF",
            borderRadius: " 2.5px",
            width: "232px",
            height: "40px",
            marginLeft: 20,
            marginRight: 10,
          }}
          {...register("company", { required: true })}
          value={companySelected?.value || ''}
          onChange={handleChangeCompany}
        >
          {companys &&
            companys.map((company: any) => {
              return (
                <option value={company.id} key={company.id}>
                  {company.corporate_name}
                </option>
              );
            })}
        </select>

        {years && (
          <select
          style={{
            background: "#F6F6F6",
            border: "1px solid #DFDFDF",
            borderRadius: " 2.5px",
            width: "120px",
            height: "40px",
          }}
          {...register("year", { required: true })}
          value={year || ''} 
          onChange={handleChangeYear}
        >
          {years.map((yearOption: any) => {
            return (
              <option value={yearOption.id} key={yearOption.id}>
                {yearOption.name}
              </option>
            );
          })}
        </select>        
        )}
      </div>

      {user.is_locked === true && (
        <div style={{ position: "relative", height: 300 }}>
          <div
            style={{
              display: "block",
              padding: 50,
              margin: 0,
              position: "absolute",
              top: "50%",
            }}
          >
            <span
              style={{ display: "block", padding: 50, textAlign: "center" }}
            >
              Você tem <b>{user.limit_lock_days} dias</b> para fazer o download
              das informações do IGEE da sua organização. Após este período os
              dados serão apagados. Caso necessário entre em contato com os
              administradores!
            </span>

            <div
              style={{
                borderStyle: "dashed",
                borderWidth: "1px",
                margin: "0 auto",
                paddingTop: 15,
                paddingBottom: 15,
                borderColor: "#9E9E9E",
                width: "75%",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() =>
                (
                  document.querySelector("#attachmentInput") as HTMLInputElement
                ).click()
              }
            >
              <img src={AttachmentIcon} alt="coisa" width={32} />

              <br />

              <span style={{ padding: 5, fontSize: 14, fontStyle: "bold" }}>
                Efetuar download do Inventário
              </span>
            </div>
          </div>
        </div>
      )}

      {user.is_locked === false && inventorys?.itens && (
        <div
          style={{
            overflow: "hidden auto",
            position: "relative",
            marginTop: 10,
            height: "calc(100vh - 130px)",
            maxHeight: "calc(100vh - 130px)",
          }}
        >
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr
                style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}
              >
                <th
                  style={{
                    textAlign: "start",
                    padding: "5px",
                    color: "#7A7D83",
                    fontWeight: "700",
                  }}
                >
                  {"Ano"}
                </th>
                <th
                  style={{
                    textAlign: "start",
                    padding: "5px",
                    color: "#7A7D83",
                    fontWeight: "700",
                  }}
                >
                  {"Razão social"}
                </th>
                <th
                  style={{
                    textAlign: "start",
                    padding: "5px",
                    color: "#7A7D83",
                    fontWeight: "700",
                  }}
                >
                  {"CNPJ"}
                </th>

                {/* TITULO DE PERCENTUAL NA TELA DE INVENTÁRIO */}
                {/* <th style={{ textAlign: 'start', padding: '5px', color: '#7A7D83', fontWeight: '700' }} >
                  {'Percentual'}
                </th> */}

                <th
                  style={{
                    textAlign: "start",
                    padding: "5px",
                    color: "#7A7D83",
                    fontWeight: "700",
                  }}
                />
              </tr>
            </thead>
            <tbody>
              {inventorys.itens.map((item: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <tr style={{ paddingBottom: 5 }}>
                      <td style={{ padding: "10px" }}>
                        <span className="title-text-underline">
                          {item.year}
                        </span>
                      </td>
                      <td style={{ padding: "10px" }}>
                        <span
                          className="list-text"
                          style={{ fontSize: "14px" }}
                        >
                          {item.company_corporate_name}
                        </span>
                      </td>
                      <td style={{ padding: "10px" }}>
                        <span
                          className="list-text"
                          style={{ fontSize: "14px" }}
                        >
                          {transformCNPJ(item.company_cnpj)}
                        </span>
                      </td>

                      {/* BOTÃO DE PERCENTUAL NA TELA DE INVENTÁRIO */}
                      {/* <td style={{ padding: '10px' }} rowSpan={2}>
                        <span className="list-text" style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }} >
                          <StatusLabel status={item.fill_percentage} />
                        </span>
                      </td> */}

                      {/* BOTÃO DE EXPORTAR NA TELA DE INVENTÁRIO */}
                      {/* <td style={{ padding: '10px' }} rowSpan={2}>
                        <button
                          className="button-view"
                          style={{ marginRight: 5 }}
                          onClick={() => handleModalVisible(item)}
                        >
                          Exportar
                        </button>
                      </td> */}
                      {/* BOTÃO DE EXPORTAR O RELATÓRIO IGEE NA TEAL DE INVENTÁRIO */}
                      <td style={{ padding: "10px" }} rowSpan={2}>
                        <button
                          className="button-view"
                          style={{ marginRight: 5 }}
                          onClick={() => handleModalVisible(item)}
                        >
                          Baixar IGEE
                        </button> 
                      </td> 
                    </tr>
                    <tr
                      style={{
                        borderBottomStyle: "dashed",
                        borderBottomWidth: 1,
                      }}
                    >
                      <td style={{ padding: "0px" }} colSpan={3}>
                        <div
                          style={{
                            height: "auto",
                            paddingLeft: 20,
                            paddingBottom: 10,
                          }}
                        >
                          <BulletInventory
                            completed={item.has_basicdata}
                            handleOnClick={redirectToBasicData}
                            width={60}
                            inventory={item}
                            title="Inventário"
                          />

                          <div style={{ display: "none" }}>
                            <BulletInventory
                              completed={item.has_company_basicdata}
                              handleOnClick={redirectToCompany}
                              width={100}
                              inventory={item}
                              title="Dados da empresa"
                            />

                            <BulletInventory
                              completed={item.has_company_info}
                              handleOnClick={redirectToCompanyInfo}
                              width={80}
                              inventory={item}
                              title="Info adicional"
                            />
                          </div>

                          <BulletInventory
                            completed={item.has_third_party_verified}
                            handleOnClick={redirectThirdPart}
                            width={50}
                            inventory={item}
                            title="Auditoria"
                          />

                          <BulletInventory
                            completed={item.has_inventory_goal}
                            handleOnClick={redirectToGoal}
                            width={40}
                            inventory={item}
                            title="Metas"
                          />

                          <BulletInventory
                            completed={item.has_inventory_limits}
                            handleOnClick={redirectInventoryLimit}
                            width={130}
                            inventory={item}
                            title="Limites organizacionais"
                          />

                          <BulletInventory
                            completed={item.has_operational_limits}
                            handleOnClick={redirectOperLimit}
                            width={120}
                            inventory={item}
                            title="Limites operacionais"
                          />

                          <BulletInventory
                            completed={item.has_inventory_method}
                            handleOnClick={redirectToMethod}
                            width={50}
                            inventory={item}
                            title="Métodos"
                          />

                          <BulletInventory
                            completed={item.has_inventory_compensation}
                            handleOnClick={redirectToCompensation}
                            width={140}
                            inventory={item}
                            title="Compensações/reduções"
                          />

                          <BulletInventory
                            completed={item.has_inventory_other}
                            handleOnClick={redirectToOther}
                            width={40}
                            inventory={item}
                            title="Outros"
                          />
                          {/* BOTÃO ESCOPO 1 OCULTO NA TELA DE INVENTÁRIO */}
                          <BulletScope
                            status={item.fill_percentage_scope1}
                            handleOnClick={redirectToScope}
                            width={60}
                            inventory={item}
                            type={1}
                            title="Escopo 01"
                          />

                          {/* BOTÃO ESCOPO 2 OCULTO NA TELA DE INVENTÁRIO */}
                          <BulletScope
                            status={item.fill_percentage_scope2}
                            handleOnClick={redirectToScope}
                            width={60}
                            inventory={item}
                            type={2}
                            title="Escopo 02"
                          />

                          {/* BOTÃO ESCOPO 3 OCULTO NA TELA DE INVENTÁRIO */}
                          <BulletScope
                            status={item.fill_percentage_scope3}
                            handleOnClick={redirectToScope}
                            width={60}
                            inventory={item}
                            type={3}
                            title="Escopo 03"
                          />
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          {inventorys?.itens?.length > 0 && (
            <Pagination
              total={inventorys.total}
              limit={25}
              goNext={goNext}
              goPrev={goPrev}
              currentPage={currentPage}
              length={inventorys.itens.length}
            />
          )}
        </div>
      )}

      <div style={{ display: "hidden" }}>
        <MACCChartPDF
          projects={projectsMacc}
          onChangeImage={handleBarMaccImageChange}
        />
      </div>

      {openModal && inventory && igeeData && (
        <ModalExportIGEE
          isOpen={openModal}
          handleModalVisible={handleModalVisible}
          inventory={inventory}
          resume={igeeData}
          maccCurveBase64={barChartMaccImageBase64}
        />
      )}
      {loadingModal && <Loading isOpen={loadingModal} />}
    </Container>
  );
};
