import React, { useEffect, useState, useCallback } from "react";
import { Document, PDFDownloadLink, Page } from "@react-pdf/renderer";
import { Button } from "../../../../../components/Button/styles";
import { IGEEProps } from "../interfaces/IGEEData";
import { Capa } from "./Pages/Capa";
import { Sumario } from "./Pages/Sumario";
import { Page3 } from "./Pages/1-Dados-org";
import { Page4 } from "./Pages/2-Info-org";
import { Page5 } from "./Pages/3-Info-ins";
import { Page6 } from "./Pages/4-Dados-inv";
import { Page7 } from "./Pages/5-1-org-limits";
import { Page8 } from "./Pages/5-2-op-limits";
import { Page9 } from "./Pages/6-1-totals";
import { Page10 } from "./Pages/6-2-resumo";
import { Page11 } from "./Pages/6-3-escope1";
import { Page12 } from "./Pages/6-4-escope2";
import { Page13 } from "./Pages/6-5-escope3";
import { Page14 } from "./Pages/6-6-kyoto";
import { Page15 } from "./Pages/7-intensidade";
import { Page16 } from "./Pages/8-descarb";
import { Page17 } from "./Pages/9-metodos";
import { Page18 } from "./Pages/10-other";
import { Page19 } from "./Pages/11-compensations";
import { Page20 } from "./Pages/12-anexos";
import { Page22 } from "./Pages/13-anexos";
import { Page23 } from "./Pages/14-anexos";
import { Page21 } from "./Pages/Conclusao";
import { Page24 } from "./Pages/10.1-other";
import { Page25 } from "./Pages/15-anexos";
import { Page26 } from "./Pages/16-anexos";
import { Page27 } from "./Pages/17-anexos";
import { Page28 } from "./Pages/7.1-intensidade";
import { PageDescarbResume } from "./Pages/8.1-descarb";
import { PageDescarbTable } from "./Pages/8.2-descarb";
import { Final } from "./Pages/Final";
import { Loading } from "../../../../../components/Loading";
import { PageOther } from "./Pages/10.2-other";
import { ChartDisaggregated } from "./Pages/6-5-escope3-chart";


export const DownloadIGEE: React.FC<IGEEProps> = (props) => {
  const [loadingModal, setLoadingModal] = useState(false);
  const { data } = props;
  const { resume } = props;
  const { maccCurveBase64 } = props;

  const document = (
    <Document>
      <Capa
        logo={resume?.company_itens.logo_image_url}
        year={data?.year}
        cover={resume?.company_itens.cover_image_url}
        companyName={data?.company_corporate_name}
      />
      <Sumario resume={resume} logo={resume?.company_itens.logo_image_url} companyId={data?.company_id} />
      <Page3 data={data} resume={resume} />
      <Page4 resume={resume} />
      <Page5 resume={resume} />
      <Page6 data={data} resume={resume} />
      <Page7 
        resume={resume} 
        companyName={data?.company_corporate_name}
      />
      <Page8 data={data} resume={resume} />
      <Page9 data={data} resume={resume} />
      <Page10 data={data} resume={resume} />
      <Page11 data={data} resume={resume} />
      <Page12 data={data} resume={resume} />
      <Page13 data={data} resume={resume} />
      <ChartDisaggregated data={data} resume={resume} />
      <Page14 data={data} resume={resume} />
      <Page15 resume={resume} />
      <Page28 resume={resume} />
      <Page16 resume={resume} maccCurveBase64={maccCurveBase64} />
      <PageDescarbResume resume={resume} />
      <PageDescarbTable resume={resume}/>
      <Page17 resume={resume} year={data?.year}/>
      <Page18 resume={resume} />
      <PageOther resume={resume} />
      <Page24 resume={resume} />
      <Page19 resume={resume} />
      <Page20
        resume={resume}
        logo={resume?.company_itens.logo_image_url}
        anexes={resume?.attachments.attachments}
        companyId={data?.company_id}
      />
      <Page22
        resume={resume}
        logo={resume?.company_itens.logo_image_url} 
        anexes={resume?.attachments.attachments} 
        companyId={data?.company_id}
      />
      <Page23 
        resume={resume}
        logo={resume?.company_itens.logo_image_url} 
        anexes={resume?.attachments.attachments}
        companyId={data?.company_id} 
      />
      <Page25 
        resume={resume}
        logo={resume?.company_itens.logo_image_url} 
        anexes={resume?.attachments_certificate.attachments_certificate} 
        companyId={data?.company_id} 
      />
      <Page26 
        resume={resume}
        logo={resume?.company_itens.logo_image_url} 
        anexes={resume?.attachments_compensation_reduction.attachments_compensation_reduction} 
        companyId={data?.company_id} 
      />
      <Page27 
        resume={resume}
        logo={resume?.company_itens.logo_image_url} 
        anexes={resume?.attachments_art.attachments_art}
        companyId={data?.company_id}  
      />
      <Page21 resume={resume} />
      <Final />
    </Document>
  );
  const filename = `Relatório IGEE:${resume?.company_itens.corporate_name}-Ano:${data?.year}.pdf`;

  const handleDownloadClick = () => {
    setLoadingModal(true);
    setTimeout(() => {
      setLoadingModal(false);
    }, 2000);
  };

  return (
    <>
      {data && (
      <>
        <PDFDownloadLink
          document={document}
          fileName={filename}
        >
          {({ loading }) =>
            loading ? "Carregando..." : "Baixar IGEE"
          }
        </PDFDownloadLink>
        {loadingModal && <Loading isOpen={loadingModal} />}
      </>
    )}
    </>
  );
};
