import { ReactNode } from "react";
import ReactModal from "react-modal";
import CloseIcon from "../../assets/icons/close.svg";
import { Container } from "../../components/Modal/styles";
import { TitleText } from "../TitleText";

type ModalProps = {
  isOpen: boolean;
  handleModalVisible: () => void;
  message?: string; // Adiciona uma propriedade para a mensagem de erro
  isPayOrTypeClient?: boolean;
};

ReactModal.setAppElement("#root");

export function Alert({ isOpen, handleModalVisible, message, isPayOrTypeClient }: ModalProps) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflow: "auto",
      maxHeight: "82%",
      padding: "20px",
      width: isPayOrTypeClient ? "auto" : "400px"
    },
  };

  return (
    <Container>
      <ReactModal isOpen={isOpen} style={customStyles}>
        <div style={{ display: "flex", justifyContent: "flex-end", position: "relative", float: "right", zIndex: 1000 }}>
          <img
            src={CloseIcon}
            alt="close-icon"
            style={{ cursor: "pointer" }}
            onClick={handleModalVisible}
          />
        </div>
        <div>
          <TitleText level={2} title="Aviso:" color="red"/>
          {message}
        </div>
      </ReactModal>
    </Container>
  );
}
