import styled from "styled-components";

type PaginationProps = {
  showQuantity?: boolean;
};

export const Container = styled.div<PaginationProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ showQuantity }) =>
    showQuantity ? "space-between" : "flex-end"};
  padding-top: 10px;

  .navigation-text {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #205ecd;
    opacity: 0.8;
    margin: 0 15px;
  }
`;
