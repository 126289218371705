import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import { styles as tableStyles } from '../../../../components/PDF/styles/table'
import { styles } from '../../../../components/PDF/styles/common'
import { TheadItem } from '../../../../components/PDF/TheadItem'
import { TbodyItem } from '../../../../components/PDF/TbodyItem'
import { formatPrice3 } from '../../../../utils/transform'

export const Scope3Emissions: React.FC<any> = (props) => {
  const { pdfData } = props
  const { upstreamTco2e} = props
  const { upstreamCo2Bio} = props
  const { downstreamTco2e} = props
  const { downstreamCo2Bio} = props
  const { homeWorkTco2e} = props
  const { homeWorkCo2Bio } = props
  const { businessTco2e} = props
  const { businessCo2Bio} = props
  const { wasteTco2e} = props
  const { wasteCo2Bio} = props


  return (
    <View wrap={false} style={styles.textContainer}>
      <Text style={{...tableStyles.tableTitle, ...styles.light}}>
        Emissões de Escopo 3 desagregadas por categoria
      </Text>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TheadItem width="40%">Categoria</TheadItem>
        <TheadItem width="20%">
          Emissões tCO<Text style={styles.subscript}>₂</Text>e
        </TheadItem>
        <TheadItem width="20%">
          Emissões CO<Text style={styles.subscript}>₂</Text> biogênico
        </TheadItem>
        <TheadItem width="20%">
          Remoções CO<Text style={styles.subscript}>₂</Text> biogênico
        </TheadItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          1. Bens e serviços comprados
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.purchased_goods_services.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.purchased_goods_services.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.purchased_goods_services.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          2. Bens de capital
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.capital_goods.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.capital_goods.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.capital_goods.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          3. Atividades relacionadas com combustível e energia não inclusas nos Escopos 1
          e 2
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.fuel_energy_not_scope_1_2.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.fuel_energy_not_scope_1_2.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.fuel_energy_not_scope_1_2.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          4. Transporte e distribuição (upstream)
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.transport_distribution_upstream.emission_tco2e + upstreamTco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.transport_distribution_upstream.emission_co2_bio + upstreamCo2Bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.transport_distribution_upstream.removal_of_bio_co2,false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          5. Resíduos e efluentes gerados nas operações
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.waste_generated_operations.emission_tco2e + wasteTco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.waste_generated_operations.emission_co2_bio + wasteCo2Bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.waste_generated_operations.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          6. Viagens a negócios
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.business_travel.emission_tco2e + businessTco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.business_travel.emission_co2_bio + businessCo2Bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.business_travel.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          7. Deslocamento de funcionários (casa-trabalho)
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.home_work_displacement.emission_tco2e + homeWorkTco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.home_work_displacement.emission_co2_bio + homeWorkCo2Bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.home_work_displacement.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          8. Bens arrendados (a organização como arrendatária)
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.leased_assets_as_lessee.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.leased_assets_as_lessee.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.leased_assets_as_lessee.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          9. Transporte e distribuição (downstream)
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.transport_distribution_downstream.emission_tco2e + downstreamTco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(
            pdfData.transport_distribution_downstream.emission_co2_bio + downstreamCo2Bio,false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(
            pdfData.transport_distribution_downstream.removal_of_bio_co2,false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          10. Processamento de produtos vendidos
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.processing_sold_products.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.processing_sold_products.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.processing_sold_products.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          11. Uso de bens e serviços vendidos
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.use_sold_goods_services.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.use_sold_goods_services.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.use_sold_goods_services.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          12. Tratamento de fim de vida dos produtos vendidos
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.end_life_products_sold.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.end_life_products_sold.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.end_life_products_sold.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          13. Bens arrendados (a organização como arrendadora)
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.leased_assets_as_lessor.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.leased_assets_as_lessor.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.leased_assets_as_lessor.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          14. Franquias
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.deductibles.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.deductibles.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.deductibles.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          15. Investimentos
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.investments.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.investments.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.investments.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          Emissões de Escopo 3 não classificáveis nas categorias 1 a 15
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.not_classifiable_categories.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.not_classifiable_categories.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.not_classifiable_categories.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={tableStyles.totalRow}>
        <TbodyItem textAlign="left" width="40%">
          Total de emissões Escopo 3
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.totals.emission_tco2e + upstreamTco2e + downstreamTco2e + homeWorkTco2e + businessTco2e + wasteTco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.totals.emission_co2_bio + upstreamCo2Bio + downstreamCo2Bio + homeWorkCo2Bio + businessCo2Bio + wasteCo2Bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.totals.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
    </View>
  )
}
