import { api } from "./api";

async function getById(energy_typeId: number) {
  try {
    const { data } = await api.get(`/energy_type/${energy_typeId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/energy_type", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(energy_type: any) {
  try {
    const { data } = await api.post(`/energy_type`, energy_type);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(energy_typeId: number, energy_type: any) {
  try {
    const { data } = await api.put(`/energy_type/${energy_typeId}`, energy_type);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(energy_typeId: number) {
  try {
    const { data } = await api.delete(`/energy_type/${energy_typeId}`);
    return data;
  } catch (error) {
    return false;
  }
}

const energyTypeService = {
  getById,
  getByParams,
  create,
  updateById,
  deleteById
};

export default energyTypeService;
