import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 1px dashed #9E9E9E;
    margin-top: 20px;

    width: 100%;
    text-align: center;
    cursor: wait;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
    }
`;