import React, { useEffect, useState } from "react";
import { GoBack } from "../../../../components/GoBack";
import { TitleText } from "../../../../components/TitleText";
import useAuth from "../../../../hooks/useAuth";
import inventoryService from "../../../../services/inventoryService";
import { Container } from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "../../../../components/Button/styles";
import { formatPrice3 } from "../../../../utils/transform";
import { formatPrice5 } from "../../../../utils/transform";
import scope1Service from "../../../../services/scope1Service";
import { Modal } from "../../../../components/Modal";
import Parser from 'html-react-parser';
import scopeService from "../../../../services/scopeService";
import { ModalScopeDisabled } from "../../components/ModalScopeDisabled";

export const FugitiveEmissions = () => {
  const history = useHistory();

  const [itemSelected, setItemSelected] = useState<any>({});
  const [openModalTotals, setOpenModalTotals] = useState(false);
  const [openModalDisabled, setOpenModalDisabled] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  const [guidelines, setGuidelines] = useState<string | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [fugitiveEmissions, setFugitiveEmissions] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [inventorySelected, setInventorySelected] = useState<any>({});
  const location = useLocation();

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.company_id && item.year){
      getInventorysById(item.company_id, item.year, item.scope)
    }else{
      history.push({
        pathname: '/inventario/escopo-1',
      });
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location]);

  async function getGuidelines() {
    const item = await scopeService.getBySlug(1, 'emissoes_fugitivas');

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  async function getInventorysById(company_id: number, year: number, scope:number) {
    const inventory = await inventoryService.getById(company_id, year)

    const entity = await scope1Service.getFugitiveEmissions(company_id, year)

    setFugitiveEmissions(entity)
    setInventorySelected(inventory)
  }

  function handleOpenInfo () {
    setOpenModal(!openModal)
  }

  function redirectToScope(item?: any) {
    if (inventorySelected.company && inventorySelected.company.id && inventorySelected.year){
      if (item.status !== 'disabled'){
        const url = '/inventario/escopo-1/emissoes-fugitivas/' + item.slug.replaceAll('_', '-')

        const state = { company_id: inventorySelected.company.id, year: inventorySelected.year }

        history.push({ pathname: url, state: state });
      }else{
        handleOpenModalDisabled(item)
      }
    }
  }

  function handleOpenModalDisabled(item?: any) {
    setItemSelected(item)
    setOpenModalDisabled(!openModalDisabled);
  }

  const handleOpenTotals = () => {
    setOpenModalTotals(!openModalTotals)
  }
  
  return (
    <Container>
      <GoBack />

      <div style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <div style={{ display: "flex", flexDirection: "column" }} >
            <div style={{ display: "flex", flexDirection: "column"}} >
              <TitleText level={1} title={'Inventário: ' + inventorySelected?.year + ' - ' + inventorySelected?.company?.corporate_name}  />
              <TitleText level={4} title={'Escopo 01 - ' + title}  />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }} >
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
          </div>
        </div>

        {fugitiveEmissions?.scopes && (
          <div style={{ overflow: "hidden auto", position: "relative", marginTop: 0,  height: "calc(100vh - 360px)", 
              maxHeight: "calc(100vh - 360px)"}} >
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                  <th style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}>
                    {"Escopo"}
                  </th>
                  <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontWeight: "700" }}>
                    {"Status"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {fugitiveEmissions?.scopes.map((scope: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr style={{paddingBottom: 5}}>
                        <td style={{ padding: "10px", width: "95%", cursor: "pointer" }}
                            onClick={() => redirectToScope(scope)}>
                          <span className="title-text-underline">
                            {scope.label}
                          </span>
                          <br/>
                          {scope.description && (
                            <span style={{fontSize: 12}}>
                              {Parser(scope.description)}
                            </span>
                          )}
                        </td>
                        <td style={{ padding: "10px" }} >
                          {scope.status === 'disabled' && (
                            <div className="bulled-scope-disabled" />
                          )}
                          {scope.status === 'pending' && (
                            <div className="bulled-scope-pending" />
                          )}
                          {scope.status === 'draft' && (
                            <div className="bulled-scope-draft" />
                          )}
                          {scope.status === 'completed' && (
                            <div className="bulled-scope-completed" />
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        <div style={{ margin: 20 }} >
          <div style={{ display: "flex", flexDirection: "row"}} >
            <div style={{ display: "flex", flexDirection: "column", width: "50%" }} >
              <span style={{textAlign: "start", fontSize: 16, fontWeight: "bold", marginTop: 5}}>
                Resumo das emissões por gás de efeito estufa
              </span>
              <span style={{textAlign: "start", fontSize: 14}}>
                Clique ao lado para ver os detalhes dos resumo de emissões
              </span>
            </div>

            <div style={{ display: "flex", flexDirection: "row", width: "50%", justifyContent: "right" }} >
              <button type="button" style={{ width: "180px", height: "47px", background: "#ffffff",
                  color: "#31363F", borderColor: "#9E9E9E", borderRadius: "2.5px", cursor: "pointer",
                  borderStyle: "dashed", fontWeight: "bold",  borderWidth: "1px", marginRight: 10 }}
                  onClick={() => handleOpenTotals()} >
                {`Emissões fugitivas totais`}
              </button>
            </div>
          </div>
        </div>

        <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                      width: "100%", borderColor: "#9E9E9E", height: "auto", 
                      display: "flex", flexDirection: "column", marginLeft: 0}} >
          <div style={{ width: "100%", display: "flex", flexDirection: "row"}} >
            <span style={{ margin: 10, width: "70%" }}>
              Emissões totais em CO₂ equivalente (toneladas métricas):
            </span>
            <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
              {formatPrice3(fugitiveEmissions.emission_tco2e, false)}
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "row", color: "#31363F", 
                fontSize: 12, fontWeight: "bold", marginLeft: 10 }} >
            Atenção: para os cálculos aqui apresentados não existe a emissão de CO₂ biogênico associado.
          </div>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: 20, marginRight: 10 }}>
        <div style={{ display: "flex", flexDirection: "row", marginRight: 10 }} >
            <div  className="bulled-scope-disabled" />
            <span className="status-legend">Escopo não contratado</span>
        </div>

        <div style={{ display: "flex", flexDirection: "row", marginRight: 10 }} >
            <div  className="bulled-scope-pending" />
            <span className="status-legend">Item não preenchido</span>
        </div>

        <div style={{ display: "flex", flexDirection: "row", marginRight: 10 }} >
            <div  className="bulled-scope-completed" />
            <span className="status-legend">Preenchimento realizado</span>
        </div>
      </div>

      <Modal isOpen={openModalTotals} handleModalVisible={() => handleOpenTotals()} isPayOrTypeClient={true}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TitleText level={1} title='Resumo das emissões por gás de efeito estufa' />

          {fugitiveEmissions.totals && (
            <div style={{ overflow: "auto", position: "relative", marginTop: 0, 
                    width: "100%", maxWidth: 750, height: "auto", maxHeight: 450}} >
              <table style={{ width: "100%", height: "100%", marginTop: 10, borderCollapse: "collapse"}} 
                      className="table-scope">
                <thead>
                  <tr>
                    <th style={{ backgroundColor: "#D8D7D7", fontSize: "12px", height: "25px", 
                        borderColor: "white", borderWidth: "1px", borderStyle: "solid" }} rowSpan={2}>
                      {"Gás"}
                    </th>
                    <th style={{ backgroundColor: "#D8D7D7", fontSize: "12px", height: "25px", 
                        borderColor: "white", borderWidth: "1px", borderStyle: "solid"}} rowSpan={2}>
                      {"Família/Tipo"}
                    </th>
                    <th style={{ backgroundColor: "#D8D7D7", fontSize: "12px", height: "25px", 
                        borderColor: "white", borderWidth: "1px", borderStyle: "solid"}} rowSpan={2}>
                      {"GWP"}
                    </th>
                    <th style={{ backgroundColor: "#D8D7D7", fontSize: "12px", height: "25px", 
                        borderColor: "white", borderWidth: "1px", borderStyle: "solid"}} colSpan={2}>
                      {"Emissão do gás, excluindo-se a parcela dos compostos"}
                    </th>
                    <th style={{ backgroundColor: "#D8D7D7", fontSize: "12px", height: "25px", 
                        borderColor: "white", borderWidth: "1px", borderStyle: "solid"}} colSpan={2}>
                      {"Emissão do gás referente à parcela dos compostos"}
                    </th>
                    <th style={{ backgroundColor: "#D8D7D7", fontSize: "12px", height: "25px", 
                        borderColor: "white", borderWidth: "1px", borderStyle: "solid"}} colSpan={2}>
                      {"Emissão total do gás"}
                    </th>
                  </tr>
                  <tr>
                    <th style={{ backgroundColor: "#D8D7D7", fontSize: "12px", height: "25px", 
                        borderColor: "white", borderWidth: "1px", borderStyle: "solid"}}>
                      {"(t)"}
                    </th>
                    <th style={{ backgroundColor: "#D8D7D7", fontSize: "12px", height: "25px", 
                        borderColor: "white", borderWidth: "1px", borderStyle: "solid"}}>
                      {"(t CO₂e)"}
                    </th>
                    <th style={{ backgroundColor: "#D8D7D7", fontSize: "12px", height: "25px", 
                        borderColor: "white", borderWidth: "1px", borderStyle: "solid"}}>
                      {"(t)"}
                    </th>
                    <th style={{ backgroundColor: "#D8D7D7", fontSize: "12px", height: "25px", 
                        borderColor: "white", borderWidth: "1px", borderStyle: "solid"}}>
                      {"(t CO₂e)"}
                    </th>
                    <th style={{ backgroundColor: "#D8D7D7", fontSize: "12px", height: "25px", 
                        borderColor: "white", borderWidth: "1px", borderStyle: "solid"}}>
                      {"(t)"}
                    </th>
                    <th style={{ backgroundColor: "#D8D7D7", fontSize: "12px", height: "25px", 
                        borderColor: "white", borderWidth: "1px", borderStyle: "solid"}}>
                      {"(t CO₂e)"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fugitiveEmissions.totals.map((gas: any) => {
                    return (
                      <React.Fragment key={gas.id}>
                        <tr>
                          <td style={{width: 120}}>
                            <span style={{ marginLeft: 5,  width: 200, fontSize: 12, fontWeight: "bold", display: "inline-block" }}>
                              {gas.name}
                            </span>
                          </td>
                          <td style={{width: 100}}>
                            <span style={{ marginLeft: 5,  width: 100, fontSize: 12, fontWeight: "bold", display: "inline-block" }}>
                              {gas.family}
                            </span>
                          </td>
                          <td style={{width: 80}}>
                            <span style={{ marginLeft: 5,  width: 80, fontSize: 12, textAlign: "right", display: "inline-block" }}>
                              {formatPrice5(gas.gwp, false)}
                            </span>
                          </td>
                          <td style={{width: 100}}>
                            <span style={{ marginLeft: 5,  width: 100, fontSize: 12, textAlign: "right", display: "inline-block" }}>
                              {formatPrice5(gas.emissions_minus_t, false)}
                            </span>
                          </td>
                          <td style={{width: 100}}>
                            <span style={{ marginLeft: 5,  width: 100, fontSize: 12, textAlign: "right", display: "inline-block" }}>
                              {formatPrice5(gas.emissions_minus_tco2e, false)}
                            </span>
                          </td>
                          <td style={{width: 100}}>
                            <span style={{ marginLeft: 5,  width: 100, fontSize: 12, textAlign: "right", display: "inline-block" }}>
                              {formatPrice5(gas.emissions_plus_t, false)}
                            </span>
                          </td>
                          <td style={{width: 100}}>
                            <span style={{ marginLeft: 5,  width: 100, fontSize: 12, textAlign: "right", display: "inline-block" }}>
                              {formatPrice5(gas.emissions_plus_tco2e, false)}
                            </span>
                          </td>
                          <td style={{width: 100}}>
                            <span style={{ marginLeft: 5,  width: 100, fontSize: 12, textAlign: "right", display: "inline-block" }}>
                              {formatPrice5(gas.emissions_t, false)}
                            </span>
                          </td>
                          <td style={{width: 100}}>
                            <span style={{ marginLeft: 5,  width: 100, fontSize: 12, textAlign: "right", display: "inline-block" }}>
                              {formatPrice5(gas.emissions_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Modal>

      <Modal isOpen={openModal} handleModalVisible={() => handleOpenInfo()} isPayOrTypeClient={true}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TitleText level={2} title='Orientações gerais' />
        </div>

        <div style={{marginTop: 10, width: 550}}>
          {guidelines && (
            <span style={{fontSize: 12, textAlign: "justify"}}>
              {Parser(guidelines)}
            </span>
          )}
        </div>

      </Modal>

      {openModalDisabled && (
        <ModalScopeDisabled
          isOpen={openModalDisabled}
          handleModalVisible={handleOpenModalDisabled}
          entityName={title + ' - ' + itemSelected.label}
        />
      )}
    </Container>
  );
};
