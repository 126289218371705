import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import treatmentTypeService from "../../../services/treatmentTypeService";
import { Container } from "./styles";

import { formatPrice3, formatPrice5 } from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

interface Inputs {
  mcf: any;
  methane_factor_dbo: any,
  methane_factor_dqo: any,
  factor_emission_nitrous_oxide: any,
  name: string;
  description: string;
}

export const TreatmentTypeEdit = () => {
  const history = useHistory();

  const [treatmentTypeSelected, setGasSelected] = useState<any>({});
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.type_id){
      getGassById(item.type_id)
    }
  },// eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const mcf = formData.mcf.replace(".", "");
      const methane_factor_dbo = formData.methane_factor_dbo.replace(".", "");
      const methane_factor_dqo = formData.methane_factor_dqo.replace(".", "");
      const factor_emission_nitrous_oxide = formData.factor_emission_nitrous_oxide.replace(".", "");


      const newGas = {
        name: formData.name,
        description: formData.description,
        mcf: mcf ? parseFloat(mcf.replace(",", ".")) : 0,
        methane_factor_dbo: methane_factor_dbo ? parseFloat(methane_factor_dbo.replace(",",".")) : 0,
        methane_factor_dqo: methane_factor_dqo ? parseFloat(methane_factor_dqo.replace(",",".")) : 0,
        factor_emission_nitrous_oxide: factor_emission_nitrous_oxide ? parseFloat(factor_emission_nitrous_oxide.replace(",", "")) : 0
      };

      if (treatmentTypeSelected?.id) {
        const result = await treatmentTypeService.updateById(
          treatmentTypeSelected.id,
          newGas
        );

        if (result && !result.error) {
         // history.goBack()

         // reset();
         // setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await treatmentTypeService.create(newGas);

        if (result && !result.error) {
          //history.goBack()

         // reset();
         // setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  async function getGassById(id: number) {
    const treatmentType = await treatmentTypeService.getById(id);

    setValue("name", treatmentType?.name);
    setValue("description", treatmentType?.description);
    setValue("mcf", formatPrice5(Number(treatmentType.mcf), false));
    setValue("methane_factor_dbo", formatPrice5(Number(treatmentType.methane_factor_dbo), false));
    setValue("methane_factor_dqo", formatPrice5(Number(treatmentType.methane_factor_dqo), false));
    setValue("factor_emission_nitrous_oxide", formatPrice5(Number(treatmentType.factor_emission_nitrous_oxide), false));

    setGasSelected(treatmentType)
  }
    
  function currency(e: React.FormEvent<HTMLInputElement>) {
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d)(\d{5})$/, "$1,$2");
      value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
      return value;
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Tipo de tratamento" />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 10, marginLeft: 50, marginRight: 50 }}>
  
        <div style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: 10 }}>
          <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "80%" }}>
            <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }}>
              Nome
            </label>
            <input style={{ width: '95%' }} {...register("name")} />
            {errors.name && <span className='requiredLabel'>Esse campo é obrigatório</span>}
          </div>

          <div style={{ display: "flex", flexDirection: "column", width: "20%" }}>
            <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }}>
              MCF
            </label>
            <input style={{ width: '95%' }} {...register("mcf", { required: true })} onChange={(event) => setValue("mcf", currency(event))} />
            {errors.mcf && <span className='requiredLabel'>Esse campo é obrigatório</span>}
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: 10 }}>
          <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
            <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }}>
              EF (kgCH4/kg DBO)
            </label>
            <input style={{ width: '95%' }} {...register("methane_factor_dbo", { required: true })} onChange={(event) => setValue("methane_factor_dbo", currency(event))} />
            {errors.methane_factor_dbo && <span className='requiredLabel'>Esse campo é obrigatório</span>}
          </div>

          <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
            <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }}>
              EF (kgCH4/kg DQO)
            </label>
            <input style={{ width: '95%' }} {...register("methane_factor_dqo", { required: true })} onChange={(event) => setValue("methane_factor_dqo", currency(event))} />
            {errors.methane_factor_dqo && <span className='requiredLabel'>Esse campo é obrigatório</span>}
          </div>

          <div style={{ display: "flex", flexDirection: "column", width: "33%" }}>
            <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }}>
              kgN2O-N/kgN
            </label>
            <input style={{ width: '95%' }} {...register("factor_emission_nitrous_oxide", { required: true })} onChange={(event) => setValue("factor_emission_nitrous_oxide", currency(event))} />
            {errors.factor_emission_nitrous_oxide && <span className='requiredLabel'>Esse campo é obrigatório</span>}
          </div>
        </div>

        <div style={{ marginTop: 15, width: "100%" }}>
          <label style={{ fontFamily: "'Roboto', sans-serif", fontSize: "16px", lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }}>
            Descrição
          </label>
          <textarea style={{ width: '100%', height: 100 }} {...register("description")} />
          {errors.description && <span className='requiredLabel'>Esse campo é obrigatório</span>}
        </div>
      </div>
      </form>
    </Container>
  );
};
