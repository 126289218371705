import React from 'react'
import { Container } from './styles'
import InfoButtonMin from "../../../../assets/icons/info-sign.png"

type StepTenProps ={
    register: (value: string) => any;
    setValue: (value: string, e: any) => any;
    handleChange: () => void;
}
export const StepTen: React.FC<StepTenProps> = ({
    register,
    setValue,
    handleChange
}) => {

    function moeda(a: string, e: string, r: string, decPlaces = 6): string {
        if (!a || a.replace(/[^\d]+/g, "") === "") {
          return "0" + r + "0".repeat(decPlaces);
        }
    
        let n = a.replace(new RegExp(`\\${e}`, "g"), "").replace(new RegExp(`\\${r}`, "g"), "");
    
        n = n.replace(/[^\d]+/g, "");
    
        while (n.length <= decPlaces) {n = "0" + n;}
    
        const len = n.length;
        const intPart = n.substring(0, len - decPlaces);
        const decPart = n.substring(len - decPlaces);
    
        const cleanedIntPart = intPart.replace(/^0+/, "") || "0";
    
        let formattedValue = cleanedIntPart.replace(/\B(?=(\d{3})+(?!\d))/g, e);
        formattedValue += r + decPart;
    
        return formattedValue;
    }
  return (
   <Container>
           <div className='body'>
               <div className='div-text'>
                   <span className='text-statement'>
                    Passo 10 - Recuperação de metano (CH₄)
                   </span>
                   <div className="tooltip" style={{ margin: 5 }}>
                        <img src={InfoButtonMin} alt="information" style={{ width: 25 }} />
                        <span className="tooltiptext">
                            Se houver, preencha a quantidade de metano (CH₄) recuperado* no tratamento de efluentes, no ano inventariado.
                        </span>
                    </div>
               </div>
   
               <div className='input-item'>
                   <div className='div-item'>
                        <span className='prompt-text'>
                            Quantidade de CH₄ recuperada do tratamento
                        </span>
                        <span style={{ textAlign: "start", fontSize: 10 }}>
                            *Metano Recuperado - Metano gerado que é recuperado, ou seja não é emitido, e queimado em um queimador ("flare") ou utilizado para geração de energia (eletricidade, calor, etc.)
                        </span>
                        <span style={{ textAlign: "start", fontSize: 10 }}>
                            Independente da utilização do metano recuperado (queima em flare ou geração de energia) considera-se que após a queima todo CH4 se transforma em CO2 biogênico, oriundo do biogás.
                        </span>
                   </div>
                    
                   <div className='data-input'>
                       <input style={{width: '185px', height: 25, textAlign: "right"}} 
                           {...register("ch4_amount_recovered_methane_after")}
                           onChange={(
                               event: React.ChangeEvent<HTMLInputElement>
                           ) => {
                               const inputValue = event.target.value;
                               const formattedValue = moeda(inputValue, ".", ",", 6);
                               setValue("ch4_amount_recovered_methane_after", formattedValue);
                               handleChange();
                             
                               event.target.value = formattedValue;
                           }}
                       />
   
                       <span className='unit-text'>
                        [tCH₄/ano]
                     </span>
                   </div>
               </div>
           </div>
       </Container>
  )
}

