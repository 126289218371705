import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import Parser from 'html-react-parser'
import useAuth from '../../../../../hooks/useAuth'
import { Container } from './styles'
import { formatPrice3, formatPrice14 } from '../../../../../utils/transform'
import inventoryService from '../../../../../services/inventoryService'
import scope3Service from '../../../../../services/scope3Service'
import scopeService from '../../../../../services/scopeService'
import TrashIcon from '../../../../../assets/icons/trash.svg'
import { Modal } from '../../../../../components/Modal'
import { GoBack } from '../../../../../components/GoBack'
import { TitleText } from '../../../../../components/TitleText'
import { Button } from '../../../../../components/Button/styles'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import { Uninformed } from '../../../../../components/Uninformed'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'
import { toast } from 'react-hot-toast'
import { Loading } from '../../../../../components/Loading'

export const Incineration3 = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([])
  const [openModal, setOpenModal] = useState(false)
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [title, setTitle] = useState<string | undefined>();
  const [loadingModal, setLoadingModal] = useState(false);
  const [guidelines, setGuidelines] = useState<string | undefined>()
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const location = useLocation()

  const [scopes, setScopes] = useState<any>([])
  const [health, sethealth] = useState<any>([])
  const [changeScope, setChangeScope] = useState<string | undefined>()
  const [changeScope2, setChangeScope2] = useState<string | undefined>()
  const [changeScope3, setChangeScope3] = useState<string | undefined>()

  const [emissionCh4Item, setEmissionCh4Item] = useState<any>()
  const [emissionN2oItem, setEmissionN2oItem] = useState<any>()
  const [emissionCo2Item, setEmissionCo2Item] = useState<any>()
  const [emissionTco2eItem, setEmissionTco2eItem] = useState<any>()
  const [emissionCo2BioItem, setEmissionCo2BioItem] = useState<any>()

  const [amountHealth, setAmountHealth] = useState<any>()
  const [emissionTco2eHealth, setEmissionTco2eHealth] = useState<any>()
  const [emissionTco2eTotal, setEmissionTco2eTotal] = useState<any>()
  const [emissionTco2eBioTotal, setEmissionTco2eBioTotal] = useState<any>()

  const [totalEmissionTco2e, setTotalEmissionTco2e] = useState<any>()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-3/residuos-solidos-gerados'
      })
    }

    getGuidelines()
  },// eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

  useEffect(() => {
    calculateHealthTotals(health)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [totalEmissionTco2e, health])

  useEffect(() => {
    calculateTotals(scopes)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [totalEmissionTco2e, scopes])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(3, 'residuos_solidos_gerados', 'incineracao')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors }
  } = useForm<any>()

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      let health_fossil_carbon = formData.health_fossil_carbon.replace('.', '')
      let health_oxidation_factor = formData.health_oxidation_factor.replace('.', '')
      let health_dry_matter_carbon = formData.health_dry_matter_carbon.replace('.', '')
      let health_conversion_factor = formData.health_conversion_factor.replace('.', '')
      let health_amount_incinerated = formData.health_amount_incinerated.replace('.', '')

      health_fossil_carbon = health_fossil_carbon ? parseFloat(health_fossil_carbon.replace(',', '.')) : 0
      health_oxidation_factor = health_oxidation_factor ? parseFloat(health_oxidation_factor.replace(',', '.')) : 0
      health_dry_matter_carbon = health_dry_matter_carbon ? parseFloat(health_dry_matter_carbon.replace(',', '.')) : 0
      health_conversion_factor = health_conversion_factor ? parseFloat(health_conversion_factor.replace(',', '.')) : 0
      health_amount_incinerated = health_amount_incinerated ? parseFloat(health_amount_incinerated.replace(',', '.')) : 0

      const itens: any[] = scopes.map((item: any) => {
        return {
          source: item.source,
          type_of_waste: item.type_of_waste,
          emission_co2: item.emission_co2,
          emission_ch4: item.emission_ch4,
          emission_n2o: item.emission_n2o,
          emission_tco2e: item.emission_tco2e,
          emission_co2_bio: item.emission_co2_bio,
        }
      })

      const scope = {
        itens: itens,
        reason: formData.reason,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        health_fossil_carbon: health_fossil_carbon,
        health_oxidation_factor: health_oxidation_factor,
        health_dry_matter_carbon: health_dry_matter_carbon,
        health_conversion_factor: health_conversion_factor,
        health_amount_incinerated: health_amount_incinerated,
        health_emission_tco2e: totalEmissionTco2e ? totalEmissionTco2e : 0,
      }

      const result = await scope3Service.saveIncineration(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('Incineration', JSON.stringify(scope));

        reset()
        setErrorApi(undefined)

        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  const { isCommonUser } = useAuth()

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope3Service.getIncineration(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      3,
      'residuos_solidos_gerados',
      'incineracao'
    )

    setFiles(result.files)

    if (entity) {
      setValue( 'health_fossil_carbon', formatPrice3(entity.health_fossil_carbon, false))
      setValue( 'health_oxidation_factor', formatPrice3(entity.health_oxidation_factor, false))
      setValue( 'health_conversion_factor', formatPrice14(entity.health_conversion_factor, false))
      setValue( 'health_dry_matter_carbon', formatPrice3(entity.health_dry_matter_carbon, false))
      setValue( 'health_amount_incinerated', formatPrice3(entity.health_amount_incinerated, false))

      setIgnoreFill(entity.ignore_fill)
      setScopeSelected(entity)
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)
      setValue('methane_recovery', entity.methane_recovery ? '1' : '0')
      setValue('destination_recovered_biogas',
        Number(entity.destination_recovered_biogas)
      )
      setScopeValues(entity?.itens)
      calculateTotals(entity?.itens)
      setScopeHealth(entity?.health)
      calculateHealthTotals(entity?.health)
      setTotalEmissionTco2e(entity.health_emission_tco2e)
      setChangeScope('load_scope_' + entity.id + '_scopes_' + entity?.itens.length)
      setChangeScope3('load_scope_' + entity.health_emission_tco2e)
    } else {
      setValue('two_anaerobic_treatments', '1')

      setScopeSelected({})

      setScopeValues([])
      calculateTotals([])

      setIgnoreFill(false)

      setScopeHealth([])
      calculateHealthTotals([])

      setChangeScope('load_scope_0_scopes_0')
      setChangeScope3('load_scope_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{5})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos

    // Verifica se o valor é igual a zero após a formatação e remove os zeros à esquerda
    if (value === '') {
        value = '0';
    }

    return value;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleNewScope = () => {
    const item = {
      source: undefined,
      type_of_waste: undefined,
      emission_co2: undefined,
      emission_ch4: undefined,
      emission_n2o: undefined,
      emission_tco2e: undefined,
      emission_co2_bio: undefined
    }

    const index = scopes.length

    const itens = scopes.concat(item)

    setChangeScope('new_scope_item_' + index)

    setScopeValues(itens)
    calculateTotals(itens)
    setValue('gases_cycle', [])
  }

  const handleNewHealth = () => {
    const item = {
      source: undefined,
      type_of_waste: undefined,
      emission_tco2e: undefined,
      amount: undefined
    }

    const index = health.length

    const itens = health.concat(item)

    setChangeScope2('new_scope_item_' + index)

    setScopeHealth(itens)
    calculateHealthTotals(itens)
    setValue('gases_cycle', [])
  }

  const setScopeValues = (itens: any, ignore_field?: string) => {
    let changeScope = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`scope.${i}.source`, item.source)
        setValue(`scope.${i}.type_of_waste`, item.type_of_waste)

        if ('emission_co2' !== ignore_field) {
          setValue(`scope.${i}.emission_co2`, item.emission_co2)
        }

        if ('emission_ch4' !== ignore_field) {
          setValue(`scope.${i}.emission_ch4`, item.emission_ch4)
        }

        if ('emission_n2o' !== ignore_field) {
          setValue(`scope.${i}.emission_n2o`, item.emission_n2o)
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`scope.${i}.emission_tco2e`, item.emission_tco2e)
        }

        if ('emission_co2_bio' !== ignore_field) {
          setValue(`scope.${i}.emission_co2_bio`, item.emission_co2_bio)
        }

        changeScope =
          changeScope +
          '_' +
          item.emission_co2 +
          '_' +
          item.emission_ch4 +
          '_' +
          item.emission_n2o
      }
    }

    setScopes(itens)
    setChangeScope(changeScope)
  }

  const setScopeHealth = (itens: any, ignore_field?: string) => {
    let changeScope = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`health.${i}.source`, item.source)
        setValue(`health.${i}.type_of_waste`, item.type_of_waste)

        if ('amount' !== ignore_field) {
          setValue(`health.${i}.amount`, item.amount)
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`health.${i}.emission_tco2e`, item.emission_tco2e)
        }

        changeScope =
          changeScope +
          '_' +
          item.emission_co2 +
          '_' +
          item.emission_ch4 +
          '_' +
          item.emission_n2o
      }
    }

    sethealth(itens)
    setChangeScope2(changeScope)
  }

  const calculateTotals = (itens: any) => {
    let _emissionCo2 = 0
    let _emissionCh4 = 0
    let _emissionN2o = 0
    let _emissionTco2e = 0
    let _emissionCo2Bio = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          _emissionCo2 = _emissionCo2 + item.emission_co2
        }
        if (item.emission_ch4) {
          _emissionCh4 = _emissionCh4 + item.emission_ch4
        }
        if (item.emission_n2o) {
          _emissionN2o = _emissionN2o + item.emission_n2o
        }
        if (item.emission_tco2e) {
          _emissionTco2e = _emissionTco2e + item.emission_tco2e
        }
        if (item.emission_co2_bio) {
          _emissionCo2Bio = _emissionCo2Bio + item.emission_co2_bio
        }
      }
    }

    setEmissionCo2Item(_emissionCo2)
    setEmissionCh4Item(_emissionCh4)
    setEmissionN2oItem(_emissionN2o)
    setEmissionTco2eItem(_emissionTco2e)
    setEmissionCo2BioItem(_emissionCo2Bio)
    
    setEmissionTco2eBioTotal(_emissionCo2Bio)
    setEmissionTco2eTotal((emissionTco2eHealth ? emissionTco2eHealth : 0) + (totalEmissionTco2e ? totalEmissionTco2e : 0) + 
      (_emissionTco2e ? _emissionTco2e : 0))

    setChangeScope( 'calculate_iten_' + _emissionCo2 + '_' + _emissionCh4 + '_' + 
        _emissionN2o + '_' + _emissionTco2e + '_' + _emissionCo2Bio )
  }

  const calculateHealthTotals = (itens: any) => {
    let _amount = 0
    let _emissionHealthTco2e = 0

    if (itens) {
      for (const item of itens) {
        if (item.amount) {
          _amount = _amount + item.amount
        }
        if (item.emission_tco2e) {
          _emissionHealthTco2e = _emissionHealthTco2e + item.emission_tco2e
        }
      }
    }

    setAmountHealth(_amount)
    setEmissionTco2eHealth(_emissionHealthTco2e)

    setEmissionTco2eTotal((_emissionHealthTco2e ? _emissionHealthTco2e : 0) + (totalEmissionTco2e ? totalEmissionTco2e : 0) + 
      (emissionTco2eItem ? emissionTco2eItem : 0))

    setChangeScope('calculate_iten_' + _amount + '_' + _emissionHealthTco2e)
  }

  const handleChangeValue = (index: number, ignore_field?: string) => {
    const emission_co2 = scopes[index].emission_co2
    const emission_ch4 = scopes[index].emission_ch4
    const emission_n2o = scopes[index].emission_n2o

    async function calculate() {
      const result = await scope3Service.calculateIncineration(
        emission_co2,
        emission_ch4,
        emission_n2o
      )

      if (result) {
        scopes[index].emission_tco2e = result.emission_tco2e

        setChangeScope(
          'change_scope_item_' +
            index +
            '_' +
            emission_co2 +
            '_' +
            emission_ch4 +
            '_' +
            emission_n2o
        )

        setScopeValues(scopes, ignore_field)
        calculateTotals(scopes)
      }
    }

    calculate()
  }

  const handleChangeHealthValue = (index: number, ignore_field?: string) => {
    const amount = health[index].amount

    async function calculate() {
      const result = await scope3Service.calculateHealthIncineration(amount)

      if (result) {
        health[index].emission_tco2e = result.emission_tco2e

        setChangeScope2('change_scope_item_' + index + '_' + amount)

        setScopeHealth(health, ignore_field)
        calculateHealthTotals(health)
      }
    }

    calculate()
  }

  function handleDelete(index: number) {
    const itens = []

    for (let i = 0; i < scopes?.length; i++) {
      if (i !== index) {
        itens.push(scopes[i])
      }
    }

    setChangeScope('remove_scope_item_' + index)

    setScopeValues(itens)
    calculateTotals(itens)
  }

  function handleHealthDelete(index: number) {
    const itens = []

    for (let i = 0; i < health?.length; i++) {
      if (i !== index) {
        itens.push(health[i])
      }
    }

    setChangeScope2('remove_scope_item_' + index)

    setScopeHealth(itens)
    calculateHealthTotals(itens)
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'residuos_solidos_gerados',
          'incineracao'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const handleDownloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const handleAttachmentChange = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 3,
        name: 'residuos_solidos_gerados',
        file: base64,
        filename: filename,
        second_name: 'incineracao'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'residuos_solidos_gerados',
          'incineracao'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  const handleChangeBasicValue = () => {
    let health_fossil_carbon = getValues('health_fossil_carbon')
    let health_oxidation_factor = getValues('health_oxidation_factor')
    let health_dry_matter_carbon = getValues('health_dry_matter_carbon')
    let health_conversion_factor = getValues('health_conversion_factor')
    let health_amount_incinerated = getValues('health_amount_incinerated')

    health_fossil_carbon = health_fossil_carbon.replace('.', '')
    health_oxidation_factor = health_oxidation_factor.replace('.', '')
    health_dry_matter_carbon = health_dry_matter_carbon.replace('.', '')
    health_conversion_factor = health_conversion_factor.replace('.', '')
    health_amount_incinerated = health_amount_incinerated.replace('.', '')

    health_fossil_carbon = health_fossil_carbon ? parseFloat(health_fossil_carbon.replace(',', '.')) : 0
    health_oxidation_factor = health_oxidation_factor ? parseFloat(health_oxidation_factor.replace(',', '.')) : 0
    health_dry_matter_carbon = health_dry_matter_carbon ? parseFloat(health_dry_matter_carbon.replace(',', '.')) : 0
    health_conversion_factor = health_conversion_factor ? parseFloat(health_conversion_factor.replace(',', '.')) : 0
    health_amount_incinerated = health_amount_incinerated ? parseFloat(health_amount_incinerated.replace(',', '.')) : 0

    async function calculate() {
      const result = await scope3Service.calculateIncinerationHealth(
        health_fossil_carbon, health_oxidation_factor, health_dry_matter_carbon,
        health_conversion_factor, health_amount_incinerated )

      if (result) {
        setTotalEmissionTco2e(result.total_emission_tco2e)

        setChangeScope3( 'change_scope_item_' + result.total_emission_tco2e )

        setEmissionTco2eTotal((emissionTco2eHealth ? emissionTco2eHealth : 0) + (result.total_emission_tco2e ? result.total_emission_tco2e : 0) + 
          (emissionTco2eItem ? emissionTco2eItem : 0))
      }
    }

    calculate()
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 03 - ' + title}  />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 215px)',
            maxHeight: 'calc(100vh - 215px)'
          }}
        >
          {!ignoreFill && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  overflow: 'hidden auto',
                  position: 'relative',
                  marginTop: 0,
                  height: '100%'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', width: '98%', marginTop: 10, marginLeft: 10 }} >
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, marginRight: 30 }} >
                    <span style={{ textAlign: 'start', fontSize: 16, marginTop: 5, fontWeight: 'bold' }} >
                      Resíduos de saúde
                    </span>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0, 
                          justifyContent: 'space-between', marginBottom: 10 }} >
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, marginRight: 30 }} >
                      <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5 }}>
                        Quantidade de resíduo incinerado [t/ano]
                      </span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                      <input
                        readOnly={isCommonUser}
                        style={{ width: '200px', height: 35, textAlign: 'right' }}
                        {...register('health_amount_incinerated')}
                        onChange={(event) => {
                          setValue('health_amount_incinerated', currency(event))
                          handleChangeBasicValue()
                        }}
                      />
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0,
                      justifyContent: 'space-between', marginBottom: 10 }} >
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, marginRight: 30 }} >
                      <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5 }}>
                        Fração de carbono na matéria seca
                      </span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                      <span style={{ marginTop: 5, textAlign: 'right', width: 200, fontSize: 12 }} >
                        {formatPrice3(scopeSelected.health_dry_matter_carbon, false)}
                      </span>
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0,
                      justifyContent: 'space-between', marginBottom: 10 }} >
                    <div style={{ display: 'flex', flexDirection: 'column',
                        marginTop: 5, marginRight: 30 }} >
                      <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5 }}>
                        Fração de carbono fóssil
                      </span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                      <span style={{ marginTop: 5, textAlign: 'right', width: 200, fontSize: 12 }} >
                        {formatPrice3(scopeSelected.health_fossil_carbon, false)}
                      </span>
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0,
                      justifyContent: 'space-between', marginBottom: 10 }} >
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, marginRight: 30 }} >
                      <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5 }}>
                        Fator de oxidação
                      </span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                      <span style={{ marginTop: 5, textAlign: 'right', width: 200, fontSize: 12 }} >
                        {formatPrice3(scopeSelected.health_oxidation_factor, false)}
                      </span>
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0,
                      justifyContent: 'space-between', marginBottom: 10 }} >
                    <div style={{ display: 'flex', flexDirection: 'column',
                        marginTop: 5, marginRight: 30 }} >
                      <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5 }}>
                        Fator de conversão de C para CO₂
                      </span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                      <span style={{ marginTop: 5, textAlign: 'right', width: 200, fontSize: 12 }} >
                        {formatPrice3(scopeSelected.health_conversion_factor, false)}
                      </span>
                    </div>
                  </div>

                  {changeScope3 && (
                    <div style={{ borderStyle: 'dashed', margin: 5, borderWidth: '1px',
                        width: '100%', borderColor: '#9E9E9E', height: '40px', display: 'flex',
                        flexDirection: 'row', marginLeft: 0 }} >
                      <span style={{ margin: 10, width: '70%' }}>
                        Quantidade de CO₂, gerada no ano (t/ano)
                      </span>
                      <span style={{ fontWeight: 'bold', margin: 10, textAlign: 'right', width: '30%' }} >
                        {formatPrice3(totalEmissionTco2e, false)}
                      </span>
                    </div>
                  )}
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '98%',
                    marginTop: 20,
                    marginLeft: 0
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                    {/* <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: 0,
                        width: '70%'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginTop: 5,
                          marginRight: 30
                        }}
                      >
                        <span
                          style={{
                            textAlign: 'start',
                            fontSize: 16,
                            marginTop: 5,
                            fontWeight: 'bold'
                          }}
                        >
                          Emissões totais de resíduos tratados por incineração
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginTop: 5,
                          marginRight: 30
                        }}
                      >
                        <span
                          style={{
                            textAlign: 'start',
                            fontSize: 12,
                            marginTop: 5,
                            marginBottom: 10
                          }}
                        >
                          Esta seção da ferramenta permite o relato das emissões de GEE calculadas a partir de outras ferramentas/métodos, 
                          para que essas sejam consideradas no resultado final do inventário de GEE. / Recomenda-se que os dados de 
                          emissões por incineração de resíduos sejam obtidos diretamente com o operador do incinerador ou que sejam 
                          calculados com o uso de métodos reconhecidos como os do IPCC (2006).
                        </span>
                      </div>
                    </div> */}
                    {/* {!isCommonUser && (
                      <div style={{ textAlign: 'right', width: '30%' }}>
                        <button
                          type="button"
                          style={{
                            width: '160px',
                            height: '47px',
                            background: '#ffffff',
                            color: '#31363F',
                            borderColor: '#9E9E9E',
                            borderRadius: '2.5px',
                            cursor: 'pointer',
                            borderStyle: 'dashed',
                            fontWeight: 'bold',
                            borderWidth: '1px'
                          }}
                          onClick={() => handleNewScope()}
                        >
                          {`Novo registro`}
                        </button>
                      </div>
                    )} */}
                  </div>

                  <div
                    style={{
                      overflow: 'auto',
                      position: 'relative',
                      marginTop: 0,
                      width: 'calc(100vw - 320px)',
                      maxWidth: 'calc(100vw - 320px)'
                    }}
                  >
                    {changeScope && scopes && scopes.length > 0 && (
                      <table
                        style={{
                          maxWidth: '100%',
                          width: '4000px',
                          borderCollapse: 'collapse'
                        }}
                        className="table-scope"
                      >
                        {/* <thead>
                          <tr>
                            <th className="head-tr-item" style={{ width: 200 }}>
                              {'Registro da fonte'}
                            </th>
                            <th className="head-tr-item" style={{ width: 200 }}>
                              {'Tipo de resíduo incinerado'}
                            </th>
                            <th className="head-tr-item" style={{ width: 200 }}>
                              {'Emissões de'}
                              <br />
                              {'CO₂ (t)'}
                            </th>
                            <th className="head-tr-item" style={{ width: 200 }}>
                              {'Emissões de'}
                              <br />
                              {'CH₄ (t)'}
                            </th>
                            <th className="head-tr-item" style={{ width: 200 }}>
                              {'Emissões de'}
                              <br />
                              {'N₂O (t)'}
                            </th>
                            <th className="head-tr-item" style={{ width: 200 }}>
                              {'Emissões de'}
                              <br />
                              {'CO₂e (t)'}
                            </th>
                            <th className="head-tr-item" style={{ width: 200 }}>
                              {'Emissões de CO₂'}
                              <br />
                              {'biogênico (t)'}
                            </th>
                            {!isCommonUser && (
                              <th className="head-tr-item" style={{ width: 50 }} />
                            )}
                          </tr>
                        </thead> */}
                        <tbody>
                          {scopes.map((scope: any, index: number) => {
                            return (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 200 }}
                                      className="input-item"
                                      {...register(`scope.${index}.source`, {
                                        required: true
                                      })}
                                      onChange={(event) => {
                                        setValue(
                                          `scope.${index}.source`,
                                          event.target.value
                                        )
                                        scopes[index].source = event.target.value
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 200 }}
                                      className="input-item"
                                      {...register(`scope.${index}.type_of_waste`, {
                                        required: true
                                      })}
                                      onChange={(event) => {
                                        setValue(
                                          `scope.${index}.type_of_waste`,
                                          event.target.value
                                        )
                                        scopes[index].type_of_waste = event.target.value
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 120, textAlign: 'right' }}
                                      className="input-item"
                                      {...register(`scope.${index}.emission_co2`)}
                                      onChange={(event) => {
                                        const formatValue = event.target.value;
                                        const valueStr = formatValue.replace('.', '')
                                        const value = valueStr
                                          ? parseFloat(valueStr.replace(',', '.'))
                                          : 0

                                        scopes[index].emission_co2 = value

                                        setValue(
                                          `scope.${index}.emission_co2`,
                                          formatValue
                                        )

                                        handleChangeValue(index, 'emission_co2')
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 120, textAlign: 'right' }}
                                      className="input-item"
                                      {...register(`scope.${index}.emission_ch4`)}
                                      onChange={(event) => {
                                        const formatValue = event.target.value;
                                        const valueStr = formatValue.replace('.', '')
                                        const value = valueStr
                                          ? parseFloat(valueStr.replace(',', '.'))
                                          : 0

                                        scopes[index].emission_ch4 = value

                                        setValue(
                                          `scope.${index}.emission_ch4`,
                                          formatValue
                                        )

                                        handleChangeValue(index, 'emission_ch4')
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 120, textAlign: 'right' }}
                                      className="input-item"
                                      {...register(`scope.${index}.emission_n2o`)}
                                      onChange={(event) => {
                                        const formatValue = event.target.value;
                                        const valueStr = formatValue.replace('.', '')
                                        const value = valueStr
                                          ? parseFloat(valueStr.replace(',', '.'))
                                          : 0

                                        scopes[index].emission_n2o = value

                                        setValue(
                                          `scope.${index}.emission_n2o`,
                                          formatValue
                                        )

                                        handleChangeValue(index, 'emission_n2o')
                                      }}
                                    />
                                  </td>
                                  <td style={{ width: 100 }}>
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        width: 100,
                                        fontSize: 12,
                                        textAlign: 'right',
                                        display: 'inline-block'
                                      }}
                                    >
                                      {formatPrice3(scope.emission_tco2e, false)}
                                    </span>
                                  </td>
                                  <td>
                                    <input
                                      readOnly={isCommonUser}
                                      style={{ width: 120, textAlign: 'right' }}
                                      className="input-item"
                                      {...register(`scope.${index}.emission_co2_bio`)}
                                      onChange={(event) => {
                                        const formatValue = event.target.value;
                                        const valueStr = formatValue.replace('.', '')
                                        const value = valueStr
                                          ? parseFloat(valueStr.replace(',', '.'))
                                          : 0

                                        scopes[index].emission_co2_bio = value

                                        setValue(
                                          `scope.${index}.emission_co2_bio`,
                                          formatValue
                                        )

                                        handleChangeValue(index, 'emission_co2_bio')
                                      }}
                                    />
                                  </td>
                                  {!isCommonUser && (
                                    <td style={{ textAlign: 'center' }}>
                                      <div
                                        onClick={() => handleDelete(index)}
                                        style={{
                                          cursor: 'pointer',
                                          textAlign: 'center',
                                          width: 70
                                        }}
                                      >
                                        <img src={TrashIcon} alt="lixeirinha"/>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              </React.Fragment>
                            )
                          })}
                        </tbody>
                        <tfoot>
                          <tr style={{ fontWeight: 'bold' }}>
                            <td
                              colSpan={2}
                              className="head-tr-item"
                              style={{ paddingRight: 5, textAlign: 'right' }}
                            >
                              {'Total'}
                            </td>
                            <td className="head-tr-item">
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 120,
                                  fontSize: 12,
                                  textAlign: 'right',
                                  display: 'inline-block'
                                }}
                              >
                                {formatPrice3(emissionCo2Item, false)}
                              </span>
                            </td>
                            <td className="head-tr-item">
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 120,
                                  fontSize: 12,
                                  textAlign: 'right',
                                  display: 'inline-block'
                                }}
                              >
                                {formatPrice3(emissionCh4Item, false)}
                              </span>
                            </td>
                            <td className="head-tr-item">
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 120,
                                  fontSize: 12,
                                  textAlign: 'right',
                                  display: 'inline-block'
                                }}
                              >
                                {formatPrice3(emissionN2oItem, false)}
                              </span>
                            </td>
                            <td className="head-tr-item">
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 120,
                                  fontSize: 12,
                                  textAlign: 'right',
                                  display: 'inline-block'
                                }}
                              >
                                {formatPrice3(emissionTco2eItem, false)}
                              </span>
                            </td>
                            <td className="head-tr-item">
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 120,
                                  fontSize: 12,
                                  textAlign: 'right',
                                  display: 'inline-block'
                                }}
                              >
                                {formatPrice3(emissionCo2BioItem, false)}
                              </span>
                            </td>
                            {!isCommonUser && (
                              <td colSpan={5} className="head-tr-item" />
                            )}
                          </tr>
                        </tfoot>
                      </table>
                    )}

                    {/* {!scopes.length && (
                      <Uninformed
                        text={
                          isCommonUser
                            ? `Nenhum registro cadastrado`
                            : `Nenhum registro foi informado, clique em 'novo' para incluir
                          seu registro.`
                        }
                      />
                    )} */}

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        marginBottom: 10
                      }}
                    >
                      {errors && errors.scope && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            margin: 10
                          }}
                        >
                          <span
                            className="requiredLabel"
                            style={{ fontSize: 14, marginTop: 10 }}
                          >
                            * Registro da fonte e Tipo de resíduo incinerado são obrigatórios
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {false && (
                  <div style={{ display: 'flex', flexDirection: 'column', width: '98%', marginTop: 20, marginLeft: 0 }} >
                    <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                      <div style={{ display: 'flex', flexDirection: 'column', margin: 0, width: '70%' }} >
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, marginRight: 30 }} >
                          <span style={{ textAlign: 'start', fontSize: 16, marginTop: 5, fontWeight: 'bold' }} >
                            Incineração de resíduos de serviços de saúde
                          </span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, marginRight: 30 }} >
                          <span
                            style={{
                              textAlign: 'start',
                              fontSize: 12,
                              marginTop: 5,
                              marginBottom: 10
                            }}
                          >
                            Esta seção da ferramenta permite o relato das emissões de GEE
                            calculadas a partir de outras ferramentas/métodos, para que
                            essas sejam consideradas no resultado final do inventário de
                            GEE.
                          </span>
                        </div>
                      </div>
                      <div style={{ textAlign: 'right', width: '30%' }}>
                        <button
                          type="button"
                          style={{
                            width: '160px',
                            height: '47px',
                            background: '#ffffff',
                            color: '#31363F',
                            borderColor: '#9E9E9E',
                            borderRadius: '2.5px',
                            cursor: 'pointer',
                            borderStyle: 'dashed',
                            fontWeight: 'bold',
                            borderWidth: '1px'
                          }}
                          onClick={() => handleNewHealth()}
                        >
                          {`Novo registro`}
                        </button>
                      </div>
                    </div>

                    <div
                      style={{
                        overflow: 'auto',
                        position: 'relative',
                        marginTop: 0,
                        width: 'calc(100vw - 320px)',
                        maxWidth: 'calc(100vw - 320px)'
                      }}
                    >
                      {changeScope2 && !!health.length && (
                        <table
                          style={{
                            maxWidth: '100%',
                            width: '4000px',
                            borderCollapse: 'collapse'
                          }}
                          className="table-scope"
                        >
                          <thead>
                            <tr>
                              <th className="head-tr-item" style={{ width: 200 }}>
                                {'Registro da fonte'}
                              </th>
                              <th className="head-tr-item" style={{ width: 200 }}>
                                {'Tipo de resíduo'}
                                <br />
                                {'e tratamento'}
                              </th>
                              <th className="head-tr-item" style={{ width: 200 }}>
                                {'Emissões de'}
                                <br />
                                {'CO₂ (t)'}
                              </th>
                              <th className="head-tr-item" style={{ width: 200 }}>
                                {'Emissões de'}
                                <br />
                                {'CO₂e (t)'}
                              </th>
                              {!isCommonUser && (
                                <th className="head-tr-item" style={{ width: 50 }} />
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {health.map((item: any, index: number) => {
                              return (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td>
                                      <input
                                        readOnly={isCommonUser}
                                        style={{ width: '95%' }}
                                        className="input-item"
                                        {...register(`health.${index}.source`, {
                                          required: true
                                        })}
                                        onChange={(event) => {
                                          setValue(
                                            `health.${index}.source`,
                                            event.target.value
                                          )
                                          health[index].source = event.target.value
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        readOnly={isCommonUser}
                                        style={{ width: '95%' }}
                                        className="input-item"
                                        {...register(`health.${index}.type_of_waste`, {
                                          required: true
                                        })}
                                        onChange={(event) => {
                                          setValue(
                                            `health.${index}.type_of_waste`,
                                            event.target.value
                                          )
                                          health[index].type_of_waste = event.target.value
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        readOnly={isCommonUser}
                                        style={{ width: '95%', textAlign: 'right' }}
                                        className="input-item"
                                        {...register(`health.${index}.amount`)}
                                        onChange={(event) => {
                                          const formatValue = event.target.value;
                                          const valueStr = formatValue.replace('.', '')
                                          const value = valueStr
                                            ? parseFloat(valueStr.replace(',', '.'))
                                            : 0

                                          health[index].amount = value

                                          setValue(`health.${index}.amount`, formatValue)

                                          handleChangeHealthValue(index, 'amount')
                                        }}
                                      />
                                    </td>
                                    <td style={{ width: 120 }}>
                                      <span
                                        style={{
                                          marginRight: 15,
                                          width: '100%',
                                          fontSize: 12,
                                          textAlign: 'right',
                                          display: 'inline-block'
                                        }}
                                      >
                                        {formatPrice3(item.emission_tco2e, false)}
                                      </span>
                                    </td>
                                    {!isCommonUser && (
                                      <td style={{ textAlign: 'center' }}>
                                        <div
                                          onClick={() => handleHealthDelete(index)}
                                          style={{
                                            cursor: 'pointer',
                                            textAlign: 'center',
                                            width: 70
                                          }}
                                        >
                                          <img src={TrashIcon} alt="lixeirinha"/>
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                </React.Fragment>
                              )
                            })}
                          </tbody>
                          <tfoot>
                            <tr style={{ fontWeight: 'bold' }}>
                              <td
                                colSpan={2}
                                className="head-tr-item"
                                style={{ paddingRight: 5, textAlign: 'right' }}
                              >
                                {'Total'}
                              </td>
                              <td className="head-tr-item">
                                <span
                                  style={{
                                    marginRight: 15,
                                    width: '100%',
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(amountHealth, false)}
                                </span>
                              </td>
                              <td className="head-tr-item">
                                <span
                                  style={{
                                    marginRight: 15,
                                    width: '100%',
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(emissionTco2eHealth, false)}
                                </span>
                              </td>
                              {!isCommonUser && <td colSpan={5} className="head-tr-item" />}
                            </tr>
                          </tfoot>
                        </table>
                      )}

                      {!health.length && (
                        <Uninformed
                          text={
                            isCommonUser
                              ? `Nenhum registro cadastrado`
                              : `Nenhum registro foi informado, clique em 'novo' para incluir seu
                            registro.`
                          }
                        />
                      )}

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          marginBottom: 10
                        }}
                      >
                        {errors && errors.health && (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              margin: 10
                            }}
                          >
                            <span
                              className="requiredLabel"
                              style={{ fontSize: 14, marginTop: 10 }}
                            >
                              * Registro da fonte e Tipo de resíduo incinerado são obrigatórios
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {!ignoreFill && (
            <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                          width: "calc(100vw - 330px)", maxWidth: "calc(100vw - 330px)",
                          borderColor: "#9E9E9E", height: "40px", display: "flex", 
                          flexDirection: "row", marginLeft: 15}} >
              <span style={{ margin: 10, width: "70%" }}>
                Emissões totais em CO₂ equivalente (toneladas métricas):
              </span>
              <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                {formatPrice3(emissionTco2eTotal, false)}
              </span>
            </div>
          )}

          {!ignoreFill && (
            <div className="row" style={{ borderStyle: 'dashed', margin: 5, borderWidth: '1px', width: 'calc(100vw - 330px)',
                maxWidth: 'calc(100vw - 330px)', borderColor: '#9E9E9E', height: '40px', marginLeft: 15 }} >
              <span style={{ margin: 10, width: '70%' }}>
                Emissões totais em CO₂ biogênico (toneladas métricas):
              </span>
              <span style={{ fontWeight: 'bold', margin: 10, textAlign: 'right', width: '30%' }} >
                {formatPrice3(emissionTco2eBioTotal, false)}
              </span>
            </div>
          )}

          {ignoreFill === false && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={handleDownloadFile}
                  onChangeAttachment={handleAttachmentChange}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  )
}
