import React, {useEffect, useState} from "react";
import { Container } from "./styles";
import { GoBack } from "../../../../../components/GoBack";
import { TitleText } from "../../../../../components/TitleText";
import useAuth from "../../../../../hooks/useAuth";
import inventoryService from "../../../../../services/inventoryService";
import { useHistory, useLocation } from "react-router-dom";
import scope3Service from "../../../../../services/scope3Service";
import scopeService from "../../../../../services/scopeService";
import { Button } from "../../../../../components/Button/styles";
import { Loading } from "../../../../../components/Loading";
import { Modal } from "../../../../../components/Modal";
import Parser from 'html-react-parser';
import { SubmitHandler, useForm } from "react-hook-form";
import ScopeEvidenceAttachments from "../../../components/ScopeEvidenceAttachments";
import toast from "react-hot-toast";
import treatmentTypeService from "../../../../../services/treatmentTypeService";
import { formatPrice3, formatPrice6 } from "../../../../../utils/transform";
import { FirstStep } from "../../../../../components/EffluentsScope3/FirstStep";
import { SecondStep } from "../../../../../components/EffluentsScope3/AlternativeOne/SecondStep";
import { Alternative } from "../../../../../components/EffluentsScope3/Alternative";
import { ThirdStep } from "../../../../../components/EffluentsScope3/AlternativeOne/ThirdStep";
import { StepFour } from "../../../../../components/EffluentsScope3/AlternativeOne/StepFour";
import IpccService from "../../../../../services/ipccService";
import { StepFive } from "../../../../../components/EffluentsScope3/AlternativeOne/StepFive";
import { StepSix } from "../../../../../components/EffluentsScope3/AlternativeOne/StepSix";
import { StepSeven } from "../../../../../components/EffluentsScope3/AlternativeOne/StepSeven";
import { StepEight } from "../../../../../components/EffluentsScope3/AlternativeOne/StepEight";
import { StepNine } from "../../../../../components/EffluentsScope3/AlternativeOne/StepNine";
import { StepTen } from "../../../../../components/EffluentsScope3/AlternativeOne/StepTen";
import { StepEleven } from "../../../../../components/EffluentsScope3/AlternativeOne/StepEleven";
import { StepTwelve } from "../../../../../components/EffluentsScope3/AlternativeOne/StepTwelve";
import { StepTwo } from "../../../../../components/EffluentsScope3/AlternativeTwo/StepTwo";
import occupationTypeService from "../../../../../services/occupationTypeService";
import { StepThree } from "../../../../../components/EffluentsScope3/AlternativeTwo/StepThree";

export const EffluentsDomestic = () => {
    const history = useHistory();

    const {isCommonUser} = useAuth()

    const [emissionCh4, setEmissionCh4] = useState<any>();
    const [emissionN2o, setEmissionN2o] = useState<any>();
    const [emissionCo2, setEmissionCo2] = useState<any>();
    const [emissionCo2Bio, setEmissionCo2Bio] = useState<any>();

    const [loadingModal, setLoadingModal] = useState(false);
    const [guidelines, setGuidelines] = useState<string | undefined>();
    const [openModal, setOpenModal] = useState(false);
    const location = useLocation();
    const [ignoreFill, setIgnoreFill] = useState(true);
    const [title, setTitle] = useState<string>();
    const [files, setFiles] = useState<any>([]);
    const [inventorySelected, setInventorySelected] = useState<any>({});
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [errorApi, setErrorApi] = useState<string | undefined>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [scopeSelected, setScopeSelected] = useState<any>({});
    const [openModalDelete, setOpenModalDelete] = useState(false);

    const [effluentsType, setEffluentsType] = useState([]);
    const [treatmentTypes, setTreatmentTypes] = useState<any>([]);
    const [treatmentFinalTypes, setTreatmentFinalTypes] = useState<any[]>([]);
    const [factorOxideNitrousFinal, setFactorOxideNitrousFinal] = useState<any>();

    const [occupationType, setOccupationType] = useState<any[]>([]);
    const [volumeEffluents, setVolumeEffluents] = useState<any>();
    const [organicLoad, setOrganicLoad] = useState<any>();
    const [contentNitrogen, setContentNitrogen] = useState<any>();

    const [finalFactorMethaneDbo, setFinalFactorMethaneDbo] = useState<any>();
    const [finalFactorMethaneDqo, setFinalFactorMethaneDqo] = useState<any>();
    const [releasedEffluent, setReleasedEffluent] = useState<boolean | null>(null);
    const [treatmentType, setTreatmentType] = useState<boolean | null>(null);
    const [alternative, setAlternative] = useState<boolean | null>(null);
    const [twoAnaerobicTreatment, setTwoAnaerobicTreatment] = useState<boolean | null>(null);
    const [stepFour, setStepFour] = useState<boolean | null>(null);
    const [stepEight, setStepEight] = useState<boolean | null>(null);
    const [ipccEffluents, setIpccEffluents] = useState<any>();
    const [Ipcc, setIpcc] = useState<any>([]);
    const [ipccEffluentsSecond, setIpccEffluentsSecond] = useState<any>();
    const [selectedUnit, setSelectedUnit] = useState("");
    const [changeScope, setChangeScope] = useState<string | undefined>();
    
    const [factorOxideNitrousFirst, setFactorOxideNitrousFirst] = useState<any>();
    const [firstFactor, setFirstFactor] = useState<any>();
    const [firstFactorMethaneDbo, setFirstFactorMethaneDbo] = useState<any>();
    const [firstFactorMethaneDqo, setFirstFactorMethaneDqo] = useState<any>();
    const [treatmentFirstTypes, setTreatmentFirstTypes] = useState<any[]>([]);
    
    const [factorOxideNitrousSecond, setFactorOxideNitrousSecond] = useState<any>();
    const [secondFactorMethaneDbo, setSecondFactorMethaneDbo] = useState<any>();
    const [secondFactorMethaneDqo, setSecondFactorMethaneDqo] = useState<any>();
    const [IpccSecond, setIpccSecond] = useState<any>([]);
    const [treatmentSecondTypes, setTreatmentSecondTypes] = useState<any[]>([]);
    const [effluentsTypeIpcc, setEffluentsTypeIpcc] = useState<any>();

    useEffect(() => {
        const item: any = location?.state;

        if (item && item.company_id && item.year){
            getInventorysById(item.company_id, item.year, item.scope)
        } else {
            history.push({
                pathname:'/inventario/escopo-3',
            });
        } getGuidelines()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]);

    async function getGuidelines() {
        const item = await scopeService.getBySubSlug(3, 'efluentes_gerados', 'domesticos')
    
        setTitle(item.label)
        setGuidelines(item.guidelines)
      }

    function handleOpenInfo () {
        setOpenModal(!openModal)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { user } = useAuth();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: {errors},
    } = useForm<any>();

    useEffect(() => {
        const selectedIpccId = watch("ipcc_first_id");
            if (selectedIpccId) {
                handleChangeValue();
            }
    }, [watch("ipcc_first_id")]);

   const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
        let released_effluent = getValues('released_effluent')
        let treatment_type = getValues('treatment_type')
        let alternative = getValues('alternative') 
        let two_anaerobic_treatments = getValues('two_anaerobic_treatments')
        let generated_liquid_effluent = getValues('generated_liquid_effluent')
        let raw_effluent = getValues('raw_effluent')
        let ipcc_first_id = getValues("ipcc_first_id")
        let effluent_degradable_unity = getValues("effluent_degradable_unity")
        let effluent_degradable = getValues('effluent_degradable')
        let removed_component_organic = getValues("removed_component_organic")
        let amount_n2_nitrogen = getValues("amount_n2_nitrogen")
        let n2o_emission_factor = getValues("n2o_emission_factor")
        let treatment_first_id = getValues('treatment_first_id')
        let treatment_second_id = getValues('treatment_second_id')
        let treatment_final_id = getValues('treatment_final_id')
        let ch4_amount_recovered_methane = getValues("ch4_amount_recovered_methane")
        let generated_liquid_effluent_after = getValues("generated_liquid_effluent_after")
        let raw_effluent_two = getValues('raw_effluent_two')
        let ipcc_second_id = getValues("ipcc_second_id")
        let effluent_degradable_after = getValues("effluent_degradable_after")
        let effluent_degradable_after_unity = getValues("effluent_degradable_after_unity")
        let nitrogen_removed_after = getValues("nitrogen_removed_after")
        let amount_n2_nitrogen_after = getValues("amount_n2_nitrogen_after")
        let n2o_emission_factor_after = getValues("n2o_emission_factor_after")
        let ch4_amount_recovered_methane_after = getValues("ch4_amount_recovered_methane_after")
        let quantity_of_treated_liquid_effluent = getValues("quantity_of_treated_liquid_effluent")
        let degradable_organic_component = getValues("degradable_organic_component")
        let degradable_organic_component_unity = getValues("degradable_organic_component_unity")
        let amount_n2_nitrogen_after_treatment = getValues("amount_n2_nitrogen_after_treatment")
        let emission_factor_final_effluent = getValues("emission_factor_final_effluent")
        let occupation_type_id = getValues("occupation_type_id")
        let number_people = getValues("number_people")

        released_effluent = released_effluent ? parseInt(released_effluent) : 0;
        treatment_type = treatment_type ? parseInt(treatment_type) : 0;
        treatment_final_id = treatment_final_id ? parseInt(treatment_final_id) : 0
        treatment_second_id = treatment_second_id ? parseInt(treatment_second_id) : 0
        alternative = alternative ? parseInt(alternative) : 0
        two_anaerobic_treatments = two_anaerobic_treatments ? parseInt(two_anaerobic_treatments) : 0
        generated_liquid_effluent = generated_liquid_effluent ? parseFloat(generated_liquid_effluent.replace(".", "").replace(",", ".")) : 0
        raw_effluent = raw_effluent ? parseInt(raw_effluent) : 0
        ipcc_first_id = ipcc_first_id ? parseInt(ipcc_first_id) : 0
        ipcc_second_id = ipcc_second_id ? parseInt(ipcc_second_id) : 0
        occupation_type_id = occupation_type_id ? parseInt(occupation_type_id) : 0
        effluent_degradable_unity = effluent_degradable_unity ? parseInt(effluent_degradable_unity) : 0
        effluent_degradable_after_unity = effluent_degradable_after_unity ? parseInt(effluent_degradable_after_unity) : 0
        effluent_degradable = effluent_degradable ? parseFloat(effluent_degradable.replace(".", "").replace(",", ".")) : 0
        effluent_degradable_after = effluent_degradable_after ? parseFloat(effluent_degradable_after.replace(".", "").replace(",", ".")) : 0
        removed_component_organic = removed_component_organic ? parseFloat(removed_component_organic.replace(".", "").replace(",", ".")) : 0
        amount_n2_nitrogen = amount_n2_nitrogen ? parseFloat(amount_n2_nitrogen.replace(".", "").replace(",", ".")) : 0
        amount_n2_nitrogen_after = amount_n2_nitrogen_after ? parseFloat(amount_n2_nitrogen_after.replace(".", "").replace(",", ".")) : 0
        n2o_emission_factor = n2o_emission_factor ? parseFloat(n2o_emission_factor.replace(".", "").replace(",", ".")) : 0
        n2o_emission_factor_after = n2o_emission_factor_after ? parseFloat(n2o_emission_factor_after.replace(".", "").replace(",", ".")) : 0
        treatment_first_id = treatment_first_id ? parseInt(treatment_first_id) : 0
        ch4_amount_recovered_methane = ch4_amount_recovered_methane ? parseFloat(ch4_amount_recovered_methane.replace(".", "").replace(",", ".")) : 0
        generated_liquid_effluent_after = generated_liquid_effluent_after ? parseFloat(generated_liquid_effluent_after.replace(".", "").replace(",", ".")) : 0
        raw_effluent_two = raw_effluent_two ? parseInt(raw_effluent_two) : 0
        nitrogen_removed_after = nitrogen_removed_after ? parseFloat(nitrogen_removed_after.replace(".", "").replace(",", ".")) : 0
        ch4_amount_recovered_methane_after = ch4_amount_recovered_methane_after ? parseFloat(ch4_amount_recovered_methane_after.replace(".", "").replace(",", ".")) : 0
        quantity_of_treated_liquid_effluent = quantity_of_treated_liquid_effluent ? parseFloat(quantity_of_treated_liquid_effluent.replace(".", "").replace(",", ".")) : 0
        degradable_organic_component = degradable_organic_component ? parseFloat(degradable_organic_component.replace(".", "").replace(",", ".")) : 0
        degradable_organic_component_unity = degradable_organic_component_unity ? parseInt(degradable_organic_component_unity) : 0
        amount_n2_nitrogen_after_treatment = amount_n2_nitrogen_after_treatment ? parseFloat(amount_n2_nitrogen_after_treatment.replace(".", "").replace(",", ".")) : 0
        emission_factor_final_effluent = emission_factor_final_effluent ? parseFloat(emission_factor_final_effluent.replace(".", "").replace(",", ".")) : 0
        number_people = number_people ? parseInt(number_people) : 0

        const scope ={
            status: 2,
            reason: formData.reason,
            ignore_fill: formData.ignore_fill,
            emission_ch4: emissionCh4,
            emission_n2o: emissionN2o,
            emission_co2e: emissionCo2,
            evidence: formData.evidence,
            emission_co2_bio: emissionCo2Bio,
            released_effluent: released_effluent,
            treatment_type: treatment_type,
            treatment_final_id: treatment_final_id,
            alternative: alternative,
            two_anaerobic_treatments: two_anaerobic_treatments,
            generated_liquid_effluent: generated_liquid_effluent,
            raw_effluent: raw_effluent,
            ipcc_first_id: ipcc_first_id,
            effluent_degradable_unity: effluent_degradable_unity,
            effluent_degradable: effluent_degradable,
            removed_component_organic: removed_component_organic,
            amount_n2_nitrogen: amount_n2_nitrogen,
            n2o_emission_factor: n2o_emission_factor,
            treatment_first_id: treatment_first_id,
            treatment_factor_emission_nitrous_oxide_first: factorOxideNitrousFirst,
            treatment_factor_emission_nitrous_oxide_second: factorOxideNitrousSecond,
            treatment_factor_emission_nitrous_oxide_final: factorOxideNitrousFinal,
            volume_effluents: volumeEffluents,
            organic_load: organicLoad,
            content_nitrogen: contentNitrogen,
            ch4_amount_recovered_methane: ch4_amount_recovered_methane,
            generated_liquid_effluent_after: generated_liquid_effluent_after,
            raw_effluent_two: raw_effluent_two,
            ipcc_second_id: ipcc_second_id,
            effluent_degradable_after: effluent_degradable_after,
            effluent_degradable_after_unity: effluent_degradable_after_unity,
            nitrogen_removed_after: nitrogen_removed_after,
            amount_n2_nitrogen_after: amount_n2_nitrogen_after,
            n2o_emission_factor_after: n2o_emission_factor_after,
            treatment_second_id: treatment_second_id,
            ch4_amount_recovered_methane_after: ch4_amount_recovered_methane_after,
            quantity_of_treated_liquid_effluent: quantity_of_treated_liquid_effluent,
            degradable_organic_component: degradable_organic_component,
            degradable_organic_component_unity: degradable_organic_component_unity,
            amount_n2_nitrogen_after_treatment: amount_n2_nitrogen_after_treatment,
            emission_factor_final_effluent: emission_factor_final_effluent,
            occupation_type_id: occupation_type_id,
            number_people: number_people
        };

        const result = await scope3Service.saveDomesticEffluents(
            inventorySelected.company.id, inventorySelected.year, scope
        );

        if (result && !result.error) {
            toast.success("Operação realizada com sucesso!")
        }
        if (result.error) {
            setErrorApi(result.error);
        }
    }
    setLoadingModal(false);
   }

   async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const types = await treatmentTypeService.getByParams({ limit: 9000, page: 1 });

    if (types) {
      setTreatmentTypes(types?.itens);

      const allowedFinalTreatments = [
        "[Selecione]",
        "Lançamento em corpos d'água (não especificado)",
        "Lançamento em corpos d'água (que não reservatórios, lagos e estuários)",
        "Lançamento em reservatórios, lagos e estuários",
        "Efluente parado a céu aberto",
      ];

      const treatmentFinalTypes = types?.itens.filter((type: any) =>
        allowedFinalTreatments.includes(type.name)
      );

      setTreatmentFinalTypes(treatmentFinalTypes);

      if (treatmentFinalTypes.length > 0) {
        handleChangeTreatmentFinal(treatmentFinalTypes[0].id);
      }

      if (treatmentFinalTypes.length > 0) {
        handleChangeTreatmentFinal(treatmentFinalTypes[0].id);
        handleChangeTreatmentSecond(types?.itens[0].id);
      }
    }

    if (types) {
        setTreatmentTypes(types?.itens);
  
        const allowedFirstTreatments = [
          "[Selecione]",
          "Tratamento aeróbio (lodo ativado, lagoa aerada, etc)",
          "Fossa séptica",
          "Reator anaeróbio",
          "Lagoa anaeróbia profunda (profundidade > 2 metros)",
          "Lagoa anaeróbia rasa (profundidade < 2 metros)",
          "Lagoa facultativa (profundidade < 2 metros)",
          "Lagoa de maturação (profundidade < 2 metros)",
          "Fossas secas",
        ];
  
        const treatmentFirstTypes = types?.itens.filter((type: any) =>
          allowedFirstTreatments.includes(type.name)
        );
  
        setTreatmentFirstTypes(treatmentFirstTypes);
  
        if (treatmentFirstTypes.length > 0) {
          handleChangeTreatmentFirst(treatmentFirstTypes[0].id);
        }
  
        if (types?.itens.length > 0) {
          handleChangeTreatmentFinal(types?.itens[0].id);
          handleChangeTreatmentSecond(types?.itens[0].id);
        }
    }

    if (types) {
        setTreatmentTypes(types?.itens);
  
        const allowedSecondTreatments = [
          "[Selecione]",
          "Tratamento aeróbio (lodo ativado, lagoa aerada, etc)",
          "Fossa séptica",
          "Reator anaeróbio",
          "Lagoa anaeróbia profunda (profundidade > 2 metros)",
          "Lagoa anaeróbia rasa (profundidade < 2 metros)",
          "Lagoa facultativa (profundidade < 2 metros)",
          "Lagoa de maturação (profundidade < 2 metros)",
          "Fossas secas",
        ];
  
        const treatmentSecondTypes = types?.itens.filter((type: any) =>
          allowedSecondTreatments.includes(type.name)
        );
  
        setTreatmentSecondTypes(treatmentSecondTypes);
  
        if (treatmentSecondTypes.length > 0) {
          handleChangeTreatmentSecond(treatmentSecondTypes[0].id);
        }
  
        if (types?.itens.length > 0) {
          handleChangeTreatmentFinal(types?.itens[0].id);
          handleChangeTreatmentFirst(types?.itens[0].id);
        }
    }

    const ipcc = await IpccService.getByParams({ limit: 9000, page: 1 });
    setIpcc(ipcc?.itens || []);
    setIpccSecond(ipcc?.itens);

    const effluent_type = await IpccService.getByParamsEffluentsType({
        limit: 9000,
        page: 1,
    });
    setEffluentsType(effluent_type?.itens);
  
    
    if (effluent_type) {
        setEffluentsType(effluent_type?.itens);
        
        const allowedTypeEffluents = [
            "[Selecione]",
          "Efluentes do refino de álcool",
          "Efluentes da produção de cerveja e malte",
          "Efluentes da processamento de peixes",
          "Efluentes da produção de ferro e aço",
          "Efluentes de frigoríficos",
          "Efluentes da produção de fertilizantes nitrogenados",
          "Efluentes da produção de plásticos e resinas",
          "Efluentes da produção de amido",
          "Outros efluentes industriais",
          
        ];
        
        const treatmentTypeEffluents = effluent_type?.itens.filter((type: any) =>
            allowedTypeEffluents.includes(type.name)
    );
    
    setEffluentsType(treatmentTypeEffluents);
    
        if (treatmentTypeEffluents.length > 0) {
            handleChangeEffluentsType(treatmentTypeEffluents[0].id);
        }
        
    }

    const ocup_type = await occupationTypeService.getByParams({limit: 9000, page: 1})
    setOccupationType(ocup_type?.itens)
    
    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope3Service.getDomesticEffluents(company_id, year)

    if (ocup_type?.itens.length > 0 && entity?.occupation_type_id) {
        handleChangeOccupationType(entity.occupation_type_id)
    }
    
    
    if (ipcc?.itens.length > 0 && entity?.ipcc_first_id) {
        handleChangeIpcc(entity.ipcc_first_id);
    }
    
    if (ipcc?.itens.length > 0 && entity?.ipcc_second_id) {
        handleChangeIpccSecond(entity.ipcc_second_id);
    }
    
    if (effluent_type?.itens.length > 0 && entity?.effluent_type) {
        handleChangeEffluentsType(entity.effluent_type);
    }
    
    const result = await inventoryService.getFiles(inventory.company.id, inventory.year, 3, 'efluentes_gerados_domesticos', 'domesticos');
  
    setFiles(result.files)

    if (entity){
        setValue("reason", entity.reason)
        setValue("evidence", entity.evidence)
        setValue("ignore_fill", entity.ignore_fill)

        setValue("treatment_first_id", entity.treatment_first_id);
        setValue("treatment_second_id", entity.treatment_second_id);
        setValue("released_effluent", entity.released_effluent.toString());
        setValue("treatment_type", entity.treatment_type.toString());
        setValue("treatment_final_id", entity.treatment_final_id);
        setValue("alternative", entity.alternative.toString());
        setValue("two_anaerobic_treatments", entity.two_anaerobic_treatments.toString())
        setValue("raw_effluent", entity.raw_effluent.toString());
        setValue("raw_effluent_two",entity.raw_effluent_two.toString());
        setValue("effluent_degradable_unity", entity.effluent_degradable_unity.toString());
        setValue("effluent_degradable_after_unity", entity.effluent_degradable_after_unity);
        setValue("degradable_organic_component_unity", entity.degradable_organic_component_unity);
        setValue("number_people", entity.number_people)

        setValue("ipcc_first_id", entity?.ipcc_first_id || "");
        setValue("ipcc_second_id", entity?.ipcc_second_id || "");
        setValue("occupation_type_id", entity?.occupation_type_id || "");

        setTreatmentType(entity.treatment_type === 1);
        setReleasedEffluent(entity.released_effluent === 1);
        setAlternative(entity.alternative === 1)
        setTwoAnaerobicTreatment(entity.two_anaerobic_treatments === 1)
        setStepFour(entity.raw_effluent === 1)
        setStepEight(entity.raw_effluent_two === 1)
        
        handleChangeIpcc(entity.ipcc_first_id)
        handleChangeIpccSecond(entity.ipcc_second_id)
        handleChangeTreatmentFirst(entity.treatment_first_id);
        handleChangeTreatmentSecond(entity.treatment_second_id);
        handleChangeTreatmentFinal(entity.treatment_final_id);
        handleChangeOccupationType(entity.occupation_type_id);

        setValue("generated_liquid_effluent", formatPrice6(Number(entity.generated_liquid_effluent), false));
        setValue("effluent_degradable", formatPrice6(Number(entity.effluent_degradable), false));
        setValue("removed_component_organic", formatPrice6(Number(entity.removed_component_organic), false));
        setValue("amount_n2_nitrogen", formatPrice6(Number(entity.amount_n2_nitrogen), false));
        setValue("n2o_emission_factor", formatPrice6(Number(entity.n2o_emission_factor), false));
        setValue("treatment_factor_emission_nitrous_oxide_first", formatPrice6(Number(entity.treatment_factor_emission_nitrous_oxide_first), false));
        setValue("treatment_factor_emission_nitrous_oxide_second", formatPrice6(Number(entity.treatment_factor_emission_nitrous_oxide_second), false));
        setValue("treatment_factor_emission_nitrous_oxide_final", formatPrice6(Number(entity.treatment_factor_emission_nitrous_oxide_final), false));
        setValue("ch4_amount_recovered_methane", formatPrice6(Number(entity.ch4_amount_recovered_methane), false));
        setValue("generated_liquid_effluent_after", formatPrice6(Number(entity.generated_liquid_effluent_after), false))
        setValue("effluent_degradable_after", formatPrice6(Number(entity.effluent_degradable_after), false));
        setValue("nitrogen_removed_after", formatPrice6(Number(entity.nitrogen_removed_after), false))
        setValue("amount_n2_nitrogen_after", formatPrice6(Number(entity.amount_n2_nitrogen_after), false))
        setValue("n2o_emission_factor_after", formatPrice6(Number(entity.n2o_emission_factor_after), false))
        setValue("ch4_amount_recovered_methane_after", formatPrice6(Number(entity.ch4_amount_recovered_methane_after), false))
        setValue("quantity_of_treated_liquid_effluent", formatPrice6(Number(entity.quantity_of_treated_liquid_effluent), false))
        setValue("degradable_organic_component", formatPrice6(Number(entity.degradable_organic_component), false));
        setValue("amount_n2_nitrogen_after_treatment", formatPrice6(Number(entity.amount_n2_nitrogen_after_treatment), false));
        setValue("emission_factor_final_effluent", formatPrice6(Number(entity.emission_factor_final_effluent), false));
        setValue("volume_effluents", formatPrice6(Number(entity.volume_effluents), false));
        setValue("organic_load", formatPrice6(Number(entity.organic_load), false))
        setValue("content_nitrogen", formatPrice6(Number(entity.content_nitrogen), false))

        setScopeSelected(entity)

        setIgnoreFill(entity.ignore_fill)
    } else {
        setValue("two_anaerobic_treatments", "0")
        setValue("treatment_type", "0")

        setScopeSelected({})

        setTwoAnaerobicTreatment(false)

        setIgnoreFill(false)
    }
    setInventorySelected(inventory)
    setLoadingModal(false)
   }

   const handleChangeValue = () => {
    let released_effluent = getValues("released_effluent");
    let treatment_type = getValues("treatment_type");
    let treatment_final_id = getValues("treatment_final_id")
    let alternative = getValues("alternative")
    let two_anaerobic_treatments = getValues("two_anaerobic_treatments")
    let generated_liquid_effluent = getValues("generated_liquid_effluent")
    let raw_effluent = getValues("raw_effluent")
    let ipcc_first_id = getValues("ipcc_first_id")
    let effluent_degradable_unity = getValues("effluent_degradable_unity") || selectedUnit;
    let effluent_degradable = getValues('effluent_degradable')
    let removed_component_organic = getValues("removed_component_organic")
    let amount_n2_nitrogen = getValues("amount_n2_nitrogen");
    let n2o_emission_factor = getValues("n2o_emission_factor");
    let treatment_first_id = getValues('treatment_first_id')
    let treatment_factor_emission_nitrous_oxide_first = getValues("treatment_factor_emission_nitrous_oxide_first");
    let treatment_factor_emission_nitrous_oxide_second = getValues("treatment_factor_emission_nitrous_oxide_second");
    let treatment_factor_emission_nitrous_oxide_final = getValues("treatment_factor_emission_nitrous_oxide_final")
    let volume_effluents = getValues("volume_effluents")
    let organic_load = getValues("organic_load")
    let content_nitrogen = getValues("content_nitrogen")
    let ch4_amount_recovered_methane = getValues("ch4_amount_recovered_methane");
    let generated_liquid_effluent_after = getValues("generated_liquid_effluent_after");
    let raw_effluent_two = getValues('raw_effluent_two')
    let ipcc_second_id = getValues("ipcc_second_id")
    let effluent_degradable_after = getValues("effluent_degradable_after")
    let effluent_degradable_after_unity = getValues("effluent_degradable_after_unity")
    let nitrogen_removed_after = getValues("nitrogen_removed_after")
    let amount_n2_nitrogen_after = getValues("amount_n2_nitrogen_after")
    let n2o_emission_factor_after = getValues("n2o_emission_factor_after")
    let treatment_second_id = getValues("treatment_second_id");
    let ch4_amount_recovered_methane_after = getValues("ch4_amount_recovered_methane_after")
    let quantity_of_treated_liquid_effluent = getValues("quantity_of_treated_liquid_effluent")
    let degradable_organic_component = getValues("degradable_organic_component")
    let degradable_organic_component_unity = getValues("degradable_organic_component_unity")
    let amount_n2_nitrogen_after_treatment = getValues("amount_n2_nitrogen_after_treatment")
    let emission_factor_final_effluent = getValues("emission_factor_final_effluent")
    let occupation_type_id = getValues("occupation_type_id")
    let number_people = getValues("number_people")

    treatment_first_id = treatment_first_id ? parseInt(treatment_first_id) : 0;
    treatment_second_id = treatment_second_id ? parseInt(treatment_second_id) : 0;
    treatment_final_id = treatment_final_id ? parseInt(treatment_final_id) : 0;
    released_effluent = released_effluent ? parseInt(released_effluent): 0;
    treatment_type = treatment_type ? parseInt(treatment_type) : 0;
    alternative = alternative ? parseInt(alternative) : 0
    two_anaerobic_treatments = two_anaerobic_treatments ? parseInt(two_anaerobic_treatments) : 0
    generated_liquid_effluent = generated_liquid_effluent ? parseFloat(generated_liquid_effluent.replace(".", "").replace(",", ".")) : 0
    raw_effluent = raw_effluent ? parseInt(raw_effluent) : 0
    raw_effluent_two = raw_effluent_two ? parseInt(raw_effluent_two): 0
    ipcc_first_id = ipcc_first_id ? parseInt(ipcc_first_id) : 0
    ipcc_second_id = ipcc_second_id ? parseInt(ipcc_second_id) : 0
    occupation_type_id = occupation_type_id ? parseInt(occupation_type_id) : 0;
    effluent_degradable_unity = effluent_degradable_unity ? parseInt(effluent_degradable_unity) : 0
    effluent_degradable_after_unity = effluent_degradable_after_unity ? parseInt(effluent_degradable_after_unity) : 0
    effluent_degradable = effluent_degradable ? parseFloat(effluent_degradable.replace(".", "").replace(",", ".")) : 0
    effluent_degradable_after = effluent_degradable_after ? parseFloat(effluent_degradable_after.replace(".", "").replace(",", ".")) : 0
    removed_component_organic = removed_component_organic ? parseFloat(removed_component_organic.replace(".", "").replace(",", ".")) : 0
    amount_n2_nitrogen = amount_n2_nitrogen ? parseFloat(amount_n2_nitrogen.replace(".", "").replace(",", ".")) : 0
    amount_n2_nitrogen_after = amount_n2_nitrogen_after ? parseFloat(amount_n2_nitrogen_after.replace(".", "").replace(",", ".")): 0;
    n2o_emission_factor = n2o_emission_factor ? parseFloat(n2o_emission_factor.replace(".", "").replace(",", ".")) : 0
    n2o_emission_factor_after = n2o_emission_factor_after ? parseFloat(n2o_emission_factor_after.replace(".", "").replace(",", ".")): 0;
    treatment_factor_emission_nitrous_oxide_first = treatment_factor_emission_nitrous_oxide_first ? parseFloat(treatment_factor_emission_nitrous_oxide_first.replace(".", "").replace(",", ".")): 0;
    treatment_factor_emission_nitrous_oxide_second = treatment_factor_emission_nitrous_oxide_second ? parseFloat(treatment_factor_emission_nitrous_oxide_second.replace(".", "").replace(",", ".")): 0;
    treatment_factor_emission_nitrous_oxide_final = treatment_factor_emission_nitrous_oxide_final ? parseFloat(treatment_factor_emission_nitrous_oxide_final.replace(".", "").replace(",", ".")): 0;
    volume_effluents = volume_effluents ? parseFloat(volume_effluents.replace(".", "").replace(",", ".")): 0;
    organic_load = organic_load ? parseFloat(organic_load.replace(".", "").replace(",", ".")): 0;
    content_nitrogen = content_nitrogen ? parseFloat(content_nitrogen.replace(".", "").replace(",", ".")): 0;
    ch4_amount_recovered_methane = ch4_amount_recovered_methane ? parseFloat(ch4_amount_recovered_methane.replace(".", "").replace(",", ".")): 0;
    generated_liquid_effluent_after = generated_liquid_effluent_after ? parseFloat(generated_liquid_effluent_after.replace(".", "").replace(",", ".")): 0;
    nitrogen_removed_after = nitrogen_removed_after ? parseFloat(nitrogen_removed_after.replace(".", "").replace(",", ".")): 0;
    ch4_amount_recovered_methane_after = ch4_amount_recovered_methane_after ? parseFloat(ch4_amount_recovered_methane_after.replace(".", "").replace(",", ".")): 0;
    quantity_of_treated_liquid_effluent = quantity_of_treated_liquid_effluent ? parseFloat(quantity_of_treated_liquid_effluent.replace(".", "").replace(",", ".")): 0;
    degradable_organic_component = degradable_organic_component ? parseFloat(degradable_organic_component.replace(".", "").replace(",", ".")): 0;
    degradable_organic_component_unity = degradable_organic_component_unity ? parseInt(degradable_organic_component_unity) : 0;
    amount_n2_nitrogen_after_treatment = amount_n2_nitrogen_after_treatment ? parseFloat(amount_n2_nitrogen_after_treatment.replace(".", "").replace(",", ".")): 0;
    emission_factor_final_effluent = emission_factor_final_effluent ? parseFloat(emission_factor_final_effluent.replace(".", "").replace(",", ".")): 0;
    number_people = number_people ? parseInt(number_people) : 0

    async function calculate () {
        const result = await scope3Service.calculateDomesticEffluents(
            released_effluent, treatment_type, treatment_final_id, alternative, two_anaerobic_treatments,
            generated_liquid_effluent, raw_effluent, ipcc_first_id, effluent_degradable_unity, effluent_degradable,
            removed_component_organic, amount_n2_nitrogen, n2o_emission_factor, treatment_first_id,
            treatment_factor_emission_nitrous_oxide_first, ch4_amount_recovered_methane, 
            generated_liquid_effluent_after, raw_effluent_two, ipcc_second_id, effluent_degradable_after,
            effluent_degradable_after_unity, nitrogen_removed_after, amount_n2_nitrogen_after, n2o_emission_factor_after,
            treatment_factor_emission_nitrous_oxide_second, treatment_second_id, ch4_amount_recovered_methane_after,
            quantity_of_treated_liquid_effluent, degradable_organic_component, degradable_organic_component_unity,
            amount_n2_nitrogen_after_treatment, emission_factor_final_effluent, treatment_factor_emission_nitrous_oxide_final,
            occupation_type_id, volume_effluents, organic_load, content_nitrogen, number_people
        );
        
        if(result) {

            setEmissionCh4(result.emission_ch4)
            setEmissionN2o(result.emission_n2o)
            setEmissionCo2(result.emission_co2e)
            setEmissionCo2Bio(result.emission_co2_bio)

            setFactorOxideNitrousFirst(result.treatment_factor_emission_nitrous_oxide_first);
            setFactorOxideNitrousSecond(result.treatment_factor_emission_nitrous_oxide_second);
            setFactorOxideNitrousFinal(result.treatment_factor_emission_nitrous_oxide_final);
            setVolumeEffluents(result.volume_effluents)
            setOrganicLoad(result.organic_load)
            setContentNitrogen(result.content_nitrogen)

            setSelectedUnit(effluent_degradable_unity);

            setChangeScope("change_scope_item_" + result.emission_ch4 + "_" + result.emission_n2o + "_" + 
                result.emission_co2e + "_" + result.emission_co2_bio)
        }
    }
    calculate();
   }

    function handleDeleteFile (id: number) {
        async function deleteFile(id:number) {
        const result = await inventoryService.deleteFile(id);

        if (result && !result.error) {
            const result = await inventoryService.getFiles(
                inventorySelected.company.id, inventorySelected.year, 3, 'efluentes_gerados_domesticos', 'domesticos');

            setFiles(result.files)
        }
        }

        deleteFile(id)
    }

    const handleOpenModalDelete = () => {
        setOpenModalDelete(true);
    };

    const downloadFile = (filename:string) => {
        window.open(filename, "_blank")
    }

    const attachmentChangeHandler = (event:any) => {
        async function saveImage(base64?:string, filename?:string) {
          const image = {
            type: 3,
            name: 'efluentes_gerados',
            file: base64,
            filename: filename,
            second_name: 'domesticos',
          };
    
          const result = await inventoryService.saveFile(
              inventorySelected.company.id, inventorySelected.year, image);
    
          if (result && !result.error) {
            const result = await inventoryService.getFiles(
                inventorySelected.company.id, inventorySelected.year, 3, 'efluentes_gerados_domesticos', 'domesticos');
    
            setFiles(result.files)
          }
        }
    
        for (const file of event.target.files) {
          const reader = new FileReader()
      
          reader.readAsDataURL(file)
      
          reader.onload = () => {
            const base64 = reader.result
      
            saveImage(base64?.toString(), file.name)
          }
        }
    };

    const handleChangeEffluentsType = async (id: any) => {
        if (!id) {
          setValue("effluent_type_first", "");
          return;
        }
      
        try {
          const result = await IpccService.getByIdEffluentsType(id);
      
          if (result) {
            const formattedValue = formatPrice6(result.nitrogen_content, false);
      
            setValue("effluent_type_first", formattedValue);
            setEffluentsTypeIpcc(result.nitrogen_content);
            setValue("effluent_type", parseInt(id));
          }
      
        } catch (error) {
          console.error(
            "Erro ao buscar dados do IPCC para o tipo de efluente:",
            error
          );
        }
    };

    const handleChangeOccupationType = async (id: number) => {
        try {
            const result = await occupationTypeService.getById(id);

            if (result) {
                setValue("occupation_type_id", id);

                setValue("effluent_generated_lpd", result.effluent_generated_lpd);

                handleChangeValue()
            }
        }catch (error) {
            console.error("Erro ao buscar tipo de ocupação:", error);
        }
    };

    const handleChangeTreatmentFirst = async (id: number) => {
        if (!id || id === 0) {
          setValue("treatment_first_id", "");
          setValue("treatment_mcf_first", "0,000");
          setValue("treatment_methane_factor_dbo_first", "0,000000");
          setValue("treatment_methane_factor_dqo_first", "0,000000");
          setValue("treatment_factor_emission_nitrous_oxide_first", 0);
          setFactorOxideNitrousFirst(0);
          setFirstFactor(0);
          setFirstFactorMethaneDbo(0);
          setFirstFactorMethaneDqo(0);
    
          handleChangeValue();
          return;
        }
    
        try {
          const result = await treatmentTypeService.getById(id);
    
          if (result) {
            setValue("treatment_first_id", id);
            setValue("treatment_mcf_first", formatPrice3(result.mcf, false));
            setValue(
              "treatment_methane_factor_dbo_first",
              formatPrice6(result.methane_factor_dbo, false)
            );
            setValue(
              "treatment_methane_factor_dqo_first",
              formatPrice6(result.methane_factor_dqo, false)
            );
    
            setFirstFactor(result.mcf);
            setFirstFactorMethaneDbo(result.methane_factor_dbo);
            setFirstFactorMethaneDqo(result.methane_factor_dqo);
    
            setValue(
              "treatment_factor_emission_nitrous_oxide_first",
              result.treatment_factor_emission_nitrous_oxide_first
            );
    
            handleChangeValue();
          }
        } catch (error) {
          console.error("Erro ao buscar tipo de tratamento:", error);
        }
    };

    const handleChangeTreatmentSecond = async (id: any) => {
        if (!id) {
          setValue("treatment_second_id", "");
          return;
        }
    
        try {
          const result = await treatmentTypeService.getById(id);
    
          if (result) {
            setValue("treatment_second_id", id);
            setValue("treatment_mcf_second", formatPrice3(result.mcf, false));
            setValue(
              "treatment_methane_factor_dbo_second",
              formatPrice6(result.methane_factor_dbo, false)
            );
            setValue(
              "treatment_methane_factor_dqo_second",
              formatPrice6(result.methane_factor_dqo, false)
            );
    
            setSecondFactorMethaneDbo(result.methane_factor_dbo);
            setSecondFactorMethaneDqo(result.methane_factor_dqo);
    
            setValue(
              "treatment_factor_emission_nitrous_oxide_second",
              result.treatment_factor_emission_nitrous_oxide_second
            );
    
            handleChangeValue();
          }
        } catch (error) {
          console.error("Erro ao buscar tipo de tratamento:", error);
        }
    };

    const handleChangeTreatmentFinal = async (id: any) => {
        if (!id) {
          setValue("treatment_final_id", "");
          setFactorOxideNitrousFinal(0);
          return;
        }
    
        try {
          const result = await treatmentTypeService.getById(id);
    
          if (result) {
            setValue("treatment_final_id", id);
            setValue(
              "treatment_methane_factor_dbo_final",
              formatPrice6(result.methane_factor_dbo, false)
            );
            setValue(
              "treatment_methane_factor_dqo_final",
              formatPrice6(result.methane_factor_dqo, false)
            );
    
            setFinalFactorMethaneDbo(result.methane_factor_dbo);
            setFinalFactorMethaneDqo(result.methane_factor_dqo);
    
            setValue(
              "treatment_factor_emission_nitrous_oxide_final",
              result.treatment_factor_emission_nitrous_oxide_final
            );
    
            handleChangeValue();
          }
        } catch (error) {
          console.error("Erro ao buscar tipo de tratamento:", error);
        }
    };

    const handleChangeIpcc = async (id: any) => {
        if (!id) {
          setValue("ipcc_effluents_first", "");
          return;
        }
    
        try {
          const result = await IpccService.getById(id);
    
          if (result) {
            const formattedValue = formatPrice6(result.value_dqo, false);
    
            setValue("ipcc_effluents_first", formattedValue);
            setIpccEffluents(result.value_dqo);
            setValue("ipcc_first_id", parseInt(id));
          }
        } catch (error) {
          console.error("Erro ao buscar dados do IPCC:", error);
        }
    };

    const handleChangeIpccSecond = async (id: any) => {
        if (!id) {
          setValue("ipcc_effluents_second", "");
          return;
        }
        try {
          const result = await IpccService.getById(id);
          
          if (result) {
            const formattedValue = formatPrice6(result.value_dqo, false);
            
            setValue("ipcc_effluents_second", formattedValue);
            setIpccEffluentsSecond(result.value_dqo);
            setValue("ipcc_second_id", parseInt(id));
          }
        } catch (error) {
          console.error("Erro ao buscar dados do IPCC:", error);
        }
      };
    
    return (
        <Container>
            <GoBack />
            <form onSubmit={handleSubmit(onSubmit)} className="form-body">
                <div className="div-head">
                    <div className="title">
                        {inventorySelected?.year && inventorySelected?.company && (
                            <div className="title">
                                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year + ' - ' + inventorySelected?.company?.corporate_name} />
                                <TitleText level={4} title={'Escopo 03 - ' + title}  />
                            </div>
                        )}
                    </div>

                    <div className="div-guidance">
                        {guidelines && (
                            <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                                style={{ margin: 'auto 10px auto auto'}} >
                                Orientações
                            </Button>
                        )}

                        {!isCommonUser && (
                            <Button color="green" size="md" style={{ margin: "auto"}} >
                                Salvar
                            </Button>
                        )}
                    </div>
                </div>

                <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

                <div className="div-checkbox" >
                    <input className="input-check" 
                            type="checkbox" {...register("ignore_fill")} 
                            onChange={(event) => {
                            setIgnoreFill(event.target.checked)
                            }} />

                    <label className="label-text" >
                        Selecione aqui, caso não deseje reportar registros deste escopo para o inventário deste ano.
                    </label>
                </div>

                {ignoreFill === true && (
                    <div className="div-justify" >
                        <label className="label-text" >
                            Informe abaixo uma justificativa para o não preenchimento do escopo selecionado
                        </label>

                        <textarea style={{width: '95%', height: 100}}
                            {...register("reason", { required: true })}/>
                                    
                        {errors.reason && (
                            <span className='requiredLabel' >Esse campo é obrigatório</span>
                        )}
                    </div>
                )}
                
                {ignoreFill === false && (
                    <div className="components" >
                            
                        <FirstStep 
                            setValue={setValue}
                            register={register}
                            treatmentFinalTypes={treatmentFinalTypes}
                            handleChangeTreatmentFinal={handleChangeTreatmentFinal}
                            treatmentType={treatmentType}
                            releasedEffluent={releasedEffluent}
                            setReleasedEffluent={setReleasedEffluent}
                            setTreatmentType={setTreatmentType}
                            handleChange={handleChangeValue}
                        />

                        <Alternative 
                            setValue={setValue}
                            register={register}
                            setAlternative={setAlternative}
                            alternative={alternative}
                            handleChange={handleChangeValue}
                        />

                        {alternative === true && treatmentType === true && releasedEffluent === false && (
                            <>
                                <SecondStep setValue={setValue} register={register} twoAnaerobicTreatment={twoAnaerobicTreatment} handleChange={handleChangeValue} setTwoAnaerobicTreatment={setTwoAnaerobicTreatment} />
                                <ThirdStep setValue={setValue} register={register} handleChange={handleChangeValue} />
                                <StepFour setValue={setValue} register={register} handleChange={handleChangeValue} setStepFour={setStepFour} stepFour={stepFour} handleChangeIpcc={handleChangeIpcc} Ipcc={Ipcc} setIpcc={setIpcc} setSelectedUnit={setSelectedUnit} selectedUnit={selectedUnit} />
                                <StepFive register={register} setValue={setValue} treatmentFirstTypes={treatmentFirstTypes} handleChangeTreatmentFirst={handleChangeTreatmentFirst} factorOxideNitrousFirst={factorOxideNitrousFirst} />
                                <StepSix register={register} setValue={setValue} handleChange={handleChangeValue} />

                                <div style={{ marginTop: 20, marginBottom: 20 }}>
                                    <span className="sub-text">
                                        Caso indicada no Passo 2 a existência de tratamento sequencial do efluente, insira os dados para o segundo tipo de tratamento.
                                    </span>
                                </div>

                                {twoAnaerobicTreatment === true && (
                                    <>
                                        <StepSeven register={register} setValue={setValue} handleChange={handleChangeValue} />
                                        <StepEight setValue={setValue} register={register} handleChange={handleChangeValue} setStepEight={setStepEight} stepEight={stepEight} handleChangeIpcc={handleChangeIpcc} Ipcc={Ipcc} setIpcc={setIpcc} setSelectedUnit={setSelectedUnit} selectedUnit={selectedUnit} />
                                        <StepNine register={register} setValue={setValue} treatmentSecondTypes={treatmentSecondTypes} handleChangeTreatmentSecond={handleChangeTreatmentSecond} factorOxideNitrousSecond={factorOxideNitrousSecond} />
                                        <StepTen register={register} setValue={setValue} handleChange={handleChangeValue} />
                                    </>
                                )}
                            </>
                        )}

                        {alternative === true && treatmentType === false && releasedEffluent === true && (
                            <>
                                <StepEleven treatmentType={treatmentType} register={register} setValue={setValue} handleChange={handleChangeValue} />
                                <StepTwelve treatmentType={treatmentType} register={register} setValue={setValue} handleChange={handleChangeValue} setSelectedUnit={setSelectedUnit} factorOxideNitrousFinal={factorOxideNitrousFinal} />
                            </>
                        )}

                        {alternative === true && treatmentType === true && releasedEffluent === true && (
                            <>
                                <SecondStep setValue={setValue} register={register} twoAnaerobicTreatment={twoAnaerobicTreatment} handleChange={handleChangeValue} setTwoAnaerobicTreatment={setTwoAnaerobicTreatment} />
                                <ThirdStep setValue={setValue} register={register} handleChange={handleChangeValue} />
                                <StepFour setValue={setValue} register={register} handleChange={handleChangeValue} setStepFour={setStepFour} stepFour={stepFour} handleChangeIpcc={handleChangeIpcc} Ipcc={Ipcc} setIpcc={setIpcc} setSelectedUnit={setSelectedUnit} selectedUnit={selectedUnit} />
                                <StepFive register={register} setValue={setValue} treatmentFirstTypes={treatmentFirstTypes} handleChangeTreatmentFirst={handleChangeTreatmentFirst} factorOxideNitrousFirst={factorOxideNitrousFirst} />
                                <StepSix register={register} setValue={setValue} handleChange={handleChangeValue} />

                                <div style={{ marginTop: 20, marginBottom: 20 }}>
                                    <span className="sub-text">
                                        Caso indicada no Passo 2 a existência de tratamento sequencial do efluente, insira os dados para o segundo tipo de tratamento.
                                    </span>
                                </div>

                                {twoAnaerobicTreatment === true && (
                                    <>
                                        <StepSeven register={register} setValue={setValue} handleChange={handleChangeValue} />
                                        <StepEight setValue={setValue} register={register} handleChange={handleChangeValue} setStepEight={setStepEight} stepEight={stepEight} handleChangeIpcc={handleChangeIpcc} Ipcc={Ipcc} setIpcc={setIpcc} setSelectedUnit={setSelectedUnit} selectedUnit={selectedUnit} />
                                        <StepNine register={register} setValue={setValue} treatmentSecondTypes={treatmentSecondTypes} handleChangeTreatmentSecond={handleChangeTreatmentSecond} factorOxideNitrousSecond={factorOxideNitrousSecond} />
                                        <StepTen register={register} setValue={setValue} handleChange={handleChangeValue} />
                                    </>
                                )}

                                <StepEleven treatmentType={treatmentType} register={register} setValue={setValue} handleChange={handleChangeValue} />
                                <StepTwelve treatmentType={treatmentType} register={register} setValue={setValue} handleChange={handleChangeValue} setSelectedUnit={setSelectedUnit} factorOxideNitrousFinal={factorOxideNitrousFinal} />
                            </>
                        )}

                        {alternative === false && (
                            <>
                                <StepTwo
                                    register={register}
                                    setValue={setValue}
                                    handleChangeOccupationType={handleChangeOccupationType}
                                    occupationType={occupationType}
                                    handleChange={handleChangeValue}
                                    volumeEffluents={volumeEffluents}
                                    organicLoad={organicLoad}
                                    contentNitrogen={contentNitrogen}
                                />

                                <StepThree 
                                    setValue={setValue} 
                                    register={register} 
                                    twoAnaerobicTreatment={twoAnaerobicTreatment} 
                                    handleChange={handleChangeValue} 
                                    setTwoAnaerobicTreatment={setTwoAnaerobicTreatment}
                                    treatmentFirstTypes={treatmentFirstTypes} 
                                    handleChangeTreatmentFirst={handleChangeTreatmentFirst}
                                    handleChangeTreatmentSecond={handleChangeTreatmentSecond}
                                    factorOxideNitrousFirst={factorOxideNitrousFirst}
                                    firstFactorMethaneDbo={firstFactorMethaneDbo}
                                    factorOxideNitrousSecond={factorOxideNitrousSecond}
                                    secondFactorMethaneDbo={secondFactorMethaneDbo}
                                    factorOxideNitrousFinal={factorOxideNitrousFinal}
                                    finalFactorMethaneDbo={finalFactorMethaneDbo}
                                />
                            </>
                        )}
                        <div className="column" style={{ margin: 10 }}>
                            <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}/>
                        </div>

                        {alternative === true && (
                            <div className="table-one" >
                                <span className="sub-text">
                                Tabela 1 - Emissões Totais de Efluentes Líquidos domésticos
                                </span>
                            </div>
                        )}
                        
                        {changeScope && alternative === true && (
                            <div className="border-table" >
                                <div className="div-table" >
                                    <span style={{ margin: 10, width: "70%" }}>
                                        Emissões de CH₄ por tratamento e/ou disposição final de efluentes [tCH₄/ano]:
                                    </span>
                                    <span className="span-total">
                                        {formatPrice3(emissionCh4, false)}
                                    </span>
                                </div>
                        
                                    {emissionCh4 < 0 && (
                                        <div className="span-alert" >
                                            O dado de metano recuperado é maior que o de metano gerado. Revise seus cálculos.
                                        </div>
                                    )}
                            </div>
                        )}

                        {changeScope && alternative === true && (
                            <div className="border-table" >
                                <div className="div-table" >
                                    <span style={{ margin: 10, width: "70%" }}>
                                        Emissões de N₂O por tratamento e/ou disposição final de efluentes [tN₂O/ano]
                                    </span>
                                    <span className="span-total">
                                        {formatPrice3(emissionN2o, false)}
                                    </span>
                                </div>

                                {emissionN2o < 0 && (
                                    <div className="span-alert" >
                                        O dado de remoção de nitrogênio como lodo é maior que a quantidade de nitrogênio no efluente gerado. Revise seus cálculos
                                    </div>
                                )}
                            </div>
                        )}

                        {changeScope && alternative === true && (
                            <div className="border-table" >
                                <div className="div-table" >
                                    <span style={{ margin: 10, width: "70%" }}>
                                        Emissões em CO₂e por tratamento e/ou disposição final de efluentes [tCO₂e/ano]:
                                    </span>
                                    <span className="span-total">
                                        {formatPrice3(emissionCo2, false)}
                                    </span>
                                </div>
                            </div>
                        )}

                        {changeScope && alternative === true && (
                            <div className="border-table" >
                                <div className="div-table" >
                                    <span style={{ margin: 10, width: "70%" }}>
                                        Emissões em CO₂ biogênico por tratamento de efluentes [tCO₂/ano]:
                                    </span>
                                    <span className="span-total">
                                        {formatPrice3(emissionCo2Bio, false)}
                                    </span>
                                </div>
                            </div>
                        )}

                        {alternative === false && (
                            <div className="table-one" >
                                <span className="sub-text">
                                Tabela 2 - Emissões Totais de Efluentes Líquidos domésticos
                                </span>
                            </div>
                        )}
                        
                        {changeScope && alternative === false && (
                            <div className="border-table" >
                                <div className="div-table" >
                                    <span style={{ margin: 10, width: "70%" }}>
                                        Emissões de CH₄ por tratamento e/ou disposição final de efluentes [tCH₄/ano]:
                                    </span>
                                    <span className="span-total">
                                        {formatPrice3(emissionCh4, false)}
                                    </span>
                                </div>
                        
                                    {emissionCh4 < 0 && (
                                        <div className="span-alert" >
                                            O dado de metano recuperado é maior que o de metano gerado. Revise seus cálculos.
                                        </div>
                                    )}
                            </div>
                        )}

                        {changeScope && alternative === false && (
                            <div className="border-table" >
                                <div className="div-table" >
                                    <span style={{ margin: 10, width: "70%" }}>
                                        Emissões de N₂O por tratamento e/ou disposição final de efluentes [tN₂O/ano]
                                    </span>
                                    <span className="span-total">
                                        {formatPrice3(emissionN2o, false)}
                                    </span>
                                </div>

                                {emissionN2o < 0 && (
                                    <div className="span-alert" >
                                        O dado de remoção de nitrogênio como lodo é maior que a quantidade de nitrogênio no efluente gerado. Revise seus cálculos
                                    </div>
                                )}
                            </div>
                        )}

                        {changeScope && alternative === false && (
                            <div className="border-table" >
                                <div className="div-table" >
                                    <span style={{ margin: 10, width: "70%" }}>
                                        Emissões em CO₂e por tratamento e/ou disposição final de efluentes [tCO₂e/ano]:
                                    </span>
                                    <span className="span-total">
                                        {formatPrice3(emissionCo2, false)}
                                    </span>
                                </div>
                            </div>
                        )}


                        {!ignoreFill && (
                            <div className="column" style={{ margin: 10 }}>
                                <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}/>

                                <div className="column">
                                    <span className="sub-text">
                                        Evidências do escopo informado
                                    </span>
                                </div>

                                <div className="column" style={{ margin: 10 }}>
                                    <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                                        Observações sobre o escopo informado.
                                    </label>

                                    <textarea
                                        readOnly={isCommonUser}
                                        style={{ width: '100%', height: 100 }}
                                        {...register('evidence')}
                                    />

                                        {errors.evidence && (
                                        <span className="requiredLabel">Esse campo é obrigatório</span>
                                    )}

                                    <ScopeEvidenceAttachments
                                        files={files}
                                        onDeleteFile={handleDeleteFile}
                                        onDownloadFile={downloadFile}
                                        onChangeAttachment={attachmentChangeHandler}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </form>
            <Modal isOpen={openModal} handleModalVisible={() => handleOpenInfo()} isPayOrTypeClient={true}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <TitleText level={2} title='Orientações gerais' />
                </div>

                <div style={{marginTop: 10, width: 550}}>
                    {guidelines && (
                        <span style={{fontSize: 12, textAlign: "justify"}}>
                        {Parser(guidelines)}
                        </span>
                    )}
                </div>

            </Modal>

            {loadingModal && (
                <Loading isOpen={loadingModal}
                />
            )}
        </Container>
    )
}