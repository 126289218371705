import { api } from "./api";

async function getById(occupation_typeId: number) {
    try {
      const { data } = await api.get(`/occupation_type/${occupation_typeId}`);
      return data
    } catch (error) {
      console.log(error);
    }
}

async function getByParams(params: any) {
    try {
      const { data } = await api.get("/occupation_type", {
        params: params
      });
      return data
    } catch (error) {
      console.log(error);
    }
}

const occupationTypeService = {
    getById,
    getByParams
  };
  
  export default occupationTypeService;