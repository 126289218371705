export const colors = {
  grid_background: '#d8d7d7',
  card_background: '#f6f6f6',
  primary: 'rgba(107, 143, 179, .8)',
  secondary: 'rgba(75, 100, 125, .8)',
  tertiary: 'rgba(229, 242, 255, .8)',
  quaternary: 'rgba(179, 145, 107, .8)',
  quinary: 'rgba(125, 101, 75, .8)',
  senary: 'rgba(165, 152, 111, .8)',
  septenary: 'rgba(96, 98, 112, .8)',
  octonary: 'rgba(107, 143, 179, .8)',
  deepSeaGreen: "#81c787", 
  mediumblue: "#A0398C", 
  lightGray: "#B8B7B9",
  darkCharcoalGray: "#5D5658", 
  darkSlateGray:  "#39595E" , 

  SkyBlueMedium: "#5c96c4",
  LightMintGreen: "#81c787",
  NeutralGray: "#b3b3b3",
  LightBronzeTan: "#cf9456",
  GoldenBeige: "#d1bc87",
  CoralPink: "#ff6f61",
  PaleGreen: "#98ddca",
  SlateBlue: "#6a5acd",
  DarkSalmon: "#e9967a",
  LightSlateGray: "#778899",
  Orchid: "#da70d6",
  DeepSkyBlue: "#00bfff",
  SpringGreen: "#00ff7f",
  Tomato: "#ff6347",
  DarkKhaki: "#bdb76b",
}