import { SubmitHandler, useForm } from 'react-hook-form';
import Logo from '../../assets/images/logo.svg'
import useAuth from '../../hooks/useAuth';
import { Container } from "./styles"
import { DescriptionText } from '../../components/DescriptionText';
import { Button } from '../../components/Button/styles';
import { useHistory } from "react-router-dom";
import { Footer } from '../../components/Footer';

interface Inputs {
    mail: string;
}

export const ForgotPassword = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { recoveryPassword, errorLogin } = useAuth();
    const history = useHistory();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = async (formData) => {
        const credentials = {
            mail: formData.mail
        }

        const result = await recoveryPassword(credentials)

        if (result) {
            history.push({
                pathname: '/login',
                state: { new_password: true }
            });
        }
    };

    return (
        <Container>
            <div className='form-container'>
                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <img src={Logo} alt='logo-sinergia' className='logo' width='150' />
                </div>
                
                <div style={{ marginTop: 0 }}>
                    <DescriptionText title='Para redefinir sua senha, informe o e-mail cadastrado' />
                </div>
                
                <form className='form-content' style={{paddingTop: 0}}
                        onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div>
                            <label style={{
                                    marginTop: 10, fontFamily: "'Roboto', sans-serif",
                                    fontSize: '16px',
                                    lineHeight: '19px',
                                    color: '#31363F',
                                    opacity: '0.8',
                                    fontWeight: "bold"
                                }}>
                                E-mail
                            </label>
                            <input
                                type="text"
                                placeholder=""
                                {...register("mail", { required: true })}
                                style={{width: '95%'}}
                            />
                            {errors.mail && (
                                <span className='requiredLabel'>Esse campo é obrigatório</span>
                            )}
                        </div>
                    </div>

                    <Button color='green' style={{ marginTop: 20, width: '100%' }}
                            type="submit" onClick={handleSubmit(onSubmit)}>
                        Recuperar senha
                    </Button>
                </form>
            </div>

            <Footer isInternal={false}/>
        </Container >
    )
}