import {
    Page,
    Image as ImagePDF,
    View,
    Text,
    StyleSheet,
  } from "@react-pdf/renderer";
  import React from "react";
  import { Logos } from "../../components/Logos";
  import { styles } from "../../styles";
  import backgroundImage from "../../../../../../../assets/images/background-nova-conta.jpg";
  import sinergiaBranca from "../../../../../../../assets/images/logo-branca.png";
  import { AttachmentsItemCompensationReduction } from "../../../interfaces/ResumeData";
  import { IGEEProps } from "../Capa";
import { ClientLogo } from "../../components/ClientLogo";
  
  interface IAnexes {
    anexes?: AttachmentsItemCompensationReduction[];
    logo?: string;
    companyId?: number;
  }
  
  const ITEMS_PER_PAGE = 14;
  
  const renderAnexos = (anexes: AttachmentsItemCompensationReduction[], resume: any, logo: any, companyId: any) => {
    const pages = [];
    for (let i = 0; i < anexes.length; i += ITEMS_PER_PAGE) {
      const currentAnexes = anexes.slice(i, i + ITEMS_PER_PAGE);
      pages.push(
        <Page size={[640, 480]} style={{ backgroundColor: "#326ed6" }} key={i}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
            }}
          >
            <View
              style={{ width: "50%", height: "100%", backgroundColor: "#326ed6" }}
            >
              <View style={local.content}>
                <Text
                  style={{
                    ...styles.sumaryTitle,
                    marginBottom: 6,
                    marginTop: -25,
                    marginLeft: '-1px',
                  }}
                >
                  12. Anexos
                </Text>
                <Text
                  style={{
                    ...styles.extraBold,
                    color: "#81c787",
                    marginBottom: 5,
                  }}
                >
                  ART Desk Review
                </Text>
                {currentAnexes.length > 0 ? (
                  currentAnexes.map((anex, idx) => (
                    <View  style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #76B987",
                      flexDirection: "row",
                      width: "100%",
                      marginVertical: "2px"}} key={idx}>
                      <Text
                        style={{
                          ...styles.extraLight,
                          fontSize: 10,
                          color: "white",
                          marginLeft: 5,
                        }}
                      >
                        Anexo {i + idx + 1}
                      </Text>
                      <Text
                        style={{
                          ...styles.extraLight,
                          fontSize: 10,
                          color: "white",
                          marginRight: 5,
                        }}
                      >
                        {anex.filename}
                      </Text>
                    </View>
                  ))
                ) : (
                  <Text style={{ ...styles.extraLight, fontSize: 10, color: "white" }}>
                    Não possui anexos
                  </Text>
                )}
              </View>
            <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        bottom: "4%",
                        left: "42%", 
                        transform: "translateX(-50%)",
                        width: "50%", 
                      }}
                    >
                      <ClientLogo logo={logo} companyId={companyId} style={{ marginLeft: -5 }} />
                      <ImagePDF
                        src={sinergiaBranca}
                        style={{ width: 120, marginLeft: -10 }} 
                      />
                    </View>
            </View>
            <View style={{ width: "50%", height: "100%" }}>
              <ImagePDF
                src={backgroundImage}
                style={{
                  objectFit: "cover",
                  height: "100%",
                  backgroundColor: "#5c96c4",
                }}
              />
              {/* primeira linha */}
              {/* Linha Horizontal */}
              <View
                style={{
                  position: "absolute",
                  bottom: "15%",
                  right: 0,
                  width: "100%",
                  height: 1,
                  backgroundColor: "white",
                  borderTop: "1px solid white",
                }}
              />
              {/* Linha Vertical */}
              <View
                style={{
                  position: "absolute",
                  top: 0,
                  right: "92%",
                  width: 1,
                  height: "100%",
                  backgroundColor: "white",
                  borderRight: "1px solid white",
                }}
              />
              {/* Bola 1 */}
              <View
                style={{
                  position: "absolute",
                  top: "93.7%",
                  right: "105.5%",
                  width: 15,
                  height: 15,
                  borderRadius: "50%",
                  backgroundColor: "white",
                  transform: "translate(50%, -50%)",
                }}
              />
              {/* Bola 2*/}
              <View
                style={{
                  position: "absolute",
                  top: "93.7%",
                  right: "23%",
                  width: 15,
                  height: 15,
                  borderRadius: "50%",
                  backgroundColor: "white",
                  transform: "translate(50%, -50%)",
                }}
              />
            </View>
          </View>
        </Page>
      );
    }
    return pages;
  };
  
  export const Page27: React.FC<IAnexes & IGEEProps> = (props) => {
    const { anexes = [], resume, logo, companyId } = props; 
    return (
      <>
        {anexes.length > 0 ? renderAnexos(anexes, resume, logo, companyId) : (
          <Page size={[640, 480]} style={{ backgroundColor: "#326ed6" }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
              }}
            >
              <View
                style={{ width: "50%", height: "100%", backgroundColor: "#326ed6" }}
              >
                <View style={local.content}>
                  <Text
                    style={{
                      ...styles.sumaryTitle,
                      marginBottom: 6,
                      marginTop: -25,
                      marginLeft: '-1px',
                    }}
                  >
                    12. Anexos
                  </Text>
                  <Text
                    style={{
                      ...styles.extraBold,
                      color: "#81c787",
                      marginBottom: 5,
                    }}
                  >
                    Art de verificação
                  </Text>
                  <Text style={{ ...styles.extraLight, fontSize: 10, color: "white" }}>
                    Não possui anexos
                  </Text>
                </View>
              <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        bottom: "4%",
                        left: "42%", 
                        transform: "translateX(-50%)",
                        width: "50%", 
                      }}
                    >
                      <ClientLogo logo={logo} companyId={companyId} style={{ marginLeft: -5 }} />
                      <ImagePDF
                        src={sinergiaBranca}
                        style={{ width: 120, marginLeft: -10 }} 
                      />
                    </View>
              </View>
              <View style={{ width: "50%", height: "100%" }}>
                <ImagePDF
                  src={backgroundImage}
                  style={{
                    objectFit: "cover",
                    height: "100%",
                    backgroundColor: "#5c96c4",
                  }}
                />
                {/* primeira linha */}
                {/* Linha Horizontal */}
                <View
                  style={{
                    position: "absolute",
                    bottom: "15%",
                    right: 0,
                    width: "100%",
                    height: 1,
                    backgroundColor: "white",
                    borderTop: "1px solid white",
                  }}
                />
                {/* Linha Vertical */}
                <View
                  style={{
                    position: "absolute",
                    top: 0,
                    right: "92%",
                    width: 1,
                    height: "100%",
                    backgroundColor: "white",
                    borderRight: "1px solid white",
                  }}
                />
                {/* Bola 1 */}
                <View
                  style={{
                    position: "absolute",
                    top: "93.7%",
                    right: "105.5%",
                    width: 15,
                    height: 15,
                    borderRadius: "50%",
                    backgroundColor: "white",
                    transform: "translate(50%, -50%)",
                  }}
                />
                {/* Bola 2*/}
                <View
                  style={{
                    position: "absolute",
                    top: "93.7%",
                    right: "23%",
                    width: 15,
                    height: 15,
                    borderRadius: "50%",
                    backgroundColor: "white",
                    transform: "translate(50%, -50%)",
                  }}
                />
              </View>
            </View>
          </Page>
        )}
      </>
    );
  };
  
  const local = StyleSheet.create({
    content: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "80%",
      justifyContent: "center",
      alignContent: "center",
      alignSelf: "center",
    },
  });
  