import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import { Container } from "./styles";
import { formatPrice14, formatPrice17 } from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import carFleetService from "../../../services/carFleetService";
import { toast } from "react-hot-toast";
import { Alert } from "../../../components/Alert";

export const CarFleetYear = () => {
  const history = useHistory();

  const [carFleetSelected, setCarFleetSelected] = useState<any>({});
  const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [years, setYears] = useState<any>([]);
  const availableYears = [2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
  const [yearInventory, setYearInventory] = useState<any | null>(2006);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.fuel_id && yearInventory){
      getFuelsById(item.fuel_id, yearInventory)
    }
    // }else{
    //   history.push('/tipo-frota')
    // }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location, yearInventory]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  useEffect(() => {
    const item: any = location?.state;

    const storedCarFleetName = localStorage.getItem("carFleetName");
    const storedCarFleetId = localStorage.getItem("carFleetId");

    if (item && item.fuel_id) {
      carFleetService.getById(item.fuel_id).then((carFleet) => {
        setCarFleetSelected(carFleet);
        localStorage.setItem("carFleetName", carFleet.name);
        localStorage.setItem("carFleetId", carFleet.id);
      });
    } else if (storedCarFleetName && storedCarFleetId) {
      setCarFleetSelected({
        name: storedCarFleetName,
        id: Number(storedCarFleetId),
      });
    }
  }, [location]);


  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = async (formData) => {
    if (formData) {
      const itens:any[] = years.map((year: any, index: number) => {
        const factor_ch4_g_km = formData.year[index].factor_ch4_g_km.replace(".", "");
        const factor_n2o_g_km = formData.year[index].factor_n2o_g_km.replace(".", "");
        const average_consumption = formData.year[index].average_consumption.replace(".", "");
        const year_id = yearInventory;
        const name = carFleetSelected.name;

        return {
          year: year.year,
          year_id: year_id,
          name:name,
          factor_ch4_g_km: factor_ch4_g_km ? parseFloat(factor_ch4_g_km.replace(",", ".")) : 0,
          factor_n2o_g_km: factor_n2o_g_km ? parseFloat(factor_n2o_g_km.replace(",", ".")) : 0,
          average_consumption: average_consumption ? parseFloat(average_consumption.replace(",", ".")) : 0,
        };
      })

      const request = {
        itens: itens,
      };
      const result = await carFleetService.updateItensByCarFleet(
        carFleetSelected.id,
        yearInventory,
        carFleetSelected.name,
        request
      );
      if (result && !result.error) {
        await getFuelsById(carFleetSelected.id, yearInventory);
       // history.goBack()

       // reset();
       // setErrorApi(undefined);
          
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error);
      }
    }
  };

  async function getFuelsById(id: number, yearInventory: number) {
    const carFleet = await carFleetService.getById(id);
    
    const itens = await carFleetService.getItensByCarFleet(id, yearInventory, carFleet.name);
    
    const yearFleet =[2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 
      2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
    
    const years : any[]= [];
    
    yearFleet.forEach((year, index) => {
      const item = getItem(year, itens?.itens || []); 
      
      const factor_ch4_g_km = item && item.factor_ch4_g_km ? item.factor_ch4_g_km : 0;
      const factor_n2o_g_km = item && item.factor_n2o_g_km ? item.factor_n2o_g_km : 0;
      const average_consumption = item && item.average_consumption ? item.average_consumption : 0;
      
      setValue(`year.${index}.factor_ch4_g_km`, formatPrice17(Number(factor_ch4_g_km), false));
      setValue(`year.${index}.factor_n2o_g_km`, formatPrice17(Number(factor_n2o_g_km), false));
      setValue(`year.${index}.average_consumption`, formatPrice17(Number(average_consumption), false));
      
      years.push({
        year: year, 
        year_label: year.toString(), 
        factor_ch4_g_km: factor_ch4_g_km,
        factor_n2o_g_km: factor_n2o_g_km,
        average_consumption: average_consumption,
      });
    });
    
    setYears(years);
    setCarFleetSelected(carFleet);
    
  }
  
    
  function getItem(year: any | undefined, itens?: any) {
    if (itens) {
      for (const item of itens) {
        // Verifica se o ano é igual ao ano especificado ou se o item é indefinido e assume o ano 2000
        if ((year !== undefined && item.year === year) || (year === undefined && item.year === 2000)) {
          return item;
        }
      }
    }
  
    return undefined;
  }
    
  function currency(e: React.FormEvent<HTMLInputElement>) {
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d)(\d{17})$/, "$1,$2");
      value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
      return value;
  }

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newYear = parseInt(event.target.value, 10);
    setYearInventory(newYear);

    if (carFleetSelected && carFleetSelected.id) {
      getFuelsById(carFleetSelected.id, newYear);
    }
  };

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, justifyContent: "space-between", marginBottom: 20 }} >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TitleText level={1} title={carFleetSelected.name || carFleetSelected.name} />
          </div>

          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <TitleText level={4} title="Selecione o ano do inventário: "/>
              <select style={{ 
                    width: '35%',
                    height: "75%", 
                    padding: '10px', 
                    borderRadius: '4px', 
                    border: '1px solid #ccc', 
                    backgroundColor: '#f9f9f9',
                    marginTop: 10,
                    marginLeft: -55
                  }} 
                  id="yearSelect" 
                  onChange={handleYearChange} 
                  value={yearInventory || ""}
                  >
                {availableYears.map(yearOption => (
                  <option key={yearOption} value={yearOption}>
                    {yearOption}
                  </option>
                ))}
              </select>
            </div>
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 10, marginLeft: 50, marginRight: 50  }} >
          <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, justifyContent: "space-between" }}>
            <TitleText level={2} title="Fatores de Emissão - MMA (2014) p/ ano" />
          </div>

          <div style={{ overflow: "hidden auto", position: "relative", height: "calc(100vh - 190px)", 
            maxHeight: "calc(100vh - 190px)"}} >
            {years && (
              <table style={{ width: "90%", borderCollapse: "collapse", marginTop: 5}}>
                <thead>
                  <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                    <th style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                      {"Ano"}
                    </th>
                    <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                      {"CH₄g/km)"}
                    </th>
                    <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                      {"N₂Og/km)"}
                    </th>
                    <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                      {"consumo médio"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {years && years.map((year: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td style={{ padding: "10px", width: "19%" }} >
                              <span className="list-text title-text-underline">
                                {year.year_label}
                              </span>
                          </td>
                          <td style={{ padding: "10px", width: "27%" }} >
                            <input style={{ width: "90%", textAlign: "right" }}
                              {...register(`year.${index}.factor_ch4_g_km`, { required: true })}
                              onChange={(event) => {
                                  setValue(`year.${index}.factor_ch4_g_km`, currency(event));
                              }}
                            />
                          </td>
                          <td style={{ padding: "10px", width: "27%" }} >
                            <input style={{ width: "90%", textAlign: "right" }}
                              {...register(`year.${index}.factor_n2o_g_km`, { required: true })}
                              onChange={(event) => {
                                  setValue(`year.${index}.factor_n2o_g_km`, currency(event));
                              }}
                            />
                          </td>
                          <td style={{ padding: "10px", width: "27%" }} >
                            <input style={{ width: "90%", textAlign: "right" }}
                              {...register(`year.${index}.average_consumption`, { required: true })}
                              onChange={(event) => {
                                  setValue(`year.${index}.average_consumption`, currency(event));
                              }}
                            />
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </form>
      <Alert
        isOpen={modalIsOpen}
        handleModalVisible={() => setModalIsOpen(false)}
        message={modalMessage}
      />
    </Container>
  );
};
