import { Page, Image as ImagePDF } from "@react-pdf/renderer";
import backgroundImage from "../../../../../../../assets/images/modelo-relatorio-igee-v3_page-0022.jpg";

export const Final = () => {
  return (
    <Page size={[640, 480]} style={{backgroundColor: "#f9faf8"}}>
      <ImagePDF
        src={backgroundImage}
        style={{ objectFit:'cover' }}
      />
    </Page>
  );
};
