import { Page, Text, View, StyleSheet,Image as ImagePDF } from "@react-pdf/renderer";
import React from "react";
import { Logos } from "../../components/Logos";
import { Header } from "../../components/Header";
import { IGEEProps } from "../../../interfaces/IGEEData";
import { styles, table } from "../../styles";
import LogoVerdeEscuro from "../../../../../../../assets/images/versoes-logo-icone-ingee-03.png";
import { BarChart } from "./chart";

export const ChartDisaggregated: React.FC<IGEEProps> = (props) => {
  const { data } = props;
  const { resume } = props;

  const tableData = [
    {
      label: "Bens e serviços comprados",
      emission_tco2e:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.purchased_goods_services?.emission_tco2e,
      emission_co2_bio:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.purchased_goods_services?.emission_co2_bio,
      removal_of_bio_co2:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.purchased_goods_services?.removal_of_bio_co2,
    },
    {
      label: "Bens de capital",
      emission_tco2e:
        resume?.resumeIgee?.emissions_by_category?.scope3?.capital_goods
          ?.emission_tco2e,
      emission_co2_bio:
        resume?.resumeIgee?.emissions_by_category?.scope3?.capital_goods
          ?.emission_co2_bio,
      removal_of_bio_co2:
        resume?.resumeIgee?.emissions_by_category?.scope3?.capital_goods
          ?.removal_of_bio_co2,
    },
    {
      label:
        "Atividades com combustível e energia não inclusas nos Escopos 1 e 2",
      emission_tco2e:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.fuel_energy_not_scope_1_2?.emission_tco2e,
      emission_co2_bio:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.fuel_energy_not_scope_1_2?.emission_co2_bio,
      removal_of_bio_co2:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.fuel_energy_not_scope_1_2?.removal_of_bio_co2,
    },
    {
      label: "Transporte e distribuição (upstream)",
      emission_tco2e:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.transport_distribution_upstream?.emission_tco2e + 
          resume?.resumeIgee?.emissions_by_category?.scope3
          ?.transport_distribution_upstream?.emission_co2,
      emission_co2_bio:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.transport_distribution_upstream?.emission_co2_bio,
      removal_of_bio_co2:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.transport_distribution_upstream?.removal_of_bio_co2,
    },
    {
      label: "Resíduos gerados nas operações",
      emission_tco2e:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.waste_generated_operations?.emission_tco2e + 
          resume?.resumeIgee?.emissions_by_category?.scope3
          ?.waste_generated_operations?.emission_co2,
      emission_co2_bio:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.waste_generated_operations?.emission_co2_bio,
      removal_of_bio_co2:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.waste_generated_operations?.removal_of_bio_co2,
    },
    {
      label: "Viagens a negócios",
      emission_tco2e:
        resume?.resumeIgee?.emissions_by_category?.scope3?.business_travel
          ?.emission_tco2e + 
          resume?.resumeIgee?.emissions_by_category?.scope3?.business_travel
          ?.emission_co2,
      emission_co2_bio:
        resume?.resumeIgee?.emissions_by_category?.scope3?.business_travel
          ?.emission_co2_bio,
      removal_of_bio_co2:
        resume?.resumeIgee?.emissions_by_category?.scope3?.business_travel
          ?.removal_of_bio_co2,
    },
    {
      label: "Deslocamento de funcionários",
      emission_tco2e:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.home_work_displacement?.emission_tco2e + 
          resume?.resumeIgee?.emissions_by_category?.scope3
          ?.home_work_displacement?.emission_co2,
      emission_co2_bio:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.home_work_displacement?.emission_co2_bio,
      removal_of_bio_co2:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.home_work_displacement?.removal_of_bio_co2,
    },
    {
      label: "Bens arrendados (a organização como arrendatária)",
      emission_tco2e:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.leased_assets_as_lessee?.emission_tco2e,
      emission_co2_bio:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.leased_assets_as_lessee?.emission_co2_bio,
      removal_of_bio_co2:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.leased_assets_as_lessee?.removal_of_bio_co2,
    },
    {
      label: "Transporte e distribuição (downstream)",
      emission_tco2e:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.transport_distribution_downstream?.emission_tco2e + 
          resume?.resumeIgee?.emissions_by_category?.scope3
          ?.transport_distribution_downstream?.emission_co2,
      emission_co2_bio:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.transport_distribution_downstream?.emission_co2_bio,
      removal_of_bio_co2:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.transport_distribution_downstream?.removal_of_bio_co2,
    },
    {
      label: "Processamento de produtos vendidos",
      emission_tco2e:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.processing_sold_products?.emission_tco2e,
      emission_co2_bio:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.processing_sold_products?.emission_co2_bio,
      removal_of_bio_co2:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.processing_sold_products?.removal_of_bio_co2,
    },
    {
      label: "Uso de bens e serviços vendidos",
      emission_tco2e:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.use_sold_goods_services?.emission_tco2e,
      emission_co2_bio:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.use_sold_goods_services?.emission_co2_bio,
      removal_of_bio_co2:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.use_sold_goods_services?.removal_of_bio_co2,
    },
    {
      label: "Tratamento de fim de vida dos produtos vendidos",
      emission_tco2e:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.end_life_products_sold?.emission_tco2e,
      emission_co2_bio:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.end_life_products_sold?.emission_co2_bio,
      removal_of_bio_co2:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.end_life_products_sold?.removal_of_bio_co2,
    },
    {
      label: "Franquias",
      emission_tco2e:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.leased_assets_as_lessor?.emission_tco2e,
      emission_co2_bio:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.leased_assets_as_lessor?.emission_co2_bio,
      removal_of_bio_co2:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.leased_assets_as_lessor?.removal_of_bio_co2,
    },
    {
      label: "Investimentos",
      emission_tco2e:
        resume?.resumeIgee?.emissions_by_category?.scope3?.investments
          ?.emission_tco2e,
      emission_co2_bio:
        resume?.resumeIgee?.emissions_by_category?.scope3?.investments
          ?.emission_co2_bio,
      removal_of_bio_co2:
        resume?.resumeIgee?.emissions_by_category?.scope3?.investments
          ?.removal_of_bio_co2,
    },
    {
      label: "Emissões de Escopo 3 não classificáveis nas categorias 1 a 14",
      emission_tco2e:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.not_classifiable_categories?.emission_tco2e,
      emission_co2_bio:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.not_classifiable_categories?.emission_co2_bio,
      removal_of_bio_co2:
        resume?.resumeIgee?.emissions_by_category?.scope3
          ?.not_classifiable_categories?.removal_of_bio_co2,
    },
  ];

  const chartData = tableData.map(item => ({
        name: item.label,
        values: [
            { key: 'emission_tco2e', value: item.emission_tco2e || 0 },
            // { key: 'emission_co2_bio', value: item.emission_co2_bio || 0 },
            // { key: 'removal_of_bio_co2', value: item.removal_of_bio_co2 || 0 }
        ]
  }));

  return (
    <Page size={[640, 480]} style={styles.Page}>
      <Header backColor="#989c9c" title="6. Resultados" />
      <View style={local.body}>
        <Text style={{ ...local.title, ...styles.italic }}>
          6.5 - Emissões de Escopo 3 desagregadas por categoria
        </Text>
        <View style={local.chart}>
            <BarChart data={chartData} width={500} height={350} />
          </View>
      </View>
      <ImagePDF 
        src={resume?.company_itens.logo_image_url}
        style={{
          width: 50,
          right: '85px',
          position: 'absolute',
          bottom: '14px'
        }}
      />
      <ImagePDF 
        src={LogoVerdeEscuro}
        style={{
          width: 90,
          right: '0px',
          bottom: '14px',
          position: 'absolute'
        }}
      />
    </Page>
  );
};

const local = StyleSheet.create({
  body: {
    display: "flex",
    width: "110%",
    height: "85%",
    paddingVertical: "10px",
    alignItems: "center",
    alignContent: "center",
  },
  chart: {
    width: "100%",
    height: "100%",
    marginLeft: 50,
  },
  table: {
    width: "100%",
    height: "auto",
  },
  title: {
    color: "#81c787",
    fontSize: 16,
    padding: "5px",
    paddingLeft: "20px",
    alignSelf: "flex-start",
    marginLeft: "15px",
    marginBottom: "5px"
  },
  content: {
    padding: "5px",
    width: "90%",
    height: "auto",
    display: "flex",
    alignSelf: "center",
    flexDirection: "column",
  },
  endColumn: {
    borderRadius: "80%",
    color: "white",
    fontSize: 11,
    alignSelf: "center",
    textAlign: "center",
    height: "25px",
    width: "50px",
    marginLeft: "6px",
    backgroundColor: "#81c787",
  },
});
