import { Document, Image, PDFDownloadLink, Text, View } from '@react-pdf/renderer'

import ResumeProjectTable from './ResumeProjectTable'
import FullProject from './FullProject'
import Legend from './Legend'

import { Project as ProjectInterface } from '../../Project/Project'

import { Button } from '../../../components/Button/styles'
import { colors } from '../../../components/PDF/styles/colors'
import { PDFPage } from '../../../components/PDF/Page'
import { styles } from '../../../components/PDF/styles/common'
import { styles as tableStyles } from '../../../components/PDF/styles/table'
import { Company } from '../interfaces/Company'
import { MaccResult } from '../interfaces/MaccProjetcs'
import ResumeProject from './ResumeProject'


const DownloadButtonPdf: React.FC<{
  chartImageBase64: string
  projects: ProjectInterface[]
  CMA: MaccResult[]
  company: Company
}> = (props) => {
  
  const { company, projects, CMA, chartImageBase64} = props

  let document = (<Document></Document>)

  if (CMA.length){
    document = (
      <Document>
      <PDFPage logo={company?.logo_image_url} title={`Ranqueamento das Iniciativas de Descarbonização`} companyName={company?.fantasy_name} 
        companyFantasyName={company?.fantasy_name} >
          <View style={styles.textContainer}>
            <Image style={{ marginTop: 10, width: '95%' }} src={chartImageBase64} />
            <View style={{ margin: '20px 0' }}>
              <ResumeProject
                      projects={CMA}
                    />
            </View>
            <View style={{ margin: '20px 0' }}>
              <View
                style={{
                  ...styles.row,
                  padding: 5,
                  backgroundColor: colors.lightGrey
                }}
              >
                <Text style={{ ...styles.text, width: '33.33%' }}>Projeto</Text>
                <Text style={{ ...styles.text, width: '33.33%' }}>
                  tCO<Text style={tableStyles.subscript}>₂</Text>e evitada
                </Text>
                <Text style={{ ...styles.text, width: '33.33%' }}>
                  Custo marginal de abatimento (R$/tCO
                  <Text style={tableStyles.subscript}>₂</Text>e)
                </Text>
              </View>
            

              {CMA.map((project) => {
                // console.log(project)
                return (
                  <ResumeProjectTable
                    key={project.projectObj.id}
                    project={project.name}
                    tCO2={project.tCO2}
                    cost={project.cost}
                  />
                )
              })}
            </View>
            <Legend />
            <View>
              {projects.map((project) => {
                return <FullProject key={project.id} project={project} />
              })}
            </View>
          </View>
        </PDFPage>
      </Document>
    )
  } 
  return (
    <>
      <PDFDownloadLink document={document} fileName={`Ranqueamento das Iniciativas de Descarbonização - ${company?.corporate_name}`}>
        <Button style={{ marginRight: 10, width: 100 }} color="blue" size="md">
          Baixar PDF
        </Button>
      </PDFDownloadLink>
    </>
  )
}

export default DownloadButtonPdf
