import { Page, Text, View, StyleSheet, Image as ImagePDF } from "@react-pdf/renderer";
import React from "react";
import { Header } from "../../components/Header";
import { Logos } from "../../components/Logos";
import { styles } from "../../styles";
import { IGEEProps } from "../../../interfaces/IGEEData";
import logoAzul from "../../../../../../../assets/images/logo-azul.png";

export const Page5: React.FC<IGEEProps> = (props) => {
  const { resume } = props;

  const getValueOrPlaceholder = (value: any, prefix = "", suffix = "") => {
    return value ? prefix + value + suffix : " - ";
  };

  const TableData = [
    {
      left: getValueOrPlaceholder(resume?.intensity_itens?.number_of_employees?.hited),
      right: "Funcionários",
    },
    {
      left: getValueOrPlaceholder(resume?.intensity_itens?.number_of_projects?.hited),
      right: "Projetos",
    },
    {
      left: getValueOrPlaceholder(resume?.intensity_itens?.number_of_products?.hited),
      right: "Produtos",
    },
    {
      left: getValueOrPlaceholder(resume?.intensity_itens?.building_area?.hited, "", "m²"),
      right: "De área construída",
    },
    {
      left: getValueOrPlaceholder(
        resume?.intensity_itens?.invoicing?.hited ? `R$${resume.intensity_itens.invoicing.hited.toLocaleString('pt-BR')}` : "",
      ),
      right: "De faturamento",
    },
  ];

  return (
    <Page size={[640, 480]} style={styles.Page}>
      <Header backColor="#81c787" title="2. Informações institucionais" />
      <View style={local.body}>
        <Text style={{ ...local.title, ...styles.italic, marginLeft: 10 }}>
          Dados do ano inventariado
        </Text>
        <View style={local.table}>
          {TableData.map((d, i) => (
            <View style={local.item} key={i}>
              <View style={local.row}>
                <View style={local.cellLeft}>
                  <Text style={{ ...local.number, ...styles.italic }}>
                    {d.left}
                  </Text>
                </View>
                <View style={local.cellRight}>
                  <Text style={{ ...local.text, ...styles.Bold }}>
                    {d.right}
                  </Text>
                </View>
              </View>
            </View>
          ))}
          <View style={local.item} />
        </View>
        <View
          style={{
            position: "absolute",
            top: "102.7%",
            width: 20,
            height: 20,
            right: '25%',
            borderRadius: "50%",
            backgroundColor: "#81c787",
            transform: "translate(50%, -50%)",
          }}
        />
        <View
          style={{
            width: "120%",
            height: "1px",
            backgroundColor: "#81c787",
            marginTop: 50
          }}
        ></View>
        <ImagePDF
          src={resume?.company_itens.logo_image_url}
          style={{
            width: 60,
            left: '50px',
            bottom: '7px',
            position: 'absolute'
          }}
        />
        <ImagePDF
          src={logoAzul}
          style={{
            width: 60,
            left: '130px',
            bottom: '10px',
            position: 'absolute'
          }}
        />
      </View>
    </Page>
  );
};

const local = StyleSheet.create({
  body: {
    display: "flex",
    width: "100%",
    height: "90%",
    alignItems: "center",
    alignContent: "center",
    padding: "20px",
  },
  title: {
    color: "gray",
    fontSize: 18,
    padding: "5px",
    alignSelf: "flex-start",
  },
  table: {
    width: "90%",
    padding: "20px",
  },
  item: {
    borderTop: "1px solid #81c787",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  cellLeft: {
    flex: 1,
    textAlign: "left",
    paddingRight: 10,
  },
  cellRight: {
    flex: 1,
    textAlign: "left",
    paddingLeft: 130,
  },
  number: {
    color: "#326ed6",
    fontSize: 26,
    textAlign: "left",
    marginLeft: 20,
  },
  text: {
    color: "#326ed6",
    fontSize: 16,
    textAlign: "left",
    marginRight: 20,
  },
});
