import { View, Text, Image as ImagePDF } from "@react-pdf/renderer";
import React from "react";
import { styles } from "../../styles";
import Logo from "../../../../../../../assets/images/versoes-logo-icone-ingee-18.png"
interface HeaderProps {
  backColor: string;
  title: string;
}

export const Header: React.FC<HeaderProps> = ({
  backColor = "#81c787",
  title,
}) => {
  return (
    <View style={{ ...styles.header, backgroundColor: backColor }}>
      <Text style={styles.headerTitle}>{title}</Text>
      <ImagePDF 
        src={Logo}
        style={{width: 30}}  
      />
    </View>
  );
};
