export const firstTableItems = [
  [
    { output: '', background: 'none' },
    { output: 'Em toneladas de gás', colSpan: 4 },
    { output: 'Em toneladas métricas de CO₂ equivalente (tCO₂e)', colSpan: 4 },
  ],
  [
    { output: 'GEE (t)', rowSpan: 2 },
    { output: 'Escopo 1', rowSpan: 2, background: '#c6c5c5' },
    { output: 'Escopo 2', colSpan: 2, background: '#c6c5c5' },
    { output: 'Escopo 3', rowSpan: 2, background: '#c6c5c5' },
    { output: 'Escopo 1', rowSpan: 2, background: '#c6c5c5' },
    { output: 'Escopo 2', colSpan: 2, background: '#c6c5c5' },
    { output: 'Escopo 3', rowSpan: 2, background: '#c6c5c5' },
  ],
  [
    { output: 'Localização', background: '#a9a9a9' },
    { output: 'Escolha de compra', background: '#a9a9a9' },
    { output: 'Localização', background: '#a9a9a9' },
    { output: 'Escolha de compra', background: '#a9a9a9' },
  ],
]
