import styled from "styled-components";

type StatusLabelProps = {
  status: string | undefined;
};

export const Container = styled.div<StatusLabelProps>`
  padding-right: 5px;
  padding-bottom: 5px;
  width: auto;
  cursor: pointer;
  display:inline-block;

  .status-text {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 19px;
  }

    .bulled-0{
    display: flex;
    flex-direction: column;
    border: 1px solid #C42329;
    color: #2E2E2E;
    background: #BDBDBD;
    padding: 5px;
    border-radius: 5px;
    height: auto;
    text-align: center;
  }

  .bulled-0-35{
    display: flex;
    flex-direction: column;
    border: 1px solid #C42329;
    color: #C42329;
    background: #FBACAF;
    padding: 5px;
    border-radius: 5px;
    height: auto;
    text-align: center;
  }

  .bulled-36-99{
    display: flex;
    flex-direction: column;
    border: 1px solid #D37620;
    color: #D37620;
    background: #F7DEC6;
    padding: 5px;
    border-radius: 5px;
    height: auto;
    text-align: center;
  }
  
  .bulled-100{
    display: flex;
    flex-direction: column;
    border: 1px solid #81c787;
    color: #81c787;
    background: #D1EDE6;
    padding: 5px;
    border-radius: 5px;
    height: auto;
    text-align: center;
  }

  word-wrap: break-word;
`;
