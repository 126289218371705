import { PaginatorContainer, Button } from "./styles";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

interface PaginatorProps {
    state: any;
    information: any;
    handlePagePrev: () => void;
    handlePageNext: () => void;
}

const Paginator = ({ state, information, handlePagePrev, handlePageNext }: PaginatorProps) => {
    return (
        <PaginatorContainer>

            <Button onClick={() => handlePagePrev()} 
                disabled={state.prev === 0}><FaAngleLeft color="#FFF" size={12}/></Button>
            <Button style={{backgroundColor: "transparent", color: "#007bff"}}>{state.current}</Button>
            <Button onClick={() => handlePageNext()} 
                disabled={state.next >= information.length}><FaAngleRight color="#FFF" size={12}/></Button>

        </PaginatorContainer>
    );
};

export default Paginator;