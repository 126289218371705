import { Container } from './styles';
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";


interface FooterResultTableProps {
  description: string;
  result: string | 0;
}

export const FooterResultTable = ({ description, result }: FooterResultTableProps) => {
    return (
        <Container>
          <span style={{ margin: 10, width: "70%" }}>
            {description}
          </span>
          <span
            style={{
              fontWeight: "bold",
              margin: 10,
              textAlign: "right",
              width: "30%",
            }}
          >
            {result}
          </span>
        </Container>
    );
};