import React from "react";
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from "react-router-dom";

import useAuth from "../hooks/useAuth";

interface RouteProps extends ReactDOMRouteProps {
  isAdmin?: boolean;
  isPrivate?: boolean;
  isUnlocked?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isAdmin = false,
  isPrivate = false,
  isUnlocked = false,
  component: Component,
  ...props
}: any) => {
  const { isAuthenticated } = useAuth();

  return (
    <ReactDOMRoute
      {...props}
      render={({ location }) => {
        if (!isPrivate || (isPrivate && isAuthenticated)){
          return <Component />;
        } else if ((isPrivate && !isAuthenticated)){
          return (
            <Redirect to={{ pathname: "/em-breve", state: { from: location }}} />
          );
        }
      }}
    />
  );
};

export default Route;
