import { api } from "./api";

async function getById(scopeId: number) {
  try {
    const { data } = await api.get(`/scope/${scopeId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getBySlug(type: number, slug: string) {
  try {
    const { data } = await api.get(`/scope/slug/${type}/${slug}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getBySubSlug(type: number, slug: string, sub_slug: string) {
  try {
    const { data } = await api.get(`/scope/slug/${type}/${slug}/${sub_slug}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/scope", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function updateById(scopeId: number, scope: any) {
  try {
    const { data } = await api.put(`/scope/${scopeId}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getByAllSlug(type: number, slug: string) {
  try {
    const { data } = await api.get(`/scope/slug/all/${type}/${slug}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

const scopeService = {
  getById,
  getBySlug,
  getByAllSlug,
  getBySubSlug,
  getByParams,
  updateById
};

export default scopeService;
