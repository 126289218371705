import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../../../components/PDF/styles/common';
import { colors } from '../../../../components/PDF/styles/colors';
import { MaccResult } from '../../interfaces/MaccProjetcs';


interface MaccTableProps {
  projects: MaccResult[];
}
const ResumeProject: React.FC<MaccTableProps> = ({ projects }) => {

  const sortedByCost = [...projects].sort((a, b) => a.cost - b.cost);
  const sortedByEmission = [...projects].sort((a, b) => b.tCO2 - a.tCO2);

  const mostRecommendedProjects = sortedByCost.slice(0, 2).map(p => p.name);
  const projectsForReview = sortedByCost.slice(-2).map(p => p.name).reverse();
  const highestEmissionAvoided = sortedByEmission[0].name;
  const lowestEmissionAvoided = sortedByEmission[sortedByEmission.length - 1].name;

  return (
    <View  style={{ 
      borderColor: colors.darkGrey, 
      borderWidth: 1,    
      padding: 5,
    }}
  >
     <Text style={styles.titleSummary}>Resumo dos Projetos</Text>
     <Text style={styles.textSummary}>
        A curva MACC é uma ferramenta visual que mostra as opções para abater emissões em ordem crescente de custo. Ela ajuda a identificar as opções mais econômicas para reduzir emissões.
      </Text>

      <Text style={styles.emphasis}>Projetos Mais Recomendados:</Text>
      <Text style={styles.textSummary}>
        {mostRecommendedProjects.join(' e ')} são os projetos mais efetivos e devem ser priorizados.
      </Text>

      <Text style={styles.emphasis}>Projetos para Revisão:</Text>
      <Text style={styles.textSummary}>
        {projectsForReview.join(' e ')} têm os maiores custos associados e devem ser revisados para otimização.
      </Text>

      <Text style={styles.emphasis}>Maior Emissão Evitada:</Text>
      <Text style={styles.textSummary}>
        O projeto {highestEmissionAvoided} tem a maior quantidade de tCO<Text style={styles.subscript}>₂</Text>e evitada.
      </Text>

      <Text style={styles.emphasis}>Menor Emissão Evitada:</Text>
      <Text style={styles.textSummary}>
        O projeto {lowestEmissionAvoided} tem a menor quantidade de tCO<Text style={styles.subscript}>₂</Text>e evitada e pode necessitar de mais atenção para aumentar sua efetividade.
      </Text>

      <Text style={styles.emphasis}>Recomendação Geral:</Text>
      <Text style={styles.textSummary}>
        Priorize os projetos mais efetivos e revise os projetos com custos mais altos para garantir a máxima eficiência em redução de emissões.
      </Text>
    </View>
  );
};

export default ResumeProject;
