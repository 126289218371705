import { Container } from "./styles"

type DescriptionTextProps  = {
    title: string | undefined;
}

export const DescriptionText = ({ title }: DescriptionTextProps) => {
    return (
        <Container>{title}</Container>
    )
}
