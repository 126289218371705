import React from 'react'
import { Container } from './styles'
import InfoButtonMin from "../../../assets/icons/info-sign.png"

type ThirdStepProps = {
    register: (value: string) => any;
    setValue: (value: string, e: any) => any;
    handleChange: () => void;
}

export const ThirdStep: React.FC<ThirdStepProps> = ({register, setValue, handleChange}) => {

    function moeda(a: string, e: string, r: string, decPlaces = 6): string {
        if (!a || a.replace(/[^\d]+/g, "") === "") {
          return "0" + r + "0".repeat(decPlaces);
        }
    
        let n = a.replace(new RegExp(`\\${e}`, "g"), "").replace(new RegExp(`\\${r}`, "g"), "");
    
        n = n.replace(/[^\d]+/g, "");
    
        while (n.length <= decPlaces) {n = "0" + n;}
    
        const len = n.length;
        const intPart = n.substring(0, len - decPlaces);
        const decPart = n.substring(len - decPlaces);
    
        const cleanedIntPart = intPart.replace(/^0+/, "") || "0";
    
        let formattedValue = cleanedIntPart.replace(/\B(?=(\d{3})+(?!\d))/g, e);
        formattedValue += r + decPart;
    
        return formattedValue;
    }

  return (
    <Container>
        <div className='div-body'>
            <div className='div-text'>
                <span className='text-statement'>
                    Passo 3 - Dados da geração de efluentes líquidos domésticos
                </span>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text'>
                        Quantidade de efluente líquido doméstico gerada no ano do inventário
                    </span>
                </div>

                <div className='data-input'>
                    <input style={{width: '200px', height: 35, textAlign: "right"}} 
                        {...register("generated_liquid_effluent")}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            const inputValue = event.target.value;
                            const formattedValue = moeda(inputValue, ".", ",", 6);
                          
                            setValue("generated_liquid_effluent", formattedValue);
                          
                            handleChange();
                          
                            event.target.value = formattedValue;
                        }}
                    />

                    <span className='unit-text'>
                    [m³/ano]
                  </span>
                </div>
            </div>
        </div>
    </Container>
  )
}

