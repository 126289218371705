import React, { useEffect, useState } from 'react'
import { GoBack } from '../../../../../components/GoBack'
import { TitleText } from '../../../../../components/TitleText'
import useAuth from '../../../../../hooks/useAuth'
import inventoryService from '../../../../../services/inventoryService'
import { Container } from './styles'
import { useLocation, useHistory } from 'react-router-dom'
import { Button } from '../../../../../components/Button/styles'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import Select from 'react-select'
import {  formatInteger,  formatPrice3, formatPrice5} from '../../../../../utils/transform'
import scope1Service from '../../../../../services/scope1Service'
import { Modal } from '../../../../../components/Modal'
import climaticCharacteristicService from '../../../../../services/climaticCharacteristicService'
import scopeService from '../../../../../services/scopeService'
import Parser from 'html-react-parser'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'
import { toast } from 'react-hot-toast'
import { Loading } from '../../../../../components/Loading'

export const LandfilledWaste = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([])
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [title, setTitle] = useState<string | undefined>();
  const [loadingModal, setLoadingModal] = useState(false);
  const [guidelines, setGuidelines] = useState<string | undefined>()
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [climCharSelected, setClimCharSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const location = useLocation()

  const [years, setYears] = useState<any>([])
  const [cities, setCities] = useState<any>([])
  const [states, setStates] = useState<any>([])
  const [scope1, setScope1] = useState<any>([])
  const [scope2, setScope2] = useState<any>([])
  const [scope3, setScope3] = useState<any>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scope4, setScope4] = useState<any>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totals, setTotals] = useState<any>([])
  const [totalEmissionCo2, setTotalEmissionCo2] = useState<any>()
  const [changeScope2, setChangeScope2] = useState<string | undefined>()
  const [changeScope3, setChangeScope3] = useState<string | undefined>()
  const [changeScopeTotal, setChangeScopeTotal] = useState<string | undefined>()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-1/residuos-solidos'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location])

  useEffect(() => {
    calculateTotalsCh4()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [years])

  useEffect(() => {
    calculateTotalsCh4()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [changeScope2])

  useEffect(() => {
    calculateTotalsCh4()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [changeScope3])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(1, 'residuos_solidos', 'residuos_aterrados')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors }
  } = useForm<any>()

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      const ch4: any[] = []
      const itens: any[] = []
      const totalItems: any[] = []
      const compositions: any[] = []
      const dispositions: any[] = []

      for (const year of years) {
        ch4.push({ year: year, amount: scope4[year] ? scope4[year] : 0 })
        itens.push({ year: year, amount: scope1[year] ? scope1[year] : 0 })

        compositions.push({
          year: year,
          type: 0,
          doc: scope2[year + '_0'] ? scope2[year + '_0'] : 0,
          percentage: 0
        })

        for (let i = 1; i < 10; i++) {
          compositions.push({
            year: year,
            type: i,
            doc: 0,
            percentage: scope2[year + '_' + i] ? scope2[year + '_' + i] : 0
          })
        }

        dispositions.push({
          year: year,
          ox: scope3[year].ox ? scope3[year].ox : undefined,
          mfc: scope3[year].mfc ? scope3[year].mfc : undefined,
          classification: scope3[year].classification
            ? scope3[year].classification
            : undefined
        })

        totalItems.push({
          year: year,
          emission_ch4: totals[year].emission_ch4
            ? parseFloat(totals[year].emission_ch4.toFixed(5))
            : 0,
          emission_co2: totals[year].emission_co2
            ? parseFloat(totals[year].emission_co2.toFixed(5))
            : 0,
          emission_co2_bio: totals[year].emission_co2_bio
            ? parseFloat(totals[year].emission_co2_bio.toFixed(5))
            : 0
        })
      }

      let fraction_ch4_biogas = getValues('fraction_ch4_biogas')
      fraction_ch4_biogas = fraction_ch4_biogas
        ? parseFloat(fraction_ch4_biogas.replace('.', '').replace(',', '.'))
        : 0

      const scope = {
        ch4: ch4,
        itens: itens,
        totals: totalItems,
        compositions: compositions,
        dispositions: dispositions,
        city: climCharSelected.city,
        reason: formData.reason,
        evidence: formData.evidence,
        state: climCharSelected.state,
        ignore_fill: formData.ignore_fill,
        emission_co2_final: totalEmissionCo2,
        fraction_ch4_biogas: fraction_ch4_biogas,
        precipitation: climCharSelected.precipitation,
        average_temperature: climCharSelected.average_temperature,
        destination_recovered_biogas: parseInt(formData.destination_recovered_biogas),
        potential_evapotranspiration: climCharSelected.potential_evapotranspiration
      }

      const result = await scope1Service.saveSolidWasteLandfilled(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('SolidWasteLandfilled', JSON.stringify(scope));

        reset()
        setErrorApi(undefined)

        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  const { isCommonUser } = useAuth()

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const states = await climaticCharacteristicService.getStates()

    setStates(
      states?.map((item: any) => {
        return {
          label: item.label,
          value: item.label
        }
      })
    )

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope1Service.getSolidWasteLandfilled(company_id, year)

    const scope1: any = {}
    const scope2: any = {}

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      1,
      'residuos_solidos',
      'residuos_aterrados'
    )

    setFiles(result.files)

    if (entity) {
      let fraction_ch4_biogas_value = Number(entity.fraction_ch4_biogas)

      if (isNaN(fraction_ch4_biogas_value)) {
        fraction_ch4_biogas_value = 0
      }

      setScopeSelected(entity)
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence_gee)
      setValue('ignore_fill', entity.ignore_fill)
      setValue(
        'destination_recovered_biogas',
        Number(entity.destination_recovered_biogas)
      )
      setValue('fraction_ch4_biogas', formatPrice5(fraction_ch4_biogas_value, false))

      setTotalEmissionCo2(formatPrice5(entity.emission_co2_final, false))

      setIgnoreFill(entity.ignore_fill)

      if (entity.state && entity.city) {
        const item = await climaticCharacteristicService.getByCity(
          entity.state,
          entity.city
        )

        setClimCharSelected(item)
      }

      if (entity?.itens) {
        for (let i = 0; i < entity?.itens?.length; i++) {
          const item = entity?.itens[i]

          setValue(`scope1_${item.year}_amount`, item.amount)
          scope1[item.year] = item.amount
        }
      }

      if (entity?.compositions) {
        for (let i = 0; i < entity?.compositions?.length; i++) {
          const item = entity?.compositions[i]

          if (item.type === 0) {
            scope2[item.year + '_' + item.type] = item.doc
          } else {
            setValue(`scope2_${item.year}_${item.type}_percentage`, item.percentage)
            scope2[item.year + '_' + item.type] = item.percentage
          }
        }
      }

      if (entity?.dispositions) {
        for (let i = 0; i < entity?.dispositions?.length; i++) {
          const item = entity?.dispositions[i]

          setValue(`scope3_${item.year}_ox`, item.ox)
          setValue(`scope3_${item.year}_mfc`, item.mfc)
          setValue(`scope3_${item.year}_classification`, item.classification)

          scope3[item.year] = {}
          scope3[item.year].ox = item.ox ? item.ox : 0
          scope3[item.year].mfc = item.mfc ? item.mfc : 0
          scope3[item.year].classification = item.classification
        }
      }

      if (entity?.ch4) {
        for (let i = 0; i < entity?.ch4?.length; i++) {
          const item = entity?.ch4[i]

          setValue(`scope4_${item.year}_amount`, item.amount)
          scope4[item.year] = item.amount
        }
      }

      if (entity?.totals) {
        for (let i = 0; i < entity?.totals?.length; i++) {
          const item = entity?.totals[i]

          setValue(`totals_${item.year}_emission_ch4`, item.emission_ch4)
          setValue(`totals_${item.year}_emission_co2`, item.emission_co2)
          setValue(`totals_${item.year}_emission_co2_bio`, item.emission_co2_bio)

          totals[item.year] = {}
          totals[item.year].emission_ch4 = item.emission_ch4 ? item.emission_ch4 : 0
          totals[item.year].emission_co2 = item.emission_co2 ? item.emission_co2 : 0
          totals[item.year].emission_co2_bio = item.emission_co2_bio
            ? item.emission_co2_bio
            : 0
        }
      }

      setYears(
        entity?.itens.map((item: any, index: number) => {
          calculateTotalsScope2(item.year, scope2)

          return item.year
        })
      )

      setScope1(scope1)

      setChangeScope2('load_scope_' + entity.id + '_scopes_' + entity?.itens.length)
      setChangeScope3(
        'load_scope_' + entity.id + '_scopes_' + entity?.dispositions.length
      )

      calculateTotalsCh4()
    } else {
      setScopeSelected({})

      setIgnoreFill(false)

      setChangeScope2('load_scope_0_scopes_0')
      setChangeScope3('load_scope_0_scopes_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d)(\d{5})$/, '$1,$2')
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')
    return value
  }

  const handleChangeState = (e: any) => {
    async function retrieveItemByUf(uf: any) {
      const states = await climaticCharacteristicService.getByState(uf)

      setCities(
        states?.map((item: any) => {
          return {
            label: item.name,
            value: item.id
          }
        })
      )

      setClimCharSelected({})
    }

    if (e) {
      retrieveItemByUf(e.value)
    } else {
      setValue('city', [])
      setClimCharSelected({})
    }
  }

  const handleChangeCity = (e: any) => {
    async function retrieveItemById(id: any) {
      const item = await climaticCharacteristicService.getById(id)

      setClimCharSelected(item)
    }

    if (e) {
      retrieveItemById(e.value)
    } else {
      setClimCharSelected({})
    }
  }

  const calculateTotalsCh4 = () => {
    const first = years[0]
    const acumulo: any = {}

    let totalEmissionCo2 = 0

    const destination_recovered_biogas = getValues('destination_recovered_biogas')
    const fraction_ch4_biogas = getValues('fraction_ch4_biogas')

    for (const year of years) {
      const ox = scope3[year].ox ? scope3[year].ox : 0
      const mfc = scope3[year].mfc ? scope3[year].mfc : 0
      const ch4 = scope4[year] ? scope4[year] : 0

      let methane_amount = 0

      const docf = 0.5

      const residuo_a = 0.4
      const residuo_b = 0.24
      const residuo_c = 0.15
      const residuo_d = 0.43
      const residuo_e = 0.2
      const residuo_f = 0.24
      const residuo_g = 0.39
      const residuo_h = 0.05

      let valor_sug_papeis = 0
      let valor_sug_madeira = 0
      let valor_sug_jardim = 0
      let valor_sug_alimentos_lodo = 0
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let valor_sug_builk_waste = 0
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const valor_sug_doc = 0.5
      const valor_sug_16_12 = 16 / 12
      let valor_sug_f = 0

      const temperature = climCharSelected.average_temperature
        ? climCharSelected.average_temperature
        : 0
      const precipitation = climCharSelected.precipitation
        ? climCharSelected.precipitation
        : 0
      const potencial = climCharSelected.potential_evapotranspiration
        ? climCharSelected.potential_evapotranspiration
        : 0

      if (temperature > 20) {
        if (precipitation < 1000) {
          valor_sug_papeis = 0.045
          valor_sug_madeira = 0.025
          valor_sug_jardim = 0.065
          valor_sug_alimentos_lodo = 0.085
          valor_sug_builk_waste = 0
        } else {
          valor_sug_papeis = 0.07
          valor_sug_madeira = 0.035
          valor_sug_jardim = 0.17
          valor_sug_alimentos_lodo = 0.4
          valor_sug_builk_waste = 0.17
        }
      } else if (precipitation / potencial < 1) {
        valor_sug_papeis = 0.04
        valor_sug_madeira = 0.02
        valor_sug_jardim = 0.05
        valor_sug_alimentos_lodo = 0.06
        valor_sug_builk_waste = 0.05
      } else {
        valor_sug_papeis = 0.06
        valor_sug_madeira = 0.03
        valor_sug_jardim = 0.1
        valor_sug_alimentos_lodo = 0.185
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        valor_sug_builk_waste = 0.09
      }

      const fraction_ch4_biogas_value = parseFloat(fraction_ch4_biogas)

      if (isNaN(fraction_ch4_biogas_value) || parseFloat(fraction_ch4_biogas) === 0) {
        valor_sug_f = 0.5
      } else {
        valor_sug_f = parseFloat(fraction_ch4_biogas)
      }

      const amount = scope1[year] ? scope1[year] : 0
      const perc_comp_a = scope2[year + '_1'] ? scope2[year + '_1'] / 100 : 0
      const perc_comp_b = scope2[year + '_2'] ? scope2[year + '_2'] / 100 : 0
      const perc_comp_c = scope2[year + '_3'] ? scope2[year + '_3'] / 100 : 0
      const perc_comp_d = scope2[year + '_4'] ? scope2[year + '_4'] / 100 : 0
      const perc_comp_e = scope2[year + '_5'] ? scope2[year + '_5'] / 100 : 0
      const perc_comp_f = scope2[year + '_6'] ? scope2[year + '_6'] / 100 : 0
      const perc_comp_g = scope2[year + '_7'] ? scope2[year + '_7'] / 100 : 0
      const perc_comp_h = scope2[year + '_8'] ? scope2[year + '_8'] / 100 : 0

      const doc_depositado_a = amount * perc_comp_a * residuo_a * mfc * docf
      const doc_depositado_b = amount * perc_comp_b * residuo_b * mfc * docf
      const doc_depositado_c = amount * perc_comp_c * residuo_c * mfc * docf
      const doc_depositado_d = amount * perc_comp_d * residuo_d * mfc * docf
      const doc_depositado_e = amount * perc_comp_e * residuo_e * mfc * docf
      const doc_depositado_f = amount * perc_comp_f * residuo_f * mfc * docf
      const doc_depositado_g = amount * perc_comp_g * residuo_g * mfc * docf
      const doc_depositado_h = amount * perc_comp_h * residuo_h * mfc * docf

      let doc_acumulado_a = 0
      let doc_acumulado_b = 0
      let doc_acumulado_c = 0
      let doc_acumulado_d = 0
      let doc_acumulado_e = 0
      let doc_acumulado_f = 0
      let doc_acumulado_g = 0
      let doc_acumulado_h = 0

      let decomp_a = 0
      let decomp_b = 0
      let decomp_c = 0
      let decomp_d = 0
      let decomp_e = 0
      let decomp_f = 0
      let decomp_g = 0
      let decomp_h = 0

      if (first === year) {
        doc_acumulado_a = doc_depositado_a
        doc_acumulado_b = doc_depositado_b
        doc_acumulado_c = doc_depositado_c
        doc_acumulado_d = doc_depositado_d
        doc_acumulado_e = doc_depositado_e
        doc_acumulado_f = doc_depositado_f
        doc_acumulado_g = doc_depositado_g
        doc_acumulado_h = doc_depositado_h
      } else {
        doc_acumulado_a =
          doc_depositado_a + acumulo[year - 1 + '_a'] * Math.exp(-valor_sug_papeis)
        doc_acumulado_b =
          doc_depositado_b + acumulo[year - 1 + '_b'] * Math.exp(-valor_sug_papeis)
        doc_acumulado_c =
          doc_depositado_c +
          acumulo[year - 1 + '_c'] * Math.exp(-valor_sug_alimentos_lodo)
        doc_acumulado_d =
          doc_depositado_d + acumulo[year - 1 + '_d'] * Math.exp(-valor_sug_madeira)
        doc_acumulado_e =
          doc_depositado_e + acumulo[year - 1 + '_e'] * Math.exp(-valor_sug_jardim)
        doc_acumulado_f =
          doc_depositado_f + acumulo[year - 1 + '_f'] * Math.exp(-valor_sug_jardim)
        doc_acumulado_g =
          doc_depositado_g + acumulo[year - 1 + '_g'] * Math.exp(-valor_sug_jardim)
        doc_acumulado_h =
          doc_depositado_h +
          acumulo[year - 1 + '_h'] * Math.exp(-valor_sug_alimentos_lodo)

        decomp_a = acumulo[year - 1 + '_a'] * (1 - Math.exp(-valor_sug_papeis))
        decomp_b = acumulo[year - 1 + '_b'] * (1 - Math.exp(-valor_sug_papeis))
        decomp_c = acumulo[year - 1 + '_c'] * (1 - Math.exp(-valor_sug_alimentos_lodo))
        decomp_d = acumulo[year - 1 + '_d'] * (1 - Math.exp(-valor_sug_madeira))
        decomp_e = acumulo[year - 1 + '_e'] * (1 - Math.exp(-valor_sug_jardim))
        decomp_f = acumulo[year - 1 + '_f'] * (1 - Math.exp(-valor_sug_jardim))
        decomp_g = acumulo[year - 1 + '_g'] * (1 - Math.exp(-valor_sug_jardim))
        decomp_h = acumulo[year - 1 + '_h'] * (1 - Math.exp(-valor_sug_alimentos_lodo))
      }

      acumulo[year + '_a'] = doc_acumulado_a ? doc_acumulado_a : 0
      acumulo[year + '_b'] = doc_acumulado_b ? doc_acumulado_b : 0
      acumulo[year + '_c'] = doc_acumulado_c ? doc_acumulado_c : 0
      acumulo[year + '_d'] = doc_acumulado_d ? doc_acumulado_d : 0
      acumulo[year + '_e'] = doc_acumulado_e ? doc_acumulado_e : 0
      acumulo[year + '_f'] = doc_acumulado_f ? doc_acumulado_f : 0
      acumulo[year + '_g'] = doc_acumulado_g ? doc_acumulado_g : 0
      acumulo[year + '_h'] = doc_acumulado_h ? doc_acumulado_h : 0

      methane_amount =
        (decomp_a +
          decomp_b +
          decomp_c +
          decomp_d +
          decomp_e +
          decomp_f +
          decomp_g +
          decomp_h) *
        valor_sug_16_12 *
        valor_sug_f

      const emission_ch4 = (methane_amount - ch4) * (1 - ox)
      const emission_co2 = emission_ch4 * 28
      let emission_co2_bio = 0

      if (destination_recovered_biogas === '1') {
        emission_co2_bio = ch4 * (44 / 16)
      }

      totals[year].emission_ch4 = emission_ch4
      totals[year].emission_co2 = emission_co2
      totals[year].emission_co2_bio = emission_co2_bio

      totalEmissionCo2 = emission_co2
    }

    setTotalEmissionCo2(totalEmissionCo2)

    setChangeScopeTotal('change_scope_total' + new Date().getMilliseconds())
  }

  const calculateTotalsScope3 = (year: number, scope: any) => {
    const classification = scope[year].classification
      ? scope[year].classification
      : undefined

    async function calculate() {
      const result = await scope1Service.calculateLandfilledDisposition(classification)

      if (result) {
        scope[year].ox = result.ox
        scope[year].mfc = result.mfc

        setScope3(scope)

        setChangeScope3('remove_scope_item_' + year + '_' + classification)
      }

      calculateTotalsCh4()
    }

    calculate()
  }

  const calculateTotalsScope2 = (year: number, scope: any) => {
    const perc_1 = scope[year + '_1'] ? scope[year + '_1'] : 0
    const perc_2 = scope[year + '_2'] ? scope[year + '_2'] : 0
    const perc_3 = scope[year + '_3'] ? scope[year + '_3'] : 0
    const perc_4 = scope[year + '_4'] ? scope[year + '_4'] : 0
    const perc_5 = scope[year + '_5'] ? scope[year + '_5'] : 0
    const perc_6 = scope[year + '_6'] ? scope[year + '_6'] : 0
    const perc_7 = scope[year + '_7'] ? scope[year + '_7'] : 0
    const perc_8 = scope[year + '_8'] ? scope[year + '_8'] : 0

    const perc_9 =
      100 - perc_1 - perc_2 - perc_3 - perc_4 - perc_5 - perc_6 - perc_7 - perc_8

    scope[year + '_9'] = perc_9
    setValue(`scope_${year}_9_percentage`, perc_9)

    async function calculate() {
      const result = await scope1Service.calculateLandfilledComposition(
        perc_1,
        perc_2,
        perc_3,
        perc_4,
        perc_5,
        perc_6,
        perc_7,
        perc_8
      )

      if (result) {
        scope[year + '_0'] = result.doc

        setScope2(scope)
      }

      calculateTotalsCh4()

      setChangeScope2(
        'remove_scope_item_' +
          year +
          '_' +
          perc_1 +
          '_' +
          perc_2 +
          '_' +
          perc_3 +
          '_' +
          perc_4 +
          '_' +
          perc_5 +
          '_' +
          perc_6 +
          '_' +
          perc_7 +
          '_' +
          perc_8 +
          '_' +
          perc_9
      )
    }

    calculate()
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'residuos_solidos',
          'residuos_aterrados'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const handleDownloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const handleAttachmentChange = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 1,
        name: 'residuos_solidos',
        file: base64,
        filename: filename,
        second_name: 'residuos_aterrados'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'residuos_solidos',
          'residuos_aterrados'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 01 - ' + title}  />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 230px)',
            maxHeight: 'calc(100vh - 230px)'
          }}
        >
          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginTop: 0
                    }}
                  >
                    Estado / Município
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 0 }}>
                    Características climáticas do local de disposição do resíduo
                  </span>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {scopeSelected &&
                    scopeSelected.id &&
                    scopeSelected.city &&
                    scopeSelected.state && (
                      <div
                        style={{
                          borderStyle: 'dashed',
                          margin: 5,
                          borderWidth: '1px',
                          borderColor: '#9E9E9E',
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: 15,
                          padding: 5
                        }}
                      >
                        <span
                          style={{
                            textAlign: 'start',
                            fontSize: 14,
                            fontWeight: 'bold',
                            margin: 6
                          }}
                        >
                          {scopeSelected.city + ' - ' + scopeSelected.state}
                        </span>
                      </div>
                    )}

                  {states &&
                    (scopeSelected === undefined || scopeSelected.state === undefined) && (
                      <Controller
                        control={control}
                        name="state"
                        render={({ field }) => (
                          <Select placeholder="[Selecione]"
                            {...field}
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                width: '120px',
                                height: 40,
                                marginTop: 5,
                                marginRight: 10,
                                backgroundColor: 'rgb(246, 246, 246)'
                              }),
                              option: (styles) => {
                                return { ...styles, color: '#31363F' }
                              }
                            }}
                            defaultValue={getValues('state')}
                            options={states}
                            onChange={handleChangeState}
                          />
                        )}
                      />
                    )}

                  {cities &&
                    (scopeSelected === undefined || scopeSelected.city === undefined) && (
                      <Controller
                        control={control}
                        name="city"
                        render={({ field }) => (
                          <Select placeholder="[Selecione]"
                            {...field}
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                width: '250px',
                                height: 40,
                                marginTop: 5,
                                marginRight: 10,
                                backgroundColor: 'rgb(246, 246, 246)'
                              }),
                              option: (styles) => {
                                return { ...styles, color: '#31363F' }
                              }
                            }}
                            defaultValue={getValues('city')}
                            options={cities}
                            onChange={handleChangeCity}
                          />
                        )}
                      />
                    )}
                </div>
              </div>

              {climCharSelected && climCharSelected.id && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      borderStyle: 'dashed',
                      margin: 5,
                      borderWidth: '1px',
                      width: '30%',
                      borderColor: '#9E9E9E',
                      height: '40px',
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: 15,
                      padding: 5
                    }}
                  >
                    <span>Temperatura anual média [ºC]:</span>
                    <span style={{ fontWeight: 'bold' }}>
                      {formatPrice3(climCharSelected.average_temperature, false)}
                    </span>
                  </div>
                  <div
                    style={{
                      borderStyle: 'dashed',
                      margin: 5,
                      borderWidth: '1px',
                      width: '30%',
                      borderColor: '#9E9E9E',
                      height: '40px',
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: 15,
                      padding: 5
                    }}
                  >
                    <span>Precipitação anual [mm/ano]:</span>
                    <span style={{ fontWeight: 'bold' }}>
                      {formatPrice3(climCharSelected.precipitation, false)}
                    </span>
                  </div>
                  <div
                    style={{
                      borderStyle: 'dashed',
                      margin: 5,
                      borderWidth: '1px',
                      width: '40%',
                      borderColor: '#9E9E9E',
                      height: '40px',
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: 15,
                      padding: 5
                    }}
                  >
                    <span>Potencial de evapotranspiração [mm/ano]:</span>
                    <span style={{ fontWeight: 'bold' }}>
                      {formatPrice3(climCharSelected.potential_evapotranspiration, false)}
                    </span>
                  </div>
                </div>
              )}

              {climCharSelected && climCharSelected.id && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 10,
                    marginRight: 0,
                    textAlign: 'right'
                  }}
                >
                  <span style={{ textAlign: 'end', fontSize: 14, marginTop: 0 }}>
                    Referências: INMET, 2018.
                  </span>
                </div>
              )}

              {climCharSelected === undefined ||
                (climCharSelected.id === undefined && (
                  <div
                    style={{
                      borderStyle: 'dashed',
                      margin: 15,
                      borderWidth: '1px',
                      borderColor: '#9E9E9E',
                      height: '60px',
                      display: 'flex',
                      flexDirection: 'column',
                      width: '95%',
                      verticalAlign: 'middle',
                      textAlign: 'center'
                    }}
                  >
                    <span style={{ textDecoration: 'underline', paddingTop: 20 }}>
                      Selecione o Estado / Cidade para poder informar os dados de
                      atividade da organização inventariante
                    </span>
                  </div>
                ))}

              {climCharSelected && climCharSelected.id && scope1 && years && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginTop: 0
                    }}
                  >
                    Dados de atividade da organização inventariante.
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 14, marginBottom: 10 }}>
                    Preencha com a quantidade de resíduos aterrada, para todos os anos em
                    que houver dados disponíveis.
                  </span>
                </div>
              )}

              {climCharSelected && climCharSelected.id && scope1 && years && (
                <div
                  style={{
                    overflow: 'auto',
                    position: 'relative',
                    marginTop: 0,
                    width: 'calc(100vw - 320px)',
                    maxWidth: 'calc(100vw - 320px)'
                  }}
                >
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }}></th>
                        {years.map((year: any) => {
                          return (
                            <React.Fragment key={year}>
                              <th className="head-tr-item" style={{ width: 100 }}>
                                {year}
                              </th>
                            </React.Fragment>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: 220 }}>
                          <span
                            style={{
                              marginLeft: 5,
                              width: 220,
                              fontSize: 12,
                              fontWeight: 'bold',
                              display: 'inline-block'
                            }}
                          >
                            Quantidade de resíduos enviados ao aterro no ano [t/ano]
                          </span>
                        </td>
                        {years.map((year: any) => {
                          return (
                            <React.Fragment key={year}>
                              <td>
                                <input
                                  style={{ width: 100, textAlign: 'right' }}
                                  className="input-item"
                                  readOnly={isCommonUser}
                                  {...register(`scope1_${year}_amount`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scope1[year] = value

                                    setValue(`scope1_${year}_amount`, formatValue)

                                    calculateTotalsCh4()
                                  }}
                                />
                              </td>
                            </React.Fragment>
                          )
                        })}
                      </tr>
                    </tbody>
                  </table>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      marginBottom: 10
                    }}
                  >
                    {errors && errors.scope && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          margin: 10
                        }}
                      >
                        <span
                          className="requiredLabel"
                          style={{ fontSize: 14, marginTop: 10 }}
                        >
                          * Todos os campos são obrigatórios
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {climCharSelected && climCharSelected.id && scope2 && years && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginTop: 0
                    }}
                  >
                    Dados da composição do resíduo.
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 14, marginBottom: 10 }}>
                    Preencha a composição do resíduo gerado pela organização. Preencha
                    apenas para os anos em que houve disposição de resíduos.
                  </span>
                </div>
              )}

              {changeScope2 &&
                climCharSelected &&
                climCharSelected.id &&
                scope2 &&
                years && (
                  <div
                    style={{
                      overflow: 'auto',
                      position: 'relative',
                      marginTop: 0,
                      width: 'calc(100vw - 320px)',
                      maxWidth: 'calc(100vw - 320px)'
                    }}
                  >
                    <table
                      style={{
                        maxWidth: '100%',
                        width: '4000px',
                        borderCollapse: 'collapse'
                      }}
                      className="table-scope"
                    >
                      <thead>
                        <tr>
                          <th className="head-tr-item" style={{ width: 200 }}>
                            {'Composição do resíduo'}
                          </th>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <th className="head-tr-item" style={{ width: 100 }}>
                                  {year}
                                </th>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: 220 }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: 220,
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              A - Papéis/papelão
                            </span>
                          </td>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <td>
                                  <input
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    readOnly={isCommonUser}
                                    {...register(`scope2_${year}_1_percentage`)}
                                    onChange={(event) => {
                                      const formatValue = event.target.value;
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      scope2[year + '_1'] = value

                                      setValue(`scope2_${year}_1_percentage`, formatValue)

                                      calculateTotalsScope2(year, scope2)
                                    }}
                                  />
                                </td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                        <tr>
                          <td style={{ width: 220 }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: 220,
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              B - Resíduos têxteis
                            </span>
                          </td>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <td>
                                  <input
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    readOnly={isCommonUser}
                                    {...register(`scope2_${year}_2_percentage`)}
                                    onChange={(event) => {
                                      const formatValue = event.target.value;
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      scope2[year + '_2'] = value

                                      setValue(`scope2_${year}_2_percentage`, formatValue)

                                      calculateTotalsScope2(year, scope2)
                                    }}
                                  />
                                </td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                        <tr>
                          <td style={{ width: 220 }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: 220,
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              C - Resíduos alimentares
                            </span>
                          </td>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <td>
                                  <input
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    readOnly={isCommonUser}
                                    {...register(`scope2_${year}_3_percentage`)}
                                    onChange={(event) => {
                                      const formatValue = event.target.value;
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      scope2[year + '_3'] = value

                                      setValue(`scope2_${year}_3_percentage`, formatValue)

                                      calculateTotalsScope2(year, scope2)
                                    }}
                                  />
                                </td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                        <tr>
                          <td style={{ width: 220 }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: 220,
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              D - Madeira
                            </span>
                          </td>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <td>
                                  <input
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    readOnly={isCommonUser}
                                    {...register(`scope2_${year}_4_percentage`)}
                                    onChange={(event) => {
                                      const formatValue = event.target.value;
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      scope2[year + '_4'] = value

                                      setValue(`scope2_${year}_4_percentage`, formatValue)

                                      calculateTotalsScope2(year, scope2)
                                    }}
                                  />
                                </td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                        <tr>
                          <td style={{ width: 220 }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: 220,
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              E - Resíduos de jardim e parque
                            </span>
                          </td>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <td>
                                  <input
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    readOnly={isCommonUser}
                                    {...register(`scope2_${year}_5_percentage`)}
                                    onChange={(event) => {
                                      const formatValue = event.target.value;
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      scope2[year + '_5'] = value

                                      setValue(`scope2_${year}_5_percentage`, formatValue)

                                      calculateTotalsScope2(year, scope2)
                                    }}
                                  />
                                </td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                        <tr>
                          <td style={{ width: 220 }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: 220,
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              F - Fraldas
                            </span>
                          </td>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <td>
                                  <input
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    readOnly={isCommonUser}
                                    {...register(`scope2_${year}_6_percentage`)}
                                    onChange={(event) => {
                                      const formatValue = event.target.value;
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      scope2[year + '_6'] = value

                                      setValue(`scope2_${year}_6_percentage`, formatValue)

                                      calculateTotalsScope2(year, scope2)
                                    }}
                                  />
                                </td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                        <tr>
                          <td style={{ width: 220 }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: 220,
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              G - Borracha e couro
                            </span>
                          </td>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <td>
                                  <input
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    readOnly={isCommonUser}
                                    {...register(`scope2_${year}_7_percentage`)}
                                    onChange={(event) => {
                                      const formatValue = event.target.value;
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      scope2[year + '_7'] = value

                                      setValue(`scope2_${year}_7_percentage`, formatValue)

                                      calculateTotalsScope2(year, scope2)
                                    }}
                                  />
                                </td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                        <tr>
                          <td style={{ width: 220 }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: 220,
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              H - Lodo de esgoto
                            </span>
                          </td>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <td>
                                  <input
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    readOnly={isCommonUser}
                                    {...register(`scope2_${year}_8_percentage`)}
                                    onChange={(event) => {
                                      const formatValue = event.target.value;
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      scope2[year + '_8'] = value

                                      setValue(`scope2_${year}_8_percentage`, formatValue)

                                      calculateTotalsScope2(year, scope2)
                                    }}
                                  />
                                </td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                        <tr>
                          <td style={{ width: 220, height: 35 }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: 220,
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              Outros materiais inertes
                            </span>
                          </td>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <td
                                  style={{
                                    fontWeight: 'bold',
                                    background:
                                      scope2[year + '_9'] < 0 ? '#C42329' : '#F6F6F6',
                                    color: scope2[year + '_9'] < 0 ? '#FFFFFF' : '#31363F'
                                  }}
                                >
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'center',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {scope2[year + '_9'] < 0
                                      ? 'Erro > 100%'
                                      : formatInteger(scope2[year + '_9']) + ' %'}
                                  </span>
                                </td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr style={{ fontWeight: 'bold' }}>
                          <td
                            className="head-tr-item"
                            style={{ paddingRight: 5, textAlign: 'left' }}
                          >
                            {'DOC - Carbono Orgânico Degradável ano a ano [tC/tMSW]'}
                          </td>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <td className="head-tr-item">
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'center',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice5(scope2[year + '_0'], false)}
                                  </span>
                                </td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                      </tfoot>
                    </table>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        marginBottom: 10
                      }}
                    >
                      {errors && errors.scope && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            margin: 10
                          }}
                        >
                          <span
                            className="requiredLabel"
                            style={{ fontSize: 14, marginTop: 10 }}
                          >
                            * Todos os campos são obrigatórios
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}

              {climCharSelected && climCharSelected.id && scope3 && years && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginTop: 0
                    }}
                  >
                    Qualidade da disposição de resíduos [MCF¹].
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 14, marginBottom: 10 }}>
                    Entre com a classificação, em relação à qualidade do aterro para onde
                    foram destinados os resíduos em cada ano.
                  </span>
                </div>
              )}

              {changeScope3 &&
                climCharSelected &&
                climCharSelected.id &&
                scope3 &&
                years && (
                  <div
                    style={{
                      overflow: 'auto',
                      position: 'relative',
                      marginTop: 0,
                      width: 'calc(100vw - 320px)',
                      maxWidth: 'calc(100vw - 320px)'
                    }}
                  >
                    <table
                      style={{
                        maxWidth: '100%',
                        width: '4000px',
                        borderCollapse: 'collapse',
                        marginBottom: 10
                      }}
                      className="table-scope"
                    >
                      <tbody>
                        <tr>
                          <td style={{ width: '25%' }} rowSpan={5}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              Qualidade do local de disposição dos resíduos
                            </span>
                          </td>
                          <td style={{ width: '25%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              A: se não possui a classificação do aterro
                            </span>
                          </td>
                          <td style={{ width: '50%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                display: 'inline-block'
                              }}
                            >
                              Aterros com classificação desconhecida e que não se encaixe
                              em nenhuma das categorias abaixo.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '25%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {'B: se aterro com profundidade < 5m'}
                            </span>
                          </td>
                          <td style={{ width: '50%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                display: 'inline-block'
                              }}
                            >
                              Todos os aterros que não atendem aos critérios dos aterros
                              sanitários e semi-aeróbios, e que possuem profundidade menor
                              que 5 metros.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '25%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {'C: se aterro com profundidade >= 5m'}
                            </span>
                          </td>
                          <td style={{ width: '50%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                display: 'inline-block'
                              }}
                            >
                              Todos os aterros que não atendem aos critérios dos aterros
                              sanitário e semi-aeróbio, e que possuem profundidade igual
                              ou maior a 5 m e/ou alto nível do lençol freático (próximo à
                              superfície). Este último critério corresponde ao
                              preenchimento de antigos corpos d'água por resíduos (ex.
                              lagos ou rios vazios).
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '25%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              D: se aterro sanitário
                            </span>
                          </td>
                          <td style={{ width: '50%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                display: 'inline-block'
                              }}
                            >
                              Devem ter controle do aterramento de resíduo (ex. resíduo
                              destinado especificamente para uma área do aterro, controle
                              de escavação e de chamas), incluindo ao menos um dos
                              seguintes métodos: (i) material de cobertura; (ii)
                              compactação mecânica; ou (iii) nivelamento do resíduo.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '25%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              E: se aterro semi-aeróbio manejado
                            </span>
                          </td>
                          <td style={{ width: '50%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                display: 'inline-block'
                              }}
                            >
                              Devem ter controle do aterramento de resíduo e incluir todas
                              as seguintes estruturas para introduzir ar nas camadas de
                              resíduos: (i) material de cobertura permeável; (ii) sistema
                              de drenagem de chorume; (iii) tanque de regulação; e (iv)
                              sistema de ventilação de gases.
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table
                      style={{
                        maxWidth: '100%',
                        width: '4000px',
                        borderCollapse: 'collapse'
                      }}
                      className="table-scope"
                    >
                      <thead>
                        <tr>
                          <th className="head-tr-item" style={{ width: 200 }}>
                            {'Anos'}
                          </th>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <th className="head-tr-item" style={{ width: 100 }}>
                                  {year}
                                </th>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: 220 }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: 220,
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              Classificação para cada ano (A, B, C, D ou E)
                            </span>
                          </td>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <td>
                                  <select
                                    style={{
                                      background: '#F6F6F6',
                                      border: '1px solid #DFDFDF',
                                      backgroundColor: '#ffffff',
                                      borderRadius: ' 2.5px',
                                      width: '110px',
                                      height: '35px'
                                    }}
                                    {...register(`scope3_${year}_classification`)}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      setValue(`scope3_${year}_classification`, value)
                                      scope3[year].classification = value

                                      calculateTotalsScope3(year, scope3)
                                    }}
                                  >
                                    <option label="" />
                                    <option value="A" label="A" />
                                    <option value="B" label="B" />
                                    <option value="C" label="C" />
                                    <option value="D" label="D" />
                                    <option value="E" label="E" />
                                  </select>
                                </td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                        <tr>
                          <td style={{ width: 220 }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: 220,
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              Fator de correção de metano (MCF)
                            </span>
                          </td>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <td className="head-tr-item">
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'center',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice5(scope3[year].mfc, false)}
                                  </span>
                                </td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                        <tr>
                          <td style={{ width: 220 }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: 220,
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              Fator de oxidação (OX)
                            </span>
                          </td>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <td className="head-tr-item">
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'center',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice5(scope3[year].ox, false)}
                                  </span>
                                </td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        marginBottom: 10
                      }}
                    >
                      {errors && errors.scope && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            margin: 10
                          }}
                        >
                          <span
                            className="requiredLabel"
                            style={{ fontSize: 14, marginTop: 10 }}
                          >
                            * Todos os campos são obrigatórios
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}

              {climCharSelected && climCharSelected.id && scope4 && years && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginTop: 0
                    }}
                  >
                    Concentração do Biogás
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 14, marginBottom: 10 }}>
                    Caso não possua este dado, deixe em branco. Será adotado o padrão
                    (default) do IPCC (2006), que é igual a 50%.
                  </span>
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '400px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <tbody>
                      <tr>
                        <td style={{ width: 220 }}>
                          <span
                            style={{
                              marginLeft: 5,
                              width: 220,
                              fontSize: 12,
                              fontWeight: 'bold',
                              display: 'inline-block'
                            }}
                          >
                            Fração de CH₄ no biogás
                          </span>
                        </td>
                        <td>
                          <input
                            style={{ width: 100, textAlign: 'center' }}
                            className="input-item"
                            readOnly={isCommonUser}
                            {...register('fraction_ch4_biogas')}
                            onChange={(event) => {
                              setValue('fraction_ch4_biogas', currency(event))

                              calculateTotalsCh4()
                            }}
                          />

                          <span
                            style={{
                              marginLeft: 5,
                              width: 20,
                              fontSize: 12,
                              fontWeight: 'bold',
                              display: 'inline-block'
                            }}
                          >
                            %
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {climCharSelected && climCharSelected.id && scope4 && years && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginTop: 0
                    }}
                  >
                    Recuperação de CH₄
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 14, marginBottom: 10 }}>
                    Há a recuperação de metano (CH₄) no aterro onde é depositado o
                    resíduo? Em caso negativo, pule este passo.
                  </span>
                </div>
              )}

              {climCharSelected && climCharSelected.id && scope4 && years && (
                <div
                  style={{
                    overflow: 'auto',
                    position: 'relative',
                    marginTop: 0,
                    width: 'calc(100vw - 320px)',
                    maxWidth: 'calc(100vw - 320px)'
                  }}
                >
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }}></th>
                        {years.map((year: any) => {
                          return (
                            <React.Fragment key={year}>
                              <th className="head-tr-item" style={{ width: 100 }}>
                                {year}
                              </th>
                            </React.Fragment>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: 220 }}>
                          <span
                            style={{
                              marginLeft: 5,
                              width: 220,
                              fontSize: 12,
                              fontWeight: 'bold',
                              display: 'inline-block'
                            }}
                          >
                            Metano recuperado do aterro ([tCH₄/ano])
                          </span>
                        </td>
                        {years.map((year: any) => {
                          return (
                            <React.Fragment key={year}>
                              <td>
                                <input
                                  style={{ width: 100, textAlign: 'right' }}
                                  className="input-item"
                                  readOnly={isCommonUser}
                                  {...register(`scope4_${year}_amount`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scope4[year] = value

                                    setValue(`scope4_${year}_amount`, formatValue)

                                    calculateTotalsCh4()
                                  }}
                                />
                              </td>
                            </React.Fragment>
                          )
                        })}
                      </tr>
                    </tbody>
                  </table>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      marginBottom: 10
                    }}
                  >
                    {errors && errors.scope && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          margin: 10
                        }}
                      >
                        <span
                          className="requiredLabel"
                          style={{ fontSize: 14, marginTop: 10 }}
                        >
                          * Todos os campos são obrigatórios
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {climCharSelected && climCharSelected.id && scope4 && years && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginBottom: 10 }}>
                    Caso não possua este dado, deixe em branco. Será adotado o padrão
                    (default) do IPCC (2006), que é igual a 50%.
                  </span>
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '400px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <tbody>
                      <tr>
                        <td style={{ width: 220 }}>
                          <span
                            style={{
                              marginLeft: 5,
                              width: 220,
                              fontSize: 12,
                              fontWeight: 'bold',
                              display: 'inline-block'
                            }}
                          >
                            No ano do inventário, qual o destino do biogás recuperado?
                          </span>
                        </td>
                        <td>
                          <select
                            style={{ borderWidth: '0px', width: '275px', height: '25px' }}
                            {...register('destination_recovered_biogas')}
                            onChange={(event) => {
                              setValue('destination_recovered_biogas', event.target.value)
                              calculateTotalsCh4()
                            }}
                          >
                            <option label="Selecione" />
                            <option value="1" label="Queima em flare" />
                            <option value="2" label="Geração de energia" />
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {climCharSelected && climCharSelected.id && totals && years && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 15,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginTop: 0
                    }}
                  >
                    Emissões totais de resíduos sólidos aterrados (emissões históricas
                    anuais, considerando disposições passadas de resíduos sólidos)
                  </span>
                </div>
              )}

              {changeScopeTotal &&
                climCharSelected &&
                climCharSelected.id &&
                totals &&
                years && (
                  <div
                    style={{
                      overflow: 'auto',
                      position: 'relative',
                      marginTop: 0,
                      width: 'calc(100vw - 320px)',
                      maxWidth: 'calc(100vw - 320px)'
                    }}
                  >
                    <table
                      style={{
                        maxWidth: '100%',
                        width: '4000px',
                        borderCollapse: 'collapse'
                      }}
                      className="table-scope"
                    >
                      <thead>
                        <tr>
                          <th className="head-tr-item" style={{ width: 200 }}></th>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <th className="head-tr-item" style={{ width: 100 }}>
                                  {year}
                                </th>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: 220 }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: 220,
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              Emissões de CH₄ ([tCH₄/ano])
                            </span>
                          </td>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <td style={{ width: 100 }}>
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice5(totals[year].emission_ch4, false)}
                                  </span>
                                </td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                        <tr>
                          <td style={{ width: 220 }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: 220,
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              Emissões de CO₂e ([tCO₂e/ano])
                            </span>
                          </td>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <td style={{ width: 100 }}>
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice5(totals[year].emission_co2, false)}
                                  </span>
                                </td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                        <tr>
                          <td style={{ width: 220 }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: 220,
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              Emissões de CO₂e biogênico ([tCO₂/ano])
                            </span>
                          </td>
                          {years.map((year: any) => {
                            return (
                              <React.Fragment key={year}>
                                <td style={{ width: 100 }}>
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice5(totals[year].emission_co2_bio, false)}
                                  </span>
                                </td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        marginBottom: 10
                      }}
                    >
                      {errors && errors.scope && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            margin: 10
                          }}
                        >
                          <span
                            className="requiredLabel"
                            style={{ fontSize: 14, marginTop: 10 }}
                          >
                            * Todos os campos são obrigatórios
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}

              {changeScopeTotal &&
                climCharSelected &&
                climCharSelected.id &&
                totals &&
                years && (
                  <div
                    style={{
                      borderStyle: 'dashed',
                      margin: 5,
                      borderWidth: '1px',
                      width: '100%',
                      borderColor: '#9E9E9E',
                      height: '40px',
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 0
                    }}
                  >
                    <span style={{ margin: 10, width: '70%' }}>
                      Emissões em CO₂e no ano inventariado [tCO₂e/ano]
                    </span>
                    <span
                      style={{
                        fontWeight: 'bold',
                        margin: 10,
                        textAlign: 'right',
                        width: '30%'
                      }}
                    >
                      {formatPrice3(totalEmissionCo2, false)}
                    </span>
                  </div>
                )}
            </div>
          )}

          {ignoreFill === false && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={handleDownloadFile}
                  onChangeAttachment={handleAttachmentChange}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  )
}
