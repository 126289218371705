import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import Select from 'react-select'
import Parser from 'html-react-parser'
import scopeService from '../../../../../services/scopeService'
import gasService from '../../../../../services/gasService'
import inventoryService from '../../../../../services/inventoryService'
import scope1Service from '../../../../../services/scope1Service'
import equipamentService from '../../../../../services/equipamentService'
import useAuth from '../../../../../hooks/useAuth'
import { Container } from './styles'
import { formatInteger, formatPrice3, formatPrice5 } from '../../../../../utils/transform'
import TrashIcon from '../../../../../assets/icons/trash.svg'
import InfoButtonMin from '../../../../../assets/icons/info-button.svg'
import { GoBack } from '../../../../../components/GoBack'
import { TitleText } from '../../../../../components/TitleText'
import { Button } from '../../../../../components/Button/styles'
import { Modal } from '../../../../../components/Modal'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'
import { Uninformed } from '../../../../../components/Uninformed'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import { toast } from 'react-hot-toast'
import { Loading } from '../../../../../components/Loading'
import { ModalDeleteDataTable } from '../../../../../components/ModalDeleteDataTable'
import HeaderSelect from '../../../../../components/ContentPages/HeaderSelect';
import TableContainerWrap from '../../../../../components/ContentPages/TableContainerWrap';
import exportScopeService from '../../../../../services/exportScopeService'


export const RefrigerationEquipment = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([])
  const [title, setTitle] = useState<string>('')
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false);
  const [guidelines, setGuidelines] = useState<string | undefined>()

  const [openModalInfo, setOpenModalInfo] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openModalAlert, setOpenModalAlert] = useState(false)
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const location = useLocation()

  const [scopeMass, setScopeMass] = useState<any>([])
  const [scopeCycles, setScopeCycles] = useState<any>([])
  const [scopeSorting, setScopeSorting] = useState<any>([])

  const [gases, setGases] = useState<any>([])
  const [equipaments, setEquipaments] = useState<any>([])

  const [changeMass, setChangeMass] = useState<string | undefined>()
  const [changeCycle, setChangeCycle] = useState<string | undefined>()
  const [changeSorting, setChangeSorting] = useState<string | undefined>()

  const [emissionTco2e, setEmissionTco2e] = useState<any>()

  const [emissionTco2eMass, setEmissionTco2eMass] = useState<any>()
  const [emissionTco2eCycle, setEmissionTco2eCycle] = useState<any>()
  const [emissionTco2eSorting, setEmissionTco2eSorting] = useState<any>()
  const [openModalDeleteSorting, setOpenModalDeleteSorting] = useState(false);
  const [openModalDeleteCycle, setOpenModalDeleteCycle] = useState(false);
  const [openModalDeleteMass, setOpenModalDeleteMass] = useState(false);

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-1'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location])

  useEffect(() => {
    calculateSumTotals()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [emissionTco2eCycle])

  useEffect(() => {
    calculateSumTotals()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [emissionTco2eMass])

  useEffect(() => {
    calculateSumTotals()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [emissionTco2eSorting])

  function calculateSumTotals() {
    const co2Mass = emissionTco2eMass ? emissionTco2eMass : 0
    const co2Cycle = emissionTco2eCycle ? emissionTco2eCycle : 0
    const co2Sorting = emissionTco2eSorting ? emissionTco2eSorting : 0

    setEmissionTco2e(co2Mass + co2Cycle + co2Sorting)
  }

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(1, 'emissoes_fugitivas', 'equipamento_refrigeracao')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  function handleOpenInfo() {
    setOpenModalInfo(!openModalInfo)
  }

  function handleOpenAlert() {
    setOpenModalAlert(!openModalAlert)
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors }
  } = useForm<any>()

  useEffect(() => {
    console.log(errors)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [errors])

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {

      if (openModalDeleteCycle) {
        setLoadingModal(false);
        return;
      }
      if (openModalDeleteMass) {
        setLoadingModal(false);
        return;
      }
      if (openModalDeleteSorting) {
        setLoadingModal(false);
        return;
      }

      const cycles: any[] = scopeCycles.map((item: any) => {
        return {
          source: item.source,
          gas_id: item.gas_id,
          new_charge_kg: item.new_charge_kg ?? 0,
          new_capacity_kg: item.new_capacity_kg ?? 0,
          exists_charge_kg: item.exists_charge_kg ?? 0,
          dismiss_capacity_kg: item.dismiss_capacity_kg ?? 0,
          dismiss_recover_kg: item.dismiss_recover_kg ?? 0,
          emission_tco2e: item.emission_tco2e ?? 0, 
        };
      });
      console.log(cycles)
      
      const mass: any[] = scopeMass.map((item: any) => {
        return {
          source: item.source,
          gas_id: item.gas_id,
          capacity_change_kg: item.capacity_change_kg ?? 0,
          stock_variation_kg: item.stock_variation_kg ?? 0,
          transferred_quantity_kg: item.transferred_quantity_kg ?? 0,
          emission_tco2e: item.emission_tco2e ?? 0,
        };
      });
      
      const sorting: any[] = scopeSorting.map((item: any) => {
        return {
          source: item.source,
          gas_id: item.gas_id,
          equipament_id: parseInt(item.equipament_id) || 0, 
          capacity_new_units_kg: item.capacity_new_units_kg ?? 0,
          capacity_units_operation_kg: item.capacity_units_operation_kg ?? 0,
          capacity_units_dispensed_kg: item.capacity_units_dispensed_kg ?? 0,
          emission_tco2e: item.emission_tco2e ?? 0,
        };
      });

      const scope = {
        reason: formData.reason,
        evidence: formData.evidence,
        status: 2,
        ignore_fill: formData.ignore_fill,
        sorting: sorting,
        cycles: cycles,
        mass: mass
      }

      const result = await scope1Service.saveRefrigerationEquipment(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        setErrorApi(undefined)

        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  const { isCommonUser } = useAuth()

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const gasesItens = await gasService.getFugitiveEmissions()

    setGases(
      gasesItens?.itens.map((gas: any) => {
        return {
          label: gas.name,
          value: gas.id
        }
      })
    )

    const equipItens = await equipamentService.getByParams({ limit: 9000, page: 1 })

    setEquipaments(
      equipItens?.itens.map((gas: any) => {
        return {
          label: gas.name,
          value: gas.id
        }
      })
    )

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope1Service.getRefrigerationEquipment(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      1,
      'emissoes_fugitivas',
      'equipamentos_refrigeracao'
    )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)

      setIgnoreFill(entity.ignore_fill)

      setScopeSelected(entity)

      setScopeSortingValues(entity?.sorting)
      calculateSortingTotals(entity?.sorting)

      setScopeCycleValues(entity?.cycles)
      calculateCycleTotals(entity?.cycles)

      setScopeMassValues(entity?.mass)
      calculateMassTotals(entity?.mass)

      setChangeMass('load_scope_' + entity.id + '_scopeMass_' + entity?.mass.length)
      setChangeCycle('load_scope_' + entity.id + '_scopeCycles_' + entity?.cycles.length)
      setChangeSorting(
        'load_scope_' + entity.id + '_scopeSorting_' + entity?.sorting.length
      )
    } else {
      setScopeSelected({})

      setScopeSortingValues([])
      calculateSortingTotals([])

      setScopeCycleValues([])
      calculateCycleTotals([])

      setScopeMassValues([])
      calculateMassTotals([])

      setIgnoreFill(false)

      setChangeMass('load_scope_0_scopeMass_0')
      setChangeCycle('load_scope_0_scopeCycles_0')
      setChangeSorting('load_scope_0_scopeSorting_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{5})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos

    // Verifica se o valor é igual a zero após a formatação e remove os zeros à esquerda
    if (value === '') {
        value = '0';
    }

    return value;
  }

  const handleChangeGasSorting = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await gasService.getById(id)

      if (result) {
        const item = {
          gas_id: result.id,
          gas_name: result.name,
          gas_gwp: result.gwp,
          equipament_id: equipaments[0].value,
          source: '',
          capacity_new_units_kg: 0,
          capacity_units_operation_kg: 0,
          capacity_units_dispensed_kg: 0,
          emission_tco2e: 0,
          gas_description: result.description
        }

        const index = scopeSorting.length

        const itens = scopeSorting.concat(item)

        setChangeSorting('new_scope_item_' + index)

        setScopeSortingValues(itens)
        calculateSortingTotals(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('gases_sorting', [])
  }

  const handleChangeGasCycle = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await gasService.getById(id)

      if (result) {
        const item = {
          gas_id: result.id,
          gas_name: result.name,
          gas_gwp: result.gwp,
          source: "",
          new_charge_kg: 0,
          new_capacity_kg: 0,
          exists_charge_kg: 0,
          dismiss_capacity_kg: 0,
          dismiss_recover_kg: 0,
          emission_tco2e: 0,
          gas_description: result.description,
        };
        

        const index = scopeCycles.length

        const itens = scopeCycles.concat(item)

        setChangeCycle('new_scope_item_' + index)

        setScopeCycleValues(itens)
        calculateCycleTotals(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('gases_cycle', [])
  }

  const handleChangeGasMass = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await gasService.getById(id)

      if (result) {
        const item = {
          gas_id: result.id,
          gas_name: result.name,
          gas_gwp: result.gwp,
          stock_variation_kg: 0,
          capacity_change_kg: 0,
          transferred_quantity_kg: 0,
          emission_tco2e: 0,
          gas_description: result.description
        }

        const index = scopeMass.length

        const itens = scopeMass.concat(item)

        setChangeMass('new_scope_item_' + index)

        setScopeMassValues(itens)
        calculateMassTotals(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('gases_mass', [])
  }

  const setScopeSortingValues = (itens: any, ignore_field?: string) => {
    let changeScope = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`sorting.${i}.source`, item.source)

        setValue(`sorting.${i}.gas_id`, item.gas_id)
        setValue(`sorting.${i}.gas_gwp`, item.gas_gwp)
        setValue(`sorting.${i}.gas_name`, item.gas_name)

        setValue(`sorting.${i}.equipament_id`, item.equipament_id)

        if ('capacity_new_units_kg' !== ignore_field) {
          setValue(`sorting.${i}.capacity_new_units_kg`, formatPrice5(item.capacity_new_units_kg, false))
        }

        if ('capacity_units_operation_kg' !== ignore_field) {
          setValue( `sorting.${i}.capacity_units_operation_kg`, formatPrice5(item.capacity_units_operation_kg, false))
        }

        if ('capacity_units_dispensed_kg' !== ignore_field) {
          setValue(`sorting.${i}.capacity_units_dispensed_kg`, formatPrice5(item.capacity_units_dispensed_kg, false))
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`sorting.${i}.emission_tco2e`, formatPrice5(item.emission_tco2e, false))
        }

        changeScope = changeScope + '_' + item.gas_id + '_' + item.amount
      }
    }

    setScopeSorting(itens)
    setChangeSorting(changeScope)
  }

  const setScopeCycleValues = (itens: any, ignore_field?: string) => {
    let changeScope = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`cycle.${i}.source`, item.source)

        setValue(`cycle.${i}.gas_id`, item.gas_id)
        setValue(`cycle.${i}.gas_gwp`, item.gas_gwp)
        setValue(`cycle.${i}.gas_name`, item.gas_name)

        if ('new_charge_kg' !== ignore_field) {
          setValue(`cycle.${i}.new_charge_kg`, formatPrice5(item.new_charge_kg, false))
        }

        if ('new_capacity_kg' !== ignore_field) {
          setValue(`cycle.${i}.new_capacity_kg`, formatPrice5(item.new_capacity_kg, false))
        }

        if ('exists_charge_kg' !== ignore_field) {
          setValue(`cycle.${i}.exists_charge_kg`, formatPrice5(item.exists_charge_kg, false))
        }

        if ('dismiss_capacity_kg' !== ignore_field) {
          setValue(`cycle.${i}.dismiss_capacity_kg`, formatPrice5(item.dismiss_capacity_kg, false))
        }

        if ('dismiss_recover_kg' !== ignore_field) {
          setValue(`cycle.${i}.dismiss_recover_kg`, formatPrice5(item.dismiss_recover_kg, false))
        }

        if ('dismiss_recover_kg' !== ignore_field) {
          setValue(`cycle.${i}.emission_tco2e`, formatPrice5(item.emission_tco2e, false))
        }

        changeScope = changeScope + '_' + item.gas_id + '_' + item.amount
      }
    }

    setScopeCycles(itens)
    setChangeCycle(changeScope)
  }

  const setScopeMassValues = (itens: any, ignore_field?: string) => {
    let changeMass = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`mass.${i}.source`, item.source)

        setValue(`mass.${i}.gas_id`, item.gas_id)
        setValue(`mass.${i}.gas_gwp`, item.gas_gwp)
        setValue(`mass.${i}.gas_name`, item.gas_name)

        if ('capacity_change_kg' !== ignore_field) {
          setValue(`mass.${i}.capacity_change_kg`, formatPrice5(item.capacity_change_kg, false))
        }

        if ('stock_variation_kg' !== ignore_field) {
          setValue(`mass.${i}.stock_variation_kg`, formatPrice5(item.stock_variation_kg, false))
        }

        if ('transferred_quantity_kg' !== ignore_field) {
          setValue(`mass.${i}.transferred_quantity_kg`, formatPrice5(item.transferred_quantity_kg, false))
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`mass.${i}.emission_tco2e`, formatPrice5(item.emission_tco2e, false))
        }

        changeMass = changeMass + '_' + item.gas_id + '_' + item.amount
      }
    }

    setScopeMass(itens)
    setChangeMass(changeMass)
  }

  function handleDeleteSorting(index: number) {
    const itens = []

    for (let i = 0; i < scopeSorting?.length; i++) {
      if (i !== index) {
        itens.push(scopeSorting[i])
      }
    }

    setChangeSorting('remove_scope_item_' + index)

    setValue('gases_sorting', [])

    setScopeSortingValues(itens)
    calculateSortingTotals(itens)
  }

  function handleOpenModalDeleteSorting() {
    setOpenModalDeleteSorting(true); 
  }

  function handleDeleteScopeAllSorting() {
    const itens: any = [];

    setChangeSorting('remove_all_scope_items');
    setValue('gases_sorting', []);
    setScopeSortingValues(itens);
    calculateSortingTotals(itens);

    setOpenModalDeleteSorting(false);
  }

  function handleDeleteCycle(index: number) {
    const itens = []

    for (let i = 0; i < scopeCycles?.length; i++) {
      if (i !== index) {
        itens.push(scopeCycles[i])
      }
    }

    setChangeCycle('remove_scope_item_' + index)

    setValue('gases_cycle', [])

    setScopeCycleValues(itens)
    calculateCycleTotals(itens)
  }

  function handleOpenModalDeleteCycle() {
    setOpenModalDeleteCycle(true); 
  }

  function handleDeleteScopeAllCycle (){
    const itens: any =[];

    setChangeCycle('remove_all_scope_items');
    setValue('gases_cycle', []);
    setScopeCycleValues(itens);
    calculateCycleTotals(itens);

    setOpenModalDeleteCycle(false);
  }

  function handleDeleteMass(index: number) {
    const itens = []

    for (let i = 0; i < scopeMass?.length; i++) {
      if (i !== index) {
        itens.push(scopeMass[i])
      }
    }

    setChangeMass('remove_scope_item_' + index)

    setValue('gases_mass', [])

    setScopeMassValues(itens)
    calculateMassTotals(itens)
  }

  function handleOpenModalDeleteMass() {
    setOpenModalDeleteMass(true); 
  }

  function handleDeleteScopeAllMass (){
    const itens: any =[];

    setChangeMass('remove_all_scope_items');
    setValue('gases_cycle', []);
    setScopeMassValues(itens);
    calculateMassTotals(itens);

    setOpenModalDeleteMass(false);
  }

  const calculateSortingTotals = (itens: any) => {
    let emissionTco2e = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_tco2e) {
          emissionTco2e = emissionTco2e + item.emission_tco2e
        }
      }
    }

    setEmissionTco2eSorting(emissionTco2e)
  }

  const calculateCycleTotals = (itens: any) => {
    let emissionTco2e = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_tco2e) {
          emissionTco2e = emissionTco2e + item.emission_tco2e
        }
      }
    }

    setEmissionTco2eCycle(emissionTco2e)
  }

  const calculateMassTotals = (itens: any) => {
    let emissionTco2e = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_tco2e) {
          emissionTco2e = emissionTco2e + item.emission_tco2e
        }
      }
    }

    setEmissionTco2eMass(emissionTco2e)
  }

  const handleChangeValueSorting = (index: number, ignore_field?: string) => {
    const gasId = scopeSorting[index].gas_id
    const equipamentId = parseInt(scopeSorting[index].equipament_id)
    const capacityNewUnitsKg = scopeSorting[index].capacity_new_units_kg
    const capacityUnitsOperationKg = scopeSorting[index].capacity_units_operation_kg
    const capacityUnitsDispensedKg = scopeSorting[index].capacity_units_dispensed_kg

    async function calculate() {
      const result = await scope1Service.calculateSortingFugitiveEmissions(
        gasId,
        equipamentId,
        capacityNewUnitsKg,
        capacityUnitsOperationKg,
        capacityUnitsDispensedKg
      )

      if (result) {
        scopeSorting[index].emission_tco2e = result.emission_tco2e

        setChangeSorting(
          'change_scope_item_' +
          index +
          '_' +
          equipamentId +
          '_' +
          capacityNewUnitsKg +
          '_' +
          capacityUnitsOperationKg +
          '_' +
          capacityUnitsDispensedKg
        )

        setScopeSortingValues(scopeSorting, ignore_field)
        calculateSortingTotals(scopeSorting)
      }
    }

    calculate()
  }

  const handleChangeValueCycle = (index: number, ignore_field?: string) => {
    const gasId = scopeCycles[index].gas_id
    const newChargeKg = scopeCycles[index].new_charge_kg
    const newCapacityKg = scopeCycles[index].new_capacity_kg
    const existsChargeKg = scopeCycles[index].exists_charge_kg
    const dismissCapacityKg = scopeCycles[index].dismiss_capacity_kg
    const dismissRecoverKg = scopeCycles[index].dismiss_recover_kg

    async function calculate() {
      const result = await scope1Service.calculateCyclesFugitiveEmissions(
        gasId,
        newChargeKg,
        newCapacityKg,
        existsChargeKg,
        dismissCapacityKg,
        dismissRecoverKg
      )

      if (result) {
        scopeCycles[index].emission_tco2e = result.emission_tco2e

        setChangeCycle(
          'change_scope_item_' +
          index +
          '_' +
          newChargeKg +
          '_' +
          newCapacityKg +
          '_' +
          existsChargeKg +
          '_' +
          dismissCapacityKg +
          '_' +
          dismissRecoverKg
        )

        setScopeCycleValues(scopeCycles, ignore_field)
        calculateCycleTotals(scopeCycles)
      }
    }

    calculate()
  }

  const handleChangeValueMass = (index: number, ignore_field?: string) => {
    const gasId = scopeMass[index].gas_id
    const stockVariationKg = scopeMass[index].stock_variation_kg
    const capacityChangeKg = scopeMass[index].capacity_change_kg
    const transferredQuantityKg = scopeMass[index].transferred_quantity_kg

    async function calculate() {
      const result = await scope1Service.calculateMassFugitiveEmissions(
        gasId,
        stockVariationKg,
        capacityChangeKg,
        transferredQuantityKg
      )

      if (result) {
        scopeMass[index].emission_tco2e = result.emission_tco2e

        setChangeMass(
          'change_scope_item_' +
          index +
          '_' +
          stockVariationKg +
          '_' +
          capacityChangeKg +
          '_' +
          transferredQuantityKg
        )

        setScopeMassValues(scopeMass, ignore_field)
        calculateMassTotals(scopeMass)
      }
    }

    calculate()
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'emissoes_fugitivas',
          'equipamentos_refrigeracao'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const handleDownloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const handleAttachmentChange = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 1,
        name: 'emissoes_fugitivas',
        file: base64,
        filename: filename,
        second_name: 'equipamentos_refrigeracao'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'emissoes_fugitivas',
          'equipamentos_refrigeracao'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }


  const handleExport = async (model: string) => {
    const item: any = location?.state;
    setLoadingModal(true);
    const response = await exportScopeService.handleExport(
      item.company_id,
      item.year,
      model
    );
    
    if(response){
      toast.success("Dados exportados com sucesso!")
    }

    setLoadingModal(false);
  };

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} className="column">
        <div
          className="row"
          style={{
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div className="column">
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 01 - ' + title}  />
              </div>
            )}
          </div>
          <div className="row">
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}

            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />
        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill && (
          <div className="column" style={{ margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 230px)',
            maxHeight: 'calc(100vh - 230px)'
          }}
        >
          {!ignoreFill && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >

              <HeaderSelect 
                description="Opção 1 - Abordagem por estágio do ciclo de vida."
                hasImport={false}
                isCommonUser={isCommonUser}
                options={gases}
                control={control}
                handleChangeValue={handleChangeGasCycle}
                name="gases_cycle"
                defaultValue={getValues('gases_cycle')}
                hasExport={{
                  active: false,
                  handleExport: () => handleExport("FugitiveEmissionsCycle"),
                }}
              />
              
              {/* Table 1 */}
              <TableContainerWrap hasContent={changeCycle && !!scopeCycles.length ? true : false} count={scopeCycles.length}>
                {changeCycle && !!scopeCycles.length && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Registro da fonte'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Gás ou composto'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'GWP'}
                        </th>
                        <th className="head-tr-item" style={{ width: 450 }} colSpan={2}>
                          {'Unidades novas'}
                        </th>
                        <th className="head-tr-item" style={{ width: 100 }}>
                          {'Unidades existentes'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={2}>
                          {'Unidades dispensadas'}
                        </th>
                        <th className="head-tr-item" style={{ width: 180 }} rowSpan={2}>
                          {'E = Emissões de CO₂e (t)'}
                        </th>
                        {!isCommonUser && (
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          >
                          {!isCommonUser && (
                            <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                              <div onClick={handleOpenModalDeleteCycle}>
                                <button style={{background: '#b81414', color: 'white', borderRadius: 5, cursor:'pointer'}}>Apagar tudo</button>
                              </div>
                            </td>
                          )}
                          </th>
                        )}
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 120 }}>
                          {'Carga (kg)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 120 }}>
                          {'Capacidade (kg)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 120 }}>
                          {'Recarga (kg)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 120 }}>
                          {'Capacidade (kg)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 120 }}>
                          {'Recuperada (kg)'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {scopeCycles.map((cycle: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`cycle.${index}.source`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`cycle.${index}.source`, event.target.value)
                                    scopeCycles[index].source = event.target.value
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <div className="row">
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      width: 200,
                                      fontSize: 12,
                                      fontWeight: 'bold',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {cycle.gas_name}
                                  </span>

                                  {cycle.gas_description && (
                                    <div className="tooltip">
                                      <img alt="infoButton" src={InfoButtonMin} />
                                      <span className="tooltiptext">
                                        {cycle.gas_description}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatInteger(cycle.gas_gwp)}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`cycle.${index}.new_charge_kg`)}
                                  onBlur={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    if (scopeCycles[index].new_capacity_kg && scopeCycles[index].new_capacity_kg > value) {
                                      scopeCycles[index].new_capacity_kg = value

                                      setValue(
                                        `cycle.${index}.new_capacity_kg`,
                                        formatPrice5(value, false)
                                      )

                                      handleChangeValueCycle(index, 'new_charge_kg')
                                    }
                                  }}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeCycles[index].new_charge_kg = value

                                    setValue(`cycle.${index}.new_charge_kg`, formatValue)

                                    handleChangeValueCycle(index, 'new_charge_kg')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`cycle.${index}.new_capacity_kg`)}
                                  onBlur={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    if (scopeCycles[index].new_charge_kg && scopeCycles[index].new_charge_kg < value) {
                                      const otherValue = scopeCycles[index].new_charge_kg
                                      scopeCycles[index].new_capacity_kg = otherValue

                                      setValue(`cycle.${index}.new_capacity_kg`, formatPrice5(otherValue, false))

                                      setOpenModalAlert(true)
                                    }

                                    handleChangeValueCycle(index, 'new_capacity_kg')
                                  }}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeCycles[index].new_capacity_kg = value

                                    setValue(
                                      `cycle.${index}.new_capacity_kg`,
                                      formatValue
                                    )

                                    handleChangeValueCycle(index, 'new_capacity_kg')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`cycle.${index}.exists_charge_kg`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeCycles[index].exists_charge_kg = value

                                    setValue(
                                      `cycle.${index}.exists_charge_kg`,
                                      formatValue
                                    )

                                    handleChangeValueCycle(index, 'exists_charge_kg')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`cycle.${index}.dismiss_capacity_kg`)}
                                  onBlur={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    if (
                                      scopeCycles[index].dismiss_recover_kg &&
                                      scopeCycles[index].dismiss_recover_kg > value
                                    ) {
                                      scopeCycles[index].dismiss_recover_kg = value

                                      setValue(
                                        `cycle.${index}.dismiss_recover_kg`,
                                        formatPrice5(value, false)
                                      )

                                      handleChangeValueCycle(index, 'dismiss_capacity_kg')
                                    }
                                  }}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeCycles[index].dismiss_capacity_kg = value

                                    setValue(
                                      `cycle.${index}.dismiss_capacity_kg`,
                                      formatValue
                                    )

                                    handleChangeValueCycle(index, 'dismiss_capacity_kg')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`cycle.${index}.dismiss_recover_kg`)}
                                  onBlur={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    if (
                                      scopeCycles[index].dismiss_capacity_kg &&
                                      scopeCycles[index].dismiss_capacity_kg < value
                                    ) {
                                      const otherValue =
                                        scopeCycles[index].dismiss_capacity_kg
                                      scopeCycles[index].dismiss_recover_kg = otherValue

                                      setValue(
                                        `cycle.${index}.dismiss_recover_kg`,
                                        formatPrice5(otherValue, false)
                                      )

                                      handleChangeValueCycle(index, 'dismiss_recover_kg')
                                    }
                                  }}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeCycles[index].dismiss_recover_kg = value

                                    setValue(
                                      `cycle.${index}.dismiss_recover_kg`,
                                      formatValue
                                    )

                                    handleChangeValueCycle(index, 'dismiss_recover_kg')
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 180,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(cycle.emission_tco2e, false)}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }}>
                                  <div
                                    onClick={() => handleDeleteCycle(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img alt="deleteIcon" src={TrashIcon} />
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={8}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 200,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionTco2eCycle, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td colSpan={5} className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {!scopeCycles.length && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum gás / composto cadastrado`
                        : `Nenhum gás / composto foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}

                <div
                  className="row"
                  style={{
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.cycle && (
                    <div
                      className="row"
                      style={{
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Registro da fonte é obrigatório
                      </span>
                    </div>
                  )}
                </div>
              </TableContainerWrap>

              <div
                className="row"
                style={{
                  marginTop: 10,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  className="column"
                  style={{
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 14,
                      marginTop: 5,
                      fontWeight: 'bold'
                    }}
                  >
                    Opção 2 - Abordagem por Balanço de Massa (Compra).
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, }} >
                    Selecione, o gás/composto ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && gases && (
                  <Controller
                    control={control}
                    name="gases_mass"
                    render={({ field }) => (
                      <Select placeholder="[Selecione]"
                        {...field}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            width: '350px',
                            height: 40,
                            marginTop: 5,
                            marginRight: 10,
                            backgroundColor: 'rgb(246, 246, 246)'
                          }),
                          option: (styles) => {
                            return { ...styles, color: '#31363F' }
                          }
                        }}
                        defaultValue={getValues('gases_mass')}
                        options={gases}
                        onChange={handleChangeGasMass}
                      />
                    )}
                  />
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeMass && !!scopeMass.length && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }}>
                          {'Registro da fonte'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Gás ou composto'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }}>
                          {'GWP'}
                        </th>
                        <th className="head-tr-item" style={{ width: 220 }}>
                          {'VE = Variação no Estoque (kg)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 220 }}>
                          {'T = Quantidade Transferida (kg)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 220 }}>
                          {'MC = Mudança de Capacidade (kg)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 180 }}>
                          {'E = Emissões de CO₂e (t)'}
                        </th>
                        {!isCommonUser && (
                          <th className="head-tr-item" style={{ width: 50 }} >
                            {!isCommonUser && (
                              <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                                <div onClick={handleOpenModalDeleteMass}>
                                  <button style={{background: '#b81414', color: 'white', borderRadius: 5, cursor:'pointer'}}>Apagar tudo</button>
                                </div>
                              </td>
                            )}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {scopeMass.map((mass: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`mass.${index}.source`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`mass.${index}.source`, event.target.value)
                                    scopeMass[index].source = event.target.value
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <div className="row">
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      width: 200,
                                      fontSize: 12,
                                      fontWeight: 'bold',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {mass.gas_name}
                                  </span>

                                  {mass.gas_description && (
                                    <div className="tooltip">
                                      <img src={InfoButtonMin} alt="information"/>
                                      <span className="tooltiptext">
                                        {mass.gas_description}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatInteger(mass.gas_gwp)}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 230, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`mass.${index}.stock_variation_kg`)}
                                  onBlur={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeMass[index].stock_variation_kg = value

                                    setValue(
                                      `mass.${index}.stock_variation_kg`,
                                      formatValue
                                    )

                                    handleChangeValueMass(index, 'stock_variation_kg')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 230, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`mass.${index}.transferred_quantity_kg`)}
                                  onBlur={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeMass[index].transferred_quantity_kg = value

                                    setValue(
                                      `mass.${index}.transferred_quantity_kg`,
                                      formatValue
                                    )

                                    handleChangeValueMass(
                                      index,
                                      'transferred_quantity_kg'
                                    )
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 230, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`mass.${index}.capacity_change_kg`)}
                                  onBlur={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeMass[index].capacity_change_kg = value

                                    setValue(
                                      `mass.${index}.capacity_change_kg`,
                                      formatValue
                                    )

                                    handleChangeValueMass(index, 'capacity_change_kg')
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 180,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(mass.emission_tco2e, false)}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }}>
                                  <div
                                    onClick={() => handleDeleteMass(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img alt="deleteIcon" src={TrashIcon} />
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={6}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 200,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionTco2eMass, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td colSpan={5} className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {!scopeMass.length && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum combustível cadastrado`
                        : `Nenhum combustível foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}

                <div
                  className="row"
                  style={{
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.mass && (
                    <div
                      className="row"
                      style={{
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Registro da fonte é obrigatório
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div
                className="row"
                style={{
                  marginTop: 10,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  className="column"
                  style={{
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 14,
                      marginTop: 5,
                      fontWeight: 'bold'
                    }}
                  >
                    Opção 3 - Triagem.
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, }} >
                    Selecione, o gás/composto ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && gases && (
                  <Controller
                    control={control}
                    name="gases_sorting"
                    render={({ field }) => (
                      <Select placeholder="[Selecione]"
                        {...field}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            width: '350px',
                            height: 40,
                            marginTop: 5,
                            marginRight: 10,
                            backgroundColor: 'rgb(246, 246, 246)'
                          }),
                          option: (styles) => {
                            return { ...styles, color: '#31363F' }
                          }
                        }}
                        defaultValue={getValues('gases_sorting')}
                        options={gases}
                        onChange={handleChangeGasSorting}
                      />
                    )}
                  />
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeSorting && scopeSorting && scopeSorting.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Registro da fonte'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Tipo de equipamento'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Gás ou composto'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'GWP'}
                        </th>
                        <th className="head-tr-item" style={{ width: 220 }} rowSpan={2}>
                          {'Carga das unidades novas (kg)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 220 }} colSpan={2}>
                          {'Capacidade'}
                        </th>
                        <th className="head-tr-item" style={{ width: 180 }} rowSpan={2}>
                          {'Emissões de CO₂e (t)'}
                        </th>
                        {!isCommonUser && (
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          >
                          {!isCommonUser && (
                            <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                              <div onClick={handleOpenModalDeleteSorting}>
                                <button style={{background: '#b81414', color: 'white', borderRadius: 5, cursor:'pointer'}}>Apagar tudo</button>
                              </div>
                            </td>
                          )}
                          </th>
                        )}
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 220 }}>
                          {'Unidades de operação (kg)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 220 }}>
                          {'Unidades dispensadas (kg)'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {scopeSorting.map((sort: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`sorting.${index}.source`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `sorting.${index}.source`,
                                      event.target.value
                                    )
                                    scopeSorting[index].source = event.target.value
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <select
                                  disabled={isCommonUser}
                                  style={{
                                    background: '#F6F6F6',
                                    border: '1px solid #DFDFDF',
                                    backgroundColor: '#ffffff',
                                    borderRadius: ' 2.5px',
                                    width: '232px',
                                    height: '40px',
                                    marginLeft: 10
                                  }}
                                  {...register(`sorting.${index}.equipament_id`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    setValue(`sorting.${index}.equipament_id`, value)
                                    scopeSorting[index].equipament_id = value

                                    handleChangeValueSorting(index, 'equipament_id')
                                  }}
                                >
                                  {equipaments &&
                                    equipaments.map((item: any) => {
                                      return (
                                        <option value={item.value} key={item.value}>
                                          {item.label}
                                        </option>
                                      )
                                    })}
                                </select>
                              </td>
                              <td style={{ width: 250 }}>
                                <div className="row">
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      width: 200,
                                      fontSize: 12,
                                      fontWeight: 'bold',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {sort.gas_name}
                                  </span>

                                  {sort.gas_description && (
                                    <div className="tooltip">
                                      <img alt="infoButton" src={InfoButtonMin} />
                                      <span className="tooltiptext">
                                        {sort.gas_description}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatInteger(sort.gas_gwp)}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 230, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`sorting.${index}.capacity_new_units_kg`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeSorting[index].capacity_new_units_kg = value

                                    setValue(
                                      `sorting.${index}.capacity_new_units_kg`,
                                      formatValue
                                    )

                                    handleChangeValueSorting(
                                      index,
                                      'capacity_new_units_kg'
                                    )
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 230, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(
                                    `sorting.${index}.capacity_units_operation_kg`
                                  )}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeSorting[index].capacity_units_operation_kg =
                                      value

                                    setValue(
                                      `sorting.${index}.capacity_units_operation_kg`,
                                      formatValue
                                    )

                                    handleChangeValueSorting(
                                      index,
                                      'capacity_units_operation_kg'
                                    )
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 230, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(
                                    `sorting.${index}.capacity_units_dispensed_kg`
                                  )}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeSorting[index].capacity_units_dispensed_kg =
                                      value

                                    setValue(
                                      `sorting.${index}.capacity_units_dispensed_kg`,
                                      formatValue
                                    )

                                    handleChangeValueSorting(
                                      index,
                                      'capacity_units_dispensed_kg'
                                    )
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 180,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(sort.emission_tco2e, false)}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }}>
                                  <div
                                    onClick={() => handleDeleteSorting(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img alt="deleteIcon" src={TrashIcon} />
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={7}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 200,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionTco2eSorting, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td colSpan={5} className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {!scopeSorting.length && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum combustível cadastrado`
                        : `Nenhum combustível foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}

                <div
                  className="row"
                  style={{
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.sorting && (
                    <div
                      className="row"
                      style={{
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Registro da fonte é obrigatório
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {!ignoreFill && (
            <div style={{
              borderStyle: "dashed", margin: 5, borderWidth: "1px",
              width: "calc(100vw - 330px)", maxWidth: "calc(100vw - 330px)",
              borderColor: "#9E9E9E", height: "auto", display: "flex",
              flexDirection: "column", marginLeft: 15
            }} >
              <div style={{ width: "100%", display: "flex", flexDirection: "row" }} >
                <span style={{ margin: 10, width: "70%" }}>
                  Emissões totais em CO₂ equivalente (toneladas métricas):
                </span>
                <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                  {formatPrice3(emissionTco2e, false)}
                </span>
              </div>
              <div style={{
                display: "flex", flexDirection: "row", color: "#31363F",
                fontSize: 12, fontWeight: "bold", marginLeft: 10
              }} >
                Atenção: para os cálculos aqui apresentados não existe a emissão de CO₂ biogênico associado.
              </div>
            </div>
          )}

          {!ignoreFill && (
            <div className="column" style={{ margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                className="column"
                style={{
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div className="column" style={{ margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={handleDownloadFile}
                  onChangeAttachment={handleAttachmentChange}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModalInfo}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div className="column">
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      <Modal isOpen={openModalAlert} isPayOrTypeClient={true} handleModalVisible={handleOpenAlert} >
        <div style={{ margin: 5, textAlign: "center" }}>
          <TitleText level={2} title="ATENÇÃO" color="red" />
        </div>

        <div style={{ marginTop: 10, width: 450 }}>
          <div style={{ margin: 5, textAlign: "center" }}>
            <span style={{ fontSize: 12 }}>A carga injetada em unidades novas deve ser igual ou maior que a sua capacidade, para estimar as emissões do processo de regarga. Unidades pré-carregadas pelo fabricante (já compradas com carga), não devem ser inclusas aqui</span>
          </div>
        </div>

        <div style={{ margin: 5, display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <Button color='black' type="button" size="md"
            onClick={handleOpenAlert}
            style={{ margin: 10, width: '285px', }}>
            OK
          </Button>
        </div>
      </Modal >

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}

      {openModalDeleteCycle && (
        <ModalDeleteDataTable
          isOpen={openModalDeleteCycle}
          handleModalVisible={() => setOpenModalDeleteCycle(false)}
          handleOk={handleDeleteScopeAllCycle}
        />
      )}

      {openModalDeleteMass && (
        <ModalDeleteDataTable
          isOpen={openModalDeleteMass}
          handleModalVisible={() => setOpenModalDeleteMass(false)}
          handleOk={handleDeleteScopeAllMass}
        />
      )}

      {openModalDeleteSorting && (
        <ModalDeleteDataTable
          isOpen={openModalDeleteSorting}
          handleModalVisible={() => setOpenModalDeleteSorting(false)}
          handleOk={handleDeleteScopeAllSorting}
        />
      )}
    </Container>
  )
}
