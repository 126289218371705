import { api } from "./api";

async function getDifferenceDays(params: any) {
  try {
    const { data } = await api.get("/utils/difference_days", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getDifferenceDaysMonth(params: any) {
  try {
    const { data } = await api.get("/utils/difference_days/by_month", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

const utilsService = {
  getDifferenceDays,
  getDifferenceDaysMonth
};

export default utilsService;
