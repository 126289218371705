import { Page, Text, View, StyleSheet, Image as ImagePDF} from "@react-pdf/renderer";
import React from "react";
import { Header } from "../../components/Header";
import { ReportData } from "../../../interfaces/ReportData";
import { Logos } from "../../components/Logos";
import { styles } from "../../styles";
import { IGEEProps } from "../../../interfaces/IGEEData";
import LogoVerdeEscuro from "../../../../../../../assets/images/versoes-logo-icone-ingee-03.png";

export const Page15: React.FC<IGEEProps> = (props) => {
  const { resume } = props;

  const getValue = (value: any) => (value === null || value === undefined || value === 0 ? "-" : value);

  const formatNumber = (value: any) => {
    if (value === "-") return "-";
    return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 4 }).format(value);
  };

  const scope1 = getValue(resume?.resumeIgee.emissions_by_category.scope1.totals?.emission_tco2e);
  const scope2 = getValue(resume?.resumeIgee.emissions_by_category.scope2.location.totals?.emission_tco2e);
  const scope3 = getValue(resume?.igee_itens.scope3_total.emission_tco2e);

  const total_scopes = [scope1, scope2, scope3].map(getValue).reduce((a, b) => (a !== "-" && b !== "-" ? a + b : "-"), 0);
  
  const totalScopesTotal = total_scopes !== "-" ? total_scopes.toFixed(4) : "-";

  const number_employees = getValue(resume?.intensity_itens.number_of_employees.hited);
  const building_area = getValue(resume?.intensity_itens.building_area.hited);
  const invoicing = getValue(resume?.intensity_itens.invoicing.hited);
  const number_of_products = getValue(resume?.intensity_itens.number_of_products.hited);
  const number_of_projects = getValue(resume?.intensity_itens.number_of_projects.hited);

  const divide = (total: any, divisor: any) => (total !== "-" && divisor !== "-" ? (total / divisor).toFixed(4) : "-");

  const result_employees = divide(totalScopesTotal, number_employees);
  const result_projects = divide(totalScopesTotal, number_of_projects);
  const result_products = divide(totalScopesTotal, number_of_products);
  const result_area = divide(totalScopesTotal, building_area);
  const result_invoincing = divide(totalScopesTotal, invoicing);

  const CarbonicTable = [
    {
      left: result_employees !== "-" ? formatNumber(result_employees) : "-",
      right: "Por funcionários",
    },
    {
      left: result_projects !== "-" ? formatNumber(result_projects) : "-",
      right: "Por projetos",
    },
    {
      left: result_products !== "-" ? formatNumber(result_products) : "-",
      right: "Por produtos",
    },
    {
      left: result_area !== "-" ? formatNumber(result_area) : "-",
      right: "Por m² de área construída",
    },
    {
      left: result_invoincing !== "-" ? formatNumber(result_invoincing) : "-",
      right: "Por faturamento",
    },
  ];

  const ReductionTable = [
    {
      year: getValue(resume?.intensity_itens?.number_of_employees.year),
      left: getValue(resume?.intensity_itens?.number_of_employees.reduction_goal) !== "-" ? formatNumber(getValue(resume?.intensity_itens?.number_of_employees.reduction_goal).toFixed(4)) : "-",
      right: "Funcionários",
    },
    {
      year: getValue(resume?.intensity_itens?.number_of_projects.year),
      left: getValue(resume?.intensity_itens?.number_of_projects.reduction_goal) !== "-" ? formatNumber(getValue(resume?.intensity_itens?.number_of_projects.reduction_goal).toFixed(4)) : "-",
      right: "Projetos",
    },
    {
      year: getValue(resume?.intensity_itens?.number_of_products.year),
      left: getValue(resume?.intensity_itens?.number_of_products.reduction_goal) !== "-" ? formatNumber(getValue(resume?.intensity_itens?.number_of_products.reduction_goal).toFixed(4)) : "-",
      right: "Produtos",
    },
    {
      year: getValue(resume?.intensity_itens?.building_area.year),
      left: getValue(resume?.intensity_itens?.building_area.reduction_goal) !== "-" ? formatNumber(getValue(resume?.intensity_itens?.building_area.reduction_goal).toFixed(4)) : "-",
      right: "m² de área construída",
    },
    {
      year: getValue(resume?.intensity_itens?.invoicing.year),
      left: getValue(resume?.intensity_itens?.invoicing.reduction_goal) !== "-" ? formatNumber(getValue(resume?.intensity_itens?.invoicing.reduction_goal).toFixed(4)) : "-",
      right: "Faturamento",
    },
  ];

  return (
    <Page
      size={[640, 480]}
      style={{ ...styles.Page, backgroundColor: "#ecf3f4" }}
    >
      <Header backColor="#81c787" title="7. Metas" />
      <View style={local.body}>
        <Text style={{ ...local.title, ...styles.boldItalic, marginLeft: 6 }}>
          Meta de redução de emissão por intensidade
        </Text>
        <View style={local.tables}>
          <View style={{ ...local.table, borderRight: "1.5px solid #81c787" }}>
            <View style={local.subTitle}>
              <Text style={{ ...styles.light, marginTop: -5, marginBottom: 15, marginLeft: 20}}>
                Intensidade carbônica no ano inventariado {"\n"}
                (Emissões (tCO<Text style={{ fontSize: 5 }}>2</Text>e))
              </Text>
              <View
                  style={{
                    position: "absolute",
                    bottom: "10%",
                    width: "200%",
                    height: 1,
                    backgroundColor: "#81c787",
                    borderTop: "1px solid #81c787",
                    marginTop: 10,
                    left: -15
                  }}
                />
            </View>
            {/* Linha Horizontal */}
            {CarbonicTable.map((d, i) => (
              <View style={local.item} key={i}>
                <View style={local.row}>
                  <View style={local.cellLeft}>
                    <Text
                      style={{
                        ...local.number,
                        ...styles.Bold,
                        ...styles.italic
                      }}
                    >
                      {d.left}
                    </Text>
                  </View>
                  <View style={{...local.cellRight}}>
                    <Text style={{ ...local.text, ...styles.Bold }}>
                      {d.right}
                    </Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
          <View style={local.table}>
            <View style={local.subTitle}>
              <Text style={{ ...styles.light, marginTop: -5, marginBottom: 15, marginLeft: 20}}>
                  Meta de redução de emissão por intensidade {"\n"}
                (Emissões (tCO<Text style={{ fontSize: 5 }}>2</Text>e))
              </Text>
            </View>
            {ReductionTable.map((d, i) => (
              <View style={local.item} key={i}>
                <View style={local.row}>
                  <View style={local.cellLeft2}>
                    <Text
                      style={{
                        ...local.number,
                        ...styles.Bold,
                        ...styles.italic,
                        color: "#81c787",

                      }}
                    >
                      {d.year} - {d.left}
                    </Text>
                  </View>
                  <View style={{...local.cellRight2}}>
                    <Text style={{ ...local.text, ...styles.Bold, color: "#81c787"}}>
                      {d.right}
                    </Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
          <View
            style={{
              position: "absolute",
              top: "116%",
              width: 20,
              height: 20,
              right: '56.1%',
              borderRadius: "50%",
              backgroundColor: "#81c787",
              transform: "translate(50%, -50%)",
            }}
          />
        </View>
        <ImagePDF 
              src={resume?.company_itens.logo_image_url}
                style={{
                  width: 50,
                  right: '85px',
                  position: 'absolute',
                  bottom: '14px'
                }}
            />
            <ImagePDF src={LogoVerdeEscuro} 
              style={{
                width: 90,
                right: '0px',
                bottom: '14px',
                position: 'absolute'
              }}
            />
      </View>
    </Page>
  );
};

const local = StyleSheet.create({
  body: {
    display: "flex",
    width: "100%",
    height: "90%",
    alignItems: "center",
    alignContent: "center",
    padding: "20px",
  },
  title: {
    color: "#a1a4a6",
    fontSize: 18,
    padding: "5px",
    alignSelf: "flex-start",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    padding: "20px",
  },
  tables: {
    display: "flex",
    width: "110%",
    flexDirection: "row",
    border: "1.5px solid #76B987",
    marginVertical: "20px",
  },
  subTitle: {
    color: "#b5b5b9",
    fontSize: 10,
    textAlign: "left",
    width: "130%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginBottom: 10,
  },
  item: {
    padding: "4px",
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: "12px",
  },
  number: {
    color: "#2a70b8",
    fontSize: 14,
  },
  text: {
    color: "#2a70b8",
    fontSize: 12,
    textAlign: "left",
    width: "100%",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%"
  },
  cellLeft: {
    flex: 1,
    textAlign: "left",
    marginLeft: 10
  },
  cellRight: {
    flex: 1,
    textAlign: "left",
    marginLeft: -65,
  },
  cellLeft2: {
    flex: 1,
    textAlign: "left",
    paddingRight: -10,
    marginLeft: -5
  },
  cellRight2: {
    flex: 1,
    textAlign: "left",
    marginRight: -5
  },
});
