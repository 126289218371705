import React from 'react';
import { ModalOverlay, ModalContainer, ModalHeader, ModalBody, ModalFooter, CloseButton, Contaiers, PreviewData } from './styles';
import { Button } from "../../styles"

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    item: any; // Aqui você pode passar qualquer estrutura de dados que deseja exibir no modal
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, item }) => {
    if (!isOpen) return null;

    return (
        <ModalOverlay>
            <ModalContainer>
                <ModalHeader>
                    <h2>Detalhes do Item</h2>
                    <CloseButton onClick={onClose}>X</CloseButton>
                </ModalHeader>
                <ModalBody>
                    <Contaiers>
                        <p><strong>ID:</strong> {item.id}</p>
                        <p><strong>Data:</strong> {item.created_at}</p>
                        <p><strong>Status:</strong> {item.status}</p>
                        <p><strong>Empresa:</strong> {item.company_name ? item.company_name : item.company_id}</p>
                        <p><strong>Usuário:</strong> {item.user_name ? item.user_name : item.user_id}</p>
                    </Contaiers>
                    <Contaiers>
                        <p><strong>Arquivo:</strong> {item.file_path}</p>
                        <p><strong>Erro:</strong> {item.error_log}</p>
                    </Contaiers>
                    {item.error_log ? (
                        <PreviewData className='custom-scrollbar '>
                            <p>{JSON.stringify(item.error_log, null, 2)}</p>
                        </PreviewData>
                    ):(
                        <PreviewData>Nenhum item encontrado...</PreviewData>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Fechar</Button>
                </ModalFooter>
            </ModalContainer>
        </ModalOverlay>
    );
};

export default Modal;
