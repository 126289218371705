import Parser from 'html-react-parser'
import Select from 'react-select'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import useAuth from '../../../../hooks/useAuth'
import { Container } from './styles'
import { formatPrice3,formatPrice5 } from '../../../../utils/transform'
import inventoryService from '../../../../services/inventoryService'
import fuelService from '../../../../services/fuelService'
import energyTypeService from '../../../../services/energyTypeService'
import scope2Service from '../../../../services/scope2Service'
import scopeService from '../../../../services/scopeService'
import TrashIcon from '../../../../assets/icons/trash.svg'
import InfoButtonMin from '../../../../assets/icons/info-sign.png';
import { GoBack } from '../../../../components/GoBack'
import { TitleText } from '../../../../components/TitleText'
import { Button } from '../../../../components/Button/styles'
import { Modal } from '../../../../components/Modal'
import ReportRecordsCheckbox from '../../components/ReportRecordsCheckbox'
import { Uninformed } from '../../../../components/Uninformed'
import ScopeEvidenceAttachments from '../../components/ScopeEvidenceAttachments'
import { toast } from 'react-hot-toast'
import { Loading } from '../../../../components/Loading'
import { ModalDeleteDataTable } from '../../../../components/ModalDeleteDataTable'

export const TedLossesPurchaseChoice = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([])
  const [openModal, setOpenModal] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false);
  const [title, setTitle] = useState<string | undefined>(); 
  const [guidelines, setGuidelines] = useState<string | undefined>()
  const [passo01, setPasso01] = useState<any>()
  const [passo02, setPasso02] = useState<any>()
  const [ignoreFill, setIgnoreFill] = useState(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  const location = useLocation()
  const [scopes, setScopes] = useState<any>([])
  const [scopes2, setScopes2] = useState<any>([])
  const [fuels, setFuels] = useState<any>([])
  const [energyTypes, setEnergyTypes] = useState<any>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const [changeScope, setChangeScope] = useState<string | undefined>()
  const [changeScope2, setChangeScope2] = useState<string | undefined>()

  const [purchaseJan, setPurchaseJan] = useState<any>()
  const [purchaseFeb, setPurchaseFeb] = useState<any>()
  const [purchaseMar, setPurchaseMar] = useState<any>()
  const [purchaseApr, setPurchaseApr] = useState<any>()
  const [purchaseMay, setPurchaseMay] = useState<any>()
  const [purchaseJun, setPurchaseJun] = useState<any>()
  const [purchaseJul, setPurchaseJul] = useState<any>()
  const [purchaseAug, setPurchaseAug] = useState<any>()
  const [purchaseSep, setPurchaseSep] = useState<any>()
  const [purchaseOct, setPurchaseOct] = useState<any>()
  const [purchaseNov, setPurchaseNov] = useState<any>()
  const [purchaseDec, setPurchaseDec] = useState<any>()
  const [emissionTco2e, setEmissionTco2e] = useState<any>()
  const [emissionTco2eBio, setEmissionTco2eBio] = useState<any>()
  const [purchaseYearly, setPurchaseYearly] = useState<any>()
  const [purchaseTotalsMwh, setPurchaseTotalsMwh] = useState<any>()
  const [emissionCo2, setEmissionCo2] = useState<any>()
  const [emissionCh4, setEmissionCh4] = useState<any>()
  const [emissionN2o, setEmissionN2o] = useState<any>()
  const [emissionTco2eTotals, setEmissionTco2eTotals] = useState<any>()
  const [emissionTco2eBioTotals, setEmissionTco2eBioTotals] = useState<any>()
  const [purchaseJan2, setPurchaseJan2] = useState<any>()
  const [purchaseFeb2, setPurchaseFeb2] = useState<any>()
  const [purchaseMar2, setPurchaseMar2] = useState<any>()
  const [purchaseApr2, setPurchaseApr2] = useState<any>()
  const [purchaseMay2, setPurchaseMay2] = useState<any>()
  const [purchaseJun2, setPurchaseJun2] = useState<any>()
  const [purchaseJul2, setPurchaseJul2] = useState<any>()
  const [purchaseAug2, setPurchaseAug2] = useState<any>()
  const [purchaseSep2, setPurchaseSep2] = useState<any>()
  const [purchaseOct2, setPurchaseOct2] = useState<any>()
  const [purchaseNov2, setPurchaseNov2] = useState<any>()
  const [purchaseDec2, setPurchaseDec2] = useState<any>()
  const [purchaseYearly2, setPurchaseYearly2] = useState<any>()
  const [emissionCo2_2, setEmissionCo2_2] = useState<any>()
  const [emissionCh4_2, setEmissionCh4_2] = useState<any>()
  const [emissionN2o_2, setEmissionN2o_2] = useState<any>()

  const [openModalDeletePurchaseChoice, setOpenModalDeletePurchaseChoice] = useState(false);
  const [openModalDeleteUntracked, setOpenModalDeleteUntracked] = useState(false);

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-2'
      })
    }

    getGuidelines()
  },// eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

  async function getGuidelines() {
    const item = await scopeService.getBySlug(2, 'perdas_esc_compra')

    setTitle(item.label)
    setGuidelines(item.guidelines)

    const extra = await scopeService.getByAllSlug(2, 'perdas_esc_compra');

    setPasso01(extra.escolha_de_compra)
    setPasso02(extra.energia_nao_rastreada)
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors }
  } = useForm<any>()

  useEffect(() => {
    calculateItemTotals()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [changeScope, changeScope2, emissionTco2e, emissionCo2_2, emissionTco2eBio])

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {

      if(openModalDeletePurchaseChoice){
        setLoadingModal(false);
        return;
      }

      if(openModalDeleteUntracked){
        setLoadingModal(false);
        return;
      }


      const itens: any[] = scopes.map((scope: any, index: number) => {
        return {
          source: scope.source,
          description: scope.description,
          purchase_jan: scope.purchase_jan ? scope.purchase_jan : 0,
          purchase_feb: scope.purchase_feb ? scope.purchase_feb : 0,
          purchase_mar: scope.purchase_mar ? scope.purchase_mar : 0,
          purchase_apr: scope.purchase_apr ? scope.purchase_apr : 0,
          purchase_may: scope.purchase_may ? scope.purchase_may : 0,
          purchase_jun: scope.purchase_jun ? scope.purchase_jun : 0,
          purchase_jul: scope.purchase_jul ? scope.purchase_jul : 0,
          purchase_aug: scope.purchase_aug ? scope.purchase_aug : 0,
          purchase_sep: scope.purchase_sep ? scope.purchase_sep : 0,
          purchase_oct: scope.purchase_oct ? scope.purchase_oct : 0,
          purchase_nov: scope.purchase_nov ? scope.purchase_nov : 0,
          purchase_dec: scope.purchase_dec ? scope.purchase_dec : 0,
          emission_co2: scope.emission_co2 ? scope.emission_co2 : 0,
          emission_ch4: scope.emission_ch4 ? scope.emission_ch4 : 0,
          emission_n2o: scope.emission_n2o ? scope.emission_n2o : 0,
          emission_factor: scope.emission_factor === '1' ? true : false,
          emission_tco2e: scope.emission_tco2e ? scope.emission_tco2e : 0,
          purchase_yearly: scope.purchase_yearly ? scope.purchase_yearly : 0,
          plant_efficiency: scope.plant_efficiency ? scope.plant_efficiency : 0,
          emission_tco2_mwh: scope.emission_tco2_mwh ? scope.emission_tco2_mwh : 0,
          emission_tch4_mwh: scope.emission_tch4_mwh ? scope.emission_tch4_mwh : 0,
          emission_tn2o_mwh: scope.emission_tn2o_mwh ? scope.emission_tn2o_mwh : 0,
          emission_tco2e_bio: scope.emission_tco2e_bio ? scope.emission_tco2e_bio : 0,
          purchase_totals_mwh: scope.purchase_totals_mwh
            ? scope.purchase_totals_mwh
            : 0,
          emission_tco2_bio_mwh: scope.emission_tn2o_mwh ? scope.emission_tn2o_mwh : 0,
          emission_suggested_tco2_mwh: scope.emission_suggested_tco2_mwh
            ? scope.emission_suggested_tco2_mwh
            : 0,
          emission_suggested_tch4_mwh: scope.emission_suggested_tch4_mwh
            ? scope.emission_suggested_tch4_mwh
            : 0,
          emission_suggested_tn2o_mwh: scope.emission_suggested_tn2o_mwh
            ? scope.emission_suggested_tn2o_mwh
            : 0,
          emission_suggested_tco2_bio_mwh: scope.emission_suggested_tco2_bio_mwh
            ? scope.emission_suggested_tco2_bio_mwh
            : 0,
          energy_type_id: scope.energy_type_id,
          fuel_id: parseInt(scope.fuel_id)
        }
      })

      const untracked: any[] = scopes2.map((scope: any, index: number) => {
        return {
          source: scope.source,
          description: scope.description,
          purchase_jan: scope.purchase_jan ? scope.purchase_jan : 0,
          purchase_feb: scope.purchase_feb ? scope.purchase_feb : 0,
          purchase_mar: scope.purchase_mar ? scope.purchase_mar : 0,
          purchase_apr: scope.purchase_apr ? scope.purchase_apr : 0,
          purchase_may: scope.purchase_may ? scope.purchase_may : 0,
          purchase_jun: scope.purchase_jun ? scope.purchase_jun : 0,
          purchase_jul: scope.purchase_jul ? scope.purchase_jul : 0,
          purchase_aug: scope.purchase_aug ? scope.purchase_aug : 0,
          purchase_sep: scope.purchase_sep ? scope.purchase_sep : 0,
          purchase_oct: scope.purchase_oct ? scope.purchase_oct : 0,
          purchase_nov: scope.purchase_nov ? scope.purchase_nov : 0,
          purchase_dec: scope.purchase_dec ? scope.purchase_dec : 0,
          emission_co2: scope.emission_co2 ? scope.emission_co2 : 0,
          emission_ch4: scope.emission_ch4 ? scope.emission_ch4 : 0,
          emission_n2o: scope.emission_n2o ? scope.emission_n2o : 0,
          purchase_yearly: scope.purchase_yearly ? scope.purchase_yearly : 0,
          emission_co2_jan: scope.emission_co2_jan ? scope.emission_co2_jan : 0,
          emission_co2_feb: scope.emission_co2_feb ? scope.emission_co2_feb : 0,
          emission_co2_mar: scope.emission_co2_mar ? scope.emission_co2_mar : 0,
          emission_co2_apr: scope.emission_co2_apr ? scope.emission_co2_apr : 0,
          emission_co2_may: scope.emission_co2_may ? scope.emission_co2_may : 0,
          emission_co2_jun: scope.emission_co2_jun ? scope.emission_co2_jun : 0,
          emission_co2_jul: scope.emission_co2_jul ? scope.emission_co2_jul : 0,
          emission_co2_aug: scope.emission_co2_aug ? scope.emission_co2_aug : 0,
          emission_co2_sep: scope.emission_co2_sep ? scope.emission_co2_sep : 0,
          emission_co2_oct: scope.emission_co2_oct ? scope.emission_co2_oct : 0,
          emission_co2_nov: scope.emission_co2_nov ? scope.emission_co2_nov : 0,
          emission_co2_dec: scope.emission_co2_dec ? scope.emission_co2_dec : 0
        }
      })

      const scope = {
        status: 2,
        reason: formData.reason,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        itens: itens,
        untracked: untracked
      }

      const result = await scope2Service.saveLossesElectricalEnergyPurchase(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('LossesElectricalEnergyPurchase', JSON.stringify(scope));
        
        setErrorApi(undefined)
          
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  const { isCommonUser } = useAuth()

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const energyTypesItens = await energyTypeService.getByParams({ limit: 9000, page: 1 })

    const energyTypes = energyTypesItens?.itens.map((type: any) => {
      return {
        label: type.name,
        value: type.id
      }
    })

    setEnergyTypes(energyTypes)

    const fuels = await fuelService.getByParams({ limit: 9000, page: 1 })

    setFuels(fuels?.itens)

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope2Service.getLossesElectricalEnergyPurchase(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      2,
      'perdas_esc_compra'
    )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)

      setScopeSelected(entity)

      for (const item of entity?.itens) {
        item.only_view_yearly = item.purchase_jan > 0 || item.purchase_feb > 0 || item.purchase_mar > 0 ||
          item.purchase_apr > 0 || item.purchase_may > 0 || item.purchase_jun > 0 ||
          item.purchase_jul > 0 ||
          item.purchase_aug > 0 ||
          item.purchase_sep > 0 ||
          item.purchase_oct > 0 ||
          item.purchase_nov > 0 ||
          item.purchase_dec > 0

        item.only_view_monthly = item.purchase_yearly > 0
      }

      for (const item of entity?.untracked) {
        item.only_view_yearly = item.purchase_jan > 0 || item.purchase_feb > 0 || item.purchase_mar > 0 ||
          item.purchase_apr > 0 || item.purchase_may > 0 || item.purchase_jun > 0 || item.purchase_jul > 0 ||
          item.purchase_aug > 0 || item.purchase_sep > 0 || item.purchase_oct > 0 || item.purchase_nov > 0 ||
          item.purchase_dec > 0
        
        item.only_view_monthly = item.purchase_yearly > 0
      }

      setScopeValues(entity?.itens)
      calculateTotals(entity?.itens)

      setScopeValues2(entity?.untracked)
      calculateTotals2(entity?.untracked)

      setIgnoreFill(entity.ignore_fill)
      setChangeScope('load_scope_' + entity.id + '_scopes_' + entity?.itens.length)
      setChangeScope2('load_scope_' + entity.id + '_scopes_' + entity?.untracked.length)
    } else {
      setScopeSelected({})
      setIgnoreFill(false)

      setScopeValues([])
      calculateTotals([])

      setScopeValues2([])
      calculateTotals2([])
      setChangeScope('load_scope_0_scopes_0')
      setChangeScope2('load_scope_0_scopes_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d)(\d{5})$/, '$1,$2')
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')
    return value
  }

  const handleChangeEnergyType = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await energyTypeService.getById(id)

      if (result) {
        const item = {
          energy_type_id: result.id,
          energy_type_name: result.name,
          need_fuel: result.need_fuel,
          only_view_yearly: false,
          only_view_monthly: false
        }

        const index = scopes.length

        const itens = scopes.concat(item)

        setChangeScope('new_scope_item_' + index)

        setScopeValues(itens)
        calculateTotals(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('energyTypes', [])
  }

  function handleDeleteScope(index: number) {
    const itens = []

    for (let i = 0; i < scopes?.length; i++) {
      if (i !== index) {
        itens.push(scopes[i])
      }
    }

    setChangeScope('remove_scope_item_' + index)

    setValue('energyTypes', [])

    setScopeValues(itens)
    calculateTotals(itens)
  }

  function handleOpenModalDeleteChoice(){
    setOpenModalDeletePurchaseChoice(true);
  }

  function handleDeleteAllScopeChoice(){
    const itens: any = [];

    setChangeScope('remove_scope_all_items');
    setValue('energyTypes', []);

    setScopeValues(itens)
    calculateTotals(itens)

    setOpenModalDeletePurchaseChoice(false);
  }

  function handleDeleteScope2(index: number) {
    const itens = []

    for (let i = 0; i < scopes2?.length; i++) {
      if (i !== index) {
        itens.push(scopes2[i])
      }
    }

    setChangeScope2('remove_scope_item_' + index)

    setValue('energyTypes', [])

    setScopeValues2(itens)
    calculateTotals2(itens)
  }

  function handleOpenModalUntracked(){
    setOpenModalDeleteUntracked(true);
  }

  function handleDeleteAllScopeUntracked(){
    const itens: any = [];

    setChangeScope2('remove_scope_all_items');
    setValue('energyTypes', []);

    setScopeValues2(itens)
    calculateTotals2(itens)

    setOpenModalDeleteUntracked(false);
  }

  const calculateItemTotals = () => {
    const totalsCo2 = emissionTco2e + emissionCo2_2 * 1

    setEmissionTco2eTotals(totalsCo2)
    setEmissionTco2eBioTotals(emissionTco2eBio)
  }

  const calculateTotals2 = (scopes: any) => {
    let purchaseJan = 0
    let purchaseFeb = 0
    let purchaseMar = 0
    let purchaseApr = 0
    let purchaseMay = 0
    let purchaseJun = 0
    let purchaseJul = 0
    let purchaseAug = 0
    let purchaseSep = 0
    let purchaseOct = 0
    let purchaseNov = 0
    let purchaseDec = 0
    let emissionCo2 = 0
    let emissionCh4 = 0
    let emissionN2o = 0
    let purchaseYearly = 0

    if (scopes) {
      for (const item of scopes) {
        if (item) {
          if (item.purchase_jan) {
            purchaseJan = purchaseJan + item.purchase_jan
          }
          if (item.purchase_feb) {
            purchaseFeb = purchaseFeb + item.purchase_feb
          }
          if (item.purchase_mar) {
            purchaseMar = purchaseMar + item.purchase_mar
          }
          if (item.purchase_apr) {
            purchaseApr = purchaseApr + item.purchase_apr
          }
          if (item.purchase_may) {
            purchaseMay = purchaseMay + item.purchase_may
          }
          if (item.purchase_jun) {
            purchaseJun = purchaseJun + item.purchase_jun
          }
          if (item.purchase_jul) {
            purchaseJul = purchaseJul + item.purchase_jul
          }
          if (item.purchase_aug) {
            purchaseAug = purchaseAug + item.purchase_aug
          }
          if (item.purchase_sep) {
            purchaseSep = purchaseSep + item.purchase_sep
          }
          if (item.purchase_oct) {
            purchaseOct = purchaseOct + item.purchase_oct
          }
          if (item.purchase_nov) {
            purchaseNov = purchaseNov + item.purchase_nov
          }
          if (item.purchase_dec) {
            purchaseDec = purchaseDec + item.purchase_dec
          }
          if (item.emission_co2) {
            emissionCo2 = emissionCo2 + item.emission_co2
          }
          if (item.emission_ch4) {
            emissionCh4 = emissionCh4 + item.emission_ch4
          }
          if (item.emission_n2o) {
            emissionN2o = emissionN2o + item.emission_n2o
          }
          if (item.purchase_yearly) {
            purchaseYearly = purchaseYearly + item.purchase_yearly
          }
        }
      }
    }

    setPurchaseJan2(purchaseJan)
    setPurchaseFeb2(purchaseFeb)
    setPurchaseMar2(purchaseMar)
    setPurchaseApr2(purchaseApr)
    setPurchaseMay2(purchaseMay)
    setPurchaseJun2(purchaseJun)
    setPurchaseJul2(purchaseJul)
    setPurchaseAug2(purchaseAug)
    setPurchaseSep2(purchaseSep)
    setPurchaseOct2(purchaseOct)
    setPurchaseNov2(purchaseNov)
    setPurchaseDec2(purchaseDec)
    setEmissionCo2_2(emissionCo2)
    setEmissionCh4_2(emissionCh4)
    setEmissionN2o_2(emissionN2o)

    if (
      purchaseJan > 0 ||
      purchaseFeb > 0 ||
      purchaseMar > 0 ||
      purchaseApr > 0 ||
      purchaseMay > 0 ||
      purchaseJun > 0 ||
      purchaseJul > 0 ||
      purchaseAug > 0 ||
      purchaseSep > 0 ||
      purchaseOct > 0 ||
      purchaseNov > 0 ||
      purchaseDec > 0
    ) {
      setPurchaseYearly2(0)
    } else {
      setPurchaseYearly2(purchaseYearly)
    }
  }

  const calculateTotals = (scopes: any) => {
    let purchaseJan = 0
    let purchaseFeb = 0
    let purchaseMar = 0
    let purchaseApr = 0
    let purchaseMay = 0
    let purchaseJun = 0
    let purchaseJul = 0
    let purchaseAug = 0
    let purchaseSep = 0
    let purchaseOct = 0
    let purchaseNov = 0
    let purchaseDec = 0
    let emissionCo2 = 0
    let emissionCh4 = 0
    let emissionN2o = 0
    let emissionTco2e = 0
    let purchaseYearly = 0
    let emissionTco2eBio = 0
    let purchaseTotalsMwh = 0

    if (scopes) {
      for (const item of scopes) {
        if (item) {
          if (item.purchase_jan) {
            purchaseJan = purchaseJan + item.purchase_jan
          }
          if (item.purchase_feb) {
            purchaseFeb = purchaseFeb + item.purchase_feb
          }
          if (item.purchase_mar) {
            purchaseMar = purchaseMar + item.purchase_mar
          }
          if (item.purchase_apr) {
            purchaseApr = purchaseApr + item.purchase_apr
          }
          if (item.purchase_may) {
            purchaseMay = purchaseMay + item.purchase_may
          }
          if (item.purchase_jun) {
            purchaseJun = purchaseJun + item.purchase_jun
          }
          if (item.purchase_jul) {
            purchaseJul = purchaseJul + item.purchase_jul
          }
          if (item.purchase_aug) {
            purchaseAug = purchaseAug + item.purchase_aug
          }
          if (item.purchase_sep) {
            purchaseSep = purchaseSep + item.purchase_sep
          }
          if (item.purchase_oct) {
            purchaseOct = purchaseOct + item.purchase_oct
          }
          if (item.purchase_nov) {
            purchaseNov = purchaseNov + item.purchase_nov
          }
          if (item.purchase_dec) {
            purchaseDec = purchaseDec + item.purchase_dec
          }
          if (item.emission_co2) {
            emissionCo2 = emissionCo2 + item.emission_co2
          }
          if (item.emission_ch4) {
            emissionCh4 = emissionCh4 + item.emission_ch4
          }
          if (item.emission_n2o) {
            emissionN2o = emissionN2o + item.emission_n2o
          }
          if (item.emission_tco2e) {
            emissionTco2e = emissionTco2e + item.emission_tco2e
          }
          if (item.purchase_yearly) {
            purchaseYearly = purchaseYearly + item.purchase_yearly
          }
          if (item.emission_tco2e_bio) {
            emissionTco2eBio = emissionTco2eBio + item.emission_tco2e_bio
          }
          if (item.purchase_totals_mwh) {
            purchaseTotalsMwh = purchaseTotalsMwh + item.purchase_totals_mwh
          }
        }
      }
    }

    setPurchaseJan(purchaseJan)
    setPurchaseFeb(purchaseFeb)
    setPurchaseMar(purchaseMar)
    setPurchaseApr(purchaseApr)
    setPurchaseMay(purchaseMay)
    setPurchaseJun(purchaseJun)
    setPurchaseJun(purchaseJul)
    setPurchaseJul(purchaseJul)
    setPurchaseAug(purchaseAug)
    setPurchaseSep(purchaseSep)
    setPurchaseOct(purchaseOct)
    setPurchaseNov(purchaseNov)
    setPurchaseDec(purchaseDec)
    setEmissionCo2(emissionCo2)
    setEmissionCh4(emissionCh4)
    setEmissionN2o(emissionN2o)
    setEmissionTco2e(emissionTco2e)
    setPurchaseYearly(purchaseYearly)
    setEmissionTco2eBio(emissionTco2eBio)
    setPurchaseTotalsMwh(purchaseTotalsMwh)
  }

  const handleChangeValue2 = (index: number, ignore_field?: string) => {
    const purchaseJan = parseFloat(scopes2[index].purchase_jan ? scopes2[index].purchase_jan : 0)
    const purchaseFeb = parseFloat(scopes2[index].purchase_feb ? scopes2[index].purchase_feb : 0)
    const purchaseMar = parseFloat(scopes2[index].purchase_mar ? scopes2[index].purchase_mar : 0)
    const purchaseApr = parseFloat(scopes2[index].purchase_apr ? scopes2[index].purchase_apr : 0)
    const purchaseMay = parseFloat(scopes2[index].purchase_may ? scopes2[index].purchase_may : 0)
    const purchaseJun = parseFloat(scopes2[index].purchase_jun ? scopes2[index].purchase_jun : 0)
    const purchaseJul = parseFloat(scopes2[index].purchase_jul ? scopes2[index].purchase_jul : 0)
    const purchaseAug = parseFloat(scopes2[index].purchase_aug ? scopes2[index].purchase_aug : 0)
    const purchaseSep = parseFloat(scopes2[index].purchase_sep ? scopes2[index].purchase_sep : 0)
    const purchaseOct = parseFloat(scopes2[index].purchase_oct ? scopes2[index].purchase_oct : 0)
    const purchaseNov = parseFloat(scopes2[index].purchase_nov ? scopes2[index].purchase_nov : 0)
    const purchaseDec = parseFloat(scopes2[index].purchase_dec ? scopes2[index].purchase_dec : 0)
    const purchaseYearly2 = parseFloat(scopes2[index].purchase_yearly ? scopes2[index].purchase_yearly : 0)

    const onlyViewYearly = purchaseJan > 0 || purchaseFeb > 0 || purchaseMar > 0 || purchaseApr > 0 ||
      purchaseMay > 0 || purchaseJun > 0 || purchaseJul > 0 || purchaseAug > 0 || purchaseSep > 0 ||
      purchaseOct > 0 || purchaseNov > 0 || purchaseDec > 0

    const onlyViewMonthly = purchaseYearly2 > 0

    const purchaseYearly = onlyViewYearly ? 0 : purchaseYearly2

    async function calculate() {
      const result = await scope2Service.calculateLossesElectricalEnergyPurchaseUntracked( inventorySelected.year,
        purchaseJan, purchaseFeb, purchaseMar, purchaseApr, purchaseMay, purchaseJun,
        purchaseJul, purchaseAug, purchaseSep, purchaseOct, purchaseNov, purchaseDec,
        purchaseYearly
      )

      if (result) {
        scopes2[index].only_view_yearly = onlyViewYearly
        scopes2[index].only_view_monthly = onlyViewMonthly

        scopes2[index].emission_co2 = result.emission_co2
        scopes2[index].emission_ch4 = result.emission_ch4
        scopes2[index].emission_n2o = result.emission_n2o
        scopes2[index].emission_co2_jan = result.emission_co2_jan
        scopes2[index].emission_co2_feb = result.emission_co2_feb
        scopes2[index].emission_co2_mar = result.emission_co2_mar
        scopes2[index].emission_co2_apr = result.emission_co2_apr
        scopes2[index].emission_co2_may = result.emission_co2_may
        scopes2[index].emission_co2_jun = result.emission_co2_jun
        scopes2[index].emission_co2_jul = result.emission_co2_jul
        scopes2[index].emission_co2_aug = result.emission_co2_aug
        scopes2[index].emission_co2_sep = result.emission_co2_sep
        scopes2[index].emission_co2_oct = result.emission_co2_oct
        scopes2[index].emission_co2_nov = result.emission_co2_nov
        scopes2[index].emission_co2_dec = result.emission_co2_dec

        setChangeScope2(
          'change_scope_item_' +
            index +
            '_' +
            purchaseJan +
            '_' +
            purchaseFeb +
            '_' +
            purchaseMar +
            '_' +
            purchaseApr +
            '_' +
            purchaseMay +
            '_' +
            purchaseJun +
            '_' +
            purchaseJul +
            '_' +
            purchaseAug +
            '_' +
            purchaseSep +
            '_' +
            purchaseOct +
            '_' +
            purchaseNov +
            '_' +
            purchaseDec +
            '_' +
            purchaseYearly
        )

        setScopeValues2(scopes2, ignore_field)
        calculateTotals2(scopes2)
      }
    }

    calculate()
  }

  const handleChangeValue = (index: number, ignore_field?: string) => {
    const emissionFactor = scopes[index].emission_factor === '1' ? true : false
    const fuelId = parseInt(scopes[index].fuel_id ? scopes[index].fuel_id : 0)
    const purchaseJan = parseFloat(scopes[index].purchase_jan ? scopes[index].purchase_jan : 0)
    const purchaseFeb = parseFloat(scopes[index].purchase_feb ? scopes[index].purchase_feb : 0)
    const purchaseMar = parseFloat(scopes[index].purchase_mar ? scopes[index].purchase_mar : 0)
    const purchaseApr = parseFloat(scopes[index].purchase_apr ? scopes[index].purchase_apr : 0)
    const purchaseMay = parseFloat(scopes[index].purchase_may ? scopes[index].purchase_may : 0)
    const purchaseJun = parseFloat(scopes[index].purchase_jun ? scopes[index].purchase_jun : 0)
    const purchaseJul = parseFloat(scopes[index].purchase_jul ? scopes[index].purchase_jul : 0)
    const purchaseAug = parseFloat(scopes[index].purchase_aug ? scopes[index].purchase_aug : 0)
    const purchaseSep = parseFloat(scopes[index].purchase_sep ? scopes[index].purchase_sep : 0)
    const purchaseOct = parseFloat(scopes[index].purchase_oct ? scopes[index].purchase_oct : 0)
    const purchaseNov = parseFloat(scopes[index].purchase_nov ? scopes[index].purchase_nov : 0)
    const purchaseDec = parseFloat(scopes[index].purchase_dec ? scopes[index].purchase_dec : 0)
    const energyTypeId = parseInt(scopes[index].energy_type_id ? scopes[index].energy_type_id : 0)
    const purchaseYearly2 = parseFloat(scopes[index].purchase_yearly ? scopes[index].purchase_yearly : 0)
    const plantEfficiency = parseFloat(scopes[index].plant_efficiency ? scopes[index].plant_efficiency : 0)
    const emissionTco2Mwh = parseFloat(scopes[index].emission_tco2_mwh ? scopes[index].emission_tco2_mwh : 0)
    const emissionTch4Mwh = parseFloat(scopes[index].emission_tch4_mwh ? scopes[index].emission_tch4_mwh : 0)
    const emissionTn2oMwh = parseFloat(scopes[index].emission_tn2o_mwh ? scopes[index].emission_tn2o_mwh : 0)
    const emissionTco2BioMwh = parseFloat(scopes[index].emission_tco2_bio_mwh ? scopes[index].emission_tco2_bio_mwh : 0)

    const onlyViewYearly = purchaseJan > 0 || purchaseFeb > 0 || purchaseMar > 0 || purchaseApr > 0 ||
      purchaseMay > 0 || purchaseJun > 0 || purchaseJul > 0 || purchaseAug > 0 || purchaseSep > 0 ||
      purchaseOct > 0 || purchaseNov > 0 || purchaseDec > 0

    const onlyViewMonthly = purchaseYearly2 > 0

    const purchaseYearly = onlyViewYearly ? 0 : purchaseYearly2

    async function calculate() {
      const result = await scope2Service.calculateLossesElectricalEnergyPurchase(fuelId, energyTypeId, emissionFactor,
        purchaseJan, purchaseFeb, purchaseMar, purchaseApr, purchaseMay, purchaseJun, purchaseJul, purchaseAug,
        purchaseSep, purchaseOct, purchaseNov, purchaseDec, purchaseYearly, plantEfficiency, emissionTco2Mwh,
        emissionTch4Mwh, emissionTn2oMwh, emissionTco2BioMwh )

      if (result) {
        scopes[index].only_view_yearly = onlyViewYearly
        scopes[index].only_view_monthly = onlyViewMonthly

        scopes[index].emission_co2 = result.emission_co2
        scopes[index].emission_ch4 = result.emission_ch4
        scopes[index].emission_n2o = result.emission_n2o
        scopes[index].emission_tco2e = result.emission_tco2e
        scopes[index].emission_tco2e_bio = result.emission_tco2e_bio
        scopes[index].purchase_totals_mwh = result.purchase_totals_mwh
        scopes[index].emission_suggested_tco2_mwh = result.emission_suggested_tco2_mwh
        scopes[index].emission_suggested_tch4_mwh = result.emission_suggested_tch4_mwh
        scopes[index].emission_suggested_tn2o_mwh = result.emission_suggested_tn2o_mwh
        scopes[index].emission_suggested_tco2_bio_mwh = result.emission_suggested_tco2_bio_mwh

        setChangeScope('change_scope_item_' + index + '_' + fuelId + '_' + energyTypeId + '_' +
            emissionFactor + '_' + purchaseJan + '_' + purchaseFeb + '_' +  purchaseMar + '_' +
            purchaseApr + '_' + purchaseMay + '_' + purchaseJun + '_' + purchaseJul + '_' +
            purchaseAug + '_' + purchaseSep + '_' + purchaseOct + '_' + purchaseNov + '_' +
            purchaseDec + '_' + purchaseYearly + '_' + plantEfficiency + '_' + emissionTco2Mwh + '_' +
            emissionTch4Mwh + '_' + emissionTn2oMwh + '_' + emissionTco2BioMwh
        )

        setScopeValues(scopes, ignore_field)
        calculateTotals(scopes)
      }
    }

    async function getFuelById(id: any) {
      const result = await fuelService.getById(id)

      if (result) {
        scopes[index].fuel_name = result.name
        scopes[index].fuel_unity = result.unity
        scopes[index].fuel_type = result.fuel_type
      }
    }

    if (ignore_field === 'fuel_id') {
      getFuelById(scopes[index].fuel_id)
    }

    calculate()
  }

  const setScopeValues = (itens: any, ignore_field?: string) => {
    let changeScope = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`scopes.${i}.source`, item.source)
        setValue(`scopes.${i}.description`, item.description)

        setValue(`scopes.${i}.energy_type_id`, item.energy_type_id)
        setValue(`scopes.${i}.energy_type_name`, item.energy_type_name)

        setValue(`scopes.${i}.fuel_id`, item.fuel_id)

        if ('plant_efficiency' !== ignore_field) {
          setValue(`scopes.${i}.plant_efficiency`, item.plant_efficiency)
        }

        if ('emission_factor' !== ignore_field) {
          setValue(
            `scopes.${i}.emission_factor`,
            item.emission_factor === true ? '1' : '0'
          )
        }

        if ('purchase_jan' !== ignore_field) {
          setValue(`scopes.${i}.purchase_jan`, item.purchase_jan)
        }

        if ('purchase_feb' !== ignore_field) {
          setValue(`scopes.${i}.purchase_feb`, item.purchase_feb)
        }

        if ('purchase_mar' !== ignore_field) {
          setValue(`scopes.${i}.purchase_mar`, item.purchase_mar)
        }

        if ('purchase_apr' !== ignore_field) {
          setValue(`scopes.${i}.purchase_apr`, item.purchase_apr)
        }

        if ('purchase_may' !== ignore_field) {
          setValue(`scopes.${i}.purchase_may`, item.purchase_may)
        }

        if ('purchase_jun' !== ignore_field) {
          setValue(`scopes.${i}.purchase_jun`, item.purchase_jun)
        }

        if ('purchase_jul' !== ignore_field) {
          setValue(`scopes.${i}.purchase_jul`, item.purchase_jul)
        }

        if ('purchase_aug' !== ignore_field) {
          setValue(`scopes.${i}.purchase_aug`, item.purchase_aug)
        }

        if ('purchase_sep' !== ignore_field) {
          setValue(`scopes.${i}.purchase_sep`, item.purchase_sep)
        }

        if ('purchase_oct' !== ignore_field) {
          setValue(`scopes.${i}.purchase_oct`, item.purchase_oct)
        }

        if ('purchase_nov' !== ignore_field) {
          setValue(`scopes.${i}.purchase_nov`, item.purchase_nov)
        }

        if ('purchase_dec' !== ignore_field) {
          setValue(`scopes.${i}.purchase_dec`, item.purchase_dec)
        }

        if ('purchase_yearly' !== ignore_field) {
          setValue(`scopes.${i}.purchase_yearly`, item.purchase_yearly)
        }

        if ('emission_tco2_mwh' !== ignore_field) {
          setValue(`scopes.${i}.emission_tco2_mwh`, item.emission_tco2_mwh)
        }

        if ('emission_tch4_mwh' !== ignore_field) {
          setValue(`scopes.${i}.emission_tch4_mwh`, item.emission_tch4_mwh)
        }

        if ('emission_tn2o_mwh' !== ignore_field) {
          setValue(`scopes.${i}.emission_tn2o_mwh`, item.emission_tn2o_mwh)
        }

        if ('emission_tco2_bio_mwh' !== ignore_field) {
          setValue(`scopes.${i}.emission_tco2_bio_mwh`, item.emission_tco2_bio_mwh)
        }

        if ('emission_suggested_tco2_mwh' !== ignore_field) {
          setValue(
            `scopes.${i}.emission_suggested_tco2_mwh`,
            item.emission_suggested_tco2_mwh
          )
        }

        if ('emission_suggested_tch4_mwh' !== ignore_field) {
          setValue(
            `scopes.${i}.emission_suggested_tch4_mwh`,
            item.emission_suggested_tch4_mwh
          )
        }

        if ('emission_suggested_tn2o_mwh' !== ignore_field) {
          setValue(
            `scopes.${i}.emission_suggested_tn2o_mwh`,
            item.emission_suggested_tn2o_mwh
          )
        }

        if ('emission_suggested_tco2_bio_mwh' !== ignore_field) {
          setValue(
            `scopes.${i}.emission_suggested_tco2_bio_mwh`,
            item.emission_suggested_tco2_bio_mwh
          )
        }

        if ('purchase_totals_mwh' !== ignore_field) {
          setValue(`scopes.${i}.purchase_totals_mwh`, item.purchase_totals_mwh)
        }

        if ('emission_co2' !== ignore_field) {
          setValue(`scopes.${i}.emission_co2`, item.emission_co2)
        }

        if ('emission_ch4' !== ignore_field) {
          setValue(`scopes.${i}.emission_ch4`, item.emission_ch4)
        }

        if ('emission_n2o' !== ignore_field) {
          setValue(`scopes.${i}.emission_n2o`, item.emission_n2o)
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`scopes.${i}.emission_tco2e`, item.emission_tco2e)
        }

        if ('emission_tco2e_bio' !== ignore_field) {
          setValue(`scopes.${i}.emission_tco2e_bio`, item.emission_tco2e_bio)
        }

        changeScope =
          changeScope +
          item.car_fleet_id +
          '_' +
          item.year +
          '_' +
          item.purchase_jan +
          '_' +
          item.purchase_feb +
          '_' +
          item.purchase_mar +
          '_' +
          item.purchase_apr +
          '_' +
          item.purchase_may +
          '_' +
          item.purchase_jun +
          '_' +
          item.purchase_jul +
          '_' +
          item.purchase_aug +
          '_' +
          item.purchase_sep +
          '_' +
          item.purchase_oct +
          '_' +
          item.purchase_nov +
          '_' +
          item.purchase_dec +
          '_' +
          item.purchase_yearly
      }
    }

    setScopes(itens)
    setChangeScope(changeScope)
  }

  const setScopeValues2 = (itens: any, ignore_field?: string) => {
    let changeScope = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`scopes2.${i}.source`, item.source)
        setValue(`scopes2.${i}.description`, item.description)

        if ('purchase_jan' !== ignore_field) {
          setValue(`scopes2.${i}.purchase_jan`, item.purchase_jan)
        }

        if ('purchase_feb' !== ignore_field) {
          setValue(`scopes2.${i}.purchase_feb`, item.purchase_feb)
        }

        if ('purchase_mar' !== ignore_field) {
          setValue(`scopes2.${i}.purchase_mar`, item.purchase_mar)
        }

        if ('purchase_apr' !== ignore_field) {
          setValue(`scopes2.${i}.purchase_apr`, item.purchase_apr)
        }

        if ('purchase_may' !== ignore_field) {
          setValue(`scopes2.${i}.purchase_may`, item.purchase_may)
        }

        if ('purchase_jun' !== ignore_field) {
          setValue(`scopes2.${i}.purchase_jun`, item.purchase_jun)
        }

        if ('purchase_jul' !== ignore_field) {
          setValue(`scopes2.${i}.purchase_jul`, item.purchase_jul)
        }

        if ('purchase_aug' !== ignore_field) {
          setValue(`scopes2.${i}.purchase_aug`, item.purchase_aug)
        }

        if ('purchase_sep' !== ignore_field) {
          setValue(`scopes2.${i}.purchase_sep`, item.purchase_sep)
        }

        if ('purchase_oct' !== ignore_field) {
          setValue(`scopes2.${i}.purchase_oct`, item.purchase_oct)
        }

        if ('purchase_nov' !== ignore_field) {
          setValue(`scopes2.${i}.purchase_nov`, item.purchase_nov)
        }

        if ('purchase_dec' !== ignore_field) {
          setValue(`scopes2.${i}.purchase_dec`, item.purchase_dec)
        }

        if ('purchase_yearly' !== ignore_field) {
          setValue(`scopes2.${i}.purchase_yearly`, item.purchase_yearly)
        }

        if ('emission_co2' !== ignore_field) {
          setValue(`scopes2.${i}.emission_co2`, item.emission_co2)
        }

        if ('emission_ch4' !== ignore_field) {
          setValue(`scopes2.${i}.emission_ch4`, item.emission_ch4)
        }

        if ('emission_n2o' !== ignore_field) {
          setValue(`scopes2.${i}.emission_n2o`, item.emission_n2o)
        }

        if ('emission_co2_jan' !== ignore_field) {
          setValue(`scopes2.${i}.emission_co2_jan`, item.emission_co2_jan)
        }

        if ('emission_co2_feb' !== ignore_field) {
          setValue(`scopes2.${i}.emission_co2_feb`, item.emission_co2_feb)
        }

        if ('emission_co2_mar' !== ignore_field) {
          setValue(`scopes2.${i}.emission_co2_mar`, item.emission_co2_mar)
        }

        if ('emission_co2_apr' !== ignore_field) {
          setValue(`scopes2.${i}.emission_co2_apr`, item.emission_co2_apr)
        }

        if ('emission_co2_may' !== ignore_field) {
          setValue(`scopes2.${i}.emission_co2_may`, item.emission_co2_may)
        }

        if ('emission_co2_jun' !== ignore_field) {
          setValue(`scopes2.${i}.emission_co2_jun`, item.emission_co2_jun)
        }

        if ('emission_co2_jul' !== ignore_field) {
          setValue(`scopes2.${i}.emission_co2_jul`, item.emission_co2_jul)
        }

        if ('emission_co2_aug' !== ignore_field) {
          setValue(`scopes2.${i}.emission_co2_aug`, item.emission_co2_aug)
        }

        if ('emission_co2_sep' !== ignore_field) {
          setValue(`scopes2.${i}.emission_co2_sep`, item.emission_co2_sep)
        }

        if ('emission_co2_oct' !== ignore_field) {
          setValue(`scopes2.${i}.emission_co2_oct`, item.emission_co2_oct)
        }

        if ('emission_co2_nov' !== ignore_field) {
          setValue(`scopes2.${i}.emission_co2_nov`, item.emission_co2_nov)
        }

        if ('emission_co2_dec' !== ignore_field) {
          setValue(`scopes2.${i}.emission_co2_dec`, item.emission_co2_dec)
        }

        changeScope =
          changeScope +
          item.car_fleet_id +
          '_' +
          item.year +
          '_' +
          item.purchase_jan +
          '_' +
          item.purchase_feb +
          '_' +
          item.purchase_mar +
          '_' +
          item.purchase_apr +
          '_' +
          item.purchase_may +
          '_' +
          item.purchase_jun +
          '_' +
          item.purchase_jul +
          '_' +
          item.purchase_aug +
          '_' +
          item.purchase_sep +
          '_' +
          item.purchase_oct +
          '_' +
          item.purchase_nov +
          '_' +
          item.purchase_dec +
          '_' +
          item.purchase_yearly
      }
    }

    setScopes2(itens)
    setChangeScope2(changeScope)
  }

  const handleNewItem = () => {
    const item = {
      source: undefined,
      description: undefined,
      only_view_yearly: false
    }

    const index = scopes2.length

    const itens = scopes2.concat(item)

    setChangeScope2('new_scope_item_' + index)

    setScopeValues2(itens)
    calculateTotals2(itens)
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          2,
          'perdas_esc_compra'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const handleDownloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const handleAttachmentChange = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 2,
        name: 'perdas_esc_compra',
        file: base64,
        filename: filename,
        second_name: ''
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          2,
          'perdas_esc_compra'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          {inventorySelected?.year && inventorySelected?.company && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
              <TitleText level={4} title={'Escopo 02 - ' + title}  />
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />
        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        {!ignoreFill && (
          <div
            style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
          />
        )}

        {!ignoreFill && (
          <div
            style={{
              overflow: 'hidden auto',
              position: 'relative',
              marginTop: 0,
              height: 'calc(100vh - 200px)',
              maxHeight: 'calc(100vh - 200px)'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 0,
                justifyContent: 'space-between',
                marginBottom: 0
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}>
                {passo01 && passo01.label && (
                  <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                    {passo01.label}
                  </span>
                )}
                <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, }} >
                  Selecione, o tipo de energia ao lado, para adicionar nova linha
                </span>
              </div>

              {passo01 && passo01.guidelines && (
                <div className="tooltip" style={{ paddingTop: 10 }}>
                  <img alt="infoButton" src={InfoButtonMin} style={{ paddingTop: 10, width: 28 }} />
                  <span className="tooltiptext">{passo01.guidelines}</span>
                </div>
              )}

              {!isCommonUser && energyTypes && (
                <Controller
                  control={control}
                  name="energyTypes"
                  render={({ field }) => (
                    <Select placeholder="[Selecione]"
                      {...field}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          width: '350px',
                          height: 40,
                          marginTop: 5,
                          marginRight: 20,
                          backgroundColor: 'rgb(246, 246, 246)'
                        }),
                        option: (styles) => {
                          return { ...styles, color: '#31363F' }
                        }
                      }}
                      defaultValue={getValues('energyTypes')}
                      options={energyTypes}
                      onChange={handleChangeEnergyType}
                    />
                  )}
                />
              )}
            </div>

            <div
              style={{
                overflow: 'auto',
                position: 'relative',
                marginTop: 20,
                width: 'calc(100vw - 320px)',
                maxHeight: 'calc(100vh - 360px)'
              }}
            >
              {changeScope && scopes && scopes.length > 0 && (
                <table
                  style={{
                    maxWidth: '100%',
                    width: '4000px',
                    borderCollapse: 'collapse'
                  }}
                  className="table-scope"
                >
                  <thead>
                    <tr>
                      <th className="head-tr-item" style={{ width: 360 }} colSpan={2}>
                        {'Fonte'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} rowSpan={3}>
                        {'Tipo de fonte de geração de energia'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} rowSpan={3}>
                        {'Combustível'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} rowSpan={3}>
                        {'Você possui o fator de emissão para essa fonte?'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} rowSpan={3}>
                        {'Qual a eficiência da planta geradora?'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} colSpan={13}>
                        {'Eletricidade Comprada (MWh)'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} colSpan={4}>
                        {'Fator de emissão fornecido pelo gerador'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} colSpan={4}>
                        {'Fator de emissão sugerido'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {'Eletricidade total comprada (MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {'Emissões de CO₂ (t)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {'Emissões de CH₄ (t)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {'Emissões de N₂O (t)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {'Emissões em CO₂e (t)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {'Emissões de CO₂ biogênico (t)'}
                      </th>
                      {!isCommonUser && (
                        <th className="head-tr-item" style={{ width: 50 }} rowSpan={3} >
                          {!isCommonUser && (
                              <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                                <div onClick={handleOpenModalDeleteChoice}>
                                  <button style={{background: '#b81414', color: 'white', borderRadius: 5, cursor:'pointer'}}>Apagar tudo</button>
                                </div>
                              </td>
                            )}
                        </th>
                      )}
                    </tr>
                    <tr>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 120 }}
                        rowSpan={2}
                      >
                        {'Registro'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 240 }}
                        rowSpan={2}
                      >
                        {'Descrição'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} colSpan={12}>
                        {'Relate aqui a compra mensal de eletricidade (MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 250 }}
                        rowSpan={2}
                      >
                        {'Relate aqui a compra anual de eletricidade (MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={2}
                      >
                        {'Fator de emissão CO₂ (tCO₂ / MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={2}
                      >
                        {'Fator de emissão CH₄ (tCH₄ / MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={2}
                      >
                        {'Fator de emissão N₂O (tN₂O / MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={2}
                      >
                        {'Fator de emissão CO₂ biogênico (tCO₂ / MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={2}
                      >
                        {'Fator de emissão CO₂ (tCO₂ / MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={2}
                      >
                        {'Fator de emissão CH₄ (tCH₄ / MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={2}
                      >
                        {'Fator de emissão N₂O (tN₂O / MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={2}
                      >
                        {'Fator de emissão CO₂ biogênico (tCO₂ / MWh)'}
                      </th>
                    </tr>
                    <tr>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Janeiro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Fevereiro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Março'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Abril'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Maio'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Junho'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Julho'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Agosto'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Setembro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Outubro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Novembro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Dezembro'}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {scopes.map((item: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 200 }}
                                className="input-item"
                                {...register(`scopes.${index}.source`, {
                                  required: true
                                })}
                                onChange={(event) => {
                                  setValue(`scopes.${index}.source`, event.target.value)
                                  scopes[index].source = event.target.value
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 200 }}
                                className="input-item"
                                {...register(`scopes.${index}.description`, {
                                  required: true
                                })}
                                onChange={(event) => {
                                  setValue(
                                    `scopes.${index}.description`,
                                    event.target.value
                                  )
                                  scopes[index].description = event.target.value
                                }}
                              />
                            </td>
                            <td style={{ width: 150 }}>
                              <span
                                style={{
                                  marginLeft: 5,
                                  width: 150,
                                  fontSize: 12,
                                  fontWeight: 'bold',
                                  display: 'inline-block'
                                }}
                              >
                                {scopes[index].energy_type_name}
                              </span>
                            </td>
                            <td style={{ width: 250 }}>
                              {scopes[index].need_fuel === false && (
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 250,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    display: 'inline-block'
                                  }}
                                />
                              )}
                              {scopes[index].need_fuel && fuels && (
                                <select
                                  style={{
                                    borderTopWidth: '0px',
                                    borderLeftWidth: '0px',
                                    borderRightWidth: '0px',
                                    background: 'transparent',
                                    borderBottomWidth: '1px solid black',
                                    borderRadius: ' 2.5px',
                                    width: '250px',
                                    height: '40px'
                                  }}
                                  {...register(`scopes.${index}.fuel_id`)}
                                  onChange={(event) => {
                                    const { value } = event.target

                                    scopes[index].fuel_id = value

                                    setValue(`scopes.${index}.fuel_id`, value)

                                    handleChangeValue(index, 'fuel_id')
                                  }}
                                >
                                  <option label="" />
                                  {fuels &&
                                    fuels.map((fuel: any) => {
                                      return (
                                        <option value={fuel.id} key={fuel.id}>
                                          {fuel.name}
                                        </option>
                                      )
                                    })}
                                </select>
                              )}
                            </td>
                            <td style={{ width: 200 }}>
                              <select
                                style={{
                                  borderWidth: '0px',
                                  width: '200px',
                                  height: '25px',
                                  background: 'transparent'
                                }}
                                {...register(`scopes.${index}.emission_factor`)}
                                onChange={(event) => {
                                  const { value } = event.target

                                  scopes[index].emission_factor = value.toString()

                                  setValue(
                                    `scopes.${index}.emission_factor`,
                                    value.toString()
                                  )

                                  handleChangeValue(index, 'emission_factor')
                                }}
                              >
                                <option value="0" label="Não" />
                                <option value="1" label="Sim" />
                              </select>
                            </td>
                            <td>
                              <div style={{ height: 40 }}>
                                <input
                                  readOnly={isCommonUser}
                                  style={{
                                    width: 130,
                                    textAlign: 'right',
                                    paddingRight: 20
                                  }}
                                  className="input-item"
                                  {...register(`scopes.${index}.plant_efficiency`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    let value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    if (value > 100) {
                                      value = 100
                                    }

                                    scopes[index].plant_efficiency = value

                                    setValue(
                                      `scopes.${index}.plant_efficiency`,
                                      formatValue
                                    )

                                    handleChangeValue(index, 'plant_efficiency')
                                  }}
                                />
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 20,
                                    fontSize: 12,
                                    position: 'relative',
                                    top: '-29px',
                                    left: '135px',
                                    fontWeight: 'bold',
                                    display: 'inline-block'
                                  }}
                                >
                                  %
                                </span>
                              </div>
                            </td>
                            <td>
                              {scopes[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.purchase_jan`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].purchase_jan = value

                                    setValue(`scopes.${index}.purchase_jan`, formatValue)

                                    handleChangeValue(index, 'purchase_jan')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopes[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.purchase_feb`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].purchase_feb = value

                                    setValue(`scopes.${index}.purchase_feb`, formatValue)

                                    handleChangeValue(index, 'purchase_feb')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopes[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.purchase_mar`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].purchase_mar = value

                                    setValue(`scopes.${index}.purchase_mar`, formatValue)

                                    handleChangeValue(index, 'purchase_mar')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopes[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.purchase_apr`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].purchase_apr = value

                                    setValue(`scopes.${index}.purchase_apr`, formatValue)

                                    handleChangeValue(index, 'purchase_apr')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopes[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.purchase_may`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].purchase_may = value

                                    setValue(`scopes.${index}.purchase_may`, formatValue)

                                    handleChangeValue(index, 'purchase_may')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopes[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.purchase_jun`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].purchase_jun = value

                                    setValue(`scopes.${index}.purchase_jun`, formatValue)

                                    handleChangeValue(index, 'purchase_jun')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopes[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.purchase_jul`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].purchase_jul = value

                                    setValue(`scopes.${index}.purchase_jul`, formatValue)

                                    handleChangeValue(index, 'purchase_jul')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopes[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.purchase_aug`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].purchase_aug = value

                                    setValue(`scopes.${index}.purchase_aug`, formatValue)

                                    handleChangeValue(index, 'purchase_aug')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopes[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.purchase_sep`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].purchase_sep = value

                                    setValue(`scopes.${index}.purchase_sep`, formatValue)

                                    handleChangeValue(index, 'purchase_sep')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopes[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.purchase_oct`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].purchase_oct = value

                                    setValue(`scopes.${index}.purchase_oct`, formatValue)

                                    handleChangeValue(index, 'purchase_oct')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopes[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.purchase_nov`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].purchase_nov = value

                                    setValue(`scopes.${index}.purchase_nov`, formatValue)

                                    handleChangeValue(index, 'purchase_nov')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopes[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.purchase_dec`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].purchase_dec = value

                                    setValue(`scopes.${index}.purchase_dec`, formatValue)

                                    handleChangeValue(index, 'purchase_dec')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopes[index].only_view_yearly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.purchase_yearly`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].purchase_yearly = value

                                    setValue(
                                      `scopes.${index}.purchase_yearly`,
                                      formatValue
                                    )

                                    handleChangeValue(index, 'purchase_yearly')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 150, textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopes.${index}.emission_tco2_mwh`)}
                                onChange={(event) => {
                                  const formatValue = currency(event);
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes[index].emission_tco2_mwh = value

                                  setValue(
                                    `scopes.${index}.emission_tco2_mwh`,
                                    formatValue
                                  )

                                  handleChangeValue(index, 'emission_tco2_mwh')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 150, textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopes.${index}.emission_tch4_mwh`)}
                                onChange={(event) => {
                                  const formatValue = currency(event);
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes[index].emission_tch4_mwh = value

                                  setValue(
                                    `scopes.${index}.emission_tch4_mwh`,
                                    formatValue
                                  )

                                  handleChangeValue(index, 'emission_tch4_mwh')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 150, textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopes.${index}.emission_tn2o_mwh`)}
                                onChange={(event) => {
                                  const formatValue = currency(event);
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes[index].emission_tn2o_mwh = value

                                  setValue(
                                    `scopes.${index}.emission_tn2o_mwh`,
                                    formatValue
                                  )

                                  handleChangeValue(index, 'emission_tn2o_mwh')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 150, textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopes.${index}.emission_tco2_bio_mwh`)}
                                onChange={(event) => {
                                  const formatValue = currency(event);
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes[index].emission_tco2_bio_mwh = value

                                  setValue(
                                    `scopes.${index}.emission_tco2_bio_mwh`,
                                    formatValue
                                  )

                                  handleChangeValue(index, 'emission_tco2_bio_mwh')
                                }}
                              />
                            </td>
                            <td style={{ width: 150 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 150,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(
                                  scopes[index].emission_suggested_tco2_mwh,
                                  false
                                )}
                              </span>
                            </td>
                            <td style={{ width: 150 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 150,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(
                                  scopes[index].emission_suggested_tch4_mwh,
                                  false
                                )}
                              </span>
                            </td>
                            <td style={{ width: 150 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 150,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(
                                  scopes[index].emission_suggested_tn2o_mwh,
                                  false
                                )}
                              </span>
                            </td>
                            <td style={{ width: 150 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 150,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(
                                  scopes[index].emission_suggested_tco2_bio_mwh,
                                  false
                                )}
                              </span>
                            </td>
                            <td style={{ width: 150 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 150,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes[index].purchase_totals_mwh, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes[index].emission_co2, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes[index].emission_ch4, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes[index].emission_n2o, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes[index].emission_tco2e, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes[index].emission_tco2e_bio, false)}
                              </span>
                            </td>
                            {!isCommonUser && (
                              <td style={{ textAlign: 'center' }}>
                                <div
                                  onClick={() => handleDeleteScope(index)}
                                  style={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    width: 70
                                  }}
                                >
                                  <img alt="deleteIcon" src={TrashIcon} />
                                </div>
                              </td>
                            )}
                          </tr>
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                  <tfoot>
                    <tr style={{ fontWeight: 'bold' }}>
                      <td
                        colSpan={6}
                        className="head-tr-item"
                        style={{ paddingRight: 5, textAlign: 'right' }}
                      >
                        {'Total'}
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseJan, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseFeb, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseMar, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseApr, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseMay, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseJun, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseJul, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseAug, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseSep, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseOct, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseNov, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseDec, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        {purchaseYearly > 0 && (
                          <span
                            style={{
                              marginRight: 5,
                              width: 100,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(purchaseYearly, false)}
                          </span>
                        )}
                      </td>
                      <td className="head-tr-item" colSpan={8} />
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 150,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseTotalsMwh, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(emissionCo2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(emissionCh4, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(emissionN2o, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(emissionTco2e, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(emissionTco2eBio, false)}
                        </span>
                      </td>
                      {!isCommonUser && <td className="head-tr-item" />}
                    </tr>
                  </tfoot>
                </table>
              )}

              {!scopes.length && (
                <Uninformed
                  text={
                    isCommonUser
                      ? `Nenhum tipo de fonte de energia cadastrada`
                      : `Nenhum tipo de fonte de energia foi selecionada, favor selecionar para
                    dar prosseguimento`
                  }
                />
              )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  marginBottom: 10
                }}
              >
                {errors && errors.scopes && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      margin: 10
                    }}
                  >
                    <span
                      className="requiredLabel"
                      style={{ fontSize: 14, marginTop: 10 }}
                    >
                      * Registro da fonte e Descrição da fonte são obrigatórios
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 10,
                justifyContent: 'space-between',
                marginBottom: 0
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                {passo02 && passo02.label && (
                  <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                    {passo02.label}
                  </span>
                )}
                <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5}} >
                  Clique, ao lado em novo registro, para adicionar nova linha
                </span>
              </div>

              {passo02 && passo02.guidelines && (
                <div className="tooltip" style={{ paddingTop: 10, marginLeft: 40 }}>
                  <img alt="infoButton" src={InfoButtonMin} style={{ paddingTop: 10, width: 28 }} />
                  <span className="tooltiptext">{passo02.guidelines}</span>
                </div>
              )}

              {!isCommonUser && (
                <div style={{ textAlign: 'right', marginRight: 20, width: '30%' }}>
                  <button
                    type="button"
                    style={{
                      width: '160px',
                      height: '47px',
                      background: '#ffffff',
                      color: '#31363F',
                      borderColor: '#9E9E9E',
                      borderRadius: '2.5px',
                      cursor: 'pointer',
                      borderStyle: 'dashed',
                      fontWeight: 'bold',
                      borderWidth: '1px'
                    }}
                    onClick={() => handleNewItem()}
                  >
                    {`Novo registro`}
                  </button>
                </div>
              )}
            </div>

            <div
              style={{
                overflow: 'auto',
                position: 'relative',
                marginTop: 20,
                width: 'calc(100vw - 320px)',
                maxHeight: 'calc(100vh - 360px)'
              }}
            >
              {changeScope2 && scopes2 && scopes2.length > 0 && (
                <table
                  style={{
                    maxWidth: '100%',
                    width: '4000px',
                    borderCollapse: 'collapse'
                  }}
                  className="table-scope"
                >
                  <thead>
                    <tr>
                      <th className="head-tr-item" style={{ width: 360 }} colSpan={2}>
                        {'Fonte'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} colSpan={13}>
                        {
                          'Eletricidade Comprada (MWh) para a qual não possua rastreamento da origem'
                        }
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                        {'Emissões totais de CO₂ (t)'}
                      </th>
                      {!isCommonUser && (
                        <th className="head-tr-item" style={{ width: 50 }} rowSpan={3} >
                          {!isCommonUser && (
                              <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                                <div onClick={handleOpenModalUntracked}>
                                  <button style={{background: '#b81414', color: 'white', borderRadius: 5, cursor:'pointer'}}>Apagar tudo</button>
                                </div>
                              </td>
                            )}
                        </th>
                      )}
                    </tr>
                    <tr>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                      >
                        {'Registro'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                      >
                        {'Descrição'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} colSpan={12}>
                        {'Relate aqui a compra mensal de eletricidade (MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 250 }}
                        rowSpan={2}
                      >
                        {'Relate aqui a compra anual de eletricidade (MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={2}
                      >
                        {'Emissões de CO₂ (t)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={2}
                      >
                        {'Emissões de CH₄ (t)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={2}
                      >
                        {'Emissões de N₂O (t)'}
                      </th>
                    </tr>
                    <tr>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Janeiro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Fevereiro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Março'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Abril'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Maio'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Junho'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Julho'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Agosto'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Setembro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Outubro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Novembro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Dezembro'}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {scopes2.map((item: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 200 }}
                                className="input-item"
                                {...register(`scopes2.${index}.source`, {
                                  required: true
                                })}
                                onChange={(event) => {
                                  setValue(`scopes2.${index}.source`, event.target.value)
                                  scopes2[index].source = event.target.value
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 200 }}
                                className="input-item"
                                {...register(`scopes2.${index}.description`, {
                                  required: true
                                })}
                                onChange={(event) => {
                                  setValue(
                                    `scopes2.${index}.description`,
                                    event.target.value
                                  )
                                  scopes2[index].description = event.target.value
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 100, textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopes2.${index}.purchase_jan`)}
                                onChange={(event) => {
                                  const formatValue = currency(event);
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes2[index].purchase_jan = value

                                  setValue(`scopes2.${index}.purchase_jan`, formatValue)

                                  handleChangeValue2(index, 'purchase_jan')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 100, textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopes2.${index}.purchase_feb`)}
                                onChange={(event) => {
                                  const formatValue = currency(event);
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes2[index].purchase_feb = value

                                  setValue(`scopes2.${index}.purchase_feb`, formatValue)

                                  handleChangeValue2(index, 'purchase_feb')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 100, textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopes2.${index}.purchase_mar`)}
                                onChange={(event) => {
                                  const formatValue = currency(event);
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes2[index].purchase_mar = value

                                  setValue(`scopes2.${index}.purchase_mar`, formatValue)

                                  handleChangeValue2(index, 'purchase_mar')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 100, textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopes2.${index}.purchase_apr`)}
                                onChange={(event) => {
                                  const formatValue = currency(event);
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes2[index].purchase_apr = value

                                  setValue(`scopes2.${index}.purchase_apr`, formatValue)

                                  handleChangeValue2(index, 'purchase_apr')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 100, textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopes2.${index}.purchase_may`)}
                                onChange={(event) => {
                                  const formatValue = currency(event);
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes2[index].purchase_may = value

                                  setValue(`scopes2.${index}.purchase_may`, formatValue)

                                  handleChangeValue2(index, 'purchase_may')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 100, textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopes2.${index}.purchase_jun`)}
                                onChange={(event) => {
                                  const formatValue = currency(event);
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes2[index].purchase_jun = value

                                  setValue(`scopes2.${index}.purchase_jun`, formatValue)

                                  handleChangeValue2(index, 'purchase_jun')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 100, textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopes2.${index}.purchase_jul`)}
                                onChange={(event) => {
                                  const formatValue = currency(event);
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes2[index].purchase_jul = value

                                  setValue(`scopes2.${index}.purchase_jul`, formatValue)

                                  handleChangeValue2(index, 'purchase_jul')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 100, textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopes2.${index}.purchase_aug`)}
                                onChange={(event) => {
                                  const formatValue = currency(event);
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes2[index].purchase_aug = value

                                  setValue(`scopes2.${index}.purchase_aug`, formatValue)

                                  handleChangeValue2(index, 'purchase_aug')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 100, textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopes2.${index}.purchase_sep`)}
                                onChange={(event) => {
                                  const formatValue = currency(event);
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes2[index].purchase_sep = value

                                  setValue(`scopes2.${index}.purchase_sep`, formatValue)

                                  handleChangeValue2(index, 'purchase_sep')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 100, textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopes2.${index}.purchase_oct`)}
                                onChange={(event) => {
                                  const formatValue = currency(event);
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes2[index].purchase_oct = value

                                  setValue(`scopes2.${index}.purchase_oct`, formatValue)

                                  handleChangeValue2(index, 'purchase_oct')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 100, textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopes2.${index}.purchase_nov`)}
                                onChange={(event) => {
                                  const formatValue = currency(event);
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes2[index].purchase_nov = value

                                  setValue(`scopes2.${index}.purchase_nov`, formatValue)

                                  handleChangeValue2(index, 'purchase_nov')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 100, textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopes2.${index}.purchase_dec`)}
                                onChange={(event) => {
                                  const formatValue = currency(event);
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes2[index].purchase_dec = value

                                  setValue(`scopes2.${index}.purchase_dec`, formatValue)

                                  handleChangeValue2(index, 'purchase_dec')
                                }}
                              />
                            </td>
                            <td>
                              {scopes2[index].only_view_yearly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopes2.${index}.purchase_yearly`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes2[index].purchase_yearly = value

                                    setValue(
                                      `scopes2.${index}.purchase_yearly`,
                                      formatValue
                                    )

                                    handleChangeValue2(index, 'purchase_yearly')
                                  }}
                                />
                              )}
                              {scopes2[index].only_view_yearly === true && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                ></span>
                              )}
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes2[index].emission_co2, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes2[index].emission_ch4, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes2[index].emission_n2o, false)}
                              </span>
                            </td>
                            {!isCommonUser && (
                              <td style={{ textAlign: 'center' }} rowSpan={2}>
                                <div
                                  onClick={() => handleDeleteScope2(index)}
                                  style={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    width: 70
                                  }}
                                >
                                  <img alt="deleteIcon" src={TrashIcon} />
                                </div>
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td
                              colSpan={2}
                              className="subhead-item"
                              style={{ paddingRight: 5, textAlign: 'right' }}
                            >
                              {'Emissões mensais de CO₂ (t)'}
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes2[index].emission_co2_jan, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes2[index].emission_co2_feb, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes2[index].emission_co2_mar, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes2[index].emission_co2_apr, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes2[index].emission_co2_may, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes2[index].emission_co2_jun, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes2[index].emission_co2_jul, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes2[index].emission_co2_aug, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes2[index].emission_co2_sep, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes2[index].emission_co2_oct, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes2[index].emission_co2_nov, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5(scopes2[index].emission_co2_dec, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }}>
                              {scopes2[index].only_view_yearly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(
                                    scopes2[index].emission_co2_yearly,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                  <tfoot>
                    <tr style={{ fontWeight: 'bold' }}>
                      <td
                        colSpan={2}
                        className="head-tr-item"
                        style={{ paddingRight: 5, textAlign: 'right' }}
                      >
                        {'Total'}
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseJan2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseFeb2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseMar2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseApr2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseMay2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseJun2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseJul2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseAug2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseSep2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseOct2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseNov2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(purchaseDec2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        {purchaseYearly2 > 0 && (
                          <span
                            style={{
                              marginRight: 5,
                              width: 100,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(purchaseYearly2, false)}
                          </span>
                        )}
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(emissionCo2_2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(emissionCh4_2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(emissionN2o_2, false)}
                        </span>
                      </td>
                      {!isCommonUser && <td className="head-tr-item" />}
                    </tr>
                  </tfoot>
                </table>
              )}

              {!scopes2.length && (
                <Uninformed
                  text={
                    isCommonUser
                      ? `Nenhum registro foi informado`
                      : `Nenhum registro foi informado, clique em 'novo' para incluir seu registro.`
                  }
                />
              )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  marginBottom: 10
                }}
              >
                {errors && errors.scopes2 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      margin: 10
                    }}
                  >
                    <span
                      className="requiredLabel"
                      style={{ fontSize: 14, marginTop: 10 }}
                    >
                      * Registro da fonte e Descrição da fonte são obrigatórios
                    </span>
                  </div>
                )}
              </div>
            </div>

            {ignoreFill === false && changeScope && changeScope2 && (
              <div
                style={{
                  borderStyle: 'dashed',
                  margin: 5,
                  borderWidth: '1px',
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 330px)',
                  borderColor: '#9E9E9E',
                  height: '40px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 15
                }}
              >
                <span style={{ margin: 10, width: '70%' }}>
                  Emissões totais em CO₂ equivalente (toneladas métricas):
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    margin: 10,
                    textAlign: 'right',
                    width: '30%'
                  }}
                >
                  {formatPrice3(emissionTco2eTotals, false)}
                </span>
              </div>
            )}

            {ignoreFill === false && changeScope && changeScope2 && (
              <div
                style={{
                  borderStyle: 'dashed',
                  margin: 5,
                  borderWidth: '1px',
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 330px)',
                  borderColor: '#9E9E9E',
                  height: '40px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 15
                }}
              >
                <span style={{ margin: 10, width: '70%' }}>
                  Emissões totais em CO₂ biogênico (toneladas métricas):
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    margin: 10,
                    textAlign: 'right',
                    width: '30%'
                  }}
                >
                  {formatPrice3(emissionTco2eBioTotals, false)}
                </span>
              </div>
            )}

            {ignoreFill === false && (
              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <div
                  style={{
                    width: '100%',
                    borderTop: '0.6px solid rgba(49, 54, 63, 0.35)'
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      marginTop: 5,
                      marginLeft: 10,
                      fontWeight: 'bold'
                    }}
                  >
                    Evidências do escopo informado
                  </span>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                    Observações sobre o escopo informado.
                  </label>

                  <textarea
                    readOnly={isCommonUser}
                    style={{ width: '100%', height: 100 }}
                    {...register('evidence')}
                  />

                  {errors.evidence && (
                    <span className="requiredLabel">Esse campo é obrigatório</span>
                  )}
                  <ScopeEvidenceAttachments
                    files={files}
                    onDeleteFile={handleDeleteFile}
                    onDownloadFile={handleDownloadFile}
                    onChangeAttachment={handleAttachmentChange}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}

    {openModalDeletePurchaseChoice && (
      <ModalDeleteDataTable
        isOpen={openModalDeletePurchaseChoice}
        handleModalVisible={() => setOpenModalDeletePurchaseChoice(false)}
        handleOk={handleDeleteAllScopeChoice}
      />
    )}

    {openModalDeleteUntracked && (
      <ModalDeleteDataTable
        isOpen={openModalDeleteUntracked}
        handleModalVisible={() => setOpenModalDeleteUntracked(false)}
        handleOk={handleDeleteAllScopeUntracked}
      />
    )}
    </Container>
  )
}
