import styled from 'styled-components';

export const Container = styled.div `
    padding: 0px;
    margin: 15px;
    width: 100%;

    .form-body{
        display:flex;
        flex-direction: column;
    }

    .div-head { 
        display: flex;
        flex-direction: row;
        margin-top: 25px;
        justify-content: space-between; 
        margin-bottom: 20px; 
    }

    .title{
        display: flex; 
        flex-direction: column;
    }

    .div-guidance{
        display: flex; 
        flex-direction: row;
    }

    .div-checkbox{
        display: flex; 
        flex-direction: row; 
        vertical-align: middle;
        height: 30px;
        margin-bottom: 10px; 
    }

    .input-check{
        height: 20px;
        width: 20px; 
        margin: 10px;
    }

    .label-text{
        font-family: 'Roboto', sans-serif;
        font-size: 16px; 
        line-height: 19px; 
        color: #31363F;
        opacity: 0.8px; 
        margin-top: 10px;
    }
    
    .div-justify {
        display: flex;
        flex-direction: column; 
        margin: 10px;
    }

    .column{
        margin-top: 5px;
        margin-right: 30px;
    }

    .sub-text{
        text-align: start;
        font-size: 16px;
        margin-top: 5px;
        margin-left: 10px;
        font-weight: bold;
    }

    .components{
        overflow: hidden auto;
        position: relative;
        margin-top: 0px;
        height: calc(100vh - 230px);
        max-height: calc(100vh - 230px);
    }

    .table-one{
        display: flex;
        flex-direction: column;
        margin-right: 30px;
        margin-bottom: 10;
    }

    .border-table{
        border-style: dashed;
        margin: 5px;
        border-width: 1px;
        width: 99%;
        border-color: #9E9E9E;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-left: 0px;
    }

    .div-table{
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .span-total{
        font-weight: bold;
        margin: 10px;
        text-align: right;
        width: 30%
    }

    .span-alert{
        display: flex;
        flex-direction: row;
        color: #C42329;
        font-size: 12px;
        font-weight: bold;
        margin-left: 10px;
    }

`