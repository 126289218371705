import { api } from "./api";

async function getById(activityId: number) {
  try {
    const { data } = await api.get(`/activity/${activityId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/activity", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(activity: any) {
  try {
    const { data } = await api.post(`/activity`, activity);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(activityId: number, activity: any) {
  try {
    const { data } = await api.put(`/activity/${activityId}`, activity);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(activityId: number) {
  try {
    const { data } = await api.delete(`/activity/${activityId}`);
    return data;
  } catch (error) {
    return false;
  }
}

const activityService = {
  getById,
  getByParams,
  create,
  updateById,
  deleteById
};

export default activityService;
