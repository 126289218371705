import { api } from "./api";

async function getById(airportId: number) {
  try {
    const { data } = await api.get(`/airport/${airportId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/airport", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(airport: any) {
  try {
    const { data } = await api.post(`/airport`, airport);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(airportId: number, airport: any) {
  try {
    const { data } = await api.put(`/airport/${airportId}`, airport);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(airportId: number) {
  try {
    const { data } = await api.delete(`/airport/${airportId}`);
    return data;
  } catch (error) {
    return false;
  }
}

const airportService = {
  getById,
  getByParams,
  create,
  updateById,
  deleteById
};

export default airportService;
