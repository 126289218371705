
import styled from 'styled-components';
import { Button } from '../../components/Button/styles'

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom: 20px;
`;

export const TooltipText = styled.span`
    font-family: "Roboto";
    visibility: hidden;
    width: 250px;
    background-color: #31363F;
    color: #fff;
    text-align: left;
    padding: 5px;
    border-radius: 2px;
    position: absolute;
    transition: all .4s ease;
    z-index: 1;
    opacity: 0.9;
    bottom: 100%;  // ou "top: 100%;" se você quiser que apareça abaixo do ícone
    left: 50%;
    transform: translateX(-50%);
`;

export const TooltipIcon = styled.span`
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #31363F;
    font-family: "Roboto";
    font-size: 14px;
    transition: all 0.4s ease;
    cursor: pointer;
    margin-left: auto;

    &:hover ${TooltipText} {
        visibility: visible;
    }
`;

export const StyledButton = styled(Button)`
  width: 120px;
  // Adicione outros estilos que você deseja
`;