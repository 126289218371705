import { api } from "./api";

async function getItem() {
  try {
    const { data } = await api.get(`/terms`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function save(terms: any) {
  try {
    const { data } = await api.post(`/terms`, terms);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getHistory() {
  try {
    const { data } = await api.get(`/terms/history`);

    return data
  } catch (error) {
    console.log(error);
  }
}

const termsService = {
  getItem,
  save,
  getHistory
};

export default termsService;
