import Parser from 'html-react-parser'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import useAuth from '../../../../../hooks/useAuth'
import { Container } from './styles'
import { formatPrice3, formatPrice5 } from '../../../../../utils/transform'
import inventoryService from '../../../../../services/inventoryService'
import scope1Service from '../../../../../services/scope1Service'
import scopeService from '../../../../../services/scopeService'
import { Button } from '../../../../../components/Button/styles'
import { Modal } from '../../../../../components/Modal'
import { GoBack } from '../../../../../components/GoBack'
import { TitleText } from '../../../../../components/TitleText'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'
import { toast } from 'react-hot-toast'
import { Loading } from '../../../../../components/Loading'

export const AnaerobicCompost = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([])
  const [openModal, setOpenModal] = useState(false)
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [requiredItens, setRequiredItens] = useState(false)
  const [guidelines, setGuidelines] = useState<string | undefined>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const location = useLocation()

  const [emissionCh4, setEmissionCh4] = useState<any>()
  const [emissionN2o, setEmissionN2o] = useState<any>()
  const [emissionTco2, setEmissionTco2] = useState<any>()
  const [emissionCo2Bio, setEmissionCo2Bio] = useState<any>()

  const [changeScope, setChangeScope] = useState<string | undefined>()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-1/residuos-solidos'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(1, 'residuos_solidos', 'compostagem_anaerobica')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors }
  } = useForm<any>()

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      let biogas_destination = formData.biogas_destination
      let compost_mass = formData.compost_mass.replace('.', '')
      let emission_factor_ch4 = formData.emission_factor_ch4
      let emission_factor_n2o = formData.emission_factor_n2o
      let amount_recovered_ch4 = formData.amount_recovered_ch4

      biogas_destination = biogas_destination ? parseInt(biogas_destination) : 1
      compost_mass = compost_mass ? parseFloat(compost_mass.replace(',', '.')) : 0
      emission_factor_ch4 = emission_factor_ch4 ? parseFloat(emission_factor_ch4.replace(',', '.')) : 0
      emission_factor_n2o = emission_factor_n2o ? parseFloat(emission_factor_n2o.replace(',', '.')) : 0
      amount_recovered_ch4 = amount_recovered_ch4 ? parseFloat(amount_recovered_ch4.replace(',', '.')) : 0

      const scope = {
        reason: formData.reason,
        compost_mass: compost_mass,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        biogas_destination: biogas_destination ? biogas_destination : 0,
        emission_factor_ch4: emission_factor_ch4 ? emission_factor_ch4 : 0,
        emission_factor_n2o: emission_factor_n2o ? emission_factor_n2o : 0,
        amount_recovered_ch4: amount_recovered_ch4 ? amount_recovered_ch4 : 0,
        emission_ch4: emissionCh4 ? emissionCh4 : 0,
        emission_n2o: emissionN2o ? emissionN2o : 0,
        emission_tco2e: emissionTco2 ? emissionTco2 : 0,
        emission_co2_bio: emissionCo2Bio ? emissionCo2Bio : 0
      }

      const result = await scope1Service.saveAnaerobicCompost(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('AnaerobicCompost', JSON.stringify(scope));

        reset()
        setErrorApi(undefined)

        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  const { isCommonUser } = useAuth()

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope1Service.getAnaerobicCompost(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      1,
      'residuos_solidos',
      'compostante_anaerobica'
    )

    setFiles(result.files)

    if (entity) {
      setScopeSelected(entity)

      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)

      setIgnoreFill(entity.ignore_fill)

      setValue('biogas_destination', entity.biogas_destination)
      setValue('compost_mass', formatPrice5(entity.compost_mass, false))
      setValue('emission_factor_ch4', formatPrice5(entity.emission_factor_ch4, false))
      setValue('emission_factor_n2o', formatPrice5(entity.emission_factor_n2o, false))
      setValue('amount_recovered_ch4', formatPrice5(entity.amount_recovered_ch4, false))

      setEmissionCh4(entity.emission_ch4)
      setEmissionN2o(entity.emission_n2o)
      setEmissionTco2(entity.emission_tco2e)
      setEmissionCo2Bio(entity.emission_co2_bio)

      setChangeScope('load_scope_' + entity.id)
    } else {
      setScopeSelected({})

      setIgnoreFill(false)

      setChangeScope('load_scope_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  const handleChangeValue = async () => {
    let compost_mass: string | number = getValues('compost_mass');
    let biogas_destination: string | number = getValues('biogas_destination');
    let emission_factor_ch4: string | number = getValues('emission_factor_ch4');
    let emission_factor_n2o: string | number = getValues('emission_factor_n2o');
    let amount_recovered_ch4: string | number = getValues('amount_recovered_ch4');

    if (typeof compost_mass === "string") {
      compost_mass = compost_mass.replace(/\./g, '');
      const dynamicString = 'change_scope_item_' + compost_mass;
    }  
  
    if (typeof emission_factor_ch4 === "string") {
      emission_factor_ch4 = emission_factor_ch4.replace('.', '');
    }
  
    if (typeof emission_factor_n2o === "string") {
      emission_factor_n2o = emission_factor_n2o.replace('.', '');
    }
  
    if (typeof amount_recovered_ch4 === "string") {
      amount_recovered_ch4 = amount_recovered_ch4.replace('.', '');
    }
  
    biogas_destination = biogas_destination ? parseInt(biogas_destination.toString()) : 1;
    compost_mass = compost_mass ? parseFloat(compost_mass.toString().replace(',', '.')) : 0;
    emission_factor_ch4 = emission_factor_ch4 ? parseFloat(emission_factor_ch4.toString().replace(',', '.')) : 0;
    emission_factor_n2o = emission_factor_n2o ? parseFloat(emission_factor_n2o.toString().replace(',', '.')) : 0;
    amount_recovered_ch4 = amount_recovered_ch4 ? parseFloat(amount_recovered_ch4.toString().replace(',', '.')) : 0;

      const result = await scope1Service.calculateAnaerobicCompost(
        compost_mass,
        emission_factor_ch4,
        emission_factor_n2o,
        amount_recovered_ch4,
        biogas_destination
      )

      if (result) {
        setEmissionCh4(result.emission_ch4)
        setEmissionN2o(result.emission_n2o)
        setEmissionTco2(result.emission_tco2e)
        setEmissionCo2Bio(result.emission_co2_bio)

        setChangeScope(
          'change_scope_item_' +
            result.emission_ch4 +
            '_' +
            result.emission_n2o +
            '_' +
            result.emission_co2e +
            '_' +
            result.emission_co2_bio
        );
      }
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d)(\d{5})$/, '$1,$2')
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')
    return value
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'residuos_solidos',
          'compostante_anaerobica'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const handleDownloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const handleAttachmentChange = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 1,
        name: 'residuos_solidos',
        file: base64,
        filename: filename,
        second_name: 'compostante_anaerobica'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'residuos_solidos',
          'compostante_anaerobica'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 01 - ' + title}  />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />

        {!isCommonUser && (
          <ReportRecordsCheckbox
            setIgnoreFill={setIgnoreFill}
            register={register}
          />
        )}

        {ignoreFill && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 230px)',
            maxHeight: 'calc(100vh - 230px)'
          }}
        >
          {!ignoreFill && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    fontWeight: 'bold'
                  }}
                >
                  Quantidade de resíduo destinado a compostagem (caso não haja
                  compostagem, deixe esta seção em branco)
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5 }}>
                    Massa de resíduo* destinado à compostagem [t/ano]
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                  <input
                    readOnly={isCommonUser}
                    style={{ width: '200px', height: 35, textAlign: 'right' }}
                    {...register('compost_mass')}
                    onChange={(event) => {
                      setValue('compost_mass', currency(event))
                      handleChangeValue()
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    fontWeight: 'bold'
                  }}
                >
                  Outros elementos
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5 }}>
                    Fator de emissão de CH₄ [gCH₄/kg resíduo]
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                  <input
                    readOnly={isCommonUser}
                    style={{ width: '200px', height: 35, textAlign: 'right' }}
                    {...register('emission_factor_ch4')}
                    onChange={(event) => {
                      setValue('emission_factor_ch4', currency(event))
                      handleChangeValue()
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5 }}>
                    Fator de emissão de N₂O [gN₂O/kg resíduo]
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                  <input
                    readOnly={isCommonUser}
                    style={{ width: '200px', height: 35, textAlign: 'right' }}
                    {...register('emission_factor_n2o')}
                    onChange={(event) => {
                      setValue('emission_factor_n2o', currency(event))
                      handleChangeValue()
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    fontWeight: 'bold'
                  }}
                >
                  Recuperação de CH₄
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5 }}>
                    Quantidade de CH₄ recuperada do tratamento por compostagem [tCH₄/ano]
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                  <input
                    readOnly={isCommonUser}
                    style={{ width: '200px', height: 35, textAlign: 'right' }}
                    {...register('amount_recovered_ch4')}
                    onChange={(event) => {
                      setValue('amount_recovered_ch4', currency(event))
                      handleChangeValue()
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5 }}>
                    No ano do inventário, qual o destino do biogás recuperado?
                  </span>
                </div>
                <span
                  style={{
                    textAlign: 'center',
                    fontSize: 14,
                    padding: 5,
                    marginTop: 0,
                    width: 200,
                    borderBottom: '1px solid black'
                  }}
                >
                  <select
                    disabled={isCommonUser}
                    style={{ borderWidth: '0px', width: '200px', height: '25px' }}
                    {...register('biogas_destination')}
                    onChange={(event) => {
                      setValue('biogas_destination', event.target.value)
                      handleChangeValue()
                    }}
                  >
                    <option value="1" label="Queima de flare" />
                    <option value="2" label="Geração de energia" />
                  </select>
                </span>
              </div>

              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginBottom: 10,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    fontWeight: 'bold'
                  }}
                >
                  Emissões totais de resíduos tratados por compostagem no ano inventariado
                </span>
              </div>

              {changeScope && (
                <div
                  style={{
                    borderStyle: 'dashed',
                    margin: 5,
                    borderWidth: '1px',
                    width: '100%',
                    borderColor: '#9E9E9E',
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 0
                  }}
                >
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <span style={{ margin: 10, width: '70%' }}>
                      Emissões de CH₄ por compostagem [tCH₄/ano]:
                    </span>
                    <span
                      style={{
                        fontWeight: 'bold',
                        margin: 10,
                        textAlign: 'right',
                        width: '30%'
                      }}
                    >
                      {formatPrice3(emissionCh4, false)}
                    </span>
                  </div>

                  {emissionCh4 !== undefined && emissionCh4 < 0 && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        color: '#C42329',
                        fontSize: 12,
                        fontWeight: 'bold',
                        marginLeft: 10
                      }}
                    >
                      O dado de CH₄ recuperado é maior que o de CH₄ gerado. Insira um dado
                      válido
                    </div>
                  )}
                </div>
              )}

              {changeScope && (
                <div
                  style={{
                    borderStyle: 'dashed',
                    margin: 5,
                    borderWidth: '1px',
                    width: '100%',
                    borderColor: '#9E9E9E',
                    height: '40px',
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 0
                  }}
                >
                  <span style={{ margin: 10, width: '70%' }}>
                    Emissões de N₂O por compostagem [tN₂O/ano]:
                  </span>
                  <span
                    style={{
                      fontWeight: 'bold',
                      margin: 10,
                      textAlign: 'right',
                      width: '30%'
                    }}
                  >
                    {formatPrice3(emissionN2o, false)}
                  </span>
                </div>
              )}

              {changeScope && (
                <div
                  style={{
                    borderStyle: 'dashed',
                    margin: 5,
                    borderWidth: '1px',
                    width: '100%',
                    borderColor: '#9E9E9E',
                    height: '40px',
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 0
                  }}
                >
                  <span style={{ margin: 10, width: '70%' }}>
                    Emissões em tCO₂e por compostagem [tCO₂e/ano]:
                  </span>
                  <span
                    style={{
                      fontWeight: 'bold',
                      margin: 10,
                      textAlign: 'right',
                      width: '30%'
                    }}
                  >
                    {formatPrice3(emissionTco2, false)}
                  </span>
                </div>
              )}

              {changeScope && (
                <div
                  style={{
                    borderStyle: 'dashed',
                    margin: 5,
                    borderWidth: '1px',
                    width: '100%',
                    borderColor: '#9E9E9E',
                    height: '40px',
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 0
                  }}
                >
                  <span style={{ margin: 10, width: '70%' }}>
                    Emissões de CO₂ biogênico [tCO₂/ano]:
                  </span>
                  <span
                    style={{
                      fontWeight: 'bold',
                      margin: 10,
                      textAlign: 'right',
                      width: '30%'
                    }}
                  >
                    {formatPrice3(emissionCo2Bio, false)}
                  </span>
                </div>
              )}

              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <div
                  style={{
                    width: '100%',
                    borderTop: '0.6px solid rgba(49, 54, 63, 0.35)'
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      marginTop: 5,
                      marginLeft: 10,
                      fontWeight: 'bold'
                    }}
                  >
                    Evidências do escopo informado
                  </span>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                    Observações sobre o escopo informado.
                  </label>

                  <textarea
                    readOnly={isCommonUser}
                    style={{ width: '100%', height: 100 }}
                    {...register('evidence')}
                  />

                  {errors.evidence && (
                    <span className="requiredLabel">Esse campo é obrigatório</span>
                  )}

                  <ScopeEvidenceAttachments
                    files={files}
                    onDeleteFile={handleDeleteFile}
                    onDownloadFile={handleDownloadFile}
                    onChangeAttachment={handleAttachmentChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  )
}
