import { View, Text, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { styles } from "../../styles";

const SUMARY = [
  { title: "Dados da organização", page: "03" },
  { title: "Informações institucionais", page: "04" },
  { title: "Dados do inventário", page: "06" },
  { title: "Limites do Inventário", page: "07" },
  { title: "Resultados", page: "09" },
  { title: "Metas", page: "15" },
  { title: "Estratégias de Descarbonização", page: "17" },
  { title: "Métodos", page: "20" },
  { title: "Outros elementos", page: "21" },
  { title: "Compensações e reduções", page: "23" },
  { title: "Anexos", page: "24" },
];

export const Sumary = () => {
  return (
    <View
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignSelf: "center",
        height: "75%",
        width: "80%",
      }}
    >
      <Text style={styles.sumaryTitle}>Sumário</Text>
      {SUMARY.map((item, idx) => (
        <View style={styles.sumaryItem} key={idx}>
          <Text style={styles.sumaryItemTitle}>{item.title}</Text>
          <Text style={styles.sumaryItemPage}>{item.page}</Text>
        </View>
      ))}
    </View>
  );
};
