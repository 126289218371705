import React, { useRef, useEffect, useState, useCallback } from 'react';
import * as d3 from 'd3';
import { colors } from '../../Dashboard/utils/colors';
import { calculateCMAandEmissions, svgToPngBase64 } from '../../../utils/utils';
import { MACCChartPropsPDF } from '../interfaces/MaccProjetcs';


function makeYGridlines(yScale: any, width: number) {
  return d3.axisLeft(yScale)
      .ticks(3)
      .tickSize(-width)
      .tickFormat(() => "")
}  
const createFormatter = (decimalPlaces: number) => {
  return d3.formatLocale({
    decimal: ",",
    thousands: ".",
    grouping: [3],
    currency: ["R$", ""],
  }).format(`,.${decimalPlaces}f`);
}
const formatNumber0 = createFormatter(0);
const formatNumber2 = createFormatter(2);
export const MACCChartPDF: React.FC<MACCChartPropsPDF> = ({ projects,  onChangeImage }) => {
  const [chartBase64Image, setChartBase64Image] = useState<string>()
  const ref: any = useRef()
  const updateImage = useCallback(() => {
    if (ref.current) {
      svgToPngBase64(ref.current)
            .then(base64Image => {              
                setChartBase64Image(base64Image as string);
                // console.log(base64Image);
            })
            .catch(error => {
                console.error("Error converting SVG to Base64:", error);
            });
    }
  }, []);  
  useEffect(() => {
    onChangeImage(chartBase64Image);
  }, [chartBase64Image, onChangeImage]);
  const { calculatedCMA, cumulativeCOAvoided} = calculateCMAandEmissions(projects); 
  // Definicao do tamanho da plotagem do grafico
  const width = 1920;
  const height = 800;
  let margin = { top: 20, right: 60, bottom: 40, left: 60 };
  // Sort de tudo
  const sortedCostsWithProjects= calculatedCMA.filter(cost => cost.cost).sort((a, b) => a.cost - b.cost);    
  const sortedCosts = sortedCostsWithProjects.map(item => item.cost);
  const sortedProjects = sortedCostsWithProjects.map(item => item.projectObj);
  const sortedtCO2 = sortedCostsWithProjects.map(item => item.tCO2);
  // Definicao dos valores maximos e minimos do eixo Y (sempre em base 10)
  // Calcula os valores arredondados e ajusta conforme necessário em uma única etapa com operadores ternários.
  const maxValue = d3.max(sortedCosts) || 0;
  const minValue = d3.min(sortedCosts) || 0;
  let roundingBaseMax = 10;
  let roundingBaseMin = 10;
  // Para maxValue
  if (maxValue > 10 && maxValue <= 100) {
      roundingBaseMax = 10 + maxValue;
  } else if (maxValue > 100 && maxValue <= 1000) {
      roundingBaseMax = 100 + maxValue;
  } else if (maxValue > 1000) {
      roundingBaseMax = 1000 + maxValue;
      margin = { top: 20, right: 320, bottom: 40, left: 180 };
  }   
  // Para minValue
  if (Math.abs(minValue) > 10 && Math.abs(minValue) <= 100) {
      roundingBaseMin = 10 - minValue;
  } else if (Math.abs(minValue) > 100 && Math.abs(minValue) <= 1000) {
      roundingBaseMin = 100 - minValue;
  } else if (Math.abs(minValue) > 1000) {
      roundingBaseMin = 1000 - minValue;
      margin = { top: 20, right: 320, bottom: 40, left: 180 };
  } 
  const calcCeilMax = Math.ceil(maxValue / roundingBaseMax) * roundingBaseMax;
  const calcFloorMin = Math.floor(minValue / roundingBaseMin) * roundingBaseMin;
  const roundedMaxValue = calcCeilMax < 10 ? 10 : calcCeilMax;
  const roundedMinValue = calcFloorMin > 0 ? -10 : calcFloorMin; 
  // Definicao dos dados das barras, tooltips, legendas e labels
  const barData = sortedCosts.map((cost, i) => ({
    cost,
    projectBar: sortedCostsWithProjects[i]
  }));    
  // Array das cores
  const colorArray = [
    colors.SkyBlueMedium,
    colors.LightMintGreen,
    colors.NeutralGray,
    colors.LightBronzeTan,
    colors.GoldenBeige,
    colors.CoralPink,
    colors.PaleGreen,
    colors.SlateBlue,
    colors.DarkSalmon,
    colors.LightSlateGray,
    colors.Orchid,
    colors.DeepSkyBlue,
    colors.SpringGreen,
    colors.Tomato,
    colors.DarkKhaki,
  ];    
  useEffect(() => {    
    d3.select(ref.current).selectAll("*").remove();
    if (ref.current) {   
      // Calculo da proprorção de tudo
      const totalCostWidth = sortedtCO2.reduce((acc, val) => acc + Math.abs(val), 0);
      const proportion = (width - (margin.right + margin.left) ) / totalCostWidth;
      // Valores Eixo Y
      const yScale = d3.scaleSymlog()
                .domain([roundedMinValue, roundedMaxValue])
                .range([height, margin.top]);  
      // Plot da Area
      const svg = d3.select(ref.current)
                .attr("width", width+600)
                .attr("height", height +200)
                .attr("viewBox", `0 0 ${width} ${height}`)   
      const barsAndLabelsGroup = svg.append("g");
      const barMiddlePositions: any[] = [];
      // Plot das barras 
      barsAndLabelsGroup.selectAll("rect")
              .data(barData)
              .enter().append("rect")
              .attr("x", (d, i) => {
                const previousWidths = barData.slice(0, i).map(item => item.projectBar.tCO2).reduce((acc, val) => acc + Math.abs(val) * proportion, 0);
                const currentXPosition = margin.left + previousWidths;
                barMiddlePositions.push(currentXPosition + 0.5 * Math.abs(d.projectBar.tCO2) * proportion);
                return currentXPosition;
             })
              .attr("y", d => d.cost < 0 ? yScale(0) : yScale(d.cost) as number)
              .attr("fill", (d: any, i: number): string => {
                return colorArray[i % colorArray.length];
              })
              .attr("height", d => d.cost < 0 ? yScale(d.cost) - yScale(0) : yScale(0) - yScale(d.cost) as number)
              .attr("width", d => Math.max(1, Math.abs(d.projectBar.tCO2) * proportion))

              // Plot dos separadores horizontais
              barsAndLabelsGroup.selectAll(".separator")
              .data(barData)
              .enter().append("line")
              .attr("class", "separator")
              .attr("x1", (d, i) => {
                  const previousWidths = barData.slice(0, i).map(item => item.projectBar.tCO2).reduce((acc, val) => acc + Math.abs(val) * proportion, 0);
                  return margin.left + previousWidths + Math.abs(d.projectBar.tCO2) * proportion / 1; // Posição no final da barra
              })
              .attr("y1", yScale(0) - 10) // Posição acima do eixo x
              .attr("x2", (d, i) => {
                  const previousWidths = barData.slice(0, i).map(item => item.projectBar.tCO2).reduce((acc, val) => acc + Math.abs(val) * proportion, 0);
                  return margin.left + previousWidths + Math.abs(d.projectBar.tCO2) * proportion / 1; // Posição no final da barra
              })
              .attr("y2", yScale(0) + 8) // Posição abaixo do eixo x
              .attr("stroke", "black") // Cor da linha
              .attr("stroke-width", 6); // Espessura da linha

       // Plot dos grids
       const gridGroup = svg.append("g")
              .attr("class", "grid");
        gridGroup.attr("transform", `translate(${margin.left},0)`)
              // .call(makeYGridlines(yScale, width - margin.left - margin.right))
              // .attr("stroke", "#E0E0E0") // Cor cinza pálida
              // .attr("opacity", 0.2); //
        const gridScales = svg.append("g")
              .attr("class", "grid");
        gridScales.append("g")
              .attr("transform", `translate(${margin.left},0)`)
              .style("font-family", "Montserrat, sans-serif")
              .style("font-size", "0px")
              .style('stroke-width', 5)
              .call(d3.axisLeft(yScale).ticks(0).tickFormat((d) => formatNumber0(d as number))); 
               
        // Valores Eixo X
          const ticksGroup = gridScales.append("g")
          .attr("transform", `translate(0,${height - margin.bottom})`)
          .style("font-family", "Montserrat, sans-serif")
          .style("font-size", "32px")
          .style("fill", "#31363F");
          barMiddlePositions.forEach((pos, index) => {
                    ticksGroup.append("line") 
                            // .attr("x1", pos)
                            // .attr("x2", pos)
                            // .attr("y1", 0)
                            // .attr("y2", 6) 
                            .attr("stroke", "white");
              
                    ticksGroup.append("text") 
                            // .attr("x", pos)
                            // .attr("y", 10) 
                            .attr("dy", ".71em")
                            .attr("text-anchor", "middle")
                            // .text([...cumulativeCOAvoided.map(String)][index]); 
                });
      // Plot Labels para barras negativas 
      barsAndLabelsGroup.selectAll(".label-negative")
                .data(sortedCosts)
                .enter().append("text")
                .attr("class", "label-negative")      
                .attr("x", (d, i) => {
                  if(barData[i].cost < 0){              
                    const previousWidths = barData.slice(0, i).map(item => item.projectBar.tCO2).reduce((acc, val) => acc + Math.abs(val) * proportion, 0);
                    return margin.left + previousWidths + (Math.abs(barData[i].projectBar.tCO2) * proportion) / 2;
                  }
                  return '';
                })
                .attr("y", (d, i) => {         
                  if(barData[i].cost < 0){              
                    return yScale(d) + 5
                  }
                  return '';
                }) // 15 pixels abaixo da barra 
                .attr("text-anchor", "middle")
                .style("font-family", "Montserrat, sans-serif")
                .style("font-size", "0px")
                .style("fill", "#31363F")  
                .text((d, i) => {      
                  if(barData[i].cost < 0){ 
                    return `${formatNumber2(d.toFixed(2) as unknown as number)}`
                  }
                    return '';
                });
      barsAndLabelsGroup.selectAll(".background-rect-negative")
                .data(sortedCosts)
                .enter()
                .insert("rect", ".label-negative") // Insere um retângulo antes do texto da etiqueta
                .attr("class", "background-rect-negative")
                // .attr("x", (d, i) => {
                //   if(barData[i].cost < 0){              
                //     const previousWidths = barData.slice(0, i).map(item => item.projectBar.tCO2).reduce((acc, val) => acc + Math.abs(val) * proportion, 0) -10;
                //     return margin.left + previousWidths + (Math.abs(barData[i].projectBar.tCO2) * proportion) / 2 - 30; // ajuste a posição, se necessário
                //   }
                //   return 0;
                // })
                // .attr("y", (d, i) => {
                //   if(barData[i].cost < 0){              
                //     return yScale(d) - 10// ajuste a posição, se necessário
                //   }
                //   return 0;
                // })
                .attr("width", 80) 
                .attr("height", 22) 
                .attr("fill", "white")
                .attr("opacity", 0.9);
      // Plot Labels para barras positivas
      barsAndLabelsGroup.selectAll(".label-positive")
                .data(sortedCosts)
                .enter().append("text")
                .attr("class", "label-positive")
                // .attr("x", (d, i) => {      
                //   if(barData[i].cost > 0){            
                //     const previousWidths = barData.slice(0, i).map(item => item.projectBar.tCO2).reduce((acc, val) => acc + Math.abs(val) * proportion, 0);
                //     return margin.left + previousWidths + (Math.abs(barData[i].projectBar.tCO2) * proportion) / 2;
                //   }
                //   return '';
                // })
                // .attr("y", (d, i) => {         
                //     if(barData[i].cost > 0){              
                //       return yScale(d) -5
                //     }
                //     return '';
                // }) // 5 pixels acima da barra
                .attr("text-anchor", "middle")
                .style("font-family", "Montserrat, sans-serif")
                .style("font-size", "32px")
                .style("fill", "#31363F")
                // .text((d, i) => {      
                //   if(barData[i].cost > 0){            
                //     return `${formatNumber2(d.toFixed(2) as unknown as number)}`
                //   }
                //     return '';
                // });
        barsAndLabelsGroup.selectAll(".background-rect-positive")
                .data(sortedCosts)
                .enter()
                .insert("rect", ".label-positive")
                .attr("class", "background-rect-positive")
                // .attr("x", (d, i) => {      
                //   if(barData[i].cost > 0){            
                //     const previousWidths = barData.slice(0, i).map(item => item.projectBar.tCO2).reduce((acc, val) => acc + Math.abs(val) * proportion, 0) -80;
                //     return margin.left + previousWidths + (Math.abs(barData[i].projectBar.tCO2) * proportion) / 2;
                //   }
                //   return 0;
                // })
                // .attr("y", (d, i) => {         
                //     if(barData[i].cost > 0){              
                //       return yScale(d) -45
                //     }
                //     return 0;
                // }) 
                // .attr("width", 170) 
                // .attr("height", 60) 
                // .attr("fill", "white")
                // .attr("opacity", 0.8);
      // Plot dos Titulos    
      const titles = svg.append("g");
      const textLines = ["Custo marginal de abatimento","(R$/tCO₂e)"];
      const textGroup = titles.append('g')
                .attr("transform", "rotate(-90)")
                .style("font-family", "Montserrat, sans-serif")
                .style("font-size", "32px")
                .style("font-color", "#31363F")  
                .style("text-anchor", "middle");
      // 'text' para cada linha, ajustando a posição 'y'.
      textLines.forEach((line, index) => {
        textGroup.append("text")
                .attr("dy", `${index + 4}em`) // Isso define a posição de cada linha. 'em' é relativo ao tamanho da fonte.          
                .attr("y", 0 - 10)
                .attr("x", 0 - (height / 2))
                .text(line);
      });
      titles.append("text")
                .attr("y", 700)
                .attr("x", (width / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .style("font-family", "Montserrat, sans-serif")
                .style("font-size", "32px")
                .style("font-color", "#31363F")  
                .text("tCO₂e evitada"); 
      const legendWidth = 150;
      const legendX = width - legendWidth - 1580; // 10 pixels de margem à direita
      const legendY = margin.top + 30;
      //Plot das legendas
      const legend = svg.append("g")
                .attr("transform", `translate(${legendX},${legendY})`);
      const legendHeight = sortedProjects.length * 20; 
      legend.append("rect")
                .attr("class", "legend-background")
                .attr("x", -10) 
                .attr("y", -10) 
                .attr("width", legendWidth + 20) 
                .attr("height", legendHeight + 20) 
                .attr("fill", "white")
                .attr("opacity", 0); 
      legend.selectAll(".color-rect")
                .data(barData)
                .enter().append("rect")
                .attr("class", "color-rect")
                .attr("x", 0)
                .attr("y", (d, i) => i * 32)
                .attr("width", 15)
                .attr("height", 15)
                .attr("fill", (d, i) => colorArray[i % colorArray.length]);
      legend.selectAll("text")
                .data(barData)
                .enter().append("text")
                .attr("x", 35) // 35 pixels à direita do retângulo
                .attr("y", (d, i) => i * 32 + 12) // centralizado verticalmente com o retângulo
                .text(d => d.projectBar.projectObj.project)
                .style("font-family", "Montserrat, sans-serif")
                .style("font-size", "30px")
                .style("fill", "#31363F");
      svg.append("line")
                .style('stroke', 'black')  // Cor da linha
                .style('stroke-width', 4)  // Espessura da linha
                .style("opacity", 1)
                .attr("marker-end", "url(#arrow)")
                .attr("x1", margin.left)
                .attr("y1", yScale(0))
                .attr("x2", width - margin.right + 20)
                .attr("y2", yScale(0)); 
      updateImage();
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps 
  [projects, updateImage]);  
  return <svg ref={ref} style={{ display: 'none' }}></svg>;
  // return <svg ref={ref}></svg>;
}