import axios from "axios";

export const api = axios.create({
    baseURL: 'https://viacep.com.br/ws/',
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
    },
});

async function getCep(cep:string) {
  try {
    const { data } = await api.get(`${cep}/json`);
    return data
  } catch (error) {
    console.log(error);
  }
}

const viacepService = {
  getCep
};

export default viacepService;
