import { api } from "./api";

async function calculateMobileCombustionRoadYear(
  car_fleet_id: number, year: number, year_fleet: number, company_id: number, consumption_jan: number, consumption_feb: number, 
  consumption_mar: number, consumption_apr: number, consumption_may: number, 
  consumption_jun: number, consumption_jul: number, consumption_aug: number, 
  consumption_sep: number, consumption_oct: number, consumption_nov: number, 
  consumption_dec: number, consumption_yearly: number) {
  try {
    const { data } = await api.put(`/scope1/mobile_combustion/road_transport/year/calculate`, 
      { car_fleet_id: car_fleet_id, year: year, year_fleet : year_fleet, company_id: company_id, consumption_jan: consumption_jan, consumption_feb: consumption_feb, 
        consumption_mar: consumption_mar, consumption_apr: consumption_apr, consumption_may: consumption_may, 
        consumption_jun: consumption_jun, consumption_jul: consumption_jul, consumption_aug: consumption_aug, 
        consumption_sep: consumption_sep, consumption_oct: consumption_oct, consumption_nov: consumption_nov, 
        consumption_dec: consumption_dec, consumption_yearly: consumption_yearly });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateMobileCombustionRoadFuel(vehicle_fuel_id: number, year: number, company_id: number, consumption_jan: number, consumption_feb: number, 
    consumption_mar: number, consumption_apr: number, consumption_may: number, consumption_jun: number, 
    consumption_jul: number, consumption_aug: number, consumption_sep: number, consumption_oct: number, 
    consumption_nov: number, consumption_dec: number, consumption_yearly: number) {
  try {
    const { data } = await api.put(`/scope1/mobile_combustion/road_transport/fuel/calculate`, 
      { vehicle_fuel_id: vehicle_fuel_id, year: year, company_id: company_id, consumption_jan: consumption_jan, consumption_feb: consumption_feb, 
        consumption_mar: consumption_mar, consumption_apr: consumption_apr, consumption_may: consumption_may, 
        consumption_jun: consumption_jun, consumption_jul: consumption_jul, consumption_aug: consumption_aug, 
        consumption_sep: consumption_sep, consumption_oct: consumption_oct, consumption_nov: consumption_nov, 
        consumption_dec: consumption_dec, consumption_yearly: consumption_yearly});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateMobileCombustionWaterFuel(vehicle_fuel_id: number, consumption_jan: number, consumption_feb: number, 
    consumption_mar: number, consumption_apr: number, consumption_may: number, consumption_jun: number, 
    consumption_jul: number, consumption_aug: number, consumption_sep: number, consumption_oct: number, 
    consumption_nov: number, consumption_dec: number, consumption_yearly: number) {
  try {
    const { data } = await api.put(`/scope1/mobile_combustion/water_transport/fuel/calculate`, 
      { vehicle_fuel_id: vehicle_fuel_id, consumption_jan: consumption_jan, consumption_feb: consumption_feb, 
        consumption_mar: consumption_mar, consumption_apr: consumption_apr, consumption_may: consumption_may, 
        consumption_jun: consumption_jun, consumption_jul: consumption_jul, consumption_aug: consumption_aug, 
        consumption_sep: consumption_sep, consumption_oct: consumption_oct, consumption_nov: consumption_nov, 
        consumption_dec: consumption_dec, consumption_yearly: consumption_yearly});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateMobileCombustionAirFuel(vehicle_fuel_id: number, consumption_yearly: number) {
  try {
    const { data } = await api.put(`/scope1/mobile_combustion/air_transport/fuel/calculate`, 
      { vehicle_fuel_id: vehicle_fuel_id, consumption_yearly: consumption_yearly});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateMobileCombustionRoadDistance(year_fleet: number, company_id: number, year: number, car_fleet_id: number, 
    distance_jan: number, distance_feb: number, distance_mar: number, distance_apr: number, 
    distance_may: number, distance_jun: number, distance_jul: number, distance_aug: number, 
    distance_sep: number, distance_oct: number, distance_nov: number, distance_dec: number, 
    distance_yearly: number) {
  try {
    const { data } = await api.put(`/scope1/mobile_combustion/road_transport/distance/calculate`, 
      { year_fleet: year_fleet, year: year, company_id: company_id, car_fleet_id: car_fleet_id, distance_jan: distance_jan, distance_feb: distance_feb, 
        distance_mar: distance_mar, distance_apr: distance_apr, distance_may: distance_may, 
        distance_jun: distance_jun, distance_jul: distance_jul, distance_aug: distance_aug, 
        distance_sep: distance_sep, distance_oct: distance_oct, distance_nov: distance_nov, 
        distance_dec: distance_dec, distance_yearly: distance_yearly});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getMobileCombustion(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/mobile_combustion/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getMobileCombustionRoad(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/mobile_combustion/road_transport/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getMobileCombustionWater(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/mobile_combustion/water_transport/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getMobileCombustionOther(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/mobile_combustion/other/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getMobileCombustionAir(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/mobile_combustion/air_transport/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveMobileCombustionOther(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/mobile_combustion/other/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveMobileCombustionRoad(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/mobile_combustion/road_transport/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveMobileCombustionWater(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/mobile_combustion/water_transport/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveMobileCombustionAir(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/mobile_combustion/air_transport/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveMobileCombustion(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/mobile_combustion/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateStationaryCombustion(
  sector_id: number, vehicle_fuel_id: number, amount: number, year: number, company_id: number) {
  try {
    const { data } = await api.put(`/scope1/stationary_combustion/fountains/calculate`, 
      { sector_id: sector_id, vehicle_fuel_id: vehicle_fuel_id, amount: amount, year: year, company_id: company_id });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateStationaryCombustionOther(emission_co2: number, emission_ch4: number, 
  emission_n2o: number) {

  try {
    const { data } = await api.put(`/scope1/stationary_combustion/other/calculate`, 
      { emission_co2 : emission_co2, emission_ch4 : emission_ch4, emission_n2o : emission_n2o});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getStationaryCombustion(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/stationary_combustion/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getStationaryCombustionOther(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/stationary_combustion/other/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getStationaryCombustionFountains(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/stationary_combustion/fountains/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveStationaryCombustionFountains(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/stationary_combustion/fountains/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveStationaryCombustionOther(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/stationary_combustion/other/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateSortingFugitiveEmissions(gas_id: number, equipament_id: number, 
    capacity_new_units_kg: number, capacity_units_operation_kg: number, capacity_units_dispensed_kg: number) {
  try {
    const { data } = await api.put(`/scope1/fugitive_emissions/refrigeration_equipment/sorting/calculate`, 
      { gas_id: gas_id, equipament_id: equipament_id, capacity_new_units_kg: capacity_new_units_kg, 
        capacity_units_operation_kg: capacity_units_operation_kg,
        capacity_units_dispensed_kg: capacity_units_dispensed_kg});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateStockFugitiveEmissions(gas_id: number, stock_start_year: number, 
  stock_end_year: number, amount_of_gas: number) {
  try {
    const { data } = await api.put(`/scope1/fugitive_emissions/hexafluoride_emissions/stock/calculate`, 
      { gas_id: gas_id, stock_start_year: stock_start_year, stock_end_year: stock_end_year, 
        amount_of_gas: amount_of_gas});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateOtherFugitiveEmissions(gas_id: number, emissions_kg: number) {
  try {
    const { data } = await api.put(`/scope1/fugitive_emissions/gee_report/other/calculate`, 
      { gas_id: gas_id, emissions_kg: emissions_kg});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateCyclesFugitiveEmissions(gas_id: number, new_charge_kg: number, 
    new_capacity_kg: number, exists_charge_kg: number, dismiss_capacity_kg: number, 
    dismiss_recover_kg: number) {
  try {
    const { data } = await api.put(`/scope1/fugitive_emissions/refrigeration_equipment/cycle/calculate`, 
      { gas_id: gas_id, new_charge_kg: new_charge_kg, 
        new_capacity_kg: new_capacity_kg, exists_charge_kg: exists_charge_kg,
        dismiss_capacity_kg: dismiss_capacity_kg, 
        dismiss_recover_kg: dismiss_recover_kg});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateCyclesNonKiotoFugitiveEmissions(gas_id: number, new_charge_kg: number, 
    new_capacity_kg: number, exists_charge_kg: number, dismiss_capacity_kg: number, 
    dismiss_recover_kg: number) {
  try {
    const { data } = await api.put(`/scope1/non_kyoto_fugitive_emissions/cycle/calculate`, 
      { gas_id: gas_id, new_charge_kg: new_charge_kg, 
        new_capacity_kg: new_capacity_kg, exists_charge_kg: exists_charge_kg,
        dismiss_capacity_kg: dismiss_capacity_kg, 
        dismiss_recover_kg: dismiss_recover_kg});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateMassFugitiveEmissions(gas_id: number, stock_variation_kg: number, 
      capacity_change_kg: number, transferred_quantity_kg: number) {
  try {
    const { data } = await api.put(`/scope1/fugitive_emissions/refrigeration_equipment/mass/calculate`, 
      { gas_id: gas_id, stock_variation_kg: stock_variation_kg, 
        capacity_change_kg: capacity_change_kg, 
        transferred_quantity_kg: transferred_quantity_kg});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateMassNonKiotoFugitiveEmissions(gas_id: number, stock_variation_kg: number, 
      capacity_change_kg: number, transferred_quantity_kg: number) {
  try {
    const { data } = await api.put(`/scope1/non_kyoto_fugitive_emissions/mass/calculate`, 
      { gas_id: gas_id, stock_variation_kg: stock_variation_kg, 
        capacity_change_kg: capacity_change_kg, 
        transferred_quantity_kg: transferred_quantity_kg});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getFugitiveEmissions(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/fugitive_emissions/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getNonKiotoFugitiveEmissions(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/non_kyoto_fugitive_emissions/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getRefrigerationEquipment(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/fugitive_emissions/refrigeration_equipment/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getHexafluorideEmissions(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/fugitive_emissions/hexafluoride_emissions/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveHexafluorideEmissions(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/fugitive_emissions/hexafluoride_emissions/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getGeeReport(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/fugitive_emissions/gee_report/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveGeeReport(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/fugitive_emissions/gee_report/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveRefrigerationEquipment(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/fugitive_emissions/refrigeration_equipment/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveFugitiveEmissions(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/fugitive_emissions/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveNonKiotoFugitiveEmissions(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/non_kyoto_fugitive_emissions/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateIndustrialProcesses(gas_id: number, emissions_t: number) {
  try {
    const { data } = await api.put(`/scope1/industrial_processes/calculate`, 
      { gas_id: gas_id, emissions_t: emissions_t});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getIndustrialProcesses(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/industrial_processes/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveIndustrialProcesses(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/industrial_processes/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateAgriculturalActivities(gas_id: number, emissions_t: number) {
  try {
    const { data } = await api.put(`/scope1/agricultural_activities/calculate`, 
      { gas_id: gas_id, emissions_t: emissions_t});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getAgriculturalActivities(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/agricultural_activities/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveAgriculturalActivities(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/agricultural_activities/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateChangesInLandUse(gas_id: number, emissions_t: number) {
  try {
    const { data } = await api.put(`/scope1/changes_in_land_use/calculate`, 
      { gas_id: gas_id, emissions_t: emissions_t});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getChangesInLandUse(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/changes_in_land_use/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveChangesInLandUse(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/changes_in_land_use/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateLiquidEffluents(nitrogen_removed: number, amount_n2_nitrogen: number, 
  treatment_first_id: number, treatment_second_id: number, n2o_emission_factor: number, effluent_degradable: number, 
  nitrogen_removed_after: number, amount_n2_nitrogen_after: number, effluent_degradable_after: number, 
  generated_liquid_effluent: number, n2o_emission_factor_after: number, effluent_degradable_unity: number, 
  two_anaerobic_treatments: number, ch4_amount_recovered_methane: number, destination_recovered_biogas: number, 
  generated_liquid_effluent_after: number, effluent_degradable_after_unity: number, 
  destination_recovered_biogas_after: number, ch4_amount_recovered_methane_after: number) {

  try {
    const { data } = await api.put(`/scope1/liquid_effluents/effluents/calculate`, 
      { nitrogen_removed : nitrogen_removed, amount_n2_nitrogen : amount_n2_nitrogen, 
        treatment_first_id : treatment_first_id, treatment_second_id : treatment_second_id, 
        n2o_emission_factor : n2o_emission_factor, effluent_degradable : effluent_degradable, 
        nitrogen_removed_after : nitrogen_removed_after, amount_n2_nitrogen_after : amount_n2_nitrogen_after, 
        effluent_degradable_after : effluent_degradable_after, generated_liquid_effluent : generated_liquid_effluent, 
        n2o_emission_factor_after : n2o_emission_factor_after, effluent_degradable_unity : effluent_degradable_unity, 
        two_anaerobic_treatments : two_anaerobic_treatments, ch4_amount_recovered_methane : ch4_amount_recovered_methane, 
        destination_recovered_biogas : destination_recovered_biogas, generated_liquid_effluent_after : generated_liquid_effluent_after, 
        effluent_degradable_after_unity : effluent_degradable_after_unity, destination_recovered_biogas_after : destination_recovered_biogas_after, 
        ch4_amount_recovered_methane_after : ch4_amount_recovered_methane_after});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateLiquidEffluentsOther(emission_co2: number, emission_ch4: number, 
  emission_n2o: number) {

  try {
    const { data } = await api.put(`/scope1/liquid_effluents/others/calculate`, 
      { emission_co2 : emission_co2, emission_ch4 : emission_ch4, emission_n2o : emission_n2o});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateMobileCombustionOther(emission_co2: number, emission_ch4: number, 
  emission_n2o: number) {

  try {
    const { data } = await api.put(`/scope1/mobile_combustion/other/calculate`, 
      { emission_co2 : emission_co2, emission_ch4 : emission_ch4, emission_n2o : emission_n2o});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getLiquidEffluents(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/liquid_effluents/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getLiquidEffluentsItem(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/liquid_effluents/effluents/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getLiquidEffluentsOther(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/liquid_effluents/others/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveLiquidEffluentsItem(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/liquid_effluents/effluents/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveLiquidEffluentsOther(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/liquid_effluents/others/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateLandfilledComposition(percentage_1: number, percentage_2: number, 
  percentage_3: number, percentage_4: number, percentage_5: number, percentage_6: number, 
  percentage_7: number, percentage_8: number) {

  try {
    const { data } = await api.put(`/scope1/solid_waste/landfilled_composition/calculate`, 
      { percentage_1 : percentage_1, percentage_2 : percentage_2, percentage_3 : percentage_3, 
        percentage_4 : percentage_4, percentage_5 : percentage_5, percentage_6 : percentage_6, 
        percentage_7 : percentage_7, percentage_8 : percentage_8});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateLandfilledDisposition(classification: string) {

  try {
    const { data } = await api.put(`/scope1/solid_waste/landfilled_disposition/calculate`, 
      { classification : classification});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getSolidWaste(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/solid_waste/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveSolidWaste(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/solid_waste/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getSolidWasteLandfilled(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/solid_waste/landfilled/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveSolidWasteLandfilled(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/solid_waste/landfilled/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getCoprocessing(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/solid_waste/coprocessing/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getAnaerobicCompost(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/solid_waste/anaerobic_compost/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveAnaerobicCompost(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/solid_waste/anaerobic_compost/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveCoprocessing(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/solid_waste/coprocessing/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateAnaerobicCompost(compost_mass: any, emission_factor_ch4: any, 
  emission_factor_n2o: any, amount_recovered_ch4: any, biogas_destination: any) {

  try {
    const { data } = await api.put(`/scope1/solid_waste/anaerobic_compost/calculate`, 
      { compost_mass : compost_mass, emission_factor_ch4 : emission_factor_ch4, 
        emission_factor_n2o : emission_factor_n2o, amount_recovered_ch4 : amount_recovered_ch4, 
        biogas_destination : biogas_destination});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateCoprocessing(coprocessing_fossil_carbon: number, coprocessing_oxidation_factor: number, 
  coprocessing_dry_matter_carbon: number,  coprocessing_conversion_factor: number, coprocessing_amount_incinerated: number) {

  try {
    const { data } = await api.put(`/scope1/solid_waste/coprocessing/calculate`, 
      { coprocessing_fossil_carbon : coprocessing_fossil_carbon, coprocessing_oxidation_factor : coprocessing_oxidation_factor, 
        coprocessing_dry_matter_carbon : coprocessing_dry_matter_carbon, coprocessing_conversion_factor : coprocessing_conversion_factor, 
        coprocessing_amount_incinerated : coprocessing_amount_incinerated});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getIncineration(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/solid_waste/incineration/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveIncineration(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/solid_waste/incineration/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateIncineration(emission_co2: number, emission_ch4: number, emission_n2o: number) {

  try {
    const { data } = await api.put(`/scope1/solid_waste/incineration/itens/calculate`, 
      { emission_co2 : emission_co2, emission_ch4 : emission_ch4, emission_n2o : emission_n2o});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateIncinerationHealth(health_fossil_carbon: number, health_oxidation_factor: number, 
  health_dry_matter_carbon: number, health_conversion_factor: number, health_amount_incinerated: number) {

  try {
    const { data } = await api.put(`/scope1/solid_waste/incineration/calculate`, 
      { health_fossil_carbon : health_fossil_carbon, health_oxidation_factor : health_oxidation_factor, 
        health_dry_matter_carbon : health_dry_matter_carbon, health_conversion_factor : health_conversion_factor, 
        health_amount_incinerated : health_amount_incinerated });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateHealthIncineration(amount: number) {

  try {
    const { data } = await api.put(`/scope1/solid_waste/incineration/health/calculate`, 
      { amount : amount});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getOther(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope1/solid_waste/other/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveOther(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope1/solid_waste/other/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateOther(emission_co2: number, emission_ch4: number, emission_n2o: number) {

  try {
    const { data } = await api.put(`/scope1/solid_waste/other/calculate`, 
      { emission_co2 : emission_co2, emission_ch4 : emission_ch4, emission_n2o : emission_n2o});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

const scope1Service = {
  getSolidWaste,
  saveSolidWaste,

  getOther,
  saveOther,
  calculateOther,

  getIncineration,
  saveIncineration,
  calculateIncineration,
  calculateIncinerationHealth,
  calculateHealthIncineration,

  getSolidWasteLandfilled,
  saveSolidWasteLandfilled,
  calculateLandfilledComposition,
  calculateLandfilledDisposition,

  getCoprocessing,
  saveCoprocessing,
  calculateCoprocessing,
  
  getAnaerobicCompost,
  saveAnaerobicCompost,
  calculateAnaerobicCompost,

  calculateStationaryCombustionOther,
  saveStationaryCombustionFountains,
  getStationaryCombustionFountains,
  calculateStationaryCombustion,
  saveStationaryCombustionOther,
  getStationaryCombustionOther,
  getStationaryCombustion,

  getMobileCombustion,
  saveMobileCombustion,
  getMobileCombustionAir,
  getMobileCombustionRoad,
  getMobileCombustionOther,
  saveMobileCombustionAir,
  saveMobileCombustionRoad,
  getMobileCombustionWater,
  saveMobileCombustionWater,
  saveMobileCombustionOther,
  calculateMobileCombustionOther,
  calculateMobileCombustionAirFuel,
  calculateMobileCombustionRoadYear,
  calculateMobileCombustionRoadFuel,
  calculateMobileCombustionWaterFuel,
  calculateMobileCombustionRoadDistance,

  getNonKiotoFugitiveEmissions,
  saveNonKiotoFugitiveEmissions,
  calculateMassNonKiotoFugitiveEmissions,
  calculateCyclesNonKiotoFugitiveEmissions,
  
  getGeeReport, 
  saveGeeReport,
  getFugitiveEmissions,
  saveFugitiveEmissions,
  getHexafluorideEmissions,
  saveHexafluorideEmissions,
  getRefrigerationEquipment,
  saveRefrigerationEquipment,
  calculateMassFugitiveEmissions,
  calculateOtherFugitiveEmissions,
  calculateStockFugitiveEmissions,
  calculateCyclesFugitiveEmissions,
  calculateSortingFugitiveEmissions,

  getIndustrialProcesses,
  saveIndustrialProcesses,
  calculateIndustrialProcesses,

  getAgriculturalActivities,
  saveAgriculturalActivities,
  calculateAgriculturalActivities,

  getChangesInLandUse,
  saveChangesInLandUse,
  calculateChangesInLandUse,

  getLiquidEffluents,
  getLiquidEffluentsItem,
  saveLiquidEffluentsItem,
  getLiquidEffluentsOther,
  saveLiquidEffluentsOther,
  calculateLiquidEffluents,
  calculateLiquidEffluentsOther,
};

export default scope1Service;
