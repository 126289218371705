import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Container } from "./styles";
import { Modal } from "../../../../components/Modal";
import { TitleText } from "../../../../components/TitleText";
import { DownloadInventory } from "../Reports/DownloadInventory";
import { DownloadIGEE } from "../Reports/DownloadIGEE";
import React from "react";

type ModalExportIGEEProps = {
  isOpen: boolean;
  inventory: any;
  resume: any;
  handleModalVisible: () => void;
  maccCurveBase64: string;
};

export const ModalExportIGEE = ({
  isOpen,
  handleModalVisible,
  inventory,
  resume,
  maccCurveBase64,
}: ModalExportIGEEProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [title, setTitle] = useState<string>("");

  interface Inputs {
    confirm: string;
  }

  useEffect(() => {
    const title =
      "Relatório IGEE: " +
      inventory.company_corporate_name +
      " Ano: " +
      inventory.year;
    setTitle(title);
  }, [inventory]);

  const { handleSubmit } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async () => {
    console.log("send");
  };

  return (
    <Modal
      isOpen={isOpen}
      isPayOrTypeClient={true}
      handleModalVisible={handleModalVisible}
    >
      <Container>
        {inventory && (
          <div>
            <div style={{ marginTop: 5, textAlign: "left" }}>
              <TitleText level={2} title={title} color="blue" />
            </div>

            <div style={{ marginTop: 10, textAlign: "left" }}>
              <TitleText
                level={3}
                title="Exportação do relatório IGEE"
                color="black"
              />

              <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }}>
                Clique abaixo para exportar o relatório.
              </span>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                style={{
                  marginTop: 15,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <DownloadIGEE
                  data={inventory}
                  resume={resume}
                  maccCurveBase64={maccCurveBase64}
                />
              </div>
            </form>
          </div>
        )}
      </Container>
    </Modal>
  );
};
