import { G, Svg, Text, Rect, Line } from "@react-pdf/renderer";
import { styles } from "../../styles";
import * as d3 from "d3";

export const sum = (data) => data.emission_co2_bio + data.emission_tco2e;

export const BarChart = ({ data, width, height }) => {
  // Configurações do gráfico
  const margin = { top: 20, right: 0, bottom: 40, left: 30 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  // Escalas
  const xScale = d3
    .scaleBand()
    .domain(data.map((d) => d.name))
    .range([0, innerWidth])
    .padding(0.2);
  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(data, (d) => d.value)])
    .range([innerHeight, 0]);

  return (
    <Svg width={width} height={height}>
      <G transform={`translate(${margin.left}, ${margin.top})`}>
        {/* Desenhar as barras */}
        {data.map((d, index) => (
          <Rect
            key={index}
            x={xScale(d.name)}
            y={yScale(d.value)}
            width={xScale.bandwidth()}
            height={innerHeight - yScale(d.value)}
            fill={"#b47d58"}
          />
        ))}
        {/* Adicionar a linha do eixo X */}
        <Line
          x1={0}
          y1={innerHeight}
          x2={innerWidth}
          y2={innerHeight}
          stroke="#989c9c"
          strokeWidth={0.5}
        />
        {/* Adicionar rótulos do eixo X */}
        <G transform={`translate(0, ${innerHeight})`}>
          {xScale.domain().map((d, index) => (
            <G key={index}>
              <Text
                x={xScale(d) + xScale.bandwidth() / 2}
                y={15}
                textAnchor="middle"
                fill="#989c9c"
                style={{
                  fontSize: 10,
                  width: xScale.bandwidth(),
                  ...styles.light,
                }}
              >
                {d[0]}
              </Text>
              <Text
                x={xScale(d) + xScale.bandwidth() / 2}
                y={24}
                textAnchor="middle"
                fill="#989c9c"
                style={{
                  fontSize: 10,
                  width: xScale.bandwidth(),
                  ...styles.light,
                }}
              >
                {d[1]}
              </Text>
            </G>
          ))}
        </G>
      </G>
    </Svg>
  );
};
