import styled from 'styled-components';

export const Container = styled.div`
    padding: 15px;
    width: 100%;

    .status-legend {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin: 7px;
    }

    .bulled-scope-disabled{
        border: 1px solid;
        width: 30px;
        height: 30px;
        border-color: #524b4d;
        color: #524b4d;
        background: #E7E7E7;
        border-radius: 5px;
        margin-left: 10px;
    }

    .bulled-scope-draft{
        border: 1px solid;
        width: 30px;
        height: 30px;
        border-color: #D37620;
        color: #D37620;
        background: #F7DEC6;
        border-radius: 5px;
        margin-left: 10px;
    }

    .bulled-scope-pending{
        border: 1px solid;
        width: 30px;
        height: 30px;
        border-color: #C42329;
        color: #C42329;
        background: #FBACAF;
        border-radius: 5px;
        margin-left: 10px;
    }

    .bulled-scope-completed{
        border: 1px solid;
        width: 30px;
        height: 30px;
        border-color: #81c787;
        color: #81c787;
        background: #D1EDE6;
        border-radius: 5px;
        margin-left: 10px;
    }
`;