import React from "react";
import { TableContainer } from "./styles";

interface TableProps {
    headers: { label: string | React.ReactNode; colSpan?: number; rowSpan?: number; style?: any; className?: string }[];
    data: any[];
    renderRow: (row: any, rowIndex: number) => React.ReactNode;
    tfoot?: () => React.ReactNode;
}

export const Table: React.FC<TableProps> = ({ headers, data, renderRow, tfoot }) => {
    return (
        <TableContainer className="table-scope">
            <thead>
                <tr>
                    {headers.map((header, index) => (
                        <th
                            key={index}
                            colSpan={header.colSpan || 1}
                            rowSpan={header.rowSpan || 1}
                            style={header.style || {}}
                            className={header.className || ""}
                        >
                        {React.isValidElement(header.label) ? <div className="react-node">{header.label}</div> : header.label}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => (
                    <React.Fragment key={index}>{renderRow(row, index)}</React.Fragment>
                ))}
            </tbody>
            {tfoot && (
                tfoot()
            )}
        </TableContainer>
    );
};