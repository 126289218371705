import styled from "styled-components";

export const Container = styled.div` 
    
    .body {
        display: flex; 
        flex-direction: column; 
        margin-top: 10px; 
        margin-left: 10px; 
    }

    .div-text {
        display: flex; 
        flex-direction: row; 
        margin-top: 5px;
        margin-right: 30px; 
    }

    .sub-div-text {
        display: flex; 
        flex-direction: column; 
        margin-top: 5px;
        margin-right: 30px; 
    }

    .text-statement {
        text-align: start;
        font-size: 16px;
        margin-top: 5px;
        font-weight: bold
    }

    .question {
        display: flex;
        flex-direction: row;
        margin-top: 0px;
        justify-content: space-between;
        margin-bottom: 10px; 
    }

    .prompt-text {
        text-align: start;
        font-size: 14px;
        margin-top: 5px; 
    }

    .choice-one {
        display: flex;
        justify-content: start;
        align-items: center;
        marginTop: 5px;
        margin-right: 20px; 
    }
`