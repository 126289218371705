import { Page, Text, Image as ImagePDF, View } from "@react-pdf/renderer";
import React from "react";
import { Logos } from "../../components/Logos";
import { Header } from "../../components/Header";
import { styles } from "../../styles";
import { IGEEProps } from "../../../interfaces/IGEEData";
import instaIcon from "../../../../../../../assets/icons/instagram-icon.png";
import phoneIcon from "../../../../../../../assets/icons/telefone-icon.png";
import siteIcon from "../../../../../../../assets/icons/icon-site-white.png";
import emailIcon from "../../../../../../../assets/icons/email-icon.png";
import LogoVerdeEscuro from "../../../../../../../assets/images/versoes-logo-icone-ingee-03.png";
import {
  isValidCnpj,
  isValidCpf,
  toNumberValues,
  transformCNPJ,
  transformCPF,
  transformOnlyNumbers,
} from "../../../../../../../utils/transform";

export const Page3: React.FC<IGEEProps> = (props) => {
  const { data } = props;
  const { resume } = props;

  const formatCnpj = (cnpj: any) => {
    const cnpjNumbers = cnpj.replace(/\D/g, ''); // Remove caracteres não numéricos
    return cnpjNumbers.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5'
    ); // Formata o CNPJ com pontos e barra
  };

  // para formatar número de celular
  const formatPhoneNumber = (phoneNumber: any) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumber;
  };

  const leftInfo = [
    {
      title: "Razão Social: ",
      content: data?.company_corporate_name,
    },
    {
      title: "Nome Fantasia: ",
      content: data?.company_fantasy_name,
    },
    {
      title: "Endereço:",
      content: data?.company_address,
    },
    {
      title: "CNPJ:",
      content: resume?.company_itens?.cnpj ? formatCnpj(resume.company_itens.cnpj.toString()) : "",
    },
    {
      title: "Atividade econômica principal: ",
      content: resume?.company_itens.economic_activity,
    },
  ];

  const rightInfo = [
    {
      title: "Ano de publicação: ",
      content: "2024",
    },
    {
      title: "Descrição dos serviços prestados: ",
      content: resume?.company_itens?.services_provided,
    },
  ];

  const contacts = [
    { label: resume?.company_itens.contacts.site, icon: siteIcon },
    { label: resume?.company_itens.contacts.email, icon: emailIcon },
    { label: formatPhoneNumber(resume?.company_itens.contacts.phone_number), icon: phoneIcon },
    { label: resume?.company_itens.contacts.instagram, icon: instaIcon },
  ];

  return (
    <Page size={[640, 480]} style={styles.Page}>
      <Header backColor="#80b780" title="1. Dados da organização" />
      <View style={{ ...styles.body }}>
        <View style={{ padding: "25px", height: "100%", width: "50%" }}>
          <View style={{ height: "100%" }}>
            {leftInfo.map((info, idx) => (
              <View
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  width: "100%",
                }}
                key={idx}
              >
                <Text
                  style={{
                    color: "#6c806e",
                    fontSize: 12,
                    ...styles.Bold,
                  }}
                >
                  {info.title}
                </Text>
                <Text
                  style={{ color: "#6c806e", fontSize: 12, ...styles.light }}
                >
                  {info.content}
                </Text>
              </View>
            ))}
          </View>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20, marginRight: 40 }}>
              <View>
                <ImagePDF 
                  src={resume?.company_itens.logo_image_url}
                  style={{
                    width: 65,
                    height: 55,
                  }}
                />
              </View>
              <View>
                <ImagePDF
                  src={LogoVerdeEscuro}
                  style={{
                    width: 115,
                    height: 40,
                    marginTop: 5
                  }}
                />
              </View>
            </View>
        </View>
        <View
          style={{
            padding: "25px",
            width: "55%",
            height: "100%",
            borderLeft: "1px solid #76B987",
          }}
        >
          <Text
            style={{
              ...styles.extraBold,
              position: "absolute",
              top: "40%",
              width: "100%",
              marginLeft: 37,
              color: "#6c806e",
              fontSize: 13
            }}
          >
            Contatos:
          </Text>
          {contacts.map((contact, idx) => {
            if (!contact.label || contact.label === null) {
              contact.label = "-";
            }

            return (
              <View
                style={{
                  position: "absolute",
                  top: `${50 + 8 * idx}%`,
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                key={idx}
              >
                <ImagePDF src={contact.icon} style={styles.greenBall} />
                <Text style={styles.iconContent}>{contact.label}</Text>
              </View>
            );
          })}
          {rightInfo.map((info, idx) => (
            <View
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "80%",
              }}
              key={idx}
            >
              <Text
                style={{ color: "#6c806e", fontSize: 12, ...styles.extraBold }}
              >
                {info.title}
              </Text>

              <Text style={{ color: "#6c806e", fontSize: 12, ...styles.light }}>
                {info.content}
              </Text>
            </View>
          ))}
        </View>
      </View>
    </Page>
  );
};
