import { useEffect, useState } from 'react'
import { formatPrice } from '../../../../utils/transform'
import { Container } from './styles'

type StatusLabelProps = {
  status: string | undefined
}

export const StatusLabel = ({ status }: StatusLabelProps) => {
  const [status0a35, setStatus0a35] = useState(false)
  const [status36a99, setStatus36a99] = useState(false)
  const [status100, setStatus100] = useState(false)

  useEffect(() => {
    let value = 0

    if (status) {
      value = parseInt(status)
    }

    setStatus0a35(value >= 0 && value <= 35)
    setStatus36a99(value >= 36 && value <= 99)
    setStatus100(value === 100)
  }, [status])

  return (
    <Container status={status}>
      {status0a35 && (
        <div className="bulled-0-35">
          <span className="status-text">{formatPrice(Number(status), false)} %</span>
        </div>
      )}
      {status36a99 && (
        <div className="bulled-36-99">
          <span className="status-text">{formatPrice(Number(status), false)} %</span>
        </div>
      )}
      {status100 && (
        <div className="bulled-100">
          <span className="status-text">{formatPrice(Number(status), false)} %</span>
        </div>
      )}
    </Container>
  )
}
