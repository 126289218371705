import styled from "styled-components";

type StatusLabelProps = {
  status: boolean | undefined;
  expired: boolean | undefined;
  locked: boolean | undefined;
};

export const Container = styled.div<StatusLabelProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 6px 8px;
  background: ${(props) =>
    props.locked === true
      ? "rgba(211, 118, 32, 0.4)"
      : props.expired === true
      ? "rgba(82, 75, 77, 0.3)"
      : props.status === true
      ? "rgba(22, 147, 25, 0.3)"
      : "rgba(196, 35, 41, 0.6)"};
  border-radius: 2.5px;
  width: 50%;

  .status-text {
    width: 130px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    text-align: center;
    line-height: 19px;
    color: ${(props) =>
      props.locked === true
        ? props.theme.colors.white
        : props.expired === true
        ? props.theme.colors.black
        : props.status === true
        ? props.theme.colors.black
        : props.theme.colors.white};
  }
  word-wrap: break-word;
`;
