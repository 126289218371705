import { api } from "./api";

const exportDataDefaultTable = async (companyId: number, year: number, model: string) => {
    try {
        const response = await api.get(
            `/export/scopes/${companyId}/${year}/${model}/`,
            { responseType: "blob" } // <- Isso permite lidar com arquivos
        );
        return response.data;
    } catch (error: any) {
        console.error("Erro ao exportar:", error);
        return { error: error };
    }
};

const handleExport = async (company_id: number, year: number, model: string) => {
    try {
        const response = await exportDataDefaultTable(
        company_id,
        year,
        model
    );

    if (response && response instanceof Blob) {
        // Criar URL para download do arquivo
        const url = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${model}.xlsx`); // Nome do arquivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true;
    } else {
        throw new Error("Ocorreu um erro ao exportar.")
    }
    } catch (error) {
        console.error("Erro ao exportar dados:", error);
        throw new Error("Ocorreu um erro ao exportar.")
    }
};


const exportScopeService = {
    exportDataDefaultTable,
    handleExport,
};

export default exportScopeService;