import styled from "styled-components";

export const Table = styled.table`
    max-width: "100%";
    width: "4000px";
    border-collapse: collapse;

    .table-scope {
        border-color: red;
        border-width: 1px;
        border-style: solid;
    }

    .table-scope tr,
    .table-scope th,
    .table-scope td {
        border-color: white;
        border-width: 1px;
        border-style: solid;
    }
    
    .table-scope td {
        background: #F6F6F6;
    }

    .head-tr-item-bottom{
        background-color: #C6C5C5;
        font-size: 12px;
        height: 25px;
    }

    .head-tr-item{
        background-color: #D8D7D7 !important;
        font-size: 12px;
        height: 25px;
    }

    .subhead-item{
        background-color: #F6F6F6 !important;
        font-size: 12px;
        height: 25px;
        text-align: right;
        font-weight: bold;
    }

    .input-item{
        background: #ffffff;
        margin: 2px;
        padding-right: 2px;
        padding-left: 2px;
        height: 30px !important;
        border: 1px solid #DFDFDF;
        border-radius: 2.5px;
    }

    .tooltip-bottom::before{
        bottom: -150px !important;
    }


    /* Estilizando a barra de rolagem */
    .custom-scrollbar {
        overflow-y: scroll;
        overflow: hidden;
    }

    .custom-scrollbar:hover {
        overflow: auto;
    }

    .custom-scrollbar::-webkit-scrollbar {
        width: 5px; /* Largura da barra de rolagem */
    }

    .custom-scrollbar::-webkit-scrollbar-track {
        background: #f1f1f1; /* Cor da trilha */
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #d0dae7; /* Cor do polegar */
        border-radius: 10px; /* Borda arredondada */
    }

    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #555; /* Cor do polegar ao passar o mouse */
    }

`;