import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { Modal } from "../../../components/Modal";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import scopeService from "../../../services/scopeService";
import { Container } from "./styles";



import { useLocation, useHistory } from "react-router-dom";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from "react-hot-toast";

interface Inputs {
  name: string;
  label: string;
  guidelines: string;
  description: string;
  item_label: string;
  item_guidelines: string;
  item_description: string;
}

export const ScopeEdit = () => {
  const history = useHistory();

  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [itens, setItens] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false)
  const [itemStatus, setItemStatus] = React.useState('');
  const [itemSelected, setItemSelected] = useState<any>({});
  const [scopeSelected, setScopeSelected] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [description, setDescription] = React.useState('');
  const [guidelines, setGuidelines] = React.useState('');
  const [itemGuidelines, setItemGuidelines] = React.useState('');
  const [itemDescription, setItemDescription] = React.useState('');

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.scope_id){
      getScopesById(item.scope_id)
    }else{
      history.push({
        pathname: '/escopo-inventario',
      });
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps 
  [location]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    console.log(errors)
  }, [errors]);

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const itens: any[] = scopeSelected.itens.map((item: any, index: number) => {
        return {
          id: item.id,
          name: item.name,
          label: item.label,
          guidelines: item.guidelines
        }
      })

      const newScope = {
        name: formData.name,
        label: formData.label,
        type: scopeSelected.type,
        guidelines: guidelines,
        description: description, 
        itens: itens
      };

      if (scopeSelected?.id) {
        const result = await scopeService.updateById(
          scopeSelected.id,
          newScope
        );

        if (result && !result.error) {
          //history.goBack()

          //reset();
          //setErrorApi(undefined);

          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  async function getScopesById(id: number) {
    const scope = await scopeService.getById(id);

    setValue("name", scope?.name);
    setValue("label", scope?.label);
    setValue("guidelines", scope?.guidelines);
    setValue("description", scope?.description);

    setGuidelines(scope?.guidelines)
    setDescription(scope?.description)

    setScopeSelected(scope)
    setItemStatus('update_itens' + new Date().getMilliseconds().toString())
  }

  function handleOpenItem(scope?: any) {
    if (scope) {
      setItemGuidelines(scope.guidelines)
      setItemDescription(scope.description)

      setValue("item_label", scope.label)
      setValue("item_guidelines", scope.guidelines)
      setValue("item_description", scope.description)

      setItemSelected(scope)
    }

    setOpenModal(!openModal)
  }

  function saveItemSelected() {
    if (scopeSelected.itens){
      for (const item of scopeSelected.itens){
        if (item.name === itemSelected.name){
          item.label = getValues("item_label")
          item.guidelines = itemGuidelines
          item.guidelines = itemDescription

          break
        }
      }
    }
    
    setItemStatus('update_itens' + new Date().getMilliseconds().toString())

    handleOpenItem()
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Escopo do inventário" />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />
        
        <div style={{ overflow: "hidden auto", position: "relative", height: "calc(100vh - 150px)", 
              maxHeight: "calc(100vh - 150px)"}} >
          <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 30, marginLeft: 50, marginRight: 50  }} >
            <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Nome
            </label>

            <input style={{width: '95%'}}
                  {...register("label", { required: true })}/>
                        
            {errors.label && (
                <span className='requiredLabel' >Esse campo é obrigatório</span>
            )}


            <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Apelido
            </label>

            <input style={{width: '95%'}} disabled
                  {...register("name", { required: true })}/>
                        
            {errors.name && (
                <span className='requiredLabel' >Esse campo é obrigatório</span>
            )}
            
            <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Descrição
            </label>

            <ReactQuill theme="snow" value={description} onChange={setDescription}/>
            
            <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Orientações gerais
            </label>

            <ReactQuill theme="snow" value={guidelines} onChange={setGuidelines}/>
                        
            {errors.guidelines && (
                <span className='requiredLabel' >Esse campo é obrigatório</span>
            )}  

            {scopeSelected?.itens && scopeSelected?.itens.length > 0 && itemStatus && (
              <table style={{ width: "100%", borderCollapse: "collapse", marginTop: 20 }} className="table-scope">
                <thead>
                  <tr>
                    <th className="head-tr-item" style={{ width: 200 }}>
                      {'Nome'}
                    </th>
                    <th className="head-tr-item"/>
                  </tr>
                </thead>
                <tbody>
                  {scopeSelected.itens.map((item: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td style={{ padding: "10px", width: "90%" }} >
                            <span>
                              {item.label}
                            </span>
                          </td>
                          <td>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                              <button type="button"
                                className="button-edit"
                                onClick={() => handleOpenItem(item)}
                              >
                                Editar
                              </button>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </form>

      <Modal isOpen={openModal} handleModalVisible={() => handleOpenItem()} isPayOrTypeClient={true} >
        <div style={{ display: "flex", flexDirection: "column", marginTop: 10, width: 600 }}>
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Nome
          </label>

          <input style={{width: '95%'}} {...register("item_label")}/>
                      
          {errors.item_label && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
            
          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Descrição
          </label>

          <ReactQuill theme="snow" value={itemDescription} onChange={setItemDescription} style={{ height: 100 }}/>
          
          <label style={{ marginTop: 55, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Orientações gerais
          </label>

          {itemSelected.html_format === true && (
            <ReactQuill theme="snow" value={itemGuidelines} onChange={setItemGuidelines} style={{ height: 100 }}/>
          )}

          {itemSelected.html_format === false && (
            <textarea style={{width: '100%', height: 100}} {...register("item_guidelines")}
                onChange={(event) => {
                  setItemGuidelines(event.target.value)
                  setValue("item_guidelines", event.target.value);
              }}/>
          )}

          <div style={{ margin: 5, marginTop: 50, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <Button color='blue' type="button" size="md" onClick={saveItemSelected}
                      style={{ margin: 10, width: '100px' }}>
                  Salvar
              </Button>
          </div>
        </div>
      </Modal>
    </Container>
  );
};
