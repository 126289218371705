import {
  Page,
  Text,
  Image as ImagePDF,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import React from "react";
import { Header } from "../../components/Header";
import { styles } from "../../styles";
import qrcode from "./qrcode.png";
import onu9 from "../../../../../../../assets/icons/ods-9.png";
import onu13 from "../../../../../../../assets/icons/ods-13.png";
import IconOds13 from "../../../../../../../assets/icons/ods-13.png";
import IconOds9 from "../../../../../../../assets/icons/ods-9.png";

import instaIcon from "../../../../../../../assets/icons/instagram-icon.png";
import phoneIcon from "../../../../../../../assets/icons/telefone-icon.png";
import siteIcon from "../../../../../../../assets/icons/icon-site-white.png";
import emailIcon from "../../../../../../../assets/icons/email-icon.png";
import { IGEEProps } from "../../../interfaces/IGEEData";

import neutralizacao from "../../../../../../../assets/images/selo-2023.1.png";
import ghg2022 from "../../../../../../../assets/images/2-Prata (Port).png";
import ghg2023 from "../../../../../../../assets/images/Membro 2024_TRANSP.png";
import parana from "../../../../../../../assets/images/mercado-interno.png";
import ods from "../../../../../../../assets/images/Selo para rodapé_SESI ODS_01.png";

interface Page17{
  year?: number; 
}

export const Page17: React.FC<IGEEProps & Page17> = (props) => {
  const { resume } = props;
  const { year } = props;

  const contacts = [
    { label: "ingee.com.br", icon: siteIcon },
    { label: "suporte@ingee.com.br", icon: emailIcon },
    { label: "(41) 3085-8810 / (41) 98414-5801", icon: phoneIcon },
    { label: "@ingee_inovacao_sustentavel", icon: instaIcon },
  ];

  return (
    <Page
      style={{ ...styles.Page, backgroundColor: "#ecf3f4" }}
      size={[640, 480]}
      wrap={false}
    >
      <Header backColor="#80b780" title="9. Métodos" />
      <View style={local.body}>
        <View style={local.offSection}>
          {contacts.map((contact, idx) => {
            if (!contact.label || contact.label === null) {
              contact.label = "-";
            }

            return (
              <View
                style={{
                  position: "absolute",
                  top: `${62 + 8 * idx}%`,
                  width: "400%",
                  height: "auto",
                  left: "103%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                key={idx}
              >
                <ImagePDF src={contact.icon} style={{...styles.greenBall}} />
                <Text style={{...styles.italic, color: "#80b780", fontSize: 11, marginLeft: 10}}>{contact.label}</Text>
              </View>
            );
          })}
        </View>
        <View style={local.section}>
          <View style={local.text}>
            <Text
              style={{
                ...styles.Bold,
                ...styles.italic,
                ...local.title,
                marginTop: '15px',
              }}
            >
              {resume?.igee_itens.year}
            </Text>
            <Text style={{ ...local.text, ...styles.light }}>
              Software para cálculo e gestão de emissões de gases de efeito
              estufa desenvolvido pela empresa Sinergia Engenharia de Meio
              Ambiente Ltda, tendo como base as diretrizes do Programa
              Brasileiro do GHG Protocol, IPCC e Norma ABNT NBR ISO 14.064.
            </Text>
          </View>
          <View style={{marginLeft: '-130px'}}>
            <ImagePDF src={qrcode} style={{...local.qrcode}} />
          </View>
        </View>
        <View style={local.section}>
          <View style={{ ...local.images, justifyContent: "flex-start" }}>
            <ImagePDF src={IconOds9} style={{...local.image}} />
            <ImagePDF src={IconOds13} style={{...local.image, marginLeft: -10}} />
          </View>
          <View style={local.text}>
            <Text
              style={{
                ...styles.Bold,
                ...styles.italic,
                ...local.title,
              }}
            >
              Disclaimer
            </Text>
            <Text style={{ ...local.text, ...styles.light }}>
              As emissões de gases de efeito estufa apresentadas neste
              inventário, foram geradas por meio de informações fornecidas pelo
              CONTRATANTE. O ingee não tem responsabilidade sobre os valores
              inputados.
            </Text>
          </View>
          <View style={local.text}>
            <Text style={{ ...local.title, ...styles.Bold, ...styles.italic, }}>
              Sobre a Sinergia Engenharia
            </Text>
            <Text style={{ ...local.text, ...styles.light }}>
              Queremos ser protagonistas na geração de um impacto positivo na
              sociedade! Somos uma empresa que atua na prestação de serviços
              ambientais e florestais. Já impactamos diversos estados e
              municípios brasileiros, autando nos setores público, industrial,
              construção civil, comércio e serviços, portuário, energia
              elétrica, entre outros.
            </Text>
          </View>
          <View>
            <Text style={{ ...styles.Bold, ...local.text, color: "#6b8077", marginLeft: '10px'}}>
              Somos carbono neutro!
            </Text>
            <View style={{...local.images, marginTop: -20}}>
              <ImagePDF src={parana} style={{...local.image, width: 45, height: 45}} />
              <ImagePDF src={ghg2023} style={{...local.image, width: 50, height: 50, marginLeft: -6, marginTop: 25}} />
              <ImagePDF src={ghg2022} style={{...local.image, width: 65, height: 65, marginLeft: -8, marginTop: 18}} />
              <ImagePDF src={neutralizacao} style={{...local.image, width: 50, height: 50, marginLeft: -10, marginTop: 25}} />
              <ImagePDF src={ods} style={{...local.image, width: 50, height: 50, marginLeft: -8, marginTop: 25}} />
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

const local = StyleSheet.create({
  body: {
    display: "flex",
    flexDirection: "row",
    height: "90%",
  },
  offSection: {
    display: "flex",
    flexDirection: "column",
    height: "103%",
    width: "10%",
    borderLeft: "1px solid #80b780",
    marginLeft: -20,
    position: "relative",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    height: "103%",
    width: "58%",
    borderLeft: "1px solid #76B987",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "10px",
    paddingRight: "10px",
    zIndex: -1,
  },
  title: {
    fontSize: 14,
    color: "#6b8077",
    textAlign: "center",
    marginLeft: '5px',
  },
  text: {
    padding: "5px",
    fontSize: 9,
    color: "#6b8077",
    textAlign:'justify',
    left: '0px',
  },
  qrcode: {
    width: "30%",
    paddingTop: "5px",
    left: -7,
  },
  images: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  image: {
    display: "flex",
    width: "50px",
    height: "50px",
    margin: "10px",
    marginTop: 30,
  },
});
