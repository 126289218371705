import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 740px;
    padding: 7px 15px;

    iframe {
        width: 740px;
        height: 450px;
        border: none;
    }
`;