import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../../components/Button/styles";
import { GoBack } from "../../../../components/GoBack";
import { TitleText } from "../../../../components/TitleText";
import useAuth from "../../../../hooks/useAuth";
import inventoryService from "../../../../services/inventoryService";
import { Container } from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import TrashIcon from '../../../../assets/icons/trash.svg'
import AttachmentIcon from '../../../../assets/icons/attachment.svg'
import DownloadIcon from '../../../../assets/icons/download.svg'


interface Inputs {
  compensation_emissions: string;
  compensation_has_projects: string;
  compensation_emission_reduction: string;
  compensation_project_emission_reduction: string;
  compensation_emissions_na: boolean;
  compensation_has_projects_na: boolean;
  compensation_emission_reduction_na: boolean;
  compensation_project_emission_reduction_na: boolean;
  compensation_amount: string;
}

export const InventoryCompensation = () => {
  const history = useHistory();
  const [inventorySelected, setInventorySelected] = useState<any>({});
  const location = useLocation();
  const [files, setFiles] = useState<any>([]);
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [emissionsNa, setEmissionsNa] = useState<boolean | undefined>();
  const [hasProjectsNa, setHasProjectsNa] = useState<boolean | undefined>();
  const [emissionReductionNa, setEmissionReductionNa] = useState<boolean | undefined>();
  const [projectEmissionReductionNa, setProjectEmissionReductionNa] = useState<boolean | undefined>();
  const [compensationAmount, setCompensationAmount] = useState('');
  const [onlyView, setOnlyView] = useState(false);

  useEffect(() => {
    const item:any = location?.state;
    if (item && item.company_id && item.year){
      getInventorysById(item.company_id, item.year);
      setOnlyView(user.profile !== 'sinergia' && user.profile !== 'admin' && user.profile !== 'analyst');
    } else {
      history.push({ pathname: '/inventario' });
    }
  }, [location]);

  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const formatForDatabase = (value: string) => {
    const formattedValue = value.replace(/\./g, '').replace(',', '.');
    return parseFloat(formattedValue);
  };

  const formatForDisplay = (value: number) => {
    return value.toLocaleString('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4 });
  };

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const newInventory = {
        compensation_emissions: emissionsNa ? 'N/A' : formData.compensation_emissions,
        compensation_has_projects: hasProjectsNa ? 'N/A' : formData.compensation_has_projects,
        compensation_emission_reduction: emissionReductionNa ? 'N/A' : formData.compensation_emission_reduction,
        compensation_project_emission_reduction: projectEmissionReductionNa ? 'N/A' : formData.compensation_project_emission_reduction,
        compensation_amount: formatForDatabase(formData.compensation_amount)
      };

      const result = await inventoryService.updateCompensationId(
        inventorySelected.company.id, inventorySelected.year, newInventory
      );

      if (result && !result.error) {
        history.goBack();
        reset();
        setErrorApi(undefined);
        toast.success("Operação realizada com sucesso!");
      } else if (result.error) {
        setErrorApi(result.error);
      }
    }
  };

  async function getInventorysById(company_id: number, year: number) {
    const inventory = await inventoryService.getById(company_id, year);
    setValue("compensation_emissions", inventory?.compensation_emissions);
    setValue("compensation_has_projects", inventory?.compensation_has_projects);
    setValue("compensation_emission_reduction", inventory?.compensation_emission_reduction);
    setValue("compensation_project_emission_reduction", inventory?.compensation_project_emission_reduction);

    setCompensationAmount(formatForDisplay(Number(inventory?.compensation_amount || 0)));

    setEmissionsNa(inventory?.compensation_emissions === 'N/A');
    setHasProjectsNa(inventory?.compensation_has_projects === 'N/A');
    setEmissionReductionNa(inventory?.compensation_emission_reduction === 'N/A');
    setProjectEmissionReductionNa(inventory?.compensation_project_emission_reduction === 'N/A');

    setValue("compensation_emissions_na", inventory?.compensation_emissions === 'N/A');
    setValue("compensation_has_projects_na", inventory?.compensation_has_projects === 'N/A');
    setValue("compensation_emission_reduction_na", inventory?.compensation_emission_reduction === 'N/A');
    setValue("compensation_project_emission_reduction_na", inventory?.compensation_project_emission_reduction === 'N/A');

    setInventorySelected(inventory);

    const result = await inventoryService.getCompensationFiles(inventory.company.id, inventory.year);
    setFiles(result.files);
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteCompensationFile(id);
      if (result && !result.error) {
        const result = await inventoryService.getCompensationFiles(
          inventorySelected.company.id,
          inventorySelected.year
        );
        setFiles(result.files);
      }
    }
    deleteFile(id);
  }

  const handleDownloadFile = (filename: string) => {
    window.open(filename, '_blank');
  };

  const handleAttachmentChange = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = { file: base64, filename: filename };
      const result = await inventoryService.saveCompensationFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      );
      if (result && !result.error) {
        const result = await inventoryService.getCompensationFiles(
          inventorySelected.company.id,
          inventorySelected.year
        );
        setFiles(result.files);
      }
    }
    for (const file of event.target.files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        saveImage(base64?.toString(), file.name);
      };
    }
  };

  function moeda(a: string) {
    return a
      .replace(/\D/g, '')
      .replace(/(\d)(\d{4})$/, '$1,$2')
      .replace(/(?=(\d{3})+(\D))\B/g, '.');
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <div style={{ display: "flex", flexDirection: "column"}} >
            <TitleText level={1} title={'Inventário: ' + inventorySelected?.year + ' - ' + inventorySelected?.company?.corporate_name}  />
            <TitleText level={4} title="Compensações e reduções do inventário"  />
          </div>
          
          {onlyView === false && (
            <Button color="green" size="md" type="submit">
              Salvar
            </Button>
          )}
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", overflow: "auto", 
              position: "absolute", top: "150px", right: "30px", left: "280px", bottom: "20px"}} >

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              A organização possui projetos de compensação de emissões? 
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={hasProjectsNa || onlyView}
              {...register("compensation_has_projects", { required: !hasProjectsNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView} 
                {...register("compensation_has_projects_na")}
                  onChange={(event) => {
                    if (hasProjectsNa === false){
                        setValue("compensation_has_projects", "N/A")
                    }

                    setHasProjectsNa(!hasProjectsNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica
            </label>
          </div>
                      
          {errors.compensation_has_projects && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Quantidade compensada (tCO₂e): 
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <input 
              style={{ width: '85%', height: 100 }} 
              {...register("compensation_amount")}
              value={compensationAmount}
              onBlur={(event) => {
                if (!event.target.value.trim()) {
                  setCompensationAmount('0');
                  setValue("compensation_amount", '0');
                }
              }} 
              onChange={(event) => {
                const inputValue = event.target.value;
                const formattedValue = moeda(inputValue);
                setCompensationAmount(formattedValue);
                setValue("compensation_amount", formattedValue);
              }}
          />
          </div>

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              A organização possui projetos de redução de emissões?
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={projectEmissionReductionNa || onlyView}
              {...register("compensation_project_emission_reduction", { required: !projectEmissionReductionNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView}
                {...register("compensation_project_emission_reduction_na")}
                  onChange={(event) => {
                    if (projectEmissionReductionNa === false){
                        setValue("compensation_project_emission_reduction", "N/A")
                    }

                    setProjectEmissionReductionNa(!projectEmissionReductionNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica
            </label>
          </div>
                      
          {errors.compensation_project_emission_reduction && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <div className="column" style={{ marginTop: 10 }}>

            <div style={{ margin: 5, paddingTop: 5, paddingBottom: 15 }} >
            <TitleText level={4} title="Certificados de compensações e evidências de reduções"  />
            </div>

            <div style={{ borderStyle: "dashed", marginTop: 20, borderWidth: "1px", margin: 5, 
                          paddingTop: 15, paddingBottom: 15, borderColor: "#9E9E9E", 
                          width: "95%", textAlign: "center", cursor: "pointer"}} 
                          onClick={() => (document.querySelector("#attachmentInput") as HTMLInputElement).click()}>
              <img src={AttachmentIcon} alt="coisa" width={32}/>

              <br/>

              <span style={{padding: 5, fontSize: 14, fontStyle: "bold"}}>
              Insira aqui a certificação de compensação e as evidências de reduções
              </span>
            </div>

          <input type="file" id="attachmentInput" style={{ display: 'none' }} multiple
            name="file" onChange={handleAttachmentChange} />

          {files && files.length > 0 && (
            <div style={{ borderStyle: "dashed", marginTop: 5, borderWidth: "1px", margin: 5, 
                            paddingTop: 5, paddingBottom: 5, borderColor: "#9E9E9E", 
                            width: "95%", textAlign: "center", cursor: "pointer"}} >
              <table style={{ width: '100%', borderCollapse: 'collapse' }} className="table-scope" >
                <thead>
                  <tr>
                    <th className="head-tr-item" style={{ width: 200 }}>
                      {'Certidão de verificação'}
                    </th>
                    <th className="head-tr-item" style={{ width: 50 }} />
                  </tr>
                </thead>
                <tbody>
                  {files.map((file: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td style={{ width: '90%', textAlign: 'left' }}>
                            <span style={{ marginLeft: 5, fontSize: 12, textAlign: 'left', display: 'inline-block' }} >
                              {file.filename}
                            </span>
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            <div style={{ display: 'inline-flex', textAlign: 'center' }} >
                              {(user.id === file.user_id || user.profile === 'sinergia') && (
                                <div onClick={() => handleDeleteFile(file.id)} 
                                    style={{ cursor: 'pointer', textAlign: 'center', width: 30 }} >
                                  <img alt="deleteIcon" src={TrashIcon} />
                                </div>
                              )}
                              <div onClick={() => handleDownloadFile(file.file_url)}
                                style={{ cursor: 'pointer', textAlign: 'center', width: 30 }} >
                                <img alt="downloadIcon" src={DownloadIcon} width={24} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )}
          </div>
        </div>
      </form>
    </Container>
  );
};
