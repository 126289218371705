import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles as tableStyles } from '../../../../components/PDF/styles/table';
import { styles } from '../../../../components/PDF/styles/common';
import { TheadItem } from '../../../../components/PDF/TheadItem';
import { TbodyItem } from '../../../../components/PDF/TbodyItem';
import { formatPrice3 } from '../../../../utils/transform';

const renderWithSubscripts = (text: string) => {
  const subscriptMap: { [key: string]: string } = {
    '₃': '3',
    '₄': '4',
  };

  const parts = text.split(/(₃|₄)/); 
  return (
    <Text>
      {parts.map((part, index) => {
        if (subscriptMap[part]) {
          return (
            <Text key={index} style={{ fontSize: 4, verticalAlign: 'sub' }}>
              {subscriptMap[part]}
            </Text>
          );
        }
        return <Text key={index}>{part}</Text>;
      })}
    </Text>
  );
};

export const NotRegulatedByKyotoProtocolEmissions: React.FC<any> = (props) => {
  const { pdfData } = props;

  return (
    <View wrap={false} style={styles.textContainer}>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TheadItem width="50%">Categoria</TheadItem>
        <TheadItem width="50%">
          Emissões tCO<Text style={styles.subscript}>₂</Text>e
        </TheadItem>
      </View>

      {pdfData.map((item: any, index: number) => {
        return (
          <React.Fragment key={index}>
            {item.id > 0 && (
              <View style={{ ...tableStyles.container, ...styles.row, ...styles.light }}>
                <TbodyItem textAlign="left" width="50%">
                  {renderWithSubscripts(item.label)}
                </TbodyItem>
                <TbodyItem textAlign="right" width="50%">
                  {formatPrice3(item.emission_tco2e, false)}
                </TbodyItem>
              </View>
            )}
            {item.id === 0 && (
              <View style={tableStyles.totalRow}>
                <TbodyItem textAlign="left" width="50%">
                  Total
                </TbodyItem>
                <TbodyItem textAlign="right" width="50%">
                  {formatPrice3(item.emission_tco2e, false)}
                </TbodyItem>
              </View>
            )}
          </React.Fragment>
        );
      })}
    </View>
  );
};
