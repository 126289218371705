import styled from 'styled-components';

export const Container = styled.div`

    .info-sismiegee {
      text-align: center;
      font-size: 10px;
      color: #000;
      position: absolute;
      bottom: 0px; 
      width: 100%; 
      z-index: 1;
    }

    .info-sismiegee a {
      color: #000;
    }

    .about-sismiegee{
      // top: 100px; 
      // left: 0px;
      // color: #81c787;
      // font-size: 12px;
      // cursor: pointer;
      // padding-top: 5px;
      // font-weight: bold;
      // position: absolute;
      // padding-left: 10px;
      // padding-right: 10px;
      // background: #FFFFFF;
      // border-radius: 10px;
      // padding-bottom: 10px;
      // box-shadow: 0px 9px 30px rgb(162 160 160 / 7%);
      // height: 26px;
      text-align: center;
      position: absolute;
      top: 70%; 
      width: -30%;
      left: 0px;
      margin-bottom: 28%;
      z-index: 1;
      cursor: pointer;
    }

    .about-sismiegee img{
      padding-right: 10px;
      margin-top: -3px;
    }

    .about-sismiegee span{
      top: -17px;
      position: relative;
    }

    // 

    .about-ods{
      // top: 100px; 
      // left: 0px;
      // color: #81c787;
      // font-size: 12px;
      // cursor: pointer;
      // padding-top: 5px;
      // font-weight: bold;
      // position: absolute;
      // padding-left: 10px;
      // padding-right: 10px;
      // background: #FFFFFF;
      // border-radius: 10px;
      // padding-bottom: 10px;
      // box-shadow: 0px 9px 30px rgb(162 160 160 / 7%);
      // height: 26px;
      text-align: center;
      position: absolute;
      top: 85%; 
      width: -30%;
      left: 0px;
      margin-bottom: 28%;
      z-index: 1;
      cursor: pointer;
    }

    .about-ods img{
      padding-right: 10px;
      margin-top: -3px;
    }

    .about-ods span{
      top: -17px;
      position: relative;
    }
`;