import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import helpService from "../../../services/helpService";
import { Container } from "./styles";
import DownloadIcon from '../../../assets/icons/download.svg'
import AttachmentIcon from '../../../assets/icons/attachment.svg'
import { useLocation, useHistory } from "react-router-dom";
import fileDownload from 'js-file-download';
import axios from 'axios';
import { toast } from "react-hot-toast";

interface Inputs {
  name: string;
  file: string;
  filename: string;
  video_url: string;
  description: string;
  attachment_type: string;
}

export const HelpTopicEdit = () => {
  const history = useHistory();

  const location = useLocation();
  const [file, setFile] = useState('')
  const [fileChanged, setFileChanged] = useState(false);
  const [helpSelected, setHelpSelected] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [attachmentType, setAttachmentType] = useState<string | undefined>();

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.help_id){
      getHelpsById(item.help_id)
    }else{
      setValue("attachment_type", "1")
      setAttachmentType("1")
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const newHelp = {
        name: formData.name,
        video_url: formData.video_url,
        description: formData.description,
        file: fileChanged ? formData.file : undefined,
        attachment_type: parseInt(formData.attachment_type),
        filename: fileChanged ? formData.filename : undefined,
      };

      if (helpSelected?.id) {
        const result = await helpService.updateById(
          helpSelected.id,
          newHelp
        );

        if (result && !result.error) {
         // history.goBack()

          //reset();
          //setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await helpService.create(newHelp);

        if (result && !result.error) {
          //history.goBack()

         // reset();
          //setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  async function getHelpsById(id: number) {
    const help = await helpService.getById(id);

    setValue("name", help?.name);
    setValue("filename", help?.filename);
    setValue("video_url", help?.video_url);
    setValue("description", help?.description);
    setValue("attachment_type", help?.attachment_type.toString());

    setAttachmentType(help?.attachment_type.toString())

    setFile(help?.file_url)

    setHelpSelected(help)
  }

  function base64toBlob(base64Image: string) {
    const parts = base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
  
    const uInt8Array = new Uint8Array(decodedData.length);
  
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
  
    return new Blob([uInt8Array], { type: imageType });
  }

	const downloadFile = () => {
    if (file){
      if (fileChanged){
        const filename = getValues("filename")
        
        fileDownload(base64toBlob(getValues("file")), filename)
      }else{
        const filename = helpSelected.filename

        axios.get(file, { responseType: 'blob' })
          .then((res) => { fileDownload(res.data, filename)})
      }
    }
  }

	const fileChangeHandler = (event:any) => {
    async function saveImage(base64?:string) {
      setValue("file", base64 ? base64 : "")
      setFile(base64 ? base64 : "")
      setValue("filename", file.name)
    }

    setFileChanged(true)

    const file = event.target.files[0]
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64 = reader.result;

      saveImage(base64?.toString())
    };
	};

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Tópicos de ajuda" />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 30, marginLeft: 50, marginRight: 50  }} >
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Nome
          </label>

          <input style={{width: '95%'}}
                {...register("name", { required: true })}/>
                      
          {errors.name && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Tipo de tópico
          </label>

          <div style={{ display: 'flex', justifyContent: 'start',  alignItems: 'center', marginTop: 5}}>
              <input type="radio" value="1" style={{width: 20, marginLeft: 0,  marginRight: 10}}
                      {...register("attachment_type", { required: true })}
                      onChange={(event) => {
                        setAttachmentType(event.target.value)
                          setValue("attachment_type", event.target.value);
                      }} /> 
              <span>Adobe PDF</span> 

              <input type="radio" value="2" style={{width: 20, marginRight: 10}}
                      {...register("attachment_type", { required: true })}
                      onChange={(event) => {
                        setAttachmentType(event.target.value)
                          setValue("attachment_type", event.target.value);
                      }} /> 
              <span>Vídeo</span> 
          </div>

          {errors.attachment_type && (
              <span style={{ color: "#E33F3F", marginTop: 10 }}>
                  Esse campo é obrigatório
              </span>
          )}

          {attachmentType === "2" && (
            <div style={{ display: "flex", flexDirection: "column" }} >
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Link Youtube
              </label>
    
              <input style={{width: '95%'}}
                    {...register("video_url", { required: true })}/>
                          
              {errors.video_url && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
          )}
          
          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Descrição
          </label>

          <textarea style={{width: '95%', height: 100}}
                {...register("description")}/>
                      
          {errors.description && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          {attachmentType === "1" && (
            <div style={{ borderStyle: "dashed", marginTop: 20, borderWidth: "1px", 
                          paddingTop: 15, paddingBottom: 15, borderColor: "#9E9E9E", 
                          width: "98%", textAlign: "center", cursor: "pointer"}} 
                          onClick={() => (document.querySelector("#fileInput") as HTMLInputElement).click()}>
              <img src={AttachmentIcon} alt="coisa" width={32}/>

              <br/>

              <span style={{padding: 5, fontSize: 14, fontStyle: "bold"}}>
                Insira aqui o arquivo que deseja anexar como tópico de ajuda.
              </span>
            </div>
          )}

          <input type="file" id="fileInput" 
                accept="image/png,image/gif,image/jpeg,application/pdf" 
                style={{ display: "none"}} name="file" 
                onChange={fileChangeHandler} />

          {attachmentType === "1" && file && file.length > 0 && (
            <div style={{ display: "flex", justifyContent: "center" }} >
              <div style={{ borderStyle: "dashed", marginTop: 20, borderWidth: "1px", 
                            padding: 10, borderColor: "#9E9E9E", 
                            width: "300px", textAlign: "center", cursor: "pointer"}} 
                            onClick={() => downloadFile()}>
                <div style={{ display: "flex", justifyContent: "center" }} >
                  <img src={DownloadIcon} alt="download" width={16}/>

                  <span style={{padding: 5, fontSize: 16, fontStyle: "bold"}}>Baixar arquivo anexado</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </Container>
  );
};
