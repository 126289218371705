import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import { styles as tableStyles } from '../../../../components/PDF/styles/table'
import { styles } from '../../../../components/PDF/styles/common'
import { TheadItem } from '../../../../components/PDF/TheadItem'
import { TbodyItem } from '../../../../components/PDF/TbodyItem'
import { formatPrice3 } from '../../../../utils/transform'

export const Scope1Emissions: React.FC<any> = (props) => {
  const { pdfData } = props
  return (
    <View wrap={false} style={styles.textContainer}>
      <Text style={{...tableStyles.tableTitle, ...styles.light}}>
        Emissões de Escopo 1 desagregadas por categoria
      </Text>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TheadItem width="40%">Categoria</TheadItem>
        <TheadItem width="20%">
          Emissões tCO<Text style={styles.subscript}>₂</Text>e
        </TheadItem>
        <TheadItem width="20%">
          Emissões CO<Text style={styles.subscript}>₂</Text> biogênico
        </TheadItem>
        <TheadItem width="20%">
          Remoções CO<Text style={styles.subscript}>₂</Text> biogênico
        </TheadItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          Combustão móvel
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.mobile_combustion.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.mobile_combustion.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.mobile_combustion.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          Combustão estacionária
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.stationary_combustion.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.stationary_combustion.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.stationary_combustion.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          Processos industriais
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.industrial_processes.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.industrial_processes.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.industrial_processes.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          Resíduos sólidos e efluentes líquidos
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.solid_waste_and_liquid_effluents.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.solid_waste_and_liquid_effluents.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(
            pdfData.solid_waste_and_liquid_effluents.removal_of_bio_co2,
            false
          )}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          Fugitivas
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.fugitives.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.fugitives.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.fugitives.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          Atividades agrícolas
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.agricultural_activities.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.agricultural_activities.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.agricultural_activities.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          Mudança no uso do solo
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.change_in_land_use.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.change_in_land_use.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.change_in_land_use.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={tableStyles.totalRow}>
        <TbodyItem textAlign="left" width="40%">
          Total de emissões Escopo 1
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.totals.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.totals.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.totals.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
    </View>
  )
}
