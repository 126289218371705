import React, { useEffect, useState } from "react";
import { Container } from "./styles"
import { Table } from "../../../../../../../components/Table/index";
import { formatInputNumber } from "../../../../../../../utils/utils";
import { BsFillTrashFill } from "react-icons/bs";
import { FaRegTrashCan } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import theme from "../../../../../../../theme";
import { BulletScope } from "../../../../../components/BulletScope"
import scope3Service from "../../../../../../../services/scope3Service";
import { json } from "d3";
import { formatPrice5 } from "../../../../../../../utils/transform";

interface TableGHGHomeOfficeProps {
    isCommonUser: boolean;
    emissionCo2T3: number | any;
    register: (value: string, e: any) => any;
    setValue: (value: string, e: any) => any;
    handleChangeValue: (index: number, ignore_field?: string) => any;
    handleDeleteHomeOfficeT3: (index: number) => any;
    handleOpenModalDelete: () => void;
    handleDeleteAllScopeT3: () => void;
    setLoadingModal: (value: boolean) => void;
    homeOfficeT3: any;
}

export const TableGHGHomeOffice: React.FC<TableGHGHomeOfficeProps> = ({ isCommonUser, register, setValue, homeOfficeT3, emissionCo2T3,
    handleChangeValue, handleDeleteHomeOfficeT3, handleDeleteAllScopeT3, 
    handleOpenModalDelete, setLoadingModal }) => {
    const location = useLocation();
    const [companyId, setCompanyId] = useState<number>();
    const [yaer, setYear] = useState<number>();


    const headers = [
        { label: "Registro da fonte", width: 100, className: "head-tr-item", },
        { label: "Descrição da Fonte", width: 100, className: "head-tr-item", },
        { label: "Número de colaboradores", width: 100, className: "head-tr-item", },
        { label: "Dias trabalhados na semana em regime remoto", width: 100, className: "head-tr-item", },
        { label: "Consumo médio por colaborador (W)", width: 100, className: "head-tr-item", },
        { label: "Dias totais trabalhados no ano", width: 100, className: "head-tr-item", },
        { label: "Consumo médio de eletricidade (MWh)", width: 100, className: "head-tr-item", },
        { label: "Emissões de CO2 (t)", width: 100, className: "head-tr-item", },
        { label: <div onClick={handleOpenModalDelete}><button onClick={() => handleDeleteAll(homeOfficeT3)} style={{background: "#FBACAF", color: "#C42329", borderRadius: 4, borderColor: "#C42329", borderWidth: 1, height: 35, width: 60, margin: 10, cursor: "pointer",}}>Apagar Todos</button></div>, width: 100, className: "head-tr-item", },
    
    ];

    // Function to handle data delete
    const handleDelete = (index: number) => {
        setLoadingModal(true)
        const scope = [homeOfficeT3[index]];
        if (scope){
            try {
                const item: any = location?.state;
                const exec_remove = async () => {
                    await scope3Service.deleteDataGHG(item.company_id, item.year, JSON.stringify(scope))
                        .then(() => toast.success("Operação realizada com sucesso!"))
                        .finally(() => setLoadingModal(false))
                }
                exec_remove()
            } catch (error) {
                console.error(error);
                toast.error("Ocoreu um erro ao realizar a operação!");
            }
        }
        handleDeleteHomeOfficeT3(index);
    };

    // Function to handle data delete
    const handleDeleteAll = (scopes: any[]) => {
        setLoadingModal(true)
        if (scopes){
            try {
                const item: any = location?.state;
                const exec_remove = async () => {
                    await scope3Service.deleteDataGHG(item.company_id, item.year, JSON.stringify(scopes))
                        .then(() => toast.success("Operação realizada com sucesso!"))
                        .finally(() => setLoadingModal(false))
                }
                exec_remove();
                
            } catch (error) {
                console.error(error);
                toast.error("Ocoreu um erro ao realizar a operação!");
            }
        }
        handleDeleteAllScopeT3();
        
    };

    // Function to handle with string and to generate <td><input> into table
    const setTdIpuntText = (rowIndex: number, field: string) => {
        return <td className="input-item">
                    <input type="text" 
                        readOnly={isCommonUser}
                        style={{ width: 200 }}
                        className="input-item"
                        {...register(`homeOfficeT3.${rowIndex}.${field}`, {
                            required: true,
                        })}
                        onChange={
                            (event) => {
                                setValue(
                                    `homeOfficeT3.${rowIndex}.${field}`, event.target.value
                                );
                                homeOfficeT3[rowIndex][field] = event.target.value
                            }
                        }
                        
                    />
            </td>
    };
    
    // Function to handle with number and to generate <td><input> into table
    const setTdIpuntNumber = (rowIndex: number, field: string) => {
        return <td className="input-item">
                    <input type="text" 
                        readOnly={isCommonUser}
                        style={{ width: 150, textAlign: 'center' }}
                        className="input-item"
                        {...register(`homeOfficeT3.${rowIndex}.${field}`, {
                            required: true,
                        })}
                        onChange={
                            (event) => {
                                const { value, formatValue } = formatInputNumber(event);
                                homeOfficeT3[rowIndex][field] = value;
                                setValue(
                                    `homeOfficeT3.${rowIndex}.${field}`, formatValue
                                );
                                handleChangeValue(rowIndex, field);
                            }
                        }
                        
                    />
            </td>
    };

    // Function to generate <td><span> into table
    const setTdSpan = (rowIndex: number, field: string, default_value?: any) => {
        return <td className="input-item">
                <span style={{ 
                    marginLeft: 5, 
                    width: 150, 
                    fontSize: 15, 
                    display: "inline-block", 
                    textAlign: "center"}}> 

                    {homeOfficeT3[rowIndex][field]} 

                </span>
            </td>
    };

    // Function to generate <td><span> into table
    const setTdSpanNumber = (rowIndex: number, field: string, default_value?: any) => {
        return <td className="input-item">
                <span style={{ 
                    marginLeft: 5, 
                    width: 150, 
                    fontSize: 15, 
                    display: "inline-block", 
                    textAlign: "center"}}> 



                    {field == "emission_co2" || field == "average_consumption_electricity" ? (
                        homeOfficeT3[rowIndex][field] ? formatPrice5(homeOfficeT3[rowIndex][field], false) : 0
                    ):(
                        homeOfficeT3[rowIndex][field] ? homeOfficeT3[rowIndex][field] : 0
                    )} 

                </span>
            </td>
    };
    
    const renderRow = (row: any, rowIndex: number) => (
        <tr key={rowIndex}>
            {setTdIpuntText(rowIndex, "source")}
            {setTdIpuntText(rowIndex, "description")}
            {setTdIpuntNumber(rowIndex, "number_employees")}
            {setTdIpuntNumber(rowIndex, "days_worked_home_office")}
            {setTdSpanNumber(rowIndex, "average_consumption_employee")}
            {setTdIpuntNumber(rowIndex, "days_worked_year")}
            {setTdSpanNumber(rowIndex, "average_consumption_electricity")}
            {setTdSpanNumber(rowIndex, "emission_co2")}
            <td><div style={{display: "flex", justifyContent: "center", alignContent: "center"}}><button style={{background: "transparent", border: "transparent", cursor: "pointer", }} onClick={() => handleDelete(rowIndex)}><FaRegTrashCan color="red" size={18}/></button></div></td>
        </tr>
    );

    const tfoot = () => {
        return <tfoot className="head-tr-item-bottom">
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ textAlign: "end", }}><b>Total Emissões de CO2 (t): </b></td>
                <td style={{ textAlign: "center", }}><b>{formatPrice5(emissionCo2T3, false) || 0.000}</b></td>
                <td></td>
            </tr>
        </tfoot>
    };

    useEffect(() => {
        const item: any = location?.state;
        if (item && item.company_id && item.year) {
            setCompanyId(item.company_id)
            setYear(item.year)
        }
    })

    return (
        <Container>
            <Table 
                headers={headers}
                data={homeOfficeT3}
                renderRow={renderRow}
                tfoot={tfoot}
            />
        </Container>
    );
};
