import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import trainCompanyService from "../../../services/trainCompanyService";
import { Container } from "./styles";

import { formatPrice6 } from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

interface Inputs {
  name: string;
  factor_co2_kg: any,
  factor_ch4_kg: any,
  factor_n2o_kg: any,
  description: string;
}

export const TrainCompaniesEdit = () => {
  const history = useHistory();

  const [trainCompanySelected, setTrainCompanySelected] = useState<any>({});
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.train_id){
      getTrainCompanysById(item.train_id)
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const factor_co2_kg = formData.factor_co2_kg.replace(".", "");
      const factor_ch4_kg = formData.factor_ch4_kg.replace(".", "");
      const factor_n2o_kg = formData.factor_n2o_kg.replace(".", "");

      const newTrainCompany = {
        name: formData.name,
        description: formData.description,
        factor_co2_kg: factor_co2_kg ? parseFloat(factor_co2_kg.replace(",", ".")) : 0,
        factor_ch4_kg: factor_ch4_kg ? parseFloat(factor_ch4_kg.replace(",", ".")) : 0,
        factor_n2o_kg: factor_n2o_kg ? parseFloat(factor_n2o_kg.replace(",", ".")) : 0,
      };

      if (trainCompanySelected?.id) {
        const result = await trainCompanyService.updateById(
          trainCompanySelected.id,
          newTrainCompany
        );

        if (result && !result.error) {
         // history.goBack()

          //reset();
         // setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await trainCompanyService.create(newTrainCompany);

        if (result && !result.error) {
          history.goBack()

          reset();
          setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  async function getTrainCompanysById(id: number) {
    const trainCompany = await trainCompanyService.getById(id);

    setValue("name", trainCompany?.name);
    setValue("description", trainCompany?.description);

    setValue("factor_co2_kg", formatPrice6(Number(trainCompany.factor_co2_kg), false));
    setValue("factor_ch4_kg", formatPrice6(Number(trainCompany.factor_ch4_kg), false));
    setValue("factor_n2o_kg", formatPrice6(Number(trainCompany.factor_n2o_kg), false));

    setTrainCompanySelected(trainCompany)
  }
    
  function currency(e: React.FormEvent<HTMLInputElement>) {
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d)(\d{6})$/, "$1,$2");
      value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
      return value;
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="TrainCompanyo verificador" />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 30, marginLeft: 50, marginRight: 50  }} >
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Nome
          </label>

          <input style={{width: '95%'}}
                {...register("name", { required: true })}/>
                      
          {errors.name && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 10 }}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Fator 01
              </label>

              <input style={{width: '95%', height: 35}}
                    {...register("factor_co2_kg")}
                    onChange={(event) => {
                        setValue("factor_co2_kg", currency(event));
                    }}/>
                          
              {errors.factor_co2_kg && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Fator 02
              </label>

              <input style={{width: '95%', height: 35}}
                    {...register("factor_ch4_kg")}
                    onChange={(event) => {
                        setValue("factor_ch4_kg", currency(event));
                    }}/>
                          
              {errors.factor_ch4_kg && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "34%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Fator 03
              </label>

              <input style={{width: '95%', height: 35}}
                    {...register("factor_n2o_kg")}
                    onChange={(event) => {
                        setValue("factor_n2o_kg", currency(event));
                    }}/>
                          
              {errors.factor_n2o_kg && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
          </div>
          
          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Descrição
          </label>

          <textarea style={{width: '95%', height: 100}}
                {...register("description")}/>
                      
          {errors.description && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>
      </form>
    </Container>
  );
};
