import { api } from "./api";

async function getById(id: number) {
  try {
    const { data } = await api.get(`/climatic_characteristic/${id}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByCity(state: string, city: string) {
  try {
    const { data } = await api.get(`/climatic_characteristic/${state}/${city}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getStates() {
  try {
    const { data } = await api.get(`/climatic_characteristic/states`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByState(uf: number) {
  try {
    const { data } = await api.get(`/climatic_characteristic/${uf}/cities`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/climatic_characteristic", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(item: any) {
  try {
    const { data } = await api.post(`/climatic_characteristic`, item);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(id: number, item: any) {
  try {
    const { data } = await api.put(`/climatic_characteristic/${id}`, item);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(id: number) {
  try {
    const { data } = await api.delete(`/climatic_characteristic/${id}`);
    return data;
  } catch (error) {
    return false;
  }
}

const climaticCharacteristicService = {
  getById,
  getByCity,
  getStates,
  getByState,
  getByParams,
  create,
  updateById,
  deleteById
};

export default climaticCharacteristicService;
