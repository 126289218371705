import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { GoBack } from "../../components/GoBack";
import { Pagination } from "../../components/Pagination";
import { TitleText } from "../../components/TitleText";
import useAuth from "../../hooks/useAuth";
import auditService from "../../services/auditService";
import { Container } from "./styles";

export const Audit = () => {
  const currentDate = new Date();
  const formattedStartDate = `${currentDate.getFullYear()}-01-01`;
  const formattedEndDate = `${currentDate.getFullYear()}-${("0" + (currentDate.getMonth() + 1)).slice(-2)}-${("0" + currentDate.getDate()).slice(-2)}`;

  const [endDate, setEndDate] = useState(formattedEndDate);
  const [startDate, setStartDate] = useState(formattedStartDate);

  const [audits, setAudits] = useState<any>([]);

  const [users, setUsers] = useState<any>([]);
  const [entities, setEntities] = useState<any>([]);
  const [companies, setCompanies] = useState<any>([]);

  const [userSelected, setUserSelected] = useState<string | undefined>(undefined);
  const [entitySelected, setEntitySelected] = useState<string | undefined>(undefined);
  const [companySelected, setCompanySelected] = useState<string | undefined>(undefined);

  const [currentPage, setCurrentPage] = useState(1);

  useAuth();

  const {
    register,
  } = useForm<any>();

  async function getAudits(page: number, user?: string, entity?: string, company?: string, start_date?: string, end_date?: string) {
    const result = await auditService.getByParams({ limit: 25, page, company: company, 
        user: user, entity: entity, start_date: start_date, end_date: end_date });

    setAudits(result);

    setCurrentPage(page)
  }

  async function getUsers() {
    const result = await auditService.getUsers();

    setUsers(result);
  }

  async function getEntities() {
    const result = await auditService.getEntities();

    setEntities(result);
  }

  async function getCompanies() {
    const result = await auditService.getCompanies();

    setCompanies(result);
  }

  useEffect(() => {
    getUsers();
    getEntities();
    getCompanies();
  
    getAudits(1, userSelected, entitySelected, companySelected, startDate, endDate);
  }, [companySelected, entitySelected, userSelected, startDate, endDate]);
  

  useEffect(() => {
    getAudits(1, userSelected, entitySelected, companySelected, startDate, endDate);
  }, [companySelected, endDate, entitySelected, startDate, userSelected]);

  const handleChangeUser = (e: any) => {
    const id = e.target.value;
    setUserSelected(id);
    getAudits(1, id, entitySelected, companySelected, startDate, endDate);
  };

  const handleChangeEntity = (e: any) => {
    const id = e.target.value;
    setEntitySelected(id);
    getAudits(1, userSelected, id, companySelected, startDate, endDate);
  };

  const handleChangeCompany = (e: any) => {
    const id = e.target.value;
    setCompanySelected(id);
    getAudits(1, userSelected, entitySelected, id, startDate, endDate);
  };

  const goPrev = () => {
    setCurrentPage(currentPage - 1);
    getAudits(currentPage - 1, userSelected, entitySelected, companySelected, startDate, endDate);
  };

  const goNext = () => {
    setCurrentPage(currentPage + 1);
    getAudits(currentPage + 1, userSelected, entitySelected, companySelected, startDate, endDate);
  }; 

  return (
    <Container>
      <GoBack />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 15,
          justifyContent: "space-between",
        }}
      >
        <TitleText level={1} title="Histórico" />

      </div>

      {audits?.itens &&
        ( 
        <div style={{ overflow: 'hidden', position: 'relative',  marginTop: 20, width: 'calc(100vw - 300px)',
              maxWidth: 'calc(100vw - 300px)', height: "auto" }} >
            <div style={{ overflow: 'auto', position: 'relative',  marginTop: 0, width: '100%', 
                height: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }} >
              <table style={{ width: "1250px", borderCollapse: "collapse" }} className="table-audit">
                <thead>
                  <tr>
                    <th className="head-tr-item" style={{width: 220}}>
                      {"Data"}
                    </th>
                    <th className="head-tr-item" style={{width: 140}}>
                      {"Sessão"}
                    </th>
                    <th className="head-tr-item" style={{width: 150}}>
                      {"Empresa"}
                    </th>
                    <th className="head-tr-item" rowSpan={2} style={{width: 280}}>
                      {"Operação"}
                    </th>
                    <th className="head-tr-item" rowSpan={2} style={{width: 250}}>
                      {"Descrição"}
                    </th>
                    <th className="head-tr-item" style={{width: 200}}>
                      {"Usuário"}
                    </th>
                  </tr>
                  <tr>
                    <th className="head-tr-item" style={{width: 220}}>
                      <div style={{ display: "flex", flexDirection: "row", fontSize: 12}}>
                        <input type='date' style={{width: 100, height: "25px !important", fontSize: 12, marginRight: 10, marginLeft: 5}}
                            {...register("start_date", { required: true, valueAsDate: true })} 
                            onChange={(e)=>setStartDate(e.target.value)} value={startDate}/>
                        
                        <input type='date' style={{width: 100, height: "25px", fontSize: 12, marginRight: 5}}
                            {...register("end_date", { required: true, valueAsDate: true })} 
                            onChange={(e)=>setEndDate(e.target.value)} value={endDate}/>
                      </div>
                    </th>
                    <th className="head-tr-item" style={{width: 150}}>
                      {entities && (
                        <select style={{ background: "#F6F6F6", border: "1px solid #DFDFDF",
                            borderRadius: " 2.5px", width: "100%", height: "25px", marginRight: 20}}
                            {...register("entities", { required: true })}
                            onChange={handleChangeEntity} >
                            <option label="TODAS"></option>
                            {entities && entities.map((item: any) => {
                              return (
                                <option value={item.name} key={item.name}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      )}
                    </th>
                    <th className="head-tr-item" style={{width: 150}}>
                      {companies && (
                        <select style={{ background: "#F6F6F6", border: "1px solid #DFDFDF",
                            borderRadius: " 2.5px", width: "100%", height: "25px", marginRight: 20}}
                            {...register("companies", { required: true })}
                            onChange={handleChangeCompany} >
                            <option label="TODAS"></option>
                            {companies && companies.map((item: any) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      )}
                    </th>
                    <th className="head-tr-item" >
                      {users && (
                        <select style={{ background: "#F6F6F6", border: "1px solid #DFDFDF",
                            borderRadius: " 2.5px", width: "100%", height: "25px", marginRight: 20}}
                            {...register("users", { required: true })}
                            onChange={handleChangeUser} >
                            <option label="TODOS"></option>
                            {users && users.map((item: any) => {
                              return (
                                <option value={item.mail} key={item.mail}>
                                  {item.label}
                                </option>
                              );
                            })}
                        </select>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {audits.itens.map((item: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td style={{ padding: "5px", fontSize: 14, textAlign: "center" }} >
                            <span className="title-text-underline">
                              {item.date}
                            </span>
                          </td>
                          <td style={{ padding: "5px", fontSize: 14 }} >
                            <span>
                              {item.entity}
                            </span>
                          </td>
                          <td style={{ padding: "5px", fontSize: 14 }} >
                            <span style={{fontSize: 12}}>
                              {item.company}
                            </span>
                          </td>
                          <td style={{ padding: "5px", fontSize: 14 }} >
                            <span>
                              {item.operation}
                            </span>
                          </td>
                          <td style={{ padding: "5px", fontSize: 14 }} >
                            <span>
                              {item.description}
                            </span>
                          </td>
                          <td style={{ padding: "5px", fontSize: 14 }} >
                            <span style={{fontWeight: "bold"}}>
                              {item.user}
                            </span>
                            <br/>
                            <span style={{fontSize: 12}}>
                              {item.mail}
                            </span>
                            <br/>
                            {'Empresa: ' + (item.company_user ? item.company_user : 'Sinergia Engenharia')}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {audits?.itens?.length > 0 && (
              <Pagination
                total={audits.total}
                limit={25}
                goNext={goNext}
                goPrev={goPrev}
                currentPage={currentPage}
                length={audits.itens.length}
              />
            )}

          </div>
        )
      }
    </Container>
  );
};
