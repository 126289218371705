import { Page, Text, View, StyleSheet, Image as ImagePDF} from "@react-pdf/renderer";
import React from "react";
import { Header } from "../../components/Header";
import { ReportData } from "../../../interfaces/ReportData";
import { Logos } from "../../components/Logos";
import { styles } from "../../styles";
import { IGEEProps } from "../../../interfaces/IGEEData";
import LogoVerdeEscuro from "../../../../../../../assets/images/versoes-logo-icone-ingee-03.png";

export const Page28: React.FC<IGEEProps> = (props) => {
  const { resume } = props;

  const getValue = (value: any) => (value === null || value === undefined || value === "0.0" || value === 0 ? "-" : value);

  const formatNumber = (value: any) => {
    if (value === "-") return "-";
    return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 4 }).format(value);
  };

  const scope1 = getValue(resume?.resumeIgee.emissions_by_category.scope1.totals?.emission_tco2e);
  const scope2 = getValue(resume?.resumeIgee.emissions_by_category.scope2.location.totals?.emission_tco2e);
  const scope3 = getValue(resume?.igee_itens.scope3_total.emission_tco2e);

  const result_scope1 = scope1 !== "-" ? Number(scope1).toFixed(4) : "-";
  const result_scope2 = scope2 !== "-" ? Number(scope2).toFixed(4) : "-";
  const result_scope3 = scope3 !== "-" ? Number(scope3).toFixed(4) : "-";

  const total_scopes = [scope1, scope2, scope3].reduce((a, b) => {
    if (a === "-" || b === "-") return "-";
    return Number(a) + Number(b);
  }, 0);

  const totalScopesTotal = total_scopes !== "-" ? total_scopes.toFixed(4) : "-";

  const goal_scope1 = getValue(resume?.igee_itens.scope1_goal);
  const goal_scope2 = getValue(resume?.igee_itens.scope2_goal);
  const goal_scope3 = getValue(resume?.igee_itens.scope3_goal);
  const goal_scopes_total = getValue(resume?.igee_itens.scopes_goal_total);

  const result_goal_scope1 = goal_scope1 !== "-" ? Number(goal_scope1).toFixed(4) : "-";
  const result_goal_scope2 = goal_scope2 !== "-" ? Number(goal_scope2).toFixed(4) : "-";
  const result_goal_scope3 = goal_scope3 !== "-" ? Number(goal_scope3).toFixed(4) : "-";
  const result_goal_scopes_total = goal_scopes_total !== "-" ? Number(goal_scopes_total).toFixed(4) : "-";

  const CarbonicTable = [
    {
      left: result_scope1 !== "-" ? formatNumber(result_scope1) : "-",
      right: "Escopo 1",
    },
    {
      left: result_scope2 !== "-" ? formatNumber(result_scope2) : "-",
      right: "Escopo 2",
    },
    {
      left: result_scope3 !== "-" ? formatNumber(result_scope3) : "-",
      right: "Escopo 3",
    },
    {
      left: totalScopesTotal !== "-" ? formatNumber(totalScopesTotal) : "-",
      right: "Total",
    },
  ];

  const ReductionTable = [
    {
        year: getValue(resume?.igee_itens.year_scope1),
        left: result_goal_scope1 !== "-" ? formatNumber(result_goal_scope1) : "-",
        right: "Escopo 1",
    },
    {
        year: getValue(resume?.igee_itens.year_scope2),
        left: result_goal_scope2 !== "-" ? formatNumber(result_goal_scope2) : "-",
        right: "Escopo 2",
    },
    {
        year: getValue(resume?.igee_itens.year_scope3),
        left: result_goal_scope3 !== "-" ? formatNumber(result_goal_scope3) : "-",
        right: "Escopo 3",
    },
    {
        year: getValue(resume?.igee_itens.year_scopes_total),
        left: result_goal_scopes_total !== "-" ? formatNumber(result_goal_scopes_total) : "-",
        right: "Total",
    },
  ];

  return (
    <Page
      size={[640, 480]}
      style={{ ...styles.Page, backgroundColor: "#ecf3f4" }}
    >
      <Header backColor="#81c787" title="7. Metas" />
      <View style={local.body}>
        <Text style={{ ...local.title, ...styles.boldItalic, marginLeft: 6 }}>
          Meta de redução de emissão absoluta
        </Text>
        <View style={local.tables}>
          <View style={{ ...local.table, borderRight: "1.5px solid #81c787" }}>
            <View style={local.subTitle}>
              <Text style={{ ...styles.light, marginTop: -5, marginBottom: 15, marginLeft: 20}}>
                Emissões absolutas no ano inventariado {"\n"}
                (tCO<Text style={{ fontSize: 5 }}>2</Text>e)
              </Text>
              <View
                  style={{
                    position: "absolute",
                    bottom: "10%",
                    width: "200%",
                    height: 1,
                    backgroundColor: "#81c787",
                    borderTop: "1px solid #81c787",
                    marginTop: 10,
                    left: -15
                  }}
                />
            </View>
            {/* Linha Horizontal */}
            {CarbonicTable.map((d, i) => (
              <View style={local.item} key={i}>
                <View style={local.row}>
                  <View style={local.cellLeft}>
                    <Text
                      style={{
                        ...local.number,
                        ...styles.Bold,
                        ...styles.italic
                      }}
                    >
                      {d.left}
                    </Text>
                  </View>
                  <View style={{...local.cellRight}}>
                    <Text style={{ ...local.text, ...styles.Bold }}>
                      {d.right}
                    </Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
          <View style={local.table}>
            <View style={local.subTitle}>
              <Text style={{ ...styles.light, marginTop: -5, marginBottom: 15, marginLeft: 20}}>
                Meta de redução de emissão absoluta {"\n"}
                (Emissões (tCO<Text style={{ fontSize: 5 }}>2</Text>e))
              </Text>
            </View>
            {ReductionTable.map((d, i) => (
              <View style={local.item} key={i}>
                <View style={local.row}>
                  <View style={local.cellLeft2}>
                    <Text
                      style={{
                        ...local.number,
                        ...styles.Bold,
                        ...styles.italic,
                        color: "#81c787",

                      }}
                    >
                      {d.year} - {d.left}
                    </Text>
                  </View>
                  <View style={{...local.cellRight2}}>
                    <Text style={{ ...local.text, ...styles.Bold, color: "#81c787"}}>
                      {d.right}
                    </Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
          <View
            style={{
              position: "absolute",
              top: "123%",
              width: 20,
              height: 20,
              right: '56.1%',
              borderRadius: "50%",
              backgroundColor: "#81c787",
              transform: "translate(50%, -50%)",
            }}
          />
        </View>
        <View style={{ position: 'absolute', bottom: 10, right: 10, flexDirection: 'row', alignItems: 'center' }}>
              <ImagePDF
                src={resume?.company_itens.logo_image_url}
                style={{
                  width: 70,
                  height: 'auto',
                  left: '10px',
                }}
              />
              <ImagePDF
                src={LogoVerdeEscuro}
                style={{
                  width: 100,
                  height: 'auto',
                }}
              />
            </View>
      </View>
    </Page>
  );
};

const local = StyleSheet.create({
  body: {
    display: "flex",
    width: "100%",
    height: "90%",
    alignItems: "center",
    alignContent: "center",
    padding: "20px",
  },
  title: {
    color: "#a1a4a6",
    fontSize: 18,
    padding: "5px",
    alignSelf: "flex-start",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    padding: "20px",
  },
  tables: {
    display: "flex",
    width: "110%",
    flexDirection: "row",
    border: "1.5px solid #76B987",
    marginVertical: "20px",
  },
  subTitle: {
    color: "#b5b5b9",
    fontSize: 10,
    textAlign: "left",
    width: "130%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginBottom: 10,
  },
  item: {
    padding: "4px",
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: "12px",
  },
  number: {
    color: "#2a70b8",
    fontSize: 14,
  },
  text: {
    color: "#2a70b8",
    fontSize: 12,
    textAlign: "left",
    width: "100%",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%"
  },
  cellLeft: {
    flex: 1,
    textAlign: "left",
    paddingRight: 10,
    marginLeft: 10
  },
  cellRight: {
    flex: 1,
    textAlign: "left",
    paddingLeft: 10,
    marginLeft: -10
  },
  cellLeft2: {
    flex: 1,
    textAlign: "left",
    paddingRight: 10,
  },
  cellRight2: {
    flex: 1,
    textAlign: "left",
    paddingLeft: 10,
  },
});
