import styled from 'styled-components';

interface MainProps {
    isMenuOpen: boolean;
}

export const Container = styled.div`
    padding: 0px;
    margin: 15px;
    width: 100%;

    .table-scope {
        border-color: red;
        border-width: 1px;
        border-style: solid;
    }

    .table-scope tr,
    .table-scope th,
    .table-scope td {
        border-color: white;
        border-width: 1px;
        border-style: solid;
    }
    
    .table-scope td {
        background: #F6F6F6;
    }

    .head-tr-item-bottom{
        background-color: #C6C5C5;
        font-size: 12px;
        height: 25px;
    }

    .head-tr-item{
        background-color: #D8D7D7 !important;
        font-size: 12px;
        height: 25px;
    }

    .subhead-item{
        background-color: #F6F6F6 !important;
        font-size: 12px;
        height: 25px;
        text-align: right;
        font-weight: bold;
    }

    .input-item{
        background: #ffffff;
        margin: 2px;
        padding-right: 2px;
        padding-left: 2px;
        height: 30px !important;
        border: 1px solid #DFDFDF;
        border-radius: 2.5px;
    }
`;

export const Main = styled.div<MainProps>`
    overflow: auto;
    position: relative;
    margin-top: 20px;
    width: ${props => props.isMenuOpen ? "calc(100vw - 320px)" : "calc(100vw - 120px)"};
    max-height: calc(100vh - 360px);
`;