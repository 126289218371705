import { StyleSheet, Font } from "@react-pdf/renderer";

import MuseoModerno from "../../../../../assets/fonts/MuseoModerno-Light.ttf";
import MuseoModernoItalic from "../../../../../assets/fonts/MuseoModerno-Italic.ttf";
import MuseoModernoBoldItalic from "../../../../../assets/fonts/MuseoModerno-BoldItalic.ttf";
import MuseoModernoExtraLight from "../../../../../assets/fonts/MuseoModerno-ExtraLight.ttf";
import MuseoModernoExtraBold from "../../../../../assets/fonts/MuseoModerno-ExtraBold.ttf";
import MuseoModernoBold from "../../../../../assets/fonts/MuseoModerno-Bold.ttf";

Font.register({ family: "MuseoModerno", src: MuseoModerno });
Font.register({
  family: "MuseoModerno-Light",
  src: MuseoModernoExtraLight,
});
Font.register({
  family: "MuseoModerno-Italic",
  src: MuseoModernoItalic,
});
Font.register({
  family: "MuseoModerno-ExtraBold",
  src: MuseoModernoExtraBold,
});
Font.register({
  family: "MuseoModerno-BoldItalic",
  src: MuseoModernoBoldItalic,
});
Font.register({
  family: "MuseoModerno-Bold",
  src: MuseoModernoBold,
});

export const table = StyleSheet.create({
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "5px"
  },
  titleSection: {
    width: "78%",
    borderBottom: "0.5px solid #64766d",
    alignSelf: "flex-end",
    alignContent: "center",
    marginBottom: "9px",
    marginRight: "8px"
  },
  titleSection2: {
    width: "100%",
    borderBottom: "0.5px solid #64766d",
    alignSelf: "flex-end",
    alignContent: "center",
    marginBottom: "9px",
    marginRight: "8px"
  },
  title: {
    fontSize: 8,
    color: "#64766d",
    alignSelf: "center",
  },
  columns: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  headerColumn: {
    borderRadius: "80%",
    color: "white",
    fontSize: 10,
    alignSelf: "center",
    padding: "3px",
    paddingHorizontal: "5px"
  },
  scope2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  body: {
    width: "100%",
    height: "100%",
  },
  body2: {
    width: "100%",
    height: "120%",
    marginLeft: -10,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "center",
    height: "auto"
  },
  rowText: {
    color: "#64766d",
    fontSize: 10,
    textAlign: "center",
    alignSelf: "center"
  },
  scope3Space: {
    display: "flex",    
    color: "#64766d",
    fontSize: 9,
    width: "60%",
    paddingVertical: "2px",
    height: "auto",
    alignContent: "flex-start",
  },
  scope3: {
    display: "flex", 
    color: "#64766d",
    height: "auto",
    paddingVertical: "2px",
    fontSize: 10,
    alignContent: "center",
    width: "16.67%",
    textAlign: "center"
  },
  kyotoSpace: {
    display: "flex",    
    color: "#64766d",
    fontSize: 9,
    width: "50%",
    height: "auto",
    alignContent: "flex-start",
  },
  kyoto: {
    display: "flex", 
    color: "#64766d",
    height: "auto",
    fontSize: 10,
    alignContent: "center",
    width: "50%",
    textAlign: "center"
  }
});

export const styles = StyleSheet.create({
  Page: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#ecf3f4",
    width: "100%",
  },
  boldItalic: {
    fontFamily: "MuseoModerno-BoldItalic",
  },
  italic: {
    fontFamily: "MuseoModerno-Italic",
  },
  light: {
    fontFamily: "MuseoModerno",
  },
  extraBold: {
    fontFamily: "MuseoModerno-ExtraBold",
  },
  extraLight: {
    fontFamily: "MuseoModerno-Light",
  },
  Bold:{
    fontFamily: "MuseoModerno-Bold"
  },
  greenBall: {
    width: 25,
    marginLeft: -13,
    height: "25px",
    borderRadius: 20,
    backgroundColor: "#80b780",
  },
  iconContent: {
    color: "#64766d",
    fontSize: 11,
    paddingHorizontal: "15px",
    fontFamily: "MuseoModerno-Italic",
    marginLeft: 11,
  },
  blueBall: {
    width: 25,
    marginLeft: 10,
    height: "25px",
    borderRadius: 20,
    backgroundColor: "#326ed6",
    top: -10
  },
  iconContentBlue: {
    color: "#326ed6",
    fontSize: 11,
    paddingHorizontal: "15px",
    fontFamily: "MuseoModerno-Italic",
    top: -9
  },
  blueBall2: {
    width: 25,
    marginLeft: 10,
    height: "25px",
    borderRadius: 20,
    backgroundColor: "#326ed6",
    top: -180
  },
  iconContentBlue2: {
    color: "#326ed6",
    fontSize: 11,
    paddingHorizontal: "15px",
    fontFamily: "MuseoModerno-Italic",
    top: -179
  },
  body: {
    display: "flex",
    backgroundColor: "#ecf3f4",
    flexDirection: "row",
    width: "100%",
    height: "92%",
  },
  header: {
    width: "100%",
    height: "8%",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: "11.5px",
  },
  headerTitle: {
    fontSize: 18,
    fontFamily: "MuseoModerno-ExtraBold",
    color: "#ecf3f4",
    marginLeft: 22
  },
  n: {
    fontSize: 24,
    fontFamily: "MuseoModerno-ExtraBold",
    color: "white",
    // backgroundColor: "red"
  },
  footer: {
    width: "100%",
    height: "15%",
    display: "flex",
    alignItems: "flex-end",
    padding: "7.5px",
    borderTop: "1px solid #76B987",
  },
  logos: {
    height: "10%",
    width: "100%",
    display: "flex",
    position: "relative",
    bottom: "5px",
    flexDirection: "row",
    alignItems: "center",
    paddingRight: "10px",
  },
  upTitleView: {
    height: "30%",
    width: "75%",
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
  },
  homeTitle: {
    color: "#2A70B8",
    fontSize: 32,
    fontFamily: "MuseoModerno",
    marginTop: -70,
  },
  homeCompany: {
    color: "#76b987",
    fontSize: 22,
    fontFamily: "MuseoModerno-Italic",
  },
  sumaryTitle: {
    fontFamily: "MuseoModerno-BoldItalic",
    fontSize: 23,
    color: "#81c787",
    marginLeft: '5px',
    marginBottom: '10px',
    marginTop: '40px'
  },
  sumaryItemTitle: {
    fontSize: 13,
    fontFamily: "MuseoModerno",
    color: "white",
    marginLeft: '5px',
  },
  sumaryItemPage: {
    fontSize: 10,
    fontFamily: "MuseoModerno-ExtraBold",
    color: "white",
    marginRight: '10px',
    marginTop: '2px'
  },
  sumaryItem: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #76B987",
    flexDirection: "row",
    width: "100%",
    marginVertical: "1px",
    marginTop: '5px'
  },
  bolinha: {
    borderRadius: "100%",
    width: 25,
    height: 25,
    display: "flex",
    marginRight: "2px",
    marginLeft: 10,
    alignItems: "center",
    alignContent: "center"
  },
  bolinhaText: {
    fontSize: '12px',
    textAlign: "center",
    marginTop: "2px",
    color: "#ecf3f4"
  },
});
