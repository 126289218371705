import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;
    margin-bottom: 0;
`;

export const Button = styled.button`
    width: 250px;
    height: 40px;
    background-color: rgb(246, 246, 246);
    border: 1px solid rgb(204, 204, 204);
    border-radius: 6px;
    box-shadow: 5px 5px 5px rgb(246, 246, 246);
    color: rgb(128, 128, 128);

    &:hover {
        background-color: rgb(128, 128, 128);
        color: blue;
    }
`;