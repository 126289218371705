import Parser from 'html-react-parser'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import useAuth from '../../../../../hooks/useAuth'
import { Container } from './styles'
import { formatPrice3 } from '../../../../../utils/transform'
import inventoryService from '../../../../../services/inventoryService'
import scope3Service from '../../../../../services/scope3Service'
import scopeService from '../../../../../services/scopeService'
import { Button } from '../../../../../components/Button/styles'
import { Modal } from '../../../../../components/Modal'
import { TitleText } from '../../../../../components/TitleText'
import { GoBack } from '../../../../../components/GoBack'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'
import { toast } from 'react-hot-toast'
import { Loading } from '../../../../../components/Loading'

export const Coprocessing3 = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([])
  const [openModal, setOpenModal] = useState(false)
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [requiredItens, setRequiredItens] = useState(false)
  const [guidelines, setGuidelines] = useState<string | undefined>()
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const location = useLocation()

  const [totalEmissionTco2e, setTotalEmissionTco2e] = useState<any>()

  const [changeScope, setChangeScope] = useState<string | undefined>()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-3/residuos-solidos-gerados'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(3, 'residuos_solidos_gerados', 'coprocessamento')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors }
  } = useForm<any>()

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      
      let coprocessing_fossil_carbon = formData.coprocessing_fossil_carbon.replace( '.', '' )
      let coprocessing_oxidation_factor = formData.coprocessing_oxidation_factor.replace( '.', '' )
      let coprocessing_dry_matter_carbon = formData.coprocessing_dry_matter_carbon.replace('.', '')
      let coprocessing_conversion_factor = formData.coprocessing_conversion_factor.replace('.', '')
      let coprocessing_amount_incinerated = formData.coprocessing_amount_incinerated.replace('.', '')

      coprocessing_fossil_carbon = coprocessing_fossil_carbon ? parseFloat(coprocessing_fossil_carbon.replace(',', '.')) : 0
      coprocessing_oxidation_factor = coprocessing_oxidation_factor ? parseFloat(coprocessing_oxidation_factor.replace(',', '.')) : 0
      coprocessing_dry_matter_carbon = coprocessing_dry_matter_carbon ? parseFloat(coprocessing_dry_matter_carbon.replace(',', '.')) : 0
      coprocessing_conversion_factor = coprocessing_conversion_factor ? parseFloat(coprocessing_conversion_factor.replace(',', '.')) : 0
      coprocessing_amount_incinerated = coprocessing_amount_incinerated ? parseFloat(coprocessing_amount_incinerated.replace(',', '.')) : 0

      const scope = {
        reason: formData.reason,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        coprocessing_fossil_carbon: coprocessing_fossil_carbon,
        coprocessing_oxidation_factor: coprocessing_oxidation_factor,
        coprocessing_dry_matter_carbon: coprocessing_dry_matter_carbon,
        coprocessing_conversion_factor: coprocessing_conversion_factor,
        coprocessing_amount_incinerated: coprocessing_amount_incinerated,
        coprocessing_emission_tco2e: totalEmissionTco2e ? totalEmissionTco2e : 0,
        total_emission_tco2e: totalEmissionTco2e
      }

      const result = await scope3Service.saveCoprocessing(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )
      if (result && !result.error) {
        localStorage.setItem('Coprocessing', JSON.stringify(scope));

        reset()
        setErrorApi(undefined)

        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  const { isCommonUser } = useAuth()

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope3Service.getCoprocessing(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      3,
      'residuos_solidos_gerados',
      'coprocessamento'
    )

    setFiles(result.files)

    if (entity) {
      setScopeSelected(entity)

      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)

      setIgnoreFill(entity.ignore_fill)

      setValue( 'coprocessing_fossil_carbon', formatPrice3(entity.coprocessing_fossil_carbon, false))
      setValue( 'coprocessing_oxidation_factor', formatPrice3(entity.coprocessing_oxidation_factor, false))
      setValue( 'coprocessing_dry_matter_carbon', formatPrice3(entity.coprocessing_dry_matter_carbon, false))
      setValue( 'coprocessing_conversion_factor', formatPrice3(entity.coprocessing_conversion_factor, false))
      setValue( 'coprocessing_amount_incinerated', formatPrice3(entity.coprocessing_amount_incinerated, false) )

      setTotalEmissionTco2e(entity.coprocessing_emission_tco2e)
      
      setChangeScope('load_scope_' + entity.id)
    } else {
      setScopeSelected({})

      setIgnoreFill(false)

      setChangeScope('load_scope_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  const handleChangeValue = () => {

    let coprocessing_fossil_carbon = getValues('coprocessing_fossil_carbon')
    let coprocessing_oxidation_factor = getValues('coprocessing_oxidation_factor')
    let coprocessing_dry_matter_carbon = getValues('coprocessing_dry_matter_carbon')
    let coprocessing_conversion_factor = getValues('coprocessing_conversion_factor')
    let coprocessing_amount_incinerated = getValues('coprocessing_amount_incinerated')

    coprocessing_fossil_carbon = coprocessing_fossil_carbon.replace('.', '')
    coprocessing_oxidation_factor = coprocessing_oxidation_factor.replace('.', '')
    coprocessing_dry_matter_carbon = coprocessing_dry_matter_carbon.replace('.', '')
    coprocessing_conversion_factor = coprocessing_conversion_factor.replace('.', '')
    coprocessing_amount_incinerated = coprocessing_amount_incinerated.replace('.', '')

    coprocessing_fossil_carbon = coprocessing_fossil_carbon ? parseFloat(coprocessing_fossil_carbon.replace(',', '.')) : 0
    coprocessing_oxidation_factor = coprocessing_oxidation_factor ? parseFloat(coprocessing_oxidation_factor.replace(',', '.')) : 0
    coprocessing_dry_matter_carbon = coprocessing_dry_matter_carbon ? parseFloat(coprocessing_dry_matter_carbon.replace(',', '.')) : 0
    coprocessing_conversion_factor = coprocessing_conversion_factor ? parseFloat(coprocessing_conversion_factor.replace(',', '.')) : 0
    coprocessing_amount_incinerated = coprocessing_amount_incinerated ? parseFloat(coprocessing_amount_incinerated.replace(',', '.')) : 0

    async function calculate() {
      const result = await scope3Service.calculateCoprocessing(
        coprocessing_fossil_carbon,
        coprocessing_oxidation_factor,
        coprocessing_dry_matter_carbon,
        coprocessing_conversion_factor,
        coprocessing_amount_incinerated
      )

      if (result) {
        setTotalEmissionTco2e(result.total_emission_tco2e)

        setChangeScope( 'change_scope_item_' + result.total_emission_tco2e )
      }
    }

    calculate()
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{5})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos

    // Verifica se o valor é igual a zero após a formatação e remove os zeros à esquerda
    if (value === '') {
        value = '0';
    }

    return value;
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'residuos_solidos_gerados',
          'coprocessamento'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const handleDownloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const handleAttachmentChange = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 3,
        name: 'residuos_solidos_gerados',
        file: base64,
        filename: filename,
        second_name: 'coprocessamento'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'residuos_solidos_gerados',
          'coprocessamento'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 03 - ' + title}  />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 230px)',
            maxHeight: 'calc(100vh - 230px)'
          }}
        >
          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    fontWeight: 'bold'
                  }}
                >
                  Coprocessamento de resíduos de papel
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5 }}>
                    Quantidade de resíduo coprocessado [t/ano]
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                  <input
                    readOnly={isCommonUser}
                    style={{ width: '200px', height: 35, textAlign: 'right' }}
                    {...register('coprocessing_amount_incinerated')}
                    onChange={(event) => {
                      setValue('coprocessing_amount_incinerated', currency(event))
                      handleChangeValue()
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5 }}>
                    Fração de carbono na matéria seca
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                  <span style={{ marginTop: 5, textAlign: 'right', width: 200, fontSize: 12 }} >
                    {formatPrice3(scopeSelected.coprocessing_dry_matter_carbon, false)}
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5 }}>
                    Fração de carbono fóssil
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                  <span style={{ marginTop: 5, textAlign: 'right', width: 200, fontSize: 12 }} >
                    {formatPrice3(scopeSelected.coprocessing_fossil_carbon, false)}
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5 }}>
                    Fator de oxidação
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                  <span style={{ marginTop: 5, textAlign: 'right', width: 200, fontSize: 12 }} >
                    {formatPrice3(scopeSelected.coprocessing_oxidation_factor, false)}
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5 }}>
                    Fator de conversão de C para CO₂
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                  <span style={{ marginTop: 5, textAlign: 'right', width: 200, fontSize: 12 }} >
                    {formatPrice3(scopeSelected.coprocessing_conversion_factor, false)}
                  </span>
                </div>
              </div>

              {changeScope && (
                <div
                  style={{
                    borderStyle: 'dashed',
                    margin: 5,
                    borderWidth: '1px',
                    width: '100%',
                    borderColor: '#9E9E9E',
                    height: '40px',
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 0
                  }}
                >
                  <span style={{ margin: 10, width: '70%' }}>
                    Quantidade de CO₂, gerada no ano (t/ano)
                  </span>
                  <span
                    style={{
                      fontWeight: 'bold',
                      margin: 10,
                      textAlign: 'right',
                      width: '30%'
                    }}
                  >
                    {formatPrice3(totalEmissionTco2e, false)}
                  </span>
                </div>
              )}

              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <div
                  style={{
                    width: '100%',
                    borderTop: '0.6px solid rgba(49, 54, 63, 0.35)'
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      marginTop: 5,
                      marginLeft: 10,
                      fontWeight: 'bold'
                    }}
                  >
                    Evidências do escopo informado
                  </span>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                    Observações sobre o escopo informado.
                  </label>

                  <textarea
                    readOnly={isCommonUser}
                    style={{ width: '100%', height: 100 }}
                    {...register('evidence')}
                  />

                  {errors.evidence && (
                    <span className="requiredLabel">Esse campo é obrigatório</span>
                  )}

                  <ScopeEvidenceAttachments
                    files={files}
                    onDeleteFile={handleDeleteFile}
                    onDownloadFile={handleDownloadFile}
                    onChangeAttachment={handleAttachmentChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  )
}
