import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import seasonalDataService from "../../../services/seasonalDataService";
import { Container } from "./styles";

import { formatPrice14, formatPrice17, formatPrice3, toNumberValues } from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SeasonalDataEdit = () => {
  const history = useHistory();

  const [seasonalDataSelected, setSeasonalDataSelected] = useState<any>({});
  const location = useLocation();
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [months, setMonths] = useState<any>([]);

  console.error('Erro na API:', errorApi);
  useEffect(() => {
    const item:any = location?.state;

    if (item && item.seasonalData_id){
      getSeasonalDatasById(item.seasonalData_id)
    }else{
      getDefaultSeasonalData()
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = async (formData) => {
    if (formData) {
      const itens:any[] = months.map((month: any, index: number) => {
        const fe_sin = formData.month[index].fe_sin.replace(".", "");
        const ethanol_percentage = formData.month[index].ethanol_percentage.replace(".", "");
        const biodiesel_percentage = formData.month[index].biodiesel_percentage.replace(".", "");

        return {
          month: month.month,
          fe_sin: fe_sin ? parseFloat(fe_sin.replace(",", ".")) : 0,
          ethanol_percentage: ethanol_percentage ? parseFloat(ethanol_percentage.replace(",", ".")) : 0,
          biodiesel_percentage: biodiesel_percentage ? parseFloat(biodiesel_percentage.replace(",", ".")) : 0,
        };
      })

      const newSeasonalData = {
        year: parseInt(formData.year),
        description: formData.description,
        itens: itens
      };

      if (seasonalDataSelected?.id) {
        const result = await seasonalDataService.updateById(
          seasonalDataSelected.id,
          newSeasonalData
        );

        if (result && !result.error) {
         // history.goBack()

         // reset();
         // setErrorApi(undefined);

          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await seasonalDataService.create(newSeasonalData);

        if (result && !result.error) {
        //  history.goBack()

         // reset();
          //setErrorApi(undefined);

          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  function getDefaultSeasonalData(){
    const monthsLabel = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

    const months = []

    for (let i = 0; i < 12; i++) {
      const month = i + 1
      const monthLabel = monthsLabel[i]

      setValue(`month.${i}.fe_sin`, formatPrice3(Number(0), false))
      setValue(`month.${i}.ethanol_percentage`, formatPrice3(Number(0), false))
      setValue(`month.${i}.biodiesel_percentage`, formatPrice3(Number(0), false))

      months.push({
        month: month,
        month_label: monthLabel,
        fe_sin: 0,
        ethanol_percentage: 0,
        biodiesel_percentage: 0,
      })
    }

    setMonths(months)
  }

  async function getSeasonalDatasById(id: number) {
    const seasonalData = await seasonalDataService.getById(id);

    const monthsLabel = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

    setValue("year", seasonalData?.year);
    setValue("description", seasonalData?.description);

    const months = []

    for (let i = 0; i < 12; i++) {
      const month = i + 1
      const monthLabel = monthsLabel[i]

      const item = getItem(month, seasonalData.itens)

      const fe_sin = item && item.fe_sin ? item.fe_sin : 0
      const ethanol_percentage = item && item.ethanol_percentage ? item.ethanol_percentage : 0
      const biodiesel_percentage = item && item.biodiesel_percentage ? item.biodiesel_percentage : 0

      setValue(`month.${i}.fe_sin`, formatPrice17(Number(fe_sin), false))
      setValue(`month.${i}.ethanol_percentage`, formatPrice3(Number(ethanol_percentage), false))
      setValue(`month.${i}.biodiesel_percentage`, formatPrice3(Number(biodiesel_percentage), false))

      months.push({
        month: month,
        month_label: monthLabel,
        fe_sin: fe_sin,
        ethanol_percentage: ethanol_percentage,
        biodiesel_percentage: biodiesel_percentage,
      })
    }

    setMonths(months)

    setSeasonalDataSelected(seasonalData)
  }
    
  function getItem(month?:number, itens?:any) {
    if (itens){
      for (const item of itens){
        if (item.month === month){
          return item
        }
      }
    }

    return undefined
  }
    
  function currency(e: React.FormEvent<HTMLInputElement>) {
      let value = e.currentTarget.value;
      // value = value.replace(/\D/g, "");
      // value = value.replace(/(\d)(\d{4})$/, "$1,$2");
      // value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
      return value;
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Dados Sazonais" />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 30, marginLeft: 50, marginRight: 50  }} >
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Ano
          </label>

          <input style={{width: '95%'}}
                {...register("year", { required: true })}
                onChange={(event) => {
                  const { value } = event.target;
                  event.target.value = toNumberValues(value);
                }}/>
                      
          {errors.year && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
          
          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Descrição
          </label>

          <textarea style={{width: '95%', height: 60}}
                {...register("description")}/>
                      
          {errors.description && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <div style={{ overflow: "hidden auto", position: "relative", height: "calc(100vh - 360px)", marginTop: 20,
            maxHeight: "calc(100vh - 360px)"}} >
            {months && months.length > 0 && (
              <table style={{ width: "90%", borderCollapse: "collapse", marginTop: 5}}>
                <thead>
                  <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                    <th style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                      {"Mês"}
                    </th>
                    <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                      {"FE do SIN"}
                    </th>
                    <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                      {"% Etanol na gasolina"}
                    </th>
                    <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                      {"% Biodiesel no diesel"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {months && months.length > 0 && months.map((month: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td style={{ padding: "10px", width: "19%" }} >
                              <span className="list-text title-text-underline">
                                {month?.month_label}
                              </span>
                          </td>
                          <td style={{ padding: "10px", width: "27%" }} >
                            <input style={{ width: "90%", textAlign: "right" }}
                              {...register(`month.${index}.fe_sin`, { required: true })}
                              onChange={(event) => {
                                  setValue(`month.${index}.fe_sin`, currency(event));
                              }}
                            />
                          </td>
                          <td style={{ padding: "10px", width: "27%" }} >
                            <input style={{ width: "90%", textAlign: "right" }}
                              {...register(`month.${index}.ethanol_percentage`, { required: true })}
                              onChange={(event) => {
                                  setValue(`month.${index}.ethanol_percentage`, currency(event));
                              }}
                            />
                          </td>
                          <td style={{ padding: "10px", width: "27%" }} >
                            <input style={{ width: "90%", textAlign: "right" }}
                              {...register(`month.${index}.biodiesel_percentage`, { required: true })}
                              onChange={(event) => {
                                  setValue(`month.${index}.biodiesel_percentage`, currency(event));
                              }}
                            />
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </form>
    </Container>
  );
};
