import styled from "styled-components";

export const Container = styled.div `
    padding: 0px;
    margin: 15px;
    width: 100%;

    .div-text{
        font-size: 16px;
        font-weight: bold;
        text-align: center;
    }

    .checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        marginTop: 5px;
        margin-right: 20px; 
    }
`