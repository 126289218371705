import React, {useState, useEffect} from "react";
import { Container } from "./styles";
import { GoBack } from "../../../../components/GoBack";
import { TitleText } from "../../../../components/TitleText";
import useAuth from "../../../../hooks/useAuth";
import inventoryService from "../../../../services/inventoryService";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "../../../../components/Button/styles";
import scope3Service from "../../../../services/scope3Service";
import { Modal } from "../../../../components/Modal";
import Parser from "html-react-parser";
import scopeService from "../../../../services/scopeService";
import { ModalScopeDisabled } from "../../components/ModalScopeDisabled";
import { formatPrice3 } from "../../../../utils/transform";
import BoxResult from "../../../../components/BoxResult";

export const EffluentsGenerated = () => {
  const history = useHistory();

  const [itemSelected, setItemSelected] = useState<any>({});
  const [openModalDisabled, setOpenModalDisabled] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  const [guidelines, setGuidelines] = useState<string | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [effluentsGenerated, setEffluentsGenerated] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [inventorySelected, setInventorySelected] = useState<any>({});
  const location = useLocation();

  useEffect(() => {
      const item:any = location?.state;
  
      if (item && item.company_id && item.year){
        getInventorysById(item.company_id, item.year, item.scope)
      }else{
        history.push({
          pathname: '/inventario/escopo-3',
        });
      }
  
      getGuidelines()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]);

    async function getGuidelines() {
        const item = await scopeService.getBySlug(3, 'efluentes_gerados');
    
        setTitle(item.label)
        setGuidelines(item.guidelines)
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { user } = useAuth();
    
      async function getInventorysById(company_id: number, year: number, scope:number) {
        const inventory = await inventoryService.getById(company_id, year)
    
        const entity = await scope3Service.getEffluentsGenerated(company_id, year)
    
        setEffluentsGenerated(entity)
        setInventorySelected(inventory)
      }
    
      function handleOpenInfo () {
        setOpenModal(!openModal)
      }
    
      function redirectToScope(item?: any) {
        if (inventorySelected.company && inventorySelected.company.id && inventorySelected.year){
          if (item.status !== 'disabled'){
            const url = '/inventario/escopo-3/efluentes-gerados/' + item.slug.replaceAll('_', '-')
    
            const state = { company_id: inventorySelected.company.id, year: inventorySelected.year }
    
            history.push({ pathname: url, state: state });
          }else{
            handleOpenModalDisabled(item)
          }
        }
      }
    
      function handleOpenModalDisabled(item?: any) {
        setItemSelected(item)
        setOpenModalDisabled(!openModalDisabled);
      }


  return (
    <Container>
      <GoBack />
      <div style={{ display: "flex", flexDirection: "column" }} >
              <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
                <div style={{ display: "flex", flexDirection: "column" }} >
                  <div style={{ display: "flex", flexDirection: "column"}} >
                    <TitleText level={1} title={'Inventário: ' + inventorySelected?.year + ' - ' + inventorySelected?.company?.corporate_name}  />
                    <TitleText level={4} title={'Escopo 03 - ' + title}  />
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }} >
                  {guidelines && (
                    <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                      style={{ margin: 'auto 10px auto auto' }} >
                      Orientações
                    </Button>
                  )}
                </div>
              </div>
      
              {effluentsGenerated?.scopes && (
                <div style={{ overflow: "hidden auto", position: "relative", marginTop: 0,  height: "calc(100vh - 360px)", 
                    maxHeight: "calc(100vh - 360px)"}} >
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                        <th style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}>
                          {"Escopo"}
                        </th>
                        <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontWeight: "700" }}>
                          {"Status"}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {effluentsGenerated?.scopes.map((scope: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr style={{paddingBottom: 5}}>
                              <td style={{ padding: "10px", width: "95%", cursor: "pointer" }}
                                  onClick={() => redirectToScope(scope)}>
                                <span className="title-text-underline">
                                  {scope.label}
                                </span>
                                <br/>
                                {scope.description && (
                                  <span style={{fontSize: 12}}>
                                    {Parser(scope.description)}
                                  </span>
                                )}
                              </td>
                              <td style={{ padding: "10px" }} >
                                {scope.status === 'disabled' && (
                                  <div className="bulled-scope-disabled" />
                                )}
                                {scope.status === 'pending' && (
                                  <div className="bulled-scope-pending" />
                                )}
                                {scope.status === 'draft' && (
                                  <div className="bulled-scope-draft" />
                                )}
                                {scope.status === 'completed' && (
                                  <div className="bulled-scope-completed" />
                                )}
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {/* Formatação dos valores na tela inicial */}
              <BoxResult result={formatPrice3(effluentsGenerated.emission_tco2e, false)} fossil={true} style={{marginTop: 30}}/>

              <BoxResult result={formatPrice3(effluentsGenerated.emission_tco2e_bio, false)} fossil={false} />
            </div>
      
            <div style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: 20, marginRight: 10 }}>
              <div style={{ display: "flex", flexDirection: "row", marginRight: 10 }} >
                  <div  className="bulled-scope-disabled" />
                  <span className="status-legend">Escopo não contratado</span>
              </div>
      
              <div style={{ display: "flex", flexDirection: "row", marginRight: 10 }} >
                  <div  className="bulled-scope-pending" />
                  <span className="status-legend">Item não preenchido</span>
              </div>
      
              <div style={{ display: "flex", flexDirection: "row", marginRight: 10 }} >
                  <div  className="bulled-scope-completed" />
                  <span className="status-legend">Preenchimento realizado</span>
              </div>
            </div>
      
            <Modal isOpen={openModal} handleModalVisible={() => handleOpenInfo()} isPayOrTypeClient={true}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TitleText level={2} title='Orientações gerais' />
              </div>
      
              <div style={{marginTop: 10, width: 550}}>
                {guidelines && (
                  <span style={{fontSize: 12, textAlign: "justify"}}>
                    {Parser(guidelines)}
                  </span>
                )}
              </div>
      
            </Modal>
      
            {openModalDisabled && (
              <ModalScopeDisabled
                isOpen={openModalDisabled}
                handleModalVisible={handleOpenModalDisabled}
                entityName={title + ' - ' + itemSelected.label}
              />
            )}
    </Container>
  );
};
