import { StyleSheet, Font } from '@react-pdf/renderer'
import poppinsBold from '../../../assets/fonts/Poppins-SemiBold.ttf'
import poppinsRegular from '../../../assets/fonts/Poppins-Light.ttf'
import MuseoModerno from "../../../assets/fonts/MuseoModerno-Light.ttf";
import MuseoModernoItalic from "../../../assets/fonts/MuseoModerno-Italic.ttf";
import MuseoModernoBoldItalic from "../../../assets/fonts/MuseoModerno-BoldItalic.ttf";
import MuseoModernoExtraLight from "../../../assets/fonts/MuseoModerno-ExtraLight.ttf";
import MuseoModernoExtraBold from "../../../assets/fonts/MuseoModerno-ExtraBold.ttf";
import MuseoModernoBold from "../../../assets/fonts/MuseoModerno-Bold.ttf";

Font.register({ family: 'PoppinsBold', src: poppinsBold })
Font.register({ family: 'PoppinsLight', src: poppinsRegular })
Font.register({
  family: 'Inconsolata',
  src: 'https://fonts.gstatic.com/s/inconsolata/v15/7bMKuoy6Nh0ft0SHnIGMuaCWcynf_cDxXwCLxiixG1c.ttf'
})
Font.register({ family: "MuseoModerno", src: MuseoModerno });
Font.register({
  family: "MuseoModerno-Light",
  src: MuseoModernoExtraLight,
});
Font.register({
  family: "MuseoModerno-Italic",
  src: MuseoModernoItalic,
});
Font.register({
  family: "MuseoModerno-ExtraBold",
  src: MuseoModernoExtraBold,
});
Font.register({
  family: "MuseoModerno-BoldItalic",
  src: MuseoModernoBoldItalic,
});
Font.register({
  family: "MuseoModerno-Bold",
  src: MuseoModernoBold,
});

// const blue = '#c4c3c3'
const lightGreen = '#b7d8c2'
const lightGrey = '#E6E6E6'
const grey = '#dbdbdb'
const darkGrey = '#BFBFBF'

export const styles = StyleSheet.create({
  boldItalic: {
    fontFamily: "MuseoModerno-BoldItalic",
  },
  italic: {
    fontFamily: "MuseoModerno-Italic",
  },
  light: {
    fontFamily: "MuseoModerno",
  },
  extraBold: {
    fontFamily: "MuseoModerno-ExtraBold",
  },
  extraLight: {
    fontFamily: "MuseoModerno-Light",
  },
  Bold:{
    fontFamily: "MuseoModerno-Bold"
  },
  tablesSectionTitle: {
    textAlign: 'left',
    fontFamily: 'PoppinsBold',
    fontSize: 8
  },
  tableTitle: {
    textAlign: 'left',
    fontFamily: 'PoppinsLight',
    fontSize: 8,
    margin: '10px 0'
  },
  tableContainer: { flexDirection: 'column', margin: '10px 0' },
  container: { width: '100%' },
  theadText: { fontFamily: 'PoppinsBold', fontSize: 7 },
  mainTheadItem: {
    padding: 5,
    fontFamily: 'PoppinsLight',
    width: '44.44%',
    fontSize: 8,
    backgroundColor: lightGrey,
    borderBottom: '1px solid white',
    borderLeft: '1px solid white',
    borderRight: '1px solid white'
  },
  theadItem: {
    textAlign: 'center',
    padding: 5,
    backgroundColor: grey,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid white',
    borderLeft: '1px solid white',
    borderRight: '1px solid white'
  },
  theadItemSubscript: {
    fontFamily: 'Inconsolata', 
    fontSize: 7
  },
  tbodyText: { fontFamily: 'PoppinsLight', fontSize: 7 },
  subscript: { fontFamily: 'PoppinsLight' },
  tbodyItem: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '6px 5px',
    borderLeft: `1px solid white`,
    borderRight: `1px solid white`,
    borderBottom: `1px solid ${lightGreen}`
  },
  totalRow: { flexDirection: 'row', backgroundColor: darkGrey },

  textWithLineBreak: {
    fontFamily: 'PoppinsLight',
    fontSize: 7,
    whiteSpace: 'pre-line', 
  },
})
