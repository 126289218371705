import axios from "axios";

export const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        Authorization: `${getStorageToken() ? `${getStorageToken()}` : ""}`,
    },
});

function getStorageToken() {
    const token = localStorage.getItem("@SismieGEE:token");
    return token ? JSON.parse(token) : "";
}

export function applyToken(): void {
    const token = getStorageToken();
    api.defaults.headers.Authorization = `${token}`;
}
