import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import { Container } from "./styles";
import { formatPrice4} from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import fuelService from "../../../services/fuelService";
import { toast } from "react-hot-toast";
import { Alert } from "../../../components/Alert";


export const FuelMonthly = () => {
  const history = useHistory();
  const [carFleetSelected, setCarFleetSelected] = useState<any>({});
  const location = useLocation();
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [months, setMonths] = useState<any>([]);
  const [year, setYear] = useState<number | null>(null);
  const availableYears = ['', 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015,
    2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
  const [averageYear, setAverageYear] = useState<number | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    const item: any = location?.state;
    if (item && item.fuel_id) {
      if (year !== null) {
        getFuelsById(item.fuel_id, year);
      }
    } else {
      history.push('/combustivel');
    }
  }, [location, year]);

  const { register, handleSubmit, setValue } = useForm<any>();

  useEffect(() => {
    if (months.length > 0) {
      const averageYearCalc = calculateAverageYear(months);
      setAverageYear(averageYearCalc);
    }
  }, [months]);

  const calculateAverageYear = (monthsData: any[]) => {
    if (monthsData.length === 0) return null;

    const totalPercentage = monthsData.reduce(
      (accumulator, currentValue) => accumulator + currentValue.percentage,
      0
    );
    return totalPercentage / monthsData.length;
  };

  const onSubmit: SubmitHandler<any> = async (formData) => {
    if (formData && year !== null) { 
      const itens = months.map((month: any, index: number) => {
        const percentage = formData.month[index].percentage.replace(",", ".");
        return {
          month: month.month,
          percentage: percentage ? parseFloat(percentage) : 0,
          year: year
        };
      });
  
      const request = {
        itens: itens
      };
  
      try {
        const result = await fuelService.updateMonthly(carFleetSelected.id, year, carFleetSelected.name, request);
  
        if (result && !result.error) {
          const updatedMonths = months.map((month: any, index: number) => ({
            ...month,
            percentage: itens[index].percentage,
          }));
  
          setMonths(updatedMonths);
          setErrorApi(undefined);
          toast.success("Operação realizada com sucesso!");
        } else {
          setErrorApi(result.error || "Erro desconhecido.");
        }
      } catch (error) {
        setErrorApi("Ocorreu um erro ao tentar salvar os dados.");
        alert("Ocorreu um erro ao tentar salvar os dados.");
      }
    }
  };  

  async function getFuelsById(id: number, selectedYear: number) {
    if (selectedYear === null) return;
    
    try {
      const carFleet = await fuelService.getById(id);
      const result = await fuelService.getMonthly(id, selectedYear, carFleet.name);
  
      if (!result || !result.itens) {
        console.error('Dados de resultado inválidos');
        setModalMessage(`Para o combustível '${carFleet.name}' o ano selecionado '${selectedYear}' não há dados na página Editar. Por favor, adicionar os dados.`);
        setModalIsOpen(true);
        setMonths([]);
        setAverageYear(null);
        setCarFleetSelected(carFleet);
        return;
      }
  
      const itens = result.itens;
      const monthsLabel = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 
          'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  
      const months = [];
  
      for (let i = 0; i < 12; i++) {
        const month = i + 1;
        const monthLabel = monthsLabel[i];
        const item = getItem(month, itens, selectedYear);
        const percentage = item && item.percentage ? item.percentage : 0;
  
        setValue(`month.${i}.percentage`, formatPrice4((percentage), false));
  
        const averageYearForMonth = item && item.month === null && item.percentage === null;
  
        months.push({
          month: month,
          month_label: monthLabel,
          percentage: percentage,
          year: selectedYear,
          averageYear: averageYearForMonth
        });
      }
  
      const averageYearSelected = itens.find((item: { year: number; }) => item.year === selectedYear)?.average_year || null;
      setAverageYear(averageYearSelected);
  
      setMonths(months);
      setCarFleetSelected(carFleet);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      alert("Erro ao buscar dados.");
    }
  }
  

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newYear = parseInt(event.target.value, 10);
    setYear(newYear);

    if (carFleetSelected && carFleetSelected.id) {
      getFuelsById(carFleetSelected.id, newYear); 
    }
  };

  function getItem(month?: number, itens?: any, year?: number) {
    if (itens) {
      for (const item of itens) {
        if (item.month === month && item.year === year) {
          return item;
        }
      }
    }
    return undefined;
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{4})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
    return value;
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title={carFleetSelected.name} />
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <TitleText level={1} title="Ano: "/>
              <select style={{ 
                    width: '150%',
                    height: "75%", 
                    marginLeft: 10, 
                    padding: '10px', 
                    borderRadius: '4px', 
                    border: '1px solid #ccc', 
                    backgroundColor: '#f9f9f9',
                    marginTop: 5 
                  }} 
                  id="yearSelect" 
                  onChange={handleYearChange} 
                  value={year || ""}
                  >
                {availableYears.map(yearOption => (
                  <option key={yearOption} value={yearOption}>
                    {yearOption}
                  </option>
                ))}
              </select>
            </div>
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
          </div>          

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 10, marginLeft: 50, marginRight: 50  }} >
          <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, justifyContent: "space-between" }}>
            <TitleText level={2} title="Fatores de ajuste mensal (combustão móvel)" />
           
          </div>

          


          <div style={{ overflow: "hidden auto", position: "relative", height: "calc(100vh - 190px)", 
            maxHeight: "calc(100vh - 190px)"}} >
            {months && months.length > 0 && (
              <table style={{ width: "90%", borderCollapse: "collapse", marginTop: 5}}>
                <thead>
                  <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                    <th style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                      {"Mês"}
                    </th>
                    <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                      {"Percentual (%)"}
                    </th>
                    
                  </tr>
                </thead>
                <tbody>
                {months && months.length > 0 && months.map((month: any, index: number) => (
                <React.Fragment key={index}>
                  <tr>
                    <td style={{ padding: "10px", width: "80%" }}>
                      <span className="list-text title-text-underline">
                        {month?.month_label}
                      </span>
                    </td>
                    <td style={{ padding: "10px", width: "20%" }}>
                      <input style={{ width: "90%", textAlign: "right" }}
                        {...register(`month.${index}.percentage`, { required: true })}
                        onChange={(event) => {
                          setValue(`month.${index}.percentage`, currency(event));
                        }}
                      />
                    </td>
                    <td></td>
                  </tr>
                </React.Fragment>
              ))}
                    
                    {averageYear !== null && (
                        <div style={{ display: "flex", justifyContent: "space-between", width:'120%' }}>
                          <h3 className="list-text title-text-underline" style={{ textAlign: "center", marginRight: 50, marginLeft: 10}}>Média Anual:</h3>
                          <div style={{ flexGrow: 100 }}></div>
                          <h3 style={{ textAlign: "right", marginLeft: 'auto'}}>{averageYear}</h3>
                        </div>
                      )}

                </tbody>
              </table>
            )}
          </div>
        </div>
      </form>
      <Alert
        isOpen={modalIsOpen}
        handleModalVisible={() => setModalIsOpen(false)}
        message={modalMessage}
      />
    </Container>
  );
};
