import styled from 'styled-components';
import BackIcon from '../../assets/images/background-senha.png';

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    background: #F9F8F8; 
    background: url(${BackIcon});
    background-repeat: no-repeat;
    background-size: 100% 100%;
   
    .form-container {
        display: flex;
        flex-direction: column;
        width: 440px;
        height: 340px;
        background: ${props => props.theme.colors.white};
        box-shadow: 10px 20px 50px rgba(0, 0, 0, 0.07);
        border-radius: 6.5px;
        padding: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -200px;
        margin-left: -220px;
    }

    .form-content {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
    }

    .password-logo {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }
`;