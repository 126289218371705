import React, { useEffect, useState } from "react";
import DownloadIcon from '../../../../assets/icons/download.svg'
import TrashIcon from '../../../../assets/icons/trash.svg'
import useAuth from '../../../../hooks/useAuth'
import InsertScopeEvidenceAttachments from './InsertScopeEvidenceAttachments'
import { useForm } from "react-hook-form";

interface File {
  id: string;
  filename: string;
  file_url: string;
  // date: string;
}
interface Props {
  files: any[]
  onDeleteFile: any
  onDownloadFile: any
  onChangeAttachment: any
}

const ScopeEvidenceAttachments: React.FC<Props> = (props) => {
  const { files, onDeleteFile, onDownloadFile, onChangeAttachment } = props
  const { isCommonUser } = useAuth()

  // const currentDate = new Date();
  // const formattedEndDate = `${("0" + currentDate.getDate()).slice(-2)}-${("0" + (currentDate.getMonth() + 1)).slice(-2)}-${currentDate.getFullYear()}`;

  // const [endDate, setEndDate] = useState(formattedEndDate);

  const {
    register,
  } = useForm<any>();

  return (
    <>
      {!isCommonUser && (
        <InsertScopeEvidenceAttachments attachmentChangeHandler={onChangeAttachment} />
      )}

      {!!files.length && (
        <div
          style={{
            borderStyle: 'dashed',
            borderWidth: '1px',
            marginTop: 10,
            padding: 5,
            borderColor: '#9E9E9E',
            textAlign: 'center'
          }}
        >
          <table
            style={{ width: '100%', borderCollapse: 'collapse' }}
            className="table-scope"
          >
            <thead>
              <tr>
                <th className="head-tr-item" style={{ width: 200 }}>
                  {'Evidências anexadas'}
                </th>
                {/* <th className="head-tr-item" style={{ width: 150 }}>
                  {'Data de Importação'}
                </th> */}
                <th className="head-tr-item" style={{ width: 50 }} />
              </tr>
            </thead>
            <tbody>
              {files.map((file: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td style={{ width: '90%', textAlign: 'left' }}>
                        <span
                          style={{
                            marginLeft: 5,
                            fontSize: 12,
                            textAlign: 'left',
                            display: 'inline-block'
                          }}
                        >
                          {file.filename}
                        </span>
                      </td>
                      {/* <td style={{ width: '20%', textAlign: 'left' }}> */}
                        {/* <span
                          style={{
                            marginLeft: 5,
                            fontSize: 12,
                            textAlign: 'left',
                            display: 'inline-block'
                          }}
                        > */}
                          {/* <input 
                          style={{
                            marginLeft: 30,
                            fontSize: 14,
                            textAlign: 'center',
                            display: 'inline-block',
                            border: 'none',
                            background: 'transparent'
                          }}
                          onChange={(e)=>setEndDate(e.target.value)} value={endDate}/> */}
                        {/* </span> */}
                      {/* </td> */}
                      <td style={{ textAlign: 'center' }}>
                        <div
                          style={{
                            display: 'inline-flex',
                            textAlign: 'center'
                          }}
                        >
                          <div
                            onClick={() => onDeleteFile(file.id)}
                            style={{
                              cursor: 'pointer',
                              textAlign: 'center',
                              width: 30
                            }}
                          >
                            <img alt="deleteIcon" src={TrashIcon} />
                          </div>
                          <div
                            onClick={() => onDownloadFile(file.file_url)}
                            style={{
                              cursor: 'pointer',
                              textAlign: 'center',
                              width: 30
                            }}
                          >
                            <img alt="downloadIcon" src={DownloadIcon} width={24} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default ScopeEvidenceAttachments