import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import vehicleFuelService from "../../../services/vehicleFuelService";
import { Container } from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import fuelService from "../../../services/fuelService";
import { toast } from "react-hot-toast";
import {Alert} from "../../../components/Alert";

interface Inputs {
  fuel: any;
  name: string;
  bio_fuel: any;
  is_air: boolean;
  is_road: boolean;
  is_rail: boolean;
  is_water: boolean;
  description: string;
  is_thermal_energy: boolean;
  is_stationary_combustion: boolean;
  is_displacement_home_work: boolean;
  year: any;
}

export const VehicleFuelEdit = () => {
  const location = useLocation();
  const [fuels, setFuels] = useState<any>([]);
  const [bioFuels, setBioFuels] = useState<any>([]);
  const [isWater, setIsWater] = useState(false);
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [vehicleFuelSelected, setVehicleFuelSelected] = useState<any>({});
  const availableYears = [2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015,
    2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
  const [selectedYear, setSelectedYear] = useState<number | undefined>(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    const item: any = location?.state;
    if (item && item.vehicleFuel_id) {
      getVehicleFuelsById(item.vehicleFuel_id);
    }
  }, [location]);

  useEffect(() => {
    if (selectedYear) {
      getFuels(selectedYear);
    }
  }, [selectedYear]);

  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const newVehicleFuel = {
        name: formData.name,
        is_air: formData.is_air,
        is_road: formData.is_road,
        is_rail: formData.is_rail,
        is_water: formData.is_water,
        description: formData.description,
        is_thermal_energy: formData.is_thermal_energy,
        is_stationary_combustion: formData.is_stationary_combustion,
        is_displacement_home_work: formData.is_displacement_home_work,
        fuel_id: formData.fuel ? formData.fuel.value : undefined,
        bio_fuel_id: formData.bio_fuel ? formData.bio_fuel.value : undefined,
        year_id: parseInt(formData.year)
      };

      try {
        let result;
        if (vehicleFuelSelected?.id) {
          result = await vehicleFuelService.updateById(vehicleFuelSelected.id, newVehicleFuel);
        } else {
          result = await vehicleFuelService.create(newVehicleFuel);
        }

        if (result && !result.error) {
          toast.success("Operação realizada com sucesso!");
        } else {
          setErrorApi(result.error);
          setModalMessage(result.error);
          setModalIsOpen(true);
        }
      } catch (error) {
        console.error("Erro ao realizar operação:", error);
        setErrorApi("Erro ao realizar a operação.");
        setModalMessage("Erro ao realizar a operação.");
        setModalIsOpen(true);
      }
    }
  };

  async function getFuels(year: number) {
    try {
      const result = await fuelService.getByParams({ limit: 9000, page: 1 });

      const fuels = [];
      const bioFuels = [];

      if (result.itens) {
        for (const fuel of result.itens) {
          if (fuel.fuel_type === 1 && fuel.year === year) {
            fuels.push({ label: fuel.name, value: fuel.id });
          }
          if (fuel.fuel_type === 2 && fuel.year === year) {
            bioFuels.push({ label: fuel.name, value: fuel.id });
          }
        }
      }

      setFuels(fuels);
      setBioFuels(bioFuels);
    } catch (error) {
      console.error("Erro ao buscar combustíveis:", error);
      setErrorApi("Erro ao buscar combustíveis.");
      setModalMessage("Erro ao buscar combustíveis.");
      setModalIsOpen(true);
    }
  }

  async function getVehicleFuelsById(id: number) {
    try {
      const vehicleFuel = await vehicleFuelService.getById(id);
      if (vehicleFuel) {
        setValue("name", vehicleFuel.name);
        setValue("is_air", vehicleFuel.is_air);
        setValue("is_road", vehicleFuel.is_road);
        setValue("is_rail", vehicleFuel.is_rail);
        setValue("is_water", vehicleFuel.is_water);
        setValue("description", vehicleFuel.description);
        setValue("is_thermal_energy", vehicleFuel.is_thermal_energy);
        setValue("is_stationary_combustion", vehicleFuel.is_stationary_combustion);
        setValue("is_displacement_home_work", vehicleFuel.is_displacement_home_work);

        setIsWater(vehicleFuel.is_water);

        if (vehicleFuel.fuel) {
          setValue("fuel", { value: vehicleFuel.fuel.id, label: vehicleFuel.fuel.name });
        }

        if (vehicleFuel.bioFuel) {
          setValue("bio_fuel", { value: vehicleFuel.bioFuel.id, label: vehicleFuel.bioFuel.name });
        }

        setVehicleFuelSelected(vehicleFuel);
      }
    } catch (error) {
      console.error("Erro ao buscar combustível por ID:", error);
      setErrorApi("Erro ao buscar combustível por ID.");
      setModalMessage("Erro ao buscar combustível por ID.");
      setModalIsOpen(true);
    }
  }

  async function handleYearChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const selectedYear = parseInt(event.target.value, 10);
    setSelectedYear(selectedYear);
  
    try {
      const vehicleFuel = await vehicleFuelService.getByNameAndYearVehicle(vehicleFuelSelected?.name || '', selectedYear);
  
      if (vehicleFuel) {
        setValue("name", vehicleFuel.name);
        setValue("is_air", vehicleFuel.is_air);
        setValue("is_road", vehicleFuel.is_road);
        setValue("is_rail", vehicleFuel.is_rail);
        setValue("is_water", vehicleFuel.is_water);
        setValue("description", vehicleFuel.description);
        setValue("is_thermal_energy", vehicleFuel.is_thermal_energy);
        setValue("is_stationary_combustion", vehicleFuel.is_stationary_combustion);
        setValue("is_displacement_home_work", vehicleFuel.is_displacement_home_work);
  
        if (vehicleFuel.fuel) {
          setValue("fuel", { value: vehicleFuel.fuel.id, label: vehicleFuel.fuel.name });
        }
  
        if (vehicleFuel.bioFuel) {
          setValue("bio_fuel", { value: vehicleFuel.bioFuel.id, label: vehicleFuel.bioFuel.name });
        }
  
        setVehicleFuelSelected(vehicleFuel);
      } else {
        reset({
          name: vehicleFuelSelected?.name || '',
          is_air: false,
          is_road: false,
          is_rail: false,
          is_water: false,
          description: '',
          is_thermal_energy: false,
          is_stationary_combustion: false,
          is_displacement_home_work: false,
          fuel: undefined,
          bio_fuel: undefined,
          year: selectedYear.toString(),
        });
  
        setVehicleFuelSelected(undefined);
      }
    } catch (error) {
      console.error("Erro ao buscar dados para o ano selecionado", error);
      setErrorApi("Erro ao buscar dados para o ano selecionado.");
      setModalMessage("Erro ao buscar dados para o ano selecionado.");
      setModalIsOpen(true);
    }
  }  
  

  return (
    <Container>
      <GoBack />
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Composição de combustível" />
          <div style={{ display: "flex", alignItems: "right", marginLeft: 50, marginBottom: -10 }}>
            <TitleText level={1} title="Ano: "/>
            <select
              style={{ 
                width: '150%',
                height: "75%", 
                marginLeft: 10, 
                padding: '10px', 
                borderRadius: '4px', 
                border: '1px solid #ccc', 
                backgroundColor: '#f9f9f9',
                marginTop: 5 
              }}
              {...register("year", { required: true })}
              onChange={handleYearChange}
            >
              {availableYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 30, marginLeft: 50, marginRight: 50  }} >
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Nome
          </label>

          <input style={{width: '95%'}}
                {...register("name", { required: true })}/>
                      
          {errors.name && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Combustível fóssil
          </label>

          <Controller control={control} name="fuel"
            render={({ field }) => (
              <Select placeholder="[Selecione]" {...field} styles={{ control: (styles) => ({
                    ...styles, width: "100%", height: 40, marginTop: 10,
                    backgroundColor: "rgb(246, 246, 246)"}),
                  option: (styles) => { return { ...styles, color: "#31363F" };},
                }} defaultValue={getValues("fuel")}
                options={fuels} isClearable={true}                      
              />
            )}
          />
                      
          {errors.fuel && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Biocombustível
          </label>

          <Controller control={control} name="bio_fuel"
            render={({ field }) => (
              <Select placeholder="[Selecione]" {...field} styles={{ control: (styles) => ({
                    ...styles, width: "100%", height: 40, marginTop: 10,
                    backgroundColor: "rgb(246, 246, 246)"}),
                  option: (styles) => { return { ...styles, color: "#31363F" };},
                }} defaultValue={getValues("bio_fuel")}
                options={bioFuels} isClearable={true}                      
              />
            )}
          />
                      
          {errors.fuel && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 20, marginBottom: 10 }}>
            <div style={{ display: "flex", flexDirection: "row", marginRight: 10, width: "33%" }}>
              <input style={{ height: "20px", width: "20px" }} type="checkbox" 
                  {...register("is_stationary_combustion")}/>

              <label style={{ marginTop: 10, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8" }} >
                  Escopo 01 - Combustão Estacionária
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginRight: 10, width: "33%" }}>
              <input style={{ height: "20px", width: "20px" }} type="checkbox" 
                  {...register("is_displacement_home_work")}/>

              <label style={{ marginTop: 10, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8" }} >
                  Escopo 03 - Deslocamento Casa-Trabalho
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginRight: 10, width: "33%" }}>
              <input style={{ height: "20px", width: "20px" }} type="checkbox" 
                  {...register("is_thermal_energy")}/>

              <label style={{ marginTop: 3, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8" }} >
                  Escopo 02 - Compra de energia térmica
              </label>
            </div>


          </div>

          <label style={{ marginTop: 10, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Combustão Móvel
          </label>

          <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 20, marginBottom: 10 }}>
            <div style={{ display: "flex", flexDirection: "row", marginRight: 10, width: "25%" }}>
              <input style={{ height: "20px", width: "20px" }} type="checkbox" 
                  {...register("is_road")}/>

              <label style={{ marginTop: 10, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8" }} >
                  Transporte rodoviário
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginRight: 10, width: "25%" }}>
              <input style={{ height: "20px", width: "20px" }} type="checkbox" 
                  {...register("is_air")}/>

              <label style={{ marginTop: 10, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8" }} >
                  Transporte aéreo
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginRight: 10, width: "25%" }}>
              <input style={{ height: "20px", width: "20px" }} type="checkbox" 
                  {...register("is_rail")}/>

              <label style={{ marginTop: 10, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8" }} >
                  Transporte ferroviário
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginRight: 10, width: "25%" }}>
              <input style={{ height: "20px", width: "20px" }} type="checkbox" 
                  {...register("is_water")}/>

              <label style={{ marginTop: 10, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8" }} >
                  Transporte hidroviário
              </label>
            </div>
          </div>
          
          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Descrição
          </label>

          <textarea style={{width: '95%', height: 100}}
                {...register("description")}/>
                      
          {errors.description && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>
      </form>
      <Alert
        isOpen={modalIsOpen}
        handleModalVisible={() => setModalIsOpen(false)}
        message={modalMessage}
      />
    </Container>
  );
};
