import { api } from "./api";

async function getById(ipcc_Id: number) {
  try {
    const { data } = await api.get(`/ipcc_effluents/${ipcc_Id}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/ipcc_effluents", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(ipcc_effluents: any) {
  try {
    const { data } = await api.post(`/ipcc_effluents`, ipcc_effluents);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(ipcc_IdId: number, ipcc_effluents: any) {
  try {
    const { data } = await api.put(`/ipcc_effluents/${ipcc_IdId}`, ipcc_effluents);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(ipcc_Id: number) {
  try {
    const { data } = await api.delete(`/ipcc_effluents/${ipcc_Id}`);
    return data;
  } catch (error) {
    return false;
  }
}

const IpccService = {
  getById,
  getByParams,
  create,
  updateById,
  deleteById
};

export default IpccService;
