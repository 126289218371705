import { Modal } from "../Modal"
import { TitleText } from "../TitleText"
import { Container } from "./styles"
import Excel, { ValueType } from 'exceljs';
import AttachmentIcon from '../../assets/icons/attachment.svg'

type ModalImportDataProps = {
    isOpen: boolean;
    entityType: number;
    entityName: string | undefined;
    handleModalVisible: () => void;
    templateFileName: string | undefined;
    handleImportData: (type?:number, data?:any) => void;
}

export const ModalImportData = ({ handleImportData, isOpen, entityName, entityType, templateFileName, handleModalVisible }: ModalImportDataProps) => {
    
    const handleDownloadFile = () => {
        const url = `${process.env.REACT_APP_API_URL}`

        window.open(url + "/static/template/" + templateFileName, '_blank')
    }
    
	const handleUploadFile = (event:any) => {
        const file = event.target.files[0];
        const wb = new Excel.Workbook();
        const reader = new FileReader();

        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            const buffer = reader.result as Buffer;

            const values:any[] = []

            let sheetName = ''; // Valor padrão, caso templateFileName seja undefined

            if (templateFileName) {
                sheetName = templateFileName.replace('template', '').replace('.xlsx', '').replace(/_/g, ' ');
            
                if (templateFileName.includes('displacement_Home')) {
                    sheetName = 'HomeWork';
                } else if (templateFileName.includes('displacement_Public1')) {
                    sheetName = 'public1';
                } else if (templateFileName.includes('displacement_Public_Manual_1')) {
                    sheetName = 'publicManual1';
                } else if (templateFileName.includes('displacement_Public2')) {
                    sheetName = 'public2';
                } else if (templateFileName.includes('displacement_Public_Manual_2')){
                    sheetName = 'publicManual2'
                } else if (templateFileName.includes('displacement_Private1')) {
                    sheetName = 'private1';
                } else if (templateFileName.includes('displacement_Private3')) {
                    sheetName = 'private3';
                } else if (templateFileName.includes('displacement_Private4')) {
                    sheetName = 'private4';
                } else if (templateFileName.includes('downstream_Air1')) {
                    sheetName = 'DownstreamAir1';
                } else if (templateFileName.includes('downstream_Air2')) {
                    sheetName = 'DownstreamAir2';
                } else if (templateFileName.includes('downstream_Water1')) {
                    sheetName = 'DownstreamWater1';
                } else if (templateFileName.includes('downstream_Road1')) {
                    sheetName = 'DownstreamRoad1';
                } else if (templateFileName.includes('downstream_Road2')) {
                    sheetName = 'DownstreamRoad2';
                } else if (templateFileName.includes('downstream_Road3')) {
                    sheetName = 'DownstreamRoad3';
                } else if (templateFileName.includes('downstream_Road4')) {
                    sheetName = 'DownstreamRoad4';
                } else if (templateFileName.includes('business_Air1')) {
                    sheetName = 'BusinessAir1';
                } else if (templateFileName.includes('business_Air2')) {
                    sheetName = 'BusinessAir2';
                } else if (templateFileName.includes('business_Bus1')) {
                    sheetName = 'BusinessBus1';
                } else if (templateFileName.includes('business_Train1')) {
                    sheetName = 'BusinessTrain1';
                } else if (templateFileName.includes('business_Road1')) {
                    sheetName = 'BusinessRoad1';
                } else if (templateFileName.includes('business_Road2')) {
                    sheetName = 'BusinessRoad2';
                } else if (templateFileName.includes('business_Road3')) {
                    sheetName = 'BusinessRoad3';
                } else if (templateFileName.includes('upstream_Air1')) {
                    sheetName = 'UpstreamAir1';
                } else if (templateFileName.includes('upstream_Air2')) {
                    sheetName = 'UpstreamAir2';
                } else if (templateFileName.includes('upstream_Water1')) {
                    sheetName = 'UpstreamWater1';
                } else if (templateFileName.includes('upstream_road1')) {
                    sheetName = 'UpstreamRoad1';
                } else if (templateFileName.includes('upstream_road2')) {
                    sheetName = 'UpstreamRoad2';
                } else if (templateFileName.includes('upstream_road3')) {
                    sheetName = 'UpstreamRoad3';
                } else if (templateFileName.includes('upstream_road4')) {
                    sheetName = 'UpstreamRoad4';
                } else if (templateFileName.includes('upstream_Air1')) {
                    sheetName = 'BusinessAir1';
                } else if (templateFileName.includes('upstream_Air2')) {
                    sheetName = 'BusinessAir2';
                } else if (templateFileName.includes('upstream_Bus1')) {
                    sheetName = 'BusinessBus1';
                } else if (templateFileName.includes('upstream_Train1')) {
                    sheetName = 'BusinessTrain1';
                } else if (templateFileName.includes('upstream_Road1')) {
                    sheetName = 'BusinessRoad1';
                } else if (templateFileName.includes('upstream_Road2')) {
                    sheetName = 'BusinessRoad2';
                } else if (templateFileName.includes('upstream_Road3')) {
                    sheetName = 'BusinessRoad3';
                }
            }
              
            
            wb.xlsx.load(buffer).then((workbook) => {
                const sheet = workbook.getWorksheet(sheetName)

                    if (!sheet) {
                        console.error(`Planilha "${sheetName}" não encontrada no arquivo Excel.`);
                        return;
                    }

                    sheet.eachRow((row, rowIndex) => {
                        const r:any[] = []

                        const size = row.cellCount

                        for (let i = 1; i <= size; i++) {
                            const col = row.getCell(i)

                            if (col.value){
                                if (col.type === ValueType.Number){
                                    r.push(parseFloat(col.value?.toString()))
                                } else if (col.type === ValueType.String){
                                    r.push(col.value?.toString())
                                } else if (col.type === ValueType.Date){
                                    let value = col.toCsvString()

                                    if (value){
                                        value = value.substring(0, 10)
                                    }

                                    r.push(value);
                                } else if (col.formula){
                                    const result = col.result

                                    if (result){
                                        r.push(result);
                                    } else{
                                        r.push("")
                                    }
                                }
                            }else{
                                r.push("")
                            }
                        }

                        values.push(r)
                    });
                handleImportData(entityType, values)
                
            });
        };
    
        handleModalVisible()
    };

    return (
        <Modal
            isOpen={isOpen}
            isPayOrTypeClient={true}
            handleModalVisible={handleModalVisible}
        >
            <Container>
                <div style={{ margin: 5, textAlign: "left" }}>
                    <TitleText level={2} title={entityName ? entityName : ""} color="blue" />
                </div>

                <div style={{ margin: 5, textAlign: "left"  }}>
                    <TitleText level={3} title="Importação de dados" color="black" />

                    <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }} >
                        Faça download do template, preencha os dados desejados e clique em 'Importar Dados'.
                    </span>
                </div>

                <div style={{ margin: 10, textAlign: "center", cursor: "pointer" }} onClick={() => handleDownloadFile() }>
                    <span style={{ textAlign: "start", fontSize: 14, marginTop: 5, textDecoration: "underline" }} >
                        Download template para importação.
                    </span>
                </div>


                <div style={{ borderStyle: "dashed", marginTop: 20, borderWidth: "1px", marginLeft: 10,
                            paddingTop: 15, paddingBottom: 15, borderColor: "#9E9E9E", marginRight: 10,
                            width: "95%", textAlign: "center", cursor: "pointer"}} 
                            onClick={() => (document.querySelector("#fileInput") as HTMLInputElement).click()}>
                    <img src={AttachmentIcon} alt="coisa" width={32}/>

                    <br/>

                    <span style={{padding: 5, fontSize: 14, fontStyle: "bold"}}>
                        Selecione o template e iniciaremos a importação desejada.
                    </span>
                </div>

                <input type="file" id="fileInput" 
                        accept="application/xlsx,application/xls" 
                        style={{ display: "none"}} name="file" 
                                onChange={handleUploadFile} />

            </Container>
        </Modal >
    )
}