import axios from "axios";

async function getCitiesByState(uf: string) {
  try {
    const { data } = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`)
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getStates() {
  try {
    const { data } = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
    return data
  } catch (error) {
    console.log(error);
  }
}

const ibgeService = {
  getCitiesByState,
  getStates
};

export default ibgeService;