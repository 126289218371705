import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import homeOfficeService from "../../../services/homeOfficeService";
import { Container } from "./styles";
import { formatPrice, toNumberValues } from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import ibgeService from "../../../services/ibgeService";
import { toast } from "react-hot-toast";

export const HomeOfficeEdit = () => {
  const history = useHistory();

  const [homeOfficeSelected, setHomeOfficeSelected] = useState<any>({});
  const location = useLocation();
  const [states, setStates] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [devices, setDevices] = useState<any>([]);
  const [isCompany, setIsCompany] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();

  useEffect(() => {
    const item:any = location?.state;

    getStates()

    if (item && item.homeOffice_id){
      getHomeOfficesById(item.homeOffice_id)
    }else{
      getNewHomeOffice()
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);

  async function getStates() {
    const result = await ibgeService.getStates();
  
    const options = result.map((sub: any) => {
      return {
        label: sub.sigla,
        value: sub.sigla,
      };
    });
    
    function getSortOrder(prop:any) {    
      return function(a:any, b:any) {    
          if (a[prop] > b[prop]) {    
              return 1;    
          } else if (a[prop] < b[prop]) {    
              return -1;    
          }    
          return 0;    
      }    
    }

    options.sort(getSortOrder("label"));

    setStates(options)
  }

  const handleChangeState = (e: any) => {
    setCities([])
    setValue("state", e)
    setValue("city", {})

    async function getCities() {
      const cities = await ibgeService.getCitiesByState(e.value);
  
      const options = cities.map((sub: any) => {
        return {
          label: sub.nome,
          value: sub.nome,
        };
      });
      
      function getSortOrder(prop:any) {    
        return function(a:any, b:any) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
      }
  
      options.sort(getSortOrder("label"));

      setCities(options)
    }

    if (e && e.value){
      getCities();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = async (formData) => {
    if (formData) {
      const itens:any[] = devices.map((device: any, index: number) => {
        return {
          name: device.name,
          hours: parseInt(formData.device[index].hours),
          power_watts: parseInt(formData.device[index].power_watts),
        };
      })

      const consumption = formData.consumption.replace(".", "");

      const newHomeOffice = {
        name: formData.name,
        company_name: isCompany ? formData.company_name : '',
        city: formData.city && !isCompany ? formData.city.value : '',
        state: formData.state && !isCompany ? formData.state.value : '',
        consumption: consumption ? parseFloat(consumption.replace(",", ".")) : 0,
        description: formData.description,
        is_company: formData.is_company,
        devices: itens
      };

      if (homeOfficeSelected?.id) {
        const result = await homeOfficeService.updateById(
          homeOfficeSelected.id,
          newHomeOffice
        );

        if (result && !result.error) {
         // history.goBack()

         // reset();
         // setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await homeOfficeService.create(newHomeOffice);

        if (result && !result.error) {
        //  history.goBack()

         // reset();
         // setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  async function getNewHomeOffice() {
    const devicesLabel = ['Ar-condicionado', 'Celular', 'Iluminação', 'Notebook']
    const devices = []

    for (let i = 0; i < 4; i++) {
      const device = devicesLabel[i]

      setValue(`device.${i}.hours`, 8)
      setValue(`device.${i}.power_watts`, 0)

      devices.push({
        name: device,
        hours: 8,
        power_watts: 0,
      })
    }

    setDevices(devices)
  }

  async function getHomeOfficesById(id: number) {
    const homeOffice = await homeOfficeService.getById(id);

    const devicesLabel = ['Ar-condicionado', 'Celular', 'Iluminação', 'Notebook']

    setValue("name", homeOffice?.name);
    setValue("is_company", homeOffice?.is_company);
    setValue("description", homeOffice?.description);
    setValue("company_name", homeOffice?.company_name);
    setValue("consumption", formatPrice(homeOffice?.consumption, false));
    setValue("city", { label: homeOffice?.city, value: homeOffice?.city});
    setValue("state", { label: homeOffice?.state, value: homeOffice?.state});

    setIsCompany(homeOffice?.is_company)

    const devices = []

    for (let i = 0; i < 4; i++) {
      const device = devicesLabel[i]

      const item = getItem(device, homeOffice.devices)

      const hours = item && item.hours ? item.hours : 0
      const power_watts = item && item.power_watts ? item.power_watts : 0

      setValue(`device.${i}.hours`, hours)
      setValue(`device.${i}.power_watts`, power_watts)

      devices.push({
        name: device,
        hours: hours,
        power_watts: power_watts,
      })
    }

    setDevices(devices)

    setHomeOfficeSelected(homeOffice)
  }
    
  function getItem(device?:string, itens?:any) {
    if (itens){
      for (const item of itens){
        if (item.name === device){
          return item
        }
      }
    }

    return undefined
  }
    
  function currency(e: React.FormEvent<HTMLInputElement>) {
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d)(\d{2})$/, "$1,$2");
      value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
      return value;
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Home Office (Consumo)" />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ overflow: "hidden auto", position: "relative", marginTop: 30, marginLeft: 50, marginRight: 50 , 
              height: "calc(100vh - 180px)",  maxHeight: "calc(100vh - 180px)"}} >
          <div style={{ display: "flex", flexDirection: "row", width: "95%"}}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "62%"}} >
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Nome
              </label>

              <input style={{width: '100%'}}
                    {...register("name", { required: true })}/>
                          
              {errors.name && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column",  width: "25%"}} >
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Consumo (kWh/mês)
              </label>

              <input style={{width: '100%'}}
                    {...register("consumption", { required: true })}
                    onChange={(event) => {
                        setValue("consumption", currency(event));
                    }}/>
                          
              {errors.consumption && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginLeft: 20, width: "20%" }} >
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Unidade específica?
              </label>

              <input type="checkbox" {...register("is_company")} style={{ width: 20, height: 20, marginLeft: 50}}
                    onChange={(event) => {
                      setIsCompany(event.target.checked)
                    }}/>
                          
              {errors.need_fuel && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
          </div>

          {isCompany === false && (
            <div style={{ display: "flex", flexDirection: "row", width: "95%"}}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%"}} >
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Estado
                </label>

                <Controller control={control} name="state" rules={{ required: true }}
                  render={({ field }) => (
                    <Select placeholder="[Selecione]" {...field} styles={{ control: (styles) => ({
                          ...styles, width: "100%", height: 40, marginTop: 10,
                          backgroundColor: "rgb(246, 246, 246)"}),
                        option: (styles) => { return { ...styles, color: "#31363F" };},
                      }} defaultValue={getValues("state")}
                      options={states} isClearable={true} onChange={handleChangeState}                       
                    />
                  )}
                />
                            
                {errors.state && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column", width: "77%"}} >
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Cidade
                </label>

                {cities && (   
                  <Controller control={control} name="city" rules={{ required: true }}
                    render={({ field }) => (
                      <Select placeholder="[Selecione]" {...field} styles={{ control: (styles) => ({
                            ...styles, width: "100%", height: 40, marginTop: 10,
                            backgroundColor: "rgb(246, 246, 246)"}),
                          option: (styles) => { return { ...styles, color: "#31363F" };},
                        }} defaultValue={getValues("city")}
                        options={cities} isClearable={true}                      
                      />
                    )}
                  />
                )}   
                            
                {errors.city && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
            </div>
          )}

          {isCompany === true && (
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "95%"}} >
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Nome da companhia
              </label>

              <input style={{width: '100%'}}
                    {...register("company_name", { required: true })}/>
                          
              {errors.company_name && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
          )}

          {devices && devices.length > 0 && (
            <table style={{ width: "95%", borderCollapse: "collapse", marginTop: 25}}>
              <thead>
                <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                  <th style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                    {"aparelho"}
                  </th>
                  <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                    {"horas úteis"}
                  </th>
                  <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                    {"Watts"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {devices && devices.length > 0 && devices.map((device: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td style={{ padding: "10px", width: "60%" }} >
                            <span className="list-text title-text-underline">
                              {device?.name}
                            </span>
                        </td>
                        <td style={{ padding: "10px", width: "20%" }} >
                          <input style={{ width: "90%", textAlign: "right" }}
                            {...register(`device.${index}.hours`, { required: true })}
                            onChange={(event) => {
                              const { value } = event.target;
                              event.target.value = toNumberValues(value);
                              devices[index].hours = toNumberValues(value);
                            }}
                          />
                        </td>
                        <td style={{ padding: "10px", width: "20%" }} >
                          <input style={{ width: "90%", textAlign: "right" }}
                            {...register(`device.${index}.power_watts`, { required: true })}
                            onChange={(event) => {
                              const { value } = event.target;
                              event.target.value = toNumberValues(value);
                              devices[index].power_watts = toNumberValues(value);
                            }}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </form>
    </Container>
  );
};
