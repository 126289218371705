import React from 'react'
import { Container } from './styles'
import { formatPrice6 } from '../../../../utils/transform'

type StepTwelveProps = {
    register: (value: string) => any;
    setValue: (value: string, e: any) => any;
    handleChange: () => void;
    treatmentType: boolean | null;
    setSelectedUnit: (value: string) => any;
    factorOxideNitrousFinal: number;
}

export const StepTwelve: React.FC<StepTwelveProps> = ({
    treatmentType,
    register,
    setValue,
    handleChange,
    setSelectedUnit,
    factorOxideNitrousFinal
}) => {

    function moeda(a: string, e: string, r: string, decPlaces = 6): string {
        if (!a || a.replace(/[^\d]+/g, "") === "") {
          return "0" + r + "0".repeat(decPlaces);
        }
    
        let n = a.replace(new RegExp(`\\${e}`, "g"), "").replace(new RegExp(`\\${r}`, "g"), "");
    
        n = n.replace(/[^\d]+/g, "");
    
        while (n.length <= decPlaces) {n = "0" + n;}
    
        const len = n.length;
        const intPart = n.substring(0, len - decPlaces);
        const decPart = n.substring(len - decPlaces);
    
        const cleanedIntPart = intPart.replace(/^0+/, "") || "0";
    
        let formattedValue = cleanedIntPart.replace(/\B(?=(\d{3})+(?!\d))/g, e);
        formattedValue += r + decPart;
    
        return formattedValue;
    }

  return (
    <Container>
        <div className='body'>
            <div className='div-text'>
                <span className='text-statement'>
                    Passo 12 - Dados da composição orgânica do efluente doméstico
                </span>
            </div>
            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text' style={{fontWeight:"bold"}}>
                        Preencha os dados relativos à carga orgânica degradável do efluente que é lançado ao ambiente.
                    </span>
                </div>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text' style={{fontWeight:"bold"}}>
                        Escolha se a unidade do dado é DBO (Demanda Biológica de Oxigênio) ou DQO (Demanda Bioquímica de Oxigênio).
                    </span>
                </div>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text'>
                    {treatmentType === null
                        ? "Componente orgânico degradável do efluente"
                        : treatmentType
                        ? "Componente orgânico degradável do efluente após tratamento"
                        : "Componente orgânico degradável do efluente sem tratamento"}
                    </span>
                </div>
                    
                <div className='data-input'>
                    <input style={{width: '130px', height: 35, textAlign: "right"}} 
                        {...register("degradable_organic_component")}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            const inputValue = event.target.value;
                            const formattedValue = moeda(inputValue, ".", ",", 6);
                            setValue("degradable_organic_component", formattedValue);
                            handleChange();
                             
                            event.target.value = formattedValue;
                        }}
                    />
   
                    <span className='span-item'>
                        <select className='select-box'
                            {...register("degradable_organic_component_unity")}
                            onChange={(event) => {
                                const unit = event.target.value;

                                setValue("degradable_organic_component_unity", unit);
                                setSelectedUnit(unit);
                                handleChange();
                            }}
                        >
                            <option value="0"></option>
                            <option value="1" label="[kgDQO/m³]" />
                            <option value="2" label="[kgDBO/m³]" />
                        </select>
                    </span>
                </div>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text' style={{fontWeight:"bold"}}>
                        Preencha os dados sobre a quantidade de nitrogênio presente no efluente.
                    </span>
                </div>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text' style={{fontWeight:"bold"}}>
                        Preencha o fator de emissão de N₂O do efluente. Se não possuir, deixe em branco, será utilizado o default sugerido por IPCC (2019).
                    </span>
                </div>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text'>
                    {treatmentType === null
                        ? "Quantidade de nitrogênio no efluente"
                        : treatmentType
                        ? "Quantidade de nitrogênio no efluente após tratamento"
                        : "Quantidade de nitrogênio no efluente não tratado"}
                    </span>
                </div>
                    
                <div className='data-input'>
                    <input style={{width: '125px', height: 30, textAlign: "right"}} 
                        {...register("amount_n2_nitrogen_after_treatment")}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            const inputValue = event.target.value;
                            const formattedValue = moeda(inputValue, ".", ",", 6);
                            setValue("amount_n2_nitrogen_after_treatment", formattedValue);
                            handleChange();
                             
                            event.target.value = formattedValue;
                        }}
                    />
   
                    <span className='span-unity'>
                        [kgN/m³]
                    </span>
                </div>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text'>
                        Fator de emissão de N₂O da disposição final do efluente
                    </span>
                </div>
                    
                <div className='data-input'>
                    <input style={{width: '124px', height: 30, textAlign: "right"}} 
                        {...register("emission_factor_final_effluent")}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            const inputValue = event.target.value;
                            const formattedValue = moeda(inputValue, ".", ",", 6);
                            setValue("emission_factor_final_effluent", formattedValue);
                            handleChange();
                             
                            event.target.value = formattedValue;
                        }}
                    />
   
                    <span className='span-unity'>
                        [kgN₂O-N/kgN]
                    </span>
                </div>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text'>
                        Fator de emissão de metano (kgCH₄/kgDBO)
                    </span>
                </div>
                    
                <div className='data-input'>
                    <input style={{width: '236px', height: 30, textAlign: "right"}} 
                        readOnly
                        {...register("treatment_methane_factor_dbo_final")}
                    />
                </div>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text'>
                        Fator de emissão de metano (kgCH₄/kgDQO)
                    </span>
                </div>
                    
                <div className='data-input'>
                    <input style={{width: '236px', height: 30, textAlign: "right"}} 
                        readOnly
                        {...register("treatment_methane_factor_dqo_final")}
                    />
                </div>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text'>
                        Fator de emissão de óxido nitroso (kgN₂O/kgN)
                    </span>
                </div>
                    
                <div className='data-input'>
                     <input 
                        style={{ width: "33vh", textAlign: 'right'}}
                        readOnly
                        {...register("treatment_factor_emission_nitrous_oxide_final")}
                        value={formatPrice6(factorOxideNitrousFinal, false)}
                    />
                </div>
            </div>
        </div>
    </Container>
  )
}

