import React, { createContext, useContext, useState, ReactNode } from 'react';

interface MenuContextType {
    isMenuOpen: boolean;
    toggleMenu: () => void;
}

const MenuContext = createContext<MenuContextType | undefined>(undefined);

export const MenuProvider = ({ children }: { children: ReactNode }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(true);

    const toggleMenu = () => setIsMenuOpen((prev) => !prev);

    return (
    <MenuContext.Provider value={{ isMenuOpen, toggleMenu }}>
        {children}
    </MenuContext.Provider>
    );
};

export const useMenu = (): MenuContextType => {
    const context = useContext(MenuContext);
    if (!context) {
        throw new Error('useMenu deve ser usado dentro de um MenuProvider');
    }
    return context;
};
