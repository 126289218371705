import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import { styles as tableStyles } from '../../../../components/PDF/styles/table'
import { styles } from '../../../../components/PDF/styles/common'
import { TheadItem } from '../../../../components/PDF/TheadItem'
import { TbodyItem } from '../../../../components/PDF/TbodyItem'
import { formatPrice3 } from '../../../../utils/transform'

export const Scope2LocationEmissions: React.FC<any> = (props) => {
  const { pdfData } = props

  return (
    <View wrap={false} style={styles.textContainer}>
      <Text style={{...tableStyles.tableTitle, ...styles.light}}>
        Emissões de Escopo 2 desagregadas por categoria (localização)
      </Text>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TheadItem width="40%">Categoria</TheadItem>
        <TheadItem width="20%">
          Emissões tCO<Text style={styles.subscript}>₂</Text>e
        </TheadItem>
        <TheadItem width="20%">
          Emissões CO<Text style={styles.subscript}>₂</Text> biogênico
        </TheadItem>
        <TheadItem width="20%">
          Remoções CO<Text style={styles.subscript}>₂</Text> biogênico
        </TheadItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          Aquisição de energia elétrica
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.electricity.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.electricity.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.electricity.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          Aquisição de energia térmica
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.thermal_energy.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.thermal_energy.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.thermal_energy.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem textAlign="left" width="40%">
          Perdas por transmissão e distribuição
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.transmission_distribution_losses.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(
            pdfData.transmission_distribution_losses.emission_co2_bio,
            false
          )}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(
            pdfData.transmission_distribution_losses.removal_of_bio_co2,
            false
          )}
        </TbodyItem>
      </View>
      <View style={tableStyles.totalRow}>
        <TbodyItem textAlign="left" width="40%">
          Total de emissões Escopo 2 (localização)
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.totals.emission_tco2e, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.totals.emission_co2_bio, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="20%">
          {formatPrice3(pdfData.totals.removal_of_bio_co2, false)}
        </TbodyItem>
      </View>
    </View>
  )
}
