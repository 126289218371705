import { Page, Text, View, StyleSheet, Image as ImagePDF } from "@react-pdf/renderer";
import React from "react";
import { Logos } from "../../components/Logos";
import { Header } from "../../components/Header";
import { styles } from "../../styles";
import { IGEEProps } from "../../../interfaces/IGEEData";
import sinergiaAzul from "../../../../../../../assets/images/logo-azul.png";

interface Page1Props {
  companyName?: string;
}


export const Page7: React.FC<Page1Props & IGEEProps> = (props) => {
  const { resume, data, companyName } = props;


  const organizationalLimit = resume?.igee_itens.organizational_limit;

  const getBolinha = (label: number | undefined) => {
    switch (label) {
      case 1:
        return (
          <View style={{ backgroundColor: "#2a70b8", ...styles.bolinha, marginBottom: "5px", marginTop: '5px' }}>
            <Text style={{...styles.Bold,
                    fontSize: '14px',
                    color: "#ecf3f4",
                    top: 3
                    }}>m</Text>
          </View>
        );
      case 2:
        return (
          <View style={{ backgroundColor: "#76b987", ...styles.bolinha, marginBottom: "5px" }}>
            <Text style={{...styles.Bold,
                    fontSize: '14px',
                    color: "#ecf3f4",
                    top: 3}}>c</Text>
          </View>
        );
      case 3:
        return (
          <View style={{ backgroundColor: "#b3b3b3", ...styles.bolinha, marginBottom: "5px" }}>
            <Text style={{...styles.Bold,
                    fontSize: '14px',
                    color: "#ecf3f4",
                    top: 3}}>u</Text>
          </View>
        );
      default:
        return null;
    }
  };

  return (
    <Page size={[640, 480]} style={styles.Page}>
      <Header backColor="#326ed6" title="4. Limites do inventário" />
      <View style={local.body}>
        <Text style={{ ...local.title, ...styles.boldItalic }}>
          Limites organizacionais
        </Text>
        <Text style={{ ...local.text, ...styles.light, textAlign: 'justify' }}>
          Abaixo é apresentada uma lista das unidades da organização e de empresas
          controladas incluídas neste inventário. É obrigatório o relato desagregado
          das emissões das unidades que possuem emissões de escopo 1 iguais ou superiores
          a 10.000 tCO<Text style={{ fontSize: 5 }}>2</Text>e por ano. O relato das emissões das outras unidades, assim como
          o de empresas controladas, é opcional. As emissões desagregadas por unidades podem ser encontradas no capítulo 6. Resultados.
        </Text>
        <View style={local.box}>
          <View style={local.section}>
            <Text style={{ ...local.text, ...styles.Bold, marginTop: 30, marginBottom: '2px' }}>
              Abordagem de consolidação utilizada no inventário:{" "}
              {resume?.limits_itens.organizational.approach}
            </Text>
            <Text style={{ ...local.text, ...styles.light }}>
              Relato de emissões sob {resume?.igee_itens.consolidation_approach}.
            </Text>
          </View>
          <View style={local.section}>
            <Text style={{ ...styles.light, color: "gray", paddingLeft: "12px", fontSize: 10 }}>Legenda</Text>
            <View style={local.captions}>
              <View style={local.caption}>
                <View style={{ backgroundColor: "#2a70b8", ...styles.bolinha, marginTop: '5px' }}>
                  <Text style={{
                    ...styles.Bold,
                    fontSize: '14px',
                    color: "#ecf3f4",
                    top: 3
                    }}>m</Text>
                </View>
                <Text style={{ ...styles.light, color: "gray", fontSize: 8, marginTop: '4px', marginLeft: 2 }}>
                  Matriz
                </Text>
              </View>
              <View style={local.caption}>
                <View style={{ backgroundColor: "#76b987", ...styles.bolinha, marginTop: '5px' }}>
                  <Text style={{
                      ...styles.Bold,
                      fontSize: '14px',
                      color: "#ecf3f4",
                      top: 3
                      }}>c</Text>
                </View>
                <Text style={{ ...styles.light, color: "gray", fontSize: 8, marginTop: '4px', marginLeft: 2 }}>
                  Controlada
                </Text>
              </View>
              <View style={local.caption}>
                <View style={{ backgroundColor: "#b3b3b3", ...styles.bolinha, marginTop: '5px' }}>
                  <Text style={{
                      ...styles.Bold,
                      fontSize: '14px',
                      color: "#ecf3f4",
                      top: 3
                      }}>u</Text>
                </View>
                <Text style={{ ...styles.light, color: "gray", fontSize: 8, marginTop: '4px', marginLeft: 2 }}>
                  Unidade
                </Text>
              </View>
            </View>
          </View>
          <View style={local.section}>
            <Text style={{ ...local.text, ...styles.light }}>
              [A matriz possui controle operacional? | % de participação
              societária referente à Matriz]
            </Text>
            {organizationalLimit !== undefined && (
              <View style={local.iconTextContainer}>
                <View style={local.iconContainer}>
                  {getBolinha(organizationalLimit)}
                </View>
                <Text style={{ ...local.text, ...styles.light, textAlign: 'justify' }}>
                  {companyName}
                </Text>
              </View>
            )}
          </View>
        </View>
        <View style={{ position: 'absolute', bottom: 10, right: 10, flexDirection: 'row', alignItems: 'center' }}>
              <ImagePDF
                src={resume?.company_itens.logo_image_url}
                style={{
                  width: 70,
                  height: 'auto',
                  marginRight: 10
                }}
              />
              <ImagePDF
                src={sinergiaAzul}
                style={{
                  width: 70,
                  height: 'auto',
                  marginRight: 10
                }}
              />
          </View>
      </View>
    </Page>
  );
};

const local = StyleSheet.create({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "90%",
    padding: "25px",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignContent: "flex-start",
  },
  title: {
    color: "#326ed6",
    fontSize: 18,
    padding: "5px",
    paddingLeft: "12px",
    alignSelf: "flex-start",
    marginBottom: '5px'
  },
  text: {
    color: "#326ed6",
    fontSize: 12,
    paddingLeft: "12px"
  },
  section: {
    paddingVertical: "10px",
    display: "flex",
    justifyContent: "center",
    alignContent: "flex-start",
  },
  captions: {
    display: "flex",
    flexDirection: "row",
  },
  caption: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "2px",
  },
  vertical: {
    display: "flex",
    flexDirection: "column",
    width: '70%',
    height: '70%'
  },
  iconTextContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
  },
  iconContainer: {
    marginRight: 2,
    marginTop: 2,
  }
});
