import {
    Page,
    Text,
    View,
    StyleSheet,
    Image as ImagePDF,
  } from "@react-pdf/renderer";
  import React from "react";
  import { Header } from "../../components/Header";
  import { styles } from "../../styles";
  import { IGEEProps } from "../../../interfaces/IGEEData";
  import LogoVerdeEscuro from "../../../../../../../assets/images/versoes-logo-icone-ingee-03.png";
  
  export const PageDescarbResume: React.FC<IGEEProps> = (props) => {
    const { resume } = props;
  
    return (
      <Page
        size={[640, 480]}
        style={{ ...styles.Page, backgroundColor: "#ecf3f4" }}
      >
        <Header backColor="#76B987" title="8. Descarbonização" />
        <View style={local.body}>
          <Text style={{ ...local.title, ...styles.italic, marginLeft: "10px", marginBottom: 10 }}>
            Resumo dos Projetos
          </Text>
          <View>
            <Text style={{...styles.light, textAlign: "justify", fontSize: "12px", color: "#a1a4a6", marginLeft: 15, marginRight: 15, marginBottom: 5}}>
                A curva MACC é uma ferramenta visual que mostra as opções para abater emissões em ordem crescente de custo.
                Ela ajuda a identificar as opções mais econômicas para reduzir emissões.{"\n"}{"\n"}
            </Text>
            <Text style={{...styles.light, textAlign: "justify", fontSize: "12px", color: "#a1a4a6",  marginLeft: 15, marginRight: 15}}>
                <Text style={{...styles.extraBold}}>Projetos Mais Recomendados:</Text> {"\n"}{"\n"}

                <Text style={{...styles.extraBold}}>Projetos para Revisão:</Text> {"\n"}{"\n"}

                <Text style={{...styles.extraBold}}>Maior Emissão Evitada:</Text> {"\n"}{"\n"}

                <Text style={{...styles.extraBold}}>Menor Emissão Evitada:</Text> {"\n"}{"\n"}

                <Text style={{...styles.extraBold}}>Recomendação Geral:</Text> {"\n"}
            </Text>
            
          </View>
          <View style={{ position: 'absolute', bottom: 10, right: 10, flexDirection: 'row', alignItems: 'center' }}>
              <ImagePDF
                src={resume?.company_itens.logo_image_url}
                style={{
                  width: 70,
                  height: 'auto',
                  left: '10px',
                }}
              />
              <ImagePDF
                src={LogoVerdeEscuro}
                style={{
                  width: 100,
                  height: 'auto',
                }}
              />
            </View>
        </View>
      </Page>
    );
  };
  
  const local = StyleSheet.create({
    body: {
      display: "flex",
      width: "100%",
      height: "90%",
      alignItems: "center",
      alignContent: "center",
      padding: "20px",
    },
    title: {
        color: "gray",
        fontSize: 18,
        padding: "5px",
        alignSelf: "flex-start",
    },
    section: {
      width: "96%",
      height: "80%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    brackets: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: "20px",
      width: "8%",
      marginLeft: "-5px",
    },
    bracket: {
      fontSize: 80,
      color: "#a1a4a6",
      marginLeft: "30px",
    },
    texts: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "auto",
      height: "50%",
      marginLeft: 30
    },
    text: {
      fontSize: 9,
    },
  });
  