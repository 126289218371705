import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../../components/Button/styles";
import { GoBack } from "../../../../components/GoBack";
import { TitleText } from "../../../../components/TitleText";
import useAuth from "../../../../hooks/useAuth";
import inventoryService from "../../../../services/inventoryService";
import { Container } from "./styles";
import TrashIcon from '../../../../assets/icons/trash.svg'
import { DefaultText } from "../../../../components/DefaulText";
import { useLocation, useHistory } from "react-router-dom";
import userService from "../../../../services/userService";
import AttachmentIcon from '../../../../assets/icons/attachment.svg'
import DownloadIcon from '../../../../assets/icons/download.svg'
import { toast } from "react-hot-toast";


interface Inputs {
  number_art: any;
  name_professional: string;
  record_crea: any;
  profession: string;
  third_party_verified: any;
  controller_name: any;
  controller_mail: any;
  trust_level: any;
}
export const InventoryThirdPart = () => {
  const history = useHistory();

  const [inventorySelected, setInventorySelected] = useState<any>({});
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [showThirdOrganisms, setShowThirdOrganisms] = useState(false);
  const [organismsSelected, setOrganismsSelected] = useState([
    {
      organism_name: '',
      controller_id: '',
      trust_level: '',
      number_art: '',
      name_professional: '',
      record_crea: '',
      profession: '',
    },
  ]);
  const [files, setFiles] = useState<any>([]);
  const [filesArt, setFilesArt] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [organismsRequired, setOrganismsRequired] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [anonymize, setAnonymize] = useState(false);
  const [onlyView, setOnlyView] = useState(false);
  const [isController, setIsController] = useState(false);

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.company_id && item.year){
      getInventorysById(item.company_id, item.year)

      setAnonymize(user.pseudo_anonymize)
      setOnlyView(user.profile !== 'sinergia' && user.profile !== 'admin' && user.profile !== 'analyst')
      setIsController(user.profile === 'controller' || user.profile === 'sinergia')
    }else{
      history.push({
        pathname: '/inventario',
      });
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location]);

  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    setOrganismsRequired(false);
  
    if (formData) {
      const third_party_verified = formData.third_party_verified === '1';
  
      let organisms: any[] = [];
  
      if (!third_party_verified || (third_party_verified && organismsSelected.length > 0)) {
        organisms = organismsSelected.map((organism: any) => {
          return {
            organism: organism.organism_name || '0',
            controller_id: organism.controller_id || null,
            trust_level: parseInt(organism.trust_level) || 0,
            number_art: organism.number_art || '',
            name_professional: organism.name_professional || '',
            record_crea: organism.record_crea || '',
            profession: organism.profession || '',
          };
        });
  
        const itens = {
          third_party_verified,
          organisms,
        };
  
        const result = await inventoryService.updateOrganismId(inventorySelected.company.id, inventorySelected.year, itens);
  
        if (result && !result.error) {
         // history.goBack();
         // reset();
          //setErrorApi(undefined);
          toast.success('Operação realizada com sucesso!');
        } else if (result.error) {
          setErrorApi(result.error);
        }
      } else if (third_party_verified) {
        setOrganismsRequired(true);
      }
    }
  };

  async function getInventorysById(company_id: number, year: number) {
    const users = await userService.getByProfile(company_id, 'controller');
    const entity = await inventoryService.getById(company_id, year);
  
    // Define third_party_verified com base nas condições fornecidas
    const third_party_verified = entity.third_party_verified || (users && users.length > 0);
    setShowThirdOrganisms(third_party_verified);
    setValue("third_party_verified", third_party_verified ? "1" : "0");
  
    const result = await inventoryService.getCertificates(entity.company.id, entity.year);
    setFiles(result.files);
  
    const resultArt = await inventoryService.getART(entity.company.id, entity.year);
    setFilesArt(resultArt.files);
  
    if (entity.organisms && entity.organisms.length > 0) {
      setOrganismsSelected(entity.organisms.map((organism: any, index: number) => {
        setValue(`organism.${index}.controller_name`, organism.controller.name + ' (' + organism.controller.mail + ')');
        setValue(`organism.${index}.controller_id`, organism.controller.id);
        setValue(`organism.${index}.controller_name`, organism.controller.name);
        setValue(`organism.${index}.trust_level`, organism.trust_level.toString());
        setValue(`organism.${index}.name_professional`, organism.name_professional);
        setValue(`organism.${index}.number_art`, organism.number_art);
        setValue(`organism.${index}.record_crea`, organism.record_crea);
        setValue(`organism.${index}.profession`, organism.profession);
  
        return {
          controller_name: organism.controller.name + ' (' + organism.controller.mail + ')',
          organism_name: organism.organism,
          controller_id: organism.controller.id,
          trust_level: organism.trust_level,
          name_professional: organism.name_professional,
          number_art: organism.number_art,
          record_crea: organism.record_crea,
          profession: organism.profession
        };
      }));
    } else if (users && users.length > 0) {
      setOrganismsSelected(users.map((user: any, index: number) => {
        setValue(`organism.${index}.controller_name`, user.name + ' (' + user.mail + ')');
        setValue(`organism.${index}.controller_id`, user.id);
        setValue(`organism.${index}.controller_name`, user.name);
        setValue(`organism.${index}.number_art`, user.number_art || '');
        setValue(`organism.${index}.name_professional`, user.name_professional || '');
        setValue(`organism.${index}.profession`, user.profession || '');
        setValue(`organism.${index}.record_crea`, user.record_crea || '');
  
        let organism = user?.organism;
        if (Array.isArray(organism)) {
          organism = organism[0];
        }
        let organism_name = '';
        if (organism) {
          organism_name = organism.name;
        }
  
        return {
          controller_name: user.name + ' (' + user.mail + ')',
          organism_name: organism_name || '',
          controller_id: user.id,
          number_art: user.number_art || '',
          name_professional: user.name_professional || '',
          profession: user.profession || '',
          record_crea: user.record_crea || ''
        };
      }));
    } else {
      setOrganismsSelected([{
        organism_name: '',
        controller_id: '',
        trust_level: '',
        number_art: '',
        name_professional: '',
        record_crea: '',
        profession: ''
      }]);
    }
  
    setInventorySelected(entity);
  }
  
  const handleDownloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteCertificate(id)

      if (result && !result.error) {
        const result = await inventoryService.getCertificates(
          inventorySelected.company.id,
          inventorySelected.year
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const handleAttachmentChange = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 1,
        name: 'atividades_agricolas',
        file: base64,
        filename: filename,
        second_name: ''
      }

      const result = await inventoryService.saveCertificate(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getCertificates(
          inventorySelected.company.id,
          inventorySelected.year
        )

        setFiles(result.files)
      }
    }

    const file = event.target.files[0]
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => {
      const base64 = reader.result

      saveImage(base64?.toString(), file.name)
    }
  }

  const handleDownloadFileArt = (filename: string) => {
    window.open(filename, '_blank')
  }

  function handleDeleteFileArt(id: number) {
    async function deleteFileArt(id: number) {
      const result = await inventoryService.deleteART(id)

      if (result && !result.error) {
        const result = await inventoryService.getART(
          inventorySelected.company.id,
          inventorySelected.year
        )

        setFilesArt (result.files)
      }
    }

    deleteFileArt(id)
  }

  const handleAttachmentChangeArt = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 1,
        name: 'atividades_agricolas',
        file: base64,
        filename: filename,
        second_name: ''
      }

      const result = await inventoryService.saveART(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getART(
          inventorySelected.company.id,
          inventorySelected.year
        )

        setFilesArt(result.files)
      }
    }

    const file = event.target.files[0]
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => {
      const base64 = reader.result

      saveImage(base64?.toString(), file.name)
    }
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <div style={{ display: "flex", flexDirection: "column"}} >
            <TitleText level={1} title={'Inventário: ' + inventorySelected?.year + ' - ' + inventorySelected?.company?.corporate_name}  />
            <TitleText level={4} title="Auditoria"  />
          </div>
          
          {onlyView === false && (
            <Button color="green" size="md" type="submit">
              Salvar
            </Button>
          )}
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", overflow: "auto", 
              position: "absolute", top: "150px", right: "30px", left: "280px", bottom: "20px"}} >

          <div style={{ display: "flex", flexDirection: "row" }} >

            <label style={{ fontFamily: "'Roboto', sans-serif", fontSize: "16px", marginBottom: 15,
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold", marginRight: 10 }} >
              O inventário será verificado por terceira parte? 
            </label>

            {showThirdOrganisms === true && (
              <span>Sim</span>
            )}

            {showThirdOrganisms === false && (
              <span>Não</span>
            )}
          </div>

          {organismsSelected && organismsSelected.length === 0 && (
            <div style={{ borderStyle: "dashed", margin: 15, borderWidth: "1px",  
                          borderColor: "#9E9E9E", height: "auto", display: "flex", 
                          flexDirection: "column", width: "95%", verticalAlign: "middle", 
                          textAlign: "center" }} >
              <span style={{ textDecoration: "underline", padding: 20 }}>
                Não existe nenhum auditor cadastrado para esta empresa, portanto não é possível ser verificado por terceira parte.
                <br/><br/>
                Clique em salvar para confirmar, ou cadastre 'Auditores' para tal verificação.
              </span>
            </div>
          )}
              {showThirdOrganisms && (
              <>
              <table style={{ width: "95%", borderCollapse: "collapse", marginTop: 5}}>
                <thead>
                  <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                    <th style={{ textAlign: "start", padding: "5px", color: "#7A7D83" }}>
                      {"Responsável"}
                    </th>
                    <th style={{ textAlign: "start", padding: "5px", color: "#7A7D83" }}>
                      {"Organismo verificador"}
                    </th>
                    <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83" }}>
                      {"Nível de verificação"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {organismsSelected.map((item: any, index: number) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td style={{ padding: "10px" }} >
                          <span className="list-text title-text-underline">
                            {item?.controller_name}
                          </span>
                        </td>
                        <td style={{ padding: "10px" }} >
                          <span className="list-text title-text-underline">
                            {item?.organism_name}
                          </span>
                        </td>
                        <td style={{ padding: "10px" }} >
                          <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: 5}}>
                            
                              <React.Fragment>
                                <input type="radio" value="1" disabled={onlyView} style={{width: 20, marginLeft: 0,  marginRight: 10}}
                                  {...register(`organism.${index}.trust_level`, { required: true })}
                                  onChange={(event) => {
                                    setValue(`organism.${index}.trust_level`, event.target.value);
                                    organismsSelected[index].trust_level = event.target.value
                                  }}/> 
                                <DefaultText title='Razoável' />
                                <input type="radio" value="2" disabled={onlyView} style={{width: 20, marginRight: 20}}
                                  {...register(`organism.${index}.trust_level`, { required: true })}
                                  onChange={(event) => {
                                    setValue(`organism.${index}.trust_level`, event.target.value);
                                    organismsSelected[index].trust_level = event.target.value
                                  }} /> 
                                <DefaultText title='Limitado' />
                              </React.Fragment>
                            
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
          

          <div style={{ display: "flex", flexDirection: "row", marginTop: 15, marginBottom: 15, 
                justifyContent: "flex-start" }} >
              {errors && errors.organism && (
                  <span style={{ color: "#E33F3F", marginTop: 10 }}>
                      * Necessário informar o grau de confiança referente o Organismo verificador
                  </span>
              )}
          </div>

          {organismsSelected &&organismsSelected.length > 0 && (
            <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                          width: "95%", borderColor: "#9E9E9E", height: "auto", display: "flex", 
                          flexDirection: "row"}} >
              <span style={{ margin: 10, width: "95%" }}>
                <b>Razoável: </b>Grau mais alto de confiança. 
              </span>
            </div> 
          )}

          {organismsSelected &&organismsSelected.length > 0 && (
            <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                          width: "95%", borderColor: "#9E9E9E", height: "auto", display: "flex", 
                          flexDirection: "row"}} >
              <span style={{ margin: 10, width: "95%" }}>
                <b>Limitado: </b>Grau de confiança inferior em relação ao nível razoável. Menor ênfase no teste detalhado de dados e informações de GEE oferecidos para apoiar a declaração de GEE.
              </span>
            </div>
          )}
          </>
          )}

          {isController === true && showThirdOrganisms && (
            <div style={{ margin: 5, paddingTop: 5, paddingBottom: 15 }} >
              <TitleText level={4} title="Certidão de verificação"  />
            </div>
          )}

          {isController === true && showThirdOrganisms && (
            <div style={{ borderStyle: "dashed", marginTop: 20, borderWidth: "1px", margin: 5, 
                          paddingTop: 15, paddingBottom: 15, borderColor: "#9E9E9E", 
                          width: "95%", textAlign: "center", cursor: "pointer"}} 
                          onClick={() => (document.querySelector("#attachmentInput") as HTMLInputElement).click()}>
              <img src={AttachmentIcon} alt="coisa" width={32}/>

              <br/>

              <span style={{padding: 5, fontSize: 14, fontStyle: "bold"}}>
                Insira aqui a Certidão de Verificação correspondente ao inventário
              </span>
            </div>
          )}

          <input type="file" id="attachmentInput" style={{ display: 'none' }}
            name="file" onChange={handleAttachmentChange} />

          {files && files.length > 0 && (
            <div style={{ borderStyle: "dashed", marginTop: 5, borderWidth: "1px", margin: 5, 
                            paddingTop: 5, paddingBottom: 5, borderColor: "#9E9E9E", 
                            width: "95%", textAlign: "center", cursor: "pointer"}} >
              <table style={{ width: '100%', borderCollapse: 'collapse' }} className="table-scope" >
                <thead>
                  <tr>
                    <th className="head-tr-item" style={{ width: 200 }}>
                      {'Certidão de verificação'}
                    </th>
                    <th className="head-tr-item" style={{ width: 200 }}>
                      {'Usuário'}
                    </th>
                    <th className="head-tr-item" style={{ width: 50 }} />
                  </tr>
                </thead>
                <tbody>
                  {files.map((file: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td style={{ width: '60%', textAlign: 'left' }}>
                            <span style={{ marginLeft: 5, fontSize: 12, textAlign: 'left', display: 'inline-block' }} >
                              {file.filename}
                            </span>
                          </td>
                          <td style={{ width: '30%', textAlign: 'left' }}>
                            <span style={{ marginLeft: 5, fontSize: 12, textAlign: 'left', display: 'inline-block' }} >
                              {file.user_name} ({file.user_mail})
                            </span>
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            <div style={{ display: 'inline-flex', textAlign: 'center' }} >
                              {(user.id === file.user_id || user.profile === 'sinergia') && (
                                <div onClick={() => handleDeleteFile(file.id)} 
                                    style={{ cursor: 'pointer', textAlign: 'center', width: 30 }} >
                                  <img alt="deleteIcon" src={TrashIcon} />
                                </div>
                              )}
                              <div onClick={() => handleDownloadFile(file.file_url)}
                                style={{ cursor: 'pointer', textAlign: 'center', width: 30 }} >
                                <img alt="downloadIcon" src={DownloadIcon} width={24} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )}

            <div style={{ margin: 5, paddingTop: 5, paddingBottom: 15 }} >
              <TitleText level={4} title="Desk Review"/>
            </div>

            {organismsSelected.map((item: any, index: number) => (
              <React.Fragment key={index}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginTop: 20,
                    marginBottom: 40,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: 10,
                      width: '20%',
                    }}
                  >
                    <label
                      style={{
                        marginTop: 5,
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: '#31363F',
                        opacity: '0.8',
                        fontWeight: 'bold',
                        marginLeft: '5px'
                      }}
                    >
                      Número da ART
                    </label>
                    <input
                      style={{ width: '100%', marginLeft:'5px' }}
                      disabled={onlyView}
                      {...register(`organism.${index}.number_art`)}
                      onChange={(event) => {
                        setValue(`organism.${index}.number_art`, event.target.value);
                        organismsSelected[index].number_art = event.target.value;
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: 10,
                      width: '20%',
                      marginLeft: 35,
                    }}
                  >
                    <label
                      style={{
                        marginTop: 5,
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: '#31363F',
                        opacity: '0.8',
                        fontWeight: 'bold',
                      }}
                    >
                      Nome do profissional
                    </label>
                    <input
                      style={{ width: '100%' }}
                      {...register(`organism.${index}.name_professional`)}
                      onChange={(event) => {
                        setValue(`organism.${index}.name_professional`, event.target.value);
                        organismsSelected[index].name_professional = event.target.value;
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: 10,
                      width: '20%',
                      marginLeft: 35,
                    }}
                  >
                    <label
                      style={{
                        marginTop: 5,
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: '#31363F',
                        opacity: '0.8',
                        fontWeight: 'bold',
                      }}
                    >
                      Registro no Crea
                    </label>
                    <input
                      style={{ width: '100%' }}
                      {...register(`organism.${index}.record_crea`)}
                      onChange={(event) => {
                        setValue(`organism.${index}.record_crea`, event.target.value);
                        organismsSelected[index].record_crea = event.target.value;
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: 10,
                      width: '20%',
                      marginLeft: 35,
                    }}
                  >
                    <label
                      style={{
                        marginTop: 5,
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: '#31363F',
                        opacity: '0.8',
                        fontWeight: 'bold',
                      }}
                    >
                      Profissão
                    </label>
                    <input
                      style={{ width: '100%' }}
                      {...register(`organism.${index}.profession`)}
                      onChange={(event) => {
                        setValue(`organism.${index}.profession`, event.target.value);
                        organismsSelected[index].profession = event.target.value;
                      }}
                    />
                  </div>
                </div>
              </React.Fragment>
            ))}

          {isController === true && (
            <div style={{ borderStyle: "dashed", marginTop: 20, borderWidth: "1px", margin: 5, 
                          paddingTop: 15, paddingBottom: 15, borderColor: "#9E9E9E", 
                          width: "95%", textAlign: "center", cursor: "pointer"}} 
                          onClick={() => (document.querySelector("#attachmentInputArt") as HTMLInputElement).click()}>
              <img src={AttachmentIcon} alt="coisa" width={32}/>

              <br/>

              <span style={{padding: 5, fontSize: 14, fontStyle: "bold"}}>
                Insira aqui a ART correspondente ao inventário
              </span>
            </div>
          )}

          <input type="file" id="attachmentInputArt" style={{ display: 'none' }}
            name="file" onChange={handleAttachmentChangeArt} />

          {filesArt && filesArt.length > 0 && (
            <div style={{ borderStyle: "dashed", marginTop: 5, borderWidth: "1px", margin: 5, 
                            paddingTop: 5, paddingBottom: 5, borderColor: "#9E9E9E", 
                            width: "95%", textAlign: "center", cursor: "pointer"}} >
              <table style={{ width: '100%', borderCollapse: 'collapse' }} className="table-scope" >
                <thead>
                  <tr>
                    <th className="head-tr-item" style={{ width: 200 }}>
                      {'ART de Desk Review'}
                    </th>
                    <th className="head-tr-item" style={{ width: 200 }}>
                      {'Usuário'}
                    </th>
                    <th className="head-tr-item" style={{ width: 50 }} />
                  </tr>
                </thead>
                <tbody>
                  {filesArt.map((file: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td style={{ width: '60%', textAlign: 'left' }}>
                            <span style={{ marginLeft: 5, fontSize: 12, textAlign: 'left', display: 'inline-block' }} >
                              {file.filename}
                            </span>
                          </td>
                          <td style={{ width: '30%', textAlign: 'left' }}>
                            <span style={{ marginLeft: 5, fontSize: 12, textAlign: 'left', display: 'inline-block' }} >
                              {file.user_name} ({file.user_mail})
                            </span>
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            <div style={{ display: 'inline-flex', textAlign: 'center' }} >
                              {(user.id === file.user_id || user.profile === 'sinergia') && (
                                <div onClick={() => handleDeleteFileArt(file.id)} 
                                    style={{ cursor: 'pointer', textAlign: 'center', width: 30 }} >
                                  <img alt="deleteIcon" src={TrashIcon} />
                                </div>
                              )}
                              <div onClick={() => handleDownloadFileArt(file.file_url)}
                                style={{ cursor: 'pointer', textAlign: 'center', width: 30 }} >
                                <img alt="downloadIcon" src={DownloadIcon} width={24} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </form>
    </Container>
  );
};
