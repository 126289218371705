import { Modal } from "../../../../components/Modal"
import { TitleText } from "../../../../components/TitleText"
import useAuth from "../../../../hooks/useAuth"
import { Container } from "./styles"

type ModalDetailsProps = {
    isOpen: boolean;
    handleModalVisible: () => void;
    history: any;
}

export const ModalDetails = ({ isOpen, handleModalVisible, history }: ModalDetailsProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { user } = useAuth();
    return (
        <Modal
            isOpen={isOpen}
            isPayOrTypeClient={true}
            handleModalVisible={handleModalVisible}
        >
            <Container>
                <TitleText level={2} title={'Versão: ' + history.version} />

                <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                  <iframe title="histórico" src={history.terms_of_use_url} />

                </div>
            </Container>
        </Modal >
    )
}