import React from "react";
import { Container } from "./styles";
import { formatPrice6 } from "../../../../utils/transform";

type StepTwoProps = {
  register: (value: string) => any;
  setValue: (value: string, e: any) => any;
  occupationType: any[];
  handleChangeOccupationType: (id: any) => void;
  handleChange: () => void;
  volumeEffluents: number;
  organicLoad: number;
  contentNitrogen: number;
};

export const StepTwo: React.FC<StepTwoProps> = ({
  register,
  setValue,
  handleChangeOccupationType,
  occupationType,
  handleChange,
  volumeEffluents,
  organicLoad,
  contentNitrogen,
}) => {
  return (
    <Container>
      <div className="div-body">
        <div className="div-text">
          <span className="text-statement">
            Passo 2 - Dados da geração de efluentes domésticos
          </span>
        </div>

        <div className="input-item">
          <div className="div-item">
            <span className="prompt-text">
              Qual o número de pessoas consideradas na geração do efluente
              doméstico?
            </span>
          </div>
          <div className="selection-input">
            <input
              style={{ width: "33vh", textAlign: "right" }}
              {...register("number_people")}
              onChange={(event) => {
                const inputValue = event.target.value;
                setValue("number_people", inputValue);

                handleChange();
              }}
            />
          </div>
        </div>

        <div className="input-item">
          <div className="div-item">
            <span className="prompt-text">
              Qual o tipo de atividade em que os efluentes domésticos são
              gerados?
            </span>
          </div>
          <div className="selection-input">
            {occupationType && (
              <select
                style={{ borderRadius: 5, width: "325px", height: 30 }}
                {...register("occupation_type_id")}
                onChange={(event) =>
                  handleChangeOccupationType(event.target.value)
                }
              >
                {occupationType.map((type: any) => (
                  <option value={type.id} key={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>

        <div className="input-item">
          <div className="div-item">
            <span className="prompt-text">
              Volume de efluentes domésticos gerados no ano
            </span>
          </div>
          <div className="selection-input">
            <input
              style={{ width: "16vh", textAlign: "right", marginTop: 5 }}
              readOnly
              {...register("volume_effluents")}
              value={formatPrice6(volumeEffluents, false)}
            />
            <span className="unit-item">[m³]</span>
          </div>
        </div>

        <div className="input-item">
          <div className="div-item">
            <span className="prompt-text">
              Carga orgânica do efluente doméstico
            </span>
          </div>
          <div className="selection-input">
            <input
              style={{ width: "16vh", textAlign: "right", marginTop: 5 }}
              readOnly
              {...register("organic_load")}
              value={formatPrice6(organicLoad, false)}
            />
            <span className="unit-item">[kgDBO/ano]</span>
          </div>
        </div>

        <div className="input-item">
          <div className="div-item">
            <span className="prompt-text">Teor de N efluente doméstico</span>
          </div>
          <div className="selection-input">
            <input
              style={{ width: "16vh", textAlign: "right", marginTop: 5 }}
              readOnly
              {...register("content_nitrogen")}
              value={formatPrice6(contentNitrogen, false)}
            />
            <span className="unit-item">[kgN/ano]</span>
          </div>
        </div>
      </div>
    </Container>
  );
};
