import { Modal } from "../../../../components/Modal"
import { TitleText } from "../../../../components/TitleText"
import useAuth from "../../../../hooks/useAuth"
import { Container } from "./styles"

type ModalPdfProps = {
    isOpen: boolean;
    handleModalVisible: () => void;
    pdf: any;
}

export const ModalPdf = ({ isOpen, handleModalVisible, pdf }: ModalPdfProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { user } = useAuth();
    return (
        <Modal
            isOpen={isOpen}
            isPayOrTypeClient={true}
            handleModalVisible={handleModalVisible}
        >
            <Container>
                <TitleText level={2} title={pdf.name} />

                <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                  <iframe src={pdf.file_url} title="pdf"/>
                </div>
            </Container>
        </Modal >
    )
}