import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100vw - 150px);
    height: calc(100vh - 150px);
    padding: 7px 15px;

    iframe {
        width: calc(100vw - 150px);
        height: calc(100vh - 150px);
        border: none;
    }
`;