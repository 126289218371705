import styled from "styled-components";

export const PaginatorContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

`;

export const Button = styled.button`
    margin: 10px 0;
    padding: 5px 10px;
    background-color: #007bff;
    color: #FFF;
    border-radius: 8px;
    border: transparent;

    &:hover {
        background-color: #e4e1e1;
        box-shadow: 0 0 5px rgba(179, 179, 179, 0.5);
    }
`;
