import React from 'react'

interface Props {
  register: any
  setIgnoreFill: any
}

const ReportRecordsCheckbox: React.FC<Props> = (props) => {
  const { register, setIgnoreFill } = props
  return (
    <div
      className="row"
      style={{
        verticalAlign: 'middle',
        height: 30,
        marginBottom: 10
      }}
    >
      <input
        style={{ height: '20px', width: '20px', margin: '10px' }}
        type="checkbox"
        {...register('ignore_fill')}
        onChange={(event) => {
          setIgnoreFill(event.target.checked)
        }}
      />

      <label
        style={{
          fontFamily: "'Roboto', sans-serif",
          fontSize: '14px',
          lineHeight: '19px',
          color: '#31363F',
          opacity: '0.8',
          marginTop: 10
        }}
      >
        Selecione aqui caso não deseje reportar registros deste escopo para o inventário
        deste ano.
      </label>
    </div>
  )
}

export default ReportRecordsCheckbox
