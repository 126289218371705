import { api } from './api'

async function getById(projectMaccId: number) {
  try {
    const { data } = await api.get(`/macc_curve/${projectMaccId}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get('/macc_curve', {
      params
    })
    return data
  } catch (error) {
    console.log(error)
  }
}

async function create(projectMacc: any) {
  try {
    const { data } = await api.post(`/macc_curve`, projectMacc)
    return data
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(projectMaccId: number, projectMacc: any) {
  try {
    const { data } = await api.put(`/macc_curve/${projectMaccId}`, projectMacc)
    return data
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(projectMaccId: number) {
  try {
    const { data } = await api.delete(`/macc_curve/${projectMaccId}`)
    return data
  } catch (error) {
    return false
  }
}

async function getGraphic(params: any) {
  try {
    const { data } = await api.get('/macc_curve/graphic', {
      params
    })
    return data
  } catch (error) {
    console.log(error)
  }
}

const projectMaccService = {
  getById,
  create,
  updateById,
  deleteById,
  getGraphic,
  getByParams,
}

export default projectMaccService
