import { api } from "./api";


async function getById(home_office_energyId: number) {
  try {
    const { data } = await api.get(`/home_office_energy/${home_office_energyId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/home_office_energy", {
      params: params
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function create(home_office_energy: any) {
  try {
    const { data } = await api.post(`/home_office_energy`, home_office_energy);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(home_office_energyId: number, home_office_energy: any) {
  try {
    const { data } = await api.put(`/home_office_energy/${home_office_energyId}`, home_office_energy);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(home_office_energyId: number) {
  try {
    const { data } = await api.delete(`/home_office_energy/${home_office_energyId}`);
    return data;
  } catch (error) {
    return false;
  }
}

const homeOfficeService = {
  getById,
  getByParams,
  create,
  updateById,
  deleteById
};

export default homeOfficeService;
