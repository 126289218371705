import { EletricityParams } from "../interfaces/ParamEmissonGeneric";
import { api } from "./api";

async function calculatePublicTransportTrain(year: number, company_id: number, address:string, worked_days: number, 
    travelled_distance: number, transport_type_id: number, number_of_passengers: number) {
  try {
    const { data } = await api.put(`/scope3/home_work_displacement/public_transport/train/calculate`, 
      { year: year, company_id: company_id, address: address, worked_days: worked_days, 
        travelled_distance: travelled_distance, transport_type_id: transport_type_id, 
        number_of_passengers: number_of_passengers });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculatePublicTransportTrainManual(year: number, company_id: number, worked_days: number, 
  travelled_distance: any, transport_type_id: number, number_of_passengers: number) {
try {
  const { data } = await api.put(`/scope3/home_work_displacement/public_transport/train_manual/calculate`, 
    { year: year, company_id: company_id, worked_days: worked_days, 
      travelled_distance: travelled_distance, transport_type_id: transport_type_id, 
      number_of_passengers: number_of_passengers });
  return data;
} catch (error: any) {
  return { error: error?.response?.data?.message }
}
}

async function calculatePublicTransportBus(year: number, company_id: number, address:string, worked_days: number, 
    travelled_distance: number, transport_type_id: number, number_of_passengers: number) {
  try {
    const { data } = await api.put(`/scope3/home_work_displacement/public_transport/bus/calculate`, 
      { year: year, company_id: company_id, address: address, worked_days: worked_days, 
        travelled_distance: travelled_distance, transport_type_id: transport_type_id, 
        number_of_passengers: number_of_passengers });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculatePublicTransportBusManual(year: number, company_id: number, worked_days: number, 
  travelled_distance: any, transport_type_id: number, number_of_passengers: number) {
try {
  const { data } = await api.put(`/scope3/home_work_displacement/public_transport/bus_manual/calculate`, 
    { year: year, company_id: company_id, worked_days: worked_days, 
      travelled_distance: travelled_distance, transport_type_id: transport_type_id, 
      number_of_passengers: number_of_passengers });
  return data;
} catch (error: any) {
  return { error: error?.response?.data?.message }
}
}

async function calculatePublicTransportFerry(year: number, company_id: number, address:string, worked_days: number, 
    travelled_distance: number, transport_type_id: number, number_of_passengers: number) {
  try {
    const { data } = await api.put(`/scope3/home_work_displacement/public_transport/ferry/calculate`, 
      { year: year, company_id: company_id, address: address, worked_days: worked_days, 
        travelled_distance: travelled_distance, transport_type_id: transport_type_id, 
        number_of_passengers: number_of_passengers });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getPublicTransport(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/home_work_displacement/public_transport/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function calculateHomeWorkDisplacementHomeOffice(params: EletricityParams) {
  try {        
    const { data } = await api.put(`/scope3/home_work_displacement/home_office/calculate`, params);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getHomeWorkDisplacementHomeOffice(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/home_work_displacement/home_office/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveHomeWorkDisplacementHomeOffice(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/home_work_displacement/home_office/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function savePublicTransport(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/home_work_displacement/public_transport/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getHomeWorkDisplacement(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/home_work_displacement/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveHomeWorkDisplacement(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/home_work_displacement/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function savePrivateVehicles(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/home_work_displacement/private_vehicle/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getPrivateVehicles(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/home_work_displacement/private_vehicle/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function calculateBusinessTripAutoRoad(car_fleet_id: number, year_fleet: number, company_id: number, year: number,
  consumption_jan: number, consumption_feb: number, consumption_mar: number, consumption_apr: number, 
  consumption_may: number, consumption_jun: number, consumption_jul: number, consumption_aug: number, 
  consumption_sep: number, consumption_oct: number, consumption_nov: number, consumption_dec: number, 
  consumption_yearly: number ) {
  try {
    const { data } = await api.put(`/scope3/business_trip/auto_travel/road/calculate`, 
    { car_fleet_id: car_fleet_id, year_fleet: year_fleet, company_id: company_id, year: year,consumption_jan: consumption_jan, consumption_feb: consumption_feb, 
      consumption_mar: consumption_mar, consumption_apr: consumption_apr, consumption_may: consumption_may, 
      consumption_jun: consumption_jun, consumption_jul: consumption_jul, consumption_aug: consumption_aug, 
      consumption_sep: consumption_sep, consumption_oct: consumption_oct, consumption_nov: consumption_nov, 
      consumption_dec: consumption_dec, consumption_yearly: consumption_yearly });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculatePrivateVehicle(year: number, car_fleet_id: number, 
    worked_days_jan: any, worked_days_feb: any, worked_days_mar: any, worked_days_apr: any, 
    worked_days_may: any, worked_days_jun: any, worked_days_jul: any, worked_days_aug: any, 
    worked_days_sep: any, worked_days_oct: any, worked_days_nov: any, worked_days_dec: any, 
    worked_days_yearly: number, average_consumption_fuel: number) {
  try {
    const { data } = await api.put(`/scope3/home_work_displacement/private_vehicle/calculate`, 
      { year: year, car_fleet_id: car_fleet_id, worked_days_jan: worked_days_jan.toString(), worked_days_feb: worked_days_feb.toString(), 
        worked_days_mar: worked_days_mar.toString(), worked_days_apr: worked_days_apr.toString(), worked_days_may: worked_days_may.toString(), 
        worked_days_jun: worked_days_jun.toString(), worked_days_jul: worked_days_jul.toString(), worked_days_aug: worked_days_aug.toString(), 
        worked_days_sep: worked_days_sep.toString(), worked_days_oct: worked_days_oct.toString(), worked_days_nov: worked_days_nov.toString(), 
        worked_days_dec: worked_days_dec.toString(), worked_days_yearly: worked_days_yearly.toString(), 
        average_consumption_fuel: average_consumption_fuel.toString()});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateVehicleDistance(year: number, car_fleet_id: number, company_id: number, address: string, 
    worked_days_jan: any, worked_days_feb: any, worked_days_mar: any, worked_days_apr: any, 
    worked_days_may: any, worked_days_jun: any, worked_days_jul: any, worked_days_aug: any, 
    worked_days_sep: any, worked_days_oct: any, worked_days_nov: any, worked_days_dec: any, 
    worked_days_yearly: number, average_distance: number) {
  try {
    const { data } = await api.put(`/scope3/home_work_displacement/private_vehicle/distances/calculate`, 
      { year: year, car_fleet_id: car_fleet_id, company_id: company_id, address: address, 
        worked_days_jan: worked_days_jan.toString(), worked_days_feb: worked_days_feb.toString(), worked_days_mar: worked_days_mar.toString(), 
        worked_days_apr: worked_days_apr.toString(), worked_days_may: worked_days_may.toString(), worked_days_jun: worked_days_jun.toString(), 
        worked_days_jul: worked_days_jul.toString(), worked_days_aug: worked_days_aug.toString(), worked_days_sep: worked_days_sep.toString(), 
        worked_days_oct: worked_days_oct.toString(), worked_days_nov: worked_days_nov.toString(), worked_days_dec: worked_days_dec.toString(), 
        worked_days_yearly: worked_days_yearly.toString(), average_distance: average_distance.toString() });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateVehicleDistanceManual(year: number, car_fleet_id: number, company_id: number, 
  worked_days_jan: any, worked_days_feb: any, worked_days_mar: any, worked_days_apr: any, 
  worked_days_may: any, worked_days_jun: any, worked_days_jul: any, worked_days_aug: any, 
  worked_days_sep: any, worked_days_oct: any, worked_days_nov: any, worked_days_dec: any, 
  worked_days_yearly: number, average_distance: number) {
try {
  const { data } = await api.put(`/scope3/home_work_displacement/private_vehicle/distances_manual/calculate`, 
    { year: year, car_fleet_id: car_fleet_id, company_id: company_id, 
      worked_days_jan: worked_days_jan.toString(), worked_days_feb: worked_days_feb.toString(), worked_days_mar: worked_days_mar.toString(), 
      worked_days_apr: worked_days_apr.toString(), worked_days_may: worked_days_may.toString(), worked_days_jun: worked_days_jun.toString(), 
      worked_days_jul: worked_days_jul.toString(), worked_days_aug: worked_days_aug.toString(), worked_days_sep: worked_days_sep.toString(), 
      worked_days_oct: worked_days_oct.toString(), worked_days_nov: worked_days_nov.toString(), worked_days_dec: worked_days_dec.toString(), 
      worked_days_yearly: worked_days_yearly.toString(), average_distance: average_distance});
  return data;
} catch (error: any) {
  return { error: error?.response?.data?.message }
}
}

async function calculateBusinessTripAutoDistance(year_fleet: number, car_fleet_id: number, company_id: number, year: number,
  distance_jan: number, distance_feb: number, distance_mar: number, distance_apr: number, 
  distance_may: number, distance_jun: number, distance_jul: number, distance_aug: number, 
  distance_sep: number, distance_oct: number, distance_nov: number, distance_dec: number, 
  distance_yearly: number) {
  try {
    const { data } = await api.put(`/scope3/business_trip/auto_travel/distance/calculate`, 
    { year_fleet: year_fleet, company_id: company_id, year: year, car_fleet_id: car_fleet_id, distance_jan: distance_jan, distance_feb: distance_feb, 
      distance_mar: distance_mar, distance_apr: distance_apr, distance_may: distance_may, 
      distance_jun: distance_jun, distance_jul: distance_jul, distance_aug: distance_aug, 
      distance_sep: distance_sep, distance_oct: distance_oct, distance_nov: distance_nov, 
      distance_dec: distance_dec, distance_yearly: distance_yearly});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateVehicleFuel(year: number, vehicle_fuel_id: number, worked_days_jan: number, worked_days_feb: number, 
    worked_days_mar: number, worked_days_apr: number, worked_days_may: number, worked_days_jun: number, 
    worked_days_jul: number, worked_days_aug: number, worked_days_sep: number, worked_days_oct: number, 
    worked_days_nov: number, worked_days_dec: number, worked_days_yearly: number, average_consumption_fuel: number) {
  try {
    const { data } = await api.put(`/scope3/home_work_displacement/private_vehicle/fuels/calculate`, 
      { year: year, vehicle_fuel_id: vehicle_fuel_id, worked_days_jan: worked_days_jan.toString(), worked_days_feb: worked_days_feb.toString(), 
        worked_days_mar: worked_days_mar.toString(), worked_days_apr: worked_days_apr.toString(), worked_days_may: worked_days_may.toString(), 
        worked_days_jun: worked_days_jun.toString(), worked_days_jul: worked_days_jul.toString(), worked_days_aug: worked_days_aug.toString(), 
        worked_days_sep: worked_days_sep.toString(), worked_days_oct: worked_days_oct.toString(), worked_days_nov: worked_days_nov.toString(), 
        worked_days_dec: worked_days_dec.toString(), worked_days_yearly: worked_days_yearly.toString(), 
        average_consumption_fuel: average_consumption_fuel.toString()});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateBusinessTripAutoFuel(vehicle_fuel_id: number, company_id: number, year: number, consumption_jan: number, consumption_feb: number, 
  consumption_mar: number, consumption_apr: number, consumption_may: number, consumption_jun: number, 
  consumption_jul: number, consumption_aug: number, consumption_sep: number, consumption_oct: number, 
  consumption_nov: number, consumption_dec: number, consumption_yearly: number) {
  try {
    const { data } = await api.put(`/scope3/business_trip/auto_travel/fuel/calculate`, 
    { vehicle_fuel_id: vehicle_fuel_id, year: year, company_id: company_id, consumption_jan: consumption_jan, 
      consumption_feb: consumption_feb, consumption_mar: consumption_mar, consumption_apr: consumption_apr, 
      consumption_may: consumption_may, consumption_jun: consumption_jun, consumption_jul: consumption_jul, 
      consumption_aug: consumption_aug, consumption_sep: consumption_sep, consumption_oct: consumption_oct, 
      consumption_nov: consumption_nov, consumption_dec: consumption_dec, consumption_yearly: consumption_yearly});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getSolidWaste(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/solid_waste_operation/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveSolidWaste(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/solid_waste_operation/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getIncineration(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/solid_waste_operation/incineration/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveIncineration(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/solid_waste_operation/incineration/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateIncineration(emission_co2: number, emission_ch4: number, emission_n2o: number) {

  try {
    const { data } = await api.put(`/scope3/solid_waste_operation/incineration/itens/calculate`, 
      { emission_co2 : emission_co2, emission_ch4 : emission_ch4, emission_n2o : emission_n2o});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateIncinerationHealth(health_fossil_carbon: number, health_oxidation_factor: number, 
  health_dry_matter_carbon: number, health_conversion_factor: number, health_amount_incinerated: number) {

  try {
    const { data } = await api.put(`/scope3/solid_waste_operation/incineration/calculate`, 
      { health_fossil_carbon : health_fossil_carbon, health_oxidation_factor : health_oxidation_factor, 
        health_dry_matter_carbon : health_dry_matter_carbon, health_conversion_factor : health_conversion_factor, 
        health_amount_incinerated : health_amount_incinerated });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateHealthIncineration(amount: number) {

  try {
    const { data } = await api.put(`/scope3/solid_waste_operation/incineration/health/calculate`, 
      { amount : amount});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getAnaerobicCompost(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/solid_waste_operation/anaerobic_compost/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveAnaerobicCompost(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/solid_waste_operation/anaerobic_compost/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateAnaerobicCompost(compost_mass: number, emission_factor_ch4: number, 
  emission_factor_n2o: number, amount_recovered_ch4: number, biogas_destination: number) {

  try {
    const { data } = await api.put(`/scope3/solid_waste_operation/anaerobic_compost/calculate`, 
      { compost_mass : compost_mass, emission_factor_ch4 : emission_factor_ch4, 
        emission_factor_n2o : emission_factor_n2o, amount_recovered_ch4 : amount_recovered_ch4, 
        biogas_destination : biogas_destination});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getSolidWasteLandfilled(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/solid_waste_operation/landfilled/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveSolidWasteLandfilled(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/solid_waste_operation/landfilled/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateLandfilledComposition(percentage_1: number, percentage_2: number, 
  percentage_3: number, percentage_4: number, percentage_5: number, percentage_6: number, 
  percentage_7: number, percentage_8: number) {

  try {
    const { data } = await api.put(`/scope3/solid_waste_operation/landfilled_composition/calculate`, 
      { percentage_1 : percentage_1, percentage_2 : percentage_2, percentage_3 : percentage_3, 
        percentage_4 : percentage_4, percentage_5 : percentage_5, percentage_6 : percentage_6, 
        percentage_7 : percentage_7, percentage_8 : percentage_8});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateLandfilledDisposition(classification: string) {

  try {
    const { data } = await api.put(`/scope3/solid_waste_operation/landfilled_disposition/calculate`, 
      { classification : classification});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getThreeCategories(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/three_categories/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveThreeCategories(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/three_categories/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateThreeCategories(type: string, emission_tgee: number) {

  try {
    const { data } = await api.put(`/scope3/three_categories/calculate`, 
      { type : type, emission_tgee : emission_tgee});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateOperationEffluents(nitrogen_removed: number, amount_n2_nitrogen: number, 
  treatment_first_id: number, treatment_second_id: number, n2o_emission_factor: number, effluent_degradable: number, 
  nitrogen_removed_after: number, amount_n2_nitrogen_after: number, effluent_degradable_after: number, 
  generated_liquid_effluent: number, n2o_emission_factor_after: number, effluent_degradable_unity: number, 
  two_anaerobic_treatments: number, ch4_amount_recovered_methane: number, destination_recovered_biogas: number, 
  generated_liquid_effluent_after: number, effluent_degradable_after_unity: number, 
  destination_recovered_biogas_after: number, ch4_amount_recovered_methane_after: number) {

  try {
    const { data } = await api.put(`/scope3/operation_effluents/calculate`, 
      { nitrogen_removed : nitrogen_removed, amount_n2_nitrogen : amount_n2_nitrogen, 
        treatment_first_id : treatment_first_id, treatment_second_id : treatment_second_id, 
        n2o_emission_factor : n2o_emission_factor, effluent_degradable : effluent_degradable, 
        nitrogen_removed_after : nitrogen_removed_after, amount_n2_nitrogen_after : amount_n2_nitrogen_after, 
        effluent_degradable_after : effluent_degradable_after, generated_liquid_effluent : generated_liquid_effluent, 
        n2o_emission_factor_after : n2o_emission_factor_after, effluent_degradable_unity : effluent_degradable_unity, 
        two_anaerobic_treatments : two_anaerobic_treatments, ch4_amount_recovered_methane : ch4_amount_recovered_methane, 
        destination_recovered_biogas : destination_recovered_biogas, generated_liquid_effluent_after : generated_liquid_effluent_after, 
        effluent_degradable_after_unity : effluent_degradable_after_unity, destination_recovered_biogas_after : destination_recovered_biogas_after, 
        ch4_amount_recovered_methane_after : ch4_amount_recovered_methane_after});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveOperationEffluents(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/operation_effluents/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getOperationEffluents(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/operation_effluents/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function calculateTransportDistributionDownstreamRoadYear(car_fleet_id: number, year_fleet: number, company_id: number, year: number,
  consumption_jan: number, consumption_feb: number, consumption_mar: number, consumption_apr: number, 
  consumption_may: number, consumption_jun: number, consumption_jul: number, consumption_aug: number, 
  consumption_sep: number, consumption_oct: number, consumption_nov: number, consumption_dec: number, 
  consumption_yearly: any ) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_downstream/road_transport/year/calculate`, 
      { car_fleet_id: car_fleet_id, year_fleet: year_fleet, company_id: company_id, year: year, consumption_jan: consumption_jan, consumption_feb: consumption_feb, 
        consumption_mar: consumption_mar, consumption_apr: consumption_apr, consumption_may: consumption_may, 
        consumption_jun: consumption_jun, consumption_jul: consumption_jul, consumption_aug: consumption_aug, 
        consumption_sep: consumption_sep, consumption_oct: consumption_oct, consumption_nov: consumption_nov, 
        consumption_dec: consumption_dec, consumption_yearly: consumption_yearly });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateTransportDistributionDownstreamRoadFuel(vehicle_fuel_id: number, company_id: number, year: number, consumption_jan: number, consumption_feb: number, 
    consumption_mar: number, consumption_apr: number, consumption_may: number, consumption_jun: number, 
    consumption_jul: number, consumption_aug: number, consumption_sep: number, consumption_oct: number, 
    consumption_nov: number, consumption_dec: number, consumption_yearly: number) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_downstream/road_transport/fuel/calculate`, 
      { vehicle_fuel_id: vehicle_fuel_id, company_id: company_id, year: year, consumption_jan: consumption_jan, consumption_feb: consumption_feb, 
        consumption_mar: consumption_mar, consumption_apr: consumption_apr, consumption_may: consumption_may, 
        consumption_jun: consumption_jun, consumption_jul: consumption_jul, consumption_aug: consumption_aug, 
        consumption_sep: consumption_sep, consumption_oct: consumption_oct, consumption_nov: consumption_nov, 
        consumption_dec: consumption_dec, consumption_yearly: consumption_yearly});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateTransportDistributionDownstreamWaterFuel(vehicle_fuel_id: number, company_id: number, year: number,consumption_jan: number, consumption_feb: number, 
    consumption_mar: number, consumption_apr: number, consumption_may: number, consumption_jun: number, 
    consumption_jul: number, consumption_aug: number, consumption_sep: number, consumption_oct: number, 
    consumption_nov: number, consumption_dec: number, consumption_yearly: number) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_downstream/water_transport/fuel/calculate`, 
      { vehicle_fuel_id: vehicle_fuel_id, company_id: company_id, year: year, consumption_jan: consumption_jan, consumption_feb: consumption_feb, 
        consumption_mar: consumption_mar, consumption_apr: consumption_apr, consumption_may: consumption_may, 
        consumption_jun: consumption_jun, consumption_jul: consumption_jul, consumption_aug: consumption_aug, 
        consumption_sep: consumption_sep, consumption_oct: consumption_oct, consumption_nov: consumption_nov, 
        consumption_dec: consumption_dec, consumption_yearly: consumption_yearly});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateTransportDistributionDownstreamAirFuel(vehicle_fuel_id: number, consumption_yearly: number, company_id: number, year: number) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_downstream/air_transport/fuel/calculate`, 
      { vehicle_fuel_id: vehicle_fuel_id, consumption_yearly: consumption_yearly, company_id: company_id, year: year});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateTransportDistributionDownstreamRoadDistance(year_fleet: number, car_fleet_id: number, company_id: number, year: number,
    distance_jan: number, distance_feb: number, distance_mar: number, distance_apr: number, 
    distance_may: number, distance_jun: number, distance_jul: number, distance_aug: number, 
    distance_sep: number, distance_oct: number, distance_nov: number, distance_dec: number, 
    distance_yearly: number) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_downstream/road_transport/distance/calculate`, 
      { year_fleet: year_fleet, car_fleet_id: car_fleet_id, company_id: company_id, year: year, distance_jan: distance_jan, distance_feb: distance_feb, 
        distance_mar: distance_mar, distance_apr: distance_apr, distance_may: distance_may, 
        distance_jun: distance_jun, distance_jul: distance_jul, distance_aug: distance_aug, 
        distance_sep: distance_sep, distance_oct: distance_oct, distance_nov: distance_nov, 
        distance_dec: distance_dec, distance_yearly: distance_yearly});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getTransportDistributionDownstream(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/transport_distribution_downstream/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getTransportDistributionDownstreamRoad(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/transport_distribution_downstream/road_transport/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getTransportDistributionDownstreamWater(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/transport_distribution_downstream/water_transport/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getTransportDistributionDownstreamOther(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/transport_distribution_downstream/other/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getTransportDistributionDownstreamAir(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/transport_distribution_downstream/air_transport/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveTransportDistributionDownstreamOther(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_downstream/other/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveTransportDistributionDownstreamRoad(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_downstream/road_transport/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveTransportDistributionDownstreamWater(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_downstream/water_transport/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveTransportDistributionDownstreamAir(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_downstream/air_transport/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveTransportDistributionDownstream(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_downstream/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateTransportDistributionDownstreamOther(emission_co2: number, emission_ch4: number, 
  emission_n2o: number) {

  try {
    const { data } = await api.put(`/scope3/transport_distribution_downstream/other/calculate`, 
      { emission_co2 : emission_co2, emission_ch4 : emission_ch4, emission_n2o : emission_n2o});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateTransportDistributionUpstreamRoadYear(car_fleet_id: number, year_fleet: number, company_id: number, year: number,
  consumption_jan: number, consumption_feb: number, consumption_mar: number, consumption_apr: number, 
  consumption_may: number, consumption_jun: number, consumption_jul: number, consumption_aug: number, 
  consumption_sep: number, consumption_oct: number, consumption_nov: number, consumption_dec: number, 
  consumption_yearly: any ) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_upstream/road_transport/year/calculate`, 
      { car_fleet_id: car_fleet_id, year_fleet: year_fleet, company_id: company_id, year: year, consumption_jan: consumption_jan, consumption_feb: consumption_feb, 
        consumption_mar: consumption_mar, consumption_apr: consumption_apr, consumption_may: consumption_may, 
        consumption_jun: consumption_jun, consumption_jul: consumption_jul, consumption_aug: consumption_aug, 
        consumption_sep: consumption_sep, consumption_oct: consumption_oct, consumption_nov: consumption_nov, 
        consumption_dec: consumption_dec, consumption_yearly: consumption_yearly });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateTransportDistributionUpstreamRoadVehicle(year: number, distance: number, transport_type_id: number, transported_freight: number) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_upstream/road_transport/vehicle/calculate`, 
      { year: year, distance: distance, transport_type_id: transport_type_id, transported_freight: transported_freight});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateTransportDistributionUpstreamWaterFuel(vehicle_fuel_id: number, consumption_jan: number, consumption_feb: number, 
    consumption_mar: number, consumption_apr: number, consumption_may: number, consumption_jun: number, 
    consumption_jul: number, consumption_aug: number, consumption_sep: number, consumption_oct: number, 
    consumption_nov: number, consumption_dec: number, consumption_yearly: number) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_upstream/water_transport/fuel/calculate`, 
      { vehicle_fuel_id: vehicle_fuel_id, consumption_jan: consumption_jan, consumption_feb: consumption_feb, 
        consumption_mar: consumption_mar, consumption_apr: consumption_apr, consumption_may: consumption_may, 
        consumption_jun: consumption_jun, consumption_jul: consumption_jul, consumption_aug: consumption_aug, 
        consumption_sep: consumption_sep, consumption_oct: consumption_oct, consumption_nov: consumption_nov, 
        consumption_dec: consumption_dec, consumption_yearly: consumption_yearly});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateTransportDistributionUpstreamWaterVessel(year: number, distance: number, transport_type_id: number, transported_freight: number) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_upstream/water_transport/vessel/calculate`, 
      { year: year, distance: distance, transport_type_id: transport_type_id, transported_freight: transported_freight});
      return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateTransportDistributionUpstreamAirFuel(vehicle_fuel_id: number, consumption_yearly: number, company_id: number, year: number) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_upstream/air_transport/fuel/calculate`, 
      { vehicle_fuel_id: vehicle_fuel_id, consumption_yearly: consumption_yearly, company_id: company_id, year: year});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateTransportDistributionUpstreamAirRoute(origin: string, destiny: string, transported_volume: any, company_id: number, year: number) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_upstream/air_transport/route/calculate`, 
      { origin: origin, destiny: destiny, transported_volume: transported_volume, company_id: company_id, year: year});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateTransportDistributionDownstreamAirRoute(origin: string, destiny: string, transported_volume: number, company_id: number, year: number) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_downstream/air_transport/route/calculate`, 
      { origin: origin, destiny: destiny, transported_volume: transported_volume, company_id: company_id, year: year});
      return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateTransportDistributionUpstreamRoadDistance(year_fleet: number, car_fleet_id: number, company_id: number, year: number,
    distance_jan: number, distance_feb: number, distance_mar: number, distance_apr: number, 
    distance_may: number, distance_jun: number, distance_jul: number, distance_aug: number, 
    distance_sep: number, distance_oct: number, distance_nov: number, distance_dec: number, 
    distance_yearly: number) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_upstream/road_transport/distance/calculate`, 
      { year_fleet: year_fleet, car_fleet_id: car_fleet_id, company_id: company_id, year: year, distance_jan: distance_jan, distance_feb: distance_feb, 
        distance_mar: distance_mar, distance_apr: distance_apr, distance_may: distance_may, 
        distance_jun: distance_jun, distance_jul: distance_jul, distance_aug: distance_aug, 
        distance_sep: distance_sep, distance_oct: distance_oct, distance_nov: distance_nov, 
        distance_dec: distance_dec, distance_yearly: distance_yearly});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getBusinessTrip(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/business_trip/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getBusinessTripAir(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/business_trip/air_travel/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getBusinessTripAuto(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/business_trip/auto_travel/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getBusinessTripBus(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/business_trip/bus_travel/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getBusinessTripTrain(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/business_trip/train_travel/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function calculateBusinessTripTrain(year: number, company_id: number, address1: string, address2 :string,
  travelled_distance: number, transport_type_id: number, number_of_passengers: number) {
  try {
    const { data } = await api.put(`/scope3/business_trip/train_travel/calculate`, 
    { year: year, company_id: company_id, address1: address1, address2: address2, travelled_distance: travelled_distance,
      transport_type_id: transport_type_id, number_of_passengers: number_of_passengers });
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateTransportDistributionDownstreamRoadVehicle(year: number, distance: number, transport_type_id: number, transported_freight: number) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_downstream/road_transport/vehicle/calculate`, 
      { year: year, distance: distance, transport_type_id: transport_type_id, transported_freight: transported_freight});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateBusinessTripBus(year: number, company_id: number, description: string, address1: string, address2 :string,
  travelled_distance: number, transport_type_id: number, number_of_passengers: number) {
try {
  const { data } = await api.put(`/scope3/business_trip/bus_travel/calculate`, 
    { year: year, company_id: company_id, description: description, address1: address1, address2: address2, travelled_distance: travelled_distance,
      transport_type_id: transport_type_id, number_of_passengers: number_of_passengers });
  return data;
} catch (error: any) {
  return { error: error?.response?.data?.message }
}
}


async function calculateBusinessTripAirRoute(origin: string, destiny: string, number_stretches: number, company_id: number, year: number) {
  try {
    const { data } = await api.put(`/scope3/business_trip/air_travel/route/calculate`, 
      { origin: origin, destiny: destiny, number_stretches: number_stretches, company_id: company_id, year: year});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateBusinessTripAirRouteBulk(company_id: number, year: number, business_trip_data: any[]) {
  try {
    const { data } = await api.put(`/scope3/business_trip/air_travel/route/calculate_bulk`, 
    {company_id: company_id, year: year, data : { business_trip_data: business_trip_data, business_trip_filename: ''}});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateBusinessTripAirDistance(type_of_trip: number, passenger_mileage: number, company_id: number, year: number) {
  try {
    const { data } = await api.put(`/scope3/business_trip/air_travel/distance/calculate`, 
      { type_of_trip: type_of_trip, passenger_mileage: passenger_mileage, company_id: company_id, year: year});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveBusinessTripAir(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/business_trip/air_travel/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveBusinessTripBus(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/business_trip/bus_travel/${companyId}/${year}`, scope);
    console.log(data)
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveBusinessTripAuto(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/business_trip/auto_travel/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveBusinessTripTrain(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/business_trip/train_travel/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getTransportDistributionUpstream(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/transport_distribution_upstream/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getTransportDistributionUpstreamRoad(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/transport_distribution_upstream/road_transport/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getTransportDistributionUpstreamWater(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/transport_distribution_upstream/water_transport/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getTransportDistributionUpstreamOther(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/transport_distribution_upstream/other/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getTransportDistributionUpstreamAir(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/transport_distribution_upstream/air_transport/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveTransportDistributionUpstreamOther(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_upstream/other/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveTransportDistributionUpstreamRoad(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_upstream/road_transport/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveTransportDistributionUpstreamWater(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_upstream/water_transport/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveTransportDistributionUpstreamAir(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_upstream/air_transport/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function saveTransportDistributionUpstream(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/transport_distribution_upstream/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateTransportDistributionUpstreamOther(emission_co2: number, emission_ch4: number, 
  emission_n2o: number) {

  try {
    const { data } = await api.put(`/scope3/transport_distribution_upstream/other/calculate`, 
      { emission_co2 : emission_co2, emission_ch4 : emission_ch4, emission_n2o : emission_n2o});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getCoprocessing(companyId: number, year: number) {
  try {
    const { data } = await api.get(`/scope3/solid_waste_operation/coprocessing/${companyId}/${year}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function saveCoprocessing(companyId: number, year: number, scope: any) {
  try {
    const { data } = await api.put(`/scope3/solid_waste_operation/coprocessing/${companyId}/${year}`, scope);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function calculateCoprocessing(coprocessing_fossil_carbon: number, coprocessing_oxidation_factor: number, 
  coprocessing_dry_matter_carbon: number,  coprocessing_conversion_factor: number, coprocessing_amount_incinerated: number) {

  try {
    const { data } = await api.put(`/scope3/solid_waste_operation/coprocessing/calculate`, 
      { coprocessing_fossil_carbon : coprocessing_fossil_carbon, coprocessing_oxidation_factor : coprocessing_oxidation_factor, 
        coprocessing_dry_matter_carbon : coprocessing_dry_matter_carbon, coprocessing_conversion_factor : coprocessing_conversion_factor, 
        coprocessing_amount_incinerated : coprocessing_amount_incinerated});
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

const scope3Service = {
  getCoprocessing, 
  saveCoprocessing, 
  calculateCoprocessing,

  getThreeCategories,
  saveThreeCategories,
  calculateThreeCategories,

  getOperationEffluents,
  saveOperationEffluents,
  calculateOperationEffluents,

  getSolidWaste,
  saveSolidWaste,

  getIncineration,
  saveIncineration,
  calculateIncineration,
  calculateIncinerationHealth,
  calculateHealthIncineration,
  
  getAnaerobicCompost,
  saveAnaerobicCompost,
  calculateAnaerobicCompost,

  getSolidWasteLandfilled,
  saveSolidWasteLandfilled,
  calculateLandfilledComposition,
  calculateLandfilledDisposition,

  calculateVehicleDistance,
  calculatePrivateVehicle,
  calculateVehicleFuel,
  savePrivateVehicles,
  getPrivateVehicles,
  
  calculateHomeWorkDisplacementHomeOffice,
  saveHomeWorkDisplacementHomeOffice,
  getHomeWorkDisplacementHomeOffice,
  calculatePublicTransportFerry,
  calculatePublicTransportTrain,
  calculatePublicTransportBus,
  savePublicTransport,
  getPublicTransport,
  calculateVehicleDistanceManual,
  calculatePublicTransportTrainManual,
  calculatePublicTransportBusManual,

  saveHomeWorkDisplacement,
  getHomeWorkDisplacement,

  getTransportDistributionDownstream,
  saveTransportDistributionDownstream,
  getTransportDistributionDownstreamAir,
  getTransportDistributionDownstreamRoad,
  getTransportDistributionDownstreamOther,
  saveTransportDistributionDownstreamAir,
  saveTransportDistributionDownstreamRoad,
  getTransportDistributionDownstreamWater,
  saveTransportDistributionDownstreamWater,
  saveTransportDistributionDownstreamOther,
  calculateTransportDistributionDownstreamOther,
  calculateTransportDistributionDownstreamAirFuel,
  calculateTransportDistributionDownstreamRoadYear,
  calculateTransportDistributionDownstreamRoadFuel,
  calculateTransportDistributionDownstreamWaterFuel,
  calculateTransportDistributionDownstreamRoadVehicle,
  calculateTransportDistributionDownstreamRoadDistance,

  getTransportDistributionUpstream,
  saveTransportDistributionUpstream,
  getTransportDistributionUpstreamAir,
  getTransportDistributionUpstreamRoad,
  getTransportDistributionUpstreamOther,
  saveTransportDistributionUpstreamAir,
  saveTransportDistributionUpstreamRoad,
  getTransportDistributionUpstreamWater,
  saveTransportDistributionUpstreamWater,
  saveTransportDistributionUpstreamOther,
  calculateTransportDistributionUpstreamOther,
  calculateTransportDistributionUpstreamAirFuel,
  calculateTransportDistributionUpstreamAirRoute,
  calculateTransportDistributionUpstreamRoadYear,
  calculateTransportDistributionUpstreamRoadVehicle,
  calculateTransportDistributionUpstreamWaterFuel,
  calculateTransportDistributionUpstreamWaterVessel,
  calculateTransportDistributionDownstreamAirRoute,
  calculateTransportDistributionUpstreamRoadDistance,

  getBusinessTrip,
  getBusinessTripBus,
  getBusinessTripAir,
  getBusinessTripAuto,
  saveBusinessTripAir,
  saveBusinessTripBus,
  saveBusinessTripAuto,
  getBusinessTripTrain,
  saveBusinessTripTrain,
  calculateBusinessTripBus,
  calculateBusinessTripTrain,
  calculateBusinessTripAutoRoad,
  calculateBusinessTripAutoFuel,
  calculateBusinessTripAirRoute,
  calculateBusinessTripAirRouteBulk,
  calculateBusinessTripAirDistance,
  calculateBusinessTripAutoDistance,

};

export default scope3Service;
