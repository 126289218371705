import styled from 'styled-components';

export const Container = styled.div`
    padding: 0px;
    margin: 15px;
    width: 100%;

    .table-scope {
        border-color: red;
        border-width: 1px;
        border-style: solid;
    }

    .table-scope tr,
    .table-scope th,
    .table-scope td {
        border-color: white;
        border-width: 1px;
        border-style: solid;
    }
    
    .table-scope td {
        background: #F6F6F6;
    }

    .head-tr-item-bottom{
        background-color: #C6C5C5;
        font-size: 12px;
        height: 25px;
    }

    .head-tr-item{
        background-color: #D8D7D7 !important;
        font-size: 12px;
        height: 25px;
    }

    .input-item{
        background: #ffffff;
        margin: 2px;
        padding-right: 2px;
        padding-left: 2px;
        height: 30px !important;
        border: 1px solid #DFDFDF;
        border-radius: 2.5px;
    }

    .bulled-scope-pending{
        border: 1px solid;
        width: 30px;
        height: 30px;
        border-color: #C42329;
        color: #C42329;
        background: #FBACAF;
        border-radius: 5px;
        margin-left: 10px;
    }

    .bulled-scope-disabled{
        border: 1px solid;
        width: 30px;
        height: 30px;
        border-color: #524b4d;
        color: #524b4d;
        background: #E7E7E7;
        border-radius: 5px;
        margin-left: 10px;
    }

    .bulled-scope-completed{
        border: 1px solid;
        width: 30px;
        height: 30px;
        border-color: #81c787;
        color: #81c787;
        background: #D1EDE6;
        border-radius: 5px;
        margin-left: 10px;
    }

    .status-legend {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin: 7px;
    }
`;