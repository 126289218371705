import React, { useState, useEffect, useMemo } from "react";
import { Image as ImagePDF } from "@react-pdf/renderer";
import WhiteLogo from "../../../../../../../assets/images/Design sem nome (6).png";

interface LogoProps {
  logo?: string;
  companyId?: number;
  style?: React.CSSProperties; 
}

const MAX_WIDTH = 65;
const MAX_HEIGHT = 65;

export const ClientLogo: React.FC<LogoProps> = ({ logo, companyId, style }) => {
  const [dimensions, setDimensions] = useState<{ width: number; height: number } | null>(null);
  const [isBlue, setIsBlue] = useState<boolean>(false); 

  useEffect(() => {
    if (logo) {
      const img = new Image();
      img.src = logo;
      img.crossOrigin = "Anonymous"; 
      img.onload = () => {
        let { width, height } = img;
        const aspectRatio = width / height;

        if (width > MAX_WIDTH || height > MAX_HEIGHT) {
          if (aspectRatio > 1) {
            width = MAX_WIDTH;
            height = MAX_WIDTH / aspectRatio;
          } else {
            height = MAX_HEIGHT;
            width = MAX_HEIGHT * aspectRatio;
          }
        }

        setDimensions({ width, height });

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (ctx) {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, img.width, img.height);
          const pixelData = ctx.getImageData(0, 0, 1, 1).data; 
          
          if (pixelData[2] > pixelData[0] && pixelData[2] > pixelData[1]) {
            setIsBlue(true);
          }
        }
      };
    }
  }, [logo]);

  const finalLogo = useMemo(() => (isBlue && companyId === 51 ? WhiteLogo : logo), [isBlue, companyId, logo]);

  const adjustedDimensions = useMemo(() => {
    if (finalLogo === WhiteLogo && dimensions) {
      return {
        width: dimensions.width * 1.6,
        height: dimensions.height * 2, 
      };
    }
    return dimensions;
  }, [finalLogo, dimensions]);

  if (!adjustedDimensions || !finalLogo) return null;

  return (
    <ImagePDF
      style={{
        width: adjustedDimensions.width,
        height: adjustedDimensions.height,
        marginRight: 5,
        ...style, 
      }}
      src={finalLogo}
    />
  );
};
