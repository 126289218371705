import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;
    margin-bottom: 10px;

    .cotainer-options {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 5px;
    }

`;