import { ReactNode } from "react";
import { Container } from "./styles";
import { useMenu } from "../../../contexts/menuContext";

interface TableContainerWrapProps {
    children: ReactNode;
    hasContent?: boolean;
    count?: number;
}

const TableContainerWrap: React.FC<TableContainerWrapProps> = ({ children, hasContent, count }) => {
    const { isMenuOpen } = useMenu();
    return <Container isMenuOpen={isMenuOpen}>
        {hasContent ? (
            <>
                <div className="table-container custom-scrollbar">
                    {children}
                </div>
                {count && count < 2 ? (<p>{count} - Registro</p>) : ( <p>{count} - Registros</p>)}
            </>
        ):(
            children
        )}
    </Container>;
};

export default TableContainerWrap;
