import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import EyeIcon from '../../assets/icons/eye.svg';
import Logo from '../../assets/images/logo-azul.png';
import PasswordIcon from '../../assets/icons/password.png';
import useAuth from '../../hooks/useAuth';
import { TitleText } from '../../components/TitleText';
import { Button } from '../../components/Button/styles';
import { Container } from "./styles"
import { Footer } from '../../components/Footer';

import '../../pages/Login/styles.css';

// import Carousel from 'react-bootstrap/Carousel';

import WhereToFind from '../../assets/images/where-to-find.png';
import PhraseHead from '../../assets/images/phrase-heade.png';
import HollowCircle from '../../assets/images/circulo-vazado.png';
import OcaImage from '../../assets/images/oca-vazada.png'
import Circle from '../../assets/images/circulo-preenchido.png';
import IconForgetPsw from '../../assets/icons/forget-password.png';
import ButtonLogin from '../../assets/icons/login.png';
import IconLogin from '../../assets/images/icon-login.png';

import Image1 from '../../assets/images/fundos-imagens-02.png';
import Image2 from '../../assets/images/fundos-imagens-03.png';
import Image3 from '../../assets/images/fundos-imagens-06.png';
import Image4 from '../../assets/images/fundos-imagens-07.png';
import Image5 from '../../assets/images/fundos-imagens-05.png';
import Image6 from '../../assets/images/fundos-imagens-04.png';
import Image7 from '../../assets/images/fundos-imagens-08.png';
import Image8 from '../../assets/images/fundos-imagens-09.png';

import MessageBox from '../../assets/icons/email-icon.png';
import TelephoneIcon from '../../assets/icons/telefone-icon.png';
import LinkedinIcon from '../../assets/icons/linkedin-icon.png';
import LocationIcon from '../../assets/icons/localizacao-icon.png';
import InstagramIcon from '../../assets/icons/instagram-icon.png';

interface Inputs {
    mail: string;
    password: string;
}

export const Login = () => {
    const history = useHistory()
    const location = useLocation();
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [newPassword, setNewPassword] = useState<boolean>(false)
    const { signIn, errorLogin, errorReason } = useAuth();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();
    const [attemptsLeft, setAttemptsLeft] = useState<number>(3);
    const [incorrectAttempts, setIncorrectAttempts] = useState<number>(0);
    const [isBlinking, setIsBlinking] = useState(false);
    const [loginError, setLoginError] = useState(false);

    const onSubmit: SubmitHandler<Inputs> = async (formData) => {
        if (formData) {

            const credentials = {
                mail: formData.mail.trim(),
                password: formData.password
            }

            signIn(credentials).then(() => {
                if (errorLogin) {
                    if (errorReason.includes("password")) {
                        setIncorrectAttempts(incorrectAttempts + 1);
                        setAttemptsLeft(attemptsLeft - 1);
                        
                        
                    }
                }
            });
        }
    };

    useEffect(() => {
        const item: any = location?.state;

        if (item && item.new_password === true) {
            setNewPassword(item.new_password)
        }
    }, [location]);

    const handleShowPassword = () => setShowPassword(!showPassword);


    useEffect(() => {
        if (incorrectAttempts > 0 && attemptsLeft > 0) {

        }
    }, [incorrectAttempts, attemptsLeft]);

    useEffect(() => {
        if (incorrectAttempts > 0 && attemptsLeft > 0) {
            setIsBlinking(true);
            setLoginError(true); 
    
            const timeout = setTimeout(() => {
                setIsBlinking(false);
                setLoginError(false); 
            }, 1000);
    
            return () => clearTimeout(timeout);
        }
    }, [incorrectAttempts, attemptsLeft]);

    return (
        <Container>
            <div className='form-container'>
                <div style={{ display: 'flex', width: "50%" }}>

                    <form onSubmit={handleSubmit(onSubmit)} className='form-content'>
                        <TitleText level={3} title='' color="#326ebe" />

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{
                                marginTop: 15, fontFamily: "'Roboto', sans-serif",
                                fontSize: '16px',
                                lineHeight: '19px',
                                color: '#326ebe',
                                // opacity: '0.8',
                                fontWeight: "bold"
                            }}>
                                <img src={IconLogin} alt="login" width={45} />
                            </label>
                            <input
                                type="text"
                                placeholder=""
                                {...register("mail", {
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Endereço de e-mail inválido"
                                    }
                                })}
                                style={{ width: '330px', borderRadius: 15, borderStyle: 'solid', borderColor: '#326ebe', marginTop: '10px', marginBottom: '15px' }}
                            />
                            {errors.mail && (
                                <span className='requiredLabel'>{errors.mail.message}</span>
                            )}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ position: 'relative' }}>
                                <label style={{
                                    marginTop: 5, fontFamily: "'Roboto', sans-serif",
                                    fontSize: '16px',
                                    lineHeight: '19px',
                                    color: '#326ebe',
                                    // opacity: '0.8',
                                    fontWeight: "bold",
                                }}>
                                    <div>
                                        <img src={PasswordIcon} alt="icon-login" width={50} />
                                    </div>
                                </label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    className={loginError ? 'erro-input' : ''}
                                    style={{ width: '330px', borderRadius: 15, borderStyle: 'solid', borderColor: loginError ? 'red' : '#326ebe', marginTop: '10px' }}
                                    placeholder=""
                                    {...register("password", { required: true })}
                                />

                                <img src={EyeIcon} alt='password-show'
                                    className='password-show' onClick={handleShowPassword} style={{marginLeft: '35px'}}/>

                                {errors.password && (
                                    <span className='requiredLabel' style={{ marginLeft: -30 }} >Esse campo é obrigatório</span>
                                )}
                            </div>
                        </div>

                        {errorLogin && errorReason.includes("blocked") && (
                            <span className='requiredLabel' style={{fontWeight:"bold", fontSize:13, marginLeft: '5px'}}>Sua conta está bloqueada, contacte o administrador</span>
                        )}

                        {errorLogin && errorReason.includes("expired") && (
                            <span className='requiredLabel' style={{fontWeight:"bold", fontSize:13}}>Usuário com acesso expirado ou inativo, contacte o administrador</span>
                        )}

                        {errorLogin && errorReason.includes("password") && (
                            <span className={`requiredLabel ${isBlinking ? 'blinking-text' : ''}`} style={{fontWeight:"bold", fontSize:13, marginLeft: '5px'}}>
                                Usuário e/ou senha inválidos. Tentativas restantes: <u style={{fontSize:14}}>{attemptsLeft}</u>
                            </span>
                        )}

                        {newPassword && newPassword === true && (
                            <span className='requiredLabel' style={{ color: "#326ed6", fontWeight: "bold" }}>
                                Uma nova senha foi enviada no seu e-mail de cadastro!
                            </span>
                        )}

                        <Button color='white' type="submit"
                            style={{ marginTop: 30, width: '50px', borderRadius: '30px', float: 'left', marginBottom: '10px', marginRight: '5px', height: '20px' }}>
                            <img src={ButtonLogin} alt="botão-login" style={{ marginTop: -10, float: 'left', width: '80px', marginLeft: '-5px' }} />
                        </Button>

                        <span
                            onClick={() => history.push('/recuperar-senha')}
                            style={{
                                display: 'flex',
                                justifyContent: 'right',
                                cursor: 'pointer',
                                fontFamily: "'Roboto', sans-serif",
                                fontSize: '15px',
                                lineHeight: '21px',
                                color: '#326ebe',
                                opacity: '0.85',
                                marginTop: '-30px',
                                marginRight: '15px',
                                marginLeft: '100px',
                            }}>
                            <img src={IconForgetPsw} alt="esqueci-minha-senha" width={160} />
                        </span>
                    </form>
                </div>

                <div>
                    <img src={Logo} alt="logo" className='logo-image' />
                </div>

            </div>


            <Footer isInternal={false} />

            <div className="background-bar">

                <div className='phrase-head'>
                    <img src={PhraseHead} alt="phraseHead" style={{ maxWidth: 700 }} />
                </div>

                <div className='title-two'>

                    <div className='where-to-find'>
                        <img src={WhereToFind} alt="where-to-find" className='where-to-find' style={{ margin: 10 }} />
                    </div>

                    <div className="icons-midia">

                        <a href="mailto:suporte@ingee.com.br">
                            <img src={MessageBox} alt="message-box" className='message-box' width={40} style={{ marginRight: 10 }} />
                        </a>

                        <a href="https://linktr.ee/ingeesinergiaengenharia" target='_blank' rel="noopener noreferrer">
                            <img src={TelephoneIcon} alt="telephone-icon" className='telephone-icon' width={40} style={{ marginRight: 10 }} />
                        </a>

                        <a href="https://www.instagram.com/ingee_inovacao_sustentavel/" target="_blank" rel="noopener noreferrer">
                            <img src={InstagramIcon} alt="instagram-icon" className='instagram-icon' width={40} style={{ marginRight: 10 }} />
                        </a>

                        <a href="https://www.linkedin.com/company/ingee-inovação-sustentável" target="_blank" rel="noopener noreferrer" >
                            <img src={LinkedinIcon} alt="linkedin-icon" className='linkedin-icon' width={40} style={{ marginRight: 10 }} />
                        </a>

                        <a href="https://maps.app.goo.gl/2Qrrmv5CyU2DsiDf8" target="_blank" rel="noopener noreferrer">
                            <img src={LocationIcon} alt="location-icon" className='location-icon' width={40} style={{ marginRight: 10 }} />
                        </a>
                    </div>
                </div>
            </div>

        <div>

                    <div className='image-one'>
                        <img src={Image1} alt="imagem-de-fundo-um" width={100} />
                    </div>

                    <div className='image-two'>
                        <img src={Image2} alt="imagem-de-fundo-dois" width={100} />
                    </div>

                    <div className='image-three'>
                        <img src={Image3} alt="imagem-de-fundo-tres" width={100}/>
                    </div>

                    <div className='image-four'>
                        <img src={Image4} alt="imagem-de-fundo-quatro" width={100}/>
                    </div>

                    {/* <div className='image-five'>
                        <img src={Image6} alt="imagem-de-fundo-cinco" width={100} />
                    </div> */}

                    {/* <div className='image-six'>
                        <img src={Image5} alt="imagem-de-fundo-seis" width={100} />
                    </div> */}

                    {/* <div className='image-seven'>
                        <img src={Image7} alt="imagem-de-fundo-sete" width={100} />
                    </div> */}

                    {/* <div className='image-eigth'>
                        <img src={Image8} alt="imagem-de-fundo-oito" width={100} />
                    </div> */}

        </div>


            {/* <div className='images-left'>

                    <div className='image-seven'>
                        <img src={Image7} alt="imagem-de-fundo-sete" width={100} />
                    </div>

                    <div className='image-five'>
                        <img src={Image6} alt="imagem-de-fundo-cinco" width={100} />
                    </div>

                    <div className='image-three'>
                        <img src={Image3} alt="imagem-de-fundo-tres" width={100}/>
                    </div>

                    <div className='image-one'>
                        <img src={Image1} alt="imagem-de-fundo-um" width={100}/>
                    </div>
            </div>
                    
            <div className='images-rigth'>

                    <div className='image-eigth'>
                        <img src={Image8} alt="imagem-de-fundo-oito" width={100} />
                    </div>

                    <div className='image-six'>
                        <img src={Image5} alt="imagem-de-fundo-seis" width={100} />
                    </div>

                    <div className='image-four'>
                        <img src={Image4} alt="imagem-de-fundo-quatro" width={100}/>
                    </div>

                    <div className='image-two'>
                        <img src={Image2} alt="imagem-de-fundo-dois"  width={100}/>
                    </div>

            </div> */}

            <div className="hollow-circle">
                <img src={HollowCircle} alt="circulo-vazado" width={200} />
            </div>

            <div className="circle-blue">
                <img src={Circle} alt="circulo" width={100} />
            </div>

            <div className="oca-image">
                <img src={OcaImage} alt="oca-image" width={100} />
            </div>


        </Container >
    )
}