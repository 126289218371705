import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    cursor: pointer;

    .back-text {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        margin-left: 10px;
        color: #205ECD;
        opacity: 0.8;
    }
`;