import Parser from 'html-react-parser'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import useAuth from '../../../../../hooks/useAuth'
import { Container } from './styles'
import { formatPrice3, formatPrice5, toNumberValues } from '../../../../../utils/transform' 
import scope3Service from '../../../../../services/scope3Service'
import inventoryService from '../../../../../services/inventoryService'
import transportTypeService from '../../../../../services/transportTypeService'
import scopeService from '../../../../../services/scopeService'
import TrashIcon from '../../../../../assets/icons/trash.svg'
import { ModalImportData } from '../../../../../components/ModalImportData'
import { Button } from '../../../../../components/Button/styles'
import { Modal } from '../../../../../components/Modal'
import { GoBack } from '../../../../../components/GoBack'
import { TitleText } from '../../../../../components/TitleText'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'
import { Uninformed } from '../../../../../components/Uninformed'
import { Loading } from '../../../../../components/Loading'
import { toast } from 'react-hot-toast'
import { ModalDeleteDataTable } from '../../../../../components/ModalDeleteDataTable'

export const TrainTrip = () => {
  const history = useHistory()

  const location = useLocation()
  const [files, setFiles] = useState<any>([])
  const [openModal, setOpenModal] = useState(false)
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  const [importErrors, setImportErrors] = useState<any>([])
  const [openModalError, setOpenModalError] = useState(false)
  const [guidelines, setGuidelines] = useState<string | undefined>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const [openModalImportTrain, setOpenModalImportTrain] = useState(false)

  const [trains, setTrains] = useState<any>([])
  const [trainTypes, setTrainTypes] = useState<any>([])
  const [emissionCo2Trains, setEmissionCo2Trains] = useState<any>()
  const [emissionTco2eTrains, setEmissionTco2eTrains] = useState<any>()
  const [changeScopeTrain, setChangeScopeTrain] = useState<string | undefined>()

  const [openModalDelete, setOpenModalDelete] = useState(false);

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-3'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(3, 'viagens_a_negocios', 'viagens_em_trens_e_metro')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors }
  } = useForm<any>()

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {

      if(openModalDelete){
        setLoadingModal(false);
        return;
      }

      const trainItens: any[] = trains.map((item: any, index: number) => {
        return {
          register: item.register.toString(),
          description: item.description.toString(),
          address1: item.address1.toString(),
          address2: item.address2.toString(),
          travelled_distance: item.travelled_distance ? item.travelled_distance.toFixed(8) : '0',
          transport_type_id: item.transport_type_id,
          number_of_passengers: parseInt(item.number_of_passengers),
          emission_co2: item.emission_co2 ? item.emission_co2.toFixed(8) : '0',
          emission_tco2e: item.emission_tco2e ? item.emission_tco2e.toFixed(8) : '0',
          factor_gco2_km: item.factor_gco2_km ? parseFloat(item.factor_gco2_km).toFixed(8) : '0',
        }
      })

      const scope = {
        itens: trainItens,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        reason: formData.ignore_fill ? formData.reason : ''
      }

      const result = await scope3Service.saveBusinessTripTrain(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('saveBusinessTripTrain', JSON.stringify(scope));
        
        setErrorApi(undefined)

        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  const { isCommonUser } = useAuth()

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{5})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos

    // Verifica se o valor é igual a zero após a formatação e remove os zeros à esquerda
    if (value === '') {
        value = '0';
    }

    return value;
  }

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const trainTypes = await transportTypeService.getByParams({
      limit: 9000,
      page: 1,
      type: 1
    })

    setTrainTypes(
      trainTypes?.itens.map((type: any) => {
        return {
          label: type.name,
          value: type.id
        }
      })
    )

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope3Service.getBusinessTripTrain(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      1,
      'viagens_a_negocios',
      'viagem_trem_e_metro'
    )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)
      setIgnoreFill(entity.ignore_fill)
      setScopeSelected(entity)
      setTrainValues(entity?.itens)
      calculateTotalsTrain(entity?.itens)
      setChangeScopeTrain('load_scope_' + entity.id + '_trains_' + entity?.itens.length)

    } else {
      setScopeSelected({})
      setIgnoreFill(false)
      setTrainValues([])
      calculateTotalsTrain([])
      setChangeScopeTrain('load_scope_0_trains_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  const handleChangeTrain = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await transportTypeService.getById(id)

      if (result) {
        const item = {
          transport_type_id: result.id,
          transport_type_name: result.name
        }

        const index = trains.length
        const itens = trains.concat(item)

        setChangeScopeTrain('new_scope_item_' + index)
        setTrainValues(itens)
        calculateTotalsTrain(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('transport_type_train', [])
  }

  const setTrainValues = (itens: any, ignore_field?: string) => {
    let changeScopeTrain = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`train.${i}.register`, item.register)
        setValue(`train.${i}.description`, item.description)
        setValue(`train.${i}.address1`, item.address1)
        setValue(`train.${i}.address2`, item.address2)
        setValue(`train.${i}.transport_type_id`, item.transport_type_id)
        setValue(`train.${i}.transport_type_name`, item.transport_type_name)

        if ('number_of_passengers' !== ignore_field) {setValue(`train.${i}.number_of_passengers`, item.number_of_passengers)}
        if ('travelled_distance' !== ignore_field) {setValue( `train.${i}.travelled_distance`, formatPrice3(Number(item.travelled_distance), false))}
        if ('factor_gco2_km' !== ignore_field) {setValue(`train.${i}.factor_gco2_km`, formatPrice3(item.factor_gco2_km, false))}
        if ('emission_co2' !== ignore_field) {setValue(`train.${i}.emission_co2`, formatPrice3(item.emission_co2, false))}
        if ('emission_tco2e' !== ignore_field) {setValue(`train.${i}.emission_tco2e`, formatPrice3(item.emission_tco2e, false))}
        changeScopeTrain =
          changeScopeTrain +
          '_' +
          item.transport_type_id +
          '_' +
          item.number_of_passengers +
          '_' +
          item.travelled_distance
      }
    }

    setTrains(itens)
    setChangeScopeTrain(changeScopeTrain)
  }

  const calculateTotalsTrain = (itens: any) => {
    let emissionCo2Trains = 0
    let emissionTco2eTrains = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {emissionCo2Trains = emissionCo2Trains + item.emission_co2}
        if (item.emission_tco2e) {emissionTco2eTrains = emissionTco2eTrains + item.emission_tco2e}
      }
    }

    setEmissionCo2Trains(emissionCo2Trains)
    setEmissionTco2eTrains(emissionTco2eTrains)

    setChangeScopeTrain('calculate_iten_' + emissionCo2Trains + '_' + emissionTco2eTrains)
  }

  const handleChangeValueTrain = (index: number, ignore_field?: string) => {
    const company_id = inventorySelected.company.id
    const year = inventorySelected.year
    const transport_type_id = trains[index].transport_type_id
    const address1 = trains[index].address1
    const address2 = trains[index].address2
    let travelled_distance = trains[index].travelled_distance ? parseFloat(trains[index].travelled_distance) : 0
    let number_of_passengers = trains[index].number_of_passengers ? parseFloat(trains[index].number_of_passengers) : 0
    travelled_distance = travelled_distance ? travelled_distance : 0
    number_of_passengers = number_of_passengers ? number_of_passengers : 0

    async function calculate() {
      const result = await scope3Service.calculateBusinessTripTrain(
        year,
        company_id,
        address1,
        address2,
        travelled_distance,
        transport_type_id,
        number_of_passengers
      )

      if (result) {
        trains[index].travelled_distance = parseFloat(result.travelled_distance)
        trains[index].factor_gco2_km = parseFloat(result.factor_gco2_km)
        trains[index].emission_co2 = parseFloat(result.emission_co2)
        trains[index].emission_tco2e = parseFloat(result.emission_tco2e)


        setChangeScopeTrain(
          'change_scope_item_' +
              index +
              '_' +
              year +
              '_' +
              transport_type_id +
              '_' +
              travelled_distance +
              '_' +
              number_of_passengers
        )

        setTrainValues(trains, ignore_field)
        calculateTotalsTrain(trains)
      }
    }

    calculate()
  }

  const handleOpenModalImportTrain = () => {
    setOpenModalImportTrain(!openModalImportTrain)
  }

  const handleOpenModalError = (data?:any) => {
    setImportErrors(data)
    setOpenModalError(!openModalError)
  }

  const handleImportTrain = (type?:number, data?:any[]) => {
    async function importData() {
      if (data){
        setLoadingModal(true)

        const errors:any[] = []
        const itens = trains

        for (let i = 1; i < data?.length; i++) {
          const transport = data[i][3]
  
          if (transport){
            let obj:any = undefined
  
            for (const t of trainTypes) {
              if (t.label === transport){
                obj = t
                break;
              }
            }
  
            if (obj){
              const transpObj = await transportTypeService.getById(obj.value)
        
              if (transpObj) {
                const register = data[i][0]
                const description = "Dados importados (" + register + ")"
                const address1 = data[i][1]
                const address2 = data[i][2]
                const number_of_passengers = 1
                
                const scope = {
                  register: register,
                  description: description,
                  address1: address1,
                  address2: address2,
                  transport_type_id: obj.value,
                  transport_type_name: obj.label,
                  number_of_passengers: number_of_passengers,
                  travelled_distance: 0,
                  factor_gco2_km: 0,
                  emission_co2: 0,
                  emission_tco2e: 0
                }
                
                const result = await scope3Service.calculateBusinessTripTrain(
                  inventorySelected.year, 
                  inventorySelected.company.id,
                  scope.address1,
                  scope.address2,
                  scope.travelled_distance,
                  scope.transport_type_id,
                  scope.number_of_passengers
                )
          
                if (result) {
                  scope.travelled_distance = parseFloat(result.travelled_distance)
                  scope.factor_gco2_km = parseFloat(result.factor_gco2_km)
                  scope.emission_co2 = parseFloat(result.emission_co2)
                  scope.emission_tco2e = parseFloat(result.emission_tco2e)
                  itens.push(scope)
                }
              }else{
                errors.push({id: i, label: transport})
              }
            }else{
              errors.push({id: i, label: transport})
            }
          }
        }
        
        setChangeScopeTrain(`import_scope_data_${data?.length}`);
  
        setTrainValues(itens)
        calculateTotalsTrain(itens)

        if (errors && errors.length > 0){
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data){
      importData()
    }
  }





  function handleDeleteTrain(index: number) {
    const itens = []

    for (let i = 0; i < trains?.length; i++) {
      if (i !== index) {
        itens.push(trains[i])
      }
    }

    setChangeScopeTrain('remove_scope_item_' + index)

    setValue('transport_type_train', [])

    setTrainValues(itens)
    calculateTotalsTrain(itens)
  }

  function handleOpenModalDeleteTrain(){
    setOpenModalDelete(true)
  }

  function handleDeleteAllScopeTrain(){
    const itens: any = [];

    setChangeScopeTrain('remove_scope_item_')

    setValue('transport_type_train', [])

    setTrainValues(itens)
    calculateTotalsTrain(itens)

    setOpenModalDelete(false)
  }

  const downloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'viagens_a_negocios',
          'metroviario'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const attachmentChangeHandler = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 1,
        name: 'viagens_a_negocios',
        file: base64,
        filename: filename,
        second_name: 'metroviario'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'viagens_a_negocios',
          'metroviario'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 03 - ' + title}  />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill === true && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 230px)',
            maxHeight: 'calc(100vh - 230px)'
          }}
        >
          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 14,
                      fontWeight: 'bold'
                    }}
                  >
                    Viagem a negócios realizadas em modal ferroviário, de acordo com
                    quilômetros percorridos no ano
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5 }} >
                    Selecione, o tipo de transporte ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && trainTypes && (
                  <div
                    style={{
                      marginTop: 15,
                      marginRight: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <Controller
                      control={control}
                      name="transport_type_train"
                      render={({ field }) => (
                        <Select placeholder="[Selecione]"
                          {...field}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: '350px',
                              height: 40,
                              backgroundColor: 'rgb(246, 246, 246)'
                            }),
                            option: (styles) => {
                              return { ...styles, color: '#31363F' }
                            }
                          }}
                          defaultValue={getValues('transport_type_train')}
                          options={trainTypes}
                          onChange={handleChangeTrain}
                        />
                      )}
                    />

                    <button
                      className="button-view"
                      style={{ height: 40, marginLeft: 5 }}
                      onClick={() => handleOpenModalImportTrain()}
                      type="button"
                    >
                      Importar Dados
                    </button>
                  </div>
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeScopeTrain && trains && trains.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={4}>{'Percurso'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>{'Tipo de transporte'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>{'Número de passageiros'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>{'Distancia percorrida (km)'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={1}>{'Fatores de emissão'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={1}>{'Emissões'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>{'Emissões totais em CO₂e (t)'}</th>
                        {!isCommonUser && (
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          >
                             {!isCommonUser && (
                              <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                                <div onClick={handleOpenModalDeleteTrain}>
                                  <button style={{background: '#b81414', color: 'white', borderRadius: 5, cursor:'pointer'}}>Apagar tudo</button>
                                </div>
                              </td>
                            )}
                          </th>
                        )}
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Registro do colaborador'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Descrição'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Endereço 1'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Endereço 2'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'kg CO₂/ P.km'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Emissões CO₂ (t)'}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trains.map((train: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`train.${index}.register`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`train.${index}.register`, event.target.value)
                                    trains[index].register = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`train.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`train.${index}.description`,event.target.value)
                                    trains[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 250 }}
                                  className="input-item"
                                  {...register(`train.${index}.address1`)}
                                  onBlur={(event) => {
                                    setValue(`train.${index}.address1`, event.target.value)
                                    trains[index].address1 = event.target.value
                                    handleChangeValueTrain(index, 'address1')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 250 }}
                                  className="input-item"
                                  {...register(`train.${index}.address2`)}
                                  onBlur={(event) => {
                                    setValue(`train.${index}.address2`, event.target.value)
                                    trains[index].address2 = event.target.value
                                    handleChangeValueTrain(index, 'address2')
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 200,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    display: 'inline-block'
                                  }}
                                >
                                  {train.transport_type_name}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`train.${index}.number_of_passengers`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    trains[index].number_of_passengers = numberValue

                                    setValue(
                                      `train.${index}.number_of_passengers`,
                                      numberValue
                                    )

                                    handleChangeValueTrain(index, 'number_of_passengers')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`train.${index}.travelled_distance`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                      trains[index].travelled_distance = value

                                    setValue(
                                      `train.${index}.travelled_distance`,
                                      formatValue
                                    )

                                    handleChangeValueTrain(index, 'travelled_distance')
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(train.factor_gco2_km), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(train.emission_co2), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(train.emission_tco2e), false)}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }}>
                                  <div
                                    onClick={() => handleDeleteTrain(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="lixeirinha"/>
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={8}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCo2Trains, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eTrains, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {trains && trains.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum tipo de transporte cadastrado`
                        : `Nenhum tipo de transporte foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.train && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {!ignoreFill && (
            <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                          width: "98%", borderColor: "#9E9E9E", height: "40px", 
                          display: "flex", flexDirection: "row", marginLeft: 10}} >
              <span style={{ margin: 10, width: "70%" }}>
                Emissões totais em CO₂ equivalente (toneladas métricas):
              </span>
              <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                {formatPrice3(emissionTco2eTrains, false)}
              </span>
            </div>
          )}

          {ignoreFill === false && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={downloadFile}
                  onChangeAttachment={attachmentChangeHandler}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && <span style={{ fontSize: 12 }}>{Parser(guidelines)}</span>}
        </div>
      </Modal>

      {openModalImportTrain && (
        <ModalImportData
          entityType={1}
          isOpen={openModalImportTrain}
          handleModalVisible={handleOpenModalImportTrain}
          entityName="Deslocamento casa-trabalho realizadas em modal metroferroviário"
          templateFileName="template_business_Train1.xlsx"
          handleImportData={handleImportTrain}
        />
      )}

      {openModalError && importErrors && (
        <Modal isOpen={openModalError} isPayOrTypeClient={true} handleModalVisible={handleOpenModalError} >
          <div style={{ margin: 5, textAlign: "left"  }}>
            <TitleText level={3} title="Falha na importação de dados" color="red" />

            <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }} >
                Ocorreram falhas na importação dos dados previstos. Abaixo maiores detalhes.
            </span>
          </div>

          <div style={{ margin: 10, textAlign: "center", cursor: "pointer" }} >
            <table style={{ width: '100%',  borderCollapse: 'collapse' }} className="table-scope" >
              <thead>
                <tr style={{ borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                  <th style={{ backgroundColor: "#D8D7D7", fontSize: 12, height: 25 }} colSpan={3}>
                    {'Falhas de Importação'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {importErrors.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr style={{ borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "20%", fontSize: 12, textAlign: "left" }} >
                            {'Linha ' + item.id}
                          </span>
                        </td>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "80%", fontSize: 12, textAlign: "left" }} >
                            {'Tipo de transporte não identificado: ' + item.label}
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Modal >
      )}

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}

    {openModalDelete && (
      <ModalDeleteDataTable
        isOpen={openModalDelete}
        handleModalVisible={() => setOpenModalDelete(false)}
        handleOk={handleDeleteAllScopeTrain}
      />
    )}
    </Container>
  )
}
