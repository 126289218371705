import { api } from "./api";

async function getById(ticket_typeId: number) {
  try {
    const { data } = await api.get(`/ticket_type/${ticket_typeId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/ticket_type", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(ticket_type: any) {
  try {
    const { data } = await api.post(`/ticket_type`, ticket_type);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(ticket_typeId: number, ticket_type: any) {
  try {
    const { data } = await api.put(`/ticket_type/${ticket_typeId}`, ticket_type);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(ticket_typeId: number) {
  try {
    const { data } = await api.delete(`/ticket_type/${ticket_typeId}`);
    return data;
  } catch (error) {
    return false;
  }
}

const ticketTypeService = {
  getById,
  getByParams,
  create,
  updateById,
  deleteById
};

export default ticketTypeService;
