import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/styles";
import { DescriptionText } from "../../components/DescriptionText";
import { GoBack } from "../../components/GoBack";
import { ModalDelete } from "../../components/ModalDelete";
import { Pagination } from "../../components/Pagination";
import { TitleText } from "../../components/TitleText";
import useAuth from "../../hooks/useAuth";
import useDebounce from "../../hooks/useDebounce";
import projectMaccService from "../../services/projectMaccService";
import { useLocation, useHistory } from "react-router-dom";
import { formatPrice, formatPrice2 } from "../../utils/transform";
import companyService from "../../services/companyService";
import { Container } from "./styles";
import { toast } from "react-hot-toast";

export const Project = () => {
  const history = useHistory();
  const [onlyView, setOnlyView] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [projects, setProjects] = useState<any>([]);
  const [projectSelected, setOrganismSelected] = useState<any>({});

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const searchDebounced = useDebounce(searchValue, 800);

  const [currentPage, setCurrentPage] = useState(1);
  const [company, setCompany] = useState<any>(undefined);
  const location = useLocation();

  const { user } = useAuth();

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.company_id){
      getCompany(item.company_id)
    }else{
      history.push({
        pathname: '/curva-macc',
      });
    }
  },  // eslint-disable-next-line react-hooks/exhaustive-deps
  [location]);

  async function getCompany(id: number) {
    if (id){
      const result = await companyService.getById(id);

      setCompany(result);
    }
  }

  async function getProjects(page: number, filter: string) {
    if (company){
      const result = await projectMaccService.getByParams({ limit: 25, page, filter: filter, company: company.id });

      setProjects(result);
  
      setCurrentPage(page)
    }
  }

  useEffect(() => {
    if (company)
      getProjects(1, searchDebounced)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [searchDebounced, company]);

  async function handleDeleteOrganism() {
    if (projectSelected) {
      const result = await projectMaccService.deleteById(
        projectSelected?.id
      );

      if (result) {
        setOpenModalDelete(false);
        getProjects(1, searchDebounced);

        toast.success("Operação realizada com sucesso!")
      }
    }
  }

  useEffect(() => {
    setOnlyView(user.profile !== 'sinergia' && user.profile !== 'admin' && user.profile !== 'analyst')
  }, // eslint-disable-next-line react-hooks/exhaustive-deps 
  []);

  async function getProjectById(id: number) {
    const maccCurve = await projectMaccService.getById(id);

    setOrganismSelected(maccCurve)
  }

  function redirectToEdit(maccCurve?: any) {
    if (maccCurve){
      history.push({
          pathname: '/curva-macc/projetos/edicao',
          state: { project_id: maccCurve.id, company_id: company.id }
      });
    }else{
      history.push({
          pathname: '/curva-macc/projetos/edicao',
          state: { company_id: company.id }
      });
    }
  }

  function handleOpenModalDelete(maccCurve?: any) {
    setOpenModalDelete(!openModalDelete);

    if (maccCurve) {
      getProjectById(maccCurve.id);
    } else {
      setOrganismSelected({});
    }
  }

  const goPrev = () => {
    setCurrentPage(currentPage - 1);
    getProjects(currentPage - 1, searchDebounced);
  };

  const goNext = () => {
    setCurrentPage(currentPage + 1);
    getProjects(currentPage + 1, searchDebounced);
  };

  return (
    <Container>
      <GoBack />
      <div style={{ display: "flex", flexDirection: "row", marginTop: 15, justifyContent: "space-between" }} >
        <div style={{ display: "flex", flexDirection: "column" }} >
          <TitleText level={2} title="Projetos de descarbonização" />
          <DescriptionText title={'Empresa: ' + (company ? company.corporate_name : '')} />
        </div>

        <input
          className="input-search"
          placeholder="Pesquisar projetos de descarbonização"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        
        {onlyView === false && (
          <Button color="blue" size="md" onClick={() => redirectToEdit()}>
            + Novo projeto
          </Button>
        )}
      </div>

      {projects?.itens &&
        (
          <div style={{ overflow: "hidden auto", position: "relative", marginTop: 10,  height: "calc(100vh - 130px)", 
              maxHeight: "calc(100vh - 130px)"}} >
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                  <th
                    style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                  >
                    {"Nome"}
                  </th>
                  <th
                    style={{ textAlign: "end", padding: "0px 20px", color: "#7A7D83", fontWeight: "700" }}
                  >
                    {"Investimento"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {projects.itens.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td style={{ padding: "10px", width: "75%" }} >
                          <span className="title-text-underline">
                            {item.project}
                          </span>
                        </td>
                        <td style={{ padding: "10px 20px", width: "15%", textAlign: "right" }} >
                          <span>
                            {formatPrice2(item.investment, false)}
                          </span>
                        </td>
                        <td>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            {(onlyView === true) && (
                              <div style={{width: "80px"}}/>
                            )}
                            {onlyView === false && (
                              <button
                                className="button-edit"
                                style={{ marginRight: 5 }}
                                onClick={() => redirectToEdit(item)}
                              >
                                Editar
                              </button>
                            )}
                            {onlyView === false && (
                              <button
                                className="button-delete"
                                style={{ marginRight: 5 }}
                                onClick={() => handleOpenModalDelete(item)}
                              >
                                Excluir
                              </button>
                            )}
                            {onlyView === true && (
                              <button
                                className="button-view"
                                style={{ marginRight: 5 }}
                                onClick={() => redirectToEdit(item)}
                              >
                                Visualizar
                              </button>
                            )}
                            
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
              {projects?.itens?.length > 0 && (
                <Pagination
                  total={projects.total}
                  limit={25}
                  goNext={goNext}
                  goPrev={goPrev}
                  currentPage={currentPage}
                  length={projects.itens.length}
                />
              )}

          </div>
        )
      }

      {openModalDelete && (
        <ModalDelete
          isOpen={openModalDelete}
          handleModalVisible={handleOpenModalDelete}
          entityName={projectSelected.name}
          handleOk={handleDeleteOrganism}
        />
      )}
    </Container>
  );
};
