import { useState } from "react";
import ModalHandleImportData from "../../ModalHandleImportData";
import { Container } from './styles';

interface ImportProps {
    title: string;
    templateFileName: string;
    option_table: string;
    handleImportData: () => any;
    observation?: string;
}

const ButtonHandleImport: React.FC<ImportProps> = ({ title, templateFileName, option_table, handleImportData, observation, }) => {
    const [openModalImport, setOpenModalImport] = useState(false);

    const handleOpenModalImport = () => {
        setOpenModalImport(!openModalImport);
    };

    return (
        <>        
            <button
                    className="button-view"
                    style={{ height: 40, marginLeft: 5 }}
                    onClick={() => handleOpenModalImport()}
                    type="button"
                >
                    Importar Dados
            </button>

            {openModalImport && (
                <ModalHandleImportData
                    isOpen={openModalImport}
                    handleModalVisible={handleOpenModalImport}
                    title={title || ""}
                    templateFileName={templateFileName || ""}
                    handleImportData={handleImportData || handleOpenModalImport}
                    option_table={option_table || ""}
                    observation={observation || ""}
                />
            )}
        </>
    )
}

export default ButtonHandleImport;