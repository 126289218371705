import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import userService from "../../../services/userService";
import { Container } from "./styles";

import { transformCPF, transformDate, transformOnlyNumbers, transformPhone } from "../../../utils/transform";
import { DefaultText } from "../../../components/DefaulText";
import { useLocation, useHistory } from "react-router-dom";
import companyService from "../../../services/companyService";
import organismService from "../../../services/organismService";
import { toast } from "react-hot-toast";
import { Loading } from "../../../components/Loading";

export const UserEdit = () => {
  const history = useHistory();

  interface Inputs {
    name: string;
    mail: string;
    cpf: string;
    office: string;
    profile: any;
    organisms: any;
    companies: any;
    active: string;
    phone: string;
    expires_in: any;
    user_type: string;
    cellphone: string;
    company_name: string;
    organism_name: string;
    pseudo_anonymize: boolean;
  }

  const { user } = useAuth();

  const [date, setDate] = useState('')
  const [onlyView, setOnlyView] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [anonymize, setAnonymize] = useState(false);
  const [companies, setCompanies] = useState<any>([]);
  const [organisms, setOrganisms] = useState<any>([]);
  const [isController, setIsController] = useState(false);
  const [userSelected, setUserSelected] = useState<any>({});
  const [selCompanies, setSelCompanies] = useState<any>([]);
  const [selOrganisms, setSelOrganisms] = useState<any>([]);
  const [externalUsers, setExternalUsers] = useState(false);
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const location = useLocation();
  const [loadingModal, setLoadingModal] = useState(false);

  async function getOrganisms() {
    const result = await organismService.getByParams({ limit: 90000, page: 1 });

    const options = result.itens.map((organism: any) => {
      return {
        label: organism.name,
        value: organism.id,
      };
    });

    setOrganisms(options);
  }

  useEffect(() => {
    const item:any = location?.state;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    if (item && item.user_id){
      getUsersById(item.user_id)

      setAnonymize(user.pseudo_anonymize && user.id !== item.user_id)
      setOnlyView(user.profile !== 'sinergia' && 
        user.profile !== 'admin' && user.id !== item.user_id)
    }else{
      history.push({
        pathname: '/usuario/inclusao',
      });
    }
  },  // eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);

  async function getUsersById(id: number) {
    const result = await companyService.getByParams({ limit: 90000, page: 1 });

    const companies = result.itens.map((company: any) => {
      return {
        label: company.corporate_name,
        value: company.id,
      };
    });

    setCompanies(companies);

    const entity = await userService.getById(id);

    setReadOnly(entity?.active === false || entity?.is_locked || entity?.is_expired)

    setValue("name", entity?.name);
    setValue("mail", entity?.mail);
    setValue("office", entity?.office);
    setValue("profile", entity?.profile);
    setValue("expires_in", entity?.expires_in);
    setValue("active", entity?.active ? "1" : "0");
    setValue("user_type", entity?.user_type.toString());
    setValue("pseudo_anonymize", entity?.pseudo_anonymize);

    if (entity?.expires_in){
      setDate(transformDate(entity?.expires_in))
    }

    setExternalUsers(entity?.user_type === 2)
    setIsController(entity?.profile === 'controller')

    if (entity?.cpf){
      setValue("cpf", transformCPF(entity?.cpf));
    }

    if (entity?.phone){
      setValue("phone", transformPhone(entity?.phone));
    }

    if (entity?.cellphone){
      setValue("cellphone", transformPhone(entity?.cellphone));
    }

    if (entity?.companies?.length > 0) {
      const options = filterCompany(entity?.companies, companies).map((company: any) => {
        setValue("company_name", company.corporate_name)

        return {
          label: company.corporate_name,
          value: company.id,
        };
      });

      setValue("companies", options);
      setSelCompanies(options)
    }

    if (entity?.organism) {
      let organism = entity?.organism

      if (Array.isArray(organism)){
        organism = organism[0]
      }

      setValue("organism_name", organism?.name)
      setValue("organisms", {'value' : organism?.id, 'label' : organism?.name});
      setSelOrganisms({'value' : organism?.id, 'label' : organism?.name});
    }

    setUserSelected(entity)
  }

  const filterCompany = (data:any[], companies:any[]) => {
    const itens = []

    for (const a of data){
      let e = false
      
      for (const b of companies){
        if (a.id === b.value){
          e = true
        }
      }

      if (e){
        itens.push(a)
      }
    }

    return itens
  }

  useEffect(() => {
    getOrganisms()

    if (user.profile === 'sinergia'){
      setValue("user_type", "1");
      setExternalUsers(false)
    }else{
      setValue("user_type", "2");
      setExternalUsers(true)
    }
    
    setValue("pseudo_anonymize", true);
  },  // eslint-disable-next-line react-hooks/exhaustive-deps 
  []);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    setLoadingModal(true)
    if (formData) {
      const itens = externalUsers ? formData.companies : undefined;
      let companies = undefined

      if (Array.isArray(itens)){
        companies = itens.map((company: any) => {
          return company.value;
        });
      }else if (itens){
        companies = [itens.value]
      }

      let newUser:any = {
        expires_in: date,
        name: formData.name,
        mail: formData.mail,
        office: formData.office,
        user_type: externalUsers ? 2 : 1,
        active: formData.active === "1",
        pseudo_anonymize: formData.pseudo_anonymize,
        profile: externalUsers ? formData.profile : "sinergia",
        phone: formData.phone ? transformOnlyNumbers(formData.phone) : undefined,
        cellphone: formData.cellphone ? transformOnlyNumbers(formData.cellphone) : undefined,
        cpf: formData.cpf ? transformOnlyNumbers(formData.cpf) : undefined,
        organism_id: isController && externalUsers ? formData.organisms.value : undefined,
        companies: companies,
      };

      if (user.pseudo_anonymize){
        newUser = {
          expires_in: date,
          office: formData.office,
          user_type: externalUsers ? 2 : 1,
          pseudo_anonymize: formData.pseudo_anonymize,
          profile: externalUsers ? formData.profile : "sinergia",
          organism_id: isController && externalUsers ? formData.organisms.value : undefined,
          companies: companies,
        };
      }

      const result = await userService.updateById(
        userSelected.id,
        newUser
      );
      
      if (result && !result.error) {
        history.goBack()

        toast.success("Operação realizada com sucesso!")
      }
      
      if (result.error) {
        setErrorApi(result.error);
      }
    }
    setLoadingModal(true)
  };

  const handleUnlockAccount = () => {
    async function unlockById() {
      const result = await userService.unlockById(
        userSelected.id
      );
      
      if (result && !result.error) {
        //history.goBack()

        toast.success("Operação realizada com sucesso!")
      }
      
      if (result.error) {
        setErrorApi(result.error);
      }
    }

    unlockById();
  };

  const handleLockAccount = () => {
    async function lockById() {
      const result = await userService.lockById(
        userSelected.id
      );
      
      if (result && !result.error) {
       // history.goBack()

        toast.success("Operação realizada com sucesso!")
      }
      
      if (result.error) {
        setErrorApi(result.error);
      }
    }

    lockById();
  };

  const handleReactiveAccount = () => {
    async function reactiveById() {
      const result = await userService.reactiveById(
        userSelected.id
      );
      
      if (result && !result.error) {
       // history.goBack()

        toast.success("Operação realizada com sucesso!")
      }
      
      if (result.error) {
        setErrorApi(result.error);
      }
    }

    reactiveById();
  };

  const handleInactivateAccount = () => {
    setLoadingModal(true)
    async function inactivateById() {
      const result = await userService.inactivateById(
        userSelected.id
      );
      
      if (result && !result.error) {
        //history.goBack()

        toast.success("Operação realizada com sucesso!")
      }
      
      if (result.error) {
        setErrorApi(result.error);
      }
    }

    inactivateById();
    setLoadingModal(true)
  };

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Usuário" />

          <div style={{ display: "flex", flexDirection: "row"}}>
            {onlyView === false && userSelected.active === true && userSelected.is_expired === false && 
              userSelected.is_locked === false && userSelected.id !== user.id && (
              <Button color="red" size="md" type="button" style={{marginRight: 10}}
                      onClick={() => handleInactivateAccount()}>
                Inativar
              </Button>
            )}

            {onlyView === false && userSelected.active === true && userSelected.is_expired === false && 
              userSelected.is_locked === false && userSelected.id !== user.id && (
              <Button color="orange" size="md" type="button" style={{marginRight: 10}}
                      onClick={() => handleLockAccount()}>
                Bloquear
              </Button>
            )}

            {onlyView === false && (userSelected.active === false || userSelected.is_expired === true) && 
              userSelected.is_locked === false && userSelected.id !== user.id && (
              <Button color="blue" size="md" type="button" style={{marginRight: 10}}
                      onClick={() => handleReactiveAccount()}>
                Reativar
              </Button>
            )}

            {onlyView === false && userSelected.active === true && userSelected.is_locked === true && userSelected.id !== user.id && (
              <Button color="blue" size="md" type="button" style={{marginRight: 10}}
                      onClick={() => handleUnlockAccount()}>
                Desbloquear
              </Button>
            )}

            {onlyView === false && userSelected.active === true && userSelected.is_expired === false && 
              userSelected.is_locked === false && (
              <Button color="green" size="md" type="submit">
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", marginTop: 30, marginLeft: 50, width: "100%" }} >
            <div style={{ display: "flex", flexDirection: "row", width: "95%"}}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "55%"}} >
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                    lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                      Nome
                  </label>
                  <input style={{ width: "95%"}} disabled={ onlyView || readOnly}
                      {...register("name", { required: true })}
                  />
                  {errors.name && (
                      <span style={{ color: "#E33F3F", marginTop: 10 }}>
                          Esse campo é obrigatório
                      </span>
                  )}
              </div>

              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "35%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Cargo
                </label>
                <input style={{ width: "95%"}} disabled={onlyView || readOnly}
                    {...register("office")}
                />
                {errors.office && (
                    <span style={{ color: "#E33F3F", marginTop: 10 }}>
                        Esse campo é obrigatório
                    </span>
                )}
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", width: "95%"}}>
                <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "55%"}} >
                    <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                      lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                        CPF
                    </label>
                    <input style={{ width: "95%"}} disabled={anonymize || onlyView || readOnly}
                        {...register("cpf")}
                        onChange={(event) => {
                          const { value } = event.target;
                          event.target.value = transformCPF(value);
                        }}
                    />
                    {errors.cpf && (
                        <span style={{ color: "#E33F3F", marginTop: 10 }}>
                            Esse campo é obrigatório
                        </span>
                    )}
                    {errorApi && errorApi.includes("cpf") && (
                        <span style={{ color: "#E33F3F", marginTop: 5 }}>
                            CPF já registrado no sistema.
                        </span>
                    )}
                </div>

                <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "35%"}} >
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                    lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                      Expira em 
                  </label>
                  
                  <input type='date' style={{width: "150px"}} disabled={onlyView || readOnly}
                      {...register("expires_in", { required: true, valueAsDate: true })} 
                      onChange={(e)=>setDate(e.target.value)} value={date}/>

                  {errors.expires_in && (
                      <span style={{ color: "#E33F3F", marginTop: 10 }}>
                          Esse campo é obrigatório
                      </span>
                  )}
                </div>
            </div>
            
            {user.user_type === 1 && (
              <div style={{ display: "flex", flexDirection: "row", width: "95%" }}>
                <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "55%" }}>
                    <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                      lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                        E-mail
                    </label>
                    <input style={{ width: "95%"}} disabled={anonymize || onlyView || readOnly}
                        {...register("mail", { required: true })}
                        onChange={(event) => {
                            event.target.value = event.target.value.toLowerCase();
                        }}
                    />
                    {errors.mail && (
                        <span style={{ color: "#E33F3F", marginTop: 10 }}>
                            Esse campo é obrigatório
                        </span>
                    )}
                    {errorApi && errorApi.includes("mail") && (
                        <span style={{ color: "#E33F3F", marginTop: 5 }}>
                            E-mail já registrado no sistema.
                        </span>
                    )}
                </div>

                <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "35%" }}>
                    <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                      lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                        Tipo de usuário
                    </label>
                    <div style={{ display: 'flex', justifyContent: 'start',  alignItems: 'center', marginTop: 5}}>
                        <input type="radio" value="1" disabled={onlyView || readOnly} style={{width: 20, marginLeft: 0,  marginRight: 10}}
                                {...register("user_type", { required: true })}
                                onChange={(event) => {
                                    setIsController(false)
                                    setExternalUsers(false)
                                    setValue("profile", 'profile');
                                    setValue("pseudo_anonymize", true);
                                    setValue("user_type", event.target.value);
                                }} /> 
                            <DefaultText title='Interno' 
                        />
                        <input type="radio" value="2" disabled={onlyView || readOnly} style={{width: 20, marginRight: 20}}
                                {...register("user_type", { required: true })}
                                onChange={(event) => {
                                    setIsController(false)
                                    setExternalUsers(true)
                                    setValue("profile", 'analyst');
                                    setValue("pseudo_anonymize", true);
                                    setValue("user_type", event.target.value);
                                }} /> 
                            <DefaultText title='Externo' 
                        />
                    </div>
                    {errors.user_type && (
                        <span style={{ color: "#E33F3F", marginTop: 10 }}>
                            Esse campo é obrigatório
                        </span>
                    )}
                </div>
              </div>
            )}
            
            {user.user_type === 2 && (
              <div style={{ display: "flex", flexDirection: "row", width: "95%"}}>
                <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "100%"}} >
                    <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                      lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                        E-mail
                    </label>
                    <input style={{ width: "95%"}} disabled={anonymize || onlyView || readOnly} {...register("mail", { required: true })}
                        onChange={(event) => {
                            event.target.value = event.target.value.toLowerCase();
                        }}
                    />
                    {errors.mail && (
                        <span style={{ color: "#E33F3F", marginTop: 10 }}>
                            Esse campo é obrigatório
                        </span>
                    )}
                    {errorApi && errorApi.includes("mail") && (
                        <span style={{ color: "#E33F3F", marginTop: 5 }}>
                            E-mail já registrado no sistema.
                        </span>
                    )}
                </div>
              </div>
            )}

            <div style={{ display: "flex", flexDirection: "row", width: "95%"}}>
                <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "55%"}} >
                    <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                      lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                        Telefone
                    </label>
                    <input style={{ width: "95%"}} disabled={anonymize || onlyView || readOnly}
                        {...register("phone")}
                        onChange={(event) => {
                          const { value } = event.target;
                          event.target.value = transformPhone(value);
                        }}
                    />
                    {errors.phone && (
                        <span style={{ color: "#E33F3F", marginTop: 10 }}>
                            Esse campo é obrigatório
                        </span>
                    )}
                </div>

                <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "35%"}} >
                    <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                      lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                        Celular
                    </label>
                    <input style={{ width: "95%"}} disabled={anonymize || onlyView || readOnly}
                        {...register("cellphone")}
                        onChange={(event) => {
                          const { value } = event.target;
                          event.target.value = transformPhone(value);
                        }}
                    />
                    {errors.cellphone && (
                        <span style={{ color: "#E33F3F", marginTop: 10 }}>
                            Esse campo é obrigatório
                        </span>
                    )}
                </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column",  marginRight: 10, width: "55%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Pseudoanonimiza conteúdo privado?
              </label>

              <div style={{ display: "flex", flexDirection: "row", 
                          verticalAlign: "middle", height: 30 }} >
                  <input style={{ height: "15px", width: "15px" }} disabled={onlyView || readOnly}
                          type="checkbox" {...register("pseudo_anonymize")}/>

                  <label style={{ fontFamily: "'Roboto', sans-serif",
                              fontSize: "14px", lineHeight: "19px", marginLeft: 10,
                              color: "#31363F", opacity: "0.8", marginTop: 10 }} >
                      Oculta dados sensíveis de usuários / empresas, para este usuário
                  </label>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", width: "95%"}}>
              {externalUsers && (
                <div style={{ display: "flex", flexDirection: "column",  marginRight: 10, width: "55%" }}>
                    <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                      lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                        Perfil
                    </label>
                    <div style={{ display: 'flex', justifyContent: 'start',  alignItems: 'center', marginTop: 5}}>
                      <input type="radio" value="admin" disabled={onlyView || readOnly} style={{width: 20, marginLeft: 0,  marginRight: 10}}
                              {...register("profile", { required: true })}
                              onChange={(event) => {
                                  setIsController(false)
                                  setValue("profile", event.target.value);
                              }} /> 
                          <DefaultText title='Administrador' 
                      />
                      <input type="radio" value="analyst" disabled={onlyView || readOnly} style={{width: 20, marginLeft: 10,  marginRight: 10}}
                              {...register("profile", { required: true })}
                              onChange={(event) => {
                                  setIsController(false)
                                  setValue("profile", event.target.value);
                              }} /> 
                          <DefaultText title='Analista' 
                      />
                      <input type="radio" value="controller" disabled={onlyView || readOnly} style={{width: 20, marginLeft: 10,  marginRight: 10}}
                              {...register("profile", { required: true })}
                              onChange={(event) => {
                                  setIsController(true)
                                  setValue("profile", event.target.value);
                              }} /> 
                          <DefaultText title='Auditor' 
                      />
                      <input type="radio" value="director" disabled={onlyView || readOnly} style={{width: 20, marginLeft: 10,  marginRight: 10}}
                              {...register("profile", { required: true })}
                              onChange={(event) => {
                                  setIsController(false)
                                  setValue("profile", event.target.value);
                              }} /> 
                          <DefaultText title='Diretor' 
                      />
                    </div>
                    {errors.profile && (
                        <span style={{ color: "#E33F3F", marginTop: 10 }}>
                            Esse campo é obrigatório
                        </span>
                    )}
                </div>
              )}
            </div>

            {externalUsers && (
              <div style={{ display: "flex", flexDirection: "column",  marginRight: 10 }}>
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                    lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                      Empresa
                  </label>
                  {(onlyView || readOnly) && (
                    <input style={{ width: "90%"}} disabled {...register("company_name")}/>
                  )}
                  {companies && onlyView === false && readOnly === false && (
                      <Controller control={control} rules={{ required: true }}
                              name="companies" render={({ field }) => (
                          <Select placeholder="[Selecione]" {...field} styles={{ 
                              control: (styles) => ({ ...styles,
                                      width: "90%", height: 40, marginTop: 10,
                                      backgroundColor: "rgb(246, 246, 246)"}),
                              option: (styles) => { return { ...styles, color: "#31363F" };},}}
                              defaultValue={selCompanies} isMulti
                              options={companies}
                              isClearable={true}                   
                          />
                          )}
                      />
                  )}
                  {errors.companies && (
                      <span style={{ color: "#E33F3F", marginTop: 10 }}>
                          Esse campo é obrigatório
                      </span>
                  )}
              </div>
            )}

            {isController && externalUsers && (
                <div style={{ display: "flex", flexDirection: "column",  marginRight: 10 }}>
                    <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                      lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                        Organismo Verificador
                    </label>
                    {(onlyView || readOnly) && (
                      <input style={{ width: "90%"}} disabled {...register("organism_name")}/>
                    )}
                    {organisms && onlyView === false && readOnly === false && (
                        <Controller control={control} rules={{ required: true }}
                                name="organisms" render={({ field }) => (
                            <Select placeholder="[Selecione]" {...field} styles={{ 
                                control: (styles) => ({ ...styles,
                                        width: "90%", height: 40, marginTop: 10,
                                        backgroundColor: "rgb(246, 246, 246)"}),
                                option: (styles) => { return { ...styles, color: "#31363F" };},}}
                                defaultValue={selOrganisms}
                                options={organisms}
                                isClearable={true}                   
                            />
                            )}
                        />
                    )}
                    {errors.organisms && (
                        <span style={{ color: "#E33F3F", marginTop: 10 }}>
                            Esse campo é obrigatório
                        </span>
                    )}
                </div>
            )}
        </div>
      </form>
      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  );
};
