import { useState } from "react"
import { Modal } from "../Modal"
import { Container } from "./styles"
import Logo from '../../assets/images/logo.svg'

import IconInfo from '../../assets/icons/botao-info.png';
import TextInfo from '../../assets/images/text-info.png';

import IconInfoOds from '../../assets/icons/botao-ods.png';
import TextInfoOds from '../../assets/images/texto-ods.png';
import IconOdsOne from '../../assets/icons/ods-13.png';
import IconOdsTwo from '../../assets/icons/ods-9.png';
import { pointer } from "d3";

type FooterProps = {
    isInternal: boolean;
}

export const Footer = ({ isInternal }: FooterProps) => {

  const [openModal, setOpenModal] = useState(false);

  function handlOpenInfo() {
    setOpenModal(!openModal);
  }

  const [openModalTwo, setOpenModalTwo] = useState (false);

  function handlOpenInfoOds() {
    setOpenModalTwo(!openModalTwo);
  }

  return (
    <Container>
      {isInternal === false && (
        <div className='about-sismiegee'
            onClick={() => handlOpenInfo()}>
          <img src={IconInfo} alt="information" width={100}/>
          {/* <span>Sobre a plataforma</span> */}
        </div>
      )}

      {isInternal === false && (
        <div className='about-ods'
            onClick={() => handlOpenInfoOds()}>
          <img src={IconInfoOds} alt="information-ods" width={100} />
          {/* <span>Sobre a plataforma</span> */}
        </div>
      )}

      <p className='info-sismiegee'>
        <a href='https://sinergiaengenharia.com.br/' target="_blank" rel="noreferrer">Ingee</a> © 2022
      </p>

      <Modal isOpen={openModal} isPayOrTypeClient={true} handleModalVisible={handlOpenInfo} >
          <div style={{marginTop: 10, width: 700, textAlign: "center" }}>
            <img src={TextInfo} alt='text-info' className='text-info' width={700} rel="noopener noreferrer" />
            {/* <div style={{ margin: 15 }}>           
              O Ingee – Sistema para Monitoramento, Relato e Verificação de Impactos de Emissões de Gases de Efeito Estufa 
              é um software desenvolvido pela empresa Sinergia Engenharia de Meio Ambiente Ltda.
              <br/>
              <br/>
              Trata-se de uma ferramenta integrada que permite a inserção de dados da instituição inventariante, o cálculo das 
              emissões e a elaboração automática do Inventário de Gases de Efeito Estufa, em conformidade com a ABNT NBR ISO 14.064, 
              facilitando a auditoria e a rastreabilidade do sistema por uma terceira parte. Além disso, permite análises 
              estratégicas de desempenho da organização, visando a transição para uma economia de baixo carbono.
              <br/>
              <br/>
              Dificuldades para acessar o sistema? Entre em contato com a Sinergia Engenharia!
              <br/>
              E-mail: suporte@ingee.com.br
              <br/>
              Telefone: (41) 3085-8810
            </div> */}
          </div>
      </Modal >

      <Modal isOpen={openModalTwo} isPayOrTypeClient={true} handleModalVisible={handlOpenInfoOds} >
          <div style={{marginTop: 10, width: 750, textAlign: "center"  }}>
            <img src={IconOdsTwo} alt="icon-info-ods-two" width={100} style={{margin: 5, marginLeft: '40px'}}/>
            <img src={IconOdsOne} alt="icon-info-ods-one" width={100} style={{margin: 5}}/>
            <img src={TextInfoOds} alt='text-info-ods' className='text-info-ods' width={700} />
            {/* <div style={{ margin: 15 }}>           
              O Ingee – Sistema para Monitoramento, Relato e Verificação de Impactos de Emissões de Gases de Efeito Estufa 
              é um software desenvolvido pela empresa Sinergia Engenharia de Meio Ambiente Ltda.
              <br/>
              <br/>
              Trata-se de uma ferramenta integrada que permite a inserção de dados da instituição inventariante, o cálculo das 
              emissões e a elaboração automática do Inventário de Gases de Efeito Estufa, em conformidade com a ABNT NBR ISO 14.064, 
              facilitando a auditoria e a rastreabilidade do sistema por uma terceira parte. Além disso, permite análises 
              estratégicas de desempenho da organização, visando a transição para uma economia de baixo carbono.
              <br/>
              <br/>
              Dificuldades para acessar o sistema? Entre em contato com a Sinergia Engenharia!
              <br/>
              E-mail: suporte@ingee.com.br
              <br/>
              Telefone: (41) 3085-8810
            </div> */}
          </div>
      </Modal >
    </Container>
  )
}