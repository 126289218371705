import React, { useEffect, useState } from "react";
import {SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import transportTypeService from "../../../services/transportTypeService";
import { Container } from "./styles";
import { formatPrice14, formatPrice17, formatPrice20, formatPrice8} from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

export const TransportTypeEdit = () => {
  const history = useHistory();

  const location = useLocation();
  const [byYear, setByYear] = useState(false);
  const [years, setYears] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [typeTransport, setTypeTransport] = useState<string | undefined>();
  const [transportTypeSelected, setTransportTypeSelected] = useState<any>({});

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.transportType_id){
      getTransportTypesById(item.transportType_id)
    }else{
      loadYears([])
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps 
  [location]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = async (formData) => {
    if (formData) {
      const itens:any[] = years.map((year: any, index: number) => {
        const emission_factor = formData.year[index].emission_factor.replace(".", "");
        const emission_co2_bio = formData.year[index].emission_co2_bio.replace(".", "");
        const emission_ch4_bio = formData.year[index].emission_ch4_bio.replace(".", "");
        const emission_n2o_bio = formData.year[index].emission_n2o_bio.replace(".", "");
        const consumption_factor = formData.year[index].consumption_factor.replace(".", "");
        const emission_co2_diesel = formData.year[index].emission_co2_diesel.replace(".", "");
        const emission_ch4_diesel = formData.year[index].emission_ch4_diesel.replace(".", "");
        const emission_n2o_diesel = formData.year[index].emission_n2o_diesel.replace(".", "");

        return {
          year: year.year_label,
          emission_factor: emission_factor ? parseFloat(emission_factor.replace(",", ".")) : 0,
          emission_co2_bio: emission_co2_bio ? parseFloat(emission_co2_bio.replace(",", ".")) : 0,
          emission_ch4_bio: emission_ch4_bio ? parseFloat(emission_ch4_bio.replace(",", ".")) : 0,
          emission_n2o_bio: emission_n2o_bio ? parseFloat(emission_n2o_bio.replace(",", ".")) : 0,
          consumption_factor: consumption_factor ? parseFloat(consumption_factor.replace(",", ".")) : 0,
          emission_co2_diesel: emission_co2_diesel ? parseFloat(emission_co2_diesel.replace(",", ".")) : 0,
          emission_ch4_diesel: emission_ch4_diesel ? parseFloat(emission_ch4_diesel.replace(",", ".")) : 0,
          emission_n2o_diesel: emission_n2o_diesel ? parseFloat(emission_n2o_diesel.replace(",", ".")) : 0,
        };
      })

      const emission_factor = formData.emission_factor ? formData.emission_factor.replace(".", "") : "0";
      const emission_co2_bio = formData.emission_co2_bio ? formData.emission_co2_bio.replace(".", "") : "0";
      const emission_ch4_bio = formData.emission_ch4_bio ? formData.emission_ch4_bio.replace(".", "") : "0";
      const emission_n2o_bio = formData.emission_n2o_bio ? formData.emission_n2o_bio.replace(".", "") : "0";
      const consumption_factor = formData.consumption_factor ? formData.consumption_factor.replace(".", "") : "0";
      const emission_co2_diesel = formData.emission_co2_diesel ? formData.emission_co2_diesel.replace(".", "") : "0";
      const emission_ch4_diesel = formData.emission_ch4_diesel ? formData.emission_ch4_diesel.replace(".", "") : "0";
      const emission_n2o_diesel = formData.emission_n2o_diesel ? formData.emission_n2o_diesel.replace(".", "") : "0";

      const newTransportType = {
        name: formData.name,
        type: parseInt(formData.type),
        description: formData.description,
        by_year: formData.by_year ? formData.by_year : false,
        emission_factor: emission_factor ? parseFloat(emission_factor.replace(",", ".")) : 0,
        emission_co2_bio: emission_co2_bio ? parseFloat(emission_co2_bio.replace(",", ".")) : 0,
        emission_ch4_bio: emission_ch4_bio ? parseFloat(emission_ch4_bio.replace(",", ".")) : 0,
        emission_n2o_bio: emission_n2o_bio ? parseFloat(emission_n2o_bio.replace(",", ".")) : 0,
        consumption_factor: consumption_factor ? parseFloat(consumption_factor.replace(",", ".")) : 0,
        emission_co2_diesel: emission_co2_diesel ? parseFloat(emission_co2_diesel.replace(",", ".")) : 0,
        emission_ch4_diesel: emission_ch4_diesel ? parseFloat(emission_ch4_diesel.replace(",", ".")) : 0,
        emission_n2o_diesel: emission_n2o_diesel ? parseFloat(emission_n2o_diesel.replace(",", ".")) : 0,
        years: itens
      };

      if (transportTypeSelected?.id) {
        const result = await transportTypeService.updateById(
          transportTypeSelected.id,
          newTransportType
        );

        if (result && !result.error) {
          history.goBack()

          //reset();
          //setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await transportTypeService.create(newTransportType);

        if (result && !result.error) {
          //history.goBack()

          //reset();
          //setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  async function getTransportTypesById(id: number) {
    const transportType = await transportTypeService.getById(id);

    setValue("name", transportType?.name);
    setValue("by_year", transportType?.by_year);
    setValue("type", transportType?.type.toString());
    setValue("description", transportType?.description);
    setValue("emission_factor", formatPrice20(transportType?.emission_factor, false));
    setValue("emission_co2_bio", formatPrice20(transportType?.emission_co2_bio, false));
    setValue("emission_ch4_bio", formatPrice20(transportType?.emission_ch4_bio, false));
    setValue("emission_n2o_bio", formatPrice20(transportType?.emission_n2o_bio, false));
    setValue("consumption_factor", formatPrice20(transportType?.consumption_factor, false));
    setValue("emission_co2_diesel", formatPrice20(transportType?.emission_co2_diesel, false));
    setValue("emission_ch4_diesel", formatPrice20(transportType?.emission_ch4_diesel, false));
    setValue("emission_n2o_diesel", formatPrice20(transportType?.emission_n2o_diesel, false));

    setByYear(transportType?.by_year ? transportType?.by_year : false)

    loadYears(transportType.years)

    setTypeTransport(transportType?.type.toString())
    setTransportTypeSelected(transportType)
  }

  async function loadYears(itens: any) {
    const years = []

    const yearLength = new Date().getFullYear() - 2006

    for (let i = 0; i < yearLength; i++) {
      const year = 2006 + i 

      const item = getItem(year, itens)

      const emission_factor = item && item.emission_factor ? item.emission_factor : 0
      const emission_co2_bio = item && item.emission_co2_bio ? item.emission_co2_bio : 0
      const emission_ch4_bio = item && item.emission_ch4_bio ? item.emission_ch4_bio : 0
      const emission_n2o_bio = item && item.emission_n2o_bio ? item.emission_n2o_bio : 0
      const consumption_factor = item && item.consumption_factor ? item.consumption_factor : 0
      const emission_co2_diesel = item && item.emission_co2_diesel ? item.emission_co2_diesel : 0
      const emission_ch4_diesel = item && item.emission_ch4_diesel ? item.emission_ch4_diesel : 0
      const emission_n2o_diesel = item && item.emission_n2o_diesel ? item.emission_n2o_diesel : 0

      setValue(`year.${i}.emission_factor`, formatPrice20(Number(emission_factor), false))
      setValue(`year.${i}.emission_co2_bio`, formatPrice20(Number(emission_co2_bio), false))
      setValue(`year.${i}.emission_ch4_bio`, formatPrice20(Number(emission_ch4_bio), false))
      setValue(`year.${i}.emission_n2o_bio`, formatPrice20(Number(emission_n2o_bio), false))
      setValue(`year.${i}.consumption_factor`, formatPrice20(Number(consumption_factor), false))
      setValue(`year.${i}.emission_co2_diesel`, formatPrice20(Number(emission_co2_diesel), false))
      setValue(`year.${i}.emission_ch4_diesel`, formatPrice20(Number(emission_ch4_diesel), false))
      setValue(`year.${i}.emission_n2o_diesel`, formatPrice20(Number(emission_n2o_diesel), false))

      years.push({
        year_label: year,
        emission_factor: emission_factor,
        emission_co2_bio: emission_co2_bio,
        emission_ch4_bio: emission_ch4_bio,
        emission_n2o_bio: emission_n2o_bio,
        consumption_factor: consumption_factor,
        emission_co2_diesel: emission_co2_diesel,
        emission_ch4_diesel: emission_ch4_diesel,
        emission_n2o_diesel: emission_n2o_diesel,
      })
    }

    setYears(years)
  }
    
  function getItem(year?:number, itens?:any) {
    if (itens){
      for (const item of itens){
        if (item.year === year){
          return item
        }
      }
    }

    return undefined
  }
    
  function currency(e: React.FormEvent<HTMLInputElement>) {
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d)(\d{20})$/, "$1,$2");
      value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
      return value;
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Tipos de transporte" />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ overflow: "hidden auto", position: "relative", height: "calc(100vh - 170px)", 
              maxHeight: "calc(100vh - 170px)", marginTop: 30, marginLeft: 10, marginRight: 50}} >
          <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "100%" }}>
            <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Nome
            </label>

            <input style={{width: '95%'}}
                  {...register("name", { required: true })}/>
                        
            {errors.name && (
                <span className='requiredLabel' >Esse campo é obrigatório</span>
            )}
          </div>

          <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 10 }}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "80%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Tipo de transporte
              </label>

              <div style={{ display: 'flex', justifyContent: 'start',  alignItems: 'center', marginTop: 5}}>
                  <input type="radio" value="1" style={{width: 20, marginLeft: 0,  marginRight: 10}}
                          {...register("type", { required: true })}
                          onChange={(event) => { setTypeTransport(event.target.value) }}/> 
                  <span>Metrô / Trem</span> 

                  <input type="radio" value="2" style={{width: 20, marginRight: 10}}
                          {...register("type", { required: true })}
                          onChange={(event) => { setTypeTransport(event.target.value) }}/> 
                  <span>Ônibus</span> 

                  <input type="radio" value="3" style={{width: 20, marginRight: 10}}
                          {...register("type", { required: true })}
                          onChange={(event) => { setTypeTransport(event.target.value) }}/> 
                  <span>Balsa</span> 

                  <input type="radio" value="4" style={{width: 20, marginRight: 10}}
                          {...register("type", { required: true })}
                          onChange={(event) => { setTypeTransport(event.target.value) }}/> 
                  <span>Caminhão</span> 

                  <input type="radio" value="5" style={{width: 20, marginRight: 10}}
                          {...register("type", { required: true })}
                          onChange={(event) => { setTypeTransport(event.target.value) }}/> 
                  <span>Embarcação</span> 
              </div>
                          
              {errors.type && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            {(typeTransport === "1" || typeTransport === "2" || typeTransport === "4" || typeTransport === "5")  && (
              <div style={{ display: "flex", flexDirection: "column", marginLeft: 50, width: "15%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    P/ ano
                </label>
                
                <input style={{ height: "25px", width: "25px" }}
                        type="checkbox" {...register("by_year")}
                        onChange={(event) => {
                          setByYear(event.target.checked)

                          if (event.target.checked){
                            setValue("emission_factor", "");
                          }
                        }}/>
                            
                {errors.by_year && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
            )}
          </div>

          <div style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: 10 }}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Fator de emissão - DEFRA (kg CO₂e / passageiro.km)
              </label>

              {byYear === false && (
                <input style={{width: '95%'}} 
                      {...register("emission_factor")}
                      onChange={(event) => {
                          setValue("emission_factor", currency(event));
                      }}/>
              )}

              {byYear === true && (
                <input style={{width: '95%'}} disabled />
              )}
                          
              {errors.emission_factor && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
            
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Fator de consumo (L/passageiro.km)
              </label>

              {byYear === false && (
                <input style={{width: '95%'}} 
                      {...register("consumption_factor", { required: true })}
                      onChange={(event) => {
                          setValue("consumption_factor", currency(event));
                      }}/>
              )}

              {byYear === true && (
                <input style={{width: '95%'}} disabled />
              )}
                          
              {errors.consumption_factor && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
          </div>

          {(typeTransport === "2" || typeTransport === "3" || typeTransport === "4") && byYear === false && (
            <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 10 }}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Óleo Diesel - Emissão (kg CO₂/p.km)
                </label>

                <input style={{width: '95%'}} 
                      {...register("emission_co2_diesel", { required: true })}
                      onChange={(event) => {
                          setValue("emission_co2_diesel", currency(event));
                      }}/>
                            
                {errors.emission_co2_diesel && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "34%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Óleo Diesel - Emissão (kg CH₄/p.km)
                </label>

                <input style={{width: '95%'}} 
                      {...register("emission_ch4_diesel", { required: true })}
                      onChange={(event) => {
                          setValue("emission_ch4_diesel", currency(event));
                      }}/>
                            
                {errors.emission_ch4_diesel && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Óleo Diesel - Emissão (kg N₂O/p.km)
                </label>

                <input style={{width: '95%'}} 
                      {...register("emission_n2o_diesel", { required: true })}
                      onChange={(event) => {
                          setValue("emission_n2o_diesel", currency(event));
                      }}/>
                            
                {errors.emission_n2o_diesel && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
            </div>
          )}

          {(typeTransport === "5") && byYear === false && (
            <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 10 }}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Diesel - fator de emissão convertido - Emissão (kg CO₂/t.km)
                </label>

                <input style={{width: '95%'}} 
                      {...register("emission_co2_diesel", { required: true })}
                      onChange={(event) => {
                          setValue("emission_co2_diesel", currency(event));
                      }}/>
                            
                {errors.emission_co2_diesel && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "34%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Diesel - fator de emissão convertido - Emissão (kg CH₄/t.km)
                </label>

                <input style={{width: '95%'}} 
                      {...register("emission_ch4_diesel", { required: true })}
                      onChange={(event) => {
                          setValue("emission_ch4_diesel", currency(event));
                      }}/>
                            
                {errors.emission_ch4_diesel && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Diesel - fator de emissão convertido - Emissão (kg N₂O/t.km)
                </label>

                <input style={{width: '95%'}} 
                      {...register("emission_n2o_diesel", { required: true })}
                      onChange={(event) => {
                          setValue("emission_n2o_diesel", currency(event));
                      }}/>
                            
                {errors.emission_n2o_diesel && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
            </div>
          )}

          {(typeTransport === "2" || typeTransport === "4") && byYear === false && (
            <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 10 }}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Biodiesel - Emissão (kg CO₂/p.km)
                </label>

                <input style={{width: '95%'}} 
                      {...register("emission_co2_bio", { required: true })}
                      onChange={(event) => {
                          setValue("emission_co2_bio", currency(event));
                      }}/>
                            
                {errors.emission_co2_bio && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "34%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Biodiesel - Emissão (kg CH₄/p.km)
                </label>

                <input style={{width: '95%'}} 
                      {...register("emission_ch4_bio", { required: true })}
                      onChange={(event) => {
                          setValue("emission_ch4_bio", currency(event));
                      }}/>
                            
                {errors.emission_ch4_bio && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Biodiesel - Emissão (kg N₂O/p.km)
                </label>

                <input style={{width: '95%'}} 
                      {...register("emission_n2o_bio", { required: true })}
                      onChange={(event) => {
                          setValue("emission_n2o_bio", currency(event));
                      }}/>
                            
                {errors.emission_n2o_bio && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
            </div>
          )}

          {(typeTransport === "5") && byYear === false && (
            <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 10 }}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Diesel - fator de emissão original (Defra-UK) - Emissão (kg CO₂/p.km)
                </label>

                <input style={{width: '95%'}} 
                      {...register("emission_co2_bio", { required: true })}
                      onChange={(event) => {
                          setValue("emission_co2_bio", currency(event));
                      }}/>
                            
                {errors.emission_co2_bio && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "34%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Diesel - fator de emissão original (Defra-UK) - Emissão (kg CH₄/t.km)
                </label>

                <input style={{width: '95%'}} 
                      {...register("emission_ch4_bio", { required: true })}
                      onChange={(event) => {
                          setValue("emission_ch4_bio", currency(event));
                      }}/>
                            
                {errors.emission_ch4_bio && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Diesel - fator de emissão original (Defra-UK) - Emissão (kg N₂O/t.km)
                </label>

                <input style={{width: '95%'}} 
                      {...register("emission_n2o_bio", { required: true })}
                      onChange={(event) => {
                          setValue("emission_n2o_bio", currency(event));
                      }}/>
                            
                {errors.emission_n2o_bio && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
            </div>
          )}

          {byYear && (
            <div style={{ display: "flex", flexDirection: "column",  width: "100%", marginTop: 10 }}>
              {years && years.length > 0 && (
                <table style={{ width: "100%", borderCollapse: "collapse", marginTop: 5}} className="table-scope">
                  <thead>
                    <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                      <th className="head-tr-item" style={{width: 200}} rowSpan={2}>
                        {"Ano"}
                      </th>
                      {typeTransport === "1" && (
                        <th className="head-tr-item" style={{width: 200}}>
                          {"Emissåo"}
                        </th>
                      )}
                      {typeTransport === "2" && (
                        <th className="head-tr-item" style={{width: 200}} rowSpan={2}>
                          {"Fator de consumo (L/passageiro.km)"}
                        </th>
                      )}
                      {typeTransport === "2" || typeTransport === "4" && (
                        <th className="head-tr-item" style={{width: 200}} colSpan={3}>
                          {"Óleo Diesel"}
                        </th>
                      )}
                      {typeTransport === "2" || typeTransport === "4" && (
                        <th className="head-tr-item" style={{width: 200}} colSpan={3}>
                          {"Biodiesel"}
                        </th>
                      )}
                      {typeTransport === "5" && (
                        <th className="head-tr-item" style={{width: 200}} colSpan={3}>
                          {"Diesel - fator de emissão original (Defra-UK)"}
                        </th>
                      )}
                      {typeTransport === "5" && (
                        <th className="head-tr-item" style={{width: 200}} colSpan={3}>
                          {"Diesel - fator de emissão convertido"}
                        </th>
                      )}
                    </tr>
                    {typeTransport === "2" && (
                      <tr>
                          <th className="head-tr-item-bottom" style={{width: 200}}>
                            {"Emissão (kg CO₂/p.km)"}
                          </th>
                          <th className="head-tr-item-bottom" style={{width: 200}}>
                            {"Emissão (kg Ch₄/p.km)"}
                          </th>
                          <th className="head-tr-item-bottom" style={{width: 200}}>
                            {"Emissão (kg N₂Op.km)"}
                          </th>
                          <th className="head-tr-item-bottom" style={{width: 200}}>
                            {"Emissão (kg CO₂/p.km)"}
                          </th>
                          <th className="head-tr-item-bottom" style={{width: 200}}>
                            {"Emissão (kg Ch₄/p.km)"}
                          </th>
                          <th className="head-tr-item-bottom" style={{width: 200}}>
                            {"Emissão (kg N₂Op.km)"}
                          </th>
                      </tr>
                      )}
                      {(typeTransport === "4" || typeTransport === "5") && (
                        <tr>
                            <th className="head-tr-item-bottom" style={{width: 200}}>
                              {"Emissão (kg CO₂/t.km)"}
                            </th>
                            <th className="head-tr-item-bottom" style={{width: 200}}>
                              {"Emissão (kg Ch₄/t.km)"}
                            </th>
                            <th className="head-tr-item-bottom" style={{width: 200}}>
                              {"Emissão (kg N₂O/t.km)"}
                            </th>
                            <th className="head-tr-item-bottom" style={{width: 200}}>
                              {"Emissão (kg CO₂/t.km)"}
                            </th>
                            <th className="head-tr-item-bottom" style={{width: 200}}>
                              {"Emissão (kg Ch₄/t.km)"}
                            </th>
                            <th className="head-tr-item-bottom" style={{width: 200}}>
                              {"Emissão (kg N₂O/t.km)"}
                            </th>
                        </tr>
                      )}
                  </thead>
                  <tbody>
                    {years && years.length > 0 && years.map((year: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td style={{ width: "6%", textAlign: "center" }} >
                                <span className="list-text title-text-underline">
                                  {year?.year_label}
                                </span>
                            </td>
                            {typeTransport === "1" && (
                              <td style={{ width: "30%" }} >
                                <input style={{ width: "95%", textAlign: "right" }} className="input-item"
                                  {...register(`year.${index}.emission_factor`, { required: true })}
                                  onChange={(event) => {
                                      setValue(`year.${index}.emission_factor`, currency(event));
                                  }}
                                />
                              </td>
                            )}
                            {typeTransport === "2" && (
                              <td style={{ width: "13%" }} >
                                <input style={{ width: "92%", textAlign: "center" }} className="input-item"
                                  {...register(`year.${index}.consumption_factor`, { required: true })}
                                  onChange={(event) => {
                                      setValue(`year.${index}.consumption_factor`, currency(event));
                                  }}
                                />
                              </td>
                            )}
                            {(typeTransport === "2" || typeTransport === "4" || typeTransport === "5") && (
                              <td style={{ width: "13%" }} >
                                <input style={{ width: "92%", textAlign: "center" }} className="input-item"
                                  {...register(`year.${index}.emission_co2_diesel`, { required: true })}
                                  onChange={(event) => {
                                      setValue(`year.${index}.emission_co2_diesel`, currency(event));
                                  }}
                                />
                              </td>
                            )}
                            {(typeTransport === "2" || typeTransport === "4" || typeTransport === "5") && (
                              <td style={{ width: "13%"  }} >
                                <input style={{ width: "92%", textAlign: "center" }} className="input-item"
                                  {...register(`year.${index}.emission_ch4_diesel`, { required: true })}
                                  onChange={(event) => {
                                      setValue(`year.${index}.emission_ch4_diesel`, currency(event));
                                  }}
                                />
                              </td>
                            )}
                            {(typeTransport === "2" || typeTransport === "4" || typeTransport === "5") && (
                              <td style={{ width: "13%" }} >
                                <input style={{ width: "92%", textAlign: "center" }} className="input-item"
                                  {...register(`year.${index}.emission_n2o_diesel`, { required: true })}
                                  onChange={(event) => {
                                      setValue(`year.${index}.emission_n2o_diesel`, currency(event));
                                  }}
                                />
                              </td>
                            )}
                            {(typeTransport === "2" || typeTransport === "4" || typeTransport === "5") && (
                              <td style={{ width: "13%" }} >
                                <input style={{ width: "92%", textAlign: "center" }} className="input-item"
                                  {...register(`year.${index}.emission_co2_bio`, { required: true })}
                                  onChange={(event) => {
                                      setValue(`year.${index}.emission_co2_bio`, currency(event));
                                  }}
                                />
                              </td>
                            )}
                            {(typeTransport === "2" || typeTransport === "4" || typeTransport === "5") && (
                              <td style={{ width: "13%" }} >
                                <input style={{ width: "92%", textAlign: "center" }} className="input-item"
                                  {...register(`year.${index}.emission_ch4_bio`, { required: true })}
                                  onChange={(event) => {
                                      setValue(`year.${index}.emission_ch4_bio`, currency(event));
                                  }}
                                />
                              </td>
                            )}
                            {(typeTransport === "2" || typeTransport === "4" || typeTransport === "5") && (
                              <td style={{ width: "13%" }} >
                                <input style={{ width: "92%", textAlign: "center" }} className="input-item"
                                  {...register(`year.${index}.emission_n2o_bio`, { required: true })}
                                  onChange={(event) => {
                                      setValue(`year.${index}.emission_n2o_bio`, currency(event));
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          )}
          
          <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "100%" }}>
            <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Descrição
            </label>

            <textarea style={{width: '95%', height: 100}}
                  {...register("description")}/>
                        
            {errors.description && (
                <span className='requiredLabel' >Esse campo é obrigatório</span>
            )}
          </div>
        </div>
      </form>
    </Container>
  );
};
