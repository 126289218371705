import React from 'react'
import { Container } from './styles'
import { EmissionFactorsTable } from './TableResults'
import { useForm } from 'react-hook-form';

type StepThreeProps = {
    register: (value: string) => any;
    setValue: (value: string, e: any) => any;
    twoAnaerobicTreatment: boolean | null;
    setTwoAnaerobicTreatment: (value: boolean) => void;
    handleChange: () => void;
    treatmentFirstTypes: any[];
    handleChangeTreatmentFirst: (id: any) => void;
    handleChangeTreatmentSecond: (id: any) => void;
    factorOxideNitrousFirst: number;
    firstFactorMethaneDbo: number;
    factorOxideNitrousSecond: number;
    secondFactorMethaneDbo: number;
    factorOxideNitrousFinal: number;
    finalFactorMethaneDbo: number;
}

export const StepThree: React.FC<StepThreeProps> = ({
    register, 
    setValue, 
    twoAnaerobicTreatment,
    setTwoAnaerobicTreatment,
    handleChange,
    treatmentFirstTypes,
    handleChangeTreatmentFirst,
    handleChangeTreatmentSecond,
    factorOxideNitrousFirst,
    firstFactorMethaneDbo,
    factorOxideNitrousSecond,
    secondFactorMethaneDbo,
    factorOxideNitrousFinal,
    finalFactorMethaneDbo,
}) => {
    const {watch} = useForm();

  return (
    <Container>
        <div className='div-body'>
            <div className='div-text'>
                <span className='text-statement'>
                    Passo 3 - Tratamentos aplicados aos efluentes domésticos
                </span>
            </div>

            <div className='input-item'>
                <div className="div-item">
                    <span className="prompt-text">
                        São aplicados, sequencialmente, dois tipos de tratamentos ao efluente doméstico gerado?
                    </span>
                </div>

                <div className='choice-one'>
                    <input type="radio" 
                        style={{ width: 20, marginLeft: 5, marginRight: 10 }}
                        value="1"
                        {...register("two_anaerobic_treatments")}
                        checked={twoAnaerobicTreatment === true} 
                        onChange={() => {
                            setValue("two_anaerobic_treatments", true)
                            setTwoAnaerobicTreatment(true)
                            handleChange()
                        }} 
                        />
                    <span>Sim</span>

                    <input type="radio" 
                        style={{ width: 20, marginLeft: 5, marginRight: 10 }}
                        value="0"
                        {...register("two_anaerobic_treatments")}
                        checked={twoAnaerobicTreatment === false} 
                        onChange={() => {
                            setValue("two_anaerobic_treatments", false)
                            setTwoAnaerobicTreatment(false)
                            handleChange()
                        }} 
                        />
                    <span>Não</span>
                </div>
            </div>

            <div className='input-item'>
                <div className="div-item">
                    <span className="prompt-text">
                        Escolha o tipo de tratamento aplicado ao efluente doméstico
                    </span>
                </div>
                <div className="selection-input">
                    {treatmentFirstTypes && (
                        <select
                            style={{ borderRadius: 5, width: "325px", height: 30}}
                            {...register("treatment_first_id")}
                            onChange={(event) => handleChangeTreatmentFirst(event.target.value)}
                        >
                            {treatmentFirstTypes.map((type: any) => (
                            <option value={type.id} key={type.id}>
                                {type.name}
                            </option>
                            ))}
                        </select>
                    )}
                </div>
            </div>

            {twoAnaerobicTreatment === true && (
                <div className='input-item'>
                    <div className="div-item">
                        <span className="prompt-text">
                            Escolha o tipo do segundo tratamento aplicado ao efluente doméstico
                        </span>
                    </div>
                    <div className="selection-input">
                        {treatmentFirstTypes && (
                            <select
                                style={{ borderRadius: 5, width: "325px", height: 30 }}
                                {...register("treatment_second_id")}
                                onChange={(event) => handleChangeTreatmentSecond(event.target.value)}
                            >
                                {treatmentFirstTypes.map((type: any) => (
                                <option value={type.id} key={type.id}>
                                    {type.name}
                                </option>
                                ))}
                            </select>
                        )}
                    </div>
                </div>
            )}

            <EmissionFactorsTable 
                factorOxideNitrousFirst={factorOxideNitrousFirst} 
                firstFactorMethaneDbo={firstFactorMethaneDbo}
                factorOxideNitrousSecond={factorOxideNitrousSecond}
                secondFactorMethaneDbo={secondFactorMethaneDbo}
                factorOxideNitrousFinal={factorOxideNitrousFinal}
                finalFactorMethaneDbo={finalFactorMethaneDbo}
                register={register} />
        </div>
    </Container>
  )
}

