import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../../components/Button/styles";
import { GoBack } from "../../../../components/GoBack";
import { TitleText } from "../../../../components/TitleText";
import useAuth from "../../../../hooks/useAuth";
import inventoryService from "../../../../services/inventoryService";
import { Container } from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

interface Inputs {
  operational_limits: string;
  other_greenhouse_gases: string;
  issues_outside_brazil: string;
  emissions_per_unit: string;
  operational_limits_na: boolean;
  other_greenhouse_gases_na: boolean;
  issues_outside_brazil_na: boolean;
  emissions_per_unit_na: boolean;
}

export const InventoryOperationLimit = () => {
  const history = useHistory();

  const [inventorySelected, setInventorySelected] = useState<any>({});
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [operationalLimitsNa, setOperationalLimitsNa] = useState<boolean | undefined>();
  const [otherGreenhouseGasesNa, setSectorSpecificToolsNa] = useState<boolean | undefined>();
  const [issuesOutsideBrazilNa, setIssuesOutsideBrazilNa] = useState<boolean | undefined>();
  const [emissionsPerUnitNa, setEmissionsPerUnitNa] = useState<boolean | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [anonymize, setAnonymize] = useState(false);
  const [onlyView, setOnlyView] = useState(false);

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.company_id && item.year){
      getInventorysById(item.company_id, item.year)

      setAnonymize(user.pseudo_anonymize)
      setOnlyView(user.profile !== 'sinergia' && user.profile !== 'admin' && user.profile !== 'analyst')
    }else{
      history.push({
        pathname: '/inventario',
      });
    }
  },// eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);

  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const newInventory = {
        operational_limits: operationalLimitsNa ? 'N/A' : formData.operational_limits,
        other_greenhouse_gases: otherGreenhouseGasesNa ? 'N/A' : formData.other_greenhouse_gases,
        issues_outside_brazil: issuesOutsideBrazilNa ? 'N/A' : formData.issues_outside_brazil,
        emissions_per_unit: emissionsPerUnitNa ? 'N/A' : formData.emissions_per_unit
      };

      const result = await inventoryService.updateOperationalLimitId(
        inventorySelected.company.id, inventorySelected.year, newInventory
      );

      if (result && !result.error) {
        //history.goBack()

        //reset();
        //setErrorApi(undefined);
          
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error);
      }
    }
  };

  async function getInventorysById(company_id: number, year: number) {
    const inventory = await inventoryService.getById(company_id, year);

    setValue("operational_limits", inventory?.operational_limits);
    setValue("other_greenhouse_gases", inventory?.other_greenhouse_gases);
    setValue("issues_outside_brazil", inventory?.issues_outside_brazil);
    setValue("emissions_per_unit", inventory?.emissions_per_unit);

    setOperationalLimitsNa(inventory?.operational_limits === 'N/A')
    setSectorSpecificToolsNa(inventory?.other_greenhouse_gases === 'N/A')
    setIssuesOutsideBrazilNa(inventory?.issues_outside_brazil === 'N/A')
    setEmissionsPerUnitNa(inventory?.emissions_per_unit === 'N/A')

    setValue("operational_limits_na", inventory?.operational_limits === 'N/A');
    setValue("other_greenhouse_gases_na", inventory?.other_greenhouse_gases === 'N/A');
    setValue("issues_outside_brazil_na", inventory?.issues_outside_brazil === 'N/A');
    setValue("emissions_per_unit_na", inventory?.emissions_per_unit === 'N/A');

    setInventorySelected(inventory)
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <div style={{ display: "flex", flexDirection: "column"}} >
            <TitleText level={1} title={'Inventário: ' + inventorySelected?.year + ' - ' + inventorySelected?.company?.corporate_name}  />
            <TitleText level={4} title="Limites operacionais"  />
          </div>
          
          {onlyView === false && (
            <Button color="green" size="md" type="submit">
              Salvar
            </Button>
          )}
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 30, marginLeft: 50, marginRight: 50  }} >
          
          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Relate aqui caso tenha incluído outros gases de efeito estufa não contemplados pelo Protocolo de Quioto
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={otherGreenhouseGasesNa || onlyView}
              {...register("other_greenhouse_gases", { required: !otherGreenhouseGasesNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView}  
                {...register("other_greenhouse_gases_na")}
                  onChange={(event) => {
                    if (operationalLimitsNa === false){
                        setValue("other_greenhouse_gases", "N/A")
                    }

                    setSectorSpecificToolsNa(!otherGreenhouseGasesNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica
            </label>
          </div>
                      
          {errors.other_greenhouse_gases && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Emissões fora do Brasil
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={issuesOutsideBrazilNa || onlyView}
              {...register("issues_outside_brazil", { required: !issuesOutsideBrazilNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView} 
                {...register("issues_outside_brazil_na")}
                  onChange={(event) => {
                    if (issuesOutsideBrazilNa === false){
                        setValue("issues_outside_brazil", "N/A")
                    }

                    setIssuesOutsideBrazilNa(!issuesOutsideBrazilNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica
            </label>
          </div>
                      
          {errors.issues_outside_brazil && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Emissões por unidade de negócios
          </label>

          <div style={{ display: "flex", flexDirection: "row"}}>
            <textarea style={{width: '85%', height: 100}} disabled={emissionsPerUnitNa || onlyView}
              {...register("emissions_per_unit", { required: !emissionsPerUnitNa })}/>

            <input style={{ height: "25px", width: "25px" }} type="checkbox" disabled={onlyView} 
                {...register("emissions_per_unit_na")}
                  onChange={(event) => {
                    if (emissionsPerUnitNa === false){
                        setValue("emissions_per_unit", "N/A")
                    }

                    setEmissionsPerUnitNa(!emissionsPerUnitNa)
                  }} />

            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                            opacity: "0.8", marginTop: "10px" }} >
                Não se aplica
            </label>
          </div>
                      
          {errors.emissions_per_unit && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>
      </form>
    </Container>
  );
};
