import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/styles";
import { GoBack } from "../../components/GoBack";
import { ModalDelete } from "../../components/ModalDelete";
import { Pagination } from "../../components/Pagination";
import { TitleText } from "../../components/TitleText";
import useAuth from "../../hooks/useAuth";
import useDebounce from "../../hooks/useDebounce";
import gasService from "../../services/gasService";
import { Container } from "./styles";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

export const Gas = () => {
  const history = useHistory();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [gases, setGases] = useState<any>([]);
  const [gasSelected, setGasSelected] = useState<any>({});

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const searchDebounced = useDebounce(searchValue, 800);

  const [currentPage, setCurrentPage] = useState(1);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  async function getGases(page: number, filter: string) {
    const result = await gasService.getByParams({ limit: 25, page, filter: filter });
    setGases(result);

    setCurrentPage(page)
  }

  useEffect(() => {
    getGases(1, searchDebounced);
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   []);

  useEffect(() => {
    getGases(1, searchDebounced)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [searchDebounced]);

  async function handleDeleteGas() {
    if (gasSelected) {
      const result = await gasService.deleteById(
        gasSelected?.id
      );

      if (result) {
        setOpenModalDelete(false);
        getGases(1, searchDebounced);

        toast.success("Operação realizada com sucesso!")
      }
    }
  }

  async function getGasesById(id: number) {
    const gas = await gasService.getById(id);

    setGasSelected(gas)
  }

  function redirectToEdit(gas?: any) {
    if (gas){
      history.push({
          pathname: '/gas/edicao',
          state: { gas_id: gas.id }
      });
    }else{
      history.push('/gas/edicao')
    }
  }

  function handleOpenModalDelete(gas?: any) {
    setOpenModalDelete(!openModalDelete);

    if (gas) {
      getGasesById(gas.id);
    } else {
      setGasSelected({});
    }
  }

  const goPrev = () => {
    setCurrentPage(currentPage - 1);
    getGases(currentPage - 1, searchDebounced);
  };

  const goNext = () => {
    setCurrentPage(currentPage + 1);
    getGases(currentPage + 1, searchDebounced);
  };

  return (
    <Container>
      <GoBack />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 15,
          justifyContent: "space-between",
        }}
      >
        <TitleText level={1} title="Gases do efeito estufa" />

        <input
          className="input-search"
          placeholder="Pesquisar gases do efeito estufa"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        
        <Button color="blue" size="md" onClick={() => redirectToEdit()}>
          + Novo gás
        </Button>
      </div>

      {gases?.itens &&
          (
            <div style={{ overflow: "hidden auto", position: "relative", marginTop: 10,  height: "calc(100vh - 130px)", 
                maxHeight: "calc(100vh - 130px)"}} >
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                    <th
                      style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                    >
                      {"Nome"}
                    </th>
                    <th
                      style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                    >
                      {"Família"}
                    </th>
                    <th
                      style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                    >
                      {"Referência"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {gases.itens.map((item: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td style={{ padding: "10px", width: "50%" }} >
                            <span className="title-text-underline">
                              {item.name}
                            </span>
                          </td>
                          <td style={{ padding: "10px", width: "20%" }} >
                            <span>
                              {item.family}
                            </span>
                          </td>
                          <td style={{ padding: "10px", width: "20%" }} >
                            <span>
                              {item.reference}
                            </span>
                          </td>
                          <td>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                            <button
                                className="button-edit"
                                onClick={() => redirectToEdit(item)}
                              >
                                Editar
                              </button>
                              <button
                                className="button-delete"
                                style={{ marginRight: 5 }}
                                onClick={() => handleOpenModalDelete(item)}
                              >
                                Excluir
                              </button>
                              
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
                {gases?.itens?.length > 0 && (
                  <Pagination
                    total={gases.total}
                    limit={25}
                    goNext={goNext}
                    goPrev={goPrev}
                    currentPage={currentPage}
                    length={gases.itens.length}
                  />
                )}

            </div>
          )
        }

      {openModalDelete && (
        <ModalDelete
          isOpen={openModalDelete}
          handleModalVisible={handleOpenModalDelete}
          entityName={gasSelected.name}
          handleOk={handleDeleteGas}
        />
      )}
    </Container>
  );
};
