import React, { useEffect, useState } from 'react'
import { GoBack } from '../../../../components/GoBack'
import { TitleText } from '../../../../components/TitleText'
import useAuth from '../../../../hooks/useAuth'
import inventoryService from '../../../../services/inventoryService'
import { Container } from './styles'
import { useLocation, useHistory } from 'react-router-dom'
import { Button } from '../../../../components/Button/styles'
import { SubmitHandler, useForm } from 'react-hook-form'
import { formatPrice3,formatPrice5 } from '../../../../utils/transform'
import scope3Service from '../../../../services/scope3Service'
import { Modal } from '../../../../components/Modal'
import Parser from 'html-react-parser'
import scopeService from '../../../../services/scopeService'
import ReportRecordsCheckbox from '../../components/ReportRecordsCheckbox'
import ScopeEvidenceAttachments from '../../components/ScopeEvidenceAttachments'
import { toast } from 'react-hot-toast'
import { Loading } from '../../../../components/Loading'

export const ScopeCategories3 = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([])
  const [loadingModal, setLoadingModal] = useState(false);
  const [guidelines, setGuidelines] = useState<string | undefined>()
  const [openModal, setOpenModal] = useState(false)
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [title, setTitle] = useState<string | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const location = useLocation()
  const [titles, setTitles] = useState<any>([])
  const [scope1, setScope1] = useState<any>([])
  const [scope2, setScope2] = useState<any>([])
  const [scope3, setScope3] = useState<any>([])
  const [scope4, setScope4] = useState<any>([])
  const [changeScope1, setChangeScope1] = useState<string | undefined>()
  const [changeScope2, setChangeScope2] = useState<string | undefined>()
  const [changeScope3, setChangeScope3] = useState<string | undefined>()
  const [changeScope4, setChangeScope4] = useState<string | undefined>()
  const [emissionTco2e, setEmissionTco2e] = useState<any>()
  const [emissionCo2Bio, setEmissionCo2Bio] = useState<any>()
  const [removalOfBioCo2, setRemovalOfBioCo2] = useState<any>()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-3'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

  async function getGuidelines() {
    const item = await scopeService.getBySlug(3, 'categorias_escopo_3')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<any>()

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      const itens: any[] = []

      if (ignoreFill === false) {
        for (let i = 1; i <= 16; i++) {
          const fields = [
            'co2',
            'ch4',
            'n2o',
            'hfc',
            'hfc23',
            'hfc32',
            'hfc41',
            'hfc125',
            'hfc134',
            'hfc134a',
            'hfc143',
            'hfc143a',
            'hfc152',
            'hfc152a',
            'hfc161',
            'hfc227ea',
            'hfc236cb',
            'hfc236ea',
            'hfc236fa',
            'hfc245ca',
            'hfc245fa',
            'hfc365mfc',
            'hfc4310mee',
            'pfc',
            'pfc14',
            'pfc116',
            'pfc218',
            'pfc318',
            'pfc3110',
            'pfc4112',
            'pfc5114',
            'pfc9118',
            'triPentafluoreto',
            'perfluorociclopropano',
            'sf6',
            'nf3',
            'co2et',
            'co2Bio',
            'removalCo2Bio'
          ]

          for (const field of fields) {
            let label = ''
            let category = ''
            let emission_tgee = 0
            let emission_tco2e = 0

            if (i >= 1 && i <= 4) {
              category = scope1['category_' + i].label
              label = scope1['category_' + i][field]?.label
              emission_tgee = scope1['category_' + i][field]?.emission_tgee
              emission_tco2e = scope1['category_' + i][field]?.emission_tco2e
            } else if (i >= 5 && i <= 8) {
              category = scope2['category_' + i].label
              label = scope2['category_' + i][field]?.label
              emission_tgee = scope2['category_' + i][field]?.emission_tgee
              emission_tco2e = scope2['category_' + i][field]?.emission_tco2e
            } else if (i >= 9 && i <= 12) {
              category = scope3['category_' + i].label
              label = scope3['category_' + i][field]?.label
              emission_tgee = scope3['category_' + i][field]?.emission_tgee
              emission_tco2e = scope3['category_' + i][field]?.emission_tco2e
            } else if (i >= 13 && i <= 16) {
              category = scope4['category_' + i].label
              label = scope4['category_' + i][field]?.label
              emission_tgee = scope4['category_' + i][field]?.emission_tgee
              emission_tco2e = scope4['category_' + i][field]?.emission_tco2e
            }

            itens.push({
              type: field,
              label: label,
              category_number: i,
              category: category,
              emission_tgee: emission_tgee,
              emission_tco2e: emission_tco2e
            })
          }
        }
      }

      const scope = {
        ignore_fill: formData.ignore_fill ? formData.ignore_fill : false,
        emission_tco2e_bio: emissionCo2Bio,
        emission_tco2e: emissionTco2e,
        reason: formData.reason,
        status: 2,
        itens: itens
      }

      const result = await scope3Service.saveThreeCategories(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('ThreeCategories', JSON.stringify(scope));
 
        setErrorApi(undefined)
          
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  const { isCommonUser } = useAuth()

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope3Service.getThreeCategories(company_id, year)
    const fields = [
      'co2',
      'ch4',
      'n2o',
      'hfc',
      'hfc23',
      'hfc32',
      'hfc41',
      'hfc125',
      'hfc134',
      'hfc134a',
      'hfc143',
      'hfc143a',
      'hfc152',
      'hfc152a',
      'hfc161',
      'hfc227ea',
      'hfc236cb',
      'hfc236ea',
      'hfc236fa',
      'hfc245ca',
      'hfc245fa',
      'hfc365mfc',
      'hfc4310mee',
      'pfc',
      'pfc14',
      'pfc116',
      'pfc218',
      'pfc318',
      'pfc3110',
      'pfc4112',
      'pfc5114',
      'pfc9118',
      'triPentafluoreto',
      'perfluorociclopropano',
      'sf6',
      'nf3',
      'co2et',
      'co2Bio',
      'removalCo2Bio'
    ]

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      3,
      'categorias_escopo_3'
    )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('ignore_fill', entity.ignore_fill)

      const titles = []

      titles.push(entity.category_1.label)
      titles.push(entity.category_2.label)
      titles.push(entity.category_3.label)
      titles.push(entity.category_4.label)
      titles.push(entity.category_5.label)
      titles.push(entity.category_6.label)
      titles.push(entity.category_7.label)
      titles.push(entity.category_8.label)
      titles.push(entity.category_9.label)
      titles.push(entity.category_10.label)
      titles.push(entity.category_11.label)
      titles.push(entity.category_12.label)
      titles.push(entity.category_13.label)
      titles.push(entity.category_14.label)
      titles.push(entity.category_15.label)
      titles.push(entity.category_16.label)

      setTitles(titles)

      const scope1 = {
        category_1: entity.category_1,
        category_2: entity.category_2,
        category_3: entity.category_3,
        category_4: entity.category_4
      }

      const scope2 = {
        category_5: entity.category_5,
        category_6: entity.category_6,
        category_7: entity.category_7,
        category_8: entity.category_8
      }

      const scope3 = {
        category_9: entity.category_9,
        category_10: entity.category_10,
        category_11: entity.category_11,
        category_12: entity.category_12
      }

      const scope4 = {
        category_13: entity.category_13,
        category_14: entity.category_14,
        category_15: entity.category_15,
        category_16: entity.category_16
      }

      for (let i = 1; i <= 16; i++) {
        for (const f of fields) {
          const emission_tgee = entity['category_' + i][f]?.emission_tgee

          if (emission_tgee)
            setValue('scope1_category_' + i + '_' + f, formatPrice5(emission_tgee, false))
          if (emission_tgee)
            setValue('scope2_category_' + i + '_' + f, formatPrice5(emission_tgee, false))
          if (emission_tgee)
            setValue('scope3_category_' + i + '_' + f, formatPrice5(emission_tgee, false))
          if (emission_tgee)
            setValue('scope4_category_' + i + '_' + f, formatPrice5(emission_tgee, false))
        }
      }

      setScope1(scope1)
      setScope2(scope2)
      setScope3(scope3)
      setScope4(scope4)

      setScopeSelected(entity)

      setIgnoreFill(entity.ignore_fill ? entity.ignore_fill : false)

      setChangeScope1('load_scope_0_scopes_0')
      setChangeScope2('load_scope_0_scopes_0')
      setChangeScope3('load_scope_0_scopes_0')
      setChangeScope4('load_scope_0_scopes_0')

      calculateTotals(scope1, scope2, scope3, scope4)
    } else {
      setScopeSelected({})

      setIgnoreFill(false)

      setChangeScope1('load_scope_0_scopes_0')
      setChangeScope2('load_scope_0_scopes_0')
      setChangeScope3('load_scope_0_scopes_0')
      setChangeScope4('load_scope_0_scopes_0')
    }

    setInventorySelected(inventory)

    setLoadingModal(false)
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d)(\d{5})$/, '$1,$2')
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')
    return value
  }

  const handleChangeValue = (type: string, field: string, category: number) => {
    let emission_tgee = 0

    if (category >= 1 && category <= 4) {
      emission_tgee = scope1['category_' + category][field].emission_tgee
    } else if (category >= 5 && category <= 8) {
      emission_tgee = scope2['category_' + category][field].emission_tgee
    } else if (category >= 9 && category <= 12) {
      emission_tgee = scope3['category_' + category][field].emission_tgee
    } else if (category >= 13 && category <= 16) {
      emission_tgee = scope4['category_' + category][field].emission_tgee
    }

    async function calculate() {
      const result = await scope3Service.calculateThreeCategories(type, emission_tgee)

      if (result) {
        if (category >= 1 && category <= 4) {
          scope1['category_' + category][field].emission_tco2e = parseFloat(
            result.emission_tco2e
          )

          setChangeScope1('change_scope_item_' + field + '_' + emission_tgee)
        } else if (category >= 5 && category <= 8) {
          scope2['category_' + category][field].emission_tco2e = parseFloat(
            result.emission_tco2e
          )

          setChangeScope2('change_scope_item_' + field + '_' + emission_tgee)
        } else if (category >= 9 && category <= 12) {
          scope3['category_' + category][field].emission_tco2e = parseFloat(
            result.emission_tco2e
          )

          setChangeScope3('change_scope_item_' + field + '_' + emission_tgee)
        } else if (category >= 13 && category <= 16) {
          scope4['category_' + category][field].emission_tco2e = parseFloat(
            result.emission_tco2e
          )

          setChangeScope4('change_scope_item_' + field + '_' + emission_tgee)
        }

        calculateTotals(scope1, scope2, scope3, scope4)
      }
    }

    calculate()
  }

  const calculateTotals = (scope1: any, scope2: any, scope3: any, scope4: any) => {
    for (let i = 1; i <= 16; i++) {
      let itens = undefined
      let hfc_emission_tgee = 0
      let pfc_emission_tgee = 0
      let pfc_emission_tco2e = 0
      let hfc_emission_tco2e = 0

      if (i >= 1 && i <= 4) {
        itens = scope1['category_' + i]
      } else if (i >= 5 && i <= 8) {
        itens = scope2['category_' + i]
      } else if (i >= 9 && i <= 12) {
        itens = scope3['category_' + i]
      } else if (i >= 13 && i <= 16) {
        itens = scope4['category_' + i]
      }

      const hfc23_emission_tgee = itens['hfc23']?.emission_tgee
        ? itens['hfc23']?.emission_tgee
        : 0
      const hfc32_emission_tgee = itens['hfc32']?.emission_tgee
        ? itens['hfc32']?.emission_tgee
        : 0
      const hfc41_emission_tgee = itens['hfc41']?.emission_tgee
        ? itens['hfc41']?.emission_tgee
        : 0
      const hfc125_emission_tgee = itens['hfc125']?.emission_tgee
        ? itens['hfc125']?.emission_tgee
        : 0
      const hfc134_emission_tgee = itens['hfc134']?.emission_tgee
        ? itens['hfc134']?.emission_tgee
        : 0
      const hfc143_emission_tgee = itens['hfc143']?.emission_tgee
        ? itens['hfc143']?.emission_tgee
        : 0
      const hfc152_emission_tgee = itens['hfc152']?.emission_tgee
        ? itens['hfc152']?.emission_tgee
        : 0
      const hfc161_emission_tgee = itens['hfc161']?.emission_tgee
        ? itens['hfc161']?.emission_tgee
        : 0
      const hfc134a_emission_tgee = itens['hfc134a']?.emission_tgee
        ? itens['hfc134a']?.emission_tgee
        : 0
      const hfc143a_emission_tgee = itens['hfc143a']?.emission_tgee
        ? itens['hfc143a']?.emission_tgee
        : 0
      const hfc152a_emission_tgee = itens['hfc152a']?.emission_tgee
        ? itens['hfc152a']?.emission_tgee
        : 0
      const hfc227ea_emission_tgee = itens['hfc227ea']?.emission_tgee
        ? itens['hfc227ea']?.emission_tgee
        : 0
      const hfc236cb_emission_tgee = itens['hfc236cb']?.emission_tgee
        ? itens['hfc236cb']?.emission_tgee
        : 0
      const hfc236ea_emission_tgee = itens['hfc236ea']?.emission_tgee
        ? itens['hfc236ea']?.emission_tgee
        : 0
      const hfc236fa_emission_tgee = itens['hfc236fa']?.emission_tgee
        ? itens['hfc236fa']?.emission_tgee
        : 0
      const hfc245ca_emission_tgee = itens['hfc245ca']?.emission_tgee
        ? itens['hfc245ca']?.emission_tgee
        : 0
      const hfc245fa_emission_tgee = itens['hfc245fa']?.emission_tgee
        ? itens['hfc245fa']?.emission_tgee
        : 0
      const hfc365mfc_emission_tgee = itens['hfc365mfc']?.emission_tgee
        ? itens['hfc365mfc']?.emission_tgee
        : 0
      const hfc4310mee_emission_tgee = itens['hfc4310mee']?.emission_tgee
        ? itens['hfc4310mee']?.emission_tgee
        : 0

      const hfc23_emission_tco2e = itens['hfc23']?.emission_tco2e
        ? itens['hfc23']?.emission_tco2e
        : 0
      const hfc32_emission_tco2e = itens['hfc32']?.emission_tco2e
        ? itens['hfc32']?.emission_tco2e
        : 0
      const hfc41_emission_tco2e = itens['hfc41']?.emission_tco2e
        ? itens['hfc41']?.emission_tco2e
        : 0
      const hfc125_emission_tco2e = itens['hfc125']?.emission_tco2e
        ? itens['hfc125']?.emission_tco2e
        : 0
      const hfc134_emission_tco2e = itens['hfc134']?.emission_tco2e
        ? itens['hfc134']?.emission_tco2e
        : 0
      const hfc143_emission_tco2e = itens['hfc143']?.emission_tco2e
        ? itens['hfc143']?.emission_tco2e
        : 0
      const hfc152_emission_tco2e = itens['hfc152']?.emission_tco2e
        ? itens['hfc152']?.emission_tco2e
        : 0
      const hfc161_emission_tco2e = itens['hfc161']?.emission_tco2e
        ? itens['hfc161']?.emission_tco2e
        : 0
      const hfc134a_emission_tco2e = itens['hfc134a']?.emission_tco2e
        ? itens['hfc134a']?.emission_tco2e
        : 0
      const hfc143a_emission_tco2e = itens['hfc143a']?.emission_tco2e
        ? itens['hfc143a']?.emission_tco2e
        : 0
      const hfc152a_emission_tco2e = itens['hfc152a']?.emission_tco2e
        ? itens['hfc152a']?.emission_tco2e
        : 0
      const hfc227ea_emission_tco2e = itens['hfc227ea']?.emission_tco2e
        ? itens['hfc227ea']?.emission_tco2e
        : 0
      const hfc236cb_emission_tco2e = itens['hfc236cb']?.emission_tco2e
        ? itens['hfc236cb']?.emission_tco2e
        : 0
      const hfc236ea_emission_tco2e = itens['hfc236ea']?.emission_tco2e
        ? itens['hfc236ea']?.emission_tco2e
        : 0
      const hfc236fa_emission_tco2e = itens['hfc236fa']?.emission_tco2e
        ? itens['hfc236fa']?.emission_tco2e
        : 0
      const hfc245ca_emission_tco2e = itens['hfc245ca']?.emission_tco2e
        ? itens['hfc245ca']?.emission_tco2e
        : 0
      const hfc245fa_emission_tco2e = itens['hfc245fa']?.emission_tco2e
        ? itens['hfc245fa']?.emission_tco2e
        : 0
      const hfc365mfc_emission_tco2e = itens['hfc365mfc']?.emission_tco2e
        ? itens['hfc365mfc']?.emission_tco2e
        : 0
      const hfc4310mee_emission_tco2e = itens['hfc4310mee']?.emission_tco2e
        ? itens['hfc4310mee']?.emission_tco2e
        : 0

      const pfc14_emission_tgee = itens['pfc14']?.emission_tgee
        ? itens['pfc14']?.emission_tgee
        : 0
      const pfc116_emission_tgee = itens['pfc116']?.emission_tgee
        ? itens['pfc116']?.emission_tgee
        : 0
      const pfc218_emission_tgee = itens['pfc218']?.emission_tgee
        ? itens['pfc218']?.emission_tgee
        : 0
      const pfc318_emission_tgee = itens['pfc318']?.emission_tgee
        ? itens['pfc318']?.emission_tgee
        : 0
      const pfc3110_emission_tgee = itens['pfc3110']?.emission_tgee
        ? itens['pfc3110']?.emission_tgee
        : 0
      const pfc4112_emission_tgee = itens['pfc4112']?.emission_tgee
        ? itens['pfc4112']?.emission_tgee
        : 0
      const pfc5114_emission_tgee = itens['pfc5114']?.emission_tgee
        ? itens['pfc5114']?.emission_tgee
        : 0
      const pfc9118_emission_tgee = itens['pfc9118']?.emission_tgee
        ? itens['pfc9118']?.emission_tgee
        : 0
      const triPentafluoreto_emission_tgee = itens['triPentafluoreto']?.emission_tgee
        ? itens['triPentafluoreto']?.emission_tgee
        : 0
      const perfluorociclopropano_emission_tgee = itens['perfluorociclopropano']
        ?.emission_tgee
        ? itens['perfluorociclopropano']?.emission_tgee
        : 0

      const pfc14_emission_tco2e = itens['pfc14']?.emission_tco2e
        ? itens['pfc14']?.emission_tco2e
        : 0
      const pfc116_emission_tco2e = itens['pfc116']?.emission_tco2e
        ? itens['pfc116']?.emission_tco2e
        : 0
      const pfc218_emission_tco2e = itens['pfc218']?.emission_tco2e
        ? itens['pfc218']?.emission_tco2e
        : 0
      const pfc318_emission_tco2e = itens['pfc318']?.emission_tco2e
        ? itens['pfc318']?.emission_tco2e
        : 0
      const pfc3110_emission_tco2e = itens['pfc3110']?.emission_tco2e
        ? itens['pfc3110']?.emission_tco2e
        : 0
      const pfc4112_emission_tco2e = itens['pfc4112']?.emission_tco2e
        ? itens['pfc4112']?.emission_tco2e
        : 0
      const pfc5114_emission_tco2e = itens['pfc5114']?.emission_tco2e
        ? itens['pfc5114']?.emission_tco2e
        : 0
      const pfc9118_emission_tco2e = itens['pfc9118']?.emission_tco2e
        ? itens['pfc9118']?.emission_tco2e
        : 0
      const triPentafluoreto_emission_tco2e = itens['triPentafluoreto']?.emission_tco2e
        ? itens['triPentafluoreto']?.emission_tco2e
        : 0
      const perfluorociclopropano_emission_tco2e = itens['perfluorociclopropano']
        ?.emission_tco2e
        ? itens['perfluorociclopropano']?.emission_tco2e
        : 0

      hfc_emission_tgee =
        hfc23_emission_tgee +
        hfc32_emission_tgee +
        hfc41_emission_tgee +
        hfc125_emission_tgee +
        hfc134_emission_tgee +
        hfc143_emission_tgee +
        hfc152_emission_tgee +
        hfc161_emission_tgee +
        hfc134a_emission_tgee +
        hfc143a_emission_tgee +
        hfc152a_emission_tgee +
        hfc227ea_emission_tgee +
        hfc236cb_emission_tgee +
        hfc236ea_emission_tgee +
        hfc236fa_emission_tgee +
        hfc245ca_emission_tgee +
        hfc245fa_emission_tgee +
        hfc365mfc_emission_tgee +
        hfc4310mee_emission_tgee

      hfc_emission_tco2e =
        hfc23_emission_tco2e +
        hfc32_emission_tco2e +
        hfc41_emission_tco2e +
        hfc125_emission_tco2e +
        hfc134_emission_tco2e +
        hfc143_emission_tco2e +
        hfc152_emission_tco2e +
        hfc161_emission_tco2e +
        hfc134a_emission_tco2e +
        hfc143a_emission_tco2e +
        hfc152a_emission_tco2e +
        hfc227ea_emission_tco2e +
        hfc236ea_emission_tco2e +
        hfc236cb_emission_tco2e +
        hfc236fa_emission_tco2e +
        hfc245ca_emission_tco2e +
        hfc245fa_emission_tco2e +
        hfc365mfc_emission_tco2e +
        hfc4310mee_emission_tco2e

      pfc_emission_tgee =
        pfc14_emission_tgee +
        pfc116_emission_tgee +
        pfc218_emission_tgee +
        pfc318_emission_tgee +
        pfc4112_emission_tgee +
        pfc3110_emission_tgee +
        pfc5114_emission_tgee +
        pfc9118_emission_tgee +
        triPentafluoreto_emission_tgee +
        perfluorociclopropano_emission_tgee

      pfc_emission_tco2e =
        pfc14_emission_tco2e +
        pfc116_emission_tco2e +
        pfc218_emission_tco2e +
        pfc318_emission_tco2e +
        pfc4112_emission_tco2e +
        pfc3110_emission_tco2e +
        pfc5114_emission_tco2e +
        pfc9118_emission_tco2e +
        triPentafluoreto_emission_tco2e +
        perfluorociclopropano_emission_tco2e

      const fields = ['co2', 'ch4', 'n2o', 'pfc', 'sf6', 'nf3', 'hfc']

      if (i >= 1 && i <= 4) {
        scope1['category_' + i]['hfc'].emission_tgee = hfc_emission_tgee
        scope1['category_' + i]['pfc'].emission_tgee = pfc_emission_tgee
        scope1['category_' + i]['hfc'].emission_tco2e = hfc_emission_tco2e
        scope1['category_' + i]['pfc'].emission_tco2e = pfc_emission_tco2e

        let emission_tco2e = 0

        for (const field of fields) {
          emission_tco2e =
            emission_tco2e +
            (scope1['category_' + i][field]?.emission_tco2e
              ? scope1['category_' + i][field]?.emission_tco2e
              : 0)
        }

        scope1['category_' + i]['co2et'].emission_tco2e = emission_tco2e

        setScope1(scope1)
        setChangeScope1('change_scope_item_totals_hfc_' + hfc_emission_tgee)
      } else if (i >= 5 && i <= 8) {
        scope2['category_' + i]['hfc'].emission_tgee = hfc_emission_tgee
        scope2['category_' + i]['pfc'].emission_tgee = pfc_emission_tgee
        scope2['category_' + i]['hfc'].emission_tco2e = hfc_emission_tco2e
        scope2['category_' + i]['pfc'].emission_tco2e = pfc_emission_tco2e

        let emission_tco2e = 0

        for (const field of fields) {
          emission_tco2e =
            emission_tco2e +
            (scope2['category_' + i][field]?.emission_tco2e
              ? scope2['category_' + i][field]?.emission_tco2e
              : 0)
        }

        scope2['category_' + i]['co2et'].emission_tco2e = emission_tco2e

        setScope2(scope2)
        setChangeScope2('change_scope_item_totals_hfc_' + hfc_emission_tgee)
      } else if (i >= 9 && i <= 12) {
        scope3['category_' + i]['hfc'].emission_tgee = hfc_emission_tgee
        scope3['category_' + i]['pfc'].emission_tgee = pfc_emission_tgee
        scope3['category_' + i]['hfc'].emission_tco2e = hfc_emission_tco2e
        scope3['category_' + i]['pfc'].emission_tco2e = pfc_emission_tco2e

        let emission_tco2e = 0

        for (const field of fields) {
          emission_tco2e =
            emission_tco2e +
            (scope3['category_' + i][field]?.emission_tco2e
              ? scope3['category_' + i][field]?.emission_tco2e
              : 0)
        }

        scope3['category_' + i]['co2et'].emission_tco2e = emission_tco2e

        setScope3(scope3)
        setChangeScope3('change_scope_item_totals_hfc_' + hfc_emission_tgee)
      } else if (i >= 13 && i <= 16) {
        scope4['category_' + i]['hfc'].emission_tgee = hfc_emission_tgee
        scope4['category_' + i]['pfc'].emission_tgee = pfc_emission_tgee
        scope4['category_' + i]['hfc'].emission_tco2e = hfc_emission_tco2e
        scope4['category_' + i]['pfc'].emission_tco2e = pfc_emission_tco2e

        let emission_tco2e = 0

        for (const field of fields) {
          emission_tco2e =
            emission_tco2e +
            (scope4['category_' + i][field]?.emission_tco2e
              ? scope4['category_' + i][field]?.emission_tco2e
              : 0)
        }

        scope4['category_' + i]['co2et'].emission_tco2e = emission_tco2e

        setScope4(scope4)
        setChangeScope4('change_scope_item_totals_hfc_' + hfc_emission_tgee)
      }
    }

    calculateTotals2(scope1, scope2, scope3, scope4)
  }

  const calculateTotals2 = (scope1: any, scope2: any, scope3: any, scope4: any) => {
    let emissionTco2e = 0
    let emissionCo2Bio = 0
    let removalOfBioCo2 = 0

    for (let i = 1; i <= 16; i++) {
      let itens = undefined

      if (i >= 1 && i <= 4) {
        itens = scope1['category_' + i]
      } else if (i >= 5 && i <= 8) {
        itens = scope2['category_' + i]
      } else if (i >= 9 && i <= 12) {
        itens = scope3['category_' + i]
      } else if (i >= 13 && i <= 16) {
        itens = scope4['category_' + i]
      }

      const co2et = itens?.co2et.emission_tco2e ? itens?.co2et?.emission_tco2e : 0
      const co2Bio = itens?.co2Bio?.emission_tgee ? itens?.co2Bio?.emission_tgee : 0
      const removalCo2Bio = itens?.removalCo2Bio?.emission_tgee
        ? itens?.removalCo2Bio?.emission_tgee
        : 0

      emissionTco2e = emissionTco2e + co2et
      emissionCo2Bio = emissionCo2Bio + co2Bio
      removalOfBioCo2 = removalOfBioCo2 + removalCo2Bio
    }

    setEmissionTco2e(emissionTco2e)
    setEmissionCo2Bio(emissionCo2Bio)
    setRemovalOfBioCo2(removalOfBioCo2)
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const downloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'categorias_escopo_3'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const attachmentChangeHandler = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 3,
        name: 'categorias_escopo_3',
        file: base64,
        filename: filename,
        second_name: ''
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'categorias_escopo_3'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 03 - ' + title}  />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill === true && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 210px)',
            maxHeight: 'calc(100vh - 210px)'
          }}
        >
          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 5,
                marginRight: 30,
                marginLeft: 10
              }}
            >
              <span
                style={{
                  textAlign: 'start',
                  fontSize: 16,
                  fontWeight: 'bold',
                  marginTop: 5,
                  width: '98%'
                }}
              >
                Emissões de Escopo 3 por categorias
              </span>
            </div>
          )}

          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeScope1 && titles && titles.length > 0 && (
                  <table
                    style={{
                      maxWidth: '98%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th
                          className="head-tr-item"
                          style={{ width: '20%' }}
                          rowSpan={3}
                        ></th>
                        <th className="head-tr-item" style={{ width: '20%' }} colSpan={2}>
                          {'Categoria 1'}
                        </th>
                        <th className="head-tr-item" style={{ width: '20%' }} colSpan={2}>
                          {'Categoria 2'}
                        </th>
                        <th className="head-tr-item" style={{ width: '20%' }} colSpan={2}>
                          {'Categoria 3'}
                        </th>
                        <th className="head-tr-item" style={{ width: '20%' }} colSpan={2}>
                          {'Categoria 4'}
                        </th>
                      </tr>
                      <tr>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: '20%' }}
                          colSpan={2}
                        >
                          {titles[0]}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: '20%' }}
                          colSpan={2}
                        >
                          {titles[1]}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: '20%' }}
                          colSpan={2}
                        >
                          {titles[2]}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: '20%' }}
                          colSpan={2}
                        >
                          {titles[3]}
                        </th>
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t GEE'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t CO₂e'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t GEE'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t CO₂e'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t GEE'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t CO₂e'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t GEE'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t CO₂e'}
                        </th>
                      </tr>
                    </thead>
                    {changeScope1 && scope1 && (
                      <tbody>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.co2.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_co2')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.co2.emission_tgee = value

                                setValue('scope1_category_1_co2', formatValue)

                                handleChangeValue(scope1.category_1.co2.type, 'co2', 1)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_1.co2.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_co2')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.co2.emission_tgee = value

                                setValue('scope1_category_2_co2', formatValue)

                                handleChangeValue(scope1.category_1.co2.type, 'co2', 2)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_2.co2.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_co2')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.co2.emission_tgee = value

                                setValue('scope1_category_3_co2', formatValue)

                                handleChangeValue(scope1.category_1.co2.type, 'co2', 3)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_3.co2.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_co2')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.co2.emission_tgee = value

                                setValue('scope1_category_4_co2', formatValue)

                                handleChangeValue(scope1.category_1.co2.type, 'co2', 4)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_4.co2.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.ch4.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_ch4')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.ch4.emission_tgee = value

                                setValue('scope1_category_1_ch4', formatValue)

                                handleChangeValue(scope1.category_1.ch4.type, 'ch4', 1)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_1.ch4.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_ch4')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.ch4.emission_tgee = value

                                setValue('scope1_category_2_ch4', formatValue)

                                handleChangeValue(scope1.category_1.ch4.type, 'ch4', 2)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_2.ch4.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_ch4')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.ch4.emission_tgee = value

                                setValue('scope1_category_3_ch4', formatValue)

                                handleChangeValue(scope1.category_1.ch4.type, 'ch4', 3)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_3.ch4.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_ch4')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.ch4.emission_tgee = value

                                setValue('scope1_category_4_ch4', formatValue)

                                handleChangeValue(scope1.category_1.ch4.type, 'ch4', 4)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_4.ch4.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.n2o.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_n2o')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.n2o.emission_tgee = value

                                setValue('scope1_category_1_n2o', formatValue)

                                handleChangeValue(scope1.category_1.n2o.type, 'n2o', 1)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_1.n2o.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_n2o')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.n2o.emission_tgee = value

                                setValue('scope1_category_2_n2o', formatValue)

                                handleChangeValue(scope1.category_1.n2o.type, 'n2o', 2)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_2.n2o.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_n2o')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.n2o.emission_tgee = value

                                setValue('scope1_category_3_n2o', formatValue)

                                handleChangeValue(scope1.category_1.n2o.type, 'n2o', 3)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_3.n2o.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_n2o')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.n2o.emission_tgee = value

                                setValue('scope1_category_4_n2o', formatValue)

                                handleChangeValue(scope1.category_1.n2o.type, 'n2o', 4)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_4.n2o.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr style={{ height: 40 }}>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_1.hfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_1.hfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_2.hfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_2.hfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_3.hfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_3.hfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_4.hfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_4.hfc.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc23.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc23')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc23.emission_tgee = value

                                setValue('scope1_category_1_hfc23', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc23.type,
                                  'hfc23',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc23.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc23')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc23.emission_tgee = value

                                setValue('scope1_category_2_hfc23', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc23.type,
                                  'hfc23',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc23.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc23')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc23.emission_tgee = value

                                setValue('scope1_category_3_hfc23', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc23.type,
                                  'hfc23',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc23.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc23')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc23.emission_tgee = value

                                setValue('scope1_category_4_hfc23', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc23.type,
                                  'hfc23',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc23.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc32.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc32')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc32.emission_tgee = value

                                setValue('scope1_category_1_hfc32', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc32.type,
                                  'hfc32',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc32.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc32')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc32.emission_tgee = value

                                setValue('scope1_category_2_hfc32', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc32.type,
                                  'hfc32',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc32.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc32')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc32.emission_tgee = value

                                setValue('scope1_category_3_hfc32', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc32.type,
                                  'hfc32',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc32.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc32')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc32.emission_tgee = value

                                setValue('scope1_category_4_hfc32', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc32.type,
                                  'hfc32',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc32.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc41.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc41')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc41.emission_tgee = value

                                setValue('scope1_category_1_hfc41', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc41.type,
                                  'hfc41',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc41.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc41')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc41.emission_tgee = value

                                setValue('scope1_category_2_hfc41', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc41.type,
                                  'hfc41',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc41.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc41')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc41.emission_tgee = value

                                setValue('scope1_category_3_hfc41', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc41.type,
                                  'hfc41',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc41.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc41')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc41.emission_tgee = value

                                setValue('scope1_category_4_hfc41', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc41.type,
                                  'hfc41',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc41.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc125.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc125')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc125.emission_tgee = value

                                setValue('scope1_category_1_hfc125', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc125.type,
                                  'hfc125',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc125.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc125')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc125.emission_tgee = value

                                setValue('scope1_category_2_hfc125', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc125.type,
                                  'hfc125',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc125.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc125')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc125.emission_tgee = value

                                setValue('scope1_category_3_hfc125', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc125.type,
                                  'hfc125',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc125.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc125')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc125.emission_tgee = value

                                setValue('scope1_category_4_hfc125', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc125.type,
                                  'hfc125',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc125.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc134.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc134')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc134.emission_tgee = value

                                setValue('scope1_category_1_hfc134', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc134.type,
                                  'hfc134',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc134.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc134')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc134.emission_tgee = value

                                setValue('scope1_category_2_hfc134', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc134.type,
                                  'hfc134',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc134.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc134')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc134.emission_tgee = value

                                setValue('scope1_category_3_hfc134', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc134.type,
                                  'hfc134',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc134.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc134')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc134.emission_tgee = value

                                setValue('scope1_category_4_hfc134', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc134.type,
                                  'hfc134',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc134.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc134a.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc134a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc134a.emission_tgee = value

                                setValue('scope1_category_1_hfc134a', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc134a.type,
                                  'hfc134a',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc134a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc134a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc134a.emission_tgee = value

                                setValue('scope1_category_2_hfc134a', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc134a.type,
                                  'hfc134a',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc134a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc134a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc134a.emission_tgee = value

                                setValue('scope1_category_3_hfc134a', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc134a.type,
                                  'hfc134a',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc134a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc134a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc134a.emission_tgee = value

                                setValue('scope1_category_4_hfc134a', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc134a.type,
                                  'hfc134a',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc134a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc143.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc143')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc143.emission_tgee = value

                                setValue('scope1_category_1_hfc143', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc143.type,
                                  'hfc143',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc143.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc143')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc143.emission_tgee = value

                                setValue('scope1_category_2_hfc143', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc143.type,
                                  'hfc143',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc143.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc143')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc143.emission_tgee = value

                                setValue('scope1_category_3_hfc143', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc143.type,
                                  'hfc143',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc143.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc143')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc143.emission_tgee = value

                                setValue('scope1_category_4_hfc143', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc143.type,
                                  'hfc143',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc143.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc143a.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc143a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc143a.emission_tgee = value

                                setValue('scope1_category_1_hfc143a', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc143a.type,
                                  'hfc143a',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc143a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc143a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc143a.emission_tgee = value

                                setValue('scope1_category_2_hfc143a', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc143a.type,
                                  'hfc143a',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc143a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc143a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc143a.emission_tgee = value

                                setValue('scope1_category_3_hfc143a', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc143a.type,
                                  'hfc143a',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc143a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc143a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc143a.emission_tgee = value

                                setValue('scope1_category_4_hfc143a', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc143a.type,
                                  'hfc143a',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc143a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc152.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc152')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc152.emission_tgee = value

                                setValue('scope1_category_1_hfc152', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc152.type,
                                  'hfc152',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc152.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc152')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc152.emission_tgee = value

                                setValue('scope1_category_2_hfc152', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc152.type,
                                  'hfc152',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc152.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc152')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc152.emission_tgee = value

                                setValue('scope1_category_3_hfc152', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc152.type,
                                  'hfc152',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc152.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc152')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc152.emission_tgee = value

                                setValue('scope1_category_4_hfc152', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc152.type,
                                  'hfc152',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc152.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc152a.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc152a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc152a.emission_tgee = value

                                setValue('scope1_category_1_hfc152a', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc152a.type,
                                  'hfc152a',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc152a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc152a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc152a.emission_tgee = value

                                setValue('scope1_category_2_hfc152a', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc152a.type,
                                  'hfc152a',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc152a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc152a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc152a.emission_tgee = value

                                setValue('scope1_category_3_hfc152a', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc152a.type,
                                  'hfc152a',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc152a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc152a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc152a.emission_tgee = value

                                setValue('scope1_category_4_hfc152a', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc152a.type,
                                  'hfc152a',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc152a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc161.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc161')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc161.emission_tgee = value

                                setValue('scope1_category_1_hfc161', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc161.type,
                                  'hfc161',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc161.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc161')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc161.emission_tgee = value

                                setValue('scope1_category_2_hfc161', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc161.type,
                                  'hfc161',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc161.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc161')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc161.emission_tgee = value

                                setValue('scope1_category_3_hfc161', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc161.type,
                                  'hfc161',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc161.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc161')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc161.emission_tgee = value

                                setValue('scope1_category_4_hfc161', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc161.type,
                                  'hfc161',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc161.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc227ea.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc227ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc227ea.emission_tgee = value

                                setValue('scope1_category_1_hfc227ea', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc227ea.type,
                                  'hfc227ea',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc227ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc227ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc227ea.emission_tgee = value

                                setValue('scope1_category_2_hfc227ea', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc227ea.type,
                                  'hfc227ea',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc227ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc227ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc227ea.emission_tgee = value

                                setValue('scope1_category_3_hfc227ea', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc227ea.type,
                                  'hfc227ea',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc227ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc227ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc227ea.emission_tgee = value

                                setValue('scope1_category_4_hfc227ea', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc227ea.type,
                                  'hfc227ea',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc227ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc236cb.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc236cb')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc236cb.emission_tgee = value

                                setValue('scope1_category_1_hfc236cb', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc236cb.type,
                                  'hfc236cb',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc236cb.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc236cb')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc236cb.emission_tgee = value

                                setValue('scope1_category_2_hfc236cb', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc236cb.type,
                                  'hfc236cb',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc236cb.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc236cb')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc236cb.emission_tgee = value

                                setValue('scope1_category_3_hfc236cb', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc236cb.type,
                                  'hfc236cb',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc236cb.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc236cb')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc236cb.emission_tgee = value

                                setValue('scope1_category_4_hfc236cb', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc236cb.type,
                                  'hfc236cb',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc236cb.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc236ea.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc236ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc236ea.emission_tgee = value

                                setValue('scope1_category_1_hfc236ea', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc236ea.type,
                                  'hfc236ea',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc236ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc236ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc236ea.emission_tgee = value

                                setValue('scope1_category_2_hfc236ea', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc236ea.type,
                                  'hfc236ea',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc236ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc236ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc236ea.emission_tgee = value

                                setValue('scope1_category_3_hfc236ea', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc236ea.type,
                                  'hfc236ea',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc236ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc236ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc236ea.emission_tgee = value

                                setValue('scope1_category_4_hfc236ea', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc236ea.type,
                                  'hfc236ea',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc236ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc236fa.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc236fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc236fa.emission_tgee = value

                                setValue('scope1_category_1_hfc236fa', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc236fa.type,
                                  'hfc236fa',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc236fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc236fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc236fa.emission_tgee = value

                                setValue('scope1_category_2_hfc236fa', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc236fa.type,
                                  'hfc236fa',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc236fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc236fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc236fa.emission_tgee = value

                                setValue('scope1_category_3_hfc236fa', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc236fa.type,
                                  'hfc236fa',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc236fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc236fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc236fa.emission_tgee = value

                                setValue('scope1_category_4_hfc236fa', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc236fa.type,
                                  'hfc236fa',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc236fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc245ca.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc245ca')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc245ca.emission_tgee = value

                                setValue('scope1_category_1_hfc245ca', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc245ca.type,
                                  'hfc245ca',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc245ca.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc245ca')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc245ca.emission_tgee = value

                                setValue('scope1_category_2_hfc245ca', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc245ca.type,
                                  'hfc245ca',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc245ca.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc245ca')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc245ca.emission_tgee = value

                                setValue('scope1_category_3_hfc245ca', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc245ca.type,
                                  'hfc245ca',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc245ca.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc245ca')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc245ca.emission_tgee = value

                                setValue('scope1_category_4_hfc245ca', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc245ca.type,
                                  'hfc245ca',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc245ca.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc245fa.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc245fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc245fa.emission_tgee = value

                                setValue('scope1_category_1_hfc245fa', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc245fa.type,
                                  'hfc245fa',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc245fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc245fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc245fa.emission_tgee = value

                                setValue('scope1_category_2_hfc245fa', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc245fa.type,
                                  'hfc245fa',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc245fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc245fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc245fa.emission_tgee = value

                                setValue('scope1_category_3_hfc245fa', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc245fa.type,
                                  'hfc245fa',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc245fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc245fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc245fa.emission_tgee = value

                                setValue('scope1_category_4_hfc245fa', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc245fa.type,
                                  'hfc245fa',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc245fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc365mfc.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc365mfc')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc365mfc.emission_tgee = value

                                setValue('scope1_category_1_hfc365mfc', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc365mfc.type,
                                  'hfc365mfc',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc365mfc.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc365mfc')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc365mfc.emission_tgee = value

                                setValue('scope1_category_2_hfc365mfc', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc365mfc.type,
                                  'hfc365mfc',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc365mfc.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc365mfc')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc365mfc.emission_tgee = value

                                setValue('scope1_category_3_hfc365mfc', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc365mfc.type,
                                  'hfc365mfc',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc365mfc.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc365mfc')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc365mfc.emission_tgee = value

                                setValue('scope1_category_4_hfc365mfc', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc365mfc.type,
                                  'hfc365mfc',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc365mfc.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.hfc4310mee.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_hfc4310mee')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.hfc4310mee.emission_tgee = value

                                setValue('scope1_category_1_hfc4310mee', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc4310mee.type,
                                  'hfc4310mee',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.hfc4310mee.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_hfc4310mee')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.hfc4310mee.emission_tgee = value

                                setValue('scope1_category_2_hfc4310mee', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc4310mee.type,
                                  'hfc4310mee',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.hfc4310mee.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_hfc4310mee')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.hfc4310mee.emission_tgee = value

                                setValue('scope1_category_3_hfc4310mee', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc4310mee.type,
                                  'hfc4310mee',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.hfc4310mee.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_hfc4310mee')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.hfc4310mee.emission_tgee = value

                                setValue('scope1_category_4_hfc4310mee', formatValue)

                                handleChangeValue(
                                  scope1.category_1.hfc4310mee.type,
                                  'hfc4310mee',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.hfc4310mee.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr style={{ height: 40 }}>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.pfc.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_1.pfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_1.pfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_2.pfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_2.pfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_3.pfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_3.pfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_4.pfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_4.pfc.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.pfc14.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_pfc14')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.pfc14.emission_tgee = value

                                setValue('scope1_category_1_pfc14', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc14.type,
                                  'pfc14',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.pfc14.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_pfc14')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.pfc14.emission_tgee = value

                                setValue('scope1_category_2_pfc14', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc14.type,
                                  'pfc14',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.pfc14.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_pfc14')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.pfc14.emission_tgee = value

                                setValue('scope1_category_3_pfc14', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc14.type,
                                  'pfc14',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.pfc14.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_pfc14')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.pfc14.emission_tgee = value

                                setValue('scope1_category_4_pfc14', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc14.type,
                                  'pfc14',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.pfc14.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.pfc116.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_pfc116')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.pfc116.emission_tgee = value

                                setValue('scope1_category_1_pfc116', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc116.type,
                                  'pfc116',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.pfc116.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_pfc116')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.pfc116.emission_tgee = value

                                setValue('scope1_category_2_pfc116', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc116.type,
                                  'pfc116',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.pfc116.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_pfc116')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.pfc116.emission_tgee = value

                                setValue('scope1_category_3_pfc116', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc116.type,
                                  'pfc116',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.pfc116.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_pfc116')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.pfc116.emission_tgee = value

                                setValue('scope1_category_4_pfc116', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc116.type,
                                  'pfc116',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.pfc116.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.pfc218.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_pfc218')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.pfc218.emission_tgee = value

                                setValue('scope1_category_1_pfc218', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc218.type,
                                  'pfc218',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.pfc218.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_pfc218')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.pfc218.emission_tgee = value

                                setValue('scope1_category_2_pfc218', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc218.type,
                                  'pfc218',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.pfc218.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_pfc218')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.pfc218.emission_tgee = value

                                setValue('scope1_category_3_pfc218', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc218.type,
                                  'pfc218',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.pfc218.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_pfc218')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.pfc218.emission_tgee = value

                                setValue('scope1_category_4_pfc218', formatValue)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.pfc218.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.pfc318.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_pfc318')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.pfc318.emission_tgee = value

                                setValue('scope1_category_1_pfc318', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc318.type,
                                  'pfc318',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.pfc318.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_pfc318')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.pfc318.emission_tgee = value

                                setValue('scope1_category_2_pfc318', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc318.type,
                                  'pfc318',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.pfc318.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_pfc318')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.pfc318.emission_tgee = value

                                setValue('scope1_category_3_pfc318', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc318.type,
                                  'pfc318',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.pfc318.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_pfc318')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.pfc318.emission_tgee = value

                                setValue('scope1_category_4_pfc318', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc318.type,
                                  'pfc318',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.pfc318.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.pfc3110.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_pfc3110')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.pfc3110.emission_tgee = value

                                setValue('scope1_category_1_pfc3110', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc3110.type,
                                  'pfc3110',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.pfc3110.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_pfc3110')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.pfc3110.emission_tgee = value

                                setValue('scope1_category_2_pfc3110', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc3110.type,
                                  'pfc3110',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.pfc3110.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_pfc3110')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.pfc3110.emission_tgee = value

                                setValue('scope1_category_3_pfc3110', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc3110.type,
                                  'pfc3110',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.pfc3110.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_pfc3110')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.pfc3110.emission_tgee = value

                                setValue('scope1_category_4_pfc3110', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc3110.type,
                                  'pfc3110',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.pfc3110.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.pfc4112.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_pfc4112')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.pfc4112.emission_tgee = value

                                setValue('scope1_category_1_pfc4112', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc4112.type,
                                  'pfc4112',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.pfc4112.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_pfc4112')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.pfc4112.emission_tgee = value

                                setValue('scope1_category_2_pfc4112', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc4112.type,
                                  'pfc4112',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.pfc4112.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_pfc4112')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.pfc4112.emission_tgee = value

                                setValue('scope1_category_3_pfc4112', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc4112.type,
                                  'pfc4112',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.pfc4112.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_pfc4112')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.pfc4112.emission_tgee = value

                                setValue('scope1_category_4_pfc4112', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc4112.type,
                                  'pfc4112',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.pfc4112.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.pfc5114.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_pfc5114')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.pfc5114.emission_tgee = value

                                setValue('scope1_category_1_pfc5114', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc5114.type,
                                  'pfc5114',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.pfc5114.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_pfc5114')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.pfc5114.emission_tgee = value

                                setValue('scope1_category_2_pfc5114', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc5114.type,
                                  'pfc5114',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.pfc5114.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_pfc5114')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.pfc5114.emission_tgee = value

                                setValue('scope1_category_3_pfc5114', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc5114.type,
                                  'pfc5114',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.pfc5114.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_pfc5114')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.pfc5114.emission_tgee = value

                                setValue('scope1_category_4_pfc5114', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc5114.type,
                                  'pfc5114',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.pfc5114.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.pfc9118.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_pfc9118')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.pfc9118.emission_tgee = value

                                setValue('scope1_category_1_pfc9118', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc9118.type,
                                  'pfc9118',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.pfc9118.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_pfc9118')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.pfc9118.emission_tgee = value

                                setValue('scope1_category_2_pfc9118', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc9118.type,
                                  'pfc9118',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.pfc9118.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_pfc9118')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.pfc9118.emission_tgee = value

                                setValue('scope1_category_3_pfc9118', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc9118.type,
                                  'pfc9118',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.pfc9118.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_pfc9118')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.pfc9118.emission_tgee = value

                                setValue('scope1_category_4_pfc9118', formatValue)

                                handleChangeValue(
                                  scope1.category_1.pfc9118.type,
                                  'pfc9118',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.pfc9118.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.triPentafluoreto.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_triPentafluoreto')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.triPentafluoreto.emission_tgee = value

                                setValue(
                                  'scope1_category_1_triPentafluoreto',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope1.category_1.triPentafluoreto.type,
                                  'triPentafluoreto',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.triPentafluoreto.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_triPentafluoreto')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.triPentafluoreto.emission_tgee = value

                                setValue(
                                  'scope1_category_2_triPentafluoreto',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope1.category_1.triPentafluoreto.type,
                                  'triPentafluoreto',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.triPentafluoreto.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_triPentafluoreto')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.triPentafluoreto.emission_tgee = value

                                setValue(
                                  'scope1_category_3_triPentafluoreto',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope1.category_1.triPentafluoreto.type,
                                  'triPentafluoreto',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.triPentafluoreto.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_triPentafluoreto')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.triPentafluoreto.emission_tgee = value

                                setValue(
                                  'scope1_category_4_triPentafluoreto',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope1.category_1.triPentafluoreto.type,
                                  'triPentafluoreto',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.triPentafluoreto.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.perfluorociclopropano.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_perfluorociclopropano')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.perfluorociclopropano.emission_tgee =
                                  value

                                setValue(
                                  'scope1_category_1_perfluorociclopropano',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope1.category_1.perfluorociclopropano.type,
                                  'perfluorociclopropano',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.perfluorociclopropano.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_perfluorociclopropano')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.perfluorociclopropano.emission_tgee =
                                  value

                                setValue(
                                  'scope1_category_2_perfluorociclopropano',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope1.category_1.perfluorociclopropano.type,
                                  'perfluorociclopropano',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.perfluorociclopropano.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_perfluorociclopropano')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.perfluorociclopropano.emission_tgee =
                                  value

                                setValue(
                                  'scope1_category_3_perfluorociclopropano',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope1.category_1.perfluorociclopropano.type,
                                  'perfluorociclopropano',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.perfluorociclopropano.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_perfluorociclopropano')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.perfluorociclopropano.emission_tgee =
                                  value

                                setValue(
                                  'scope1_category_4_perfluorociclopropano',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope1.category_1.perfluorociclopropano.type,
                                  'perfluorociclopropano',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.perfluorociclopropano.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.sf6.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_sf6')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.sf6.emission_tgee = value

                                setValue('scope1_category_1_sf6', formatValue)

                                handleChangeValue(scope1.category_1.sf6.type, 'sf6', 1)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_1.sf6.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_sf6')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.sf6.emission_tgee = value

                                setValue('scope1_category_2_sf6', formatValue)

                                handleChangeValue(scope1.category_1.sf6.type, 'sf6', 2)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_2.sf6.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_sf6')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.sf6.emission_tgee = value

                                setValue('scope1_category_3_sf6', formatValue)

                                handleChangeValue(scope1.category_1.sf6.type, 'sf6', 3)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_3.sf6.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_sf6')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.sf6.emission_tgee = value

                                setValue('scope1_category_4_sf6', formatValue)

                                handleChangeValue(scope1.category_1.sf6.type, 'sf6', 4)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_4.sf6.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.nf3.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_nf3')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.nf3.emission_tgee = value

                                setValue('scope1_category_1_nf3', formatValue)

                                handleChangeValue(scope1.category_1.nf3.type, 'nf3', 1)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_1.nf3.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_nf3')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.nf3.emission_tgee = value

                                setValue('scope1_category_2_nf3', formatValue)

                                handleChangeValue(scope1.category_1.nf3.type, 'nf3', 2)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_2.nf3.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_nf3')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.nf3.emission_tgee = value

                                setValue('scope1_category_3_nf3', formatValue)

                                handleChangeValue(scope1.category_1.nf3.type, 'nf3', 3)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_3.nf3.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_nf3')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.nf3.emission_tgee = value

                                setValue('scope1_category_4_nf3', formatValue)

                                handleChangeValue(scope1.category_1.nf3.type, 'nf3', 4)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope1.category_4.nf3.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr style={{ height: 40 }}>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.co2et.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}></td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.co2et.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}></td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.co2et.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}></td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.co2et.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}></td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.co2et.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.co2Bio.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_co2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.co2Bio.emission_tgee = value

                                setValue('scope1_category_1_co2Bio', formatValue)

                                handleChangeValue(
                                  scope1.category_1.co2Bio.type,
                                  'co2Bio',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.co2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_co2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.co2Bio.emission_tgee = value

                                setValue('scope1_category_2_co2Bio', formatValue)

                                handleChangeValue(
                                  scope1.category_1.co2Bio.type,
                                  'co2Bio',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.co2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_co2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.co2Bio.emission_tgee = value

                                setValue('scope1_category_3_co2Bio', formatValue)

                                handleChangeValue(
                                  scope1.category_1.co2Bio.type,
                                  'co2Bio',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.co2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_co2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.co2Bio.emission_tgee = value

                                setValue('scope1_category_4_co2Bio', formatValue)

                                handleChangeValue(
                                  scope1.category_1.co2Bio.type,
                                  'co2Bio',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.co2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope1.category_1.removalCo2Bio.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_1_removalCo2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_1.removalCo2Bio.emission_tgee = value

                                setValue('scope1_category_1_removalCo2Bio', formatValue)

                                handleChangeValue(
                                  scope1.category_1.removalCo2Bio.type,
                                  'removalCo2Bio',
                                  1
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_1.removalCo2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_2_removalCo2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_2.removalCo2Bio.emission_tgee = value

                                setValue('scope1_category_2_removalCo2Bio', formatValue)

                                handleChangeValue(
                                  scope1.category_1.removalCo2Bio.type,
                                  'removalCo2Bio',
                                  2
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_2.removalCo2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_3_removalCo2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_3.removalCo2Bio.emission_tgee = value

                                setValue('scope1_category_3_removalCo2Bio', formatValue)

                                handleChangeValue(
                                  scope1.category_1.removalCo2Bio.type,
                                  'removalCo2Bio',
                                  3
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_3.removalCo2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope1_category_4_removalCo2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope1.category_4.removalCo2Bio.emission_tgee = value

                                setValue('scope1_category_4_removalCo2Bio', formatValue)

                                handleChangeValue(
                                  scope1.category_1.removalCo2Bio.type,
                                  'removalCo2Bio',
                                  4
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope1.category_4.removalCo2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                )}
              </div>
            </div>
          )}

          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeScope2 && titles && titles.length > 0 && (
                  <table
                    style={{
                      maxWidth: '98%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th
                          className="head-tr-item"
                          style={{ width: '20%' }}
                          rowSpan={3}
                        ></th>
                        <th className="head-tr-item" style={{ width: '20%' }} colSpan={2}>
                          {'Categoria 5'}
                        </th>
                        <th className="head-tr-item" style={{ width: '20%' }} colSpan={2}>
                          {'Categoria 6'}
                        </th>
                        <th className="head-tr-item" style={{ width: '20%' }} colSpan={2}>
                          {'Categoria 7'}
                        </th>
                        <th className="head-tr-item" style={{ width: '20%' }} colSpan={2}>
                          {'Categoria 8'}
                        </th>
                      </tr>
                      <tr>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: '20%' }}
                          colSpan={2}
                        >
                          {titles[4]}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: '20%' }}
                          colSpan={2}
                        >
                          {titles[5]}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: '20%' }}
                          colSpan={2}
                        >
                          {titles[6]}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: '20%' }}
                          colSpan={2}
                        >
                          {titles[7]}
                        </th>
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t GEE'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t CO₂e'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t GEE'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t CO₂e'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t GEE'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t CO₂e'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t GEE'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t CO₂e'}
                        </th>
                      </tr>
                    </thead>
                    {changeScope2 && scope2 && (
                      <tbody>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.co2.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_co2')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.co2.emission_tgee = value

                                setValue('scope2_category_5_co2', formatValue)

                                handleChangeValue(scope2.category_5.co2.type, 'co2', 5)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_5.co2.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_co2')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.co2.emission_tgee = value

                                setValue('scope2_category_6_co2', formatValue)

                                handleChangeValue(scope2.category_5.co2.type, 'co2', 6)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_6.co2.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_co2')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.co2.emission_tgee = value

                                setValue('scope2_category_7_co2', formatValue)

                                handleChangeValue(scope2.category_5.co2.type, 'co2', 7)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_7.co2.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_co2')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.co2.emission_tgee = value

                                setValue('scope2_category_8_co2', formatValue)

                                handleChangeValue(scope2.category_5.co2.type, 'co2', 8)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_8.co2.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.ch4.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_ch4')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.ch4.emission_tgee = value

                                setValue('scope2_category_5_ch4', formatValue)

                                handleChangeValue(scope2.category_5.ch4.type, 'ch4', 5)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_5.ch4.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_ch4')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.ch4.emission_tgee = value

                                setValue('scope2_category_6_ch4', formatValue)

                                handleChangeValue(scope2.category_5.ch4.type, 'ch4', 6)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_6.ch4.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_ch4')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.ch4.emission_tgee = value

                                setValue('scope2_category_7_ch4', formatValue)

                                handleChangeValue(scope2.category_5.ch4.type, 'ch4', 7)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_7.ch4.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_ch4')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.ch4.emission_tgee = value

                                setValue('scope2_category_8_ch4', formatValue)

                                handleChangeValue(scope2.category_5.ch4.type, 'ch4', 8)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_8.ch4.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.n2o.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_n2o')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.n2o.emission_tgee = value

                                setValue('scope2_category_5_n2o', formatValue)

                                handleChangeValue(scope2.category_5.n2o.type, 'n2o', 5)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_5.n2o.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_n2o')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.n2o.emission_tgee = value

                                setValue('scope2_category_6_n2o', formatValue)

                                handleChangeValue(scope2.category_5.n2o.type, 'n2o', 6)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_6.n2o.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_n2o')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.n2o.emission_tgee = value

                                setValue('scope2_category_7_n2o', formatValue)

                                handleChangeValue(scope2.category_5.n2o.type, 'n2o', 7)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_7.n2o.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_n2o')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.n2o.emission_tgee = value

                                setValue('scope2_category_8_n2o', formatValue)

                                handleChangeValue(scope2.category_5.n2o.type, 'n2o', 8)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_8.n2o.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr style={{ height: 40 }}>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_5.hfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_5.hfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_6.hfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_6.hfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_7.hfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_7.hfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_8.hfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_8.hfc.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc23.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc23')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc23.emission_tgee = value

                                setValue('scope2_category_5_hfc23', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc23.type,
                                  'hfc23',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc23.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc23')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc23.emission_tgee = value

                                setValue('scope2_category_6_hfc23', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc23.type,
                                  'hfc23',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc23.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc23')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc23.emission_tgee = value

                                setValue('scope2_category_7_hfc23', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc23.type,
                                  'hfc23',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc23.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc23')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc23.emission_tgee = value

                                setValue('scope2_category_8_hfc23', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc23.type,
                                  'hfc23',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc23.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc32.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc32')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc32.emission_tgee = value

                                setValue('scope2_category_5_hfc32', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc32.type,
                                  'hfc32',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc32.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc32')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc32.emission_tgee = value

                                setValue('scope2_category_6_hfc32', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc32.type,
                                  'hfc32',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc32.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc32')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc32.emission_tgee = value

                                setValue('scope2_category_7_hfc32', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc32.type,
                                  'hfc32',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc32.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc32')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc32.emission_tgee = value

                                setValue('scope2_category_8_hfc32', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc32.type,
                                  'hfc32',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc32.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc41.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc41')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc41.emission_tgee = value

                                setValue('scope2_category_5_hfc41', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc41.type,
                                  'hfc41',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc41.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc41')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc41.emission_tgee = value

                                setValue('scope2_category_6_hfc41', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc41.type,
                                  'hfc41',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc41.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc41')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc41.emission_tgee = value

                                setValue('scope2_category_7_hfc41', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc41.type,
                                  'hfc41',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc41.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc41')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc41.emission_tgee = value

                                setValue('scope2_category_8_hfc41', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc41.type,
                                  'hfc41',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc41.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc125.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc125')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc125.emission_tgee = value

                                setValue('scope2_category_5_hfc125', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc125.type,
                                  'hfc125',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc125.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc125')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc125.emission_tgee = value

                                setValue('scope2_category_6_hfc125', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc125.type,
                                  'hfc125',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc125.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc125')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc125.emission_tgee = value

                                setValue('scope2_category_7_hfc125', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc125.type,
                                  'hfc125',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc125.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc125')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc125.emission_tgee = value

                                setValue('scope2_category_8_hfc125', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc125.type,
                                  'hfc125',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc125.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc134.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc134')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc134.emission_tgee = value

                                setValue('scope2_category_5_hfc134', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc134.type,
                                  'hfc134',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc134.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc134')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc134.emission_tgee = value

                                setValue('scope2_category_6_hfc134', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc134.type,
                                  'hfc134',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc134.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc134')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc134.emission_tgee = value

                                setValue('scope2_category_7_hfc134', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc134.type,
                                  'hfc134',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc134.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc134')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc134.emission_tgee = value

                                setValue('scope2_category_8_hfc134', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc134.type,
                                  'hfc134',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc134.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc134a.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc134a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc134a.emission_tgee = value

                                setValue('scope2_category_5_hfc134a', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc134a.type,
                                  'hfc134a',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc134a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc134a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc134a.emission_tgee = value

                                setValue('scope2_category_6_hfc134a', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc134a.type,
                                  'hfc134a',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc134a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc134a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc134a.emission_tgee = value

                                setValue('scope2_category_7_hfc134a', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc134a.type,
                                  'hfc134a',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc134a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc134a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc134a.emission_tgee = value

                                setValue('scope2_category_8_hfc134a', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc134a.type,
                                  'hfc134a',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc134a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc143.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc143')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc143.emission_tgee = value

                                setValue('scope2_category_5_hfc143', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc143.type,
                                  'hfc143',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc143.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc143')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc143.emission_tgee = value

                                setValue('scope2_category_6_hfc143', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc143.type,
                                  'hfc143',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc143.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc143')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc143.emission_tgee = value

                                setValue('scope2_category_7_hfc143', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc143.type,
                                  'hfc143',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc143.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc143')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc143.emission_tgee = value

                                setValue('scope2_category_8_hfc143', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc143.type,
                                  'hfc143',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc143.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc143a.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc143a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc143a.emission_tgee = value

                                setValue('scope2_category_5_hfc143a', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc143a.type,
                                  'hfc143a',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc143a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc143a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc143a.emission_tgee = value

                                setValue('scope2_category_6_hfc143a', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc143a.type,
                                  'hfc143a',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc143a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc143a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc143a.emission_tgee = value

                                setValue('scope2_category_7_hfc143a', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc143a.type,
                                  'hfc143a',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc143a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc143a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc143a.emission_tgee = value

                                setValue('scope2_category_8_hfc143a', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc143a.type,
                                  'hfc143a',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc143a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc152.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc152')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc152.emission_tgee = value

                                setValue('scope2_category_5_hfc152', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc152.type,
                                  'hfc152',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc152.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc152')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc152.emission_tgee = value

                                setValue('scope2_category_6_hfc152', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc152.type,
                                  'hfc152',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc152.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc152')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc152.emission_tgee = value

                                setValue('scope2_category_7_hfc152', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc152.type,
                                  'hfc152',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc152.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc152')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc152.emission_tgee = value

                                setValue('scope2_category_8_hfc152', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc152.type,
                                  'hfc152',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc152.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc152a.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc152a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc152a.emission_tgee = value

                                setValue('scope2_category_5_hfc152a', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc152a.type,
                                  'hfc152a',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc152a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc152a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc152a.emission_tgee = value

                                setValue('scope2_category_6_hfc152a', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc152a.type,
                                  'hfc152a',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc152a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc152a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc152a.emission_tgee = value

                                setValue('scope2_category_7_hfc152a', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc152a.type,
                                  'hfc152a',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc152a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc152a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc152a.emission_tgee = value

                                setValue('scope2_category_8_hfc152a', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc152a.type,
                                  'hfc152a',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc152a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc161.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc161')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc161.emission_tgee = value

                                setValue('scope2_category_5_hfc161', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc161.type,
                                  'hfc161',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc161.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc161')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc161.emission_tgee = value

                                setValue('scope2_category_6_hfc161', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc161.type,
                                  'hfc161',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc161.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc161')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc161.emission_tgee = value

                                setValue('scope2_category_7_hfc161', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc161.type,
                                  'hfc161',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc161.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc161')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc161.emission_tgee = value

                                setValue('scope2_category_8_hfc161', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc161.type,
                                  'hfc161',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc161.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc227ea.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc227ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc227ea.emission_tgee = value

                                setValue('scope2_category_5_hfc227ea', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc227ea.type,
                                  'hfc227ea',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc227ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc227ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc227ea.emission_tgee = value

                                setValue('scope2_category_6_hfc227ea', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc227ea.type,
                                  'hfc227ea',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc227ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc227ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc227ea.emission_tgee = value

                                setValue('scope2_category_7_hfc227ea', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc227ea.type,
                                  'hfc227ea',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc227ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc227ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc227ea.emission_tgee = value

                                setValue('scope2_category_8_hfc227ea', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc227ea.type,
                                  'hfc227ea',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc227ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc236cb.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc236cb')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc236cb.emission_tgee = value

                                setValue('scope2_category_5_hfc236cb', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc236cb.type,
                                  'hfc236cb',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc236cb.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc236cb')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc236cb.emission_tgee = value

                                setValue('scope2_category_6_hfc236cb', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc236cb.type,
                                  'hfc236cb',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc236cb.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc236cb')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc236cb.emission_tgee = value

                                setValue('scope2_category_7_hfc236cb', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc236cb.type,
                                  'hfc236cb',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc236cb.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc236cb')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc236cb.emission_tgee = value

                                setValue('scope2_category_8_hfc236cb', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc236cb.type,
                                  'hfc236cb',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc236cb.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc236ea.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc236ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc236ea.emission_tgee = value

                                setValue('scope2_category_5_hfc236ea', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc236ea.type,
                                  'hfc236ea',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc236ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc236ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc236ea.emission_tgee = value

                                setValue('scope2_category_6_hfc236ea', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc236ea.type,
                                  'hfc236ea',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc236ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc236ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc236ea.emission_tgee = value

                                setValue('scope2_category_7_hfc236ea', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc236ea.type,
                                  'hfc236ea',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc236ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc236ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc236ea.emission_tgee = value

                                setValue('scope2_category_8_hfc236ea', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc236ea.type,
                                  'hfc236ea',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc236ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc236fa.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc236fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc236fa.emission_tgee = value

                                setValue('scope2_category_5_hfc236fa', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc236fa.type,
                                  'hfc236fa',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc236fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc236fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc236fa.emission_tgee = value

                                setValue('scope2_category_6_hfc236fa', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc236fa.type,
                                  'hfc236fa',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc236fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc236fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc236fa.emission_tgee = value

                                setValue('scope2_category_7_hfc236fa', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc236fa.type,
                                  'hfc236fa',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc236fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc236fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc236fa.emission_tgee = value

                                setValue('scope2_category_8_hfc236fa', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc236fa.type,
                                  'hfc236fa',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc236fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc245ca.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc245ca')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc245ca.emission_tgee = value

                                setValue('scope2_category_5_hfc245ca', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc245ca.type,
                                  'hfc245ca',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc245ca.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc245ca')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc245ca.emission_tgee = value

                                setValue('scope2_category_6_hfc245ca', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc245ca.type,
                                  'hfc245ca',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc245ca.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc245ca')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc245ca.emission_tgee = value

                                setValue('scope2_category_7_hfc245ca', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc245ca.type,
                                  'hfc245ca',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc245ca.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc245ca')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc245ca.emission_tgee = value

                                setValue('scope2_category_8_hfc245ca', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc245ca.type,
                                  'hfc245ca',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc245ca.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc245fa.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc245fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc245fa.emission_tgee = value

                                setValue('scope2_category_5_hfc245fa', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc236fa.type,
                                  'hfc236fa',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc245fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc245fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc245fa.emission_tgee = value

                                setValue('scope2_category_6_hfc245fa', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc236fa.type,
                                  'hfc236fa',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc245fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc245fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc245fa.emission_tgee = value

                                setValue('scope2_category_7_hfc245fa', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc236fa.type,
                                  'hfc236fa',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc245fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc245fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc245fa.emission_tgee = value

                                setValue('scope2_category_8_hfc245fa', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc236fa.type,
                                  'hfc236fa',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc245fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc365mfc.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc365mfc')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc365mfc.emission_tgee = value

                                setValue('scope2_category_5_hfc365mfc', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc365mfc.type,
                                  'hfc365mfc',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc365mfc.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc365mfc')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc365mfc.emission_tgee = value

                                setValue('scope2_category_6_hfc365mfc', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc365mfc.type,
                                  'hfc365mfc',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc365mfc.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc365mfc')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc365mfc.emission_tgee = value

                                setValue('scope2_category_7_hfc365mfc', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc365mfc.type,
                                  'hfc365mfc',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc365mfc.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc365mfc')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc365mfc.emission_tgee = value

                                setValue('scope2_category_8_hfc365mfc', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc365mfc.type,
                                  'hfc365mfc',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc365mfc.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.hfc4310mee.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_hfc4310mee')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.hfc4310mee.emission_tgee = value

                                setValue('scope2_category_5_hfc4310mee', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc4310mee.type,
                                  'hfc4310mee',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.hfc4310mee.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_hfc4310mee')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.hfc4310mee.emission_tgee = value

                                setValue('scope2_category_6_hfc4310mee', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc4310mee.type,
                                  'hfc4310mee',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.hfc4310mee.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_hfc4310mee')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.hfc4310mee.emission_tgee = value

                                setValue('scope2_category_7_hfc4310mee', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc4310mee.type,
                                  'hfc4310mee',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.hfc4310mee.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_hfc4310mee')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.hfc4310mee.emission_tgee = value

                                setValue('scope2_category_8_hfc4310mee', formatValue)

                                handleChangeValue(
                                  scope2.category_5.hfc4310mee.type,
                                  'hfc4310mee',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.hfc4310mee.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr style={{ height: 40 }}>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.pfc.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_5.pfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_5.pfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_6.pfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_6.pfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_7.pfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_7.pfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_8.pfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_8.pfc.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.pfc14.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_pfc14')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.pfc14.emission_tgee = value

                                setValue('scope2_category_5_pfc14', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc14.type,
                                  'pfc14',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.pfc14.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_pfc14')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.pfc14.emission_tgee = value

                                setValue('scope2_category_6_pfc14', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc14.type,
                                  'pfc14',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.pfc14.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_pfc14')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.pfc14.emission_tgee = value

                                setValue('scope2_category_7_pfc14', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc14.type,
                                  'pfc14',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.pfc14.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_pfc14')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.pfc14.emission_tgee = value

                                setValue('scope2_category_8_pfc14', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc14.type,
                                  'pfc14',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.pfc14.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.pfc116.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_pfc116')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.pfc116.emission_tgee = value

                                setValue('scope2_category_5_pfc116', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc116.type,
                                  'pfc116',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.pfc116.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_pfc116')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.pfc116.emission_tgee = value

                                setValue('scope2_category_6_pfc116', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc116.type,
                                  'pfc116',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.pfc116.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_pfc116')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.pfc116.emission_tgee = value

                                setValue('scope2_category_7_pfc116', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc116.type,
                                  'pfc116',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.pfc116.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_pfc116')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.pfc116.emission_tgee = value

                                setValue('scope2_category_8_pfc116', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc116.type,
                                  'pfc116',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.pfc116.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.pfc218.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_pfc218')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.pfc218.emission_tgee = value

                                setValue('scope2_category_5_pfc218', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc218.type,
                                  'pfc218',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.pfc218.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_pfc218')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.pfc218.emission_tgee = value

                                setValue('scope2_category_6_pfc218', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc218.type,
                                  'pfc218',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.pfc218.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_pfc218')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.pfc218.emission_tgee = value

                                setValue('scope2_category_7_pfc218', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc218.type,
                                  'pfc218',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.pfc218.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_pfc218')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.pfc218.emission_tgee = value

                                setValue('scope2_category_8_pfc218', formatValue)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.pfc218.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.pfc318.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_pfc318')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.pfc318.emission_tgee = value

                                setValue('scope2_category_5_pfc318', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc318.type,
                                  'pfc318',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.pfc318.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_pfc318')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.pfc318.emission_tgee = value

                                setValue('scope2_category_6_pfc318', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc318.type,
                                  'pfc318',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.pfc318.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_pfc318')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.pfc318.emission_tgee = value

                                setValue('scope2_category_7_pfc318', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc318.type,
                                  'pfc318',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.pfc318.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_pfc318')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.pfc318.emission_tgee = value

                                setValue('scope2_category_8_pfc318', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc318.type,
                                  'pfc318',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.pfc318.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.pfc3110.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_pfc3110')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.pfc3110.emission_tgee = value

                                setValue('scope2_category_5_pfc3110', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc3110.type,
                                  'pfc3110',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.pfc3110.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_pfc3110')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.pfc3110.emission_tgee = value

                                setValue('scope2_category_6_pfc3110', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc3110.type,
                                  'pfc3110',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.pfc3110.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_pfc3110')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.pfc3110.emission_tgee = value

                                setValue('scope2_category_7_pfc3110', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc3110.type,
                                  'pfc3110',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.pfc3110.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_pfc3110')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.pfc3110.emission_tgee = value

                                setValue('scope2_category_8_pfc3110', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc3110.type,
                                  'pfc3110',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.pfc3110.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.pfc4112.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_pfc4112')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.pfc4112.emission_tgee = value

                                setValue('scope2_category_5_pfc4112', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc4112.type,
                                  'pfc4112',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.pfc4112.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_pfc4112')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.pfc4112.emission_tgee = value

                                setValue('scope2_category_6_pfc4112', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc4112.type,
                                  'pfc4112',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.pfc4112.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_pfc4112')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.pfc4112.emission_tgee = value

                                setValue('scope2_category_7_pfc4112', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc4112.type,
                                  'pfc4112',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.pfc4112.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_pfc4112')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.pfc4112.emission_tgee = value

                                setValue('scope2_category_8_pfc4112', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc4112.type,
                                  'pfc4112',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.pfc4112.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.pfc5114.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_pfc5114')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.pfc5114.emission_tgee = value

                                setValue('scope2_category_5_pfc5114', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc5114.type,
                                  'pfc5114',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.pfc5114.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_pfc5114')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.pfc5114.emission_tgee = value

                                setValue('scope2_category_6_pfc5114', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc5114.type,
                                  'pfc5114',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.pfc5114.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_pfc5114')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.pfc5114.emission_tgee = value

                                setValue('scope2_category_7_pfc5114', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc5114.type,
                                  'pfc5114',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.pfc5114.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_pfc5114')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.pfc5114.emission_tgee = value

                                setValue('scope2_category_8_pfc5114', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc5114.type,
                                  'pfc5114',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.pfc5114.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.pfc9118.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_pfc9118')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.pfc9118.emission_tgee = value

                                setValue('scope2_category_5_pfc9118', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc9118.type,
                                  'pfc9118',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.pfc9118.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_pfc9118')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.pfc9118.emission_tgee = value

                                setValue('scope2_category_6_pfc9118', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc9118.type,
                                  'pfc9118',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.pfc9118.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_pfc9118')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.pfc9118.emission_tgee = value

                                setValue('scope2_category_7_pfc9118', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc9118.type,
                                  'pfc9118',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.pfc9118.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_pfc9118')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.pfc9118.emission_tgee = value

                                setValue('scope2_category_8_pfc9118', formatValue)

                                handleChangeValue(
                                  scope2.category_5.pfc9118.type,
                                  'pfc9118',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.pfc9118.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.triPentafluoreto.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_triPentafluoreto')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.triPentafluoreto.emission_tgee = value

                                setValue(
                                  'scope2_category_5_triPentafluoreto',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope2.category_5.triPentafluoreto.type,
                                  'triPentafluoreto',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.triPentafluoreto.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_triPentafluoreto')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.triPentafluoreto.emission_tgee = value

                                setValue(
                                  'scope2_category_6_triPentafluoreto',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope2.category_5.triPentafluoreto.type,
                                  'triPentafluoreto',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.triPentafluoreto.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_triPentafluoreto')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.triPentafluoreto.emission_tgee = value

                                setValue(
                                  'scope2_category_7_triPentafluoreto',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope2.category_5.triPentafluoreto.type,
                                  'triPentafluoreto',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.triPentafluoreto.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_triPentafluoreto')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.triPentafluoreto.emission_tgee = value

                                setValue(
                                  'scope2_category_8_triPentafluoreto',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope2.category_5.triPentafluoreto.type,
                                  'triPentafluoreto',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.triPentafluoreto.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.perfluorociclopropano.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_perfluorociclopropano')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.perfluorociclopropano.emission_tgee =
                                  value

                                setValue(
                                  'scope2_category_5_perfluorociclopropano',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope2.category_5.perfluorociclopropano.type,
                                  'perfluorociclopropano',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.perfluorociclopropano.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_perfluorociclopropano')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.perfluorociclopropano.emission_tgee =
                                  value

                                setValue(
                                  'scope2_category_6_perfluorociclopropano',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope2.category_5.perfluorociclopropano.type,
                                  'perfluorociclopropano',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.perfluorociclopropano.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_perfluorociclopropano')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.perfluorociclopropano.emission_tgee =
                                  value

                                setValue(
                                  'scope2_category_7_perfluorociclopropano',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope2.category_5.perfluorociclopropano.type,
                                  'perfluorociclopropano',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.perfluorociclopropano.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_perfluorociclopropano')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.perfluorociclopropano.emission_tgee =
                                  value

                                setValue(
                                  'scope2_category_8_perfluorociclopropano',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope2.category_5.perfluorociclopropano.type,
                                  'perfluorociclopropano',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.perfluorociclopropano.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.sf6.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_sf6')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.sf6.emission_tgee = value

                                setValue('scope2_category_5_sf6', formatValue)

                                handleChangeValue(scope2.category_5.sf6.type, 'sf6', 5)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_5.sf6.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_sf6')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.sf6.emission_tgee = value

                                setValue('scope2_category_6_sf6', formatValue)

                                handleChangeValue(scope2.category_5.sf6.type, 'sf6', 6)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_6.sf6.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_sf6')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.sf6.emission_tgee = value

                                setValue('scope2_category_7_sf6', formatValue)

                                handleChangeValue(scope2.category_5.sf6.type, 'sf6', 7)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_7.sf6.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_sf6')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.sf6.emission_tgee = value

                                setValue('scope2_category_8_sf6', formatValue)

                                handleChangeValue(scope2.category_5.sf6.type, 'sf6', 8)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_8.sf6.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.nf3.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_nf3')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.nf3.emission_tgee = value

                                setValue('scope2_category_5_nf3', formatValue)

                                handleChangeValue(scope2.category_5.nf3.type, 'nf3', 5)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_5.nf3.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_nf3')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.nf3.emission_tgee = value

                                setValue('scope2_category_6_nf3', formatValue)

                                handleChangeValue(scope2.category_5.nf3.type, 'nf3', 6)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_6.nf3.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_nf3')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.nf3.emission_tgee = value

                                setValue('scope2_category_7_nf3', formatValue)

                                handleChangeValue(scope2.category_5.nf3.type, 'nf3', 7)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_7.nf3.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_nf3')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.nf3.emission_tgee = value

                                setValue('scope2_category_8_nf3', formatValue)

                                handleChangeValue(scope2.category_5.nf3.type, 'nf3', 8)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope2.category_8.nf3.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr style={{ height: 40 }}>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.co2et.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}></td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.co2et.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}></td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.co2et.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}></td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.co2et.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}></td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.co2et.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.co2Bio.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_co2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.co2Bio.emission_tgee = value

                                setValue('scope2_category_5_co2Bio', formatValue)

                                handleChangeValue(
                                  scope2.category_5.co2Bio.type,
                                  'co2Bio',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.co2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_co2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.co2Bio.emission_tgee = value

                                setValue('scope2_category_6_co2Bio', formatValue)

                                handleChangeValue(
                                  scope2.category_5.co2Bio.type,
                                  'co2Bio',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.co2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_co2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.co2Bio.emission_tgee = value

                                setValue('scope2_category_7_co2Bio', formatValue)

                                handleChangeValue(
                                  scope2.category_5.co2Bio.type,
                                  'co2Bio',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.co2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_co2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.co2Bio.emission_tgee = value

                                setValue('scope2_category_8_co2Bio', formatValue)

                                handleChangeValue(
                                  scope2.category_5.co2Bio.type,
                                  'co2Bio',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.co2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope2.category_5.removalCo2Bio.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_5_removalCo2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_5.removalCo2Bio.emission_tgee = value

                                setValue('scope2_category_5_removalCo2Bio', formatValue)

                                handleChangeValue(
                                  scope2.category_5.removalCo2Bio.type,
                                  'removalCo2Bio',
                                  5
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_5.removalCo2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_6_removalCo2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_6.removalCo2Bio.emission_tgee = value

                                setValue('scope2_category_6_removalCo2Bio', formatValue)

                                handleChangeValue(
                                  scope2.category_5.removalCo2Bio.type,
                                  'removalCo2Bio',
                                  6
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_6.removalCo2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_7_removalCo2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_7.removalCo2Bio.emission_tgee = value

                                setValue('scope2_category_7_removalCo2Bio', formatValue)

                                handleChangeValue(
                                  scope2.category_5.removalCo2Bio.type,
                                  'removalCo2Bio',
                                  7
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_7.removalCo2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope2_category_8_removalCo2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope2.category_8.removalCo2Bio.emission_tgee = value

                                setValue('scope2_category_8_removalCo2Bio', formatValue)

                                handleChangeValue(
                                  scope2.category_5.removalCo2Bio.type,
                                  'removalCo2Bio',
                                  8
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope2.category_8.removalCo2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                )}
              </div>
            </div>
          )}

          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeScope3 && titles && titles.length > 0 && (
                  <table
                    style={{
                      maxWidth: '98%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th
                          className="head-tr-item"
                          style={{ width: '20%' }}
                          rowSpan={3}
                        ></th>
                        <th className="head-tr-item" style={{ width: '20%' }} colSpan={2}>
                          {'Categoria 9'}
                        </th>
                        <th className="head-tr-item" style={{ width: '20%' }} colSpan={2}>
                          {'Categoria 10'}
                        </th>
                        <th className="head-tr-item" style={{ width: '20%' }} colSpan={2}>
                          {'Categoria 11'}
                        </th>
                        <th className="head-tr-item" style={{ width: '20%' }} colSpan={2}>
                          {'Categoria 12'}
                        </th>
                      </tr>
                      <tr>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: '20%' }}
                          colSpan={2}
                        >
                          {titles[8]}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: '20%' }}
                          colSpan={2}
                        >
                          {titles[9]}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: '20%' }}
                          colSpan={2}
                        >
                          {titles[10]}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: '20%' }}
                          colSpan={2}
                        >
                          {titles[11]}
                        </th>
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t GEE'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t CO₂e'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t GEE'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t CO₂e'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t GEE'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t CO₂e'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t GEE'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t CO₂e'}
                        </th>
                      </tr>
                    </thead>
                    {changeScope3 && scope3 && (
                      <tbody>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.co2.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_co2')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.co2.emission_tgee = value

                                setValue('scope3_category_9_co2', formatValue)

                                handleChangeValue(scope3.category_9.co2.type, 'co2', 9)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_9.co2.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_co2')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.co2.emission_tgee = value

                                setValue('scope3_category_10_co2', formatValue)

                                handleChangeValue(scope3.category_9.co2.type, 'co2', 10)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_10.co2.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_co2')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.co2.emission_tgee = value

                                setValue('scope3_category_11_co2', formatValue)

                                handleChangeValue(scope3.category_9.co2.type, 'co2', 11)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_11.co2.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_co2')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.co2.emission_tgee = value

                                setValue('scope3_category_12_co2', formatValue)

                                handleChangeValue(scope3.category_9.co2.type, 'co2', 12)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_12.co2.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.ch4.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_ch4')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.ch4.emission_tgee = value

                                setValue('scope3_category_9_ch4', formatValue)

                                handleChangeValue(scope3.category_9.ch4.type, 'ch4', 9)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_9.ch4.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_ch4')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.ch4.emission_tgee = value

                                setValue('scope3_category_10_ch4', formatValue)

                                handleChangeValue(scope3.category_9.ch4.type, 'ch4', 10)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_10.ch4.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_ch4')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.ch4.emission_tgee = value

                                setValue('scope3_category_11_ch4', formatValue)

                                handleChangeValue(scope3.category_9.ch4.type, 'ch4', 11)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_11.ch4.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_ch4')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.ch4.emission_tgee = value

                                setValue('scope3_category_12_ch4', formatValue)

                                handleChangeValue(scope3.category_9.ch4.type, 'ch4', 12)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_12.ch4.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.n2o.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_n2o')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.n2o.emission_tgee = value

                                setValue('scope3_category_9_n2o', formatValue)

                                handleChangeValue(scope3.category_9.n2o.type, 'n2o', 9)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_9.n2o.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_n2o')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.n2o.emission_tgee = value

                                setValue('scope3_category_10_n2o', formatValue)

                                handleChangeValue(scope3.category_9.n2o.type, 'n2o', 10)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_10.n2o.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_n2o')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.n2o.emission_tgee = value

                                setValue('scope3_category_11_n2o', formatValue)

                                handleChangeValue(scope3.category_9.n2o.type, 'n2o', 11)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_11.n2o.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_n2o')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.n2o.emission_tgee = value

                                setValue('scope3_category_12_n2o', formatValue)

                                handleChangeValue(scope3.category_9.n2o.type, 'n2o', 12)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_12.n2o.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr style={{ height: 40 }}>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_9.hfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_9.hfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_10.hfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_10.hfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_11.hfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_11.hfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_12.hfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_12.hfc.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc23.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc23')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc23.emission_tgee = value

                                setValue('scope3_category_9_hfc23', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc23.type,
                                  'hfc23',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc23.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc23')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc23.emission_tgee = value

                                setValue('scope3_category_10_hfc23', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc23.type,
                                  'hfc23',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc23.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc23')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc23.emission_tgee = value

                                setValue('scope3_category_11_hfc23', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc23.type,
                                  'hfc23',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc23.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc23')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc23.emission_tgee = value

                                setValue('scope3_category_12_hfc23', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc23.type,
                                  'hfc23',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc23.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc32.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc32')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc32.emission_tgee = value

                                setValue('scope3_category_9_hfc32', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc32.type,
                                  'hfc32',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc32.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc32')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc32.emission_tgee = value

                                setValue('scope3_category_10_hfc32', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc32.type,
                                  'hfc32',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc32.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc32')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc32.emission_tgee = value

                                setValue('scope3_category_11_hfc32', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc32.type,
                                  'hfc32',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc32.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc32')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc32.emission_tgee = value

                                setValue('scope3_category_12_hfc32', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc32.type,
                                  'hfc32',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc32.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc41.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc41')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc41.emission_tgee = value

                                setValue('scope3_category_9_hfc41', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc41.type,
                                  'hfc41',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc41.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc41')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc41.emission_tgee = value

                                setValue('scope3_category_10_hfc41', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc41.type,
                                  'hfc41',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc41.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc41')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc41.emission_tgee = value

                                setValue('scope3_category_11_hfc41', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc41.type,
                                  'hfc41',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc41.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc41')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc41.emission_tgee = value

                                setValue('scope3_category_12_hfc41', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc41.type,
                                  'hfc41',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc41.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc125.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc125')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc125.emission_tgee = value

                                setValue('scope3_category_9_hfc125', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc125.type,
                                  'hfc125',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc125.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc125')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc125.emission_tgee = value

                                setValue('scope3_category_10_hfc125', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc125.type,
                                  'hfc125',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc125.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc125')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc125.emission_tgee = value

                                setValue('scope3_category_11_hfc125', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc125.type,
                                  'hfc125',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc125.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc125')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc125.emission_tgee = value

                                setValue('scope3_category_12_hfc125', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc125.type,
                                  'hfc125',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc125.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc134.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc134')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc134.emission_tgee = value

                                setValue('scope3_category_9_hfc134', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc134.type,
                                  'hfc134',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc134.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc134')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc134.emission_tgee = value

                                setValue('scope3_category_10_hfc134', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc134.type,
                                  'hfc134',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc134.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc134')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc134.emission_tgee = value

                                setValue('scope3_category_11_hfc134', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc134.type,
                                  'hfc134',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc134.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc134')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc134.emission_tgee = value

                                setValue('scope3_category_12_hfc134', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc134.type,
                                  'hfc134',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc134.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc134a.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc134a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc134a.emission_tgee = value

                                setValue('scope3_category_9_hfc134a', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc134a.type,
                                  'hfc134a',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc134a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc134a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc134a.emission_tgee = value

                                setValue('scope3_category_10_hfc134a', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc134a.type,
                                  'hfc134a',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc134a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc134a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc134a.emission_tgee = value

                                setValue('scope3_category_11_hfc134a', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc134a.type,
                                  'hfc134a',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc134a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc134a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc134a.emission_tgee = value

                                setValue('scope3_category_12_hfc134a', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc134a.type,
                                  'hfc134a',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc134a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc143.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc143')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc143.emission_tgee = value

                                setValue('scope3_category_9_hfc143', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc143.type,
                                  'hfc143',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc143.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc143')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc143.emission_tgee = value

                                setValue('scope3_category_10_hfc143', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc143.type,
                                  'hfc143',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc143.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc143')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc143.emission_tgee = value

                                setValue('scope3_category_11_hfc143', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc143.type,
                                  'hfc143',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc143.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc143')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc143.emission_tgee = value

                                setValue('scope3_category_12_hfc143', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc143.type,
                                  'hfc143',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc143.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc143a.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc143a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc143a.emission_tgee = value

                                setValue('scope3_category_9_hfc143a', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc143a.type,
                                  'hfc143a',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc143a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc143a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc143a.emission_tgee = value

                                setValue('scope3_category_10_hfc143a', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc143a.type,
                                  'hfc143a',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc143a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc143a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc143a.emission_tgee = value

                                setValue('scope3_category_11_hfc143a', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc143a.type,
                                  'hfc143a',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc143a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc143a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc143a.emission_tgee = value

                                setValue('scope3_category_12_hfc143a', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc143a.type,
                                  'hfc143a',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc143a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc152.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc152')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc152.emission_tgee = value

                                setValue('scope3_category_9_hfc152', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc152.type,
                                  'hfc152',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc152.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc152')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc152.emission_tgee = value

                                setValue('scope3_category_10_hfc152', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc152.type,
                                  'hfc152',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc152.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc152')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc152.emission_tgee = value

                                setValue('scope3_category_11_hfc152', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc152.type,
                                  'hfc152',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc152.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc152')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc152.emission_tgee = value

                                setValue('scope3_category_12_hfc152', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc152.type,
                                  'hfc152',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc152.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc152a.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc152a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc152a.emission_tgee = value

                                setValue('scope3_category_9_hfc152a', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc152a.type,
                                  'hfc152a',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc152a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc152a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc152a.emission_tgee = value

                                setValue('scope3_category_10_hfc152a', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc152a.type,
                                  'hfc152a',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc152a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc152a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc152a.emission_tgee = value

                                setValue('scope3_category_11_hfc152a', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc152a.type,
                                  'hfc152a',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc152a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc152a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc152a.emission_tgee = value

                                setValue('scope3_category_12_hfc152a', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc152a.type,
                                  'hfc152a',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc152a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc161.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc161')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc161.emission_tgee = value

                                setValue('scope3_category_9_hfc161', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc161.type,
                                  'hfc161',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc161.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc161')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc161.emission_tgee = value

                                setValue('scope3_category_10_hfc161', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc161.type,
                                  'hfc161',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc161.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc161')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc161.emission_tgee = value

                                setValue('scope3_category_11_hfc161', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc161.type,
                                  'hfc161',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc161.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc161')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc161.emission_tgee = value

                                setValue('scope3_category_12_hfc161', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc161.type,
                                  'hfc161',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc161.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc227ea.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc227ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc227ea.emission_tgee = value

                                setValue('scope3_category_9_hfc227ea', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc227ea.type,
                                  'hfc227ea',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc227ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc227ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc227ea.emission_tgee = value

                                setValue('scope3_category_10_hfc227ea', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc227ea.type,
                                  'hfc227ea',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc227ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc227ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc227ea.emission_tgee = value

                                setValue('scope3_category_11_hfc227ea', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc227ea.type,
                                  'hfc227ea',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc227ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc227ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc227ea.emission_tgee = value

                                setValue('scope3_category_12_hfc227ea', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc227ea.type,
                                  'hfc227ea',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc227ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc236cb.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc236cb')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc236cb.emission_tgee = value

                                setValue('scope3_category_9_hfc236cb', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc236cb.type,
                                  'hfc236cb',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc236cb.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc236cb')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc236cb.emission_tgee = value

                                setValue('scope3_category_10_hfc236cb', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc236cb.type,
                                  'hfc236cb',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc236cb.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc236cb')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc236cb.emission_tgee = value

                                setValue('scope3_category_11_hfc236cb', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc236cb.type,
                                  'hfc236cb',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc236cb.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc236cb')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc236cb.emission_tgee = value

                                setValue('scope3_category_12_hfc236cb', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc236cb.type,
                                  'hfc236cb',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc236cb.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc236ea.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc236ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc236ea.emission_tgee = value

                                setValue('scope3_category_9_hfc236ea', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc236ea.type,
                                  'hfc236ea',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc236ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc236ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc236ea.emission_tgee = value

                                setValue('scope3_category_10_hfc236ea', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc236ea.type,
                                  'hfc236ea',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc236ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc236ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc236ea.emission_tgee = value

                                setValue('scope3_category_11_hfc236ea', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc236ea.type,
                                  'hfc236ea',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc236ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc236ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc236ea.emission_tgee = value

                                setValue('scope3_category_12_hfc236ea', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc236ea.type,
                                  'hfc236ea',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc236ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc236fa.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc236fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc236fa.emission_tgee = value

                                setValue('scope3_category_9_hfc236fa', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc236fa.type,
                                  'hfc236fa',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc236fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc236fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc236fa.emission_tgee = value

                                setValue('scope3_category_10_hfc236fa', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc236fa.type,
                                  'hfc236fa',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc236fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc236fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc236fa.emission_tgee = value

                                setValue('scope3_category_11_hfc236fa', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc236fa.type,
                                  'hfc236fa',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc236fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc236fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc236fa.emission_tgee = value

                                setValue('scope3_category_12_hfc236fa', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc236fa.type,
                                  'hfc236fa',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc236fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc245ca.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc245ca')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc245ca.emission_tgee = value

                                setValue('scope3_category_9_hfc245ca', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc245ca.type,
                                  'hfc245ca',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc245ca.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc245ca')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc245ca.emission_tgee = value

                                setValue('scope3_category_10_hfc245ca', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc245ca.type,
                                  'hfc245ca',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc245ca.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc245ca')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc245ca.emission_tgee = value

                                setValue('scope3_category_11_hfc245ca', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc245ca.type,
                                  'hfc245ca',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc245ca.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc245ca')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc245ca.emission_tgee = value

                                setValue('scope3_category_12_hfc245ca', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc245ca.type,
                                  'hfc245ca',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc245ca.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc245fa.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc245fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc245fa.emission_tgee = value

                                setValue('scope3_category_9_hfc245fa', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc236fa.type,
                                  'hfc236fa',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc245fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc245fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc245fa.emission_tgee = value

                                setValue('scope3_category_10_hfc245fa', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc236fa.type,
                                  'hfc236fa',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc245fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc245fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc245fa.emission_tgee = value

                                setValue('scope3_category_11_hfc245fa', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc236fa.type,
                                  'hfc236fa',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc245fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc245fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc245fa.emission_tgee = value

                                setValue('scope3_category_12_hfc245fa', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc236fa.type,
                                  'hfc236fa',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc245fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc365mfc.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc365mfc')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc365mfc.emission_tgee = value

                                setValue('scope3_category_9_hfc365mfc', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc365mfc.type,
                                  'hfc365mfc',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc365mfc.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc365mfc')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc365mfc.emission_tgee = value

                                setValue('scope3_category_10_hfc365mfc', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc365mfc.type,
                                  'hfc365mfc',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc365mfc.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc365mfc')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc365mfc.emission_tgee = value

                                setValue('scope3_category_11_hfc365mfc', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc365mfc.type,
                                  'hfc365mfc',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc365mfc.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc365mfc')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc365mfc.emission_tgee = value

                                setValue('scope3_category_12_hfc365mfc', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc365mfc.type,
                                  'hfc365mfc',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc365mfc.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.hfc4310mee.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_hfc4310mee')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.hfc4310mee.emission_tgee = value

                                setValue('scope3_category_9_hfc4310mee', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc4310mee.type,
                                  'hfc4310mee',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.hfc4310mee.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_hfc4310mee')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.hfc4310mee.emission_tgee = value

                                setValue('scope3_category_10_hfc4310mee', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc4310mee.type,
                                  'hfc4310mee',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.hfc4310mee.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_hfc4310mee')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.hfc4310mee.emission_tgee = value

                                setValue('scope3_category_11_hfc4310mee', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc4310mee.type,
                                  'hfc4310mee',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.hfc4310mee.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_hfc4310mee')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.hfc4310mee.emission_tgee = value

                                setValue('scope3_category_12_hfc4310mee', formatValue)

                                handleChangeValue(
                                  scope3.category_9.hfc4310mee.type,
                                  'hfc4310mee',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.hfc4310mee.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr style={{ height: 40 }}>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.pfc.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_9.pfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_9.pfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_10.pfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_10.pfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_11.pfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_11.pfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_12.pfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_12.pfc.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.pfc14.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_pfc14')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.pfc14.emission_tgee = value

                                setValue('scope3_category_9_pfc14', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc14.type,
                                  'pfc14',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.pfc14.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_pfc14')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.pfc14.emission_tgee = value

                                setValue('scope3_category_10_pfc14', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc14.type,
                                  'pfc14',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.pfc14.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_pfc14')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.pfc14.emission_tgee = value

                                setValue('scope3_category_11_pfc14', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc14.type,
                                  'pfc14',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.pfc14.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_pfc14')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.pfc14.emission_tgee = value

                                setValue('scope3_category_12_pfc14', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc14.type,
                                  'pfc14',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.pfc14.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.pfc116.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_pfc116')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.pfc116.emission_tgee = value

                                setValue('scope3_category_9_pfc116', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc116.type,
                                  'pfc116',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.pfc116.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_pfc116')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.pfc116.emission_tgee = value

                                setValue('scope3_category_10_pfc116', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc116.type,
                                  'pfc116',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.pfc116.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_pfc116')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.pfc116.emission_tgee = value

                                setValue('scope3_category_11_pfc116', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc116.type,
                                  'pfc116',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.pfc116.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_pfc116')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.pfc116.emission_tgee = value

                                setValue('scope3_category_12_pfc116', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc116.type,
                                  'pfc116',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.pfc116.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.pfc218.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_pfc218')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.pfc218.emission_tgee = value

                                setValue('scope3_category_9_pfc218', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc218.type,
                                  'pfc218',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.pfc218.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_pfc218')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.pfc218.emission_tgee = value

                                setValue('scope3_category_10_pfc218', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc218.type,
                                  'pfc218',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.pfc218.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_pfc218')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.pfc218.emission_tgee = value

                                setValue('scope3_category_11_pfc218', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc218.type,
                                  'pfc218',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.pfc218.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_pfc218')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.pfc218.emission_tgee = value

                                setValue('scope3_category_12_pfc218', formatValue)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.pfc218.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.pfc318.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_pfc318')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.pfc318.emission_tgee = value

                                setValue('scope3_category_9_pfc318', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc318.type,
                                  'pfc318',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.pfc318.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_pfc318')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.pfc318.emission_tgee = value

                                setValue('scope3_category_10_pfc318', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc318.type,
                                  'pfc318',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.pfc318.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_pfc318')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.pfc318.emission_tgee = value

                                setValue('scope3_category_11_pfc318', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc318.type,
                                  'pfc318',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.pfc318.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_pfc318')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.pfc318.emission_tgee = value

                                setValue('scope3_category_12_pfc318', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc318.type,
                                  'pfc318',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.pfc318.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.pfc3110.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_pfc3110')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.pfc3110.emission_tgee = value

                                setValue('scope3_category_9_pfc3110', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc3110.type,
                                  'pfc3110',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.pfc3110.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_pfc3110')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.pfc3110.emission_tgee = value

                                setValue('scope3_category_10_pfc3110', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc3110.type,
                                  'pfc3110',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.pfc3110.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_pfc3110')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.pfc3110.emission_tgee = value

                                setValue('scope3_category_11_pfc3110', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc3110.type,
                                  'pfc3110',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.pfc3110.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_pfc3110')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.pfc3110.emission_tgee = value

                                setValue('scope3_category_12_pfc3110', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc3110.type,
                                  'pfc3110',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.pfc3110.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.pfc4112.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_pfc4112')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.pfc4112.emission_tgee = value

                                setValue('scope3_category_9_pfc4112', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc4112.type,
                                  'pfc4112',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.pfc4112.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_pfc4112')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.pfc4112.emission_tgee = value

                                setValue('scope3_category_10_pfc4112', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc4112.type,
                                  'pfc4112',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.pfc4112.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_pfc4112')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.pfc4112.emission_tgee = value

                                setValue('scope3_category_11_pfc4112', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc4112.type,
                                  'pfc4112',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.pfc4112.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_pfc4112')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.pfc4112.emission_tgee = value

                                setValue('scope3_category_12_pfc4112', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc4112.type,
                                  'pfc4112',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.pfc4112.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.pfc5114.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_pfc5114')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.pfc5114.emission_tgee = value

                                setValue('scope3_category_9_pfc5114', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc5114.type,
                                  'pfc5114',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.pfc5114.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_pfc5114')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.pfc5114.emission_tgee = value

                                setValue('scope3_category_10_pfc5114', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc5114.type,
                                  'pfc5114',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.pfc5114.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_pfc5114')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.pfc5114.emission_tgee = value

                                setValue('scope3_category_11_pfc5114', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc5114.type,
                                  'pfc5114',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.pfc5114.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_pfc5114')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.pfc5114.emission_tgee = value

                                setValue('scope3_category_12_pfc5114', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc5114.type,
                                  'pfc5114',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.pfc5114.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.pfc9118.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_pfc9118')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.pfc9118.emission_tgee = value

                                setValue('scope3_category_9_pfc9118', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc9118.type,
                                  'pfc9118',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.pfc9118.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_pfc9118')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.pfc9118.emission_tgee = value

                                setValue('scope3_category_10_pfc9118', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc9118.type,
                                  'pfc9118',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.pfc9118.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_pfc9118')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.pfc9118.emission_tgee = value

                                setValue('scope3_category_11_pfc9118', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc9118.type,
                                  'pfc9118',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.pfc9118.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_pfc9118')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.pfc9118.emission_tgee = value

                                setValue('scope3_category_12_pfc9118', formatValue)

                                handleChangeValue(
                                  scope3.category_9.pfc9118.type,
                                  'pfc9118',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.pfc9118.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.triPentafluoreto.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_triPentafluoreto')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.triPentafluoreto.emission_tgee = value

                                setValue(
                                  'scope3_category_9_triPentafluoreto',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope3.category_9.triPentafluoreto.type,
                                  'triPentafluoreto',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.triPentafluoreto.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_triPentafluoreto')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.triPentafluoreto.emission_tgee = value

                                setValue(
                                  'scope3_category_10_triPentafluoreto',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope3.category_9.triPentafluoreto.type,
                                  'triPentafluoreto',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.triPentafluoreto.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_triPentafluoreto')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.triPentafluoreto.emission_tgee = value

                                setValue(
                                  'scope3_category_11_triPentafluoreto',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope3.category_9.triPentafluoreto.type,
                                  'triPentafluoreto',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.triPentafluoreto.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_triPentafluoreto')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.triPentafluoreto.emission_tgee = value

                                setValue(
                                  'scope3_category_12_triPentafluoreto',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope3.category_9.triPentafluoreto.type,
                                  'triPentafluoreto',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.triPentafluoreto.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.perfluorociclopropano.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_perfluorociclopropano')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.perfluorociclopropano.emission_tgee =
                                  value

                                setValue(
                                  'scope3_category_9_perfluorociclopropano',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope3.category_9.perfluorociclopropano.type,
                                  'perfluorociclopropano',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.perfluorociclopropano.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_perfluorociclopropano')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.perfluorociclopropano.emission_tgee =
                                  value

                                setValue(
                                  'scope3_category_10_perfluorociclopropano',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope3.category_9.perfluorociclopropano.type,
                                  'perfluorociclopropano',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.perfluorociclopropano.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_perfluorociclopropano')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.perfluorociclopropano.emission_tgee =
                                  value

                                setValue(
                                  'scope3_category_11_perfluorociclopropano',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope3.category_9.perfluorociclopropano.type,
                                  'perfluorociclopropano',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.perfluorociclopropano.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_perfluorociclopropano')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.perfluorociclopropano.emission_tgee =
                                  value

                                setValue(
                                  'scope3_category_12_perfluorociclopropano',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope3.category_9.perfluorociclopropano.type,
                                  'perfluorociclopropano',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.perfluorociclopropano.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.sf6.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_sf6')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.sf6.emission_tgee = value

                                setValue('scope3_category_9_sf6', formatValue)

                                handleChangeValue(scope3.category_9.sf6.type, 'sf6', 9)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_9.sf6.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_sf6')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.sf6.emission_tgee = value

                                setValue('scope3_category_10_sf6', formatValue)

                                handleChangeValue(scope3.category_9.sf6.type, 'sf6', 10)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_10.sf6.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_sf6')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.sf6.emission_tgee = value

                                setValue('scope3_category_11_sf6', formatValue)

                                handleChangeValue(scope3.category_9.sf6.type, 'sf6', 11)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_11.sf6.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_sf6')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.sf6.emission_tgee = value

                                setValue('scope3_category_12_sf6', formatValue)

                                handleChangeValue(scope3.category_9.sf6.type, 'sf6', 12)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_12.sf6.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.nf3.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_nf3')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.nf3.emission_tgee = value

                                setValue('scope3_category_9_nf3', formatValue)

                                handleChangeValue(scope3.category_9.nf3.type, 'nf3', 9)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_9.nf3.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_nf3')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.nf3.emission_tgee = value

                                setValue('scope3_category_10_nf3', formatValue)

                                handleChangeValue(scope3.category_9.nf3.type, 'nf3', 10)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_10.nf3.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_nf3')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.nf3.emission_tgee = value

                                setValue('scope3_category_11_nf3', formatValue)

                                handleChangeValue(scope3.category_9.nf3.type, 'nf3', 11)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_11.nf3.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_nf3')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.nf3.emission_tgee = value

                                setValue('scope3_category_12_nf3', formatValue)

                                handleChangeValue(scope3.category_9.nf3.type, 'nf3', 12)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope3.category_12.nf3.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr style={{ height: 40 }}>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.co2et.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}></td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.co2et.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}></td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.co2et.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}></td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.co2et.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}></td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.co2et.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.co2Bio.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_co2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.co2Bio.emission_tgee = value

                                setValue('scope3_category_9_co2Bio', formatValue)

                                handleChangeValue(
                                  scope3.category_9.co2Bio.type,
                                  'co2Bio',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.co2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_co2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.co2Bio.emission_tgee = value

                                setValue('scope3_category_10_co2Bio', formatValue)

                                handleChangeValue(
                                  scope3.category_9.co2Bio.type,
                                  'co2Bio',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.co2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_co2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.co2Bio.emission_tgee = value

                                setValue('scope3_category_11_co2Bio', formatValue)

                                handleChangeValue(
                                  scope3.category_9.co2Bio.type,
                                  'co2Bio',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.co2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_co2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.co2Bio.emission_tgee = value

                                setValue('scope3_category_12_co2Bio', formatValue)

                                handleChangeValue(
                                  scope3.category_9.co2Bio.type,
                                  'co2Bio',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.co2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope3.category_9.removalCo2Bio.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_9_removalCo2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_9.removalCo2Bio.emission_tgee = value

                                setValue('scope3_category_9_removalCo2Bio', formatValue)

                                handleChangeValue(
                                  scope3.category_9.removalCo2Bio.type,
                                  'removalCo2Bio',
                                  9
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_9.removalCo2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_10_removalCo2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_10.removalCo2Bio.emission_tgee = value

                                setValue('scope3_category_10_removalCo2Bio', formatValue)

                                handleChangeValue(
                                  scope3.category_9.removalCo2Bio.type,
                                  'removalCo2Bio',
                                  10
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_10.removalCo2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_11_removalCo2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_11.removalCo2Bio.emission_tgee = value

                                setValue('scope3_category_11_removalCo2Bio', formatValue)

                                handleChangeValue(
                                  scope3.category_9.removalCo2Bio.type,
                                  'removalCo2Bio',
                                  11
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_11.removalCo2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope3_category_12_removalCo2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope3.category_12.removalCo2Bio.emission_tgee = value

                                setValue('scope3_category_12_removalCo2Bio', formatValue)

                                handleChangeValue(
                                  scope3.category_9.removalCo2Bio.type,
                                  'removalCo2Bio',
                                  12
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope3.category_12.removalCo2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                )}
              </div>
            </div>
          )}

          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeScope4 && titles && titles.length > 0 && (
                  <table
                    style={{
                      maxWidth: '98%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th
                          className="head-tr-item"
                          style={{ width: '20%' }}
                          rowSpan={3}
                        ></th>
                        <th className="head-tr-item" style={{ width: '20%' }} colSpan={2}>
                          {'Categoria 13'}
                        </th>
                        <th className="head-tr-item" style={{ width: '20%' }} colSpan={2}>
                          {'Categoria 14'}
                        </th>
                        <th className="head-tr-item" style={{ width: '20%' }} colSpan={2}>
                          {'Categoria 15'}
                        </th>
                        <th className="head-tr-item" style={{ width: '20%' }} colSpan={2}>
                          {'Categoria 16'}
                        </th>
                      </tr>
                      <tr>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: '20%' }}
                          colSpan={2}
                        >
                          {titles[12]}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: '20%' }}
                          colSpan={2}
                        >
                          {titles[13]}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: '20%' }}
                          colSpan={2}
                        >
                          {titles[14]}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: '20%' }}
                          colSpan={2}
                        >
                          {titles[15]}
                        </th>
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t GEE'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t CO₂e'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t GEE'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t CO₂e'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t GEE'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t CO₂e'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t GEE'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: '10%' }}>
                          {'t CO₂e'}
                        </th>
                      </tr>
                    </thead>
                    {changeScope4 && scope4 && (
                      <tbody>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.co2.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_co2')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.co2.emission_tgee = value

                                setValue('scope4_category_13_co2', formatValue)

                                handleChangeValue(scope4.category_13.co2.type, 'co2', 13)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_13.co2.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_co2')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.co2.emission_tgee = value

                                setValue('scope4_category_14_co2', formatValue)

                                handleChangeValue(scope4.category_13.co2.type, 'co2', 14)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_14.co2.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_co2')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.co2.emission_tgee = value

                                setValue('scope4_category_15_co2', formatValue)

                                handleChangeValue(scope4.category_13.co2.type, 'co2', 15)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_15.co2.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_co2')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.co2.emission_tgee = value

                                setValue('scope4_category_16_co2', formatValue)

                                handleChangeValue(scope4.category_13.co2.type, 'co2', 16)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_16.co2.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.ch4.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_ch4')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.ch4.emission_tgee = value

                                setValue('scope4_category_13_ch4', formatValue)

                                handleChangeValue(scope4.category_13.ch4.type, 'ch4', 13)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_13.ch4.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_ch4')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.ch4.emission_tgee = value

                                setValue('scope4_category_14_ch4', formatValue)

                                handleChangeValue(scope4.category_13.ch4.type, 'ch4', 14)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_14.ch4.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_ch4')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.ch4.emission_tgee = value

                                setValue('scope4_category_15_ch4', formatValue)

                                handleChangeValue(scope4.category_13.ch4.type, 'ch4', 15)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_15.ch4.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_ch4')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.ch4.emission_tgee = value

                                setValue('scope4_category_16_ch4', formatValue)

                                handleChangeValue(scope4.category_13.ch4.type, 'ch4', 16)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_16.ch4.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.n2o.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_n2o')}
                              onChange={(event) => {
                                const formatValue = currency(event)
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.n2o.emission_tgee = value

                                setValue('scope4_category_13_n2o', formatValue)

                                handleChangeValue(scope4.category_13.n2o.type, 'n2o', 13)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_13.n2o.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_n2o')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.n2o.emission_tgee = value

                                setValue('scope4_category_14_n2o', formatValue)

                                handleChangeValue(scope4.category_13.n2o.type, 'n2o', 14)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_14.n2o.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_n2o')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.n2o.emission_tgee = value

                                setValue('scope4_category_15_n2o', formatValue)

                                handleChangeValue(scope4.category_13.n2o.type, 'n2o', 15)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_15.n2o.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_n2o')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.n2o.emission_tgee = value

                                setValue('scope4_category_16_n2o', formatValue)

                                handleChangeValue(scope4.category_13.n2o.type, 'n2o', 16)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_16.n2o.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr style={{ height: 40 }}>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_13.hfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_13.hfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_14.hfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_14.hfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_15.hfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_15.hfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_16.hfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_16.hfc.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc23.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc23')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc23.emission_tgee = value

                                setValue('scope4_category_13_hfc23', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc23.type,
                                  'hfc23',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc23.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc23')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc23.emission_tgee = value

                                setValue('scope4_category_14_hfc23', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc23.type,
                                  'hfc23',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc23.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc23')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc23.emission_tgee = value

                                setValue('scope4_category_15_hfc23', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc23.type,
                                  'hfc23',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc23.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc23')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc23.emission_tgee = value

                                setValue('scope4_category_16_hfc23', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc23.type,
                                  'hfc23',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc23.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc32.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc32')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc32.emission_tgee = value

                                setValue('scope4_category_13_hfc32', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc32.type,
                                  'hfc32',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc32.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc32')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc32.emission_tgee = value

                                setValue('scope4_category_14_hfc32', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc32.type,
                                  'hfc32',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc32.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc32')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc32.emission_tgee = value

                                setValue('scope4_category_15_hfc32', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc32.type,
                                  'hfc32',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc32.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc32')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc32.emission_tgee = value

                                setValue('scope4_category_16_hfc32', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc32.type,
                                  'hfc32',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc32.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc41.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc41')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc41.emission_tgee = value

                                setValue('scope4_category_13_hfc41', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc41.type,
                                  'hfc41',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc41.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc41')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc41.emission_tgee = value

                                setValue('scope4_category_14_hfc41', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc41.type,
                                  'hfc41',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc41.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc41')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc41.emission_tgee = value

                                setValue('scope4_category_15_hfc41', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc41.type,
                                  'hfc41',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc41.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc41')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc41.emission_tgee = value

                                setValue('scope4_category_16_hfc41', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc41.type,
                                  'hfc41',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc41.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc125.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc125')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc125.emission_tgee = value

                                setValue('scope4_category_13_hfc125', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc125.type,
                                  'hfc125',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc125.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc125')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc125.emission_tgee = value

                                setValue('scope4_category_14_hfc125', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc125.type,
                                  'hfc125',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc125.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc125')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc125.emission_tgee = value

                                setValue('scope4_category_15_hfc125', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc125.type,
                                  'hfc125',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc125.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc125')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc125.emission_tgee = value

                                setValue('scope4_category_16_hfc125', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc125.type,
                                  'hfc125',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc125.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc134.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc134')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc134.emission_tgee = value

                                setValue('scope4_category_13_hfc134', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc134.type,
                                  'hfc134',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc134.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc134')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc134.emission_tgee = value

                                setValue('scope4_category_14_hfc134', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc134.type,
                                  'hfc134',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc134.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc134')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc134.emission_tgee = value

                                setValue('scope4_category_15_hfc134', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc134.type,
                                  'hfc134',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc134.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc134')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc134.emission_tgee = value

                                setValue('scope4_category_16_hfc134', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc134.type,
                                  'hfc134',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc134.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc134a.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc134a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc134a.emission_tgee = value

                                setValue('scope4_category_13_hfc134a', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc134a.type,
                                  'hfc134a',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc134a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc134a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc134a.emission_tgee = value

                                setValue('scope4_category_14_hfc134a', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc134a.type,
                                  'hfc134a',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc134a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc134a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc134a.emission_tgee = value

                                setValue('scope4_category_15_hfc134a', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc134a.type,
                                  'hfc134a',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc134a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc134a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc134a.emission_tgee = value

                                setValue('scope4_category_16_hfc134a', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc134a.type,
                                  'hfc134a',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc134a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc143.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc143')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc143.emission_tgee = value

                                setValue('scope4_category_13_hfc143', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc143.type,
                                  'hfc143',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc143.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc143')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc143.emission_tgee = value

                                setValue('scope4_category_14_hfc143', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc143.type,
                                  'hfc143',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc143.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc143')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc143.emission_tgee = value

                                setValue('scope4_category_15_hfc143', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc143.type,
                                  'hfc143',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc143.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc143')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc143.emission_tgee = value

                                setValue('scope4_category_16_hfc143', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc143.type,
                                  'hfc143',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc143.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc143a.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc143a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc143a.emission_tgee = value

                                setValue('scope4_category_13_hfc143a', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc143a.type,
                                  'hfc143a',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc143a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc143a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc143a.emission_tgee = value

                                setValue('scope4_category_14_hfc143a', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc143a.type,
                                  'hfc143a',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc143a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc143a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc143a.emission_tgee = value

                                setValue('scope4_category_15_hfc143a', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc143a.type,
                                  'hfc143a',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc143a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc143a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc143a.emission_tgee = value

                                setValue('scope4_category_16_hfc143a', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc143a.type,
                                  'hfc143a',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc143a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc152.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc152')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc152.emission_tgee = value

                                setValue('scope4_category_13_hfc152', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc152.type,
                                  'hfc152',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc152.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc152')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc152.emission_tgee = value

                                setValue('scope4_category_14_hfc152', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc152.type,
                                  'hfc152',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc152.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc152')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc152.emission_tgee = value

                                setValue('scope4_category_15_hfc152', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc152.type,
                                  'hfc152',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc152.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc152')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc152.emission_tgee = value

                                setValue('scope4_category_16_hfc152', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc152.type,
                                  'hfc152',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc152.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc152a.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc152a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc152a.emission_tgee = value

                                setValue('scope4_category_13_hfc152a', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc152a.type,
                                  'hfc152a',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc152a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc152a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc152a.emission_tgee = value

                                setValue('scope4_category_14_hfc152a', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc152a.type,
                                  'hfc152a',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc152a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc152a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc152a.emission_tgee = value

                                setValue('scope4_category_15_hfc152a', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc152a.type,
                                  'hfc152a',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc152a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc152a')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc152a.emission_tgee = value

                                setValue('scope4_category_16_hfc152a', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc152a.type,
                                  'hfc152a',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc152a.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc161.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc161')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc161.emission_tgee = value

                                setValue('scope4_category_13_hfc161', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc161.type,
                                  'hfc161',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc161.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc161')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc161.emission_tgee = value

                                setValue('scope4_category_14_hfc161', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc161.type,
                                  'hfc161',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc161.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc161')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc161.emission_tgee = value

                                setValue('scope4_category_15_hfc161', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc161.type,
                                  'hfc161',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc161.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc161')}
                              onChange={(event) => {
                                const formatValue = currency(event)
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc161.emission_tgee = value

                                setValue('scope4_category_16_hfc161', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc161.type,
                                  'hfc161',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc161.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc227ea.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc227ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc227ea.emission_tgee = value

                                setValue('scope4_category_13_hfc227ea', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc227ea.type,
                                  'hfc227ea',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc227ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc227ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc227ea.emission_tgee = value

                                setValue('scope4_category_14_hfc227ea', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc227ea.type,
                                  'hfc227ea',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc227ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc227ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc227ea.emission_tgee = value

                                setValue('scope4_category_15_hfc227ea', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc227ea.type,
                                  'hfc227ea',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc227ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc227ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc227ea.emission_tgee = value

                                setValue('scope4_category_16_hfc227ea', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc227ea.type,
                                  'hfc227ea',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc227ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc236cb.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc236cb')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc236cb.emission_tgee = value

                                setValue('scope4_category_13_hfc236cb', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc236cb.type,
                                  'hfc236cb',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc236cb.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc236cb')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc236cb.emission_tgee = value

                                setValue('scope4_category_14_hfc236cb', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc236cb.type,
                                  'hfc236cb',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc236cb.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc236cb')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc236cb.emission_tgee = value

                                setValue('scope4_category_15_hfc236cb', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc236cb.type,
                                  'hfc236cb',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc236cb.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc236cb')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc236cb.emission_tgee = value

                                setValue('scope4_category_16_hfc236cb', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc236cb.type,
                                  'hfc236cb',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc236cb.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc236ea.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc236ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc236ea.emission_tgee = value

                                setValue('scope4_category_13_hfc236ea', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc236ea.type,
                                  'hfc236ea',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc236ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc236ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc236ea.emission_tgee = value

                                setValue('scope4_category_14_hfc236ea', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc236ea.type,
                                  'hfc236ea',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc236ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc236ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc236ea.emission_tgee = value

                                setValue('scope4_category_15_hfc236ea', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc236ea.type,
                                  'hfc236ea',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc236ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc236ea')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc236ea.emission_tgee = value

                                setValue('scope4_category_16_hfc236ea', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc236ea.type,
                                  'hfc236ea',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc236ea.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc236fa.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc236fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc236fa.emission_tgee = value

                                setValue('scope4_category_13_hfc236fa', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc236fa.type,
                                  'hfc236fa',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc236fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc236fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc236fa.emission_tgee = value

                                setValue('scope4_category_14_hfc236fa', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc236fa.type,
                                  'hfc236fa',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc236fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc236fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc236fa.emission_tgee = value

                                setValue('scope4_category_15_hfc236fa', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc236fa.type,
                                  'hfc236fa',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc236fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc236fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc236fa.emission_tgee = value

                                setValue('scope4_category_16_hfc236fa', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc236fa.type,
                                  'hfc236fa',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc236fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc245ca.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc245ca')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc245ca.emission_tgee = value

                                setValue('scope4_category_13_hfc245ca', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc245ca.type,
                                  'hfc245ca',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc245ca.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc245ca')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc245ca.emission_tgee = value

                                setValue('scope4_category_14_hfc245ca', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc245ca.type,
                                  'hfc245ca',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc245ca.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc245ca')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc245ca.emission_tgee = value

                                setValue('scope4_category_15_hfc245ca', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc245ca.type,
                                  'hfc245ca',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc245ca.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc245ca')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc245ca.emission_tgee = value

                                setValue('scope4_category_16_hfc245ca', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc245ca.type,
                                  'hfc245ca',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc245ca.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc245fa.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc245fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc245fa.emission_tgee = value

                                setValue('scope4_category_13_hfc245fa', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc236fa.type,
                                  'hfc236fa',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc245fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc245fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc245fa.emission_tgee = value

                                setValue('scope4_category_14_hfc245fa', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc236fa.type,
                                  'hfc236fa',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc245fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc245fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc245fa.emission_tgee = value

                                setValue('scope4_category_15_hfc245fa', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc236fa.type,
                                  'hfc236fa',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc245fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc245fa')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc245fa.emission_tgee = value

                                setValue('scope4_category_16_hfc245fa', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc236fa.type,
                                  'hfc236fa',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc245fa.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc365mfc.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc365mfc')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc365mfc.emission_tgee = value

                                setValue('scope4_category_13_hfc365mfc', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc365mfc.type,
                                  'hfc365mfc',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc365mfc.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc365mfc')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc365mfc.emission_tgee = value

                                setValue('scope4_category_14_hfc365mfc', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc365mfc.type,
                                  'hfc365mfc',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc365mfc.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc365mfc')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc365mfc.emission_tgee = value

                                setValue('scope4_category_15_hfc365mfc', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc365mfc.type,
                                  'hfc365mfc',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc365mfc.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc365mfc')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc365mfc.emission_tgee = value

                                setValue('scope4_category_16_hfc365mfc', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc365mfc.type,
                                  'hfc365mfc',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc365mfc.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.hfc4310mee.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_hfc4310mee')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.hfc4310mee.emission_tgee = value

                                setValue('scope4_category_13_hfc4310mee', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc4310mee.type,
                                  'hfc4310mee',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.hfc4310mee.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_hfc4310mee')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.hfc4310mee.emission_tgee = value

                                setValue('scope4_category_14_hfc4310mee', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc4310mee.type,
                                  'hfc4310mee',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.hfc4310mee.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_hfc4310mee')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.hfc4310mee.emission_tgee = value

                                setValue('scope4_category_15_hfc4310mee', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc4310mee.type,
                                  'hfc4310mee',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.hfc4310mee.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_hfc4310mee')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.hfc4310mee.emission_tgee = value

                                setValue('scope4_category_16_hfc4310mee', formatValue)

                                handleChangeValue(
                                  scope4.category_13.hfc4310mee.type,
                                  'hfc4310mee',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.hfc4310mee.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr style={{ height: 40 }}>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.pfc.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_13.pfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_13.pfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_14.pfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_14.pfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_15.pfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_15.pfc.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_16.pfc.emission_tgee, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_16.pfc.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.pfc14.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_pfc14')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.pfc14.emission_tgee = value

                                setValue('scope4_category_13_pfc14', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc14.type,
                                  'pfc14',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.pfc14.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_pfc14')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.pfc14.emission_tgee = value

                                setValue('scope4_category_14_pfc14', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc14.type,
                                  'pfc14',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.pfc14.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_pfc14')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.pfc14.emission_tgee = value

                                setValue('scope4_category_15_pfc14', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc14.type,
                                  'pfc14',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.pfc14.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_pfc14')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.pfc14.emission_tgee = value

                                setValue('scope4_category_16_pfc14', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc14.type,
                                  'pfc14',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.pfc14.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.pfc116.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_pfc116')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.pfc116.emission_tgee = value

                                setValue('scope4_category_13_pfc116', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc116.type,
                                  'pfc116',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.pfc116.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_pfc116')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.pfc116.emission_tgee = value

                                setValue('scope4_category_14_pfc116', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc116.type,
                                  'pfc116',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.pfc116.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_pfc116')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.pfc116.emission_tgee = value

                                setValue('scope4_category_15_pfc116', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc116.type,
                                  'pfc116',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.pfc116.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_pfc116')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.pfc116.emission_tgee = value

                                setValue('scope4_category_16_pfc116', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc116.type,
                                  'pfc116',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.pfc116.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.pfc218.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_pfc218')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.pfc218.emission_tgee = value

                                setValue('scope4_category_13_pfc218', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc218.type,
                                  'pfc218',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.pfc218.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_pfc218')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.pfc218.emission_tgee = value

                                setValue('scope4_category_14_pfc218', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc218.type,
                                  'pfc218',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.pfc218.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_pfc218')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.pfc218.emission_tgee = value

                                setValue('scope4_category_15_pfc218', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc218.type,
                                  'pfc218',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.pfc218.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_pfc218')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.pfc218.emission_tgee = value

                                setValue('scope4_category_16_pfc218', formatValue)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.pfc218.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.pfc318.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_pfc318')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.pfc318.emission_tgee = value

                                setValue('scope4_category_13_pfc318', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc318.type,
                                  'pfc318',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.pfc318.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_pfc318')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.pfc318.emission_tgee = value

                                setValue('scope4_category_14_pfc318', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc318.type,
                                  'pfc318',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.pfc318.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_pfc318')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.pfc318.emission_tgee = value

                                setValue('scope4_category_15_pfc318', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc318.type,
                                  'pfc318',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.pfc318.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_pfc318')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.pfc318.emission_tgee = value

                                setValue('scope4_category_16_pfc318', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc318.type,
                                  'pfc318',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.pfc318.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.pfc3110.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_pfc3110')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.pfc3110.emission_tgee = value

                                setValue('scope4_category_13_pfc3110', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc3110.type,
                                  'pfc3110',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.pfc3110.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_pfc3110')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.pfc3110.emission_tgee = value

                                setValue('scope4_category_14_pfc3110', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc3110.type,
                                  'pfc3110',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.pfc3110.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_pfc3110')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.pfc3110.emission_tgee = value

                                setValue('scope4_category_15_pfc3110', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc3110.type,
                                  'pfc3110',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.pfc3110.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_pfc3110')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.pfc3110.emission_tgee = value

                                setValue('scope4_category_16_pfc3110', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc3110.type,
                                  'pfc3110',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.pfc3110.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.pfc4112.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_pfc4112')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.pfc4112.emission_tgee = value

                                setValue('scope4_category_13_pfc4112', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc4112.type,
                                  'pfc4112',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.pfc4112.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_pfc4112')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.pfc4112.emission_tgee = value

                                setValue('scope4_category_14_pfc4112', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc4112.type,
                                  'pfc4112',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.pfc4112.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_pfc4112')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.pfc4112.emission_tgee = value

                                setValue('scope4_category_15_pfc4112', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc4112.type,
                                  'pfc4112',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.pfc4112.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_pfc4112')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.pfc4112.emission_tgee = value

                                setValue('scope4_category_16_pfc4112', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc4112.type,
                                  'pfc4112',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.pfc4112.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.pfc5114.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_pfc5114')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.pfc5114.emission_tgee = value

                                setValue('scope4_category_13_pfc5114', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc5114.type,
                                  'pfc5114',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.pfc5114.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_pfc5114')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.pfc5114.emission_tgee = value

                                setValue('scope4_category_14_pfc5114', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc5114.type,
                                  'pfc5114',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.pfc5114.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_pfc5114')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.pfc5114.emission_tgee = value

                                setValue('scope4_category_15_pfc5114', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc5114.type,
                                  'pfc5114',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.pfc5114.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_pfc5114')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.pfc5114.emission_tgee = value

                                setValue('scope4_category_16_pfc5114', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc5114.type,
                                  'pfc5114',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.pfc5114.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.pfc9118.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_pfc9118')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.pfc9118.emission_tgee = value

                                setValue('scope4_category_13_pfc9118', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc9118.type,
                                  'pfc9118',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.pfc9118.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_pfc9118')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.pfc9118.emission_tgee = value

                                setValue('scope4_category_14_pfc9118', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc9118.type,
                                  'pfc9118',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.pfc9118.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_pfc9118')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.pfc9118.emission_tgee = value

                                setValue('scope4_category_15_pfc9118', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc9118.type,
                                  'pfc9118',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.pfc9118.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_pfc9118')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.pfc9118.emission_tgee = value

                                setValue('scope4_category_16_pfc9118', formatValue)

                                handleChangeValue(
                                  scope4.category_13.pfc9118.type,
                                  'pfc9118',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.pfc9118.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.triPentafluoreto.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_triPentafluoreto')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.triPentafluoreto.emission_tgee = value

                                setValue(
                                  'scope4_category_13_triPentafluoreto',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope4.category_13.triPentafluoreto.type,
                                  'triPentafluoreto',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.triPentafluoreto.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_triPentafluoreto')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.triPentafluoreto.emission_tgee = value

                                setValue(
                                  'scope4_category_14_triPentafluoreto',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope4.category_13.triPentafluoreto.type,
                                  'triPentafluoreto',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.triPentafluoreto.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_triPentafluoreto')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.triPentafluoreto.emission_tgee = value

                                setValue(
                                  'scope4_category_15_triPentafluoreto',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope4.category_13.triPentafluoreto.type,
                                  'triPentafluoreto',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.triPentafluoreto.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_triPentafluoreto')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.triPentafluoreto.emission_tgee = value

                                setValue(
                                  'scope4_category_16_triPentafluoreto',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope4.category_13.triPentafluoreto.type,
                                  'triPentafluoreto',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.triPentafluoreto.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.perfluorociclopropano.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_perfluorociclopropano')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.perfluorociclopropano.emission_tgee =
                                  value

                                setValue(
                                  'scope4_category_13_perfluorociclopropano',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope4.category_13.perfluorociclopropano.type,
                                  'perfluorociclopropano',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.perfluorociclopropano.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_perfluorociclopropano')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.perfluorociclopropano.emission_tgee =
                                  value

                                setValue(
                                  'scope4_category_14_perfluorociclopropano',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope4.category_13.perfluorociclopropano.type,
                                  'perfluorociclopropano',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.perfluorociclopropano.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_perfluorociclopropano')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.perfluorociclopropano.emission_tgee =
                                  value

                                setValue(
                                  'scope4_category_15_perfluorociclopropano',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope4.category_13.perfluorociclopropano.type,
                                  'perfluorociclopropano',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.perfluorociclopropano.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_perfluorociclopropano')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.perfluorociclopropano.emission_tgee =
                                  value

                                setValue(
                                  'scope4_category_16_perfluorociclopropano',
                                  formatValue
                                )

                                handleChangeValue(
                                  scope4.category_13.perfluorociclopropano.type,
                                  'perfluorociclopropano',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.perfluorociclopropano.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.sf6.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_sf6')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.sf6.emission_tgee = value

                                setValue('scope4_category_13_sf6', formatValue)

                                handleChangeValue(scope4.category_13.sf6.type, 'sf6', 13)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_13.sf6.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_sf6')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.sf6.emission_tgee = value

                                setValue('scope4_category_14_sf6', formatValue)

                                handleChangeValue(scope4.category_13.sf6.type, 'sf6', 14)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_14.sf6.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_sf6')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.sf6.emission_tgee = value

                                setValue('scope4_category_15_sf6', formatValue)

                                handleChangeValue(scope4.category_13.sf6.type, 'sf6', 15)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_15.sf6.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_sf6')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.sf6.emission_tgee = value

                                setValue('scope4_category_16_sf6', formatValue)

                                handleChangeValue(scope4.category_13.sf6.type, 'sf6', 16)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_16.sf6.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.nf3.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_nf3')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.nf3.emission_tgee = value

                                setValue('scope4_category_13_nf3', formatValue)

                                handleChangeValue(scope4.category_13.nf3.type, 'nf3', 13)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_13.nf3.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_nf3')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.nf3.emission_tgee = value

                                setValue('scope4_category_14_nf3', formatValue)

                                handleChangeValue(scope4.category_13.nf3.type, 'nf3', 14)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_14.nf3.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_nf3')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.nf3.emission_tgee = value

                                setValue('scope4_category_15_nf3', formatValue)

                                handleChangeValue(scope4.category_13.nf3.type, 'nf3', 15)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_15.nf3.emission_tco2e, false)}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_nf3')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.nf3.emission_tgee = value

                                setValue('scope4_category_16_nf3', formatValue)

                                handleChangeValue(scope4.category_13.nf3.type, 'nf3', 16)
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(scope4.category_16.nf3.emission_tco2e, false)}
                            </span>
                          </td>
                        </tr>
                        <tr style={{ height: 40 }}>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.co2et.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}></td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.co2et.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}></td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.co2et.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}></td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.co2et.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}></td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.co2et.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.co2Bio.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_co2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.co2Bio.emission_tgee = value

                                setValue('scope4_category_13_co2Bio', formatValue)

                                handleChangeValue(
                                  scope4.category_13.co2Bio.type,
                                  'co2Bio',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.co2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_co2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.co2Bio.emission_tgee = value

                                setValue('scope4_category_14_co2Bio', formatValue)

                                handleChangeValue(
                                  scope4.category_13.co2Bio.type,
                                  'co2Bio',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.co2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_co2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.co2Bio.emission_tgee = value

                                setValue('scope4_category_15_co2Bio', formatValue)

                                handleChangeValue(
                                  scope4.category_13.co2Bio.type,
                                  'co2Bio',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.co2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_co2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.co2Bio.emission_tgee = value

                                setValue('scope4_category_16_co2Bio', formatValue)

                                handleChangeValue(
                                  scope4.category_13.co2Bio.type,
                                  'co2Bio',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.co2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '20%' }}>
                            <span
                              style={{
                                marginLeft: 5,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 'bold',
                                display: 'inline-block'
                              }}
                            >
                              {scope4.category_13.removalCo2Bio.label}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_13_removalCo2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_13.removalCo2Bio.emission_tgee = value

                                setValue('scope4_category_13_removalCo2Bio', formatValue)

                                handleChangeValue(
                                  scope4.category_13.removalCo2Bio.type,
                                  'removalCo2Bio',
                                  13
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_13.removalCo2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_14_removalCo2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_14.removalCo2Bio.emission_tgee = value

                                setValue('scope4_category_14_removalCo2Bio', formatValue)

                                handleChangeValue(
                                  scope4.category_13.removalCo2Bio.type,
                                  'removalCo2Bio',
                                  14
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_14.removalCo2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_15_removalCo2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_15.removalCo2Bio.emission_tgee = value

                                setValue('scope4_category_15_removalCo2Bio', formatValue)

                                handleChangeValue(
                                  scope4.category_13.removalCo2Bio.type,
                                  'removalCo2Bio',
                                  15
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_15.removalCo2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                          <td style={{ width: '10%' }}>
                            <input
                              readOnly={isCommonUser}
                              style={{ width: '95%', textAlign: 'right' }}
                              className="input-item"
                              {...register('scope4_category_16_removalCo2Bio')}
                              onChange={(event) => {
                                const formatValue = currency(event);
                                const valueStr = formatValue.replace('.', '')
                                const value = valueStr
                                  ? parseFloat(valueStr.replace(',', '.'))
                                  : 0

                                scope4.category_16.removalCo2Bio.emission_tgee = value

                                setValue('scope4_category_16_removalCo2Bio', formatValue)

                                handleChangeValue(
                                  scope4.category_13.removalCo2Bio.type,
                                  'removalCo2Bio',
                                  16
                                )
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <span
                              style={{
                                marginRight: 5,
                                width: '95%',
                                fontSize: 12,
                                display: 'inline-block',
                                textAlign: 'right'
                              }}
                            >
                              {formatPrice5(
                                scope4.category_16.removalCo2Bio.emission_tco2e,
                                false
                              )}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                )}
              </div>
            </div>
          )}

          {ignoreFill === false &&
            changeScope1 &&
            changeScope2 &&
            changeScope3 &&
            changeScope4 && (
              <div
                style={{
                  borderStyle: 'dashed',
                  margin: 5,
                  borderWidth: '1px',
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 330px)',
                  borderColor: '#9E9E9E',
                  height: '40px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 15
                }}
              >
                <span style={{ margin: 10, width: '70%' }}>
                  Emissões totais em CO₂ equivalente (toneladas métricas):
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    margin: 10,
                    textAlign: 'right',
                    width: '30%'
                  }}
                >
                  {formatPrice3(emissionTco2e, false)}
                </span>
              </div>
            )}

          {ignoreFill === false &&
            changeScope1 &&
            changeScope2 &&
            changeScope3 &&
            changeScope4 && (
              <div
                style={{
                  borderStyle: 'dashed',
                  margin: 5,
                  borderWidth: '1px',
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 330px)',
                  borderColor: '#9E9E9E',
                  height: '40px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 15
                }}
              >
                <span style={{ margin: 10, width: '70%' }}>
                  Emissões totais em CO₂ biogênico (toneladas métricas):
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    margin: 10,
                    textAlign: 'right',
                    width: '30%'
                  }}
                >
                  {formatPrice3(emissionCo2Bio, false)}
                </span>
              </div>
            )}

          {ignoreFill === false &&
            changeScope1 &&
            changeScope2 &&
            changeScope3 &&
            changeScope4 && (
              <div
                style={{
                  borderStyle: 'dashed',
                  margin: 5,
                  borderWidth: '1px',
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 330px)',
                  borderColor: '#9E9E9E',
                  height: '40px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 15
                }}
              >
                <span style={{ margin: 10, width: '70%' }}>
                  Remoções totais de CO₂ biogênico (toneladas métricas):
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    margin: 10,
                    textAlign: 'right',
                    width: '30%'
                  }}
                >
                  {formatPrice3(removalOfBioCo2, false)}
                </span>
              </div>
            )}

          {ignoreFill === false && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={downloadFile}
                  onChangeAttachment={attachmentChangeHandler}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && <span style={{ fontSize: 12 }}>{Parser(guidelines)}</span>}
        </div>
      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  )
}
