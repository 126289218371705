import React from "react";
import { Table } from "./styles";
import TrashIcon from "../../../../../../../assets/icons/trash.svg";
import { BsFillTrashFill } from "react-icons/bs";
import { FaRegTrashCan } from "react-icons/fa6";


interface HomeOfficeTableProps {
    fieldsMonthreadOnly?: boolean;
    homeOffice: any;
    typeHomeOffice: string;
    handleOpenModalDelete: () => void;
    isCommonUser: boolean;
    register: any;
    setValue: (a: string, event: any) => void;
    formatInputNumber: (e: any) => any;
    handleChangeValue: (index: any, value: string) => void;
    formatPrice3: (value: number, showCurrencyName: boolean) => string | 0;
    handleDeleteHomeOffice: (index: number) => void;
    handleDeleteAllScope: () => void;
    purchaseJan: any;
    purchaseFeb: any;
    purchaseMar: any;
    purchaseApr: any;
    purchaseMay: any;
    purchaseJun: any;
    purchaseJul: any;
    purchaseAug: any;
    purchaseSep: any;
    purchaseOct: any;
    purchaseNov: any;
    purchaseDec: any;
    purchaseYearly: any;
    purchaseTotal: any;
    emissionCo2: any;
    emissionCh4: any;
    emissionN2o: any;
};

export const HomeOfficeTable = ({ 
    homeOffice, handleOpenModalDelete, isCommonUser, register,setValue, formatInputNumber, 
    handleChangeValue, formatPrice3, handleDeleteHomeOffice, handleDeleteAllScope,
    purchaseJan, purchaseFeb, purchaseMar, purchaseApr, purchaseMay, purchaseJun, purchaseJul, 
    purchaseAug, purchaseSep, purchaseOct, purchaseNov, purchaseDec, purchaseYearly,
    purchaseTotal, emissionCo2, emissionCh4, emissionN2o, fieldsMonthreadOnly, typeHomeOffice,
}: HomeOfficeTableProps) => {
    return (

        <Table className="table-scope custom-scrollbar">
            <thead>
                <tr>
                    <th
                    className="head-tr-item"
                    style={{ width: 360 }}
                    colSpan={3}
                    >
                    {"Fonte"}
                    </th>
                    <th
                        className="head-tr-item"
                        style={{ width: 360 }}
                        colSpan={3}
                    >
                        {"Colaborador"}
                    </th>
                    
                    <th
                        className="head-tr-item"
                        style={{ width: 200 }}
                        colSpan={13}
                    >
                        {"Eletricidade Comprada (MWh)"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 250 }}
                        rowSpan={3}
                    >
                        {"Eletricidade total comprada (MWh)"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                    >
                        {"Emissões de CO₂ (t)"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                    >
                        {"Emissões de CH₄ (t)"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                    >
                        {"Emissões de N₂O (t)"}
                    </th>
                    {!isCommonUser && (
                        <th
                        className="head-tr-item"
                        style={{ width: 50 }}
                        rowSpan={3}
                        >
                        {!isCommonUser && (
                            <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>

                            <div onClick={handleOpenModalDelete}><button onClick={() => handleDeleteAllScope} style={{background: "#FBACAF", color: "#C42329", borderRadius: 4, borderColor: "#C42329", borderWidth: 1, height: 35, width: 60, margin: 10, cursor: "pointer",}}>Apagar Todos</button></div>
                            </td>
                        )}
                        </th>
                    )}
                </tr>
                <tr>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                    >
                        {"Registro"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                    >
                        {"Descrição"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                    >
                        {"Companhia de energia"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                    >
                    {"Dias trabalhados na semana em regime remoto"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                    >
                        {"Dias totais trabalhados no ano"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                    >
                        {"Consumo médio de eletricidade (MWh)"}
                    </th>
                    
                    <th
                        className="head-tr-item"
                        style={{ width: 200 }}
                        colSpan={12}
                    >
                        {"Relate aqui a compra mensal de eletricidade (MWh)"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 250 }}
                        rowSpan={2}
                    >
                        {"Relate aqui a compra anual de eletricidade (MWh)"}
                    </th>
                </tr>
                <tr>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                    >
                        {"Janeiro"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                    >
                        {"Fevereiro"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                    >
                        {"Março"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                    >
                        {"Abril"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                    >
                        {"Maio"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                    >
                        {"Junho"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                    >
                        {"Julho"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                    >
                        {"Agosto"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                    >
                        {"Setembro"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                    >
                        {"Outubro"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                    >
                        {"Novembro"}
                    </th>
                    <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                    >
                        {"Dezembro"}
                    </th>
                </tr>
            </thead>
            <tbody>
                {homeOffice.map((item: any, index: number) => {
                    return (
                        <React.Fragment key={index}>
                            <tr>
                                <td>
                                    <input
                                    readOnly={isCommonUser}
                                    style={{ width: 200 }}
                                    className="input-item"
                                    {...register(`${typeHomeOffice}.${index}.source`, {
                                        required: true,
                                    })}
                                    onChange={(event) => {
                                        setValue(
                                        `${typeHomeOffice}.${index}.source`,
                                        event.target.value
                                        );
                                        homeOffice[index].source = event.target.value;
                                    }}
                                    />
                                </td>
                                <td>
                                    <input
                                    readOnly={isCommonUser}
                                    style={{ width: 200 }}
                                    className="input-item"
                                    {...register(
                                        `${typeHomeOffice}.${index}.description`,
                                        { required: true }
                                    )}
                                    onChange={(event) => {
                                        setValue(
                                        `${typeHomeOffice}.${index}.description`,
                                        event.target.value
                                        );
                                        homeOffice[index].description =
                                        event.target.value;
                                    }}
                                    />
                                </td>
                                <td style={{ width: 200 }}>
                                    <span
                                    style={{
                                        marginLeft: 5,
                                        width: 200,
                                        fontSize: 12,
                                        fontWeight: "bold",
                                        display: "inline-block",
                                    }}
                                    >
                                    {homeOffice[index].private_name}
                                    </span>

                                    <br />

                                    {homeOffice[index].city &&
                                    homeOffice[index].state && (
                                        <span
                                        style={{
                                            marginLeft: 5,
                                            width: 300,
                                            fontSize: 12,
                                            display: "inline-block",
                                        }}
                                        >
                                        <b>{"Cidade: "}</b>
                                        {homeOffice[index].city +
                                            " - " +
                                            homeOffice[index].state}
                                        </span>
                                    )}

                                    {homeOffice[index].company_name && (
                                    <span
                                        style={{
                                        marginLeft: 5,
                                        width: 300,
                                        fontSize: 12,
                                        display: "inline-block",
                                        }}
                                    >
                                        <b>{"Companhia: "}</b>
                                        {homeOffice[index].company_name}
                                    </span>
                                    )}
                                </td>

                                                            
                                <td>
                                    <input
                                        readOnly={isCommonUser}
                                        style={{ width: 100, textAlign: "center" }}
                                        className="input-item"
                                        {...register(
                                        `${typeHomeOffice}.${index}.utils_day_work_home`
                                        )}
                                        onChange={(event) => {
                                        const { value, formatValue } =
                                            formatInputNumber(event);
                                        homeOffice[index].utils_day_work_home = value;

                                        setValue(
                                            `${typeHomeOffice}.${index}.utils_day_work_home`,
                                            formatValue
                                        );

                                        handleChangeValue(index, "utils_day_work_home");
                                        }}
                                    />
                                </td>
                                <td>
                                    <input
                                        readOnly={isCommonUser}
                                        style={{ width: 100, textAlign: "center" }}
                                        className="input-item"
                                        {...register(
                                        `${typeHomeOffice}.${index}.total_days_year`
                                        )}
                                        onChange={(event) => {
                                        const { value, formatValue } =
                                            formatInputNumber(event);

                                        homeOffice[index].total_days_year = value;

                                        setValue(
                                            `${typeHomeOffice}.${index}.total_days_year`,
                                            formatValue
                                        );

                                        handleChangeValue(index, "total_days_year");
                                        }}
                                    />
                                </td>
                                <td>
                                    <input
                                        readOnly={isCommonUser}
                                        style={{ width: 100, textAlign: "center" }}
                                        className="input-item"
                                        {...register(
                                        `${typeHomeOffice}.${index}.average_consume`
                                        )}
                                        onChange={(event) => {
                                        const { value, formatValue } =
                                            formatInputNumber(event);

                                        homeOffice[index].average_consume = value;

                                        setValue(
                                            `${typeHomeOffice}.${index}.average_consume`,
                                            formatValue
                                        );

                                        handleChangeValue(index, "average_consume");
                                        }}
                                    />
                                </td>
                                    

                                <td>
                                    {/* Começo da declaração de valores mensais */}

                                    {fieldsMonthreadOnly ? (
                                        <span
                                            style={{
                                            marginRight: 5,
                                            width: 100,
                                            fontSize: 12,
                                            display: "inline-block",
                                            textAlign: "right",
                                            }}
                                        >
                                            {formatPrice3(
                                            homeOffice[index].purchase_jan,
                                            false
                                            )}
                                        </span>
                                    ): (
                                        homeOffice[index].only_view_monthly === false && (
                                            <input
                                                readOnly={isCommonUser ? (isCommonUser) : ( fieldsMonthreadOnly ? (fieldsMonthreadOnly) : (false) )}
                                                style={{ width: 100, textAlign: "center" }}
                                                className="input-item"
                                                {...register(
                                                `${typeHomeOffice}.${index}.purchase_jan`
                                                )}
                                                onChange={(event) => {
                                                const { value, formatValue } =
                                                    formatInputNumber(event);
                                                homeOffice[index].purchase_jan = value;

                                                setValue(
                                                    `${typeHomeOffice}.${index}.purchase_jan`,
                                                    formatValue
                                                );

                                                handleChangeValue(index, "purchase_jan");
                                                }}
                                            />
                                        )
                                    )}
                                </td>
                                <td>
                                    {fieldsMonthreadOnly ? (
                                        <span
                                            style={{
                                            marginRight: 5,
                                            width: 100,
                                            fontSize: 12,
                                            display: "inline-block",
                                            textAlign: "right",
                                            }}
                                        >
                                            {formatPrice3(
                                            homeOffice[index].purchase_feb,
                                            false
                                            )}
                                        </span>
                                    ):(                                    
                                        homeOffice[index].only_view_monthly === false && (
                                            <input
                                                readOnly={isCommonUser ? (isCommonUser) : ( fieldsMonthreadOnly ? (fieldsMonthreadOnly) : (false) )}
                                                style={{ width: 100, textAlign: "center" }}
                                                className="input-item"
                                                {...register(
                                                `${typeHomeOffice}.${index}.purchase_feb`
                                                )}
                                                onChange={(event) => {
                                                const { value, formatValue } =
                                                    formatInputNumber(event);

                                                homeOffice[index].purchase_feb = value;

                                                setValue(
                                                    `${typeHomeOffice}.${index}.purchase_feb`,
                                                    formatValue
                                                );

                                                handleChangeValue(index, "purchase_feb");
                                                }}
                                            />
                                        )
                                    )}
                                </td>
                                <td>
                                    {fieldsMonthreadOnly ? (
                                        <span
                                            style={{
                                            marginRight: 5,
                                            width: 100,
                                            fontSize: 12,
                                            display: "inline-block",
                                            textAlign: "right",
                                            }}
                                        >
                                            {formatPrice3(
                                            homeOffice[index].purchase_mar,
                                            false
                                            )}
                                        </span>
                                    ):(
                                        homeOffice[index].only_view_monthly === false && (
                                            <input
                                                readOnly={isCommonUser ? (isCommonUser) : ( fieldsMonthreadOnly ? (fieldsMonthreadOnly) : (false) )}
                                                style={{ width: 100, textAlign: "center" }}
                                                className="input-item"
                                                {...register(
                                                `${typeHomeOffice}.${index}.purchase_mar`
                                                )}
                                                onChange={(event) => {
                                                const { value, formatValue } =
                                                    formatInputNumber(event);
        
                                                homeOffice[index].purchase_mar = value;
        
                                                setValue(
                                                    `${typeHomeOffice}.${index}.purchase_mar`,
                                                    formatValue
                                                );
        
                                                handleChangeValue(index, "purchase_mar");
                                                }}
                                            />
                                        )
                                    )}
                                </td>
                            <td>
                                {fieldsMonthreadOnly ? (
                                    <span
                                        style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: "inline-block",
                                        textAlign: "right",
                                        }}
                                    >
                                        {formatPrice3(
                                        homeOffice[index].purchase_apr,
                                        false
                                        )}
                                    </span>
                                ):(
                                    homeOffice[index].only_view_monthly === false && (
                                    <input
                                        readOnly={isCommonUser ? (isCommonUser) : ( fieldsMonthreadOnly ? (fieldsMonthreadOnly) : (false) )}
                                        style={{ width: 100, textAlign: "center" }}
                                        className="input-item"
                                        {...register(
                                        `${typeHomeOffice}.${index}.purchase_apr`
                                        )}
                                        onChange={(event) => {
                                        const { value, formatValue } =
                                            formatInputNumber(event);

                                        homeOffice[index].purchase_apr = value;

                                        setValue(
                                            `${typeHomeOffice}.${index}.purchase_apr`,
                                            formatValue
                                        );

                                        handleChangeValue(index, "purchase_apr");
                                        }}
                                    />
                                    )
                                )}

                            </td>
                            <td>
                                {fieldsMonthreadOnly ? (
                                    <span
                                        style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: "inline-block",
                                        textAlign: "right",
                                        }}
                                    >
                                        {formatPrice3(
                                        homeOffice[index].purchase_may,
                                        false
                                        )}
                                    </span>
                                ):(
                                    homeOffice[index].only_view_monthly === false && (
                                    <input
                                        readOnly={isCommonUser ? (isCommonUser) : ( fieldsMonthreadOnly ? (fieldsMonthreadOnly) : (false) )}
                                        style={{ width: 100, textAlign: "center" }}
                                        className="input-item"
                                        {...register(
                                        `${typeHomeOffice}.${index}.purchase_may`
                                        )}
                                        onChange={(event) => {
                                        const { value, formatValue } =
                                            formatInputNumber(event);
    
                                        homeOffice[index].purchase_may = value;
    
                                        setValue(
                                            `${typeHomeOffice}.${index}.purchase_may`,
                                            formatValue
                                        );
    
                                        handleChangeValue(index, "purchase_may");
                                        }}
                                    />
                                    )
                                )}

                            </td>
                            <td>
                                {fieldsMonthreadOnly ? (
                                    <span
                                        style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: "inline-block",
                                        textAlign: "right",
                                        }}
                                    >
                                        {formatPrice3(
                                        homeOffice[index].purchase_jun,
                                        false
                                        )}
                                    </span>
                                ):(
                                    homeOffice[index].only_view_monthly === false && (
                                    <input
                                        readOnly={isCommonUser ? (isCommonUser) : ( fieldsMonthreadOnly ? (fieldsMonthreadOnly) : (false) )}
                                        style={{ width: 100, textAlign: "center" }}
                                        className="input-item"
                                        {...register(
                                        `${typeHomeOffice}.${index}.purchase_jun`
                                        )}
                                        onChange={(event) => {
                                        const { value, formatValue } =
                                            formatInputNumber(event);
    
                                        homeOffice[index].purchase_jun = value;
    
                                        setValue(
                                            `${typeHomeOffice}.${index}.purchase_jun`,
                                            formatValue
                                        );
    
                                        handleChangeValue(index, "purchase_jun");
                                        }}
                                    />
                                    )
                                )}

                            </td>
                            <td>
                                {fieldsMonthreadOnly ? (
                                    <span
                                        style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: "inline-block",
                                        textAlign: "right",
                                        }}
                                    >
                                        {formatPrice3(
                                        homeOffice[index].purchase_jul,
                                        false
                                        )}
                                    </span>
                                ):(
                                    homeOffice[index].only_view_monthly === false && (
                                    <input
                                        readOnly={isCommonUser ? (isCommonUser) : ( fieldsMonthreadOnly ? (fieldsMonthreadOnly) : (false) )}
                                        style={{ width: 100, textAlign: "center" }}
                                        className="input-item"
                                        {...register(
                                        `${typeHomeOffice}.${index}.purchase_jul`
                                        )}
                                        onChange={(event) => {
                                        const { value, formatValue } =
                                            formatInputNumber(event);

                                        homeOffice[index].purchase_jul = value;

                                        setValue(
                                            `${typeHomeOffice}.${index}.purchase_jul`,
                                            formatValue
                                        );

                                        handleChangeValue(index, "purchase_jul");
                                        }}
                                    />
                                    )
                                )}

                            </td>
                            <td>
                                {fieldsMonthreadOnly ? (
                                    <span
                                        style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: "inline-block",
                                        textAlign: "right",
                                        }}
                                    >
                                        {formatPrice3(
                                        homeOffice[index].purchase_aug,
                                        false
                                        )}
                                    </span>
                                ):(
                                    homeOffice[index].only_view_monthly === false && (
                                        <input
                                            readOnly={isCommonUser ? (isCommonUser) : ( fieldsMonthreadOnly ? (fieldsMonthreadOnly) : (false) )}
                                            style={{ width: 100, textAlign: "center" }}
                                            className="input-item"
                                            {...register(
                                            `${typeHomeOffice}.${index}.purchase_aug`
                                            )}
                                            onChange={(event) => {
                                            const { value, formatValue } =
                                                formatInputNumber(event);

                                            homeOffice[index].purchase_aug = value;

                                            setValue(
                                                `${typeHomeOffice}.${index}.purchase_aug`,
                                                formatValue
                                            );

                                            handleChangeValue(index, "purchase_aug");
                                            }}
                                        />
                                    )
                                )}
                            </td>
                            <td>
                                {fieldsMonthreadOnly? (
                                    <span
                                        style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: "inline-block",
                                        textAlign: "right",
                                        }}
                                    >
                                        {formatPrice3(
                                        homeOffice[index].purchase_sep,
                                        false
                                        )}
                                    </span>
                                ):(
                                
                                    homeOffice[index].only_view_monthly === false && (
                                        <input
                                            readOnly={isCommonUser ? (isCommonUser) : ( fieldsMonthreadOnly ? (fieldsMonthreadOnly) : (false) )}
                                            style={{ width: 100, textAlign: "center" }}
                                            className="input-item"
                                            {...register(
                                            `${typeHomeOffice}.${index}.purchase_sep`
                                            )}
                                            onChange={(event) => {
                                            const { value, formatValue } =
                                                formatInputNumber(event);

                                            homeOffice[index].purchase_sep = value;

                                            setValue(
                                                `${typeHomeOffice}.${index}.purchase_sep`,
                                                formatValue
                                            );

                                            handleChangeValue(index, "purchase_sep");
                                            }}
                                        />
                                    )
                                )}
                            </td>
                            <td>
                                {fieldsMonthreadOnly ? (
                                    <span
                                        style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: "inline-block",
                                        textAlign: "right",
                                        }}
                                    >
                                        {formatPrice3(
                                        homeOffice[index].purchase_oct,
                                        false
                                        )}
                                    </span>
                                ):(
                                    homeOffice[index].only_view_monthly === false && (
                                        <input
                                            readOnly={isCommonUser ? (isCommonUser) : ( fieldsMonthreadOnly ? (fieldsMonthreadOnly) : (false) )}
                                            style={{ width: 100, textAlign: "center" }}
                                            className="input-item"
                                            {...register(
                                            `${typeHomeOffice}.${index}.purchase_oct`
                                            )}
                                            onChange={(event) => {
                                            const { value, formatValue } =
                                                formatInputNumber(event);

                                            homeOffice[index].purchase_oct = value;

                                            setValue(
                                                `${typeHomeOffice}.${index}.purchase_oct`,
                                                formatValue
                                            );

                                            handleChangeValue(index, "purchase_oct");
                                            }}
                                        />
                                    )
                                )}
                            </td>
                            <td>

                                {fieldsMonthreadOnly ? (
                                    <span
                                        style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: "inline-block",
                                        textAlign: "right",
                                        }}
                                    >
                                        {formatPrice3(
                                        homeOffice[index].purchase_nov,
                                        false
                                        )}
                                    </span>
                                ):(
                                    homeOffice[index].only_view_monthly === false && (
                                    <input
                                        readOnly={isCommonUser ? (isCommonUser) : ( fieldsMonthreadOnly ? (fieldsMonthreadOnly) : (false) )}
                                        style={{ width: 100, textAlign: "center" }}
                                        className="input-item"
                                        {...register(
                                        `${typeHomeOffice}.${index}.purchase_nov`
                                        )}
                                        onChange={(event) => {
                                        const { value, formatValue } =
                                            formatInputNumber(event);
    
                                        homeOffice[index].purchase_nov = value;
    
                                        setValue(
                                            `${typeHomeOffice}.${index}.purchase_nov`,
                                            formatValue
                                        );
    
                                        handleChangeValue(index, "purchase_nov");
                                        }}
                                    />
                                    )
                                )}

                            </td>
                            <td>
                                {fieldsMonthreadOnly ? (
                                    <span
                                        style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: "inline-block",
                                        textAlign: "right",
                                        }}
                                    >
                                        {formatPrice3(
                                        homeOffice[index].purchase_dec,
                                        false
                                        )}
                                    </span>
                                ):(
                                    homeOffice[index].only_view_monthly === false && (
                                    <input
                                        readOnly={isCommonUser ? (isCommonUser) : ( fieldsMonthreadOnly ? (fieldsMonthreadOnly) : (false) )}
                                        style={{ width: 100, textAlign: "center" }}
                                        className="input-item"
                                        {...register(
                                        `${typeHomeOffice}.${index}.purchase_dec`
                                        )}
                                        onChange={(event) => {
                                        const { value, formatValue } =
                                            formatInputNumber(event);
    
                                        homeOffice[index].purchase_dec = value;
    
                                        setValue(
                                            `${typeHomeOffice}.${index}.purchase_dec`,
                                            formatValue
                                        );
                                        handleChangeValue(index, "purchase_dec");
                                        }}
                                    />
                                    )
                                )}
                            </td>
                            <td>
                                {!homeOffice[index].only_view_yearly && (
                                <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: "center" }}
                                    className="input-item"
                                    {...register(
                                    `${typeHomeOffice}.${index}.purchase_yearly`
                                    )}
                                    onChange={(event) => {
                                    const { value, formatValue } =
                                        formatInputNumber(event);

                                    homeOffice[index].purchase_yearly = value;

                                    setValue(
                                        `${typeHomeOffice}.${index}.purchase_yearly`,
                                        formatValue
                                    );

                                    handleChangeValue(index, "purchase_yearly");
                                    }}
                                />
                                )}
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                                <span
                                style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                }}
                                >
                                {formatPrice3(
                                    homeOffice[index].purchase_total,
                                    false
                                )}
                                </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                                <span
                                style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                }}
                                >
                                {formatPrice3(
                                    homeOffice[index].emission_co2,
                                    false
                                )}
                                </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                                <span
                                style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                }}
                                >
                                {formatPrice3(
                                    homeOffice[index].emission_ch4,
                                    false
                                )}
                                </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                                <span
                                style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                }}
                                >
                                {formatPrice3(
                                    homeOffice[index].emission_n2o,
                                    false
                                )}
                                </span>
                            </td>
                            {!isCommonUser && (
                                <td style={{ textAlign: "center" }} rowSpan={2}>
                                <div
                                    onClick={() => handleDeleteHomeOffice(index)}
                                    style={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                    width: 70,
                                    }}
                                >
                                    <FaRegTrashCan color="red" size={18}/>
                                </div>
                                </td>
                            )}
                            </tr>
                            <tr>
                            <td
                                colSpan={3}
                                className="subhead-item"
                                style={{ paddingRight: 5, textAlign: "right" }}
                            >
                                {"Emissões mensais de CO₂ (t)"}
                            </td>

                            <td style={{ width: 100 }}>
                                    <span
                                    style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: "inline-block",
                                        textAlign: "right",
                                    }}
                                    >
                                    {/* conteúdo vazio */}
                                    </span>
                                </td>

                                <td style={{ width: 100 }}>
                                    <span
                                    style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: "inline-block",
                                        textAlign: "right",
                                    }}
                                    >
                                    {/* conteúdo vazio */}
                                    </span>
                                </td>

                                <td style={{ width: 100 }}>
                                    <span
                                    style={{
                                        marginRight: 5,
                                        width: 100,
                                        fontSize: 12,
                                        display: "inline-block",
                                        textAlign: "right",
                                    }}
                                    >
                                    {/* conteúdo vazio */}
                                    </span>
                                </td>

                            <td style={{ width: 100 }}>
                                {homeOffice[index].only_view_monthly === false && (
                                <span
                                    style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                    }}
                                >
                                    {formatPrice3(
                                    homeOffice[index].emission_co2_jan,
                                    false
                                    )}
                                </span>
                                )}
                            </td>
                            <td style={{ width: 100 }}>
                                {homeOffice[index].only_view_monthly === false && (
                                <span
                                    style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                    }}
                                >
                                    {formatPrice3(
                                    homeOffice[index].emission_co2_feb,
                                    false
                                    )}
                                </span>
                                )}
                            </td>
                            <td style={{ width: 100 }}>
                                {homeOffice[index].only_view_monthly === false && (
                                <span
                                    style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                    }}
                                >
                                    {formatPrice3(
                                    homeOffice[index].emission_co2_mar,
                                    false
                                    )}
                                </span>
                                )}
                            </td>
                            <td style={{ width: 100 }}>
                                {homeOffice[index].only_view_monthly === false && (
                                <span
                                    style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                    }}
                                >
                                    {formatPrice3(
                                    homeOffice[index].emission_co2_apr,
                                    false
                                    )}
                                </span>
                                )}
                            </td>
                            <td style={{ width: 100 }}>
                                {homeOffice[index].only_view_monthly === false && (
                                <span
                                    style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                    }}
                                >
                                    {formatPrice3(
                                    homeOffice[index].emission_co2_may,
                                    false
                                    )}
                                </span>
                                )}
                            </td>
                            <td style={{ width: 100 }}>
                                {homeOffice[index].only_view_monthly === false && (
                                <span
                                    style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                    }}
                                >
                                    {formatPrice3(
                                    homeOffice[index].emission_co2_jun,
                                    false
                                    )}
                                </span>
                                )}
                            </td>
                            <td style={{ width: 100 }}>
                                {homeOffice[index].only_view_monthly === false && (
                                <span
                                    style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                    }}
                                >
                                    {formatPrice3(
                                    homeOffice[index].emission_co2_jul,
                                    false
                                    )}
                                </span>
                                )}
                            </td>
                            <td style={{ width: 100 }}>
                                {homeOffice[index].only_view_monthly === false && (
                                <span
                                    style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                    }}
                                >
                                    {formatPrice3(
                                    homeOffice[index].emission_co2_aug,
                                    false
                                    )}
                                </span>
                                )}
                            </td>
                            <td style={{ width: 100 }}>
                                {homeOffice[index].only_view_monthly === false && (
                                <span
                                    style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                    }}
                                >
                                    {formatPrice3(
                                    homeOffice[index].emission_co2_sep,
                                    false
                                    )}
                                </span>
                                )}
                            </td>
                            <td style={{ width: 100 }}>
                                {homeOffice[index].only_view_monthly === false && (
                                <span
                                    style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                    }}
                                >
                                    {formatPrice3(
                                    homeOffice[index].emission_co2_oct,
                                    false
                                    )}
                                </span>
                                )}
                            </td>
                            <td style={{ width: 100 }}>
                                {homeOffice[index].only_view_monthly === false && (
                                <span
                                    style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                    }}
                                >
                                    {formatPrice3(
                                    homeOffice[index].emission_co2_nov,
                                    false
                                    )}
                                </span>
                                )}
                            </td>
                            <td style={{ width: 100 }}>
                                {homeOffice[index].only_view_monthly === false && (
                                <span
                                    style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                    }}
                                >
                                    {formatPrice3(
                                    homeOffice[index].emission_co2_dec,
                                    false
                                    )}
                                </span>
                                )}
                            </td>
                            <td style={{ width: 100 }} />
                            </tr>
                        </React.Fragment>
                    );
                })}
            </tbody>
            <tfoot>
                <tr style={{ fontWeight: "bold" }}>
                    <td
                    colSpan={3}
                    className="head-tr-item"
                    style={{ paddingRight: 5, textAlign: "right" }}
                    >
                    {"Total"}
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {/**/}
                    </span>
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {/**/}
                    </span>
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {/**/}
                    </span>
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {formatPrice3(purchaseJan, false)}
                    </span>
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {formatPrice3(purchaseFeb, false)}
                    </span>
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {formatPrice3(purchaseMar, false)}
                    </span>
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {formatPrice3(purchaseApr, false)}
                    </span>
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {formatPrice3(purchaseMay, false)}
                    </span>
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {formatPrice3(purchaseJun, false)}
                    </span>
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {formatPrice3(purchaseJul, false)}
                    </span>
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {formatPrice3(purchaseAug, false)}
                    </span>
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {formatPrice3(purchaseSep, false)}
                    </span>
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {formatPrice3(purchaseOct, false)}
                    </span>
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {formatPrice3(purchaseNov, false)}
                    </span>
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {formatPrice3(purchaseDec, false)}
                    </span>
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {formatPrice3(purchaseYearly, false)}
                    </span>
                    </td>
                    <td className="head-tr-item">
                    <span
                        style={{
                        marginRight: 5,
                        width: 100,
                        fontSize: 12,
                        textAlign: "right",
                        display: "inline-block",
                        }}
                    >
                        {formatPrice3(purchaseTotal, false)}
                    </span>
                    </td>
                    <td className="head-tr-item">
                        <span
                            style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                            }}
                        >
                            {formatPrice3(emissionCo2, false)}
                        </span>
                    </td>
                    <td className="head-tr-item">
                        <span
                            style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                            }}
                        >
                            {formatPrice3(emissionCh4, false)}
                        </span>
                    </td>
                    <td className="head-tr-item">
                        <span
                            style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                            }}
                        >
                            {formatPrice3(emissionN2o, false)}
                        </span>
                    </td>
                    {!isCommonUser && <td className="head-tr-item" />}
                </tr>
            </tfoot>
        </Table>

    );
};