type TypeThItems = {
  output: string;
  colSpan?: number;
  background?: string;
  rowSpan?: number;
};

export const Table = (props: {
  theadItems: TypeThItems[][];
  tbodyItems?: any;
}) => {
  const { theadItems } = props;

  return (
    <table style={{ width: "100%", marginBottom: "1rem" }}>
      <thead>
        {theadItems.map((tr, indexTh) => (
          <tr key={indexTh.toString()}>
            {tr.map((th, indexTR) => (
              <th
                key={indexTR.toString()}
                className="thead-item"
                colSpan={th.colSpan}
                rowSpan={th.rowSpan}
                style={{ background: th.background }}
              >
                {th.output}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>{props.tbodyItems}</tbody>
    </table>
  );
};
