import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Button } from '../../../components/Button/styles'
import { GoBack } from '../../../components/GoBack'
import { TitleText } from '../../../components/TitleText'
import useAuth from '../../../hooks/useAuth'
import airportService from '../../../services/airportService'
import { Container } from './styles'
import {  toNumberValues} from '../../../utils/transform'
import { useLocation, useHistory } from 'react-router-dom'
import { toast } from 'react-hot-toast'

interface Inputs {
  name: string
  city: string
  acronym: string
  country: string
  latitude_n_s: string
  longitude_l_o: string
  latitude_degree: number
  latitude_minute: number
  latitude_second: number
  longitude_degree: number
  longitude_minute: number
  longitude_second: number
}

export const AirportEdit = () => {
  const history = useHistory()

  const [airportSelected, setAirportSelected] = useState<any>({})
  const location = useLocation()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.airport_id) {
      getAirportsById(item.airport_id)
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth()

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const newAirport = {
        name: formData.name,
        city: formData.city,
        acronym: formData.acronym,
        country: formData.country,
        latitude_n_s: formData.latitude_n_s,
        longitude_l_o: formData.longitude_l_o,
        latitude_degree: parseInt(formData.latitude_degree.toString()),
        latitude_minute: parseInt(formData.latitude_minute.toString()),
        latitude_second: parseInt(formData.latitude_second.toString()),
        longitude_degree: parseInt(formData.longitude_degree.toString()),
        longitude_minute: parseInt(formData.longitude_minute.toString()),
        longitude_second: parseInt(formData.longitude_second.toString())
      }

      if (airportSelected?.id) {
        const result = await airportService.updateById(airportSelected.id, newAirport)

        if (result && !result.error) {
         // history.goBack()

          //reset()
         // setErrorApi(undefined)

          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error)
        }
      } else {
        const result = await airportService.create(newAirport)

        if (result && !result.error) {
         // history.goBack()

         // reset()
         // setErrorApi(undefined)

          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error)
        }
      }
    }
  }

  async function getAirportsById(id: number) {
    const airport = await airportService.getById(id)

    setValue('name', airport?.name)
    setValue('city', airport?.city)
    setValue('acronym', airport?.acronym)
    setValue('country', airport?.country)
    setValue('latitude_n_s', airport?.latitude_n_s)
    setValue('longitude_l_o', airport?.longitude_l_o)
    setValue('latitude_degree', airport?.latitude_degree)
    setValue('latitude_minute', airport?.latitude_minute)
    setValue('latitude_second', airport?.latitude_second                        )
    setValue('longitude_degree', airport?.longitude_degree)
    setValue('longitude_minute', airport?.longitude_minute)
    setValue('longitude_second', airport?.longitude_second)

    setAirportSelected(airport)
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <TitleText level={1} title="Aeroporto" />

          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '90%',
            marginTop: 30,
            marginLeft: 50,
            marginRight: 50
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '95%',
              marginTop: 0,
              marginBottom: 10
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10,
                width: '33%'
              }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#31363F',
                  opacity: '0.8',
                  fontWeight: 'bold'
                }}
              >
                Sigla
              </label>

              <input style={{ width: '95%' }} {...register('name', { required: true })} />

              {errors.name && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10,
                width: '33%'
              }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#31363F',
                  opacity: '0.8',
                  fontWeight: 'bold'
                }}
              >
                Cidade
              </label>

              <input style={{ width: '95%' }} {...register('city', { required: true })} />

              {errors.city && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10,
                width: '33%'
              }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#31363F',
                  opacity: '0.8',
                  fontWeight: 'bold'
                }}
              >
                País
              </label>

              <input
                style={{ width: '95%' }}
                {...register('country', { required: true })}
              />

              {errors.country && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div>
          </div>

          <label
            style={{
              marginTop: 10,
              fontFamily: "'Roboto', sans-serif",
              fontSize: '16px',
              lineHeight: '19px',
              color: '#31363F',
              opacity: '0.8',
              fontWeight: 'bold'
            }}
          >
            Latitude
          </label>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '95%',
              marginTop: 0,
              marginBottom: 10
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10,
                width: '25%'
              }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#31363F',
                  opacity: '0.8',
                  fontWeight: 'bold'
                }}
              >
                Graus
              </label>
              <input
                style={{ width: '100%' }}
                {...register('latitude_degree', { required: true })}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = toNumberValues(value)
                }}
              />
              {errors.latitude_degree && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10,
                width: '25%'
              }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#31363F',
                  opacity: '0.8',
                  fontWeight: 'bold'
                }}
              >
                Minutos
              </label>
              <input
                style={{ width: '100%' }}
                {...register('latitude_minute', { required: true })}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = toNumberValues(value)
                }}
              />
              {errors.latitude_minute && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10,
                width: '25%'
              }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#31363F',
                  opacity: '0.8',
                  fontWeight: 'bold'
                }}
              >
                Segundos
              </label>
              <input
                style={{ width: '100%' }}
                {...register('latitude_second', { required: true })}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = toNumberValues(value)
                }}
              />
              {errors.latitude_second && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10,
                width: '25%'
              }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#31363F',
                  opacity: '0.8',
                  fontWeight: 'bold'
                }}
              >
                N/S
              </label>

              <input
                style={{ width: '95%' }}
                {...register('latitude_n_s', { required: true })}
              />

              {errors.latitude_n_s && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div>
          </div>

          <label
            style={{
              marginTop: 10,
              fontFamily: "'Roboto', sans-serif",
              fontSize: '16px',
              lineHeight: '19px',
              color: '#31363F',
              opacity: '0.8',
              fontWeight: 'bold'
            }}
          >
            Longitude
          </label>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '95%',
              marginTop: 0,
              marginBottom: 10
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10,
                width: '25%'
              }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#31363F',
                  opacity: '0.8',
                  fontWeight: 'bold'
                }}
              >
                Graus
              </label>
              <input
                style={{ width: '100%' }}
                {...register('longitude_degree', { required: true })}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = toNumberValues(value)
                }}
              />
              {errors.longitude_degree && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10,
                width: '25%'
              }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#31363F',
                  opacity: '0.8',
                  fontWeight: 'bold'
                }}
              >
                Minutos
              </label>
              <input
                style={{ width: '100%' }}
                {...register('longitude_minute', { required: true })}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = toNumberValues(value)
                }}
              />
              {errors.longitude_minute && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10,
                width: '25%'
              }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#31363F',
                  opacity: '0.8',
                  fontWeight: 'bold'
                }}
              >
                Segundos
              </label>
              <input
                style={{ width: '100%' }}
                {...register('longitude_second', { required: true })}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = toNumberValues(value)
                }}
              />
              {errors.longitude_second && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10,
                width: '25%'
              }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#31363F',
                  opacity: '0.8',
                  fontWeight: 'bold'
                }}
              >
                L/O
              </label>

              <input
                style={{ width: '95%' }}
                {...register('longitude_l_o', { required: true })}
              />

              {errors.longitude_l_o && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div>
          </div>
        </div>
      </form>
    </Container>
  )
}
