import Inventory from "../../assets/icons/inventory.svg";


import { IMenu } from "../../typings/Menu";

export function linksLocked() {
  const menu: IMenu[] = [
    { 
      path: "/inventario", icon: Inventory, name: "Inventário", active: true,
    },
  ];

  return menu;
}
