import React, { useEffect, useState } from "react";
import { GoBack } from "../../components/GoBack";
import { Pagination } from "../../components/Pagination";
import { TitleText } from "../../components/TitleText";
import useAuth from "../../hooks/useAuth";
import useDebounce from "../../hooks/useDebounce";
import scopeService from "../../services/scopeService";
import { Container } from "./styles";
import { useHistory } from "react-router-dom";

export const Scope = () => {
  const history = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [scopes, setScopes] = useState<any>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({});

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const searchDebounced = useDebounce(searchValue, 800);

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  async function getScopes(page: number, filter: string) {
    const result = await scopeService.getByParams({ limit: 25, page, filter: filter });
    setScopes(result);

    setCurrentPage(page)
  }

  useEffect(() => {
    getScopes(1, searchDebounced);
  },// eslint-disable-next-line react-hooks/exhaustive-deps
   []);

  useEffect(() => {
    getScopes(1, searchDebounced)
  }, [searchDebounced]);

  function redirectToEdit(scope?: any) {
    if (scope){
      history.push({
          pathname: '/escopo-inventario/edicao',
          state: { scope_id: scope.id }
      });
    }else{
      history.push('/escopo-inventario/edicao')
    }
  }

  const goPrev = () => {
    setCurrentPage(currentPage - 1);
    getScopes(currentPage - 1, searchDebounced);
  };

  const goNext = () => {
    setCurrentPage(currentPage + 1);
    getScopes(currentPage + 1, searchDebounced);
  };

  return (
    <Container>
      <GoBack />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 15,
          justifyContent: "space-between",
        }}
      >
        <TitleText level={1} title="Escopos do inventário" />

        <input
          className="input-search"
          placeholder="Pesquisar escopos do inventário"
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>

      {scopes?.itens &&
        (
          <div style={{ overflow: "hidden auto", position: "relative", marginTop: 10,  height: "calc(100vh - 130px)", 
              maxHeight: "calc(100vh - 130px)"}} >
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                  <th
                    style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                  >
                    {"Nome"}
                  </th>
                  <th
                    style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                  >
                    {"Apelido"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {scopes.itens.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td style={{ padding: "10px", width: "45%" }} >
                          <span className="title-text-underline">
                            {'Escopo: ' + item.type + ' - ' + item.label}
                          </span>
                        </td>
                        <td style={{ padding: "10px", width: "45%" }} >
                          <span>
                            {item.name}
                          </span>
                        </td>
                        <td>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <button
                              className="button-edit"
                              onClick={() => redirectToEdit(item)}
                            >
                              Editar
                            </button>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
              {scopes?.itens?.length > 0 && (
                <Pagination
                  total={scopes.total}
                  limit={25}
                  goNext={goNext}
                  goPrev={goPrev}
                  currentPage={currentPage}
                  length={scopes.itens.length}
                />
              )}

          </div>
        )
      }
    </Container>
  );
};
