import styled from "styled-components";
import Template from '../../assets/images/template-blue-two.png';


export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    background: #F9F8F8; 
    background: url(${Template});
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .logo-image{
        position: absolute;
    }
    
    .footer-wrapper {
        width: 100%;
        position: absolute;
        bottom: 10px;
    }

    .form-container {
        display: flex;
        flex-direction: column;
        width: 480px;
        height: 300px;
        background: ${props => props.theme.colors.white};
        box-shadow: 10px 20px 50px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
        padding: 5px;
        position: absolute;
        top: 55%;
        left: 50%;
        margin-top: -200px;
        margin-left: -245px;
    }

    .title-soon img {
        width: 50%;
    }

    @font-face {
        font-family: 'Museo Moderno';
        src: url('../fonts/MuseoModerno-BoldItalic.ttf') format('truetype');
        font-weight: bold;
      }

    .background-container{
        width: 100vw;
        height: 100vh;
        positon: fixed;
    }

    .button-login {
        margin-top:15px;
        right: 15px;
        cursor: pointer;
        position: absolute;
        border: 1px solid white; 
        background-color: transparent;
        padding: 10px 20px; 
        font-size: 16px; 
        cursor: pointer; 
        transition: background-color 0.3s; 
        width: 100px;
        height: 40px;
    }
    .button-login:hover {
        background-color: #93bf85; 
        color: #fff;
    }
`;