import React from 'react'
import { Container } from './styles'
import InfoButtonMin from "../../../../assets/icons/info-sign.png"

type SecondStepProps = {
    register: (value: string) => any;
    setValue: (value: string, e: any) => any;
    twoAnaerobicTreatment: boolean | null;
    setTwoAnaerobicTreatment: (value: boolean) => void;
    handleChange: () => void;
}

export const SecondStep: React.FC<SecondStepProps> = ({
    register, 
    setValue, 
    twoAnaerobicTreatment,
    setTwoAnaerobicTreatment,
    handleChange
}) => {
  return (
    <Container>
        <div className='div-body'>
            <div className="div-text">
                <span className="text-statement">
                    Passo 2 -Tratamentos sequenciais aplicados aos efluentes domésticos
                </span>
                <div className="tooltip" style={{ margin: 5 }}>
                    <img src={InfoButtonMin} alt="information" style={{ width: 25 }} />
                        <span className="tooltiptext">
                            Caso exista tratamento sequencial, preencha os Passos 1 a 5 e também os Passos 7 a 10.
                        </span>
                </div>
            </div>

            <div className='input-item'>
                <div className="div-text">
                    <span className="prompt-text">
                    São aplicados, sequencialmente, dois tipos de tratamentos ao efluente doméstico gerado?
                    </span>
                </div>

                <div className='choice-one'>
                    <input type="radio" 
                        style={{ width: 20, marginLeft: 5, marginRight: 10 }}
                        value="1"
                        {...register("two_anaerobic_treatments")}
                        checked={twoAnaerobicTreatment === true} 
                        onChange={() => {
                            setValue("two_anaerobic_treatments", true)
                            setTwoAnaerobicTreatment(true)
                            handleChange()
                        }} 
                    />
                    <span>Sim</span>

                    <input type="radio" 
                        style={{ width: 20, marginLeft: 5, marginRight: 10 }}
                        value="0"
                        {...register("two_anaerobic_treatments")}
                        checked={twoAnaerobicTreatment === false} 
                        onChange={() => {
                            setValue("two_anaerobic_treatments", false)
                            setTwoAnaerobicTreatment(false)
                            handleChange()
                        }} 
                    />
                    <span>Não</span>
                </div>
            </div>
        </div>
    </Container>
  )
}
