import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import EyeIcon from '../../assets/icons/eye.svg';
import Logo from '../../assets/images/logo.svg'
import useAuth from '../../hooks/useAuth';
import { Container } from "./styles"
import { Button } from '../../components/Button/styles';
import termsService from '../../services/termsService';
import userService from '../../services/userService';
import { isStrongPassword, isValidCpf, transformCPF, transformOnlyNumbers } from '../../utils/transform';
import { useHistory } from 'react-router-dom';
import { Footer } from '../../components/Footer';

interface Inputs {
    name: string;
    mail: string;
    cpf: string;
    office: string;
    password: string;
    accept_terms: boolean;
    accept_policies: boolean;
}

export const CreateAccount = () => {
    const history = useHistory()
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();

    const [term, setTerm] = useState<any>([]);
    const [invalidCpf, setInvalidCpf] = useState(false);
    const [userToken, setUserToken] = useState<any>({});
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [userSelected, setUserSelected] = useState<any>({});
    const [invalidToken, setInvalidToken] = useState<boolean>(false);
    const [errorTerms, setErrorTerms] = useState<boolean>(false);
    const [errorPassword1, setErrorPassword1] = useState<boolean>(false);
    const [errorPassword2, setErrorPassword2] = useState<boolean>(false);
    const [errorPassword3, setErrorPassword3] = useState<boolean>(false);
    const [errorPassword4, setErrorPassword4] = useState<boolean>(false);
    const [errorApi, setErrorApi] = useState<string | undefined>();
    const { logout, isAuthenticated } = useAuth();

    async function getUserData() {
        setTerm(await termsService.getItem());

        const url = window.location.pathname;

        const token = url.replace('/criar-conta/', '')
        
        const result = await userService.getByToken(token);

        if (result && result.id){
            setUserToken(token)
            setUserSelected(result);

            setValue("mail", result.mail)
            setValue("name", result.name)
            setValue("office", result.office)

            if (result.cpf)
                setValue("cpf", transformCPF(result.cpf))
        }else{
            setInvalidToken(true)
        }
    }

    useEffect(() => {
        if (isAuthenticated){
            logout(false)
        }
        
        getUserData()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    const onSubmit: SubmitHandler<Inputs> = async (formData) => {
        setErrorTerms(false)
        setInvalidCpf(false)

        if (formData) {
            if (formData.accept_terms === false || formData.accept_policies === false){
                setErrorTerms(true)
            } else if (isStrongPassword(formData.password)){
                if (isValidCpf(formData.cpf)){
                    const newUser = {
                        name: formData.name,
                        mail: formData.mail,
                        password: formData.password,
                        office: formData.office,
                        cpf: formData.cpf ? transformOnlyNumbers(formData.cpf) : undefined
                    };
        
                    const result = await userService.updateByToken(
                        userToken,
                        newUser
                    );
        
                    if (result && !result.error) {
                        history.push('/login')
        
                        setErrorApi(undefined);
                    }
        
                    if (result.error) {
                        setErrorApi(result.error);
                    }
                }else{
                  setInvalidCpf(true)
                }
            }
        }
    };

    const handleShowPassword = () => setShowPassword(!showPassword);
    

    return (
        <Container>
            <div className='form-container'>
                <div style={{ textAlign: 'center' }}>
                    <img src={Logo} alt='logo-sinergia' className='logo' width='120' />
                </div>
                {invalidToken === true && (
                <span style={{textAlign:"center", paddingTop:"160px"}}>
                        Token informado é inválido, solicite um novo acesso ao administrador da plataforma.
                    </span>
                )}
                {invalidToken === false && (
                    <form onSubmit={handleSubmit(onSubmit)} className='form-content'>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, }}
                            >
                                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                                    lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                                    Nome
                                </label>
                                <input
                                    style={{width: "320px", marginTop: 5}} 
                                    {...register("name", { required: true })}
                                />
                                {errors.name && (
                                    <span style={{ color: "#C42329", marginTop: 5 }}>
                                        Esse campo é obrigatório
                                    </span>
                                )}
                            </div>

                            <div style={{ display: "flex", flexDirection: "column", marginTop: 5}}>
                                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", 
                                    fontSize: "16px", lineHeight: "19px", color: "#31363F", 
                                    opacity: "0.8", fontWeight: "bold" }} >
                                    CPF
                                </label>
                                <input
                                    style={{width: "220px"}}
                                    {...register("cpf", { required: true })}
                                    onChange={(event) => {
                                        const { value } = event.target;
                                        event.target.value = transformCPF(value);
                                    }}
                                />
                                {errors.cpf && (
                                    <span style={{ color: "#C42329", marginTop: 5 }}>
                                        Esse campo é obrigatório
                                    </span>
                                )}
                                {errorApi && errorApi.includes("cpf") && (
                                    <span style={{ color: "#E33F3F", marginTop: 5 }}>
                                        CPF já registrado no sistema.
                                    </span>
                                )}
                                {invalidCpf && (
                                    <span className='requiredLabel' >CPF inválido.</span>
                                )}
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ display: "flex", flexDirection: "column", marginRight: 10 }}>
                                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", 
                                    fontSize: "16px", lineHeight: "19px", color: "#31363F", 
                                    opacity: "0.8", fontWeight: "bold" }} >
                                    E-mail
                                </label>
                                <input style={{width: "320px", marginTop: 5}}
                                    {...register("mail", { required: true })}
                                    onChange={(event) => {
                                        event.target.value = event.target.value.toLowerCase();
                                    }}
                                />
                                {errors.mail && (
                                    <span style={{ color: "#C42329", marginTop: 10 }}>
                                        Esse campo é obrigatório
                                    </span>
                                )}
                                {errorApi && errorApi.includes("mail") && (
                                    <span style={{ color: "#E33F3F", marginTop: 5 }}>
                                        E-mail já registrado no sistema.
                                    </span>
                                )}
                            </div>

                            <div style={{ display: "flex", flexDirection: "column", marginTop: 5 }}>
                                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", 
                                    fontSize: "16px", lineHeight: "19px", color: "#31363F", 
                                    opacity: "0.8", fontWeight: "bold" }} >
                                    Cargo
                                </label>
                                <input style={{width: "220px"}}
                                    {...register("office", { required: true })}/>
                                {errors.office && (
                                    <span style={{ color: "#C42329", marginTop: 10 }}>
                                        Esse campo é obrigatório
                                    </span>
                                )}
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                            <div style={{ marginTop: 0, position: 'relative' }}>
                                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", 
                                    fontSize: "16px", lineHeight: "19px", color: "#31363F", 
                                    opacity: "0.8", fontWeight: "bold" }} >
                                    Senha
                                </label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    style={{width: '540px'}}
                                    placeholder=""
                                    {...register("password", { required: true })}
                                    onChange={(event) => {
                                        const value = event.target.value;

                                        setErrorPassword1(value.match(/.{8,}/) == undefined)
                                        setErrorPassword2(value.match(/[0-9]{1,}/) == undefined)
                                        setErrorPassword3(value.match(/[A-Z]{1,}/) == undefined)
                                        setErrorPassword4(value.match(/[a-z]{1,}/) == undefined)
                                    }}
                                />
                                
                                <img src={EyeIcon} alt='password-logo' 
                                    className='password-logo' onClick={handleShowPassword} />
                                
                                {errors.password && (
                                    <span style={{ color: '#C42329', marginTop: 10 }}>
                                        Esse campo é obrigatório
                                    </span>
                                )}
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", marginRight: 5 }}>
                            <input style={{ height: "25px", width: "25px" }}
                                    type="checkbox" {...register("accept_terms")}/>

                            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                                            opacity: "0.8", marginTop: "10px" }} >
                                Declaro que aceito o <a href={term?.terms_of_use_url} target="_blank" rel="noreferrer">termo de uso</a> da plataforma.
                            </label>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", marginRight: 5 }}>
                            <input style={{ height: "25px", width: "25px" }}
                                    type="checkbox" {...register("accept_policies")}/>

                            <label style={{ fontFamily: "'Roboto', sans-serif", marginLeft: "10px", 
                                            fontSize: "16px", lineHeight: "19px", color: "#31363F",
                                            opacity: "0.8", marginTop: "10px" }} >
                                Declaro que aceito a <a href={term?.usage_policies_url} target="_blank" rel="noreferrer">politica de privacidade</a> da plataforma.
                            </label>
                        </div>

                        <Button color='blue' 
                                type="submit" 
                                style={{ marginTop: 30, width: '100%' }}>
                            Concluir registro
                        </Button>

                        {errorTerms &&
                            <span style={{ color: '#C42329', marginTop: 10 }}>
                                Necessário aceitar os termos de uso e politicas de privacidade para dar prosseguimento.
                            </span>
                        }

                        {errorPassword1 &&
                            <span style={{ color: '#C42329', marginTop: 10 }}>
                                Senha: necessário 8 dígitos 
                            </span>
                        }

                        {errorPassword2 &&
                            <span style={{ color: '#C42329', marginTop: 10 }}>
                                Senha: necessário ao menos um dígito numérico
                            </span>
                        }

                        {errorPassword3 &&
                            <span style={{ color: '#C42329', marginTop: 10 }}>
                                Senha: necessário ao menos uma letra maíuscula
                            </span>
                        }

                        {errorPassword4 &&
                            <span style={{ color: '#C42329', marginTop: 10 }}>
                                Senha: necessário ao menos uma letra minúscula
                            </span>
                        }
                    </form>
                )}
            </div>

            <Footer isInternal={false}/>
        </Container >
    )
}