import { api } from "./api";

async function getById(organismId: number) {
  try {
    const { data } = await api.get(`/organism/${organismId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/organism", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(organism: any) {
  try {
    const { data } = await api.post(`/organism`, organism);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(organismId: number, organism: any) {
  try {
    const { data } = await api.put(`/organism/${organismId}`, organism);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(organismId: number) {
  try {
    const { data } = await api.delete(`/organism/${organismId}`);
    return data;
  } catch (error) {
    return false;
  }
}

const organismService = {
  getById,
  getByParams,
  create,
  updateById,
  deleteById
};

export default organismService;
