import { useState } from "react";
import { DescriptionText } from "../DescriptionText";
import ArrowLeft from "../../assets/icons/arrow-left-blue.svg";
import ArrowRight from "../../assets/icons/arrow-right-blue.svg";
import { Container } from "./styles";

type PaginationProps = {
  currentPage: number;
  total: number;
  limit?: number | any;
  goPrev: () => void;
  goNext: () => void;
  showQuantity?: boolean;
  length?: number;
};

export const Pagination = ({
  currentPage,
  total,
  limit,
  goPrev,
  goNext,
  showQuantity = true,
  length,
}: PaginationProps) => {
  const [pages] = useState(Math.ceil(total / limit || 1));

  return (
    <Container showQuantity={showQuantity}>
      {/* {showQuantity && (
        <DescriptionText title={`Exibindo ${length} de ${total}`} />
      )} */}
      <div>
        {currentPage > 1 && (
          <img
            src={ArrowLeft}
            alt="arrow-left"
            style={{ cursor: "pointer" }}
            onClick={() => goPrev()}
          />
        )}
        <span className="navigation-text">{`${currentPage} de ${
          pages === 0 ? 1 : pages
        }`}</span>
        {currentPage < pages && (
          <img
            src={ArrowRight}
            alt="arrow-right"
            style={{ cursor: "pointer", marginRight: 20 }}
            onClick={() => goNext()}
          />
        )}
      </div>
    </Container>
  );
};
