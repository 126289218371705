import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Menu } from './components/Menu';
import { AuthContext, AuthProvider } from './contexts/userAuth';
import { Routes } from './routes';
import theme from './theme';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <AuthProvider>
            <div style={{ display: 'flex' }}>
              <AuthContext.Consumer>
                {context => context.isAuthenticated && <Menu />}
              </AuthContext.Consumer>

              <Toaster position="top-right" reverseOrder={true} />
              
              <Routes />
            </div>
          </AuthProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
