import React from "react";

export const Line = () => {
  return (
    <div
      style={{
        marginTop: 5,
        width: "100%",
        borderTop: "0.6px solid rgba(49, 54, 63, 0.35)",
      }}
    />
  );
};
