import { api } from "./api";

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/audit", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getUsers() {
  try {
    const { data } = await api.get("/audit/users");
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getEntities() {
  try {
    const { data } = await api.get("/audit/entities");
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getCompanies() {
  try {
    const { data } = await api.get("/audit/companies");
    return data
  } catch (error) {
    console.log(error);
  }
}

const auditService = {
  getByParams,
  getUsers,
  getEntities,
  getCompanies
};

export default auditService;
