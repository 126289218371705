import React from "react";
import { Container } from "./styles";

export const Uninformed = (props: { text: string }) => {
  return (
    <Container>
      <span>{props.text}</span>
    </Container>
  );
};
