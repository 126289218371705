import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import userService from "../../../services/userService";
import { Container } from "./styles";

import { DefaultText } from "../../../components/DefaulText";
import { useLocation, useHistory } from "react-router-dom";
import companyService from "../../../services/companyService";
import organismService from "../../../services/organismService";
import { toast } from "react-hot-toast";

export const UserInclude = () => {
  const history = useHistory();

  interface Inputs {
    name: string;
    mail: string;
    profile: any;
    companies: any;
    organisms: any;
    expires_in: any;
    user_type: string;
    pseudo_anonymize: boolean;
  }

  const { user } = useAuth();

  const [date, setDate] = useState('')
  const [companies, setCompanies] = useState<any>([]);
  const [organisms, setOrganisms] = useState<any>([]);
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [selCompanies, setSelCompanies] = useState<any>([]);
  const [selOrganisms, setSelOrganisms] = useState<any>([]);
  const [externalUsers, setExternalUsers] = useState(false);
  const [isController, setIsController] = useState(false);
  const location = useLocation();

  async function getCompanies() {
    const result = await companyService.getByParams({ limit: 90000, page: 1 });

    const options = result.itens.map((company: any) => {
      return {
        label: company.corporate_name,
        value: company.id,
      };
    });

    setCompanies(options);

    if (options && options.length === 1){
      setSelCompanies(options[0])
      setValue("companies", options[0]);
    }
  }

  useEffect(() => {
    if (user.profile !== 'sinergia' && user.profile !== 'admin'){
      history.push({ pathname: '/usuario' });
    }
  },  // eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);

  async function getOrganisms() {
    const result = await organismService.getByParams({ limit: 90000, page: 1 });

    const options = result.itens.map((organism: any) => {
      return {
        label: organism.name,
        value: organism.id,
      };
    });

    setOrganisms(options);

    if (options && options.length === 1){
      setSelOrganisms(options[0])
      setValue("organisms", options[0]);
    }
  }

  useEffect(() => {
    getCompanies()
    getOrganisms()

    if (user.user_type === 1){
      setValue("user_type", "1");
      setExternalUsers(false)
      setValue("profile", "sinergia");
    }else{
      setValue("user_type", "2");
      setExternalUsers(true)
      setValue("profile", "analyst");
    }
    
    setValue("pseudo_anonymize", true);
  },   // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    console.log(errors)
  }, [errors]);

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const itens = externalUsers ? formData.companies : undefined;
      let companies = undefined

      if (Array.isArray(itens)){
        companies = itens.map((company: any) => {
          return company.value;
        });
      }else if (itens){
        companies = [itens.value]
      }

      const newUser = {
        expires_in: date,
        name: formData.name,
        mail: formData.mail,
        user_type: externalUsers ? 2 : 1,
        pseudo_anonymize: formData.pseudo_anonymize,
        profile: externalUsers ? formData.profile : "sinergia",
        organism_id: isController && externalUsers ? formData.organisms.value : undefined,
        companies: companies,
      };

      const result = await userService.create(newUser);
      
      if (result && !result.error) {
       /// history.goBack()

        toast.success("Operação realizada com sucesso!")
      }
      
      if (result.error) {
        setErrorApi(result.error);
      }
    }
  };

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Usuário" />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", marginTop: 30, marginLeft: 50, width: "90%" }} >
            <div style={{ display: "flex", flexDirection: "row", width: "95%"}}>
                <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "100%"}} >
                    <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                      lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                        Nome
                    </label>
                    <input style={{ width: "95%"}} 
                        {...register("name", { required: true })}
                    />
                    {errors.name && (
                        <span style={{ color: "#E33F3F", marginTop: 10 }}>
                            Esse campo é obrigatório
                        </span>
                    )}
                </div>
            </div>
            
            {user.user_type === 1 && (
              <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                  <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "60%" }}>
                      <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                        lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                          E-mail
                      </label>
                      <input style={{ width: "95%"}} {...register("mail", { required: true })}
                          onChange={(event) => {
                              event.target.value = event.target.value.toLowerCase();
                          }}
                      />
                      {errors.mail && (
                          <span style={{ color: "#E33F3F", marginTop: 10 }}>
                              Esse campo é obrigatório
                          </span>
                      )}
                      {errorApi && errorApi.includes("mail") && (
                          <span style={{ color: "#E33F3F", marginTop: 5 }}>
                              E-mail já registrado no sistema.
                          </span>
                      )}
                  </div>

                  <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "40%" }}>
                      <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                        lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                          Tipo de usuário
                      </label>
                      <div style={{ display: 'flex', justifyContent: 'start',  alignItems: 'center', marginTop: 5}}>
                          <input type="radio" value="1" style={{width: 20, marginLeft: 0,  marginRight: 10}}
                                  {...register("user_type", { required: true })}
                                  onChange={(event) => {
                                      setIsController(false)
                                      setExternalUsers(false)
                                      setValue("profile", 'profile');
                                      setValue("pseudo_anonymize", true);
                                      setValue("user_type", event.target.value);
                                  }} /> 
                              <DefaultText title='Interno' 
                          />
                          <input type="radio" value="2" style={{width: 20, marginRight: 20}}
                                  {...register("user_type", { required: true })}
                                  onChange={(event) => {
                                      setIsController(false)
                                      setExternalUsers(true)
                                      setValue("profile", 'analyst');
                                      setValue("pseudo_anonymize", true);
                                      setValue("user_type", event.target.value);
                                  }} /> 
                              <DefaultText title='Externo' 
                          />
                      </div>
                      {errors.user_type && (
                          <span style={{ color: "#E33F3F", marginTop: 10 }}>
                              Esse campo é obrigatório
                          </span>
                      )}
                  </div>
              </div>
            )}
            
            {user.user_type === 2 && (
              <div style={{ display: "flex", flexDirection: "row", width: "95%"}}>
                <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "100%"}} >
                    <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                      lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                        E-mail
                    </label>
                    <input style={{ width: "95%"}} {...register("mail", { required: true })}
                        onChange={(event) => {
                            event.target.value = event.target.value.toLowerCase();
                        }}
                    />
                    {errors.mail && (
                        <span style={{ color: "#E33F3F", marginTop: 10 }}>
                            Esse campo é obrigatório
                        </span>
                    )}
                    {errorApi && errorApi.includes("mail") && (
                        <span style={{ color: "#E33F3F", marginTop: 5 }}>
                            E-mail já registrado no sistema.
                        </span>
                    )}
                </div>
              </div>
            )}

            <div style={{ display: "flex", flexDirection: "row"}}>

              {externalUsers && (
                <div style={{ display: "flex", flexDirection: "column",  marginRight: 10, width: "60%" }}>
                    <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                      lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                        Perfil
                    </label>
                    <div style={{ display: 'flex', justifyContent: 'start',  alignItems: 'center', marginTop: 5}}>
                        <input type="radio" value="admin" style={{width: 20, marginLeft: 0,  marginRight: 10}}
                                {...register("profile", { required: true })}
                                onChange={(event) => {
                                    setIsController(false)
                                    setValue("profile", event.target.value);
                                }} /> 
                            <DefaultText title='Administrador' 
                        />
                        <input type="radio" value="analyst" style={{width: 20, marginLeft: 10,  marginRight: 10}}
                                {...register("profile", { required: true })}
                                onChange={(event) => {
                                    setIsController(false)
                                    setValue("profile", event.target.value);
                                }} /> 
                            <DefaultText title='Analista' 
                        />
                        <input type="radio" value="controller" style={{width: 20, marginLeft: 10,  marginRight: 10}}
                                {...register("profile", { required: true })}
                                onChange={(event) => {
                                    setIsController(true)
                                    setValue("profile", event.target.value);
                                }} /> 
                            <DefaultText title='Auditor' 
                        />
                        <input type="radio" value="director" style={{width: 20, marginLeft: 10,  marginRight: 10}}
                                {...register("profile", { required: true })}
                                onChange={(event) => {
                                    setIsController(false)
                                    setValue("profile", event.target.value);
                                }} /> 
                            <DefaultText title='Diretor' 
                        />
                    </div>
                    {errors.profile && (
                        <span style={{ color: "#E33F3F", marginTop: 10 }}>
                            Esse campo é obrigatório
                        </span>
                    )}
                </div>
              )}

              <div style={{ display: "flex", flexDirection: "column",  marginRight: 10, width: "40%" }}>
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                    lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                      Expira em 
                  </label>

                  <input type='date' style={{width: "150px"}}
                      {...register("expires_in", { required: true, valueAsDate: true })} 
                      onChange={(e)=>setDate(e.target.value)} value={date}/>

                  {errors.expires_in && (
                      <span style={{ color: "#E33F3F", marginTop: 10 }}>
                          Esse campo é obrigatório
                      </span>
                  )}
              </div>

            </div>

            {externalUsers && (
              <div style={{ display: "flex", flexDirection: "column",  marginRight: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Empresa
                </label>
                {companies && (
                    <Controller control={control} rules={{ required: true }}
                            name="companies" render={({ field }) => (
                        <Select placeholder="[Selecione]" {...field} styles={{ 
                            control: (styles) => ({ ...styles,
                                    width: "90%", height: 40, marginTop: 10,
                                    backgroundColor: "rgb(246, 246, 246)"}),
                            option: (styles) => { return { ...styles, color: "#31363F" };},}}
                            defaultValue={selCompanies} isMulti
                            options={companies}
                            isClearable={true}                   
                        />
                        )}
                    />
                )}
                {errors.companies && (
                    <span style={{ color: "#E33F3F", marginTop: 10 }}>
                        Esse campo é obrigatório
                    </span>
                )}
              </div>
            )}

            {isController && externalUsers && (
              <div style={{ display: "flex", flexDirection: "column",  marginRight: 10 }}>
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                    lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                      Organismo Verificador
                  </label>
                  {companies && (
                      <Controller control={control} rules={{ required: true }}
                              name="organisms" render={({ field }) => (
                          <Select placeholder="[Selecione]" {...field} styles={{ 
                              control: (styles) => ({ ...styles,
                                      width: "90%", height: 40, marginTop: 10,
                                      backgroundColor: "rgb(246, 246, 246)"}),
                              option: (styles) => { return { ...styles, color: "#31363F" };},}}
                              defaultValue={selOrganisms}
                              options={organisms}
                              isClearable={true}                   
                          />
                          )}
                      />
                  )}
                  {errors.organisms && (
                      <span style={{ color: "#E33F3F", marginTop: 10 }}>
                          Esse campo é obrigatório
                      </span>
                  )}
              </div>
            )}

            <div style={{ display: "flex", flexDirection: "column",  marginRight: 10, width: "60%" }}>
              <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Pseudoanonimiza conteúdo privado?
              </label>

              <div style={{ display: "flex", flexDirection: "row", 
                          verticalAlign: "middle", height: 30 }} >
                <input style={{ height: "15px", width: "15px" }} 
                        type="checkbox" {...register("pseudo_anonymize")}/>

                <label style={{ fontFamily: "'Roboto', sans-serif",
                            fontSize: "14px", lineHeight: "19px", marginLeft: 10,
                            color: "#31363F", opacity: "0.8", marginTop: 10 }} >
                    Oculta dados sensíveis de usuários / empresas, para este usuário
                </label>
              </div>
            </div>
        </div>
      </form>
    </Container>
  );
};
