import { useHistory } from 'react-router-dom'
import ArrowLeft from '../../assets/icons/arrow-left-blue.svg'
import { Container } from './styles'

export const GoBack = () => {
    const history = useHistory();

    return (
        <Container onClick={() => history.goBack()} style={{ width: '5%'}}>
            <img src={ArrowLeft} alt='voltar-icon' />
            <span className='back-text'>Voltar</span>
        </Container>
    )
}

