import React from "react";
import { Container } from "./style";
import Logo from '../../assets/images/logo-azul.png';
import MessageBox from '../../assets/icons/email-icon.png';
import TelephoneIcon from '../../assets/icons/telefone-icon.png';
import LinkedinIcon from '../../assets/icons/linkedin-icon.png';
import LocationIcon from '../../assets/icons/localizacao-icon.png';
import InstagramIcon from '../../assets/icons/instagram-icon.png';
import SoonText from '../../assets/images/soon.png';
import { Button } from "../../components/Button/styles";
import { useHistory } from "react-router-dom";

export const SoonPage = () => {
    const history = useHistory();

    function handlePageLogin() {
        history.push('/login');
      }

    return (
        <Container>
            <div>
                <Button color="green" size="md" type="submit" className="button-login" onClick={handlePageLogin}>
                    Login
                </Button>
            </div>
            <div className='form-container'>
                <div
                    className="title-soon"
                    style={{
                        marginTop: 10,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                    <img src={SoonText} alt="text-soon" />
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 60,
                        marginTop: 30
                    }} >
                    <img src={Logo} alt="logo ingee" />
                </div>
            </div>
            <div className="footer-wrapper">
                <div className="footer"
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                    }}>

                    <div className='icons-wrapper'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '10px'
                        }}>

                        <a href="mailto:suporte@ingee.com.br">
                            <img src={MessageBox} alt="message-box" className='message-box' width={40} style={{ marginRight: 20 }} />
                        </a>

                        <a href="https://linktr.ee/ingeesinergiaengenharia" target='_blank' rel="noopener noreferrer">
                            <img src={TelephoneIcon} alt="telephone-icon" className='telephone-icon' width={40} style={{ marginRight: 20 }} />
                        </a>

                        <a href="https://www.instagram.com/ingee_inovacao_sustentavel/" target="_blank" rel="noopener noreferrer">
                            <img src={InstagramIcon} alt="instagram-icon" className='instagram-icon' width={40} style={{ marginRight: 20 }} />
                        </a>

                        <a href="https://www.linkedin.com/company/ingee-inovação-sustentável" target="_blank" rel="noopener noreferrer" >
                            <img src={LinkedinIcon} alt="linkedin-icon" className='linkedin-icon' width={40} style={{ marginRight: 20 }} />
                        </a>

                        <a href="https://maps.app.goo.gl/2Qrrmv5CyU2DsiDf8" target="_blank" rel="noopener noreferrer">
                            <img src={LocationIcon} alt="location-icon" className='location-icon' width={40} style={{ marginRight: 10 }} />
                        </a>
                    </div>
                </div>
            </div>
        </Container>
    )
}