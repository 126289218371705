import React, { useEffect, useState } from "react";
import { GoBack } from "../../../../../components/GoBack";
import { TitleText } from "../../../../../components/TitleText";
import useAuth from "../../../../../hooks/useAuth";
import inventoryService from "../../../../../services/inventoryService";
import { Container } from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "../../../../../components/Button/styles";
import { SubmitHandler, useForm } from "react-hook-form";
import { formatPrice3, formatPrice, formatPrice5 } from "../../../../../utils/transform";
import scope1Service from "../../../../../services/scope1Service";
import { Modal } from "../../../../../components/Modal";
import treatmentTypeService from "../../../../../services/treatmentTypeService";
import Parser from 'html-react-parser';
import scopeService from "../../../../../services/scopeService";
import InfoButtonMin from '../../../../../assets/icons/info-sign.png'
import { toast } from "react-hot-toast";
import { Loading } from "../../../../../components/Loading";
import ScopeEvidenceAttachments from "../../../components/ScopeEvidenceAttachments";
import IpccService from "../../../../../services/ipccService";

export const Effluents = () => {
  const history = useHistory();

  const [files, setFiles] = useState<any>([]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  const [guidelines, setGuidelines] = useState<string | undefined>();
  const [passo01, setPasso01] = useState<any>();
  const [passo02, setPasso02] = useState<any>();
  const [passo03, setPasso03] = useState<any>();
  const [passo04, setPasso04] = useState<any>();
  const [passo05, setPasso05] = useState<any>();
  const [passo06, setPasso06] = useState<any>();
  const [passo07, setPasso07] = useState<any>();
  const [passo08, setPasso08] = useState<any>();
  const [passo09, setPasso09] = useState<any>();
  const [passo10, setPasso10] = useState<any>();
  const [passo11, setPasso11] = useState<any>();
  const [passo12, setPasso12] = useState<any>();

  const [openModal, setOpenModal] = useState(false);
  const [ignoreFill, setIgnoreFill] = useState(true);
  const [secondTreatment, setSecondTreatment] = useState<boolean | null>(null);
  const [releasedEffluent, setReleasedEffluent] = useState<boolean | null>(null);
  const [stepFour, setStepFour] = useState<boolean | null>(null);
  const [stepEight, setStepEight] = useState<boolean | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({});
  const [inventorySelected, setInventorySelected] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const location = useLocation();

  const [treatmentTypes, setTreatmentTypes] = useState<any>([]);
  const [Ipcc, setIpcc] = useState<any>([]);
  const [IpccSecond, setIpccSecond] = useState<any>([]);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [changeScope, setChangeScope] = useState<string | undefined>();

  const { isCommonUser } = useAuth()
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [firstFactor, setFirstFactor] = useState<any>();
  const [firstFactorMethaneDbo, setFirstFactorMethaneDbo] = useState<any>();
  const [firstFactorMethaneDqo, setFirstFactorMethaneDqo] = useState<any>();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [firstFactorMethane, setFirstFactorMethane] = useState<any>();
  const [secondFactorMethaneDbo, setSecondFactorMethaneDbo] = useState<any>();
  const [secondFactorMethaneDqo, setSecondFactorMethaneDqo] = useState<any>();

  const [finalFactorMethane, setFinalFactorMethane] = useState<any>();
  const [finalFactorMethaneDbo, setFinalFactorMethaneDbo] = useState<any>();
  const [finalFactorMethaneDqo, setFinalFactorMethaneDqo] = useState<any>();

  const [ipccEffluents, setIpccEffluents] = useState<any>();
  const [ipccEffluentsSecond, setIpccEffluentsSecond] = useState<any>();

  const [emissionCh4, setEmissionCh4] = useState<any>();
  const [emissionN2o, setEmissionN2o] = useState<any>();
  const [emissionCo2, setEmissionCo2] = useState<any>();
  const [emissionCo2Bio, setEmissionCo2Bio] = useState<any>();
  const [factorOxideNitrousFirst, setFactorOxideNitrousFirst] = useState<any>();
  const [factorOxideNitrousSecond, setFactorOxideNitrousSecond] = useState<any>();
  const [factorOxideNitrousFinal, setFactorOxideNitrousFinal] = useState<any>();
  const [typeOfTreatment, setTypeOfTreatment] = useState<boolean | null>(null);

  const [treatmentFinalTypes, setTreatmentFinalTypes] = useState<any[]>([]);
  const [treatmentFirstTypes, setTreatmentFirstTypes] = useState<any[]>([]);
  const [treatmentSecondTypes, setTreatmentSecondTypes] = useState<any[]>([]);
  const [selectedUnit, setSelectedUnit] = useState('');

  const [selectedUnitAfter, setSelectedUnitAfter] = useState('');

  const [message, setMessage] = useState('');

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.company_id && item.year){
      getInventorysById(item.company_id, item.year, item.scope)
    }else{
      history.push({
        pathname: '/inventario/escopo-1',
      });
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location]);

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(1, 'efluentes_liquidos', 'efluentes')

    setTitle(item.label)
    setGuidelines(item.guidelines)

    const extra = await scopeService.getByAllSlug(1, 'efluentes_liquidos');

    setPasso01(extra.passo_01)
    setPasso02(extra.passo_02)
    setPasso03(extra.passo_03)
    setPasso04(extra.passo_04)
    setPasso05(extra.passo_05)
    setPasso06(extra.passo_06)
    setPasso07(extra.passo_07)
    setPasso08(extra.passo_08)
    setPasso09(extra.passo_09)
    setPasso10(extra.passo_10)
    setPasso11(extra.passo_11)
    setPasso12(extra.passo_12)
  }

  function handleOpenInfo () {
    setOpenModal(!openModal)
  }

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<any>();

  const effluentType = watch("effluent_type");

  React.useEffect(() => {
    if (effluentType === "other-industrial") {
      setMessage("Para a classificação 'Outros' efluentes é necessário informar, obrigatoriamente, o teor de N e carga orgânica por m³ de efluente, pois não há valores default do IPCC.");
    } else if (effluentType === "domestic_sewage") {
      setMessage("Para a classificação 'Esgoto Doméstico' é necessário informar, obrigatoriamente, o teor de N e carga orgânica por m³ de efluente.");
    } else {
      setMessage("");
    }
  }, [effluentType]);

  const parseValueOrDefault = (value: any, type: 'int' | 'float' = 'int', isForeignKey: boolean = false) => {
    if (value === null || value === undefined || value === 0) {
        return isForeignKey ? null : 0;
    }
    if (type === 'int') return parseInt(value) || 0; 
    if (type === 'float') {
        let parsedValue = parseFloat(value.toString().replace(".", "").replace(",", "."));
        return isNaN(parsedValue) ? 0 : parsedValue;
    }
    return value;
};



  useEffect(() => {
    if (getValues('treatment_first_id')) {
      handleChangeValue();
    }
  }, [getValues('treatment_first_id')]);

  useEffect(() => {
    const selectedIpccId = watch("ipcc_first_id");
    if (selectedIpccId) {
      handleChangeValue();
    }
  }, [watch("ipcc_first_id")]);

  useEffect(() => {
    const selectedIpccIdSecond = watch("ipcc_second_id");
    if (selectedIpccIdSecond) {
      handleChangeValue();
    }
  }, [watch("ipcc_second_id")]);

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true);
  
    if (formData) {
      let amount_n2_nitrogen = parseValueOrDefault(getValues('amount_n2_nitrogen'), 'float');
      let treatment_first_id = parseValueOrDefault(getValues('treatment_first_id'), 'int');
      let treatment_second_id = parseValueOrDefault(getValues('treatment_second_id'), 'int');
      let treatment_final_id = parseValueOrDefault(getValues('treatment_final_id'), 'int');
      let n2o_emission_factor = parseValueOrDefault(getValues('n2o_emission_factor'), 'float');
      let effluent_degradable = parseValueOrDefault(getValues('effluent_degradable'), 'float');
      let removed_component_organic = parseValueOrDefault(getValues('removed_component_organic'), 'float');
      let nitrogen_removed_after = parseValueOrDefault(getValues('nitrogen_removed_after'), 'float');
      let amount_n2_nitrogen_after = parseValueOrDefault(getValues('amount_n2_nitrogen_after'), 'float');
      let effluent_degradable_after = parseValueOrDefault(getValues('effluent_degradable_after'), 'float');
      let n2o_emission_factor_after = parseValueOrDefault(getValues('n2o_emission_factor_after'), 'float');
      let effluent_degradable_unity = parseValueOrDefault(getValues('effluent_degradable_unity'));
      let degradable_organic_component_unity = parseValueOrDefault(getValues('degradable_organic_component_unity'));
      let removed_component_organic_unity = parseValueOrDefault(getValues('removed_component_organic_unity'));
      let nitrogen_removed_after_unity = parseValueOrDefault(getValues('nitrogen_removed_after_unity'));
      let two_anaerobic_treatments = parseValueOrDefault(getValues('two_anaerobic_treatments'));
      let ch4_amount_recovered_methane = parseValueOrDefault(getValues('ch4_amount_recovered_methane'), 'float');
      let destination_recovered_biogas = parseValueOrDefault(getValues('destination_recovered_biogas'), 'int');
      let generated_liquid_effluent_after = parseValueOrDefault(getValues('generated_liquid_effluent_after'), 'float');
      let effluent_degradable_after_unity = parseValueOrDefault(getValues('effluent_degradable_after_unity'));
      let destination_recovered_biogas_after = parseValueOrDefault(getValues('destination_recovered_biogas_after'), 'int');
      let ch4_amount_recovered_methane_after = parseValueOrDefault(getValues('ch4_amount_recovered_methane_after'), 'float');
      let effluent_type = getValues('effluent_type');
      let type_of_treatment = parseValueOrDefault(getValues('type_of_treatment'));
      let released_effluent = parseValueOrDefault(getValues('released_effluent'));
      let quantity_of_liquid_effluent = parseValueOrDefault(getValues('quantity_of_liquid_effluent'), 'float');
      let quantity_of_treated_liquid_effluent = parseValueOrDefault(getValues('quantity_of_treated_liquid_effluent'), 'float');
      let degradable_organic_component = parseValueOrDefault(getValues('degradable_organic_component'), 'float');
      let amount_n2_nitrogen_after_treatment = parseValueOrDefault(getValues('amount_n2_nitrogen_after_treatment'), 'float');
      let emission_factor_final_effluent = parseValueOrDefault(getValues('emission_factor_final_effluent'), 'float');
      let ipcc_first_id = parseValueOrDefault(getValues('ipcc_first_id'));
      let ipcc_second_id = parseValueOrDefault(getValues('ipcc_second_id'));
      let raw_effluent = parseValueOrDefault(getValues('raw_effluent'));
      let raw_effluent_two = parseValueOrDefault(getValues('raw_effluent_two'));
  
      const scope = {
        status: 2,
        reason: formData.reason,
        emission_ch4: emissionCh4,
        emission_n2o: emissionN2o,
        emission_co2e: emissionCo2,
        evidence: formData.evidence,
        emission_co2_bio: emissionCo2Bio,
        ignore_fill: formData.ignore_fill,
        amount_n2_nitrogen,
        treatment_first_id,
        treatment_second_id,
        treatment_final_id,
        n2o_emission_factor,
        effluent_degradable,
        nitrogen_removed_after,
        amount_n2_nitrogen_after,
        two_anaerobic_treatments,
        effluent_degradable_after,
        n2o_emission_factor_after,
        effluent_degradable_unity,
        nitrogen_removed_after_unity,
        ch4_amount_recovered_methane,
        destination_recovered_biogas,
        generated_liquid_effluent_after,
        effluent_degradable_after_unity,
        destination_recovered_biogas_after,
        ch4_amount_recovered_methane_after,
        effluent_type,
        type_of_treatment,
        released_effluent,
        quantity_of_liquid_effluent,
        removed_component_organic,
        removed_component_organic_unity,
        quantity_of_treated_liquid_effluent,
        degradable_organic_component,
        amount_n2_nitrogen_after_treatment,
        emission_factor_final_effluent,
        ipcc_first_id,
        ipcc_second_id,
        raw_effluent,
        raw_effluent_two,
        degradable_organic_component_unity,
        treatment_factor_emission_nitrous_oxide_first : factorOxideNitrousFirst,
        treatment_factor_emission_nitrous_oxide_second: factorOxideNitrousSecond,
        treatment_factor_emission_nitrous_oxide_final: factorOxideNitrousFinal
      };
  
      const result = await scope1Service.saveLiquidEffluentsItem(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      );
  
      if (result && !result.error) {
        history.goBack();
        setErrorApi(undefined);
        toast.success("Operação realizada com sucesso!");
      } else if (result.error) {
        setErrorApi(result.error);
      }
    }
  
    setLoadingModal(false);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true);
  
    try {
      const types = await treatmentTypeService.getByParams({ limit: 9000, page: 1 });
  
      if (types) {
        setTreatmentTypes(types?.itens);

        const allowedFinalTreatments = [
          "[Selecione]",
          "Lançamento em corpos d'água (não especificado)",
          "Lançamento em corpos d'água (que não reservatórios, lagos e estuários)",
          "Lançamento em reservatórios, lagos e estuários",
          "Efluente parado a céu aberto"
        ];
  
        const treatmentFinalTypes = types?.itens.filter((type: any) =>
          allowedFinalTreatments.includes(type.name)
        );
  
        setTreatmentFinalTypes(treatmentFinalTypes);
  
        if (treatmentFinalTypes.length > 0) {
          handleChangeTreatmentFinal(treatmentFinalTypes[0].id);
        }
  
        if (types?.itens.length > 0) {
          handleChangeTreatmentFirst(types?.itens[0].id);
          handleChangeTreatmentSecond(types?.itens[0].id);
        }
      }

      if (types) {
        setTreatmentTypes(types?.itens);

        const allowedFirstTreatments = [
          "[Selecione]",
          "Tratamento aeróbio (lodo ativado, lagoa aerada, etc)",
          "Fossa séptica",
          "Reator anaeróbio",
          "Lagoa anaeróbia profunda (profundidade > 2 metros)",
          "Lagoa anaeróbia rasa (profundidade < 2 metros)",
          "Lagoa facultativa (profundidade < 2 metros)",
          "Lagoa de maturação (profundidade < 2 metros)",
          "Fossas secas"
        ];
  
        const treatmentFirstTypes = types?.itens.filter((type: any) =>
          allowedFirstTreatments.includes(type.name)
        );
  
        setTreatmentFirstTypes(treatmentFirstTypes);
  
        if (treatmentFirstTypes.length > 0) {
          handleChangeTreatmentFirst(treatmentFirstTypes[0].id);
        }
  
        if (types?.itens.length > 0) {
          handleChangeTreatmentFinal(types?.itens[0].id);
          handleChangeTreatmentSecond(types?.itens[0].id);
        }
      }

      if (types) {
        setTreatmentTypes(types?.itens);

        const allowedSecondTreatments = [
          "[Selecione]",
          "Tratamento aeróbio (lodo ativado, lagoa aerada, etc)",
          "Fossa séptica",
          "Reator anaeróbio",
          "Lagoa anaeróbia profunda (profundidade > 2 metros)",
          "Lagoa anaeróbia rasa (profundidade < 2 metros)",
          "Lagoa facultativa (profundidade < 2 metros)",
          "Lagoa de maturação (profundidade < 2 metros)",
          "Fossas secas"
        ];
  
        const treatmentSecondTypes = types?.itens.filter((type: any) =>
          allowedSecondTreatments.includes(type.name)
        );
  
        setTreatmentSecondTypes(treatmentSecondTypes);
  
        if (treatmentSecondTypes.length > 0) {
          handleChangeTreatmentSecond(treatmentSecondTypes[0].id);
        }
  
        if (types?.itens.length > 0) {
          handleChangeTreatmentFinal(types?.itens[0].id);
          handleChangeTreatmentFirst(types?.itens[0].id);
        }
      }

      const ipcc = await IpccService.getByParams({ limit: 9000, page: 1 });
      setIpcc(ipcc?.itens);
      setIpccSecond(ipcc?.itens);
  
      const inventory = await inventoryService.getById(company_id, year);
      const entity = await scope1Service.getLiquidEffluentsItem(company_id, year);
  
      if (ipcc?.itens.length > 0 && entity?.ipcc_first_id) {
        handleChangeIpcc(entity.ipcc_first_id);
      }
  
      if (ipcc?.itens.length > 0 && entity?.ipcc_second_id) {
        handleChangeIpccSecond(entity.ipcc_second_id);
      }
  
      const result = await inventoryService.getFiles(
        inventory.company.id,
        inventory.year,
        1,
        'efluentes_liquidos',
        'efluentes'
      );
  
      setFiles(result.files);
  
      if (entity) {
        setValue("reason", entity.reason);
        setValue("evidence", entity.evidence);
        setValue("ignore_fill", entity.ignore_fill);
  
        setValue("treatment_first_id", entity.treatment_first_id.toString());
        setValue("treatment_second_id", entity.treatment_second_id.toString());
        setValue("treatment_final_id", entity.treatment_final_id.toString());
  
        setValue("ipcc_first_id", entity?.ipcc_first_id || "");
        setValue("ipcc_second_id", entity?.ipcc_second_id || "");
        setValue("two_anaerobic_treatments", entity.two_anaerobic_treatments.toString());
        setValue("effluent_degradable_unity", entity.effluent_degradable_unity.toString());
        setValue("removed_component_organic_unity", entity.removed_component_organic_unity);
        setValue("nitrogen_removed_after_unity", entity.nitrogen_removed_after_unity);
        setValue("effluent_degradable_after_unity", entity.effluent_degradable_after_unity.toString());
        setValue("degradable_organic_component_unity", entity.degradable_organic_component_unity);
        setValue("effluent_type", entity.effluent_type);
        setValue("type_of_treatment", entity.type_of_treatment.toString());
        setValue("released_effluent", entity.released_effluent ? entity.released_effluent.toString() : "0");
        setValue("raw_effluent", entity.raw_effluent.toString());
        setValue("raw_effluent_two", entity.raw_effluent_two.toString());
  
        handleChangeTreatmentFirst(entity.treatment_first_id);
        handleChangeTreatmentSecond(entity.treatment_second_id);
        handleChangeTreatmentFinal(entity.treatment_final_id);
        handleChangeIpcc(entity.ipcc_first_id);
        handleChangeIpccSecond(entity.ipcc_second_id);
  
        setSecondTreatment(entity.two_anaerobic_treatments === 1);
        setTypeOfTreatment(entity.type_of_treatment === 1);
        setReleasedEffluent(entity.released_effluent === 1);
        setStepFour(entity.raw_effluent === 1);
        setStepEight(entity.raw_effluent_two === 1);
  
        setValue("quantity_of_liquid_effluent", formatPrice5(Number(entity.quantity_of_liquid_effluent), false));
        setValue("amount_n2_nitrogen", formatPrice5(Number(entity.amount_n2_nitrogen), false));
        setValue("n2o_emission_factor", formatPrice5(Number(entity.n2o_emission_factor), false));
        setValue("effluent_degradable", formatPrice5(Number(entity.effluent_degradable), false));
        setValue("removed_component_organic", formatPrice5(Number(entity.removed_component_organic), false));
        setValue("nitrogen_removed_after", formatPrice5(Number(entity.nitrogen_removed_after), false));
        setValue("amount_n2_nitrogen_after", formatPrice5(Number(entity.amount_n2_nitrogen_after), false));
        setValue("effluent_degradable_after", formatPrice5(Number(entity.effluent_degradable_after), false));
        setValue("n2o_emission_factor_after", formatPrice5(Number(entity.n2o_emission_factor_after), false));
        setValue("ch4_amount_recovered_methane", formatPrice5(Number(entity.ch4_amount_recovered_methane), false));
        setValue("destination_recovered_biogas", entity.destination_recovered_biogas);
        setValue("generated_liquid_effluent_after", formatPrice5(Number(entity.generated_liquid_effluent_after), false));
        setValue("ch4_amount_recovered_methane_after", formatPrice5(Number(entity.ch4_amount_recovered_methane_after), false));
        setValue("destination_recovered_biogas_after", entity.destination_recovered_biogas_after);
        setValue("quantity_of_treated_liquid_effluent", formatPrice5(Number(entity.quantity_of_treated_liquid_effluent), false));
        setValue("degradable_organic_component", formatPrice5(Number(entity.degradable_organic_component), false));
        setValue("amount_n2_nitrogen_after_treatment", formatPrice5(Number(entity.amount_n2_nitrogen_after_treatment), false));
        setValue("emission_factor_final_effluent", formatPrice5(Number(entity.emission_factor_final_effluent), false));
        setValue("treatment_factor_emission_nitrous_oxide_first", formatPrice5(Number(entity.treatment_factor_emission_nitrous_oxide_first), false));
        setValue("treatment_factor_emission_nitrous_oxide_second", formatPrice5(Number(entity.treatment_factor_emission_nitrous_oxide_second), false));
        setValue("treatment_factor_emission_nitrous_oxide_final", formatPrice5(Number(entity.treatment_factor_emission_nitrous_oxide_final), false));
  
        setScopeSelected(entity);
        setIgnoreFill(entity.ignore_fill);
      } else {
        setValue("two_anaerobic_treatments", "");
        setScopeSelected({});
        setIgnoreFill(false);
        setSecondTreatment(false);
      }
  
      setInventorySelected(inventory);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setLoadingModal(false);
    }
  }
  
    
  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{5})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos

    // Verifica se o valor é igual a zero após a formatação e remove os zeros à esquerda
    if (value === '') {
        value = '0';
    }

    return value;
  }

  function moeda(a: string, e: string, r: string, decPlaces = 5): string {
    let n = a.replace(new RegExp(`\\${e}`, 'g'), '').replace(new RegExp(`\\${r}`, 'g'), '');
    n = n.replace(/[^\d]+/g, '');

    while (n.length <= decPlaces) {
        n = '0' + n;
    }

    const len = n.length;
    const intPart = n.substring(0, len - decPlaces);
    const decPart = n.substring(len - decPlaces);

    const cleanedIntPart = intPart.replace(/^0+/, '') || '0';

    let formattedValue = cleanedIntPart.replace(/\B(?=(\d{3})+(?!\d))/g, e);
    formattedValue += r + decPart;

    return formattedValue;
}

  const handleChangeValue = () => {
    let amount_n2_nitrogen = getValues('amount_n2_nitrogen')
    let treatment_first_id = getValues('treatment_first_id')
    let treatment_second_id = getValues('treatment_second_id')
    let treatment_final_id = getValues('treatment_final_id')
    let ipcc_first_id = getValues('ipcc_first_id')
    let ipcc_second_id = getValues('ipcc_second_id')
    let n2o_emission_factor = getValues('n2o_emission_factor')
    let effluent_degradable = getValues('effluent_degradable')
    let removed_component_organic = getValues('removed_component_organic')
    let nitrogen_removed_after = getValues('nitrogen_removed_after')
    let two_anaerobic_treatments = getValues('two_anaerobic_treatments')
    let amount_n2_nitrogen_after = getValues('amount_n2_nitrogen_after')
    let effluent_degradable_after = getValues('effluent_degradable_after')
    let n2o_emission_factor_after = getValues('n2o_emission_factor_after')
    let effluent_degradable_unity = getValues('effluent_degradable_unity') || selectedUnit;
    let removed_component_organic_unity = getValues('removed_component_organic_unity')
    let nitrogen_removed_after_unity = getValues('nitrogen_removed_after_unity')
    let degradable_organic_component_unity = getValues('degradable_organic_component_unity')
    let ch4_amount_recovered_methane = getValues('ch4_amount_recovered_methane')
    let destination_recovered_biogas = getValues('destination_recovered_biogas')
    let generated_liquid_effluent_after = getValues('generated_liquid_effluent_after')
    let effluent_degradable_after_unity = getValues('effluent_degradable_after_unity') || selectedUnitAfter;
    let destination_recovered_biogas_after = getValues('destination_recovered_biogas_after')
    let ch4_amount_recovered_methane_after = getValues('ch4_amount_recovered_methane_after')
    let type_of_treatment = getValues('type_of_treatment')
    let released_effluent = getValues('released_effluent')
    let quantity_of_liquid_effluent = getValues('quantity_of_liquid_effluent')
    let quantity_of_treated_liquid_effluent = getValues('quantity_of_treated_liquid_effluent')
    let degradable_organic_component = getValues('degradable_organic_component')
    let amount_n2_nitrogen_after_treatment = getValues('amount_n2_nitrogen_after_treatment')
    let emission_factor_final_effluent = getValues('emission_factor_final_effluent')
    let raw_effluent = getValues('raw_effluent')
    let raw_effluent_two = getValues('raw_effluent_two')
    let treatment_factor_emission_nitrous_oxide_first = getValues('treatment_factor_emission_nitrous_oxide_first')
    let treatment_factor_emission_nitrous_oxide_second = getValues('treatment_factor_emission_nitrous_oxide_second')
    let treatment_factor_emission_nitrous_oxide_final = getValues('treatment_factor_emission_nitrous_oxide_final')

    treatment_first_id = treatment_first_id ? treatment_first_id.toString() : null;
    treatment_second_id = treatment_second_id ? treatment_second_id.toString() : null;
    treatment_final_id = treatment_final_id ? treatment_final_id.toString() : null;
    ipcc_first_id = ipcc_first_id ? parseInt(ipcc_first_id) : 0
    ipcc_second_id = ipcc_second_id ? parseInt(ipcc_second_id) : 0
    raw_effluent = raw_effluent ? raw_effluent : 0
    raw_effluent_two = raw_effluent_two ? parseInt(raw_effluent_two) : 0
    effluent_degradable_unity = effluent_degradable_unity ? parseInt(effluent_degradable_unity) : 0
    nitrogen_removed_after_unity = nitrogen_removed_after_unity ? parseInt(nitrogen_removed_after_unity) : 0
    removed_component_organic_unity = removed_component_organic_unity ? parseInt(removed_component_organic_unity) : 0
    effluent_degradable_after_unity = effluent_degradable_after_unity ? parseInt(effluent_degradable_after_unity) : 0
    degradable_organic_component_unity = degradable_organic_component_unity ? parseInt(degradable_organic_component_unity) : 0
    treatment_factor_emission_nitrous_oxide_first = treatment_factor_emission_nitrous_oxide_first ? parseFloat(treatment_factor_emission_nitrous_oxide_first.replace(".", "").replace(",", ".")) : 0
    treatment_factor_emission_nitrous_oxide_second = treatment_factor_emission_nitrous_oxide_second ? parseFloat(treatment_factor_emission_nitrous_oxide_second.replace(".", "").replace(",", ".")) : 0
    treatment_factor_emission_nitrous_oxide_final = treatment_factor_emission_nitrous_oxide_final ? parseFloat(treatment_factor_emission_nitrous_oxide_final.replace(".", "").replace(",", ".")) : 0

    amount_n2_nitrogen = amount_n2_nitrogen ? parseFloat(amount_n2_nitrogen.replace(".", "").replace(",", ".")) : 0
    n2o_emission_factor = n2o_emission_factor ? parseFloat(n2o_emission_factor.replace(".", "").replace(",", ".")) : 0
    effluent_degradable = effluent_degradable ? parseFloat(effluent_degradable.replace(".", "").replace(",", ".")) : 0
    removed_component_organic = removed_component_organic ? parseFloat(removed_component_organic.replace(".", "").replace(".", ".")) : 0
    nitrogen_removed_after = nitrogen_removed_after ? parseFloat(nitrogen_removed_after.replace(".", "").replace(",", ".")) : 0
    amount_n2_nitrogen_after = amount_n2_nitrogen_after ? parseFloat(amount_n2_nitrogen_after.replace(".", "").replace(",", ".")) : 0
    effluent_degradable_after = effluent_degradable_after ? parseFloat(effluent_degradable_after.replace(".", "").replace(",", ".")) : 0
    n2o_emission_factor_after = n2o_emission_factor_after ? parseFloat(n2o_emission_factor_after.replace(".", "").replace(",", ".")) : 0
    two_anaerobic_treatments = destination_recovered_biogas ? parseFloat(two_anaerobic_treatments.replace(".", "").replace(",", ".")) : 0
    ch4_amount_recovered_methane = ch4_amount_recovered_methane ? (parseFloat(ch4_amount_recovered_methane.replace(".", "").replace(",", "."))) : 0
    destination_recovered_biogas = destination_recovered_biogas ? parseInt(destination_recovered_biogas) : 0
    generated_liquid_effluent_after = generated_liquid_effluent_after ? parseFloat(generated_liquid_effluent_after.replace(".", "").replace(",", ".")) : 0
    destination_recovered_biogas_after = destination_recovered_biogas_after ? parseInt(destination_recovered_biogas_after) : 0
    ch4_amount_recovered_methane_after = ch4_amount_recovered_methane_after ? parseFloat(ch4_amount_recovered_methane_after.replace(".", "").replace(",", ".")) : 0
    type_of_treatment = destination_recovered_biogas_after ? parseFloat(type_of_treatment.replace(".", "").replace(",", ".")) : 0
    released_effluent = released_effluent 
    quantity_of_liquid_effluent = quantity_of_liquid_effluent ? parseFloat(quantity_of_liquid_effluent.replace(".", "").replace(",", ".")) : 0
    quantity_of_treated_liquid_effluent = quantity_of_treated_liquid_effluent ? parseFloat(quantity_of_treated_liquid_effluent.replace(".", "").replace(",", ".")): 0
    degradable_organic_component = degradable_organic_component ? parseFloat(degradable_organic_component.replace(",", ".")) : 0
    amount_n2_nitrogen_after_treatment = amount_n2_nitrogen_after_treatment ? parseFloat(amount_n2_nitrogen_after_treatment.replace(",", ".")) : 0
    emission_factor_final_effluent = emission_factor_final_effluent ? parseFloat(emission_factor_final_effluent.replace(",", "").replace(",", ".")) : 0

    async function calculate() {
      const result = await scope1Service.calculateLiquidEffluents(
        amount_n2_nitrogen, treatment_first_id, treatment_second_id, n2o_emission_factor, 
        effluent_degradable, nitrogen_removed_after, amount_n2_nitrogen_after, effluent_degradable_after, 
        n2o_emission_factor_after, effluent_degradable_unity, two_anaerobic_treatments, 
        ch4_amount_recovered_methane, destination_recovered_biogas, generated_liquid_effluent_after, 
        effluent_degradable_after_unity, destination_recovered_biogas_after, ch4_amount_recovered_methane_after,
        type_of_treatment, released_effluent, quantity_of_liquid_effluent, removed_component_organic,
        removed_component_organic_unity, nitrogen_removed_after_unity, quantity_of_treated_liquid_effluent, degradable_organic_component,
        amount_n2_nitrogen_after_treatment, emission_factor_final_effluent, treatment_final_id, ipcc_first_id, ipcc_second_id,
        degradable_organic_component_unity, treatment_factor_emission_nitrous_oxide_first, treatment_factor_emission_nitrous_oxide_second,
        treatment_factor_emission_nitrous_oxide_final);

      if (result){
        setEmissionCh4(result.emission_ch4)
        setEmissionN2o(result.emission_n2o)
        setEmissionCo2(result.emission_co2e)
        setEmissionCo2Bio(result.emission_co2_bio)
        setFactorOxideNitrousFirst(result.treatment_factor_emission_nitrous_oxide_first)
        setFactorOxideNitrousSecond(result.treatment_factor_emission_nitrous_oxide_second)
        setFactorOxideNitrousFinal(result.treatment_factor_emission_nitrous_oxide_final)

        setChangeScope("change_scope_item_" + result.emission_ch4 + "_" + result.emission_n2o + "_" + 
            result.emission_co2e + "_" + result.emission_co2_bio)
      }

      setSelectedUnit(effluent_degradable_unity);
      setValue("removed_component_organic_unity", parseInt(effluent_degradable_unity));

      setSelectedUnitAfter(effluent_degradable_after_unity);
      setValue("nitrogen_removed_after_unity", parseInt(effluent_degradable_after_unity));
    }

    calculate()
  }

  const handleChangeTreatmentFirst = async (id: number) => {
    if (!id) {
      setValue("treatment_first_id", "");
      setFactorOxideNitrousFirst(0); 
      return;
    }
  
    try {
      const result = await treatmentTypeService.getById(id);
  
      if (result) {
        setValue("treatment_first_id", id); 
        setValue("treatment_mcf_first", formatPrice3(result.mcf, false));
        setValue("treatment_methane_factor_dbo_first", formatPrice5(result.methane_factor_dbo, false));
        setValue("treatment_methane_factor_dqo_first", formatPrice5(result.methane_factor_dqo, false));
  
        setFirstFactor(result.mcf);
        setFirstFactorMethaneDbo(result.methane_factor_dbo);
        setFirstFactorMethaneDqo(result.methane_factor_dqo);
  
        setValue("treatment_factor_emission_nitrous_oxide_first", result.treatment_factor_emission_nitrous_oxide_first);
  
        handleChangeValue();
      }
    } catch (error) {
      console.error("Erro ao buscar tipo de tratamento:", error);
    }
  };

  const handleChangeTreatmentSecond = (e: any) => {
    async function retrieveTreatmentTypeById(id: any) {
        const result = await treatmentTypeService.getById(id);

        if (result){
          setValue("treatment_mcf_second", formatPrice3(result.mcf, false))
          setValue("treatment_methane_factor_dbo_second", formatPrice5(result.methane_factor_dbo, false))
          setValue("treatment_methane_factor_dqo_second", formatPrice5(result.methane_factor_dqo, false))

          setFirstFactorMethane(result.mcf)
          setSecondFactorMethaneDbo(result.treatment_methane_factor_dbo_second)
          setSecondFactorMethaneDqo(result.treatment_methane_factor_dqo_second)

          handleChangeValue()
        }
    }

    if (e){
      retrieveTreatmentTypeById(e)
    }
  }

  const handleChangeTreatmentFinal = (e: any) => {
    async function retrieveTreatmentTypeById(id: any) {
        const result = await treatmentTypeService.getById(id);

        if (result){
          setValue("treatment_mcf_final", formatPrice3(result.mcf, false))
          setValue("treatment_methane_factor_dbo_final", formatPrice5(result.methane_factor_dbo, false))
          setValue("treatment_methane_factor_dqo_final", formatPrice5(result.methane_factor_dqo, false))

          setFinalFactorMethane(result.mcf)
          setFinalFactorMethaneDbo(result.treatment_methane_factor_dbo_final)
          setFinalFactorMethaneDqo(result.treatment_methane_factor_dqo_final)

          handleChangeValue()
        }
    }

    if (e){
      retrieveTreatmentTypeById(e)
    }
  }

  const handleChangeIpcc = async (id: any) => {
    if (!id) {
      setValue("ipcc_effluents_first", "");
      return;
    }
  
    try {
      const result = await IpccService.getById(id);
  
      if (result) {
        const formattedValue = formatPrice5(result.value_dqo, false);
  
        setValue("ipcc_effluents_first", formattedValue); 
        setIpccEffluents(result.value_dqo); 
        setValue("ipcc_first_id", parseInt(id)); 
      }
    } catch (error) {
      console.error("Erro ao buscar dados do IPCC:", error);
    }
  };

  const handleChangeIpccSecond = async (id: any) => {
    if (!id) {
      setValue("ipcc_effluents_second", "");
      return;
    }
    try {
      const result = await IpccService.getById(id);
    
      if (result) {
        const formattedValue = formatPrice5(result.value_dqo, false);
    
        setValue("ipcc_effluents_second", formattedValue); 
        setIpccEffluentsSecond(result.value_dqo);
        setValue("ipcc_second_id", parseInt(id)); 
      }
    } catch (error) {
      console.error("Erro ao buscar dados do IPCC:", error);
    }
  };

  function handleDeleteFile (id: number) {
    async function deleteFile(id:number) {
      const result = await inventoryService.deleteFile(id);

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
            inventorySelected.company.id, inventorySelected.year, 1, 'efluentes_liquidos', 'efluentes');

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

	const downloadFile = (filename:string) => {
    window.open(filename, "_blank")
  }

	const attachmentChangeHandler = (event:any) => {
    async function saveImage(base64?:string, filename?:string) {
      const image = {
        type: 1,
        name: 'efluentes_liquidos',
        file: base64,
        filename: filename,
        second_name: 'efluentes',
      };

      const result = await inventoryService.saveFile(
          inventorySelected.company.id, inventorySelected.year, image);

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
            inventorySelected.company.id, inventorySelected.year, 1, 'efluentes_liquidos', 'efluentes');

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
	};

  const getUnitLabel = (unit: any) => {
    switch (parseInt(unit)) {
      case 1:
        return '[kgDQO/m³]';
      case 2:
        return '[kgDBO/m³]';
      default:
        return '';
    }
  };

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          {inventorySelected?.year && inventorySelected?.company && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
              <TitleText level={4} title={'Escopo 01 - ' + title}  />
            </div>
          )}
          <div style={{ display: "flex", flexDirection: "row" }} >
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        {!isCommonUser && (
          <div style={{ display: "flex", flexDirection: "row", verticalAlign: "middle", height: 30, marginBottom: 10 }} >
              <input style={{ height: "20px", width: "20px", margin:"10px" }} 
                      type="checkbox" {...register("ignore_fill")} 
                      onChange={(event) => {
                        setIgnoreFill(event.target.checked)
                      }} />

              <label style={{ fontFamily: "'Roboto', sans-serif", fontSize: "14px", lineHeight: "19px", 
                          color: "#31363F", opacity: "0.8", marginTop: 10 }} >
                  Selecione aqui, caso não deseje reportar registros deste escopo para o inventário deste ano.
              </label>
          </div>
        )}

        {ignoreFill === true && (
          <div style={{ display: "flex", flexDirection: "column", margin: 10}} >
            <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Informe abaixo uma justificativa para o não preenchimento do escopo selecionado
            </label>

            <textarea style={{width: '95%', height: 100}}
                  {...register("reason", { required: true })}/>
                        
            {errors.reason && (
                <span className='requiredLabel' >Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div style={{ overflow: "hidden auto", position: "relative", marginTop: 0,  height: "calc(100vh - 230px)", 
            maxHeight: "calc(100vh - 230px)"}} >
          {ignoreFill === false && (
            <div style={{ display: "flex", flexDirection: "column", width: "98%", marginTop: 10, marginLeft: 10 }} >
              <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                {passo01 && passo01.label && (
                  <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                    {passo01.label}
                  </span>
                )}

                {passo01 && passo01.guidelines && (
                  <div className="tooltip" style={{margin: 5}}>
                    <img src={InfoButtonMin} alt="information" style={{width: 25}}/>
                    <span className="tooltiptext">{passo01.guidelines}</span>
                  </div>
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "column", marginTop: 0, marginBottom: 10 }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                  <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }}>
                    Qual tipo de efluente gerado?
                  </span>
                  <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: 5 }}>
                    <select
                      style={{ width: "330px", marginLeft: 0, height: 30, borderRadius: 5 }}
                      {...register("effluent_type")}
                    >
                      <option value="void">[Selecione]</option>
                      <option value="domestic_sewage">Esgoto doméstico</option>
                      <option value="effluents-refining-alcohol">Efluentes do refino de álcool</option>
                      <option value="effluents-beer-malt">Efluentes da produção de cerveja e malte</option>
                      <option value="fish-effluents">Efluentes do processamento de peixes</option>
                      <option value="iron-steel-effluents">Efluentes da produção de ferro e aço</option>
                      <option value="refrigeration-effluents">Efluentes de frigoríficos</option>
                      <option value="nitrogen-fertilizer-effluents">Efluentes da produção de fertilizantes nitrogenados</option>
                      <option value="effluents-plastics-resins">Efluentes da produção de plásticos e resinas</option>
                      <option value="effluents-starch">Efluentes da produção de amido</option>
                      <option value="other-industrial">Outros efluentes industriais</option>
                    </select>
                  </div>
                </div>
                {message && (
                  <div style={{ marginTop: 10, color: 'red', fontSize: 12 }}>
                    {message}
                  </div>
                )}
              </div>

              <div>
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, justifyContent: "space-between", marginBottom: 10 }} >
                <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}}>
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    O efluente passa por algum tipo de tratamento?
                  </span>
                </div>

                <div style={{ display: 'flex', justifyContent: 'start',  alignItems: 'center', marginTop: 5,  marginRight: 20}}>
                  <input type="radio" value="1" style={{width: 20, marginLeft: 0,  marginRight: 10}}
                        {...register("type_of_treatment")}
                        onChange={(event) => {
                          setValue("type_of_treatment", event.target.value);
                          setTypeOfTreatment(true);
                          handleChangeValue();
                        }} />  
                  <span>Sim</span> 

                  <input type="radio" value="0" style={{width: 20, marginRight: 10}}
                        {...register("type_of_treatment")}
                        onChange={(event) => {
                          setValue("type_of_treatment", event.target.value);
                          setTypeOfTreatment(false);
                          handleChangeValue();
                        }} />  
                  <span>Não</span> 
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, justifyContent: "space-between", marginBottom: 10 }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    {typeOfTreatment === null 
                      ? "O efluente é lançado ao meio ambiente?"  
                      : typeOfTreatment 
                        ? "O efluente tratado é lançado ao meio ambiente?" 
                        : "O efluente sem tratamento é lançado ao meio ambiente?"}       
                  </span>
                </div>

                <div style={{ display: 'flex', justifyContent: 'start',  alignItems: 'center', marginTop: 5,  marginRight: 20}}>
                  <input type="radio" value="1" style={{width: 20, marginLeft: 0,  marginRight: 10}}
                        {...register("released_effluent")} 
                        onChange={(event) => {
                          setValue("released_effluent", event.target.value);
                          setReleasedEffluent(true);
                          handleChangeValue();
                        }} />  
                  <span>Sim</span> 

                  <input type="radio" value="0" style={{width: 20, marginRight: 10}}
                        {...register("released_effluent")}
                        onChange={(event) => {
                          setValue("released_effluent", event.target.value);
                          setReleasedEffluent(false);
                          handleChangeValue();
                        }} />  
                  <span>Não</span> 
                </div>
              </div>
            </div>
            {releasedEffluent === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, justifyContent: "space-between", marginBottom: 10 }}>
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30 }}>
                  <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }}>
                    {typeOfTreatment === null 
                      ? "Caso o efluente seja lançado ao ambiente, qual a sua disposição final?"  
                      : typeOfTreatment 
                        ? "Caso o efluente tratado seja lançado ao ambiente, qual a sua disposição final?" 
                        : "Caso o efluente sem tratamento seja lançado ao ambiente, qual a sua disposição final?"}       
                  </span>
                  <span style={{ textAlign: "start", fontSize: 10 }}>
                    *Caso haja a disposição final do efluente ao ambiente, preencha os dados indicados nos Passos 11 e 12.
                  </span>
                </div>

                {treatmentFinalTypes && (
                  <select style={{ borderRadius: 5, width: '325px', height: 30}}
                    {...register("treatment_final_id")} 
                    defaultValue=""
                    onChange={(event) => {
                      const { value } = event.target;

                      handleChangeTreatmentFinal(value)
                      if (!value) {
                        setValue("treatment_final_id", "")
                      }
                    }}>
                    {treatmentFinalTypes && treatmentFinalTypes.map((type: any) => {
                      return (
                        <option value={type.id} key={type.id}>
                          {type.name}
                        </option>
                      );
                    })}
                </select>
              )}
              </div>
            )}

            {typeOfTreatment === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                {passo02 && passo02.label && (
                  <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                    {passo02.label}
                  </span>
                )}

                {passo02 && passo02.guidelines && (
                  <div className="tooltip" style={{margin: 5}}>
                    <img src={InfoButtonMin} alt="information" style={{width: 25}}/>
                    <span className="tooltiptext">{passo02.guidelines}</span>
                  </div>
                )}
              </div>
            )}
              {typeOfTreatment === true && (
                              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                                justifyContent: "space-between", marginBottom: 10 }} >
                            <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                              <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                                São aplicados, sequencialmente, dois tipos de tratamentos ao efluente gerado?
                              </span>
                            </div>
            
                            <div style={{ display: 'flex', justifyContent: 'start',  alignItems: 'center', marginTop: 5,  marginRight: 20}}>
                                <input type="radio" value="1" style={{width: 20, marginLeft: 0,  marginRight: 10}}
                                        {...register("two_anaerobic_treatments")}
                                        onChange={(event) => {
                                          setValue("two_anaerobic_treatments", event.target.value);
                                          setSecondTreatment(true)
                                          handleChangeValue()
                                        }} />  
                                <span>Sim</span> 
            
                                <input type="radio" value="0" style={{width: 20, marginRight: 10}}
                                        {...register("two_anaerobic_treatments")}
                                        onChange={(event) => {
                                          setValue("two_anaerobic_treatments", event.target.value);
                                          setSecondTreatment(false)
                                          handleChangeValue()
                                        }} />  
                                <span>Não</span> 
                            </div>
                          </div>
                  )}

              {typeOfTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                  {passo03 && passo03.label && (
                    <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                      {passo03.label}
                    </span>
                  )}
              </div>
              )}

              {typeOfTreatment === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between" }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    Quantidade de efluente líquido gerada no ano do inventário
                  </span>
                </div>
                

                <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                  <input style={{width: '198px', height: 35, textAlign: "right", marginRight: 0}}
                        {...register("quantity_of_liquid_effluent")} 
                        onChange={(event) => {
                            setValue("quantity_of_liquid_effluent", currency(event));
                            handleChangeValue()
                        }}/>

                <span style={{textAlign: "center", fontSize: 14, padding:0,  marginTop: 6, 
                                width: 60, borderBottom: "1px solid black", paddingRight: 15}}>
                  [m³/ano]
                </span>
                </div>
              </div>
              )}

              {typeOfTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                  {passo04 && passo04.label && (
                    <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                      {passo04.label}
                    </span>
                  )}

                {passo04 && passo04.guidelines && (
                  <div className="tooltip" style={{margin: 5}}>
                    <img src={InfoButtonMin} alt="information" style={{width: 25}}/>
                    <span className="tooltiptext">{passo04.guidelines}</span>
                  </div>
                )}
              </div>
              )}
              {typeOfTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      Você possui o componente orgânico degradável do efluente (KgDQO/m³ ou KgDBO/m³) <br />na entrada do tratamento
                      (efluente bruto)?
                    </span>
                  </div>

                <div style={{ display: 'flex', justifyContent: 'start',  alignItems: 'center', marginTop: 5,  marginRight: 20}}>
                  <input type="radio" value="1" style={{width: 20, marginLeft: 0,  marginRight: 10}}
                        {...register("raw_effluent")} 
                        onChange={(event) => {
                          setValue("raw_effluent", event.target.value);
                          setStepFour(true);
                          handleChangeValue();
                        }} />  
                  <span>Sim</span> 

                  <input type="radio" value="0" style={{width: 20, marginRight: 10}}
                        {...register("raw_effluent")}
                        onChange={(event) => {
                          setValue("raw_effluent", event.target.value);
                          setStepFour(false);
                          handleChangeValue();
                        }} />  
                  <span>Não</span> 
                </div>
              </div>
              )}

            {stepFour === false && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between" }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                   Selecione o setor industrial (IPCC)
                  </span>
                </div>

                {Ipcc && (
                  <select style={{ borderTopWidth: "0px", borderLeftWidth: "0px", borderRightWidth: "0px", 
                      border: "1px solid black", borderRadius: 5, width: '320px', height: 30}}
                      {...register("ipcc_first_id")} 
                      defaultValue=""
                      onChange={(event) => {
                        const { value } = event.target;

                        handleChangeIpcc(value)
                        if (!value) {
                          setValue("ipcc_effluents_first", ""); 
                        }
                      }}>
                      <option value="">[Selecione]</option>
                      {Ipcc && Ipcc.map((type: any) => {
                        return (
                          <option value={type.id} key={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                  </select>
                )}
              </div>
            )}

            {stepFour === false && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between", marginBottom: 10 }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 10, marginRight: 20}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}> 
                    Componente orgânico degradável do efluente, na entrada do tratamento
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: 0, marginTop: 5 }}>
                  <input readOnly style={{width: '182px',
                      height: 23,
                      textAlign: "right",
                      backgroundColor: '#c0c0c0',
                      border: 'none',
                      pointerEvents: 'none',
                      color: '#333'}}
                    {...register("ipcc_effluents_first")}
                    value={watch("ipcc_effluents_first") || ""} />
                    <span style={{
                        height: 25,
                        textAlign: "right",
                        backgroundColor: '#c0c0c0',
                        border: 'none',
                        pointerEvents: 'none',
                        color: '#333'
                        }}>
                      [kgDQO/m³]
                    </span>
                </div>
              </div>
            )}

            {stepFour === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30 }}>
                  <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }}>
                    Componente orgânico degradável do efluente, na entrada do tratamento
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", margin: 0 }}>
                  <input
                    style={{ width: '160px', height: 35, textAlign: "right" }}
                    {...register("effluent_degradable")}
                    onChange={(event) => {
                      setValue("effluent_degradable", currency(event));
                      handleChangeValue();
                    }}
                  />
                  <span style={{ textAlign: "center", fontSize: 14, padding: 5, marginTop: 0, width: 100, borderBottom: "1px solid black" }}>
                    <select
                      style={{ borderWidth: "0px", width: "100px", height: "25px" }}
                      {...register("effluent_degradable_unity")}
                      onChange={(event) => {
                        const unit = event.target.value;
                        setValue("effluent_degradable_unity", parseInt(unit));
                        setSelectedUnit(unit); 
                        handleChangeValue();
                      }}
                    >
                      <option value='0'></option>
                      <option value='1' label='[kgDQO/m³]' />
                      <option value='2' label='[kgDBO/m³]' />
                    </select>
                  </span>
                </div>
              </div>
            )}
            {typeOfTreatment === true && stepFour === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, justifyContent: "space-between", marginBottom: 10 }}>
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30 }}>
                  <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }}>
                    Componente orgânico do efluente removido com o lodo
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", margin: 0 }}>
                  <input
                    style={{ width: '160px', height: 35, textAlign: "right" }}
                    {...register("removed_component_organic")}
                    onChange={(event) => {
                      setValue("removed_component_organic", currency(event));
                      handleChangeValue();
                    }}
                  />
                  <span style={{ textAlign: "center", fontSize: 14, padding: 5, marginTop: 3, width: 100, borderBottom: "1px solid black" }}>
                    {getUnitLabel(selectedUnit)} 
                  </span>
                </div>
              </div>
            )}
            {stepFour === false && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, justifyContent: "space-between", marginBottom: 10 }}>
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30 }}>
                  <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }}>
                    Componente orgânico do efluente removido com o lodo
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", margin: 0 }}>
                  <input
                    style={{ width: '160px', height: 35, textAlign: "right" }}
                    {...register("removed_component_organic")}
                    onChange={(event) => {
                      setValue("removed_component_organic", currency(event));
                      handleChangeValue();
                    }}
                  />
                   <span style={{ textAlign: "center", fontSize: 14, padding: 5, marginTop: 3, width: 100, borderBottom: "1px solid black" }}>
                      [kgDQO/m³]
                    </span>
                </div>
              </div>
            )}
            {typeOfTreatment === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between" }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    Quantidade de Nitrogênio no efluente gerado, na entrada do tratamento
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                  <input style={{width: '200px', height: 35, textAlign: "right"}}
                        {...register("amount_n2_nitrogen")}
                        onChange={(event) => {
                            setValue("amount_n2_nitrogen", currency(event));
                            handleChangeValue()
                        }}/>

                  <span style={{textAlign: "center", fontSize: 14, padding:3,  marginTop: 5, 
                                            width: 62, borderBottom: "1px solid black"}}>
                    [kgN/m³]
                  </span>
                </div>
              </div>
            )}
            {typeOfTreatment === true && (
              <div>
                  {message && (
                    <div style={{ marginTop: 10, color: 'red', fontSize: 12 }}>
                      {message}
                    </div>
                  )}
              </div>
            )}
            {typeOfTreatment === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between" }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    Fator de emissão de N₂O do tratamento do efluente
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                  <input style={{width: '162px', height: 35, textAlign: "right"}}
                        {...register("n2o_emission_factor")}
                        onChange={(event) => {
                            setValue("n2o_emission_factor", currency(event));
                            handleChangeValue()
                        }}/>

                  <span style={{textAlign: "center", fontSize: 14, padding:3,  marginTop: 5, 
                                            width: 100, borderBottom: "1px solid black"}}>
                    [kgN₂O-N/kgN]
                  </span>
                </div>
              </div>
            )}
            {typeOfTreatment === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                {passo05 && passo05.label && (
                  <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                    {passo05.label}
                  </span>
                )}

                {passo05 && passo05.guidelines && (
                  <div className="tooltip" style={{margin: 5}}>
                    <img src={InfoButtonMin} alt="information" style={{width: 25}}/>
                    <span className="tooltiptext">{passo05.guidelines}</span>
                  </div>
                )}
              </div>
            )}
            {typeOfTreatment === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between" }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    Escolha o tipo de tratamento do efluente
                  </span>
                </div>

                {treatmentFirstTypes && (
                  <select
                    style={{
                      borderTopWidth: "0px",
                      borderLeftWidth: "0px",
                      borderRightWidth: "0px",
                      border: "1px solid black",
                      borderRadius: 5,
                      width: "320px",
                      height: 30,
                    }}
                    {...register("treatment_first_id")}
                    defaultValue=""
                    onChange={(event) => {
                      const { value } = event.target;

                      handleChangeTreatmentFirst(Number(value));
                      if (!value) {
                        setValue("treatment_first_id", ""); 
                      }
                    }}
                  >
                    {treatmentFirstTypes.map((type: any) => (
                      <option value={type.id} key={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            )}
            {typeOfTreatment === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between", marginBottom: 10 }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}> 
                    Fator de emissão de metano (kgCH₄/kgDBO) 
                  </span>
                </div>
                <input readOnly style={{width: '270px', height: 35, textAlign: "right"}}
                      {...register("treatment_methane_factor_dbo_first")}/> 
              </div>
            )}
            {typeOfTreatment === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between", marginBottom: 10 }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}> 
                    Fator de emissão de metano (kgCH₄/kgDQO) 
                  </span>
                </div>
                <input readOnly style={{width: '270px', height: 35, textAlign: "right"}}
                      {...register("treatment_methane_factor_dqo_first")}/> 
              </div>
            )}
            {typeOfTreatment === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between", marginBottom: 10 }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}> 
                    Fator de emissão de óxido nitroso (kgN₂O/kgN)
                  </span>
                </div>
                <span
                  style={{ width: "273px", height: 35, textAlign: "right", fontSize: 14, borderBottom: "1px solid black" }}
                  {...register("treatment_factor_emission_nitrous_oxide_first")}
                  > 
                  <div style={{marginTop: 5, marginRight: 2}}>
                    {factorOxideNitrousFirst !== undefined ? formatPrice5(factorOxideNitrousFirst, false) : ""}
                  </div>
                </span>
              </div>
            )}
            {typeOfTreatment === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                {passo06 && passo06.label && (
                  <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                    {passo06.label}
                  </span>
                )}

                {passo06 && passo06.guidelines && (
                  <div className="tooltip" style={{margin: 5}}>
                    <img src={InfoButtonMin} alt="information" style={{width: 25}}/>
                    <span className="tooltiptext">{passo06.guidelines}</span>
                  </div>
                )}
              </div>
            )}
            {typeOfTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, justifyContent: "space-between" }}>
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30 }}>
                    <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }}>
                      Quantidade de CH₄ recuperada do tratamento
                    </span>
                    <span style={{ textAlign: "start", fontSize: 10 }}>
                      *Metano Recuperado - Metano gerado que é recuperado, ou seja, não é emitido, e queimado em um queimador ("flare") ou utilizado para geração de energia (eletricidade, calor, etc.)
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: 0 }}>
                    <input
                      style={{ width: '172px', height: 32, textAlign: "right", marginTop: 3 }}
                      {...register("ch4_amount_recovered_methane")}
                      onChange={(event) => {
                        const formattedValue = moeda(event.target.value, '.', ',', 5);
                        setValue("ch4_amount_recovered_methane", formattedValue || "0"); 
                        handleChangeValue();
                      }}
                    />
                    <span style={{
                      textAlign: "center",
                      fontSize: 14,
                      padding: 9,
                      marginTop: 1,
                      width: 74,
                      borderBottom: "1px solid black"
                    }}>
                      [tCH₄/ano]
                    </span>
                  </div>
                </div>
              )}
            {typeOfTreatment === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between", marginBottom: 10 }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 10, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    No ano do inventário, qual o destino do biogás recuperado?  
                  </span>
                </div>

                <select style={{ border: "1px solid black", borderRadius: 5, width: '320px', height: 30, marginTop: 15}}
                    {...register("destination_recovered_biogas")}
                    onChange={(event) => {

                      handleChangeValue()
                    }}>
                    <option value='0'>[Selecione]</option>
                    <option value='1' label='Queima em flare'/>
                    <option value='2' label='Geração de energia'/>
                </select>
              </div>
            )}

              {typeOfTreatment === true && (
                <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                  Caso indicada no Passo 2 a existência de tratamento sequencial do efluente, insira os dados para o segundo tipo de tratamento.
                </span>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                  {passo07 && passo07.label && (
                    <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                      {passo07.label}
                    </span>
                  )}

                  {passo07 && passo07.guidelines && (
                    <div className="tooltip" style={{margin: 5}}>
                      <img src={InfoButtonMin} alt="information" style={{width: 25}}/>
                      <span className="tooltiptext">{passo07.guidelines}</span>
                    </div>
                  )}
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      Quantidade de efluente líquido após a aplicação do primeiro tratamento
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                    <input style={{width: '205px', height: 35, textAlign: "right"}}
                          {...register("generated_liquid_effluent_after")}
                          onChange={(event) => {
                              setValue("generated_liquid_effluent_after", currency(event));
                              handleChangeValue()
                          }}/>

                    <span style={{textAlign: "center", fontSize: 14, padding:2,  marginTop: 5, 
                                    width: 55, borderBottom: "1px solid black"}}>
                      [m³/ano]
                    </span>
                  </div>
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                  {passo08 && passo08.label && (
                    <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                      {passo08.label}
                    </span>
                  )}

                  {passo08 && passo08.guidelines && (
                    <div className="tooltip" style={{margin: 5}}>
                      <img src={InfoButtonMin} alt="information" style={{width: 25}}/>
                      <span className="tooltiptext">{passo08.guidelines}</span>
                    </div>
                  )}
                </div>
              )}
            {secondTreatment === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, justifyContent: "space-between", marginBottom: 10 }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    Você possui o componente orgânico degradável do efluente (KgDQO/m³ ou KgDBO/m³) <br />na entrada do tratamento
                    (efluente bruto)?
                  </span>
                </div>

                <div style={{ display: 'flex', justifyContent: 'start',  alignItems: 'center', marginTop: 5,  marginRight: 20}}>
                  <input type="radio" value="1" style={{width: 20, marginLeft: 0,  marginRight: 10}}
                        {...register("raw_effluent_two")} 
                        onChange={(event) => {
                          setValue("raw_effluent_two", event.target.value);
                          setStepEight(true);
                          handleChangeValue();
                        }} />  
                  <span>Sim</span> 

                  <input type="radio" value="0" style={{width: 20, marginRight: 10}}
                        {...register("raw_effluent_two")}
                        onChange={(event) => {
                          setValue("raw_effluent_two", event.target.value);
                          setStepEight(false);
                          handleChangeValue();
                        }} />  
                  <span>Não</span> 
                </div>
              </div>
            )}

            {stepEight === false && secondTreatment === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between" }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                   Selecione o setor industrial (IPCC)
                  </span>
                </div>

                {Ipcc && (
                  <select style={{ borderTopWidth: "0px", borderLeftWidth: "0px", borderRightWidth: "0px", 
                    border: "1px solid black", borderRadius: 5, width: '320px', height: 30}}
                      {...register("ipcc_second_id")} 
                      defaultValue=""
                      onChange={(event) => {
                        const { value } = event.target;

                        handleChangeIpccSecond(value)
                        if (!value) {
                          setValue("ipcc_effluents_second", ""); 
                        }
                      }}>
                      <option value="">[Selecione]</option>
                      {Ipcc && Ipcc.map((type: any) => {
                        return (
                          <option value={type.id} key={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                  </select>
                )}
              </div>
            )}

            {stepEight === false && secondTreatment === true && (
              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                justifyContent: "space-between", marginBottom: 10 }} >
              <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 20}} >
                <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}> 
                  Componente orgânico degradável do efluente, na entrada do tratamento
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 5 }}>
                <input readOnly style={{width: '182px',
                    height: 23,
                    textAlign: "right",
                    backgroundColor: '#c0c0c0',
                    border: 'none',
                    pointerEvents: 'none',
                    color: '#333'}}
                  {...register("ipcc_effluents_second")}
                  value={watch("ipcc_effluents_second") || ""} />
                  <span style={{
                      height: 25,
                      textAlign: "right",
                      backgroundColor: '#c0c0c0',
                      border: 'none',
                      pointerEvents: 'none',
                      color: '#333'
                      }}>
                    [kgDQO/m³]
                  </span>
              </div>
            </div>
            )}

              {stepEight === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      Componente orgânico degradável do efluente, na entrada do tratamento
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                    <input style={{width: '160px', height: 35, textAlign: "right"}}
                          {...register("effluent_degradable_after")}
                          onChange={(event) => {
                              setValue("effluent_degradable_after", currency(event));
                              handleChangeValue()
                          }}/>

                    <span style={{textAlign: "center", fontSize: 14, padding:5,  marginTop: 0, 
                                              width: 100, borderBottom: "1px solid black"}}>
                      <select style={{ borderWidth: "0px", width: "100px", height: "25px"}}
                          {...register("effluent_degradable_after_unity")}
                          onChange={(event) => {
                            const unit = event.target.value;
                            setValue("effluent_degradable_after_unity", parseInt(unit));
                            setSelectedUnitAfter(unit);
                            handleChangeValue();
                          }}>
                          <option value='0'></option>
                          <option value='1' label='[kgDQO/m³]'/>
                          <option value='2' label='[kgDBO/m³]'/>
                      </select>
                    </span>
                  </div>
                </div>
              )}

              {secondTreatment === true && stepEight === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      Componente orgânico do efluente removido com o lodo
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                    <input style={{width: '160px', height: 35, textAlign: "right"}}
                          {...register("nitrogen_removed_after")}
                          onChange={(event) => {
                              setValue("nitrogen_removed_after", currency(event));
                              handleChangeValue()
                          }}/>

                  <span style={{ textAlign: "center", fontSize: 14, padding: 5, marginTop: 3, width: 100, borderBottom: "1px solid black" }}>
                    {getUnitLabel(selectedUnitAfter)} 
                  </span>
                  </div>
                </div>
              )}
              {stepEight === false && secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, justifyContent: "space-between", marginBottom: 10 }}>
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30 }}>
                    <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }}>
                      Componente orgânico do efluente removido com o lodo
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", margin: 0 }}>
                    <input
                      style={{ width: '160px', height: 35, textAlign: "right" }}
                      {...register("nitrogen_removed_after")}
                      onChange={(event) => {
                        setValue("nitrogen_removed_after", currency(event));
                        handleChangeValue();
                      }}
                    />
                    <span style={{ textAlign: "center", fontSize: 14, padding: 5, marginTop: 3, width: 100, borderBottom: "1px solid black" }}>
                        [kgDQO/m³]
                      </span>
                  </div>
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      Quantidade de nitrogênio no efluente após o primeiro tratamento
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                    <input style={{width: '190px', height: 35, textAlign: "right"}}
                          {...register("amount_n2_nitrogen_after")}
                          onChange={(event) => {
                            setValue("amount_n2_nitrogen_after", currency(event));
                            handleChangeValue()
                          }}/>

                    <span style={{textAlign: "center", fontSize: 14, padding:2,  marginTop: 5, 
                                    width: 70, borderBottom: "1px solid black"}}>
                      [kgN/m³]
                    </span>
                  </div>
                </div>
              )}
              {secondTreatment  === true && (
              <div>
                  {message && (
                    <div style={{ marginTop: 10, color: 'red', fontSize: 12 }}>
                      {message}
                    </div>
                  )}
              </div>
            )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      Fator de emissão de N₂O do tratamento do efluente
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                    <input style={{width: '160px', height: 35, textAlign: "right"}}
                          {...register("n2o_emission_factor_after")}
                          onChange={(event) => {
                              setValue("n2o_emission_factor_after", currency(event));
                              handleChangeValue()
                          }}/>

                    <span style={{textAlign: "center", fontSize: 14, padding:2,  marginTop: 5, 
                                    width: 100, borderBottom: "1px solid black"}}>
                      [kgN₂O-N/kgN]
                    </span>
                  </div>
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                  {passo09 && passo09.label && (
                    <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                      {passo09.label}
                    </span>
                  )}

                  {passo09 && passo09.guidelines && (
                    <div className="tooltip" style={{margin: 5}}>
                      <img src={InfoButtonMin} alt="information" style={{width: 25}}/>
                      <span className="tooltiptext">{passo09.guidelines}</span>
                    </div>
                  )}
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between" }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      Escolha o tipo de tratamento aplicado ao efluente
                    </span>
                  </div>

                  {treatmentSecondTypes && (
                    <select style={{ borderTopWidth: "0px", borderLeftWidth: "0px", borderRightWidth: "0px", 
                      border: "1px solid black", borderRadius: 5, width: '320px', height: 30}}
                        {...register("treatment_second_id")}
                        defaultValue=""
                        onChange={(event) => {
                          const { value } = event.target;

                          handleChangeTreatmentSecond(value)
                          if (!value) {
                            setValue("treatment_second_id", "")
                          }
                        }}>
                        {treatmentSecondTypes && treatmentSecondTypes.map((type: any) => {
                          return (
                            <option value={type.id} key={type.id}>
                              {type.name}
                            </option>
                          );
                        })}
                    </select>
                  )}
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                  justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}> 
                      Fator de emissão de metano (kgCH₄/kgDBO) 
                    </span>
                  </div>
                  <input readOnly style={{width: '270px', height: 35, textAlign: "right"}}
                        {...register("treatment_methane_factor_dbo_second")}/> 
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                  justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}> 
                      Fator de emissão de metano (kgCH₄/kgDQO) 
                    </span>
                  </div>
                  <input readOnly style={{width: '270px', height: 35, textAlign: "right"}}
                        {...register("treatment_methane_factor_dqo_second")}/> 
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                  justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}> 
                      Fator de emissão de óxido nitroso (kgN₂O/kgN) 
                    </span>
                  </div>
                  <span
                    style={{ width: "273px", height: 35, textAlign: "right", fontSize: 14, borderBottom: "1px solid black" }}
                    {...register("treatment_factor_emission_nitrous_oxide_second")}
                    >          
                    <div style={{marginTop: 5, marginRight: 2}}>
                      {formatPrice5(factorOxideNitrousSecond, false)}
                    </div>
                  </span>
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                  {passo10 && passo10.label && (
                    <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                      {passo10.label}
                    </span>
                  )}

                  {passo10 && passo10.guidelines && (
                    <div className="tooltip" style={{margin: 5}}>
                      <img src={InfoButtonMin} alt="information" style={{width: 25}}/>
                      <span className="tooltiptext">{passo10.guidelines}</span>
                    </div>
                  )}
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, justifyContent: "space-between" }}>
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30 }}>
                    <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }}>
                      Quantidade de CH₄ recuperada do tratamento
                    </span>
                    <span style={{ textAlign: "start", fontSize: 10 }}>
                      *Metano Recuperado - Metano gerado que é recuperado, ou seja, não é emitido, e queimado em um queimador ("flare")<br /> ou utilizado para geração de energia (eletricidade, calor, etc.)
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: 0 }}>
                    <input
                      style={{ width: '184px', height: 25, textAlign: "right", marginTop: 1 }}
                      {...register("ch4_amount_recovered_methane_after")}
                      onChange={(event) => {
                        setValue("ch4_amount_recovered_methane_after", currency(event));
                        handleChangeValue();
                      }}
                    />
                    <span style={{
                      textAlign: "center",
                      fontSize: 14,
                      padding: 5,
                      marginTop: 0,
                      width: 74,
                      borderBottom: "1px solid black"
                    }}>
                      [tCH₄/ano]
                    </span>
                  </div>
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between", marginBottom: 5 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 10, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      No ano do inventário, qual o destino do biogás recuperado? 
                    </span>
                  </div>

                  <select style={{ borderTopWidth: "0px", borderLeftWidth: "0px", borderRightWidth: "0px", 
                      border: "1px solid black", borderRadius: 5, width: '320px', height: 30, marginTop: 10}}
                      {...register("destination_recovered_biogas_after")}
                      onChange={(event) => {
                          handleChangeValue()
                      }}>
                      <option value='0'>[Selecione]</option>
                      <option value='1' label='Queima em flare'/>
                      <option value='2' label='Geração de energia'/>
                  </select>
                </div>
              )}

              {releasedEffluent === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                  {passo11 && passo11.label && (
                    <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                      {passo11.label}
                    </span>
                  )}

                  {passo11 && passo11.guidelines && (
                    <div className="tooltip" style={{margin: 5}}>
                      <img src={InfoButtonMin} alt="information" style={{width: 25}}/>
                      <span className="tooltiptext">{passo11.guidelines}</span>
                    </div>
                  )}
                </div>
              )}

            {releasedEffluent === true && (
                    <div style={{ display: "flex", flexDirection: "row", marginTop: 0, justifyContent: "space-between", marginBottom: 10 }}>
                      <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30 }}>
                        <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }}>
                          {typeOfTreatment === null 
                            ? "Quantidade de efluente líquido lançado ao ambiente" 
                            : typeOfTreatment 
                              ? "Quantidade de efluente líquido tratado lançado ao ambiente" 
                              : "Quantidade de efluente líquido não tratado lançado ao ambiente"}
                        </span>
                      </div>
                      
                      <div style={{ display: "flex", flexDirection: "row", margin: 0 }}>
                        <input style={{ width: '210px', height: 35, textAlign: "right" }}
                          {...register("quantity_of_treated_liquid_effluent")}
                          onChange={(event) => {
                            setValue("quantity_of_treated_liquid_effluent", currency(event));
                            handleChangeValue();
                          }} />
                        <span style={{ textAlign: "center", fontSize: 14, padding: 2, marginTop: 5, width: 55, borderBottom: "1px solid black" }}>
                          [m³/ano]
                        </span>
                      </div>
                    </div>
                  )}

              {releasedEffluent === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                  {passo12 && passo12.label && (
                    <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                      {passo12.label}
                    </span>
                  )}

                  {passo12 && passo12.guidelines && (
                    <div className="tooltip" style={{margin: 5}}>
                      <img src={InfoButtonMin} alt="information" style={{width: 25}}/>
                      <span className="tooltiptext">{passo12.guidelines}</span>
                    </div>
                  )}
                </div>
              )}

              {releasedEffluent === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, justifyContent: "space-between", marginBottom: 10 }} >
                  
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30 }} >
                    <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }}>
                      {typeOfTreatment === null ? "Componente orgânico degradável do efluente" 
                        : typeOfTreatment ? "Componente orgânico degradável do efluente após tratamento" 
                        : "Componente orgânico degradável do efluente sem tratamento"}
                    </span>
                    <span style={{ textAlign: "start", fontSize: 10 }}>
                      *Preencha os dados sobre a quantidade de nitrogênio presente no efluente. Para efluentes industriais, se não possuir, deixe em branco,<br />será utilizado o default sugerido por IPCC (2019).
                    </span>
                    <span style={{ textAlign: "start", fontSize: 10 }}>
                      *Preencha o fator de emissão de N₂O do efluente. Se não possuir, deixe em branco, será utilizado o default sugerido por IPCC (2019).
                    </span>
                    {message && (
                      <div style={{ marginTop: 5, color: 'red', fontSize: 12 }}>
                        {message}
                      </div>
                    )}
                  </div>

                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: 0 }} >
                    <input style={{ width: '170px', height: 26, textAlign: "right", marginTop: 5 }} {...register("degradable_organic_component")}
                          onChange={(event) => { 
                            setValue("degradable_organic_component", currency(event)); 
                            handleChangeValue() 
                          }}/>

                    <span style={{textAlign: "center", fontSize: 14, padding:4,  marginTop: 1, 
                                              width: 90, borderBottom: "1px solid black"}}>
                      <select style={{ borderWidth: "0px", width: "100px", height: "24px"}}
                        {...register("degradable_organic_component_unity")}
                          onChange={(event) => {
                            setValue("degradable_organic_component_unity", parseInt(event.target.value)); 
                            handleChangeValue(); 
                          }}>
                          <option value='0'></option>
                          <option value='1' label='[kgDQO/m³]'/>
                          <option value='2' label='[kgDBO/m³]'/>
                      </select>
                    </span>
                  </div>
                </div>
              )}

              {releasedEffluent === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      {typeOfTreatment === null ? "Quantidade de nitrogênio no efluente"
                      : typeOfTreatment ? "Quantidade de nitrogênio no efluente após tratamento"
                      : "Quantidade de nitrogênio no efluente não tratado"}
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                    <input style={{width: '192px', height: 35, textAlign: "right"}}
                          {...register("amount_n2_nitrogen_after_treatment")}
                          onChange={(event) => {
                              setValue("amount_n2_nitrogen_after_treatment", currency(event));
                              handleChangeValue()
                          }}/>

                    <span style={{textAlign: "center", fontSize: 14, padding:3,  marginTop: 5, 
                                    width: 68, borderBottom: "1px solid black", paddingRight: 5}}>
                      [kgN/m³]
                    </span>
                  </div>
                </div>
              )}

              {releasedEffluent === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      Fator de emissão de N₂O da disposição final do efluente
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                    <input style={{width: '160px', height: 35, textAlign: "right"}}
                          {...register("emission_factor_final_effluent")}
                          onChange={(event) => {
                              setValue("emission_factor_final_effluent", currency(event));
                              handleChangeValue()
                          }}/>

                    <span style={{textAlign: "center", fontSize: 14, padding:3,  marginTop: 5, 
                                    width: 100, borderBottom: "1px solid black"}}>
                      [kgN₂O-N/kgN]
                    </span>
                  </div>
                </div>
              )}

                {releasedEffluent === true && (
                  <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between", marginBottom: 10 }} >
                    <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                      <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}> 
                        Fator de emissão de metano (kgCH₄/kgDBO) 
                      </span>
                    </div>
                    <input readOnly style={{width: '266px', height: 35, textAlign: "right"}}
                          {...register("treatment_methane_factor_dbo_final")}/> 
                  </div>
                )}

                {releasedEffluent === true && (
                  <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between", marginBottom: 10 }} >
                    <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                      <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}> 
                        Fator de emissão de metano (kgCH₄/kgDQO) 
                      </span>
                    </div>
                    <input readOnly style={{width: '266px', height: 35, textAlign: "right"}}
                          {...register("treatment_methane_factor_dqo_final")}/> 
                  </div>
                )}

                {releasedEffluent === true && (
                  <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between", marginBottom: 10 }} >
                    <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                      <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}> 
                        Fator de emissão de óxido nitroso (kgN₂O/kgN) 
                      </span>
                    </div>
                    <span
                      style={{ width: "270px", height: 35, textAlign: "right", fontSize: 14, borderBottom: "1px solid black" }}
                      {...register("treatment_factor_emission_nitrous_oxide_final")}
                      >          
                      <div style={{marginTop: 5, marginRight: 2}}>
                        {formatPrice5(factorOxideNitrousFinal, false)}
                      </div>
                    </span>
                  </div>
                )}

              <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)", marginBottom: 10, marginTop: 20}} />

              <div style={{ display: "flex", flexDirection: "column", marginRight: 30, marginBottom: 10}} >
                <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                  Tabela 1 - Emissões Totais de Efluentes Líquidos
                </span>
              </div>

              {changeScope && (
                <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                              width: "100%", borderColor: "#9E9E9E", height: "auto", 
                              display: "flex", flexDirection: "column", marginLeft: 0}} >
                  <div style={{ width: "100%", display: "flex", flexDirection: "row"}} >
                    <span style={{ margin: 10, width: "70%" }}>
                      Emissões de CH₄  por tratamento e/ou disposição final de efluentes [tCH₄/ano]:
                    </span>
                    <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                      {formatPrice3(emissionCh4, false)}
                    </span>
                  </div>

                  {emissionCh4 < 0 && (
                    <div style={{ display: "flex", flexDirection: "row", color: "#C42329", 
                          fontSize: 12, fontWeight: "bold", marginLeft: 10 }} >
                      O dado de metano recuperado é maior que o de metano gerado. Revise seus cálculos.
                    </div>
                  )}
                </div>
              )}

              {changeScope && (
                <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                              width: "100%", borderColor: "#9E9E9E", height: "auto", 
                              display: "flex", flexDirection: "column", marginLeft: 0}} >
                  <div style={{ width: "100%", display: "flex", flexDirection: "row"}} >
                    <span style={{ margin: 10, width: "70%" }}>
                      Emissões de N₂O por tratamento e/ou disposição final de efluentes [tN₂O/ano]
                    </span>
                    <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                      {formatPrice3(emissionN2o, false)}
                    </span>
                  </div>

                  {emissionN2o < 0 && (
                    <div style={{ display: "flex", flexDirection: "row", color: "#C42329", 
                          fontSize: 12, fontWeight: "bold", marginLeft: 10 }} >
                      O dado de remoção de nitrogênio como lodo é maior que a quantidade de nitrogênio no efluente gerado. Revise seus cálculos
                    </div>
                  )}
                </div>
              )}

              {changeScope && (
                <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                              width: "100%", borderColor: "#9E9E9E", height: "auto", 
                              display: "flex", flexDirection: "row", marginLeft: 0}} >
                  <span style={{ margin: 10, width: "70%" }}>
                    Emissões em CO₂e por tratamento e/ou disposição final de efluentes [tCO₂e/ano]
                  </span>
                  <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                    {formatPrice3(emissionCo2, false)}
                  </span>
                </div>
              )}

              {changeScope && (
                <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                              width: "100%", borderColor: "#9E9E9E", height: "auto", 
                              display: "flex", flexDirection: "row", marginLeft: 0}} >
                  <span style={{ margin: 10, width: "70%" }}>
                    Emissões em CO₂ biogênico por tratamento de efluentes [tCO₂/ano]
                  </span>
                  <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                    {formatPrice3(emissionCo2Bio, false)}
                  </span>
                </div>
              )}

            </div>
          )}

          {!ignoreFill && (
            <div className="column" style={{ margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                className="column"
                style={{
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div className="column" style={{ margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={downloadFile}
                  onChangeAttachment={attachmentChangeHandler}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal isOpen={openModal} handleModalVisible={() => handleOpenInfo()} isPayOrTypeClient={true}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TitleText level={2} title='Orientações gerais' />
        </div>

        <div style={{marginTop: 10, width: 550}}>
          {guidelines && (
            <span style={{fontSize: 12, textAlign: "justify"}}>
              {Parser(guidelines)}
            </span>
          )}
        </div>

      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  );
};
