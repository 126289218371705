import React, { Component } from 'react';
import algoDeuErrado from '../../assets/images/algo-deu-errado.svg';
import LogoOcaAzul from '../../assets/images/logo-oca-azul.png'
import styled from 'styled-components';

interface ErrorBoundaryState {
  hasError: boolean;
  errorInfo?: React.ErrorInfo; 
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export const Button = styled.button`
    margin: 10px 0;
    padding: 5px 15px;
    background-color: #007bff;
    color: #FFF;
    border-radius: 6px;
    border: transparent;

    &:hover {
        background-color: #e4e1e1;
        box-shadow: 0 0 5px rgba(179, 179, 179, 0.5);
    }
`;

export const LogsError = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 2px dashed #C1C1C1;
    border-radius: 8px;
    height: 200px;
    color: #777777;
    font-size: 16px;
    text-align: center;
`;


class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: any): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(_: any, info: React.ErrorInfo) {
    this.setState({ errorInfo: info });
    // TODO ADD LOG
  }

  handleGoBack = () => {
    window.history.back();
  }

  render() {
    if (this.state.hasError && this.state.errorInfo) {
      return (
        <div>
          <img src={LogoOcaAzul} alt="logo-oca-azul" width={42} />
          <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <h1>Oops! Algo deu errado.</h1>
            <img src={algoDeuErrado} alt="algo-deu-errado" />
            <p>Tente novamente e se o problema persistir, entre em contato com a Sinergia Engenharia.</p>
            <Button onClick={this.handleGoBack}>Voltar</Button>
            <p>Se precisar envie esse log para ajudar a identificar o erro:</p>
            <LogsError>
              {this.state.errorInfo.componentStack}
            </LogsError>
          
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
