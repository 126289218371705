import { Page, Image as ImagePDF, View } from "@react-pdf/renderer";
import React from "react";
import { Logos } from "../../components/Logos";
import { Sumary } from "../../components/Sumary";
import backgroundImage from "../../../../../../../assets/images/image-sumary.png";
import sinergiaBranca from "../../../../../../../assets/images/logo-branca.png";
import { IGEEProps } from "../../../interfaces/IGEEData";
import { ClientLogo } from "../../components/ClientLogo";

interface LogoProps {
  logo?: string;
  companyId?: number;
}
export const Sumario: React.FC<IGEEProps & LogoProps> = (props) => {
  const { resume, logo, companyId } = props;
  
  return (
    <Page size={[640, 480]}>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          backgroundColor: "#326ed6",
          position: "relative",
        }}
      >
        <View style={{ width: "50%", height: "100%" }}>
          <ImagePDF
            src={backgroundImage}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </View>
        {/* primeira linha */}
        {/* Linha Horizontal */}
        <View
          style={{
            position: "absolute",
            bottom: "15%",
            left: 0,
            width: "100%",
            height: 1,
            backgroundColor: "white",
            borderTop: "1px solid white",
          }}
        />
        {/* Linha Vertical */}
        <View
          style={{
            position: "absolute",
            top: 0,
            right: "60%",
            width: 1,
            height: "100%",
            backgroundColor: "white",
            borderRight: "1px solid white",
          }}
        />
        {/* Bola 1 */}
        <View
          style={{
            position: "absolute",
            top: "93.7%",
            right: "66.7%",
            width: 15,
            height: 15,
            borderRadius: "50%",
            backgroundColor: "white",
            transform: "translate(50%, -50%)",
          }}
        />
        {/* segunda linha */}
        {/* Linha Vertical */}
        <View
          style={{
            position: "absolute",
            top: 0,
            right: "5%",
            width: 1,
            height: "100%",
            backgroundColor: "white",
            borderRight: "1px solid white",
          }}
        />
        {/* Bola 2*/}
        <View
          style={{
            position: "absolute",
            top: "93.7%",
            right: "11.7%",
            width: 15,
            height: 15,
            borderRadius: "50%",
            backgroundColor: "white",
            transform: "translate(50%, -50%)",
          }}
        />
        <View style={{ width: "47%", height: "100%", position: "relative" }}>
        <Sumary />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            bottom: "4%",
            left: "50%", 
            transform: "translateX(-50%)",
            width: "50%", 
          }}
        >
          <ClientLogo logo={logo} companyId={companyId} style={{ marginLeft: -5 }} />
          <ImagePDF
            src={sinergiaBranca}
            style={{ width: 120, marginLeft: -10 }} 
          />
        </View>
      </View>
      </View>
    </Page>
  );
};
