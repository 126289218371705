import { Container } from "./styles"
import LoadingImg from '../../assets/images/loading.gif'
import ReactModal from "react-modal";

type ModalDetailsProps = {
    isOpen: boolean;
}

export const Loading = ({ isOpen}: ModalDetailsProps) => {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        overflow: "auto",
        maxHeight: "82%",
        padding: "20px",
        width: "220px",
        background: "transparent",
        border: "none",
      },
    };

    return (
        <Container>
          <ReactModal isOpen={isOpen} style={customStyles}>
            <div style={{textAlign: "center"}}>
                <img src={LoadingImg} alt="loading" width='100' />

                <br/>

                <span style={{width: "100%", height: "200px", marginTop: 90}}>
                    Aguarde ...
                </span>
            </div>
          </ReactModal>
        </Container>
    )
}