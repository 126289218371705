import { Page, View } from '@react-pdf/renderer'
import React from 'react'
import Header  from '../Header'
import { Footer } from '../Footer'

interface PageProps {
  title: string
  page?: string
  logo?: string
  hasHeader?: boolean
  companyFantasyName?: any
  companyName: any
}

export const PDFPage: React.FC<PageProps> = (props) => {
  const { companyFantasyName, page, hasHeader = true, title, companyName,  logo } = props
  return (
    <Page size="A4" style={{ justifyContent: 'space-between' }}>
      <View>
        {hasHeader && <Header title={title} companyName={companyName} logo={logo}/>}
        {props.children}
      </View>
      <Footer company={companyName} page={page} />
    </Page>
  )
}
