import User from '../../assets/icons/user.svg'
import Company from '../../assets/icons/company.svg'
import Check from "../../assets/icons/check_icon.svg";
import Inventory from '../../assets/icons/inventory.svg'
import Macc from "../../assets/icons/macc.svg";
import Graficos from "../../assets/icons/graficos.svg";
import { IMenu } from '../../typings/Menu';


export function links() {
    
    const menu: IMenu[] = [
        // {
        //   path: "/inicio", icon: Inventory, name: "Inicio", active: true,
        // },
        {
          path: "/inventario", icon: Inventory, name: "Inventário", active: true
        },
        // { 
        //   path: "/resumo", icon: Graficos, name: "Resumo", active: true 
        // },
        { 
          path: "/rpe", icon: Graficos, name: "RPE - GHG Protocol", active: true 
        },
         { 
          path: "/curva-macc", icon: Macc, name: "Descarbonização", active: true 
        },
        // { 
        //   path: "/indicadores", icon: Graficos, name: "Indicadores", active: true 
        // },
        { 
          path: "-", icon: '', name: '', active: true 
        },
        {
          path: "/empresa", icon: Company, name: "Empresas", active: true
        },
        {
          path: "/usuario", icon: User, name: "Usuários", active: true,
        },
        {
          path: "/auditoria", icon: Check, name: "Histórico", active: true,
        },
    ];

    return menu
}