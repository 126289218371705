import { useEffect, useState } from "react";
import { Container } from "./styles";

type StatusLabelProps = {
  width: number | undefined;
  title: string | undefined;
  status: string | undefined;
  handleOnClick: (inventory: any, type:number) => void;
  inventory: any;
  type: number;
};

export const BulletScope = ({ status, title, width, handleOnClick, inventory, type }: StatusLabelProps) => {
  const [status0, setStatus0] = useState(false);
  const [status0a35, setStatus0a35] = useState(false);
  const [status36a99, setStatus36a99] = useState(false);
  const [status100, setStatus100] = useState(false);

  useEffect(() => {
    let value = 0

    if (status){
      value = parseInt(status)
    }

    //setStatus0(value === 0)

    setStatus0a35(value >= 0 && value <= 35)
    setStatus36a99(value >= 36 && value <= 99)
    setStatus100(value === 100)
  }, [status]);

  return (
    <Container status={status}>
       {status0 && (
        <div  className="bulled-0" onClick={() => handleOnClick(inventory, type)} style={{width: width}}>
          <span className="status-text">{title}</span>
        </div>
      )}
      {status0a35 && (
        <div  className="bulled-0-35" onClick={() => handleOnClick(inventory, type)} style={{width: width}}>
          <span className="status-text">{title}</span>
        </div>
      )}
      {status36a99 && (
        <div  className="bulled-36-99" onClick={() => handleOnClick(inventory, type)} style={{width: width}}>
          <span className="status-text">{title}</span>
        </div>
      )}
      {status100 && (
        <div  className="bulled-100" onClick={() => handleOnClick(inventory, type)} style={{width: width}}>
          <span className="status-text">{title}</span>
        </div>
      )}
    </Container>
  );
};
