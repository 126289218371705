import React from "react";
import { G, Rect, Svg, Text, Line } from "@react-pdf/renderer";
import * as d3 from "d3";

export const BarChart = ({ data, width, height }) => {
    const margin = { top: 20, right: 35, bottom: 40, left: 220 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height  - margin.top - margin.bottom;

    const styles = {
        light: {
            fontFamily: "MuseoModerno",
        },
    };

    const categories = data.map(d => d.name);
    const keys = Array.from(new Set(data.flatMap(d => d.values.map(v => v.key))));

    // Definindo escalas
    const yScale = d3
        .scaleBand()
        .domain(categories)
        .range([1, innerHeight + 10])
        .padding(0.2);

    const xScale = d3
        .scaleLinear()
        .domain([0, d3.max(data.flatMap(d => d.values.map(v => v.value)))])
        .range([0, innerWidth]);

    const colorScale = d3
        .scaleOrdinal()
        .domain(keys)
        .range(d3.schemeCategory10);

    const stack = d3.stack()
        .keys(keys)
        .value((d, key) => {
            const value = d.values.find(v => v.key === key);
            return value ? value.value : 0;
        });

    const series = stack(data);

    // Função para quebrar o texto em múltiplas linhas
    const breakText = (text, maxChars) => {
        const words = text.split(' ');
        let lines = [];
        let currentLine = '';

        words.forEach(word => {
            if ((currentLine + word).length <= maxChars) {
                currentLine += `${word} `;
            } else {
                lines.push(currentLine.trim());
                currentLine = `${word} `;
            }
        });

        lines.push(currentLine.trim());
        return lines;
    };

    return (
        <Svg width={width} height={height}>
            <G transform={`translate(${margin.left},${margin.top})`}>
                {/* Linha vertical ao longo do eixo Y */}
                <Line
                    x1={0}
                    y1={0}
                    x2={0}
                    y2={innerHeight + 10}
                    stroke="#000" 
                    strokeWidth={0.2} 
                />

                {series.map((layer, i) => (
                    <G key={i}>
                        {layer.map((d, j) => (
                            <G key={j}>
                                <Rect
                                    y={yScale(categories[j])}
                                    x={xScale(d[0])}
                                    height={yScale.bandwidth()}
                                    width={xScale(d[1]) - xScale(d[0])}
                                    fill={"#81c787"}
                                />
                                {/* Adicionando o valor no final de cada barra */}
                                <Text
                                    x={xScale(d[1]) + 3}
                                    y={yScale(categories[j]) + yScale.bandwidth() / 2 + 2}
                                    fontSize={7}
                                    style={styles.light}
                                    textAnchor="start"
                                >
                                    {d[1].toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Text>
                            </G>
                        ))}
                    </G>
                ))}

                {categories.map((category, i) => (
                    breakText(category, 50).map((line, j) => (
                        <Text
                            key={`${i}-${j}`}
                            y={yScale(categories[i]) + yScale.bandwidth() / 2 + 2 + j * 9}
                            x={-10}
                            textAnchor="end"
                            style={styles.light}
                            fontSize={8}
                        >
                            {line}
                        </Text>
                    ))
                ))}
            </G>
        </Svg>
    );
};
