import { Container } from "./styles";

type StatusLabelProps = {
  status: boolean | undefined;
  expired: boolean | undefined;
  locked: boolean | undefined;
};

export const StatusLabel = ({ status, expired, locked }: StatusLabelProps) => {
  const getStatusLabel = () => {
    if (locked === true){
      return "Bloqueado";
    }else if (expired === true){
      return "Expirado";
    }else if (status === true){
      return "Ativo";
    }else{
      return "Inativo";
    }
  };

  return (
    <Container status={status} expired={expired} locked={locked}>
      <span className="status-text">{getStatusLabel()}</span>
    </Container>
  );
};
