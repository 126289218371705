import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import EyeIcon from '../../assets/icons/eye.svg';
import Logo from '../../assets/images/logo.svg';
import useAuth from '../../hooks/useAuth';
import { TitleText } from '../../components/TitleText';
import { Button } from '../../components/Button/styles';
import { Container } from "./styles";
import { Footer } from '../../components/Footer';

interface Inputs {
    password: string;
    confirm_password: string;
}

export const NewPassword = () => {
    const { updatePassword, isFirstAccess } = useAuth();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPassword2, setShowPassword2] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<Inputs>();

    const password = watch("password", "");

    const handleShowPassword = () => setShowPassword(!showPassword);
    const handleShowPassword2 = () => setShowPassword2(!showPassword2);

    const onSubmit: SubmitHandler<Inputs> = async (formData) => {
        if (formData) {
            if (formData.password === formData.confirm_password) {
                if (validatePassword(formData.password)) {
                    updatePassword(formData);
                } else {
                    alert("A senha não atende aos critérios mínimos.");
                }
            } else {
                alert("As senhas não são iguais.");
            }
        }
    };

    const validatePassword = (password: string) => {
        const errors: string[] = [];

        if (password.length < 8) {
            errors.push('A senha deve ter pelo menos 8 caracteres.');
        }

        if (!/[A-Z]/.test(password)) {
            errors.push('A senha deve conter pelo menos uma letra maiúscula.');
        }

        if (!/[a-z]/.test(password)) {
            errors.push('A senha deve conter pelo menos uma letra minúscula.');
        }

        if (!/\d/.test(password)) {
            errors.push('A senha deve conter pelo menos um número.');
        }

        // Exibe as mensagens de erro
        if (errors.length > 0) {
            alert(errors.join('\n'));
            return false;
        }

        return true;
    };

    

    return (
        <Container>
            <div className='form-container'>
                <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                    <img src={Logo} alt='logo-sinergia' className='logo' width='150' />
                </div>

                <div style={{ margin: -20, textAlign: "center" }}>
                    <TitleText level={3} title='Alteração de senha' />
                </div>
                
                <form className='form-content' style={{ paddingTop: 15 }} onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                        <label style={{ marginLeft: 45 }}>Senha</label>
                        <div style={{ position: 'relative', marginLeft: 45 }}>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder=""
                                {...register("password", { 
                                    required: true,
                                    minLength: {
                                        value: 8,
                                        message: 'A senha deve ter pelo menos 8 caracteres.'
                                    },
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                                        message: 'A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula e um número.'
                                    }
                                })}
                                style={{ width: '350px' }}
                            />
                            <img src={EyeIcon} alt='password-show' className='password-show' onClick={handleShowPassword} style={{ position: 'absolute', top: '35%', right: '45px', transform: 'translateY(-50%)', cursor: 'pointer', width: '30px' }} />
                        </div>
                        {errors.password && (
                            <span className='requiredLabel' style={{ marginLeft: 45 }}>{errors.password.message}</span>
                        )}
                    </div>

                    <label style={{ marginTop: 10, marginLeft: 45 }}>
                        Confirme a nova senha
                    </label>
                    <input
                        type={showPassword2 ? 'text' : 'password'}
                        placeholder=""
                        {...register("confirm_password", { required: true })}
                        style={{ width: '350px', marginLeft: 45 }}
                    />
                    <img src={EyeIcon} alt='password-show' className='password-show' onClick={handleShowPassword2} style={{ position: 'absolute', top: '68%', right: '65px', transform: 'translateY(-50%)', cursor: 'pointer', width: '30px' }} />
                    {errors.confirm_password && (
                        <span className='requiredLabel' style={{ marginLeft: 90 }}>Esse campo é obrigatório</span>
                    )}

                    <div style={{ marginTop: 6, textAlign: "center" }}>
                        <Button color='blue' type="submit" style={{ marginTop: 10, width: '285px' }}>
                            Alterar senha
                        </Button>
                    </div>
                </form>
            </div>

            <Footer isInternal={false}/>
        </Container >
    )
}
