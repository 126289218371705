import { Page, Text, View, StyleSheet, Image as ImagePDF } from "@react-pdf/renderer";
import React from "react";
import { Logos } from "../../components/Logos";
import { Header } from "../../components/Header";
import { IGEEProps } from "../../../interfaces/IGEEData";
import { styles, table } from "../../styles";
import LogoVerdeEscuro from "../../../../../../../assets/images/versoes-logo-icone-ingee-03.png";

// Função para renderizar caracteres subscritos
const renderWithSubscripts = (text: string) => {
  const subscriptMap: { [key: string]: string } = {
    '₃': '3',
    '₄': '4',
  };

  const parts = text.split(/(₃|₄)/);
  return (
    <Text>
      {parts.map((part, index) => {
        if (subscriptMap[part]) {
          return (
            <Text key={index} style={{ fontSize: 5, verticalAlign: 'sub' }}>
              {subscriptMap[part]}
            </Text>
          );
        }
        return <Text key={index}>{part}</Text>;
      })}
    </Text>
  );
};

const gases = [
  "CFC-11",
  "CFC-12",
  "CFC-13",
  "CFC-113",
  "CFC-114",
  "CFC-115",
  "Halon-1301",
  "Halon-1211",
  "Halon-2402",
  "Terradoreto de carbono (CCl₄)",
  "Bromometano (CH₃Br)",
  "Methyl chloroform (CH₃CCl₃)",
  "HCFC-21",
  "HCFC-22 (R22)",
  "HCFC-123",
  "HCFC-124",
  "HCFC-141b",
  "HCFC-142b",
  "HCFC-225ca",
  "HCFC-225cb",
];

export const Page14: React.FC<IGEEProps> = (props) => {
  const { data } = props;
  const { resume } = props;

  const Table = () => {
    // Função para renderizar todos os gases em uma única coluna
    const renderGasList = () => {
      return resume?.resumeIgee?.not_kyoto
        .filter((d) => gases.includes(d.label))
        .map((d, i) => (
          <View
            style={{
              ...table.row,
              backgroundColor: i % 2 ? "#cfd5d9" : "",
            }}
            key={i}
          >
            <Text
              style={{
                ...table.kyotoSpace,
                ...styles.light,
                lineHeight: "1px",
                marginLeft: "10px",
              }}
            >
              {renderWithSubscripts(d.label)}
            </Text>
            <Text style={{ ...table.kyoto, ...styles.light }}>
              {d.emission_tco2e?.toFixed(2).replace('.', ',')}
            </Text>
          </View>
        ));
    };

    return (
      <View style={local.table}>
        <View style={table.header}>
          <View style={table.columns}>
            <Text
              style={{
                fontSize: 10,
                ...styles.Bold,
                alignSelf: "center",
                color: "#6c8a6e",
                textAlign: "center",
                marginLeft: 265,
                width: "100%",
              }}
            >
              Emissões tCO<Text style={{ fontSize: 6 }}>2</Text>e
            </Text>
          </View>
        </View>
        <View>
          {renderGasList()}
          <Text
            style={{
              ...table.kyotoSpace,
              ...styles.Bold,
              lineHeight: "1px",
              color: "#64766d",
              marginLeft: "10px",
              marginTop: 5
            }}
          >
            Total de emissões de {"\n"}<Text style={{ fontSize: 9, ...styles.Bold }}>outros gases</Text>
          </Text>

          <View style={table.kyoto}>
            <Text
              style={{
                backgroundColor: "#6c8a6e",
                ...local.endColumn,
                ...styles.Bold,
                marginLeft: "83.5vh"
              }}
            >
              {resume?.resumeIgee?.not_kyoto[
                resume.resumeIgee?.not_kyoto.length - 1
              ].emission_tco2e.toFixed(2).replace('.', ',')}
            </Text>
          </View>
        </View>
        <View
          style={{
            ...table.row,
            marginTop: "10px",
          }}
        >
        </View>
      </View>
    );
  };

  return (
    <Page
      size={[640, 480]}
      style={{ ...styles.Page, backgroundColor: "#ecf3f4" }}
    >
      <Header backColor="#989c9c" title="6. Resultados" />
      <View style={local.body}>
        <Text style={{ ...local.title, ...styles.italic }}>
          6.6 - Emissões de gases de efeito estufa não contemplados pelo
          Protocolo de Quioto
        </Text>
        <View style={local.content}>
          <Table />
        </View>
      </View>
      <View style={{ position: 'absolute', bottom: 10, right: 10, flexDirection: 'row', alignItems: 'center' }}>
              <ImagePDF
                src={resume?.company_itens.logo_image_url}
                style={{
                  width: 70,
                  height: 'auto',
                  left: '10px',
                }}
              />
              <ImagePDF
                src={LogoVerdeEscuro}
                style={{
                  width: 100,
                  height: 'auto',
                }}
              />
            </View>
    </Page>
  );
};

const local = StyleSheet.create({
  body: {
    display: "flex",
    width: "100%",
    height: "75%",
    paddingVertical: "10px",
    alignItems: "center",
    alignContent: "center",
  },
  table: {
    width: "100%",
    height: "auto",
  },
  title: {
    color: "#6c8174",
    fontSize: 14,
    padding: "5px",
    paddingLeft: "20px",
    alignSelf: "flex-start",
    marginLeft: "13px"
  },
  content: {
    padding: "5px",
    width: "90%",
    height: "auto",
    display: "flex",
    alignSelf: "center",
    flexDirection: "column",
  },
  endColumn: {
    borderRadius: "80%",
    color: "white",
    fontSize: 9,
    alignSelf: "auto",
    paddingVertical: 3, 
    textAlign: "center",
    height: "18px",
    width: "55px",
    marginTop: -10
  },
});
