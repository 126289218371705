import React from 'react';
import { Table, TableCell, TableContainer, TableHeader, TableRow, Card, CardColumn, Summary, Legend, LegendItem, EmptyStateContainer } from './styles'; // Adicione os novos estilos aqui
import { MaccResult } from '../interfaces/MaccProjetcs';


interface MaccTableProps {
    projects: MaccResult[];
}

export const MaccTable: React.FC<MaccTableProps> = ({ projects }) => {
    const [sortField, setSortField] = React.useState<'name' | 'tCO2' | 'cost'>('name');
    const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('asc');

    if (projects.length === 0) {
        return (
            <EmptyStateContainer>
                <h3>Não há dados</h3>
                <p>Atualmente, não há projetos para serem analisados. Adicione projetos ou verifique suas fontes de dados.</p>
            </EmptyStateContainer>
        );
    } 



    const sortedByCost = [...projects].sort((a, b) => a.cost - b.cost);
    const sortedByEmission = [...projects].sort((a, b) => b.tCO2 - a.tCO2);

    const mostRecommendedProjects = projects.slice().sort((a, b) => a.cost - b.cost).slice(0, 2).map(p => p.name);
    const projectsForReview = sortedByCost.slice(-2).map(p => p.name).reverse();
    const highestEmissionAvoided = sortedByEmission[0].name;
    const lowestEmissionAvoided = sortedByEmission[sortedByEmission.length - 1].name;

    const sortedProjects = [...projects].sort((a, b) => {
        if (sortDirection === 'asc') {
            return a[sortField] > b[sortField] ? 1 : -1;
        } else {
            return a[sortField] < b[sortField] ? 1 : -1;
        }
        });    

    return (
    <Card>
        <CardColumn>
        <TableContainer>
            <Table>
                <thead>
                    <TableRow>
                    <TableHeader onClick={() => {
                            if (sortField === 'name') {
                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                            } else {
                                setSortField('name');
                                setSortDirection('asc');
                            }
                        }}>
                            Projeto {sortField === 'name' && (sortDirection === 'asc' ? '▲' : '▼')}
                        </TableHeader>
                        <TableHeader onClick={() => {
                            if (sortField === 'tCO2') {
                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                            } else {
                                setSortField('tCO2');
                                setSortDirection('asc');
                            }
                        }}>
                            tCO₂e evitada {sortField === 'tCO2' && (sortDirection === 'asc' ? '▲' : '▼')}
                        </TableHeader>
                        <TableHeader onClick={() => {
                            if (sortField === 'cost') {
                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                            } else {
                                setSortField('cost');
                                setSortDirection('asc');
                            }
                        }}>
                            Custo marginal de abatimento (R$/tCO₂e) {sortField === 'cost' && (sortDirection === 'asc' ? '▲' : '▼')}
                        </TableHeader>                      
                    </TableRow>
                </thead>
                <tbody>
                    {sortedProjects.map((project) => (
                        <TableRow key={project.name} style={project.tCO2 > 20000 ? {boxShadow: '0 0 10px green'} : {}}>
                            <TableCell>{project.name}</TableCell>
                            <TableCell>{project.tCO2.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</TableCell>
                            <TableCell>
                                {project.cost < 0 ? 
                                    <span style={{color: 'green', marginRight: '5px'}}>✔️</span> : 
                                    <span style={{color: 'red', marginRight: '5px'}}>❗</span>
                                }
                                {project.cost.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                            </TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
            <Legend>
                <LegendItem>
                    <span style={{color: 'green'}}>✔️</span>
                    Projeto efetivo (CMA negativo)
                </LegendItem>
                <LegendItem>
                    <span style={{color: 'red'}}>❗</span>
                    Custo a ser implementado (CMA positivo)
                </LegendItem>
            </Legend>
        </TableContainer>
        </CardColumn>
        <CardColumn>
        <Summary>
                    <h3>Resumo dos Projetos</h3>
                    <p>
                        A curva MACC é uma ferramenta visual que mostra as opções para abater emissões em ordem crescente de custo. Ela ajuda a identificar as opções mais econômicas para reduzir emissões.
                    </p>
                    <p>
                        <strong>Projetos Mais Recomendados:</strong> {mostRecommendedProjects.join(' e ')} são os projetos mais efetivos e devem ser priorizados.
                    </p>
                    <p>
                        <strong>Projetos para Revisão:</strong> {projectsForReview.join(' e ')} têm os maiores custos associados e devem ser revisados para otimização.
                    </p>
                    <p>
                        <strong>Maior Emissão Evitada:</strong> O projeto {highestEmissionAvoided} tem a maior quantidade de tCO₂ evitada.
                    </p>
                    <p>
                        <strong>Menor Emissão Evitada:</strong> O projeto {lowestEmissionAvoided} tem a menor quantidade de tCO₂ evitada e pode necessitar de mais atenção para aumentar sua efetividade.
                    </p>
                    <p>
                        <strong>Recomendação Geral:</strong> Priorize os projetos mais efetivos e revise os projetos com custos mais altos para garantir a máxima eficiência em redução de emissões.
                    </p>
                </Summary>
            </CardColumn>
    </Card>
    );
}
