import { Modal } from "../../../../components/Modal"
import { TitleText } from "../../../../components/TitleText"
import useAuth from "../../../../hooks/useAuth"
import { Container } from "./styles"

type ModalYoutubeProps = {
    isOpen: boolean;
    handleModalVisible: () => void;
    video: any;
}

export const ModalYoutube = ({ isOpen, handleModalVisible, video }: ModalYoutubeProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { user } = useAuth();

    const urlEmbed = (url: string) => {
        if (url.indexOf('youtube') > -1){
            return url.replace('www.youtube.com/watch?v=', 'www.youtube.com/embed/')
        }

        if (url.indexOf('loom') > -1){
            return url.replace('www.loom.com/share/', 'www.loom.com/embed/')
        }

        return url
    };

    return (
        <Modal
            isOpen={isOpen}
            isPayOrTypeClient={true}
            handleModalVisible={handleModalVisible}
        >
            <Container>
                <TitleText level={2} title={video.name} />

                <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                    
                  <iframe src={urlEmbed(video.video_url)} title="Video player" />

                </div>
            </Container>
        </Modal >
    )
}