import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import { styles } from '../styles/table'

export const TbodyItem: React.FC<{
  width: string
  textAlign?: 'center' | 'left' | 'right' | 'justify'
}> = (props) => {
  const { width, textAlign = 'center' } = props
  return (
    <View style={{ ...styles.tbodyItem, width }}>
      <Text
        style={{
          ...styles.light,
          textAlign,
          width: '100%',
          fontSize: 7
        }}
      >
        {props.children}
      </Text>
    </View>
  )
}
