import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px !important;
    position: relative;
    top: -20px;

    .container-actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
    }
`;