import { Container } from "./styles";

type StatusLabelProps = {
  width: number | undefined;
  title: string | undefined;
  completed: boolean | undefined;
  handleOnClick: (inventory: any) => void;
  inventory: any;
};

export const BulletInventory = ({ completed, title, width, handleOnClick, inventory }: StatusLabelProps) => {

  return (
    <Container completed={completed}>
      {completed === true && (
        <div  className="bulled-completed" onClick={() => handleOnClick(inventory)} style={{width: width}}>
          <span className="status-text">{title}</span>
        </div>
      )}
      {completed === false && (
        <div  className="bulled-pending" onClick={() => handleOnClick(inventory)} style={{width: width}}>
          <span className="status-text">{title}</span>
        </div>
      )}
    </Container>
  );
};
