import { api } from "./api";

async function getById(unityId: number) {
  try {
    const { data } = await api.get(`/unity/${unityId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/unity", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(unity: any) {
  try {
    const { data } = await api.post(`/unity`, unity);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(unityId: number, unity: any) {
  try {
    const { data } = await api.put(`/unity/${unityId}`, unity);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(unityId: number) {
  try {
    const { data } = await api.delete(`/unity/${unityId}`);
    return data;
  } catch (error) {
    return false;
  }
}

const unityService = {
  getById,
  getByParams,
  create,
  updateById,
  deleteById
};

export default unityService;
