import { G, Svg, Text, Rect, Line } from "@react-pdf/renderer";
import * as d3 from "d3";
import { styles } from "../../styles";

export const sum = (data) => data.emission_co2_bio + data.emission_tco2e;

export const BarChartTwo = ({ data, width, height }) => {
  // Configurações do gráfico
  const margin = { top: 20, right: 40, bottom: 40, left: 0 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  // Filter data for bars but keep original data for axis labels
  const filteredData = data.filter((d) => d.value !== 0);

  // Escalas
  const xScale = d3
    .scaleBand()
    .domain(data.map((d) => d.name)) // Use original data for x-axis labels
    .range([0, innerWidth - 60]) // Extend the range to allow space on the right
    .padding(0.5); // Adjusted padding for more space

  const yScale = d3
    .scaleLinear()
    .domain([0, 100]) // Use original data for y-axis domain
    .range([innerHeight, 0]);

  return (
    <Svg width={width} height={height}>
      <G transform={`translate(${margin.left}, ${margin.top})`}>
        {/* Desenhar as barras */}
        {filteredData.map((d, index) => {
          const xPos = xScale(d.name) + 10;
          const barWidth = xScale.bandwidth() * 0.8;
          const barHeight = innerHeight - yScale(d.value);

          return (
            <Rect
              key={index}
              x={xPos - 12} // Use xPos directly for bar's horizontal position
              y={yScale(d.value)}
              width={barWidth}
              height={barHeight}
              fill={"#326ed6"}
            />
          );
        })}
        {/* Desenhar a linha do eixo X */}
        <Line
          x1={25}
          y1={innerHeight}
          x2={innerWidth + 12}
          y2={innerHeight}
          stroke="#6c8a6e"
          strokeWidth={0.6}
        />
        {/* Adicionar eixos */}
        <G transform={`translate(0, ${innerHeight})`}>
          {xScale.domain().map((d, index) => (
            <G key={index}>
              <Text
                x={xScale(d) + xScale.bandwidth() / 2}
                y={15}
                textAnchor="middle"
                fill="#6c8a6e"
                style={{
                  fontSize: 9,
                  width: xScale.bandwidth(),
                  ...styles.light,
                }}
              >
                {d[0]}
              </Text>
              <Text
                x={xScale(d) + xScale.bandwidth() / 2}
                y={24}
                textAnchor="middle"
                fill="#6c8a6e"
                style={{
                  fontSize: 9,
                  width: xScale.bandwidth(),
                  ...styles.light,
                }}
              >
                {d[1]}
              </Text>
              <Text
                x={xScale(d) + xScale.bandwidth() / 2}
                y={33}
                textAnchor="middle"
                fill="#6c8a6e"
                style={{
                  fontSize: 9,
                  width: xScale.bandwidth(),
                  ...styles.light,
                }}
              >
                {d[2]}
              </Text>
            </G>
          ))}
        </G>
      </G>
    </Svg>
  );
};