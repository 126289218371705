import {
  Page,
  Image as ImagePDF,
  View,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";
import React from "react";
import { styles } from "../../styles";
import backgroundImage from "../../../../../../../assets/images/background-nova-conta.jpg";
import { AttachmentsItem } from "../../../interfaces/ResumeData";
import { IGEEProps } from "../../../interfaces/IGEEData";
import sinergiaBranca from "../../../../../../../assets/images/logo-branca.png";

interface IAnexes {
  anexes?: AttachmentsItem[];
  logo?: string;
}

const ITEMS_PER_PAGE = 15;

export const Page23: React.FC<IAnexes & IGEEProps> = (props) => {
  const { anexes, resume } = props;

  const renderAnexes = (scope: string, pageIndex: number) => {
    return anexes
      ? anexes
          .filter((anex) => anex.type === scope)
          .slice(pageIndex * ITEMS_PER_PAGE, (pageIndex + 1) * ITEMS_PER_PAGE)
          .map((anex, idx) => (
            <View
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #76B987",
                flexDirection: "row",
                width: "100%",
                marginVertical: "2px",
              }}
              key={idx + pageIndex * ITEMS_PER_PAGE}
            >
              <Text
                style={{ ...styles.extraLight, fontSize: 10, color: "white", marginLeft: 5 }}
              >
                Anexo {idx + 1 + pageIndex * ITEMS_PER_PAGE}
              </Text>
              <Text
                style={{ ...styles.extraLight, fontSize: 10, color: "white", marginRight: 5 }}
              >
                {anex.name}
              </Text>
            </View>
          ))
      : "";
  };

  const totalAnexes = anexes ? anexes.filter((anex) => anex.type === "Escopo 3").length : 0;
  const numberOfPages = Math.ceil(totalAnexes / ITEMS_PER_PAGE) || 1;

  const renderPage = (pageIndex: number) => {
    const pageAnexes = anexes
      ? anexes.filter((anex) => anex.type === "Escopo 3").slice(pageIndex * ITEMS_PER_PAGE, (pageIndex + 1) * ITEMS_PER_PAGE)
      : [];

    const hasAnexes = pageAnexes.length > 0;

    return (
      <Page size={[640, 480]} style={{ backgroundColor: "#326ed6" }} key={pageIndex}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
          }}
        >
          <View style={{ width: "50%", height: "100%", backgroundColor: "#326ed6" }}>
            <View style={local.content}>
              <Text style={{ ...styles.sumaryTitle, marginBottom: 6, marginTop: -25, marginLeft: '-1px' }}>
                12. Anexos
              </Text>
              <Text style={{ ...styles.extraBold, color: "#81c787", marginBottom: 5 }}>
                Escopo 3
              </Text>
              {hasAnexes ? renderAnexes("Escopo 3", pageIndex) : <Text style={{ ...styles.extraLight, fontSize: 10, color: "white"}}>Não possui anexos</Text>}
            </View>
            <View>
              <ImagePDF 
                src={resume?.company_itens.logo_image_url}
                style={{
                  position: "absolute", 
                  width: 50,
                  left: 75,
                  bottom: 5,
                }}
              />
              <ImagePDF
                src={sinergiaBranca}
                style={{ 
                  position: "absolute",
                  width: 100, 
                  left: 130,
                  bottom: 8,
                }}
              />
            </View>
          </View>
          <View style={{ width: "50%", height: "100%" }}>
            <ImagePDF
              src={backgroundImage}
              style={{
                objectFit: "cover",
                height: "100%",
                backgroundColor: "#5c96c4",
              }}
            />
          </View>
          {/* primeira linha */}
          {/* Linha Horizontal */}
          <View
            style={{
              position: "absolute",
              bottom: "15%",
              right: 0,
              width: "50%",
              height: 1,
              backgroundColor: "white",
              borderTop: "1px solid white",
            }}
          />
          {/* Linha Vertical */}
          <View
            style={{
              position: "absolute",
              top: 0,
              right: "46%",
              width: 1,
              height: "100%",
              backgroundColor: "white",
              borderRight: "1px solid white",
            }}
          />
          {/* Bola 1 */}
          <View
            style={{
              position: "absolute",
              top: "93.7%",
              right: "52.7%",
              width: 15,
              height: 15,
              borderRadius: "50%",
              backgroundColor: "white",
              transform: "translate(50%, -50%)",
            }}
          />
          {/* Bola 2*/}
          <View
            style={{
              position: "absolute",
              top: "93.7%",
              right: "11.7%",
              width: 15,
              height: 15,
              borderRadius: "50%",
              backgroundColor: "white",
              transform: "translate(50%, -50%)",
            }}
          />
        </View>
      </Page>
    );
  };

  return (
    <>
      {anexes && anexes.length > 0
        ? Array.from({ length: numberOfPages }).map((_, pageIndex) => renderPage(pageIndex))
        : renderPage(0)}
    </>
  );
};

const local = StyleSheet.create({
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "80%",
    justifyContent: "center",
    alignContent: "center",
    alignSelf: "center",
  },
});
 