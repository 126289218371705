import { api } from "./api";

async function getById(trainCompaniesId: number) {
  try {
    const { data } = await api.get(`/train_companies/${trainCompaniesId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/train_companies", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(train_companies: any) {
  try {
    const { data } = await api.post(`/train_companies`, train_companies);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(trainCompaniesId: number, train_companies: any) {
  try {
    const { data } = await api.put(`/train_companies/${trainCompaniesId}`, train_companies);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(trainCompaniesId: number) {
  try {
    const { data } = await api.delete(`/train_companies/${trainCompaniesId}`);
    return data;
  } catch (error) {
    return false;
  }
}

const trainCompanyService = {
  getById,
  getByParams,
  create,
  updateById,
  deleteById
};

export default trainCompanyService;
