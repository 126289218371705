import styled from "styled-components";

type DefaultTextProps = {
  bold: boolean | undefined;
};

export const Container = styled.span<DefaultTextProps>`
  font-family: "Poppins", sans-serif;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.black};
  opacity: 0.9;
`;
