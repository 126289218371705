import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import energyTypeService from "../../../services/energyTypeService";
import { Container } from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

interface Inputs {
  name: string;
  need_fuel: boolean;
  description: string;
}

export const EnergyTypeEdit = () => {
  const history = useHistory();

  const [energyTypeSelected, setEnergyTypeSelected] = useState<any>({});
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.energyType_id){
      getEnergyTypesById(item.energyType_id)
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const newEnergyType = {
        name: formData.name,
        need_fuel: formData.need_fuel,
        description: formData.description
      };

      if (energyTypeSelected?.id) {
        const result = await energyTypeService.updateById(
          energyTypeSelected.id,
          newEnergyType
        );

        if (result && !result.error) {
          //history.goBack()

          //reset();
          //setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await energyTypeService.create(newEnergyType);

        if (result && !result.error) {
          history.goBack()

          reset();
          setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  async function getEnergyTypesById(id: number) {
    const energyType = await energyTypeService.getById(id);

    setValue("name", energyType?.name);
    setValue("need_fuel", energyType?.need_fuel);
    setValue("description", energyType?.description);

    setEnergyTypeSelected(energyType)
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Tipo de energia" />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 30, marginLeft: 50, marginRight: 50  }} >
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "70%" }} >
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Nome
              </label>

              <input style={{width: '95%'}}
                    {...register("name", { required: true })}/>
                          
              {errors.name && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "30%" }} >
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Necessita combustível?
              </label>

              <input type="checkbox" {...register("need_fuel")} style={{ width: 20, height: 20}}/>
                          
              {errors.need_fuel && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
          </div>
          
          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Descrição
          </label>

          <textarea style={{width: '95%', height: 100}}
                {...register("description")}/>
                      
          {errors.description && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>
      </form>
    </Container>
  );
};
