import {
  Page,
  Image as ImagePDF,
  View,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";
import React from "react";
import { styles } from "../../styles";
import backgroundImage from "./image.png";
import { IGEEProps } from "../../../interfaces/IGEEData";
import OcaImage from "../../../../../../../assets/images/oca-vazada.png"

export const Page21: React.FC<IGEEProps> = (props) => {
  const { resume } = props;
  return (
    <Page size={[640, 480]} style={{backgroundColor: "#ecf3f4"}}>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
        }}
      >
        <View style={{ width: "60%", height: "100%" }}>
          <ImagePDF
            src={backgroundImage}
            style={{ width: "100%", height: "100%" }}
          />
        </View>
        <View style={{ width: "40%", height: "100%" }}>
          <View style={local.content}>
            <Text
              style={{
                ...styles.light,
                fontSize: 22,
                color: "#326ed6",
                textAlign: "right",
                marginBottom: 5,
                lineHeight: 1.2,
              }}
            >
              Um novo capítulo {"\n"}da nossa história.
            </Text>
            <Text
              style={{
                ...styles.extraBold,
                fontSize: 12,
                color: "#b3b3b3",
                textAlign: "right",
                marginBottom: 5,
              }}
            >
              Conclusão
            </Text>
            <Text
              style={{
                ...styles.extraLight,
                fontSize: 10,
                textAlign: "right",
                color: "#b3b3b3",
                marginBottom: 2
              }}
            >
              {resume?.company_itens.conclusion_company
                ? resume.company_itens.conclusion_company
                : " "}
            </Text>
            <Text
              style={{
                ...styles.extraBold,
                fontSize: 12,
                textAlign: "right",
                color: "#b3b3b3",
              }}
            >
               {resume?.company_itens.ceo_name}, 2024
            </Text>
          </View>
          <View>
            <View 
              style={{
                backgroundColor: 'transparent', 
                width: 35, 
                height: 50, 
                borderRadius: 100,
                justifyContent: 'center', 
                alignItems: 'center', 
                borderWidth: 1, 
                borderColor: '#326ed6',
                marginLeft: '90px',
              }}
            />
            <ImagePDF
              src={OcaImage}
              style={{
                width: 110,
                height: 130,
                marginLeft: '110px',
              }}
            />
          </View>
        </View>
      </View>
    </Page>
  );
};
const local = StyleSheet.create({
  content: {
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "80%",
    justifyContent: "flex-start",
    alignContent: "center",
    alignSelf: "center",
  },
  bigN: {
    fontSize: 170,
    textAlign: "center",
    marginLeft: "10px",
    color: "#2c6cb4",
  },
});
