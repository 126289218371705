import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import { styles } from '../styles/common'
import moment from 'moment'
import { Company } from '../../../interfaces/Company'

interface FooterProps {
  page?: string;
  company?: Company; 
}

export const Footer: React.FC<FooterProps> = (props) => {
  const { company } = props; 
  const minutes = moment().minutes()
  const currentHours = moment().hours()
  const currentMinutes = minutes < 10 ? `0${minutes}` : minutes
  const currentDate = moment().format('DD/MM/YYYY')

  return (
    <View fixed style={styles.footerContainer}>
      <View style={styles.footerLine} />
      <View
        style={{
          justifyContent: 'space-between',
          flexDirection: 'row',
          width: '100%',
          margin: '6px 0'
        }}
      >
        <View style={styles.row}>
          <Text style={{  marginRight: 10, ...styles.light, fontSize: 10 }}>{currentDate}</Text>
          <Text style={{ ...styles.light, fontSize: 10}}>{`${currentHours}:${currentMinutes}`}</Text>
        </View>
        <Text style={{...styles.Bold, fontSize: 10}}>{props.company}</Text>
        <Text render={(props) => `pág. ${props.pageNumber}`} style={{...styles.light, fontSize: 10}} />
      </View>
    </View>
  )
}
