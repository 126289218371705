import {
  Page,
  Text,
  View,
  StyleSheet,
  Image as ImagePDF,
} from "@react-pdf/renderer";
import React from "react";
import { Header } from "../../components/Header";
import { styles } from "../../styles";
import { IGEEProps } from "../../../interfaces/IGEEData";
import LogoAzul from "../../../../../../../assets/images/logo-azul.png";

export const Page19: React.FC<IGEEProps> = (props) => {
  const { resume } = props;

  const inventoryElementsCompensation = resume?.inventory_elements_compensation.inventory_elements_compensation || [];
  const inventoryCompensationProject = resume?.inventory_elements_project.inventory_elements_project || [];

  return (
    <Page size={[640, 480]} style={styles.Page}>
      <Header title="11. Compensações e reduções" backColor="#326ed6" />
      <View style={local.body}>
        <Text style={{ ...local.title, ...styles.italic}}>
          11.1 Compensação de emissões {"\n"}
          <Text style={{ ...local.subTitle, ...styles.light }}>
            A organização possui projetos de compensação de emissões?
          </Text>
        </Text>
        <View style={local.compSection}>
        <View
            style={{
              position: "absolute",
              top: "126%",
              width: 20,
              height: 20,
              right: '14%',
              borderRadius: "50%",
              backgroundColor: "#326ed6",
              transform: "translate(50%, -50%)",
            }}
          />
          <View style={table.body}>
            <View style={table.Header}>
              <Text style={{ ...table.ColG, ...styles.Bold, fontSize: 11, marginLeft: '25px'}}>
                Projetos de compensação
              </Text>
              <Text style={{ ...table.Col, ...styles.light}}>
                Quantidade compensada {"\n"}
                <Text style={{...styles.Bold}}>
                  (tCO<Text style={{ fontSize: 5 }}>2</Text>e)
                </Text>
              </Text>
            </View>
            <View style={table.Body}>
              {inventoryCompensationProject?.map((info, idx) => {
                return (
                  <View
                    style={{
                      backgroundColor: idx % 2 === 0 ? "white" : "#c4cccc",
                      ...table.row,
                    }}
                    key={idx}
                  >
                    <Text style={{ ...table.colG, ...styles.light }}>
                      {info.awnser}
                    </Text>
                    <Text style={{...table.Col, ...styles.light, marginLeft: 70}}>
                      {resume?.igee_itens?.compensation_amount === 0 
                        ? "-" 
                        : resume?.igee_itens?.compensation_amount.toLocaleString('pt-BR', { minimumFractionDigits: 4 })}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>
        </View>
        <Text style={{ ...local.title, ...styles.italic, marginTop: '15px' }}>
          11.2 Redução de emissões {"\n"}
          <Text style={{ ...local.subTitle, ...styles.light}}>
            A organização possui projetos de redução de emissões?
          </Text>
        </Text>
          <Text style={{
              flexDirection: "column",
              justifyContent: "center", 
              alignItems: "flex-start",
              width: "90%", 
              textAlign: "justify", 
              marginLeft: '-30px',
            }}>
            {inventoryElementsCompensation.map((info, idx) => (
              <View
              style={{
                padding: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "100%",
                textAlign: "justify",
              }}
              key={idx}
              >
              <Text
                style={{
                  color: "#64766d",
                  fontSize: 10,
                  ...styles.light,
                }}
                >
                {info.awnser}
              </Text>
            </View>
            ))}
          </Text>
          <ImagePDF 
                src={resume?.company_itens.logo_image_url}
                style={{
                  width: 50,
                  right: '100px',
                  position: 'absolute',
                  bottom: '0px',
                }}
              />
              <ImagePDF
                src={LogoAzul}
                style={{ 
                  width: 60,
                  right: '20px', 
                  position: 'absolute',
                  bottom: '0px',
                }}
              />
      </View>
    </Page>
  );
};

const table = StyleSheet.create({
  body: {
    display: "flex",
    flexDirection: "column",
  },
  Header: {
    display: "flex",
    flexDirection: "row",
    fontSize: 13,
    color: "#b3b3b3",
    margin: "10px",
    alignItems: "center",
    textAlign: "center",
  },
  ColG: {
    width: "55%",
    textAlign: "left",
    paddingLeft: "10px",
  },
  Col: {
    width: "45%",
    fontSize: 10,
  },
  Body: {
    fontSize: 10,
    color: "#6b8077",
  },
  colG: {
    width: "82%",
    textAlign: "left",
    paddingLeft: "10px",
  },
  col: {
    width: "18%",
  },
  row: {
    width: "85%",
    height: "30px",
    marginBottom: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    marginLeft: '35px',
  },
});

const local = StyleSheet.create({
  body: {
    display: "flex",
    width: "100%",
    height: "90%",
    alignItems: "center",
    alignContent: "center",
    padding: "20px",
  },
  title: {
    color: "#326ed6",
    fontSize: 12,
    padding: "5px",
    alignSelf: "flex-start",
    marginLeft: '10px',
  },
  subTitle: {
    color: "#2c6cb4",
    fontSize: 12,
  },
  compSection: {
    height: "40%",
    width: "110%",
    borderBottom: "1px solid #326ed6",
  },
  reduSection: {
    height: "25%",
    width: "100%",
    textAlign: "center",
    alignItems: "center",
  },
  additionalInfo: {
    width: "100%",
    marginTop: 10,
    position: 'absolute',
  },
});
