import { api } from "./api";

async function getById(transport_typeId: number) {
  try {
    const { data } = await api.get(`/transport_type/${transport_typeId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/transport_type", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(transport_type: any) {
  try {
    const { data } = await api.post(`/transport_type`, transport_type);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(transport_typeId: number, transport_type: any) {
  try {
    const { data } = await api.put(`/transport_type/${transport_typeId}`, transport_type);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(transport_typeId: number) {
  try {
    const { data } = await api.delete(`/transport_type/${transport_typeId}`);
    return data;
  } catch (error) {
    return false;
  }
}

const transportTypeService = {
  getById,
  getByParams,
  create,
  updateById,
  deleteById
};

export default transportTypeService;
