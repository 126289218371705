import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import companyService from "../../../services/companyService";
import { Container } from "./styles";
import Parser from 'html-react-parser'
import {
  isValidCnpj,
  isValidCpf,
  toNumberValues,
  transformCNPJ,
  transformCPF,
  transformOnlyNumbers,
  transformPhone
} from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import activityService from "../../../services/activityService";
import viacepService from "../../../services/viacepService";
import { toast } from "react-hot-toast";
import { Loading } from "../../../components/Loading";
import defaultImage from "../../../assets/images/fundo-pdf.jpg";
import scopeService from "../../../services/scopeService";
import { Modal } from "../../../components/Modal";

interface Inputs {
  UF: string;
  sector: any;
  cep: string;
  cnpj: string;
  cnae: string;
  logo: string;
  city: string;
  company: any;
  subsector: any;
  number: string;
  address: string;
  sector_name: string;
  fantasy_name: string;
  logo_filename: string;
  corporate_name: string;
  subsector_name: string;
  responsible_cpf: string;
  responsible_name: string;
  responsible_email: string;
  responsible_office: string;
  director_message: string;
  director: string;
  director_filename: string;
  cover: string;
  cover_filename: string;
  services_provided: string;
  instagram_company: string;
  site_company: string;
  conclusion_company: string;
  phone_number: any;
}

export const CompanyEdit = () => {
  const history = useHistory();

  const location = useLocation();
  const [UF, setUF] = useState("");
  const [cep, setCep] = useState("");
  const [city, setCity] = useState("");
  const [number, setNumber] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [address, setAddress] = useState("");
  const [imageLogo, setImageLogo] = useState("");
  const [imageDirector, setImageDirector] = useState("");
  const [imageCover, setImageCover] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [onlyView, setOnlyView] = useState(false);
  const [anonymize, setAnonymize] = useState(false);
  const [companies, setCompanies] = useState<any>([]);
  const [invalidCpf, setInvalidCpf] = useState(false);
  const [invalidCnpj, setInvalidCnpj] = useState(false);
  const [logoChanged, setLogoChanged] = useState(false);
  const [imageDirectorChanged, setImageDirectorChanged] = useState(false);
  const [imageCoverChanged, setImageCoverChanged] = useState(false);
  const [activities, setActivities] = useState<any>([]);
  const [selCompany, setSelCompany] = useState<any>([]);
  const [subActivities, setSubActivities] = useState<any>([]);
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [companySelected, setCompanySelected] = useState<any>({});
  const [loadingModal, setLoadingModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [remainingChars, setRemainingChars] = useState<Partial<Record<keyof Inputs, string>>>({
    director_message: "415",
  });
  const [openModal, setOpenModal] = useState(false)
  const [guidelines, setGuidelines] = useState<string | undefined>()
  const [title, setTitle] = useState<string>('')

  useEffect(
    () => {
      const item: any = location?.state;

      if (item && item.company_id) {
        getCompanysById(item.company_id);

        setAnonymize(user.pseudo_anonymize);
        setOnlyView(
          user.profile !== "sinergia" &&
            user.profile !== "admin" &&
            user.profile !== "analyst"
        );
      } else {
        history.push({ pathname: "/empresa" });
      }
      getGuidelines()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  async function getCompanies() {
    const result = await companyService.getParents();

    const options = result.itens.map((company: any) => {
      return {
        label: company.corporate_name,
        value: company.id,
      };
    });

    setCompanies(options);
  }

  useEffect(
    () => {
      getCompanies();
      getActivities();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { user } = useAuth();

  async function getGuidelines() {
    const item = await scopeService.getBySlug(4, 'company_edit')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    setLoadingModal(true);
    if (formData) {
      setInvalidCpf(false);
      setInvalidCnpj(false);

      if (anonymize || isValidCnpj(formData.cnpj)) {
        if (anonymize || isValidCpf(formData.responsible_cpf)) {
          let formUf = UF;
          let formCep = cep;
          let formCity = city;
          let formNumber = number;
          let formAddress = logradouro;

          if (formCity === undefined || formCity === "")
            formCity = formData.city;

          if (formUf === undefined || formUf === "") formUf = formData.UF;

          if (formCep === undefined || formCep === "") formCep = formData.cep;

          if (formAddress === undefined || formAddress === "")
            formAddress = formData.address;

          if (formNumber === undefined || formNumber === "")
            formNumber = formData.number;

          let newCompany: any = {
            cnae: formData.cnae,
            sector: formData.sector.label,
            fantasy_name: formData.fantasy_name,
            subsector: formData.subsector.label,
            corporate_name: formData.corporate_name,
            responsible_cpf: formData.responsible_cpf,
            responsible_name: formData.responsible_name,
            logo: logoChanged ? formData.logo : undefined,
            responsible_email: formData.responsible_email,
            phone_number: formData.phone_number,
            responsible_office: formData.responsible_office,
            logo_filename: logoChanged ? formData.logo_filename : undefined,
            company_id: formData.company ? formData.company.value : undefined,
            cnpj: formData.cnpj
              ? transformOnlyNumbers(formData.cnpj)
              : undefined,
            director_message: formData.director_message,
            conclusion_company: formData.conclusion_company,
            services_provided: formData.services_provided,
            instagram_company: formData.instagram_company,
            site_company: formData.site_company,
            director: imageDirectorChanged ? formData.director : undefined,
            director_filename: imageDirectorChanged
              ? formData.director_filename
              : undefined,
            cover: imageCoverChanged ? formData.cover : undefined,
            cover_filename: imageCoverChanged
              ? formData.cover_filename
              : undefined,
            address: {
              address: formAddress,
              city: formCity,
              UF: formUf,
              CEP: formCep,
              number: formNumber,
            },
          };

          if (user.pseudo_anonymize) {
            newCompany = {
              corporate_name: formData.corporate_name,
              fantasy_name: formData.fantasy_name,
              sector: formData.sector.label,
              subsector: formData.subsector.label,
              responsible_office: formData.responsible_office,
              phone_number: formData.phone_number,
              cnae: formData.cnae,
              logo: logoChanged ? formData.logo : undefined,
              logo_filename: logoChanged ? formData.logo_filename : undefined,
              director_message: formData.director_message,
              conclusion_company: formData.conclusion_company,
              services_provided: formData.services_provided,
              instagram_company: formData.instagram_company,
              site_company: formData.site_company,
              director_filename: imageDirectorChanged
                ? formData.director_filename
                : undefined,
              director: imageDirectorChanged ? formData.director : undefined,
              cover: imageCoverChanged ? formData.cover : undefined,
              cover_filename: imageCoverChanged
                ? formData.cover_filename
                : undefined,
              address: {
                address: formAddress,
                city: formCity,
                UF: formUf,
                CEP: formCep,
                number: formNumber,
              },
            };
          }

          if (companySelected?.id) {
            const result = await companyService.updateById(
              companySelected.id,
              newCompany
            );

            if (result && !result.error) {

              reset();
              setErrorApi(undefined);

              toast.success("Operação realizada com sucesso!");
            }

            if (result.error) {
              setErrorApi(result.error);
            }
          } else {
            const result = await companyService.create(newCompany);

            if (result && !result.error) {
             /// history.goBack();

              //reset();
              //setErrorApi(undefined);

              toast.success("Operação realizada com sucesso!");
            }

            if (result.error) {
              setErrorApi(result.error);
            }
          }
        } else {
          setInvalidCpf(true);
        }
      } else {
        setInvalidCnpj(true);
      }
    }
    setLoadingModal(false);
  };

  async function getActivities() {
    setLoadingModal(false);
    const result = await activityService.getByParams({ limit: 90000, page: 1 });

    const options = result.itens.map((activity: any) => {
      return {
        label: activity.name,
        value: activity.id,
      };
    });

    setActivities(options);
    setLoadingModal(false);
  }

  const logoChangeHandler = (event: any) => {
    const file = event.target.files[0];

    const maxSizeInBytes = 24 * 1024 * 1024;

    if (file.size > maxSizeInBytes) {
      alert(
        "O tamanho do arquivo excede o limite permitido de 24 MB. Por favor, selecione uma imagem menor."
      );
      return;
    }

    async function saveImage(base64?: string) {
      setValue("logo", base64 ? base64 : "");
      setImageLogo(base64 ? base64 : "");
      setValue("logo_filename", file.name);
    }

    setLogoChanged(true);

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64 = reader.result;

      saveImage(base64?.toString());
    };
  };

  const ImageDirectorChangeHandler = (event: any) => {
    const file = event.target.files[0];

    const maxSizeInBytes = 24 * 1024 * 1024;

    if (file.size > maxSizeInBytes) {
      alert(
        "O tamanho do arquivo excede o limite permitido de 24 MB. Por favor, selecione uma imagem menor."
      );
      return;
    }

    async function saveImage(base64?: string) {
      setValue("director", base64 ? base64 : "");
      setImageDirector(base64 ? base64 : "");
      setValue("director_filename", file.name);
    }

    setImageDirectorChanged(true);

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64 = reader.result;

      saveImage(base64?.toString());
    };
  };

  const ImageCoverChangeHandler = async (
    event: any,
    useDefaultImage: boolean = false
  ) => {
    async function saveImage(base64?: string, filename?: string) {
      setValue("cover", base64 ? base64 : "");
      setImageCover(base64 ? base64 : "");
      setValue("cover_filename", filename || "");
    }

    setImageCoverChanged(true);

    if (useDefaultImage) {
      const defaultImageBase64 = await convertToBase64(defaultImage);
      const defaultImageFilename = "fundos-imagens-07.png";
      console.log(defaultImageFilename);
      saveImage(defaultImageBase64, defaultImageFilename);
      return;
    }

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64 = reader.result;

      saveImage(base64?.toString(), file.name);
    };
  };

  // Função para converter a imagem para base64
  const convertToBase64 = (file: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result as string);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = function () {
        reject(new Error("Erro ao carregar imagem"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", file);
      xhr.send();
    });
  };

  const handleChangeActivity = (e: any) => {
    setSubActivities([]);
    setValue("sector", e);
    setValue("subsector", {});

    async function getSubActivities() {
      const item = await activityService.getById(e.value);

      const options = item.subactivities.map((sub: any) => {
        return {
          label: sub,
          value: sub,
        };
      });

      setSubActivities(options);
    }

    if (e.value) {
      getSubActivities();
    }
  };

  useEffect(
    () => {
      const cepRegex = /^[0-9]{5}[0-9]{3}$/;

      async function retrieveCep() {
        const inputElementUf = document.querySelector(
          "#UF"
        ) as HTMLInputElement;
        const inputElementLocal = document.querySelector(
          "#CITY"
        ) as HTMLInputElement;
        const inputElementAddress = document.querySelector(
          "#ADDRESS"
        ) as HTMLInputElement;

        const r = await viacepService.getCep(cep);

        if (r.uf) {
          inputElementUf.value = `${r?.uf}`;
          setUF(r?.uf);
          setValue("UF", r?.uf);
        } else {
          setUF("");
          setValue("UF", "");
        }

        if (r.localidade) {
          inputElementLocal.value = `${r?.localidade}`;
          setCity(r?.localidade);
          setValue("city", r?.localidade);
        } else {
          setCity("");
          setValue("city", "");
        }

        if (r.logradouro && r.bairro) {
          inputElementAddress.value = `${r?.logradouro}, ${r?.bairro}`;
          setLogradouro(`${r?.logradouro}, ${r?.bairro}`);
          setValue("address", `${r?.logradouro}, ${r?.bairro}`);
        } else {
          setLogradouro("");
          setValue("address", "");
        }
      }

      if (cep && cepRegex.test(cep)) retrieveCep();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [cep]
  );

  async function getCompanysById(id: number) {
    const company = await companyService.getById(id);

    setValue("corporate_name", company?.corporate_name);
    setValue("fantasy_name", company?.fantasy_name);
    setValue("director_message", company?.director_message);
    setValue("services_provided", company?.services_provided);
    setValue("conclusion_company", company?.conclusion_company);

    if (company?.cnpj) {
      setValue("cnpj", transformCNPJ(company?.cnpj));
    }

    setValue("cnae", company?.cnae);
    setValue("responsible_name", company?.responsible_name);

    if (company?.responsible_cpf) {
      setValue("responsible_cpf", transformCPF(company?.responsible_cpf));
    }

    setValue("responsible_email", company?.responsible_email);
    setValue("responsible_office", company?.responsible_office);

    if (company?.sector) {
      setValue("sector", { label: company?.sector, value: company?.sector });
      setValue("sector_name", company?.sector);
    }

    if (company?.subsector) {
      setValue("subsector", {
        label: company?.subsector,
        value: company?.subsector,
      });
      setValue("sector_name", company?.subsector);
    }

    if (company?.parent) {
      const option = {
        label: company?.parent?.corporate_name,
        value: company?.parent?.id,
      };

      setSelCompany(option);
      setValue("company", option);
    }

    if (company?.instagram_company) {
      setValue("instagram_company", company?.instagram_company);
    }

    if (company?.site_company) {
      setValue("site_company", company?.site_company);
    }

    setValue("number", company?.address?.number);
    setValue("address", company?.address?.address);
    setValue("cep", company?.address?.CEP);
    setValue("UF", company?.address?.UF);
    setValue("city", company?.address?.city);
    setValue("phone_number", transformPhone(company?.phone_number));

    setImageLogo(company?.logo_image_url);
    setImageDirector(company?.director_image_url);
    setImageCover(company?.cover_image_url);

    setCompanySelected(company);
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const maxLength = 415;  // ING-35
    const trimmedValue = value.slice(0, maxLength); // Limita o texto a maxLength caracteres
    const remaining = maxLength - trimmedValue.length;
    setRemainingChars((prevChars) => ({
      ...prevChars,
      [name as keyof Inputs]: remaining,
    }));
    setValue(name as keyof Inputs, trimmedValue);
  };

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 10,
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <TitleText level={1} title="Empresa" />

          {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}

          {onlyView === false && (
            <Button color="green" size="md" type="submit">
              Salvar
            </Button>
          )}
        </div>

        <div
          style={{
            width: "100%",
            borderTop: "0.6px solid rgba(49, 54, 63, 0.35)",
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "90%",
            marginTop: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
              position: "absolute",
              top: "100px",
              right: "10px",
              left: "290px",
              bottom: "20px",
            }}
          >
            <div
              style={{ display: "left", flexDirection: "column", width: "60%" }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", width: "95%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "50%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    Razão social
                  </label>
                  <input
                    style={{ width: "100%" }}
                    disabled={onlyView}
                    {...register("corporate_name", { required: true })}
                  />
                  {errors.corporate_name && (
                    <span className="requiredLabel">
                      Esse campo é obrigatório
                    </span>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "50%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    Nome fantasia
                  </label>
                  <input
                    style={{ width: "100%" }}
                    disabled={onlyView}
                    {...register("fantasy_name", { required: true })}
                  />
                  {errors.fantasy_name && (
                    <span className="requiredLabel">
                      Esse campo é obrigatório
                    </span>
                  )}
                </div>
              </div>

              {companySelected &&
                companySelected.itens &&
                companySelected.itens.length === 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "98%",
                    }}
                  >
                    <label
                      style={{
                        marginTop: 5,
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: "16px",
                        lineHeight: "19px",
                        color: "#31363F",
                        opacity: "0.8",
                        fontWeight: "bold",
                      }}
                    >
                      Matriz
                    </label>
                    {companies && (
                      <Controller
                        control={control}
                        rules={{ required: false }}
                        name="company"
                        render={({ field }) => (
                          <Select
                            placeholder="[Selecione]"
                            {...field}
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                width: "95%",
                                height: 40,
                                marginTop: 10,
                                backgroundColor: "rgb(246, 246, 246)",
                              }),
                              option: (styles) => {
                                return { ...styles, color: "#31363F" };
                              },
                            }}
                            defaultValue={selCompany}
                            options={companies}
                            isClearable={true}
                          />
                        )}
                      />
                    )}

                    {errors.company && (
                      <span style={{ color: "#E33F3F", marginTop: 10 }}>
                        Esse campo é obrigatório
                      </span>
                    )}
                  </div>
                )}

              <div
                style={{ display: "flex", flexDirection: "row", width: "95%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "50%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    Setor
                  </label>

                  {onlyView === true && (
                    <input
                      style={{ width: "100%" }}
                      disabled
                      {...register("sector_name")}
                    />
                  )}

                  {onlyView === false && activities && (
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="sector"
                      render={({ field }) => (
                        <Select
                          placeholder="[Selecione]"
                          {...field}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: "100%",
                              height: 40,
                              marginTop: 10,
                              backgroundColor: "rgb(246, 246, 246)",
                            }),
                            option: (styles) => {
                              return { ...styles, color: "#31363F" };
                            },
                          }}
                          defaultValue={getValues("sector")}
                          options={activities}
                          isClearable={true}
                          onChange={handleChangeActivity}
                        />
                      )}
                    />
                  )}
                  {errors.sector && (
                    <span className="requiredLabel">
                      Esse campo é obrigatório
                    </span>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "50%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    Sub-setor
                  </label>

                  {onlyView === true && (
                    <input
                      style={{ width: "100%" }}
                      disabled
                      {...register("subsector_name")}
                    />
                  )}

                  {onlyView === false && subActivities && (
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="subsector"
                      render={({ field }) => (
                        <Select
                          placeholder="[Selecione]"
                          {...field}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: "100%",
                              height: 40,
                              marginTop: 10,
                              backgroundColor: "rgb(246, 246, 246)",
                            }),
                            option: (styles) => {
                              return { ...styles, color: "#31363F" };
                            },
                          }}
                          defaultValue={getValues("subsector")}
                          options={subActivities}
                          isClearable={true}
                        />
                      )}
                    />
                  )}
                  {errors.subsector && (
                    <span className="requiredLabel">
                      Esse campo é obrigatório
                    </span>
                  )}
                </div>
              </div>

              <div
                style={{ display: "flex", flexDirection: "row", width: "95%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "50%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    CNPJ
                  </label>
                  <input
                    style={{ width: "100%" }}
                    disabled={onlyView || anonymize}
                    {...register("cnpj", { required: true })}
                    onChange={(event) => {
                      const { value } = event.target;
                      event.target.value = transformCNPJ(value);
                    }}
                  />
                  {errors.cnpj && (
                    <span className="requiredLabel">
                      Esse campo é obrigatório
                    </span>
                  )}
                  {errorApi && errorApi.includes("cnpj") && (
                    <span className="requiredLabel">
                      CNPJ já registrado no sistema.
                    </span>
                  )}
                  {invalidCnpj && (
                    <span className="requiredLabel">CNPJ inválido.</span>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "50%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    CNAE
                  </label>
                  <input
                    style={{ width: "100%" }}
                    disabled={onlyView}
                    {...register("cnae", { required: false })}
                  />
                  {errors.cnae && (
                    <span className="requiredLabel">
                      Esse campo é obrigatório
                    </span>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 10,
                  width: "94%",
                }}
              >
                <label
                  style={{
                    marginTop: 5,
                    fontFamily: "'Roboto', sans-serif",
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: "#31363F",
                    opacity: "0.8",
                    fontWeight: "bold",
                  }}
                >
                  Serviços prestados
                </label>
                <input
                  style={{ width: "100%" }}
                  disabled={onlyView}
                  {...register("services_provided", { required: false })}
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", width: "95%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "15%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    CEP
                  </label>
                  <input
                    style={{ width: "100%" }}
                    disabled={onlyView}
                    {...register("cep", { required: true })}
                    onChange={(event) => {
                      const { value } = event.target;
                      event.target.value = toNumberValues(value);
                      setCep(toNumberValues(value));
                    }}
                  />
                  {errors.cep && (
                    <span className="requiredLabel">
                      Esse campo é obrigatório
                    </span>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "75%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    Endereço
                  </label>
                  <input
                    style={{ width: "100%" }}
                    disabled={onlyView}
                    id="ADDRESS"
                    {...register("address")}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "10%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    Número
                  </label>
                  <input
                    style={{ width: "100%" }}
                    disabled={onlyView}
                    {...register("number")}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
              </div>

              <div
                style={{ display: "flex", flexDirection: "row", width: "95%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "50%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    Cidade
                  </label>
                  <input
                    style={{ width: "100%" }}
                    disabled={onlyView}
                    id="CITY"
                    {...register("city")}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "50%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    Estado
                  </label>
                  <input
                    style={{ width: "100%" }}
                    disabled={onlyView}
                    id="UF"
                    {...register("UF")}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>

              <div
                style={{ display: "flex", flexDirection: "row", width: "95%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "30%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    Número de telefone
                  </label>
                  <input
                    style={{ width: "100%" }}
                    disabled={onlyView}
                    {...register("phone_number", { required: false })}
                    onChange={(event) => {
                      const { value } = event.target;
                     
                      event.target.value = transformPhone(value);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "50%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    Nome do responsável
                  </label>
                  <input
                    style={{ width: "100%" }}
                    disabled={onlyView || anonymize}
                    {...register("responsible_name", { required: true })}
                  />
                  {errors.responsible_name && (
                    <span className="requiredLabel">
                      Esse campo é obrigatório
                    </span>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "50%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    CPF do responsável
                  </label>
                  <input
                    style={{ width: "100%" }}
                    disabled={onlyView || anonymize}
                    {...register("responsible_cpf", { required: true })}
                    onChange={(event) => {
                      const { value } = event.target;
                      event.target.value = transformCPF(value);
                    }}
                  />
                  {errors.responsible_cpf && (
                    <span className="requiredLabel">
                      Esse campo é obrigatório
                    </span>
                  )}
                  {invalidCpf && (
                    <span className="requiredLabel">CPF inválido.</span>
                  )}
                </div>
              </div>

              <div
                style={{ display: "flex", flexDirection: "row", width: "95%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "50%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    E-mail do responsável
                  </label>
                  <input
                    style={{ width: "100%" }}
                    disabled={onlyView || anonymize}
                    {...register("responsible_email", { required: true })}
                  />
                  {errors.responsible_email && (
                    <span className="requiredLabel">
                      Esse campo é obrigatório
                    </span>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "50%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    Cargo do responsável
                  </label>
                  <input
                    style={{ width: "100%" }}
                    disabled={onlyView}
                    {...register("responsible_office", { required: true })}
                  />
                  {errors.responsible_office && (
                    <span className="requiredLabel">
                      Esse campo é obrigatório
                    </span>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 10,
                  width: "94%",
                }}
              >
                <label
                  style={{
                    marginTop: 5,
                    fontFamily: "'Roboto', sans-serif",
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: "#31363F",
                    opacity: "0.8",
                    fontWeight: "bold",
                  }}
                >
                  Mensagem do diretor ({remainingChars.director_message})
                </label>
                <input
                  style={{ width: "100%" }}
                  disabled={onlyView}
                  {...register("director_message", { required: false })}
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 10,
                  width: "94%",
                }}
              >
                <label
                  style={{
                    marginTop: 5,
                    fontFamily: "'Roboto', sans-serif",
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: "#31363F",
                    opacity: "0.8",
                    fontWeight: "bold",
                  }}
                >
                  Conclusão
                </label>
                <input
                  style={{ width: "100%" }}
                  disabled={onlyView}
                  {...register("conclusion_company", { required: false })}
                />
              </div>

              <div
                style={{ display: "flex", flexDirection: "row", width: "95%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "25%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    Instagram
                  </label>
                  <input
                    style={{ width: "100%" }}
                    disabled={onlyView}
                    {...register("instagram_company", { required: false })}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 10,
                    width: "30%",
                  }}
                >
                  <label
                    style={{
                      marginTop: 5,
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#31363F",
                      opacity: "0.8",
                      fontWeight: "bold",
                    }}
                  >
                    Site Institucional
                  </label>
                  <input
                    style={{ width: "100%" }}
                    disabled={onlyView}
                    {...register("site_company", { required: false })}
                  />
                </div>
              </div>
              {companySelected &&
                companySelected.itens &&
                companySelected.itens.length > 0 && (
                  <span
                    style={{
                      textAlign: "start",
                      fontSize: 14,
                      marginTop: 20,
                      fontWeight: "bold",
                    }}
                  >
                    Empresas relacionadas a esta matriz
                  </span>
                )}

              {companySelected &&
                companySelected.itens &&
                companySelected.itens.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "95%",
                      marginTop: 20,
                    }}
                  >
                    <table
                      className="table-scope"
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <thead>
                        <tr
                          style={{
                            borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)",
                          }}
                        >
                          <th
                            className="head-tr-item"
                            style={{
                              width: "50%",
                              textAlign: "left",
                              paddingLeft: 10,
                            }}
                          >
                            {"Filial"}
                          </th>
                          <th
                            className="head-tr-item"
                            style={{
                              width: "50%",
                              textAlign: "left",
                              paddingLeft: 10,
                            }}
                          >
                            {"CNPJ"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {companySelected.itens.map(
                          (item: any, index: number) => {
                            return (
                              <React.Fragment key={index}>
                                <tr>
                                  <td style={{ padding: "10px", width: "20%" }}>
                                    <span className="title-text-underline">
                                      {item.corporate_name}
                                    </span>
                                  </td>
                                  <td style={{ padding: "10px", width: "30%" }}>
                                    <span>{transformCNPJ(item.cnpj)}</span>
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
            </div>

            <div style={{ display: "right", flexDirection: "row" }}>
              <div
                style={{ position: "absolute", top: "1%", marginLeft: "60%" }}
              >
                <div
                  style={{
                    borderStyle: "dashed",
                    borderWidth: "1px",
                    borderColor: "#9E9E9E",
                    height: 240,
                    display: "flex",
                    flexDirection: "column",
                    width: 220,
                    verticalAlign: "middle",
                    textAlign: "center",
                  }}
                >
                  {imageLogo && (
                    <img
                      src={imageLogo}
                      alt="logo"
                      style={{
                        margin: "auto",
                        display: "block",
                        maxHeight: "100%",
                        maxWidth: "100%",
                      }}
                    />
                  )}
                </div>

                {onlyView === false && (
                  <button
                    type="button"
                    style={{
                      width: "222px",
                      height: "35px",
                      background: "#ffffff",
                      color: "#31363F",
                      borderColor: "#9E9E9E",
                      borderRadius: "2.5px",
                      cursor: "pointer",
                      borderStyle: "dashed",
                      marginTop: 10,
                      fontWeight: "bold",
                      borderWidth: "1px",
                    }}
                    onClick={() =>
                      (
                        document.querySelector("#logoInput") as HTMLInputElement
                      ).click()
                    }
                  >
                    {`Selecionar logo da empresa`}
                  </button>
                )}

                <input
                  type="file"
                  id="logoInput"
                  accept="image/png,image/gif,image/jpeg"
                  style={{ display: "none" }}
                  name="file"
                  onChange={logoChangeHandler}
                />
              </div>

              <div
                style={{ position: "absolute", top: "1%", marginLeft: "80%" }}
              >
                <div
                  style={{
                    borderStyle: "dashed",
                    borderWidth: "1px",
                    borderColor: "#9E9E9E",
                    height: 240,
                    display: "flex",
                    flexDirection: "column",
                    width: 220,
                    verticalAlign: "middle",
                    textAlign: "center",
                  }}
                >
                  {imageDirector && (
                    <img
                      src={imageDirector}
                      alt="diretor"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </div>

                {onlyView === false && (
                  <button
                    type="button"
                    style={{
                      width: "222px",
                      height: "35px",
                      background: "#ffffff",
                      color: "#31363F",
                      borderColor: "#9E9E9E",
                      borderRadius: "2.5px",
                      cursor: "pointer",
                      borderStyle: "dashed",
                      marginTop: 10,
                      fontWeight: "bold",
                      borderWidth: "1px",
                    }}
                    onClick={() =>
                      (
                        document.querySelector(
                          "#DirectorInput"
                        ) as HTMLInputElement
                      ).click()
                    }
                  >
                    {`Selecionar imagem do diretor`}
                  </button>
                )}
                <input
                  type="file"
                  id="DirectorInput"
                  accept="image/png,image/gif,image/jpeg"
                  style={{ display: "none" }}
                  name="file"
                  onChange={ImageDirectorChangeHandler}
                />
              </div>

              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  width: "38%",
                  marginLeft: "60%",
                }}
              >
                <div
                  style={{
                    borderStyle: "dashed",
                    borderWidth: "1px",
                    borderColor: "#9E9E9E",
                    height: 230,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    verticalAlign: "middle",
                    textAlign: "center",
                  }}
                >
                  {imageCover && (
                    <img
                      src={imageCover}
                      alt="capa"
                      style={{
                        margin: "auto",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </div>

                <button
                  type="button"
                  style={{
                    width: "40%",
                    height: "35px",
                    background: "#ffffff",
                    color: "#31363F",
                    borderColor: "#9E9E9E",
                    borderRadius: "2.5px",
                    cursor: "pointer",
                    borderStyle: "dashed",
                    marginTop: 10,
                    fontWeight: "bold",
                    borderWidth: "1px",
                    marginRight: "20%",
                  }}
                  onClick={() =>
                    (
                      document.querySelector("#CoverInput") as HTMLInputElement
                    ).click()
                  }
                >
                  {`Inserir imagem`}
                </button>

                <button
                  type="button"
                  style={{
                    width: "40%",
                    height: "35px",
                    background: "#ffffff",
                    color: "#31363F",
                    borderColor: "#9E9E9E",
                    borderRadius: "2.5px",
                    cursor: "pointer",
                    borderStyle: "dashed",
                    marginTop: 10,
                    fontWeight: "bold",
                    borderWidth: "1px",
                  }}
                  onClick={() => ImageCoverChangeHandler(null, true)}
                >
                  {`Usar imagem padrão`}
                </button>

                <input
                  type="file"
                  id="CoverInput"
                  accept="image/png,image/gif,image/jpeg"
                  style={{ display: "none" }}
                  name="file"
                  onChange={ImageCoverChangeHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div className="column">
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>
      {loadingModal && <Loading isOpen={loadingModal} />}
    </Container>
  );
};
