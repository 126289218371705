import { Page, Text, View, StyleSheet, Image as ImagePDF } from "@react-pdf/renderer";
import React from "react";
import { Header } from "../../components/Header";
import { IGEEProps } from "../../../interfaces/IGEEData";
import { styles, table } from "../../styles";
import { BarChart, sum } from "./chart";
import { chartData } from "../../../../../../MaccCurve/utils/chartData";
import LogoVerdeEscuro from "../../../../../../../assets/images/versoes-logo-icone-ingee-03.png";
import { BarChartTwo } from "./chartTwo";
import { BarChartThree } from "./chatThree";

export const Page12: React.FC<IGEEProps> = (props) => {
  const { data } = props;
  const { resume } = props;

  const locationData = [
    {
      name: ["Aquisição", "de energia", "elétrica"],
      value: sum(
        resume?.resumeIgee?.emissions_by_category?.scope2?.location.electricity
      ),
    },
    {
      name: ["Perdas de", "transmissão", "e distribuição"],
      value: sum(
        resume?.resumeIgee?.emissions_by_category?.scope2?.location
          .transmission_distribution_losses
      ),
    },
  ];

  const choiceData = [
    {
      name: ["Aquisição", "de energia", "elétrica"],
      value: sum(
        resume?.resumeIgee?.emissions_by_category?.scope2.purchase_choice?.electricity
      ),
    },
    {
      name: ["Perdas de", "transmissão", "e distribuição"],
      value: sum(
        resume?.resumeIgee?.emissions_by_category?.scope2.purchase_choice?.transmission_distribution_losses
      ),
    },
  ];

  const thermalEnergyData = [
    {
      name: ["Aquisição", "de energia", "térmica"],
      value: sum(
        resume?.resumeIgee?.emissions_by_category?.scope2?.purchase_choice?.thermal_energy
      ),
    },
  ];

  const Table = () => {
    return (
      <View style={{ ...local.table, width: "500px", marginTop: 10, height: "145px", marginLeft: -10 }}>
        <View style={table.header}>
          <View style={{ ...table.columns, height: "50px" }}>
            <View style={{ ...table.scope2, width: "33%" }}>
              <Text
                style={{
                  fontSize: 11,
                  ...styles.Bold,
                  alignSelf: "flex-start",
                  color: "#326ed6",
                  marginLeft: 1,
                  lineHeight: 1
                }}
              >
                <Text style={{ fontSize: 8 }}>Total de emissões {"\n"}</Text>
                Escopo 2
              </Text>
            </View>
            {locationData.map((d, i) => (
              <View style={{ ...table.scope2, width: "25%" }} key={i}>
                <Text
                  style={{
                    borderRadius: "80%",
                    color: "white",
                    fontSize: 10,
                    alignSelf: "center",
                    textAlign: "center",
                    height: "18px",
                    width: "50px",
                    backgroundColor: "#326ed6",
                    ...styles.light,
                    marginLeft: -30,
                    marginTop: 5
                  }}
                >
                  {d.value.toFixed(2).replace('.', ',')}
                </Text>
              </View>
            ))}
          </View>
        </View>
        <View style={{...table.body2, marginLeft: 5}}>
          <View style={{ ...table.row, backgroundColor: "#cfd5d9" }}>
            <Text
              style={{
                color: "#64766d",
                fontSize: 9,
                alignSelf: "center",
                ...styles.Bold,
                width: "20%",
                textAlign: "left",
                lineHeight: 1,
                marginBottom: 8,
                marginLeft: -10,
              }}
            >
              Emissões {"\n"} tCO<Text style={{ fontSize: 5 }}>2</Text>e
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "25%", marginLeft: -5 }}>
              {resume?.resumeIgee?.emissions_by_category?.scope2?.location.electricity?.emission_tco2e.toFixed(
                2
              ).replace('.', ',')}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "25%", marginLeft: -20}}>
              {resume?.resumeIgee?.emissions_by_category?.scope2?.location.transmission_distribution_losses?.emission_tco2e.toFixed(
                2
              ).replace('.', ',')}
            </Text>
          </View>
          <View style={{ ...table.row, backgroundColor: "white" }}>
            <Text
              style={{
                color: "#64766d",
                fontSize: 9,
                alignSelf: "center",
                ...styles.Bold,
                width: "26%",
                textAlign: "left",
                lineHeight: 1,
                marginBottom: 8,
                marginLeft: -10,
              }}
            >
              Emissões de {"\n"} CO<Text style={{ fontSize: 5 }}>2</Text>{" "}
              biogênico
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "25%", marginLeft: -20 }}>
              {resume?.resumeIgee?.emissions_by_category?.scope2?.location.electricity?.emission_co2_bio.toFixed(
                2
              ).replace('.', ',')}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "25%", marginLeft: -20 }}>
              {resume?.resumeIgee?.emissions_by_category?.scope2?.location.transmission_distribution_losses?.emission_co2_bio.toFixed(
                2
              ).replace('.', ',')}
            </Text>
          </View>
          <View style={{ ...table.row, backgroundColor: "#cfd5d9" }}>
            <Text
              style={{
                color: "#64766d",
                fontSize: 9,
                alignSelf: "center",
                ...styles.Bold,
                width: "26%",
                textAlign: "left",
                lineHeight: 1,
                marginBottom: 8,
                marginLeft: -10,
              }}
            >
              Remoções de {"\n"} CO<Text style={{ fontSize: 5 }}>2</Text>{" "}
              biogênico
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "25%", marginLeft: -20 }}>
              {resume?.resumeIgee?.emissions_by_category?.scope2?.location.electricity?.removal_of_bio_co2.toFixed(
                2
              ).replace('.', ',')}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "25%", marginLeft: -20 }}>
              {resume?.resumeIgee?.emissions_by_category?.scope2?.location.transmission_distribution_losses?.removal_of_bio_co2.toFixed(
                2
              ).replace('.', ',')}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const ChoiceTable = () => {
    return (
      <View style={{ ...local.table, width: "350px", marginTop: 10, height: "145px"}}>
        <View style={{...table.header}}>
          <View style={{ ...table.columns, height: "50px" }}>
            {choiceData.map((d, i) => (
              <View style={{ ...table.scope2, width: "25%" }} key={i}>
                <Text
                  style={{
                    borderRadius: "80%",
                    color: "white",
                    fontSize: 10,
                    alignSelf: "center",
                    textAlign: "center",
                    height: "18px",
                    width: "50px",
                    backgroundColor: "#326ed6",
                    ...styles.light,
                    marginTop: 5,
                    marginLeft: -20
                  }}
                >
                  {d.value.toFixed(2).replace('.', ',')}
                </Text>
              </View>
            ))}
          </View>
        </View>
        <View style={{...table.body2, marginLeft: -5}}>
          <View
            style={{ ...table.row, height: "26px", backgroundColor: "#cfd5d9" }}
          >
            <Text style={{ ...table.rowText, ...styles.light, width: "33%" }}>
              {resume?.resumeIgee?.emissions_by_category?.scope2?.purchase_choice.electricity?.emission_tco2e.toFixed(
                2
              ).replace('.', ',')}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "33%" }}>
              {resume?.resumeIgee?.emissions_by_category?.scope2?.purchase_choice.transmission_distribution_losses?.emission_tco2e.toFixed(
                2
              ).replace('.', ',')}
            </Text>
          </View>
          <View
            style={{ ...table.row, height: "26px", backgroundColor: "white" }}
          >
            <Text style={{ ...table.rowText, ...styles.light, width: "33%" }}>
              {resume?.resumeIgee?.emissions_by_category?.scope2?.purchase_choice.electricity?.emission_co2_bio.toFixed(
                2
              ).replace('.', ',')}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "33%" }}>
              {resume?.resumeIgee?.emissions_by_category?.scope2?.purchase_choice.transmission_distribution_losses?.emission_co2_bio.toFixed(
                2
              ).replace('.', ',')}
            </Text>
          </View>
          <View
            style={{ ...table.row, height: "26px", backgroundColor: "#cfd5d9" }}
          >
            <Text style={{ ...table.rowText, ...styles.light, width: "33%" }}>
              {resume?.resumeIgee?.emissions_by_category?.scope2?.purchase_choice.electricity?.removal_of_bio_co2.toFixed(
                2
              ).replace('.', ',')}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "33%" }}>
              {resume?.resumeIgee?.emissions_by_category?.scope2?.purchase_choice.transmission_distribution_losses?.removal_of_bio_co2.toFixed(
                2
              ).replace('.', ',')}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const ThermalEnergyTable = () => {
    return (
      <View style={{ ...local.table, width: "260px", marginTop: 10, height: "150px"}}>
        <View style={{...table.header}}>
          <View style={{ ...table.columns, height: "50px" }}>
            {thermalEnergyData.map((d, i) => (
              <View style={{ ...table.scope2, width: "33%" }} key={i}>
                <Text
                  style={{
                    borderRadius: "80%",
                    color: "white",
                    fontSize: 10,
                    alignSelf: "center",
                    textAlign: "center",
                    height: "18px",
                    width: "50px",
                    backgroundColor: "#326ed6",
                    ...styles.light,
                    marginTop: 5,
                    marginLeft: -30
                  }}
                >
                  {d.value.toFixed(2).replace('.', ',')}
                </Text>
              </View>
            ))}
          </View>
        </View>
        <View style={{...table.body2}}>
          <View
            style={{ ...table.row, height: "26px", backgroundColor: "#cfd5d9" }}
          >
            <Text style={{ ...table.rowText, ...styles.light, width: "33%" }}>
              {resume?.resumeIgee?.emissions_by_category?.scope2?.purchase_choice.thermal_energy?.emission_tco2e.toFixed(
                2
              ).replace('.', ',')}
            </Text>
          </View>
          <View
            style={{ ...table.row, height: "26px", backgroundColor: "white" }}
          >
            <Text style={{ ...table.rowText, ...styles.light, width: "33%" }}>
              {resume?.resumeIgee?.emissions_by_category?.scope2?.purchase_choice.thermal_energy?.emission_co2_bio.toFixed(
                2
              ).replace('.', ',')}
            </Text>
          </View>
          <View
            style={{ ...table.row, height: "26px", backgroundColor: "#cfd5d9" }}
          >
            <Text style={{ ...table.rowText, ...styles.light, width: "33%" }}>
              {resume?.resumeIgee?.emissions_by_category?.scope2?.purchase_choice.thermal_energy?.removal_of_bio_co2.toFixed(
                2
              ).replace('.', ',')}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  return (
    <Page size={[640, 480]} style={styles.Page}>
      <Header backColor="#989c9c" title="6. Resultados" />
      <View style={local.body}>
        <Text style={{ ...local.title, ...styles.italic, marginBottom: 5 }}>
          6.4 - Emissões de Escopo 2 desagregadas por categoria
        </Text>
        <View style={{...local.content, marginTop: 10}}>
          <View style={local.titles}>
            <Text
              style={{
                ...styles.light,
                color: "#326ed6",
                fontSize: 9,
                textAlign: "center",
                marginBottom: 20,
                marginLeft: 10
              }}
            >
              Abordagem baseada na{" "}{"\n"}
              <Text style={{ ...styles.Bold }}>localização</Text>
            </Text>
            <Text
              style={{
                ...styles.light,
                color: "#326ed6",
                fontSize: 9,
                textAlign: "center",
                marginRight: 150
              }}
            >
              Abordagem baseada na{" "}{"\n"}
              <Text style={{ ...styles.Bold }}>escolha de compra</Text>
            </Text>
          </View>
          <View style={local.charts}>
            <View style={{...local.charts2, marginLeft: 15}}>
              <BarChart data={locationData} width={300} height={150} />
            </View>
            <View style={{...local.charts3, marginLeft: -12}}>
              <BarChartTwo data={choiceData} width={300} height={150} />
            </View>
            <View style={{...local.charts4, marginLeft: -8}}>
              <BarChartThree data={thermalEnergyData} width={200} height={150} />
            </View>
          </View>
          <View style={local.tables}>
            <Table />
            <ChoiceTable />
            <ThermalEnergyTable />
          </View>
        </View>
      </View>
      <ImagePDF 
        src={resume?.company_itens.logo_image_url}
        style={{
          width: 50,
          right: '85px',
          position: 'absolute',
          bottom: '14px'
        }}
      />
      <ImagePDF 
        src={LogoVerdeEscuro}
        style={{
          width: 90,
          right: '0px',
          bottom: '14px',
          position: 'absolute'
        }}
      />
    </Page>
  );
};

const local = StyleSheet.create({
  body: {
    display: "flex",
    width: "100%",
    height: "75%",
    paddingVertical: "10px",
    alignItems: "center",
    alignContent: "center",
  },
  chart: {
    width: "270px",
    height: "250px",
    display: "flex",
    alignContent: "flex-end",
    flexDirection: "row",
    margin: "5px",
  },
  titles: {
    display: "flex",
    width: "100%",
    height: "auto",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-around",
  },
  charts: {
    display: "flex",
    width: "100%",
    height: "auto",
    flexDirection: "row",
    alignContent: "flex-end",
    justifyContent: "flex-end",
  },
  charts2: {
    display: "flex",
    width: "95%",
    height: "130%",
    flexDirection: "row",
    alignContent: "flex-end",
    justifyContent: "flex-end",
    marginLeft: 20,
  },
  charts3: {
    display: "flex",
    width: "70%",
    height: "130%",
    flexDirection: "row",
    alignContent: "flex-end",
    justifyContent: "flex-end",
    marginLeft: 20,
  },
  charts4: {
    display: "flex",
    width: "65%",
    height: "130%",
    flexDirection: "row",
    alignContent: "flex-end",
    justifyContent: "flex-end",
    marginRight: 10
  },
  table: {
    width: "100%",
    padding: "5px",
    marginHorizontal: "5px",
  },
  tables: {
    display: "flex",
    flexDirection: "row",
    marginTop: "40px",
  },
  title: {
    color: "#2a70b8",
    fontSize: 16,
    padding: "5px",
    paddingLeft: "20px",
    alignSelf: "flex-start",
    marginLeft: "15px"
  },
  content: {
    padding: "20px",
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
  },
});
