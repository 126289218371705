import React from 'react';
import { StyledInnerContainer } from './styles';

interface InnerContainerProps {
  children?: React.ReactNode;
}

const InnerContainer: React.FC<InnerContainerProps> = ({ children }) => {
  return <StyledInnerContainer>{children}</StyledInnerContainer>;
};

export default InnerContainer;
