import React from 'react'; // 'ReactNode' é importado para tipar corretamente as props
import { View, Text } from '@react-pdf/renderer';
import { styles as tableStyles } from '../../../../../components/PDF/styles/table'
import { styles } from '../../../../../components/PDF/styles/common'
import { colors } from '../../../../../components/PDF/styles/colors'

const FullProjectFieldWithSubs:React.FC<{ value: string;}> = (props) => {
  const { value } = props


  return (

    <View style={{ ...styles.row, margin: '5px 0' }}>
      <Text style={{ ...styles.text, minWidth: 150 }}> 
      tCO
      <Text style={tableStyles.subscript}>₂</Text> 
      e evitada
      </Text>
      <Text
        style={{
          ...styles.text,
          borderBottom: `1px solid ${colors.lightGrey}`,
          width: '100%'
        }}
      >
          {value}
      </Text>
     
    </View>
  )
}

export default FullProjectFieldWithSubs;
