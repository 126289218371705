import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import activityService from "../../../services/activityService";
import { Container } from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import TrashIcon from '../../../assets/icons/trash.svg'
import { toast } from "react-hot-toast";

interface Inputs {
  name: string;
  description: string;
}

export const ActivityEdit = () => {
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sSize, setSSize] = useState(0);
  const [activitySelected, setActivitySelected] = useState<any>({});
  const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [subactivities, setSubactivities] = useState<any>([]);

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.activity_id){
      getActivitysById(item.activity_id)
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const activityItens = subactivities.map((item: any) => {
        return item?.name;
      })

      const newActivity = {
        name: formData.name,
        subactivities: activityItens,
      };

      if (activitySelected?.id) {
        const result = await activityService.updateById(
          activitySelected.id,
          newActivity
        );

        if (result && !result.error) {
         /// history.goBack()

         // reset();
         // setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await activityService.create(newActivity);

        if (result && !result.error) {
         // history.goBack()

        //  reset();
          //setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  async function getActivitysById(id: number) {
    const activity = await activityService.getById(id);

    setValue("name", activity?.name);

    setActivitySelected(activity)

    const list = activity.subactivities.map((item: any) => {
      return {
        name: item
      };
    })

    setSubactivities(list)
    setSSize(list.length)

    setActivitySelected(activity)
  }

  const onAddSubActivity = () => {
    const inputItem = (document.querySelector("#subactivity") as HTMLInputElement)

    const item = inputItem.value

    if (item){
      const itens = subactivities

      itens.push({name: item})

      setSubactivities(itens)
      setSSize(itens.length)
      
      inputItem.value = ""
    }
  }

  async function handleSeleteSubActivity(item: any) {
    const itens = subactivities.filter(function(a:any) { 
      return a.name !== item.name
    })

    setSubactivities(itens)
    setSSize(subactivities.length)
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, justifyContent: "space-between", marginBottom: 10 }} >
          <TitleText level={1} title="Setor econômico" />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 20, marginLeft: 50, marginRight: 50  }} >
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Nome
          </label>

          <input style={{width: '95%'}}
                {...register("name", { required: true })}/>
                      
          {errors.name && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
          
          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Sub-setores
          </label>
          <div style={{ display: "flex", flexDirection: "row" }}>

            <input autoComplete={"off"} style={{ width: "95%"}} id="subactivity"  />

            <Button color="black" size="md" onClick={()=>onAddSubActivity()} type="button"
                style={{ height: "40px", width: "50px",  marginTop: -5, marginLeft: 10 }}>
              +
            </Button>
          </div>

          {subactivities && subactivities.length > 0 && (
            <div style={{ marginTop: 15, paddingBottom: 15, maxHeight: "calc(100vh - 320px)", overflow: "auto" }}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tbody>
                  {subactivities.map((subactivity: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td
                            style={{ padding: "10px" }}
                          >
                            <span className="list-text">
                              {subactivity.name}
                            </span>
                          </td>
                          <td>
                            <button
                              style={{
                                width: "40px",
                                height: "35px",
                                background: "#ffffff",
                                borderColor: "#E33F3F",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderRadius: "2.5px",
                                color: "#E33F3F",
                                cursor: "pointer",
                                fontWeight: "bold", 
                                float: "right"
                              }}
                              type="button"
                              onClick={() => handleSeleteSubActivity(subactivity)}
                            >
                              <img src={TrashIcon} alt="lixeirinha"/>
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </form>
    </Container>
  );
};
