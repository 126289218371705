import styled from "styled-components";

export const Container = styled.div`
    /* max-width: 100%; */
    width: 1500px;
    border-collapse: collapse;

    /* Estilizando a barra de rolagem */
    .custom-scrollbar {
        overflow-y: scroll;
        overflow: hidden;
    }

    .custom-scrollbar:hover {
        overflow: auto;
    }

    .custom-scrollbar::-webkit-scrollbar {
        width: 5px; /* Largura da barra de rolagem */
    }

    .custom-scrollbar::-webkit-scrollbar-track {
        background: #f1f1f1; /* Cor da trilha */
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #d0dae7; /* Cor do polegar */
        border-radius: 10px; /* Borda arredondada */
    }

    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #555; /* Cor do polegar ao passar o mouse */
    }
`;