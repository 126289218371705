import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/styles";
import { GoBack } from "../../components/GoBack";
import { TitleText } from "../../components/TitleText";
import helpService from "../../services/helpService";
import { Container } from "./styles";
import AttachmentIcon from '../../assets/icons/adobe-pdf.svg'
import VideoPlayerIcon from '../../assets/icons/video-player.png'
import { ModalPdf } from "./components/ModalPdf";
import { ModalYoutube } from "./components/ModalYoutube";
import { ModalTicket } from "./components/ModalTicket";

export const Help = () => {

  const [pdf, setPdf] = useState<any>([]);
  const [video, setVideo] = useState<any>([]);
  const [videos, setVideos] = useState<any>([]);
  const [manuais, setManuais] = useState<any>([]);
  const [openModalPdf, setOpenModalPdf] = useState(false);
  const [openModalVideo, setOpenModalVideo] = useState(false);
  const [openModalTicket, setOpenModalTicket] = useState(false);

  useEffect(() => {
    getHelps();
  }, []);

  function handleOpenModalPdf(item?: any) {
    setOpenModalPdf(!openModalPdf);
    setPdf(item);
  }

  function handleOpenModalVideo(item?: any) {
    setOpenModalVideo(!openModalVideo);
    setVideo(item);
  }

  async function getHelps() {
    const result = await helpService.getByParams({ limit: 999999, page: 1 });

    const videos = []
    const manuais = []

    if (result.itens){
      for (const i of result.itens){
          if (i.attachment_type === 1){
            manuais.push(i)
          }else{
            videos.push(i)
          } 
      }
    }

    setVideos(videos)
    setManuais(manuais)
  }

  const youTubeGetID = (url: string) => {
    const [a, , b] = url
      .replace(/(>|<)/gi, "")
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (b !== undefined) {
      return b.split(/[^0-9a-z_-]/i)[0];
    } else {
      return a;
    }
  };

  const thumbVideo = (url: string) => {
    if (url.indexOf('youtube') > -1){
      return `https://i1.ytimg.com/vi/${youTubeGetID( url )}/mqdefault.jpg`
    }

    return VideoPlayerIcon
  };

  function handleNewSuggest() {
    setOpenModalTicket(!openModalTicket);
  }


  return (
    <Container>
      <GoBack />

      <div style={{ display: "flex", flexDirection: "column", marginTop: 15, justifyContent: "space-between" }} >
        <div style={{ display: "flex", flexDirection: "row" }} >
          <div style={{ display: "flex", flexDirection: "column" }} >
            <TitleText level={1} title="Ajuda" />

            <span style={{ textAlign: 'start', fontSize: 16, marginTop: 5, marginBottom: 10, fontWeight: 'normal' }} >
              Abaixo você vai encontrar manuais de ajuda, que podem auxiliar na navegação e uso da plataforma.
            </span>
          </div>

          <Button color="blue" size="md" type="button" onClick={handleNewSuggest}
                style={{ margin: 'auto 10px auto auto' }} >
            Abrir Chamado
          </Button>
        </div>
      
        <div style={{ overflow: 'hidden auto', position: 'relative', marginTop: 0,
            height: 'calc(100vh - 140px)', maxHeight: 'calc(100vh - 140px)' }} >
          {manuais && (
            <div style={{ borderStyle: 'dashed', margin: 5, borderWidth: '1px', width: 'calc(100vw - 330px)',
                    maxWidth: 'calc(100vw - 330px)', borderColor: '#9E9E9E', height: 'auto', display: 'flex',
                    flexDirection: 'column', padding: 10, marginBottom: 15}} >
  
              <TitleText level={3} title="Manuais de utilização" />
  
              <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5, fontWeight: 'normal' }} >
                Clique no manual desejado para visualização das instruções.
              </span>
  
              <div style={{ display: "grid", gridTemplateColumns: "25% 25% 25% 25%", overflow: "auto", paddingBottom: 15 }} >
                
                {manuais.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <div style={{ borderStyle: "solid", padding: 20, borderWidth: "1px", 
                                    borderColor: "#9E9E9E", width: "200px", height: "auto", minWidth: "200px", 
                                    textAlign: "center", cursor: "pointer", marginTop: 20, marginRight: 20}} 
                                    onClick={() => handleOpenModalPdf(item)}>
                        <img src={AttachmentIcon} alt="coisa" />
          
                        <br/>
          
                        <span style={{padding: 5, fontSize: 14, fontWeight: "bold"}}>
                          {item.name}
                        </span>

                        <br/>
          
                        {item.description && (
                          <span style={{padding: 5, fontSize: 12}}>
                            {item.description}
                          </span>
                        )}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          )}
        
          {videos && (
            <div style={{ borderStyle: 'dashed', margin: 5, borderWidth: '1px', width: 'calc(100vw - 330px)',
                    maxWidth: 'calc(100vw - 330px)', borderColor: '#9E9E9E', height: 'auto', display: 'flex',
                    flexDirection: 'column', padding: 10}} >
  
              <TitleText level={3} title="Vídeos de Instrução" />
  
              <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5, fontWeight: 'normal' }} >
                Clique no vídeo desejado para visualização das instruções.
              </span>
  
              <div style={{ display: "grid", gridTemplateColumns: "25% 25% 25% 25%", overflow: "auto", paddingBottom: 15 }} >
                
                {videos.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <div style={{ borderStyle: "solid", padding: 20, borderWidth: "1px", 
                                    borderColor: "#9E9E9E", width: "200px", height: "auto", minWidth: "200px", 
                                    textAlign: "center", cursor: "pointer", marginTop: 20, marginRight: 20}} 
                                    onClick={() => handleOpenModalVideo(item)}>
                        <img alt="tumb"
                          style={{ objectFit: "cover" }}
                          src={thumbVideo(item.video_url)}
                          width="100%"
                        />
          
                        <br/>
          
                        <span style={{padding: 5, fontSize: 14, fontWeight: "bold"}}>
                          {item.name}
                        </span>

                        <br/>
          
                        {item.description && (
                          <span style={{padding: 5, fontSize: 12}}>
                            {item.description}
                          </span>
                        )}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      {pdf && openModalPdf && (
        <ModalPdf
          pdf={pdf}
          isOpen={openModalPdf}
          handleModalVisible={handleOpenModalPdf}
        />
      )}

      {video && openModalVideo && (
        <ModalYoutube
          video={video}
          isOpen={openModalVideo}
          handleModalVisible={handleOpenModalVideo}
        />
      )}

      {openModalTicket && (
        <ModalTicket 
            isOpen={openModalTicket}
            handleModalVisible={handleNewSuggest} />
      )}

    </Container>
  );
};
