import React, { useState } from "react";
import { Modal } from "../Modal";
import { TitleText } from "../TitleText";
import AttachmentIcon from '../../assets/icons/attachment.svg'

import { Container } from "./styles"

type ModalProps = {
    isOpen: boolean;
    handleModalVisible: () => void;
    handleImportData: (data: any[], option_table: string) => void;
    title: string;
    templateFileName: string;
    option_table: string;
    children?: React.ReactNode;
    isPayOrTypeClient?: boolean;
    observation?: string;
};


const ModalHandleImportData = ({isOpen, handleModalVisible, handleImportData, title, templateFileName, option_table, observation, ...props}: ModalProps) => {
    const [file, setFile] = useState<any>([]);

    const handleDownloadFile = () => {
        const url = `${process.env.REACT_APP_API_URL}`

        window.open(url + "/static/template/" + templateFileName, '_blank')
    };

    const handleUploadFile = (event:any) => {
        const file_up = event.target.files[0];
        setFile(file_up);
        handleImportData(file_up, option_table)
    };

    return (
        <Modal
            isOpen={isOpen}
            isPayOrTypeClient={true}
            handleModalVisible={handleModalVisible}
        >
            <Container>
                <div style={{ margin: 5, textAlign: "left" }}>
                    <TitleText level={2} title={title ? title : ""} color="blue" />
                </div>

                <div style={{ margin: 5, textAlign: "left"  }}>
                    <TitleText level={3} title="Importação de dados" color="black" />

                    <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }} >
                        Faça download do template, preencha os dados desejados e clique em 'Importar Dados'.
                    </span>
                    {observation && (
                        <div>
                            <br />
                            <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, fontStyle: 'italic' }} >
                                <strong>Atenção:</strong> {observation}
                            </span>
                        </div>
                    )}
                </div>

                <div style={{ margin: 10, textAlign: "center", cursor: "pointer" }} onClick={() => handleDownloadFile() }>
                    <span style={{ textAlign: "start", fontSize: 14, marginTop: 5, textDecoration: "underline" }} >
                        Download template para importação.
                    </span>
                </div>


                <div style={{ borderStyle: "dashed", marginTop: 20, borderWidth: "1px", marginLeft: 10,
                            paddingTop: 15, paddingBottom: 15, borderColor: "#9E9E9E", marginRight: 10,
                            width: "95%", textAlign: "center", cursor: "pointer"}} 
                            onClick={() => (document.querySelector("#fileInput") as HTMLInputElement).click()}>
                    <img src={AttachmentIcon} alt="coisa" width={32}/>

                    <br/>

                    <span style={{padding: 5, fontSize: 14, fontStyle: "bold"}}>
                        Selecione o template e iniciaremos a importação desejada.
                    </span>
                </div>

                <input type="file" id="fileInput" 
                        accept="application/xlsx,application/xls" 
                        style={{ display: "none"}} name="file" 
                                onChange={handleUploadFile} />

            </Container>
        </Modal>
    )
};

export default ModalHandleImportData;