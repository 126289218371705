import { Page, Text, View, Image as ImagePDF } from "@react-pdf/renderer";
import React from "react";
import { Logos } from "../../components/Logos";
import { Header } from "../../components/Header";
import { ReportData } from "../../../interfaces/ReportData";
import { styles } from "../../styles";
import { IGEEProps } from "../../../interfaces/IGEEData";
import LogoVerdeEscuro from "../../../../../../../assets/images/versoes-logo-icone-ingee-03.png";

const formatSubscript = (text: string) => {
  const parts = text.split(/(CO2)/g);
  return parts.map((part, index) =>
    part === "CO2" ? (
      <Text key={index}>
        CO<Text style={{ fontSize: 5 }}>2</Text>
      </Text>
    ) : (
      part
    )
  );
};

// Função para encontrar o ponto de quebra sem dividir palavras
const findBreakPoint = (text: any, maxLength: number) => {
  let breakPoint = text.lastIndexOf(' ', maxLength);
  if (breakPoint === -1) breakPoint = maxLength;
  return breakPoint;
};

// Componente para exibir a pergunta e a resposta longa dividida em páginas
const LongAnswerPage: React.FC<{ label: string; answer: string; logoUrl: any; pageNumber?: number }> = ({ label, answer, logoUrl }) => (
  <Page size={[640, 480]} style={{ ...styles.Page, backgroundColor: "#ecf3f4" }}>
    <Header backColor="#81c787" title={`10. Outros elementos`} />
    <View style={{ padding: "15px", height: "90%", textAlign: "justify" }}>
      <Text style={{ color: "#64766d", fontSize: 10, ...styles.Bold, marginBottom: 10, marginLeft: "20px", marginRight: "15px", marginTop: 20 }}>
        {formatSubscript(label)}
      </Text>
      <Text style={{ color: "#64766d", fontSize: 8, ...styles.light, marginLeft: "20px", textAlign: "justify",marginRight: "15px", lineHeight: 1.8 }}>
        {formatSubscript(answer)}
      </Text>
      <ImagePDF
        src={logoUrl}
        style={{
          width: 50,
          position: "absolute",
          bottom: "10px",
          right: "115px",
        }}
      />
      <ImagePDF
        src={LogoVerdeEscuro}
        style={{
          width: 90,
          position: "absolute",
          bottom: "10px",
          right: "25px",
        }}
      />
    </View>
  </Page>
);

export const Page18: React.FC<IGEEProps> = (props) => {
  const { resume } = props;

  const inventoryElements = resume?.inventory_elements.inventory_elements || [];
  const inventoryElementsSize = inventoryElements.length;

  const halfSize = Math.ceil(inventoryElementsSize / 2);

  const leftInfo = inventoryElements.slice(0, halfSize);
  const rightInfo = inventoryElements.slice(halfSize);

  return (
    <>
    <Page
      size={[640, 480]}
      style={{ ...styles.Page, backgroundColor: "#ecf3f4" }}
    >
      <Header backColor="#81c787" title="10. Outros elementos" />
      <View style={{ ...styles.body }}>
        <View
          style={{
            padding: "15px",
            height: "105%",
            width: "50%",
            borderRight: "1px solid #81c787",
          }}
        >
          <View
            style={{
              position: "absolute",
              top: "100%",
              width: 20,
              height: 20,
              right: "13.5%",
              borderRadius: "50%",
              backgroundColor: "#81c787",
              transform: "translate(50%, -50%)",
            }}
          />
          <View style={{ height: "100%" }}>
            {leftInfo.map((info, idx) => (
              info.awnser.length <= 600 && (
              <View
                style={{
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  width: "100%",
                  marginLeft: 12,
                  textAlign: "justify",
                }}
                key={idx}
              >
                <Text
                  style={{
                    color: "#64766d",
                    fontSize: 10,
                    ...styles.Bold,
                    textAlign: "justify",
                    marginRight: 4,
                  }}
                >
                  {formatSubscript(info.label)}
                </Text>
                <Text
                  style={{
                    color: "#64766d",
                    fontSize: 8,
                    ...styles.light,
                    marginRight: 4,
                  }}
                >
                  {formatSubscript(info.awnser)}
                </Text>
              </View>
              )
            ))}
          </View>
        </View>
        <View
          style={{
            padding: "15px",
            width: "50%",
            height: "100%",
          }}
        >
          <View style={{ height: "100%" }}>
            {rightInfo.map((info, idx) => (
              info.awnser.length <= 600 && (
              <View
                style={{
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  width: "100%",
                  marginLeft: 5,
                  marginRight: 2,
                  textAlign: "justify",
                }}
                key={idx}
              >
                <Text
                  style={{
                    color: "#64766d",
                    fontSize: 10,
                    ...styles.Bold,
                    textAlign: "justify",
                    marginRight: 4,
                  }}
                >
                  {formatSubscript(info.label)}
                </Text>
                <Text
                  style={{
                    color: "#64766d",
                    fontSize: 8,
                    ...styles.light,
                    marginRight: 4,
                  }}
                >
                  {formatSubscript(info.awnser)}
                </Text>
              </View>
              )
            ))}
            <View style={{ position: 'absolute', right: 10, bottom: 0, flexDirection: 'row', alignItems: 'center' }}>
              <ImagePDF
                src={resume?.company_itens.logo_image_url}
                style={{
                  width: 70,
                  height: 'auto',
                  left: '10px',
                }}
              />
              <ImagePDF
                src={LogoVerdeEscuro}
                style={{
                  width: 100,
                  height: 'auto',
                }}
              />
            </View>
          </View>
        </View>
      </View>
    </Page>
    {/* Verifica o tamanho das respostas e renderiza novas páginas para respostas longas */}
    {inventoryElements.map((info, idx) => {
      if (info.awnser.length > 1768) {
        // Encontrar pontos de quebra para não dividir palavras
        const breakPoint1 = findBreakPoint(info.awnser, 1768);
        const firstPart = info.awnser.slice(0, breakPoint1);
        const secondPart = info.awnser.slice(breakPoint1).trim();

        return (
          <React.Fragment key={`long-${idx}`}>
            <LongAnswerPage label={info.label} answer={firstPart} logoUrl={resume?.company_itens.logo_image_url} pageNumber={1} />
            <LongAnswerPage label={info.label} answer={secondPart} logoUrl={resume?.company_itens.logo_image_url} pageNumber={2} />
          </React.Fragment>
        );
      } else if (info.awnser.length > 600) {
        // Exibir em uma única página se for maior que 600 e menor ou igual a 1768 caracteres
        return (
          <LongAnswerPage key={`long-${idx}`} label={info.label} answer={info.awnser} logoUrl={resume?.company_itens.logo_image_url} />
        );
      } else {
        return null;
      }
    })}
  </>
  );
};
