import {
  Page,
  Text,
  View,
  StyleSheet,
  Image as ImagePDF,
} from "@react-pdf/renderer";
import React from "react";
import { Header } from "../../components/Header";
import { styles } from "../../styles";
import { IGEEProps } from "../../../interfaces/IGEEData";
import LogoVerdeEscuro from "../../../../../../../assets/images/versoes-logo-icone-ingee-03.png";

export const Page16: React.FC<IGEEProps> = (props) => {
  const { maccCurveBase64 } = props;
  const { resume } = props;

  return (
    <Page
      size={[640, 480]}
      style={{ ...styles.Page, backgroundColor: "#ecf3f4" }}
    >
      <Header backColor="#76B987" title="8. Descarbonização" />
      <View style={local.body}>
        <Text style={{ ...local.title, ...styles.Bold, marginLeft: "10px" }}>
          Gráfico de curva MACC
        </Text>
        <View style={local.section}>
          <View style={{ width: "100%", marginTop: 50, marginLeft: -10}}>
            {maccCurveBase64 && <ImagePDF src={maccCurveBase64} 
            style={{
              height: "100%", 
              width: "100%", 
              objectFit: "contain",
              backgroundColor: "#ecf3f4"
              }}/>}
          </View>
          {/* <View style={local.brackets}>
            <Text style={{ ...styles.extraLight, ...local.bracket }}>
              {"]"}
            </Text>
            <Text style={{ ...styles.extraLight, ...local.bracket, marginTop: -40 }}>
              {"]"}
            </Text>
          </View> */}
          {/* <View style={local.texts}>
            <Text
              style={{ ...local.text, color: "#4081c6", ...styles.Bold }}
            >
              Opção MENOS custo efetiva {"\n"} (custo a ser implementado)
            </Text>
            <Text
              style={{ ...local.text, color: "#a1a4a6", ...styles.Bold }}
            >
              Emissões cumulativas abatidas em {"\n"} comparação ao cenário BAU
              (tCO<Text style={{ fontSize: 8 }}>2</Text>e)
            </Text>
            <Text
              style={{ ...local.text, color: "#4081c6", ...styles.Bold }}
            >
              Opção MAIS custo efetiva {"\n"} (pagam por si próprios)
            </Text>
          </View> */}
        </View>
        <ImagePDF 
              src={resume?.company_itens.logo_image_url}
                style={{
                  width: 50,
                  right: '85px',
                  position: 'absolute',
                  bottom: '14px'
                }}
            />
            <ImagePDF src={LogoVerdeEscuro} 
              style={{
                width: 90,
                right: '0px',
                bottom: '14px',
                position: 'absolute'
              }}
            />
      </View>
    </Page>
  );
};

const local = StyleSheet.create({
  body: {
    display: "flex",
    width: "100%",
    height: "90%",
    alignItems: "center",
    alignContent: "center",
    padding: "20px",
  },
  title: {
    color: "#a1a4a6",
    fontSize: 18,
    padding: "5px",
    alignSelf: "flex-start",
  },
  section: {
    width: "96%",
    height: "80%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  // maccCurve: {
  //   width: "65%",
  //   height: "80%",
  //   marginLeft: -5
  // },
  brackets: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "20px",
    width: "8%",
    marginLeft: "-5px",
  },
  bracket: {
    fontSize: 80,
    color: "#a1a4a6",
    marginLeft: "30px",
  },
  texts: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "auto",
    height: "50%",
    marginLeft: 30
  },
  text: {
    fontSize: 9,
  },
});
