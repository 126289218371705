import React from "react";
import { G, Path, Svg, Text, Rect } from "@react-pdf/renderer";
import * as d3 from "d3";

export const DonutChart = ({ data }) => {
  const styles = {
    light: {
      fontFamily: "MuseoModerno",
    },
  };

  // Configurações do gráfico
  const width = 250;
  const height = 150;
  const radius = Math.min(width, height) / 2;

  // Cores para os setores
  const customColors = ["#b47d58", "#2a71b8", "#76b987", "#b3b3b3"];
  const color = d3.scaleOrdinal(customColors);

  // Criação do gráfico de rosca
  const pie = d3.pie().value((d) => d.value);
  const arc = d3
    .arc()
    .innerRadius(radius * 0.6)
    .outerRadius(radius);

  const legendBoxSize = 10; // Tamanho da caixa da legenda
  const legendSpacing = 2; // Espaçamento entre as caixas da legenda e os textos

  const chartHeight = height;
  const legendHeight = legendBoxSize + legendSpacing;
  const totalHeight = chartHeight + legendHeight + 20;

  const legendXSpacing = 45; // Espaçamento entre legendas

  return (
    <Svg width={width + 90} height={totalHeight}>
      {/* Gráfico principal */}
      <G transform={`translate(${width / 2}, ${height / 2})`}>
        {pie(data).map((d, i) => {
          const centroid = arc.centroid(d);
          const labelX = centroid[0];
          const labelY = centroid[1];
          const percentage =
            ((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100;

          return (
            <G key={i}>
              <Path d={arc(d)} fill={color(i.toString())} />
              {percentage >= 2 && (
                <Text
                  x={labelX}
                  y={labelY}
                  fill="white"
                  fontSize={9}
                  textAnchor="middle"
                  style={styles.light}
                >
                  {`${percentage.toFixed(2).replace('.', ',')}%`}
                </Text>
              )}
            </G>
          );
        })}
      </G>

      {/* Legenda abaixo do gráfico */}
      <G transform={`translate(${(width - data.length * (legendBoxSize + legendSpacing + legendXSpacing)) / 2}, ${chartHeight + 20})`}>
        {data.map((d, i) => {
          const legendX = i * (legendBoxSize + legendSpacing + legendXSpacing);

          return (
            <G key={i} transform={`translate(${legendX}, 0)`}>
              <Rect
                width={legendBoxSize}
                height={legendBoxSize}
                fill={color(i.toString())}
              />
              <Text
                x={legendBoxSize + legendSpacing}
                y={legendBoxSize - 2}
                fontSize={9}
                style={styles.light}
                fill="#6c8a6e"
              >
                {d.label}
              </Text>
            </G>
          );
        })}
      </G>
    </Svg>
  );
};
