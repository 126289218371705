import React, { useEffect } from "react";
import { Container } from "./styles";
import InfoButtonMin from "../../../assets/icons/info-sign.png";
import { useForm } from "react-hook-form";

type FirstStepProps ={
  treatmentType: boolean | null;
  releasedEffluent: boolean | null;
  treatmentFinalTypes: any[];
  setTreatmentType: (value: boolean) => void;
  setReleasedEffluent: (value: boolean) => void;
  handleChangeTreatmentFinal: (id: any) => void;
  register: (value: string) => any;
  setValue: (value: string, e: any) => any;
  handleChange: () => void;
}

export const FirstStep: React.FC<FirstStepProps> = ({
  treatmentType, 
  releasedEffluent, 
  treatmentFinalTypes, 
  setReleasedEffluent,
  setTreatmentType,
  handleChangeTreatmentFinal,
  handleChange,
  register, 
  setValue
}) => {


  return (
    <Container>
      <div className="body">
        <div className="div-text">
          <span className="text-statement">
            Passo 1 - Dados de tratamento e disposição final do efluente doméstico
          </span>
          <div className="tooltip" style={{ margin: 5 }}>
            <img src={InfoButtonMin} alt="information" style={{ width: 25 }} />
            <span className="tooltiptext">
              Indique no Passo 1, a existência ou não de tratamentos aplicados ao efluente doméstico e a sua disposição final.
            </span>
          </div>
        </div>

        <div className="question">
          <div className="div-text">
            <span className="prompt-text">
              O efluente doméstico passa por algum tipo de tratamento?
            </span>
          </div>

          <div className="choice-one">
            <input
              type="radio"
              value="1"
              style={{ width: 20, marginLeft: 0, marginRight: 10 }}
              {...register("treatment_type")}
              checked={treatmentType === true}
              onChange={() => {
                setValue("treatment_type", true);
                setTreatmentType(true)
                handleChange()
              }}
            />
            <span>Sim</span>

            <input
              type="radio"
              value="0"
              style={{ width: 20, marginRight: 10 }}
              {...register("treatment_type")}
              checked={treatmentType === false}
              onChange={() => {
                setValue("treatment_type", false);
                setTreatmentType(false)
                handleChange()
              }}
            />
            <span>Não</span>
          </div>
        </div>

        <div className="question">
          <div className="div-text">
            <span className="prompt-text">
              {treatmentType === null
                ? "O efluente doméstico é lançado ao meio ambiente?"
                : treatmentType
                ? "O efluente doméstico tratado é lançado ao meio ambiente?"
                : "O efluente doméstico sem tratamento é lançado ao meio ambiente?"}
            </span>
          </div>

          <div className="choice-one">
            <input
              type="radio"
              value="1"
              style={{ width: 20, marginLeft: 0, marginRight: 10 }}
              {...register("released_effluent")}
              checked={releasedEffluent === true} 
              onChange={() => {
                setValue("released_effluent", true); 
                setReleasedEffluent(true)
                handleChange(); 
              }}
            />
            <span>Sim</span>

            <input
              type="radio"
              value="0"
              style={{ width: 20, marginRight: 10 }}
              {...register("released_effluent")}
              checked={releasedEffluent === false} 
              onChange={() => {
                setValue("released_effluent", false);
                setReleasedEffluent(false)
                handleChange();
              }}
            />
            <span>Não</span>
          </div>
        </div>

        {releasedEffluent && (
          <div className="question">
            <div className="sub-div-text">
              <span className="prompt-text">
                {treatmentType === null
                  ? "Caso o efluente seja lançado ao ambiente, qual a sua disposição final?"
                  : treatmentType
                  ? "Caso o efluente tratado seja lançado ao ambiente, qual a sua disposição final?"
                  : "Caso o efluente sem tratamento seja lançado ao ambiente, qual a sua disposição final?"}
              </span>
            </div>

            {treatmentFinalTypes && (
              <select
                style={{ borderRadius: 5, width: "325px", height: 30 }}
                {...register("treatment_final_id")}
                onChange={(event) => handleChangeTreatmentFinal(event.target.value)}
              >
                {treatmentFinalTypes.map((type: any) => (
                  <option value={type.id} key={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            )}
          </div>
        )}

      </div>
    </Container>
  );
};
