import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/styles";
import { GoBack } from "../../components/GoBack";
import { ModalDelete } from "../../components/ModalDelete";
import { Pagination } from "../../components/Pagination";
import { TitleText } from "../../components/TitleText";
import useAuth from "../../hooks/useAuth";
import useDebounce from "../../hooks/useDebounce";
import companyService from "../../services/companyService";
import { Container } from "./styles";
import { useHistory } from "react-router-dom";
import { transformCNPJ, transformCPF } from "../../utils/transform";
import { toast } from "react-hot-toast";

export const Company = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [companys, setCompanys] = useState<any>([]);
  const [companySelected, setCompanySelected] = useState<any>({});

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const searchDebounced = useDebounce(searchValue, 800);
  // const [onlyView, setOnlyView] = useState(false);

  const [onlyView, setOnlyView] = useState(user.profile !== 'sinergia' && user.profile !== 'admin' && user.profile !== 'analyst');
  
  const [currentPage, setCurrentPage] = useState(1);


  async function getCompanys(page: number, filter: string) {
    const result = await companyService.getByParams({
      limit: 25,
      page,
      filter: filter,
    });
    setCompanys(result);

    setCurrentPage(page);
  }

  useEffect(() => {
    getCompanys(1, searchDebounced);

    setOnlyView(
      user.profile !== "sinergia" &&
        user.profile !== "admin" &&
        user.profile !== "analyst"
    );
  }, [searchDebounced, user.profile]);

  useEffect(() => {
    getCompanys(1, searchDebounced);
  }, [searchDebounced]);

  async function handleDeleteCompany() {
    if (companySelected) {
      const result = await companyService.deleteById(companySelected?.id);

      if (result) {
        setOpenModalDelete(false);
        getCompanys(1, searchDebounced);

        toast.success("Operação realizada com sucesso!");
      }
    }
  }

  async function getCompanysById(id: number) {
    const company = await companyService.getById(id);

    setCompanySelected(company);
  }

  function redirectToEdit(company: any) {
    history.push({
      pathname: "/empresa/edicao",
      state: { company_id: company.id },
    });
  }

  function redirectToInfo(company: any) {
    history.push({
      pathname: "/empresa/info",
      state: { company_id: company.id },
    });
  }

  function redirectToScope(company: any) {
    history.push({
      pathname: "/empresa/escopo",
      state: { company_id: company.id },
    });
  }

  function redirectToInclude() {
    history.push("/empresa/inclusao");
  }

  function handleOpenModalDelete(company?: any) {
    setOpenModalDelete(!openModalDelete);

    if (company) {
      getCompanysById(company.id);
    } else {
      setCompanySelected({});
    }
  }

  const goPrev = () => {
    setCurrentPage(currentPage - 1);
    getCompanys(currentPage - 1, searchDebounced);
  };

  const goNext = () => {
    setCurrentPage(currentPage + 1);
    getCompanys(currentPage + 1, searchDebounced);
  };



  return (
    <Container>
      <GoBack />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 15,
          justifyContent: "space-between",
        }}
      >
        <TitleText level={1} title="Empresas" />

        <input
          className="input-search"
          placeholder="Pesquisar empresas"
          onChange={(e) => setSearchValue(e.target.value)}
        />

        {user.profile === "sinergia" && (
          <Button color="blue" size="md" onClick={() => redirectToInclude()}>
            + Nova empresa
          </Button>
        )}

        {user.profile !== "sinergia" && <div style={{ width: 130 }} />}
      </div>

      {companys?.itens && (
        <div
          style={{
            overflow: "hidden auto",
            position: "relative",
            marginTop: 10,
            height: "calc(100vh - 130px)",
            maxHeight: "calc(100vh - 130px)",
          }}
        >
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr
                style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}
              >
                <th
                  style={{
                    textAlign: "start",
                    padding: "5px",
                    color: "#7A7D83",
                    fontWeight: "700",
                  }}
                >
                  {"Empresa"}
                </th>
                <th
                  style={{
                    textAlign: "start",
                    padding: "5px",
                    color: "#7A7D83",
                    fontWeight: "700",
                  }}
                >
                  {"Responsável"}
                </th>
                <th
                  style={{
                    textAlign: "start",
                    padding: "5px",
                    color: "#7A7D83",
                    fontWeight: "700",
                  }}
                >
                  {"Anos Liberados"}
                </th>
              </tr>
            </thead>
            <tbody>
              {companys.itens.map((item: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td style={{ padding: "10px", width: "20%" }}>
                        <span className="title-text-underline">
                          {item.corporate_name}
                        </span>
                        <br />
                        <span style={{ fontSize: "12px" }}>
                          {transformCNPJ(item.cnpj)}
                        </span>
                      </td>
                      <td style={{ padding: "10px", width: "30%" }}>
                        <span>{item.responsible_name}</span>
                        <br />
                        <span style={{ fontSize: "12px" }}>
                          {item.responsible_email}
                        </span>
                        <br />
                        <span style={{ fontSize: "12px" }}>
                          {transformCPF(item.responsible_cpf)}
                        </span>
                      </td>
                      <td style={{ padding: "10px", width: "20%" }}>
                        <span>{item.years}</span>
                      </td>
                      <td style={{ width: "30%" }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <button
                            className="button-scope"
                            style={{ marginRight: 5 }}
                            onClick={() => redirectToScope(item)}
                          >
                            Escopos
                          </button>
                          <button
                            className="button-info"
                            style={{ marginRight: 5 }}
                            onClick={() => redirectToInfo(item)}
                          >
                            Info <br />
                            Adicional
                          </button>
                          {onlyView === false && (
                            <button
                              className="button-edit"
                              style={{ marginRight: 5 }}
                              onClick={() => redirectToEdit(item)}
                            >
                              Editar
                            </button>
                          )}
                          {user.profile === "sinergia" && (
                            <button
                              className="button-delete"
                              style={{ marginRight: 5 }}
                              onClick={() => handleOpenModalDelete(item)}
                            >
                              Excluir
                            </button>
                          )}
                          {onlyView === true && (
                            <button
                              className="button-view"
                              style={{ marginRight: 5 }}
                              onClick={() => redirectToEdit(item)}
                            >
                              Visualizar
                            </button>
                          )}
                          
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          {companys?.itens?.length > 0 && (
            <Pagination
              total={companys.total}
              limit={25}
              goNext={goNext}
              goPrev={goPrev}
              currentPage={currentPage}
              length={companys.itens.length}
            />
          )}
        </div>
      )}

      {openModalDelete && (
        <ModalDelete
          isOpen={openModalDelete}
          handleModalVisible={handleOpenModalDelete}
          entityName={companySelected.name}
          handleOk={handleDeleteCompany}
        />
      )}
    </Container>
  );
};
