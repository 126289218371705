import React from 'react'
import { View} from '@react-pdf/renderer'

import { styles } from '../../../../components/PDF/styles/common'
import { colors } from '../../../../components/PDF/styles/colors'

import { formatPrice3 } from '../../../../utils/transform'
import { formatPrice4 } from '../../../../utils/transform'
import { Project } from '../../../Project/Project'

import FullProjectField from './FullProjectField'
import FullProjectFieldWithSubs from './FullProjectFieldWithSubs'




const FullProject: React.FC<{
  project: Project
}> = (props) => {
  const { project } = props
  return (
    <View
      wrap={false}
      style={{
        ...styles.column,
        borderBottom: `1px solid ${colors.lightGrey}`,
        borderTop: `1px solid ${colors.lightGrey}`,
        margin: '10px 0'
      }}
    >
      <FullProjectField title="PROJETO" value={project.project} />
      <FullProjectField
        title="INVESTIMENTO (R$):"
        value={formatPrice3(project.investment, false)}
      />
      <FullProjectField
        title="BENEFÍCIO FINANCEIRO (R$):"
        value={formatPrice3(project.financial_beneficiary, false)}
      />
      <FullProjectField
        title="CUSTO OPERACIONAL (R$):"
        value={formatPrice3(project.operational_cost, false)}
      />
      <FullProjectField
        title="TAXA DE JUROS (decimal):"
        value={formatPrice3(project.tax_fees, false)}
      />
      <FullProjectField
        title="TEMPO DE VIDA (anos):"
        value={formatPrice3(project.lifetime, false)}
      />
        {/* <FullProjectField
        title="tCO₂e evitada"
        value={formatPrice3(project.annual_emission, false)}
      /> */}
      <FullProjectFieldWithSubs  value={formatPrice4(project.annual_emission, false)}/>
    </View>
  )
}

export default FullProject
