import styled from 'styled-components';

interface ContainerProps {
    isMenuOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
    overflow: auto;
    position: relative;
    margin-top: 0;
    width: ${props => props.isMenuOpen ? 'calc(100vw - 330px)' : 'calc(100vw - 140px)'};
    max-width: ${props => props.isMenuOpen ? 'calc(100vw - 320px)' : 'calc(100vw - 140px)'};

    .table-scope {
        max-width: 100%;
        width: 4000px;
        border-collapse: collapse;
    }

    .table-scope thead {
        position: sticky;
        top: 0;
        background-color: white; /* Para garantir que o cabeçalho não fique transparente */
        /* z-index: 10; Para sobrepor outras linhas */
    }

    .table-container {
        max-height: 500px; /* Defina a altura máxima da tabela */
        overflow-y: auto; /* Adiciona rolagem vertical */
        border: 1px solid #ccc;
    }

    /* Estilos barra rolagem menu */
    .custom-scrollbar {
        overflow-y: scroll;
        overflow: hidden;
    }

    .custom-scrollbar:hover {
        overflow: auto;
    }

    .custom-scrollbar::-webkit-scrollbar {
        width: 10px; /* Largura da barra de rolagem */
        height: 10px; /* Largura da barra de rolagem */
    }

    .custom-scrollbar::-webkit-scrollbar-track {
        background: #f1f1f1; /* Cor da trilha */
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #d0dae7; /* Cor do polegar */
        border-radius: 10px; /* Borda arredondada */
    }

    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #555; /* Cor do polegar ao passar o mouse */
    }

`;