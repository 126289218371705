import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { colors } from '../../Dashboard/utils/colors';
import { calculateCMAandEmissions, formatPrefix, formatPrefixWithoutFormat, formatSufix } from '../../../utils/utils';
import { MACCChartProps, MaccProject } from '../interfaces/MaccProjetcs';


  // Create ToolTip string
  function createTooltipHTML(project: MaccProject, tCO2: number) {
      return `
        <strong>Projeto:</strong> ${project.project}<br/>
        <strong>Investimento:</strong> ${formatSufix(project.investment.toString(), "R$")}<br/>
        <strong>Benefício financeiro:</strong> ${formatSufix(project.financial_beneficiary.toString(), "R$")}<br/>
        <strong>Custo operacional:</strong> ${formatSufix(project.operational_cost.toString(), "R$")}<br/>
        <strong>Taxa de juros:</strong> ${formatPrefix(project.tax_fees.toString(), "")}<br/>
        <strong>Tempo de vida:</strong> ${formatPrefixWithoutFormat(project.lifetime.toString(), "anos")}<br/>
        <strong>Emissão anual:</strong> ${formatPrefix(project.annual_emission.toString(), "tCO₂e")}<br/>
        <strong>tCO₂e evitada:</strong> ${formatPrefix(tCO2.toString(), "tCO₂e")}<br/>
      `;
  }


  const createFormatter = (decimalPlaces: number) => {
    return d3.formatLocale({
      decimal: ",",
      thousands: ".",
      grouping: [3],
      currency: ["R$", ""],
    }).format(`,.${decimalPlaces}f`);
  }
  
  const formatNumber0 = createFormatter(0);
  const formatNumber2 = createFormatter(2);

function makeYGridlines(yScale: any, width: number) {
  return d3.axisLeft(yScale)
      .ticks(5)
      .tickSize(-width)
      .tickFormat(() => "")
}

export const MACCChart: React.FC<MACCChartProps> = ({ projects }) => {
  const ref: any = useRef() 


  const { calculatedCMA, cumulativeCOAvoided} = calculateCMAandEmissions(projects);  

  
   // Definicao do tamanho da plotagem do grafico
   const width = 1920;
   const height = 600;
   let margin = { top: 20, right: 60, bottom: 40, left: 60 };
   

  // Sort de tudo
  const sortedCostsWithProjects= calculatedCMA.filter(cost => cost.cost).sort((a, b) => a.cost - b.cost);   

  const sortedCosts = sortedCostsWithProjects.map(item => item.cost);
  
  const sortedtCO2 = sortedCostsWithProjects.map(item => item.tCO2);
  const sortedProjects = sortedCostsWithProjects.map(item => item.projectObj);

  // Definicao dos valores maximos e minimos do eixo Y (sempre em base 10)
  // Calcula os valores arredondados e ajusta conforme necessário em uma única etapa com operadores ternários.
  const maxValue = d3.max(sortedCosts) || 0;
  const minValue = d3.min(sortedCosts) || 0;

  let roundingBaseMax = 10;
  let roundingBaseMin = 10;

  
  // Para maxValue
  if (maxValue > 10 && maxValue <= 100) {
      roundingBaseMax = 10 + maxValue;
  } else if (maxValue > 100 && maxValue <= 1000) {
      roundingBaseMax = 100 + maxValue;
  } else if (maxValue > 1000) {
      roundingBaseMax = 1000 + maxValue;
      margin = { top: 20, right: 80, bottom: 40, left: 80 };
  }   
  // Para minValue
  if (Math.abs(minValue) > 10 && Math.abs(minValue) <= 100) {
      roundingBaseMin = 10 - minValue;
  } else if (Math.abs(minValue) > 100 && Math.abs(minValue) <= 1000) {
      roundingBaseMin = 100 - minValue;
  } else if (Math.abs(minValue) > 1000) {
      roundingBaseMin = 1000 - minValue;
      margin = { top: 20, right: 80, bottom: 40, left: 80 };
  } 

  const calcCeilMax = (maxValue / roundingBaseMax) * roundingBaseMax;
  const calcFloorMin = (minValue / roundingBaseMin) * roundingBaseMin;
  
  const roundedMaxValue = calcCeilMax < 10 ? 10 : calcCeilMax;
  const roundedMinValue = calcFloorMin > 0 ? -10 : calcFloorMin; 

  // Definicao dos dados das barras, tooltips, legendas e labels
  const barData = sortedCosts.map((cost, i) => ({
    cost,
    projectBar: sortedCostsWithProjects[i]
  }));    

  // Array das cores
  const colorArray = [
    colors.SkyBlueMedium,
    colors.LightMintGreen,
    colors.NeutralGray,
    colors.LightBronzeTan,
    colors.GoldenBeige,
    colors.CoralPink,
    colors.PaleGreen,
    colors.SlateBlue,
    colors.DarkSalmon,
    colors.LightSlateGray,
  ]; 
  useEffect(() => {
    d3.select(ref.current).selectAll("*").remove();
    if (ref.current) {
  
      // Definição do tamanho da plotagem do gráfico
      const totalCostWidth = sortedtCO2.reduce((acc, val) => acc + Math.abs(val), 0);
      const proportion = (width - (margin.right * 2)) / totalCostWidth;
  
      // Plot do ToolTip
      const tooltip = d3.select("body").append("div")
        .attr("class", "tooltip")
        .style("opacity", 0.5)
        .style("background-color", "white")
        .style("padding", "8px")
        .style("border-radius", "6px")
        .style("position", "absolute")
        .style("z-index", "10")
        .style("font-family", "Montserrat, sans-serif")
        .style("font-size", "16px")
        .style("fill", "#31363F")
        .style("pointer-events", "none"); // Isso garante que o tooltip não interfira com outros eventos do mouse.
  
      // Valores Eixo Y
      const yScale = d3.scaleSymlog()
        .domain([roundedMinValue, roundedMaxValue])
        .range([height, margin.top - 70]);
  
      // Plot da Área
      const svg = d3.select(ref.current)
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", `0 0 ${width + 250} ${height}`)
        .attr("preserveAspectRatio", "xMidYMid meet");
  
      const barsAndLabelsGroup = svg.append("g");
  
      const barMiddlePositions: number[] = [];
      // Plot das barras 
      barsAndLabelsGroup.selectAll("rect")
        .data(barData)
        .enter().append("rect")
        .attr("x", (d, i) => {
          const previousWidths = barData.slice(0, i).map(item => item.projectBar.tCO2).reduce((acc, val) => acc + Math.abs(val) * proportion, 0);
          const currentXPosition = margin.left + previousWidths;
          barMiddlePositions.push(currentXPosition + 0.5 * Math.abs(d.projectBar.tCO2) * proportion);
  
          return currentXPosition;
        })
        .attr("y", d => d.cost < 0 ? yScale(0) : yScale(d.cost) as number)
        .attr("fill", (d, i) => colorArray[i % colorArray.length])
        .attr("height", d => d.cost < 0 ? yScale(d.cost) - yScale(0) : yScale(0) - yScale(d.cost) as number)
        .attr("width", d => Math.max(1, Math.abs(d.projectBar.tCO2) * proportion))
        .on("mouseover", function (event, d) {
          const project = d.projectBar.projectObj;
          const tCO2 = d.projectBar.tCO2;
  
          tooltip.transition()
            .duration(100)
            .style("opacity", .9);
          tooltip.html(createTooltipHTML(project, tCO2))
            .style("left", (event.pageX + 5) + "px")
            .style("top", (event.pageY - 28) + "px");
        })
        .on("mouseout", function () {
          tooltip.transition()
            .duration(200)
            .style("opacity", 0);
        });
  
        // Plot dos separadores horizontais com setas horizontais
          barsAndLabelsGroup.selectAll(".separator")
          .data(barData)
          .enter().append("line")
          .attr("class", "separator")
          .attr("x1", (d, i) => {
            const previousWidths = barData.slice(0, i).map(item => item.projectBar.tCO2).reduce((acc, val) => acc + Math.abs(val) * proportion, 0);
            return margin.left + previousWidths + 10; // Ajuste para posicionar a seta ponta com ponta
          })
          .attr("y1", yScale(0)) // Mesma altura do eixo x
          .attr("x2", (d, i) => {
            const previousWidths = barData.slice(0, i).map(item => item.projectBar.tCO2).reduce((acc, val) => acc + Math.abs(val) * proportion, 0);
            return margin.left + previousWidths + Math.abs(d.projectBar.tCO2) * proportion - 5; // Ajuste para posicionar a seta ponta com ponta
          })
          .attr("y2", yScale(0)) // Mesma altura do eixo x
          .attr("stroke", "black") // Cor da linha
          .attr("marker-start", "url(#arrow)") // Adiciona seta no início
          .attr("marker-end", "url(#arrow)") // Adiciona seta no final
          .attr("stroke-width", 3); // Espessura da linha
            
      // Plot dos grids
      const gridGroup = svg.append("g")
        .attr("class", "grid")
  
      gridGroup.attr("transform", `translate(${margin.left},0)`)
        // .call(makeYGridlines(yScale, width - margin.left - margin.right)) // linhas no gráfico
        // .attr("stroke", "#E0E0E0") // Cor cinza pálida
        // .attr("opacity", 0.1); //
  
      const gridScales = svg.append("g");
  
      // Adiciona o defs com a seta (se não existir)
      const defs = gridScales.append("defs");
      const marker = defs.append("marker")
        .attr("id", "arrow")
        .attr("viewBox", "0 0 10 10")
        .attr("refX", 5)
        .attr("refY", 5)
        .attr("markerWidth", 6)
        .attr("markerHeight", 6)
        .attr("orient", "auto-start-reverse")
        .append("path")
        .attr("d", "M 0 0 L 10 5 L 0 10 z")
        .style("fill", "black");
  
      // Adiciona o eixo Y com a seta
      gridScales.append("g")
        .attr("transform", `translate(${margin.left},0)`)
        .style("font-family", "Montserrat, sans-serif")
        .style("font-size", "0px")
        .attr("opacity", 1)
        .style('stroke-width', 4)
        .call(d3.axisLeft(yScale)
          .tickSize(0) // esconde as escalas da barra
          .tickFormat(() => "")
        )
        .select(".domain") // Seleciona a linha do eixo
        .attr("marker-end", "url(#arrow)"); // Adiciona o marcador de seta
  
      // Valores Eixo X
      const xScale = d3.scaleLinear()
        .domain([0, totalCostWidth])
        .range([margin.left, width - margin.right]);
  
      const ticksGroup = gridScales.append("g")
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .style("font-family", "Montserrat, sans-serif")
        .style("font-size", "16px")
        .style("fill", "#31363F");
  
      barMiddlePositions.forEach((pos, index) => {
        ticksGroup.append("line")
          .attr("x1", pos)
          .attr("x2", pos)
          .attr("y1", 0)
          .attr("y2", 6)
          .attr("stroke", "none");
  
        ticksGroup.append("text")
          .attr("x", pos)
          .attr("y", 10)
          .attr("dy", ".71em")
          .attr("text-anchor", "middle")
          // .text([...cumulativeCOAvoided.map(String)][((index))]);
      });

      // Plot Labels para barras negativas 
      barsAndLabelsGroup.selectAll(".label-negative")
                .data((sortedCosts))
                .enter().append("text")
                .attr("class", "label-negative")      
                // .attr("x", (d, i) => {
                //   if(barData[i].cost < 0){              
                //     const previousWidths = barData.slice(0, i).map(item => item.projectBar.tCO2).reduce((acc, val) => acc + Math.abs(val) * proportion, 0);                    
                //     const position = margin.left + previousWidths + (Math.abs(barData[i].projectBar.tCO2) * proportion) / 2;                    
                //     return position
                //   }
                //   return '';
                // })
                // .attr("y", (d, i) => {         
                //   if(barData[i].cost < 0){              
                //     return yScale(d) + 15
                //   }
                //   return '';
                // }) // 15 pixels abaixo da barra 
                .attr("text-anchor", "middle")
                .style("font-family", "Montserrat, sans-serif")
                .style("font-size", "16px")
                .style("fill", "#31363F")  
                // .text((d, i) => {      
                //   if(barData[i].cost < 0){            
                //     return `${formatNumber2(d.toFixed(2) as unknown as number)}`
                //   }
                //     return '';
                // });

      // Plot Labels para barras positivas
      barsAndLabelsGroup.selectAll(".label-positive")
                .data(sortedCosts)
                .enter().append("text")
                .attr("class", "label-positive")
                // .attr("x", (d, i) => {      
                //   if(barData[i].cost > 0){            
                //     const previousWidths = barData.slice(0, i).map(item => item.projectBar.tCO2).reduce((acc, val) => acc + Math.abs(val) * proportion, 0);                    
                //     const position = margin.left + previousWidths + (Math.abs(barData[i].projectBar.tCO2) * proportion) / 2;                    
                //     return position
                //   }
                //   return '';
                // })
                // .attr("y", (d, i) => {         
                //     if(barData[i].cost > 0){              
                //       return yScale(d) -5
                //     }
                //     return '';
                // }) // 5 pixels acima da barra
                .attr("text-anchor", "middle")
                .style("font-family", "Montserrat, sans-serif")
                .style("font-size", "16px")
                .style("fill", "#31363F")
                // .text((d, i) => {      
                //   if(barData[i].cost > 0){            
                //     return `${formatNumber2((d).toFixed(2) as unknown as number)}`
                //   }
                //     return '';
                // });
      
      // Plot dos Titulos    
      const titles = svg.append("g");
      titles.append("text")
                .attr("transform", "rotate(-90)")
                .style("font-family", "Montserrat, sans-serif")
                .style("font-size", "24px")
                .style("font-color", "#31363F")  
                .attr("y", 20 - 10)
                .attr("x", 0 - (height / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text("Custo marginal de abatimento (R$/tCO₂e)");

      titles.append("text")
                .attr("y", 600)
                .attr("x", (width / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .style("font-family", "Montserrat, sans-serif")
                .style("font-size", "30px")
                .style("font-color", "#31363F")  
                .text("tCO₂e evitada");
      
          
      const legendWidth = 150;
      const legendX = width - legendWidth - 1680; // 10 pixels de margem à direita
      const legendY = margin.top - 80;
      
      //Plot das legendas
      const legend = svg.append("g")
                .attr("transform", `translate(${legendX},${legendY})`);

      const legendHeight = sortedProjects.length * 20; 

      legend.append("rect")
                .attr("class", "legend-background")
                .attr("x", -10) 
                .attr("y", -10) 
                .attr("width", legendWidth + 20) 
                .attr("height", legendHeight + 20) 
                .attr("fill", "white")
                .attr("opacity", 0); 
      
      legend.selectAll(".color-rect")
                .data(barData)
                .enter().append("rect")
                .attr("class", "color-rect")
                .attr("x", 40)
                .attr("y", (d, i) => i * 30)
                .attr("width", 20)
                .attr("height", 20)
                .attr("fill", (d, i) => colorArray[i % colorArray.length])
                .on("mouseover", function(event, d) {
                  const project = d.projectBar.projectObj;
                  const tCO2 = d.projectBar.tCO2;

                  const tooltipWidth = 200;
              
                  tooltip.transition()
                      .duration(100)
                      .style("opacity", .9);
                  tooltip.html(createTooltipHTML(project, tCO2))
                      .style("left", (event.pageX + 5) + "px")
                      .style("top", (event.pageY - 28) + "px");
                })
                .on("mouseout", function() {
                    tooltip.transition()
                        .duration(200)
                        .style("opacity", 0);
                });
      
      legend.selectAll("text")
                .data(barData)
                .enter().append("text")
                .attr("x", 80) // 20 pixels à direita do retângulo
                .attr("y", (d, i) => i * 32 + 12) // centralizado verticalmente com o retângulo
                .text(d => d.projectBar.projectObj.project)
                .style("font-family", "Montserrat, sans-serif")
                .style("font-size", "25px")
                .style("fill", "#31363F")  
                .on("mouseover", function(event, d) {
                  const project = d.projectBar.projectObj;
                  const tCO2 = d.projectBar.tCO2;

                  tooltip.transition()
                      .duration(100)
                      .style("opacity", .9);
                  tooltip.html(createTooltipHTML(project, tCO2))
                      .style("left", (event.pageX + 5) + "px")
                      .style("top", (event.pageY - 28) + "px");
                })
                .on("mouseout", function() {
                    tooltip.transition()
                        .duration(200)
                        .style("opacity", 0);
                });

                svg.append("line")
                  .style('stroke', 'black')  // Cor da linha
                  .style('stroke-width', 4)  // Espessura da linha
                  .style("opacity", 1)
                  .attr("marker-end", "url(#arrow)")
                  .attr("x1", margin.left)
                  .attr("y1", yScale(0))
                  .attr("x2", width - margin.right + 40)
                  .attr("y2", yScale(0)); 
      return () => {
        // descontrucao do ToolTip
        tooltip.remove();
      };
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps 
  [projects]);

  
  return <svg ref={ref}></svg>;
}