import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import companyService from "../../../services/companyService";
import { Container } from "./styles";
import TrashIcon from '../../../assets/icons/trash.svg'
import { isValidCnpj, toNumberValues, transformCNPJ, transformOnlyNumbers } from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

export const CompanyRegister = () => {
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sSize, setSSize] = useState(0);
  const [scopes, setScopes] = useState<any>([]);
  const [scope1, setScope1] = useState<any>([]);
  const [scope2, setScope2] = useState<any>([]);
  const [scope3, setScope3] = useState<any>([]);
  const [companies, setCompanies] = useState<any>([]);
  const [invalidCnpj, setInvalidCnpj] = useState(false);
  const [errorApi, setErrorApi] = useState<string | undefined>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [companySelected, setCompanySelected] = useState<any>({});

  async function getScopes() {
    let itens = await companyService.getScopesDefault(1)

    setScope1(itens.map((scope: any) => {
      return {
        slug: scope.slug, 
        name: scope.name, 
        label: scope.label, 
        sub_slug: scope.sub_slug, 
        enable: false
      };
    }));

    itens = await companyService.getScopesDefault(2)

    setScope2(itens.map((scope: any) => {
      return {
        slug: scope.slug, 
        name: scope.name, 
        label: scope.label, 
        sub_slug: scope.sub_slug, 
        enable: false
      };
    }));

    itens = await companyService.getScopesDefault(3)

    setScope3(itens.map((scope: any) => {
      return {
        slug: scope.slug, 
        name: scope.name, 
        label: scope.label, 
        sub_slug: scope.sub_slug, 
        enable: false
      };
    }));
  }

  async function getCompanies() {
    const result = await companyService.getParents();

    const options = result.itens.map((company: any) => {
      return {
        label: company.corporate_name,
        value: company.id,
      };
    });

    setCompanies(options);
  }

  useEffect(() => {
    getScopes()
    getCompanies()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = async (formData) => {
    if (formData) {

      if (isValidCnpj(formData.cnpj)){
        const years:any[] = []
  
        for (const item of scopes){
          const scopes:any[] = []
  
          for (let i = 0; i < scope1?.length; i++) {
            const base = "year_" + item.year + "_scope1";
            const name = scope1[i].name;
  
            const check = formData[base][name].check
  
            const checkObj = {
                type: 1,
                name: scope1[i].name,
                slug: scope1[i].slug,
                label: scope1[i].label,
                sub_slug: scope1[i].sub_slug,
                enable: check
            }
    
            scopes.push(checkObj)
          }
  
          for (let i = 0; i < scope2?.length; i++) {
            const base = "year_" + item.year + "_scope2";
            const name = scope2[i].name;
  
            const check = formData[base][name].check
  
            const checkObj = {
                type: 2,
                name: scope2[i].name,
                slug: scope2[i].slug,
                label: scope2[i].label,
                sub_slug: scope2[i].sub_slug,
                enable: check
            }
    
            scopes.push(checkObj)
          }
  
          for (let i = 0; i < scope3?.length; i++) {
            const base = "year_" + item.year + "_scope3";
            const name = scope3[i].name;
  
            const check = formData[base][name].check
  
            const checkObj = {
                type: 3,
                name: scope3[i].name,
                slug: scope3[i].slug,
                label: scope3[i].label,
                sub_slug: scope3[i].sub_slug,
                enable: check
            }
    
            scopes.push(checkObj)
          }
  
          const obj = {
              year: Number(item.year),
              scopes: scopes
          }
  
          years.push(obj)
        }
  
        const item = {
            cnae: formData.cnae,
            fantasy_name: formData.fantasy_name,
            corporate_name: formData.corporate_name,
            responsible_name: formData.responsible_name,
            responsible_email: formData.responsible_email,
            company_id: formData.company ? formData.company.value : undefined,
            cnpj: formData.cnpj ? transformOnlyNumbers(formData.cnpj) : undefined,
            years: years,
        };
  
        const result = await companyService.register(
          item
        );
  
        if (result && !result.error) {
         // history.goBack()
  
         // reset();
         // setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }
  
        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  const onAddYear = () => {
    const inputItem = (document.querySelector("#yearInput") as HTMLInputElement)

    const year = inputItem.value

    if (year){
      const itens = scopes

      itens.push({year: year, scope1: scope1, scope2: scope2, scope3: scope3})

      setScopes(itens)
      setSSize(itens.length)

      for (let i = 0; i < scope1?.length; i++) {
        setValue("year_" + year + "_scope1." + scope1[i].name + ".check", true)
      }

      for (let i = 0; i < scope2?.length; i++) {
        setValue("year_" + year + "_scope2." + scope2[i].name + ".check", true)
      }

      for (let i = 0; i < scope3?.length; i++) {
        setValue("year_" + year + "_scope3." + scope3[i].name + ".check", true)
      }
      
      inputItem.value = ""
    }
  }

  async function handleDeleteYear(item: any) {
    const itens = scopes.filter(function(a:any) { 
      return a.year !== item.year
    })

    setScopes(itens)
    setSSize(scopes.length)
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, justifyContent: "space-between", marginBottom: 10 }} >
          <TitleText level={1} title="Nova empresa"  />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ overflow: "auto", position: "absolute", top: "110px", right: "0px", left: "280px", bottom: "20px"}} >
          <div style={{ display: "flex", flexDirection: "row", width: "95%" }}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%"}}>
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                    lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                      Razão social
                  </label>
                  <input style={{width: "100%"}} 
                      {...register("corporate_name", { required: true })}
                  />
                  {errors.corporate_name && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                  )}
              </div>
              <div style={{ display: "flex", flexDirection: "column",  marginRight: 10, width: "50%"}}>
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                    lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                      Nome fantasia
                  </label>
                  <input style={{width: "100%"}} 
                      {...register("fantasy_name", { required: true })}
                  />
                  {errors.fantasy_name && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                  )}
              </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", width: "95%" }}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                    lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                      CNPJ
                  </label>
                  <input style={{width: "100%"}}
                      {...register("cnpj", { required: true })}
                      onChange={(event) => {
                        const { value } = event.target;
                        event.target.value = transformCNPJ(value);
                      }}
                  />
                  {errors.cnpj && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                  )}
                  {/* {errorApi && errorApi.includes("cnpj") && (
                    <span className='requiredLabel' >CNPJ já registrado no sistema.</span>
                  )} */}
                  {invalidCnpj && (
                    <span className='requiredLabel' >CNPJ inválido.</span>
                  )}
              </div>

              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                    lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                      CNAE
                  </label>
                  <input style={{width: "100%"}}
                      {...register("cnae", { required: false })}
                  />
                  {errors.cnae && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                  )}
              </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", width: "95%" }}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                    lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                      Nome do responsável
                  </label>
                  <input style={{width: "100%"}} 
                      {...register("responsible_name", { required: true })}
                  />
                  {errors.responsible_name && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                  )}
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                    lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                      E-mail do responsável
                  </label>
                  <input style={{width: "100%"}}
                      {...register("responsible_email", { required: true })}
                  />
                  {errors.responsible_email && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                  )}
                  {errorApi && errorApi.includes("mail") && (
                    <span className='requiredLabel' >E-mail já registrado no sistema.</span>
                  )}
              </div>
          </div>


          <div style={{ display: "flex", flexDirection: "column",  marginRight: 10 }}>
            <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Matriz
            </label>
            {companies && (
                <Controller control={control} rules={{ required: false }}
                        name="company" render={({ field }) => (
                    <Select placeholder="[Selecione]" {...field} styles={{ 
                        control: (styles) => ({ ...styles,
                                width: "95%", height: 40, marginTop: 10,
                                backgroundColor: "rgb(246, 246, 246)"}),
                        option: (styles) => { return { ...styles, color: "#31363F" };},}}
                        options={companies}
                        isClearable={true}                   
                    />
                    )}
                />
            )}

            {errors.company && (
                <span style={{ color: "#E33F3F", marginTop: 10 }}>
                    Esse campo é obrigatório
                </span>
            )}
          </div>

          <div style={{ display: "flex", flexDirection: "row", marginTop: 20, 
                justifyContent: "space-between", marginBottom: 0 }} >
            <TitleText level={3} title="Anos liberados"  />
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
              <input autoComplete={"off"} style={{ background: "#F6F6F6",
                  border: "1px solid #DFDFDF", borderRadius: " 2.5px",
                  width: "95%", paddingLeft: 16, height: "35px",
                  marginTop: 5, }} id="yearInput"  
                  placeholder="Informe o ano desejado para liberação"
                  onChange={(event) => {
                      const { value } = event.target;
                      event.target.value = toNumberValues(value);
                  }}
              />

              <Button color="black" size="md" onClick={()=>onAddYear()} type="button"
                  style={{ height: "40px", width: "50px",  marginTop: 5, marginLeft: 10, marginRight: 10 }}>
                  +
              </Button>
          </div>

          {scopes && scopes.length > 0 && (
            <div style={{ marginTop: 15, paddingBottom: 15, overflow: "auto" }}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tbody>
                  {scopes.map((scope: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div style={{ display: "flex", flexDirection: "row", width: "95%", marginBottom: 10,
                              borderStyle: "dashed", borderWidth: "1px", borderColor: "#9E9E9E", }}>
                          <div style={{ verticalAlign: "middle", width: "15%", padding: 10 }}>
                              <span style={{textDecoration: "underline", fontSize: 24, fontWeight: "bold"}}>
                                  {scope.year}
                              </span>
                          </div>

                          <div style={{ display: "flex", flexDirection: "column", width: "75%", height: 250, overflow: "auto" }}>
                            <table style={{ width: "100%", borderCollapse: "collapse", marginTop: 5 }} className="table-scope">
                              <thead>
                                <tr>
                                  <th className="head-tr-item" style={{ width: 200 }}>
                                    {'Escopo 01'}
                                  </th>
                                  <th className="head-tr-item"/>
                                </tr>
                              </thead>
                              <tbody>
                                {scope.scope1.map((item: any, index: number) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr>
                                        <td style={{ width: "90%" }} >
                                          <span>
                                            {item.label}
                                          </span>
                                        </td>
                                        <td>
                                          <div style={{ width: "100%", textAlign: "center" }}>
                                            <input style={{ height: "15px", width: "15px" }} 
                                                    type="checkbox" {...register(`year_${scope.year}_scope1.${item.name}.check`)}/>
                                          </div>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </tbody>
                            </table>

                            <table style={{ width: "100%", borderCollapse: "collapse", marginTop: 5 }} className="table-scope">
                              <thead>
                                <tr>
                                  <th className="head-tr-item" style={{ width: 200 }}>
                                    {'Escopo 02'}
                                  </th>
                                  <th className="head-tr-item"/>
                                </tr>
                              </thead>
                              <tbody>
                                {scope.scope2.map((item: any, index: number) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr>
                                        <td style={{ width: "90%" }} >
                                          <span>
                                            {item.label}
                                          </span>
                                        </td>
                                        <td>
                                          <div style={{ width: "100%", textAlign: "center" }}>
                                            <input style={{ height: "15px", width: "15px" }} 
                                                    type="checkbox" {...register(`year_${scope.year}_scope2.${item.name}.check`)}/>
                                          </div>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </tbody>
                            </table>

                            <table style={{ width: "100%", borderCollapse: "collapse", marginTop: 5 }} className="table-scope">
                              <thead>
                                <tr>
                                  <th className="head-tr-item" style={{ width: 200 }}>
                                    {'Escopo 03'}
                                  </th>
                                  <th className="head-tr-item"/>
                                </tr>
                              </thead>
                              <tbody>
                                {scope.scope3.map((item: any, index: number) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr>
                                        <td style={{ width: "90%" }} >
                                          <span>
                                            {item.label}
                                          </span>
                                        </td>
                                        <td>
                                          <div style={{ width: "100%", textAlign: "center" }}>
                                            <input style={{ height: "15px", width: "15px" }} 
                                                    type="checkbox" {...register(`year_${scope.year}_scope3.${item.name}.check`)}/>
                                          </div>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          <div style={{ verticalAlign: "middle", width: "10%", padding: 10 }}>
                              <button style={{ width: "40px", height: "35px",
                                      background: "#ffffff", borderColor: "#E33F3F",
                                      borderWidth: "1px", borderStyle: "solid",
                                      borderRadius: "2.5px", color: "#E33F3F",
                                      cursor: "pointer", fontWeight: "bold", 
                                      float: "right" }}
                                  type="button"
                                  onClick={() => handleDeleteYear(scope)}
                              >
                                  <img src={TrashIcon} alt="lixeirinha"/>
                              </button>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </form>
    </Container>
  );
};
