import React, {useState, useEffect} from 'react'
import { Container } from './styles'
import InfoButtonMin from "../../../../assets/icons/info-sign.png"

type StepFourProps = {
    register: (value: string) => any;
    setValue: (value: string, e: any) => any;
    handleChange: () => void;
    setStepFour: (value: boolean) => void;
    stepFour: boolean | null;
    Ipcc: any[];
    setIpcc: (value: any) => any;
    handleChangeIpcc: (id: any) => void;
    setSelectedUnit: (value: string) => any;
    selectedUnit: any;
}

export const StepFour: React.FC<StepFourProps> = ({
    register, 
    setValue, 
    handleChange, 
    setStepFour,
    stepFour, 
    Ipcc = [],
    handleChangeIpcc,
    selectedUnit,
    setSelectedUnit,
    setIpcc}) => {

    function moeda(a: string, e: string, r: string, decPlaces = 6): string {
        if (!a || a.replace(/[^\d]+/g, "") === "") {
            return "0" + r + "0".repeat(decPlaces);
        }
        
        let n = a.replace(new RegExp(`\\${e}`, "g"), "").replace(new RegExp(`\\${r}`, "g"), "");
        
        n = n.replace(/[^\d]+/g, "");
        
        while (n.length <= decPlaces) {n = "0" + n;}
        
        const len = n.length;
        const intPart = n.substring(0, len - decPlaces);
        const decPart = n.substring(len - decPlaces);
        
        const cleanedIntPart = intPart.replace(/^0+/, "") || "0";
        
        let formattedValue = cleanedIntPart.replace(/\B(?=(\d{3})+(?!\d))/g, e);
        formattedValue += r + decPart;
        
        return formattedValue;
    }

    const getUnitLabel = (unit: number) => {
        switch (unit) {
            case 1:
                return "[kgDQO/m³]";
            case 2:
                return "[kgDBO/m³]";
            default:
                return "";
        }
    };

    useEffect(() => {
      const selectedUnit = ("effluent_degradable_unity");
      if (selectedUnit) {
        handleChange();
      }
    }, [("effluent_degradable_unity")]);


  return (
    <Container>
        <div className='div-body'>
            <div className='div-text'>
                <span className='text-statement'>
                    Passo 4 - Dados da composição orgânica do efluente doméstico
                </span>
                <div className="tooltip" style={{ margin: 5 }}>
                    <img src={InfoButtonMin} alt="information" style={{ width: 25 }} />
                    <span className="tooltiptext">
                        Preencha os dados relativos à carga orgânica degradável do efluente doméstico.
                    </span>
                </div>
            </div>
            
            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text' style={{fontWeight:"bold"}}>
                        Para efluentes domésticos, se não possuir um valor
                        específico de DQO, selecione "Não" e escolha o tipo de setor
                        industrial, que será utilizado o valor do IPCC 2006.
                    </span>
                </div>
            </div>
            
            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text' style={{fontWeight:"bold"}}>
                        Se não possuir um valor específico de carga orgânica
                        removida com o lodo, deixe em branco. Será assumido que não
                        houve remoção de lodo no tratamento.
                    </span>
                </div>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text'>
                        Você possui o componente orgânico degradável do efluente
                        (KgDQO/m³ ou KgDBO/m³) <br />
                        na entrada do tratamento (efluente bruto)?
                    </span>
                </div>

                <div className='selection-input'>
                    <input
                        type="radio"
                        value="1"
                        style={{ width: 20, marginLeft: 0, marginRight: 10 }}
                        {...register("raw_effluent")}
                        onChange={(event) => {
                            setValue("raw_effluent", event.target.value);
                            setStepFour(true);
                            handleChange();
                        }}
                    />
                        <span>Sim</span>

                    <input
                        type="radio"
                        value="0"
                        style={{ width: 20, marginRight: 10 }}
                        {...register("raw_effluent")}
                        onChange={(event) => {
                            setValue("raw_effluent", event.target.value);
                            setStepFour(false);
                            handleChange();
                        }}
                    />
                        <span>Não</span>
                </div>
            </div>
           
            {/* caso a opção seja Não no passo 4 */}
            {stepFour === false && (
                <div className='input-item'>
                    <div className='div-item'>
                        <span className='prompt-text'>
                            Selecione o setor industrial (IPCC)
                        </span>
                    </div>

                    <div className='selection-input'>
                        {Ipcc && (
                            <select
                                style={{ borderRadius: 5, width: "325px", height: 30 }}
                                {...register("ipcc_first_id")}
                                defaultValue=""
                                onChange={(event) => {
                                    const { value } = event.target;

                                    handleChangeIpcc(value);
                                    if (!value) {
                                    setValue("ipcc_effluents_first", "");
                                    }
                                }}
                            >
                                <option value="">[Selecione]</option>
                                    {Ipcc.map((type: any) => {
                                    return (
                                        <option value={type.id} key={type.id}>
                                        {type.name}
                                        </option>
                                    );
                                })}
                            </select>
                        )}
                    </div>
                </div>
            )}

            {stepFour === false && (
               <div className='input-item'>
                   <div className='div-item'>
                       <span className='prompt-text'>
                           Componente orgânico degradável do efluente, na entrada do
                           tratamento
                       </span>
                   </div>

                    <div className='selection-input'>
                        <input 
                            className='value-item'
                             readOnly
                            {...register("ipcc_effluents_first")}
                        />
                        <span className='unit-item'>
                            [kgDQO/m³]
                        </span>
                    </div>
               </div>
            )}

            {/* caso a opção seja sim na opção 4 */}

            {stepFour === true && (
                <div className='input-item'>
                    <div className='div-item'>
                        <span className='prompt-text'>
                           Componente orgânico degradável do efluente, na entrada do
                           tratamento
                       </span>
                    </div>

                    <div className='selection-input'>
                        <input style={{width: '135px', height: 35, textAlign: "right"}} 
                            {...register("effluent_degradable")}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                const inputValue = event.target.value;
                                const formattedValue = moeda(inputValue, ".", ",", 6);                   
                                setValue("effluent_degradable", formattedValue);                  
                                handleChange();           
                                event.target.value = formattedValue;
                            }}
                        />

                        <span className='span-item'>
                            <select className='select-box'
                                {...register("effluent_degradable_unity")}
                                onChange={(event) => {
                                    const unit = event.target.value;

                                    setValue("effluent_degradable_unity", unit);
                                    setSelectedUnit(unit);
                                    handleChange();
                                }}
                            >
                                <option value="0"></option>
                                <option value="1" label="[kgDQO/m³]" />
                                <option value="2" label="[kgDBO/m³]" />
                            </select>
                        </span>
                    </div>
                </div>
            )}

            {stepFour === true && (
                <div className='input-item'>
                    <div className='div-item'>
                        <div className='prompt-text'>
                            Componente orgânico do efluente removido com o lodo
                        </div>
                    </div>
                    <div className='selection-input'>
                        <input style={{width: '135px', height: 25, textAlign: "right"}} 
                            {...register("removed_component_organic")}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                const inputValue = event.target.value;
                                const formattedValue = moeda(inputValue, ".", ",", 6);               
                                setValue("removed_component_organic", formattedValue);                      
                                handleChange();                       
                                event.target.value = formattedValue;
                            }}
                        />

                        <span className='span-item' style={{height: 15}}>
                            {getUnitLabel(selectedUnit)}
                        </span>
                    </div>
                </div>
            )}

            {stepFour === false && (
                <div className='input-item'>
                    <div className='div-item'>
                        <div className='prompt-text'>
                            Componente orgânico do efluente removido com o lodo
                        </div>
                    </div>

                    <div className='selection-input'>
                        <input style={{width: '135px', height: 25, textAlign: "right"}} 
                            {...register("removed_component_organic")}
                            onChange={(event) => {
                                const inputValue = event.target.value;
                                const formattedValue = moeda(inputValue, ".", ",", 6);               
                                setValue("removed_component_organic", formattedValue);                      
                                handleChange();                       
                                event.target.value = formattedValue;
                            }}
                        />

                        <span className='span-item' style={{height: 15}}>
                            [kgDQO/m³]
                        </span>
                    </div>
                </div>
            )}

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text' style={{fontWeight:"bold"}}>
                        Preencha os dados sobre a quantidade de nitrogênio presente
                        no efluente. Para efluentes industriais, se não possuir,
                        deixe em branco, será utilizado o default sugerido por IPCC
                        (2019).
                    </span>
                </div>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <span className='prompt-text' style={{fontWeight:"bold"}}>
                        Preencha o fator de emissão de N₂O do efluente. Se não
                        possuir, deixe em branco, será utilizado o default sugerido
                        por IPCC (2019).
                    </span>
                </div>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <div className='prompt-text'>
                        Quantidade de nitrogênio no efluente gerado
                    </div>
                </div>
                <div className='selection-input'>
                    <input style={{width: '135px', height: 25, textAlign: "right"}} 
                        {...register("amount_n2_nitrogen")}
                        onChange={(event) => {
                            const inputValue = event.target.value;
                            const formattedValue = moeda(inputValue, ".", ",", 6);               
                            setValue("amount_n2_nitrogen", formattedValue);                      
                            handleChange();                       
                            event.target.value = formattedValue;
                        }}
                    />

                    <span className='span-item' style={{height: 15}}>
                        [kgN/m³]
                    </span>
                </div>
            </div>

            <div className='input-item'>
                <div className='div-item'>
                    <div className='prompt-text'>
                        Fator de emissão de N₂O do tratamento do efluente
                    </div>
                </div>
                <div className='selection-input'>
                    <input style={{width: '135px', height: 25, textAlign: "right"}} 
                        {...register("n2o_emission_factor")}
                        onChange={(event) => {
                            const inputValue = event.target.value;
                            const formattedValue = moeda(inputValue, ".", ",", 6);               
                            setValue("n2o_emission_factor", formattedValue);                      
                            handleChange();                       
                            event.target.value = formattedValue;
                        }}
                    />
                    
                    <span className='span-item' style={{height: 15}}>
                        [kgN₂O-N/kgN]
                    </span>
                </div>
            </div>

        </div>
    </Container>
  )
}

