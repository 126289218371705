import { Container } from './styles';
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";


interface HomeOfficeSubHeaderProps {
  descripton: string;
  options_description: string;
  isCommonUser: boolean;
  homeEnergy: any;
  control: any;
  getValues: (value: string) => any;
  handleChangeHomeOffice: (e: any) => void;
  handleOpenModalImport: () => any;
}

export const HomeOfficeSubHeader = ({ descripton, options_description, isCommonUser, homeEnergy, control, getValues, handleChangeHomeOffice, handleOpenModalImport }: HomeOfficeSubHeaderProps) => {
    return (
        <Container>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <span
              style={{
                textAlign: "start",
                fontSize: 16,
                fontWeight: "bold",
                marginTop: 5,
              }}
            >
              {descripton}
            </span>
            <span
              style={{ textAlign: "start", fontSize: 12, marginTop: 5 }}
            >
              {options_description}
            </span>
          </div>

          <div style={{ textAlign: "left", marginRight: 20, width: "30%" }}>
            {!isCommonUser && homeEnergy && (
              <div
                style={{
                  marginTop: 15,
                  marginRight: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Controller
                  control={control}
                  name="home_office_params"
                  render={({ field }) => (
                    <Select
                      placeholder="[Selecione]"
                      {...field}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          width: "250px",
                          height: 40,
                          backgroundColor: "rgb(246, 246, 246)",
                        }),
                        option: (styles) => {
                          return { ...styles, color: "#31363F" };
                        },
                      }}
                      defaultValue={getValues("home_office_params")}
                      options={homeEnergy}
                      onChange={handleChangeHomeOffice}
                    />
                  )}
                />

                <button
                  className="button-view"
                  style={{ height: 40, marginLeft: 5 }}
                  onClick={() => handleOpenModalImport()}
                  type="button"
                >
                  Importar Dados
                </button>
              </div>
            )}
          </div>
        </Container>
    );
};