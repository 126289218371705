import { DefaultTheme } from 'styled-components';
import { ColorTheme } from './typings/Theme/index';


const colors: ColorTheme = {
  black: '#31363F',
  white: '#FFFFFF',
  grey: '#524b4d',
  red: '#C42329',
  green: '#81c787',
  orange: '#D37620',
  blue: '#326ed6',
  purple: '#9500ff',

};

const theme: DefaultTheme = {
  colors
};


export default theme;
