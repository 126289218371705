import styled from 'styled-components';

export const Container = styled.nav`
    display: flex;
    flex-direction: column;
    // box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    max-width: 280px;
    width: 280px;
    background: #FFFFFF;
    height: 100vh;

    .menu-divider{
        border-top: 0.6px solid rgba(49, 54, 63, 0.35);
        margin: 2px 10px 2px 10px;
    }

    .user-name {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #192A3E;
    }

    .user-mail {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: #90A0B7;
    }

    .container-links {
        list-style-type: none;
        padding: 0;
        margin-top: 10px;
    }

    .link {
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 15px;
        padding-top: 8px;
        padding-bottom: 8px;
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: ${props => props.theme.colors.grey};
        opacity: 0.65;
    }

    .logout-text {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: ${props => props.theme.colors.grey};
        cursor: pointer;
        opacity: 1;
    }

    .active-link {
        background: rgba(49, 54, 63, 0.08);
        opacity: 1;
        border-right: 3px solid ${props=>props.theme.colors.black};
    }

    .content-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    .containt-logout {
        padding-left: 15px;
        padding-top: 8px;
        padding-bottom: 20px;
        position: absolute;
        bottom: 0px;
        width: 250px;
    }

    .containt-help {
        padding-left: 13px;
        padding-top: 8px;
        padding-bottom: 20px;
        position: absolute;
        bottom: 35px;
        width: 250px;
    }

    .link-text {
        margin-left: 10px;
    }

    .escopo-inventario-link{
        margin-left: 2px;
    }

    .organismo-link, 
    .dashboard-link, 
    .home-office-link,
    .refrigeracao-link, 
    .topico-ajuda-link,
    .tipo-chamado-link,
    .tipo-transporte-link,
    .tipo-transporte-link,
    .combustivel-particular-link, 
    .conssessionaria-trem-link{
        margin-left: -2px;
    }
    
    .logo-image {
        position: absolute;
        bottom: 100px;
        padding-left: 70px;
    }
    
    .image-help {
        margin-leftt: -2px;
    }
`;