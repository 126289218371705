import styled from "styled-components";

export const Container = styled.div`
  padding: 15px;
  width: 100%;

  .container {
    overflow-y: scroll;
    position: relative;
    height: calc(100vh - 130px);
    max-height: calc(100vh - 130px);
  }

  .title-container {
    margin-top: 20px;
  }

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
  }

  .thead-item {
    background-color: #D8D7D7;
    font-size: 12px;
    height: 25px;
  }

  .tbody-item-bold {
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    padding-left: 10px;
    background: #F6F6F6;
  }

  .tbody-item {
    font-size: 12px;
    height: 25px;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    background: #F6F6F6;
    text-align: right;
  }

  .tbody-item_total {
    font-size: 12px;
    font-weight: bold;
    text-align: right;
    padding-left: 10px;
    padding-right: 10px;
    background: #c6c5c5;
    height: 25px;
  }

  .table-title {
    font-weight: 500;
    font-family: "Roboto", sans-serif;
  }

  .table-container {
    overflow-x: scroll;
    position: relative;
    max-width: calc(100vw - 300px);
  }
`;
