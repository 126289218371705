import Parser from 'html-react-parser'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import useAuth from '../../../../hooks/useAuth'
import { Container } from './styles'
import { formatPrice3, formatPrice5 } from '../../../../utils/transform'
import inventoryService from '../../../../services/inventoryService'
import fuelService from '../../../../services/fuelService'
import energyTypeService from '../../../../services/energyTypeService'
import scope2Service from '../../../../services/scope2Service'
import scopeService from '../../../../services/scopeService'
import TrashIcon from '../../../../assets/icons/trash.svg'
import InfoButtonMin from '../../../../assets/icons/info-sign.png';
import { Button } from '../../../../components/Button/styles'
import { Modal } from '../../../../components/Modal'
import { GoBack } from '../../../../components/GoBack'
import { TitleText } from '../../../../components/TitleText'
import ReportRecordsCheckbox from '../../components/ReportRecordsCheckbox'
import ScopeEvidenceAttachments from '../../components/ScopeEvidenceAttachments'
import { Uninformed } from '../../../../components/Uninformed'
import { toast } from 'react-hot-toast'
import { Loading } from '../../../../components/Loading'
import { EletricityParams, EletricityResultType } from '../../../../interfaces/ParamEmissonGeneric'
import { months as electMonths} from '../../../../utils/handleUtils'
import { ModalDeleteDataTable } from '../../../../components/ModalDeleteDataTable';

export const ElectricityLocation = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([])
  const [title, setTitle] = useState<string | undefined>(); 
  const [guidelines, setGuidelines] = useState<string | undefined>()
  const [sistemaSin, setSistemaSin] = useState<any>()
  const [sistemaAmazonas, setSistemaAmazonas] = useState<any>()
  const [outrosSistemas, setOutrosSistemas] = useState<any>()
  const [sistemaEvitadas, setSistemaEvitadas] = useState<any>()

  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fuels, setFuels] = useState<any>([])
  const [scopeSin, setScopeSin] = useState<any>([])
  const [openModal, setOpenModal] = useState(false)
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [scopeOther, setScopeOther] = useState<any>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [energyTypes, setEnergyTypes] = useState<any>([])
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingEvideces, setLoadingEvideces] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [scopeAmazonas, setScopeAmazonas] = useState<any>([])
  const [purchaseJanSin, setPurchaseJanSin] = useState<any>()
  const [purchaseFebSin, setPurchaseFebSin] = useState<any>()
  const [purchaseMarSin, setPurchaseMarSin] = useState<any>()
  const [purchaseAprSin, setPurchaseAprSin] = useState<any>()
  const [purchaseMaySin, setPurchaseMaySin] = useState<any>()
  const [purchaseJunSin, setPurchaseJunSin] = useState<any>()
  const [purchaseJulSin, setPurchaseJulSin] = useState<any>()
  const [purchaseAugSin, setPurchaseAugSin] = useState<any>()
  const [purchaseSepSin, setPurchaseSepSin] = useState<any>()
  const [purchaseOctSin, setPurchaseOctSin] = useState<any>()
  const [purchaseNovSin, setPurchaseNovSin] = useState<any>()
  const [purchaseDecSin, setPurchaseDecSin] = useState<any>()
  const [emissionCo2Sin, setEmissionCo2Sin] = useState<any>()
  const [emissionCh4Sin, setEmissionCh4Sin] = useState<any>()
  const [emissionN2oSin, setEmissionN2oSin] = useState<any>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const [emissionCo2Other, setEmissionCo2Other] = useState<any>()
  const [emissionCh4Other, setEmissionCh4Other] = useState<any>()
  const [emissionN2oOther, setEmissionN2oOther] = useState<any>()
  const [purchaseTotalSin, setPurchaseTotalSin] = useState<any>()
  const [purchaseYearlySin, setPurchaseYearlySin] = useState<any>()
  const [emissionTco2eOther, setEmissionTco2eOther] = useState<any>()
  const [inventorySelected, setInventorySelected] = useState<any>({})
  const [purchaseJanAmazonas, setPurchaseJanAmazonas] = useState<any>()
  const [purchaseFebAmazonas, setPurchaseFebAmazonas] = useState<any>()
  const [purchaseMarAmazonas, setPurchaseMarAmazonas] = useState<any>()
  const [purchaseAprAmazonas, setPurchaseAprAmazonas] = useState<any>()
  const [purchaseMayAmazonas, setPurchaseMayAmazonas] = useState<any>()
  const [purchaseJunAmazonas, setPurchaseJunAmazonas] = useState<any>()
  const [purchaseJulAmazonas, setPurchaseJulAmazonas] = useState<any>()
  const [purchaseAugAmazonas, setPurchaseAugAmazonas] = useState<any>()
  const [purchaseSepAmazonas, setPurchaseSepAmazonas] = useState<any>()
  const [purchaseOctAmazonas, setPurchaseOctAmazonas] = useState<any>()
  const [purchaseNovAmazonas, setPurchaseNovAmazonas] = useState<any>()
  const [purchaseDecAmazonas, setPurchaseDecAmazonas] = useState<any>()
  const [emissionCo2Amazonas, setEmissionCo2Amazonas] = useState<any>()
  const [emissionCh4Amazonas, setEmissionCh4Amazonas] = useState<any>()
  const [emissionN2oAmazonas, setEmissionN2oAmazonas] = useState<any>()
  const [emissionTco2eTotals, setEmissionTco2eTotals] = useState<any>()
  const [emissionTco2eBioOther, setEmissionTco2eBioOther] = useState<any>()
  const [changeScopeSin, setChangeScopeSin] = useState<string | undefined>()
  const [purchaseYearlyAmazonas, setPurchaseYearlyAmazonas] = useState<any>()
  const [purchaseTotalAmazonas, setPurchaseTotalAmazonas] = useState<any>()
  const [emissionTco2eBioTotals, setEmissionTco2eBioTotals] = useState<any>()
  const [changeScopeOther, setChangeScopeOther] = useState<string | undefined>()
  const [changeScopeAmazonas, setChangeScopeAmazonas] = useState<string | undefined>()

  const [scopeAvoided, setScopeAvoided] = useState<any>([])
  const [purchaseJanAvoided, setPurchaseJanAvoided] = useState<any>()
  const [purchaseFebAvoided, setPurchaseFebAvoided] = useState<any>()
  const [purchaseMarAvoided, setPurchaseMarAvoided] = useState<any>()
  const [purchaseAprAvoided, setPurchaseAprAvoided] = useState<any>()
  const [purchaseMayAvoided, setPurchaseMayAvoided] = useState<any>()
  const [purchaseJunAvoided, setPurchaseJunAvoided] = useState<any>()
  const [purchaseJulAvoided, setPurchaseJulAvoided] = useState<any>()
  const [purchaseAugAvoided, setPurchaseAugAvoided] = useState<any>()
  const [purchaseSepAvoided, setPurchaseSepAvoided] = useState<any>()
  const [purchaseOctAvoided, setPurchaseOctAvoided] = useState<any>()
  const [purchaseNovAvoided, setPurchaseNovAvoided] = useState<any>()
  const [purchaseDecAvoided, setPurchaseDecAvoided] = useState<any>()
  const [emissionCo2Avoided, setEmissionCo2Avoided] = useState<any>()
  const [emissionCh4Avoided, setEmissionCh4Avoided] = useState<any>()
  const [emissionN2oAvoided, setEmissionN2oAvoided] = useState<any>()
  const [purchaseTotalAvoided, setPurchaseTotalAvoided] = useState<any>()
  const [purchaseYearlyAvoided, setPurchaseYearlyAvoided] = useState<any>()
  const [changeScopeAvoided, setChangeScopeAvoided] = useState<string | undefined>()

  const [openModalDeleteSin, setOpenModalDeleteSin] = useState(false);
  const [openModalDeleteAmazonas, setOpenModalDeleteAmazonas] = useState(false);
  const [openModalDeleteOther, setOpenModalDeleteOther] = useState(false);
  const [openModalDeleteAvoided, setOpenModalDeleteAvoided] = useState(false);

  type ActionFunction = (val: any) => void;

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-2'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [location])

  async function getGuidelines() {
    const item = await scopeService.getBySlug(2, 'eletricidade_localizacao')

    setTitle(item.label)
    setGuidelines(item.guidelines)

    const extra = await scopeService.getByAllSlug(2, 'eletricidade_localizacao');

    setSistemaSin(extra.sistema_sin)
    setSistemaAmazonas(extra.sistema_amazonas)
    setOutrosSistemas(extra.outros_sistemas)
    setSistemaEvitadas(extra.sistema_evitadas)
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<any>()

  useEffect(() => {
    const totalsCo2 =
      (emissionCo2Sin ? emissionCo2Sin : 0) +
      (emissionCo2Amazonas ? emissionCo2Amazonas : 0) +
      (emissionCh4Other ? emissionCh4Other : 0) +
      (emissionTco2eOther ? emissionTco2eOther : 0)

    setEmissionTco2eTotals(totalsCo2)
    setEmissionTco2eBioTotals(emissionTco2eBioOther)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [changeScopeSin])

  useEffect(() => {
    const totalsCo2 =
      (emissionCo2Sin ? emissionCo2Sin : 0) +
      (emissionCo2Amazonas ? emissionCo2Amazonas : 0) +
      (emissionCh4Other ? emissionCh4Other : 0) +
      (emissionTco2eOther ? emissionTco2eOther : 0)

    setEmissionTco2eTotals(totalsCo2)
    setEmissionTco2eBioTotals(emissionTco2eBioOther)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [changeScopeAmazonas])

  useEffect(() => {
    const totalsCo2 =
      (emissionCo2Sin ? emissionCo2Sin : 0) +
      (emissionCo2Amazonas ? emissionCo2Amazonas : 0) +
      (emissionCh4Other ? emissionCh4Other : 0) +
      (emissionTco2eOther ? emissionTco2eOther : 0)

    setEmissionTco2eTotals(totalsCo2)
    setEmissionTco2eBioTotals(emissionTco2eBioOther)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [changeScopeOther])

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)
    if (formData) {

      if(openModalDeleteSin){
        setLoadingModal(false);
        return;
      }

      if(openModalDeleteAmazonas){
        setLoadingModal(false);
        return;
      }

      if(openModalDeleteOther){
        setLoadingModal(false);
        return;
      }

      if(openModalDeleteAvoided){
        setLoadingModal(false);
        return;
      }

      const sin: any[] = scopeSin.map((scope: any, index: number) => {
        return {
          source: scope.source,
          description: scope.description,
          purchase_jan: scope.purchase_jan ? scope.purchase_jan : 0,
          purchase_feb: scope.purchase_feb ? scope.purchase_feb : 0,
          purchase_mar: scope.purchase_mar ? scope.purchase_mar : 0,
          purchase_apr: scope.purchase_apr ? scope.purchase_apr : 0,
          purchase_may: scope.purchase_may ? scope.purchase_may : 0,
          purchase_jun: scope.purchase_jun ? scope.purchase_jun : 0,
          purchase_jul: scope.purchase_jul ? scope.purchase_jul : 0,
          purchase_aug: scope.purchase_aug ? scope.purchase_aug : 0,
          purchase_sep: scope.purchase_sep ? scope.purchase_sep : 0,
          purchase_oct: scope.purchase_oct ? scope.purchase_oct : 0,
          purchase_nov: scope.purchase_nov ? scope.purchase_nov : 0,
          purchase_dec: scope.purchase_dec ? scope.purchase_dec : 0,
          emission_co2: scope.emission_co2 ? scope.emission_co2 : 0,
          emission_ch4: scope.emission_ch4 ? scope.emission_ch4 : 0,
          emission_n2o: scope.emission_n2o ? scope.emission_n2o : 0,
          purchase_total: scope.purchase_total ? scope.purchase_total : 0,
          purchase_yearly: scope.purchase_yearly ? scope.purchase_yearly : 0,
          emission_co2_jan: scope.emission_co2_jan ? scope.emission_co2_jan : 0,
          emission_co2_feb: scope.emission_co2_feb ? scope.emission_co2_feb : 0,
          emission_co2_mar: scope.emission_co2_mar ? scope.emission_co2_mar : 0,
          emission_co2_apr: scope.emission_co2_apr ? scope.emission_co2_apr : 0,
          emission_co2_may: scope.emission_co2_may ? scope.emission_co2_may : 0,
          emission_co2_jun: scope.emission_co2_jun ? scope.emission_co2_jun : 0,
          emission_co2_jul: scope.emission_co2_jul ? scope.emission_co2_jul : 0,
          emission_co2_aug: scope.emission_co2_aug ? scope.emission_co2_aug : 0,
          emission_co2_sep: scope.emission_co2_sep ? scope.emission_co2_sep : 0,
          emission_co2_oct: scope.emission_co2_oct ? scope.emission_co2_oct : 0,
          emission_co2_nov: scope.emission_co2_nov ? scope.emission_co2_nov : 0,
          emission_co2_dec: scope.emission_co2_dec ? scope.emission_co2_dec : 0
        }
      })


      const amazonas: any[] = scopeAmazonas.map((scope: any, index: number) => {
        return {
          source: scope.source,
          description: scope.description,
          purchase_jan: scope.purchase_jan ? scope.purchase_jan : 0,
          purchase_feb: scope.purchase_feb ? scope.purchase_feb : 0,
          purchase_mar: scope.purchase_mar ? scope.purchase_mar : 0,
          purchase_apr: scope.purchase_apr ? scope.purchase_apr : 0,
          purchase_may: scope.purchase_may ? scope.purchase_may : 0,
          purchase_jun: scope.purchase_jun ? scope.purchase_jun : 0,
          purchase_jul: scope.purchase_jul ? scope.purchase_jul : 0,
          purchase_aug: scope.purchase_aug ? scope.purchase_aug : 0,
          purchase_sep: scope.purchase_sep ? scope.purchase_sep : 0,
          purchase_oct: scope.purchase_oct ? scope.purchase_oct : 0,
          purchase_nov: scope.purchase_nov ? scope.purchase_nov : 0,
          purchase_dec: scope.purchase_dec ? scope.purchase_dec : 0,
          emission_co2: scope.emission_co2 ? scope.emission_co2 : 0,
          emission_ch4: scope.emission_ch4 ? scope.emission_ch4 : 0,
          emission_n2o: scope.emission_n2o ? scope.emission_n2o : 0,
          purchase_totals: scope.purchase_totals ? scope.purchase_totals : 0,
          purchase_yearly: scope.purchase_yearly ? scope.purchase_yearly : 0,
          emission_co2_jan: scope.emission_co2_jan ? scope.emission_co2_jan : 0,
          emission_co2_feb: scope.emission_co2_feb ? scope.emission_co2_feb : 0,
          emission_co2_mar: scope.emission_co2_mar ? scope.emission_co2_mar : 0,
          emission_co2_apr: scope.emission_co2_apr ? scope.emission_co2_apr : 0,
          emission_co2_may: scope.emission_co2_may ? scope.emission_co2_may : 0,
          emission_co2_jun: scope.emission_co2_jun ? scope.emission_co2_jun : 0,
          emission_co2_jul: scope.emission_co2_jul ? scope.emission_co2_jul : 0,
          emission_co2_aug: scope.emission_co2_aug ? scope.emission_co2_aug : 0,
          emission_co2_sep: scope.emission_co2_sep ? scope.emission_co2_sep : 0,
          emission_co2_oct: scope.emission_co2_oct ? scope.emission_co2_oct : 0,
          emission_co2_nov: scope.emission_co2_nov ? scope.emission_co2_nov : 0,
          emission_co2_dec: scope.emission_co2_dec ? scope.emission_co2_dec : 0
        }
      })

      const other: any[] = scopeOther.map((scope: any, index: number) => {
        return {
          source: scope.source,
          description: scope.description,
          emission_co2: scope.emission_co2 ? scope.emission_co2 : 0,
          emission_ch4: scope.emission_ch4 ? scope.emission_ch4 : 0,
          emission_n2o: scope.emission_n2o ? scope.emission_n2o : 0,
          emission_co2_t: scope.emission_co2_t ? scope.emission_co2_t : 0,
          emission_bio_co2_t: scope.emission_bio_co2_t ? scope.emission_bio_co2_t : 0
        }
      })

      const avoided: any[] = scopeAvoided.map((scope: any, index: number) => {
        return {
          source: scope.source,
          description: scope.description,
          purchase_jan: scope.purchase_jan ? scope.purchase_jan : 0,
          purchase_feb: scope.purchase_feb ? scope.purchase_feb : 0,
          purchase_mar: scope.purchase_mar ? scope.purchase_mar : 0,
          purchase_apr: scope.purchase_apr ? scope.purchase_apr : 0,
          purchase_may: scope.purchase_may ? scope.purchase_may : 0,
          purchase_jun: scope.purchase_jun ? scope.purchase_jun : 0,
          purchase_jul: scope.purchase_jul ? scope.purchase_jul : 0,
          purchase_aug: scope.purchase_aug ? scope.purchase_aug : 0,
          purchase_sep: scope.purchase_sep ? scope.purchase_sep : 0,
          purchase_oct: scope.purchase_oct ? scope.purchase_oct : 0,
          purchase_nov: scope.purchase_nov ? scope.purchase_nov : 0,
          purchase_dec: scope.purchase_dec ? scope.purchase_dec : 0,
          emission_co2: scope.emission_co2 ? scope.emission_co2 : 0,
          emission_ch4: scope.emission_ch4 ? scope.emission_ch4 : 0,
          emission_n2o: scope.emission_n2o ? scope.emission_n2o : 0,
          purchase_total: scope.purchase_total ? scope.purchase_total : 0,
          purchase_yearly: scope.purchase_yearly ? scope.purchase_yearly : 0,
          emission_co2_jan: scope.emission_co2_jan ? scope.emission_co2_jan : 0,
          emission_co2_feb: scope.emission_co2_feb ? scope.emission_co2_feb : 0,
          emission_co2_mar: scope.emission_co2_mar ? scope.emission_co2_mar : 0,
          emission_co2_apr: scope.emission_co2_apr ? scope.emission_co2_apr : 0,
          emission_co2_may: scope.emission_co2_may ? scope.emission_co2_may : 0,
          emission_co2_jun: scope.emission_co2_jun ? scope.emission_co2_jun : 0,
          emission_co2_jul: scope.emission_co2_jul ? scope.emission_co2_jul : 0,
          emission_co2_aug: scope.emission_co2_aug ? scope.emission_co2_aug : 0,
          emission_co2_sep: scope.emission_co2_sep ? scope.emission_co2_sep : 0,
          emission_co2_oct: scope.emission_co2_oct ? scope.emission_co2_oct : 0,
          emission_co2_nov: scope.emission_co2_nov ? scope.emission_co2_nov : 0,
          emission_co2_dec: scope.emission_co2_dec ? scope.emission_co2_dec : 0
        }
      })
      
      const scope = {
        status: 2,
        reason: formData.reason,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        amazonas: amazonas,
        other: other,
        sin: sin,
        avoided: avoided
      }

      const result = await scope2Service.saveElectricityLocation(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('ElectricityLocation', JSON.stringify(scope)); 
        
        setErrorApi(undefined)
        
        toast.success("Operação realizada com sucesso!")
      }
      
      if (result.error) {
        setErrorApi(result.error)
      }
    }
    
    setLoadingModal(false)
  }
  
  const { isCommonUser } = useAuth()

  const handleGetFiles = async (inventory: any) => {
    if(inventory){
      setLoadingEvideces(true)
      const result = await inventoryService.getFiles(
        inventory.company.id,
        inventory.year,
        2,
        'eletricidade_localizacao'
      )
      setFiles(result.files)
      setLoadingEvideces(false)
    }
  }

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)
    
    const energyTypesItens = await energyTypeService.getByParams({ limit: 9000, page: 1 })
    
    const energyTypes = energyTypesItens?.itens.map((type: any) => {
      return {
        label: type.name,
        value: type.id
      }
    })
    
    setEnergyTypes(energyTypes)
    
    const fuels = await fuelService.getByParams({ limit: 9000, page: 1 })
    
    setFuels(fuels?.itens)
    
    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope2Service.getElectricityLocation(company_id, year)    
      if (entity) {
        setValue('reason', entity.reason)
        setValue('evidence', entity.evidence)
        setValue('ignore_fill', entity.ignore_fill)
        
        setScopeSelected(entity)
        
        for (const item of entity?.sin) {
          item.only_view_yearly =
          item.purchase_jan > 0 ||
          item.purchase_feb > 0 ||
          item.purchase_mar > 0 ||
          item.purchase_apr > 0 ||
          item.purchase_may > 0 ||
          item.purchase_jun > 0 ||
          item.purchase_jul > 0 ||
          item.purchase_aug > 0 ||
          item.purchase_sep > 0 ||
          item.purchase_oct > 0 ||
          item.purchase_nov > 0 ||
          item.purchase_dec > 0

        item.only_view_monthly = item.purchase_yearly > 0
      }

      for (const item of entity?.amazonas) {
        item.only_view_yearly =
          item.purchase_jan > 0 ||
          item.purchase_feb > 0 ||
          item.purchase_mar > 0 ||
          item.purchase_apr > 0 ||
          item.purchase_may > 0 ||
          item.purchase_jun > 0 ||
          item.purchase_jul > 0 ||
          item.purchase_aug > 0 ||
          item.purchase_sep > 0 ||
          item.purchase_oct > 0 ||
          item.purchase_nov > 0 ||
          item.purchase_dec > 0

        item.only_view_monthly = item.purchase_yearly > 0
      }

      for (const item of entity?.avoided) {
        item.only_view_yearly =
          item.purchase_jan > 0 ||
          item.purchase_feb > 0 ||
          item.purchase_mar > 0 ||
          item.purchase_apr > 0 ||
          item.purchase_may > 0 ||
          item.purchase_jun > 0 ||
          item.purchase_jul > 0 ||
          item.purchase_aug > 0 ||
          item.purchase_sep > 0 ||
          item.purchase_oct > 0 ||
          item.purchase_nov > 0 ||
          item.purchase_dec > 0

        item.only_view_monthly = item.purchase_yearly > 0
      }
      
      
      setScopeValuesSin(entity?.sin)
      calculateTotalsSin(entity?.sin)

      setScopeValuesOther(entity?.other)
      calculateTotalsOther(entity?.other)

      setScopeValuesAmazonas(entity?.amazonas)
      calculateTotalsAmazonas(entity?.amazonas)

      setScopeValuesAvoided(entity?.avoided)
      calculateTotalsAvoided(entity?.avoided)

      setIgnoreFill(entity.ignore_fill)
      setChangeScopeSin('load_scope_' + entity.id + '_scopes_' + entity?.sin.length)
      setChangeScopeOther('load_scope_' + entity.id + '_scopes_' + entity?.other.length)
      setChangeScopeAmazonas('load_scope_' + entity.id + '_scopes_' + entity?.amazonas.length)
      setChangeScopeAvoided('load_scope_' + entity.id + '_scopes_' + entity?.avoided.length)
    } else {
      setScopeSelected({})
      setIgnoreFill(false)

      setScopeValuesSin([])
      calculateTotalsSin([])

      setScopeValuesOther([])
      calculateTotalsOther([])

      setScopeValuesAmazonas([])
      calculateTotalsAmazonas([])

      setScopeValuesAvoided([])
      calculateTotalsAvoided([])

      setChangeScopeSin('load_scope_0_scopes_0')
      setChangeScopeOther('load_scope_0_scopes_0')
      setChangeScopeAmazonas('load_scope_0_scopes_0')
      setChangeScopeAvoided('load_scope_0_scopes_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
    await handleGetFiles(inventory) // ING-119
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{5})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos

    // Verifica se o valor é igual a zero após a formatação e remove os zeros à esquerda
    if (value === '') {
        value = '0';
    }

    return value;
  }

  function handleDeleteScope(index: number, scope: any[], setChangeScope: ActionFunction, setScopeValues: ActionFunction, calculateTotals: ActionFunction) {
    const items = scope?.filter((item: any, i: number) => i !== index);

    setChangeScope('remove_scope_item_' + index);
    
    setValue('energyTypes', []);

    setScopeValues(items);
    calculateTotals(items);
  }

  function handleDeleteScopeSin(index: number) {
    handleDeleteScope(index, scopeSin, setChangeScopeSin, setScopeValuesSin, calculateTotalsSin);
  }

  function handleDeleteScopeOther(index: number) {
    handleDeleteScope(index, scopeOther, setChangeScopeOther, setScopeValuesOther, calculateTotalsOther);
  }

  function handleDeleteScopeAmazonas(index: number) {
    handleDeleteScope(index, scopeAmazonas, setChangeScopeAmazonas, setScopeValuesAmazonas, calculateTotalsAmazonas);
  }

  function handleDeleteScopeAvoided(index: number) {
    handleDeleteScope(index, scopeAvoided, setChangeScopeAvoided, setScopeValuesAvoided, calculateTotalsAvoided);
  }

  function handleOpenModalDeleteSin(){
    setOpenModalDeleteSin(true);
  }

  function handleOpenModalDeleteAmazonas(){
    setOpenModalDeleteAmazonas(true);
  }

  function handleOpenModalDeleteOther(){
    setOpenModalDeleteOther(true);
  }

  function handleOpenModalDeleteAvoided(){
    setOpenModalDeleteAvoided(true);
  }

  function handleDeleteAllScopeSin(){
    const itens: any = [];

    setChangeScopeSin('remove_all_scopes_items')
    setValue('energyTypes', []);

    setScopeValuesSin(itens);
    calculateTotalsSin(itens);

    setOpenModalDeleteSin(false);
  }

  function handleDeleteAllScopeAmazonas(){
    const itens: any = [];

    setChangeScopeAmazonas('remove_all_scopes_items')
    setValue('energyTypes', []);

    setScopeValuesAmazonas(itens);
    calculateTotalsAmazonas(itens);

    setOpenModalDeleteAmazonas(false);
  }

  function handleDeleteAllScopeOther(){
    const itens: any = [];

    setChangeScopeOther('remove_all_scopes_items')
    setValue('energyTypes', []);

    setScopeValuesOther(itens);
    calculateTotalsOther(itens);

    setOpenModalDeleteOther(false);
  }

  function handleDeleteAllScopeAvoided(){
    const itens: any = [];

    setChangeScopeAvoided('remove_all_scopes_items')
    setValue('energyTypes', []);

    setScopeValuesAvoided(itens);
    calculateTotalsAvoided(itens);

    setOpenModalDeleteAvoided(false);
  }

  const sumProperty = (items: any[], property: string) => {
    return items.reduce((sum, item) => sum + (item[property] || 0), 0);
  };
  
  const calculateTotalsSin = (scopes: any) => {
    if (!scopes) return;
  
    const properties = [
      'purchase_jan', 'purchase_feb', 'purchase_mar', 'purchase_apr',
      'purchase_may', 'purchase_jun', 'purchase_jul', 'purchase_aug',
      'purchase_sep', 'purchase_oct', 'purchase_nov', 'purchase_dec',
      'emission_co2', 'emission_ch4', 'emission_n2o',
      'purchase_total', 'purchase_yearly'
    ];
  
    const sums = Object.fromEntries(properties.map(prop => [prop, sumProperty(scopes, prop)]));
  
    setPurchaseJanSin(sums.purchase_jan);
    setPurchaseFebSin(sums.purchase_feb);
    setPurchaseMarSin(sums.purchase_mar);
    setPurchaseAprSin(sums.purchase_apr);
    setPurchaseMaySin(sums.purchase_may);
    setPurchaseJunSin(sums.purchase_jun);
    setPurchaseJulSin(sums.purchase_jul);
    setPurchaseAugSin(sums.purchase_aug);
    setPurchaseSepSin(sums.purchase_sep);
    setPurchaseOctSin(sums.purchase_oct);
    setPurchaseNovSin(sums.purchase_nov);
    setPurchaseDecSin(sums.purchase_dec); 
    setEmissionCo2Sin(sums.emission_co2);
    setEmissionCh4Sin(sums.emission_ch4);
    setEmissionN2oSin(sums.emission_n2o);
    setPurchaseTotalSin(sums.purchase_total);
    setPurchaseYearlySin(sums.purchase_yearly);
  
    const totalsCo2 = sums.emission_co2 + (emissionCo2Amazonas || 0) + (emissionTco2eOther || 0);
  
    setEmissionTco2eTotals(totalsCo2);
    setEmissionTco2eBioTotals(emissionTco2eBioOther);
  };
    
  const calculateTotalsAmazonas = (scopes: any) => {
    
    if (!scopes) return;
  
    const properties = [
      'purchase_jan', 'purchase_feb', 'purchase_mar', 'purchase_apr',
      'purchase_may', 'purchase_jun', 'purchase_jul', 'purchase_aug',
      'purchase_sep', 'purchase_oct', 'purchase_nov', 'purchase_dec',
      'emission_co2', 'emission_ch4', 'emission_n2o',
      'purchase_yearly'
    ];
  
    const sums = Object.fromEntries(properties.map(prop => [prop, sumProperty(scopes, prop)]));

    setPurchaseJanAmazonas(sums.purchase_jan);
    setPurchaseFebAmazonas(sums.purchase_feb);
    setPurchaseMarAmazonas(sums.purchase_mar);
    setPurchaseAprAmazonas(sums.purchase_apr);
    setPurchaseMayAmazonas(sums.purchase_may);
    setPurchaseJunAmazonas(sums.purchase_jun);
    setPurchaseJulAmazonas(sums.purchase_jul);
    setPurchaseAugAmazonas(sums.purchase_aug);
    setPurchaseSepAmazonas(sums.purchase_sep);
    setPurchaseOctAmazonas(sums.purchase_oct);
    setPurchaseNovAmazonas(sums.purchase_nov);
    setPurchaseDecAmazonas(sums.purchase_dec); 
    setEmissionCo2Amazonas(sums.emission_co2);
    setEmissionCh4Amazonas(sums.emission_ch4);
    setEmissionN2oAmazonas(sums.emission_n2o);
  
    const purchaseTotals = sums.purchase_yearly +
      sums.purchase_jan + sums.purchase_feb + sums.purchase_mar +
      sums.purchase_apr + sums.purchase_may + sums.purchase_jun +
      sums.purchase_jul + sums.purchase_aug + sums.purchase_sep +
      sums.purchase_oct + sums.purchase_nov + sums.purchase_dec;
  
    setPurchaseTotalAmazonas(purchaseTotals);
    setPurchaseYearlyAmazonas(sums.purchase_yearly);

    const totalsCo2 = sums.emission_co2 + (emissionCo2Sin || 0) + (emissionTco2eOther || 0); 

    setEmissionTco2eTotals(totalsCo2);
    setEmissionTco2eBioTotals(emissionTco2eBioOther);
  };
  
  const calculateTotalsOther = (scopes: any) => {
    if (!scopes) return;
  
    const properties = [
      'emission_co2', 'emission_ch4', 'emission_n2o',
      'emission_co2_t', 'emission_bio_co2_t'
    ];
  
    const sums = Object.fromEntries(properties.map(prop => [prop, sumProperty(scopes, prop)]));
  
    setEmissionCo2Other(sums.emission_co2);
    setEmissionCh4Other(sums.emission_ch4);
    setEmissionN2oOther(sums.emission_n2o);
    setEmissionTco2eOther(sums.emission_co2_t);
    setEmissionTco2eBioOther(sums.emission_bio_co2_t);
  
    const totalsCo2 = (emissionCo2Sin || 0) + (emissionCo2Amazonas || 0) + sums.emission_co2;
  
    setEmissionTco2eTotals(totalsCo2);
    setEmissionTco2eBioTotals(sums.emission_bio_co2_t);
  };  

  const calculateTotalsAvoided = (scopes: any) => {
    if (!scopes) return;

    const properties = [
      'purchase_jan', 'purchase_feb', 'purchase_mar', 'purchase_apr',
      'purchase_may', 'purchase_jun', 'purchase_jul', 'purchase_aug',
      'purchase_sep', 'purchase_oct', 'purchase_nov', 'purchase_dec',
      'emission_co2', 'emission_ch4', 'emission_n2o',
      'purchase_total', 'purchase_yearly'
    ];

    const sums = Object.fromEntries(properties.map(prop => [prop, sumProperty(scopes, prop)]));

    setPurchaseJanAvoided(sums.purchase_jan);
    setPurchaseFebAvoided(sums.purchase_feb);
    setPurchaseMarAvoided(sums.purchase_mar);
    setPurchaseAprAvoided(sums.purchase_apr);
    setPurchaseMayAvoided(sums.purchase_may);
    setPurchaseJunAvoided(sums.purchase_jun);
    setPurchaseJulAvoided(sums.purchase_jul);
    setPurchaseAugAvoided(sums.purchase_aug);
    setPurchaseSepAvoided(sums.purchase_sep);
    setPurchaseOctAvoided(sums.purchase_oct);
    setPurchaseNovAvoided(sums.purchase_nov);
    setPurchaseDecAvoided(sums.purchase_dec);
    setEmissionCo2Avoided(sums.emission_co2);
    setEmissionCh4Avoided(sums.emission_ch4);
    setEmissionN2oAvoided(sums.emission_n2o);
    setPurchaseTotalAvoided(sums.purchase_total);
    setPurchaseYearlyAvoided(sums.purchase_yearly);

    const totalsCo2 = sums.emission_co2 + (emissionCo2Amazonas || 0) + (emissionTco2eOther || 0) + (emissionCo2Sin || 0);

    // setEmissionTco2eTotals(totalsCo2);
    // setEmissionTco2eBioTotals(emissionTco2eBioOther);
  };


  const handleChangeValueSin = (index: number, ignore_field?: string) => {
    handleChangeValueGeneric(scopeSin, index, ignore_field, 
                             scope2Service.calculateElectricityLocationSin, 
                             setChangeScopeSin, setScopeValuesSin, calculateTotalsSin);
  }  
  const handleChangeValueAmazonas = (index: number, ignore_field?: string) => {
    handleChangeValueGeneric(scopeAmazonas, index, ignore_field, 
                             scope2Service.calculateElectricityLocationAmazonas, 
                             setChangeScopeAmazonas, setScopeValuesAmazonas, calculateTotalsAmazonas);
  }  

  const handleChangeValueAvoided = (index: number, ignore_field?: string) => {
    handleChangeValueGeneric(scopeAvoided, index, ignore_field,
      scope2Service.calculateElectricityLocationAvoided,
      setChangeScopeAvoided, setScopeValuesAvoided, calculateTotalsAvoided);
  }


  const handleChangeValueGeneric = (
    scope: any[], 
    index: number, 
    ignore_field: string | undefined,
    calculateElectricity: (params: EletricityParams) => Promise<EletricityResultType>, 
    setChangeScope: (value: string) => void,
    setScopeValues: (value: any[], field: string | undefined) => void,
    calculateTotals: (value: any[]) => void) => {
    const electricityParams: EletricityParams = electMonths.reduce((acc, month) => {
      (acc as any)[`purchase_${month}`] = parseFloat(scope[index][`purchase_${month}`]) || 0;
      return acc;
    }, {} as EletricityParams);
  
    const onlyViewYearly = Object.values(electricityParams).some(value => value > 0);
    const purchase_yearly = onlyViewYearly ? 0 : parseFloat(scope[index].purchase_yearly);
  
    electricityParams.purchase_yearly = purchase_yearly;
    electricityParams.year = inventorySelected.year
  
    const onlyViewMonthly = purchase_yearly > 0;
  
    async function calculate() {
      const result = await calculateElectricity(electricityParams);
  
      if (result) {
        scope[index] = { ...scope[index], ...result, only_view_yearly: onlyViewYearly, only_view_monthly: onlyViewMonthly };
  
        setChangeScope(
          'change_scope_item_' +
          index +
          '_' +
          Object.values(electricityParams).join('_')
        );
        setScopeValues(scope, ignore_field);
        calculateTotals(scope);
      }
    }
    calculate();
  }  

  const handleChangeValueOther = (index: number, ignore_field?: string) => {
    const electricityLocationParams: EletricityParams = {
      emission_co2: parseFloat(scopeOther[index].emission_co2 || "0"),
      emission_ch4: parseFloat(scopeOther[index].emission_ch4 || "0"),
      emission_n2o: parseFloat(scopeOther[index].emission_n2o || "0"),
    };
  
    async function calculate() {
      const result = await scope2Service.calculateElectricityLocationOther(electricityLocationParams);
  
      if (result) {
        scopeOther[index].emission_co2_t = result.emission_co2_t
  
        setChangeScopeOther(
          'change_scope_item_' +
          index +
          '_' +
          Object.values(electricityLocationParams).join('_')
        );
  
        setScopeValuesOther(scopeOther, ignore_field);
        calculateTotalsOther(scopeOther);
      }
    }
    calculate();
  }  

  const sinFields = [
    ...electMonths.map(month => `purchase_${month}`),
    ...electMonths.map(month => `emission_co2_${month}`),
    ...electMonths.map(month => `emission_ch4_${month}`),
    ...electMonths.map(month => `emission_n2o_${month}`),
    'purchase_yearly', 'purchase_total', 'emission_co2', 'emission_ch4', 'emission_n2o'
  ];  

  const setScopeValuesSin = (itens: any, ignore_field?: string) => {
    setScopeValuesGeneric(itens, 'scopeSin', sinFields, ignore_field);
  };  

  const amazonasFields = [
    ...electMonths.map(month => `purchase_${month}`),
    ...electMonths.map(month => `emission_co2_${month}`),
    ...electMonths.map(month => `emission_ch4_${month}`),
    ...electMonths.map(month => `emission_n2o_${month}`),
    'purchase_yearly', 'purchase_totals', 'emission_co2', 'emission_ch4', 'emission_n2o'
  ];  

  const setScopeValuesAmazonas = (itens: any, ignore_field?: string) => {
    setScopeValuesGeneric(itens, 'scopeAmazonas', amazonasFields, ignore_field);
  }

  const avoidedFields = [
    ...electMonths.map(month => `purchase_${month}`),
    ...electMonths.map(month => `emission_co2_${month}`),
    ...electMonths.map(month => `emission_ch4_${month}`),
    ...electMonths.map(month => `emission_n2o_${month}`),
    'purchase_yearly', 'purchase_total', 'emission_co2', 'emission_ch4', 'emission_n2o'
  ];

  const setScopeValuesAvoided = (itens: any, ignore_field?: string) => {
    setScopeValuesGeneric(itens, 'scopeAvoided', avoidedFields, ignore_field);
  };

  const otherFields = ['emission_co2', 'emission_ch4', 'emission_n2o', 'emission_co2_t', 'emission_bio_co2_t'];
  const setScopeValuesOther = (itens: any, ignore_field?: string) => {
    setScopeValuesGeneric(itens, 'scopeOther', otherFields, ignore_field);
  };

  const setScopeValuesGeneric = (itens: any, scopeType: string, fields: string[], ignore_field?: string) => {
    let changeScope = 'change_scope_item_';

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i];

        setValue(`${scopeType}.${i}.source`, item.source);
        setValue(`${scopeType}.${i}.description`, item.description);

        fields.forEach(field => {
          if (field !== ignore_field) {
            if (electMonths.some(month => field.includes(month))) {
              setValue(`${scopeType}.${i}.${field}`, field.startsWith('purchase_') ? formatPrice5
               (item[field], false) : item[field]);
            } else {
              setValue(`${scopeType}.${i}.${field}`, formatPrice5
                (item[field], false));
            }
          }
        });

        if (fields.includes('purchase_yearly')) {
          changeScope += `_${item.car_fleet_id}_${item.year}_${item.purchase_yearly}`;
        } else {
          const fieldValues = fields.map(field => item[field]).join("_");
          changeScope += `_${item.car_fleet_id}_${item.year}_${fieldValues}`;
        }
      }

      if(scopeType === 'scopeSin') setScopeSin(itens);
      if(scopeType === 'scopeOther') setScopeOther(itens);
      if(scopeType === 'scopeAmazonas') setScopeAmazonas(itens);
      if(scopeType === 'scopeAvoided') setScopeAvoided(itens);

      if(scopeType === 'scopeSin') setChangeScopeSin(changeScope);
      if(scopeType === 'scopeOther') setChangeScopeOther(changeScope);
      if(scopeType === 'scopeAmazonas') setChangeScopeAmazonas(changeScope);
      if(scopeType === 'scopeAvoided') setChangeScopeAvoided(changeScope);

    }
  };

  const handleNewItemSin = () => {
    const item = {
      source: undefined,
      description: undefined,
      only_view_yearly: false, 
      only_view_monthly: false
    }

    const index = scopeSin.length

    const itens = scopeSin.concat(item)

    setChangeScopeSin('new_scope_item_' + index)

    setScopeValuesSin(itens)
    calculateTotalsSin(itens)
  }

  const handleNewItemAmazonas = () => {
    const item = {
      source: undefined,
      description: undefined,
      only_view_yearly: false, 
      only_view_monthly: false
    }

    const index = scopeAmazonas.length

    const itens = scopeAmazonas.concat(item)

    setChangeScopeAmazonas('new_scope_item_' + index)

    setScopeValuesAmazonas(itens)
    calculateTotalsAmazonas(itens)
  }

  const handleNewItemAvoided = () => {
    const item = {
      source: undefined,
      description: undefined,
      only_view_yearly: false,
      only_view_monthly: false
    }

    const index = scopeAvoided.length

    const itens = scopeAvoided.concat(item)

    setChangeScopeAvoided('new_scope_item_' + index)

    setScopeValuesAvoided(itens)
    calculateTotalsAvoided(itens)
  }


  const handleNewItemOther = () => {
    const item = {
      source: undefined,
      description: undefined,
      only_view_yearly: false
    }

    const index = scopeOther.length

    const itens = scopeOther.concat(item)

    setChangeScopeOther('new_scope_item_' + index)

    setScopeValuesOther(itens)
    calculateTotalsOther(itens)
  }

  const handleDownloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          2,
          'eletricidade_localizacao'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const handleAttachmentChange = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 2,
        name: 'eletricidade_localizacao',
        file: base64,
        filename: filename,
        second_name: ''
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          2,
          'eletricidade_localizacao'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          {inventorySelected?.year && inventorySelected?.company && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
              <TitleText level={4} title={'Escopo 02 - ' + title}  />
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        {!ignoreFill && (
          <div
            style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
          />
        )}

        {!ignoreFill && (
          <div
            style={{
              overflow: 'hidden auto',
              position: 'relative',
              marginTop: 0,
              height: 'calc(100vh - 200px)',
              maxHeight: 'calc(100vh - 200px)'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 10,
                justifyContent: 'space-between',
                marginBottom: 0
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                {sistemaSin && sistemaSin.label && (
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginTop: 5
                    }}
                  >
                    {sistemaSin.label}
                  </span>
                )}
                <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5}} >
                  Clique, ao lado em novo registro, para adicionar nova linha
                </span>
              </div>

              {sistemaSin && sistemaSin.guidelines && (
                <div className="tooltip" style={{ paddingTop: 10 }}>
                  <img src={InfoButtonMin} alt="information" style={{ paddingTop: 10 }} />
                  <span className="tooltiptext">{sistemaSin.guidelines}</span>
                </div>
              )}

              <div style={{ textAlign: 'right', marginRight: 20, width: '30%' }}>
                {!isCommonUser && (
                  <button
                    type="button"
                    style={{
                      width: '160px',
                      height: '47px',
                      background: '#ffffff',
                      color: '#31363F',
                      borderColor: '#9E9E9E',
                      borderRadius: '2.5px',
                      cursor: 'pointer',
                      borderStyle: 'dashed',
                      fontWeight: 'bold',
                      borderWidth: '1px'
                    }}
                    onClick={() => handleNewItemSin()}
                  >
                    {`Novo registro`}
                  </button>
                )}
              </div>
            </div>

            <div
              style={{
                overflow: 'auto',
                position: 'relative',
                marginTop: 20,
                width: 'calc(100vw - 315px)',
                maxHeight: 'calc(100vh - 360px)'
              }}
            >
              {changeScopeSin && scopeSin && scopeSin.length > 0 && (
                <table
                  style={{
                    maxWidth: '100%',
                    width: '4000px',
                    borderCollapse: 'collapse'
                  }}
                  className="table-scope"
                >
                  <thead>
                    <tr>
                      <th className="head-tr-item" style={{ width: 360 }} colSpan={2}>
                        {'Fonte'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} colSpan={13}>
                        {'Eletricidade Comprada (MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 250 }}
                        rowSpan={3}
                      >
                        {'Eletricidade total comprada (MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {'Emissões de CO₂ (t)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {'Emissões de CH₄ (t)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {'Emissões de N₂O (t)'}
                      </th>
                      {!isCommonUser && (
                        <th className="head-tr-item" style={{ width: 50 }} rowSpan={3} >
                          {!isCommonUser && (
                            <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                              <div onClick={handleOpenModalDeleteSin}>
                                <button style={{background: '#b81414', color: 'white', borderRadius: 5, cursor:'pointer'}}>Apagar tudo</button>
                              </div>
                            </td>
                          )}
                        </th>
                      )}
                    </tr>
                    <tr>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                      >
                        {'Registro'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                      >
                        {'Descrição'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} colSpan={12}>
                        {'Relate aqui a compra mensal de eletricidade (MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 250 }}
                        rowSpan={2}
                      >
                        {'Relate aqui a compra anual de eletricidade (MWh)'}
                      </th>
                    </tr>
                    <tr>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Janeiro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Fevereiro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Março'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Abril'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Maio'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Junho'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Julho'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Agosto'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Setembro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Outubro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Novembro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Dezembro'}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {scopeSin.map((item: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 200 }}
                                className="input-item"
                                {...register(`scopeSin.${index}.source`, {
                                  required: true
                                })}
                                onChange={(event) => {
                                  setValue(`scopeSin.${index}.source`, event.target.value)
                                  scopeSin[index].source = event.target.value
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 200 }}
                                className="input-item"
                                {...register(`scopeSin.${index}.description`, {
                                  required: true
                                })}
                                onChange={(event) => {
                                  setValue(
                                    `scopeSin.${index}.description`,
                                    event.target.value
                                  )
                                  scopeSin[index].description = event.target.value
                                }}
                              />
                            </td>
                            <td>
                              {scopeSin[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeSin.${index}.purchase_jan`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')): 0

                                    scopeSin[index].purchase_jan = value

                                    setValue(`scopeSin.${index}.purchase_jan`, formatValue)

                                    handleChangeValueSin(index, 'purchase_jan')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeSin[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeSin.${index}.purchase_feb`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeSin[index].purchase_feb = value

                                    setValue(`scopeSin.${index}.purchase_feb`, formatValue)

                                    handleChangeValueSin(index, 'purchase_feb')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeSin[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeSin.${index}.purchase_mar`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeSin[index].purchase_mar = value

                                    setValue(`scopeSin.${index}.purchase_mar`, formatValue)

                                    handleChangeValueSin(index, 'purchase_mar')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeSin[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeSin.${index}.purchase_apr`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeSin[index].purchase_apr = value

                                    setValue(`scopeSin.${index}.purchase_apr`, formatValue)

                                    handleChangeValueSin(index, 'purchase_apr')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeSin[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeSin.${index}.purchase_may`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeSin[index].purchase_may = value

                                    setValue(`scopeSin.${index}.purchase_may`, formatValue)

                                    handleChangeValueSin(index, 'purchase_may')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeSin[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeSin.${index}.purchase_jun`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeSin[index].purchase_jun = value

                                    setValue(`scopeSin.${index}.purchase_jun`, formatValue)

                                    handleChangeValueSin(index, 'purchase_jun')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeSin[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeSin.${index}.purchase_jul`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeSin[index].purchase_jul = value

                                    setValue(`scopeSin.${index}.purchase_jul`, formatValue)

                                    handleChangeValueSin(index, 'purchase_jul')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeSin[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeSin.${index}.purchase_aug`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeSin[index].purchase_aug = value

                                    setValue(`scopeSin.${index}.purchase_aug`, formatValue)

                                    handleChangeValueSin(index, 'purchase_aug')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeSin[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeSin.${index}.purchase_sep`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeSin[index].purchase_sep = value

                                    setValue(`scopeSin.${index}.purchase_sep`, formatValue)

                                    handleChangeValueSin(index, 'purchase_sep')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeSin[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeSin.${index}.purchase_oct`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeSin[index].purchase_oct = value

                                    setValue(`scopeSin.${index}.purchase_oct`, formatValue)

                                    handleChangeValueSin(index, 'purchase_oct')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeSin[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeSin.${index}.purchase_nov`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeSin[index].purchase_nov = value

                                    setValue(`scopeSin.${index}.purchase_nov`, formatValue)

                                    handleChangeValueSin(index, 'purchase_nov')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeSin[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeSin.${index}.purchase_dec`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeSin[index].purchase_dec = value

                                    setValue(`scopeSin.${index}.purchase_dec`, formatValue)

                                    handleChangeValueSin(index, 'purchase_dec')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {!scopeSin[index].only_view_yearly && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeSin.${index}.purchase_yearly`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeSin[index].purchase_yearly = value

                                    setValue(
                                      `scopeSin.${index}.purchase_yearly`,
                                      formatValue
                                    )

                                    handleChangeValueSin(index, 'purchase_yearly')
                                  }}
                                />
                              )}
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5
                                (scopeSin[index].purchase_total, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5
                                (scopeSin[index].emission_co2, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5
                                (scopeSin[index].emission_ch4, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5
                                (scopeSin[index].emission_n2o, false)}
                              </span>
                            </td>
                            {!isCommonUser && (
                              <td style={{ textAlign: 'center' }} rowSpan={2}>
                                <div
                                  onClick={() => handleDeleteScopeSin(index)}
                                  style={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    width: 70
                                  }}
                                >
                                  <img src={TrashIcon} alt="lixeirinha"/>
                                </div>
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td
                              colSpan={2}
                              className="subhead-item"
                              style={{ paddingRight: 5, textAlign: 'right' }}
                            >
                              {'Emissões mensais de CO₂ (t)'}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeSin[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeSin[index].emission_co2_jan, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeSin[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeSin[index].emission_co2_feb, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeSin[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeSin[index].emission_co2_mar, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeSin[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeSin[index].emission_co2_apr, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeSin[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeSin[index].emission_co2_may, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeSin[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeSin[index].emission_co2_jun, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeSin[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeSin[index].emission_co2_jul, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeSin[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeSin[index].emission_co2_aug, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeSin[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeSin[index].emission_co2_sep, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeSin[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeSin[index].emission_co2_oct, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeSin[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeSin[index].emission_co2_nov, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeSin[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeSin[index].emission_co2_dec, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }} />
                          </tr>
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                  <tfoot>
                    <tr style={{ fontWeight: 'bold' }}>
                      <td
                        colSpan={2}
                        className="head-tr-item"
                        style={{ paddingRight: 5, textAlign: 'right' }}
                      >
                        {'Total'}
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseJanSin, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseFebSin, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseMarSin, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseAprSin, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseMaySin, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseJunSin, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseJulSin, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseAugSin, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseSepSin, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseOctSin, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseNovSin, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseDecSin, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseYearlySin, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseTotalSin, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (emissionCo2Sin, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (emissionCh4Sin, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (emissionN2oSin, false)}
                        </span>
                      </td>
                      {!isCommonUser && <td className="head-tr-item" />}
                    </tr>
                  </tfoot>
                </table>
                // termina aqui
              )}

              {!scopeSin.length && (
                <Uninformed
                  text={
                    isCommonUser
                    ? `Nenhum registro foi informado`
                    : `Nenhum registro foi informado, clique em 'novo' para incluir seu registro.`
                  }
                />
              )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  marginBottom: 10
                }}
              >
                {errors && errors.scopeSin && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      margin: 10
                    }}
                  >
                    <span
                      className="requiredLabel"
                      style={{ fontSize: 14, marginTop: 10 }}
                    >
                      * Registro da fonte e Descrição da fonte são obrigatórios
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 10,
                justifyContent: 'space-between',
                marginBottom: 0
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                {sistemaAmazonas && sistemaAmazonas.label && (
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginTop: 5
                    }}
                  >
                    {sistemaAmazonas.label}
                  </span>
                )}
                <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5}} >
                  Clique, ao lado em novo registro, para adicionar nova linha
                </span>
              </div>

              {sistemaAmazonas && sistemaAmazonas.guidelines && (
                <div className="tooltip" style={{ paddingTop: 10 }}>
                  <img src={InfoButtonMin} alt="information" style={{ paddingTop: 10 }} />
                  <span className="tooltiptext">{sistemaAmazonas.guidelines}</span>
                </div>
              )}

              <div style={{ textAlign: 'right', marginRight: 20, width: '30%' }}>
                {!isCommonUser &&
                <button
                type="button"
                  style={{
                    width: '160px',
                    height: '47px',
                    background: '#ffffff',
                    color: '#31363F',
                    borderColor: '#9E9E9E',
                    borderRadius: '2.5px',
                    cursor: 'pointer',
                    borderStyle: 'dashed',
                    fontWeight: 'bold',
                    borderWidth: '1px'
                  }}
                  onClick={() => handleNewItemAmazonas()}
                >
                  {`Novo registro`}
                </button>
                }
              </div>
            </div>

            <div
              style={{
                overflow: 'auto',
                position: 'relative',
                marginTop: 20,
                width: 'calc(100vw - 315px)',
                maxHeight: 'calc(100vh - 360px)'
              }}
            >
              {changeScopeAmazonas && scopeAmazonas && scopeAmazonas.length > 0 && (
                <table
                  style={{
                    maxWidth: '100%',
                    width: '4000px',
                    borderCollapse: 'collapse'
                  }}
                  className="table-scope"
                >
                  <thead>
                    <tr>
                      <th className="head-tr-item" style={{ width: 400 }} colSpan={2}>
                        {'Fonte'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} colSpan={13}>
                        {'Eletricidade comprada (MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {'Eletricidade total comprada (MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {'Emissões totais de CO₂ (t)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {'Emissões totais de CH₄ (t)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {'Emissões totais de N₂O (t)'}
                      </th>
                      {!isCommonUser && (
                        <th className="head-tr-item" style={{ width: 50 }} rowSpan={3} >
                          {!isCommonUser && (
                              <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                                <div onClick={handleOpenModalDeleteAmazonas}>
                                  <button style={{background: '#b81414', color: 'white', borderRadius: 5, cursor:'pointer'}}>Apagar tudo</button>
                                </div>
                              </td>
                            )}
                        </th>
                      )}
                    </tr>
                    <tr>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                      >
                        {'Registro'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                      >
                        {'Descrição'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} colSpan={12}>
                        {'Relate aqui a compra mensal de eletricidade (MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 250 }}
                        rowSpan={2}
                      >
                        {'Relate aqui a compra anual de eletricidade (MWh)'}
                      </th>
                    </tr>
                    <tr>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Janeiro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Fevereiro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Março'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Abril'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Maio'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Junho'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Julho'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Agosto'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Setembro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Outubro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Novembro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Dezembro'}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {scopeAmazonas.map((item: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 200 }}
                                className="input-item"
                                {...register(`scopeAmazonas.${index}.source`, {
                                  required: true
                                })}
                                onChange={(event) => {
                                  setValue(
                                    `scopeAmazonas.${index}.source`,
                                    event.target.value
                                  )
                                  scopeAmazonas[index].source = event.target.value
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 200 }}
                                className="input-item"
                                {...register(`scopeAmazonas.${index}.description`, {
                                  required: true
                                })}
                                onChange={(event) => {
                                  setValue(
                                    `scopeAmazonas.${index}.description`,
                                    event.target.value
                                  )
                                  scopeAmazonas[index].description = event.target.value
                                }}
                              />
                            </td>
                            <td>

                              {/* AQUI */}
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAmazonas.${index}.purchase_jan`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAmazonas[index].purchase_jan = value

                                    setValue(
                                      `scopeAmazonas.${index}.purchase_jan`,
                                      formatValue
                                    )

                                    handleChangeValueAmazonas(index, 'purchase_jan')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAmazonas.${index}.purchase_feb`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAmazonas[index].purchase_feb = value

                                    setValue(
                                      `scopeAmazonas.${index}.purchase_feb`,
                                      formatValue
                                    )

                                    handleChangeValueAmazonas(index, 'purchase_feb')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAmazonas.${index}.purchase_mar`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAmazonas[index].purchase_mar = value

                                    setValue(
                                      `scopeAmazonas.${index}.purchase_mar`,
                                      formatValue
                                    )

                                    handleChangeValueAmazonas(index, 'purchase_mar')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAmazonas.${index}.purchase_apr`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAmazonas[index].purchase_apr = value

                                    setValue(
                                      `scopeAmazonas.${index}.purchase_apr`,
                                      formatValue
                                    )

                                    handleChangeValueAmazonas(index, 'purchase_apr')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAmazonas.${index}.purchase_may`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAmazonas[index].purchase_may = value

                                    setValue(
                                      `scopeAmazonas.${index}.purchase_may`,
                                      formatValue
                                    )

                                    handleChangeValueAmazonas(index, 'purchase_may')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAmazonas.${index}.purchase_jun`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAmazonas[index].purchase_jun = value

                                    setValue(
                                      `scopeAmazonas.${index}.purchase_jun`,
                                      formatValue
                                    )

                                    handleChangeValueAmazonas(index, 'purchase_jun')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAmazonas.${index}.purchase_jul`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAmazonas[index].purchase_jul = value

                                    setValue(
                                      `scopeAmazonas.${index}.purchase_jul`,
                                      formatValue
                                    )

                                    handleChangeValueAmazonas(index, 'purchase_jul')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAmazonas.${index}.purchase_aug`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAmazonas[index].purchase_aug = value

                                    setValue(
                                      `scopeAmazonas.${index}.purchase_aug`,
                                      formatValue
                                    )

                                    handleChangeValueAmazonas(index, 'purchase_aug')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAmazonas.${index}.purchase_sep`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAmazonas[index].purchase_sep = value

                                    setValue(
                                      `scopeAmazonas.${index}.purchase_sep`,
                                      formatValue
                                    )

                                    handleChangeValueAmazonas(index, 'purchase_sep')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAmazonas.${index}.purchase_oct`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAmazonas[index].purchase_oct = value

                                    setValue(
                                      `scopeAmazonas.${index}.purchase_oct`,
                                      formatValue
                                    )

                                    handleChangeValueAmazonas(index, 'purchase_oct')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAmazonas.${index}.purchase_nov`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAmazonas[index].purchase_nov = value

                                    setValue(
                                      `scopeAmazonas.${index}.purchase_nov`,
                                      formatValue
                                    )

                                    handleChangeValueAmazonas(index, 'purchase_nov')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAmazonas.${index}.purchase_dec`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAmazonas[index].purchase_dec = value

                                    setValue(
                                      `scopeAmazonas.${index}.purchase_dec`,
                                      formatValue
                                    )

                                    handleChangeValueAmazonas(index, 'purchase_dec')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {!scopeAmazonas[index].only_view_yearly && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAmazonas.${index}.purchase_yearly`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAmazonas[index].purchase_yearly = value

                                    setValue(
                                      `scopeAmazonas.${index}.purchase_yearly`,
                                      formatValue
                                    )

                                    handleChangeValueAmazonas(index, 'purchase_yearly')
                                  }}
                                />
                              )}
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5
                                (
                                  scopeAmazonas[index].purchase_totals,
                                  false
                                )}
                              </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5
                                (scopeAmazonas[index].emission_co2, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5
                                (scopeAmazonas[index].emission_ch4, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5
                                (scopeAmazonas[index].emission_n2o, false)}
                              </span>
                            </td>
                            {!isCommonUser && (
                              <td style={{ textAlign: 'center' }} rowSpan={2}>
                                <div
                                  onClick={() => handleDeleteScopeAmazonas(index)}
                                  style={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    width: 70
                                  }}
                                >
                                  <img src={TrashIcon} alt="lixeirinha"/>
                                </div>
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td
                              colSpan={2}
                              className="subhead-item"
                              style={{ paddingRight: 5, textAlign: 'right' }}
                            >
                              {'Emissões mensais de CO₂ (t)'}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (
                                    scopeAmazonas[index].emission_co2_jan,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (
                                    scopeAmazonas[index].emission_co2_feb,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (
                                    scopeAmazonas[index].emission_co2_mar,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (
                                    scopeAmazonas[index].emission_co2_apr,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (
                                    scopeAmazonas[index].emission_co2_may,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (
                                    scopeAmazonas[index].emission_co2_jun,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (
                                    scopeAmazonas[index].emission_co2_jul,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (
                                    scopeAmazonas[index].emission_co2_aug,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (
                                    scopeAmazonas[index].emission_co2_sep,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (
                                    scopeAmazonas[index].emission_co2_oct,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (
                                    scopeAmazonas[index].emission_co2_nov,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAmazonas[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (
                                    scopeAmazonas[index].emission_co2_dec,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5
                                (
                                  scopeAmazonas[index].emission_co2_yearly,
                                  false
                                )}
                              </span>
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                  <tfoot>
                    <tr style={{ fontWeight: 'bold' }}>
                      <td
                        colSpan={2}
                        className="head-tr-item"
                        style={{ paddingRight: 5, textAlign: 'right' }}
                      >
                        {'Total'}
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseJanAmazonas, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseFebAmazonas, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseMarAmazonas, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseAprAmazonas, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseMayAmazonas, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseJunAmazonas, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseJulAmazonas, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseAugAmazonas, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseSepAmazonas, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseOctAmazonas, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseNovAmazonas, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseDecAmazonas, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseYearlyAmazonas, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseTotalAmazonas, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (emissionCo2Amazonas, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (emissionCh4Amazonas, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (emissionN2oAmazonas, false)}
                        </span>
                      </td>
                      {!isCommonUser && <td className="head-tr-item" />}
                    </tr>
                  </tfoot>
                </table>
              )}

              {!scopeAmazonas.length && (
                <Uninformed
                  text={
                    isCommonUser
                    ? `Nenhum registro foi informado`
                    : `Nenhum registro foi informado, clique em 'novo' para incluir seu registro.`
                  }
                />
              )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  marginBottom: 10
                }}
              >
                {errors && errors.scopeAmazonas && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      margin: 10
                    }}
                  >
                    <span
                      className="requiredLabel"
                      style={{ fontSize: 14, marginTop: 10 }}
                    >
                      * Registro da fonte e Descrição da fonte são obrigatórios
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 10,
                justifyContent: 'space-between',
                marginBottom: 0
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                {outrosSistemas && outrosSistemas.label && (
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginTop: 5
                    }}
                  >
                    {outrosSistemas.label}
                  </span>
                )}
                <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5}} >
                  Clique, ao lado em novo registro, para adicionar nova linha
                </span>
              </div>

              {outrosSistemas && outrosSistemas.guidelines && (
                <div className="tooltip" style={{ paddingTop: 10 }}>
                  <img src={InfoButtonMin} alt="information" style={{ paddingTop: 10 }} />
                  <span className="tooltiptext">{outrosSistemas.guidelines}</span>
                </div>
              )}

              <div style={{ textAlign: 'right', marginRight: 20, width: '30%' }}>
                {!isCommonUser && (
                  <button
                    type="button"
                    style={{
                      width: '160px',
                      height: '47px',
                      background: '#ffffff',
                      color: '#31363F',
                      borderColor: '#9E9E9E',
                      borderRadius: '2.5px',
                      cursor: 'pointer',
                      borderStyle: 'dashed',
                      fontWeight: 'bold',
                      borderWidth: '1px'
                    }}
                    onClick={() => handleNewItemOther()}
                  >
                    {`Novo registro`}
                  </button>
                )}
              </div>
            </div>

            <div
              style={{
                overflow: 'auto',
                position: 'relative',
                marginTop: 20,
                width: 'calc(100vw - 315px)',
                maxHeight: 'calc(100vh - 360px)'
              }}
            >
              {changeScopeOther && scopeOther && scopeOther.length > 0 && (
                <table
                  style={{
                    maxWidth: '100%',
                    width: '4000px',
                    borderCollapse: 'collapse'
                  }}
                  className="table-scope"
                >
                  <thead>
                    <tr>
                      <th className="head-tr-item" style={{ width: 360 }} colSpan={2}>
                        {'Fonte'}
                      </th>
                      <th className="head-tr-item" style={{ width: 100 }} rowSpan={2}>
                        {'Emissões de CO₂ (t)'}
                      </th>
                      <th className="head-tr-item" style={{ width: 100 }} rowSpan={2}>
                        {'Emissões de CH₄ (t)'}
                      </th>
                      <th className="head-tr-item" style={{ width: 100 }} rowSpan={2}>
                        {'Emissões de N₂O (t)'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                        {'Emissões em CO₂e (t)'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                        {'Emissões de CO₂ biogênico (t)'}
                      </th>
                      {!isCommonUser && (
                        <th className="head-tr-item" style={{ width: 50 }} rowSpan={2} >
                          {!isCommonUser && (
                            <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                              <div onClick={handleOpenModalDeleteOther}>
                                <button style={{background: '#b81414', color: 'white', borderRadius: 5, cursor:'pointer'}}>Apagar tudo</button>
                              </div>
                            </td>
                          )}
                        </th>
                      )}
                    </tr>
                    <tr>
                      <th className="head-tr-item-bottom" style={{ width: 200 }}>
                        {'Registro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 200 }}>
                        {'Descrição'}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {scopeOther.map((item: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: "95%" }}
                                className="input-item"
                                {...register(`scopeOther.${index}.source`, {
                                  required: true
                                })}
                                onChange={(event) => {
                                  setValue(
                                    `scopeOther.${index}.source`,
                                    event.target.value
                                  )
                                  scopeOther[index].source = event.target.value
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: "95%" }}
                                className="input-item"
                                {...register(`scopeOther.${index}.description`, {
                                  required: true
                                })}
                                onChange={(event) => {
                                  setValue(
                                    `scopeOther.${index}.description`,
                                    event.target.value
                                  )
                                  scopeOther[index].description = event.target.value
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: "95%", textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopeOther.${index}.emission_co2`)}
                                onChange={(event) => {
                                  const formatValue = event.target.value;
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopeOther[index].emission_co2 = value

                                  setValue(
                                    `scopeOther.${index}.emission_co2`,
                                    formatValue
                                  )

                                  handleChangeValueOther(index, 'emission_co2')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: "95%", textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopeOther.${index}.emission_ch4`)}
                                onChange={(event) => {
                                  const formatValue = event.target.value;
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopeOther[index].emission_ch4 = value

                                  setValue(
                                    `scopeOther.${index}.emission_ch4`,
                                    formatValue
                                  )

                                  handleChangeValueOther(index, 'emission_ch4')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: "95%", textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopeOther.${index}.emission_n2o`)}
                                onChange={(event) => {
                                  const formatValue = event.target.value;
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopeOther[index].emission_n2o = value

                                  setValue(
                                    `scopeOther.${index}.emission_n2o`,
                                    formatValue
                                  )

                                  handleChangeValueOther(index, 'emission_n2o')
                                }}
                              />
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5
                                (scopeOther[index].emission_co2_t, false)}
                              </span>
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: "95%", textAlign: 'center' }}
                                className="input-item"
                                {...register(`scopeOther.${index}.emission_bio_co2_t`)}
                                onChange={(event) => {
                                  const formatValue = event.target.value;
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopeOther[index].emission_bio_co2_t = value

                                  setValue(
                                    `scopeOther.${index}.emission_bio_co2_t`,
                                    formatValue
                                  )

                                  handleChangeValueOther(index, 'emission_bio_co2_t')
                                }}
                              />
                            </td>
                            {!isCommonUser && (
                              <td style={{ textAlign: 'center' }} rowSpan={2}>
                                <div
                                  onClick={() => handleDeleteScopeOther(index)}
                                  style={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    width: 70
                                  }}
                                >
                                  <img src={TrashIcon} alt="lixeirinha"/>
                                </div>
                              </td>
                            )}
                          </tr>
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                  <tfoot>
                    <tr style={{ fontWeight: 'bold' }}>
                      <td
                        colSpan={2}
                        className="head-tr-item"
                        style={{ paddingRight: 5, textAlign: 'right' }}
                      >
                        {'total'}
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (emissionCo2Other, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (emissionCh4Other, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (emissionN2oOther, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (emissionTco2eOther, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (emissionTco2eBioOther, false)}
                        </span>
                      </td>
                      {!isCommonUser && <td className="head-tr-item" />}
                    </tr>
                  </tfoot>
                </table>
              )}

              {!scopeOther.length && (
                <Uninformed
                  text={
                    isCommonUser
                      ? `Nenhum registro foi informado`
                      : `Nenhum registro foi informado, clique em 'novo' para incluir seu registro.`
                  }
                />
              )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  marginBottom: 10
                }}
              >
                {errors && errors.scopeOther && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      margin: 10
                    }}
                  >
                    <span
                      className="requiredLabel"
                      style={{ fontSize: 14, marginTop: 10 }}
                    >
                      * Registro da fonte e Descrição da fonte são obrigatórios
                    </span>
                  </div>
                )}

              </div>
            </div>

            {/* Emissões Evitadas */}

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 10,
                justifyContent: 'space-between',
                marginBottom: 0
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                {sistemaEvitadas && sistemaEvitadas.label && (
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginTop: 5
                    }}
                  >
                    {sistemaEvitadas.label}
                  </span>
                )}
                <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5 }} >
                  Clique, ao lado em novo registro, para adicionar nova linha
                </span>
              </div>

              {sistemaEvitadas && sistemaEvitadas.guidelines && (
                <div className="tooltip" style={{ paddingTop: 10 }}>
                  <img src={InfoButtonMin} alt="information" style={{ paddingTop: 10 }} />
                  <span className="tooltiptext">{sistemaSin.guidelines}</span>
                </div>
              )}

              <div style={{ textAlign: 'right', marginRight: 20, width: '30%' }}>
                {!isCommonUser && (
                  <button
                    type="button"
                    style={{
                      width: '160px',
                      height: '47px',
                      background: '#ffffff',
                      color: '#31363F',
                      borderColor: '#9E9E9E',
                      borderRadius: '2.5px',
                      cursor: 'pointer',
                      borderStyle: 'dashed',
                      fontWeight: 'bold',
                      borderWidth: '1px'
                    }}
                    onClick={() => handleNewItemAvoided()}
                  >
                    {`Novo registro`}
                  </button>
                )}
              </div>
            </div>

            <div
              style={{
                overflow: 'auto',
                position: 'relative',
                marginTop: 20,
                width: 'calc(100vw - 320px)',
                maxHeight: 'calc(100vh - 360px)'
              }}
            >
              {changeScopeAvoided && scopeAvoided && scopeAvoided.length > 0 && (
                <table
                  style={{
                    maxWidth: '100%',
                    width: '4000px',
                    borderCollapse: 'collapse'
                  }}
                  className="table-scope"
                >
                  <thead>
                    <tr>
                      <th className="head-tr-item" style={{ width: 360 }} colSpan={2}>
                        {'Fonte'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} colSpan={13}>
                        {'Eletricidade Gerada (MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 250 }}
                        rowSpan={3}
                      >
                        {'Eletricidade total gerada (MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {'Emissões de CO₂ (t) evitadas'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {'Emissões de CH₄ (t)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {'Emissões de N₂O (t)'}
                      </th>
                      {!isCommonUser && (
                        <th className="head-tr-item" style={{ width: 50 }} rowSpan={3} >
                        {!isCommonUser && (
                            <td style={{ textAlign: 'center', background: 'transparent', border: 'none' }}>
                              <div onClick={handleOpenModalDeleteAvoided}>
                                <button style={{background: '#b81414', color: 'white', borderRadius: 5, cursor:'pointer'}}>Apagar tudo</button>
                              </div>
                            </td>
                          )}
                        </th>
                      )}
                    </tr>
                    <tr>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                      >
                        {'Registro'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                      >
                        {'Descrição'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} colSpan={12}>
                        {'Relate aqui a eletricidade mensal gerada (MWh)'}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 250 }}
                        rowSpan={2}
                      >
                        {'Relate aqui a geração anual de eletricidade (MWh)'}
                      </th>
                    </tr>
                    <tr>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Janeiro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Fevereiro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Março'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Abril'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Maio'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Junho'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Julho'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Agosto'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Setembro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Outubro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Novembro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Dezembro'}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {scopeAvoided.map((item: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 200 }}
                                className="input-item"
                                {...register(`scopeAvoided.${index}.source`, {
                                  required: true
                                })}
                                onChange={(event) => {
                                  setValue(`scopeAvoided.${index}.source`, event.target.value)
                                  scopeAvoided[index].source = event.target.value
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 200 }}
                                className="input-item"
                                {...register(`scopeAvoided.${index}.description`, {
                                  required: true
                                })}
                                onChange={(event) => {
                                  setValue(
                                    `scopeAvoided.${index}.description`,
                                    event.target.value
                                  )
                                  scopeAvoided[index].description = event.target.value
                                }}
                              />
                            </td>
                            <td>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAvoided.${index}.purchase_jan`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    scopeAvoided[index].purchase_jan = value

                                    setValue(`scopeAvoided.${index}.purchase_jan`, formatValue)

                                    handleChangeValueAvoided(index, 'purchase_jan')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAvoided.${index}.purchase_feb`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAvoided[index].purchase_feb = value

                                    setValue(`scopeAvoided.${index}.purchase_feb`, formatValue)

                                    handleChangeValueAvoided(index, 'purchase_feb')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAvoided.${index}.purchase_mar`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAvoided[index].purchase_mar = value

                                    setValue(`scopeAvoided.${index}.purchase_mar`, formatValue)

                                    handleChangeValueAvoided(index, 'purchase_mar')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAvoided.${index}.purchase_apr`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAvoided[index].purchase_apr = value

                                    setValue(`scopeAvoided.${index}.purchase_apr`, formatValue)

                                    handleChangeValueAvoided(index, 'purchase_apr')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAvoided.${index}.purchase_may`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAvoided[index].purchase_may = value

                                    setValue(`scopeAvoided.${index}.purchase_may`, formatValue)

                                    handleChangeValueAvoided(index, 'purchase_may')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAvoided.${index}.purchase_jun`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAvoided[index].purchase_jun = value

                                    setValue(`scopeAvoided.${index}.purchase_jun`, formatValue)

                                    handleChangeValueAvoided(index, 'purchase_jun')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAvoided.${index}.purchase_jul`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAvoided[index].purchase_jul = value

                                    setValue(`scopeAvoided.${index}.purchase_jul`, formatValue)

                                    handleChangeValueAvoided(index, 'purchase_jul')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAvoided.${index}.purchase_aug`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAvoided[index].purchase_aug = value

                                    setValue(`scopeAvoided.${index}.purchase_aug`, formatValue)

                                    handleChangeValueAvoided(index, 'purchase_aug')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAvoided.${index}.purchase_sep`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAvoided[index].purchase_sep = value

                                    setValue(`scopeAvoided.${index}.purchase_sep`, formatValue)

                                    handleChangeValueAvoided(index, 'purchase_sep')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAvoided.${index}.purchase_oct`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAvoided[index].purchase_oct = value

                                    setValue(`scopeAvoided.${index}.purchase_oct`, formatValue)

                                    handleChangeValueAvoided(index, 'purchase_oct')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAvoided.${index}.purchase_nov`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAvoided[index].purchase_nov = value

                                    setValue(`scopeAvoided.${index}.purchase_nov`, formatValue)

                                    handleChangeValueAvoided(index, 'purchase_nov')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAvoided.${index}.purchase_dec`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAvoided[index].purchase_dec = value

                                    setValue(`scopeAvoided.${index}.purchase_dec`, formatValue)

                                    handleChangeValueAvoided(index, 'purchase_dec')
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {!scopeAvoided[index].only_view_yearly && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`scopeAvoided.${index}.purchase_yearly`)}
                                  onChange={(event) => {
                                    const formatValue = event.target.value;
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopeAvoided[index].purchase_yearly = value

                                    setValue(
                                      `scopeAvoided.${index}.purchase_yearly`,
                                      formatValue
                                    )

                                    handleChangeValueAvoided(index, 'purchase_yearly')
                                  }}
                                />
                              )}
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5
                                (scopeAvoided[index].purchase_total, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5
                                (scopeAvoided[index].emission_co2, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5
                                (scopeAvoided[index].emission_ch4, false)}
                              </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: 'inline-block',
                                  textAlign: 'right'
                                }}
                              >
                                {formatPrice5
                                (scopeAvoided[index].emission_n2o, false)}
                              </span>
                            </td>
                            {!isCommonUser && (
                              <td style={{ textAlign: 'center' }} rowSpan={2}>
                                <div
                                  onClick={() => handleDeleteScopeAvoided(index)}
                                  style={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    width: 70
                                  }}
                                >
                                  <img src={TrashIcon} alt="lixeirinha" />
                                </div>
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td
                              colSpan={2}
                              className="subhead-item"
                              style={{ paddingRight: 5, textAlign: 'right' }}
                            >
                              {'Emissões mensais evitadas de CO₂ (t)'}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeAvoided[index].emission_co2_jan, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeAvoided[index].emission_co2_feb, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeAvoided[index].emission_co2_mar, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeAvoided[index].emission_co2_apr, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeAvoided[index].emission_co2_may, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeAvoided[index].emission_co2_jun, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeAvoided[index].emission_co2_jul, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeAvoided[index].emission_co2_aug, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeAvoided[index].emission_co2_sep, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeAvoided[index].emission_co2_oct, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeAvoided[index].emission_co2_nov, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {scopeAvoided[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5
                                  (scopeAvoided[index].emission_co2_dec, false)}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }} />
                          </tr>
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                  <tfoot>
                    <tr style={{ fontWeight: 'bold' }}>
                      <td
                        colSpan={2}
                        className="head-tr-item"
                        style={{ paddingRight: 5, textAlign: 'right' }}
                      >
                        {'Total'}
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseJanAvoided, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseFebAvoided, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseMarAvoided, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseAprAvoided, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseMayAvoided, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseJunAvoided, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseJulAvoided, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseAugAvoided, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseSepAvoided, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseOctAvoided, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseNovAvoided, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseDecAvoided, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseYearlyAvoided, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (purchaseTotalAvoided, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (emissionCo2Avoided, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (emissionCh4Avoided, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5
                          (emissionN2oAvoided, false)}
                        </span>
                      </td>
                      {!isCommonUser && <td className="head-tr-item" />}
                    </tr>
                  </tfoot>
                </table>
                // termina aqui
              )}

              {!scopeAvoided.length && (
                <Uninformed
                  text={
                    isCommonUser
                      ? `Nenhum registro foi informado`
                      : `Nenhum registro foi informado, clique em 'novo' para incluir seu registro.`
                  }
                />
              )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  marginBottom: 10
                }}
              >
                {errors && errors.scopeAvoided && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      margin: 10
                    }}
                  >
                    <span
                      className="requiredLabel"
                      style={{ fontSize: 14, marginTop: 10 }}
                    >
                      * Registro da fonte e Descrição da fonte são obrigatórios
                    </span>
                  </div>
                )}
              </div>
            </div>
            <br />

            {!ignoreFill && changeScopeSin && changeScopeAmazonas && changeScopeOther && (
              <div
                style={{
                  borderStyle: 'dashed',
                  margin: 5,
                  borderWidth: '1px',
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 330px)',
                  borderColor: '#9E9E9E',
                  height: '40px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 15
                }}
              >
                <span style={{ margin: 10, width: '70%' }}>
                  Emissões totais em CO₂ equivalente (toneladas métricas):
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    margin: 10,
                    textAlign: 'right',
                    width: '30%'
                  }}
                >
                  {formatPrice3
                  (emissionTco2eTotals, false)}
                </span>
              </div>
            )}

            {!ignoreFill && changeScopeSin && changeScopeAmazonas && changeScopeOther && (
              <div
                style={{
                  borderStyle: 'dashed',
                  margin: 5,
                  borderWidth: '1px',
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 330px)',
                  borderColor: '#9E9E9E',
                  height: '40px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 15
                }}
              >
                <span style={{ margin: 10, width: '70%' }}>
                  Emissões totais em CO₂ biogênico (toneladas métricas):
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    margin: 10,
                    textAlign: 'right',
                    width: '30%'
                  }}
                >
                  {formatPrice3
                  (emissionTco2eBioTotals, false)}
                </span>
              </div>
            )}

            {!ignoreFill && (
              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <div
                  style={{
                    width: '100%',
                    borderTop: '0.6px solid rgba(49, 54, 63, 0.35)'
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      marginTop: 5,
                      marginLeft: 10,
                      fontWeight: 'bold'
                    }}
                  >
                    Evidências do escopo informado
                  </span>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                    Observações sobre o escopo informado.
                  </label>

                  <textarea
                    readOnly={isCommonUser}
                    style={{ width: '100%', height: 100 }}
                    {...register('evidence')}
                  />

                  {errors.evidence && (
                    <span className="requiredLabel">Esse campo é obrigatório</span>
                  )}

                  <ScopeEvidenceAttachments
                    files={files}
                    onDeleteFile={handleDeleteFile}
                    onDownloadFile={handleDownloadFile}
                    onChangeAttachment={handleAttachmentChange}
                    isLoading={loadingEvideces}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </form>
      
      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}

    {openModalDeleteSin && (
      <ModalDeleteDataTable
        isOpen={openModalDeleteSin}
        handleModalVisible={() => setOpenModalDeleteSin(false)}
        handleOk={handleDeleteAllScopeSin}
      />
    )}

    {openModalDeleteAmazonas && (
      <ModalDeleteDataTable
        isOpen={openModalDeleteAmazonas}
        handleModalVisible={() => setOpenModalDeleteAmazonas(false)}
        handleOk={handleDeleteAllScopeAmazonas}
      />
    )}

    {openModalDeleteOther && (
      <ModalDeleteDataTable
        isOpen={openModalDeleteOther}
        handleModalVisible={() => setOpenModalDeleteOther(false)}
        handleOk={handleDeleteAllScopeOther}
      />
    )}

    {openModalDeleteAvoided && (
      <ModalDeleteDataTable
        isOpen={openModalDeleteAvoided}
        handleModalVisible={() => setOpenModalDeleteAvoided(false)}
        handleOk={handleDeleteAllScopeAvoided}
      />
    )}

    </Container>
  )
}
