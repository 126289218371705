import styled from 'styled-components'

export const Container = styled.div`
  text-align: center;
  border: 1px dashed #9e9e9e;
  margin-top: 50px;
  padding: 20px;

  span {
    text-decoration: underline;
    padding-top: 50;
  }
`
