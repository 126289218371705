import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Button } from "../Button/styles"
import { Modal } from "../Modal"
import { TitleText } from "../TitleText"
import { Container } from "./styles"

type ModalDeleteProps = {
    handleOk: () => void;
    isOpen: boolean;   
    handleModalVisible: () => void;
}

export const ModalDeleteDataTable = ({ isOpen, handleModalVisible, handleOk }: ModalDeleteProps) => {

    const [errorSubmit, setErrorSubmit] = useState(false);

    interface Inputs {
        confirm: string;
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = async (formData) => {
        if (formData) {
            setErrorSubmit(false)

            if (formData.confirm.toLocaleUpperCase() === "EXCLUIR"){
                handleOk()
            }else{
                setErrorSubmit(true)
            }
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            isPayOrTypeClient={true}
            handleModalVisible={handleModalVisible}
        >
            <Container>
                <div style={{ margin: 5, textAlign: "center" }}>
                    <TitleText level={2} title="ATENÇÃO" color="red" />
                </div>

                <div style={{ margin: 5, textAlign: "center"  }}>
                    <span style={{fontWeight: "bold"}}>Você deseja excluir todos os registros?</span>
                </div>

                <div style={{ margin: 5, textAlign: "center"  }}>
                    <span style={{fontSize: 12}}>Para confirmar a exclusão digite a palavra EXCLUIR</span>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ marginTop: 5, justifyContent: "center", display: 'flex', 
                        flexDirection: 'column', marginBottom: 5, marginLeft: 40  }}>
                        <input
                            type="text"
                            placeholder="EXCLUIR"
                            {...register("confirm", { required: true })}
                            style={{width: '350px'}}
                        />
                                    
                        {errors.confirm && (
                            <span className='requiredLabel'>Esse campo é obrigatório</span>
                        )}
                                
                        {errorSubmit && (                            
                            <span className='requiredLabel'>{"Digite 'EXCLUIR' para prosseguir"}</span>
                        )}
                    </div>

                    <div style={{ margin: 5, display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <Button color='black' type="button" size="md"
                                onClick={handleModalVisible}
                                style={{ margin: 10, width: '285px', }}>
                            Cancelar
                        </Button>

                        <Button color='red' type="submit" size="md"
                                style={{ margin: 10, width: '285px' }}>
                            Confirmar exclusão
                        </Button>
                    </div>
                </form>
            </Container>
        </Modal >
    )
}