import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import { styles } from '../styles/table'

export const TheadItem: React.FC<{
  width?: string
  height?: string
  background?: string
}> = (props) => {
  const { width, height, background = '#dbdbdb' } = props
  return (
    <View
      style={{
        ...styles.theadItem,
        width,
        height,
        backgroundColor: background,
        ...styles.Bold
      }}
    >
      <Text style={{...styles.Bold, fontSize: 7}}>{props.children}</Text>
    </View>
  )
}
