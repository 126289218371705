import styled from 'styled-components';
import BackIcon from '../../assets/images/background-nova-conta.jpg';

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    background-image:center;
    background: url(${BackIcon});
    background-repeat: no-repeat;
    background-size: 100% 100%;
   
    .form-container {
        display: flex;
        flex-direction: column;
        width: 560px;
        height: auto;
        background: ${props => props.theme.colors.white};
        box-shadow: 0px 9px 30px rgba(162, 160, 160, 0.07);
        border-radius: 6.5px;
        padding: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -290px;
        margin-left: -280px;
    }

    .form-content {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .password-logo {
        position: relative;
        left: 510px;
        cursor: pointer;
        top: -27px;
        width:25px;
    }
`;