import React from 'react'
import { Document, PDFDownloadLink, Text, View } from '@react-pdf/renderer'
import { PDFPage } from '../../../components/PDF/Page'
import { CompanyData } from '../../../interfaces/CompanyData'
import { Button } from '../../../components/Button/styles'
import { styles as tableStyles } from '../../../components/PDF/styles/table'
import { styles } from '../../../components/PDF/styles/common'

import { ConsolidatedEmissions } from './ConsolidatedEmissions'
import { Scope1Emissions } from './Scope1Emissions'
import { Scope3Emissions } from './Scope3Emissions'
import { Scope2LocationEmissions } from './Scope2LocationEmissions'
import { Scope2PurchaseChoiceEmissions } from './Scope2PurchaseChoiceEmissions'
import { NotRegulatedByKyotoProtocolEmissions } from './NotRegulatedByKyotoProtocolEmissions'

interface Props extends CompanyData {
  pdfData: {
    emissions_by_category: any
    metric_tons: any
    not_kyoto: any
    tons_of_gas: any
    company_itens :{
      logo_image_url: string;
    }
  }
  upstreamTco2e: number;
  upstreamCo2Bio: number;
  downstreamTco2e: number;
  downstreamCo2Bio: number;
  homeWorkTco2e: number;
  homeWorkCo2Bio: number;
  businessTco2e: number;
  businessCo2Bio: number;
  wasteTco2e: number;
  wasteCo2Bio: number;
}

export const DownloadResumePDFButton: React.FC<Props> = (props) => {
  const { data, pdfData } = props
  const { upstreamTco2e, upstreamCo2Bio, downstreamTco2e, downstreamCo2Bio, homeWorkTco2e, homeWorkCo2Bio, businessTco2e, businessCo2Bio, wasteTco2e, wasteCo2Bio } = props
  const document = (
    <Document>
      <PDFPage logo={pdfData?.company_itens.logo_image_url} title={`Registro Público de Emissões - Ano base: ${data?.year}`}
       companyName={data?.corporate_name as string} >
        <Text style={{ ...tableStyles.tablesSectionTitle, ...styles.textContainer, marginBottom: -6, ...styles.Bold }}>
          Registro público de emissões - GHG Protocol
        </Text>

        <ConsolidatedEmissions
          pdfData={{ metricTons: pdfData.metric_tons, tonsOfGas: pdfData.tons_of_gas }}
        />

        <Text style={{ ...tableStyles.tablesSectionTitle, ...styles.textContainer, ...styles.Bold }}>
          Resumo das emissões de GEE da organização, por escopo e categoria
        </Text>

        <Scope1Emissions pdfData={pdfData.emissions_by_category.scope1} />
        <Scope2LocationEmissions pdfData={pdfData.emissions_by_category.scope2.location} />
        <Scope2PurchaseChoiceEmissions
          pdfData={pdfData.emissions_by_category.scope2.purchase_choice}
        />
        <Scope3Emissions
          pdfData={pdfData.emissions_by_category.scope3}
          upstreamTco2e={upstreamTco2e}
          upstreamCo2Bio={upstreamCo2Bio}
          downstreamTco2e={downstreamTco2e}
          downstreamCo2Bio={downstreamCo2Bio}
          homeWorkTco2e={homeWorkTco2e}
          homeWorkCo2Bio={homeWorkCo2Bio}
          businessTco2e={businessTco2e}
          businessCo2Bio={businessCo2Bio}
          wasteTco2e={wasteTco2e}
          wasteCo2Bio={wasteCo2Bio}
        />
        
        <View wrap={false}>
          <Text style={{ ...tableStyles.tablesSectionTitle, ...styles.textContainer, ...styles.Bold }}>
            Emissões de outros GEE não regulados pelo Protocolo de Quioto
          </Text>
          <NotRegulatedByKyotoProtocolEmissions pdfData={pdfData.not_kyoto} />
        </View>
      </PDFPage>
    </Document>
  )

  return (
    <>
      <PDFDownloadLink
        fileName={`Registro Público de Emissões - ${data?.corporate_name} (${data?.year})`}
        document={document}
      >
        <Button
          style={{ marginRight: 20, marginBottom: 5, pointerEvents: 'none' }}
          color="blue"
          size="md"
        >
          Baixar PDF
        </Button>
      </PDFDownloadLink>
    </>
  )
}
