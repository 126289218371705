import { Page, Text, Image as ImagePDF, Font, View } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import backgroundImage from "../../../../../../../assets/images/background-nova-conta.jpg";
import OcaVazada from "../../../../../../../assets/images/oca-vazada2.png"
import OcaPreenchida from "../../../../../../../assets/images/oca-preenchida2.png"
import backgroundPDF from "../../../../../../../assets/images/fundo-pdf.jpg"
import { styles } from "../../styles";
import sinergiaAzul from "../../../../../../../assets/images/logo-azul.png";
import { ResumeData } from "../../../../../../Inventory/components/Reports/interfaces/ResumeData";
import { InventoryData } from "../../../../../../Inventory/components/Reports/interfaces/InventoryData";
import { ClientLogo } from "../../components/ClientLogo";
import { Logos } from "../../components/Logos";

interface Page1Props {
  year?: number;
  cover?: string;
  companyName?: string;
  logo?: string;
  companyId?: number;
}

export interface IGEEProps {
  data?: InventoryData;
  resume?: ResumeData;
}

export const Capa: React.FC<Page1Props & IGEEProps> = (props) => {
  const { logo, year, cover, companyName, resume, companyId } = props;


  return (
    <Page size={[640, 480]}>
      <View style={{ height: "100%", backgroundColor: "#ecf3f4", position: 'relative' }}>
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20, marginRight: 20 }}>
          <ClientLogo logo={logo} companyId={companyId} />
          <ImagePDF
            src={sinergiaAzul}
            style={{
              width: 65,
              height: 25
            }}
          />
        </View>
        <View 
          style={{
            backgroundColor: 'transparent', 
            width: 85, 
            height: 115, 
            borderRadius: 50, 
            marginLeft: -30,
            justifyContent: 'center', 
            alignItems: 'center', 
            borderWidth: 1.5, 
            borderColor: '#80b780', 
            top: -25
          }}
        />
        <View style={styles.upTitleView}>
          <Text style={styles.homeTitle}>
            Inventário de Emissões de Gases de Efeito Estufa{" "}
            <Text style={{ ...styles.homeTitle, ...styles.extraBold, ...styles.boldItalic }}>
              {year}
            </Text>
          </Text>
          <Text style={{...styles.homeCompany, ...styles.boldItalic, flexWrap: 'wrap' }}>{companyName}</Text>
        </View>
        <View style={{ height: "70%", width: "100%", position: 'relative' }}>
          <ImagePDF
            src={cover}
            style={{ objectFit: "cover"}}
          />
          {/* <ImagePDF
            src={OcaVazada}
            style={{ width: 120, height: 120, position: 'absolute', top: '5%', right: '20%', transform: 'translate(50%, -50%)' }}
          />
          <ImagePDF
            src={OcaPreenchida}
            style={{ width: 120, height: 120, position: 'absolute', bottom: '-50px', right: '80%', transform: 'translate(50%, -50%)' }}
          /> */}
        </View>
      </View>
    </Page>
  );
};
