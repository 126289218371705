import { useState } from "react";
import { Container } from "./styles";
import { Controller } from "react-hook-form";
import Select from "react-select";
import ModalHandleImportData from "../../ModalHandleImportData";

interface ModalImportProps {
    title: string;
    templateFileName: string;
    option_table: string;
    handleImportData: () => any;
    observation?: string;
}

interface HasExport {
    active: boolean;
    handleExport?: (option: string) => void; 
}

interface HeaderSelectProps {
    description: string;
    name: string;
    hasImport: boolean;
    isCommonUser: boolean;
    options: any;
    control: any;
    defaultValue: () => any;
    handleChangeValue: (value: any) => any;
    selectedYear?: string | number;
    modalImport?: ModalImportProps;
    hasExport?: HasExport;
};

const HeaderSelect = ({ description, name, hasImport, isCommonUser, selectedYear, options, control, handleChangeValue, defaultValue, modalImport, hasExport }: HeaderSelectProps) => {
    const [openModalImport, setOpenModalImport] = useState(false);

    const handleOpenModalImport = () => {
        setOpenModalImport(!openModalImport);
    };
    
    
    return (
        <Container>
            <div className="column" style={{ marginRight: 30 }}>
                <span
                    style={{
                        textAlign: 'start',
                        fontSize: 14,
                        marginTop: 25,
                        fontWeight: 'bold'
                    }}
                >
                    {description}
                </span>
                <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5 }} >
                    Selecione, uma opção ao lado, para adicionar nova linha
                </span>
            </div>

            {!isCommonUser && options.length > 0 && (

                <div className="cotainer-options">
                    <Controller
                        control={control}
                        name={name}
                        render={({ field }) => (
                            <Select placeholder="[Selecione]"
                                {...field}
                                styles={{
                                    control: (styles) => ({
                                        ...styles,
                                        width: '350px',
                                        height: 40,
                                        marginTop: 15,
                                        marginRight: 10,
                                        backgroundColor: 'rgb(246, 246, 246)',
                                    }),
                                    option: (styles) => {
                                        return { ...styles, color: '#31363F' }
                                    }
                                }}
                                defaultValue={defaultValue}
                                options={options}
                                onChange={(selectedOption) => {
                                    field.onChange(selectedOption);
                                    handleChangeValue(selectedOption);
                                }}
                            />
                        )}
                    />

                    {hasImport && (
                        <button
                            className="button-view"
                            style={{ height: 40, marginLeft: 5}}
                            onClick={() => handleOpenModalImport()}
                            type="button"
                        >
                            Importar Dados
                        </button>
                    )}

                    {hasExport?.active && hasExport?.handleExport && (
                        <button
                            className="button-edit"
                            style={{ height: 40, marginLeft: 5 }}
                            onClick={() => {hasExport?.handleExport ? hasExport?.handleExport(name) : alert("Exportação não está disponível para este item.")}}
                            type="button"
                        >
                            Exportar Dados
                        </button>
                    )}

                </div>
            )}

            {openModalImport && (
                <ModalHandleImportData
                    isOpen={openModalImport}
                    handleModalVisible={handleOpenModalImport}
                    title={modalImport?.title || ""}
                    templateFileName={modalImport?.templateFileName || ""}
                    handleImportData={modalImport?.handleImportData || handleOpenModalImport}
                    option_table={modalImport?.option_table || ""}
                    observation={modalImport?.observation || ""}
                />
            )}
        </Container>
    );
};

export default HeaderSelect;