import { api } from "./api";

async function getById(gasId: number) {
  try {
    const { data } = await api.get(`/gas/${gasId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/gas", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getFugitiveEmissions() {
  try {
    const { data } = await api.get("/gas/fugitive_emissions");
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getFugitiveEmissionsOther() {
  try {
    const { data } = await api.get("/gas/fugitive_emissions_other");
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getChangesInLandUse() {
  try {
    const { data } = await api.get("/gas/changes_in_land_use");
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getIndustrialProcesses() {
  try {
    const { data } = await api.get("/gas/industrial_processes");
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getFugitiveEmissionsSf6Nf3() {
  try {
    const { data } = await api.get("/gas/fugitive_emissions_sf6_nf3");
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getNonKiotoFugitiveEmissions() {
  try {
    const { data } = await api.get("/gas/non_kioto_fugitive_emissions");
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getAgriculturalActivities() {
  try {
    const { data } = await api.get("/gas/agricultural_activities");
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(gas: any) {
  try {
    const { data } = await api.post(`/gas`, gas);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(gasId: number, gas: any) {
  try {
    const { data } = await api.put(`/gas/${gasId}`, gas);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(gasId: number) {
  try {
    const { data } = await api.delete(`/gas/${gasId}`);
    return data;
  } catch (error) {
    return false;
  }
}

const gasService = {
  create,
  getById,
  updateById,
  deleteById,
  getByParams,
  getChangesInLandUse,
  getFugitiveEmissions,
  getIndustrialProcesses,
  getFugitiveEmissionsSf6Nf3,
  getAgriculturalActivities,
  getFugitiveEmissionsOther,
  getNonKiotoFugitiveEmissions,
};

export default gasService;
