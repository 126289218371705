import React, { useEffect, useState } from "react";

import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import inventoryService from "../../../services/inventoryService";
import { Container } from "./styles";
import Parser from 'html-react-parser';
import { formatPrice3} from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import { StatusLabel } from "../components/StatusLabel";
import { ModalScopeDisabled } from "../components/ModalScopeDisabled";


export const InventoryScope = () => {
  const history = useHistory();

  const [openModalDisabled, setOpenModalDisabled] = useState(false);
  const [itemSelected, setItemSelected] = useState<any>({});
  const [inventorySelected, setInventorySelected] = useState<any>({});
  const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [scopes, setScopes] = useState<any>([]);
  const [scope, setScope] = useState<number>(0);

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.company_id && item.year){
      getInventorysById(item.company_id, item.year, item.scope)
    }else{
      history.push({
        pathname: '/inventario',
      });
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  async function getInventorysById(company_id: number, year: number, scope:number) {
    const inventory = await inventoryService.getById(company_id, year)
    const scopes = await inventoryService.getScopes(company_id, year, scope)

    setInventorySelected(inventory)
    setScopes(scopes)
    setScope(scope)
  }

  function redirectToScope(item?: any) {
    if (inventorySelected.company && inventorySelected.company.id && inventorySelected.year){
      if (item.status !== 'disabled'){
        const url = '/inventario/escopo-' + scope + "/" + item.name.replaceAll('_', '-')
        const state = { company_id: inventorySelected.company.id, 
          year: inventorySelected.year, name: item.name, scope: scope }

        history.push({ pathname: url, state: state });
      }else{
        handleOpenModalDisabled(item)
      }
    }
  }

  function handleOpenModalDisabled(item?: any) {
    setItemSelected(item)
    setOpenModalDisabled(!openModalDisabled);
  }

  return (
    <Container>
      <GoBack />

      <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
        <div style={{ display: "flex", flexDirection: "column"}} >
          <TitleText level={1} title={'Inventário: ' + inventorySelected?.year + ' - ' + inventorySelected?.company?.corporate_name}  />
          <TitleText level={4} title={"Registro dos itens correspondentes ao Escopo 0" + scope}  />
        </div>
      
        

        <span className="list-text" style={{ fontSize: "12px" }}>
          <StatusLabel status={scopes.fill_percentage} />
        </span>
      </div>

      {scopes?.scopes && (
        <div style={{ overflow: "hidden auto", position: "relative", marginTop: 10,  height: "calc(100vh - 320px)", 
            maxHeight: "calc(100vh - 320px)"}} >
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                <th style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}>
                  {"Escopo"}
                </th>
                <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontWeight: "700" }}>
                  {"Status"}
                </th>
              </tr>
            </thead>
            <tbody>
              {scopes?.scopes.map((scope: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <tr style={{paddingBottom: 5}}>
                      <td style={{ padding: "10px", width: "95%", cursor: "pointer" }}
                          onClick={() => redirectToScope(scope)}>
                        <span className="title-text-underline">
                          {scope.label}
                        </span>
                        <br/>
                        {scope.description && (
                          <span style={{fontSize: 12}}>
                            {Parser(scope.description)}
                          </span>
                        )}
                      </td>
                      <td style={{ padding: "10px" }} >
                        {scope.status === 'disabled' && (
                          <div className="bulled-scope-disabled" />
                        )}
                        {scope.status === 'pending' && (
                          <div className="bulled-scope-pending" />
                        )}
                        {scope.status === 'draft' && (
                          <div className="bulled-scope-draft" />
                        )}
                        {scope.status === 'completed' && (
                          <div className="bulled-scope-completed" />
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <div style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: 20, marginRight: 10 }}>
        <div style={{ display: "flex", flexDirection: "row", marginRight: 10 }} >
            <div  className="bulled-scope-disabled" />
            <span className="status-legend">Escopo não contratado</span>
        </div>

        <div style={{ display: "flex", flexDirection: "row", marginRight: 10 }} >
            <div  className="bulled-scope-pending" />
            <span className="status-legend">Pendente de preenchimento</span>
        </div>

        <div style={{ display: "flex", flexDirection: "row", marginRight: 10 }} >
            <div  className="bulled-scope-draft" />
            <span className="status-legend">Parcialmente preenchido</span>
        </div>

        <div style={{ display: "flex", flexDirection: "row", marginRight: 10 }} >
            <div  className="bulled-scope-completed" />
            <span className="status-legend">Preenchimento finalizado</span>
        </div>
      </div>

      {scopes && (
        <div className="row" style={{ borderStyle: 'dashed', margin: 5, marginTop: 15, borderWidth: '1px', width: 'calc(100vw - 330px)',
            maxWidth: 'calc(100vw - 330px)', borderColor: '#9E9E9E', height: '40px', marginLeft: 15 }} >
          <span style={{ margin: 10, width: '70%' }}>
            Emissões totais em CO₂ equivalente (toneladas métricas):
          </span>
          <span style={{ fontWeight: 'bold', margin: 10, textAlign: 'right', width: '30%' }} >
            {formatPrice3(scopes.emission_tco2e, false)}
          </span>
        </div>
      )}

      {scopes && (
        <div className="row" style={{ borderStyle: 'dashed', margin: 5, borderWidth: '1px', width: 'calc(100vw - 330px)',
            maxWidth: 'calc(100vw - 330px)', borderColor: '#9E9E9E', height: '40px', marginLeft: 15 }} >
          <span style={{ margin: 10, width: '70%' }}>
            Emissões totais em CO₂ biogênico (toneladas métricas):
          </span>
          <span style={{ fontWeight: 'bold', margin: 10, textAlign: 'right', width: '30%' }} >
            {formatPrice3(scopes.emission_tco2e_bio, false)}
          </span>
        </div>
      )}

      {openModalDisabled && (
        <ModalScopeDisabled
          isOpen={openModalDisabled}
          handleModalVisible={handleOpenModalDisabled}
          entityName={itemSelected.label}
        />
      )}
    </Container>
  );
};
