import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10!important;
    margin: 5;
    width: "calc(100vw - 330px)";
    max-width: "calc(100vw - 330px)";
    height: 40px;
    margin-left: 15px;
    border-style: dashed;
    border: 1px dashed #9E9E9E;

`;
