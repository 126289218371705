import { api } from "./api";

async function getById(vehicle_fuelId: number) {
  try {
    const { data } = await api.get(`/vehicle_fuel/${vehicle_fuelId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/vehicle_fuel", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(vehicle_fuel: any) {
  try {
    const { data } = await api.post(`/vehicle_fuel`, vehicle_fuel);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(vehicle_fuelId: number, vehicle_fuel: any) {
  try {
    const { data } = await api.put(`/vehicle_fuel/${vehicle_fuelId}`, vehicle_fuel);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(vehicle_fuelId: number) {
  try {
    const { data } = await api.delete(`/vehicle_fuel/${vehicle_fuelId}`);
    return data;
  } catch (error) {
    return false;
  }
}

async function getItensByVehicleFuel(vehicle_fuelId: number) {
  try {
    const { data } = await api.get(`/vehicle_fuel/years/${vehicle_fuelId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function updateItensByVehicleFuel(vehicle_fuelId: number, itens: any) {
  try {
    const { data } = await api.put(`/vehicle_fuel/years/${vehicle_fuelId}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getStationaryCombustion() {
  try {
    const { data } = await api.get("/vehicle_fuel/stationary_combustion");
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getHomeWorkDisplacement() {
  try {
    const { data } = await api.get("/vehicle_fuel/displacement_home_work");
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getThermalEnergy() {
  try {
    const { data } = await api.get("/vehicle_fuel/thermal_energy");
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getAnualFactor() {
  try {
    const { data } = await api.get("/vehicle_fuel/anual_factor");
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByNameAndYearVehicle(name: string, year: number) {
  try {
    const { data } = await api.get(`/vehicle_fuel/${year}/${name}`);
    return data; 
  } catch (error) {
    console.error("Erro ao buscar combustível por nome e ano:", error);
    throw new Error('Erro ao buscar combustível por nome e ano.');
  }
}

const vehicleFuel = {
  getById,
  create,
  updateById,
  deleteById,
  getByParams,
  getAnualFactor,
  getThermalEnergy,
  getItensByVehicleFuel,
  updateItensByVehicleFuel,
  getStationaryCombustion,
  getHomeWorkDisplacement,
  getByNameAndYearVehicle
};

export default vehicleFuel;
