import React from 'react'
import { View, Text } from '@react-pdf/renderer'

import { styles } from '../../../../../components/PDF/styles/common'
import { colors } from '../../../../../components/PDF/styles/colors'

const FullProjectField: React.FC<{ title: string; value: number | string }> = (props) => {
  const { title, value } = props

  return (
    <View style={{ ...styles.row, margin: '5px 0' }}>
      <Text style={{ ...styles.text, minWidth: 150 }}>{title}</Text>
      <Text
        style={{
          ...styles.text,
          borderBottom: `1px solid ${colors.lightGrey}`,
          width: '100%'
        }}
      >
        {value}
      </Text>
    </View>
  )
}

export default FullProjectField
