import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: auto;

    .container-global {
        display: flex;
        justify-content: space-between;
        gap: 10px;

    }

    .success{
        color: green;
    }

    .error{
        color: red;
    }


    tr {
        height: 15px;
        max-height: 15px;
        /* overflow: hidden; */
        
        &:hover {
            background-color: #f5f5f5;
        }
    }

    .paginator {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

    }

    .button-actions {
        color: #007bff;
        
        &:hover {
        color: #000;
    }
    }

    /* Estilizando a barra de rolagem */
    .custom-scrollbar {
        overflow-y: scroll;
        overflow: hidden;
    }

    .custom-scrollbar:hover {
        overflow: auto;
    }

    .custom-scrollbar::-webkit-scrollbar {
        width: 8px; /* Largura da barra de rolagem */
        height: 8px;
    }

    .custom-scrollbar::-webkit-scrollbar-track {
        background: #f1f1f1; /* Cor da trilha */
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #d0dae7; /* Cor do polegar */
        border-radius: 10px; /* Borda arredondada */
    }

    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #555; /* Cor do polegar ao passar o mouse */
    }
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    font-size: 12px;

    tr > td {
        height: 15px;
        max-height: 15px;
        overflow: hidden;
    }
`;

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const TableItem = styled.td`
    height: 15px;
    max-height: 15px;
    padding: 5px 15px;
    text-align: left;
    border-bottom: 1px solid #e4e1e1;
    transition: background-color 0.3s;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden; /* Impede o conteúdo de ultrapassar a altura da célula */
`;

export const TableHeader = styled.th`
    background-color: #e4e1e1;
    padding: 10px 15px;
    text-align: left;
    /* font-weight: bold; */
    border-bottom: 2px solid #e4e1e1;
    text-transform: capitalize;
    text-align: center;
`;

export const FilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .container {
        display: flex;
        gap: 10px;

    }
`;

export const Input = styled.input`
    padding: 5px 5px;
    border-radius: 8px;
    border: 1px solid #e4e1e1;
    margin: 10px 0;
    outline: none;
    height: 17px;
    font-size: 14px;

    &:focus {
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
`;

export const Select = styled.select`
    padding: 5px 10px;
    border-radius: 8px;
    border: 1px solid #e4e1e1;
    margin: 10px 0;
    background-color: white;
    font-size: 14px;
    color: #333;
`;

export const Button = styled.button`
    margin: 10px 0;
    padding: 5px 15px;
    background-color: #007bff;
    color: #FFF;
    border-radius: 6px;
    border: transparent;

    &:hover {
        background-color: #e4e1e1;
        box-shadow: 0 0 5px rgba(179, 179, 179, 0.5);
    }
`;

export const NoItemsFound = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 2px dashed #C1C1C1;
    border-radius: 8px;
    height: 200px;
    color: #777777;
    font-size: 16px;
    text-align: center;
`;

