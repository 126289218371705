import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import companyService from "../../../services/companyService";
import { Container } from "./styles";

import TrashIcon from '../../../assets/icons/trash.svg'
import { toNumberValues, transformCNPJ } from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import toast from "react-hot-toast";

export const CompanyScope = () => {
  const history = useHistory();

  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sSize, setSSize] = useState(0);
  const [scopes, setScopes] = useState<any>([]);
  const [scope1, setScope1] = useState<any>([]);
  const [scope2, setScope2] = useState<any>([]);
  const [scope3, setScope3] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [companySelected, setCompanySelected] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [anonymize, setAnonymize] = useState(false);
  const [onlyView, setOnlyView] = useState(false);

  async function getScopes() {
    let itens = await companyService.getScopesDefault(1)

    setScope1(itens.map((scope: any) => {
      return {
        slug: scope.slug, 
        name: scope.name, 
        label: scope.label, 
        sub_slug: scope.sub_slug, 
        enable: false
      };
    }));

    itens = await companyService.getScopesDefault(2)

    setScope2(itens.map((scope: any) => {
      return {
        slug: scope.slug, 
        name: scope.name, 
        label: scope.label, 
        sub_slug: scope.sub_slug, 
        enable: false
      };
    }));

    itens = await companyService.getScopesDefault(3)

    setScope3(itens.map((scope: any) => {
      return {
        slug: scope.slug, 
        name: scope.name, 
        label: scope.label, 
        sub_slug: scope.sub_slug, 
        enable: false
      };
    }));
  }

  useEffect(() => {
    getScopes()
  },// eslint-disable-next-line react-hooks/exhaustive-deps
   []);

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.company_id){
      getCompanysById(item.company_id)

      setAnonymize(user.pseudo_anonymize)
      setOnlyView(user.profile !== 'sinergia')
    }else{
      history.push({
        pathname: '/empresa',
      });
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);

  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = async (formData) => {
    if (formData) {
      const years:any[] = []

      for (const item of scopes){
        const scopes:any[] = []

        for (let i = 0; i < scope1?.length; i++) {
          const base = "year_" + item.year + "_scope1";
          const name = scope1[i].name;

          const check = formData[base][name].check

          const checkObj = {
              type: 1,
              name: scope1[i].name,
              slug: scope1[i].slug,
              label: scope1[i].label,
              sub_slug: scope1[i].sub_slug,
              enable: check
          }
  
          scopes.push(checkObj)
        }

        for (let i = 0; i < scope2?.length; i++) {
          const base = "year_" + item.year + "_scope2";
          const name = scope2[i].name;

          const check = formData[base][name].check

          const checkObj = {
              type: 2,
              name: scope2[i].name,
              slug: scope2[i].slug,
              label: scope2[i].label,
              sub_slug: scope2[i].sub_slug,
              enable: check
          }
  
          scopes.push(checkObj)
        }

        for (let i = 0; i < scope3?.length; i++) {
          const base = "year_" + item.year + "_scope3";
          const name = scope3[i].name;

          const check = formData[base][name].check

          const checkObj = {
              type: 3,
              name: scope3[i].name,
              slug: scope3[i].slug,
              label: scope3[i].label,
              sub_slug: scope3[i].sub_slug,
              enable: check
          }
  
          scopes.push(checkObj)
        }

        const obj = {
            year: Number(item.year),
            scopes: scopes
        }

        years.push(obj)
      }

      const item = {
          years: years,
      };

      const result = await companyService.updateScopes(
        companySelected.id,
        item
      );

      if (result && !result.error) {
        //history.goBack()

       // reset();
        //setErrorApi(undefined);
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error);
      }
    }
  };

  async function getCompanysById(id: number) {
    const company = await companyService.getById(id);

    setCompanySelected(company)

    const result = await companyService.getScopeById(id)

    for (const year of result){
      for (let i = 0; i < year.scope1?.length; i++) {
        const item = year?.scope1[i].enable
        const name = "year_" + year.year + "_scope1." + year?.scope1[i].name + ".check";
  
        setValue(name, item)
      }
  
      for (let i = 0; i < year.scope2?.length; i++) {
        const item = year?.scope2[i].enable
        const name = "year_" + year.year + "_scope2." + year?.scope2[i].name + ".check";
  
        setValue(name, item)
      }
  
      for (let i = 0; i < year.scope3?.length; i++) {
        const item = year?.scope3[i].enable
        const name = "year_" + year.year + "_scope3." + year?.scope3[i].name + ".check";
  
        setValue(name, item)
      }
    }

    setScopes(result)
  }

  const onAddYear = () => {
    const inputItem = (document.querySelector("#yearInput") as HTMLInputElement)

    const year = inputItem.value

    if (year){
      const itens = scopes

      itens.push({year: year, scope1: scope1, scope2: scope2, scope3: scope3})

      setScopes(itens)
      setSSize(itens.length)

      for (let i = 0; i < scope1?.length; i++) {
        setValue("year_" + year + "_scope1." + scope1[i].name + ".check", true)
      }

      for (let i = 0; i < scope2?.length; i++) {
        setValue("year_" + year + "_scope2." + scope2[i].name + ".check", true)
      }

      for (let i = 0; i < scope3?.length; i++) {
        setValue("year_" + year + "_scope3." + scope3[i].name + ".check", true)
      }
      
      inputItem.value = ""
    }
  }

  async function handleDeleteYear(item: any) {
    const itens = scopes.filter(function(a:any) { 
      return a.year !== item.year
    })

    setScopes(itens)
    setSSize(scopes.length)
  }

  const selectAll = (year: string, scopeType: string, scopeList: any[]) => {
    scopeList.forEach((item) => {
      const name = `year_${year}_${scopeType}.${item.name}.check`;
      setValue(name, true, { shouldDirty: true }); 
    });
  };

  const deselectAll = (year: string, scopeType: string, scopeList: any[]) => {
    scopeList.forEach((item) => {
      const name = `year_${year}_${scopeType}.${item.name}.check`;
      setValue(name, false, { shouldDirty: true });
    });
  };
  

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, justifyContent: "space-between", marginBottom: 10 }} >
          <TitleText level={1} title={companySelected.corporate_name + ' (' + transformCNPJ(companySelected.cnpj) + ')'}  />
          
          {onlyView === false && (
            <Button color="green" size="md" type="submit">
              Salvar
            </Button>
          )}
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ overflow: "auto", position: "absolute", top: "110px", right: "0px", left: "280px", bottom: "20px"}} >

          <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                justifyContent: "space-between", marginBottom: 0 }} >
            <TitleText level={3} title="Anos liberados"  />
          </div>
          
          {onlyView === false && (
            <div style={{ display: "flex", flexDirection: "row" }}>
                <input autoComplete={"off"} style={{ background: "#F6F6F6",
                    border: "1px solid #DFDFDF", borderRadius: " 2.5px",
                    width: "95%", paddingLeft: 16, height: "35px",
                    marginTop: 10, }} id="yearInput"  
                    placeholder="Informe o ano desejado para liberação"
                    onChange={(event) => {
                        const { value } = event.target;
                        event.target.value = toNumberValues(value);
                    }}
                />

                <Button color="black" size="md" onClick={()=>onAddYear()} type="button"
                    style={{ height: "40px", width: "50px",  marginTop: 10, marginLeft: 10, marginRight: 10 }}>
                    +
                </Button>
            </div>
          )}

          {scopes && scopes.length > 0 && (
            <div style={{ marginTop: 15, paddingBottom: 15, overflow: "auto" }}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tbody>
                  {scopes.map((scope: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div style={{ display: "flex", flexDirection: "row", width: "95%", marginBottom: 10,
                              borderStyle: "dashed", borderWidth: "1px", borderColor: "#9E9E9E", }}>
                          <div style={{ verticalAlign: "middle", width: "10%", padding: 10 }}>
                              <span style={{textDecoration: "underline", fontSize: 24, fontWeight: "bold"}}>
                                  {scope.year}
                              </span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "column", width: "90%", height: 250, overflow: "auto" }}>
                            <table style={{ width: "100%", borderCollapse: "collapse", marginTop: 5 }} className="table-scope">
                            <thead>
                              <tr>
                                <th className="head-tr-item" style={{ width: 200 }}>
                                  {'Escopo 01'}
                                </th>
                                <th className="head-tr-item"/>
                                <th>
                                  <Button color="green" size="md" type="submit" className="button-login" onClick={() => selectAll(scope.year, "scope1", scope.scope1)}
                                    style={{width: 100, height: 30, marginBottom: 5}}>
                                    Todos
                                  </Button>
                                  <Button color="red" size="md" type="submit" className="button-login" onClick={() => deselectAll(scope.year, "scope1", scope.scope1)}
                                    style={{width: 100, height: 30}}>
                                    Nenhum
                                  </Button>
                                </th>
                              </tr>
                            </thead>
                              <tbody>
                                {scope.scope1.map((item: any, index: number) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr>
                                        <td style={{ width: "90%" }} >
                                          <span>
                                            {item.label}
                                          </span>
                                        </td>
                                        <td>
                                          <div style={{ width: "100%", textAlign: "center" }}>
                                            <input style={{ height: "15px", width: "15px" }} 
                                                    type="checkbox" disabled={onlyView}
                                                    {...register(`year_${scope.year}_scope1.${item.name}.check`)}/>
                                          </div>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </tbody>
                            </table>

                            <table style={{ width: "100%", borderCollapse: "collapse", marginTop: 5 }} className="table-scope">
                              <thead>
                                <tr>
                                  <th className="head-tr-item" style={{ width: 200 }}>
                                    {'Escopo 02'}
                                  </th>
                                  <th className="head-tr-item"/>
                                  <Button color="green" size="md" type="submit" className="button-login" onClick={() => selectAll(scope.year, "scope2", scope.scope2)}
                                    style={{width: 100, height: 30, marginBottom: 5}}>
                                    Todos
                                  </Button>
                                  <Button color="red" size="md" type="submit" className="button-login" onClick={() => deselectAll(scope.year, "scope2", scope.scope2)}
                                    style={{width: 100, height: 30,}}>
                                    Nenhum
                                  </Button>
                                </tr>
                              </thead>
                              <tbody>
                                {scope.scope2.map((item: any, index: number) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr>
                                        <td style={{ width: "90%" }} >
                                          <span>
                                            {item.label}
                                          </span>
                                        </td>
                                        <td>
                                          <div style={{ width: "100%", textAlign: "center" }}>
                                            <input style={{ height: "15px", width: "15px" }} 
                                                    type="checkbox" disabled={onlyView}
                                                    {...register(`year_${scope.year}_scope2.${item.name}.check`)}/>
                                          </div>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </tbody>
                            </table>

                            <table style={{ width: "100%", borderCollapse: "collapse", marginTop: 5 }} className="table-scope">
                              <thead>
                                <tr>
                                  <th className="head-tr-item" style={{ width: 200 }}>
                                    {'Escopo 03'}
                                  </th>
                                  <th className="head-tr-item"/>
                                  <Button color="green" size="md" type="submit" className="button-login" onClick={() => selectAll(scope.year, "scope3", scope.scope3)}
                                    style={{width: 100, height: 30, marginBottom: 5}}>
                                    Todos
                                  </Button>
                                  <Button color="red" size="md" type="submit" className="button-login" onClick={() => deselectAll(scope.year, "scope3", scope.scope3)}
                                    style={{width: 100, height: 30}}>
                                    Nenhum
                                  </Button>
                                </tr>
                              </thead>
                              <tbody>
                                {scope.scope3.map((item: any, index: number) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr>
                                        <td style={{ width: "90%" }} >
                                          <span>
                                            {item.label}
                                          </span>
                                        </td>
                                        <td>
                                          <div style={{ width: "100%", textAlign: "center" }}>
                                            <input style={{ height: "15px", width: "15px" }} 
                                                    type="checkbox" disabled={onlyView}
                                                    {...register(`year_${scope.year}_scope3.${item.name}.check`)}/>
                                          </div>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
  
                          {onlyView === false && (
                            <div style={{ verticalAlign: "middle", width: "10%", padding: 10 }}>
                                <button style={{ width: "40px", height: "35px",
                                        background: "#ffffff", borderColor: "#E33F3F",
                                        borderWidth: "1px", borderStyle: "solid",
                                        borderRadius: "2.5px", color: "#E33F3F",
                                        cursor: "pointer", fontWeight: "bold", 
                                        float: "right" }}
                                    type="button"
                                    onClick={() => handleDeleteYear(scope)}
                                >
                                    <img src={TrashIcon} alt="lixeirinha"/>
                                </button>
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </form>
    </Container>
  );
};
