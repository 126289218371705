import React from 'react';
import { HeaderContainer, StyledButton } from './styles'; // Ajuste o caminho conforme necessário
import { TitleText } from '../TitleText';
import Select from 'react-select'

export interface HeaderWithTooltipProps {
    title: string;
    tooltipImageSrc: string;
    tooltipDescription: string;
    selectValue:any;
    selectOption:any;
    selectChange: any;
    buttonRedirect: any;
    buttonLabel: any;
    buttonHasRedirect: any;
    hasDownload: any;
    downloadComponent?: React.ReactNode;
}

const HeaderWithTooltip: React.FC<HeaderWithTooltipProps> = 
({ title, tooltipImageSrc, tooltipDescription,selectValue ,selectOption ,selectChange,buttonRedirect,  buttonLabel, buttonHasRedirect, hasDownload, downloadComponent}) => {

    const optionColor = '#31363f'
    const companySelectStyles = {
        control: (styles: any) => ({
          ...styles,
          backgroundColor: 'rgb(246, 246, 246)',
          marginBottom: 5,
          marginRight: 20,
          minWidth: 300
        }),
        option: (styles: any) => {
          return { ...styles, color: optionColor }
        }
      }

    return (
        <HeaderContainer>
            <div className="column">
                <div className="row">
                    <TitleText level={1} title={title} />
                    <div className="tooltip" style={{margin: 10}}>
                        <img src={tooltipImageSrc} alt="information"/>
                        <span className="tooltiptext">{tooltipDescription}</span>
                    </div>                   
                </div>
            </div>
            <div className="row">
                <Select 
                    value={selectValue}
                    options={selectOption}
                    styles={companySelectStyles}
                    placeholder="Empresa"
                    onChange={selectChange}
                />
            </div>
            {hasDownload && downloadComponent}
            {buttonHasRedirect && (
                <StyledButton onClick={buttonRedirect} color="green" size="md">
                    {buttonLabel}
                </StyledButton>
            )}
        </HeaderContainer>
    );
};

export default HeaderWithTooltip;
