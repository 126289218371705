import { Page, Text, View, StyleSheet, Image as ImagePDF } from "@react-pdf/renderer";
import React from "react";
import { Logos } from "../../components/Logos";
import { Header } from "../../components/Header";
import { IGEEProps } from "../../../interfaces/IGEEData";
import { styles, table } from "../../styles";
import LogoVerdeEscuro from "../../../../../../../assets/images/versoes-logo-icone-ingee-03.png";

export const Page10: React.FC<IGEEProps> = (props) => {
  const { data } = props;
  const { resume } = props;

  const formatSubscriptCO2 = (text: string) => {
    const parts = text.split(/(CO2)/g);
    return parts.map((part, index) =>
      part === "CO2" ? (
        <Text key={index}>
          CO<Text style={{ fontSize: 5 }}>2</Text>
        </Text>
      ) : (
        part
      )
    );
  };

  const formatSubscriptCH4 = (text: string) => {
    const parts = text.split(/(CO2)/g);
    return parts.map((part, index) =>
      part === "CH4" ? (
        <Text key={index}>
          CH<Text style={{ fontSize: 5 }}>4</Text>
        </Text>
      ) : (
        part
      )
    );
  };

  const formatSubscriptN2O = (text: string) => {
    const parts = text.split(/(CO2)/g);
    return parts.map((part, index) =>
      part === "N2O" ? (
        <Text key={index}>
          N<Text style={{ fontSize: 5 }}>2</Text>O
        </Text>
      ) : (
        part
      )
    );
  };

  const formatSubscriptSF6 = (text: string) => {
    const parts = text.split(/(CO2)/g);
    return parts.map((part, index) =>
      part === "SF6" ? (
        <Text key={index}>
          SF<Text style={{ fontSize: 5 }}>6</Text>
        </Text>
      ) : (
        part
      )
    );
  };

  const formatSubscriptNF3 = (text: string) => {
    const parts = text.split(/(CO2)/g);
    return parts.map((part, index) =>
      part === "NF3" ? (
        <Text key={index}>
          NF<Text style={{ fontSize: 5 }}>3</Text>
        </Text>
      ) : (
        part
      )
    );
  };

  const tons_of_gas = [
    {
      label: formatSubscriptCO2("CO2"),
      scope1: resume?.resumeIgee?.tons_of_gas?.scope1?.emission_co2,
      scope2_location: resume?.resumeIgee?.tons_of_gas?.scope2?.location.emission_co2,
      scope2_purchase: resume?.resumeIgee?.tons_of_gas?.scope2?.purchase_choice.emission_co2,
      scope3: resume?.resumeIgee?.tons_of_gas?.scope3?.emission_co2,
    },
    {
      label: formatSubscriptCH4("CH4"),
      scope1: resume?.resumeIgee?.tons_of_gas?.scope1?.emission_ch4,
      scope2_location: resume?.resumeIgee?.tons_of_gas?.scope2?.location.emission_ch4,
      scope2_purchase: resume?.resumeIgee?.tons_of_gas?.scope2?.purchase_choice.emission_ch4,
      scope3: resume?.resumeIgee?.tons_of_gas?.scope3?.emission_ch4,
    },
    {
      label: formatSubscriptN2O("N2O"),
      scope1: resume?.resumeIgee?.tons_of_gas?.scope1?.emission_n2o,
      scope2_location: resume?.resumeIgee?.tons_of_gas?.scope2?.location.emission_n2o,
      scope2_purchase: resume?.resumeIgee?.tons_of_gas?.scope2?.purchase_choice.emission_n2o,
      scope3: resume?.resumeIgee?.tons_of_gas?.scope3?.emission_n2o,
    },
    {
      label: formatSubscriptSF6("SF6"),
      scope1: resume?.resumeIgee?.tons_of_gas?.scope1?.emission_sf6,
      scope2_location: resume?.resumeIgee?.tons_of_gas?.scope2?.location.emission_sf6,
      scope2_purchase: resume?.resumeIgee?.tons_of_gas?.scope2?.purchase_choice.emission_sf6,
      scope3: resume?.resumeIgee?.tons_of_gas?.scope3?.emission_sf6,
    },
    {
      label: formatSubscriptNF3("NF3"),
      scope1: resume?.resumeIgee?.tons_of_gas?.scope1?.emission_nf3,
      scope2_location: resume?.resumeIgee?.tons_of_gas?.scope2?.location.emission_nf3,
      scope2_purchase: resume?.resumeIgee?.tons_of_gas?.scope2?.purchase_choice.emission_nf3,
      scope3: resume?.resumeIgee?.tons_of_gas?.scope3?.emission_nf3,
    },
    {
      label: ("HFC"),
      scope1: resume?.resumeIgee?.tons_of_gas?.scope1?.emission_hfcs,
      scope2_location: resume?.resumeIgee?.tons_of_gas?.scope2?.location.emission_hfcs,
      scope2_purchase: resume?.resumeIgee?.tons_of_gas?.scope2?.purchase_choice.emission_hfcs,
      scope3: resume?.resumeIgee?.tons_of_gas?.scope3?.emission_hfcs,
    },
    {
      label: ("PFC"),
      scope1: resume?.resumeIgee?.tons_of_gas?.scope1?.emission_pfcs,
      scope2_location: resume?.resumeIgee?.tons_of_gas?.scope2?.location.emission_pfcs,
      scope2_purchase: resume?.resumeIgee?.tons_of_gas?.scope2?.purchase_choice.emission_pfcs,
      scope3: resume?.resumeIgee?.tons_of_gas?.scope3?.emission_pfcs,
    },
  ];
  
  const totals = tons_of_gas.reduce(
    (acc, gas) => {
      acc.scope1 += gas.scope1 || 0;
      acc.scope2_location += gas.scope2_location || 0;
      acc.scope2_purchase += gas.scope2_purchase || 0;
      acc.scope3 += gas.scope3 || 0;
      return acc;
    },
    { scope1: 0, scope2_location: 0, scope2_purchase: 0, scope3: 0 }
  );
  
  tons_of_gas.push({
    label: "Total",
    scope1: resume?.resumeIgee?.tons_of_gas?.totals?.scope1_total || totals.scope1,
    scope2_location: resume?.resumeIgee?.tons_of_gas?.totals?.scope2_location_total || totals.scope2_location,
    scope2_purchase: resume?.resumeIgee?.tons_of_gas?.totals?.scope2_purchase_choice_total || totals.scope2_purchase,
    scope3: resume?.resumeIgee?.tons_of_gas?.totals?.scope3_total || totals.scope3,
  });

  const calculateTotal = (tons_of_gas: any) => {
    return tons_of_gas.reduce((acc: any, gas: any) => {
      acc.scope1 += gas.scope1 || 0;
      acc.scope2_location += gas.scope2_location || 0;
      acc.scope2_purchase += gas.scope2_purchase || 0;
      acc.scope3 += gas.scope3 || 0;
      return acc;
    }, { scope1: 0, scope2_location: 0, scope2_purchase: 0, scope3: 0 });
  };
  
  
  const total = calculateTotal(tons_of_gas);
  
  const metric_tons = [
    {
      label: "CO₂",
      scope1: resume?.resumeIgee?.metric_tons?.scope1?.emission_co2,
      scope2_location:
        resume?.resumeIgee?.metric_tons?.scope2?.location.emission_co2,
      scope2_purchase:
        resume?.resumeIgee?.metric_tons?.scope2?.purchase_choice.emission_co2,
      scope3: resume?.resumeIgee?.metric_tons?.scope3?.emission_co2,
    },
    {
      label: "CH₄",
      scope1: resume?.resumeIgee?.metric_tons?.scope1?.emission_ch4,
      scope2_location:
        resume?.resumeIgee?.metric_tons?.scope2?.location.emission_ch4,
      scope2_purchase:
        resume?.resumeIgee?.metric_tons?.scope2?.purchase_choice.emission_ch4,
      scope3: resume?.resumeIgee?.metric_tons?.scope3?.emission_ch4,
    },
    {
      label: "N₂O",
      scope1: resume?.resumeIgee?.metric_tons?.scope1?.emission_n2o,
      scope2_location:
        resume?.resumeIgee?.metric_tons?.scope2?.location.emission_n2o,
      scope2_purchase:
        resume?.resumeIgee?.metric_tons?.scope2?.purchase_choice.emission_n2o,
      scope3: resume?.resumeIgee?.metric_tons?.scope3?.emission_n2o,
    },
    {
      label: "SF₆",
      scope1: resume?.resumeIgee?.metric_tons?.scope1?.emission_sf6,
      scope2_location:
        resume?.resumeIgee?.metric_tons?.scope2?.location.emission_sf6,
      scope2_purchase:
        resume?.resumeIgee?.metric_tons?.scope2?.purchase_choice.emission_sf6,
      scope3: resume?.resumeIgee?.metric_tons?.scope3?.emission_sf6,
    },
    {
      label: "NF₃",
      scope1: resume?.resumeIgee?.metric_tons?.scope1?.emission_nf3,
      scope2_location:
        resume?.resumeIgee?.metric_tons?.scope2?.location.emission_nf3,
      scope2_purchase:
        resume?.resumeIgee?.metric_tons?.scope2?.purchase_choice.emission_nf3,
      scope3: resume?.resumeIgee?.metric_tons?.scope3?.emission_nf3,
    },
    {
      label: "HFC",
      scope1: resume?.resumeIgee?.metric_tons?.scope1?.emission_hfcs,
      scope2_location:
        resume?.resumeIgee?.metric_tons?.scope2?.location.emission_hfcs,
      scope2_purchase:
        resume?.resumeIgee?.metric_tons?.scope2?.purchase_choice.emission_hfcs,
      scope3: resume?.resumeIgee?.metric_tons?.scope3?.emission_hfcs,
    },
    {
      label: "PFC",
      scope1: resume?.resumeIgee?.metric_tons?.scope1?.emission_pfcs,
      scope2_location:
        resume?.resumeIgee?.metric_tons?.scope2?.location.emission_pfcs,
      scope2_purchase:
        resume?.resumeIgee?.metric_tons?.scope2?.purchase_choice.emission_pfcs,
      scope3: resume?.resumeIgee?.metric_tons?.scope3?.emission_pfcs,
    },
    {
      label: "Total",
      scope1: resume?.resumeIgee?.metric_tons?.totals?.scope1_total,
      scope2_location:
        resume?.resumeIgee?.metric_tons?.totals?.scope2_location_total,
      scope2_purchase:
        resume?.resumeIgee?.metric_tons?.totals?.scope2_purchase_choice_total,
      scope3: resume?.resumeIgee?.metric_tons?.totals?.scope3_total,
    },
  ];

  const MetricTable = () => {
    return (
      <View style={local.metricTable}>
        <View style={table.header}>
          <View style={table.titleSection2}>
            <Text style={{               
              fontSize: 10,
              color: "#64766d",
              alignSelf: "center", 
              ...styles.italic,
              marginBottom: 5 
              }}>
              Em toneladas métricas de CO<Text style={{ fontSize: 5 }}>2</Text>{" "}
              equivalente (tCO
              <Text style={{ fontSize: 5 }}>2</Text>e)
            </Text>
          </View>
          <View style={table.columns}>
          <View style={table.scope2}>
            <Text
              style={{
                borderRadius: "80%", 
                color: "white",
                fontSize: 9,
                paddingVertical: 3, 
                paddingHorizontal: 8, 
                height: "18px", 
                width: "55px", 
                backgroundColor: "#b47d58",
                ...styles.Bold,
              }}
            >
                Escopo 1
              </Text>
            </View>
            <View style={table.scope2}>
              <Text
                style={{
                  borderRadius: "80%", 
                  color: "white",
                  fontSize: 9,
                  paddingVertical: 3, 
                  paddingHorizontal: 8, 
                  height: "18px", 
                  width: "55px", 
                  backgroundColor: "#2a71b8",
                  ...styles.Bold,
                }}
              >
                Escopo 2
              </Text>
              <Text
                style={{
                  ...styles.extraLight,
                  color: "#2a71b8",
                  fontSize: 6,
                  alignSelf: "center",
                }}
              >
                (Localização)
              </Text>
            </View>
            <View style={table.scope2}>
              <Text
                style={{
                  borderRadius: "80%", 
                  color: "white",
                  fontSize: 9,
                  paddingVertical: 3, 
                  paddingHorizontal: 8, 
                  height: "18px", 
                  width: "55px", 
                  backgroundColor: "#2a71b8",
                  ...styles.Bold,
                }}
              >
                Escopo 2
              </Text>
              <Text
                style={{
                  ...styles.extraLight,
                  color: "#2a71b8",
                  fontSize: 6,
                  alignSelf: "center",
                }}
              >
                (Escolha de compra)
              </Text>
            </View>
            <View style={table.scope2}>
              <Text
                style={{
                  borderRadius: "80%", 
                  color: "white",
                  fontSize: 9,
                  paddingVertical: 3, 
                  paddingHorizontal: 8, 
                  height: "18px", 
                  width: "55px", 
                  backgroundColor: "#76b987",
                  ...styles.Bold,
                  marginRight: 8
                }}
              >
                Escopo 3
              </Text>
            </View>
          </View>
        </View>
        {metric_tons.map((gas, index) => (
          <View
            key={index}
            style={{
              flexDirection: "row", 
              justifyContent: "space-between",
              alignItems: "center", 
              paddingVertical: 1, 
              borderBottomWidth: 1, 
              borderBottomColor: "#e0e0e0",
              backgroundColor: index % 2 === 0 ? "#ffffff" : "#cfd5d9",
              marginTop: gas.label === "Total" ? 20 : 0,
            }}
          >
            <Text
              style={{
                ...styles.light,
                fontSize: 10,
                color: "#64766d",
                textAlign: "center", 
                flex: 1,
                marginLeft: 5
              }}
            >
              {gas.scope1 ? gas.scope1.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0,00"}
            </Text>
            <Text
              style={{
                ...styles.light,
                fontSize: 10,
                color: "#64766d",
                textAlign: "center", 
                flex: 1,
              }}
            >
              {gas.scope2_location?.toFixed(2).replace('.', ',') ? gas.scope2_location?.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.0"}
            </Text>
            <Text
              style={{
                ...styles.light,
                fontSize: 10,
                color: "#64766d",
                textAlign: "center", 
                flex: 1,
              }}
            >
              {gas.scope2_purchase?.toFixed(2).replace('.', ',') ? gas.scope2_purchase?.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.0"}
            </Text>
            <Text
              style={{
                ...styles.light,
                fontSize: 10,
                color: "#64766d",
                textAlign: "center", 
                flex: 1,
              }}
            >
              {gas.scope3.toFixed(2).replace('.', ',') ? gas.scope3.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.0"}
            </Text>
          </View>
        ))}
      </View>
    );
  };

  const TonsTable = () => {
    return (
      <View style={local.tonsTable}>
        <View style={table.header}>
          <View style={table.titleSection}>
            <Text style={{
              fontSize: 10,
              color: "#64766d",
              alignSelf: "center", 
              ...styles.italic,
              marginBottom: 5 
              }}>
              Em toneladas de gás
            </Text>
          </View>
          <View style={table.columns}>
            <View style={table.scope2}>
              <Text
                style={{
                  fontSize: 10,
                  ...styles.Bold,
                  alignSelf: "flex-start",
                  color: "#64766d",
                  top: 3
                }}
              >
                Categoria
              </Text>
            </View>
            <View style={table.scope2}>
              <Text
                style={{
                  borderRadius: "80%", 
                  color: "white",
                  fontSize: 9,
                  paddingVertical: 3, 
                  paddingHorizontal: 8, 
                  height: "18px", 
                  width: "55px", 
                  backgroundColor: "#b47d58",
                  ...styles.Bold,
                }}
              >
                Escopo 1
              </Text>
            </View>
            <View style={table.scope2}>
              <Text
                style={{
                  borderRadius: "80%", 
                  color: "white",
                  fontSize: 9,
                  paddingVertical: 3, 
                  paddingHorizontal: 8, 
                  height: "18px", 
                  width: "55px", 
                  backgroundColor: "#2a71b8",
                  ...styles.Bold,
                }}
              >
                Escopo 2
              </Text>
              <Text
                style={{
                  ...styles.extraLight,
                  color: "#2a71b8",
                  fontSize: 6,
                  alignSelf: "center",
                }}
              >
                (Localização)
              </Text>
            </View>
            <View style={table.scope2}>
              <Text
                style={{
                  borderRadius: "80%", 
                  color: "white",
                  fontSize: 9,
                  paddingVertical: 3, 
                  paddingHorizontal: 8, 
                  height: "18px", 
                  width: "55px", 
                  backgroundColor: "#2a71b8",
                  ...styles.Bold,
                }}
              >
                Escopo 2
              </Text>
              <Text
                style={{
                  ...styles.extraLight,
                  color: "#2a71b8",
                  fontSize: 6,
                  alignSelf: "center",
                }}
              >
                (Escolha de compra)
              </Text>
            </View>
            <View style={table.scope2}>
              <Text
                style={{
                  borderRadius: "80%", 
                  color: "white",
                  fontSize: 9,
                  paddingVertical: 3, 
                  paddingHorizontal: 8, 
                  height: "18px", 
                  width: "55px", 
                  backgroundColor: "#76b987",
                  ...styles.Bold,
                  marginRight: 8
                }}
              >
                Escopo 3
              </Text>
            </View>
          </View>
        </View>
        {tons_of_gas.map((gas, index) => (
          <View
            key={index}
            style={{
              flexDirection: "row", 
              justifyContent: "space-between",
              alignItems: "center", 
              paddingVertical: 1, 
              borderBottomWidth: 1, 
              borderBottomColor: "#e0e0e0",
              backgroundColor: index % 2 === 0 ? "#ffffff" : "#cfd5d9",
              marginTop: gas.label === "Total" ? 20 : 0,
            }}
          >
            <Text
              style={{
                ...styles.Bold,
                fontSize: 10,
                color: "#64766d",
                textAlign: "left", 
                flex: 1, 
                marginLeft: "25px"
              }}
            >
              {gas.label}
            </Text>
            <Text
              style={{
                ...styles.light,
                fontSize: 10,
                color: "#64766d",
                textAlign: "center", 
                flex: 1,
                marginLeft: -25
              }}
            >
              {gas.scope1 ? gas.scope1.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0,00"}
            </Text>
            <Text
              style={{
                ...styles.light,
                fontSize: 10,
                color: "#64766d",
                textAlign: "center", 
                flex: 1,
              }}
            >
              {gas.scope2_location?.toFixed(2).replace('.', ',') ? gas.scope2_location?.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.0"}
            </Text>
            <Text
              style={{
                ...styles.light,
                fontSize: 10,
                color: "#64766d",
                textAlign: "center", 
                flex: 1,
              }}
            >
              {gas.scope2_purchase?.toFixed(2).replace('.', ',') ? gas.scope2_purchase?.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }): "0.0"}
            </Text>
            <Text
              style={{
                ...styles.light,
                fontSize: 10,
                color: "#64766d",
                textAlign: "center", 
                flex: 1,
              }}
            >
              {gas.scope3.toFixed(2).replace('.', ',') ? gas.scope3.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.0"}
            </Text>
          </View>
        ))}
      </View>
    );
  };
  

  return (
    <Page
      size={[640, 480]}
      style={{ ...styles.Page, backgroundColor: "#ecf3f4" }}
    >
      <Header backColor="#989c9c" title="6. Resultados" />
      <View style={local.body}>
        <Text style={{ ...local.title, ...styles.italic, marginLeft: "15px" }}>
          6.2 Resumo das emissões totais
        </Text>
        <View style={local.tables}>
          <TonsTable />
          <MetricTable />
        </View>
      </View>
      <View style={{ position: 'absolute', bottom: 10, right: 10, flexDirection: 'row', alignItems: 'center' }}>
              <ImagePDF
                src={resume?.company_itens.logo_image_url}
                style={{
                  width: 70,
                  height: 'auto',
                  left: '10px',
                }}
              />
              <ImagePDF
                src={LogoVerdeEscuro}
                style={{
                  width: 100,
                  height: 'auto',
                }}
              />
            </View>
    </Page>
  );
};
const local = StyleSheet.create({
  body: {
    height: "80%",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "#ecf3f4",
  },
  title: {
    color: "#979a9b",
    fontSize: 16,
    padding: "5px",
    alignSelf: "flex-start",
    paddingLeft: "20px",
    marginTop: -25
  },
  tables: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "20px",
    justifyContent: "space-around",
    height: "auto",
    width: "100%",
    alignContent: "center",
    alignSelf: "center",
  },
  tonsTable: {
    paddingLeft: "10px",
    paddingRight: "5px",
    width: "50%",
    alignSelf: "center",
    height: "100%",
  },
  metricTable: {
    paddingRight: "10px",
    width: "40%",
    alignSelf: "center",
    height: "100%",
  },
  text: {
    fontSize: 16,
  },
});