import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import fuelService from "../../../services/fuelService";
import { Container } from "./styles";
import { formatPrice, formatPrice4 } from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import sectorService from "../../../services/sectorService";
import { toast } from "react-hot-toast";
import { Alert } from "../../../components/Alert";

export const FuelFatorsCh4 = () => {
  const history = useHistory();
  const [fuelSelected, setFuelSelected] = useState<any>({});
  const location = useLocation();
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [sectors, setSectors] = useState<any>([]);
  const [year, setYear] = useState<any | null>(null);
  const availableYears = [2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const { user } = useAuth();

  const { register, handleSubmit, setValue } = useForm<any>();

  useEffect(() => {
    const item: any = location?.state;
    if (item && item.fuel_id) {
      getFuelsById(item.fuel_id, year);
    } else {
      history.push('/combustivel');
    }
  }, [location, year]);

  const onSubmit: SubmitHandler<any> = async (formData) => {
    if (formData && year !== null) {
        const setoresMap = new Map<number, number>();
        sectors.forEach((sector: any) => {
            setoresMap.set(sector.sector, 0);
        });

        // Atualiza setores com valores preenchidos
        formData.sector.forEach((sectorData: any, index: number) => {
            const value = sectorData.value.replace(",", ".");
            const sector_id = sectors[index]?.sector;
            if (sector_id !== undefined) {
                setoresMap.set(sector_id, value ? parseFloat(value) : 0);
            }
        });

        // Cria a lista de itens para enviar ao backend
        const itens = Array.from(setoresMap, ([sector_id, value]) => ({
            fuel_id: fuelSelected.id,
            sector_id: sector_id,
            value: value,
            year: year,
        }));

        const request = {
            itens: itens,
        };

        try {
            const result = await fuelService.updateFactorsCh4(fuelSelected.id, year, sanitizeFuelName(fuelSelected.name), request);

            if (result && !result.error) {
                const updatedSector = sectors.map((sector: any) => ({
                    ...sector,
                    value: setoresMap.get(sector.sector) || 0,
                }));

                setSectors(updatedSector);
                setErrorApi(undefined);
                toast.success("Operação realizada com sucesso!");
            } else {
                setErrorApi(result.error || "Erro desconhecido.");
                alert(result.error || "Erro desconhecido.");
            }
        } catch (error) {
            setErrorApi("Ocorreu um erro ao tentar salvar os dados.");
            alert("Ocorreu um erro ao tentar salvar os dados.");
        }
    }
};

  const sanitizeFuelName = (name: string): string => {
    return name.trim().replace('}', '');  
};

async function getFuelsById(id: number, selectedYear: number) {
  if (selectedYear === null) return;

  try {
      const result = await sectorService.getByParams({ limit: 9000, page: 1 });
      const fuel = await fuelService.getById(id); 
      const ch4 = await fuelService.getFactorsCh4(id, selectedYear, fuel.name);

      if (!ch4 || !ch4.itens) {
          console.error('Dados de resultado inválidos');
          setModalMessage(`Para o combustível '${fuel.name}' o ano selecionado '${selectedYear}' não há dados na página Editar. Por favor, adicionar os dados.`);
          setModalIsOpen(true);
          setSectors([]);
          setFuelSelected(fuel);
          return;
      }

      const itens = result.itens;
      const sectorsLabel = [
          'Comercial ou Institucional', 
          'Energia', 
          'Manufatura ou Construção', 
          'Residencial, Agricultura, Florestal ou Pesca' 
      ];

      const sectors = [];

      for (let i = 0; i < 4; i++) {
          const sector = itens[i]; 
          if (!sector || !sector.id) continue;

          let value = 0;
          const sectorId = sector.id; 

          for (const item of ch4.itens) {
              if (item.sector.id === sectorId) { 
                  value = item.value;
                  break;
              }
          }

          const sectorLabel = sectorsLabel[i];
          setValue(`sector.${i}.value`, formatPrice4((value), false));

          sectors.push({
              sector: sectorId, 
              sector_label: sectorLabel,
              value: value,
              year: selectedYear,
          });
      }

      setSectors(sectors);
      setFuelSelected(fuel);
  } catch (error) {
      console.error('Erro ao buscar dados:', error);
      alert("Erro ao buscar dados.");
  }
}
  

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newYear = parseInt(event.target.value, 10);
    setYear(newYear);

    if (fuelSelected && fuelSelected.id) {
      getFuelsById(fuelSelected.id, newYear);
    }
  };

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{4})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
    return value;
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, justifyContent: "space-between", marginBottom: 20 }} >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TitleText level={1} title={fuelSelected.name} />
          </div>
          
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <TitleText level={1} title="Ano: "/>
              <select style={{ 
                    width: '150%',
                    height: "75%", 
                    marginLeft: 10, 
                    padding: '10px', 
                    borderRadius: '4px', 
                    border: '1px solid #ccc', 
                    backgroundColor: '#f9f9f9',
                    marginTop: 10
                  }} 
                  id="yearSelect" 
                  onChange={handleYearChange} 
                  value={year || ""}
                  >
                {availableYears.map(yearOption => (
                  <option key={yearOption} value={yearOption}>
                    {yearOption}
                  </option>
                ))}
              </select>
            </div>
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 10, marginLeft: 50, marginRight: 50  }} >
          <div style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}>
            <TitleText level={2} title="Fatores de Emissão - IPCC (2006)" />
            <TitleText level={3} title="CH₄ (kg/TJ) por setor de atividade" />
          </div>

          <table style={{ width: "90%", borderCollapse: "collapse", marginTop: 5 }}>
            <thead>
              <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                <th style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                  {"Setor"}
                </th>
                <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                  {"Valor"}
                </th>
              </tr>
            </thead>
            <tbody>
              {sectors.map((sector: any, index: number) => (
                <tr key={index} style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                  <td className="list-text title-text-underline" style={{ fontSize: 16, textAlign: "start", padding: "5px" }}>
                    {sector.sector_label}
                  </td>
                  <td style={{ padding: "5px", textAlign: "center" }}>
                    <input
                      type="text"
                      {...register(`sector.${index}.value`)}
                      style={{
                        padding: "4px",
                        width: "30%",
                        fontSize: 14,
                        color: "#31363F",
                        border: "1px solid rgba(49, 54, 63, 0.25)",
                        borderRadius: "2px",
                        backgroundColor: "#FFFFFF",
                        textAlign: "center"
                      }}
                      onChange={(e) => {
                        e.currentTarget.value = currency(e);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </form>
      <Alert
        isOpen={modalIsOpen}
        handleModalVisible={() => setModalIsOpen(false)}
        message={modalMessage}
      />
    </Container>
  );
};
