import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';
import { styles } from '../../../../components/PDF/styles/common';
import { colors } from '../../../../components/PDF/styles/colors';

import checkImage from '../../../../assets/images/check.png'; 
import alertImage from '../../../../assets/images/alert.png'; 

const alertHeight = 10; 
const alertOriginalWidth = 50; 
const alertOriginalHeight = 132; 
const alertProportionalWidth = (alertOriginalWidth / alertOriginalHeight) * alertHeight;

const Legend: React.FC = () => {
  return (
    <View 
      style={{ 
        flexDirection: 'row', 
        justifyContent: 'center', 
        alignItems: 'center', 
        marginVertical: 5, 
        borderColor: colors.darkGrey, 
        borderWidth: 1, 
        borderStyle: 'solid', 
        padding: 5, 
      }}
    >
      <View 
        style={{ 
          flexDirection: 'row', 
          alignItems: 'center',
          marginRight: 15, 
        }}
      >
        <Image
          src={checkImage}
          style={{
            width: 10, 
            height: 10,
            marginRight: 5, 
          }}
        />
        <Text style={styles.text}>Projeto efetivo (CMA negativo)</Text>
      </View>
      <View 
        style={{ 
          flexDirection: 'row', 
          alignItems: 'center',
          marginLeft: 15, 
        }}
      >
        <Image
          src={alertImage}
          style={{
            width: alertProportionalWidth, 
            height: 10,
            marginRight: 5, 
          }}
        />
        <Text style={styles.text}>Custo a ser implementado (CMA positivo)</Text>
      </View>
    </View>
  );
};

export default Legend;
