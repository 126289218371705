import {
  Page,
  Text,
  View,
  StyleSheet,
  Image as ImagePDF,
} from "@react-pdf/renderer";
import React from "react";
import { Header } from "../../components/Header";
import { styles } from "../../styles";
import { IGEEProps } from "../../../interfaces/IGEEData";
import LogoVerdeEscuro from "../../../../../../../assets/images/versoes-logo-icone-ingee-03.png";
import { colors } from "../../../../../../../components/PDF/styles/colors";
import Legend from "../../../../../../MaccCurve/pdf/Legend";
import { LegendItem } from "../../../../../../MaccCurve/table/styles";
import { MaccProject, MaccResult } from "../../../interfaces/MaccProject";
import ResumeProjectTable from "../../../../../../MaccCurve/pdf/ResumeProjectTable";
import checkImage from "../../../../../../../assets/images/check.png"; 
import alertImage from "../../../../../../../assets/images/alert.png";


export const PageDescarbTable: React.FC<IGEEProps> = (props) => {
  const { resume } = props;

  return (
    <Page
      size={[640, 480]}
      style={{ ...styles.Page, backgroundColor: "#ecf3f4" }}
    >
      <Header backColor="#76B987" title="8. Descarbonização" />
      <View style={{ ...local.body }}>
        
        <View style={{ margin: '20px 0' }}>
          <View
            style={{
              flexDirection: 'row',
              padding: 5,
              backgroundColor: colors.lightGrey
            }}
          >
            <Text style={{ ...styles.Bold, width: '30%', fontSize: "10px", marginTop: 8 }}>Projeto</Text>
            <Text style={{ ...styles.Bold, width: '30%', fontSize: "10px", marginTop: 8, marginLeft: 10  }}>
              tCO<Text style={{ fontSize: 5 }}>2</Text>e evitada
            </Text>
            <Text style={{ ...styles.Bold, width: '30%', fontSize: "10px"  }}>
              Custo marginal de{"\n"} abatimento (R$/tCO
              <Text style={{ fontSize: 5 }}>2</Text>e)
            </Text>
          </View>
          {resume?.maccCurve_itens?.macc_curve?.map((item: any, index: any) => (
            <View
              key={index}
              style={{
                flexDirection: 'row',
                padding: 5,
                borderBottomWidth: 1,
                borderBottomColor: '#ccc',
              }}
            >
              <Text style={{ ...styles.light, width: '30%', fontSize: "10px" }}>
                {item.project}
              </Text>
              <Text
                style={{ ...styles.light, width: '30%', fontSize: "10px", marginLeft: 10 }}
              >
                {item.avoided_tco2.toLocaleString('pt-BR', { minimumFractionDigits: 4 })}
              </Text>
              <View style={{ flexDirection: 'row', width: '30%', alignItems: 'center' }}>
                <ImagePDF
                  source={item.marginal_cost < 0 ? checkImage : alertImage}
                  style={
                    item.marginal_cost < 0
                      ? { width: 10, height: 10, marginRight: 5 }
                      : { width: 5, height: 15, marginRight: 5 }
                  }
                />
                <Text style={{ ...styles.light, fontSize: "10px" }}>
                  {item.marginal_cost.toLocaleString('pt-BR', { minimumFractionDigits: 4 })}
                </Text>
              </View>
            </View>
          ))}
          <Legend>
            <LegendItem>
              <Text style={{ color: 'green' }}>✔️</Text> Projeto efetivo (CMA negativo)
            </LegendItem>
            <LegendItem>
              <Text style={{ color: 'red' }}>❗</Text> Custo a ser implementado (CMA positivo)
            </LegendItem>
          </Legend>
        </View>
        
        <View style={{ position: 'absolute', bottom: 10, right: 10, flexDirection: 'row', alignItems: 'center' }}>
              <ImagePDF
                src={resume?.company_itens.logo_image_url}
                style={{
                  width: 70,
                  height: 'auto',
                  left: '10px',
                }}
              />
              <ImagePDF
                src={LogoVerdeEscuro}
                style={{
                  width: 100,
                  height: 'auto',
                }}
              />
            </View>
      </View>
    </Page>
  );
};

const local = StyleSheet.create({
  body: {
    display: "flex",
    width: "100%",
    height: "90%",
    alignItems: "center",
    alignContent: "center",
    padding: "20px",
  },
  title: {
    color: "gray",
    fontSize: 18,
    padding: "5px",
    alignSelf: "flex-start",
  },
  subscript: {
    fontSize: 8,
    lineHeight: 1,
  },
  section: {
    width: "96%",
    height: "80%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  brackets: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "20px",
    width: "8%",
    marginLeft: "-5px",
  },
  bracket: {
    fontSize: 80,
    color: "#a1a4a6",
    marginLeft: "30px",
  },
  texts: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "auto",
    height: "50%",
    marginLeft: 30
  },
  text: {
    fontSize: 9,
  },
});
