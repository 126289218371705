import { Page, Text, View, StyleSheet, Image as ImagePDF } from "@react-pdf/renderer";
import React from "react";
import { Logos } from "../../components/Logos";
import { Header } from "../../components/Header";
import { IGEEProps } from "../../../interfaces/IGEEData";
import { styles, table } from "../../styles";
import { BarChart, sum } from "./chart";
import LogoVerdeEscuro from "../../../../../../../assets/images/versoes-logo-icone-ingee-03.png";



export const Page11: React.FC<IGEEProps> = (props) => {
  const { data } = props;
  const { resume } = props;

  const formatValue = (value: number): string => {
    return value === 0 ? "-" : value.toFixed(2).replace('.', ',');
  };
  
  const chartData = [
    {
      name: ["Combustão", "estacionária"],
      value: sum(
        resume?.resumeIgee?.emissions_by_category?.scope1?.stationary_combustion
      ),
    },
    {
      name: ["Combustão", "móvel"],
      value: sum(
        resume?.resumeIgee?.emissions_by_category?.scope1?.mobile_combustion
      ),
    },
    {
      name: ["Processos", "industriais"],
      value: sum(
        resume?.resumeIgee?.emissions_by_category?.scope1?.industrial_processes
      ),
    },
    {
      name: ["Resíduos", "sólidos"],
      value: sum(
        resume?.resumeIgee?.emissions_by_category?.scope1?.solid_waste_and_liquid_effluents
      ),
    },
    {
      name: ["Efluentes", "líquidos"],
      value: sum(
        resume?.resumeIgee?.emissions_by_category?.scope1
          ?.solid_waste_and_liquid_effluents
      ),
    },
    {
      name: ["Fugitivas", " "],
      value: sum(resume?.resumeIgee?.emissions_by_category?.scope1?.fugitives),
    },
    {
      name: ["Atividades", "agrícolas"],
      value: sum(
        resume?.resumeIgee?.emissions_by_category?.scope1
          ?.agricultural_activities
      ),
    },
    {
      name: ["Mudança no", "uso do solo"],
      value: sum(
        resume?.resumeIgee?.emissions_by_category?.scope1
          ?.agricultural_activities
      ),
    },
  ];

  const Table = () => {
    return (
      <View style={local.table}>
        <View style={table.header}>
          <View style={table.columns}>
            <View style={{ ...table.scope2, width: "20%" }}>
              <Text
                style={{
                  fontSize: 11,
                  ...styles.extraBold,
                  alignSelf: "flex-start",
                  color: "#b47d58",
                  marginBottom: 30
                }}
              >
                <Text style={{ fontSize: 8}}>Total de emissões do {"\n"}</Text>Escopo 1
              </Text>
            </View>
            {chartData.map((d, i) => (
              <View style={{ ...table.scope2, width: "12%" }} key={i}>
                <Text
                  style={{
                    borderRadius: "80%",
                    color: "white",
                    fontSize: 10,
                    alignSelf: "center",
                    textAlign: "center",
                    height: "18px",
                    width: "50px",
                    backgroundColor: "#b47d58",
                    ...styles.light,
                    marginLeft: -20
                  }}
                >
                  {formatValue(d.value)}
                </Text>
              </View>
            ))}
          </View>
        </View>
        <View style={table.body}>
          <View style={{ ...table.row, backgroundColor: "#cfd5d9" }}>
            <Text
              style={{
                color: "#64766d",
                fontSize: 9,
                alignSelf: "center",
                ...styles.Bold,
                width: "18%",
                marginLeft: "10px",
                textAlign: "left",
                lineHeight: 1,
                marginBottom: 8
              }}
            >
              Emissões {"\n"} tCO<Text style={{ fontSize: 5 }}>2</Text>e
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%" }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.stationary_combustion?.emission_tco2e ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%", marginLeft: 5 }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.mobile_combustion?.emission_tco2e ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%", marginLeft: 5 }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.industrial_processes?.emission_tco2e ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%", marginLeft: 5 }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.solid_waste_and_liquid_effluents?.emission_tco2e ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%", marginLeft: 5 }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.solid_waste_and_liquid_effluents?.emission_tco2e ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%", marginLeft: 5 }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.fugitives?.emission_tco2e ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%", marginLeft: 5 }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.agricultural_activities?.emission_tco2e ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%", marginLeft: 5 }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.change_in_land_use?.emission_tco2e ?? 0
              )}
            </Text>
          </View>
          <View style={{ ...table.row, backgroundColor: "white" }}>
            <Text
              style={{
                color: "#64766d",
                fontSize: 9,
                alignSelf: "center",
                ...styles.Bold,
                width: "18%",
                marginLeft: "10px",
                textAlign: "left",
                lineHeight: 1,
                marginBottom: 8
              }}
            >
              Emissões de {"\n"} CO<Text style={{ fontSize: 5 }}>2</Text>{" "}
              biogênico
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%", marginLeft: -8 }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.stationary_combustion?.emission_co2_bio ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%"}}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.mobile_combustion?.emission_co2_bio ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%" }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.industrial_processes?.emission_co2_bio ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%" }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.solid_waste_and_liquid_effluents?.emission_tco2e ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%" }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.solid_waste_and_liquid_effluents?.emission_co2_bio ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%" }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.fugitives?.emission_co2_bio ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%" }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.agricultural_activities?.emission_co2_bio ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%" }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.change_in_land_use?.emission_co2_bio ?? 0
              )}
            </Text>
          </View>
          <View style={{ ...table.row, backgroundColor: "#cfd5d9" }}>
            <Text
              style={{
                color: "#64766d",
                fontSize: 9,
                alignSelf: "center",
                ...styles.Bold,
                width: "18%",
                marginLeft: "10px",
                textAlign: "left",
                lineHeight: 1,
                marginBottom: 8
              }}
            >
              Remoções de {"\n"} CO<Text style={{ fontSize: 5 }}>2</Text>{" "}
              biogênico
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%", marginLeft: -8 }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.stationary_combustion?.removal_of_bio_co2 ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%" }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.mobile_combustion?.removal_of_bio_co2 ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%" }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.industrial_processes?.removal_of_bio_co2 ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%" }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.solid_waste_and_liquid_effluents?.removal_of_bio_co2 ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%" }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.solid_waste_and_liquid_effluents?.removal_of_bio_co2 ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%" }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.fugitives?.removal_of_bio_co2 ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%" }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.agricultural_activities?.removal_of_bio_co2 ?? 0
              )}
            </Text>
            <Text style={{ ...table.rowText, ...styles.light, width: "12%" }}>
              {formatValue(
                resume?.resumeIgee?.emissions_by_category?.scope1?.change_in_land_use?.removal_of_bio_co2 ?? 0
              )}
            </Text>
          </View>
        </View>
      </View>
    );};
  
  return (
    <Page size={[640, 480]} style={styles.Page}>
      <Header backColor="#989c9c" title="6. Resultados" />
      <View style={local.body}>
        <Text style={{ ...local.title, ...styles.boldItalic }}>
          6.3 - Emissões de Escopo 1 desagregadas por categoria
        </Text>
        <View style={local.content}>
          <View style={local.chart}>
            <BarChart data={chartData} width={537.6} height={125} />
          </View>
          <Table />
        </View>
      </View>
      <ImagePDF 
        src={resume?.company_itens.logo_image_url}
        style={{
          width: 50,
          right: '85px',
          position: 'absolute',
          bottom: '14px'
        }}
      />
      <ImagePDF 
        src={LogoVerdeEscuro}
        style={{
          width: 90,
          right: '0px',
          bottom: '14px',
          position: 'absolute'
        }}
      />
    </Page>
  );
};

const local = StyleSheet.create({
  body: {
    display: "flex",
    width: "100%",
    height: "75%",
    paddingVertical: "10px",
    alignItems: "center",
    alignContent: "center",
  },
  chart: {
    width: "537.6px",
    height: "250px",
    display: "flex",
    alignContent: "flex-end",
    alignSelf: "flex-end",
  },
  table: {
    width: "100%",
    padding: "5px",
  },
  title: {
    color: "#b47d58",
    fontSize: 16,
    padding: "5px",
    paddingLeft: "20px",
    alignSelf: "flex-start",
    marginLeft: "15px"
  },
  content: {
    padding: "20px",
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
  },
}
);