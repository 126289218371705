import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/styles";
import { GoBack } from "../../components/GoBack";
import { ModalDelete } from "../../components/ModalDelete";
import { Pagination } from "../../components/Pagination";
import { TitleText } from "../../components/TitleText";
import useAuth from "../../hooks/useAuth";
import useDebounce from "../../hooks/useDebounce";
import userService from "../../services/userService";
import { Container } from "./styles";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

import { StatusLabel } from "./components/StatusLabel";
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Inputs {
  name: string;
  mail: string;
  cpf: string;
  office: string;
  phone: string;
  cellphone: string;
  profile: any;
  companies: any;
}

export const User = () => {
  const history = useHistory();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [users, setUsers] = useState<any>([]);
  const [userSelected, setUserSelected] = useState<any>({});

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const searchDebounced = useDebounce(searchValue, 800);

  const [currentPage, setCurrentPage] = useState(1);
  const [onlyView, setOnlyView] = useState(false);

  const { user } = useAuth();

  async function getUsers(page: number, filter: string) {
    const result = await userService.getByParams({ limit: 25, page, filter: filter });
    setUsers(result);

    setCurrentPage(page)
  }

  useEffect(() => {
    getUsers(1, searchDebounced);

    setOnlyView(user.profile !== 'sinergia' && user.profile !== 'admin')
  }, [searchDebounced, user.profile]);

  useEffect(() => {
    getUsers(1, searchDebounced)
  }, [searchDebounced]);

  async function handleDeleteUser() {
    if (userSelected) {
      const result = await userService.deleteById(
        userSelected?.id
      );

      if (result) {
        setOpenModalDelete(false);
        getUsers(1, searchDebounced);
        
        toast.success("Operação realizada com sucesso!")
      }
    }
  }

  async function getUsersById(id: number) {
    const user = await userService.getById(id);

    setUserSelected(user)
  }

  function redirectToEdit(user: any) {
    history.push({ 
        pathname: '/usuario/edicao',
        state: { user_id: user.id }
    });
  }

  function redirectToInclude() {
    history.push('/usuario/inclusao')
  }

  function formatProfile(profile:string) {
    if (profile === 'admin'){
      return 'Administrador'
    } else if (profile === 'analyst'){
      return 'Analista'
    }else if (profile === 'controller'){
      return 'Auditor'
    }else if (profile === 'director'){
      return 'Diretor'
    }else if (profile === 'sinergia'){
      return 'Super-administrador'
    }

    return ''
  }

  function handleOpenModalDelete(user?: any) {
    setOpenModalDelete(!openModalDelete);

    if (user) {
      getUsersById(user.id);
    } else {
      setUserSelected({});
    }
  }

  const goPrev = () => {
    setCurrentPage(currentPage - 1);
    getUsers(currentPage - 1, searchDebounced);
  };

  const goNext = () => {
    setCurrentPage(currentPage + 1);
    getUsers(currentPage + 1, searchDebounced);
  };

  return (
    <Container>
      <GoBack />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 25,
          justifyContent: "space-between",
        }}
      >
        <TitleText level={1} title="Usuários" />

        <input
          className="input-search"
          placeholder="Pesquisar usuários da plataforma"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        
        {onlyView === false && (
          <Button color="blue" size="md" onClick={() => redirectToInclude()}>
            + Novo Usuário
          </Button>
        )}
        
        {onlyView === true && (
          <div style={{width: 130}}/>
        )}
      </div>

      {users?.itens &&
          (
            <div style={{ overflow: "hidden auto", position: "relative", marginTop: 10,  height: "calc(100vh - 130px)", 
                maxHeight: "calc(100vh - 130px)"}} >
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                    <th
                      style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                    >
                      {"Nome"}
                    </th>
                    <th
                      style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                    >
                      {"Empresa"}
                    </th>
                    <th
                      style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                    >
                      {"Perfil"}
                    </th>
                    <th
                      style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                    >
                      {"Status"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users.itens.map((item: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td style={{ padding: "10px", width: "40%" }} >
                            <span className="title-text-underline">
                              {item.name}
                            </span>
                            <br/>
                            <span style={{ fontSize: 12 }} >
                              {item.mail}
                            </span>
                            {item.organism && item.organism.id && (
                              <div>
                                <span style={{ fontSize: 12 }} >
                                  <b>Organismo: </b>{item.organism.name}
                                </span>
                              </div>
                            )}
                          </td>
                          <td style={{ padding: "10px", width: "20%" }} >
                            <span>
                              {item.companies_name && (
                                item.companies_name
                              )}
                              {item.companies_name === '' && (
                                'Sinergia Engenharia'
                              )}
                            </span>
                          </td>
                          <td style={{ padding: "10px", width: "20%" }} >
                            <span>
                              {formatProfile(item.profile)}
                            </span>
                          </td>
                          <td style={{ padding: "10px", width: "10%" }}>
                            <span>
                              <StatusLabel status={item.active} expired={item.is_expired} locked={item.is_locked} />
                            </span>
                          </td>
                          <td style={{width: "20%"}}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                              {(user.id === item.id || onlyView === true) && (
                                <div style={{width: "80px"}}/>
                              )}
                               {((user.id === item.id || onlyView === false) && item.is_locked === false && item.is_expired === false && item.active === true) && (
                                <button
                                  className="button-edit"
                                  style={{ marginRight: 5 }}
                                  onClick={() => redirectToEdit(item)}
                                >
                                  Editar
                                </button>
                              )}
                              {user.id !== item.id && onlyView === false && (
                                <button
                                  className="button-delete"
                                  style={{ marginRight: 5 }}
                                  onClick={() => handleOpenModalDelete(item)}
                                >
                                  Excluir
                                </button>
                              )}
                              {user.id !== item.id && onlyView === true && (
                                <button
                                  className="button-view"
                                  style={{ marginRight: 5 }}
                                  onClick={() => redirectToEdit(item)}
                                >
                                  Visualizar
                                </button>
                              )}
                             
                              {((user.id === item.id || onlyView === false) && item.is_locked === true && item.is_expired === false && item.active === true) && (
                                <button
                                  className="button-view"
                                  style={{ marginRight: 5 }}
                                  onClick={() => redirectToEdit(item)}
                                >
                                  Desbloquear
                                </button>
                              )}
                              {((user.id === item.id || onlyView === false) && item.is_locked === false && (item.is_expired === true || item.active === false)) && (
                                <button
                                  className="button-view"
                                  style={{ marginRight: 5 }}
                                  onClick={() => redirectToEdit(item)}
                                >
                                  Reativar
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
                {users?.itens?.length > 0 && (
                  <Pagination
                    total={users.total}
                    limit={25}
                    goNext={goNext}
                    goPrev={goPrev}
                    currentPage={currentPage}
                    length={users.itens.length}
                  />
                )}

            </div>
          )
        }

      {openModalDelete && (
        <ModalDelete
          isOpen={openModalDelete}
          handleModalVisible={handleOpenModalDelete}
          entityName={userSelected.name}
          handleOk={handleDeleteUser}
        />
      )}
    </Container>
  );
};
