import { api } from "./api";

async function getById(helpId: number) {
  try {
    const { data } = await api.get(`/help/${helpId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/help", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(help: any) {
  try {
    const { data } = await api.post(`/help`, help);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(helpId: number, help: any) {
  try {
    const { data } = await api.put(`/help/${helpId}`, help);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(helpId: number) {
  try {
    const { data } = await api.delete(`/help/${helpId}`);
    return data;
  } catch (error) {
    return false;
  }
}

async function openTicket(help: any) {
  try {
    const { data } = await api.post(`/help/ticket`, help);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

const helpService = {
  getById,
  getByParams,
  create,
  updateById,
  deleteById, 
  openTicket
};

export default helpService;
