import styled from 'styled-components';

export const Container = styled.div`
    padding: 0px;
    margin: 15px;
    width: 100%;

    .table-scope {
        border-color: red;
        border-width: 1px;
        border-style: solid;
    }

    .table-scope tr,
    .table-scope th,
    .table-scope td {
        border-color: white;
        border-width: 1px;
        border-style: solid;
    }
    
    .table-scope td {
        background: #F6F6F6;
    }

    .head-tr-item-bottom{
        background-color: #C6C5C5;
        font-size: 12px;
        height: 25px;
    }

    .head-tr-item{
        background-color: #D8D7D7 !important;
        font-size: 12px;
        height: 25px;
    }

    .subhead-item{
        background-color: #F6F6F6 !important;
        font-size: 12px;
        height: 25px;
        text-align: right;
        font-weight: bold;
    }

    .input-item{
        background: #ffffff;
        margin: 2px;
        padding-right: 2px;
        padding-left: 2px;
        height: 30px !important;
        border: 1px solid #DFDFDF;
        border-radius: 2.5px;
    }

    .title{
        display: flex;
        flex-direction: column;
    }

    .title-2{
        display: flex;
        flex-direction: row;
        margin-top: 25px;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    
    .title-3{
        display: flex;
        flex-direction: row;
    }

    .botton-blue {
        color: blue;
        font-size: medium;
        margin: auto 10px auto auto;
}
    .divisor {
        width: 100%;
        border-top: 0.6px solid rgba(49, 54, 63, 0.35)
}

    .title-4{
        display: flex;
        flex-direction: column;
        margin:10px;
}   

    .label-1{
        margin-top: 15px;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: 19px;
        color: #31363F;
        opacity: 0.8;
        font-weight: bold;
}
    .textarea{
        width: 95%;;
        height:100px;
    }
    .register-1{
        overflow: hidden auto;
        position: relative;
        margin-top: 0px;
        height: calc(100vh - 230px);
        max-height: calc(100vh - 230px);
    }
    
    .register-2{
        display: flex;
        flex-direction: column;
        width: 98%;
        margin-top: 10px;
        margin-left: 10px;
    }
    .register-3{
        display: flex;
        flex-direction: row;
        margin-top: 0px;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .register-4{
        display: flex;
        flex-direction: column;
        margin-right: 30px;
    }
    .register-5{
        margin-top: 15px;
        margin-right: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .register-6{
        overflow: auto;
        position: relative;
        margin-top: 0px;
        width: calc(100vw - 320px);
        max-width: calc(100vw - 320px);
    }
    .table{
        max-height: 100%;
        width: 4000px;
        border-collapse: collapse;
    }



    .span-1{
        text-align: start;
        font-size: 14px;
        font-weight: bold;
    }
    .span-2{
        text-align: start;
        font-size: 12px;
        margin-top: 5px;
    }
    .span-3{
        margin-left: 5px;
        width: 200px;
        font-size: 12px;
        font-weight: bold;
        display: inline-block;
    }
    .span-4{
        margin-right: 5px;
        width: 120px;
        font-size: 12px;
        text-align: right;
        display: inline-block;
    }
    .delete{
        cursor: pointer;
        text-align: center;
        width: 70px;
    }

    
    .td-1{
        width: 200px;
    }














`;