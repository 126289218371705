import { useState, useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { Container } from './styles'

import { Company } from './interfaces/Company'
import { Option } from './interfaces/Option'

import DownloadPdfButton from './pdf'

import projectMaccService from '../../services/projectMaccService'

import { TitleText } from '../../components/TitleText'
import { GoBack } from '../../components/GoBack'
import { Line } from '../../components/Line'
import InfoButtonMin from '../../assets/icons/info-sign.png'
import companyService from '../../services/companyService'
import { ValueType } from 'react-select'
import HeaderWithTooltip from '../../components/HeaderContainer'
import WrappedUninformed from '../../components/WrappedUninformed'
import { MACCChart } from './utils/MACCChart'
import { calculateCMAandEmissions } from '../../utils/utils'
import { MaccTable } from './table/MaccTable'
import { MaccResult } from './interfaces/MaccProjetcs'
import InnerContainer from '../../components/InnerContainer'
import { MACCChartPDF } from './utils/MACCChartPDF'
import { Loading } from '../../components/Loading'

const title = 'Curva MACC'
const buttonLabel = 'Projetos de Descarbonização'
const titleText = 'Ranqueamento das Iniciativas de Descarbonização'
const UninformedLabel = 'Nenhum projeto encontrado' 
const maccCurveDescription = `A curva MACC é uma ferramenta que possibilita a análise financeira de um projeto, 
evidenciando o custo-benefício de sua implementação, auxiliando na tomada de decisão.  
A curva MACC é expressa através de um gráfico de barras, relacionando o custo de um projeto pela tonelada de CO₂e emitida/reduzida. 
A partir da curva MACC, é possível comparar diferentes projetos e seu custo-benefício. 
Barras acima do eixo horizontal representam projetos que exigem investimento líquido de capital, 
e as barras abaixo do eixo horizontal representam projetos que geram um ganho financeiro para a organização.`

export const MaccCurve = () => {
  const history = useHistory()

  const [companies, setCompanies] = useState<Company[]>()
  const [projectsMacc, setProjectsMacc] = useState<any[]>([])
  const [selectedCompany, setSelectedCompany] = useState<any>(null)
  const [maccCurveProjects, setMaccCurveProjects] = useState<[]>([])
  const [barChartImageBase64, setBarChartImageBase64] = useState<string>('')
  const [selectCompanyValue, setSelectCompanyValue] = useState<any>()
  const [calculatedCMA, setCalculatedCMA] = useState<MaccResult[]>([])
  const [loadingModal, setLoadingModal] = useState(false)

  const handleBarImageChange = useCallback(
    (image: string) => {
      setBarChartImageBase64(image);
    },
    []
  );

  const companiesOptions = useMemo(
    () =>
      companies?.map(
        (company: Company): Option => ({
          label: company.corporate_name,
          value: company.id
        })
      ),
    [companies]
  )

  const getCompanies = async () => {
    const result = await companyService.getByParams({ limit: 9000, page: 1 })
    setCompanies(result?.itens)
  }

  async function getProjects(id: number) {
    setLoadingModal(true)
    const projects = await projectMaccService.getByParams({ company: id, limit: 99999, page: 1 })
    setProjectsMacc(projects?.itens)
    setLoadingModal(false)
  }

  const handleSelectedCompanyChange = useCallback(
    (item: ValueType<Option, false>) => {
      const company = companies?.find((company: Company) => company.id === item?.value)
      setSelectedCompany(company)
      setSelectCompanyValue(item)

      // Salva a empresa selecionada no localStorage
      if (item) {
        localStorage.setItem('selectedCompany', JSON.stringify(item))
      } else {
        localStorage.removeItem('selectedCompany')
      }
    },
    [companies]
  )

  useEffect(() => {
    const result = calculateCMAandEmissions(projectsMacc).calculatedCMA
    setCalculatedCMA(result)
  }, [projectsMacc])

  useEffect(() => {
    if (selectedCompany) {
      getProjects(selectedCompany.id)
      getMaccCurveProjects(selectedCompany.id)
    }
  }, [selectedCompany])

  useEffect(() => {
    getCompanies()
  }, [])

  useEffect(() => {
    // Recupera a empresa selecionada do localStorage
    const storedCompany = localStorage.getItem('selectedCompany')
    if (storedCompany && companiesOptions) {
      const parsedCompany = JSON.parse(storedCompany)
      handleSelectedCompanyChange(parsedCompany)
    } else if (companiesOptions) {
      handleSelectedCompanyChange(companiesOptions[0])
    }
  }, [companiesOptions, handleSelectedCompanyChange])

  async function getMaccCurveProjects(id: number) {
    const projects = await projectMaccService.getGraphic({ company: id })
    setMaccCurveProjects(projects?.itens)
  }

  const redirectToProjects = () => {
    if (selectedCompany) {
      history.push({
        pathname: '/curva-macc/projetos',
        state: { company_id: selectedCompany.id }
      })
    }
  }

  const hasMaccCurveProjects = !!maccCurveProjects.length

  return (
    <Container>
      <GoBack />
      <HeaderWithTooltip
        title={title}
        tooltipImageSrc={InfoButtonMin}
        tooltipDescription={maccCurveDescription}
        selectValue={selectCompanyValue}
        selectOption={companiesOptions}
        selectChange={handleSelectedCompanyChange}
        buttonRedirect={redirectToProjects}
        buttonLabel={buttonLabel}
        buttonHasRedirect={true}
        hasDownload={hasMaccCurveProjects}
        downloadComponent={
          <DownloadPdfButton
            projects={projectsMacc}
            CMA={calculatedCMA}
            company={selectedCompany}
            chartImageBase64={barChartImageBase64}
          />
        }
      />

      <InnerContainer>
        <Line />
        <TitleText level={4} title={titleText} />
        {hasMaccCurveProjects ? (
          <>
            <MACCChartPDF projects={projectsMacc} onChangeImage={handleBarImageChange} />
            <MACCChart projects={projectsMacc} />
            <MaccTable projects={calculatedCMA} />
          </>
        ) : (
          <WrappedUninformed text={UninformedLabel} />
        )}
      </InnerContainer>
      {loadingModal && <Loading isOpen={loadingModal} />}
    </Container>
  )
}
