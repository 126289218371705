import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import { styles as tableStyles } from '../../../../components/PDF/styles/table'
import { styles } from '../../../../components/PDF/styles/common'
import { TheadItem } from '../../../../components/PDF/TheadItem'
import { TbodyItem } from '../../../../components/PDF/TbodyItem'
import { formatPrice3 } from '../../../../utils/transform'

export const ConsolidatedEmissions: React.FC<any> = (props) => {
  const { pdfData } = props
  const { metricTons, tonsOfGas } = pdfData
  // const { downstreamTco2e} = props

  return (
    <View wrap={false} style={styles.textContainer}>
      <Text style={{...tableStyles.tableTitle, ...styles.light}}>
        Emissões consolidadas, por tipo de GEE e escopos
      </Text>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <View style={{ width: '11.11%' }} />
        <View style={tableStyles.mainTheadItem}>
          <Text style={{ textAlign: 'center', ...styles.light }}>
            Em toneladas de gás
          </Text>
        </View>
        <View style={tableStyles.mainTheadItem}>
          <Text style={{ textAlign: 'center', ...styles.light }}>
            Emissões em toneladas métricas de CO
            <Text style={styles.subscript}>₂</Text> equivalente (tCO
            <Text style={styles.subscript}>₂</Text>e)
          </Text>
        </View>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TheadItem width="11.11%">GEE</TheadItem>
        <TheadItem width="11.11%">Escopo 1</TheadItem>
        <View style={{ ...styles.column, width: '22.22%' }}>
          <TheadItem>Escopo 2</TheadItem>
          <View style={styles.row}>
            <TheadItem width="50%" background="#BFBFBF">
              Localização
            </TheadItem>
            <TheadItem width="50%" background="#BFBFBF">
              Escolha de Compra
            </TheadItem>
          </View>
        </View>
        <TheadItem width="11.11%">Escopo 3</TheadItem>
        <TheadItem width="11.11%">Escopo 1</TheadItem>
        <View style={{ ...styles.column, width: '22.22%' }}>
          <TheadItem>Escopo 2</TheadItem>
          <View style={styles.row}>
            <TheadItem width="50%" background="#BFBFBF">
              Localização
            </TheadItem>
            <TheadItem width="50%" background="#BFBFBF">
              Escolha de Compra
            </TheadItem>
          </View>
        </View>
        <TheadItem width="11.11%">Escopo 3</TheadItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem width="11.11%">
          CO<Text style={styles.subscript}>₂</Text>
        </TbodyItem>

        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope1.emission_co2, false)}
        </TbodyItem>

        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope2.location.emission_co2, false)}
        </TbodyItem>

        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope2.purchase_choice.emission_co2, false)}
        </TbodyItem>

        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope3.emission_co2, false)}
        </TbodyItem>

        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope1.emission_co2 , false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope2.location.emission_co2, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope2.purchase_choice.emission_co2, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope3.emission_co2, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem width="11.11%">
          CH<Text style={styles.subscript}>₄</Text>
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope1.emission_ch4, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope2.location.emission_ch4, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope2.purchase_choice.emission_ch4, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope3.emission_ch4, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope1.emission_ch4, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope2.location.emission_ch4, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope2.purchase_choice.emission_ch4, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope3.emission_ch4, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem width="11.11%">
          N<Text style={styles.subscript}>₂</Text>O
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope1.emission_n2o, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope2.location.emission_n2o, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope2.purchase_choice.emission_n2o, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope3.emission_n2o, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope1.emission_n2o, false)}
        </TbodyItem>

        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope2.location.emission_n2o, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope2.purchase_choice.emission_n2o, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope3.emission_n2o, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem width="11.11%">HFCs</TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope1.emission_hfcs, false)}
        </TbodyItem>

        <TbodyItem textAlign="right" width="11.11%">
          -
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          -
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope3.emission_hfcs, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope1.emission_hfcs, false)}
        </TbodyItem>

        <TbodyItem textAlign="right" width="11.11%">
          -
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          -
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope3.emission_hfcs, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem width="11.11%">PFCs</TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope1.emission_pfcs, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          -
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          -
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope3.emission_pfcs, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope1.emission_pfcs, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          -
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          -
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope3.emission_pfcs, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem width="11.11%">
          SF<Text style={styles.subscript}>₆</Text>
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope1.emission_sf6, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          -
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          -
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope3.emission_sf6, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope1.emission_sf6, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          -
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          -
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope3.emission_sf6, false)}
        </TbodyItem>
      </View>
      <View style={{ ...tableStyles.container, ...styles.row }}>
        <TbodyItem width="11.11%">
          NF<Text style={styles.subscript}>₃</Text>
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope1.emission_nf3, false)}
        </TbodyItem>

        <TbodyItem textAlign="right" width="11.11%">
          -
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          -
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(tonsOfGas.scope3.emission_nf3, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope1.emission_nf3, false)}
        </TbodyItem>

        <TbodyItem textAlign="right" width="11.11%">
          -
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          -
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.scope3.emission_nf3, false)}
        </TbodyItem>
      </View>
      <View style={tableStyles.totalRow}>
        <TbodyItem width="11.11%">Total</TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.totals.scope1_total, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.totals.scope2_location_total, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.totals.scope2_purchase_choice_total, false)}
        </TbodyItem>
        <TbodyItem textAlign="right" width="11.11%">
          {formatPrice3(metricTons.totals.scope3_total, false)}
        </TbodyItem>
      </View>
    </View>
  )
}
