import { useState } from "react"
import { Button } from "../../../../components/Button/styles"
import { Modal } from "../../../../components/Modal"
import { TitleText } from "../../../../components/TitleText"
import { Container } from "./styles"

type ModalDeleteProps = {
    isOpen: boolean;
    entityName: string;
    handleModalVisible: () => void;
}

export const ModalScopeDisabled = ({ isOpen, handleModalVisible, entityName }: ModalDeleteProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [errorSubmit, setErrorSubmit] = useState(false);

    return (
        <Modal
            isOpen={isOpen}
            isPayOrTypeClient={true}
            handleModalVisible={handleModalVisible}
        >
            <Container>
                <div style={{ margin: 5, textAlign: "center" }}>
                    <TitleText level={2} title="ATENÇÃO" color="red" />
                </div>

                <div style={{ margin: 5, textAlign: "center"  }}>
                    <span style={{fontWeight: "bold"}}>O Escopo </span> 
                    <span style={{fontWeight: "bold", color:"#326ed6"}}>{entityName}</span> 
                    <span style={{fontWeight: "bold"}}> não está liberado para preenchimento.</span>
                </div>

                <div style={{ margin: 5, textAlign: "center"  }}>
                    <span style={{fontSize: 12}}>
                        Caso deseje preenchê-lo, favor contactar a Sinergia Engenharia para liberação.
                    </span>
                </div>

                <div style={{ marginTop: 15, textAlign: "center"  }}>
                    <Button color='black' type="button" size="md"
                            onClick={handleModalVisible}
                            style={{  width: '120px', }}>
                        OK
                    </Button>
                </div>
            </Container>
        </Modal >
    )
}