import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../components/Button/styles";
import { GoBack } from "../../components/GoBack";
import { TitleText } from "../../components/TitleText";
import useAuth from "../../hooks/useAuth";
import mailService from "../../services/mailService";
import { Container } from "./styles";

import { toNumberValues } from "../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

interface Inputs {
  smtp: string;
  port: string;
  user: string;
  is_ssl: boolean;
  is_tls: boolean;
  password: string;
  mail_support: string;
}

export const Mail = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const history = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mailSelected, setFuelSelected] = useState<any>({});
  const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();

  useEffect(() => {
    getItem()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps 
  [location]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const newFuel = {
        smtp: formData.smtp,
        user: formData.user,
        is_ssl: formData.is_ssl,
        is_tls: formData.is_tls,
        password: formData.password, 
        mail_support: formData.mail_support,
        port: parseInt(formData.port),
      };

      const result = await mailService.save(newFuel);

      if (result && !result.error) {
        reset();
        setErrorApi(undefined);
        getItem()

        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error);
      }
    }
  };

  async function getItem() {
    const mail = await mailService.getItem();

    setValue("smtp", mail?.smtp);
    setValue("port", mail?.port);
    setValue("user", mail?.user);
    setValue("is_ssl", mail?.is_ssl);
    setValue("is_tls", mail?.is_tls);
    setValue("password", mail?.password);
    setValue("mail_support", mail?.mail_support);

    setFuelSelected(mail)
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Configurações de E-mail" />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 20, marginLeft: 50, marginRight: 50  }} >
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              E-mail de Suporte
          </label>

          <input style={{width: '92%'}}
                {...register("mail_support", { required: true })}/>
                      
          {errors.mail_support && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 20, marginLeft: 50, marginRight: 50  }} >
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Usuário
          </label>

          <input style={{width: '92%'}}
                {...register("user", { required: true })}/>
                      
          {errors.user && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 20, marginLeft: 50, marginRight: 50  }} >
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Senha
          </label>

          <input style={{width: '92%'}} type="password"
                {...register("password", { required: true })}/>
                      
          {errors.password && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 20, marginLeft: 50, marginRight: 50  }} >
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              SMTP
          </label>

          <input style={{width: '92%'}}
                {...register("smtp", { required: true })}/>
                      
          {errors.smtp && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 20, marginLeft: 50, marginRight: 50  }} >
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Porta
          </label>

          <input style={{width: '92%'}}
                {...register("port", { required: true })}
                onChange={(event) => {
                  const { value } = event.target;
                  event.target.value = toNumberValues(value);
                }}/>
                      
          {errors.port && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>

        <div style={{ display: "flex", flexDirection: "row", width: "95%", marginLeft: 50, marginRight: 50  }}>
          <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
            <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                SSL
            </label>

            <input style={{ height: "25px", width: "25px" }}
                    type="checkbox" {...register("is_ssl")}/>
                        
            {errors.is_ssl && (
                <span className='requiredLabel' >Esse campo é obrigatório</span>
            )}
          </div>

          <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
            <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                TLS
            </label>

            <input style={{ height: "25px", width: "25px" }}
                    type="checkbox" {...register("is_tls")}/>
                        
            {errors.is_tls && (
                <span className='requiredLabel' >Esse campo é obrigatório</span>
            )}
          </div>
        </div>
      </form>
    </Container>
  );
};
