import styled from 'styled-components';

export const Container = styled.div`
    padding: 0px;
    margin: 15px;
    width: 100%;

    .table-scope {
        border-color: red;
        border-width: 1px;
        border-style: solid;
    }

    .table-scope tr,
    .table-scope th,
    .table-scope td {
        border-color: white;
        border-width: 1px;
        border-style: solid;
    }
    
    .table-scope td {
        background: #F6F6F6;
    }

    .head-tr-item-bottom{
        background-color: #C6C5C5;
        font-size: 12px;
        height: 25px;
    }

    .head-tr-item{
        background-color: #D8D7D7 !important;
        font-size: 12px;
        height: 25px;
    }

    .input-item{
        background: #ffffff;
        margin: 2px;
        padding-right: 2px;
        padding-left: 2px;
        height: 30px !important;
        border: 1px solid #DFDFDF;
        border-radius: 2.5px;
    }

    .div-model-1{
        overflow: hidden auto;
        position: relative;
        margin-top: 0px;
        height: calc(100vh - 230px);
        max-height: calc(100vh - 230px);
    }

    .div-model-2{
        display: flex;
        flex-direction: column;
        width: 98%;
        margin-top: 10px;
        margin-left: 10px;
    }
    .div-model-3{
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        margin-right: 30px;
    }
    .div-model-4{
        display: flex;
        flex-direction: row;
        margin-top: 0;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .div-model-5{
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        margin-right: 30px;
    }
    
    .div-model-6{
        display: flex;
        flex-direction: row;
        margin:0;
    }
    .div-model-7{
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        margin-bottom:10px;
        margin-right: 30px;
    }
    .div-model-8{
        border-style: dashed;
        margin: 5px;
        border-width: 1px;
        width: 100%;
        border-color: #9E9E9E;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-left: 0px;
    }
    .div-model-9{
        width: 100%;
        display: flex;
        flex-direction: row;
    }
    .div-model-10{
        display: flex;
        flex-direction: row;
        color: #C42329;
        font-size: 12px;
        font-weight: bold;
        margin-left: 10px;
    }
    .div-model-11{
        border-style: dashed;
        margin: 5px;
        border-width: 1px;
        width: 100%;
        border-color: #9E9E9E;
        height: 40px;
        display: flex;
        flex-direction: row;
        margin-left: 0px;
    }
    .div-model-12{
        display: flex;
        flex-direction: column;
        margin: 10px;
    }
    .div-model-13{
        width: 100%;
        border-top: 0.6px solid rgba(49, 54, 63, 0.35);
    }

    
    .span-model-1{
        text-align: start;
        font-size: 16px;
        margin-top: 5px;
        font-weight: bold;
    }
    .span-model-2{
        text-align: start;
        font-size: 14px;
        margin-top: 5px;
    }
    .span-model-3{
        margin: 10px;
        width: 70%;
    }
    .span-model-4{
        font-weight: bold;
        margin: 10px;
        text-align: right;
        width: 30%;
        
    }

    .span-model-5{
        text-align: start;
        font-size: 16px;
        margin-top: 5px;
        margin-left: 10px;
        font-weight: bold;
    }
    .label-model {
        margin-top:5px;
        font-family: 'Roboto', sans-serif;
    }
    
    .input-model {
        width: 200px;
        height: 35px;
        text-align: right;
    }

    .label-model-1{
        margin-top: 15px;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: 19px;
        color: #31363F;
        opacity: 0.8;
        font-weight: bold;
        }

    .tooltip .tooltiptext {
        font-family: "Roboto";
        visibility: hidden;
        width: 250px;
        background-color: #31363F;
        color: #fff;
        text-align: left;
        padding: 5px;
        border-radius: 2px;
        position: absolute;
        transition: all .2s ease;
        z-index: 1;
        opacity: 0.9;
        
    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
    }

    `;