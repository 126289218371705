import { Page, Text, View, StyleSheet, Image as ImagePDF} from "@react-pdf/renderer";
import React from "react";
import { Logos } from "../../components/Logos";
import { Header } from "../../components/Header";
import { DonutChart } from "./chart";
import { IGEEProps } from "../../../interfaces/IGEEData";
import { styles, table } from "../../styles";
import LogoVerdeEscuro from "../../../../../../../assets/images/versoes-logo-icone-ingee-03.png";

export const Page9: React.FC<IGEEProps> = (props) => {
  const { data } = props;
  const { resume } = props;

  const hasMetrics = resume?.resumeIgee.metric_tons ? true : false;
  const hasTotals = resume?.resumeIgee?.metric_tons?.totals ? true : false;

  function breakCompanyName(companyName: any, limit = 15) {
    if (companyName.length > limit) {
      const words = companyName.split(' ');
      
      let brokenName = '';
      let currentLine = '';
  
      for (let i = 0; i < words.length; i++) {
        if ((currentLine + words[i]).length > limit) {
          brokenName += currentLine.trim() + '\n'; 
          currentLine = ''; 
        }
        currentLine += words[i] + ' '; 
      }
      
      brokenName += currentLine.trim();
  
      return brokenName;
    } else {
      return companyName;
    }
  }

// const donutData = [
//   {
//     value: hasMetrics && hasTotals ? (resume?.resumeIgee?.metric_tons?.totals?.scope1_total)?.toFixed(2).replace('.', ',') : 0,
//     label: "Escopo 1",
//   },
//   {
//     value: hasMetrics && hasTotals ?
//       (resume?.resumeIgee?.metric_tons?.totals?.scope2_location_total ?? 0) +
//       (resume?.resumeIgee?.metric_tons?.totals?.scope2_purchase_choice_total ?? 0) : 0,
//     label: "Escopo 2",
//   },
//   {
//     value: hasMetrics && hasTotals ? resume?.igee_itens.scope3_total.emission_tco2e : 0,
//     label: "Escopo 3",
//   },
//   {
//     value: resume?.resumeIgee.not_kyoto?.[resume?.resumeIgee.not_kyoto.length - 1]?.emission_tco2e ?? 0,
//     label: "Outros gases*",
//   },
// ];

const GOALS = [
  {
    label: "escopo 1",
    value: (resume?.resumeIgee?.metric_tons?.totals?.scope1_total ?? 0).toFixed(2).replace('.', ','),
  },
  {
    label: "escopo 2",
    value: (
      (resume?.resumeIgee?.metric_tons?.totals?.scope2_purchase_choice_total ?? 0) +
      (resume?.resumeIgee?.metric_tons?.totals?.scope2_location_total ?? 0)
    ).toFixed(2).replace('.', ','),
  },
  {
    label: "escopo 3",
    value: (resume?.igee_itens.scope3_total.emission_tco2e ?? 0).toFixed(2).replace('.', ','),
  },
  {
    label: "emissões \n totais",
    value:
    (
      (resume?.resumeIgee?.metric_tons?.totals?.scope1_total ?? 0) +
      (resume?.resumeIgee?.metric_tons?.totals?.scope2_purchase_choice_total ?? 0) +
      (resume?.resumeIgee?.metric_tons?.totals?.scope2_location_total ?? 0) +
      (resume?.igee_itens.scope3_total.emission_tco2e ?? 0)
    ).toFixed(2).replace('.', ','),
  },
  {
    label: "Outros \n gases",
    value: (resume?.resumeIgee.not_kyoto?.[resume?.resumeIgee.not_kyoto.length - 1]?.emission_tco2e ?? 0).toFixed(2).replace('.', ','),
  },
];

const totalScope1 = resume?.resumeIgee?.metric_tons?.totals?.scope1_total ?? 0;
const totalScope2 = (resume?.resumeIgee?.metric_tons?.totals?.scope2_location_total ?? 0) +
                    (resume?.resumeIgee?.metric_tons?.totals?.scope2_purchase_choice_total ?? 0);
const totalScope3 = resume?.igee_itens.scope3_total.emission_tco2e ?? 0;
const totalKioto = resume?.resumeIgee.not_kyoto?.[resume?.resumeIgee.not_kyoto.length - 1]?.emission_tco2e ?? 0;
const total = totalScope1 + totalScope2 + totalScope3 + totalKioto;

const TableData = [
  {
    label: breakCompanyName(resume?.company_itens.corporate_name),
    scope1: {
      value: totalScope1,
      percentage: total ? (totalScope1 / total * 100) : 0,
    },
    scope2: {
      value: totalScope2,
      percentage: total ? (totalScope2 / total * 100) : 0,
    },
    scope3: {
      value: totalScope3,
      percentage: total ? (totalScope3 / total * 100) : 0,
    },
    outrosGases: {
      value: totalKioto,
      percentage: total ? (totalKioto / total * 100) : 0,
    },
    total: {
      value: total,
      percentage: 100,
    },
  },
];

const donutData = [
  {
    value: totalScope1,
    label: "Escopo 1",
  },
  {
    value: totalScope2,
    label: "Escopo 2",
  },
  {
    value: totalScope3,
    label: "Escopo 3",
  },
  {
    value: totalKioto,
    label: "Outros gases*",
  },
];
   
 

  const Table = () => {
    return (
      <View style={local.table}>
        <View style={table.header}>
          <View style={table.columns}>
            <View style={table.scope2}>
              <Text
                style={{
                  fontSize: 11,
                  ...styles.Bold,
                  alignItems: "center",
                  color: "gray",
                  marginRight: 5,
                  lineHeight: 1.5,
                  marginBottom: 3
                }}
              >
                Unidades {"\n"} de negócio
              </Text>
            </View>
            <View style={table.scope2}>
              <Text
                style={{
                  ...table.headerColumn,
                  backgroundColor: "#b47d58",
                  ...styles.Bold,
                  marginTop: 5,
                  marginLeft: -2
                }}
              >
                Escopo 1
              </Text>
            </View>
            <View style={table.scope2}>
              <Text
                style={{
                  ...table.headerColumn,
                  backgroundColor: "#2a71b8",
                  ...styles.Bold,
                  marginTop: 5
                }}
              >
                Escopo 2
              </Text>
            </View>
            <View style={table.scope2}>
              <Text
                style={{
                  ...table.headerColumn,
                  backgroundColor: "#76b987",
                  ...styles.Bold,
                  marginTop: 5
                }}
              >
                Escopo 3
              </Text>
            </View>
            <View style={table.scope2}>
              <Text
                style={{
                  ...table.headerColumn,
                  backgroundColor: "#b3b3b3",
                  ...styles.Bold,
                  marginTop: 5
                }}
              >
                Outros gases
              </Text>
            </View>
          </View>
        </View>
        <View style={table.body}>
          {TableData.map((categorie, i) => {
            return (
              <View key={i}>
                <View
                  style={{
                    ...table.row,
                    backgroundColor: i % 3 ? "" : "#cfd5d9",
                  }}
                >
                  <Text style={{ ...table.rowText, ...styles.light, marginBottom: 2, width: 80, marginLeft: -15}}>
                    {categorie.label || '-'}
                  </Text>
                  <Text style={{ ...table.rowText, ...styles.light, textAlign: "left", marginLeft: -25 }}>
                    {typeof categorie.scope1?.value === 'number' ? categorie.scope1?.value.toFixed(2).replace('.', ',') : '-'}
                  </Text>
                  <Text style={{ ...table.rowText, ...styles.light, textAlign: "left" }}>
                    {typeof categorie.scope2?.value === 'number' ? categorie.scope2?.value.toFixed(2).replace('.', ',') : '-'}
                  </Text>
                  <Text style={{ ...table.rowText, ...styles.light, textAlign: "left"}}>
                    {typeof categorie.scope3?.value === 'number' ? categorie.scope3?.value.toFixed(2).replace('.', ',') : '-'}
                  </Text>
                  <Text style={{ ...table.rowText, ...styles.light, textAlign: "left", marginRight: 5}}>
                    {typeof categorie.outrosGases?.value === 'number' ? categorie.outrosGases?.value.toFixed(2).replace('.', ',') : '-'}
                  </Text>
                </View>
                <View style={{ ...table.row }}>
                  <Text style={{ ...table.rowText, ...styles.light, fontSize: 9, marginLeft: -8}}>
                    Porcentagem
                  </Text>
                  <Text style={{ ...table.rowText, ...styles.light, fontSize: 9, marginLeft: -10 }}>
                    {typeof categorie.scope1?.percentage === 'number' ? categorie.scope1?.percentage.toFixed(2).replace('.', ',') + '%' : '-'}
                  </Text>
                  <Text style={{ ...table.rowText, ...styles.light, fontSize: 9 }}>
                    {typeof categorie.scope2?.percentage === 'number' ? categorie.scope2?.percentage.toFixed(2).replace('.', ',') + '%' : '-'}
                  </Text>
                  <Text style={{ ...table.rowText, ...styles.light, fontSize: 9 }}>
                    {typeof categorie.scope3?.percentage === 'number' ? categorie.scope3?.percentage.toFixed(2).replace('.', ',') + '%' : '-'}
                  </Text>
                  <Text style={{ ...table.rowText, ...styles.light, fontSize: 9 }}>
                    {typeof categorie.outrosGases?.percentage === 'number' ? categorie.outrosGases?.percentage.toFixed(2).replace('.', ',') + '%' : '-'}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
      </View>

      
    );
  };
 
  
  const Component = () => {
    return (
      <Page size={[640, 480]} style={styles.Page}>
        <Header backColor="#b3b3b3" title="6. Resultados" />
        <View style={local.body}>
          <Text style={{ ...local.title, ...styles.italic}}>
            6.1 Emissões totais do ano inventariado
          </Text>
          <View style={local.section}>
            <View style={{...local.donutChart}}>
              <DonutChart data={donutData} />
              <Text style={{ ...local.donutSub, ...styles.extraLight, marginTop: 50 }}>
                *Outros gases não contemplados pelo Protocolo de Quioto
              </Text>
            </View>
            <Table />
          </View>
          <View style={{ width: "100%", borderTop: "1px solid #76B987" }}>
            <View style={local.footer}>
              <View style={local.bolinhas}>
                {GOALS.map((goal, idx) => (
                  <View style={local.bolinha} key={idx}>
                    <Text style={{ ...styles.italic, ...local.bolinhaTitle }}>
                      {goal.label}
                    </Text>
                    <Text style={{ ...styles.extraBold, ...local.bolinhaValue }}>
                      {goal.value}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
      
      </Page>
    );
  };

  return (
    <Page size={[640, 480]} style={styles.Page}>
      <Header backColor="#989c9c" title="6. Resultados" />
      <View style={local.body}>
        <Text style={{ ...local.title, ...styles.italic }}>
          6.1 Emissões totais do ano inventariado
        </Text>
        <View style={{...local.section}}>
          <View style={{...local.donutChart}}>
            <DonutChart data={donutData} />
            <Text style={{ ...local.donutSub, ...styles.extraLight, marginLeft: 18 }}>
              *Outros gases não contemplados pelo Protocolo de Quioto
            </Text>
          </View>
          <Table />
        </View>
        <View style={{ width: "100%", borderTop: "1px solid #81c787" }}>
          <View style={local.footer}>
            
            <View style={{...local.bolinhas}}>
              {GOALS.map((goal, idx) => (
                <View style={{...local.bolinha}} key={idx}>
                  <Text style={{ ...styles.italic, ...local.bolinhaTitle }}>
                    {goal.label}
                  </Text>
                  <Text style={{ ...styles.Bold, ...local.bolinhaValue }}>
                    {goal.value}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <ImagePDF 
            src={resume?.company_itens.logo_image_url}
            style={{
              width: 50,
              right: '85px',
              position: 'absolute',
              bottom: '14px'
            }}
          />
          <ImagePDF 
            src={LogoVerdeEscuro}
            style={{
              width: 90,
              right: '0px',
              bottom: '14px',
              position: 'absolute'
            }}
          />
        </View>
      </View>
    </Page>
  );
};

const local = StyleSheet.create({
  body: {
    display: "flex",
    width: "100%",
    height: "auto",
    paddingVertical: "10px",
    alignItems: "center",
    alignContent: "center",
    backgroundColor: "#ecf3f4",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    width: "80%",
    height: "30%",
    paddingLeft: "20px",
  },
  bolinhas: {
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-end",
    justifyContent: "space-evenly",
    width: "90%",
    height: "auto",
    marginTop: "20px",
  },
  bolinha: {
    borderRadius: 35,
    backgroundColor: "#326ed6",
    width: 70,
    height: 70,
    display: "flex",
    alignContent: "center",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderWidth: 2,
    borderColor: "#81c787",
  },
  bolinhaTitle: {
    color: "white",
    fontSize: 10,
    flexWrap: "wrap",
  },
  bolinhaValue: {
    color: "#81c787",
    fontSize: 12,
  },
  title: {
    color: "gray",
    fontSize: 18,
    padding: "5px",
    paddingLeft: "20px",
    alignSelf: "flex-start",
    marginLeft: "14px"
  },
  donutSub: {
    color: "#64766d",
    fontSize: 8,
    marginTop: "20px",
    alignSelf: "center",
    width: "100%",
  },
  subTitle: {
    fontSize: 11,
    color: "#a1a4a6",
    alignSelf: "flex-start",
    width: "80%",
    paddingLeft: "20px",
    height: "auto",
  },
  section: {
    width: "100%",
    height: "70%",
    display: "flex",
    alignContent: "center",
    flexDirection: "row",
    padding: "10px",
  },
  donutChart: {
    height: "120%",
    width: "40%",
    padding: "10px",
    alignContent: "center",
    display: "flex",
  },
  table: {
    height: "auto",
    width: "60%",
    padding: "10px",
  },
});
