import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/styles";
import { GoBack } from "../../components/GoBack";
import { ModalDelete } from "../../components/ModalDelete";
import { Pagination } from "../../components/Pagination";
import { TitleText } from "../../components/TitleText";
import useAuth from "../../hooks/useAuth";
import useDebounce from "../../hooks/useDebounce";
import activityService from "../../services/activityService";
import { Container } from "./styles";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

export const Activity = () => {
  const history = useHistory();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [activities, setActivities] = useState<any>([]);
  const [activitySelected, setActivitySelected] = useState<any>({});

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const searchDebounced = useDebounce(searchValue, 800);

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  async function getActivities(page: number, filter: string) {
    const result = await activityService.getByParams({ limit: 25, page, filter: filter });

    setActivities(result);

    setCurrentPage(page)
  }

  useEffect(() => {
    getActivities(1, searchDebounced);
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   []);

  useEffect(() => {
    getActivities(1, searchDebounced)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [searchDebounced]);

  async function handleDeleteActivity() {
    if (activitySelected) {
      const result = await activityService.deleteById(
        activitySelected?.id
      );

      if (result) {
        setOpenModalDelete(false);
        getActivities(1, searchDebounced);
          
        toast.success("Operação realizada com sucesso!")
      }
    }
  }

  async function getActivitiesById(id: number) {
    const activity = await activityService.getById(id);

    setActivitySelected(activity)
  }

  function redirectToEdit(activity?: any) {
    if (activity){
      history.push({
          pathname: '/setor/edicao',
          state: { activity_id: activity.id }
      });
    }else{
      history.push('/setor/edicao')
    }
  }

  function handleOpenModalDelete(activity?: any) {
    setOpenModalDelete(!openModalDelete);

    if (activity) {
      getActivitiesById(activity.id);
    } else {
      setActivitySelected({});
    }
  }

  const goPrev = () => {
    setCurrentPage(currentPage - 1);
    getActivities(currentPage - 1, searchDebounced);
  };

  const goNext = () => {
    setCurrentPage(currentPage + 1);
    getActivities(currentPage + 1, searchDebounced);
  };

  return (
    <Container>
      <GoBack />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 15,
          justifyContent: "space-between",
        }}
      >
        <TitleText level={1} title="Setores econômicos" />

        <input
          className="input-search"
          placeholder="Pesquisar setores econômicas"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        
        <Button color="blue" size="md" onClick={() => redirectToEdit()}>
          + Novo setor
        </Button>
      </div>

      {activities?.itens &&
        (
          <div style={{ overflow: "hidden auto", position: "relative", marginTop: 10,  height: "calc(100vh - 130px)", 
              maxHeight: "calc(100vh - 130px)"}} >
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                  <th
                    style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                  >
                    {"Nome"}
                  </th>
                  <th
                    style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                  >
                    {"Descrição"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {activities.itens.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td style={{ padding: "10px", width: "45%" }} >
                          <span className="title-text-underline">
                            {item.name}
                          </span>
                        </td>
                        <td style={{ padding: "10px", width: "45%" }} >
                          <span>
                            {item.description}
                          </span>
                        </td>
                        <td>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                          <button
                              className="button-edit"
                              onClick={() => redirectToEdit(item)}
                            >
                              Editar
                            </button>
                            <button
                              className="button-delete"
                              style={{ marginRight: 5 }}
                              onClick={() => handleOpenModalDelete(item)}
                            >
                              Excluir
                            </button>
                           
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
              {activities?.itens?.length > 0 && (
                <Pagination
                  total={activities.total}
                  limit={25}
                  goNext={goNext}
                  goPrev={goPrev}
                  currentPage={currentPage}
                  length={activities.itens.length}
                />
              )}

          </div>
        )
      }

      {openModalDelete && (
        <ModalDelete
          isOpen={openModalDelete}
          handleModalVisible={handleOpenModalDelete}
          entityName={activitySelected.name}
          handleOk={handleDeleteActivity}
        />
      )}
    </Container>
  );
};
